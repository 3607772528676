import { call } from 'redux-saga/effects';
import { BiEvent } from 'analytics';
import { select } from 'redux-saga/effects';
import { User } from 'utils/entities';
import { waitForUserResolve } from '../routing/handlers/utils';
import { isAgentManagerSelector, userProfileSelector } from 'store/state/domainData/selectors';

export function* userPreProcessor({ name, category, payload }: BiEvent) {
  yield call(waitForUserResolve);
  const user: User = yield select(userProfileSelector);
  if (user && user.uid) {
    const isAgentManager = yield select(isAgentManagerSelector);
    const userRoles = [ ...user.roles, (isAgentManager ? 'agent_manager' : null) ].filter(Boolean);
    return {
      name,
      category,
      payload: {
        ...payload,
        common: {
          user_id: user.uid,
          user_roles: userRoles,
        },
      },
    };
  }

  return { name, category, payload };
}
