import styled from '@emotion/styled';
import { Link } from 'components/link';

export const SaveSearchButtonWrapper = styled.div<{noMargin?: boolean}>`
  direction: ${({ theme }) =>  theme.isRTL ? 'rtl' : 'ltr'};
  float: ${({ theme }) => theme.isRTL ? 'left' : 'right'};
  display: flex;
  ${({ theme }) => theme.media.showAt({ to: 3 })`
    margin-top: 5px;
  `};
  ${({ theme, noMargin }) => theme.media.showAt({ from: 3 })`
    margin-top: ${noMargin ? 0 : '7px'};
  `};
`;

export const SavedSearchLink = styled(Link)`
  ${({ theme }) => `
    padding: ${theme.spacing(1.5)} ${theme.spacing(2.5)};
    background-color: ${theme.colors.brand.lightest};
    border-radius: ${theme.borderRadius.extraXLarge};
    color: ${theme.colors.brand.main};
    text-decoration: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing(0.5)};
  `}
`;

export const SaveSearchButtonMobileRoot = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: space-around;
  `}
`;
