import React from 'react';
import * as Styled from './styled';
import CrossIcon from 'assets/svg/interface-16-close-1-5.svg';
import MadlanWhite from 'assets/svg/madlan-white.svg';
import { FadeWrapper } from 'components/authentication/styled';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useLocale } from 'locale';
import config from 'config';


interface MobileDiscoveryProps {
  setModalStatus: (v: boolean) => void;
  marketLink: string;
}

const imageUrl = 'mobile/mobile-discovery.jpeg';
const imageUrlWithPush = 'mobile/mobile-discovery-push.png';

const imageOpts = { height: 716 };


const MobileDiscovery: React.FC<MobileDiscoveryProps> = (props) => {
  const {
    setModalStatus,
    marketLink,
  } = props;
  const { t } = useLocale();
  const constructImageURI = useImageUrlBuilder();

  const onClose = () => {
    setModalStatus(false);
  };

  const handleClick = () => {
    window.open(marketLink, '_blank').focus();
    onClose();
  };
  // temporary copy-paste until push is in prod and we remove pushNotificationsEnabled config option
  if (config.pushNotificationsEnabled) {
    return (
      <OverlayWrapper>
        <FadeWrapper isVisible>
          <Styled.ModalOverlay onClick={onClose} />
          <Styled.Root>
            <Styled.MainContentWrapper>
              <Styled.DiscoveryInfoWithPush>
                <Styled.Header>
                  <Styled.CloseButton />
                  <div/>
                  <Styled.CloseButton onClick={onClose}>
                    <CrossIcon width={16} height={16} />
                  </Styled.CloseButton>
                </Styled.Header>
                <Styled.Title weight="bold">{t('discovery.mobile.withPush.title')}</Styled.Title>
                <Styled.BodyTxt weight="bold">{t('discovery.mobile.withPush.body')}</Styled.BodyTxt>
                <Styled.Picture imgUrl={constructImageURI(imageUrlWithPush, imageOpts)}/>
              </Styled.DiscoveryInfoWithPush>
              <Styled.MoreBtn onClick={handleClick} size="extraXLarge" mode="primary">{t('discovery.mobile.more.btn')}</Styled.MoreBtn>
            </Styled.MainContentWrapper>
          </Styled.Root>
        </FadeWrapper>
      </OverlayWrapper>
    );
  }

  return (
    <OverlayWrapper>
      <FadeWrapper isVisible>
        <Styled.ModalOverlay onClick={onClose} />
        <Styled.Root>
          <Styled.MainContentWrapper>
            <Styled.DiscoveryInfo>
              <Styled.Header>
                <Styled.CloseButton />
                <MadlanWhite />
                <Styled.CloseButton onClick={onClose}>
                  <CrossIcon width={16} height={16} />
                </Styled.CloseButton>
              </Styled.Header>
              <Styled.Title weight="bold">{t('discovery.mobile.title')}</Styled.Title>
              <Styled.Picture imgUrl={constructImageURI(imageUrl, imageOpts)} />
            </Styled.DiscoveryInfo>
            <Styled.MoreBtn onClick={handleClick} size="extraXLarge" mode="primary">{t('discovery.mobile.more.btn')}</Styled.MoreBtn>
          </Styled.MainContentWrapper>
        </Styled.Root>
      </FadeWrapper>
    </OverlayWrapper>
  );
};

export default MobileDiscovery;
