import { InformationAndServicesLinksSection, InformationAndServicesSectionToLinks, LinksSection, LinksSectionLink, SectionToLinks } from 'components/navigation/more-actions-menu/types';
import { Route } from 'config/routes';
import { createSelector } from 'reselect';
import { isAgentSelector, isConnectedUserSelector } from 'store/state/domainData/selectors';
import config from 'config';

const baseUrl = '/';

export const agentsLinks: LinksSectionLink[] = [
  { label: 'מאגר המתווכים', path: `${baseUrl}search-agent?source=left_menu` },
  { label: 'מדד המתווכים 2023/24', path: `${baseUrl}madad?source=left_menu` },
  { label: 'מדד המתווכים 2022/23', path: `${baseUrl}madad/2023?source=left_menu` },
  { label: 'מדד המתווכים 2021/22', path: `${baseUrl}madad/2022?source=left_menu` },
  { label: 'מדד המתווכים 2020/21', path: `${baseUrl}madad/2021?source=left_menu` },
  { label: 'מדד המתווכים 2019/20', path: `${baseUrl}madad/2020?source=left_menu` },
];

export const informationAndServicesLinks: InformationAndServicesSectionToLinks = {
  [InformationAndServicesLinksSection.Main] : [
    { label: 'מדלן אישי', path: `${baseUrl}blog/מדלן-אישי/` },
    { label: 'הבלוק - בלוג הבית של מדלן', path: `${baseUrl}blog/` },
    { label: 'מדלן חינוך ובתי ספר', path: `${baseUrl}education?source=left_menu` },
  ],
  [InformationAndServicesLinksSection.GuidesAndTools] : [
    { label: 'מחשבון מס רכישה', path: `${baseUrl}blog/מחשבון-מס-רכישה-2020/` },
    { label: 'מחשבון תשואות שכירות', path: `${baseUrl}blog/מחשבון-תשואת-שכירות-2022/` },
    { label: 'משכנתא', path: `${baseUrl}mortgage/articles?source=left_menu` },
    { label: 'מדריכים נוספים', path: `${baseUrl}blog/guides/` },
  ],
  [InformationAndServicesLinksSection.ManagementSystems] : [
    { label: 'מדלן ליזמים', path: `${baseUrl}professional?source=left_menu` },
    { label: 'מדלן למתווכים', path: `${baseUrl}proAgent` },
    { label: 'MadlanX', path: config.madlanXUrl },
  ],
};

export const staticLinksSelector = createSelector([
  isConnectedUserSelector,
  isAgentSelector,
], (isUser, isAgent): SectionToLinks => ({
  [LinksSection.Localize]: [
    { label: 'פרסום ליזמים', path: `${baseUrl}lp/promotion?source=left_menu` },
    { label: 'פרסום למתווכים', path: `${baseUrl}lp/agents?source=left_menu` },
    { label: 'מי אנחנו', path: `${baseUrl}etc/aboutUs?source=left_menu` },
    { label: 'שאלות נפוצות', path: `${baseUrl}etc/faq?source=left_menu` },
    { label: 'צרו קשר', path: `${baseUrl}lp/connectUs?source=left_menu` },
    { label: 'הצהרת נגישות', path: `${baseUrl}${Route.Accessibility}?source=left_menu` },
  ],
  [LinksSection.Guides]: [
    { label: 'חינוך', path: `${baseUrl}education?source=left_menu` },
    { label: 'מדריך ערים', path: `${baseUrl}local/pricesHeatmap?source=left_menu` },
    { label: 'משכנתא', path: `${baseUrl}mortgage/articles?source=left_menu` },
    { label: 'אינדקס יזמים', path: `${baseUrl}developers?source=left_menu` },
    { label: 'מאגר המתווכים', path: `${baseUrl}search-agent?source=left_menu` },
    { label: 'מדד המתווכים של מדלן 2023/24', path: `${baseUrl}madad?source=left_menu` },
    { label: 'מדד המתווכים של מדלן 2022/23', path: `${baseUrl}madad/2023?source=left_menu` },
    { label: 'מדד המתווכים של מדלן 2021/22', path: `${baseUrl}madad/2022?source=left_menu` },
    { label: 'מדד המתווכים של מדלן 2020/21', path: `${baseUrl}madad/2021?source=left_menu` },
    { label: 'בלוג', path: `//www.madlan.co.il/blog/?source=left_menu` },
    { label: 'מחשבון מס רכישה 2021', path: `//www.madlan.co.il/blog/מחשבון-מס-רכישה-2020/?source=left_menu` },
  ],
  [LinksSection.Tools]: [
    { label: 'מדלן למתווכים', path: `${baseUrl}agent/main?source=left_menu` },
    { label: 'מדלן ליזמים', path: `${baseUrl}professional?source=left_menu` },
  ],
  [LinksSection.ManageBulletin]: [
    { label: 'העלאת מודעה', path: `${baseUrl}upload-bulletin?source=left_menu` },
    ...(isUser
      ? (isAgent
        ? [
          { label: 'מערכת מתווכים', hideInApp: true, path: `${baseUrl}agent/main?source=left_menu` },
          { label: 'מערכת ניהול נדל״ן מסחרי', path: `${baseUrl}manage-bulletins?source=left_menu` },
        ] : [ { label: 'מודעות שפרסמתי', path: `${baseUrl}manage-bulletins?source=left_menu` } ]
      ) : []),
  ].filter(Boolean),
}));
