import React from 'react';
import { Cover, CoverImageWrapper, CoverWrapper, SearchSection, Title, TitleMiddle, BlogSectionWrapper } from 'components/check-address/styled';
import { useLocale } from 'locale';
import { State as RouteState, NavigateToFn } from 'config/routes';
import Footer from 'components/static-footer';
import SearchBlock from 'components/check-address/search-block/SearchBlock';
import { Area, MarketplaceType } from 'utils/entities';
import CoverImageResidential from 'assets/svg/check-address/cover-image-residential.svg';
import CoverImageCommercial from 'assets/svg/check-address/cover-image-commercial.svg';
import CoverImageResidentialMobile from 'assets/svg/check-address/cover-image-residential-mobile.svg';
import CoverImageCommercialMobile from 'assets/svg/check-address/cover-image-commercial-mobile.svg';
import CoverImageResidentialTablet from 'assets/svg/check-address/cover-image-residential-tablet.svg';
import CoverImageCommercialTablet from 'assets/svg/check-address/cover-image-commercial-tablet.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import BlogSection from 'components/blog-carousel/check-address';

interface CheckAddressProps {
  navigate: NavigateToFn;
  route: RouteState;
  areasWithCommercialInfoPage: Area[];
  marketplace: MarketplaceType;
}

export const CheckAddress: React.FC<CheckAddressProps> = ({
  navigate,
  route,
  areasWithCommercialInfoPage,
  marketplace,
}) => {
  const { t } = useLocale();
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobile = mediaBreakpoint === 1;
  const isTablet = mediaBreakpoint === 2;
  const isCommercial = marketplace === MarketplaceType.Commercial;

  const getCoverImage = () => {
    if (isCommercial) {
      if (isMobile)  return <CoverImageCommercialMobile />;
      else if (isTablet) return <CoverImageCommercialTablet />;
      return <CoverImageCommercial />;
    }
    else {
      if (isMobile)  return <CoverImageResidentialMobile />;
      else if (isTablet) return <CoverImageResidentialTablet />;
      return <CoverImageResidential />;
    }
  };

  return (
    <>
      <CoverWrapper isCommercial={isCommercial}>
        <Cover>
          <SearchSection>
            <Title>
              {t('checkAddress.title.start')}
              <TitleMiddle isCommercial={isCommercial}>
                {isCommercial ?
                  t('checkAddress.title.middle.commercial') :
                  t('checkAddress.title.middle.residential')
                }
              </TitleMiddle>
              {t('checkAddress.title.end')}
            </Title>
            <SearchBlock
              route={route}
              areasWithCommercialInfoPage={areasWithCommercialInfoPage}
              isCommercial={isCommercial}
              navigate={navigate}
            />
          </SearchSection>
          <CoverImageWrapper>
            {getCoverImage()}
          </CoverImageWrapper>
        </Cover>
      </CoverWrapper>
      <BlogSectionWrapper>
        <BlogSection />
      </BlogSectionWrapper>
      <Footer noBorderTop/>
    </>
  );
};
