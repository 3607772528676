import React from 'react';
import { SmallText } from 'ds/components/typography';
import { VerticalLine, Wrapper } from './styled';
import { useLocale } from 'locale';
import { NoDataWidget } from '../common/NoDataWidget';
import { PriceCollapsedDynamic } from 'components/widget/prices/section/selectors';
import { ShowAt } from 'consts/breakpoints';

export const PricingDynamicContent: React.FC<PriceCollapsedDynamic> = ({ avgPrice, yieldPrice }) => {

  const { t, formatMoneyWithMetricPrefix, formatNumber } = useLocale();

  return avgPrice || yieldPrice ? (
    <ShowAt at={1}>
      {isMobile => (
        <Wrapper>
          {avgPrice ? (
            <SmallText>
              {t('widget.collapsed.price', { type: 'buy', isMobile })}&#12539;
              <span>{formatMoneyWithMetricPrefix(avgPrice, avgPrice <= 1E6 ? 0 : 1)}</span>
            </SmallText>
          ) : null}
          {yieldPrice ? (
            <>
              <VerticalLine />
              <SmallText>{t('widget.collapsed.price', { type: 'yield' })}&#12539;{formatNumber(yieldPrice)}%</SmallText>
            </>
          ) : null}
        </Wrapper>
      )}
    </ShowAt>
  ) : <NoDataWidget>{t('widgetTitle.collapsed.noDataAtThisTime')}</NoDataWidget>;
};
