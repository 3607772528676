import { select, take, getContext, call } from 'redux-saga/effects';

import { SortField } from 'components/listing-sort/types';
import { BiEvent } from 'analytics';
import { prevRouteSelector, getRouteParams, routeSelector } from 'store/state/selectors/router';
import { SET_DOMAIN_DATA, RESET_SSR_HYDRATION } from 'store/state/domainData/types';
import { RootAction } from 'store/state';
import { DealType, IBulletin, IProject, DocId2InfoDocument } from 'utils/entities';
import { searchListPoiListSelector, searchInfoEntriesSelector } from 'store/state/domainData/selectors';

import { LoadType } from '../apiService/types';
import { mapAddressToAnalytics } from './personalAssistant';
import { Condition, Seller } from 'components/filters/types';
import { searchViewModeSelector } from 'store/state/app/selectors';
import { SearchViewMode } from 'components/list-view-switcher/constants';


interface ISearchPropertyResultsTagLines {
  property_id: string;
  number_of_additional_taglines?: number;
  match_percentage?: number;
  tag_line?: string;
}

export const sortFieldToTrackingField: Partial<Record<SortField, string>> = {
  [SortField.BestSchool]: 'schools',
  [SortField.BestSecular]: 'scholls_secular',
  [SortField.BestReligious]: 'schools_religious',
  [SortField.LightRail]: 'light_rail',
  [SortField.Commute]: 'commute',
  [SortField.QuietStreets]: 'quiet_street',
  [SortField.Parks]: 'park_access',
  [SortField.DogParks]: 'dog_friendly',
  [SortField.Safety]: 'crime',
  [SortField.FamilyFriendly]: 'family_friendly',
};

export const dealTypeToTrackingType: Record<DealType, string> = {
  [DealType.Buy]: 'buy_home',
  [DealType.Rent]: 'rent_home',
};

export const mapRangeItemToTrackingStringTuple = (count: number) => count === null ? '' : count.toString();

const mapSellerToSellerType = (sellerType: Seller) => {
  switch (sellerType) {
    case Seller.Private:
      return 'individual';
    case Seller.Agent:
      return 'agent';
    case Seller.Developer:
      return 'developer';
    default:
      const exhaustiveCheck: never = sellerType;
      throw new Error(`Unhandled sellerType case: ${exhaustiveCheck}`);
  }
};

const eventCategoriesWithExcludePayloadSearch = new Set([
  'bulletin_upload_wizard',
  'city_change',
  'developer',
  'favorites',
  'homepage',
  'page',
  'pwa',
  'ugc',
]);

const eventCategoriesHomePageCover = new Set([
  'search_bar_tab_click',
  'search_bar_chip_click',
  'search_bar_mobile_form_category_click',
  'search_bar_mobile_form_chip_click',
]);

const searchListResponsePattern = (action: RootAction) => (
  (action.type === RESET_SSR_HYDRATION && action.payload.loadType === LoadType.SearchList)
  ||
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.SearchList)
);

let prevTrackingSearchSource: string = null;

export function* searchResultsWorker() {
  while (true) {
    yield take(searchListResponsePattern);
    const poiList: Array<IBulletin | IProject> = yield select(searchListPoiListSelector);
    const { sendEvent } = yield getContext('analytics');
    const params = yield select(getRouteParams);

    let trackingSearchSource;

    if (params.tracking_search_source !== prevTrackingSearchSource || (prevTrackingSearchSource === 'new_search' && params.tracking_search_source === 'new_search')) {
      prevTrackingSearchSource = params.tracking_search_source;
      trackingSearchSource = params.tracking_search_source;
    }

    if (poiList.length) {
      yield call(sendEvent, 'search_results_loaded', 'search', {
        event: {
          search_top_results: poiList.slice(0, 4).map(p => p.id).filter(Boolean),
          search_top_results_taglines: poiList.slice(0, 20).map(poi => {
            if ('insights' in poi && poi.id && poi.insights.insights) {
              const insights = poi.insights.insights;
              if (insights.length && insights[0].tradeoff) {
                let resultsTaglinesObj: ISearchPropertyResultsTagLines = {
                  property_id: poi.id,
                  number_of_additional_taglines: insights.length - 1,
                  match_percentage: poi.matchScore || undefined,
                };

                if (insights[0].tradeoff.tagLine) {
                  resultsTaglinesObj = { ...resultsTaglinesObj, tag_line: insights[0].tradeoff.tagLine };
                }

                return resultsTaglinesObj;
              }
            }
            return {
              property_id: poi.id || 'N/A',
            };
          }).filter(Boolean),
          source: trackingSearchSource,
        },
      });
    }
    else {
      yield call(sendEvent, 'search_zero_results', 'search', {
        event: {
          source: 'search_results_loaded',
        },
      });
    }
  }
}

const makeSearchFilters = (filters: any) => ({
  amenities: filters.amenities || [],
  baths: (filters.bathsRange || []).map(mapRangeItemToTrackingStringTuple),
  bedrooms: (filters.roomsRange || []).map(mapRangeItemToTrackingStringTuple),
  home_type: filters.propertyTypes || [],
  price: filters.priceRange || [],
  // until BE fix
  // is_discount_project: Boolean(filters.projectDiscount),
  price_for_sqm: filters.ppmRange || [],
  seller_type: filters.seller ? filters.seller.map((seller: Seller) => mapSellerToSellerType(seller)) : [],
  search_additional_filters: {
    bargains: {
      appartments_at_affordable_price_based_on_mad_madlan_choosen: filters.priceChanges
        ? filters.priceChanges.underPriceEstimation
        : false,
      apartments_whose_price_has_recently_dropped_choosen: filters.priceChanges
        ? filters.priceChanges.priceDrop
        : false,
    },
    appartment_condition: {
      is_new_from_developer:
        filters.conditions && filters.conditions.findIndex((c: string) => c === Condition.New) !== -1,
      is_new_appartment:
        filters.conditions && filters.conditions.findIndex((c: string) => c === Condition.AsNew) !== -1,
      is_repaired_appartment:
        filters.conditions && filters.conditions.findIndex((c: string) => c === Condition.Renovated) !== -1,
      is_preserved_appartment:
        filters.conditions && filters.conditions.findIndex((c: string) => c === Condition.Preserved) !== -1,
      is_old_appartment:
        filters.conditions && filters.conditions.findIndex((c: string) => c === Condition.ToRenovated) !== -1,
    },
  },
});

export function* searchPreProcessor(evt: BiEvent) {
  const { name, category, payload = {} } = evt;
  const { params } = yield select(routeSelector);
  const docsInfo: DocId2InfoDocument[] = yield select(searchInfoEntriesSelector);
  const view = yield select(searchViewModeSelector);
  const cardType = view ? (view === SearchViewMode.Grid ? 'square' : 'list') : null;

  const sort = params.sort || (payload.search && payload.search.power_sort_options) || [];
  const filters = params.filters || {};
  const docs = docsInfo || [];

  const searchType = params.dealType
    ? dealTypeToTrackingType[params.dealType]
    : payload.search && payload.search.search_type
      ? payload.search.search_type
      : 'check_address';

  const sortOptions = sort.map(([ sortField ]: [ SortField ]) => {
    return sortFieldToTrackingField[sortField];
  }).filter(Boolean);

  if (eventCategoriesWithExcludePayloadSearch.has(category)) return evt;

  if (name !== 'search_submit' || (name === 'search_submit' && payload.event.source === 'map')) {
    evt = {
      ...evt,
      payload: {
        ...payload,
        search: {
          search_type: searchType,
          text_entered: docs.map(({ document }) => document.docId),
          power_sort_options: sortOptions,
          locations_selected: docs.map(({ document }) => document).filter(Boolean).map(mapAddressToAnalytics),
          ...(cardType ? { 'card_type': cardType } : null),
        },
      },
    };
  }

  if (eventCategoriesHomePageCover.has(name)) {
    evt = {
      ...evt,
      payload: {
        ...evt.payload,
        search: {
          search_type: evt.payload.search.search_type,
          number_of_offices_returned: payload && payload.search && payload.search.number_of_offices_returned,
          number_of_agents_returned: payload && payload.search && payload.search.number_of_agents_returned,
          number_of_developers_returned: payload && payload.search && payload.search.number_of_developers_returned,
        },
      },
    };
  }

  if (name === 'survey_answer_submit') {
    return evt;
  }

  if (name === 'search_filter_clear') {
    const prevRoute = yield select(prevRouteSelector);
    const prevFilters = prevRoute && prevRoute.params && prevRoute.params.filters || {};
    return {
      ...evt,
      payload: {
        ...evt.payload,
        event: {
          ...evt.payload.event,
          search_filter_selected: makeSearchFilters(prevFilters),
        },
      },
    };
  }

  if (name === 'search_filter_submit_click' && payload.search) {
    return {
      name,
      category,
      payload: {
        ...evt.payload,
        search: {
          ...evt.payload.search,
          search_filters: {
            ...makeSearchFilters(filters),
            ...evt.payload.search.search_filters || {},
          },
        },
      },
    };
  }

  if (name === 'search_priorities_apply') {
    return {
      name,
      category,
      payload: {
        ...evt.payload,
        search: {
          ...evt.payload.search,
          search_filters: makeSearchFilters(filters),
        },
      },
    };
  }

  return {
    name,
    category,
    payload: {
      ...evt.payload,
      search: {
        ...evt.payload.search,
        search_filters: makeSearchFilters(filters),
      },
    },
  };
}
