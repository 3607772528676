import React from 'react';
import {
  Address,
  PropertyDetailItem,
  PropertyDetails,
  PropertyDetailTitle,
  PropertyDetailValue,
  Subheader,
  PropertyDetailsColumn, PropertyImage,
} from './styled';
import { useLocale } from 'locale';
import { IAffiliationPopupDeal } from 'components/deal-affiliation-popup/types';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'utils/gallery';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { Route } from 'config/routes';
import { Link } from 'components/link';


const EMPTY = '-';

interface DealAffiliationSubheaderProps {
  deal: IAffiliationPopupDeal;
}

const DealAffiliationSubheader: React.FC<DealAffiliationSubheaderProps> = ({ deal }) => {
  const { t, formatDate, formatMoney, formatArea } = useLocale();
  const constructImageURI = useImageUrlBuilder();

  const parsedArea = deal.area ? parseInt(deal.area, 10) : null;
  const pricePerSqMeter = parsedArea ? Math.floor(deal.price / parsedArea) : null;
  const imageUrl = constructImageURI(DEFAULT_PLACEHOLDER_IMAGE, { height: 168 });

  const { streetName, streetNumber, neighbourhood, city } = deal.structuredAddress;
  const address = `${streetName ? streetName : ''} ${streetNumber ? streetNumber + ',' : ''} ${neighbourhood ? neighbourhood + ',' : ''}\n ${city ? city : ''}`;

  return (
    <Subheader>
      <PropertyImage>
        <img src={imageUrl} />
      </PropertyImage>
      <Link target="_blank" routeName={Route.Sold} routeParams={{ id: deal.soldId }}>
        <Address weight="bold" data-auto="deal-address">
          {address}
        </Address>
      </Link>
      <PropertyDetails>
        <PropertyDetailsColumn>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-date-label">
              {t('dealAffiliationPopup.property.transactionDate')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-date-value">
              {deal.dealDate ? formatDate(deal.dealDate) : EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-price-label">
              {t('dealAffiliationPopup.property.price')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-price-value">
              {deal.price ? formatMoney(deal.price) : EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
        </PropertyDetailsColumn>
        <PropertyDetailsColumn>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-area-label">
              {t('dealAffiliationPopup.property.propertyArea')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-area-value">
              {parsedArea ? formatArea(parsedArea) : EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-price-persqmeter-label">
              {t('dealAffiliationPopup.property.pricePerSquareMeter')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-price-persqmeter-value">
              {pricePerSqMeter ? formatMoney(pricePerSqMeter) : EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
        </PropertyDetailsColumn>
        <PropertyDetailsColumn>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-rooms-label">
              {t('dealAffiliationPopup.property.rooms')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-rooms-value">
              {deal.rooms || EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-floor-label">
              {t('dealAffiliationPopup.property.floor')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-floor-value">
              {deal.floor ? t('filters.floorLabels', { floor: deal.floor }) : EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
        </PropertyDetailsColumn>
        <PropertyDetailsColumn>
          <PropertyDetailItem>
            <PropertyDetailTitle data-auto="deal-year-label">
              {t('dealAffiliationPopup.property.constructionYear')}
            </PropertyDetailTitle>
            <PropertyDetailValue data-auto="deal-year-value">
              {deal.constructionYear || EMPTY}
            </PropertyDetailValue>
          </PropertyDetailItem>
        </PropertyDetailsColumn>
      </PropertyDetails>
    </Subheader>
  );
};

export default DealAffiliationSubheader;
