import styled from '@emotion/styled';
import { TextLabel } from 'ds/components/typography';

export const AuthenticationSocialSuggestion = styled(TextLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};
    position: absolute;
    padding: 0 ${theme.spacing(2)};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

export const AuthenticationSocialSuggestionWrapper = styled(TextLabel)`
  display: flex;
  justify-content: center;
  height: 16px;
  position: relative;
  color: ${({ theme }) => theme.colors.neutrals.grey4};
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  ::before {
    position: relative;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      width: calc(100vw - 32px);
    `};
    top: 7px;
    background-color: ${({ theme }) => theme.colors.neutrals.grey8};
  }
`;


export const Root = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
  margin-top: ${theme.spacing(2)};
`);
