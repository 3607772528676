import { BiEvent } from 'analytics';

import { User } from 'utils/entities';
import { select } from 'redux-saga/effects';
import { userProfileSelector } from 'store/state/domainData/selectors';
import { createUserCommutePreferences } from './utils';


export function* userPreferencesPreProcessor({ name, category, payload }: BiEvent) {
  const user: User = yield select(userProfileSelector);

  if (!(user && user.commutePreference && user.commutePreference.commuteType)) {
    return { name, category, payload };
  }

  const { commutePreference } = user;
  return {
    name,
    category,
    payload: {
      ...payload,
      user_preferences: {
        commute_preferences: createUserCommutePreferences(commutePreference),
      },
    },
  };
}
