import styled from '@emotion/styled';
import { ItemWrapper as ItemRoot } from 'ds/components/carousels/carousel-projects/styled';

export const ItemWrapper = styled(ItemRoot)`
  ${({ theme }) => `
    &:first-of-type {
      margin-right: ${theme.spacing(4)};
    }
    > div:first-of-type {
      border: 1px solid ${theme.colors.neutrals.grey8};
      box-shadow: none;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: 0 -${theme.spacing(0.5)};
    .scrollBox > div:first-of-type {
      margin-right: ${theme.spacing(3)};
    }
  `}
`;
