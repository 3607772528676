import { getContext, select, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { reviewWizardStepIndexSelector, reviewWizardStepsSelector, textReviewInputSelector } from 'store/state/UGC/selectors';
import { Topic, RatingTopicToEventRatingTopic } from 'components/user-generated-content/utils/initialUGCConfig';
import { SET_RATING_FROM_PREVIEW, SET_RATING, NEXT_STEP } from 'store/state/UGC/types';
import { setRating } from 'store/state/UGC/actions';
import { RootAction } from 'store/state';
import { MUTATE } from 'store/state/mutationsResponse/types';
import { MutationType } from '../apiService/types';

function* isTextReviewStepByIndex(index: number) {
  const stepsOptions = yield select(reviewWizardStepsSelector);
  return stepsOptions[index] && stepsOptions[index].type === Topic.TextReview;
}

function* sendTextReviewSubmitEvent() {
  const textReviewInput = yield select(textReviewInputSelector);
  if (textReviewInput) {
    const { sendEvent } = yield getContext('analytics');
    sendEvent('ugc_review_text_submit', 'ugc', { event: { review_text: textReviewInput } });
  }
}

function* sendReviewDoneEvent() {
  const { sendEvent } = yield getContext('analytics');
  sendEvent('ugc_review_done_click', 'ugc');
}

function* selectedRatingWorker({ payload }: ReturnType<typeof setRating>) {
  const { value, topic } = payload;
  const { sendEvent } = yield getContext('analytics');
  const currentStepIndex = yield select(reviewWizardStepIndexSelector);
  const textReviewIsFirstStep = yield call(isTextReviewStepByIndex, 0);
  // rateTopicIndex is expected to be 1-based index
  const rateTopicIndex = textReviewIsFirstStep ? currentStepIndex : currentStepIndex + 1;
  sendEvent('ugc_rate_click', 'ugc', {
    event: {
      rate_numeric_value: value,
      topic: RatingTopicToEventRatingTopic[topic],
      topic_index: rateTopicIndex,
    },
  });
}

function* doneReviewWorker() {
  const stepsOptions = yield select(reviewWizardStepsSelector);
  const textReviewIsFirstStep = yield call(isTextReviewStepByIndex, 0);
  if (!(textReviewIsFirstStep && stepsOptions.length > 1)) {
    // otherwise, such event has already been sent
    yield call(sendTextReviewSubmitEvent);
  }
  yield call(sendReviewDoneEvent);
}

function* movedToNextStepWorker() {
  const currentStepIndex = yield select(reviewWizardStepIndexSelector);
  const prevStepWasTextReview = yield call(isTextReviewStepByIndex, currentStepIndex - 1);
  if (prevStepWasTextReview) {
    yield call(sendTextReviewSubmitEvent);
  }
}

const addUGCMutationPattern = (action: RootAction) => (
  action.type === MUTATE && action.mutationType === MutationType.AddUGC
);

export function* ugcEventsWatcher() {
  yield takeLatest([ SET_RATING, SET_RATING_FROM_PREVIEW ], selectedRatingWorker);
  yield takeEvery(NEXT_STEP, movedToNextStepWorker);
  yield takeEvery(addUGCMutationPattern, doneReviewWorker);
}
