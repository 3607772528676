import { call, put, select } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { navigateTo } from 'store/state/router/actions';
import { mortgageOfficeSelector } from 'store/state/domainData/selectors/mortgageOffice';
import { waitForUserResolve } from '../utils';

function* loadMortgageOffice(route: RouteState) {
  const { id } = route.params;
  yield call(waitForUserResolve);

  const loadOptions: LoadOptions<LoadType.MortgageOfficeById> = {
    loadType: LoadType.MortgageOfficeById,
    meta: {
      variables: { query : { ids: [ id ] } },
    },
  };
  yield call(queryData, loadOptions);

  const mortgageOfficeData = yield select(mortgageOfficeSelector);

  if (!mortgageOfficeData) {
    yield put(navigateTo(Route.Home, {}, { replace: true }));
  }
}

export function* mortgageOfficePageHandler(params: RouteState) {
  yield call(loadMortgageOffice, params);
}
