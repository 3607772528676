import React  from 'react';
import {
  CarouselControls,
  CarouselControlsWrapper,
  ThumbnailsBox,
  ThumbnailsWrapper, ThumbnailsWrapperInner, AbsoluteFill,
} from 'ds/components/ListingCard';
import { IThumbnail, PoiId } from 'utils/entities';
import {
  NextControlArrowWrapper,
  PagerCategoryWrapper,
  PagerWrapper, PlaceholderImage,
  PrevControlArrowWrapper,
} from '../styled';
import ControlsArrow from '../ControlsArrow';
import { Pager } from 'components/cards/listing/Pager';
import { ViewPager } from 'components/view-pager';
import { getInfiniteNextIndex, getInfinitePrevIndex, shouldLazilyLoad } from 'utils/gallery';
import SliderItem from '../SliderItem';
import config from 'config';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { LazyLoad } from 'components/lazy-load';

const LAZY_LOAD_MARGIN = 1;

export interface CarouselProps {
  id: PoiId;
  thumbnails: IThumbnail[];
  slideIndex: number;
  setSlideIndex: (index: number) => void;
  emptyLazyLoadPlaceholder?: boolean;
  hideLogoPreview?: boolean;
  hideMap?: boolean;
  logoPath: string;
  hadInteraction: boolean;
  onlyOneImageShouldBeDisplayed?: boolean;
  isMobile: boolean;
  handleInteraction: () => void;
  altText?: string;
  disableSwipe?: boolean;
  height?: number;
  controlsSize?: 'medium' | 'large';
}

const Carousel: React.FC<CarouselProps> = ({
  thumbnails,
  slideIndex,
  handleInteraction,
  hideMap,
  hideLogoPreview,
  logoPath,
  hadInteraction,
  onlyOneImageShouldBeDisplayed,
  children,
  isMobile,
  setSlideIndex,
  emptyLazyLoadPlaceholder,
  altText,
  disableSwipe,
  height,
  controlsSize = 'medium',
}) => {
  const withLogoPreview = Boolean((config.universalCardWithLogo && logoPath && slideIndex === 0) && !hideLogoPreview);
  const totalPictureLength = thumbnails.length;

  const slideMapper = ({ url, rotateDegrees }: IThumbnail, i: number) => {
    const shouldLoad = hadInteraction
      ? shouldLazilyLoad(slideIndex, i, totalPictureLength, LAZY_LOAD_MARGIN)
      : i === slideIndex;

    return shouldLoad ? (
      <SliderItem
        // there is possibility of having two images which are the same hence we use index as key here
        key={i}
        path={url}
        rotateDegrees={rotateDegrees}
        altText={altText}
        dataAuto="universal-card-image"
      />
    // perf optimization to reduce styled overhead
    ) : <div style={{ flexGrow: 1 }} key={i} />;
  };


  const handlePrevSlide = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSlideIndex(getInfinitePrevIndex(slideIndex, totalPictureLength));
  };

  const handleNextSlide = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSlideIndex(getInfiniteNextIndex(slideIndex, totalPictureLength));
  };

  const placeholderUrl = 'placeholder_images2/placeholder_v2.png';

  const renderPlaceholder = (container?: React.RefObject<HTMLDivElement>) => {
    if (thumbnails.length && !emptyLazyLoadPlaceholder) {
      return (
        <AbsoluteFill ref={container}>
          {slideMapper(thumbnails[0], 0)}
        </AbsoluteFill>
      );
    }
    return <div style={{ height: 1 }} ref={container} />;
  };

  const constructImageURI = useImageUrlBuilder();

  return (
    <ThumbnailsBox
      withLogo={withLogoPreview}
      withHover={!isMobile}
      data-auto="universal-card-thumbnail-wrapper"
      className="universal-card-thumbnail-wrapper"
    >
      <ThumbnailsWrapper height={height} className="universal-card-thumbnails-wrapper">
        <LazyLoad renderPlaceholder={renderPlaceholder}>
          {totalPictureLength ? (
            <ThumbnailsWrapperInner
              onTouchStart={handleInteraction}
              onMouseEnter={handleInteraction}
              className="universal-card-thumbnail-image"
            >
              {onlyOneImageShouldBeDisplayed ? (
                <AbsoluteFill>
                  {slideMapper(thumbnails[0], 0)}
                </AbsoluteFill>
              ) : (
                <ViewPager
                  align="center"
                  slideIndex={slideIndex}
                  onSlideIndexChange={setSlideIndex}
                  disableSwipe={disableSwipe}
                  isRTL
                >
                  {thumbnails.map(slideMapper)}
                </ViewPager>
              )}
            </ThumbnailsWrapperInner>
          ) : (
            <PlaceholderImage
              imageUrl={constructImageURI(placeholderUrl)}
              className="universal-card-thumbnail-image"
              data-auto="universal-card-image-placeholder"
            />
          )}
          {children}
          {thumbnails.length && !onlyOneImageShouldBeDisplayed && hideMap ? (
            <CarouselControlsWrapper>
              {isMobile ? null : (
                <NextControlArrowWrapper className="universal-card-hover-show">
                  <ControlsArrow
                    type="prev"
                    onClick={handleNextSlide}
                    size={controlsSize}
                    disabled={thumbnails.length <= 1}
                  />
                </NextControlArrowWrapper>
              )}
              <CarouselControls>
                <PagerWrapper className="universal-card-pager-hover">
                  {thumbnails.length && !(disableSwipe && isMobile) ? (
                    <PagerCategoryWrapper className="bulletin-pager">
                      <Pager
                        total={thumbnails.length}
                        onChangeIndex={setSlideIndex}
                        index={slideIndex > thumbnails.length - 1 ? null : slideIndex}
                      />
                    </PagerCategoryWrapper>
                  ) : null}
                </PagerWrapper>
              </CarouselControls>
              {isMobile ? null : (
                <PrevControlArrowWrapper className="universal-card-hover-show">
                  <ControlsArrow
                    type="next"
                    onClick={handlePrevSlide}
                    size={controlsSize}
                    disabled={thumbnails.length <= 1}
                  />
                </PrevControlArrowWrapper>
              )}
            </CarouselControlsWrapper>
          ) : null}
        </LazyLoad>
      </ThumbnailsWrapper>
    </ThumbnailsBox>
  );
};

export default Carousel;
