import styled from '@emotion/styled';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import { SmallText } from 'ds/components/typography';

export const SELECTED_VALUE_CLASS = 'autocomplete-selected-value';

const MAP_BUTTON_WIDTH = 106;

export const AutocompleteInputWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.neutrals.white};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    max-width: calc(100% - ${MAP_BUTTON_WIDTH}px); flex-grow: 1;
  `}
`;

export const MobileOpenContentWrapper = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.neutrals.white};
  `}
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const MobileOpenContentHeader = styled.div`
  ${({ theme }) => `
    position: relative;
    height: 48px;
    box-shadow: ${theme.shadow.level0};
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 ${theme.spacing(2)};
  `}
`;

export const MobileOpenContentBody = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    .textfield-icon {
      right: 13px;
      justify-content: center;
      svg path {
        fill: ${theme.colors.neutrals.grey2};
        margin-left: ${theme.spacing(0.5)};
      }

    }
    input {
      margin-bottom: ${theme.spacing(5)};
      width: 100%;
      background: ${theme.colors.neutrals.grey10};
      padding-right: ${theme.spacing(5)};
      ::placeholder  {
        color: ${theme.colors.neutrals.grey4};
      }
      animation: widthGrow .3s linear;

      @keyframes widthGrow {
        0% {
          width: calc(100% - ${MAP_BUTTON_WIDTH}px);
        }
        100% {
          width: 100%;
        }
      }
    }
  `}
`;

export const MobileSelectedValuesWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    .${SELECTED_VALUE_CLASS} {
      max-width: 100%;
      flex-shrink: 0;
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
      margin-bottom: ${theme.spacing(0.5)};
      div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
  `}
`;

export const MobileSuggestionsWrapper = styled.div`
  ${({ theme }) => `
    .autocomplete-suggestion {
      &, &:last-of-type {
        margin: 0 -${theme.spacing(1)} ${theme.spacing(0.5)};
      }
      > div:first-of-type {
        top: 0;
      }
      width: auto;
      display: flex;
      align-items: center;
    }
  `}
`;

export const CloseIconWrapper = styled.div`
  ${({ theme }) => `
    ${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};
    position: absolute;
    top: calc(50% - 16px);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      pointer-events: none;
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
`;

export const MobileSubtitle = styled(SmallText)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    color: ${theme.colors.neutrals.grey3};
  `}
`;

export const MobileContentWrapper = styled.div<{ lastValueWidth: number; hasValues: boolean; }>`
  ${({ theme, lastValueWidth, hasValues }) => `
    position: relative;
    padding: 0 ${theme.spacing(5)};
    height: 40px;
    border: 1px solid ${theme.colors.neutrals.grey7};
    border-radius: ${theme.borderRadius.small};
    display: flex;
    align-items: center;
    * { pointer-events: none; }
    background-color: ${hasValues ? theme.colors.neutrals.white : theme.colors.neutrals.grey10};

    .${SELECTED_VALUE_CLASS}, .autcomplete-selected-value-limiter {
      max-width: 100%;
      flex-shrink: 0;
      transition: all 0s;
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
      div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      ${lastValueWidth ? `
        &[data-last-value="true"], &[data-last-value="true"] div {
          max-width: ${lastValueWidth}px;
        }
      ` : ''}
    }
    input {
      width: 100%;
      padding: 0;
      border: none !important;
      height: 28px;
      line-height: 28px;
      font-size: ${typographyFontSizeCfg.text}px;
      background-color: ${theme.colors.neutrals.grey10};
      ::placeholder  {
        color: ${theme.colors.neutrals.grey4};
      }
    }
  `}
`;

export const DesktopContentWrapper = styled.div<{ isActive?: boolean; multipleRows: boolean; lastValueWidth: number; }>`
  ${({ theme, isActive, multipleRows, lastValueWidth }) => `
    ${!isActive ? '* { pointer-events: none; }' : 'flex-wrap: wrap;'}
    position: relative;
    padding: 3px 36px ${multipleRows ? 0 : 3}px;
    border: 1px solid ${isActive ? theme.colors.brand.main : theme.colors.neutrals.grey7};
    transition: border-color .2s ease;
    border-radius: ${theme.borderRadius.small};
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    .${SELECTED_VALUE_CLASS}, .${SELECTED_VALUE_CLASS}-limiter {
      max-width: 100%;
      flex-shrink: 0;
      transition: all 0s;
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
      div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      ${multipleRows ? `margin-bottom: ${theme.spacing(.5)};` : ''}
      ${lastValueWidth && !isActive ? `
        &[data-last-value="true"], &[data-last-value="true"] div {
          max-width: ${lastValueWidth}px;
        }
      ` : ''}
    }
    input {
      width: 100%;
      padding: 0;
      border: none !important;
      height: 24px;
      line-height: 24px;
      font-size: ${typographyFontSizeCfg.smallText}px;
    }
    * {
      transition: all 0s;
    }
  `}
`;

export const DesktopSuggestionsWrapper = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.neutrals.white};
    border-radius: ${theme.borderRadius.small};
    z-index: 3;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: ${theme.spacing(2)} 0;
    margin-top: ${theme.spacing(0.5)};
    border: 1px solid ${theme.colors.neutrals.grey6};
    box-shadow: ${theme.shadow.level7};
    .autocomplete-suggestion {
      margin: 0 !important;
      height: auto;
      padding: ${theme.spacing(1)} ${theme.spacing(1.5)};
      svg {
        margin: 0;
      }
      div {
        &:first-of-type {
          top: 3px;
          ${theme.isRTL ? 'right' : 'left'}: -${theme.spacing(0.5)};
        }
        font-size: ${typographyFontSizeCfg.smallText}px;
      }
    }
  `}
`;

export const MobileIconWrapper = styled.span`
  position: absolute;
  right: 12px;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey2};
    }
  }
`;

export const DesktopIconWrapper = styled.span`
  position: absolute;
  ${({ theme }) => `
    ${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(1.5)};
    top: 7px;
    svg {
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    top: 10px;
  `}
`;

export const ExpandIconWrapper = styled.span<{ isActive: boolean }>`
  position: absolute;
  ${({ theme, isActive }) => `
    ${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
    top: ${isActive ? 5 : 6}px;
    svg {
      transform: scaleY(${isActive ? '-' : ''}1);
      path {
        fill: ${theme.colors.neutrals.grey2};
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    top: 9px;
  `}
`;

export const DesktopInputWrapper = styled.span<{ isActive: boolean; hasValues: boolean }>`
  ${({ isActive, hasValues }) => `
    opacity: ${isActive || !hasValues ? 1 : 0};
    max-width: ${hasValues ? (isActive ? '100px' : 0) : '100%'};
  `}
`;

export const StickyDoneButton = styled.div`
  height: 56px;
  position: fixed;
  z-index: 1002;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  ${({ theme }) => `
    background: ${theme.colors.neutrals.white};
    padding: 0 ${theme.spacing(3)};
    box-shadow: ${theme.shadow.level2};
  `}
`;
