import React, { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';


export const useResizeObserver = (
  callback: ResizeObserverCallback,
  targets: Array<(React.MutableRefObject<HTMLElement>)>
) => {
  const observer = useRef<ResizeObserver>(null);
  const callbackRef = useRef<ResizeObserverCallback>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [ callback ]);

  useEffect(() => {
    observer.current = new ResizeObserver((...args) => callbackRef.current(...args));

    return () => {
      observer.current.disconnect();
      // in order to assist GC
      observer.current = null;
    };
  }, []);
  useEffect(() => {

    targets.forEach((target) => {
      if (target.current) observer.current.observe(target.current);
    });
  }, [ targets ]);
};
