import React from 'react';
import { useLocale } from 'locale';
import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import { NoDataWidget } from '../common/NoDataWidget';

const Root = styled(SmallText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface NeighborsDynamicContentProps {
  text: string;
}

export const NeighborsDynamicContent: React.FC<NeighborsDynamicContentProps> = props => {
  const { text } = props;
  const { t } = useLocale();

  if (!text) {
    return <NoDataWidget>{t('widgetCollapsed.noData', { title: t('widgetTitle.neighbors') })}</NoDataWidget>;
  }

  return (
    <Root>{text}</Root>
  );
};
