import React from 'react';
import { MarketplaceType } from 'utils/entities';
import { marketplaceSelector } from 'store/state/selectors/router';
import { useSelector } from 'react-redux';

export interface WithMarketplaceProps {
  marketplace: MarketplaceType;
}

export function withMarketplace<T>(Comp: React.ComponentType<T>) {

  const outComp: React.FC<WithMarketplaceProps & T> = (props) => {
    const marketplace = useSelector(marketplaceSelector);

    return <Comp {...props} marketplace={marketplace}/>;
  };

  return outComp;
}
