import { getContext } from 'redux-saga/effects';
import { Store } from 'redux';
import { Router } from 'router5';
import { DoneFn } from 'router5/types/types/base';
import { State as RouteState } from 'config/routes';
import { transitionStart } from 'store/state/router/actions';


export function* useRouterMiddleware() {
  const router = yield getContext('router');

  router.useMiddleware((r: Router, dependencies: { store: Store }) => {
    const { store: { dispatch } } = dependencies;
    return (toState: RouteState, fromState: RouteState, done: DoneFn) => {
      dispatch(transitionStart({ route: toState, previousRoute: fromState, done }));
    };
  });
}
