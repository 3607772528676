import React from 'react';


export interface InstaPagerProps {
  total: number;
  current: number;
  left?: number;
  right?: number;
  update: (index: number) => void;
}


const SIZE = 10;


const wrapperStyle: React.CSSProperties = {
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-flex',
};

const childrenStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  transition: '.3s ease',
};


const childStyle: React.CSSProperties = {
  height: SIZE,
  lineHeight: SIZE,
  width: SIZE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const bulletStyle: React.CSSProperties = {

  opacity: .5,
  background: '#fff',
  height: 5,
  width: 5,
  transition: '.2s ease',
  display: 'block',
  borderRadius: '50%',
  transformOrigin: '50% 50%',
};

const bulletCurrentStyle: React.CSSProperties = {
  opacity: 1,
  transform: 'scale(1.1, 1.1)',
};
const bulletPreLastStyle = {
  transform: 'scale(.6, .6)',
};

const bulletLastHasMoreStyle = {
  transform: 'scale(.4, .4)',
};


export class InstaPager extends React.Component<InstaPagerProps, null> {

  public static defaultProps: Partial<InstaPagerProps> = {
    current: 0,
  };

  public getBulletStyle = (index: number) => {
    const {
      total, current,
    } = this.props;

    const { left, right } = this.props;
    const isInvisible = (index < left || index > right);
    if (isInvisible) return null;

    return {
      ...bulletStyle,

      ...(index === current ? bulletCurrentStyle : null),

      ...((index === left && left > 0) ? bulletLastHasMoreStyle : null),
      ...((index === right && total - 1 > right) ? bulletLastHasMoreStyle : null),

      ...((index + 1 === right && total - 1 > right) ? bulletPreLastStyle : null),
      ...((index - 1 === left && left > 0) ? bulletPreLastStyle : null),
    };
  }


  public render() {

    const { total, update, left, right, current } = this.props;

    const to = SIZE * this.props.left;

    const points: React.ReactNode[] = [];

    for (let index = 0; index < total; index++) {
      const style = this.getBulletStyle(index);

      if (style === null) {
        points.push(<div key={index} style={{ width: SIZE }}/>);
      }

      else {
        points.push(
          <div style={childStyle} key={index} onClick={() => update(index)}>
            <div data-auto={`bullet-pager-${index}`} style={style} className={`bulletPager ${index === current ? 'bulletCurrent' : ''}`} />
          </div>
        );
      }
    }

    return (
      <div style={{ ...wrapperStyle, width: `${SIZE * (right - left + 1)}px` }}>
        <div style={{ ...childrenStyle, transform: `translate3d(-${to}px, 0, 0)` }}>
          {points}
        </div>
      </div>
    );
  }
}
