import { createSelector } from 'reselect';
import { insightsByIdsSelector } from 'components/map/Insights/selectors/common';
import { flow } from 'lodash';
import { routeSelector, getRouteParams } from 'store/state/selectors/router';
import { addressDocEntrySelector } from './index';
import { feedbackPopupModalOpenInsightSelector } from 'store/state/app/selectors';
import { ISendFeedbackVariables } from 'store/sagas/apiService/types';
import { Route } from 'config/routes';
import { State } from 'store/state';

export const feedbackVariablesSelector = createSelector([
  insightsByIdsSelector,
  (_: State, id: string) => id,
  flow(routeSelector, (r) => r.name),
  flow(getRouteParams, (p) => p.id),
  addressDocEntrySelector,
  feedbackPopupModalOpenInsightSelector,
], (insightsByIds, id, routeName, routeId, address, insightId): ISendFeedbackVariables => {
  const insight = insightsByIds[insightId];

  // !!!commented fields are part of new API for feedback and report mutation
  const variables: ISendFeedbackVariables = {
    insightType:  insight ? insight.type : null,
    insightRecordId: insight ? insight.insightRecordId : null,
    message: null,
    bulletinId: null,
    // feedback: null,
    // email: null,
    address: null,
    sourceType: null,
  };
  switch (routeName) {
    case Route.AddressPage:
      variables.sourceType = 'address';
      variables.address = address ? address.name : null;
      break;
    case Route.ProjectPage:
      variables.sourceType = 'project';
      variables.bulletinId = routeId;
      break;
    case Route.LocalPage:
      variables.sourceType = 'local';
      break;
    case Route.UnitPage:
      variables.sourceType = 'bulletin';
      variables.bulletinId = routeId;
      break;
  }

  return variables;
});
