import React from 'react';
import Modal from 'ds/components/modal';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { H3, Text } from 'ds/components/typography';
import { SpacingBlock } from 'components/spacing-block';
import ErrorIcon from 'assets/svg/manage-bulletin-error.svg';
import { BasicButton } from 'ds/components/button/BasicButton';
import { AffiliationRequestResponseDealType, RequestDealAffiliationResponseType } from 'utils/entities';

const Root = styled.div`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.neutrals.grey1};
    padding: ${theme.spacing(1)};
    white-space: pre-line;
  `}
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => `
    width: 194px;
    margin: 0 auto;
  `}
`;

const CloseButton = styled(BasicButton)`
  ${({ theme }) => `
    &, &:hover {
      background-color: ${theme.colors.alert.red};
      color: ${theme.colors.neutrals.white};
      border: none;
    }
  `}
`;

interface ErrorPopupProps {
  responseType: RequestDealAffiliationResponseType | AffiliationRequestResponseDealType;
  onButtonClick: () => void;
  onClose: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ responseType, onButtonClick, onClose  }) => {
  const { t } = useLocale();

  return (
    <Modal isOpen desktopModalPositionCenter onClose={onClose}>
      <Root>
        <ErrorIcon />
        <SpacingBlock mTop={3}>
          <H3 weight="bold" data-auto="popup-header">{t('dealAffiliationPopup.error.header')}</H3>
        </SpacingBlock>
        <SpacingBlock mTop={0.5} mBottom={5}>
          <Text data-auto="popup-subheader">
            {t('dealAffiliationPopup.error.subheader', { responseType })}
          </Text>
        </SpacingBlock>
        <ButtonWrapper>
          <CloseButton
            onClick={onButtonClick}
            fullWidth
            size="large"
            data-auto="popup-button"
          >
            {t('dealAffiliationPopup.error.button', { responseType })}
          </CloseButton>
        </ButtonWrapper>
      </Root>
    </Modal>
  );
};

export default ErrorPopup;

