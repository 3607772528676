import IntlMessageFormat from 'intl-messageformat';
import React, { useCallback, useContext } from 'react';
import { Subtract } from 'utils/type-utils';
import { ISOString, parseISODate } from './formatDate';
import formatMetric from './formatMetric';
import hewbrewTranslations from './he-IL.json';
import { memoize } from 'lodash';
import config from 'config';

export type HebrewKeys = keyof typeof hewbrewTranslations;


export type INumberToMetricStringFormatter = (amount: number, digits?: number, enforcedSiValue?: number) => string;
export type INumberToStringFormatter = (amount: number, withCurrency?: boolean) => string;
type INumberToFixedFormatter = (amount: number, digits?: number) => string;
export type DateFormatter = (value: Date | ISOString | number, customFormat?: Intl.DateTimeFormatOptions) => string;
type TranslateParams = Record<string, string | boolean | number>;
type TranslateIconParams = Record<string, React.ReactElement>;
export type ITranslate = (term: HebrewKeys, params?: TranslateParams) => string;
export type ITranslateWithIcons = (term: HebrewKeys, iconParams?: TranslateIconParams, params?: TranslateParams) => string | React.ReactNode[];

export interface WithLocaleProps {
  t: ITranslate;
  tWithIcons: ITranslateWithIcons;
  locale: ILocale;
  formatMoney: INumberToStringFormatter;
  formatArea: INumberToStringFormatter;
  formatMoneyWithMetricPrefix: INumberToMetricStringFormatter;
  formatDate: DateFormatter;
  formatNumber: INumberToStringFormatter;
  formatDistance: INumberToFixedFormatter;
}

export type ILocale = 'he-IL';
enum AriaUnit {
  IL = 'מ"ר',
}

enum DistanceUnit {
  IL = 'ק"מ',
}

const getIconPlaceholderTemplate = (index: string | number) => `%$${index}%$`;

export const LocaleContext = React.createContext<{ locale: ILocale }>({ locale: config.locale });

const localeConfig = {
  'he-IL': {
    translations: hewbrewTranslations,
    currency: 'ILS',
    areaUnit: AriaUnit.IL,
    distanceUnit: DistanceUnit.IL,
  },
};

class TranslationsMap {
  constructor(private locale: string) {}
  private map = new Map<string, IntlMessageFormat>();

  public get(key: string) {
    if (!this.map.has(key)) {
      this.map.set(
        key,
        new IntlMessageFormat(localeConfig[this.locale].translations[key], this.locale)
      );
    }
    return this.map.get(key);
  }
}
const getIntlObjects = memoize((locale: ILocale) => (
  new TranslationsMap(locale)
));

const KM_IN_MILES = 1.609344;

const PLACEHOLDER_REG = /(\%\$\d+\%\$)/;

export const useLocale = (): WithLocaleProps  => {
  const { locale } = useContext(LocaleContext);

  const intlObjs = getIntlObjects(locale);

  const t: ITranslate = useCallback((term, params) => {
    const message = intlObjs.get(term);
    if (!message) {
      throw new Error(`Someone forgot to add translation key "${term}" to ${locale} locale`);
    }
    try {
      return message.format(params);
    }
    catch (e) {
      // tslint:disable-next-line: no-console
      console.error('Message: ', localeConfig[locale].translations[term], 'Params:', params);
      throw new Error(`Cannot format locale string "${term}" in ${locale} locale.
      The crash is probably because one of variables is of unexpected value.
      `);
    }
  }, [ locale, intlObjs ]);

  const tWithIcons = useCallback((term, iconParams = {}, params = {}) => {
    const iconKeys = Object.keys(iconParams);
    const placeholderParams = {};
    if (iconKeys.length) {
      const iconMap = iconKeys.reduce((acc, key, i) => {
        const placeholder = getIconPlaceholderTemplate(i);
        placeholderParams[key] = placeholder;
        acc[placeholder] = iconParams[key];
        return acc;
      }, {});
      const formatted = t(term, { ...params, ...placeholderParams });
      return formatted.split(PLACEHOLDER_REG).map((i, index) => {
        if (iconMap[i]) return React.cloneElement(iconMap[i], { key: index });
        return i;
      });
    }
    return t(term, params);
  }, [ t ]);

  const formatDate = useCallback<DateFormatter>((date, customFormat) => {
    let value: Date = null;
    if (typeof date === 'string') value = parseISODate(date);
    // @TODO: drop support for timestamps once BE starts to send only ISOStrings
    else if (typeof date === 'number') value = new Date(date);
    else value = date;

    const message = new IntlMessageFormat('{value, date, customFormat}', locale, {
      date: {
        customFormat: {
          ...customFormat,
          timeZone: 'UTC',
        },
      },
    });
    return message.format({ value });
  }, [ locale ]);

  const formatNumber = useCallback((value) => {
    const message = new IntlMessageFormat('{value, number}', locale);
    return message.format({ value });
  }, [ locale ]);

  const formatMoney: INumberToStringFormatter = useCallback((amount, withCurrency = true) => {
    const message = new IntlMessageFormat('{amount, number, money}', locale, {
      number: {
        money: {
          ...(withCurrency ? {
            style: 'currency',
            currency: localeConfig[locale].currency,
          } : {}),
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      },
    });
    return message.format({ amount });
  }, [ locale ]);

  const formatArea: INumberToStringFormatter = useCallback((amount, withCurrency = true) => {
    const message = new IntlMessageFormat(`{area, number, squareUnits}${withCurrency ? ` {unitType}` : ''}`, locale, {
      number: {
        squareUnits: {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      },
    });
    const unitType = localeConfig[locale].areaUnit;
    return message.format({ area: amount, unitType });
  }, [ locale ]);

  const formatDistance = useCallback((amount: number, digits = 0) => {
    const message = new IntlMessageFormat('{distance}{unitType}', locale);
    const distance = (locale === 'he-IL' ? amount : amount / KM_IN_MILES).toFixed(digits);
    const unitType = localeConfig[locale].distanceUnit;
    return message.format({ distance, unitType });
  }, [ locale ]);

  const formatMoneyWithMetricPrefix = useCallback<INumberToMetricStringFormatter>((amount, digits: number = 2, enforcedSiValue?: number) => {
    const formattedAmount = formatMetric(amount, digits, enforcedSiValue, localeConfig[locale].currency === 'ILS');
    if (localeConfig[locale].currency === 'USD') {
      return `$${formattedAmount}`;
    }

    return `${formattedAmount} ₪`;
  }, [ locale ]);

  return {
    t,
    tWithIcons,
    formatArea,
    formatMoney,
    formatNumber,
    formatMoneyWithMetricPrefix,
    locale,
    formatDate,
    formatDistance,
  };
};

export const withLocale = <P extends Partial<WithLocaleProps>>(Component: React.ComponentType<P>) => {

  const Wrapped: React.ComponentType<Subtract<P, WithLocaleProps>> = (props) => {
    const localeProps = useLocale();

    const childProps = { ...props, ...localeProps } as P;

    return (
      <Component {...childProps}/>
    );
  };

  return Wrapped;
};
