import React from 'react';
import { NextStepBtn, StepsNavigation } from './styled';
import { useLocale } from 'locale';
import { Button, CleanButton } from 'ds/components/button';


interface NavigationProps {
  onForward: () => void;
  onBackward: () => void;
  onComplete: () => void;
  isBackStep: boolean;
  isLastStep: boolean;
  isSkipStep: boolean;
}

export const Navigation: React.FC<NavigationProps> = React.memo(props => {
  const {
    onForward,
    onBackward,
    onComplete,
    isBackStep,
    isLastStep,
    isSkipStep,
  } = props;
  const { t } = useLocale();

  const handleStepForward = () => {
    if (isLastStep) {
      onComplete();
    }
    else {
      onForward();
    }
  };

  return (
    <StepsNavigation>
      {isBackStep ? (
        <CleanButton data-auto="back-button" onClick={onBackward}>
          {t('ugc.modal.button.back')}
        </CleanButton>
      ) : null}
      <NextStepBtn>
        {isLastStep ? (
          <Button data-auto="done-button" onClick={handleStepForward}>
            {t('ugc.modal.button.done')}
          </Button>
        ) : (
          <CleanButton data-auto="next-skip-button" onClick={handleStepForward}>
            {t(isSkipStep ? 'ugc.modal.button.skip' : 'ugc.modal.button.next')}
          </CleanButton>
        )}
      </NextStepBtn>
    </StepsNavigation>
  );
});
