import React from 'react';
import { ThumbnailsWrapperInner, HeightAwareSliderItemContainer, rangeToGalleryHeight } from './styled';
import { IThumbnail } from 'utils/entities';
import { ViewPager } from 'components/view-pager';
import { shouldLazilyLoad } from 'utils/gallery';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useScreenBreakpoint } from 'consts/breakpoints';


interface ImageGalleryProps {
  images: IThumbnail[];
  activeSlide: number;
  handleSlideIndexChange(idx: number): void;
  openFullscreen(isMobile: boolean): () => void;
}

const LAZY_LOAD_MARGIN = 3;
const IMAGE_PIXEL_DENSITY = 2;

interface SliderItemProps {
  url: string;
  shouldLoad: boolean;
  isFloorPlan: boolean;
  rotateDegrees: number;
}

const SliderItem: React.FC<SliderItemProps> = ({
  url,
  shouldLoad,
  isFloorPlan,
  rotateDegrees,
}) => {
  const breakpointValue = useScreenBreakpoint();
  const constructImageURI = useImageUrlBuilder();
  const uri = constructImageURI(url, {
    height: rangeToGalleryHeight[breakpointValue] * IMAGE_PIXEL_DENSITY,
    rotateDegrees,
  });

  return (
    <HeightAwareSliderItemContainer isFloorPlan={isFloorPlan}>
      <div
        data-auto="bulletin-image"
        data-auto-url={uri}
        style={{
          backgroundImage: shouldLoad ? `url("${uri}")` : null,
        }}
      />
    </HeightAwareSliderItemContainer>
  );
};


export const ImageGallery: React.FunctionComponent<ImageGalleryProps> = (props) => {
  const {
    images,
    activeSlide,
    handleSlideIndexChange,
    openFullscreen,
  } = props;

  return (
    <ThumbnailsWrapperInner onClick={openFullscreen(false)}>
      <ViewPager
        align="center"
        slideIndex={activeSlide}
        onSlideIndexChange={handleSlideIndexChange}
      >
        {images.map(({ url, isFloorPlan, rotateDegrees }, i) => {

          const shouldLoad = shouldLazilyLoad(activeSlide, i, images.length, LAZY_LOAD_MARGIN);

          return (
            <SliderItem
              key={`${url}-${i}`}
              url={url}
              isFloorPlan={isFloorPlan}
              shouldLoad={shouldLoad}
              rotateDegrees={rotateDegrees}
            />
          );
        })}
      </ViewPager>
    </ThumbnailsWrapperInner>
  );
};
