import React from 'react';
import Elevator from 'assets/svg/elevator.svg';
import NewElevator from 'assets/svg/new-elevator.svg';
import Parking from 'assets/svg/parking.svg';
import NewParking from 'assets/svg/new-parking.svg';
import AirConditioner from 'assets/svg/air-condition.svg';
import Grating from 'assets/svg/filters/project-16-bars.svg';
import Safety from 'assets/svg/filters/project-16-saftey-unit.svg';
import NewSafety from 'assets/svg/safety.svg';
import Balcony from 'assets/svg/balcony.svg';
import NewBalcony from 'assets/svg/new-balcony.svg';
import Wheel from 'assets/svg/wheelchair-il.svg';
import Storage from 'assets/svg/filters/project-16-warehouse.svg';
import NewStorage from 'assets/svg/storage.svg';
import Pandoor from 'assets/svg/filters/door.svg';
import People from 'assets/svg/new-people.svg';
import Paint from 'assets/svg/paint.svg';
import NewDoorman from 'assets/svg/new-doorman.svg';
import CheckCircle from 'assets/svg/new-check-circle.svg';
import SubDivisible from 'assets/svg/squares-four.svg';
import Garden from 'assets/svg/garden.svg';
import { Amenity } from 'utils/entities';
import { ExtraAmenityType } from './amenities-chips';


export const unitAmenities: Amenity[] = [
  Amenity.AirConditioner,
  Amenity.UnitLaundry,
  Amenity.Furnished,
  Amenity.Balcony,
  Amenity.Fireplace,
  Amenity.Patio,
  Amenity.Dishwasher,
  Amenity.JulietBalcony,
  Amenity.CeilingFan,
  Amenity.OutdoorSpace,
  Amenity.HeatingSystem,
];

export const buildingAmenities: Amenity[] = [
  Amenity.Elevator,
  Amenity.BuildingLaundry,
  Amenity.Parking,
  Amenity.Storage,
  Amenity.Heating,
  Amenity.Doorman,
  Amenity.Gym,
  Amenity.Pool,
  Amenity.SecureRoom,
  Amenity.PandoorDoors,
  Amenity.Grating,
  Amenity.Accessible,
  Amenity.Garden,
];

export const checkListAmenities: Amenity[] = [
  Amenity.Parking,
  Amenity.SecureRoom,
  Amenity.Elevator,
  Amenity.Storage,
  Amenity.AirConditioner,
  Amenity.Accessible,
  Amenity.Grating,
  Amenity.PandoorDoors,
  Amenity.Balcony,
];

export const featuredAmenities: Array<{
  icon: React.ReactNode,
  key: Amenity,
}> = [
  {
    icon: <Elevator />,
    key: Amenity.Elevator,
  },
  {
    icon: <AirConditioner />,
    key: Amenity.AirConditioner,
  },
  {
    icon: <Parking />,
    key: Amenity.Parking,
  },
  {
    icon: <Balcony />,
    key: Amenity.Balcony,
  },
  {
    icon: <Garden />,
    key: Amenity.Garden,
  },
  {
    icon: <Grating />,
    key: Amenity.Grating,
  },
  {
    icon: <Safety />,
    key: Amenity.SecureRoom,
  },
  {
    icon: <Storage />,
    key: Amenity.Storage,
  },
  {
    icon: <Wheel />,
    key: Amenity.Accessible,
  },
  {
    icon: <Pandoor />,
    key: Amenity.PandoorDoors,
  },
];

export const commercialAmenities: Set<string> = new Set([
  Amenity.SecureRoom,
  Amenity.Doorman,
  Amenity.Storage,
  Amenity.SubDivisible,
]);

export const amenityToIconMap = {
  [Amenity.Parking]: <NewParking />,
  [Amenity.SecureRoom]: <NewSafety />,
  [Amenity.Balcony]: <NewBalcony />,
  [Amenity.Elevator]: <NewElevator />,
  [Amenity.Storage]: <NewStorage />,
  [Amenity.Doorman]: <NewDoorman />,
  [Amenity.SubDivisible]: <SubDivisible />,
};

export const extraAmenityToIconMap = {
  [ExtraAmenityType.People]: <People />,
  [ExtraAmenityType.GeneralCondition]: <Paint />,
  [ExtraAmenityType.QualityClass]: <CheckCircle />,
};
