import styled from '@emotion/styled';
import { H3, SmallText, Text } from 'ds/components/typography';
import { LinkStyled } from 'ds/components/link/styled';
import { BaseRoot } from '../styled';


export const Root = styled(BaseRoot)``;

export const FormContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3)};
  `}
`;

export const FieldWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    .textfield-error-msg {
      position: absolute;
    }
  `}
`;

export const MobileSubHeaderContainer = styled.div`
  text-align: center;
  > div {
    display: inline;
  }
`;

export const Header = styled(H3)`
  ${({ theme }) => `
    text-align: center;
    margin-bottom: ${theme.spacing(3)};
  `}
`;

export const Footer = styled(SmallText)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(4)};
    text-align: center;
  `}
`;

export const FooterLink = styled(LinkStyled)`
  display: inline;
`;

export const SimpleText = styled(Text)`
  text-align: center;
`;
