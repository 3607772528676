import React, { useRef, useState, useContext, useEffect, useMemo } from 'react';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { isNumber } from 'lodash';


type TargetID = string | number;
interface ScrollSpyTargetProps extends React.HTMLProps<HTMLDivElement> {
  targetId: TargetID;
  maxAspectRatio?: number; // from 0 to 1 can be float
}
const Context = React.createContext<IContextValue>(null);

const THRESHOLDS_NUM = 8;
const THRESHOLDS = Array(THRESHOLDS_NUM + 1).fill(null).map((_, i) => i / THRESHOLDS_NUM);

const MAGIC_MARGINS = '-128px 0px 0px 0px';

function validateAspectRatio(aspectRatio: number) {
  if (isNumber(aspectRatio)) {
    if (aspectRatio < 0 || aspectRatio > 1) {
      // tslint:disable-next-line: no-console
      console.warn('maxAspectRatio must be a number between 0 and 1');
    }
  }
  return aspectRatio;
}

export const ScrollSpyTarget: React.FC<ScrollSpyTargetProps> = React.memo((props) => {
  const { targetId, maxAspectRatio, children, ...divProps } = props;
  // if this assertion breaks, fix props destructuring on previous line
  const assertedDivProps: React.HTMLProps<HTMLDivElement> = divProps;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { addTarget, removeTarget } = useContext(Context);

  useEffect(() => {
    addTarget({ targetId: props.targetId, element: wrapperRef });
    return () => {
      removeTarget(props.targetId);
    };
  }, [ wrapperRef.current ]);

  return (
    <div {...assertedDivProps} ref={wrapperRef} data-auto={targetId + '-block'} data-max-aspect-ratio={validateAspectRatio(maxAspectRatio)} data-scroll-spy-id={targetId}>
      {children}
    </div>
  );
});



export interface ScrollSpyProviderProps {
  onTargetsChange?: (keys: Set<string>) => void;
  onChange: (sectionId: TargetID, intersectionRatio?: number) => void;
  root?: React.MutableRefObject<HTMLElement>;
  rootMargin?: string;
}

interface ITarget {
  targetId: TargetID;
  element: React.MutableRefObject<HTMLElement>;
}

interface IContextValue {
  addTarget: (target: ITarget) => void;
  removeTarget: (targetId: TargetID) => void;
}

const getMaxKey = (ratios: Map<string, number>) => {
  const keys = ratios.keys();

  let maxKey: string = null;

  for (const key of keys) {
    const value = ratios.get(key);
    const lastMaxValue = maxKey ? ratios.get(maxKey) : -Infinity;
    if (value > lastMaxValue) maxKey = key;
  }

  // handle case when all ratios are 0
  if (ratios.get(maxKey) === 0) return null;

  return maxKey;
};

type TargetsMap = Map<string, React.MutableRefObject<HTMLElement>>;

export const ScrollSpyProvider: React.FC<ScrollSpyProviderProps> = React.memo((props) => {

  const [ targets, setTargets ] = useState<TargetsMap>(new Map());
  const ratios = useRef<Map<string, number>>(new Map());

  useEffect(() => {
    if (props.onTargetsChange) props.onTargetsChange(new Set(targets.keys()));
  }, [ targets ]);

  useIntersectionObserver((entries) => {
    const lastMaxKey = getMaxKey(ratios.current);

    entries.forEach((e) => {
      const target = e.target as HTMLElement;
      const maxAspectRatio = target.dataset.maxAspectRatio ? parseFloat(target.dataset.maxAspectRatio) : null;
      ratios.current.set(target.dataset.scrollSpyId.toString(), maxAspectRatio ? Math.min(e.intersectionRatio, maxAspectRatio) : e.intersectionRatio);
    });

    const nextMaxKey = getMaxKey(ratios.current);

    if (lastMaxKey !== nextMaxKey) props.onChange(nextMaxKey, ratios.current.get(nextMaxKey));

  }, [ ...targets.values() ].filter(Boolean), { threshold: THRESHOLDS, rootMargin: props.rootMargin, root: props.root });

  const providerValue = useMemo((): IContextValue => ({
    addTarget: ({ targetId, element }) => {
      setTargets((prevTargets) => {
        const nextTargets = new Map(prevTargets.entries());
        nextTargets.set(targetId.toString(), element);
        return nextTargets;
      });
    },
    removeTarget: (targetId) => {
      setTargets((prevTargets) => {
        const nextTargets = new Map(prevTargets.entries());
        nextTargets.delete(targetId.toString());
        return nextTargets;
      });

    },
  }), [ setTargets ]);

  return (
    <Context.Provider value={providerValue}>
      {props.children}
    </Context.Provider>
  );
});

ScrollSpyProvider.defaultProps = {
  rootMargin: MAGIC_MARGINS,
};
