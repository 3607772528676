import React from 'react';
import { Link } from 'ds/components/link';

export const getDeveloperLink = (id: string) => id ? `/developer/${id}` : null;

export const getAgencyLink = (id: string) => id ? `/agentsOffice/${id}` : null;

export const getAgentLink = (id: string) => id ? `/agent/${id}` : null;

interface CheckedLinkProps {
  link?: string;
  isPreventEvent?: boolean;
}

export const CheckedLink: React.FC<CheckedLinkProps> = ({ children, link, isPreventEvent }) => link ? (
  <a href={link} target="_blank" onClick={(e) => isPreventEvent ? e.stopPropagation() : null}>
    {children}
  </a>
) : <>{children}</>;

export const CheckedLinkText: React.FC<CheckedLinkProps> = ({ children, link }) => link ? (
  <Link href={link} target="_blank">
    {children}
  </Link>
) : <>{children}</>;
