import React from 'react';
import { useLocale } from 'locale';
import styled from '@emotion/styled';
import { TextLabel } from 'ds/components/typography';
import defaultTheme from 'ds/theme/default';
import { colorToAlpha } from 'helpers/insight';


export enum TagType {
  New = 'new',
  UpdatedToday = 'updatedToday',
  Project = 'project',
  Discount = 'discount',
  PriceDrop = 'priceDrop',
  Commercial = 'commercial',
}

const typeToFontColor: Record<TagType, string> = {
  [TagType.New]: defaultTheme.colors.neutrals.grey1,
  [TagType.UpdatedToday]: defaultTheme.colors.neutrals.grey1,
  [TagType.Project]: defaultTheme.colors.neutrals.white,
  [TagType.Discount]: defaultTheme.colors.neutrals.white,
  [TagType.PriceDrop]: defaultTheme.colors.neutrals.white,
  [TagType.Commercial]: defaultTheme.colors.brand.madlanGreen,
};

const typeToBgColor: Record<TagType, string> = {
  [TagType.New]: defaultTheme.colors.label.yellow,
  [TagType.UpdatedToday]: defaultTheme.colors.label.yellow,
  [TagType.Project]: colorToAlpha(defaultTheme.colors.neutrals.grey1, 0.9),
  [TagType.Discount]: defaultTheme.colors.label.gold,
  [TagType.PriceDrop]: defaultTheme.colors.label.violet,
  [TagType.Commercial]: colorToAlpha(defaultTheme.colors.brand.madlanGreen, 0.07),
};

const TagRoot = styled(TextLabel)<{ type: TagType; }>`
  ${({ theme, type }) => `
    display: flex;
    padding: ${theme.spacing(0.5)};
    border-radius: ${theme.borderRadius.tiny};
    line-height: 15px;
    color: ${typeToFontColor[type]};
    background-color: ${typeToBgColor[type]};
    letter-spacing: 0.2px;
    white-space: nowrap;
  `}
`;


TagRoot.defaultProps = { small: true, weight: 'bold' };

const Tag: React.FC<{ type: TagType; label?: string; }> = ({ type, label }) => {
  const { t } = useLocale();
  let text = '';
  switch (type) {
    case TagType.New:
      text = t('card.tag.new');
      break;
    case TagType.UpdatedToday:
      text = t('card.tag.updatedToday');
      break;
    case TagType.Project:
      text = t('card.tag.project');
      break;
    case TagType.Discount:
      text = t('card.tag.discount');
      break;
    case TagType.PriceDrop:
      text = t('card.tag.priceDrop');
      break;
  }
  return (
    <TagRoot type={type} data-auto={`tag-${type}`}>
      {label || text}
    </TagRoot>
  );
};

export default Tag;
