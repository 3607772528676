import React from 'react';
import config from 'config';
import { connect } from 'react-redux';
import { daysFrom } from 'helpers/dateUtils';
import { ISOString, parseISODate } from 'locale/formatDate';
import { useLocale } from 'locale';
import { DealType, PoiId } from 'utils/entities';
import { State } from 'store/state';
import { RelevancyLabelWrapper } from 'ds/components/ListingCard';
import { createSelector } from 'reselect';
import { lastViewedPoiIdsSet } from 'store/state/domainData/selectors';
import { useABTests } from 'hooks/useABTests';
import { AddLastUpdateToWeights } from 'config/abTests';

export enum UnitRelevancy {
  New = 'new',
  Relevant = 'relevant',
  Old = 'old',
}

export const getRelevancy = (createdDate: ISOString, isInLastViewed: boolean, isBulletinType: boolean, dealType: DealType = DealType.Buy) => {
  // @TODO: check product requirements on relevancy if date is null
  if (!createdDate) return UnitRelevancy.Old;
  const daysInMarket = config.daysInMarket[dealType];
  const relevancyDays = isBulletinType ? daysInMarket.bulletinsAsRelevantInDays : daysInMarket.projectsAsRelevantInDays;
  const newDays = isBulletinType ? daysInMarket.bulletinsAsNewInDays : daysInMarket.projectsAsNewInDays;
  const passedDays: number = daysFrom(parseISODate(createdDate));
  let unitRelevancy: UnitRelevancy = passedDays > relevancyDays
    ? UnitRelevancy.Old
    : passedDays > newDays
      ? UnitRelevancy.Relevant
      : UnitRelevancy.New;

  if (isInLastViewed && unitRelevancy === UnitRelevancy.New) {
    unitRelevancy = UnitRelevancy.Relevant;
  }

  return unitRelevancy;
};

interface RelevancyLabelOwnProps {
  createdDate: ISOString;
  lastUpdatedDate?: ISOString;
  isBulletinType: boolean;
  id: PoiId;
  dealType?: DealType;
  customRenderer?: (props: { text: string }) => JSX.Element;
}
interface RelevancyLabelStateProps {
  isInLastViewed: boolean;
  dealType: DealType;
}

const RelevancyLabel: React.FC<RelevancyLabelOwnProps & RelevancyLabelStateProps> = (props) => {
  const { createdDate, lastUpdatedDate, isInLastViewed, dealType, isBulletinType, customRenderer } = props;

  const daysInMarket = config.daysInMarket[dealType];
  if (!daysInMarket || !isBulletinType) return null;

  const { t } = useLocale();
  const relevancyDays = isBulletinType
    ? daysInMarket.bulletinsAsRelevantInDays
    : daysInMarket.projectsAsRelevantInDays;
  const passedDays = createdDate ? daysFrom(parseISODate(createdDate)) : null;
  const relevancy = getRelevancy(createdDate, isInLastViewed, isBulletinType, dealType);
  const { _be_addLastUpdateToWeights } = useABTests();
  const isModeB = _be_addLastUpdateToWeights === AddLastUpdateToWeights.modeB;
  const isUpdated = new Date(lastUpdatedDate).getTime() !== new Date(createdDate).getTime();
  const passedUpdateDays = lastUpdatedDate ? daysFrom(new Date(lastUpdatedDate)) : null;

  let text = null;
  if (!isModeB) {
    text = t('unitPage.dateLabel', { passedDays, relevancy, relevancyDays });
  }
  else if (isModeB && (
    (isUpdated && passedUpdateDays === 0) || relevancy === UnitRelevancy.New || (!isUpdated && passedDays === 0)
  )) {
    text = t('unitPage.dateLabel.updated', { relevancy });
  }

  if (!text) return null;

  return customRenderer ? customRenderer({ text }) : (
    <RelevancyLabelWrapper
      small
      caps
      data-auto="date-label"
      isDark
      isNewOffer={relevancy === UnitRelevancy.New}
    >
      {text}
    </RelevancyLabelWrapper>
  );
};

const isInLastViewedSelector = createSelector([
  lastViewedPoiIdsSet,
  (_: State, id: PoiId) => id,
], (set, id) => set.has(id));

const mapStateToProps = (state: State, props: RelevancyLabelOwnProps) => ({
  isInLastViewed: isInLastViewedSelector(state, props.id),
});

export default connect(mapStateToProps)(RelevancyLabel);
