import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import HebrewContactModalMobile from 'components/contact-modal/HebrewContactModalMobile';
import HebrewContactModalMobileModeB from 'components/contact-modal/HebrewContactModalMobileModeB';
import {
  contactFormInitialValuesSelector,
  createValidationFn,
  mapDispatchToProps,
  checkEnabledLeadingAgentFeature,
} from 'helpers/contact';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { lastContactedByPoiIdSelector, poiByPoiIdSelector } from './selectors';
import { useLocale } from 'locale';
import { IFormFields } from 'store/sagas/apiService/types';
import { ContactAgentSubmitterSource, SubmitContactAgentPayload } from 'store/state/app/payloads';
import {
  getAddress,
  renderAddress,
  UnitCardPrimaryParams,
  agentContactFormTranslateKeys,
  getShortlistContactFormParams,
} from 'utils/addressFormatter';
import { useForm } from 'react-final-form-hooks';
import { IBulletin, ICommercialBulletin, ICommercialProject, IProject, PoiId, PromotionValues, LeadingAgentFeature, Award } from 'utils/entities';
import { IContactAgentValues } from 'helpers/contactFormStore';
import { ContactModalOpenMeta } from 'store/state/app';
import { capitalize } from 'utils';
import MortgageModeA from 'components/inner-contact-form/mortgage-form';
import MortgageModeB from 'components/inner-contact-form/mortgage-form/modeB';
import Modal from 'ds/components/modal';
import { isProjectPoiType } from 'utils/marketplaceRoutes';
import { useCheckProjectPage } from 'hooks/useCheckProjectPage';
import { unitPageLeadingAgentSelector } from 'store/state/domainData/selectors/unit-page-leading-data';
import { useCheckUnitPage, useCheckCommercialUnitPage } from 'hooks/useCheckUnitPage';


interface LocalizedContactModalProps {
  onSetContactModalPoiId: (type: PoiId, meta: ContactModalOpenMeta) => void;
  contactFormInitialValues: IContactAgentValues;
  onContactPageSubmit: (payload: SubmitContactAgentPayload) => void;
  onCallClick?: (values?: Partial<IFormFields>) => void;
  poi: IBulletin | IProject | ICommercialProject | ICommercialBulletin;
  leadingData: Award;
}

const LocalizedContactModal: React.FC<LocalizedContactModalProps> = props => {
  const {
    poi,
    onSetContactModalPoiId,
    contactFormInitialValues,
    onContactPageSubmit,
    onCallClick,
    leadingData,
  } = props;

  const { id, type, addressDetails } = poi;
  const { t } = useLocale();
  const designRange = useScreenBreakpoint();
  const isDesktopLayout = useCheckProjectPage() || useCheckUnitPage() || useCheckCommercialUnitPage();
  const showContactFormAgentLabel = checkEnabledLeadingAgentFeature(leadingData, LeadingAgentFeature.ContactForm);

  const isDesktop = designRange >= 3 || isDesktopLayout;

  const Mortgage = isDesktop ? MortgageModeB : MortgageModeA;
  const ContactModalMobile = isDesktop ? HebrewContactModalMobileModeB : HebrewContactModalMobile;

  const initialValues: Partial<IFormFields> = {
    ...contactFormInitialValues,
    message: t('contactAgentModal.labels.messagePlaceholder', {
      address: capitalize(getAddress<UnitCardPrimaryParams>(t, renderAddress)(
        addressDetails,
        agentContactFormTranslateKeys,
        addressDetails.resolutionPreferences,
        getShortlistContactFormParams
      )),
    }),
  };


  const onSubmit = useCallback((formValues: IFormFields) => {
    onContactPageSubmit({
      key: id,
      variables: { ...formValues, id, type },
      source: isDesktop ? ContactAgentSubmitterSource.Form : ContactAgentSubmitterSource.Modal,
    });
  }, [ id, type, isDesktop ]);

  const validate = useCallback(createValidationFn(t), [ t ]);

  const { form, handleSubmit } = useForm({ onSubmit, validate, initialValues });

  const closeModal = () => {
    onSetContactModalPoiId(null, { userInitiated: true });
  };

  const hideForm = () => {
    form.reset();
    closeModal();
  };

  const hidePhoneNumber = isProjectPoiType(poi) && (!poi.promotionStatus || poi.promotionStatus.status !== PromotionValues.Promoted);

  return (
    <ShowAt from={2}>
      {matches => matches || isDesktopLayout ? (
        <Modal
          isOpen
          onClose={closeModal}
          mobileModalPositionBottom={isDesktopLayout}
        >
          <Mortgage
            isModal
            isLeadingAgent={showContactFormAgentLabel}
            poi={poi}
            addressDetails={addressDetails}
            hidePhoneNumber={hidePhoneNumber}
          />
        </Modal>
      ) : (
        <ContactModalMobile
          poi={poi}
          isLeadingAgent={showContactFormAgentLabel}
          onClose={hideForm}
          formApi={form}
          handleSubmit={handleSubmit}
          onPhoneClick={onCallClick}
        />
      )}
    </ShowAt>
  );
};

const mapStateToProps = (state: State) => ({
  contactFormInitialValues: contactFormInitialValuesSelector(state),
  lastContacted: lastContactedByPoiIdSelector(state),
  poi: poiByPoiIdSelector(state),
  leadingData: unitPageLeadingAgentSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizedContactModal);
