import React from 'react';
import { WebAuthSocialsButtons, WebAuthSocialsButtonsProps } from './web-social-buttons';
import mobileConfig, { Platform } from 'react-native/config';
import { insideReactNative } from 'react-native/lib/platform';
import SocialsButtons from 'react-native/components/social-buttons';

// tslint:disable-next-line: no-empty-interface
interface AuthSocialsButtonsProps extends WebAuthSocialsButtonsProps {}

const platformSocialBtnsMap: Partial<Record<Platform, JSX.Element>> = {
  android: <SocialsButtons />,
  ios: <div />,
};

const AuthSocialsButtons: React.FC<AuthSocialsButtonsProps> = (props) => {
  const platform = mobileConfig.platform;
  const insideRN = insideReactNative();
  const {
    modalLaterallPaddingMobile,
    withSuggestionBefore,
    onSocialLogin,
  } = props;

  return insideRN ? platformSocialBtnsMap[platform] : (
    <WebAuthSocialsButtons
      modalLaterallPaddingMobile={modalLaterallPaddingMobile}
      withSuggestionBefore={withSuggestionBefore}
      onSocialLogin={onSocialLogin}
    />
  );
};

export default AuthSocialsButtons;
