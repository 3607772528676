import React, { useEffect } from 'react';
import { RootAction } from 'store/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Tooltip from 'ds/components/tooltip';
import { useLocale } from 'locale';
import { ToastContainer, ToastContent, TooltipLink, TooltipText } from './styled';
import { Route } from 'config/routes';
import { setTimeout } from 'timers';
import { setIsLikedToastOpen } from 'store/state/app/actions';
import { useScreenBreakpoint } from 'consts/breakpoints';
import HeartIcon from 'assets/svg/heart-extra.svg';
import { ClickAwayListener } from 'components/authentication/ClickAwayListener';
import withEvents from 'analytics/withEvents';

interface LikedToastProps {
  children?: React.ReactElement | JSX.Element;
  closeToast: () => void;
  onTooltipLinkClick?: () => void;
}

const LikedToast: React.FC<LikedToastProps> = ({ children, closeToast, onTooltipLinkClick }) => {
  const { t } = useLocale();
  const isMobile = useScreenBreakpoint() === 1;

  useEffect(() => {
    setTimeout(() => closeToast(), isMobile ? 4000 : 8000);
  }, []);

  const handleTooltipLinkClick = () => {
    closeToast();
    onTooltipLinkClick();
  };

  const content = (
    <>
      {isMobile && <HeartIcon width={33} height={35} />}
      <TooltipText>{t('likedToast.text')}</TooltipText>
      <TooltipLink
        onClick={handleTooltipLinkClick}
        routeName={Route.Shortlist}
        preserveParams
        data-auto={`${Route.Shortlist}-link`}
      >
        {t('likedToast.link')}
      </TooltipLink>
    </>
  );

  return (
    <>
      {isMobile ? (
        <ClickAwayListener onClickAway={closeToast}>
          <ToastContainer>
            <ToastContent>{content}</ToastContent>
          </ToastContainer>
        </ClickAwayListener>
      ) : (
        <Tooltip
          placement="bottom-start"
          tooltip={content}
          clickable
          wrapperStyle={{ marginTop: -4, maxWidth: 'none', padding: '22px 16px' }}
          disableClickAway
        >
          {children}
        </Tooltip>
      )}
    </>
  );
};

const LikedToastWithEvents = withEvents<LikedToastProps>(sendEvent => ({
  onTooltipLinkClick() {
    sendEvent('page_navigation_bar_icon_click', 'page', { event: { icon: 'shortlist_toast' } });
  },
}))(LikedToast);

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  closeToast: () => dispatch(setIsLikedToastOpen(false)),
});

export default connect(null, mapDispatchToProps)(LikedToastWithEvents);
