import React from 'react';
import { useField } from 'react-final-form-hooks';
import { ITranslate, useLocale } from 'locale';
import { UploadBulletinStepProps } from '../';
import { TextField } from 'ds/components/input/TextField';
import { H3, Text } from 'ds/components/typography';
import {
  FULL_NAME_REGEXP,
  isILMobilePhoneNumberValid,
  isILPhoneNumberValid,
  isNotEmpty,
} from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { SpacingBlock } from 'components/spacing-block';
import {
  InputWithValidation,
  SectionHeading,
  StepHeading,
  TipBlock,
  TipContentWrapper,
  TipIconWrapper,
  TipRecommendation,
} from '../../styled';
import { ContactsStepWrapper } from './styled';
import TipIcon from 'assets/svg/tip.svg';

const TextFieldWithValidationMessage = withValidationMessage(TextField);

const validateNameField = (t: ITranslate) => (val: string) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.contactNameValidationMessage');

const validatePhoneField = (t: ITranslate, isMobilePhone: boolean) => (val: string) =>
  isMobilePhone
    ? (isILMobilePhoneNumberValid(val) ? undefined : t('uploadBulletinPage.contactMobilePhoneValidationMessage'))
    : (isILPhoneNumberValid(val) ? undefined : t('uploadBulletinPage.contactPhoneValidationMessage'));

interface ContactsStepProps extends UploadBulletinStepProps {
  showTip: boolean;
  isPrivateUser: boolean;
}

const ContactsStep: React.FC<ContactsStepProps> = ({
  formInstance,
  showTip,
  hideErrorOnFocus,
  isPrivateUser,
}) => {
  const { t } = useLocale();
  const userNameField = useField('userName', formInstance, validateNameField(t));
  const userPhoneField = useField('userPhone', formInstance, validatePhoneField(t, isPrivateUser));
  const formatNameField = (e: React.ChangeEvent<HTMLInputElement>) => {
    userNameField.input.onChange(e.target.value.replace(FULL_NAME_REGEXP, ''));
  };
  const termsLink = '/etc/terms';

  return (
    <SpacingBlock mBottom={3}>
      <ContactsStepWrapper>
        <StepHeading>{t('uploadBulletinPage.contactsStep.heading')}</StepHeading>
        <SpacingBlock mBottom={4}>
          <H3>{t('uploadBulletinPage.contactsStep.reminder')}</H3>
        </SpacingBlock>
        <SpacingBlock mBottom={2}>
          <SectionHeading weight="bold">
            {t('uploadBulletinPage.contactsStep.userNameLabel')}
          </SectionHeading>
        </SpacingBlock>
        <SpacingBlock mBottom={4}>
          <InputWithValidation error={userNameField.meta.error && userNameField.meta.touched}>
            <TextFieldWithValidationMessage
              {...userNameField.input}
              {...userNameField.meta}
              onFocus={() => hideErrorOnFocus(true)}
              onBlur={() => hideErrorOnFocus(false)}
              onChange={formatNameField}
              type="text"
              placeholder={t('uploadBulletinPage.contactsStep.userNameLabel.placeholder')}
            />
          </InputWithValidation>
        </SpacingBlock>
        <SpacingBlock mBottom={2}>
          <SectionHeading weight="bold">
            {t('uploadBulletinPage.contactsStep.userPhoneLabel')}
          </SectionHeading>
        </SpacingBlock>
        <InputWithValidation error={userPhoneField.meta.error && userPhoneField.meta.touched}>
          <TextFieldWithValidationMessage
            {...userPhoneField.input}
            {...userPhoneField.meta}
            onFocus={() => hideErrorOnFocus(true)}
            onBlur={() => hideErrorOnFocus(false)}
            placeholder={t('uploadBulletinPage.contactsStep.userPhoneLabel.placeholder', { isPrivateUser })}
          />
        </InputWithValidation>
      </ContactsStepWrapper>
      {showTip ? (
        <SpacingBlock mTop={5}>
          <TipBlock>
            <TipIconWrapper>
              <TipIcon />
            </TipIconWrapper>
            <TipContentWrapper>
              <SpacingBlock mBottom={1}>
                <Text weight="bold">{t('uploadBulletinPage.contactsStep.tip.header')}</Text>
              </SpacingBlock>
              <TipRecommendation>
                {t('uploadBulletinPage.contactsStep.tip.recommendation')}
                <a href={termsLink} target="_blank" rel="nofollow">
                  {t('uploadBulletinPage.contactsStep.tip.termsLink')}
                </a>
              </TipRecommendation>
            </TipContentWrapper>
          </TipBlock>
        </SpacingBlock>
      ) : null}
    </SpacingBlock>
  );
};

export default ContactsStep;
