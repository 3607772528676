import { Domain } from '.';
import { LoadType, MutationType } from 'store/sagas/apiService/types';
import { domainInitialState } from './utils';
import { MutationsResponseAction } from '../mutationsResponse';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';


export function userContentTextReviewsReducer(state: Domain<LoadType.UserContentTextReviews> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.UserContentTextReviews> {
  if (action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.AddUGCEmotion) {

    const newDocId2TextReviews = state.data.docId2TextReviews.map(item => {
      if (item.ugcId === action.meta.variables.ugcId) {
        return {
          ...item,
          emotions: {
            emotions: action.meta.response.data.addUgcEmotion.emotions,
            userEmotion: action.meta.response.data.addUgcEmotion.userEmotion,
          },
        };
      }
      return item;
    });

    return {
      ...state,
      data: {
        ...state.data,
        docId2TextReviews: newDocId2TextReviews,
      },
    };
  }
  return state;
}
