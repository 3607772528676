import * as React from 'react';
import styled from '@emotion/styled';

const StyledPane = styled.div`
  display: flex;
  flex-direction: column;
  backface-visibility: visible;
  position: absolute;
  overflow: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutrals.black };
`;

interface PaneProps {
  children: React.ReactNode;
  width: number;
  rotate: number;
}

export const Pane: React.FC<PaneProps> = ({
  children,
  width,
  rotate,
}) => (
  <StyledPane
    style={{
      width,
      transform: `rotateY(${rotate}deg) translateZ(${width / 2}px)`,
    }}
  >
    {children}
  </StyledPane>
);
