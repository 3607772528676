import { createSelector } from 'reselect';
import { transportationCarouselDataSelector, transportationHubsDataSelector } from './transportation';
import { marketplaceSelector, routeSelector } from '../router';
import { Route } from 'config/routes';
import { ANALYTICS_MAPPING, Section } from 'components/navigation/subheader/SectionsNavigation';
import { ilSchoolsDataSelector } from './schools';
import { makeCarouselDataSelectorByType } from '../utils';
import { IInsight, InsightDescriptor, InsightType, MarketplaceType, ResolutionPreference } from 'utils/entities';
import {
  constructionCarouselDataSelector,
  constructionsInConstruction,
  constructionsInFiling,
  constructionsInFilingApproved,
} from './construction';
import { nuisancesCarouselDataSelector } from './nuisances';
import { quietStreetsDataSelector } from './quietStreets';
import {
  createAreaHighlightsAboutToComeListSelector,
  createAreaHighlightsGoodToKnowListSelector,
  hasBuildingDetailsSectionSelector,
  hasPricesEstimationSectionSelector,
  impactfulTradeoffsSelector,
  makeInsightsByTypeSelector,
  resolvedLocalPagePreviewSelector,
  unitPageListingWithInsightsSelector,
} from 'store/state/domainData/selectors';
import { uniq, flow, first } from 'lodash';
import { hasEstimationDataSelector, responseDataSelector } from 'components/widget/prices/section/selectors';
import { isPricesListingsTabsWithDataSelector } from './prices/selectors';
import { DISPLAYED_RATINGS } from 'screens/UnitPage/residential/Review';
import { currentPoiSelector } from 'store/state/selectors/poi';

type AnalyticSectionWithData = [ string, ReturnType<typeof getSectionKeyName> ] | null;
type SectionsResult = { insights: InsightType[] } & Record<'sections' | 'collapsed_sections', string[]>;

const collapsedRoutes = new Set([
  Route.UnitPage,
  Route.UnitPageCommercial,
  Route.ProjectPage,
  Route.ProjectPageCommercial,
]);

export const isCollapsedInsightsSelector = createSelector(
  routeSelector ,
  (route) => collapsedRoutes.has(route.name)
);
export const isCollapsedInsightsSectionKeySelector = createSelector([ routeSelector ], (route) => collapsedRoutes.has(route.name) ? 'collapsed_sections' : 'sections');

const showInsightsCarouselRoutes = new Set([
  Route.UnitPage,
  Route.UnitPageCommercial,
  Route.AddressPage,
  Route.ProjectPage,
  Route.ProjectPageCommercial,
]);
export const showInsightsCarouselSelector = createSelector([ routeSelector ], (route) => showInsightsCarouselRoutes.has(route.name));

const areaHighlightsTypeMap = (insight: IInsight) => insight.category;

const SchoolsCarouselDataSelectorByType = makeCarouselDataSelectorByType(InsightType.Education);
const SafetyCarouselDataSelectorByType = makeCarouselDataSelectorByType(InsightType.Safety);
const PriceCarouselDataSelectorByType = makeCarouselDataSelectorByType(InsightType.Prices);
const LivabilityCarouselDataSelectorByType = makeCarouselDataSelectorByType(InsightType.Livability);

const areaHighlightsListGoodToKnowSelector = createAreaHighlightsGoodToKnowListSelector(areaHighlightsTypeMap);
const areaHighlightsListAboutToComeSelector = createAreaHighlightsAboutToComeListSelector(areaHighlightsTypeMap);

const getSectionKeyName = (isCollapsed: boolean) => isCollapsed ? 'collapsed_sections' : 'sections';

const transportationSectionNameWithDataSelector = createSelector([
  transportationHubsDataSelector,
  transportationCarouselDataSelector,
  isCollapsedInsightsSectionKeySelector,
], (data, carouselData, sectionKeyName): AnalyticSectionWithData => data.length || carouselData.length ? [ ANALYTICS_MAPPING[Section.TRANSPORTATION], sectionKeyName ] : null);

const isSchoolsSectionNameWithDataSelector = createSelector([
  ilSchoolsDataSelector,
  SchoolsCarouselDataSelectorByType,
  showInsightsCarouselSelector,
  marketplaceSelector,
  isCollapsedInsightsSectionKeySelector,
], (data, carouselData, showCarousel, marketplace, sectionKeyName): AnalyticSectionWithData => {
  const widgetSchoolsHasCarouselData = showCarousel && carouselData && carouselData.length;
  const isCommercial = marketplace === MarketplaceType.Commercial;

  return !((!data && !widgetSchoolsHasCarouselData) || (isCommercial && !widgetSchoolsHasCarouselData))
    ? [ ANALYTICS_MAPPING[Section.SCHOOLS], sectionKeyName ]
    : null;
});

const safetySectionNameWithDataSelector = createSelector([
  SafetyCarouselDataSelectorByType,
  showInsightsCarouselSelector,
  isCollapsedInsightsSectionKeySelector,
], (carouselData, showCarousel, sectionKeyName): AnalyticSectionWithData => showCarousel && carouselData && carouselData.length ? [ ANALYTICS_MAPPING[Section.SAFETY], sectionKeyName ] : null);

const constructionsSectionNameWithDataSelector = createSelector([
  constructionsInFiling,
  constructionsInFilingApproved,
  constructionsInConstruction,
  constructionCarouselDataSelector,
  showInsightsCarouselSelector,
  isCollapsedInsightsSectionKeySelector,
], (inFiling, inFilingApproved, inConstruction, carouselData, showCarousel, sectionKeyName): AnalyticSectionWithData => {
  const inFilingJoined = [ ...inFiling, ...inFilingApproved ];
  const widgetHasInConstruction = inConstruction && inConstruction.length;
  const widgetHasInFiling = inFilingJoined && inFilingJoined.length;

  if (widgetHasInConstruction || widgetHasInFiling || (carouselData && carouselData.length)) {
    return [ ANALYTICS_MAPPING[Section.PLANNING], sectionKeyName ];
  }
  return null;
});

const pricesSectionNameWithDataSelector = createSelector([
  PriceCarouselDataSelectorByType,
  flow(makeInsightsByTypeSelector(InsightDescriptor.PriceTrendsNew), first),
  responseDataSelector,
  showInsightsCarouselSelector,
  marketplaceSelector,
  isCollapsedInsightsSectionKeySelector,
], (carouselData, priceTrends, { dynamicContentData }, showCarousel, marketplace, sectionKeyName) => {
  return !(
    marketplace === MarketplaceType.Commercial
    && !(showCarousel && carouselData && carouselData.length)
  ) && (
    !dynamicContentData
    || !(dynamicContentData.avgPrice || dynamicContentData.yieldPrice)
  ) && !(priceTrends && priceTrends.summary && priceTrends.summary.nonText && priceTrends.summary.nonText.data)
    ? null
    : [ ANALYTICS_MAPPING[Section.PRICES], sectionKeyName ];
});

const pricesEstimationWithDataSectionSelector = createSelector([
  hasEstimationDataSelector,
  hasPricesEstimationSectionSelector,
], (hasSection, hasData) => hasSection && hasData ? [ ANALYTICS_MAPPING[Section.PRICES_ESTIMATION], 'sections' ] : null);

const priceHistoryWithDataSectionSelector = createSelector([
  isPricesListingsTabsWithDataSelector,
], (hasData) => hasData ? [ ANALYTICS_MAPPING[Section.PRICE_HISTORY], 'sections' ] : null);

const ugcWithDataSectionSelector = createSelector([
  resolvedLocalPagePreviewSelector,
  routeSelector,
], (localData, route) => route.name === Route.UnitPage && localData && (localData.latestOpinion || (localData.userRatings && DISPLAYED_RATINGS.some(dr => localData.userRatings[dr]))) ? [ ANALYTICS_MAPPING[Section.UGC], 'sections' ] : null);

const buildingDetailsWithDataSectionSelector = createSelector([
  hasBuildingDetailsSectionSelector,
], (hasSection) => hasSection ? [ ANALYTICS_MAPPING[Section.BUILDING_DETAILS], 'sections' ] : null);

const nuisancesSectionNameWithDataSelector = createSelector([
  nuisancesCarouselDataSelector,
  showInsightsCarouselSelector,
  isCollapsedInsightsSectionKeySelector,
], (carouselData, showCarousel, sectionKeyName) => {
  return !carouselData || !carouselData.length || !showCarousel
    ? null
    : [ ANALYTICS_MAPPING[Section.NUISANCES], sectionKeyName ];
});

const neighborhoodLifeSectionNameWithDataSelector = createSelector([
  LivabilityCarouselDataSelectorByType,
  isCollapsedInsightsSelector,
  showInsightsCarouselSelector,
  isCollapsedInsightsSectionKeySelector,
], (carouselData, isCollapsed, showCarousel, sectionKeyName) => {
  return !(isCollapsed && showCarousel && carouselData && carouselData.length) ? null : [ ANALYTICS_MAPPING[Section.NEIGHBOURHOOD_LIFE], sectionKeyName ];
});

const quietStreetSectionNameWithDataSelector = createSelector([
  quietStreetsDataSelector,
  LivabilityCarouselDataSelectorByType,
  isCollapsedInsightsSelector,
  showInsightsCarouselSelector,
  isCollapsedInsightsSectionKeySelector,
], (data, carouselData, isCollapsed, showCarousel, sectionKeyName) => {
  return !data && !(!isCollapsed && showCarousel && carouselData && carouselData.length) ? null : [ ANALYTICS_MAPPING[Section.QUIET_STREETS], sectionKeyName ];
});

const maybeCollapsedSectionsSelector = createSelector([
  transportationSectionNameWithDataSelector,
  isSchoolsSectionNameWithDataSelector,
  safetySectionNameWithDataSelector,
  constructionsSectionNameWithDataSelector,
  pricesSectionNameWithDataSelector,
  nuisancesSectionNameWithDataSelector,
  neighborhoodLifeSectionNameWithDataSelector,
  quietStreetSectionNameWithDataSelector,
], (...sections) => sections);

export const collapsedSectionsWithDataSelector = createSelector([ maybeCollapsedSectionsSelector, isCollapsedInsightsSelector ], (sections, isCollapsed) => {
  return isCollapsed ? sections.filter(Boolean).map(el => el[0]) : undefined;
});

const otherSectionsSelector = createSelector([
  pricesEstimationWithDataSectionSelector,
  priceHistoryWithDataSectionSelector,
  buildingDetailsWithDataSectionSelector,
  ugcWithDataSectionSelector,
], (...sections) => sections);

export const areaInsightsHasDataSelector = createSelector([
  areaHighlightsListGoodToKnowSelector,
  areaHighlightsListAboutToComeSelector,
], (goodToKnowList, aboutToComeList) => uniq([ ...goodToKnowList, ...aboutToComeList ].filter(Boolean)));

export const impactfulInsightsSelector = createSelector([
  impactfulTradeoffsSelector,
  unitPageListingWithInsightsSelector,
], (impactInsightList, bulletin) => {
  const impactList = Boolean(bulletin)
  && (!bulletin.addressDetails.resolutionPreferences || bulletin.addressDetails.resolutionPreferences === ResolutionPreference.Accurate)
  && impactInsightList.length
    ? impactInsightList
    : [];

  return impactList.map(el => el.category);
});

const hasProjectDiscountSelector = createSelector(currentPoiSelector,
  (poi): boolean => Boolean(poi && 'discount' in poi && poi.discount && poi.discount.showDiscount)
);

export const insightSectionsWithDataSelector = createSelector([
  areaInsightsHasDataSelector,
  impactfulInsightsSelector,
  maybeCollapsedSectionsSelector,
  otherSectionsSelector,
  hasProjectDiscountSelector,
  routeSelector,
], (areaInsights, impactInsights, maybeCollapsedSections, otherSections, hasDiscount, route) => {
  const insights = uniq([ ...areaInsights, ...impactInsights ].filter(Boolean));

  const result: SectionsResult = {
    sections: [],
    collapsed_sections: [],
    insights,
  };

  [ ...maybeCollapsedSections, ...otherSections ].filter(Boolean).forEach(([ sectionName, sectionKey ]) => {
    result[sectionKey].push(sectionName);
  });

  if (route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial) {
    result.collapsed_sections = [ ...result.sections ];
    result.sections = [];
  }

  if (hasDiscount) {
    result.sections.push('project_sale_promotion');
  }

  return result;
});
