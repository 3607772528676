import { connect } from 'react-redux';
import { Authentication } from './Authentication';
import { RootAction, State } from 'store/state';
import { Dispatch } from 'redux';
import { AuthenticationModalType, SocialVendor } from './types';
import {
  setAuthEmail,
  setAuthModal,
  userLoginSocial,
  signInSignUpWithEmail,
  userLoginWithEmailAndPassword,
} from 'store/state/app/actions';
import {
  ILoginWithEmailAndPasswordVariables,
  ISignInSignUpWithEmailVariables,
  IResetPasswordRequestVariables,
  IResetPasswordUpdateVariables,
  MutationType,
  AuthError,
} from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import {
  resetPasswordRequestResponseSelector,
  resetPasswordUpdateResponseSelector,
} from 'store/state/mutationsResponse/selectors';
import { authEmailSelector, authModalPendingActionSelector, authModalStatusSelector } from 'store/state/app/selectors';
import { userAuthErrorSelector } from 'store/state/domainData/selectors';
import { flow } from 'lodash';
import { Route } from 'config/routes';

const authModalAlreadyExistsEmailSelector = flow(userAuthErrorSelector, (e) => e ? e.email : null);
const loginFailedSelectorDirty = flow(userAuthErrorSelector, (e) => e ? e.reason === AuthError.LoginFailed : null);

const mapStateToProps = (state: State) => ({
  activeModal: authModalStatusSelector(state),
  alreadyExistsEmail: authModalAlreadyExistsEmailSelector(state),
  resetPasswordRequestResponse: resetPasswordRequestResponseSelector(state),
  resetPasswordUpdateResponse: resetPasswordUpdateResponseSelector(state),
  pendingAction: authModalPendingActionSelector(state),
  isLoginFailed: loginFailedSelectorDirty(state),
  email: authEmailSelector(state),
});


const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetPassword: (data: IResetPasswordUpdateVariables, key: Route) => dispatch(inlineMutate(MutationType.ResetPasswordUpdate, data, key)),
  onResetPasswordRequest: (data: IResetPasswordRequestVariables) => dispatch(inlineMutate(MutationType.ResetPasswordRequest, data)),
  onSocialLogin: (vendor: SocialVendor) => dispatch(userLoginSocial(vendor)),
  onSignInSignUpWithEmail: (data: ISignInSignUpWithEmailVariables) => dispatch(signInSignUpWithEmail(data)),
  onLoginWithEmailAndPassword: (data: ILoginWithEmailAndPasswordVariables) => dispatch(userLoginWithEmailAndPassword(data)),
  onSetActiveModal: (type: AuthenticationModalType) => dispatch(setAuthModal({ type }, { isUserInitiated: true })),
  setEmail: (email: string) => dispatch(setAuthEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
