import React from 'react';
import { useForm } from 'react-final-form-hooks';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { AuthenticationField, MIN_PASSWORD_LENGTH } from './types';
import { SetPasswordRoot, SetPasswordInfo, FormControlsWrapper } from './styled';

interface SetPasswordFormProps {
  password: string;
  onPasswordChange(e: React.ChangeEvent<HTMLInputElement>): void;
  isPasswordValid: boolean;
  dirtyFields: Set<AuthenticationField>;
  onSetDirty: (field: AuthenticationField) => void;
  onSubmit: () => void;
}

export const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
  password,
  onPasswordChange,
  isPasswordValid,
  dirtyFields,
  onSetDirty,
  onSubmit,
}) => {
  const { t } = useLocale();
  const { handleSubmit } = useForm({ onSubmit });

  return (
    <SetPasswordRoot>
      <SetPasswordInfo>{t('authentication.setPassword.info')}</SetPasswordInfo>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          data-auto="set-password-field"
          type="password"
          fullWidth
          onChange={onPasswordChange}
          value={password}
          placeholder={t('authentication.setPassword.inputPlaceholder')}
          hasError={!isPasswordValid && dirtyFields.has('password')}
          errorMessage={t('authentication.passwordInvalidMessage', { length: MIN_PASSWORD_LENGTH })}
          onBlur={() => onSetDirty('password')}
        />
        <FormControlsWrapper>
          <Button
            disabled={!isPasswordValid || !password}
            fullWidth
            size="large"
          >
            {t('authentication.setPassword.submitText')}
          </Button>
        </FormControlsWrapper>
      </form>
    </SetPasswordRoot>
  );
};
