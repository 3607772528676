import { localDocSelector, makeNullSafeDomainGetter, strictLocalDocSelector, localDocPreviewSelector, createIsLoadingSelector } from './common';
import { flow } from 'lodash';
import { createSelector } from 'reselect';
import { createAgentsCardsSelector, createAgenciesCardsSelector } from './madad';

export const resolvedLocalPageSelector = makeNullSafeDomainGetter(localDocSelector, 'searchLocal2');
export const docSelector = makeNullSafeDomainGetter(strictLocalDocSelector, 'docId2Information');
export const resolvedStrictLocalDocSelector = flow(docSelector, (info) => info && info.document);
export const resolvedLocalPagePreviewSelector = makeNullSafeDomainGetter(localDocPreviewSelector, 'searchLocal2');

export const isStrictLocalDocLoadingSelector = createIsLoadingSelector(strictLocalDocSelector);

export const localPageMadadWinnersSelector = flow(
  resolvedLocalPageSelector,
  (data) => data ? data.madadWinners : null
);

export const localPageMadadReviewsSelector = flow(
  resolvedLocalPageSelector,
  (data) => data ? data.userRatings : null
);
export const localPageMadadAgentsCardsSelector = createAgentsCardsSelector(
  flow(localPageMadadWinnersSelector, winnersGroup => winnersGroup ? winnersGroup.agent : null)
);
export const localPageMadadAgenciesCardsSelector = createAgenciesCardsSelector(
  flow(localPageMadadWinnersSelector, winnersGroup => winnersGroup ? winnersGroup.office : null)
);

export const cityDocIdSelector = createSelector([
  resolvedStrictLocalDocSelector,
], (address) => {
  if (address) {
    const city = address.hierarchyLevel.find((item) => item.text === address.city);
    return city ? city.docId : null;
  }
  return null;
});
