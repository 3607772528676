import React from 'react';
import { Link } from 'components/link';
import { BackLinkConfig } from './configFactory';
import { PoiId } from 'utils/entities';
import { ContactAgentModalSource, ContactModalOpenMeta } from 'store/state/app';
import Cookie from 'js-cookie';
import { INTENT_MODAL_SESSION_KEY } from 'store/sagas/contactPageWatcher';
import { useScreenBreakpoint } from 'consts/breakpoints';

export interface BackLinkProps {
  config: BackLinkConfig;
  intentModalSource?: ContactAgentModalSource;
  openIntentModal?: (id: PoiId, meta: ContactModalOpenMeta) => void;
  poiId?: string;
}

export const BackLink: React.FC<BackLinkProps> = props => {
  const { children, config, intentModalSource, openIntentModal, poiId } = props;
  const { routeName, routeParams, isPrevRoute, prevPageIndex } = config;
  const screen = useScreenBreakpoint();
  const isMobile = screen < 3;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const intentSession = Boolean(Cookie.get(INTENT_MODAL_SESSION_KEY));
    if (intentModalSource && !intentSession && isMobile) {
      e.preventDefault();
      openIntentModal(poiId, { source: intentModalSource });
    }
    else if (isPrevRoute) {
      e.preventDefault();
      prevPageIndex ? window.history.go(-prevPageIndex) : window.history.back();
    }
  };

  return (
    <Link
      routeName={routeName}
      routeParams={routeParams}
      data-auto="back-link"
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};
