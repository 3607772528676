import styled from '@emotion/styled';
import { Button } from 'ds/components/button';

export const Root = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 359px;
    padding: ${theme.spacing(1)};
  `}
`;

export const ContactButton = styled(Button)`
  ${({ theme }) => `
    width: 279px;
    margin: ${theme.spacing(2.5)} ${theme.spacing(2.5)} 0 ${theme.spacing(2.5)};
  `}
`;

export const FavoritesMobileButtonWrapper = styled.div`
  z-index: 2;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.neutrals.white};
`;

export const ShareWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
`;

export const CardSeparator = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.neutrals.grey8};
    width: 100%;
    height: 1px;
    margin-top: ${theme.spacing(2.5)};
  `}
`;

export const CardInner = styled.div`
  min-width: 100%;
`;
