import { fork, call, getContext, put, select } from 'redux-saga/effects';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { Route, State as RouteState } from 'config/routes';
import { queryData } from 'store/sagas/apiService';

import { soldPageDealSelector, userCommuteWithPrioritiesSelector } from 'store/state/domainData/selectors';

import { flow } from 'lodash';
import * as actions from 'store/state/router/actions';
import { IPoiUserData } from 'utils/entities';
import { waitForUserResolve } from '../utils';
import { FetchInsightsParams, loadInsights } from '../loadInsights';

const hasDealSelector = flow(soldPageDealSelector, Boolean);


const insightsVariablesSelector = flow(
  soldPageDealSelector,
  (deal): FetchInsightsParams => {
    if (!deal) return null;

    return {
      docId: deal.addressDetails.docId,
    };
  }
);

export function* soldPageHandler(params: RouteState) {
  if (!params.params.id) return;
  yield call(waitForUserResolve);
  const userData: IPoiUserData = yield select(userCommuteWithPrioritiesSelector);

  const loadOpts: LoadOptions<LoadType.Deal> = {
    loadType: LoadType.Deal,
    meta: {
      variables: {
        ids: [
          { type: 'deal', id: params.params.id },
        ],
        userData,
      },
    },
  };

  yield call(queryData, loadOpts);

  const soldPageExists = yield select(hasDealSelector);

  if (!soldPageExists) {
    const logger = yield getContext('logger');
    logger.error('Sold page did not exist, redirecting Home.');
    yield put(actions.navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }

  const loadInsightsParams: FetchInsightsParams = yield select(insightsVariablesSelector);

  if (!loadInsightsParams.docId) return;

  const loadOptions: LoadOptions<LoadType.AddressDoc> = {
    loadType: LoadType.AddressDoc,
    meta: { variables: { docId: loadInsightsParams.docId } },
  };

  yield fork(loadInsights, loadInsightsParams);
  yield fork(queryData, loadOptions);
}
