import React from 'react';
import UploadBulletinPage from 'screens/UploadBulletinPage';
import {
  UploadBulletinPageOwnProps,
} from 'screens/UploadBulletinPage/UploadBulletinPage';
import { DraftState } from 'screens/UploadBulletinPage/types';

interface EditBulletinPageProps extends UploadBulletinPageOwnProps {
  isLoading: boolean;
  initialValues: DraftState;
}

export const EditBulletinPage: React.FC<EditBulletinPageProps> = (props) => {
  if (props.isLoading || !props.initialValues) return null;

  return <UploadBulletinPage isEdit {...props} />;
};
