import { Avatar } from 'components/navigation/header/styled';
import { useLocale } from 'locale';
import React from 'react';
import { AgentDetails, Opinion, UserRatings } from 'utils/entities';
import PersonIcon from 'assets/svg/seller.svg';
import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { SmallText, Text } from 'ds/components/typography';
import { ellipsis } from 'utils/texts';
import { Link } from 'ds/components/link';
import { Link as RouteLink } from 'components/link';
import { Route } from 'config/routes';
import { ShowAt } from 'consts/breakpoints';

interface ReviewProps {
  opinion: Opinion;
  ratings?: { [rating in UserRatings]?: number | boolean };
  localPageDocId: string;
}

export const DISPLAYED_RATINGS: UserRatings[] = [ 'communityRank', 'cleanRank', 'schoolsRank', 'publicTransportRank', 'shoppingRank', 'pastimeRank' ];

const ReviewContainer = styled.div`
  display:flex;
  flex-direction: column;

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    height: 420px;
    flex-direction: row;
    margin: -12px;
    > div {
      flex-basis:0;
      flex: 1 1 0;
      margin: 12px;
    }
  `};
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  margin-right: 16px;
  > div:first-of-type {
    font-weight: bold;
  }
`;

const Card = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${({ theme }) => theme.colors.neutrals.grey8};
  padding: 32px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
  :first-of-type{
    margin-bottom: 24px;
  }
  `};
`;

const PersonCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  background: ${({ theme }) => colorToAlpha(theme.colors.brand.main, 0.07)};
  text-align: center;
  line-height: 32px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
  }
`;

const Advantage = styled.p`
  word-break: break-word;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  font-size: 17px;
  line-height: 1.58;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
      margin-bottom: 32px;
  `};
`;

const StyledRouteLink = styled(RouteLink)`
    text-decoration: none;
`;

const OpinionLink = styled(Link)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.brand.main};
`;

const ScoreRowContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin-bottom: 18px;
  :last-of-type{
    margin-bottom: 0;
    ${({ theme }) => theme.media.showAt({ at: 1 })`
      margin-bottom: 32px;
    `};
  }
`;
const ScoreLineContainer = styled.div`
  position:relative;
  align-self: center;
  width: 81px;
  height: 8px;
  background: ${({ theme }) => theme.colors.neutrals.grey8};
  vertical-align: middle;
`;

const ScoreFill = styled.div<{ rating: number }>`
  height:8px;
  background: ${({ theme }) => theme.colors.brand.main};
  position:absolute;
  left:0;
  width: ${({ rating }) => rating}%;
`;

const AgentLink = styled.a`
  color: ${({ theme }) => theme.colors.brand.light };
  text-decoration: none;
`;

const AgentDetailsContainer = styled.div`
  text-align: right;
  display: flex;
`;

const Review: React.FC<ReviewProps> = ({ opinion, ratings, localPageDocId }) => {

  const { t } = useLocale();

  const displayRatings = ratings && DISPLAYED_RATINGS.some(dr => ratings[dr]);
  const displayAdvantage = opinion && opinion.advantage;

  return (
    <ShowAt from={1}>
      {() => (
        <ReviewContainer>
          {displayRatings ? (
            <Card data-auto="ratings-card">
              <div>
                <Text style={{ marginBottom: 24 }} weight={'bold'}>{t('unitPage.ratings.title')}</Text>
                {DISPLAYED_RATINGS.map(dr => ratings[dr] ? (
                  <ScoreRow
                    key={dr}
                    rating={ratings[dr] as number}
                    title={t('localPage.neighbours.ratings.rated', { ratingKey: dr })}
                  />
                ) : null)}
              </div>
              <StyledRouteLink
                preserveParams
                routeName={Route.LocalPage}
                routeParams={{ id: localPageDocId }}
              >
                <OpinionLink data-auto="opinion-link" simple withArrow>{t('unitPage.opinions.link')}</OpinionLink>
              </StyledRouteLink>
            </Card>
          ) : null}
          {displayAdvantage ? (
            <Card data-auto="review-card">
              <div>
                <ContactContainer>
                  <div>
                    {
                      opinion.agentDetails ?
                        <Avatar large userInitials={null} imageURL={opinion.agentDetails.logo} /> :
                        <EnduserIcon />
                    }
                  </div>
                  <TitleContainer>
                    {
                      opinion.agentDetails
                        ? <AgentTitle agentDetails={opinion.agentDetails} />
                        : <div>{t('localPage.reviews.endUser.title', { sex: opinion.sex, age: opinion.age })}</div>
                    }
                    <SmallText>{opinion.date}</SmallText>
                  </TitleContainer>
                </ContactContainer>
                <Advantage>{ellipsis(opinion.advantage, 120)}</Advantage>
              </div>
              <StyledRouteLink
                preserveParams
                routeName={Route.LocalPage}
                routeParams={{ id: localPageDocId }}
              >
                <OpinionLink data-auto="opinion-link" simple withArrow>{t('unitPage.opinions.link')}</OpinionLink>
              </StyledRouteLink>
            </Card>
          ) : null}
        </ReviewContainer>
      )}
    </ShowAt>
  );
};

const AgentTitle = ({ agentDetails }: { agentDetails: AgentDetails }) => {
  return (
    <AgentDetailsContainer>
      <AgentLink target={'_blank'} href={agentDetails.url} style={{ fontWeight: 'bold' }}>
        {agentDetails.name}
      </AgentLink>
    </AgentDetailsContainer>
  );
};

const EnduserIcon = () => {
  return (
    <PersonCircle>
      <PersonIcon style={{ width: 24, height: 24, verticalAlign: 'middle' }} />
    </PersonCircle>
  );
};

const ScoreRow = ({ title, rating }: { title: string, rating: number }) => {
  return (
    <ScoreRowContainer>
      <Text>{title}</Text>
      <ScoreLineContainer>
        <ScoreFill rating={rating * 20} />
      </ScoreLineContainer>
    </ScoreRowContainer>
  );
};

export default Review;
