import React, { useEffect, useRef, useState } from 'react';
import HeartIcon from 'assets/svg/heart-empty.svg';
import { Wrapper } from './styled';


interface AnimatedHeartIconProps {
  size?: number;
  isFilled?: boolean;
  isTransparent?: boolean;
}

const AnimatedHeartIcon: React.FC<AnimatedHeartIconProps> = ({
  size = 24,
  isFilled,
  isTransparent,
}) => {
  const [ withWobble, setWithWobble ] = useState(!isFilled);
  const iconRef = useRef(null);

  useEffect(() => {
    const removeGrow = (evt: AnimationEvent) =>
      evt.animationName === 'grow' &&
      iconRef.current &&
      iconRef.current.children[0] &&
      iconRef.current.children[0].classList.remove('grow');

    if (iconRef.current && iconRef.current.children[0]) {
      const icon = iconRef.current.children[0];
      icon.addEventListener('animationend', removeGrow);
    }

    return () =>
      iconRef.current &&
      iconRef.current.children[0] &&
      iconRef.current.children[0].removeEventListener('animationend', removeGrow);
  }, []);

  const handleClick = () => {
    if (isFilled) {
      setWithWobble(false);
    }

    if (!isFilled && iconRef.current && iconRef.current.children[0]) {
      const icon = iconRef.current.children[0];
      icon.classList.add('grow');
    }
  };

  return (
    <Wrapper
      ref={iconRef}
      isFilled={isFilled}
      withWobble={withWobble}
      onClick={handleClick}
      onMouseEnter={() => setWithWobble(!isFilled)}
      isTransparent={isTransparent}
    >
      <HeartIcon width={size} height={size} />
    </Wrapper>
  );
};

export default AnimatedHeartIcon;
