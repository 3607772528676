import styled from '@emotion/styled';
import { TextLabel } from 'ds/components/typography';

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(0.25)} ${theme.spacing(1)}`};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  background-color: ${({ theme }) => theme.colors.brand.darker};
`;

export const Text = styled(TextLabel)`
  position: relative;
  top: 1px;
  ${({ theme }) => `margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(0.5)}`};
  color: ${({ theme }) => theme.colors.neutrals.white};
`;
