import React, { useMemo } from 'react';
import AgentTags from 'components/cards/new-layout/components/AgentTags';
import Price from 'components/cards/new-layout/components/Price';
import Tag, { TagType } from 'components/cards/new-layout/components/Tag';
import { TagsWrapper, PropertyPrimaryLine, PropertySecondaryLine } from 'components/cards/new-layout/styled';
import { useLocale } from 'locale';
import renderLineItem from 'components/labelLineItem';
import { getCommercialBulletinInfoConfig } from 'components/cards/new-layout/components/info-line-config';
import { useUniversalCardAddress } from 'components/cards/listing/UniversalCard/utils';
import { getBuildingClassKey } from 'utils/entities';
import { SpacingBlock } from 'components/spacing-block';
import { InfoSidePaddingContainer, LastInfoLine } from './styled';
import { CommercialBulletinUniversalCardProps } from 'components/cards/types';

const CommercialBulletin: React.FC<CommercialBulletinUniversalCardProps> = (props) => {
  const {
    isAgent,
    isExclusive,
    price,
    eventsHistory,
    generalCondition,
    qualityClass,
    area,
    size,
    buildingClass,
    floor,
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
  } = props;
  const localeProps = useLocale();
  const { t } = localeProps;

  const infoLineConfig = useMemo(() => {
    return getCommercialBulletinInfoConfig(area, price, size, floor, buildingClass, localeProps).filter(({ showCondition }) => showCondition);
  }, [ area, price, size, buildingClass, localeProps, getCommercialBulletinInfoConfig ]);

  const primaryAddress = useUniversalCardAddress(
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    true
  );

  const propertyTypeLabel = !!buildingClass ? (
    t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial: true })
  ) : null;
  const secondaryLine = [ propertyTypeLabel, primaryAddress ].filter(Boolean).join(', ');

  return (
    <InfoSidePaddingContainer>
      <Price price={price} eventsHistory={eventsHistory} hidePriceDrop />
      <SpacingBlock mTop={0.5}>
        <PropertyPrimaryLine>
          {infoLineConfig.map(renderLineItem)}
        </PropertyPrimaryLine>
      </SpacingBlock>
      <SpacingBlock mTop={0.5}>
        <PropertySecondaryLine data-auto="property-address">
          {secondaryLine}
        </PropertySecondaryLine>
      </SpacingBlock>
      <LastInfoLine>
        <TagsWrapper>
        {isAgent ? <AgentTags isExclusive={isExclusive} /> : null}
        {generalCondition ? (
          <Tag
            type={TagType.Commercial}
            label={t('filters.conditionsOptions', { value: generalCondition })}
          />
        ) : null}
        {qualityClass ? (
          <Tag
            type={TagType.Commercial}
            label={t('commercial.listing.offerDetails.qualityClass', { value: qualityClass })}
          />
        ) : null}
        </TagsWrapper>
      </LastInfoLine>
    </InfoSidePaddingContainer>
  );
};

export default CommercialBulletin;
