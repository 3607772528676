import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { TextLabel } from 'ds/components/typography';

export const LabelDynamicContent = styled(TextLabel)`
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.tiny};
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.07)};
    color: ${theme.colors.neutrals.grey1};
    padding: ${theme.spacing(0.25)} ${theme.spacing(0.5)};
  `}
`;
