import React from 'react';
import { GenericFilterProps } from '../../types';
import { useLocale } from 'locale';
import { DealType } from 'utils/entities';
import { Section } from '../../styled/common';
import * as Styled from './styled';
import { ControlButtonGroupWithStyles } from './styled';
import { ControlButtonGroup } from 'ds/components/button';


interface DealTypeSectionProps extends GenericFilterProps<'dealType'> {}

const DealTypeSection: React.FC<DealTypeSectionProps> = ({
  type,
  resetAll,
  isInline,
  config,
  commitedValue,
  intermediateValue,
  setIntermediateValue,
}) => {
  const { t } = useLocale();

  const onChange = (dealType: DealType) => {
    if (commitedValue === dealType) return;
    resetAll(commitedValue === DealType.Buy ? DealType.Rent : DealType.Buy);
  };
  const { dealTypeOptions } = config;
  const options = dealTypeOptions.map((dealType) => ({
    value: dealType,
    label: t('filters.dealType', { dealType }),
    onClick: () => isInline ? setIntermediateValue(dealType) : onChange(dealType),
  }));

  if (isInline) {
    return (
      <Section>
        <Styled.OptionsList>
          {options.map(({ value, label, onClick }) => (
            <Styled.DealTypeOption
              key={value}
              size="extraLarge"
              onClick={onClick}
              active={value === intermediateValue}
            >
              {label}
            </Styled.DealTypeOption>
          ))}
        </Styled.OptionsList>
      </Section>
    );
  }

  return (
    <ControlButtonGroupWithStyles
      data-section-filter-name={type}
      data-auto-filter-name={type}
      data-auto={`filter-${type}`}
    >
      <ControlButtonGroup
        options={options}
        currentValue={commitedValue}
      />
    </ControlButtonGroupWithStyles>
  );
};

export default DealTypeSection;
