import React from 'react';
import { useLocale } from 'locale';
import MailIcon from 'assets/svg/mail.svg';
import PhoneIcon from 'assets/svg/phone-negative.svg';
import WarningIcon from 'assets/svg/warning.svg';
import { Text } from 'ds/components/typography';
import { SpacingBlock } from 'components/spacing-block';
import { FreeUserMessageWrapper, ContactsWrapper, ContactsItem, ContactLabel, ContactsItemInner } from './styled';


const FreeUserMessage: React.FC = () => {
  const { t } = useLocale();
  const phone = t('uploadBulletinPage.freeUserMessage.phone');
  const email = t('uploadBulletinPage.freeUserMessage.email');
  const subjectText = t('uploadBulletinPage.freeUserMessage.emailSubject');
  const bodyText = t('uploadBulletinPage.freeUserMessage.emailBody');
  const uri = `mailto:${email}?subject=${subjectText}&body=${bodyText}`;


  return (
    <FreeUserMessageWrapper>
      <SpacingBlock mLeft={2.5}>
        <WarningIcon width={40} height={40} />
      </SpacingBlock>
      <div>
        <Text data-acc-heading="h3" weight="bold">{t('uploadBulletinPage.freeUserMessage.title')}</Text>
        <SpacingBlock mTop={1}>
          <Text>{t('uploadBulletinPage.freeUserMessage.subtitle')}</Text>
          <ContactLabel>{t('uploadBulletinPage.freeUserMessage.contactLabel')}</ContactLabel>
        </SpacingBlock>
        <ContactsWrapper>
          <ContactsItem withMargin>
            <a href={uri}>
              <ContactsItemInner weight="medium">
                <MailIcon height={16} width={16} />
                {email}
              </ContactsItemInner>
            </a>
          </ContactsItem>
          <ContactsItem>
            <a href={`tel:${phone}`}>
              <ContactsItemInner weight="medium">
                <PhoneIcon height={16} width={16}/>
                {phone}
              </ContactsItemInner>
            </a>
          </ContactsItem>
        </ContactsWrapper>
      </div>
    </FreeUserMessageWrapper>
  );
};

export default FreeUserMessage;
