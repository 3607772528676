import { DisplayInlineOption, ItemIcon, SelectedIcon } from 'ds/components/input/Select/styled';
import { ShowAt } from 'consts/breakpoints';
import { SmallText, Text } from 'ds/components/typography';
import IconCheckmark from 'assets/svg/dropdown/selected.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';
import { useLocale } from 'locale';
import { SelectItemProps } from 'ds/components/input/Select';


interface CopyTextProps {
  copyText: string;
  item: SelectItemProps;
  isSelected: boolean;
}

export const CopyText: React.FC<CopyTextProps> = (props) => {
  const { copyText, item, isSelected } = props;
  const { t } = useLocale();

  return (
    <CopyToClipboard text={copyText ? copyText : window.location.href}>
      <DisplayInlineOption>
        {item.icon ? (
          <ItemIcon>{item.icon}</ItemIcon>
        ) : null}
        <ShowAt at={1}>
          <Text>{isSelected ? t('content.copied') : item.label}</Text>
        </ShowAt>
        <ShowAt from={2}>
          <SmallText>{isSelected ? t('content.copied') : item.label}</SmallText>
        </ShowAt>
        {isSelected ? (
          <SelectedIcon>
            <IconCheckmark />
          </SelectedIcon>
        ) : null}
      </DisplayInlineOption>
    </CopyToClipboard>
  );
};
