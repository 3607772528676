import { createSelector } from 'reselect';
import { head } from 'lodash';
import {
  createIsLoadingSelector,
  editListingPoiSelector,
  makeNullSafeDomainGetter,
} from 'store/state/domainData/selectors/common';
import { IBulletin } from 'utils/entities';

export const baseEditListingPoiSelector = makeNullSafeDomainGetter(editListingPoiSelector, 'poiByIds');
export const isEditListingPoiLoadingSelector = createIsLoadingSelector(editListingPoiSelector);

export const editListingSelector = createSelector([ baseEditListingPoiSelector ], (pois: IBulletin[]): IBulletin => head(pois));
