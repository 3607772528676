import React from 'react';
import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';


export interface AmenityChipProps {
  title: string;
  icon: JSX.Element;
  locator: string;
}

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  alignItems: 'center',
  backgroundColor: theme.colors.neutrals.grey11,
  borderRadius: theme.borderRadius.tiny,
}));

const IconWrapper = styled.div({
  '> svg': {
    height: '24px',
    width: '24px',
    display: 'block',
  },
});

const TitleStyled = styled(SmallText)(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
}));

export const AmenityChip: React.FC<AmenityChipProps> = (props) => (
  <Root data-auto-highlight-amenity={props.locator}>
    <IconWrapper>{props.icon}</IconWrapper>
    <TitleStyled weight="medium">{props.title}</TitleStyled>
  </Root>
);
