import React from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { State } from 'store/state';
import withEvents from 'analytics/withEvents';
import { ShowAt } from 'consts/breakpoints';
import { shareHandler, ShareType } from 'helpers/share-helpers';
import { ShareButton } from 'components/navigation/share/ShareButton';
import { isShareModalOpenSelector, ShareModalType } from 'components/share-modal';
import ShareModal from 'components/share-modal';
import { ShareActions } from 'components/share-actions';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { setShareModalOpen } from 'store/state/app/actions';
import { IAutocompleteAddress } from 'utils/entities';
import { mapShareTypeToUgcShareEventMethod } from '../utils';

const UGC_SHARE_PARAM = 'specificUgcShare=true';

const SHARE_TYPES: ShareType[] = [
  ShareType.Email,
  ShareType.CopyLink,
  ShareType.Twitter,
  ShareType.FacebookMessenger,
  ShareType.Facebook,
];

interface UgcShareReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  addressDocEntry: IAutocompleteAddress;
  notify?: (type: ShareType) => void;
}

export const UgcShareReviewModal: React.FC<UgcShareReviewModalProps> = props => {
  const { isOpen, onClose, addressDocEntry, notify } = props;
  const { t } = useLocale();

  if (!isOpen) return null;

  const text = t('ugc.shareReviewModal.shareText');
  const { href, origin, pathname } = window.location;
  const searchParams = `?${UGC_SHARE_PARAM}`;
  const url = `${origin}${pathname}${searchParams}`;

  const shouldStayOpen = (value: ShareType) => value === ShareType.CopyLink;

  const shouldResetSelectedValue = () => true;

  const handleShare = (type: ShareType) => {
    shareHandler({ type, url, text, shareViaEmailOptions: { t, addressDocEntry } });
    notify(type);
  };

  return (
    <ShowAt at={1}>
      {matches => matches ? (
        <ShareButton
          onClose={onClose}
          initialIsOpen
          handleShare={handleShare}
          shouldStayOpen={shouldStayOpen}
          shouldResetSelectedValue={shouldResetSelectedValue}
        />
      ) : (
        <ShareModal
          header={t('ugc.shareReviewModal.title')}
          name={ShareModalType.UgcTextReview}
          getBaseLink={() => href}
          renderActions={copyText => (
            <ShareActions
              types={SHARE_TYPES}
              copyText={copyText}
              onShare={handleShare}
            />
          )}
        />
      )}
    </ShowAt>
  );
};

const mapStateToProps = (state: State) => ({
  isOpen: isShareModalOpenSelector(state, ShareModalType.UgcTextReview),
  addressDocEntry: addressDocEntrySelector(state),
});

const mapDispatchToProps = {
  onClose: () => setShareModalOpen(null),
};

const ConnectedUgcShareReviewModal = connect(mapStateToProps, mapDispatchToProps)(UgcShareReviewModal);

export default withEvents(sendEvent => ({
  notify(type: ShareType) {
    const payload = { event: { method: mapShareTypeToUgcShareEventMethod[type], source: 'specific_review' } };
    sendEvent('ugc_review_share_click', 'ugc', payload);
  },
}))(ConnectedUgcShareReviewModal);
