import React, { useRef } from 'react';
import { useMapMover } from 'hooks/useMapMover';


export const MovableMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  useMapMover(mapRef);
  return (
    <div ref={mapRef} />
  );
};
