import React, { useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { routeContext } from 'react-router5';
import Modal from 'ds/components/modal';
import { useLocale } from 'locale';
import HomeDoneIcon from 'assets/svg/home-done.svg';
import { shareActionTypeToIcon, shareHandler, ShareType } from 'helpers/share-helpers';
import { IBulletin, ICommercialBulletin } from 'utils/entities';
import { isServer } from 'utils';
import { Route } from 'config/routes';
import {
  AfterUploadModalWrapper,
  Description,
  HeaderTitle,
  IconWrapper,
  LinksWrapper,
  SocialLink,
  SocialLinkWrapper,
} from './styled';
import { isCommercialBulletin } from 'utils/marketplaceRoutes';


export interface ReportPopupViewProps {
  isModalOpen?: boolean;
  poi: IBulletin | ICommercialBulletin;
  onClosePopup: () => void;
}

export const AfterUploadModalView: React.FC<ReportPopupViewProps> = ({
  poi,
  isModalOpen,
  onClosePopup,
}) => {
  const { t } = useLocale();
  const router = useContext(routeContext);

  const onClose = () => {
    onClosePopup();
  };

  if (!isModalOpen || !poi) return null;

  const onSocialShare = (type: ShareType) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    shareHandler({ type, url: window.location.href, shareViaEmailOptions: { poi, t } });
  };

  const isCommercial = isCommercialBulletin(poi);
  const linkRoute = isCommercial ? Route.UnitPageCommercial : Route.UnitPage;
  const copyText = isServer() ? null : (window.location.origin + router.router.buildUrl(linkRoute, { id: poi.id }));

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      mobileModalPositionBottom
    >
      <AfterUploadModalWrapper>
        <IconWrapper>
          <HomeDoneIcon />
        </IconWrapper>
        <HeaderTitle weight="bold">{t('uploadBulletin.afterUpload.modal.title')}</HeaderTitle>
        <Description>{t('uploadBulletin.afterUpload.modal.description')}</Description>
        <LinksWrapper>
          <SocialLinkWrapper onClick={onSocialShare(ShareType.Facebook)}>
            <SocialLink>
              {shareActionTypeToIcon[ShareType.Facebook]}
            </SocialLink>
          </SocialLinkWrapper>
          <SocialLinkWrapper>
            <CopyToClipboard text={copyText}>
              <SocialLink>
                {shareActionTypeToIcon[ShareType.CopyLink]}
              </SocialLink>
            </CopyToClipboard>
          </SocialLinkWrapper>
          <SocialLinkWrapper onClick={onSocialShare(ShareType.Email)}>
            <SocialLink>
              {shareActionTypeToIcon[ShareType.Email]}
            </SocialLink>
          </SocialLinkWrapper>
        </LinksWrapper>
      </AfterUploadModalWrapper>
    </Modal>
  );
};
