import React from 'react';
import Tag, { TagType } from 'components/cards/new-layout/components/Tag';

interface ProjectTagProps {
  hasDiscount: boolean;
  isPromoted: boolean;
}

const ProjectTag: React.FC<ProjectTagProps> = ({ hasDiscount, isPromoted }) => {
  if (hasDiscount) return <Tag type={TagType.Discount} />;

  if (isPromoted) return <Tag type={TagType.Project} />;

  return null;
};

export default ProjectTag;
