import { dealTypeSelector } from 'store/state/selectors/search';
import { Route, State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { cancel, fork, getContext, put, all, call, select } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { loadInsights } from 'store/sagas/routing/handlers/loadInsights';
import { ISearchPoiVariables, IAutocompleteEntry } from 'utils/entities';
import { makeSearchVariablesSelector } from 'store/state/app/selectors';
import { resolvedStrictLocalDocSelector } from 'store/state/domainData/selectors';
import { queryProjectsAds, fetchArticles, getCityDocIdFromDocument } from 'store/sagas/routing/handlers/utils';
import { MarketplaceType } from 'utils/entities';
import { commercialLocalInfoSelector } from 'store/state/domainData/selectors/commercialLocalInfo';

export function* employmentAreaPageHandler(params: RouteState) {
  const docId = params.params.id;

  try {
    const insightsTask = yield fork(loadInsights, { docId, user: null });

    const queries = [
      call(queryData, {
        loadType: LoadType.StrictLocalDoc,
        meta: { variables: { docId } },
      }),
      call(queryData, {
        loadType: LoadType.CommercialLocalInfo,
        meta: { variables: { docId } },
      }),
      call(queryData, {
        loadType: LoadType.FooterByDocId,
        meta: { variables: { cityDocId: docId, footerContext: 'area' } },
      }),
    ];

    try {
      yield all(queries);

      const commercialLocalInfo = yield select(commercialLocalInfoSelector);
      if (!commercialLocalInfo) {
        yield put(navigateTo(
          Route.CheckAddress,
          { marketplace: MarketplaceType.Commercial },
          { replace: true, ssrHttpStatus: 302 }
        ));
      }
    }
    catch (e) {
      const logger = yield getContext('logger');
      logger.error('A page docId query failed, redirecting Home.', e);
      yield cancel(insightsTask);
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
      return;
    }
    const areaDocument: IAutocompleteEntry = yield select(resolvedStrictLocalDocSelector);
    const variables: ISearchPoiVariables = yield select(makeSearchVariablesSelector(resolvedStrictLocalDocSelector));
    yield fork(fetchArticles, getCityDocIdFromDocument([ areaDocument ]), 'employmentArea');
    const dealType = yield select(dealTypeSelector);
    const projectLoadOptions: LoadOptions<LoadType.SearchProjectList> = {
      loadType: LoadType.SearchProjectList,
      meta: { variables: { ...variables, dealType, isCommercialRealEstate: true } },
    };
    yield fork(queryData, projectLoadOptions);
  }
  catch (e) {
    const logger = yield getContext('logger');
    logger.error('One of queries failed in employment area page handler, redirecting Home.', e);
    yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }

  yield fork(queryProjectsAds, docId, 'commercialMarketplace');
}
