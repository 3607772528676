import React from 'react';
import { SelectStyleType } from './';
import {
  StyledSelectItem,
  StyledSelectItemCircle,
  StyledSelectItemSquare,
} from './styled';

export interface SelectItemProps {
  innerMarkup: React.ReactNode;
  isActive: boolean;
  error: string;
  onSelect: () => void;
  styleType: SelectStyleType;
}


const getSelectItemMarkup = (styleType: SelectStyleType): React.ElementType => {
  switch (styleType) {
    case SelectStyleType.circle:
      return StyledSelectItemCircle;
    case SelectStyleType.square:
      return StyledSelectItemSquare;
  }

  return StyledSelectItem;
};

export const SelectItem: React.FC<SelectItemProps> = ({
  innerMarkup,
  isActive,
  onSelect,
  styleType,
  error,
}) => {
  const SelectItemMarkup = getSelectItemMarkup(styleType);

  return (
    <SelectItemMarkup onClick={onSelect} isActive={isActive} type="button" error={error}>
      {innerMarkup}
    </SelectItemMarkup>
  );
};
