export enum Topic {
  Doorman = 'doorman',
  SafetyUnit = 'safetyUnit',
  Service = 'service',
  Roomates = 'roomates',
  TextReview = 'textReview',
}

export type RatingTopic = Exclude<Topic, Topic.TextReview>;

type EventRatingTopic = 'landlord' | 'neighbors' | 'safety' | 'service';

export const RatingTopicToEventRatingTopic: Record<RatingTopic, EventRatingTopic> = {
  [Topic.Doorman]: 'landlord',
  [Topic.SafetyUnit]: 'safety',
  [Topic.Service]: 'service',
  [Topic.Roomates]: 'neighbors',
};

export enum AnswerType {
  Bad = 1,
  SoSo,
  Good,
  Great,
}

export interface Step {
  type: Topic;
  answerTypes?: AnswerType[];
  activeAnswer?: AnswerType | null;
}

export const DEFAULT_ANSWER_TYPES = [ AnswerType.Bad, AnswerType.SoSo, AnswerType.Good, AnswerType.Great ];

export const TOPICS = [ Topic.SafetyUnit, Topic.Roomates, Topic.Service, Topic.Doorman ];
