import React, { FC } from 'react';
import { connect } from 'react-redux';
import { flow } from 'lodash';
import styled from '@emotion/styled';
import { State } from 'store/state';
import { TextLabel } from 'ds/components/typography';
import DefaultIcon from 'assets/svg/maki-general-new.svg';
import { userProfileSelector } from 'store/state/domainData/selectors';

const NotificationTagListWrapper = styled.div`
  display: flex;
  width: 100%;
  &:not(:empty) {
    margin-bottom: ${({ theme }) => `${theme.spacing(1)}`};
  }
`;

const NotificationTagWrapper = styled(TextLabel)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    height: 24px;
    padding: ${theme.spacing(1)};
    border-radius: ${theme.borderRadius.tiny};
    background-color: ${theme.colors.neutrals.grey10};
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};
    color: ${ theme.colors.neutrals.grey2 };
  `}
`;

const NotificationTagSvgIconWrapper = styled.div<{ iconColor: string; }>`
  ${({ theme, iconColor }) => `
    padding-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
    padding-top: ${theme.spacing(0.25)};
    ${iconColor ? `
      svg > path {
        fill: ${iconColor};
      }
    ` : ''}
  `};
`;

interface GetIconColorReturnValue {
  icon?: JSX.Element;
  color?: string;
  label?: string;
}

const defaultConfig: GetIconColorReturnValue = {
  icon: <DefaultIcon />,
  color: '',
  label: '',
};

interface NotificationTagProps {
  tag: string;
}

export const NotificationTag: FC<NotificationTagProps> = ({ tag }) => {
  const { icon, color, label } = defaultConfig;
  return (
    <NotificationTagListWrapper>
      <NotificationTagWrapper weight="bold" caps small>
        <NotificationTagSvgIconWrapper iconColor={color}>
          {icon}
        </NotificationTagSvgIconWrapper>
        {label || tag}
      </NotificationTagWrapper>
    </NotificationTagListWrapper>
  );
};

const commuteTypeSelector = flow(userProfileSelector,
    user => user && user.commutePreference && user.commutePreference.commuteType || null);

const mapStateToProps = (state: State) => ({
  commuteType: commuteTypeSelector(state),
});

export default connect(mapStateToProps)(NotificationTag);
