import * as React from 'react';
import { ViewPagerProps, ViewPagerState } from './index';

export const calcToX = (props: ViewPagerProps, state: ViewPagerState) => {

  const { align, children, infinite } = props;
  const { gestureState: { down, dx, __granted }, slidesWidths, containerWidth, infiniteIdx, slideIndex } = state;

  let toX = 0;
  const childrenCount = React.Children.count(children);

  const maxI = infinite ? (childrenCount * infiniteIdx + slideIndex) : slideIndex;

  for (let i = 0; i < maxI; i++) {
    toX -= slidesWidths[i];
  }

  const currentSlideWidth = slidesWidths[slideIndex] || 0;

  if (align === 'center') {
    toX += (containerWidth - currentSlideWidth) / 2;
  }
  else if (align === 'right') {
    toX += containerWidth - currentSlideWidth;
  }

  if (down && __granted && dx) toX += props.isRTL ? -dx : dx;

  return toX;
};

export const calcToXForCube = (props: ViewPagerProps, state: ViewPagerState) => {
  const { align, isRTL } = props;
  const { gestureState: { down, dx, __granted }, containerWidth, slideIndex } = state;

  let toX = 0;
  const maxI = slideIndex;

  for (let i = 0; i < maxI; i++) {
    toX -= containerWidth;
  }

  const currentSlideWidth = containerWidth || 0;

  if (align === 'center') {
    toX += (containerWidth - currentSlideWidth) / 2;
  }
  else if (align === 'right') {
    toX += containerWidth - currentSlideWidth;
  }

  if (down && __granted && dx) toX += isRTL ? -dx : dx;

  return toX / containerWidth * 90;
};


const circ = (timeFraction: number) => {
  return 1 - Math.sin(Math.acos(timeFraction));
};

const linearConversion = (a: [number, number], b: [number, number]) => {
  const o = a[1] - a[0];
  const n = b[1] - b[0];

  return (x: number) => ((x - a[0]) * n) / o + b[0];
};


export const getScale = (x: number, scaleRange: [number, number]) => {
  const diff = Math.abs(x) % 90;

  if (!diff) {
    return 1;
  }

  const convert =
    diff > 45
      ? linearConversion([ 90, 45 ], [ 1, 0.5 ])
      : linearConversion([ 45, 0 ], [ 0.5, 1 ]);

  const eased = circ(convert(diff));
  const scale = linearConversion([ 1, 0.5 ], scaleRange);
  return scale(eased);
};


