import { BiEvent } from 'analytics';
import { IInsight, INSIGHT_TYPE_TO_EVENT_INSIGHT_CATEGORY } from 'utils/entities';
import { select, getContext, take, call } from 'redux-saga/effects';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import {
  insightsByIdsSelector,
  goodTradeoffSelector,
  otherTradeoffSelector,
} from 'store/state/domainData/selectors';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from 'store/sagas/apiService/types';
import { RootAction } from 'store/state';
import { insightSectionsWithDataSelector } from 'store/state/selectors/insights/insightSections';

enum ListName {
  PRO = 'pro',
  CON = 'con',
  IMPACT = 'impact',
  VERIFIED = 'verified',
  MUST_KNOW = 'must_know',
  UPCOMING = 'upcoming',
}

const insightsLoadPattern = (action: RootAction) =>
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.Insights;

function* waitForInsightsLoad() {
  yield take(insightsLoadPattern);
}

const mapTradeoff = (tradeoff: IInsight, index: number, listName: ListName) => {
  const tradeoffEvent: any = {
    insight: {
      insight_id: tradeoff.id,
      insight_category: INSIGHT_TYPE_TO_EVENT_INSIGHT_CATEGORY[tradeoff.category],
      insight_sub_category: tradeoff.type,
    },
    list_name: listName,
    index,
  };

  if (tradeoff.tradeoff && tradeoff.tradeoff.value) {
    tradeoffEvent.insight.trade_off = {
      trade_off_value : tradeoff.tradeoff.value,
    };

    if (tradeoff.tradeoff.goodTradeoff !== null) {
      tradeoffEvent.insight.trade_off = {
        ...tradeoffEvent.insight.trade_off,
        is_good_trade_off: tradeoff.tradeoff.goodTradeoff,
      };
    }
  }

  return tradeoffEvent;
};

function* displayedTradeoffsPreprocessor() {
  yield call(waitForInsightsLoad);

  const prosTradeoffs: IInsight[] = yield select(goodTradeoffSelector);
  const consTradeoffs: IInsight[] = yield select(otherTradeoffSelector);

  const tradeoffsToSend = prosTradeoffs
    .map((tradeoff, index) => mapTradeoff(tradeoff, index, ListName.PRO))
    .concat(
      consTradeoffs.map((tradeoff, index) => mapTradeoff(tradeoff, index, ListName.CON))
    );

  return tradeoffsToSend;
}


export function* closeInsightWorker() {
  while (true) {
    const { payload: { previousRoute, route } } = yield take(TRANSITION_SUCCESS);

    if (previousRoute && previousRoute.params.insightId && !route.params.insightId) {
      const { sendEvent } = yield getContext('analytics');

      sendEvent('insight_close', 'insight', {
        insight: {
          insight_id: previousRoute.params.insightId,
        },
      });
    }
  }
}


export function* insightsPreProcessor({ name, category, payload }: BiEvent) {
  if (name === 'property_view' || name === 'address_view') {

    const displayedTradeoffs = yield call(displayedTradeoffsPreprocessor);
    const loadedInsightSections = yield select(insightSectionsWithDataSelector);

    return {
      name,
      category,
      payload: {
        ...payload,
        event: {
          ...payload.event,
          ...loadedInsightSections,
        },
        displayed_insights: displayedTradeoffs,
      },
    };
  }

  if (category === 'insight') {
    const insightsByIds: Record<string, IInsight> = yield select(insightsByIdsSelector);
    const insightData = insightsByIds[payload.insight.insight_id];
    if (!insightData) {
      const logger = yield getContext('logger');
      logger.warn('Insight not found by id, could not send BI event');
      return null;
    }
    return {
      name,
      category,
      payload: {
        ...payload,
        insight: {
          ...payload.insight,
          insight_id: insightData.id,
          insight_category: INSIGHT_TYPE_TO_EVENT_INSIGHT_CATEGORY[insightData.category],
          insight_sub_category: insightData.type,
        },
      },
    };
  }

  return { name, category, payload };
}
