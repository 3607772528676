import React from 'react';
import { get } from 'lodash';

import { useImageUrlBuilder, ImageOptions } from 'hooks/useImageUrlBuilder';
import { useWindowSize } from 'hooks/useWindowSize';
import { useScreenBreakpoint } from 'consts/breakpoints';
import CloseIcon from 'assets/svg/cross.svg';
import { ViewPager } from 'components/view-pager';
import { ViewFader } from 'components/view-fader';
import { Pager } from 'components/cards/listing/Pager';
import ControlsIcon from 'assets/svg/control-chevron.svg';
import { addEventListenerFn, EventListenerObject } from 'helpers/pan-responder';
import { getInfiniteNextIndex, getInfinitePrevIndex, shouldLazilyLoad } from 'utils/gallery';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { IThumbnail } from 'utils/entities';
import ArrowIcon from 'assets/svg/left-negative.svg';
import { WithLocaleProps } from 'locale';
import { PopupImageGalleryStatus } from 'components/unit-page/gallery/Gallery';
import { TransformedImage } from 'components/transformed-image';
import { KeyboardKeys } from 'utils/keyboardKeys';
import {
  ArrowIconWrapper, Block, CloseIconStyle, Container,
  ControlsArrowWrapper,
  ControlsStyle, GalleryWrapper, ImageItem,
  ImageWrapper,
  LegendFloorTotalLabel,
  LegendFloorTotalWrapper, LegendTotalLabel, LegendWrapper, SliderControls, SliderPager,
  ThumbnailImage,
} from './styled';
import {
  IMAGE_PIXEL_DENSITY,
  MAX_IMAGE_HEIGHT,
  SLIDER_THUMBNAIL_HEIGHT,
  SLIDER_THUMBNAIL_MAX_ITEMS_VISIBLE,
  ViewMode,
} from './constants';

interface PopupImageGalleryState {
  sliderControlsIndex: number;
}

interface EventViewPhotoScrollProps {
  dir: string;
  image: IThumbnail;
  index: number;
  total: number;
}

export interface PopupImageGalleryProps extends WithLocaleProps {
  images: IThumbnail[];
  onCloseClick: (e?: React.SyntheticEvent<HTMLElement>) => void;
  mode?: ViewMode;
  setGalleryStatus: (data: PopupImageGalleryStatus) => void;
  slideIndex: number;
  eventViewPhotoScroll?: (opts: EventViewPhotoScrollProps) => void;
  showLegend?: boolean;
}


type Direction = 'next' | 'prev';

interface ControlsArrowProps {
  dir: Direction;
  onClick(): void;
}

const LAZY_LOAD_MARGIN = 2;

const ControlsArrow: React.FC<ControlsArrowProps> = (props) => (
  <ControlsArrowWrapper {...props}>
    <ControlsIcon height={60} width={45} />
  </ControlsArrowWrapper>
);

interface ThumbnailProps {
  handleSlideIndexChange: (index: number, forceEventClick?: boolean) => void;
  currentIndex: number;
  index: number;
  path: string;
  rotateDegrees?: number;
}

const Thumbnail: React.FC<ThumbnailProps> = (props) => {
  const constructImageURI = useImageUrlBuilder();
  const imgSrc = constructImageURI(props.path, {
    height: SLIDER_THUMBNAIL_HEIGHT * IMAGE_PIXEL_DENSITY,
    rotateDegrees: props.rotateDegrees,
  });

  const jumpToSlide = () => props.handleSlideIndexChange(props.index, true);

  return (
    <ImageItem mode="slider" onClick={jumpToSlide} isActive={props.currentIndex === props.index}>
      <ImageWrapper>
        <ThumbnailImage url={imgSrc} />
      </ImageWrapper>
    </ImageItem>
  );
};

interface ImageGalleryItemProps {
  shouldLoad: boolean;
  thumbnail: IThumbnail;
}

const ImageGalleryItem: React.FC<ImageGalleryItemProps> = ({ shouldLoad, thumbnail }) => {
  const { isFloorPlan, url, description, rotateDegrees } = thumbnail;
  const breakpointValue = useScreenBreakpoint();
  const [ windowWidth ] = useWindowSize({ unsafelyForSSRInitialize: true });
  const isMobile = breakpointValue <= 2;

  const commonImageOptions: ImageOptions = { rotateDegrees };
  const imageOptions: ImageOptions = isMobile
    ? { ...commonImageOptions, width: windowWidth }
    : { ...commonImageOptions, height: MAX_IMAGE_HEIGHT * IMAGE_PIXEL_DENSITY };

  return (
    <ImageItem>
      <ImageWrapper>
        {shouldLoad ? (
          <TransformedImage
            style={{ height: isFloorPlan || !isMobile ? '100%' : 'auto' }}
            path={url}
            alt={description}
            {...imageOptions}
          />
        ) : null}
      </ImageWrapper>
    </ImageItem>
  );
};

class PopupImageGalleryView extends React.PureComponent<PopupImageGalleryProps, PopupImageGalleryState> {

  public static defaultProps: Partial<PopupImageGalleryProps> = {
    mode: 'carousel',
    showLegend: true,
  };

  public state: PopupImageGalleryState = {
    sliderControlsIndex: 0,
  };

  public componentDidMount () {
    const { slideIndex } = this.props;

    if (this.props.mode === 'slider') {
      this.keyDownHandler = addEventListenerFn(document.documentElement, 'keydown', this.handleKeyDown);
      this.adjustControlsIndex(slideIndex);
    }
  }

  public componentWillUnmount () {
    if (this.keyDownHandler) this.keyDownHandler.remove();
  }

  private keyDownHandler: EventListenerObject = null;

  private handleKeyDown = (e: KeyboardEvent): void => {
    if (e.code === KeyboardKeys.Left) {
      this.handlePrevSlide();
    }
    else if (e.code === KeyboardKeys.Right) {
      this.handleNextSlide();
    }
    else if (e.code === KeyboardKeys.ESC) {
      this.props.onCloseClick();
    }
  };

  private renderImage = (thumbnail: IThumbnail, i: number) => {
    const shouldLoad = shouldLazilyLoad(this.props.slideIndex, i, this.props.images.length, LAZY_LOAD_MARGIN);

    return (
      <ImageGalleryItem key={thumbnail.url} shouldLoad={shouldLoad} thumbnail={thumbnail} />
    );
  };

  private handleSlideIndexChange = (idx: number, forceEventClick?: boolean) => {
    this.props.setGalleryStatus({ slideIndex: idx });
    const total = this.props.images.length;
    const image = this.props.images[idx];

    if (this.props.mode === 'slider') {

      if (forceEventClick && this.props.eventViewPhotoScroll) {
        this.props.eventViewPhotoScroll({ dir: 'click', image, index: idx, total });
      }
      this.adjustControlsIndex(idx);
    }
    else {
      let dir = 'left';
      if (idx === 0 && this.props.slideIndex === this.props.images.length - 1) {
        dir = 'right';
      }
      else if (idx === this.props.images.length - 1 && this.props.slideIndex === 0) {
        dir = 'left';
      }
      else if (this.props.slideIndex < idx) {
        dir = 'right';
      }
      if (this.props.eventViewPhotoScroll) {
        this.props.eventViewPhotoScroll({ dir, image, index: idx, total });
      }
    }
  };

  private adjustControlsIndex = (slideIndex: number) => {
    const minThreshold = SLIDER_THUMBNAIL_MAX_ITEMS_VISIBLE / 2;
    const maxThreshold = this.props.images.length - minThreshold;
    this.setState(() => ({
      sliderControlsIndex: slideIndex - minThreshold >= 0
        ? (slideIndex <= maxThreshold
          ? slideIndex - minThreshold
          : maxThreshold - minThreshold)
        : 0,
    }));
  };

  private renderSliderControls = ({ url, rotateDegrees }: IThumbnail, id: number) => {
    const { slideIndex } = this.props;

    return (
      <Thumbnail
        index={id}
        key={url}
        handleSlideIndexChange={this.handleSlideIndexChange}
        currentIndex={slideIndex}
        path={url}
        rotateDegrees={rotateDegrees}
      />
    );

  };

  private handlePrevSlide = () => {
    const total = this.props.images.length;
    const nextIndex = getInfinitePrevIndex(this.props.slideIndex, this.props.images.length);
    const image = this.props.images[nextIndex];
    this.handleSlideIndexChange(nextIndex);
    if (this.props.eventViewPhotoScroll) {
      this.props.eventViewPhotoScroll({ dir: 'left', image, index: nextIndex, total });
    }
  };

  private handleNextSlide = () => {
    const total = this.props.images.length;
    const nextIndex = getInfiniteNextIndex(this.props.slideIndex, this.props.images.length);
    const image = this.props.images[nextIndex];
    this.handleSlideIndexChange(nextIndex);
    if (this.props.eventViewPhotoScroll) {
      this.props.eventViewPhotoScroll({ dir: 'right', image, index: nextIndex, total });
    }
  };

  private handleFirstFloorPlan = () => {
    const floorPlanImages = this.props.images.filter((img: IThumbnail) => img.isFloorPlan);
    const floorPlanFirstIndex = this.props.images.length - floorPlanImages.length;
    this.handleSlideIndexChange(floorPlanFirstIndex);
  };

  public render() {
    const { sliderControlsIndex } = this.state;
    const { mode, images, slideIndex, t, onCloseClick } = this.props;
    const { isFloorPlan, description } = get(images, [ slideIndex ], {
      isFloorPlan: false,
      description: null,
    });
    const floorPlanImages = images.filter((img: IThumbnail) => img.isFloorPlan);
    const currentFloorPlanIndex = slideIndex - images.length + floorPlanImages.length;

    return (
      <OverlayWrapper>
        <Block>
          <Container>
            <CloseIconStyle onClick={onCloseClick}>
              <CloseIcon width={24} height={24} />
            </CloseIconStyle>
            <GalleryWrapper>
              {mode === 'carousel' ? (
                <>
                  <ViewPager slideIndex={slideIndex} onSlideIndexChange={this.handleSlideIndexChange}>
                    {images.map(this.renderImage)}
                  </ViewPager>
                  <ControlsStyle>
                    <Pager index={slideIndex} onChangeIndex={this.handleSlideIndexChange} total={images.length} limit={5}/>
                  </ControlsStyle>
                </>
              ) : (
                <>
                  <SliderPager>
                    <ViewFader activeIndex={slideIndex}>
                      {images.map(this.renderImage)}
                    </ViewFader>
                    {images.length > 1 ? (
                      <>
                        <ControlsArrow dir="prev" onClick={this.handlePrevSlide} />
                        <ControlsArrow dir="next" onClick={this.handleNextSlide} />
                      </>
                    ) : null}
                  </SliderPager>
                  {this.props.showLegend ? (
                    <SliderControls>
                      <LegendWrapper>
                        <LegendTotalLabel>
                          {isFloorPlan ? t('unitPage.gallery.floorPlanCounterLabel', { count: currentFloorPlanIndex + 1, total: floorPlanImages.length })
                            : t('unitPage.gallery.imageCounterLabel', { count: slideIndex + 1, total: images.length })}
                          {description ? ` - ${description}` : null}
                        </LegendTotalLabel>
                        {!floorPlanImages.length || isFloorPlan ? null : <LegendFloorTotalWrapper>
                          <ArrowIconWrapper onClick={this.handleFirstFloorPlan}>
                            <ArrowIcon width={24} height={24}/>
                          </ArrowIconWrapper>
                          <LegendFloorTotalLabel>
                            {t('unitPage.gallery.floorPlanTotalLabel', { count: floorPlanImages.length })}
                          </LegendFloorTotalLabel>
                        </LegendFloorTotalWrapper> }
                      </LegendWrapper>
                      <ViewPager slideIndex={sliderControlsIndex} disableSwipe>
                        {images.map(this.renderSliderControls)}
                      </ViewPager>
                    </SliderControls>
                  ) : null}

                </>
              )}
            </GalleryWrapper>
          </Container>
        </Block>
      </OverlayWrapper>
    );
  }
}

export default PopupImageGalleryView;
