import { State } from 'store/state';
import { flow } from 'lodash';
import { createSelector } from 'reselect';
import { PoiId } from 'utils/entities';
import { routeSelector } from '../selectors/router';
import { Route } from 'config/routes';
import { savedDatedPoisSelector, lastViewedIdsSelector } from 'store/state/domainData/selectors';


export const searchContextSelector = (state: State) => (state.searchContext);

export const savedPoiIdsSelector = createSelector([
  savedDatedPoisSelector,
], (favorites) => new Set(favorites.map(({ poiId: id }) => id.id)));

export const viewedPoisSelector = createSelector([
  flow(searchContextSelector, (c) => c.viewedPois),
  lastViewedIdsSelector,
], (ctxLastViewed, profileLastViewed) => profileLastViewed.length ? profileLastViewed : ctxLastViewed);

export const visitedPoiIdsSelector = createSelector([
  viewedPoisSelector,
], (pois) => (
  new Set<PoiId>(pois.map((p) => p.poiId.id))
));

export const currentZoomSelector = flow(searchContextSelector, (ctx) => ctx.dataLayers.zoom);
export const lastSearchBboxSelector = flow(searchContextSelector, (ctx) => ctx.dataLayers.bbox);


const POIS_PAGES = new Set([ Route.UnitPage, Route.ProjectPage, Route.UnitPageCommercial, Route.ProjectPageCommercial ]);

export const activePoiIdsSelector = createSelector([
  flow(searchContextSelector, ({ activePoiIds }) => activePoiIds),
  routeSelector,
], (ids, route) => {
  const shouldReplaceActivePoi = POIS_PAGES.has(route.name) && ids.length === 0;

  return new Set(shouldReplaceActivePoi ? [ route.params.id ] : ids);

});

