import { MadadContactModalInfo } from 'components/madad/contact-modal/ContactModal';
import { getContext, select, take, takeEvery } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import { submitContactAgent } from 'store/state/app/actions';
import { madadContactModalStateSelector } from 'store/state/app/selectors';
import { SET_MADAD_CONTACT_MODAL_STATE, SUBMIT_USER_CONTACT_AGENT } from 'store/state/app/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from '../apiService/types';

const openMadadContactModalPattern = (action: RootAction) => (
  action.type === SET_MADAD_CONTACT_MODAL_STATE && Boolean(action.payload)
);

const contactSubmitMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.AgentOfficeContactPageSubmit
);

function* madadContactModalWorker() {
  const { sendEvent } = yield getContext('analytics');

  const info: MadadContactModalInfo = yield select(madadContactModalStateSelector);

  const { payload: submitContactPayload }: ReturnType<typeof submitContactAgent> = yield take(SUBMIT_USER_CONTACT_AGENT);

  const event = {
    source: info.eventSource || 'madad',
    method: 'phone',
    message: submitContactPayload.variables.message,
    request_type: submitContactPayload.requestType,
    agent_details: {
      id: info.isAgent ? info.id : undefined,
      phone_number: info.phone,
      name: info.agentName,
      office_id: info.officeId,
      office_name: info.officeName,
    },
    contact_details: {
      phone: submitContactPayload.variables.phone,
    },
  };

  sendEvent('user_contact_me_form_submit', 'user', { event });

  const action: SetMutationResponseAction<MutationType.AgentOfficeContactPageSubmit> = yield take(contactSubmitMutationPattern);

  const { response: { data } } = action.meta;
  const leadId = data && data.contactV4 && data.contactV4.lead && data.contactV4.lead.leadId;

  if (leadId) {
    sendEvent('user_contact_me_form_submit_success', 'user', {
      event: {
        ...event,
        lead_id: leadId,
      },
    });
  }
}

export function* madadContactWatcher() {
  yield takeEvery(openMadadContactModalPattern, madadContactModalWorker);
}
