import styled from '@emotion/styled';
import { LoginType } from 'utils/entities';
import { Button } from './index';

export interface SocialAuthButtonProps {
  loginType: LoginType;
}

export const SocialAuthButton = styled(Button)<SocialAuthButtonProps>`
  ${({ loginType }) => {
    switch (loginType) {
      case 'facebook': return `background: #3b5998`;
      case 'google': return `background: #e83a35`;
      default: return '';
    }
  }}
`;
