import React from 'react';
import { MapViewWrapper } from 'components/unit-page/gallery/styled';
import { MapSnapshot } from './MapSnapshot';
import { MapViewLabel, MapViewLabelText } from './styled';
import MapViewLabelBox from 'assets/svg/label.svg';
import { useLocale } from 'locale';

interface StaticMapProps {
  pinLabel?: React.ReactElement;
  lng: number;
  lat: number;
}


export const StaticMap: React.FC<StaticMapProps> = ({ lng, lat, pinLabel }) => {
  const { t } = useLocale();
  const label = pinLabel || (
    <MapViewLabel>
      <MapViewLabelBox/>
      <MapViewLabelText weight={'bold'}>{t('staticMap.hereLabel')}</MapViewLabelText>
    </MapViewLabel>
  );
  return (
    <MapViewWrapper>
      <MapSnapshot lng={lng} lat={lat} />
      {label}
    </MapViewWrapper>
  );
};
