import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { BreakpointValue } from 'consts/breakpoints';
import { SpacingScale } from 'ds/theme/spacing';
import { Global, css } from '@emotion/core';
import React from 'react';
import { REGULAR_ROOT_ID } from 'consts/rootNodes';
import { Theme } from 'ds';
import { SUBMENU_NAVIGATION_CLASS_NAMES } from 'components/navigation/subheader/SectionsNavigation';
import { SpacingBlock } from 'components/spacing-block';
import { ContentWidthMode } from 'utils/entities';

export const breakpointToNarrowContentWidth: { [key in BreakpointValue]: string } = {
  1: '100%',
  2: '100%',
  3: '544px',
  4: '624px',
  5: '756px',
};

export const breakpointToContentWidth: { [key in BreakpointValue]: string } = {
  1: '100%',
  2: '100%',
  3: '624px',
  4: '800px',
  5: '800px',
};

export const breakpointToWideContentWidth: { [key in BreakpointValue]: string } = {
  1: '100%',
  2: '100%',
  3: '544px',
  4: '935px',
  5: '935px',
};

export const breakpointToContentSlotPadding: { [key in BreakpointValue]: SpacingScale } = {
  1: 3,
  2: 6,
  3: 4,
  4: 4,
  5: 4,
};

// based on header height in mobile and tablet
export const FIXED_HEADER_HEIGHT_MOBILE_DEVICES = 190;
export const HEADER_HEIGHT = 57;
export const HEADER_SUBHEADER_HEIGHT = 120;

export const contentSlotVerticalPadding: SpacingScale = 3;

export const RootSlot = styled('main')`
  position: relative;
  background: ${({ theme }) => theme.colors.neutrals.white};
`;

export const ContentSlotSlider = styled.div<{ isSlided: boolean }>`
  ${({ isSlided }) => isSlided ? `transform: translateX(-100%);` : ''}
`;

export const ContentSlot = styled('div')<{ fullWidth?: boolean; contentWidthMode?: ContentWidthMode; isMapExpanded: boolean }>`
  ${({ theme, fullWidth }) => `
    position: relative;
    ${fullWidth ? '' : 'transition: width 0.3s;'}
    will-change: width;
    z-index: 1;
    background-color: ${theme.colors.neutrals.white};
  `}
  ${({ theme, fullWidth, contentWidthMode = ContentWidthMode.Default }) => {
    const contentBreakpoints = contentWidthMode === ContentWidthMode.Narrow
      ? breakpointToNarrowContentWidth
      : contentWidthMode === ContentWidthMode.Wide
        ? breakpointToWideContentWidth
        : breakpointToContentWidth;

    return Object.keys(contentBreakpoints).map(key =>
      theme.media.showAt({ at: +key as BreakpointValue })` width: ${fullWidth ? '100%' : contentBreakpoints[key]};`
    );
  }}

  ${({ isMapExpanded }) => isMapExpanded ? 'display: none;' : ''};

  ${({ theme }) => (
    theme.media.showAt({ from: 3 })`
      min-height: calc(100vh - ${HEADER_HEIGHT}px);
    `
  )}
`;

export const MapSlot = styled.div<{
  isVisible?: boolean;
  isSlidedIntoView?: boolean;
  isFull?: boolean;
  isSubheaderPresent: boolean;
}>`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  top: ${({ isSubheaderPresent }) => isSubheaderPresent ? HEADER_SUBHEADER_HEIGHT : HEADER_HEIGHT}px;
  background-image: ${
    ({ theme }) => `linear-gradient(to top, ${colorToAlpha(theme.colors.neutrals.white, 0.47)}, transparent);`
  };
  transform: ${({ isVisible }) => `translateY(${isVisible ? '0%' : '-200%'})`};
  transition: width 0.3s;
  will-change: width;

  ${({ theme, isFull, isSlidedIntoView }) => theme.media.showAt({ to: 2 })`
    left: 100%;
    width: 100%;
    transform: translateX(${isSlidedIntoView ? '-100%' : '0'});
    top: ${isFull ? 0 : `${HEADER_SUBHEADER_HEIGHT}px`};
    right: auto;
  `}
`;

export const HeaderWrapper = styled.div<{ isCheckMode?: boolean }>`
  z-index: 101;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutrals.white};
  position: sticky;
  top: 0;
  transition: transform 300ms;

  ${({ theme, isCheckMode }) => theme.media.showAt({ from: 1, to: 2 })`
    ${isCheckMode ? `
      .${SUBMENU_NAVIGATION_CLASS_NAMES.ROOT} {
        position: absolute;
        top: 100%;
        background: ${theme.colors.neutrals.white};
      }
    ` : ''}
  `}

`;

export const ContentControls = styled('div')`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const ContentHeading = styled('div')``;

export const SorterWrapper = styled('div')<{ noMargin?: boolean }>`
  button {
    padding: 0;
  }
  white-space: nowrap;
`;

export const WithContentSlotTopPadding = styled.div`
  padding-top: ${props => props.theme.spacing(contentSlotVerticalPadding)};
`;

export const FooterWrapper = styled.div`
  padding-bottom: ${props => props.theme.spacing(8)};
`;

export const WithContentSlotBottomPadding = styled.div`
  padding-bottom: ${props => props.theme.spacing(contentSlotVerticalPadding)};
`;

export const HRLine = styled(SpacingBlock) <{ useCurrentColor?: boolean}>`
  border-bottom: 1px solid ${props => props.useCurrentColor
    ? 'currentColor'
    : props.theme.colors.neutrals.grey8
  };
`;

export const AbsoluteFiller = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const WithContentSlotSidePaddingContainer = styled.div<{ noMobilePadding?: boolean, noPadding?: boolean }>`
  ${({ theme, noMobilePadding, noPadding }) => {
    if (noPadding) {
      return '';
    }
    const paddingBreakpoints = breakpointToContentSlotPadding;

    return Object.keys(paddingBreakpoints)
      .slice(noMobilePadding ? 1 : 0)
      .map(key => {
        const padding = paddingBreakpoints[key];
        return theme.media.showAt({ at: +key as BreakpointValue })`padding: 0 ${theme.spacing(padding)}; `;
      });
  }}
`;

/**
 * should be used to keep scrollbar in its place
 * on e.g. macos devices with hardware mouse
 * while page is empty (e.g. loading)
 * i call this component Mushonizer.
 */
export const ContentPlaceholder: React.FC = () => (
  <Global<Theme>
    styles={(theme) => css`
      ${theme.media.showAt({ from: 3 })`
        #${REGULAR_ROOT_ID} {
          min-height: calc(100vh + 1px);
        }
      `}
    `}
  />
);
