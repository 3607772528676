import styled from '@emotion/styled';
import { H3, H2, Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';


export const FadeWrapper = styled.div<{ isVisible: boolean, transitionTime?: number, deferTime?: number }>`
  ${({ isVisible, transitionTime = 200, deferTime = 0 }) => isVisible ? `
    opacity: 1;
    visibility: visible;
    transition: opacity ${transitionTime}ms ease ${deferTime}ms, visibility 0s ${deferTime}ms;
  ` : `
    opacity: 0;
    visibility: hidden;
    transition: opacity ${transitionTime}ms ease 0ms, visibility 0s ${transitionTime}ms, height 0s ${transitionTime}ms;
    height: 0;
    overflow: hidden;
  `}
`;

export const AuthenticationTypeSwitchWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.neutrals.grey2};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin-top: auto;
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const AuthenticationTypeSwitch = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.brand.main};
    cursor: pointer;
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(0.5)};
  `}
`;

export const FormControlsWrapper = styled.div<{ smallWidth?: boolean }>`
  margin-left: auto;
  margin-right: auto;

  ${({ theme, smallWidth }) => `
    margin-top: ${theme.spacing(3)};
    ${smallWidth && 'width: 150px;'};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const AuthenticationBase = styled.div`
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    max-width: 338px;
  `}
`;

export const AuthenticationRoot = styled(AuthenticationBase)`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    width: 320px;
    max-width:unset;
  `}
`;

export const SetPasswordRoot = styled(AuthenticationBase)``;

export const EnterPasswordFormRoot = styled(AuthenticationBase)``;

export const SetPasswordFormRoot = styled(AuthenticationBase)``;

const AuthenticationInfoDefault = styled(Text)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(0.5)};
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const AuthenticationInfo = styled(AuthenticationInfoDefault)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    padding: 0 ${theme.spacing(4)};
    white-space: pre-wrap;
    text-align: center;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: 0 ${theme.spacing(1.25)};
  `}
`;

export const SetPasswordInfo = styled(AuthenticationInfo)`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: 0;
  `}
`;

export const EnterPasswordInfo = styled(SetPasswordInfo)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(4)};
  `}
`;

export const AuthenticationForgotPasswordButton = styled.div`
  ${({ theme }) => `
    display: inline-block;
    margin-top: ${theme.spacing(4)};
    color: ${theme.colors.neutrals.grey2};
    cursor: pointer;
  `}
`;

export const AuthTrapWrapper = styled.div<{ vCentered: boolean; }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${({ theme, vCentered }) => `
    background: ${theme.colors.neutrals.white};
    padding: ${theme.spacing(7)} ${theme.spacing(3.5)} 0;
    ${vCentered ? 'justify-content: center;' : ''}
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShortlistPreStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => `
    margin-top: -136px;
    width: 100%;
    svg {
      margin-bottom: ${theme.spacing(4)};
    }
    h3 {
      margin-bottom: ${theme.spacing(1)};
    }
    button {
      margin-top: ${theme.spacing(4)};
    }
  `}
`;

export const AuthenticationInfoHeader = styled(H3)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
  `}
  & a {
    color: ${({ theme }) => theme.colors.brand.main};
    font-weight: 700;
    text-decoration: none;
  }
`;

export const Rule = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ConsentsInfoHeader = styled(H2)`
  &&& {
    text-align: center;
    white-space: break-spaces;
    font-size: ${typographyFontSizeCfg.h2.mobile}px;
    line-height: ${typographyLineHeightCfg.h2.mobile}px;
  }
`;

export const ConsentsLogoWrapper = styled(LogoWrapper)`
  margin-top: 0;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: 0 ${theme.spacing(3)} ${theme.spacing(4)};
  `}
`;
