import styled from '@emotion/styled';
import { Text, SmallText } from 'ds/components/typography';

export const LocalDataRoot = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: solid 1px ${({ theme }) => theme.colors.neutrals.grey8};
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  padding: ${({ theme }) => theme.spacing(1.5)};
  margin: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(6)} 0`};
`;

export const LocalDataLabel = styled(Text)<{alwaysDisplayed?: boolean}>`

  color: ${({ theme }) => theme.colors.neutrals.grey1};

  ${({ theme, alwaysDisplayed }) => theme.media.showAt({ to: 2 })`
     ${!alwaysDisplayed && 'display: none' }
  `}
`;

export const LocalDataInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
`;

export const LocalDataInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.neutrals.grey2};

  span {
    color: ${({ theme }) => theme.colors.neutrals.grey1};
    font-weight: bold;
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    > div div {
      margin: 0;
      width: 100%;
      margin-bottom: ${theme.spacing(0.5)};

      ::after {
        display: none;
      }
    }
  `}
`;

export const SchoolTypeWrapper = styled(SmallText)`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 100%;
  `}
`;
export const SchoolBinWrapper = styled(SmallText)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  `}

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    &:before {
      content: '';
      display: block;
      height: 14px;
      margin: 0 ${theme.spacing(1)};
      width: 1px;
      background: ${theme.colors.neutrals.grey6};
    }
  `}
`;

export const LocalDataImage = styled.div`
  margin-${({ theme }) => (theme.isRTL ? 'left' : 'right') + `:${theme.spacing(2)}`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 72px;
  width: 72px;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
`;

export const LocalDataPPS = styled(SmallText)`
`;

export const LocalDataTransactions = styled(SmallText)`
`;

export const Divider = styled.div`
  margin: 0 12px;
  width: 1px;
  height: 14px;
  background-color: ${({ theme }) => theme.colors.neutrals.grey6};
`;

export const LocalDataLink = styled.div`
  a {
    text-decoration: none;
  }
  div {
    font-weight: 500;
  }
`;

export const MultiLocalDataRoot = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(4)} 0 ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    margin: 0 -${theme.spacing(4)};
  `}
`;

export const MultiLocalDataLink = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.small};
    width: 160px;
  `}
`;

export const MultiLocalDataCarouselItem = styled.div`
  ${({ theme }) => `
    padding-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
  `}
`;

export const LocalDataCarouselLocation = styled(SmallText)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;
