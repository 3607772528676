import { put } from 'redux-saga/effects';
import { mutate } from 'store/state/mutationsResponse/actions';
import { MutationType } from 'store/sagas/apiService/types';
import { State as RouteState } from 'config/routes';

export function* unsubscribePageHandler(route: RouteState) {
  yield put(mutate({
    meta: {
      variables: {
        token: route.params.token,
        unsubscribe: true,
      },
    },
    mutationType: MutationType.SavedSearchUnsubscribe,
  }));
}
