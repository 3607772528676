import React, { useCallback, useState } from 'react';
import {
  CompletionTypeText,
  DropdownListWrapper,
  OpenSelectPlaceholderWrapper,
  SelectPlaceholderWrapper,
  SelectPlaceholderIcon,
} from 'components/check-address/styled';
import Select, { SelectItemProps } from 'ds/components/input/Select';
import { useLocale } from 'locale';
import { ItemIcon } from 'ds/components/input/Select/styled';
import { Text } from 'ds/components/typography';
import { CompletionType, DealType, MarketplaceType } from 'utils/entities';
import { NavigateToFn, Route } from 'config/routes';

const selectStyles = {
  marginTop: 58,
  paddingLeft: 36,
  right: -3,
};


interface DropdownListProps {
  isMobileOrTablet: boolean;
  isMobile: boolean;
  items: SelectItemProps[];
  navigate: NavigateToFn;
}

const DropdownList: React.FC<DropdownListProps> = ({ isMobileOrTablet, isMobile, items, navigate }) => {
  const { t } = useLocale();
  const [ isSelectOpen, setIsSelectOpen ] = useState(false);

  const renderItem = useCallback((item: SelectItemProps) => (
    <>
      {item.icon ? (
        <ItemIcon>{item.icon}</ItemIcon>
      ) : null}
        <Text>{item.label}</Text>
        <CompletionTypeText>{t('autocomplete.completionType', { type: CompletionType.EmploymentArea })}</CompletionTypeText>
    </>
  ), []);

  const handleItemClick = (docId: string) => {
    navigate(Route.EmploymentAreaPage, {
      id: docId,
      term: [ docId ],
      dealType: DealType.Buy,
      marketplace: MarketplaceType.Commercial,
    });
  };

  const placeholder = isMobile ? t('checkAddress.dropdown.placeholder.short') : t('checkAddress.dropdown.placeholder');

  return (
    <DropdownListWrapper isMobileOrTablet={isMobileOrTablet}>
      <Select
        selectionPromptText={!(isMobile && isSelectOpen) ?
          <SelectPlaceholderWrapper>
           <SelectPlaceholderIcon/>{placeholder}
          </SelectPlaceholderWrapper>
          :
          <OpenSelectPlaceholderWrapper>
            {t('checkAddress.dropdown.placeholder')}
          </OpenSelectPlaceholderWrapper>
        }
        size="large"
        onChange={(docId) => handleItemClick(docId)}
        onStateChange={(options, stateAndHelpers) => setIsSelectOpen(stateAndHelpers.isOpen)}
        items={items ? items : []}
        renderItem={renderItem}
        styles={selectStyles}
        fullScreenMobileMenu
      />
  </DropdownListWrapper>
  );
};

export default DropdownList;

