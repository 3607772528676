import React, { useCallback, useLayoutEffect } from 'react';

interface KeyPressContainerProps<T, P> {
  isOpen: boolean;
  items: T[];
  setHighlightedIndex: (index: number) => void;
  highlightedIndex: number;
  children: React.ReactElement;
  valueExtractor: (v: T) => P;
}

export const KeyPressContainer = <T extends unknown, P>(props: KeyPressContainerProps<T, P>) => {
  const { isOpen, valueExtractor, items, highlightedIndex, setHighlightedIndex, children } = props;
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (isOpen) {
      const keyDownValue = e.key.toString().toLowerCase();
      const indexValue = items.findIndex((item: T) => {
        const dropDownItem = valueExtractor(item).toString().trim().toLowerCase();
        const firstCharacter = dropDownItem.charAt(0).toLowerCase();
        return firstCharacter === keyDownValue;
      });

      if (indexValue >= 0 && indexValue !== highlightedIndex) {
        setHighlightedIndex(indexValue);
      }
    }
  }, [ isOpen, items ]);

  useLayoutEffect(() => {
    document.addEventListener('keypress', handleKeyPress, true);
    return () => document.removeEventListener('keypress', handleKeyPress, true);
  }, [ handleKeyPress ]);

  return children;
};
