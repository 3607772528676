import { MadadDto } from 'utils/entities';
import { TopTenBadgeVariant } from 'components/madad/top-ten-badge';
import { KeyboardNumberKeys } from 'utils/keyboardKeys';


export const mapToTopTenBadgeVariant: Record<MadadDto['category'], TopTenBadgeVariant> = {
  'top_10': 'regular',
  'top_10_gold': 'golden',
  'winner': null,
};

export function noopKeypress(event: React.KeyboardEvent<HTMLElement>) {
  const key = event.which || event.keyCode || 0;
  if (key === KeyboardNumberKeys.Enter || key === KeyboardNumberKeys.Space) {
    event.stopPropagation();
  }
}

export function noopClick(event: React.MouseEvent<HTMLElement>) {
  event.stopPropagation();
}
