import React from 'react';
import { useImageUrlBuilder, ImageOptions } from 'hooks/useImageUrlBuilder';


interface TransformedImageProps extends ImageOptions {
  path: string;
  alt?: string;
  style?: React.CSSProperties;
  dataAuto?: string;
  // I don't think it's convinient to use use React.HTMLImgElementProps or whatever here
}

export const TransformedImage: React.FC<TransformedImageProps> = React.memo((props) => {
  const { path, alt, style, children, ...transformOpts } = props;
  const imageUrlBuilder = useImageUrlBuilder();
  const imgSrc = imageUrlBuilder(path, transformOpts);
  const autoTag = props.dataAuto ? { 'data-auto': props.dataAuto } : null;

  return <img src={imgSrc} alt={alt} style={style} {...autoTag} />;
});
