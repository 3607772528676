import { RootAction } from 'store/state';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { select, getContext, call, takeLatest } from 'redux-saga/effects';
import { tabuByDocIdHasErrorsSelector, validTabuByDocIdSelector } from 'store/state/domainData/selectors/tabuByDocId';
import { Tabu } from 'utils/entities';
import { LoadType } from '../apiService/types';
import { tabuFoundPattern } from '../tabuWatcher';
import { activeInsightIdSelector } from 'store/state/insightsContext/selectors';
import { TabuSource } from 'components/tabu/Tabu';


enum TabuAvailabilityResult {
  Found = 'tabu_found',
  NotFound = 'tabu_not_found',
  Fail = 'search_fail',
}

const tabuAvailabilityClickResultPattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.Tabu
);

function* tabuFoundEvent() {
  const { sendEvent } = yield getContext('analytics');
  const insightId: string = yield select(activeInsightIdSelector);

  const source: TabuSource = insightId ? 'insight' : 'page';

  yield call(sendEvent, 'tabu_send_to_mail_click', 'tabu', {
    event: {
      source,
    },
  });
}

function* tabuCheckAvailabilityClickResultEvent() {
  const { sendEvent } = yield getContext('analytics');
  const tabu: Tabu = yield select(validTabuByDocIdSelector);
  const error: boolean = yield select(tabuByDocIdHasErrorsSelector);

  let status = '';

  if (error) {
    status = TabuAvailabilityResult.Fail;
  }
  else {
    status = tabu ? TabuAvailabilityResult.Found : TabuAvailabilityResult.NotFound;
  }

  const payload = {
    event: {
      status,
    },
  };
  yield call(sendEvent, 'tabu_check_availability_click_result', 'tabu', payload);
}

export function* tabuEventsWatcher() {
  yield takeLatest(tabuAvailabilityClickResultPattern, tabuCheckAvailabilityClickResultEvent);
  yield takeLatest(tabuFoundPattern, tabuFoundEvent);
}
