import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: inline;
  a {
    text-decoration: none;
  }
`;

export const IconWrapper = styled.div`
  svg {
    vertical-align: middle;
  }
`;
