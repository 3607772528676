import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import { LoadOptions, LoadType, UserTypeCommercial } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID } from 'store/state/app/types';
import { setManageListingsFilterAgentUserId } from 'store/state/app/actions';
import { userProfileSelector, isAgentSelector } from 'store/state/domainData/selectors';
import { User } from 'utils/entities';


function* loadDataWorker(userId: string) {
  const isAgentId = /^re_/.test(userId);
  const userIsAgent = yield select(isAgentSelector);
  const userType = isAgentId ? UserTypeCommercial.agent : UserTypeCommercial.user;

  try {
    const loadOpts: LoadOptions<LoadType.AdminSearch> = {
      loadType: LoadType.AdminSearch,
      meta: {
        variables: {
          includeResidential: userIsAgent ? false : !isAgentId,
          usersIds: [ userId ],
          timestamp: Date.now(),
          userType,
        },
      },
    };

    yield call(queryData, loadOpts);
  }
  catch (error) {
    const logger = yield getContext('logger');
    logger.error('User listings not resolved', error);
  }
}

function* changeFilterWorker(action: ReturnType<typeof setManageListingsFilterAgentUserId>) {
  const agentUserId = action.payload;

  yield call(loadDataWorker, agentUserId);
}

export function* manageBulletinsAdminHandler() {
  const user: User = yield select(userProfileSelector);
  yield call(loadDataWorker, user.uid);
  yield takeEvery(SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID, changeFilterWorker);
}
