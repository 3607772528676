import React from 'react';
import { GenericFilterProps, RangeTuple } from '../types';
import { Section, SectionBody, SectionTitle } from '../styled/common';
import { FloorRangeWrapper } from '../styled/floorRange';
import Range from 'ds/components/input/RangeField/RangeField';
import { useLocale } from 'locale';

const VISIBLE_ITEMS_COUNT = 5;

const FloorRange: React.FC<GenericFilterProps <'floorRange'>> = ({
  type,
  config,
  isInline,
  intermediateValue,
  setIntermediateValue,
}) => {
  const { t } = useLocale();

  const onChange = ([ from, to ]: RangeTuple<number>) => {
    setIntermediateValue([ from, to ]);
  };

  const { floorsOptions } = config;

  const options = floorsOptions.map((v) => ({
    value: v,
    label: t('filters.floorLabels', { floor: v }) || `${v}`,
  }));

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
        <SectionTitle isBold>
          {t('filters.floor.label')}
        </SectionTitle >
        <SectionBody data-auto="filterCheckboxes">
          <Range
            onChange={onChange}
            values={intermediateValue}
            options={options}
            fullWidth
            expandOptionsPosition="top"
            visibleItemsCount={VISIBLE_ITEMS_COUNT}
            labelTo={t('filters.floor.to')}
            labelFrom={t('filters.floor.from')}
            isMaxValueInfinite
          />
        </SectionBody>
      </Section>
    );
  }

  return (
    <FloorRangeWrapper>
      <Section data-section-filter-name={type} data-auto-filter-name={type}>
        <SectionTitle>{t('filters.floor')}</SectionTitle>
        <SectionBody>
          <Range
            onChange={onChange}
            values={intermediateValue}
            options={options}
            expandOptionsPosition="top"
            isMaxValueInfinite
          />
        </SectionBody>
      </Section>
    </FloorRangeWrapper>
  );
};

export default React.memo(FloorRange);
