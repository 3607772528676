import styled from '@emotion/styled';
import { H3, SmallText, Text } from 'ds/components/typography';
import { LinkStyled } from 'ds/components/link/styled';
import { BaseRoot } from '../styled';


export const Root = styled(BaseRoot)``;

export const IframeContainer = styled.iframe<{ sideExpand: boolean }>`
  ${({ theme, sideExpand }) => `
    flex-grow: 1;
    height: 356px;
    margin: ${sideExpand ? `${theme.spacing(3)} -15px 0` : `${theme.spacing(3)} 0 0`}
  `}
`;

export const IframeWrapper = styled.div`
  display: flex;
`;

export const Header = styled(H3)`
  ${({ theme }) => `
    text-align: center;
    margin-bottom: ${theme.spacing(3)};
  `}
`;

export const Footer = styled(SmallText)`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.neutrals.grey3};
    span {
      margin-left: ${theme.spacing(.5)};
    }
  `}
`;

export const FooterLink = styled(LinkStyled)`
  display: inline;
`;

export const SimpleText = styled(Text)`
  text-align: center;
  white-space: pre-wrap;
`;
