import React from 'react';

import {
  InputFieldWrapper,
  AssistiveText,
  ErrorMessage,
  IconWrapper,
  InputInnerWrapper,
  PriceFilterLabelText,
} from 'ds/components/input/styled';

import { Input, InputProps } from './Input';
import { Textarea } from './Textarea';

type CommonType = InputProps<HTMLInputElement | HTMLTextAreaElement>;

export type TextFieldProps = CommonType & {
  label?: string;
  hintText?: string;
  errorMessage?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  icon?: React.ReactNode;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  charactersCounterMax?: number;
};

export class TextField extends React.Component<TextFieldProps, never> {
  public static defaultProps: Partial<TextFieldProps> = {
    type: 'text',
    hasError: false,
    disabled: false,
    placeholder: '',
    label: '',
    fullWidth: false,
    multiline: false,
    rows: 3,
  };

  public render () {
    const { label, hasError, placeholder, disabled, hintText, errorMessage, type, fullWidth, multiline, rows, icon, inputRef, charactersCounterMax, ...rest } = this.props;
    const showIcon = !multiline && icon;
    return (
      <InputFieldWrapper data-auto="textfield-wrapper" fullWidth={fullWidth}>
        <label data-auto="textfield-label">
          {label ? <PriceFilterLabelText data-auto="textfield-label-text" disabled={disabled}>{label}</PriceFilterLabelText> : null}
          <InputInnerWrapper data-auto="textfield-input-wrapper" disabled={disabled} withIcon={!!icon}>
            {showIcon ? <IconWrapper className="textfield-icon" data-auto="textfield-icon">{icon}</IconWrapper> : null}
            {multiline
              ? (
                <Textarea
                  innerRef={inputRef}
                  data-auto="textfield-text-area"
                  className="textarea-input"
                  rows={rows}
                  hasError={hasError}
                  disabled={disabled}
                  placeholder={placeholder}
                  {...(rest as InputProps<HTMLTextAreaElement>)}
                />
              ) : (
                <Input
                  innerRef={inputRef}
                  data-auto="textfield-input"
                  className="textfield-input"
                  autoComplete="off"
                  type={type}
                  hasError={hasError}
                  disabled={disabled}
                  placeholder={placeholder}
                  {...(rest as InputProps<HTMLInputElement>)}
                />
              )
            }
          </InputInnerWrapper>
        </label>
        {charactersCounterMax ? <AssistiveText data-auto="textfield-assistive-text">{rest.value ? rest.value.toString().length : 0}/{charactersCounterMax}</AssistiveText> : null}
        {hasError && errorMessage ? <ErrorMessage className="textfield-error-msg" data-auto="textfield-error-msg">{errorMessage}</ErrorMessage> : null}
        {!hasError && hintText ? <AssistiveText data-auto="textfield-assistive-text">{hintText}</AssistiveText> : null}
      </InputFieldWrapper>
    );
  }
}
