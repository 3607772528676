import React from 'react';
import ClockIcon from 'assets/svg/clock.svg';
import CheckCircleIcon from 'assets/svg/check-circle.svg';
import WarningCircleIcon from 'assets/svg/warning-circle.svg';
import { useLocale } from 'locale';
import { DealAffiliationStatus } from 'utils/entities';
import * as Styled from './styled';


interface StatusLabelProps {
  status: DealAffiliationStatus;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  const { t } = useLocale();

  let icon;
  switch (status) {
    case DealAffiliationStatus.Pending:
      icon = <ClockIcon />;
      break;
    case DealAffiliationStatus.Approved:
      icon = <CheckCircleIcon />;
      break;
    case DealAffiliationStatus.Rejected:
      icon = <WarningCircleIcon />;
      break;
    default:
      const exhaustiveCheck: never = status;
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
  }

  return (
    <Styled.Label status={status} data-auto="deal-status">
      {icon} {t('dealAffiliationPopup.statusLabel', { status })}
    </Styled.Label>
  );
};

export default StatusLabel;
