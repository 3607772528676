import styled from '@emotion/styled';
import { HEADER_HEIGHT } from './magicNumbers';


export const XYDrawerWrapper = styled.div`
  will-change: transform;
  top: 0;
  left: 0;
  right: 0;
`;

export const XYDrawerFakeBG = styled.div`
  position: absolute;
  top: 100%;
  height: 1000px;
  left: 0;
  right: 0;
  content: '';
  background: ${({ theme }) => theme.colors.neutrals.grey10};
  z-index: 1;
`;

export const XYDrawerCardWrapper = styled.div`
  width: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  max-width: 400px;
  & > div {
    padding: 16px 6px;
    display: flex;
    flex-grow: 1;
  }
`;

export const XYDrawerListWrapper = styled.div`
  min-width: 0;
  & .view-pager-rail {
    padding: 0 28px;
    transform: translateZ(0);
  }
`;

export const XYDrawerBG = styled.div<{ isExpanded: boolean }>`
  border-radius: ${({ theme }) => `${theme.borderRadius.extraLarge} ${theme.borderRadius.extraLarge} 0 0`};
  background-image: linear-gradient(to bottom, ${({ theme }) => `${theme.colors.neutrals.white}, ${theme.colors.neutrals.grey10}`});
  box-shadow: ${({ theme, isExpanded }) => isExpanded ? theme.shadow.level7 : theme.shadow.level5};
  transform: translateZ(0);
`;

export const XYDrawerTabsWrapper = styled.div<{ isStuck: boolean }>`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: inset ${({ theme }) => theme.shadow.negative};
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  ${({ isStuck, theme }) => isStuck ? `
    position: fixed;
    top: ${HEADER_HEIGHT}px;
    box-shadow: ${theme.shadow.level2};
    left: 0;
    right: 0;
  ` : ''}
`;

export const XYDrawerTabContainer = styled.div`
  flex-shrink: 0;
  margin: 0 14px;
`;

export const XYDrawerTabsPadder = styled.div`
  padding: 0 18px;
  display: flex;
  min-width: 0;
  flex-shrink: 0;
`;

export const XYDrawerTabsContainer = styled.div`
  text-transform: uppercase;
  display: flex;
`;

export const XYDrawerBarHandle = styled.div<{ down?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 9px;
  /* tapable area */
  width: 96px;
  direction: ltr;
  margin: 0 auto;
  &:before, &:after {
    content: '';
    width: 17px;
    margin: 0 -1px;
    height: 6px;
    transition: 400ms ease transform;
    background-color: ${({ theme }) => theme.colors.neutrals.grey7};
    border-radius: ${({ theme }) => theme.borderRadius.tiny};
  }
  &:before {
    border-radius: ${({ theme }) => `${theme.borderRadius.tiny} 0 0 ${theme.borderRadius.tiny}`};
  }
  &:after {
    border-radius: ${({ theme }) => `0 ${theme.borderRadius.tiny} ${theme.borderRadius.tiny} 0`};
  }
  ${({ down }) => down ? `
    &:before {
      transform: rotateZ(15deg);
    }
    &:after {
      transform: rotateZ(-15deg);
    }
  ` : ''}
`;
