import { isServer } from 'utils';

type RequestIdleCallbackCallback = (cb: { didTimeout: boolean, timeRemaining: () => number }) => void;

function requestIdleCallbackPonyfill(cb: RequestIdleCallbackCallback) {
  const start = Date.now();
  return setTimeout(() => {
    cb({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
}

function cancelIdleCallbackPonyfill(id: number) {
  clearTimeout(id);
}

type RIC = typeof requestIdleCallbackPonyfill;
type CIC = typeof cancelIdleCallbackPonyfill;

const requestIdleCallbackImpl: RIC = !isServer() && (window as any).requestIdleCallback
  ? (window as any).requestIdleCallback
  : requestIdleCallbackPonyfill;

const cancelIdleCallbackImpl: CIC = !isServer() && (window as any).cancelIdleCallback
  ? (window as any).cancelIdleCallback
  : cancelIdleCallbackPonyfill;

const ricPromise = () => new Promise((r) => requestIdleCallbackImpl(r));


export {
  requestIdleCallbackImpl as requestIdleCallback,
  cancelIdleCallbackImpl as cancelIdleCallback,
  ricPromise,
};
