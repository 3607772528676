import { InsightType, InsightDescriptor } from 'utils/entities';
import { InsightSummary } from 'store/state/selectors/insights/summaryTypes';

export enum HighlightGroup {
  GoodToKnow = 'goodToKnow',
  ThingsYouWouldLove = 'thingsYouWouldLove',
  ThingsYouShouldKnow = 'thingsYouShouldKnow',
  Schools = 'schools',
  Prices = 'prices',
  PricesCarousel = 'pricesCarousel',
  SchoolsCarousel = 'schoolsCarousel',
  LivabilityCarousel = 'livabilityCarousel',
  SafetyCarousel = 'safetyCarousel',
  Neighbourhood = 'neighbourhood',
  BuildingPermit = 'buildingPermit',
}

export interface IHighlight {
  id: string;
  type: InsightType;
  subType: InsightDescriptor;
  title: string;
  text: InsightSummary;
  previewText: string;
  group: HighlightGroup;
  insightRecordId: string;
}

export type SourceType = 'address' | 'bulletin' | 'project' | 'local' | 'tabu';

export interface WithInsightType {
  insightType: InsightType;
}
