import styled from '@emotion/styled';
import { BaseFlexColumnWrapper } from '../../styled';
import { H3, SmallText, Text } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const UploadImageStepWrapper = styled(BaseFlexColumnWrapper)``;

export const RecommendedImages = styled.div`
  display: flex;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-wrap: wrap;
  `}
`;

export const PreviewHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing(3)};
  color: ${props => props.theme.colors.neutrals.grey1};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(2)};
  `}

  div {
    color: ${props => props.theme.colors.brand.main};
    &:first-of-type {
      color: ${props => props.theme.colors.neutrals.grey1};
    }
  }
`;

export const PreviewHeaderCounter = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
  `}
`;

export const EditModeToggle = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.brand.main};
    text-decoration: underline;
  `}
`;

export const CategoryWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: ${({ theme }) => theme.spacing(2)};
  color: ${props => props.theme.colors.neutrals.grey2};

  div {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(1.25)};
  `}
`;

export const UploadBulletinWrapper = styled.div<{ editingMode: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  .main-bulletin-wrapper {
    img {
      border-radius: ${({ theme }) => theme.borderRadius.small};
    }

    button {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    & + div .dropzone-container {
      margin-top: ${({ theme }) => theme.spacing(5)};

      ${({ theme }) => theme.media.showAt({ at: 1 })`
        margin-top: ${theme.spacing(6)};
      `}

      .dropzone-placeholders-wrapper {
        display: none;
      }

      .dropzone {
        border: none;
        min-height: 0;

        & > .dropzone-info {
          display: none;
        }

        & > svg {
          display: none;
        }

        .dropzone-preview-file-placeholder {
          display: flex;
        }
      }
    }
  }

  .dropzone-container {
    height: auto;

    &:focus {
      ouline: none;
    }

    .dropzone-placeholders-wrapper {
      .dropzone-placeholder {
        width: 196px;
        height: 86px;
        background-color: rgba(225, 247, 247, 0.4);

        ${({ theme }) => theme.media.showAt({ at: 1 })`
          width: 140px;

          &:nth-of-type(5), &:nth-of-type(6) {
            display: none;
          }
        `}
      }
    }
  }

  .dropzone {
    border: 1px dashed ${props => props.theme.colors.brand.lighter};
    width: 100%;
    min-height: 220px;
    height: auto;
    border-radius: ${({ theme }) => theme.borderRadius.tiny};

    &:focus {
      outline: none;
    }

    & > .dropzone-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: ${props => props.theme.colors.brand.lighter};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      div {
        position: absolute;
        bottom: -32px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);

      ${({ theme }) => theme.media.showAt({ at: 1 })`
        display: none;
      `}
      }
    }

    & > svg {
      position: absolute;
      fill: ${props => props.theme.colors.alert.red};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .dropzone-preview-files-wrapper {
      border-radius: ${({ theme }) => theme.borderRadius.micro};
      width: 205px;
      height: 165px;
      margin-bottom: ${({ theme }) => theme.spacing(1.25)};

      ${({ theme }) => theme.media.showAt({ at: 1 })`
        width: 156px;
        height: 105px;
      `}
    }

    .dropzone-preview-files-container:focus {
      outline: none;
    }

    .dropzone-preview-file-placeholder {
      display: none;
      width: 205px;
      height: 165px;
      border: 1px dashed ${props => props.theme.colors.brand.lighter};
      border-radius: ${({ theme }) => theme.borderRadius.small};

      ${({ theme }) => theme.media.showAt({ at: 1 })`
        width: 156px;
        height: 105px;
      `}
    }

    .dropzone-preview-file {
      border-radius: ${({ theme }) => theme.borderRadius.small};

      button {
        position: absolute;
        padding: 0;
        height: 32px;
        width: 32px;
        background-color: ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.4)};
        border: none;
        top: 5%;
        left: 5%;

        ${({ theme, editingMode }) => theme.media.showAt({ at: 1 })`
          ${editingMode ? '' : 'display: none;'}
        `}

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
`;

export const ChangeMainBulletinButton = styled.div`
  color: ${props => props.theme.colors.brand.main};
  text-decoration: underline;
  line-height: 24px;
  cursor: pointer;
`;

export const MainBulletinControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const MainBulletinHeaderWrapper = styled(BaseFlexColumnWrapper)`
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 15px;

    ${({ theme }) => theme.media.showAt({ at: 1 })`
      font-size: 13px;
    `}
  }
`;

export const PreviewFileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 48%;
  `};
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    width: 32%;
  `};
  height: 100px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PreviewFile = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    min-width: 0;
    overflow: hidden;
    > button {
      position: absolute;
      left: 6px;
      top: 6px;
      background-color: ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.4)};
      border: none;
    }
`;

export const MainBulletinWrapper = styled.div`
  position: relative;
  display: block;
  height: 220px;

  button {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.4)};
    border: none;
    padding: 0;

    ${({ theme }) => theme.media.showAt({ at: 1 })`
      display: none;
    `}
  }
`;

export const PreviewImagePlaceholder = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.brand.main};

  & > svg {
    fill: ${props => props.theme.colors.alert.red}
  }
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 48%;
  `};
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    width: 32%;
  `};
  height: 100px;
  border: 1px dashed ${({ theme }) => theme.colors.brand.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  position: relative;
`;

export const PreviewImagePlaceholderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  path {
    fill: ${({ theme }) => theme.colors.brand.main}};
  }
`;

export const BulletinImagePreview = styled.img`
  position: relative;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

export const SubHeader = styled(H3)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const UploadLimitText = styled(SmallText)<{ hasError: boolean; }>`
  ${({ theme, hasError }) => `
    margin-top: ${theme.spacing(1)};
    color: ${hasError ? theme.colors.alert.red : theme.colors.brand.main};
  `}
`;
