import React from 'react';
import {
  AddressSuggestion,
  AddressSuggestionText,
  CompletionTypeText,
  IconSuggestion,
} from './styled';

interface SuggestionItemProps {
  isActive: boolean;
  icon: React.ReactNode;
  downshiftItemProps: any; // any from downshift.d.ts
  secondaryLabel: React.ReactNode;
  label: React.ReactNode;
}

export const SuggestionItem: React.FC<SuggestionItemProps> = (props) => {

  const { isActive, icon, label, downshiftItemProps, secondaryLabel } = props;

  return (
    <AddressSuggestion
      data-auto="autocomplete-suggestion"
      className="autocomplete-suggestion"
      {...downshiftItemProps}
      isActive={isActive}
    >
      <IconSuggestion>{icon}</IconSuggestion>
      <AddressSuggestionText data-auto="suggetion-text">{label}</AddressSuggestionText>
      {secondaryLabel ? (
        <CompletionTypeText data-auto="suggestion-type">
          {secondaryLabel}
        </CompletionTypeText>
      ) : null}
    </AddressSuggestion>
  );
};
