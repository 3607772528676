import React, { useState } from 'react';
import styled from '@emotion/styled';
import Downshift, { StateChangeOptions } from 'downshift';
import { CleanButton, ControlButton } from 'ds/components/button';
import { IntersectionSpy } from 'components/intersection-spy';
import { WithLocaleProps, withLocale } from 'locale';
import withEvents from 'analytics/withEvents';

import {
  FilterButtonsContainer,
  FilterInnerContainer,
  FilterPopupContainer,
  FilterPopupTitle,
  OpenSection,
  ClickSection,
} from '../styled/dropdown';
import { makeCallAll, convertToEventType } from '../utils';
import { IFiltersState } from '../types';
import { SmallText } from 'ds/components/typography';
import Tooltip from 'ds/components/tooltip';
import { getQAAutomationParams } from 'utils/qa';


type Callback = () => void;

interface FilterSectionProps {
  label: string;
  title?: string;
  alignment?: string;
  icon?: JSX.Element;
  onClear: Callback;
  onSubmit: Callback;
  isActive: boolean;
  type: keyof IFiltersState | 'moreFilters' | 'sizeFilter';
  automationTag?: string;
  isClearButtonDisabled?: boolean;
  onFilterClick?: (label: string, type: string) => void;
  withBottomSpacing?: boolean;
  withPriceTooltip?: boolean;
  ignoreWrapperPadding?: boolean;
}

export const Section = styled.div`
  position: relative;
  display: inline-flex;
  margin-${props => props.theme.isRTL ? 'left' : 'right'}: 8px;
`;


const FilterSection: React.FunctionComponent<FilterSectionProps & WithLocaleProps> = (props) => {

  const {
    t,
    icon,
    isActive,
    children,
    label,
    title,
    onSubmit,
    onClear,
    isClearButtonDisabled,
    alignment,
    type,
    onFilterClick,
    withBottomSpacing,
    automationTag = type,
    withPriceTooltip,
    ignoreWrapperPadding = false,
  } = props;

  const { popups } = getQAAutomationParams();

  const [ hasBottomScrollShadow, setBottomScrollShadow ] = useState(false);

  const handleBottomIntersectionSpy = (entry: IntersectionObserverEntry) => {
    setBottomScrollShadow(!entry.isIntersecting);
  };

  const clickFilterEvent = ({ isOpen }: StateChangeOptions<any>) => {
    if (isOpen) onFilterClick(label, type);
  };

  return (
    <Section data-button-filter-name={automationTag} data-auto={`filter-${automationTag}`}>
      <Downshift
        onOuterClick={onSubmit}
        onStateChange={clickFilterEvent}
      >
        {({ getLabelProps, isOpen, toggleMenu, closeMenu }) => (
          <div>
            <ClickSection>
              {withPriceTooltip && popups !== 'disable' ? (
                <Tooltip
                  size="medium"
                  placement="bottom-start"
                  clickable
                  wrapperStyle={{ visibility: 'visible' }}
                  tooltip={<SmallText>{t('tooltip.priceChanges')}</SmallText>}
                >
                  <ControlButton {...getLabelProps()} mode={isActive ? 'selected' : 'filter'} onClick={toggleMenu}>
                    {icon}
                    <span data-auto="filterName">{label}</span>
                  </ControlButton>
                </Tooltip>
              ) : (
                <ControlButton {...getLabelProps()} mode={isActive ? 'selected' : 'filter'} onClick={toggleMenu}>
                  {icon}
                  <span data-auto="filterName">{label}</span>
                </ControlButton>
              )}
            </ClickSection>
            {isOpen ? (
              <OpenSection alignment={alignment}>
                <FilterPopupContainer data-auto="filters-container">
                  <FilterInnerContainer
                    withOutPadding={ignoreWrapperPadding}
                    id={withBottomSpacing && 'filters-inner-spacing'} // we need id to override cascade styles
                    data-auto={(automationTag === 'propertyTypes' || automationTag === 'amenities' || automationTag === 'conditions' || automationTag === 'seller') ? 'filterCheckboxes' : 'sliderButtonFilter'}
                  >
                    {title ? <FilterPopupTitle weight="bold" data-auto="filterPopupTitle" data-auto-filter-title={title}>{title}</FilterPopupTitle> : null}
                    {children}
                    <IntersectionSpy onIntersection={handleBottomIntersectionSpy} />
                  </FilterInnerContainer>
                  <FilterButtonsContainer hasScrollShadow={hasBottomScrollShadow}>
                    <CleanButton
                      mode="secondary"
                      data-auto="filterClearButton"
                      onClick={makeCallAll(closeMenu, onClear)}
                      disabled={isClearButtonDisabled}
                    >
                      {t('filters.clear')}
                    </CleanButton>
                    <CleanButton
                      data-auto="filterSubmitButton"
                      onClick={makeCallAll(closeMenu, onSubmit)}
                    >
                      {t('filters.apply')}
                    </CleanButton>
                  </FilterButtonsContainer>
                </FilterPopupContainer>
              </OpenSection>
            ) : null}
          </div>
        )}
      </Downshift>
    </Section>
  );
};

export default withEvents<FilterSectionProps>((sendEvent, props) => ({
  onFilterClick(label, type) {
    sendEvent('search_filter_button_click', 'search', {
      event: {
        button_clicked: label,
        filter_category: convertToEventType(type),
      },
    });
  },
  onClear() {
    if (props.isActive) {
      sendEvent('search_filter_clear', 'search', {
        event: {
          filter_category: props.type,
        },
      });
    }
  },
}))(withLocale(FilterSection));
