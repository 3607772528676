import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { IFavoritesToggle, IFavoritesToggleMeta } from 'store/sagas/apiService/types';
import { toggleFavorites } from 'store/state/app/actions';
import { State } from 'store/state';
import { savedPoiIdsSelector } from 'store/state/searchContext/selectors';
import { SmallText, Text } from 'ds/components/typography';
import Tooltip from 'ds/components/tooltip';
import { PoiId, PoiType } from 'utils/entities';
import { ControlButton } from 'ds/components/button';
import { DefaultComponent, IconWrapper } from './styled';
import AnimatedHeartIcon from 'components/favorites/animated-heart-icon/AnimatedHeartIcon';
import { Mode } from 'ds/components/button/ControlButton';

interface FavoritesTogglerProps {
  onToggleFavorites: (variables: IFavoritesToggle, meta: IFavoritesToggleMeta) => void;
  isFavorite: boolean;
}

export enum FavouritesIconMode {
  Default = 'default',
  White = 'white',
}

interface FavoritesTogglerOwnProps {
  id: PoiId;
  type: PoiType;
  tooltip?: boolean;
  noText?: boolean;
  eventSource?: string;
  withButton?: boolean;
  isRound?: boolean;
  iconMode?: FavouritesIconMode;
  controlButtonMode?: Mode;
}

const FavoritesToggler: React.FC<FavoritesTogglerProps & FavoritesTogglerOwnProps> = ({
  onToggleFavorites,
  isFavorite,
  id,
  type,
  tooltip = true,
  noText,
  withButton,
  isRound,
  eventSource,
  iconMode = FavouritesIconMode.Default,
  controlButtonMode,
}) => {
  const { t } = useLocale();

  const toggleFavoritesHandler = useCallback(() => {
    onToggleFavorites({
      favorites: [ { id, type } ],
      isFavorite,
    }, { source: eventSource });
  }, [ onToggleFavorites, isFavorite ]);

  const Component = withButton ? ControlButton : DefaultComponent;
  const componentProps = withButton ? { size: 'large', fontType: 'important', isInitialIconColor: true, isRound, mode: controlButtonMode } : {};

  let iconSize;
  if (isRound) {
    iconSize = 26;
  }
  else if (noText) {
    iconSize = 24;
  }
  else {
    iconSize = 22;
  }

  return tooltip ? (
    <Component
      {...componentProps}
      data-auto="bulletin-favorite-button"
      data-auto-active={isFavorite}
      onClick={toggleFavoritesHandler}
    >
      <Tooltip
        size="small"
        placement="bottom"
        tooltip={<SmallText>{t('tooltip.shortList')}</SmallText>}
        hideArrow
      >
        <IconWrapper mode={iconMode} isFavorite={isFavorite}>
          <AnimatedHeartIcon size={iconSize} isFilled={isFavorite} />
        </IconWrapper>
      </Tooltip>
    </Component>
  ) : (
    <Component
      {...componentProps}
      data-auto="bulletin-favorite-button"
      data-auto-active={isFavorite}
      onClick={toggleFavoritesHandler}
    >
      <AnimatedHeartIcon isTransparent={controlButtonMode === 'transparent'} size={iconSize} isFilled={isFavorite} />
      {noText ? null : <Text weight="medium">{t('tooltip.save')}</Text>}
    </Component>
  );
};

const mapDispatchToProps = {
  onToggleFavorites: toggleFavorites,
};

const mapStateToProps = (state: State, props: FavoritesTogglerOwnProps) => ({
  isFavorite: savedPoiIdsSelector(state).has(props.id),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesToggler);
