import { createSelector } from 'reselect';

import { contactPoisByIdsHeapSelector } from 'store/state/selectors/poisHeap';
import { contactAgentSuccessPoiIdSelector, contactModalPoiIdSelector } from 'store/state/app/selectors';
import { lastContactedIdsSelector } from 'store/state/domainData/selectors';


export const poiByPoiIdSelector = createSelector([
  contactPoisByIdsHeapSelector,
  contactModalPoiIdSelector,
], (ContactPoisDict, contactModalPoiId) => ContactPoisDict[contactModalPoiId]);


export const poiFromContactAgentSuccessSelector = createSelector([
  contactPoisByIdsHeapSelector,
  contactAgentSuccessPoiIdSelector,
], (ContactPoisDict, poiId) => ContactPoisDict[poiId]);

export const lastContactedByPoiIdSelector = createSelector([
  lastContactedIdsSelector,
  contactModalPoiIdSelector,
], (lastContactedPoiIds, contactModalPoiId) => lastContactedPoiIds.find((item) => item.poiId.id === contactModalPoiId));
