import { IAutocompleteEntry, CompletionType } from './entities';

export const ellipsis = (text: string, maxLength: number): string => text.length <= maxLength ? text : `${text.substr(0, maxLength - 4)}...`;

export const getEntryName = (entry: IAutocompleteEntry) => {
  switch (entry.type) {
    case CompletionType.Borough:
      return entry.borough;
    case CompletionType.City:
      return entry.city;
    case CompletionType.Street:
      return entry.street;
    case CompletionType.Neighbourhood:
      return entry.neighbourhood;
    case CompletionType.SchoolDoc:
      return entry.schoolName;
    default:
      return entry.name;
  }
};
