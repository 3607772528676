import React from 'react';
import styled from '@emotion/styled';
import { useTransition, animated, config } from 'react-spring';

export const TabsWrapper = styled.div`

`;

export const TabsHeader = styled.div`
  display: inline-flex;
  align-items: center;
  & > div {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 32px;
    &:last-of-type {
      margin: 0;
    }
  }
`;

export const TabsBody = styled.div`
  padding-top: 32px;
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    padding-top: 16px;
  `}
`;

interface TabsProps {
  headers: JSX.Element[];
  bodies: JSX.Element[];
  headerWrapper?: React.FunctionComponent;
  bodyWrapper?: React.FunctionComponent;
  activeTab: number;
  setActiveTab: (index: number) => void;
}

export const Tabs: React.FunctionComponent<TabsProps> = (props) => {
  const { headers, bodies, headerWrapper, bodyWrapper, activeTab, setActiveTab } = props;
  const Header = headerWrapper || TabsHeader;
  const Body = bodyWrapper || TabsBody;


  const transitions = useTransition(activeTab, i => i, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { display: 'none', opacity: 0 },
    config: config.default,
    initial: { opacity: 1 },
  });

  return (
    <TabsWrapper>
      <Header>
        {headers.map((tabHeader, id) => (
          <div onClick={() => setActiveTab(id)} key={id}>
            {tabHeader}
          </div>
        ))}
      </Header>
      <Body>
        {transitions.map(({ item, key, props: style }) => (
          <animated.div style={style} key={key}>
            {bodies[item]}
          </animated.div>
        ))}
      </Body>
    </TabsWrapper>
  );
};
