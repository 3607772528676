import React from 'react';
import { useLocale } from 'locale';
import { shareHandler, ShareType } from 'helpers/share-helpers';
import withEvents from 'analytics/withEvents';
import ShareModal, { ShareModalType } from 'components/share-modal';
import { ShareActions } from 'components/share-actions';
import GratitudeIcon from 'assets/svg/feedback-ugc.svg';
import { mapShareTypeToUgcShareEventMethod } from '../utils';


type UsedShareTypes = Exclude<ShareType, ShareType.Email | ShareType.CopyLink>;

const SHARE_TYPES: UsedShareTypes[] = [ ShareType.Twitter, ShareType.Facebook, ShareType.FacebookMessenger ];

interface UgcGratitudeModalProps {
  notify?: (type: ShareType) => void;
}

const UgcGratitudeModal: React.FC<UgcGratitudeModalProps> = (props) => {
  const { notify } = props;
  const { t } = useLocale();

  const modalType = ShareModalType.UgcGratitudeModal;
  const header = t('shareModal.header', { type: modalType });
  const description = t('ugc.gratitudeModal.description');
  const text = t('ugc.gratitudeModal.share.text');

  return (
    <ShareModal
      mainIcon={<GratitudeIcon />}
      header={header}
      description={description}
      name={modalType}
      getBaseLink={() => `${window.location.origin}/explore?ugc_invite=true`}
      renderActions={url => (
        <ShareActions
          types={SHARE_TYPES}
          onShare={(type: ShareType) => {
            shareHandler({ type, url, text });
            notify(type);
          }}
        />
      )}
    />
  );
};

export default withEvents(sendEvent => ({
  notify(type: UsedShareTypes) {
    const payload = { event: { method: mapShareTypeToUgcShareEventMethod[type], source: 'after_submit' } };
    sendEvent('ugc_review_share_click', 'ugc', payload);
  },
}))(UgcGratitudeModal);
