import { Route, State as RouteState } from 'config/routes';
import { call, put, select } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadType } from 'store/sagas/apiService/types';
import { navigateTo } from 'store/state/router/actions';
import { agentPageDataSelector } from 'store/state/domainData/selectors/agentById';
import { resetDomainData } from 'store/state/domainData/actions';
import { RealEstateLevel } from 'utils/entities';
import { prevRouteSelector } from 'store/state/selectors/router';
import { officePageDataSelector } from 'store/state/domainData/selectors/officeById';


function* loadAgentPage(route: RouteState) {
  const prevRoute = yield select(prevRouteSelector);
  if (prevRoute) {
    yield put(resetDomainData({ loadType: LoadType.AgentById }));
    yield put(resetDomainData({ loadType: LoadType.OfficeById }));
    yield put(resetDomainData({ loadType: LoadType.AgentBulletinsById }));
    yield put(resetDomainData({ loadType: LoadType.OfficeBulletinsById }));
    yield put(resetDomainData({ loadType: LoadType.SearchAwardsByIds }));
  }

  const { id } = route.params;

  yield call(queryData, { loadType: LoadType.AgentById, meta: { variables: { id, level: RealEstateLevel.Public } } });

  const agentData = yield select(agentPageDataSelector);
  if (agentData) {
    yield call(queryData, { loadType: LoadType.SearchAwardsByIds, meta: { variables: { args: { ids: [ id ] } } } });
    yield call(queryData, { loadType: LoadType.OfficeById, meta: { variables: { id: agentData.officeId, level: RealEstateLevel.Public } } });
    yield call(queryData, { loadType: LoadType.AgentBulletinsById, meta: { variables: { id, level: RealEstateLevel.Public } } });
    const officeData = yield select(officePageDataSelector);
    if (officeData.allOfficeBulletinsInAgentPage) {
      yield call(queryData, { loadType: LoadType.OfficeBulletinsById, meta: { variables: { id: officeData.officeId, level: RealEstateLevel.Public } } });
    }
  }
  else {
    yield put(navigateTo(Route.MadadPage, {}, { replace: true }));
  }
}

export function* agentPageHandler(params: RouteState) {
  yield call(loadAgentPage, params);
}
