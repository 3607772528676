import { convertFromRaw, RawDraftContentState, ContentBlock } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const blockRenderers = {
  'unstyled': (block: ContentBlock) => {
    return block.getText();
  },
};

export function rawToHtml(rawState: RawDraftContentState): string {
  if (rawState) {
    return stateToHTML(convertFromRaw(rawState), { blockRenderers });
  }
  return '';
}
