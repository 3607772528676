import { createStandardAction } from 'typesafe-actions';
import {
  SET_DEAL_TYPE,
  SET_DOC_ID,
  SET_PREVIEW_DOC_ID,
  SET_SMART_SORT,
  GO_BACK,
  SKIP,
  SUBMIT_WIZARD,
  ISetSmartSortMeta,
  ISetDocIdMeta,
  SET_SEARCH_STR,
  SET_COMMUTE_ADDRESS,
  SET_COMMUTE_RUSH_HOUR,
  SET_COMMUTE_TYPE,
  SET_PRICE_RANGE,
  SET_ROOMS_RANGE,
  NEXT,
  SET_OPEN,
  ISetDealTypeMeta,
  SET_PURCHASING_REASON,
  SET_MOVE_IN,
  SET_COMMUTE_SUB_STEP,
  SET_COMMUTE_MAX_DISTANCE_TRAIN,
  SET_COMMUTE_MAX_TIME_WALK,
  SET_COMMUTE_MAX_TIME_CAR,
  SET_COMMUTE_MAX_TIME,
} from './types';
import { SortValue } from 'components/listing-sort/types';
import { IAutocompleteEntry, DealType, CommuteType, PurchasingReason } from 'utils/entities';
import { PreferredAddress } from 'components/commute-popup/GenericCommutePreferenceForm';
import { RangeTuple, RoomOption } from 'components/filters/types';
import { MoveIn } from './index';
import { SetWizardOpenPayload } from './payloads';

export const setWizardOpen = createStandardAction(SET_OPEN)<SetWizardOpenPayload>();
export const setDealType = createStandardAction(SET_DEAL_TYPE)<DealType, ISetDealTypeMeta>();
export const setDocId = createStandardAction(SET_DOC_ID)<IAutocompleteEntry, ISetDocIdMeta>();
export const setPreviewDocId = createStandardAction(SET_PREVIEW_DOC_ID)<IAutocompleteEntry>();
export const setSearchStr = createStandardAction(SET_SEARCH_STR)<string>();
export const setSmartSort = createStandardAction(SET_SMART_SORT)<SortValue[], ISetSmartSortMeta>();
export const takeStepBack = createStandardAction(GO_BACK)();
export const skipStep = createStandardAction(SKIP)();
export const nextStep = createStandardAction(NEXT)();
export const submitWizard = createStandardAction(SUBMIT_WIZARD)();
export const setCommuteAddress = createStandardAction(SET_COMMUTE_ADDRESS)<PreferredAddress>();
export const setCommuteType = createStandardAction(SET_COMMUTE_TYPE)<CommuteType>();
export const setCommuteRushHour = createStandardAction(SET_COMMUTE_RUSH_HOUR)<boolean>();
export const setCommuteMaxDistanceTrain = createStandardAction(SET_COMMUTE_MAX_DISTANCE_TRAIN)<number>();
export const setCommuteMaxTimeWalk = createStandardAction(SET_COMMUTE_MAX_TIME_WALK)<number>();
export const setCommuteMaxTimeCar = createStandardAction(SET_COMMUTE_MAX_TIME_CAR)<number>();
export const setCommuteMaxTime = createStandardAction(SET_COMMUTE_MAX_TIME)<number>();
export const setPriceRange = createStandardAction(SET_PRICE_RANGE)<RangeTuple<number>>();
export const setRoomsRange = createStandardAction(SET_ROOMS_RANGE)<RangeTuple<RoomOption>>();
export const setPurchasingReasonAction = createStandardAction(SET_PURCHASING_REASON)<PurchasingReason>();
export const setMoveInAction = createStandardAction(SET_MOVE_IN)<MoveIn>();
export const setCommuteSubStep = createStandardAction(SET_COMMUTE_SUB_STEP)<boolean>();
