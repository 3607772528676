import React from 'react';
import { RatingAnswerButton } from './RatingAnswerButton';
import {
  AnswerListWrapper,
  QuestionDescriptionWrapper,
  QuestionDescription,
  QuestionIconWrapper,
  QuestionTextWrapper,
} from './styled';


export interface AnswerItem {
  label: string;
  value: number;
  icon: JSX.Element;
}

interface RatingStepProps {
  isPreview?: boolean;
  question: string;
  questionDescription?: string;
  questionIcon?: JSX.Element;
  answers: AnswerItem[];
  activeAnswer?: number;
  onSelectAnswer: (answer: AnswerItem) => void;
}

export const UgcRatingStep: React.FC<RatingStepProps> = React.memo(props => {
  const {
    isPreview,
    question,
    questionDescription,
    questionIcon,
    answers,
    activeAnswer,
    onSelectAnswer,
  } = props;

  return (
    <>
      {questionIcon ? <QuestionIconWrapper>{questionIcon}</QuestionIconWrapper> : null}
      <QuestionTextWrapper  data-auto="ugc-question" isPreview={isPreview} weight="bold">{question}</QuestionTextWrapper>
      {questionDescription ? (
        <QuestionDescriptionWrapper>
          <QuestionDescription>{questionDescription}</QuestionDescription>
        </QuestionDescriptionWrapper>
      ) : null}
      <AnswerListWrapper  data-auto="answer-list-wrapper" className={activeAnswer ? 'answered' : ''} isPreview={isPreview}>
        {answers.map((answer: AnswerItem) => (
          <RatingAnswerButton
            key={answer.value}
            text={answer.label}
            icon={answer.icon}
            isActive={answer.value === activeAnswer}
            select={() => {
              onSelectAnswer(answer);
            }}
          />
        ))}
      </AnswerListWrapper>
    </>
  );
});
