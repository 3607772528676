import { IImageItem, IThumbnail } from './entities';


export const DEFAULT_STREETS_IMG_SIZE = { width: 1280, height: 720 };
export const DEFAULT_PLACEHOLDER_IMAGE = 'placeholder_images2/unit_card_plachholder_pic@2x.png';
export const DEFAULT_PLACEHOLDER_IMAGE_URL = 'placeholder_images2/emty-gallery.png';

export const shouldLazilyLoad = (currentIndex: number, testIndex: number, total: number, lazyLoadMargin: number) => {
  const maxIdx = total - 1;
  const distance = Math.abs(testIndex - currentIndex);
  const remainder = distance % maxIdx;

  return isNaN(remainder) ? true : remainder <= lazyLoadMargin;
};

const createIndexGetter = (factor: 1 | -1, infinite: boolean) => (currentIndex: number, total: number) => {
  const maxIdx = total - 1;
  const sum = currentIndex + factor;

  if (infinite) {
    return Math.min(
      Math.max(0, sum),
      maxIdx
    );
  }

  if (sum > maxIdx) return 0;
  if (sum < 0) return maxIdx;
  return sum;
};

export const getInfiniteNextIndex = createIndexGetter(1, false);
export const getInfinitePrevIndex = createIndexGetter(-1, false);

export const getClampedNextIndex = createIndexGetter(1, true);
export const getClampedPrevIndex = createIndexGetter(-1, true);

const IMAGES_SUPER_LIMIT = 50;
export const processImgs = (images: IImageItem[] = []): IThumbnail[] => {
  const ordered = images.slice(0, IMAGES_SUPER_LIMIT).reduce<{ floorPlans: IThumbnail[], rest: IThumbnail[] }>((acc, current) => {
    if (!current) return acc;

    const { description, isFloorplan: isFloorPlan, imageUrl: url, rotation: rotateDegrees } = current;
    const item = { url, isFloorPlan, description, rotateDegrees };

    if (isFloorPlan) acc.floorPlans.push(item);
    else acc.rest.push(item);

    return acc;
  }, { floorPlans: [], rest: [] });

  return [
    ...ordered.rest,
    ...ordered.floorPlans,
  ];
};

