import { MUTATE, RESET_MUTATION_RESPONSE, SET_MUTATION_RESPONSE } from './types';

import { MutationType, MutationOptions, MutationVariablesByType, MutationResponse } from 'store/sagas/apiService/types';
import { createStandardAction } from 'typesafe-actions';


interface ActionParams<T extends MutationType> {
  mutationType: T;
  meta: {
    key?: string;
    response: MutationResponse<T>;
    variables: MutationVariablesByType[T];
  };
}

export interface SetMutationResponseAction<T extends MutationType> extends ActionParams<T> {
  type: typeof SET_MUTATION_RESPONSE;
}

type SMRAU = {
  [K in MutationType]: SetMutationResponseAction<K>;
}[MutationType];

type MRAU = {
  [ K in MutationType ]: MutationOptions<K> & { type: typeof MUTATE };
}[ MutationType ];

export const setMutationResponse = <T extends MutationType>(params: ActionParams<T>) => ({
  type: SET_MUTATION_RESPONSE,
  ...params,
} as SMRAU);


export const resetMutationResponse = createStandardAction(RESET_MUTATION_RESPONSE)<{ mutationType: MutationType, key?: string }>();


export const mutate = <T extends MutationType>(params: MutationOptions<T>) => ({
  type: MUTATE as typeof MUTATE,
  ...params,
  meta: {
    key: null as string,
    ...params.meta,
  },
} as MRAU);

export const inlineMutate = <T extends MutationType>(mutationType: T, variables: MutationVariablesByType[T], key: string = null) => mutate({
  meta: {
    variables,
    key,
  },
  mutationType,
});
