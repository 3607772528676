import React from 'react';

import { IStyledLink, LinkStyled, LinkStyledSimple } from './styled';
import ArrowLinkIcon from 'assets/svg/link-arrow.svg';
import { SmallText, Text } from 'ds/components/typography';

interface ILink extends React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLDivElement>, IStyledLink { }

export const Link: React.FunctionComponent<ILink> = ({ small, simple, ...props }) => {
  const Comp = simple ? LinkStyledSimple : LinkStyled;
  const TextComponent = small ? SmallText : Text;
  return (
    <TextComponent>
      <Comp small={small} {...props}>
        {props.children} {props.withArrow ? <ArrowLinkIcon /> : null}
      </Comp>
    </TextComponent>
  );
};

