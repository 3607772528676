import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { contactFormInitialValuesSelector, getContactPageEventPayload } from 'helpers/contact';
import { State, RootAction } from 'store/state';
import { contactAgentInputClick, setContactAgentOpen, submitContactAgent } from 'store/state/app/actions';
import { SubmitContactAgentPayload } from 'store/state/app/payloads';
import { ContactV4Variables, IContactPageSubmitVariables, MutationType } from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import { contactPageResponseSelector } from 'store/state/mutationsResponse/selectors';
import withEvents from 'analytics/withEvents';
import { MortgageFormProps } from './mortgage-form';
import Mortgage from './MortgageFormPopupAfterLead';
import { RequestType } from 'components/contact-modal/HebrewContactModal';
import { isBulletinPoiType } from 'utils/marketplaceRoutes';
import { IBulletin, ICommercialBulletin, ICommercialProject, IProject, PocType, PoiId } from 'utils/entities';
import { ContactModalOpenMeta } from 'store/state/app';

export type FullPoiType = IProject | ICommercialProject | IBulletin | ICommercialBulletin;


const MortgageWithEvents = withEvents<MortgageFormProps>((sendEvent, props) => ({
  onCallClickEvent: (isVisible: boolean, formValues: IContactPageSubmitVariables) => {
    const contactDetails = getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, formValues, isVisible);
    const event = {
      ...contactDetails.event,
      ...(props.source ? { source: props.source } : null),
      ...('poc' in props.poi && props.poi.poc && props.poi.poc.type === PocType.Agent && !isVisible ? {
        agent_details: {
          id: props.poi.poc.agentId,
          office_id: props.poi.poc.officeId,
        },
      } : null),
    };

    sendEvent(isVisible ? 'property_lead_call_submit' : 'property_lead_phone_expose', 'property', {
      ...contactDetails,
      event,
      bulletinId: props.poi.id,
    });
  },
  handleSubmitEvent: (hasErrors: boolean, formValues: IContactPageSubmitVariables) => {
    const contactDetails = getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, formValues, null, RequestType.RequestInfo);

    const event = {
      ...contactDetails.event,
      ...(props.source ? { source: props.source } : null),
    };

    sendEvent(hasErrors ? 'property_lead_form_submit_attempt' : 'property_lead_form_submit', 'property', {
      ...contactDetails,
      event,
      bulletinId: props.poi.id,
    });
  },
}))(Mortgage);

const mapStateToProps = (state: State, props: Partial<MortgageFormProps>) => {
  const contactResponse = contactPageResponseSelector(state, props.poi.id);
  const isContacted = Boolean(contactResponse && contactResponse.data && contactResponse.data.contactV4);
  return {
    isContacted,
    contactFormInitialValues: contactFormInitialValuesSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onContactPageSubmit: (payload: SubmitContactAgentPayload) => dispatch(submitContactAgent(payload)),
  onContactMortgageSubmit: (data: ContactV4Variables) => dispatch(inlineMutate(MutationType.ContactV4, data)),
  onSetContactModalPoiId: (id: PoiId, meta: ContactModalOpenMeta) => dispatch(setContactAgentOpen(id, meta)),
  onContactAgentInputClick: (eventName: string, poi: FullPoiType) => dispatch(contactAgentInputClick({ eventName, poi })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MortgageWithEvents);
