import styled from '@emotion/styled';
import { ProjectNameLabel } from 'components/cards/new-layout/styled';

export const ProjectNameText = styled(ProjectNameLabel)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(4)};
  `}
`;

export const InfoSidePaddingContainer = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing(1)};
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

export const LastInfoLine = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;
