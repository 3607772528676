import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  div:first-of-type {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    div:first-of-type {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.isRTL ? theme.spacing(1) : '0'};
    }
  `}
`;
