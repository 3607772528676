import styled from '@emotion/styled';
import { H3, SmallText, TextLabel } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';


export const AnswerListWrapper = styled.div<{ isPreview: boolean }>`
  ${({ theme, isPreview }) => `
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    max-width: 370px;
    margin-top: ${isPreview ? theme.spacing(4) : theme.spacing(8)};
  `}
`;

export const QuestionIconWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: ${theme.borderRadius.round};
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.07)};
    margin-bottom: ${theme.spacing(3)};

    & svg {
      width: 40px;
      height: 40px;
      & path {
        fill: ${theme.colors.brand.main};
      }
    }
  `}
`;

export const QuestionTextWrapper = styled(H3)<{ isPreview: boolean }>`
  ${({ theme, isPreview }) => `
    color: ${isPreview ? theme.colors.brand.lighter : 'inherit'};
  `}
`;

export const QuestionDescriptionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const QuestionDescription = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    position: absolute;
    top: ${theme.spacing(1)};
  `}
`;

export const AnswerWrapper = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.spacing(0.5)};
    cursor: pointer;
    width: 80px;
    text-align: center;
    & svg {
      width: 36px;
      height: 36px;
    }
    &:hover svg {
      & path {
        fill: ${theme.colors.brand.main};
      }
    }
  `}

  .answered &:not(.active) {
    opacity: 0.5;
  }
  &.active svg {
    & path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
    & circle {
      fill: ${({ theme }) => colorToAlpha(theme.colors.brand.main, 0.07)};
    }
  }
`;

export const AnswerTextWrapper = styled(TextLabel)<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => isActive ? theme.colors.brand.main : theme.colors.neutrals.grey3};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
