import config from '../config';
import { TrackJS } from 'trackjs';
import { isServer } from '@madlan145/client-toolbox';
import { isBot } from 'utils/bot';

let trackJSEnabled = false;

export function isTrackJSEnabled() {
  return trackJSEnabled;
}

function shouldEnableTrackJS() {

  if (isServer) return false;

  if (config.mode === 'development') return false;

  if (isBot()) return false;

  return true;
}

if (shouldEnableTrackJS()) {
  TrackJS.install({ token: 'b1271db84f96495681a9d7b10d3327de' });
  TrackJS.addMetadata('client_version', config.version);
  TrackJS.addMetadata('v2', 'true');
  trackJSEnabled = true;
}
