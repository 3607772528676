import React, { useMemo } from 'react';
import FilterSection from '../common/DropdownSection';
import { useLocale } from 'locale';
import { FiltersSingleComponentsMap } from 'components/filters/config';
import { SizeFilterWrapper } from '../styled/common';
import { GenericFilterProps, GroupFilterProps } from '../types';
import { ShowAt } from 'consts/breakpoints';


const SizeFilter: React.FC<GroupFilterProps> = (props) => {
  const {
    getProps,
    commitValues,
    isFilterActive,
    filters,
    marketplace,
    commitedValues,
    intermediateValues,
    countActiveFilters,
    makeMultipleResetValue,
    config,
  } = props;

  const { t } = useLocale();
  const filtersAliases = useMemo(() => filters.map(({ filterType }) => filterType), [ filters ]);

  const isActive = isFilterActive(filtersAliases, commitedValues);
  const reset = () => makeMultipleResetValue(filtersAliases);

  return (
    <SizeFilterWrapper data-auto="filter-sizeFilter-wrapper">
      <FilterSection
        isClearButtonDisabled={!isActive}
        ignoreWrapperPadding
        type="sizeFilter"
        automationTag="sizeFilter"
        icon={config.filtersIcons.areaRange}
        onClear={reset}
        label={t('filters.size', { count: countActiveFilters(filtersAliases, commitedValues) })}
        onSubmit={commitValues}
        isActive={isActive}
      >
        <ShowAt to={2}>
          {isMobile => filters.map(({ filterType, isInline }) => {
            const Component: React.ComponentType<GenericFilterProps<typeof filterType>> = FiltersSingleComponentsMap[marketplace][filterType];
            return <Component key={filterType} {...getProps(filterType, intermediateValues, commitedValues)} isInline={isInline} isMobile={isMobile} />;
          })}
        </ShowAt>
      </FilterSection>
    </SizeFilterWrapper>
  );
};

export default SizeFilter;
