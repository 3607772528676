import styled from '@emotion/styled';
import { FitTextFactory, SmallText, TextLabel } from 'ds/components/typography';
import { AvatarBoxProps } from '.';
import { PROFILE_ITEM_SIZE_DEFAULT, PROFILE_ITEM_SIZE_LARGE } from '../const';
import { colorToAlpha } from 'helpers/insight';


export const Root = styled.div<{ isOneRowLayout: boolean }>`
  ${({ isOneRowLayout }) => `
    display: flex;
    ${isOneRowLayout ? `
      align-items: center;
    ` : `
      flex-direction: column;
    `}
  `}
`;

export const AvatarBoxContainer = styled.div<{ isOneRowLayout: boolean; }>`
  ${({ theme, isOneRowLayout }) => `
    display: flex;
    > div {
      ${isOneRowLayout ? `
        margin-left: ${theme.spacing(1)};
      ` : `
        margin-left: ${theme.spacing(1.5)};
        margin-bottom: ${theme.spacing(1.5)};
      `}
    }
    svg {
      display: block;
    }
  `}
`;

export const AgentInfo = styled.div<{ isOneRowLayout: boolean }>`
  ${({ theme, isOneRowLayout }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${isOneRowLayout ? `
      margin-right: ${theme.spacing(.5)};
    ` : `
      margin-bottom: ${theme.spacing(3)};
    `};
  `}
`;

export const FitText = styled(FitTextFactory(2))``;

export const AgentTitle = styled.div``;

export const AgentText = styled(SmallText.withComponent('span'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const AgentTextDivide = styled(AgentText)`
  ${({ theme }) => `
    margin: 0 ${theme.spacing(.5)};
    ::after {
      display: inline;
      content: '•';
    }
  `}
`;

export const AgentMadadText = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
  `}
`;

export const ContentWithLabel = styled.div`
  position: relative;
  > svg {
    width: 80px;
    height: 80px;
  }
`;

export const LeadingLabel = styled(TextLabel)<{ disablePosition?: boolean }>(({ theme, disablePosition }) => ({
  color: theme.colors.brand.lighter,
  backgroundColor: theme.colors.brand.lightest,
  whiteSpace: 'nowrap',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.borderRadius.tiny,
  border: `1px solid ${colorToAlpha(theme.colors.brand.lighter, 0.2)}`,
  ...(disablePosition ? { } : {
    bottom: '-16px',
    position: 'absolute',
    zIndex: 12,
    left: '50%',
    transform: 'translateX(calc(-50% - 8px))',
  }),
}));

function getSize(isLarge: boolean, isLeadingAgent: boolean) {
  if (isLeadingAgent) return '80px';
  return isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT;
}

export const AvatarBox = styled.div<Pick<AvatarBoxProps, 'imageURL' | 'shape' | 'isLarge' | 'isLeadingAgent'>>`
  ${({ theme, imageURL, shape, isLarge, isLeadingAgent }) => `
    width: ${getSize(isLarge, isLeadingAgent)};
    height: ${getSize(isLarge, isLeadingAgent)};
    margin-right: ${isLeadingAgent ? theme.spacing(2) : 0};
    overflow: hidden;
    ${shape === 'circle' ? `
      border-radius: ${theme.borderRadius.round};
      border: 1px solid ${theme.colors.neutrals.grey6};
    ` : ''}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: ${theme.colors.neutrals.grey6};
    ${imageURL ? `background-image: url("${imageURL}");` : ''}
  `}
`;

export const LinkWrapper = styled.div`
  & > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 24px;
    ${({ theme }) => `
      color: ${theme.colors.brand.main};
      > svg {
        fill: ${theme.colors.brand.main};
      }
      &:hover {
        color: ${theme.colors.brand.main};
        > svg {
          fill: ${theme.colors.brand.main};
        }
      }
      &:focus {
        color: ${theme.colors.brand.main}
      }
    `};
  }
`;

export const NonClickableWrapper = styled.div<any>``;
