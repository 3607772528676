import styled from '@emotion/styled';
import {
  MAX_IMAGE_HEIGHT,
  SLIDER_THUMBNAIL_HEIGHT,
  SLIDER_THUMBNAIL_MAX_ITEMS_VISIBLE,
  SLIDER_THUMBNAIL_SIDE_PADDING,
  SLIDER_THUMBNAIL_WIDTH, ViewMode,
} from './constants';

export const Block = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  color: #fff;
  background-color: rgba(0, 0, 0, .9);
  display: flex;
  direction: ltr;
`;

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  & .view-pager-rail {
    display: flex;
  }
`;
export const ControlsStyle = styled.div`
  position: fixed;
  width:100%;
  left: 0;
  bottom: 8px;
  display: flex;
  justify-content: center;
  padding: 8px 0 0;
`;

export const CloseIconStyle = styled.div`
  z-index: 1;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
`;

export const ImageItem = styled.div<{ mode?: ViewMode, isActive?: boolean }>`
  width: ${({ mode = 'carousel' }) => mode === 'carousel' ? '100vw' : 'auto'};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 100%;
    user-select: none;
    pointer-events: none;
    max-height: ${({ mode = 'carousel' }) => mode === 'carousel' ? MAX_IMAGE_HEIGHT : SLIDER_THUMBNAIL_HEIGHT}px;
  }

  & img, & div {
    transition: all .2s ease;
    ${({ isActive }) => isActive !== undefined
      ? (isActive ? `opacity: 1;` : `opacity: .5;`)
      : ''
    }
  }
`;

export const ThumbnailImage = styled.div<{ url: string }>`
  height: ${SLIDER_THUMBNAIL_HEIGHT}px;
  width: ${SLIDER_THUMBNAIL_WIDTH}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-image: url("${({ url }) => url}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${SLIDER_THUMBNAIL_SIDE_PADDING}px;
`;

export const SliderPager = styled.div`
  position: relative;
  width: 100%;
  height: 55vh;
  overflow: hidden;
  display: flex;
  align-items: center;

  > div {
    height: 100%;
  }
`;

export const SliderControls = styled.div`
  padding: 5px 0;
  max-width: ${(SLIDER_THUMBNAIL_WIDTH + SLIDER_THUMBNAIL_SIDE_PADDING * 2) * SLIDER_THUMBNAIL_MAX_ITEMS_VISIBLE}px;
  margin: 0 auto;
`;

export const GalleryWrapper = styled.div`
  margin: 0 auto;
  min-width: 0;
`;

export const LegendWrapper = styled.div`
  padding: 0 12px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
  color: ${({ theme }) => theme.colors.neutrals.white};
  display: flex;
  flex-direction: ${props => props.theme.isRTL ? 'row-reverse' : 'row'};
  font-size: 13px;
`;

export const LegendFloorTotalWrapper = styled.div`
  text-align: ${props => props.theme.isRTL ? 'left' : 'right'};
  width: 50%;
`;

export const LegendTotalLabel = styled.div`
  text-align: ${props => props.theme.isRTL ? 'right' : 'left'};
  width: 50%;
  padding: 4px 0 9px 0;
}
`;

export const LegendFloorTotalLabel = styled.div`
  float: ${props => props.theme.isRTL ? 'none' : 'right'};
  margin: ${props => props.theme.isRTL ? '5px 0 0 29px' : '5px 5px 0 5px'};
`;

export const ArrowIconWrapper = styled.span`
  float: ${props => props.theme.isRTL ? 'left' : 'right'};
  ${props => !props.theme.isRTL && `transform: rotate(0.5turn);
    display: inline-block;
    bottom: 2px;
    position: relative;`}
`;


type Direction = 'next' | 'prev';

export interface ControlsArrowProps {
  dir: Direction;
  onClick(): void;
  disabled?: boolean;
}

export const ControlsArrowWrapper = styled.span<ControlsArrowProps>`
  cursor: pointer;
  height: 60px;
  transition: opacity .2s ease;
  svg {
    transform: scaleX(${({ dir }) => dir === 'next' ? '-' : ''}1);
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ dir }) => dir === 'next' ? 'right' : 'left' }: 32px;
`;
