import { PreviewCard } from 'components/navigation/shortlist-hover-preview/preview-card/PreviewCard';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction } from 'store/state';
import { ContactModalOpenMeta } from 'store/state/app';
import { setContactAgentOpen } from 'store/state/app/actions';
import { PoiId } from 'utils/entities';

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetContactModalPoiId: (id: PoiId, meta: ContactModalOpenMeta) => dispatch(setContactAgentOpen(id, meta)),
});

export default connect(null, mapDispatchToProps)(PreviewCard);
