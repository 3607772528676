import styled from '@emotion/styled';
import { TextLabel } from 'ds/components/typography';

export const CommercialMoreInfoWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(4)};
  `}
`;


export const CommercialMoreInfo = styled.div<{ noBorder?: boolean; noPadding?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme, isMobile }) => isMobile ? `
    flex-direction: column;
    align-items: flex-start;
    && > * {
      margin-bottom: ${theme.spacing(1.25)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  ` : ''}
  ${({ noPadding, isMobile }) => noPadding || isMobile ? `
    && > * {
      padding-left: 0;
      padding-right: 0;
    }
  ` : ''}
`;

export const CommercialSectionTitle = styled(TextLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    padding-bottom: ${theme.spacing(2)}
  `};
`;

export const AdditionalCarouselWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.neutrals.grey9};
    padding-top: ${theme.spacing(12)};
  `}
`;
