import React, { useMemo } from 'react';
import { ITranslate, useLocale } from 'locale';
import { useField } from 'react-final-form-hooks';
import { isNotEmpty } from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';
import Select from 'ds/components/input/Select';
import { SpacingBlock } from 'components/spacing-block';
import { Section, SectionHeading, SelectFieldWrapper } from 'screens/UploadBulletinPage/styled';
import { UploadBulletinStepProps } from '../../';
import { genPropertyTypeOptions } from './helpers';
import { PropertyType } from 'components/filters/types';

const SelectWithValidationMessage = withValidationMessage(Select);

const validateField = (t: ITranslate) => (val: PropertyType) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');

interface BuildingClassSectionProps extends UploadBulletinStepProps {
  buildingClasses: PropertyType[];
}

export const BuildingClassSection: React.FC<BuildingClassSectionProps> = ({
  formInstance,
  buildingClasses,
  isCommercial,
}) => {
  const { t } = useLocale();
  const allOptions = useMemo(() => buildingClasses.map(genPropertyTypeOptions(t, isCommercial)), [ t ]);

  const buildingClass = useField('buildingClass', formInstance, validateField(t));

  return (
    <Section>
      <SpacingBlock mBottom={1}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.mainInfoStep.buildingClassHeading')}
        </SectionHeading>
      </SpacingBlock>
      <SpacingBlock mBottom={2}>
        <SelectFieldWrapper
          error={buildingClass.meta.error && buildingClass.meta.touched}
          isEmpty={!buildingClass.input.value}
        >
          <SelectWithValidationMessage
            size="large"
            items={allOptions}
            {...buildingClass.meta}
            {...buildingClass.input}
            selectionPromptText={t('uploadBulletinPage.mainInfoStep.buildingClassPlaceholder')}
          />
        </SelectFieldWrapper>
      </SpacingBlock>
    </Section>
  );
};
