import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { Task, MulticastChannel } from 'redux-saga';
import { take, select, fork, cancel } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadType } from 'store/sagas/apiService/types';
import { searchBboxSelector } from 'store/state/selectors/search';
import {
  userCommuteWithPrioritiesSelector,
  searchDocEntriesSelector,
  searchListCursorSelector,
} from 'store/state/domainData/selectors';
import { ISearchPoiVariables, MarketplaceType, PoiType } from 'utils/entities';
import { SortField, SortDirection } from 'components/listing-sort/types';
import { boundsToTileRange } from 'components/map/utils';
import { searchParamsSelector, pageSelector } from './selectors';
import { makeOffset, MAX_ZOOM } from './utils';
import { listingEditedSelector } from 'store/state/app/selectors';


export const searchVariablesSelector = createSelector([
  searchParamsSelector,
  searchDocEntriesSelector,
  searchBboxSelector,
  pageSelector,
  userCommuteWithPrioritiesSelector,
  searchListCursorSelector,
  listingEditedSelector,
], ({ searchParams, marketplaceType }, searchDocs, bounds, page, userData, cursor, listingEdited): ISearchPoiVariables => {
  if (!bounds || !searchParams.dealType) return null;

  const tileRanges = [ boundsToTileRange(bounds, MAX_ZOOM) ];
  let sort = searchParams.sort || [];

  if (searchDocs && searchDocs.length) {
    sort = [
      ...sort,
      {
        field: SortField.Geometry,
        order: SortDirection.Asc,
        reference: null,
        docIds: searchDocs.map(doc => doc.docId),
      },
    ];
  }

  const isCommercialRealEstate = marketplaceType === MarketplaceType.Commercial;
  const poiTypes: PoiType[] = isCommercialRealEstate ? [ 'commercialBulletin', 'project' ] : [ 'bulletin', 'project' ];

  const safePage = page || 1;
  const offset = makeOffset(safePage);
  const isInitial = offset.offset === 0;
  const cursorBulletinsOffset = cursor && cursor.bulletinsOffset ? cursor.bulletinsOffset : undefined;
  const bulletinsOffset = isInitial ? 0 : cursorBulletinsOffset;

  return {
    ...searchParams,
    isCommercialRealEstate,
    sort,
    userContext: userData,
    tileRanges,
    poiTypes,
    searchContext: 'marketplace',
    cursor: {
      seenProjects: page > 1 ? cursor && cursor.seenProjects : null,
      bulletinsOffset,
    },
    ...offset,
    listingEdited: listingEdited || undefined,
  };
});

export function* searchListWorker(chnl: MulticastChannel<ISearchPoiVariables>) {
  yield take(chnl, '*');

  let bgTask: Task = null;

  while (true) {
    const currVariables: ISearchPoiVariables = yield select(searchVariablesSelector);

    if (currVariables) {
      bgTask = yield fork(queryData, {
        loadType: LoadType.SearchList,
        meta: {
          variables: currVariables,
        },
      });
    }

    while (true) {
      yield take(chnl, '*');

      const nextSearchVariables: ISearchPoiVariables = yield select(searchVariablesSelector);

      if (!isEqual(currVariables, nextSearchVariables)) {
        if (bgTask) yield cancel(bgTask);
        break;
      }
    }
  }
}
