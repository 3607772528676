import React from 'react';
import { useForm } from 'react-final-form-hooks';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { SmallText } from 'ds/components/typography';
import { AuthenticationField, MIN_PASSWORD_LENGTH } from './types';
import { EnterPasswordFormRoot, EnterPasswordInfo, AuthenticationForgotPasswordButton, FormControlsWrapper } from './styled';
import { AuthError } from 'store/sagas/apiService/types';

interface EnterPasswordFormProps {
  email: string;
  password: string;
  onPasswordChange(e: React.ChangeEvent<HTMLInputElement>): void;
  isPasswordValid: boolean;
  dirtyFields: Set<AuthenticationField>;
  onSetDirty: (field: AuthenticationField) => void;
  onSubmit: () => void;
  onToggleResetPassword: () => void;
  isLoginFailed: boolean;
}

export const EnterPasswordForm: React.FC<EnterPasswordFormProps> = ({
  email,
  onPasswordChange,
  isPasswordValid,
  password,
  dirtyFields,
  onSetDirty,
  onSubmit,
  onToggleResetPassword,
  isLoginFailed,
}) => {
  const { t } = useLocale();
  const { handleSubmit } = useForm({ onSubmit });

  return (
    <EnterPasswordFormRoot>
      <EnterPasswordInfo>{t('authentication.enterPassword.info', { email })}</EnterPasswordInfo>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          data-auto="set-password-field"
          type="password"
          fullWidth
          onChange={onPasswordChange}
          value={password}
          placeholder={t('authentication.enterPassword.inputPlaceholder')}
          hasError={(isLoginFailed || !isPasswordValid) && dirtyFields.has('password')}
          errorMessage={isLoginFailed
            ? t('authentication.authError', { error: AuthError.LoginFailed })
            : t('authentication.passwordInvalidMessage', { length: MIN_PASSWORD_LENGTH })
          }
          onBlur={() => onSetDirty('password')}
        />
        <FormControlsWrapper>
          <Button
            data-auto="password-submit"
            disabled={!isPasswordValid || !password}
            fullWidth
            size="large"
          >
            {t('authentication.enterPassword.submitText')}
          </Button>
        </FormControlsWrapper>
      </form>
      <AuthenticationForgotPasswordButton data-auto="forgot-password-button" onClick={onToggleResetPassword}>
        <SmallText>{t('authentication.forgotPasswordLabel')}</SmallText>
      </AuthenticationForgotPasswordButton>
    </EnterPasswordFormRoot>
  );
};

