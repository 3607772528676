import React from 'react';
import BulletinCard from './BulletinCard';
import ProjectCard from './ProjectCard';
import CommercialBulletin from './CommercialBulletin';
import CommercialProject from './CommercialProject';
import { UniversalCardPoiUnion, CardType } from 'components/cards/types';

const PropertyInfo: React.FC<UniversalCardPoiUnion> = (props) => {
  switch (props.cardType) {
    case CardType.Bulletin:
      return <BulletinCard {...props} />;

    case CardType.Project:
      return <ProjectCard {...props} />;

    case CardType.CommercialBulletin:
      return <CommercialBulletin {...props} />;

    case CardType.CommercialProject:
      return <CommercialProject {...props} />;
  }
};

export default PropertyInfo;
