
// @ts-ignore
import centroid from '@turf/centroid';
import { polygon } from '@turf/helpers';

import { BoundingBox, ITileRange } from './entities';
import { wsg84ToGoogleGrid } from 'components/map/utils';
import { MAX_ZOOM } from 'store/sagas/routing/handlers/search/utils';

export const addBufferToBounds = (bbox: BoundingBox, buffer = 0.001): BoundingBox => {
  const [ [ w, s ], [ e, n ] ] = bbox;
  return [
    [ w - buffer, s - buffer ],
    [ e + buffer, n + buffer ],
  ];
};

export const makeBufferAroundCenter = ([ lng, lat ]: [ number, number ], buffer = 0.001): BoundingBox => {
  const w = lng - buffer;
  const s = lat - buffer;
  const e = lng + buffer;
  const n = lat + buffer;
  return [ [ w, s ], [ e, n ] ];
};

export const createPoly = <T>([ [ w, s ], [ e, n ] ]: BoundingBox, props?: T) => polygon([ [ [ w, n ], [ e, n ], [ e, s ], [ w, s ], [ w, n ] ] ], props);

export const getFetureCentroid = (feature: GeoJSON.Feature): [ number, number ] => centroid(feature).geometry.coordinates;

export const getTileRange = (lng: number, lat: number): ITileRange => {
  const tile = wsg84ToGoogleGrid(lng, lat, MAX_ZOOM);
  return ({
    x1: tile.x,
    y1: tile.y,
    x2: tile.x + 1,
    y2: tile.y + 1,
  });
};
