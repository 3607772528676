import React, { useEffect } from 'react';


export const TitleUpdater: React.FC<{ title: string }> = ({ title }) => {
  useEffect(() => {
    const titleE = document.querySelector('title');
    if (title && titleE) titleE.innerText = title;
  }, [ title ]);

  return null;
};
