import React from 'react';
import { Dispatch } from 'redux';
import { ShowAt } from 'consts/breakpoints';
import { State as RouteState, Route } from 'config/routes';
import { prevRouteSelector, routeSelector } from 'store/state/selectors/router';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import CloseIcon from 'assets/svg/cross.svg';
import { colorToAlpha } from 'helpers/insight';
import { State, RootAction } from 'store/state';
import { navigateTo } from 'store/state/router/actions';
import { setMobileSearchMapViewActive as setMobileSearchMapViewActiveAction } from 'store/state/app/actions';
import {
  setHoveredDataLayerId,
  setIsMobileLayersToggleOpen as setIsMobileLayersToggleOpenAction,
  setIsInfoBoxOpen as setIsInfoBoxOpenAction,
} from 'store/state/insightsContext/actions';
import { setActivePoiIds } from 'store/state/searchContext/actions';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import withEvents from 'analytics/withEvents';


export const CloseMapMobileButton = styled.div`
  position: fixed;
  top: 8px;
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: 16px;
  width: 32px;
  height: 32px;
  z-index: 100;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  background: ${({ theme }) => colorToAlpha(theme.colors.neutrals.grey1, 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
`;

export const CloseMapButton: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <ShowAt to={2}>
    <CloseMapMobileButton onClick={onClose}>
      <CloseIcon width={16} height={16} />
    </CloseMapMobileButton>
  </ShowAt>
);


interface CloseMobileMapButtonProps {
  route: RouteState;
  prevRoute: boolean;
  setMobileSearchMapViewActive: typeof setMobileSearchMapViewActiveAction;
  setIsInfoBoxOpen: (isOpen: boolean) => void;
  setIsMobileLayersToggleOpen: (isOpen: boolean) => void;
  navigate: typeof navigateTo;
  resetMapActiveLayer: () => void;
  isSearch: boolean;
  onClose?: () => void;
}

const CloseMapMobileButtonView: React.FC<CloseMobileMapButtonProps> = (props) => {
  const {
    route,
    navigate,
    setMobileSearchMapViewActive,
    setIsInfoBoxOpen,
    setIsMobileLayersToggleOpen,
    resetMapActiveLayer,
    prevRoute,
    onClose,
  } = props;

  const handleMapClose = () => {
    setIsInfoBoxOpen(false);
    setIsMobileLayersToggleOpen(false);
    onClose();
    if (route.params.sourceVisibleSection && prevRoute) {
      window.history.back();
    }
    else if (route.name === Route.Search || route.name === Route.SearchCommercial) {
      setMobileSearchMapViewActive(false);
    }
    else {
      navigate(route.name, { ...route.params, isMapActive: undefined }, { replace: true });
    }
    resetMapActiveLayer();
  };

  return <CloseMapButton onClose={handleMapClose} />;
};

const CloseMapMobileButtonViewWithEvents = withEvents<CloseMobileMapButtonProps>((sendEvent, props) => ({
  onClose() {
    if (!props.isSearch) return;

    sendEvent('map_toggle_click', 'map', {
      event: {
        'toggle_direction': 'collapse',
      },
    });
  },
}))(CloseMapMobileButtonView);

const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
  prevRoute: Boolean(prevRouteSelector(state)),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  navigate: (name: Route, params?: Record<string, any>, opts?: NavigationOptions) => {
    dispatch(navigateTo(name, params, opts));
  },
  setMobileSearchMapViewActive: (isActive: boolean) => {
    dispatch(setMobileSearchMapViewActiveAction(isActive));
  },
  setIsInfoBoxOpen: (isOpen: boolean) => dispatch(setIsInfoBoxOpenAction(isOpen)),
  setIsMobileLayersToggleOpen: (isOpen: boolean) => dispatch(setIsMobileLayersToggleOpenAction(isOpen)),
  resetMapActiveLayer: () => {
    dispatch(setHoveredDataLayerId(null));
    dispatch(setActivePoiIds([], { source: 'map' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseMapMobileButtonViewWithEvents);
