import { connect } from 'react-redux';
import { State } from 'store/state';
import { setFeedbackModal, askSubmitFeedback } from 'store/state/UGC/actions';
import { isFeedbackModalOpenSelector } from 'store/state/UGC/selectors';
import { UgcFeedbackModal } from 'components/user-generated-content/feedback-modal';

const mapStateToProps = (state: State) => ({
  isOpen: isFeedbackModalOpenSelector(state),
});

const mapDispatchToProps = {
  handleClose: () => setFeedbackModal({ isOpen: false }),
  handleSubmit: askSubmitFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(UgcFeedbackModal);
