import { createSelector } from 'reselect';
import { routeSelector, getRouteParams } from 'store/state/selectors/router';
import { resolvedStrictLocalDocSelector } from 'store/state/domainData/selectors';
import { flow, head, keyBy } from 'lodash';
import { madadParticipantsFilterData, DEFAULT_CITY_DOC_ID, DEFAULT_2024_CITY_DOC_ID } from '../domainData/selectors/madadParticipants';
import { Route } from 'config/routes';

export const madadLocalDocSelector = createSelector([
  resolvedStrictLocalDocSelector,
  routeSelector,
], (localDoc, route) => {
  if (localDoc && localDoc.docId === route.params.id) {
    return localDoc;
  }
  return null;
});

export const MADADS = new Set([ Route.MadadCity, Route.MadadPage ]);
export const MADADS_ARCHIVE = new Set([ Route.MadadArchivePage, Route.MadadCityArchive ]);

export const filterRecordsByDocIdSelector = createSelector([
  madadParticipantsFilterData,
], (filterRecord) => keyBy(filterRecord, (r) => r.city.value));


export const madadCityDocIdSelector = createSelector([
  flow(getRouteParams, p => p.id),
  filterRecordsByDocIdSelector,
  madadParticipantsFilterData,
], (docId, filterRecordsDict, filterRecords) => {
  const headItem = filterRecordsDict[DEFAULT_CITY_DOC_ID] || head(filterRecords);
  const city = headItem && headItem.city;
  const cityDocId = (filterRecordsDict[docId] ? docId : (city && city.value.toString()));

  return cityDocId;
});


export const madad2024CityDocIdSelector = createSelector([
  flow(getRouteParams, p => p.id),
  filterRecordsByDocIdSelector,
  madadParticipantsFilterData,
], (docId, filterRecordsDict, filterRecords) => {
  const headItem = filterRecordsDict[DEFAULT_2024_CITY_DOC_ID] || head(filterRecords);
  const city = headItem && headItem.city;
  const cityDocId = (filterRecordsDict[docId] ? docId : (city && city.value.toString()));

  return cityDocId;
});

export const madadCityNameSelector = createSelector(
  [ madadParticipantsFilterData, madadCityDocIdSelector ],
  (filterRecords, cityDocId) => {
    const currentCity = filterRecords.find(d => d.city.value === cityDocId);
    const currentCityName = currentCity && currentCity.city.label;
    return currentCityName ? currentCityName : null;
  }
);
