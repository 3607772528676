import styled from '@emotion/styled';
import { DefaultText, SmallText } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';
import { Text } from 'ds/components/typography';

export const TagsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${theme.spacing(0.5)};
  `}
`;

interface ProjectNameProps {
  size?: 'small' | 'medium' | 'large';
  isPromoted?: boolean;
}

export const ProjectNameLabel = styled(DefaultText)<ProjectNameProps>`
  font-size: ${typographyFontSizeCfg.h2.mobile}px;
  line-height: ${typographyLineHeightCfg.h2.mobile}px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme, size = 'medium', isPromoted }) => `
    color: ${isPromoted ? theme.colors.brand.darker : theme.colors.neutrals.grey1};

    ${size === 'large' ? `
      font-size: ${typographyFontSizeCfg.h1.mobile}px;
      line-height: ${typographyLineHeightCfg.h1.mobile}px;
    ` : ''}

    ${size === 'small' ? `
      font-size: ${typographyFontSizeCfg.h3.mobile}px;
      line-height: ${typographyLineHeightCfg.h3.mobile}px;
    ` : ''}
  `}
`;

ProjectNameLabel.defaultProps = {
  weight: 'bold',
};

export const PropertyPrimaryLine = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const PropertySecondaryLine = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
  `}
`;

export const ListingCardWrapper = styled.div<{ isActive: boolean; }>`
  border-radius: ${props => props.theme.borderRadius.small};
  ${({ theme, isActive }) => `
    background: ${theme.colors.neutrals.white};
    border-radius: ${theme.borderRadius.small};
    border: 1px solid ${theme.colors.neutrals.white};
    overflow: hidden;

    ${isActive ? `
      box-shadow: ${theme.shadow.level1};
    ` : `
      border: 1px solid ${theme.colors.neutrals.grey8};
    `}
    .universal-card-thumbnail-image {
      border: 1px solid ${theme.colors.neutrals.grey8};
    }
  `}
  ${({ theme, isActive }) => isActive ? `
    box-shadow: ${theme.shadow.level1};
  ` : ''}

  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: false }) `
    .universal-card-hover-show {
      display: none;
    }
  `}

  .universal-card-pager-hover {
    display: none;
  }

  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: false })`
    &:hover {
      .universal-card-pager-hover {
        display: flex;
      }

      .universal-card-hover-show {
        display: flex;
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: true }) `
    .universal-card-pager-hover {
      display: flex;
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    .thumbnail-logo {
      opacity: 0;
      transition: opacity .2s ease;
    }
    &:hover {
      .thumbnail-logo {
        opacity: 1;
      }
    }
  `}
`;
