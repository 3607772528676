// export interface TouchesEvent {
//   touches: Array<{ pageX: number; pageY: number; }>;
// }

export type MouseOrTouchEvent = MouseEvent | TouchEvent;
export type ValuesTuple = [number, number];

export interface Unsubscribe {
  remove: () => void;
}

/**
 * In a given values tuple immutably sets newValue at idx position
 * @returns new values tuple
 */
export const setValueByIdx = (values: ValuesTuple, idx: number, newValue: number) => {
  const cloned = [ ...values ] as ValuesTuple;
  cloned[idx] = newValue;
  return cloned;
};
/**
 * Compares two tuples equality quickly
 */
export const areTuplesEqual = (a: ValuesTuple, b: ValuesTuple) => (
  // TODO: proper namings
  a[0] === b[0] && a[1] === b[1]
);
/**
 * In a given values tuple gets index of the closest item
 */
export const getClosestIdx = ([ a, b ]: ValuesTuple, to: number) => {
  if (b === null || Math.abs(a - to) < Math.abs(b - to)) return 0;
  return 1;
};

export const isDeltaValid = ([ a, b ]: ValuesTuple, minDelta: number) => (
  b - a >= minDelta
);

const clamp = (value: number, min = 0, max = 1) => (
  Math.min(Math.max(value, min), max)
);

export function _addEventListener(node: HTMLElement, ...evtListenerArgs: any[]): Unsubscribe {
  // @ts-ignore
  node.addEventListener(...evtListenerArgs);
  return {
    remove: () => {
      // @ts-ignore
      node.removeEventListener(...evtListenerArgs);
    },
  };
}


const isTouchEvent = (evt: MouseOrTouchEvent): evt is TouchEvent => (
  typeof (evt as any).changedTouches !== 'undefined'
);

function getPointerPosition(evt: MouseOrTouchEvent) {
  if (isTouchEvent(evt) && evt.changedTouches[0]) {
    return {
      x: evt.changedTouches[0].pageX,
      y: evt.changedTouches[0].pageY,
    };
  }
  else if (evt instanceof MouseEvent) {
    return {
      x: evt.pageX,
      y: evt.pageY,
    };
  }
  return null;
}

export function calculateRelative(node: HTMLElement, evt: MouseOrTouchEvent) {
  const { width, left } = node.getBoundingClientRect();
  const xOffset = left + window.pageXOffset;
  const pos = getPointerPosition(evt);

  return clamp((pos.x - xOffset) / width);
}

export const toRelative = (absValues: ValuesTuple, min: number, max: number) => (
  absValues.map((absValue) => (
    absValue !== null ? (absValue - min) / (max - min) : null
  )) as ValuesTuple
);
export const toAbsolute = (relativeValue: number, min: number, max: number) => (
  relativeValue * (max - min) + min
);
export const roundByStep = (value: number, step: number | null) => (
  step
    ? Math.round(value / step) * step
    : value
);

export function noOp() {
  // This function is tired, it won't do anything
}
