import React from 'react';
import { useLocale } from 'locale';
import { SocialButton } from 'components/authentication/social-buttons/social-button';
import { SocialVendor } from 'components/authentication/types';
import { ReactNativeClientTunnelContext, AUTH_TYPE } from 'react-native/reactNativeClientTunnel';
import * as Styled from './styled';

const buttons: SocialVendor[] = [
  // 'facebook', until facebook doesnt work on client
  'google',
];

export const SocialsButtons: React.FC = () => {
  const { t } = useLocale();
  const ctx = React.useContext(ReactNativeClientTunnelContext);

  return (
    <Styled.Root>
      {buttons.map((type) => (
        <SocialButton onClick={() => ctx.push({ type: AUTH_TYPE, payload: { type } })} type={type}>
          {t('authentication.socialButtonLabel', { type })}
        </SocialButton>
      ))}
      <Styled.AuthenticationSocialSuggestionWrapper>
        <Styled.AuthenticationSocialSuggestion>{t('authentication.submitSuggestion')}</Styled.AuthenticationSocialSuggestion>
      </Styled.AuthenticationSocialSuggestionWrapper>
    </Styled.Root>
  );
};
