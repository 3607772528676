import React from 'react';
import { useLocale } from 'locale';
import { Wrapper, SchoolType } from './styled';
import { ShowAt } from 'consts/breakpoints';
import { SmallText } from 'ds/components/typography';
import { connect } from 'react-redux';
import { Rate } from '../schools/styled';
import { ILSchool } from 'store/state/selectors/insights/schools/types';
import { isNil } from 'lodash';
import { bestSchoolSelector } from '../schools';
import { lowSchoolRatingList } from 'components/cards/listing/UniversalCard/utils';

interface EducationProps {
  bestSchool: ILSchool;
  rating: number;
}

export const ILEducationDynamicContentNotConnected: React.FC<EducationProps> = ({ bestSchool, rating }) => {
  const { t } = useLocale();

  if (!bestSchool) return <SmallText>{t('widgetTitle.schools.collapsed.noData')}</SmallText>;

  const { schoolName, orthodox, schoolScore } = bestSchool;

  return (
    <ShowAt from={2}>
      {isDesktop => {
        const showRate = schoolScore && isDesktop && !isNil(rating) && !lowSchoolRatingList.has(rating);
        return (
          <Wrapper>
            {schoolName ? <SmallText>{schoolName}</SmallText> : null}
            <SchoolType>({t('widget.collapsed.orthodox', { orthodox })})</SchoolType>
            <SmallText>{t('widget.collapsed.registryArea')}</SmallText>
            {showRate ?
              <Rate>{t('widget.collapsed.bestSchool', { rating })}</Rate>
              : null}
          </Wrapper>
        );
      }}
    </ShowAt>
  );
};

export const ILEducationDynamicContent = connect(bestSchoolSelector)(ILEducationDynamicContentNotConnected);
