import React from 'react';
import { SidebarHeader, CloseButton, SidebarBody, LinksSectionWrapper, LinksSectionTitle } from './styled';

import CloseIcon from 'assets/svg/close.svg';
import ProfileIcon from 'assets/svg/profile.svg';
import { StaticLinksSections } from './StaticLinksSections';
import { useLocale } from 'locale';
import { ShowAt } from 'consts/breakpoints';
import { MenuLink } from './MenuLink';
import { LinksSection } from './types';
import { setAuthModal, setUserMobileProfileOpen } from 'store/state/app/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction, State } from 'store/state';
import { AuthenticationModalType } from 'components/authentication/types';
import { AuthModalSource } from 'store/state/app';
import AccessibilityIcon from 'assets/svg/disabled.svg';
import { isVisitorUserSelector } from 'store/state/domainData/selectors';

interface SidebarProps {
  onClose: () => void;
  isVisitor: boolean;
  onOpenAuthModal: () => void;
  openProfile: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose, isVisitor, onOpenAuthModal, openProfile }) => {
  const { t } = useLocale();

  const handleAuthClick = () => {
    onOpenAuthModal();
    onClose();
  };

  const onOpenProfileClick = () => {
    openProfile();
    onClose();
  };

  const handleAccessibilityClick = () => {
    const accessibilityButton = document.getElementById('INDmenu-btn');
    if (accessibilityButton) {
      accessibilityButton.click();
      onClose();
    }
  };

  return (
    <>

      <SidebarHeader>
        <CloseButton onClick={onClose}>
          <CloseIcon width={16} height={16} />
        </CloseButton>
      </SidebarHeader>
      <SidebarBody>
        <ShowAt to={2}>
          <LinksSectionWrapper>
            <LinksSectionTitle>
              {t('navigation.moreActionsMenu.linksSectionLabel', { section: LinksSection.Profile })}
            </LinksSectionTitle>
            {isVisitor ? (
              <MenuLink onClick={handleAuthClick} accented>
                <ProfileIcon width={24} height={24} />
                {t('authentication.authenticationLabel', { isSignUp: true })}
              </MenuLink>
            ) : (
              <MenuLink onClick={onOpenProfileClick} accented>
                <ProfileIcon width={24} height={24} />
                {t('navigation.moreActionsMenu.myProfile')}
              </MenuLink>
            )}
            <MenuLink onClick={handleAccessibilityClick} accented>
              <AccessibilityIcon width={24} height={24} />
              {t('accessibilityMenuButton')}
            </MenuLink>
          </LinksSectionWrapper>
        </ShowAt>
        <StaticLinksSections />
      </SidebarBody>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  isVisitor: isVisitorUserSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onOpenAuthModal: () => dispatch(setAuthModal(
    { type: AuthenticationModalType.SignUp },
    { isUserInitiated: true, source: AuthModalSource.SignUpButton }
  )),
  openProfile: () => dispatch(setUserMobileProfileOpen(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
