import React, { useRef, useState } from 'react';
import Downshift, { ControllerStateAndHelpers, DownshiftState, StateChangeOptions } from 'downshift';
import ChevronDown from 'assets/svg/chevron-down.svg';
import ChevronUp from 'assets/svg/chevron-up.svg';
import IconClose from 'assets/svg/dropdown/close.svg';
import IconCheckmark from 'assets/svg/dropdown/selected.svg';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { ShowAt } from 'consts/breakpoints';
import { STICKY_ROOT } from 'consts/rootNodes';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { H3, SmallText, Text } from 'ds/components/typography';

import SelectItem from './SelectItem';
import { KeyPressContainer } from './KeyPressContainer';
import {
  Backdrop,
  CloseMenuButton,
  Hideable,
  ItemIcon,
  ItemList,
  Menu,
  MenuHeader,
  NoResults,
  RightIconWrapper,
  SearchSelectInput,
  SelectedIcon,
  ToggleButton,
  Wrapper,
} from './styled';
import { useLocale } from 'locale';


export interface ISelectSize {
  size?: 'large' | 'medium';
}

export interface SelectItemProps {
  value: string | number;
  label: string;
  disabled?: boolean;
  icon?: JSX.Element;
  isMobileOnly?: boolean;
}

interface CommonSelectProps<T, P> {
  initialIsOpen?: boolean;
  alignment?: 'left' | 'right';
  verticalPosition?: 'top' | 'bottom';
  selectionPromptText?: string | JSX.Element;
  items: T[];
  valueExtractor?: (item: T) => P;
  labelExtractor?: (item: T) => string;
  valueComparator?: (value1: P, value2: P) => boolean;
  renderItem?: (item: T, isSelected: boolean) => React.ReactNode;
  menuZIndex?: number;
}

interface SelectProps<T, P> extends ISelectSize, CommonSelectProps<T, P> {
  onChange: (value: P) => void;
  value?: P;
  isUncontrolled?: boolean;
  isSearchable?: boolean;
  renderSelected?: (label?: string) => string | JSX.Element;
  renderCustomToggle?: (props: {}) => React.ReactNode;
  shouldStayOpen?: (value: P) => boolean;
  shouldResetSelectedValue?: (value: P) => boolean;
  customIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  itemsContainerStyles?: React.CSSProperties;
  noBorders?: boolean;
  clickButtonEvent?: () => void;
  visibleItemsCount?: number;
  onStateChange?: (options: StateChangeOptions<T>, stateAndHelpers: ControllerStateAndHelpers<T>) => void;
  keyPressEnabled?: boolean;
  keyPressValueExtractor?: (item: T) => P;
  fullScreenMobileMenu?: boolean;
  styles?: React.CSSProperties;
  disabled?: boolean;
  searchPlaceholderText?: string;
}

const itemToString = (item: { label: string }) => item && item.label ? item.label : '';

interface ItemListContainerProps<T, P> extends Partial<ControllerStateAndHelpers<any>>, ISelectSize, CommonSelectProps<T, P> {
  isOpen: boolean;
  isSearchable?: boolean;
  copyText?: string;
  itemsContainerStyles?: React.CSSProperties;
  visibleItemsCount?: number;
  keyPressEnabled?: boolean;
  keyPressValueExtractor?: (item: T) => P;
  fullScreenMobileMenu: boolean;
  styles?: React.CSSProperties;
  menuZIndex?: number;
}

const ItemListContainer = <T extends unknown = SelectItemProps, P = string>(props: ItemListContainerProps<T, P>) => {
  const {
    isOpen,
    isSearchable,
    alignment,
    verticalPosition,
    getMenuProps,
    closeMenu,
    selectionPromptText,
    items,
    getItemProps,
    highlightedIndex,
    selectedItem,
    valueExtractor,
    valueComparator,
    renderItem,
    labelExtractor,
    visibleItemsCount,
    keyPressEnabled,
    keyPressValueExtractor,
    setHighlightedIndex,
    fullScreenMobileMenu,
    itemsContainerStyles,
    styles,
    // sorry
    menuZIndex = 1002,
  } = props;
  const [ menuIsScrolled, setMenuIsScrolled ] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const ghostNodeRef = useRef<HTMLDivElement>(null);
  const { t } = useLocale();

  useIntersectionObserver(
    ([ entry ]) => setMenuIsScrolled(!entry.isIntersecting),
    [ ghostNodeRef ],
    {
      root: parentRef,
      threshold: 0,
    }
  );
  const isSelected = (item: T) => selectedItem && valueComparator(valueExtractor(selectedItem), valueExtractor(item));
  return (
    <ShowAt at={1}>
      {matches => {
        const WrapperComponent = matches ? OverlayWrapper : React.Fragment;
        const KeyPressWrapper = keyPressEnabled ? KeyPressContainer : React.Fragment;
        const keyPressProps = keyPressEnabled ? { items, isOpen, setHighlightedIndex, highlightedIndex, valueExtractor: keyPressValueExtractor } : null;
        const wrapperComponentProps = matches ? { ...getMenuProps({ refKey: 'innerRef' }), portalId: STICKY_ROOT } : {};
        return (
          <KeyPressWrapper {...keyPressProps}>
            <WrapperComponent {...wrapperComponentProps}>
              <Hideable
                isSearchable={isSearchable}
                isVisible={isOpen}
                alignment={alignment}
                verticalPosition={verticalPosition}
                style={styles}
                data-auto="select-menu"
              >
                <Backdrop onClick={() => closeMenu()} />
                <Menu zIndex={menuZIndex} {...(matches ? {} : getMenuProps())} fullScreenMobileMenu={fullScreenMobileMenu}>
                  <MenuHeader hasShadow={menuIsScrolled}>
                    <H3 weight="medium">{selectionPromptText}</H3>
                    <CloseMenuButton onClick={() => closeMenu()}>
                      <IconClose />
                    </CloseMenuButton>
                  </MenuHeader>
                  <ItemList
                    data-auto="menu-item-root"
                    ref={parentRef}
                    visibleItemsCount={visibleItemsCount}
                    fullScreenMobileMenu={fullScreenMobileMenu}
                    style={itemsContainerStyles}
                  >
                    <div style={{ height: '0px' }} ref={ghostNodeRef} />
                    {isSearchable && !items.length ? (
                      <NoResults>{t('select.noResults')}</NoResults>
                    ) : (
                      items.map((item, index) => (
                        <SelectItem
                          data-auto="menu-item"
                          data-auto-sort-name={labelExtractor(item)}
                          highlighted={highlightedIndex === index}
                          selected={isSelected(item)}
                          key={valueExtractor(item)}
                          {...getItemProps({ index, item })}
                        >
                          {renderItem(item, isSelected(item))}
                        </SelectItem>
                      ))
                    )}
                  </ItemList>
                </Menu>
              </Hideable>
            </WrapperComponent>
          </KeyPressWrapper>
        );
      }}
    </ShowAt>
  );
};

const Select = <T extends unknown = SelectItemProps, P = string>(props: SelectProps<T, P>) => {
  const {
    initialIsOpen,
    items,
    value,
    isUncontrolled,
    isSearchable,
    onChange,
    size = 'medium',
    alignment,
    verticalPosition = 'bottom',
    renderSelected,
    valueComparator,
    selectionPromptText,
    searchPlaceholderText,
    valueExtractor,
    labelExtractor,
    renderCustomToggle,
    shouldStayOpen,
    shouldResetSelectedValue,
    customIcon,
    rightIcon,
    noBorders,
    renderItem,
    clickButtonEvent,
    visibleItemsCount,
    onStateChange,
    keyPressEnabled,
    itemsContainerStyles,
    keyPressValueExtractor,
    fullScreenMobileMenu,
    styles,
    disabled,
    menuZIndex,
  } = props;

  const [ filteredItems, setFilteredItems ] = useState<T[]>(items);
  const [ searchValue, setSearchValue ] = useState<string>('');

  const resetSearch = () => {
    setSearchValue('');
    setFilteredItems(items);
  };

  const stateReducer = (state: DownshiftState<T>, changes: StateChangeOptions<T>) => {
    if (isSearchable && !state.isOpen) resetSearch();

    switch (changes.type) {
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: shouldStayOpen && shouldStayOpen(changes.selectedItem && valueExtractor(changes.selectedItem)),
        };
      case Downshift.stateChangeTypes.clickButton:
        if (shouldResetSelectedValue && shouldResetSelectedValue(state.selectedItem && valueExtractor(state.selectedItem))) {
          return {
            ...changes,
            inputValue: null,
            selectedItem: null,
          };
        }
      case Downshift.stateChangeTypes.blurButton:
        if (isSearchable) {
          return {
            ...changes,
            isOpen: true,
          };
        }
      default:
        return changes;
    }
  };

  const TextComponent = size === 'large' ? Text : SmallText;
  const id = `down-shift-${items.map(item => valueExtractor(item)).join('-')}`;

  let selectedItemResult: T | undefined;
  if (!isUncontrolled) {
    const foundSelectedItem = items.find(item => valueComparator(valueExtractor(item), value));
    // selectedItem must not be undefined if you're using downshift a "controlled" component
    selectedItemResult = foundSelectedItem === undefined ? null : foundSelectedItem;
  }

  const onSearchSelectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    setSearchValue(targetValue);
    setFilteredItems(items.filter((i) => (i as SelectItemProps).label.includes(targetValue.trim())));
  };

  const SelectedText: React.FC<{ selectedItem: any }> = ({ selectedItem }) => (
    <TextComponent data-auto="sort-selector" className="select-value-text">
      {selectionPromptText && !selectedItem
        ? selectionPromptText
        : renderSelected(selectedItem && labelExtractor(selectedItem))}
    </TextComponent>
  );

  return (
    <Downshift
      id={id}
      initialIsOpen={initialIsOpen}
      itemToString={itemToString}
      selectedItem={selectedItemResult}
      onChange={(item) => item && !item.disabled && onChange(valueExtractor(item))}
      stateReducer={stateReducer}
      onStateChange={onStateChange}
    >
      {(renderProps) => {
        const { getToggleButtonProps, getRootProps, selectedItem, isOpen, closeMenu } = renderProps;
        const onChevronDownClick = (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          closeMenu();
        };

        return (
          <Wrapper {...getRootProps()} onClick={isOpen ? null : clickButtonEvent}>
            {renderCustomToggle ? renderCustomToggle(getToggleButtonProps()) : (
              <ToggleButton
                {...getToggleButtonProps()}
                greenBorder={isSearchable && isOpen}
                size={size}
                noBorders={noBorders}
                className="select-value-button"
                disabled={disabled}
              >
                {rightIcon && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
                {isSearchable ? (
                  <>
                    {isOpen ? (
                      <SearchSelectInput
                        autoFocus
                        value={searchValue}
                        onChange={onSearchSelectInputChange}
                        placeholder={searchPlaceholderText}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    ) : (
                      <SelectedText selectedItem={selectedItem} />
                    )}
                  </>
                ) : (
                  <SelectedText selectedItem={selectedItem} />
                )}
                {isSearchable && items && items.length === 1
                  ? null
                  : (customIcon ? customIcon : (isOpen ? <ChevronUp onClick={onChevronDownClick} /> : <ChevronDown />))
                }
              </ToggleButton>
            )}
            {isOpen ? (
              <ItemListContainer
                styles={styles}
                valueExtractor={valueExtractor}
                labelExtractor={labelExtractor}
                valueComparator={valueComparator}
                selectionPromptText={selectionPromptText}
                items={isSearchable ? filteredItems : items}
                itemsContainerStyles={itemsContainerStyles}
                isSearchable={isSearchable}
                alignment={alignment}
                verticalPosition={verticalPosition}
                renderItem={renderItem}
                visibleItemsCount={visibleItemsCount}
                keyPressEnabled={keyPressEnabled}
                keyPressValueExtractor={keyPressValueExtractor}
                fullScreenMobileMenu={fullScreenMobileMenu}
                menuZIndex={menuZIndex}
                {...renderProps}
              />
            ) : null}
          </Wrapper>
        );
      }}
    </Downshift>
  );
};

Select.defaultProps = {
  renderSelected: (label: string) => label,
  valueComparator: (value1: any, value2: any) => value1 === value2,
  valueExtractor: (item: SelectItemProps) => item.value,
  labelExtractor: (item: SelectItemProps) => item.label,
  renderItem: (item: SelectItemProps, isSelected: boolean) => (
    <>
      {item.icon ? (
        <ItemIcon>{item.icon}</ItemIcon>
      ) : null}
      <ShowAt at={1}>
        <Text>{item.label}</Text>
      </ShowAt>
      <ShowAt from={2}>
        <SmallText>{item.label}</SmallText>
      </ShowAt>
      {isSelected ? (
        <SelectedIcon>
          <IconCheckmark />
        </SelectedIcon>
      ) : null}
    </>
  ),
};

export default Select;
