import { State } from 'store/state';
import { LoadType } from 'store/sagas/apiService/types';
import { createSelector } from 'reselect';
import { flow, keyBy } from 'lodash';
import { Domain } from '../';


type DomainGetter<T extends LoadType> = (state: State) => Domain<T>;

export const makeDomainGetter = <T extends LoadType>(domain: T) => (state: State) => state.domainData[domain];

export const addressSearchSelector = makeDomainGetter(LoadType.AddressSearch);
export const streetSearchSelector = makeDomainGetter(LoadType.StreetSearch);
export const searchProjectListSelector = makeDomainGetter(LoadType.SearchProjectList);
export const searchListSelector = makeDomainGetter(LoadType.SearchList);
export const searchDocSelector = makeDomainGetter(LoadType.SearchDoc);
export const getOfficeListingsSelector = makeDomainGetter(LoadType.GetOfficeListings);
export const localDataSelector = makeDomainGetter(LoadType.LocalData);
export const addressDocSelector = makeDomainGetter(LoadType.AddressDoc);
export const localDocSelector = makeDomainGetter(LoadType.LocalDoc);
export const localDocPreviewSelector = makeDomainGetter(LoadType.LocalDocPreview);
export const commercialLocalInfoDomainSelector = makeDomainGetter(LoadType.CommercialLocalInfo);
export const areasWithCommercialInfoPageDomainSelector = makeDomainGetter(LoadType.AreasWithCommercialInfoPage);
export const searchLocalAddressPageDomainSelector = makeDomainGetter(LoadType.SearchLocalAddressPage);
export const strictLocalDocSelector = makeDomainGetter(LoadType.StrictLocalDoc);
export const strictUnitDocSelector = makeDomainGetter(LoadType.StrictUnitDoc);
export const insightsSelector = makeDomainGetter(LoadType.Insights);
export const searchMapListingsSelector = makeDomainGetter(LoadType.SearchMapListings);
export const searchMapDataLayersSelector = makeDomainGetter(LoadType.SearchMapDataLayers);
export const searchMapClustersSelector = makeDomainGetter(LoadType.SearchMapClusters);
export const userSelector = makeDomainGetter(LoadType.CurrentUser);
export const resetPasswordVerifySelector = makeDomainGetter(LoadType.ResetPasswordVerify);
export const unitBulletinsByIdsSelector = makeDomainGetter(LoadType.UnitPageBulletin);
export const unitCommercialBulletinsByIdsSelector = makeDomainGetter(LoadType.UnitPageCommercialBulletin);
export const dealByIdsSelector = makeDomainGetter(LoadType.Deal);
export const projectPageProjectsByIdsSelector = makeDomainGetter(LoadType.ProjectPageProject);
export const projectPageCommercialProjectsByIdsSelector = makeDomainGetter(LoadType.ProjectPageCommercialProject);
export const mapPreviewSelector = makeDomainGetter(LoadType.MapPreview);
export const mapMultiPreviewSelector = makeDomainGetter(LoadType.MapMultiPreview);
export const ilHomePageBulletinsSelector = makeDomainGetter(LoadType.IlHomePageBulletins);
export const searchBySearchIdSelector = makeDomainGetter(LoadType.SearchBySearchId);
export const shortlistPoisSelector = makeDomainGetter(LoadType.ShortlistPois);
export const favoritesPoisSelector = makeDomainGetter(LoadType.FavoritesPois);
export const userListingsSelector = makeDomainGetter(LoadType.UserAllListings);
export const userListingsWithOfficesSelector = makeDomainGetter(LoadType.AdminSearch);
export const userContentDomainSelector = makeDomainGetter(LoadType.UserContent);
export const userContentTextReviewsDomainSelector = makeDomainGetter(LoadType.UserContentTextReviews);
export const userContentRatingReviewsDomainSelector = makeDomainGetter(LoadType.UserContentRatingReviews);
export const madadDomainSelector = makeDomainGetter(LoadType.Madad);
export const madadParticipantsDomainSelector = makeDomainGetter(LoadType.MadadParticipants);
export const madadAgentsOfficesDomainSelector = makeDomainGetter(LoadType.MadadAgentsOffices);
export const madadSearchAgentsOfficesDomainSelector = makeDomainGetter(LoadType.MadadSearchAgentsOffices);
export const homePageBannerDomainSelector = makeDomainGetter(LoadType.HomePageBanner);
export const bannersByDocIdDomainSelector = makeDomainGetter(LoadType.BannersByDocId);
export const searchPageBannersByDocIdDomainSelector = makeDomainGetter(LoadType.SearchPageBannersByDocId);
export const additionalBannersByDocIdDomainSelector = makeDomainGetter(LoadType.AdditionalBannersByDocId);
export const madadWinnersBannersByDocIdDomainSelector = makeDomainGetter(LoadType.MadadWinnersBannersByDocId);
export const madadWinnersBannersForStreetByDocIdsDomainSelector = makeDomainGetter(LoadType.MadadWinnersBannersForStreetByDocId);
export const madadWinnersBannersExistForDocIdDomainSelector = makeDomainGetter(LoadType.MadadWinnersExistForDocId);
export const madadWinnersBannersForStreetExistForDocIdDomainSelector = makeDomainGetter(LoadType.MadadWinnersExistForStreetForDocId);
export const tabuByDocIdDomainSelector = makeDomainGetter(LoadType.Tabu);
export const footerByDocIdDomainSelector = makeDomainGetter(LoadType.FooterByDocId);
export const busRouteByIdsDomainSelector = makeDomainGetter(LoadType.BusRouteByIds);
export const editListingPoiSelector = makeDomainGetter(LoadType.EditListingPoi);
export const madadAgentBannerSelector = makeDomainGetter(LoadType.MadadAgentBanner);
export const projectMapLogoDomainSelector = makeDomainGetter(LoadType.ProjectMapLogo);
export const isPhoneRegisteredDomainSelector = makeDomainGetter(LoadType.IsPhoneRegistered);
export const developerByIdSelector = makeDomainGetter(LoadType.DeveloperById);
export const searchDevelopersIndexSelector = makeDomainGetter(LoadType.SearchDevelopers);
export const officeByIdSelector = makeDomainGetter(LoadType.OfficeById);
export const officeDataByIdSelector = makeDomainGetter(LoadType.OfficeDataById);
export const officeBulletinsByIdSelector = makeDomainGetter(LoadType.OfficeBulletinsById);
export const agentByIdSelector = makeDomainGetter(LoadType.AgentById);
export const agentWithBulletinsByIdSelector = makeDomainGetter(LoadType.AgentWithBulletinsById);
export const searchAgentAwardsByIds = makeDomainGetter(LoadType.SearchAwardsByIds);
export const getUnitPageLeadingDataSelector = makeDomainGetter(LoadType.GetUnitPageLeadingData);
export const searchAgentAwardsByDocIds = makeDomainGetter(LoadType.SearchAwardsByDocIds);
export const agentContactInfoByIdSelector = makeDomainGetter(LoadType.AgentContactInfoById);
export const agentBulletinsByIdSelector = makeDomainGetter(LoadType.AgentBulletinsById);
export const teamByOfficeIdSelector = makeDomainGetter(LoadType.TeamByOfficeId);
export const mortgageOfficesIndexSelector = makeDomainGetter(LoadType.MortgageOfficesByCityDocIds);
export const additionalListingCarouselSearchSelector = makeDomainGetter(LoadType.AdditionalCarouselSearch);
export const listingPostLeadCarouselSearchSelector = makeDomainGetter(LoadType.ListingPostLeadCarouselSearch);
export const mortgageOfficeByIdSelector = makeDomainGetter(LoadType.MortgageOfficeById);
export const multipleDevelopersSelector = makeDomainGetter(LoadType.DevelopersAndPromotedProjects);
export const userNotificationDevicesDomainSelector = makeDomainGetter(LoadType.UserNotificationDevices);
export const featuredMortgageOfficeDomainSelector = makeDomainGetter(LoadType.FeaturedMortgageOffice);
export const getAffiliationRequestsSelector = makeDomainGetter(LoadType.GetDealsAffiliationRequests);
export const getDealAffiliationRequestsBySaleIdSelector = makeDomainGetter(LoadType.GetDealAffiliationRequestsBySaleId);
export const searchDealsSelector = makeDomainGetter(LoadType.SearchDeals);
export const recommendedListingsDomainSelector = makeDomainGetter(LoadType.GetRecommendedListings);

export const makeByIdsDictionarySelector = <T extends { id: string }, K extends unknown[]>(
  listSelector: (state: State, ...args: K) => T[]
): (state: State, ...args: K) => Record<string, T> => createSelector(listSelector, (list): Record<string, T> => keyBy(list, (item) => item.id));

export const makeNullSafeDomainGetter = <T extends LoadType>(
  selector: DomainGetter<T>,
  field: keyof Domain<T>['data'],
  ignoreLoading = false
) => flow(selector, (domain) => {
  if (!domain || !domain.data || (!ignoreLoading && domain.loading)) return null;
  return domain.data[field];
});

export const createNullSafeDomainMetaVariablesSelector = <T extends LoadType>(selector: DomainGetter<T>) => (
  flow(selector, domain => domain && domain.meta && domain.meta.variables || null)
);


export const createIsLoadingSelector = <T extends LoadType>(selector: DomainGetter<T>) => flow(selector, ({ loading }) => loading);
