import React, { useState, useRef, useCallback } from 'react';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import SearchIcon from 'assets/svg/search3.svg';
import LocationIcon from 'assets/svg/location-gallery.svg';
import { config as completionTypesConfig, CompletionListGroup } from 'components/commute-popup/CompletionTypeConfig';
import { buildSearchPayload, getResidentialNavigationParams, IAutocompleteTrackingEventMeta } from 'components/search-widget/helpers';
import { AddressAutocompleteSource } from 'components/autocomplete/types';
import { SearchButton, AutocompleteInputWrapper } from 'components/check-address/styled';
import { IAutocompleteEntry } from 'utils/entities';
import { Route, State as RouteState, NavigateToFn  } from 'config/routes';
import { AppMode } from 'store/state/selectors/router';
import { StepOrder } from 'components/user-generated-content/utils';
import { useLocale } from 'locale';
import withEvents from 'analytics/withEvents';


const MAX_RESULT_SHOWN_COUNT = 6;

interface AutocompleteProps {
  isMobileOrTablet: boolean;
  isMobile: boolean;
  route: RouteState;
  navigate: NavigateToFn;
  onSearch?: (meta?: IAutocompleteTrackingEventMeta) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ isMobileOrTablet, isMobile, route, navigate, onSearch }) => {
  const { t } = useLocale();
  const [ searchStr, setSearchStr ] = useState('');
  const inputRef = useRef(null);
  const isUGCInvite = route.params.ugc_invite === 'true';

  const handleSearch = useCallback((value: IAutocompleteEntry) => {
    if (!value) {
      return;
    }
    if (onSearch) {
      onSearch({
        selectedItems: [ value ],
        searchMode: AppMode.Check,
      });
    }
    if (value.docId) {
      const { route: navRoute, params, options } = getResidentialNavigationParams(value, AppMode.Check);
      if (navRoute === Route.Search) params.tracking_search_source = 'new_search';
      if (isUGCInvite) params.initialQuestion = StepOrder.QuestionsFirst;
      navigate(navRoute, params, options);
    }
  }, [ route, navigate ]);

  const handleSearchButtonClick = (mobileOrTablet: boolean) => {
    const input = inputRef.current.querySelector('input');
    mobileOrTablet ? input.click() : input.focus();
  };

  return (
    <>
      <AutocompleteInputWrapper isMobileOrTablet={isMobileOrTablet} ref={inputRef}>
        <AddressAutocompleteInput
          showType
          disableHebrewLayoutConvert
          optionsCount={MAX_RESULT_SHOWN_COUNT}
          completionTypes={completionTypesConfig[CompletionListGroup.CheckAddressTypes]}
          searchStr={searchStr}
          onSearchStrChange={setSearchStr}
          placeholder={isMobile ?
            t('checkAddress.autocomplete.placeholder.short') :
            t('checkAddress.autocomplete.placeholder')}
          inputIcon={<LocationIcon width={20} height={20}/>}
          onChange={handleSearch}
          source={AddressAutocompleteSource.CheckAddress}
          initialValue={null}
        />
      </AutocompleteInputWrapper>
      <SearchButton onClick={() => handleSearchButtonClick(isMobileOrTablet)}>
        <SearchIcon style={{ verticalAlign: 'middle', marginLeft: 8 }}/>
        {t('checkAddress.autocomplete.searchButton')}
      </SearchButton>
    </>
  );
};

export default withEvents<AutocompleteProps>(sendEvent => ({
  onSearch(meta) {
    sendEvent('search_submit', 'search', {
      event: {
        source: 'check_address',
      },
      search: buildSearchPayload(meta),
    });
  },
}))(Autocomplete);
