import { RootAction } from 'store/state';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from 'store/sagas/apiService/types';
import { select, take } from 'redux-saga/effects';
import { isUnitPageBulletinLoadingSelector, isUnitPageCommercialBulletinLoadingSelector, isProjectPageProjectLoadingSelector, isCommercialProjectPageProjectLoadingSelector } from 'store/state/domainData/selectors';
import { createSelector } from 'reselect';
import { CompletionType, IAutocompleteEntry } from 'utils/entities';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';


const isPoiLoadingSelector = createSelector([
  routeSelector,
  isUnitPageBulletinLoadingSelector,
  isCommercialProjectPageProjectLoadingSelector,
  isUnitPageCommercialBulletinLoadingSelector,
  isProjectPageProjectLoadingSelector,
], (route, isBulletinLoading, isCommercialProgectLoading, isCommercialBulletinLoading, isProjectLoading) => {
  if (!route) return false;

  switch (route.name) {
    case Route.UnitPage:
      return isBulletinLoading;
    case Route.UnitPageCommercial:
      return isCommercialBulletinLoading;
    case Route.ProjectPage:
      return isProjectLoading;
    case Route.ProjectPageCommercial:
      return isCommercialProgectLoading;
    default:
      return false;
  }
});

export const addressResolvePattern = (action: RootAction) => {
  if (action.type === SET_DOMAIN_DATA) {
    return (action.loadType === LoadType.SearchDoc || action.loadType === LoadType.AddressDoc) && Boolean(action.payload.data);
  }
  return false;
};

export const poiResolvePattern = (action: RootAction) => {
  if (action.type === SET_DOMAIN_DATA) {
    return (
      action.loadType === LoadType.UnitPageBulletin
      || action.loadType === LoadType.ProjectPageProject
      || action.loadType === LoadType.ProjectPageCommercialProject
      || action.loadType === LoadType.UnitPageCommercialBulletin
    ) && Boolean(action.payload.data);
  }
  return false;
};

export function* waitForPoiResolve() {
  const isPoiLoading: boolean = yield select(isPoiLoadingSelector);

  if (isPoiLoading) {
    yield take(poiResolvePattern);
  }
}

export const MAX_ZOOM = 18;
const PAGINATION_LIMIT = 50;

export const makeOffset = (page: number, limit = PAGINATION_LIMIT) => ({
  offset: (page - 1) * limit,
  limit,
});

export const hasNextPage = (page: number, total: number) => (
  page * PAGINATION_LIMIT < total
);

export const getDocId = (address: IAutocompleteEntry) => {
  const isAddress = address.type === CompletionType.Address;
  if (isAddress && address.relevantDocIds) {
    const hood = address.relevantDocIds.find(el => el.type === CompletionType.Neighbourhood);
    return hood ? hood.docId : address.docId;
  }
  return address.docId;
};
