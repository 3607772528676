import React from 'react';
import { Condition, GenericFilterProps } from '../types';
import { useLocale } from 'locale';
import { PerformantCheckboxGroup } from '../common/PerformantCheckbox';
import { SecondaryTitle, Section, SectionBody, SectionTitle } from '../styled/common';
import FilterSection from '../common/DropdownSection';
import { isFilterActiveByType } from '../utils';
import styled from '@emotion/styled';
import { MarketplaceType } from 'utils/entities';

const Description = styled.span`
  color: ${({ theme }) => theme.colors.neutrals.grey4};
`;

const Conditions: React.FC<GenericFilterProps <'conditions'>> = ({
  intermediateValue,
  setIntermediateValue,
  isInline,
  commitValues,
  commitedValue,
  resetValue,
  config,
  type,
  alignment,
  initialValue,
  marketplace,
}) => {
  const { t } = useLocale();
  const { conditions, filtersIcons } = config;
  const isCommercial = marketplace === MarketplaceType.Commercial;

  const checkboxLabelGetter = (value: Condition) => {
    return (
      <span>
        {t('filters.conditionsOptions', { value })}
        {isCommercial ? null : <Description>{t('filters.conditionsOptionsDescription', { value })}</Description>}
      </span>
    );
  };

  const checkboxGroup = (
    <PerformantCheckboxGroup
      values={intermediateValue}
      options={conditions}
      labelGetter={checkboxLabelGetter}
      valueSetter={setIntermediateValue}
    />
  );

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto-filter-name="conditions" data-auto={`filter-${type}`}>
        <SectionTitle isBold data-auto="conditionsTitleRoot">
          <div data-auto="conditionsTitle">
            {t('filters.conditions', { count: null })}
            {intermediateValue.length ? (
              <SecondaryTitle data-auto="selectedTypesCount">
                {intermediateValue.length}
              </SecondaryTitle>
            ) : null}
          </div>
        </SectionTitle >
        <SectionBody data-auto="filterCheckboxes">
          {checkboxGroup}
        </SectionBody>
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={!isFilterActiveByType.conditions(intermediateValue, initialValue)}
      type={type}
      alignment={alignment}
      icon={filtersIcons[type]}
      onClear={resetValue}
      label={t('filters.conditions', { count: commitedValue.length })}
      onSubmit={commitValues}
      isActive={isFilterActiveByType.conditions(commitedValue, initialValue)}
    >
      {checkboxGroup}
    </FilterSection>
  );
};

export default React.memo(Conditions);
