import React from 'react';
import { useLocale, ITranslate } from 'locale';
import { useField } from 'react-final-form-hooks';
import { Section, SectionHeading, InputWrapper } from 'screens/UploadBulletinPage/styled';
import { UploadBulletinStepProps } from 'screens/UploadBulletinPage/steps';
import { isNotEmpty, isPositiveNumber } from 'utils/formValidationHelpers';
import { SpacingBlock } from 'components/spacing-block';
import NumberInput from 'screens/UploadBulletinPage/components/number-input';

const MAX_VALUE = 999999;

const validateField = (t: ITranslate) => (val: string) => {
  if (isNotEmpty(val) && isPositiveNumber(val)) {
    return +val > MAX_VALUE ? t('uploadBulletinPage.maxValueFieldValidationMessage', { forMoney: false }) : undefined;
  }
  return t('uploadBulletinPage.emptyFieldValidationMessage');
};

export const AreaSection: React.FC<UploadBulletinStepProps> = ({ formInstance, hideErrorOnFocus }) => {
  const { t } = useLocale();

  const area = useField('area', formInstance, validateField(t));

  return (
    <Section>
      <SpacingBlock mBottom={1}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.mainInfoStep.areaHeading')}
        </SectionHeading>
      </SpacingBlock>
      <InputWrapper error={area.meta.touched && area.meta.error}>
        <NumberInput
          {...area.input}
          {...area.meta}
          onFocus={() => hideErrorOnFocus(true)}
          onBlur={() => hideErrorOnFocus(false)}
          placeholder={t('uploadBulletinPage.mainInfoStep.areaPlaceholder')}
        />
      </InputWrapper>
    </Section>
  );
};
