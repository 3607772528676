import { AvailabilityType, MarketplaceType } from 'utils/entities';
import { memoize } from 'lodash';
import { ITranslate } from 'locale';
import { config } from 'components/filters/config';
import { PropertyType } from 'components/filters/types';


export const genFloorOptions = memoize((t: ITranslate, marketplace: MarketplaceType) => config[marketplace].uploadFloorsOptions.map((value) => ({
  label: t('filters.floorLabels', { floor: value }) || `${value}`,
  value,
})));

const AVAILABILITY_OPTIONS: AvailabilityType[]  = [
  AvailabilityType.Immediate,
  AvailabilityType.Flexible,
  AvailabilityType.Date,
];

export const genAvailabilityTypeOptions = memoize((t) => AVAILABILITY_OPTIONS.map(value => ({
  label: t('uploadBulletinPage.mainInfoStep.availability.options', { value }),
  value,
})));

export const MAX_ROOMS = 10;

export const genBedsOptions = (t: ITranslate, includeEverything?: boolean) => {
  const options = new Array(MAX_ROOMS).fill(null).map((_, index) => {
    const roomNumber = index + 1;

    return {
      label: t('uploadBulletinPage.mainInfoStep.bedsNumberLabel', { isMax: roomNumber === MAX_ROOMS, roomNumber }),
      value: roomNumber,
    };
  });

  const emptyOption = {
    label: t('aria.form.infinity'),
    value: -1,
  };

  if (includeEverything) options.unshift(emptyOption);

  return options;
};

export const genPropertyTypeOptions = (t: ITranslate, isCommercial: boolean) => (value: PropertyType) => ({
  label: t('uploadBulletin.mainInfoStep.buildingClassOptions', { value, isCommercial }),
  value,
});
