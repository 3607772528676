import React from 'react';

import { InstaPager } from './InstaPager';

export interface PagerState {
  left: number;
  right: number;
}
export interface PagerProps {
  total: number;
  onChangeIndex: (i: number) => void;
  index: number;
  limit?: number;
}

const BULLETS_LIMIT = 4;

export class Pager extends React.PureComponent<PagerProps, PagerState> {

  constructor(props: PagerProps) {
    super(props);

    this.state = {
      left: 0,
      right: Math.min(props.total, props.limit || BULLETS_LIMIT) - 1,
    };
  }

  public static getDerivedStateFromProps(props: PagerProps, state: PagerState) {
    const { index } = props;
    let { left, right } = state;

    if ((index >= state.right - 1) && (state.right + 1 < props.total)) {
      right = state.right + 1;
      left = state.left + 1;
    }

    if ((index <= state.left + 1) && (state.left - 1 >= 0)) {
      right = state.right - 1;
      left = state.left - 1;
    }

    return { left, right };
  }


  public render() {

    return (
      <InstaPager
        total={this.props.total}
        left={this.state.left}
        right={this.state.right}
        update={this.props.onChangeIndex}
        current={this.props.index}
      />
    );
  }
}
