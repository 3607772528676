import { select, call } from 'redux-saga/effects';
import { LoadType } from 'store/sagas/apiService/types';
import { neighborhoodDocIdSelector } from 'store/state/domainData/selectors/addressDoc';
import { addressPageMadadWinnersSelector } from 'store/state/domainData/selectors/searchLocalAddressPage';
import { queryData } from 'store/sagas/apiService';
import { ILocalPageVariables } from 'utils/entities';


export function* madadWinnersHandler() {
  const neighborhoodDocId = yield select(neighborhoodDocIdSelector);
  const data = yield select(addressPageMadadWinnersSelector);
  if (neighborhoodDocId && !data) {
    const variables: ILocalPageVariables = { docId: neighborhoodDocId };
    yield call(queryData, {
      loadType: LoadType.SearchLocalAddressPage,
      meta: { variables },
    });
  }
}
