import React, { useState } from 'react';
import Modal from 'ds/components/modal';
import { SmallText, Text } from 'ds/components/typography';
import { useLocale } from 'locale';
import * as Styled from './styled';
import { useScreenBreakpoint } from 'consts/breakpoints';
import CodeInput from 'components/code-input';
import { Button } from 'ds/components/button';
import { PhoneValidationStatus, ICheckValidationCodeVariables } from 'store/sagas/apiService/types';

interface ValidatePhoneModalProps {
  phoneNumber: string;
  onClose: () => void;
  resendCode: () => void;
  submitCode: (val: ICheckValidationCodeVariables) => void;
  error?: PhoneValidationStatus;
  isLoading: boolean;
}

const CODE_LENGTH = 4;

const ValidatePhoneModal: React.FC<ValidatePhoneModalProps> = ({
  onClose,
  phoneNumber,
  error,
  resendCode,
  submitCode,
  isLoading,
}) => {
  const [ code, setCode ] = useState('');
  const { t } = useLocale();
  const isMobile = useScreenBreakpoint() < 3;
  const InfoText = isMobile ? SmallText : Text;
  const isBlocked = error === PhoneValidationStatus.Blocked;
  const isDisabled = code.length !== CODE_LENGTH || isLoading || isBlocked
    || !!code.split('').filter(n => isNaN(parseInt(n, 10))).length;
  const InvalidCodeError = error ? (<>{t('uploadBulletin.validatePhoneModal.error.invalidCode')}</>) : null;
  const email = t('uploadBulletin.validatePhoneModal.contactMail.email');
  const subjectText = t('uploadBulletin.validatePhoneModal.contactMail.subject');
  const bodyText = t('uploadBulletin.validatePhoneModal.contactMail.body');
  const emailUrl = `mailto:${email}?subject=${subjectText}&body=${bodyText}`;
  const onSubmitCode = () => submitCode({ phoneNumber, code });
  const closeModal = () => {
    setCode('');
    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={closeModal}
      title={t('uploadBulletin.validatePhoneModal.title')}
      desktopModalPositionCenter
      isFullWidthMainContent={isMobile}
    >
      <Styled.Wrapper>
        <Styled.InfoBox>
          <InfoText>{t('uploadBulletin.validatePhoneModal.info.title')}</InfoText>
          <Styled.PhoneNumber weight="medium">{phoneNumber}</Styled.PhoneNumber>
          <InfoText>
            {t('uploadBulletin.validatePhoneModal.info.subtitle')}
            <Styled.CloseModal onClick={closeModal}>{t('uploadBulletin.validatePhoneModal.info.close')}</Styled.CloseModal>
          </InfoText>
        </Styled.InfoBox>
        <form noValidate>
          <Styled.CodeWrapper>
            <CodeInput
              disabled={isBlocked}
              codeLength={CODE_LENGTH}
              value={code}
              setValue={setCode}
              resetFocus
              error={InvalidCodeError}
            />
          </Styled.CodeWrapper>
          <Button
            size={isMobile ? 'extraLarge' : 'large'}
            fullWidth
            disabled={isDisabled}
            onClick={onSubmitCode}
          >
            {t('uploadBulletin.validatePhoneModal.submitCode')}
          </Button>
          <Styled.ResendCodeWrapper>
            {isBlocked ? (
              <>
                <Styled.BlockedError>{t('uploadBulletin.validatePhoneModal.error.blocked')}</Styled.BlockedError>
                <Styled.BlockedContact>
                  {t('uploadBulletin.validatePhoneModal.error.blockedHint')}
                  <a href={emailUrl}>{t('uploadBulletin.validatePhoneModal.error.blockedLink')}</a>
                </Styled.BlockedContact>
              </>
            ) : (
              <>
                {t('uploadBulletin.validatePhoneModal.resendCode.description')}
                <Styled.ResendCode onClick={resendCode}>
                  {t('uploadBulletin.validatePhoneModal.resendCode.btn')}
                </Styled.ResendCode>
              </>
            )}
          </Styled.ResendCodeWrapper>
        </form>
      </Styled.Wrapper>
    </Modal>
  );
};

export default ValidatePhoneModal;
