import { select, takeLatest, fork, put } from 'redux-saga/effects';
import { REQUEST_TABU_BY_DOC_ID } from 'store/state/app/types';
import { docIdForTabuSelector } from 'components/tabu/selectors';
import { tabuByDocIdHandler } from './routing/handlers/tabu';
import { RootAction } from 'store/state';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from './apiService/types';
import { navigateTo } from 'store/state/router/actions';
import { routeSelector, DialogWithURLType } from 'store/state/selectors/router';
import { State as RouteState, DecodedRouteParams } from 'config/routes';
import { validTabuByDocIdSelector } from 'store/state/domainData/selectors/tabuByDocId';
import { Tabu } from 'utils/entities';


export const tabuFoundPattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.Tabu
  && Boolean(action.payload.data && action.payload.data.docId2NesachTabu && action.payload.data.docId2NesachTabu.downloadToken)
);

function* tabuFoundHandler() {
  const { name, params }: RouteState = yield select(routeSelector);
  const tabu: Tabu = yield select(validTabuByDocIdSelector);

  const paramsToGo: DecodedRouteParams = {
    ...params,
    dialog: {
      type: DialogWithURLType.TabuWizard,
      meta: {
        ...tabu,
        dialog: DialogWithURLType.TabuWizard,
      },
    },
  };

  yield put(navigateTo(name, paramsToGo));
}

function* requestTabuHandler() {
  const docId: string = yield select(docIdForTabuSelector);

  yield fork(tabuByDocIdHandler, docId);
}

export function* tabuWatcher() {
  yield takeLatest(REQUEST_TABU_BY_DOC_ID, requestTabuHandler);
  yield takeLatest(tabuFoundPattern, tabuFoundHandler);
}
