import { connect } from 'react-redux';
import MobileDiscovery from './mobile-discovery';
import { setMobileDiscoveryPopupStatus } from 'store/state/app/actions';


const mapDispatchToProps = {
  setModalStatus: setMobileDiscoveryPopupStatus,
};

export default connect(null, mapDispatchToProps)(MobileDiscovery);
