import { reverse, values, orderBy } from 'lodash';
import {
  createIsLoadingSelector,
  favoritesPoisSelector,
  makeNullSafeDomainGetter,
  shortlistPoisSelector,
  makeByIdsDictionarySelector,
} from './common';
import { createSelector } from 'reselect';
import {
  IBulletin,
  ICommercialBulletin,
  ICommercialProject,
  IProject,
  DatedPoi,
} from 'utils/entities';
import {
  savedDatedPoisSelector,
  lastViewedIdsSelector,
  lastContactedIdsSelector,
} from 'store/state/domainData/selectors/currentUser';
import uniqBy from 'lodash/uniqBy';
import { poiMapper } from 'store/sagas/routing/shortlistPage';
import { State } from 'store/state/index';
import { daysFrom } from 'helpers/dateUtils';
import { parseISODate } from 'locale/formatDate';

const EMPTY: Array<IBulletin | IProject | ICommercialProject | ICommercialBulletin> = [];

export const baseFavoritesPoisSelector = makeNullSafeDomainGetter(favoritesPoisSelector, 'poiByIds', true);
export const isLoadingFavoritesPoisSelector = createIsLoadingSelector(favoritesPoisSelector);

export const favoritesPoisListSelector = createSelector(
  baseFavoritesPoisSelector,
  (data => data || EMPTY)
);

export const baseShortlistPoisSelector = makeNullSafeDomainGetter(shortlistPoisSelector, 'poiByIds');
export const isShortlistLoadingSelector = createIsLoadingSelector(shortlistPoisSelector);

const shortlistPoisListSelector = createSelector(baseShortlistPoisSelector, data => data || EMPTY);


export const orderedFavoritesListSelector = createSelector(
  savedDatedPoisSelector,
  (list) => reverse(list));

const uniqOrderByDate = (data: DatedPoi[]): DatedPoi[] => {
  const datedMap = data.reduce((res, item) => {
    if (res[item.poiId.id]) {
      const resDate = new Date(res[item.poiId.id].date);
      const itemDate = new Date(item.date);
      res[item.poiId.id] = resDate > itemDate ? res[item.poiId.id] : item;
    }
    else {
      res[item.poiId.id] = item;
    }
    return res;
  } , {});

  const list: DatedPoi[] = values(datedMap);

  return orderBy(list, item => new Date(item.date), 'desc');
};

export const lastContactedListSelector = createSelector(
  lastContactedIdsSelector,
  (list: DatedPoi[]): DatedPoi[] => uniqOrderByDate(list)
);

const MAX_RECENTLY_VIEWED = 20;
export const lastViewedListSelector = createSelector(lastViewedIdsSelector,
  list => {
    const relevantDays = 90;
    const mostRecentItems = list.filter(i => daysFrom(parseISODate(i.date)) < relevantDays);
    return uniqBy(mostRecentItems, i => i.poiId.id).slice(0, MAX_RECENTLY_VIEWED);
  }
);

export const shortlistPoisIdsSelector = createSelector([
  lastContactedListSelector,
  lastViewedListSelector,
], (contacted, viewed) => {
  const list = [ ...contacted, ...viewed ];
  const uniqPois: DatedPoi[] = uniqBy(list, (datedPoi: DatedPoi) => datedPoi.poiId.id);
  return uniqPois.map(poiMapper);
});

const favoritesAndShortlistPoisSelector = createSelector([
  favoritesPoisListSelector,
  shortlistPoisListSelector,
], (favorites, shortlist ) => [ ...favorites, ...shortlist ]);

export const makeShortlistIdsSelector = (userPoisSelector: (state: State) => DatedPoi[]) => createSelector([
  userPoisSelector,
  favoritesAndShortlistDictSelector,
], (userPois, shortListPois) =>
    userPois.filter(({ poiId }) => !!shortListPois[poiId.id])
);

export const favoritesAndShortlistDictSelector = makeByIdsDictionarySelector(favoritesAndShortlistPoisSelector);

export const userFavoritesSelector = createSelector([
  orderedFavoritesListSelector,
  favoritesAndShortlistDictSelector,
], (favorites, poisDict) =>
  favorites.map(({ poiId }) => poisDict[poiId.id]).filter(Boolean)
);

const PREVIEW_MAX_ITEMS = 2;
export const userFavoritesPreviewPoisSelector = createSelector(
  userFavoritesSelector,
  (list) => list.slice(0, PREVIEW_MAX_ITEMS)
);

export const userFavoritesPreviewPoisCountSelector = createSelector(
  userFavoritesPreviewPoisSelector,
  list => list.length
);

export const userFavoritesCounterSelector = createSelector(
  userFavoritesSelector,
  (list): number => list.length || 0
);
