import { isServer } from 'utils';

export function isIOS(userAgent: string) {
  return isIPhone(userAgent) || isIPad(userAgent);
}

export function isAndroid(userAgent: string) {
  if (!isServer() && window.location.href.indexOf('devSmallMapTest') !== -1) {
    return false;
  }

  return /android/i.test(userAgent);
}

function isIPhone(userAgent: string) {
  if (!isServer() && window.location.href.indexOf('devSmallMapTest') !== -1) {
    return false;
  }

  return /iPhone/i.test(userAgent) && /WebKit/i.test(userAgent) && !(/(FxiOS|OPiOS|mercury)/i.test(userAgent));
}

function isIPad(userAgent: string) {
  if (!isServer() && window.location.href.indexOf('devSmallMapTest') !== -1) {
    return false;
  }

  return /iPad/i.test(userAgent) && /WebKit/i.test(userAgent) && !(/(FxiOS|OPiOS|mercury)/i.test(userAgent));
}

export function isWindows(userAgent: string) {
  return /Windows/i.test(userAgent);
}

export const isStandaloneIOS = () => 'standalone' in window.navigator && Boolean((window.navigator as any).standalone);

export function isStandaloneMode() {
  // Safari < 13
  if (isStandaloneIOS()) {
    return true;
  }
  // normie browsers
  return (window.matchMedia('(display-mode: standalone)').matches);
}
