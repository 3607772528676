import React from 'react';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { FilterLine } from '../styled/dropdown';


interface PerformantCheckboxProps<T extends string> {
  valueSetter: (v: T) => void;
  value: T;
  checked: boolean;
  label: string | React.ReactNode;
  icon?: JSX.Element;
}

interface PerformantCheckboxGroupProps<T extends string> {
  options: T[];
  labelGetter: (value: T) => string | React.ReactNode;
  iconGetter?: (value: T) => JSX.Element;
  values: T[];
  valueSetter: (value: T[]) => void;
  renderItem?: (prop: RenderItemProp<T>) => JSX.Element;
}

export interface RenderItemProp<T> {
  toggler: (value: T) => void;
  checked: boolean;
  value: T;
  label: string | React.ReactNode;
  icon: JSX.Element;
}

const defaultMapper = <T extends string>({ value, toggler, checked, label, icon }: RenderItemProp<T>) => (
  <PerformantCheckbox
    key={value}
    valueSetter={toggler}
    checked={checked}
    value={value}
    label={label}
    icon={icon}
  />
);

export class PerformantCheckboxGroup<T extends string> extends React.PureComponent<PerformantCheckboxGroupProps<T>, never> {

  private toggleHandler = (value: T) => {
    const intermediateValuesSet = new Set(this.props.values);

    if (intermediateValuesSet.has(value)) intermediateValuesSet.delete(value);
    else intermediateValuesSet.add(value);
    this.props.valueSetter([ ...intermediateValuesSet ]);
  }

  public render() {
    const { options, values, labelGetter, iconGetter, renderItem = defaultMapper } = this.props;
    const valuesSet = new Set(values);

    return options.map(value => renderItem({
      toggler: this.toggleHandler,
      checked: valuesSet.has(value),
      value,
      label: labelGetter(value),
      icon: iconGetter ? iconGetter(value) : null,
    }));
  }
}

export class PerformantCheckbox<T extends string> extends React.PureComponent<PerformantCheckboxProps<T>, never> {

  private clickHandler = () => this.props.valueSetter(this.props.value);

  public render() {
    const { valueSetter, ...props } = this.props;

    return (
      <FilterLine data-auto={`filterCheckbox-${this.props.value}`}>
        <CheckboxField {...props} onClick={this.clickHandler} />
      </FilterLine>
    );
  }
}
