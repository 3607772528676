import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const Rate = styled(SmallText)`
  min-height: initial;
  line-height: 14px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(3)};
  background-color: ${props => colorToAlpha(props.theme.colors.brand.main, 0.07)};
  padding: 2px 4px;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  flex-shrink: 0;
`;
