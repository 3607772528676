import {
  createIsLoadingSelector,
  makeNullSafeDomainGetter,
  mortgageOfficesIndexSelector,
} from 'store/state/domainData/selectors/common';
import { createSelector } from 'reselect';
import { getRouteParams } from 'store/state/selectors/router';
import { flow } from 'lodash';


export const mortgageOfficesDomainSelector = makeNullSafeDomainGetter(mortgageOfficesIndexSelector, 'getPublicMortgageAdvisorsOfficesByCityDocIds', true);
export const mortgageOfficesSelector = createSelector(mortgageOfficesDomainSelector, data => data && data.offices ? data.offices : null);

export const mortgageOfficesTotalCountSelector = createSelector(mortgageOfficesDomainSelector, data => data ? data.total : null);
export const isMortgageOfficesLoadingSelector = createIsLoadingSelector(mortgageOfficesIndexSelector);

export const isMortgageOfficesSearchInWholeCountrySelector = flow(getRouteParams, params => !params.term || (params.term && params.term[0] === 'ישראל'));
export const cityDocIdSelector = flow(getRouteParams, params => params && params.term && params.term[0]);
