import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { State } from 'store/state';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';
import { editListingStatusAction } from 'store/state/uploadBulletin/actions';
import { unitPageBulletinSelector, unitPageCommercialBulletinSelector } from 'store/state/domainData/selectors';
import { ManageMenu } from './ManageMenu';
import { setOpenStatusDeleteListingModal } from 'store/state/app/actions';


const poiSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
], (route, bulletin, commercialBulletin) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
  }
  return poi;
});

const mapStateToProps = (state: State) => ({
  poi: poiSelector(state),
});

const mapDispatchToProps = {
  editListingStatus: editListingStatusAction,
  toggleDeletePopup: setOpenStatusDeleteListingModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMenu);
