import { call, select, take, cancel, put, takeEvery, fork, all, race, getContext, retry } from 'redux-saga/effects';
import { createTilesCacheResolver, ITilesCacheResolver } from './tilesCacheResolver';
import { searchParamsSelector } from './selectors';
import {
  ISearchPoiVariables,
  ITileRange,
  PoiId,
  DATA_LAYERS_POI_TYPES,
  IPoiUserData,
  PoiType,
  ISearchParametersWithoutSort,
  IBulletin,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
  MarketplaceType,
} from 'utils/entities';

import { flow } from 'lodash';
import { queryData } from 'store/sagas/apiService';
import { LoadType, LoadOptions, IDataLayersVariables } from 'store/sagas/apiService/types';
import { fetchMore, resetDomainData } from 'store/state/domainData/actions';

import { MulticastChannel, Task } from 'redux-saga';
import { SET_ACTIVE_POI_IDS, SET_BBOX, DATA_LAYERS_BBOX_UPDATE } from 'store/state/searchContext/types';
import { setActivePoiIds, setBbox, updateDataLayersBbox } from 'store/state/searchContext/actions';
import { RootAction } from 'store/state';
import { routeSelector, routeFixedMultiPreviewSelector } from 'store/state/selectors/router';
import { State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { mapPoiByIdsSelector, userCommuteWithPrioritiesSelector } from 'store/state/domainData/selectors';
import { boundsToTileRange } from 'components/map/utils';
import { MAX_ZOOM } from './utils';
import { dataLayersZoomSelector } from 'store/state/selectors/search';
import { currentZoomSelector, lastSearchBboxSelector } from 'store/state/searchContext/selectors';
import { createSelector } from 'reselect';
import { CLUSTER_MAX_ZOOM } from 'consts/clusterMaxZoom';
import { SET_FIXED_MULTI_PREVIEW } from 'store/state/app/types';
import { RouterAction } from 'store/state/router/actionType';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { isSearchRoute } from 'utils/marketplaceRoutes';
import { projectMapLogoSelector } from 'store/state/domainData/selectors/projectMapLogo';
import { TrackJS } from 'trackjs';


type SetSearchBboxAction = ReturnType<typeof setBbox>;
type DataLayersBboxUpdateAction = ReturnType<typeof updateDataLayersBbox>;


export interface IContextualSearchParametersWithoutSort {
  searchParams: ISearchParametersWithoutSort;
  marketplaceType: MarketplaceType;
}

const baseSearchParamsSelector = flow(searchParamsSelector, (params): IContextualSearchParametersWithoutSort => {
  const { sort, ...rest } = params.searchParams;
  return {
    searchParams: rest,
    marketplaceType: params.marketplaceType,
  };
});


type MapPreviewLoadType = LoadType.MapPreview | LoadType.MapMultiPreview;

function* getMapPreview(poiIds: PoiId[], loadType: MapPreviewLoadType) {
  const poiByIds: Record<PoiId, IBulletin | IProject | ICommercialBulletin | ICommercialProject> = yield select(mapPoiByIdsSelector);
  const userData: IPoiUserData = yield select(userCommuteWithPrioritiesSelector);
  const ids = poiIds.map((poiId) => ({ id: poiId, type: poiByIds[poiId] ? poiByIds[poiId].type : 'bulletin' }));
  const loadOpts: LoadOptions<typeof loadType> = {
    loadType,
    meta: {
      variables: { ids, userData },
      initialize: false,
      __fetchPolicy: 'cache-first',
    },
  };

  yield call(queryData, loadOpts);
}

const MAP_PREVIEW_QUERY_LIMIT = 3;
const MAP_PREVIEW_DELAY = 500;
function* baseLoadMapPreview(poiIds: PoiId[], loadType: MapPreviewLoadType) {
  if (poiIds.length) {
    try {
      yield retry(MAP_PREVIEW_QUERY_LIMIT, MAP_PREVIEW_DELAY, getMapPreview, poiIds, loadType);
    }
    catch {
      TrackJS.track([
        '[ILDEV-7839] Incorrect desktop map card on search results page',
        `poiIds=${JSON.stringify(poiIds)}`,
        `loadType=${loadType}`,
      ]);
    }
  }
  else {
    yield put(resetDomainData({ loadType }));
  }
}

function* loadMapPreview(action: ReturnType<typeof setActivePoiIds>) {
  yield baseLoadMapPreview(action.payload, LoadType.MapPreview);
}

function* loadMapMultiPreview() {
  const fixedMultiPreviewIds = yield select(routeFixedMultiPreviewSelector);
  yield baseLoadMapPreview(fixedMultiPreviewIds || [], LoadType.MapMultiPreview);
}

const mapPreviewMatcher = (action: RootAction) => (
  action.type === SET_ACTIVE_POI_IDS && action.meta.source === 'map'
);

const multiMapPreviewMatcher = (action: RootAction | RouterAction) => {
  switch (action.type) {
    case SET_FIXED_MULTI_PREVIEW:
      return true;
    case TRANSITION_SUCCESS:
      const options = action.payload.route.meta.options || {};
      return options.source !== 'multiPreviewPopup' && options.source !== 'popstate';
  }

  return false;
};

function* navigateToBbox(action: SetSearchBboxAction) {
  const route: RouteState = yield select(routeSelector);
  if (isSearchRoute(route.name)) {
    yield put(
      navigateTo(route.name, {
        ...route.params,
        bbox: action.payload,
        page: undefined,
        tracking_search_source: 'map',
      }, { replace: true })
    );

    const { sendEvent } = yield getContext('analytics');
    sendEvent('search_submit', 'search', {
      event: {
        source: 'map',
      },
    });
  }
}

function* dataLayersWorker() {
  const tilesCacheResolver: ITilesCacheResolver = yield call(createTilesCacheResolver);

  while (true) {
    const bboxAction: DataLayersBboxUpdateAction = yield take(DATA_LAYERS_BBOX_UPDATE);
    const zoom: number = yield select(dataLayersZoomSelector);
    const bbox = bboxAction.payload.bbox;
    const resolvedTiles = tilesCacheResolver.get(zoom);

    const current = [ boundsToTileRange(bbox, zoom) ];
    yield put(fetchMore({
      loadType: LoadType.SearchMapDataLayers,
      meta: {
        variables: {
          withTransportationAPI3: true,
          tileRanges: current,
          tileRangesExcl: resolvedTiles,
          zoom,
          dataLayerQuery: [ ...DATA_LAYERS_POI_TYPES ].map(dataLayerType => ({ dataLayerType, query: null })),
        },
      },
    }));

    tilesCacheResolver.add(current, zoom);
  }
}

const clustersSearchVariablesSelector = createSelector([
  baseSearchParamsSelector,
  lastSearchBboxSelector,
  dataLayersZoomSelector,
], ({ searchParams, marketplaceType }, bbox, zoom): IDataLayersVariables => {

  return {
    tileRanges: [ boundsToTileRange(bbox, zoom) ],
    zoom,
    dataLayerQuery: [ {
      dataLayerType: 'listingCluster',
      query: {
        ...searchParams,
        isCommercialRealEstate: marketplaceType === MarketplaceType.Commercial,
      },
    } ],
  };
});

function* clustersWorker(chnl: MulticastChannel<ISearchPoiVariables>) {

  const tilesCacheResolver: ITilesCacheResolver = yield call(createTilesCacheResolver);

  yield all([
    take(chnl, '*'),
    take(DATA_LAYERS_BBOX_UPDATE),
  ]);

  while (true) {
    const initialSearchVariables: IDataLayersVariables = yield select(clustersSearchVariablesSelector);

    yield put(fetchMore({
      loadType: LoadType.SearchMapClusters,
      meta: {
        variables: initialSearchVariables,
      },
    })),

    yield call(tilesCacheResolver.setCurrentParams, yield select(baseSearchParamsSelector));
    yield call(tilesCacheResolver.add, initialSearchVariables.tileRanges, initialSearchVariables.zoom);

    while (true) {
      yield race([
        take(chnl, '*'),
        take(DATA_LAYERS_BBOX_UPDATE),
      ]);

      const currentSearchVariables: IDataLayersVariables = yield select(clustersSearchVariablesSelector);
      const shouldInvalidate: boolean = yield call(tilesCacheResolver.setCurrentParams, yield select(baseSearchParamsSelector));

      const resolvedTiles: ITileRange[] = yield call(tilesCacheResolver.get, currentSearchVariables.zoom);

      if (shouldInvalidate) {
        yield put(resetDomainData({ loadType: LoadType.SearchMapClusters }));
        break;
      }

      const alreadyFetched: boolean = yield call(tilesCacheResolver.has, currentSearchVariables.tileRanges, MAX_ZOOM);

      if (!alreadyFetched) {
        yield all([
          put(fetchMore({
            loadType: LoadType.SearchMapClusters,
            meta: {
              variables: {
                ...currentSearchVariables,
                tileRangesExcl: resolvedTiles,
              },
            },
          })),
        ]);

        yield call(tilesCacheResolver.add, currentSearchVariables.tileRanges, currentSearchVariables.zoom);
      }
    }
  }
}

const mainSearchVariablesSelector = createSelector([
  baseSearchParamsSelector,
  currentZoomSelector,
  lastSearchBboxSelector,
], ({ searchParams, marketplaceType }, zoom, bbox): [ number, ISearchPoiVariables ] => {
  const poiTypes: PoiType[] = [ 'project' ];
  const noCluster = zoom === null ? null : zoom > CLUSTER_MAX_ZOOM;
  const isCommercialRealEstate = marketplaceType === MarketplaceType.Commercial;

  if (noCluster === true) {
    poiTypes.push(isCommercialRealEstate ? 'commercialBulletin' : 'bulletin');
  }

  return [ noCluster ? MAX_ZOOM : CLUSTER_MAX_ZOOM, {
    ...searchParams,
    isCommercialRealEstate,
    tileRanges: [ boundsToTileRange(bbox, MAX_ZOOM) ],
    sort: null,
    userContext: null,
    poiTypes,
  } ];
});

function* maybeFetchProjectMapLogo() {
  const logos = yield select(projectMapLogoSelector);
  if (logos) return;

  yield fork(queryData, {
    loadType: LoadType.ProjectMapLogo,
    meta: {
      variables: {},
    },
  });
}

function* mainSearchMapWorker(chnl: MulticastChannel<ISearchPoiVariables>) {

  const tilesCacheResolver: ITilesCacheResolver = yield call(createTilesCacheResolver);

  yield all([
    take(chnl, '*'),
    take(DATA_LAYERS_BBOX_UPDATE),
  ]);

  while (true) {
    const [ initialZoom, initialSearchVariables ]: [ number, ISearchPoiVariables ] = yield select(mainSearchVariablesSelector);

    yield call(maybeFetchProjectMapLogo);
    const bgTask: Task = yield fork(queryData, {
      loadType: LoadType.SearchMapListings,
      meta: {
        variables: initialSearchVariables,
      },
    });
    yield call(tilesCacheResolver.setCurrentParams, yield select(baseSearchParamsSelector));
    yield call(tilesCacheResolver.add, initialSearchVariables.tileRanges, initialZoom);

    while (true) {
      yield race([
        take(chnl, '*'),
        take(DATA_LAYERS_BBOX_UPDATE),
      ]);

      const [ zoom, currentSearchVariables ]: [ number, ISearchPoiVariables ] = yield select(mainSearchVariablesSelector);
      const shouldInvalidate: boolean = yield call(tilesCacheResolver.setCurrentParams, yield select(baseSearchParamsSelector));

      const resolvedTiles: ITileRange[] = yield call(tilesCacheResolver.get, zoom);

      if (shouldInvalidate) {
        yield call(tilesCacheResolver.remove, initialSearchVariables.tileRanges, initialZoom);
        yield cancel(bgTask);
        break;
      }

      const alreadyFetched: boolean = yield call(tilesCacheResolver.has, currentSearchVariables.tileRanges, zoom);

      if (!alreadyFetched) {
        yield all([
          put(fetchMore({
            loadType: LoadType.SearchMapListings,
            meta: {
              variables: {
                ...currentSearchVariables,
                tileRangesExcl: resolvedTiles,
                userContext: null,
                sort: null,
              },
            },
          })),
        ]);

        yield call(tilesCacheResolver.add, currentSearchVariables.tileRanges, zoom);
      }
    }
  }
}

export function* searchMapWorker(chnl: MulticastChannel<ISearchPoiVariables>) {
  yield takeEvery(mapPreviewMatcher, loadMapPreview);
  yield takeEvery(multiMapPreviewMatcher, loadMapMultiPreview);
  yield takeEvery(SET_BBOX, navigateToBbox);
  yield fork(dataLayersWorker);
  yield fork(mainSearchMapWorker, chnl);
  yield fork(clustersWorker, chnl);
}
