import { connect } from 'react-redux';
import FeedbackPopupView from 'components/common-feedback-popup-view';
import { State } from 'store/state';
import { setAddressFeedbackPopupOpen, setToastMessage } from 'store/state/app/actions';
import { isAddressFeedbackPopupModalOpenSelector } from 'store/state/app/selectors';
import { addressPageFeedbackOptions, FeedbackPopupType } from 'components/common-feedback-popup-view/utils';


const mapStateToProps = (state: State) => ({
  isPopupModalOpen: isAddressFeedbackPopupModalOpenSelector(state),
  options: addressPageFeedbackOptions,
  feedbackType: FeedbackPopupType.Feedback,
});

const mapDispatchToProps = {
  onClosePopup: () => setAddressFeedbackPopupOpen(false),
  onSubmit: () => setToastMessage({ term: 'feedback.popup.toasterMessageConfirmation' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPopupView);
