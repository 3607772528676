import { useState, useEffect } from 'react';
import { useGlobalEventListener } from './useGlobalEventListener';
import { isServer } from 'utils';

const getWindowSize = (): [ number, number ] => [ window.innerWidth, window.innerHeight ];

interface IWindowSizeHookOptions {
  unsafelyForSSRInitialize?: boolean;
  disableGlobalListener?: boolean;
}

export const useWindowSize = ({ unsafelyForSSRInitialize, disableGlobalListener }: IWindowSizeHookOptions = {}) => {
  const [ size, setSize ] = useState(unsafelyForSSRInitialize ? getWindowSize : [ null, null ]);
  useEffect(() => {
    if (size[0] === null && size[1] === null) {
      setSize(getWindowSize);
    }
  }, []);

  if (!disableGlobalListener) {
    useGlobalEventListener(isServer() ? null : window, 'resize', () => {
      setSize(getWindowSize);
    });
  }
  return size;
};
