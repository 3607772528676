import Cookie from 'js-cookie';
import React from 'react';

export const TOKEN_KEY = 'USER_TOKEN_V2';

export interface UserTokenStore {
  get: () => string;
  set: (token: string) => void;
  remove: () => void;
}

export class ServerTokenStore implements UserTokenStore {
  constructor(private token: string) {}
  public get() {
    return this.token;
  }
  public set(token: string) {
    this.token = token;
  }
  public remove() {
    throw new Error('User token can not be removed in SSR');
  }
}

const USER_TOKEN_TTL_DAYS = 1440;
export const USER_TOKEN_TTL_MS = USER_TOKEN_TTL_DAYS * 24 * 60 * 60 * 1000;

const cookieOpts: Cookie.CookieAttributes = {
  expires: USER_TOKEN_TTL_DAYS, // days from now
};

export class ClientTokenStore implements UserTokenStore {
  public remove() {
    Cookie.remove(TOKEN_KEY);
  }
  public set(token: string) {
    if (token) {
      Cookie.set(TOKEN_KEY, token, cookieOpts);
    }
  }
  public get() {
    const token = Cookie.get(TOKEN_KEY);
    // defensive checks for historical reasons
    if (token && token !== 'undefined' && token !== 'null') {
      return token;
    }
    return null;
  }
}

export const TokenContext = React.createContext<UserTokenStore>(null);
