import { addEventPostProcessor, addEventPreProcessor, AnalyticsV2, AppContextV2, EventsCounter, isServer, AbTestsFilter } from '@madlan145/client-toolbox';
import config from 'config';
import { Route, State } from 'config/routes';
import { getCurrentDesignRange } from 'consts/breakpoints';
import Cookies from 'js-cookie';
import { constants, Router } from 'router5';
import uuid from 'uuid/v4';
import './fullStory';
import { GoogleTagManager } from './postProcessors/googleTagManager';
import { createPubSub } from 'utils/pubSub';
import React from 'react';
import { getIsraelLegacySecondaryUserId } from './israelLegacyUserId';
import { TrackJS } from 'trackjs';
import { isFullStoryInitialized, initFullstory } from './fullStory';
import { routeSelector } from 'store/state/selectors/router';
import { getABTestsContext } from 'utils/abtests';
import { isTrackJSEnabled } from 'helpers/jstracking';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { getPlatformName } from 'react-native/lib/platform';
import { Platform } from 'react-native/config';


export interface BiEvent { name: string; category: string; payload: any; }

let userId = isServer ? null : Cookies.get('APP_CTX_USER_ID');

function getDomainForCookie() {
  const hostname = window.location.hostname;
  return hostname.substr(hostname.indexOf('.'));
}

export const getSecondaryUserId = () => {
  if (!userId) {
    userId = getIsraelLegacySecondaryUserId();
    if (!userId) {
      userId = uuid();
    }

    const cookieSettings = { expires: 999, domain: getDomainForCookie() };
    Cookies.set('APP_CTX_USER_ID', userId, cookieSettings);

    // temporary support for legacy IL integration
    Cookies.set('Infinite_user_id_key', userId, cookieSettings);
    Cookies.set('Infinite_user_id_key', userId, { expires: 999, domain: window.location.hostname });
  }

  return userId;
};

export const getSecondaryUserIdAsNumber = () => {
  return Number.parseInt(getSecondaryUserId().replace('-', ''), 16);
};

let sessionId = isServer ? null : Cookies.get('APP_CTX_SESSION_ID');

export const getSessionId = () => {
  if (!sessionId) {
    sessionId = uuid();
    Cookies.set('APP_CTX_SESSION_ID', sessionId, { expires: null, domain: getDomainForCookie() });
  }

  return sessionId;
};

if (isTrackJSEnabled()) {
  TrackJS.configure({
    userId: getSecondaryUserId(),
    sessionId: getSessionId(),
  });

  TrackJS.addMetadata('referer', document.referrer || 'NA');

  // if (!(window as any).bldon9brwa2d) {
  //   TrackJS.track('ads-blocked');
  // }
}

export type IAnalytics = ReturnType<typeof initAnalytics>;
export const AnalyticsContext = React.createContext<IAnalytics>(null);

function mapPlatformName(platform: Platform) {
  switch (platform) {
    case 'android':
      return 'android_app';
    case 'ios':
      return 'ios_app';
    default:
      return 'web';
  }
}

export const initAnalytics = (router: Router) => {
  initFullstory();

  const RANGE_TO_LAYOUT_NAME = {
    1: 'mobile',
    2: 'tablet_portrait',
    3: 'tablet_landscape',
    4: 'small_desktop',
    5: 'large_desktop',
  };

  // @TODO: fix type definitions in client-toolbox
  const getUILayout = (): any => {
    const v = getCurrentDesignRange();

    return RANGE_TO_LAYOUT_NAME[v];
  };

  const ROUTE_TO_PAGE_TYPE: Record<Route, string> = {
    [Route.AddressPage]: 'address_page',
    [Route.UnitPage]: 'unit_page',
    [Route.UnitPageCommercial]: 'unit_page',
    [Route.Home]: 'home_page',
    [Route.Search]: 'search_result_page',
    [Route.TermsPage]: 'terms_page',
    [Route.Profile]: 'profile_page',
    [Route.ProjectPage]: 'project_page',
    [Route.ProjectPageCommercial]: 'project_page',
    [Route.ProjectPromotionPage]: 'project_promotion_page',
    [Route.CheckAddress]: 'check_address_page',
    [Route.MyHomes]: 'my_homes_page',
    [Route.SavedSearchesPage]: 'saved_searches_page',
    [Route.Shortlist]: 'favorites_page',
    [Route.LocalPage]: 'local_page',
    [Route.UnsubscribePage]: 'unsubscribe_page',
    [Route.SavedSearch]: 'saved_search_redirect_page',
    [Route.AriaSearchForm]: 'aria_search_form_page',
    [Route.AriaSearchResults]: 'aria_search_results_page',
    [Route.AriaUnit]: 'aria_unit_page',
    [Route.AriaProject]: 'aria_project_page',
    [Route.UploadBulletin]: 'upload_bulletin_page',
    [Route.ManageBulletins]: 'manage_bulletins_page',
    [Route.EditBulletin]: 'edit_bulletin_page',
    [Route.EditCommercialBulletin]: 'edit_bulletin_page',
    [Route.Sold]: 'sold_page',
    [Route.StreetPage]: 'street_page',
    [Route.MadadPage]: 'madad_page',
    [Route.MadadCity]: 'madad_page',
    [Route.MadadArchivePage]: 'madad_page',
    [Route.MadadCityArchive]: 'madad_page',
    [Route.MadadArchive2020Page]: 'madad_page',
    [Route.MadadAgentPage]: 'madad_page',
    [Route.MadadSearchPage]: 'madad_search_page',
    [Route.Accessibility]: 'accessibility_page',
    [Route.Sitemap]: 'sitemap_page',
    [Route.CommercialMarketLanding]: 'commercial_landing',
    [Route.SearchCommercial]: 'search_result_page',
    [Route.DeveloperPage]: 'developer_page',
    [Route.DevelopersSearchPage]: 'developer_index',
    [Route.DevelopersCitySearchPage]: 'developer_index',
    [Route.MortgageOfficesSearchPage]: 'mortgage_index_page',
    [Route.MortgageOfficesCitySearchPage]: 'mortgage_index_page',
    [Route.EmploymentAreaPage]: 'employment_area_page',
    [Route.MortgageOfficePage]: 'mortgage_consult_page',
    [Route.OfficePage]: 'office_page',
    [Route.AgentPage]: 'agent_page',
    [Route.MadadLottery]: 'lottery_page',
    [Route.MadadLotteryTerms]: 'lottery_terms_page',
    [Route.Israel4ever]: 'host_refugees',
    [Route.AutomationTestPage]: 'sitemap_page', // test page for automation, value is just for not to fail
    [Route.PushSettings]: 'notification_manager_page',
    [Route.DevelopersPromotionPage]: 'b2b_landing_page',
    [Route.AgentsPromotionPage]: 'b2b_landing_page',
    [Route.Deals]: 'console_agent_deals_page',
  };

  addEventPreProcessor(new AbTestsFilter());

  const platformName = mapPlatformName(getPlatformName());

  const appContext = new AppContextV2({
    userId: () => null,
    secondaryUserId: () => getSecondaryUserId(),
    sessionId: getSessionId,
    sessionStartPage: document.location.href,
    version: config.version,
    appID: config.appID,
    platformName,
    history: router.getDependencies().fakeHistory,
    getSessionReplayPlatform: () => isFullStoryInitialized() ? 'fullstory' : null,
    getUILayout,
    pageTypeGetter: () => {
      const { store } = router.getDependencies();
      const state = store.getState();
      const { name } = routeSelector(state);

      return ROUTE_TO_PAGE_TYPE[name];
    },
  });

  addEventPreProcessor(appContext);
  addEventPreProcessor({
    process: (name, category, payload) => {
      return {
        ...payload,
        ab_test: getABTestsContext().context,
      };
    },
  });

  addEventPreProcessor({
    process: (name, category, payload) => {
      const platform = mapPlatformName(getPlatformName());
      if (payload.platform && platform) {
        return {
          ...payload,
          platform: {
            ...payload.platform,
            platform,
          },
        };
      }
      return payload;
    },
  });

  addEventPreProcessor(new EventsCounter());

  if (config.events && config.events.processors) {
    const cep = config.events.processors;
    if (cep.analytics) {
      addEventPostProcessor(new AnalyticsV2(cep.analytics.url, cep.analytics.log));
      addEventPostProcessor(new GoogleTagManager());
    }
  }

  const pubSub = createPubSub<BiEvent>();

  const sendEvent = (name: string, category: string, payload?: any) => {
    pubSub.dispatch({ name, category, payload });
  };

  const subscribe = pubSub.subscribe;

  router.addEventListener(constants.TRANSITION_SUCCESS, (toState: State, fromState: State, options: NavigationOptions) => {
    setTimeout(() => {
      if (!fromState) {
        // initial load
        return sendEvent('page_view', 'page');
      }

      // maybe we need to init full story ? sometimes we start full story recording only from a specific url
      initFullstory();

      // different routes, log it
      if (fromState.name !== toState.name) {
        return sendEvent('page_view', 'page');
      }

      // same route names, check for different ids
      if (fromState.params.id !== toState.params.id) {
        return sendEvent('page_view', 'page');
      }

      // same route names, check for different ids
      if (toState.name === Route.AddressPage && fromState.params.address !== toState.params.address) {
        return sendEvent('page_view', 'page');
      }
    });
  });
  if (!isServer) {
    router.getDependencies().fakeHistory.listen((loc: any) => {
      if ((window as any).Intercom) {
        (window as any).Intercom('update');
      }
    });
  }

  return {
    sendEvent,
    subscribe,
  };
};
