import { escapeRegExp } from 'lodash';
import React from 'react';
import RecentSearchIcon from 'assets/svg/recent.svg';
import { InitialSuggestion } from 'utils/initialSuggestionsConfig';
import { IAutocompleteEntry } from 'utils/entities';
import { ITranslate } from 'locale';


const EMPTY_ARRAY: IAutocompleteEntry[] = [];

export function replaceString(
  str: string,
  match: string | RegExp,
  fn: (str: string, idx: number, char: number) => React.ReactNode
): React.ReactNodeArray {
  let curCharStart = 0;
  let curCharLen = 0;

  let re: RegExp;

  if (match instanceof RegExp) {
    re = match;
  }
  else {
    re = new RegExp(`(${escapeRegExp(match)})`, 'gi');
  }

  const result: Array<React.ReactNode | string> = str.split(re);
  // Apply fn to all odd elements
  for (let i = 1; i < result.length; i += 2) {
    const currRes = result[i] as string;
    curCharLen = currRes.length;
    curCharStart += (result[i - 1] as string).length;
    result[i] = fn(currRes, i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
}

export const autocompleteEntriesToSuggestions = (entries: IAutocompleteEntry[], t: ITranslate) => entries.length && [ ({
  icon: <RecentSearchIcon />,
  title: t('autocomplete.results.recentSuggestions'),
  entries,
}) ];

export const flattenInitialSuggestions = (initialSuggestions: InitialSuggestion[]): IAutocompleteEntry[] =>
  initialSuggestions ? initialSuggestions.flatMap(({ entries }) => entries) : EMPTY_ARRAY;
