import { LocalStorage } from 'utils/localStorage';
import { isServer } from 'utils';
import { memoize } from 'lodash';
import { nonNull } from 'helpers/index';

const KEY = 'CONTACT_FORM';

export interface IContactAgentValues {
  phone: string;
  name: string;
  email: string;
}

export const getLastContactAgentValues = memoize((): IContactAgentValues => isServer() ? null : LocalStorage.get(KEY), () => '');
export const setLastContactAgentValues = (value: IContactAgentValues) => {
  LocalStorage.set(KEY, value);
  getLastContactAgentValues.cache.clear();
};

export const setEmptyLastContactAgentValues = (nextValues: IContactAgentValues) => {
  const prevValues = getLastContactAgentValues() || { phone: '', name: '', email: '' };
  const { phone, email, name } = nextValues;
  LocalStorage.set(KEY, {
    phone: nonNull(phone) ? phone : prevValues.phone,
    name: nonNull(name) ? name : prevValues.name,
    email: nonNull(email) ? email : prevValues.email,
  });
  getLastContactAgentValues.cache.clear();
};
