import { call, Effect, fork, getContext, race, select, take } from 'redux-saga/effects';
import {
  GO_BACK,
  NEXT,
  SET_DEAL_TYPE,
  SET_DOC_ID,
  SET_OPEN,
  SKIP,
  SUBMIT_WIZARD,
} from 'store/state/homepageWizard/types';
import { homepageWizardSelector, homePageWizardDealTypeSelector, homePageWizardPurchasingReasonSelector, homePageWizardCommuteSelector } from 'store/state/homepageWizard/selectors';
import { HomepageWizardState } from 'store/state/homepageWizard';
import { CommutePreference, CompletionType, DealType, IAutocompleteEntry, PurchasingReason } from 'utils/entities';
import { dealTypeToTrackingType, sortFieldToTrackingField } from './search';
import { isEmptyRange, normalizePriceRange, normalizeRoomsRange } from 'components/filters/utils';
import { createUserCommutePreferences } from './utils';
import { RootAction } from 'store/state';
import { noop } from 'lodash';
import { Task } from 'redux-saga';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { marketplaceSelector } from 'store/state/selectors/router';


const purchasingReasonToDisplayValue: Record<PurchasingReason, string> = {
  [PurchasingReason.FirstHouse]: 'זו הדירה הראשונה שלנו',
  [PurchasingReason.Upgrading]: 'משדרגים מהדירה הנוכחית',
  [PurchasingReason.Investment]: 'דירה להשקעה',
  [PurchasingReason.SomethingElse]: 'סיבה אחרת',
};

interface ISurveyQuestion {
  question_text: string;
  question_type: 'single_choice' | 'location_single_choice' | 'multiple_choice';
  question_name: string;
}

interface LivingPreference {
  address: string;
  location_type: CompletionType;
  city: string;
  borough?: string;
  neighborhood?: string;
  zip_code?: string;
  latitude: number;
  longitude: number;
}

interface SurveyAnswer {
  display_value: string;
  numeric_value?: number;
  text_value?: string;
}

interface QuestionWithAnswer {
  survey_question: ISurveyQuestion;
  survey_answer: SurveyAnswer[];
}

interface QuestionDescription {
  forward?: Effect;
  skip?: Effect;
  backward?: Effect;
  type: QuestionType;
  manipulateOffset?: number;
}

enum QuestionType {
  DealType,
  Filters,
  PowerSort,
  Commute,
  PurchasePurpose,
}

const questionMeta: Record<QuestionType, ISurveyQuestion> = {
  [QuestionType.DealType]: {
    question_text: 'מחפשים דירה לקניה או להשכרה?',
    question_type: 'single_choice',
    question_name: 'homepage_wizard/deal_type',
  },
  [QuestionType.Filters]: {
    question_text: 'תקציב ומספר חדרים?',
    question_type: 'multiple_choice',
    question_name: 'homepage_wizard/price_and_rooms',
  },
  [QuestionType.PowerSort]: {
    question_text: 'מה חשוב לכם?',
    question_type: 'multiple_choice',
    question_name: 'homepage_wizard/power_sort',
  },
  [QuestionType.Commute]: {
    question_text: 'איך אתם מגיעים לעבודה?',
    question_type: 'location_single_choice',
    question_name: 'homepage_wizard/commute',
  },
  [QuestionType.PurchasePurpose]: {
    question_text: 'מה מטרת הרכישה?',
    question_type: 'single_choice',
    question_name: 'homepage_wizard/purchase_purpose',
  },
};

const never = () => new Promise(noop);

function* completeWizardWorker() {
  yield take(SUBMIT_WIZARD);
}

function* forwardCommute() {
  const dealType = yield select(homePageWizardDealTypeSelector);
  if (dealType === DealType.Rent) {
    yield take(SUBMIT_WIZARD);
    const commute: CommutePreference = yield select(homePageWizardCommuteSelector);
    if (commute.commuteType) return true;
    return yield call(never);
  }
  return yield take(NEXT);
}

function* skipCommute() {
  const dealType = yield select(homePageWizardDealTypeSelector);
  if (dealType === DealType.Rent) {
    yield take(SUBMIT_WIZARD);
    const commute: CommutePreference = yield select(homePageWizardCommuteSelector);
    if (!commute.commuteType) return true;
    return yield call(never);
  }
  return yield take(SKIP);
}

function* forwardPurchasePurpose() {
  yield take(SUBMIT_WIZARD);
  const purchasingReason = yield select(homePageWizardPurchasingReasonSelector);
  if (purchasingReason) return true;
  return yield call(never);
}

function* skipPurchasePurposeIL() {
  yield take(SUBMIT_WIZARD);
  const purchasingReason = yield select(homePageWizardPurchasingReasonSelector);
  if (!purchasingReason) return true;
  return yield call(never);
}

const QUESTIONS_CONFIG: Partial<Record<QuestionType, QuestionDescription>> = {
  [QuestionType.DealType]: {
    type: QuestionType.DealType,
    forward: race([
      take(SET_DEAL_TYPE),
      take(SET_DOC_ID),
      take(NEXT),
    ]),
  },
  [QuestionType.Filters]: { type: QuestionType.Filters },
  [QuestionType.PowerSort]: { type: QuestionType.PowerSort },
  [QuestionType.Commute]: {
    type: QuestionType.Commute,
    forward: call(forwardCommute),
    skip: call(skipCommute),
  },
  [QuestionType.PurchasePurpose]: {
    type: QuestionType.PurchasePurpose,
    skip: call(skipPurchasePurposeIL),
    forward: call(forwardPurchasePurpose),
  },
};

const DEFAULT_EFFECTS: Partial<QuestionDescription> = {
  skip: take(SKIP),
  backward: take(GO_BACK),
  forward: take(NEXT),
};

function getLivingPreferenceFromAddress(address?: Partial<IAutocompleteEntry>): LivingPreference {
  if (!address) {
    return null;
  }

  return {
    address: address.name,
    location_type: address.type,
    city: address.city,
    borough: 'borough' in address ? address.borough : undefined,
    neighborhood: 'neighbourhood' in address ? address.neighbourhood : undefined,
    zip_code: 'zipcode' in address ? address.zipcode : undefined,
    latitude: address.location[1],
    longitude: address.location[0],
  };
}

function createUserPreferences(commutePreference: CommutePreference) {
  const preferences = createUserCommutePreferences(commutePreference);
  return preferences
    ? { commute_preferences: preferences }
    : null;
}

const createWizardEventsContext = () => {

  let answersAccumulator: Partial<Record<string, QuestionWithAnswer>> = {};

  function* reject(questionType: QuestionType, index: number, event: string, isSubmitted: boolean) {
    const { commutePreference }: HomepageWizardState = yield select(homepageWizardSelector);
    const { sendEvent } = yield getContext('analytics');

    const question = {
      ...questionMeta[questionType],
      order_index: index,
    };

    const payload: any = {
      survey_question: question,
      previous_survey_questions_and_answers: answersAccumulator,
      survey_submitted: isSubmitted,
    };

    if (commutePreference.commuteType) {
      payload.user_preferences = createUserPreferences(commutePreference);
    }

    yield call(sendEvent, event, 'survey', payload);
  }

  function* skip(questionType: QuestionType, index: number, isSubmitted: boolean) {
    yield call(reject, questionType, index, 'survey_skip_click', isSubmitted);
  }

  function* back(questionType: QuestionType, index: number) {
    yield call(reject, questionType, index, 'survey_back_click', false);
  }


  function* answer(questionType: QuestionType, index: number, isSubmitted: boolean) {
    const {
      sort,
      dealType,
      address,
      roomsRange,
      priceRange,
      commutePreference,
      purchasingReason,
    }: HomepageWizardState = yield select(homepageWizardSelector);
    const marketplace = yield select(marketplaceSelector);
    const { sendEvent } = yield getContext('analytics');

    const additionalObjects: any = {};
    let surveyAnswer: SurveyAnswer[];

    switch (questionType) {
      case QuestionType.DealType:
        surveyAnswer = [ { display_value: dealType === DealType.Buy ? 'buy' : 'rent' } ];
        break;
      case QuestionType.PowerSort: {
        surveyAnswer = sort.map(([ field ]) => ({
          display_value: field,
          text_value: sortFieldToTrackingField[field],
        }));
        break;
      }
      case QuestionType.PurchasePurpose: {
        surveyAnswer = [ {
          display_value: purchasingReasonToDisplayValue[purchasingReason],
          text_value: purchasingReason,
        } ];
        break;
      }
      case QuestionType.Filters:
        additionalObjects.search = {
          search_type: dealTypeToTrackingType[dealType],
          search_filters: {
            bedrooms: isEmptyRange(roomsRange)
              ? undefined
              : normalizeRoomsRange(roomsRange, marketplace).map(b => b === null ? '' : b.toString()),
            price: isEmptyRange(priceRange)
              ? undefined
              : normalizePriceRange(priceRange, marketplace).map(p => p === null ? 0 : p),
          },
        };
        break;
    }

    if (address) {
      additionalObjects.living_preference_location = getLivingPreferenceFromAddress(address);
    }

    if (commutePreference.commuteType) {
      additionalObjects.user_preferences = createUserPreferences(commutePreference);
    }

    const question = {
      ...questionMeta[questionType],
      order_index: index,
    };

    const questionAnswerObj: QuestionWithAnswer = {
      survey_question: question,
      survey_answer: surveyAnswer,
    };

    yield call(sendEvent, 'survey_answer_submit', 'survey', {
      ...additionalObjects,
      ...questionAnswerObj,
      previous_survey_questions_and_answers: answersAccumulator,
      survey_submitted: isSubmitted,
    });

    if (questionAnswerObj.survey_answer) {
      answersAccumulator = {
        ...answersAccumulator,
        [questionAnswerObj.survey_question.question_name]: questionAnswerObj,
      };
    }
  }

  return {
    skip,
    back,
    answer,
  };
};

function* getQuestions() {
  const questions = [
    QuestionType.DealType,
    QuestionType.Filters,
    QuestionType.PowerSort,
    QuestionType.Commute,
    QuestionType.PurchasePurpose,
  ];

  return questions.map(q => QUESTIONS_CONFIG[q]);
}

function* questionsTraverser(questionsList: QuestionDescription[]) {
  const completeWizard: Task = yield fork(completeWizardWorker);
  const ctx = yield call(createWizardEventsContext);

  let questionPointer = 0;

  while (questionPointer < questionsList.length) {
    const {
      type,
      forward,
      backward,
      skip,
      manipulateOffset = null,
    } = { ...DEFAULT_EFFECTS, ...questionsList[questionPointer] };
    const manipulation = manipulateOffset ? questionsList[questionPointer + manipulateOffset] : null;
    const [ answered, wentBack, skipped, manipulated ] = yield race([
      forward,
      backward,
      skip,
      manipulation ? manipulation.forward : null,
    ].filter(Boolean));

    const isWizardRunning = yield call(completeWizard.isRunning);
    if (answered) {
      yield fork(ctx.answer, type, questionPointer, !isWizardRunning);
      questionPointer++;
    }
    if (skipped) {
      yield fork(ctx.skip, type, questionPointer, !isWizardRunning);
      questionPointer++;
    }
    if (wentBack) {
      yield fork(ctx.back, type, questionPointer);
      questionPointer--;
    }
    if (manipulated) {
      yield fork(ctx.answer, manipulation.type, questionPointer + manipulateOffset, false);
    }
  }
}

const wizardCloseMatcher = (action: RootAction) => (
  action.type === SET_OPEN && !action.payload.isOpen
);

function* eventsWorker() {
  const questions: QuestionDescription[] = yield call(getQuestions);
  yield take(SET_OPEN);
  yield fork(questionsTraverser, questions);
}

export function* homepageEventsWorker() {
  yield take(TRANSITION_SUCCESS);
  while (true) {
    yield race([
      call(eventsWorker),
      take(wizardCloseMatcher),
    ]);
  }
}
