import styled from '@emotion/styled';
import { H2, H3, SmallText } from 'ds/components/typography';


export const TopMenu = styled.div<{noBorderTop?: boolean}>`
  ${({ theme, noBorderTop }) => noBorderTop ? '' : `border-top: 1px solid ${theme.colors.neutrals.grey7};`};
  padding: 40px 0 20px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `flex-direction: column;`}
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `flex-direction: row;`}
  ${({ theme }) => theme.media.showAt({ from: 3 }) `flex-direction: column;`}
  &&  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: NovemberHebrew;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.neutrals.grey2};
    :last-of-type {margin: 0}
    ${({ theme }) => theme.media.showAt({ to: 2 }) `
      ::after {
        content: '';
        display: flex;
        width: 1px;
        height: 12px;
        margin: 0 12px;
        background-color: ${theme.colors.neutrals.grey7};
      }
      :last-of-type {
        margin-bottom: 8px;
        ::after {content: none}
      }
    `}
  }
`;

export const TopMenuSection = styled.div<{ idCommercialLanding?: boolean }>`
  width: 100%;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `max-width: 100%;`}
  ${({ theme }) => theme.media.showAt({ at: 3 }) `max-width: 128px;`}
  ${({ theme, idCommercialLanding }) => theme.media.showAt({ at: 4 }) `max-width: ${idCommercialLanding ? 142 : 168}px;`}
  ${({ theme }) => theme.media.showAt({ at: 5 }) `max-width: 174px;`}
  ${({ theme }) => `> div:first-of-type {margin-bottom: ${theme.spacing(3)}}`}
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    > div:first-of-type {margin-bottom: ${theme.spacing(2)}}
    margin-bottom: ${theme.spacing(3)};
    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export const BottomMenu = styled.div`
  padding: 24px 0;
  background-color: ${({ theme }) => theme.colors.neutrals.grey10};
  > div {
    display: flex;
    justify-content: space-between;
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    > div {
      display: block;
    }
  `}
`;

export const SocialMenu = styled.div`
  padding: 20px 0 0;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1136px;
  margin: 0 auto;
  ${({ theme }) => theme.media.showAt({ from : 2, to: 3 }) `max-width: 100%; padding: 0 ${theme.spacing(6)};`}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `padding: 0 ${theme.spacing(3)};`}
`;

export const DefaultLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  :active,
  :visited,
  :focus {
    color: inherit;
  }
`;

export const ExpandedDesktopLinkWrapper = styled(H3.withComponent('div'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
    margin-bottom: ${theme.spacing(2.5)};
  `}
`;

export const ExpandedMobileLinkWrapper = styled(SmallText)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1.5)};
    color: ${theme.colors.neutrals.grey2};
  `}
`;

export const DesktopHeader = styled(H2)`
  ${({ theme }) => `
    color: ${theme.colors.brand.madlanGreen};
  `}
`;

export const MobileHeader = styled(H3)`
  ${({ theme }) => `
    color: ${theme.colors.brand.madlanGreen};
  `}
`;

export const ExpandedDesktopSectionHeader = styled(H2)`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const ExpandedMobileSectionHeader = styled(H3)`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const ExpandedSection = styled.div<{ bigRightPadding: boolean }>`
  ${({ theme, bigRightPadding }) => `
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing(12)};
    padding-right: ${theme.spacing(bigRightPadding ? 8 : 1)};
  `}
`;

export const ExpandedLinkSection = styled.div<{ smallMargins: boolean }>`
  ${({ theme, smallMargins }) => `
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacing(smallMargins ? 6 : 8)};
    margin-top: ${theme.spacing(smallMargins ? 2 : 3.5)};
    > div {
      margin-bottom: ${theme.spacing(smallMargins ? 1.5 : 2.5)};
      :last-of-type {
        margin-bottom: 0;
      }
    }
  `}
`;

export const ExpandedHeaderWrapper = styled.div<{ bigSpacing: boolean }>`
  ${({ theme, bigSpacing }) => `
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin-left: ${theme.spacing(1)};
    }
    ${bigSpacing ? `
      margin: ${theme.spacing(7)} 0;
    ` : `
      margin: ${theme.spacing(4.5)} 0 ${theme.spacing(2.5)};
    `}
  `}
`;
