
import scrollIntoView from 'scroll-into-view';
import { ActionType } from 'typesafe-actions';
import { take, call, delay, race } from 'redux-saga/effects';

import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { transitionSuccess } from 'store/state/router/actions';

const TIMEOUT_FOR_ELEMENT_TO_RENDER = 5000;

function getElementById(id: string): HTMLElement | null {
  return document.getElementById(id);
}

function scheduleScrollIntoView(element: HTMLElement): void {
  requestAnimationFrame(() => scrollIntoView(element, { align: { top: 0 } }));
}

function* waitForElementRendered(id: string) {
  let element: HTMLElement | null = yield call(getElementById, id);
  if (element) return element;

  while (true) {
    yield take('*');
    element = yield call(getElementById, id);
    if (element) return element;
  }
}

function* scrollIntoViewById(id: string, timeout: number = TIMEOUT_FOR_ELEMENT_TO_RENDER) {
  const [ isTimedOut, element ] = yield race([
    delay(timeout),
    call(waitForElementRendered, id),
  ]);

  if (!isTimedOut) {
    yield call(scheduleScrollIntoView, element);
  }
}

export function* scrollToElement() {
  while (true) {
    const { payload }: ActionType<typeof transitionSuccess> = yield take(TRANSITION_SUCCESS);
    const id: string | undefined = payload.route.meta && payload.route.meta.options && payload.route.meta.options.scrollToId;
    if (id) {
      yield call(scrollIntoViewById, id);
    }
  }
}
