import React from 'react';
import { BuildingClass } from 'utils/entities';
import { WithLocaleProps } from 'locale';
import { UniversalCardLineLabels } from 'components/cards/listing/UniversalCard/utils';
import { PropertyDetailsText, PropertyDetailsSeparator } from './styled';

export const getBulletinInfoConfig = (bedsCount: number, size: number, floor: number, buildingClass: BuildingClass, { t, formatArea }: WithLocaleProps) => {
  const separator = <PropertyDetailsSeparator />;
  return [
    {
      label: UniversalCardLineLabels.Beds,
      separator,
      showCondition: typeof bedsCount === 'number',
      render: () => (
        <PropertyDetailsText
          data-auto="property-rooms"
        >
          {t('listingCard.bedsCountLabel', { bedsCount })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator,
      showCondition: floor !== undefined && floor !== null,
      render: () => (
        <PropertyDetailsText data-auto="property-floor">
          {t('listingCard.floorLabel', { floor })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Size,
      separator,
      showCondition: Boolean(size) && size > 0,
      render: () => (
        <PropertyDetailsText data-auto="property-size">
          {formatArea(size)}
        </PropertyDetailsText>
      ),
    },
  ];
};
