import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => `
    text-align: center;
    white-space: pre;
    padding: ${theme.spacing(6)} 0 ${theme.spacing(5)} 0;

    a {
      color: ${theme.colors.brand.main};
      text-decoration: underline;
    }
  `}
`;
