import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { InsightType, InsightDescriptor } from 'utils/entities';
import { ExpandCollection } from 'store/state/insightsContext';
import { Button, ControlButton } from 'ds/components/button';
import { DESKTOP_MULTI_INPUT_WRAPPER_CLASS } from 'components/autocomplete/multi-location-autocomplete/MultiLocationAutocomplete';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import { SmallText } from 'ds/components/typography';


export const MOBILE_SEUBMENU_HEIGHT = 48;
export const NAVIGATION_SUBHEADER_HEIGHT = 40;

export const SubheaderWrapper = styled.div<{ isVisible?: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors.neutrals.white};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    height: 64px;
    box-shadow: ${theme.shadow.level2};
  `}
`;

export const SearchInputMobileMainStyles = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    > div label input {
      min-height: ${theme.spacing(4)};
      background: ${theme.colors.neutrals.grey10};
      border-color: ${theme.colors.neutrals.grey9};
    }
  `}
`;

export const SubMenuBackButton = styled.div<{ isCheckMode?: boolean }>`
  display: flex;
  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutrals.grey1};
    > div {
      white-space: nowrap;
    }
  }

  svg {
    transform: ${({ theme }) => theme.isRTL ? 'rotate(180deg)' : ''};
    fill: ${props => props.theme.colors.neutrals.grey4};
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
  }
`;

export const SubMobileBackButton = styled(SubMenuBackButton)<{ isCheckMode?: boolean }>`
  a {
    &:after {
      display: ${({ theme, isCheckMode }) => theme.isRTL && !isCheckMode ? 'block' : 'none'};
    }
    > svg {
      margin: initial;
    }
  }
`;

export const MobileSubmenuWrapper = styled.div`
  height: ${MOBILE_SEUBMENU_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  padding: 0 ${({ theme }) => theme.spacing(3)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    display: flex;

    svg {
      padding: 0;
      border-radius: 0;
      background: rgba(255, 255, 255, 0);
      height: 24px;
      width: 24px;
    }
  }

  > div:nth-of-type(2) {
    height: ${({ theme }) => theme.spacing(3)};

    > div:not(:last-of-type) {
      margin-left: ${({ theme }) => theme.spacing(3)};
    }

    ${({ theme }) => theme.media.showAt({ to: 2 })`
      > div:last-of-type {
        margin-${theme.isRTL ? 'right' : 'left'}: 0;
      }
    `}
  }

  > div > div:first-of-type {
    svg {
      transform: scaleX(${({ theme }) => theme.isRTL ? '-' : ''}1);
    }
  }
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    width: 280px;
    height: 32px;
    flex-shrink: 0;
    .${DESKTOP_MULTI_INPUT_WRAPPER_CLASS} {
      width: 100%;
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 3 }) `
    width: 200px;
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    display: flex;
    flex-direction: row;
  `}
`;

export const MapButton = styled(Button)`
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.neutrals.grey2};
    margin-right: ${theme.spacing(1)};
    font-size: ${typographyFontSizeCfg.smallText}px;
    font-weight: 400;
    gap: ${theme.spacing(1)};
    border-color: ${theme.colors.neutrals.grey7};
    flex-shrink: 1;
    padding-right: ${theme.spacing(2.5)};
    padding-left: ${theme.spacing(2.5)};

    svg {
      transform: scaleX(-1);
      path {
        fill: ${theme.colors.neutrals.grey2};
      }
    }
  `}

`;

export const NavigationSearchInputWrapper = styled(SearchInputMobileMainStyles)`
  position: relative;
  display: flex;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(1)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(3)};

    &:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -${theme.spacing(2)};
    ${theme.isRTL ? 'left' : 'right'}: -${theme.spacing(4)};
    height: ${theme.spacing(4)};
    display: block;
    width: 1px;
    background: ${theme.colors.neutrals.grey8};
  }
  `}

  input {
      width: 300px;
      height: ${({ theme }) => theme.spacing(4)};
      font-size: 13px;

      &[placeholder] {
        text-overflow: ellipsis;
      }
    }

  & > div {
    display: flex;
    align-items: center;

    ${({ theme }) => theme.media.showAt({ to: 2 })`
      svg {
        height: ${theme.spacing(2)};
        width: ${theme.spacing(2)};
      }

      input {
        width: calc(100vw - 170px);
        max-width: 457px;
      }
    `}
  }

  & .autocomplete-textfield-wrapper {
    [data-auto=textfield-input-wrapper] > div:first-of-type {
      width: 16px;
      height: 16px;
    }
  }
`;

export const SearchSubheaderWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(3)};
  display: flex;
  width: 100%;
  align-items: center;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.colors.neutrals.grey8};
    flex-wrap: wrap;
  `}
`;

export const ItemPageNavigationSubheaderWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    display: flex;
    flex-grow: 1;
    padding-left: ${theme.spacing(3)};
  `}
`;

export const AgentConsoleNavigationSubheaderWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(3)};
`;

export const SearchInputSeparator = styled.div`
  height: 32px;
  width: 1px;
  margin: 0 16px;
  background: ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const SubheaderSeparator = styled.div`
  width: 0;
  max-width: 0;
  &::after {
    content: '';
    display: flex;
    height: 32px;
    width: 1px;
    background: ${({ theme }) => theme.colors.neutrals.grey6};
  }
`;

export const FiltersWrapper = styled.div`
  [data-button-filter-name] {
    white-space: nowrap;
    flex-shrink: 0;
  }
`;

export const ButtonsWrapper = styled.div<{ singleBtn: boolean; shouldDisplayLocalData: boolean; }>`
  ${({ theme, singleBtn, shouldDisplayLocalData }) => `
    margin-top: ${theme.spacing(1.5)};
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      flex-basis: ${singleBtn ? `100%` : `calc(50% - 6px)`};
    }
    margin-top: ${theme.spacing(1)};
    ${shouldDisplayLocalData ? `margin-top: ${theme.spacing(2)}` : ''};
    button {
      width: 100%;
      height: 40px;
      justify-content: center;

      ${shouldDisplayLocalData ? `
        height: 32px;
        border-radius: 0;
        border: none;
        padding-left: ${theme.spacing(2)};
        padding-right: 0;
        margin-top: 0;

        svg {
          margin-left: ${theme.spacing(0.5)};
        }
      ` : ''};
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    position: relative;
    > div {
      position: initial;
    }
  `}
`;

export const PrioritiesButton = styled(ControlButton)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.brand.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => colorToAlpha(theme.colors.brand.lightest, 0.7)};
  color: ${({ theme }) => theme.colors.brand.main};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
  }
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    height: 32px;
    padding: 0 12px;
    cursor: pointer;
  `}
    ${({ theme }) => theme.media.showAt({ to: 2 })`
    height: 40px;
  `}
`;

export const ExpandSubheaderWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const COLORED_COLLECTIONS = [
  ExpandCollection.Prices,
  ExpandCollection.Schools,
  ExpandCollection.Neighbourhood,
  ExpandCollection.ILSchools,
  ExpandCollection.Verified,
  ExpandCollection.AreaHighlights,
  ExpandCollection.NonVerified,
];

export const HighlightGroupLabel = styled.div<{ category: InsightType; group: ExpandCollection, type: InsightDescriptor }>`
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: 32px;
  ${({ category, theme, group }) => COLORED_COLLECTIONS.includes(group) ? `
    color: ${theme.colors.insights[category]}
  ` : ''}
`;

export const NavigationWrapper = styled.div<{ isModeB?: boolean }>`
  display: flex;
  width: 100%;
  min-height: ${({ isModeB }) => isModeB ? 48 : NAVIGATION_SUBHEADER_HEIGHT}px;

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    & > div:first-of-type {
      padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(3)};
    }
    & > div:nth-of-type(2) {
      padding-left: 0;
    }
  `};

  > div {
    display: flex;
  }
`;

export const WrapperTooltipFilter = styled.div`
  position: relative;
  width: 100%;
`;

export const MobileShareWrapper = styled.div`
  svg {
    path:last-of-type {
      fill: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
`;


export const CurrentPageBreadcrumb = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;
