import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import config from 'config';
import { ILatLngPoint, Size, BoundingBox } from 'utils/entities';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { zoomForBounds, getAspectRatio } from 'utils/zoomForBounds';


const DEFAULT_LAT = 32.18011647;
const DEFAULT_LNG = 34.858621365000005;


const MapSnapshotWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;


interface MapSnapshotProps extends ILatLngPoint {
  zoom?: number;
}

const constructStaticMapUrl = ({ lat, lng, zoom = 14 }: MapSnapshotProps, { width, height }: Size) => {
  const token = config.mapbox.accessToken;
  const mapUrl = config.mapbox.bulletinsMapStyleURL.replace('mapbox://styles/', '');
  return `https://api.mapbox.com/styles/v1/${mapUrl}/static/${lng || DEFAULT_LNG},${lat || DEFAULT_LAT},${zoom},0/${width}x${height}@2x?access_token=${token}&attribution=false&logo=false`;
};

export const MapSnapshot: React.FC<MapSnapshotProps> = React.memo((props) => {
  const [ size, setSize ] = useState<Size>(null);
  const containerEl = useRef<HTMLDivElement>(null);

  useResizeObserver(([ entry ]) => {
    setSize({
      width: Math.round(entry.contentRect.width),
      height: Math.round(entry.contentRect.height),
    });
  }, [ containerEl ]);

  return (
    <MapSnapshotWrapper ref={containerEl}>
      {size ? (
        <img src={constructStaticMapUrl(props, size)} style={{ width: '100%', height: '100%' }} />
      ) : null}
    </MapSnapshotWrapper>
  );
});


interface MapSnapshotBoundsProps {
  bounds: BoundingBox;
  padding: any;
  base: number;
}

const PADDING = 220;

export const MapSnapshotBounds: React.FC<MapSnapshotBoundsProps> = (props) => {

  const { base, bounds, padding } = props;
  const ratio = getAspectRatio(bounds);
  const width = ratio < 1 ? Math.round(base * ratio) : base;
  const height = ratio < 1 ? base : Math.round(base / ratio);

  const { center, zoom } = zoomForBounds(width, height, bounds, padding);

  const [ lng, lat ] = center;
  const url = constructStaticMapUrl({
    zoom,
    lng,
    lat,
  }, { width: width + PADDING, height: height + PADDING });

  return (
    <>

      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url('${url}')`,
          backgroundSize: 'cover',
          filter: 'blur(14px)',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: -PADDING / 2,
          left: -PADDING / 2,
          right: -PADDING / 2,
          bottom: -PADDING / 2,
          backgroundImage: `url('${url}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </>
  );

};
