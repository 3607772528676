import { connect } from 'react-redux';
import { FeedbackPopupView, ISubmitPopupData } from './feedback-popup-view';
import { State } from 'store/state';
import { mutate } from 'store/state/mutationsResponse/actions';
import { MutationType } from 'store/sagas/apiService/types';
import { activeInsightIdSelector } from 'store/state/insightsContext/selectors';
import { FeedbackPopupType } from './utils';
import { feedbackVariablesSelector } from 'store/state/domainData/selectors/feedbackVariables';

export interface FeedbackPopupOwnProps {
  popupTitle: string;
  submitButtonText: string;
  onSubmit: () => void;
  feedbackType: FeedbackPopupType;
}


const mapStateToProps = (state: State) => {
  const activeInsightId = activeInsightIdSelector(state);
  return {
    activeInsightId: activeInsightIdSelector(state),
    variables: feedbackVariablesSelector(state, activeInsightId),
  };
};

const mapDispatchToProps = {
  mutate,
};

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps: FeedbackPopupOwnProps) => ({
  ...ownProps,
  onSubmit: (submitPopupData: ISubmitPopupData) => {
    ownProps.onSubmit();
    dispatchProps.mutate({
      mutationType: MutationType.SendFeedback,
      meta: {
        key: stateProps.activeInsightId,
        variables: {
          ...stateProps.variables,
          ...submitPopupData,
          feedbackType: ownProps.feedbackType,
        },
      },
    });
  },
}))(FeedbackPopupView);
