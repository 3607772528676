import React, { useState, useCallback, useRef, useEffect, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { ClickAwayListener } from 'components/authentication/ClickAwayListener';
import { Manager, PopperProps, RefHandler, PopperArrowProps, Reference, Popper } from 'react-popper';
import { Modifiers, Placement } from 'popper.js';
import { createPanResponder } from 'helpers/pan-responder';
import { ShowAt } from 'consts/breakpoints';
import CloseIcon from 'assets/svg/interface-16-close-1-5.svg';
import { noop } from 'lodash';

type TooltipSize = 'small' | 'medium';

interface TooltipProps {
  tooltip: React.ReactNode;
  placement?: PopperProps['placement'];
  size?: TooltipSize;
  hideArrow?: boolean;
  raw?: boolean;
  wrapperStyle?: React.CSSProperties;
  tapThreshold?: number;
  clickable?: boolean;
  disableClickAway?: boolean;
}

type TooltipWrapperProps = Pick<TooltipProps, 'size' | 'hideArrow' | 'clickable'>;

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  max-width: 300px;
  background-color: ${({ theme }) => theme.colors.neutrals.grey1};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  box-shadow: ${props => props.theme.shadow.level1};
  color: ${props => props.theme.colors.neutrals.white};
  padding: ${({ size }) => size === 'small' ? '4px 8px' : '12px' };
  z-index: 101;
  ${({ clickable }) => clickable ? `
    display: flex;
    align-items: center;
  ` : ''};
  &[data-placement^="top"] {
    margin-bottom: ${ ({ hideArrow }) => hideArrow ? '6px' : '12px' };
  }
  &[data-placement^="left"] {
    margin-right: ${ ({ hideArrow }) => hideArrow ? '6px' : '12px' };
  }
  &[data-placement^="right"] {
    margin-left: ${ ({ hideArrow }) => hideArrow ? '6px' : '12px' };
  }
  &[data-placement^="bottom"] {
    margin-top: ${ ({ hideArrow }) => hideArrow ? '6px' : '12px' };
  }
`;

const TooltipArrow = styled.div<{ placement: string }>`
  position: absolute;
  ${({ placement }) => placement && placement.split('-')[0]}: 100%;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: -6px;
    left: calc(50% - 6px);
    background: ${({ theme }) => theme.colors.neutrals.grey1};
    transform: rotate(45deg);
    box-shadow: ${({ theme }) => theme.shadow.level1};
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
`;

const LONG_TAP_THRESHOLD = 150;

interface TooltipContainerProps {
  linkRef: RefHandler;
  style: React.CSSProperties;
  placement: Placement;
  arrowProps: PopperArrowProps;
  onClick?: () => void;
  size?: TooltipSize;
  hideArrow?: boolean;
  onMouseEnter?(event: React.MouseEvent<HTMLElement>): void;
  onMouseLeave?(event: React.MouseEvent<HTMLElement>): void;
  scheduleUpdate?(): void;
  styleVisibility?: boolean;
}

export const TooltipContainer: React.FC<TooltipContainerProps> = (props) => {
  const {
    linkRef,
    style,
    placement,
    onClick,
    size,
    hideArrow,
    children,
    arrowProps,
    onMouseEnter,
    onMouseLeave,
    scheduleUpdate,
    styleVisibility,
  } = props;

  useEffect(() => {
    if (scheduleUpdate) {
      scheduleUpdate();
    }
  }, [ styleVisibility ]);

  const wrapperStyle: CSSProperties = styleVisibility === undefined
    ? style
    : { ...style, visibility: styleVisibility ? 'visible' : 'hidden' };

  return (
    <TooltipWrapper
      ref={linkRef}
      style={wrapperStyle}
      data-placement={placement}
      onClick={onClick}
      size={size}
      hideArrow={hideArrow}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="tooltip-wrapper"
    >
      {children}
      {!hideArrow && (
        <TooltipArrow
          className="tooltip-arrow"
          placement={placement}
          ref={arrowProps.ref}
          style={arrowProps.style}
        />
      )}
    </TooltipWrapper>
  );
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  placement,
  size,
  hideArrow,
  raw,
  wrapperStyle,
  tapThreshold = LONG_TAP_THRESHOLD,
  clickable,
  disableClickAway,
}) => {
  const [ tooltipVisible, setTooltipVisible ] = useState(false);
  const [ hasBeenClicked, setHasBeenClicked ] = useState(false);
  const handleMouseEnter = useCallback(() => {
    if (!tooltipVisible) setTooltipVisible(true);
  }, [ tooltipVisible ]);
  const handleMouseLeave = useCallback(() => !hasBeenClicked && setTooltipVisible(false), [ hasBeenClicked ]);
  const handleAwayClick = useCallback(() => {
    setTooltipVisible(false);
    setHasBeenClicked(false);
  }, []);

  useEffect(() => {
    if (clickable) {
      setTooltipVisible(true);
    }
  }, [ clickable ]);

  const touchStartTime = useRef(null);
  const touchHandlers = clickable ? null : useRef(createPanResponder({
    onStart: () => {
      touchStartTime.current = +Date.now();
      setTooltipVisible(false);
      setHasBeenClicked(false);
    },
    onMove: (state, e) => {
      if (!state.down) {
        const isLongTap = (+Date.now() - touchStartTime.current) > tapThreshold;
        if (isLongTap) {
          setTooltipVisible(true);
          e.preventDefault();
        }
      }
    },
    onEnd: () => {
      touchStartTime.current = null;
    },
  }));

  const mouseHandlers = clickable ? null : {
    onMouseOver: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  const modifiers: Modifiers = {
    preventOverflow: {
      boundariesElement: 'viewport',
    },
  };

  return (
    <Manager>
      <ShowAt at={1}>
        {isMobile => (
          <Reference>
            {({ ref }) => (
              <div
                {...(isMobile ? touchHandlers.current : mouseHandlers)}
                className="tooltip-wrapper"
                ref={ref}
              >
                {children}
              </div>
            )}
          </Reference>
        )}
      </ShowAt>
      {tooltipVisible ? (
        <ClickAwayListener onClickAway={disableClickAway ? noop : handleAwayClick}>
          <Popper
            placement={placement || 'top'}
            positionFixed
            modifiers={modifiers}
          >
            {({ ref, style, placement: tooltipPlacement, arrowProps }) => (
              raw ? (
                ReactDOM.createPortal(
                  <div ref={ref} style={{ ...style, zIndex: 102 }} data-placement={placement}>
                    {tooltip}
                  </div>, document.getElementById('sticky-root'))
              ) : (
                ReactDOM.createPortal(
                  <TooltipWrapper
                    ref={ref}
                    style={{ ...style, ...wrapperStyle }}
                    data-placement={tooltipPlacement}
                    onClick={clickable ? undefined : handleAwayClick}
                    size={size}
                    hideArrow={hideArrow}
                    clickable={clickable}
                  >
                    {tooltip}
                    {!hideArrow && (
                      <>
                        <TooltipArrow
                          placement={tooltipPlacement}
                          ref={arrowProps.ref}
                          style={arrowProps.style}
                        />
                        {clickable ? (
                          <CloseWrapper>
                            <CloseIcon data-auto="tooltip-close-button" onClick={handleAwayClick} />
                          </CloseWrapper>
                        ) : null}
                      </>
                    )}
                  </TooltipWrapper>, document.getElementById('sticky-root'))
              ))}
          </Popper>
        </ClickAwayListener>
      ) : null}
    </Manager>
  );
};

export default Tooltip;
