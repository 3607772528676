const emojisEncodeRange = [
  '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
  '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
  '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
];

const emojiRef = new RegExp(emojisEncodeRange.join('|'), 'g');
const emptySpace = String.fromCharCode(8205);

function replaceEmoji(v: string): string {
  if (v && v.length) {
    return v.replace(emojiRef, '').replace(emptySpace, '');
  }
  return v;
}

export default replaceEmoji;
