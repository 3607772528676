import React from 'react';
import styled from '@emotion/styled';
import { withLocale, WithLocaleProps } from 'locale';
import { ControlButton } from 'ds/components/button';
import AdjustmentsIcon from 'assets/svg/adjustments.svg';
import { useTheme } from 'hooks/useTheme';

interface NumberIconProps {
  num: number;
}

const FilterLabel = styled.span`
  ${({ theme }) => `color: ${theme.colors.neutrals.grey1};`}
`;

interface FilterSummaryButtonProps extends WithLocaleProps {
  isScrolled?: boolean;
  filtersNumber?: number;
  onClick?: (e?: React.MouseEvent) => void;
}

const FilterSummaryButton: React.FunctionComponent<FilterSummaryButtonProps> = ({
  t,
  onClick,
  filtersNumber,
  isScrolled,
}) => (
  <ControlButton onClick={onClick} onlyIcon={isScrolled} isInitialIconColor>
    {filtersNumber ? <NumberIcon num={filtersNumber} /> : <AdjustmentsIcon />}
    {!isScrolled && <FilterLabel data-auto="filtersButton">{t('filters.filterResults')}</FilterLabel>}
  </ControlButton>
);

export const NumberIcon: React.FunctionComponent<NumberIconProps> = ({ num }) => {
  const theme = useTheme();
  return (
    <svg width={16} viewBox="0 0 16 16" height={16} >
      <rect fill={theme.colors.brand.main} width={16} height={16} rx={4} ry={4}/>
      <text textAnchor="middle" style={{ font: '13px/13px NovemberHebrew' }} x={8} y={12} fill="white">{num}</text>
    </svg>
  );
};

export default withLocale(FilterSummaryButton);
