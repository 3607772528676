import { State as Route } from 'config/routes';
import { RouterAction } from './actionType';
import {
  TRANSITION_START,
  TRANSITION_SUCCESS,
  TRANSITION_ERROR,
  CLEAR_ERRORS,
} from './types';
import { last } from 'lodash';

export const MAX_ROUTES_HISTORY_SIZE = 20;

export interface RouterState {
  routesHistory: Route[];
  transitionRoute: Route;
  transitionError: any;
}

export const initialState: RouterState = {
  routesHistory: [],
  transitionRoute: null,
  transitionError: null,
};

export default function routerReducer(state: RouterState = initialState, action: RouterAction) {
  switch (action.type) {
    case TRANSITION_START:
      return {
        ...state,
        transitionRoute: action.payload.route,
        transitionError: null,
      };

    case TRANSITION_SUCCESS: {
      const { routesHistory } = state;
      const { route } = action.payload;
      const { replace = false } = route && route.meta && route.meta.options || {};

      let newRoutesHistory: Route[];
      if (replace) {
        newRoutesHistory = routesHistory.slice(0, - 1).concat(route);
      }
      else {
        const idx = routesHistory.findIndex(item => item.meta.id === route.meta.id);
        if (idx === -1) {
          newRoutesHistory = [ ...routesHistory, route ].slice(-MAX_ROUTES_HISTORY_SIZE);
        }
        else {
          newRoutesHistory = routesHistory.slice(0, idx + 1);
        }
      }
      const lastRoute = last(newRoutesHistory);
      newRoutesHistory = [
        ...newRoutesHistory.slice(0, -1),
        {
          ...lastRoute,
          meta: {
            ...lastRoute.meta,
            middlewareMeta: route && route.meta && route.meta.middlewareMeta,
          },
        },
      ];

      return {
        ...state,
        transitionRoute: null,
        transitionError: null,
        routesHistory: newRoutesHistory,
      };
    }

    case TRANSITION_ERROR:
      return {
        ...state,
        transitionRoute: action.payload.route,
        transitionError: action.payload.transitionError,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        transitionRoute: null,
        transitionError: null,
      };

    default:
      return state;
  }
}
