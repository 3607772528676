import React from 'react';
import { IABTestContext } from 'config/abTests';
import { useABTests } from 'hooks/useABTests';


export function withABTests<P extends {}>(
  Component: React.ComponentType<P & IABTestContext>
): React.FC<P> {
  return (props) => {
    const abtests = useABTests();
    return <Component {...props} {...abtests} />;
  };
}

export function withAbTestsSwitcher<K extends keyof IABTestContext, P extends {}>(
  abTestKey: K,
  switchMap: Partial<Record<IABTestContext[K], React.ComponentType<P>>>,
  FallbackComponent: React.ComponentType<P>
): React.ComponentType<P> {
  return React.forwardRef((props, ref) => {
    const abtests = useABTests();
    const value = abtests[abTestKey];
    const Comp: React.ComponentType<P> = switchMap[value] || FallbackComponent;

    return <Comp ref={ref} {...props as any} />;
  }) as unknown as React.ComponentType<P>;
}
