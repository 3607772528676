import { call, fork, put, all, select } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { setAuthModal, setToastMessage } from 'store/state/app/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { SortDirection, SortField } from 'components/listing-sort/types';
import { verifyRessetPasswordToken, fetchArticles } from 'store/sagas/routing/handlers/utils';
import { navigateTo } from 'store/state/router/actions';
import { IABTestContext, RemoveWizard } from 'config/abTests';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';
import { LocalStorage } from 'utils/localStorage';
import { IS_WIZARD_COMPLETED } from 'consts/localStorageKeys';
import { insideReactNative } from 'react-native/lib/platform';


export function* loadData(initialState: RouteState) {
  // TODO: move it to qgl file after BE will fix Reference type
  const sortFields: any = [
    [
      { field: SortField.PriceEstimation, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'רחובות-ישראל', field: 'doc-id' },
        docId: 'רחובות-ישראל',
      },
    ],
    [
      { field: SortField.PriceEstimation, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'רמת-גן-ישראל', field: 'doc-id' },
        docId: 'רמת-גן-ישראל',
      },
    ],
    [
      { field: SortField.PriceEstimation, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'נתניה-ישראל', field: 'doc-id' },
        docId: 'נתניה-ישראל',
      },
    ],
    [
      { field: SortField.BestSchool, order:  SortDirection.Asc },
      { field: SortField.FamilyFriendly, order: SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'רחובות-ישראל', field: 'doc-id' },
        docId: 'רחובות-ישראל',
      },
    ],
    [
      { field: SortField.BestSchool, order:  SortDirection.Asc },
      { field: SortField.FamilyFriendly, order: SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'תל-אביב-יפו-ישראל', field: 'doc-id' },
        docId: 'תל-אביב-יפו-ישראל',
      },
    ],
    [
      { field: SortField.BestSchool, order:  SortDirection.Asc },
      { field: SortField.FamilyFriendly, order: SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'חיפה-ישראל', field: 'doc-id' },
        docId: 'חיפה-ישראל',
      },
    ],
    [
      { field: SortField.QuietStreets, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'באר-שבע-ישראל', field: 'doc-id' },
        docId: 'באר-שבע-ישראל',
      },
    ],
    [
      { field: SortField.QuietStreets, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'תל-אביב-יפו-ישראל', field: 'doc-id' },
        docId: 'תל-אביב-יפו-ישראל',
      },
    ],
    [
      { field: SortField.QuietStreets, order:  SortDirection.Asc },
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'קרית-מוצקין-ישראל', field: 'doc-id' },
        docId: 'קרית-מוצקין-ישראל',
      },
    ],
    [
      { field: SortField.Geometry, order: SortDirection.Asc,
        reference: { dataset: { country: 'israel', type: 'places' }, value: 'תל-אביב-יפו-ישראל', field: 'doc-id' },
        docId: 'תל-אביב-יפו-ישראל',
      },
    ],
  ];
  yield fork(queryData, {
    loadType: LoadType.IlHomePageBulletins,
    meta: {
      variables: {
        sort1: sortFields[0],
        sort2: sortFields[1],
        sort3: sortFields[2],
        sort4: sortFields[3],
        sort5: sortFields[4],
        sort6: sortFields[5],
        sort7: sortFields[6],
        sort8: sortFields[7],
        sort9: sortFields[8],
        sort10: sortFields[9],
      },
    },
  });
  const loadOptions: LoadOptions<LoadType.AreasWithCommercialInfoPage> = {
    loadType: LoadType.AreasWithCommercialInfoPage,
    meta: {
      variables: {},
    },
  };
  yield fork(queryData, loadOptions);
}

export function* homeHandler(initialState: RouteState) {
  const isMobileApp = insideReactNative();
  const abTestsContext: IABTestContext = yield select(abTestsStateSelector);
  yield all([
    call(loadData, initialState),
    call(queryData, {
      loadType: LoadType.HomePageBanner,
      meta: { variables: { useTimestamp: isMobileApp } },
    }),
    call(fetchArticles, null, 'homepage'),
  ]);

  const token = initialState.params.token;

  if (abTestsContext.removeWizard === RemoveWizard.modeB) {
    LocalStorage.set(IS_WIZARD_COMPLETED, true);
  }

  if (token) {
    const isTokenValid: boolean = yield call(verifyRessetPasswordToken, token);

    if (isTokenValid) {
      yield put(setAuthModal({ type: AuthenticationModalType.SetPassword }, { isUserInitiated: false }));
    }
    else {
      yield put(setAuthModal({ type: AuthenticationModalType.ResetPassword }, { isUserInitiated: false }));
      yield put(setToastMessage({ term: 'resetPasswordToken.expiredOrInvalid' }));
      yield put(navigateTo(Route.Home, null, { replace: true }));
    }
  }
}
