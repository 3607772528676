import { Route, State as RouteState } from 'config/routes';
import { head } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadType } from 'store/sagas/apiService/types';
import { RootAction } from 'store/state';
import { resetDomainData } from 'store/state/domainData/actions';
import { cityDocIdSelector } from 'store/state/domainData/selectors/searchMortgageOffices';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { prevRouteSelector, routeSelector } from 'store/state/selectors/router';

export const MORTGAGE_OFFICES_PER_PAGE = 12;


function* loadData() {
  const cityDocId = yield select(cityDocIdSelector);

  const currentRoute: RouteState = yield select(routeSelector);
  const previousRoute: RouteState = yield select(prevRouteSelector);

  if (currentRoute.name === Route.MortgageOfficesSearchPage || (previousRoute && previousRoute.name === Route.UnitPage && currentRoute.name === Route.MortgageOfficesCitySearchPage)) {
    yield put(resetDomainData({ loadType: LoadType.SearchDoc }));
  }

  yield call(queryData, {
    loadType: LoadType.SearchDoc,
    meta: {
      variables: { docIds: cityDocId ? [ cityDocId ] : [] },
    },
  });


  yield call(queryData, {
    loadType: LoadType.MortgageOfficesByCityDocIds,
    meta: {
      // TODO until SSR disabled no offset
      variables: { query: { cityDocIds: cityDocId ? [ cityDocId ] : [], limit: 1000  } },
    },
  });
}

const mortgageOfficeIndexPattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS
  && (action.payload.route.name === Route.MortgageOfficesSearchPage || action.payload.route.name === Route.MortgageOfficesCitySearchPage)
  && (
    !action.payload.previousRoute
    || (
      head([ ...(action.payload.previousRoute.params.term || []) ]) !== head([ ...(action.payload.route.params.term || []) ])
    )
  )
);

export function* mortgageOfficesSearchPageHandler(params: RouteState) {
  yield call(loadData);
  yield takeEvery(mortgageOfficeIndexPattern, loadData);
}

export function* ssrMortgageOfficesSearchPageHandler(params: RouteState) {
  yield call(loadData);
}
