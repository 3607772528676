import { IEnhancer, Fetcher } from '.';

export class PerimeterXListener implements IEnhancer {
  public enhance = async (url: string, init: RequestInit, innerFetch: Fetcher) => {
    const response = await innerFetch(url, init);

    if (init.method === 'POST' && response.status === 403 && response.headers.get('content-type') === 'text/html') {
      const expr = /px-captcha/gm;
      const txtResult = await response.text();
      if (expr.test(txtResult)) {
        await (new Promise(() => {
          window.location.reload();
        }));
      }
    }

    return response;
  };
}
