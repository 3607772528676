import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { CommutePreference, User, CommuteType } from 'utils/entities';
import { H3 } from 'ds/components/typography';
import { useLocale } from 'locale';
import {
  CommutePreferenceTitle,
  ProfileButtonWrapper,
} from './styled';
import { Button } from 'ds/components/button';
import { useTheme } from 'hooks/useTheme';
import { GenericCommutePreferenceForm } from 'components/commute-popup/GenericCommutePreferenceForm';
import { ShowAt } from 'consts/breakpoints';

interface CommutePopupFormProps {
  onSaveCommute: (commutePreference: CommutePreference) => void;
  user: User;
}

export const CommutePopupForm: React.FC<CommutePopupFormProps> = ({
  user = {},
  onSaveCommute,
}) => {
  const { t } = useLocale();

  const commutePreference: CommutePreference = useMemo(() => user.commutePreference || null, [ user ]);
  const safeCommutePreferenceCheck = useCallback(() => commutePreference || { rushHour: null, commuteType: null, text: null, location: null }, [ user ]);

  const [ searchStr, setSearchStr ] = useState<string>('');
  const [ commuteType, setCommuteType ] = useState<CommuteType>('commute');
  const [ rushHour, setRushHour ] = useState<boolean>(Boolean(false));
  const [ address, setAddress ] = useState(null);

  useEffect(() => {
    if (user && user.commutePreference) {
      setSearchStr(safeCommutePreferenceCheck().text || '');
      setCommuteType(safeCommutePreferenceCheck().commuteType);
      setRushHour(safeCommutePreferenceCheck().rushHour);
    }
  }, [ user ]);

  const theme = useTheme();

  const handleSaveCommute = () => {
    onSaveCommute({
      ...address,
      commuteType,
      rushHour,
    });
  };

  const isValid = Boolean(address);
  const hasChanges = ((address && address.text !== safeCommutePreferenceCheck().text)
    || rushHour !== safeCommutePreferenceCheck().rushHour
    || commuteType !== safeCommutePreferenceCheck().commuteType);

  return (
    <>
      <ShowAt from={3}>
        <CommutePreferenceTitle>
          <H3 weight="bold">{t('profile.commuteFormTitle')}</H3>
        </CommutePreferenceTitle>
      </ShowAt>
      <GenericCommutePreferenceForm
        searchStr={searchStr}
        commuteType={commuteType}
        rushHour={rushHour}
        accentColor={theme.colors.brand.main}
        initialPreference={commutePreference}
        onSetSearchStr={setSearchStr}
        onSetCommuteType={setCommuteType}
        onSetRushHour={setRushHour}
        onAddressSelect={setAddress}
      />
      <ProfileButtonWrapper>
        <ShowAt at={1}>
          {matches => (
            <Button
              data-auto="save-commute-button"
              size="large"
              disabled={!isValid || !hasChanges}
              fullWidth={matches}
              onClick={handleSaveCommute}
            >
              {t('commutePopup.submitButtonLabel')}
            </Button>
          )}
        </ShowAt>
      </ProfileButtonWrapper>
    </>
  );
};
