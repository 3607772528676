import { Route, State as RouteState } from 'config/routes';
import { createSelector } from 'reselect';
import { routeSelector } from 'store/state/selectors/router';
import { MortgageFormProps, Mortgage } from './MortgageForm';
import { State } from 'store/state';
import React from 'react';
import { connect } from 'react-redux';
import { projectPageProjectSelector, projectPageCommercialProjectSelector } from 'store/state/domainData/selectors';
import { IProject, PromotionValues } from 'utils/entities';

interface MortgageFormPopupAfterLeadProps extends MortgageFormProps {
  projectPage: IProject;
  route: RouteState;
}

const MortgageFormPopupAfterLead: React.FC<MortgageFormPopupAfterLeadProps> = props => {
  const { projectPage, route, ...rest } = props;
  const hidePhoneNumber = projectPage && projectPage.promotionStatus.status === PromotionValues.NotPromoted && (route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial);

  return <Mortgage hidePhoneNumber={hidePhoneNumber} {...rest} />;
};

export const projectSelector = createSelector([
  routeSelector,
  projectPageProjectSelector,
  projectPageCommercialProjectSelector,
], (route, project, commercialProject) => {
  switch (route.name) {
    case Route.ProjectPage:
      return project;
    case Route.ProjectPageCommercial:
      return commercialProject;
    default:
      return null;
  }
});

const mapStateToProps = (state: State) => ({
  projectPage: projectSelector(state),
  route: routeSelector(state),
});

export default connect(mapStateToProps)(MortgageFormPopupAfterLead);
