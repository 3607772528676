import { takeEvery, put, getContext, call, select, fork, take, all } from 'redux-saga/effects';
import { AUTH_GOOGLE, AUTH_FACEBOOK, ReactNativeClientTunnelEvent, BACK_BTN, ReactNativeClientTunnel, EXIT_BACKGROUND, REGISTER_PUSH_DEVICE_TOKEN, REPORT_PUSH_STATUS } from 'react-native/reactNativeClientTunnel';
import { mutate } from 'store/state/mutationsResponse/actions';
import { MutationType, LoadOptions, LoadType, RemoveNotificationDeviceReason } from 'store/sagas/apiService/types';
import { get } from 'lodash';
import { eventChannel, buffers } from 'redux-saga';
import { prevRouteSelector, routeSelector } from 'store/state/selectors/router';
import { setDevicePushStatus, setToastMessage } from 'store/state/app/actions';
import { DevicePushStatus } from 'store/state/app';
import { Platform } from 'react-native/config';
import { DeviceType, User } from 'utils/entities';
import { getPlatformName } from 'react-native/lib/platform';
import { queryData } from 'store/sagas/apiService';
import { waitForUserChange } from 'store/sagas/routing/handlers/utils';
import { userProfileSelector } from 'store/state/domainData/selectors';
import { LOGOUT } from 'store/state/app/types';
import { devicePushStatusSelector } from 'store/state/app/selectors';
import { mutateWorker } from 'store/sagas/apiService/mutationsWatcher';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { State as RouteState, Route } from 'config/routes';
import { pushNotificationsFeatureEnabled } from 'utils/pushNotificationsFeatureEnabled';


const PLATFORM_MAPPING: Record<Platform, DeviceType> = {
  android: 'Android',
  ios: 'iOS',
};

function* reactNativeActionHandler(action: ReactNativeClientTunnelEvent) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_PUSH_DEVICE_TOKEN: {
      const pushStatus = payload as DevicePushStatus;
      yield put(setDevicePushStatus(pushStatus));
      if (pushStatus.hasPermission) {
        yield put(mutate({
          mutationType: MutationType.AddNotificationDevice,
          meta: {
            variables: {
              deviceToken: pushStatus.deviceToken,
              deviceType: PLATFORM_MAPPING[getPlatformName()],
            },
          },
        }));
      }

      break;
    }
    case AUTH_GOOGLE:
      yield put(mutate({
        mutationType: MutationType.GoogleLogin,
        meta: {
          variables: {
            idToken: get(payload, 'idToken'),
          },
        },
      }));
      break;
    case REPORT_PUSH_STATUS:
      yield put(setDevicePushStatus(payload as any));
      break;
    case BACK_BTN:
      const prevRoute = yield select(prevRouteSelector);
      if (prevRoute) {
        window.history.back();
      }
      else {
        const reactNativeClientTunnel: ReactNativeClientTunnel = yield getContext('reactNativeClientTunnel');
        reactNativeClientTunnel.push({ type: EXIT_BACKGROUND, payload: null });
      }
      break;
    case AUTH_FACEBOOK:
      yield put(mutate({
        mutationType: MutationType.FacebookLogin,
        meta: {
          variables: {
            fbUserId: get(payload, 'userID'),
            accessToken: get(payload, 'accessToken'),
            email: get(payload, 'email'),
          },
        },
      }));
      break;
    default:
      break;
  }
}

function* loadNotificationDevices() {
  while (true) {
    yield call(waitForUserChange, (a, b) => ((a && a.uid) === (b && b.uid)));

    const user: User = yield select(userProfileSelector);
    if (user) {
      const loadOpts: LoadOptions<LoadType.UserNotificationDevices> = {
        loadType: LoadType.UserNotificationDevices,
        meta: {
          variables: {
            __uid: user.uid,
          },
        },
      };
      yield call(queryData, loadOpts);
    }

  }
}

function* logoutHandler() {
  const status: DevicePushStatus = yield select(devicePushStatusSelector);
  if (status && status.deviceToken) {
    yield call(mutateWorker, mutate({
      mutationType: MutationType.RemoveNotificationDevice,
      meta: {
        variables: {
          deviceToken: status.deviceToken,
          deleteReason: RemoveNotificationDeviceReason.LOGGED_OUT,
        },
      },
    }));
  }
}

function enablePushPattern(action: RootAction) {
  return action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.AddNotificationDevice;
}

function goBack() {
  window.history.back();
}

function* handlePushFeedback() {
  while (true)  {
    yield take(enablePushPattern);
    const prevRoute: RouteState = yield select(prevRouteSelector);
    const route: RouteState = yield select(routeSelector);

    if (route.name !== Route.PushSettings) {
      yield put(setToastMessage({ term: 'pushNotifications.manage.successToast' }));
    }
    if (route.name === Route.SavedSearchesPage && prevRoute.name === Route.Search) {
      yield call(goBack);
    }
  }
}

function* pushWorker() {
  const pushFeatureEnabled = yield call(pushNotificationsFeatureEnabled);
  if (!pushFeatureEnabled) return;

  yield fork(loadNotificationDevices);
  yield fork(handlePushFeedback);
  yield takeEvery(LOGOUT, logoutHandler);
}

function* eventsHandler() {
  const { subscribe } = yield getContext('reactNativeClientTunnel');
  const channel = yield call(eventChannel, subscribe, buffers.expanding(10));
  yield takeEvery(channel, reactNativeActionHandler);
}

export function* reactNativeWatcher() {
  yield all([
    fork(eventsHandler),
    fork(pushWorker),
  ]);
}
