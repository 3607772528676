import styled from '@emotion/styled';

export const RadioButtonContainer = styled.label<{inTest: boolean}>`
  display: flex;
  margin-bottom: ${({ theme, inTest }) => theme.spacing(inTest ? 2 : 3)};
  cursor: pointer;
  align-items: center;
  input[type=radio] {
    display: none;
  }
`;

export const RadioMark = styled.div`
  width: 18px;
  height: 18px;
  border: solid 1px ${props => props.theme.colors.neutrals.grey6};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  background-color: ${props => props.theme.colors.neutrals.white};
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(1.5)};

  input:checked + & {
    position: relative;
    border-color: ${({ theme }) => theme.colors.brand.main};

    &::before {
      content: '';
      position: absolute;
      top: 25%;
      left: 25%;
      width: 50%;
      height: 50%;
      border-radius: ${({ theme }) => theme.borderRadius.round};
      background: ${({ theme }) => theme.colors.brand.main};
    }
  }
`;
