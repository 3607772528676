import React from 'react';
import { connect } from 'react-redux';
import { Route, useRoute } from 'config/routes';
import { State } from 'store/state';
import { isEditCommercialAllowedSelector, isEditStatusAllowedSelector } from 'store/state/domainData/selectors';
import ManageMenu from 'components/manage-bulletin/self-contained';


interface MaybeEditStatusWithRouteProps {
  isEditStatusAllowed: boolean;
  isEditCommercialStatusAllowed: boolean;
}

const MaybeEditStatusWithRoute: React.FC<MaybeEditStatusWithRouteProps> = ({
  isEditStatusAllowed,
  isEditCommercialStatusAllowed,
}) => {
  const { route: { name } } = useRoute();

  switch (name) {
    case Route.UnitPage:
      return isEditStatusAllowed ? <ManageMenu iconOnly /> : null;
    case Route.UnitPageCommercial:
      return isEditCommercialStatusAllowed ? <ManageMenu iconOnly /> : null;
    default:
      return null;
  }
};

const mapStateToProps = (state: State) => ({
  isEditStatusAllowed: isEditStatusAllowedSelector(state),
  isEditCommercialStatusAllowed: isEditCommercialAllowedSelector(state),
});

export default connect(mapStateToProps)(MaybeEditStatusWithRoute);
