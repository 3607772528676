import { createSelector } from 'reselect';
import {
  makeNullSafeDomainGetter,
  listingPostLeadCarouselSearchSelector,
  createIsLoadingSelector,
  makeByIdsDictionarySelector,
} from 'store/state/domainData/selectors/common';
import { IBulletin, ICommercialBulletin } from 'utils/entities';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { MutationType } from 'store/sagas/apiService/types';
import { filterRelevantAgentsListings } from 'store/state/domainData/selectors/additionalListingCarousel';

export const listingsPostLeadCarouselSelector = makeNullSafeDomainGetter(listingPostLeadCarouselSearchSelector, 'searchPoiV2');
export const isListingsPostLeadCarouselLoadingSelector = createIsLoadingSelector(listingPostLeadCarouselSearchSelector);

const EMPTY: Array<IBulletin | ICommercialBulletin> = [];
export const listingsPostLeadCarouselListSelector  = createSelector(listingsPostLeadCarouselSelector, (search) => search && search.poi ? search.poi : EMPTY);
export const listingsPostLeadCarouselDictSelector = makeByIdsDictionarySelector(listingsPostLeadCarouselListSelector);

export const postLeadCarouselListingsSelector = createSelector([
  listingsPostLeadCarouselListSelector,
  currentPoiSelector,
  state => state.mutationsResponse[MutationType.ContactPageSubmit],
], (
  pois: Array<IBulletin | ICommercialBulletin>,
  currentPoi,
  contactedMap
) => {
  const currentPoiId = currentPoi ? currentPoi.id : null;

  return filterRelevantAgentsListings(pois.filter(poi => {
    const isContacted = Boolean(contactedMap && contactedMap[poi.id] && contactedMap[poi.id].data && contactedMap[poi.id].data.contactV4);
    return poi.id !== currentPoiId && !isContacted;
  }));
});

export const hasPostLeadListingsSelector = createSelector(postLeadCarouselListingsSelector, data => Boolean(data && data.length));
