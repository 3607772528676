import { css } from '@emotion/core';

const fontUrl = 'https://assets.localize.city/ng';
const fontUrlNovemberHebrew = 'https://assets.localize.city/novemberHebrew';
/* tslint:disable */
export default css`

@font-face {
    font-family: 'NovemberHebrew';
    src: url('${fontUrl}/NovemberHebrew-Medium.woff2') format('woff2'),
        url('${fontUrl}/NovemberHebrew-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NovemberHebrew';
    src: url('${fontUrl}/NovemberHebrew-Regular.woff2') format('woff2'),
        url('${fontUrl}/NovemberHebrew-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NovemberHebrew';
    src: url('${fontUrl}/NovemberHebrew-Bold.woff2') format('woff2'),
        url('${fontUrl}/NovemberHebrew-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face
   {
       font-family: "NovemberHebrew";
       src: url('${fontUrlNovemberHebrew}/WF-030306-010351-002157.eot');
       src: url('${fontUrlNovemberHebrew}/WF-030306-010351-002157.eot?#iefix') format('embedded-opentype'),
          url('${fontUrlNovemberHebrew}/WF-030306-010351-002157.woff') format('woff'),
          url('${fontUrlNovemberHebrew}/WF-030306-010351-002157.ttf') format('truetype');
       font-weight: 900;
       font-style: normal;
   }

@font-face {
    font-family: 'NovemberHebrew';
    src: url('${fontUrl}/NovemberHebrew-Light.woff2') format('woff2'),
        url('${fontUrl}/NovemberHebrew-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
`;
