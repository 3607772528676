import { createSelector } from 'reselect';
import { dealAffiliationPopupStateSelector } from 'store/state/app/selectors';
import {
  IDealAffiliationFormValues,
  IDealAffiliationPopupState,
  AffiliationPopupMode,
} from 'components/deal-affiliation-popup/types';
import {
  agentIdSelector,
  isAgentSelector,
  isAgentManagerSelector,
  userProfileSelector,
  agentsInOfficeSortedSelector,
} from 'store/state/domainData/selectors';
import { ICommercialAgentsInOffice } from 'store/sagas/apiService/types';
import {
  editDealAffiliationRequestResponseSelector,
  requestDealAffiliationResponseSelector,
} from 'store/state/mutationsResponse/selectors';

export const popupStateSelector = createSelector(dealAffiliationPopupStateSelector, (state): IDealAffiliationPopupState => (state || {}) as IDealAffiliationPopupState);


const formInitialValues: IDealAffiliationFormValues = {
  soldId: '',
  agentId: '',
  dealSide: null,
  dealReceiptLinks: [],
};

export const dealAffiliationPopupInitialValuesSelector = createSelector([
  popupStateSelector,
  agentIdSelector,
], (popupState, currentAgentId): IDealAffiliationFormValues => {
  if (popupState.isOpen) {
    const { deal, affiliationRequest, mode } = popupState;
    const isNew = mode === AffiliationPopupMode.New;
    const defaultValue = isNew || !affiliationRequest;
    const agentId = isNew && currentAgentId;

    return {
      soldId: deal.soldId,
      agentId: agentId || (defaultValue ? formInitialValues.agentId : affiliationRequest.agentId || formInitialValues.agentId),
      dealSide: defaultValue ? formInitialValues.dealSide : affiliationRequest.affiliationType || formInitialValues.dealSide,
      dealReceiptLinks: defaultValue ? formInitialValues.dealReceiptLinks : affiliationRequest.receiptUrls || formInitialValues.dealReceiptLinks,
    };
  }
  return formInitialValues;
});

export const dealAffiliationPopupAgentsSelector = createSelector([
  popupStateSelector,
  agentsInOfficeSortedSelector,
  isAgentSelector,
  isAgentManagerSelector,
  userProfileSelector,
], (popupState, agentsInOffice, isAgent, isAgentManager, user): ICommercialAgentsInOffice[] => {
  if (isAgent && !isAgentManager) {
    return [ {
      agentName: ((user.firstName ? user.firstName : '') + ' ' + (user.lastName ? user.lastName : '')).trim(),
      userId: user.uid,
      agentId: user.agentStatus && user.agentStatus.agentId,
    } ];
  }

  if (!popupState.isOpen || !popupState.affiliationRequest || !popupState.affiliationRequest.agentId || !agentsInOffice) {
    return agentsInOffice;
  }

  const { agentId, agentName } = popupState.affiliationRequest;
  const maybeAddToList = !(agentsInOffice.find(item => item.agentId === agentId));

  return [
    (maybeAddToList ? ({
      agentName,
      userId: null,
      agentId,
    }) : null),
    ...agentsInOffice,
  ].filter(Boolean);
});

export const isEditLoadingSelector = createSelector(
  editDealAffiliationRequestResponseSelector,
  (response) => response && response.loading
);

export const hasEditResponseErrorSelector = createSelector(
  editDealAffiliationRequestResponseSelector,
  (response) => Boolean(response && (response.errors || response.data && response.data.publicEditDealAffiliationRequest.errorMessage))
);

export const isCreateLoadingSelector = createSelector(
  requestDealAffiliationResponseSelector,
  (response) => response && response.loading
);

export const hasCreateResponseErrorSelector = createSelector(
  requestDealAffiliationResponseSelector,
  (response) => Boolean(response && (response.errors || response.data && response.data.requestDealAffiliation.errorMessage))
);

export const responseTypeSelector = createSelector(
  [
    hasCreateResponseErrorSelector,
    hasEditResponseErrorSelector,
    requestDealAffiliationResponseSelector,
    editDealAffiliationRequestResponseSelector,
  ],
  (
    hasCreateResponseError,
    hasEditResponseError,
    createResponse,
    editResponse
  ) => {
    if (hasCreateResponseError) {
      return (
        createResponse &&
        createResponse.data &&
        createResponse.data.requestDealAffiliation &&
        createResponse.data.requestDealAffiliation.__typename
      );
    }
    else if (hasEditResponseError) {
      return (
        editResponse &&
        editResponse.data &&
        editResponse.data.publicEditDealAffiliationRequest &&
        editResponse.data.publicEditDealAffiliationRequest.__typename
      );
    }

    return null;
  }
);
