import { IRequestDealAffiliationResponse, IDealAffiliationRequest } from 'utils/entities';


export const responseDealToAgentDeal = (data: IRequestDealAffiliationResponse): IDealAffiliationRequest => {
  return {
    soldId: data.soldId,
    affiliationRequestId: data.id,
    lastUpdated: data.updatedAt,
    createdAt: (new Date()).toISOString(),
    agentId: data.agentId,
    affiliationType: data.agentType,
    receiptUrls: data.receiptUrls,
    affiliationStatus: data.status,
    rejectionReason: data.rejectionReason,
    dealDate: data.dealDate,
    agentName: data.agentName,
    price: data.price,
    structuredAddress: data.structuredAddress,
    propertyType: data.propertyType,
    rooms: data.rooms,
    constructionYear: data.constructionYear,
    area: data.area,
    floor: data.floor,
  };
};
