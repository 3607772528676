import { fork, call } from 'redux-saga/effects';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { fetchArticles } from '../utils';

export function* checkAddressHandler() {
  const loadOptions: LoadOptions<LoadType.AreasWithCommercialInfoPage> = {
    loadType: LoadType.AreasWithCommercialInfoPage,
    meta: {
      variables: {},
    },
  };
  yield fork(queryData, loadOptions);
  yield call(fetchArticles, null, 'explore');
}
