import React from 'react';
import { ReviewNameWrapper, ReviewStepTitle } from './styled';
import { TextField } from 'ds/components/input/TextField';
import { SmallText } from 'ds/components/typography';
import { useLocale } from 'locale';

interface UgcTextReviewStepProps {
  setNameValue: (value: string) => void;
  setAreaValue: (value: string) => void;
  nameValue: string;
  areaValue: string;
}

export const UgcTextReviewStep: React.FC<UgcTextReviewStepProps> = React.memo(props => {
  const { setNameValue, setAreaValue, nameValue, areaValue } = props;
  const { t } = useLocale();

  return (
    <>
      <ReviewStepTitle weight="bold">{t('ugc.modal.textReview.reviewInputLabel')}</ReviewStepTitle>
      <TextField
        value={areaValue}
        placeholder={t('ugc.modal.textReview.reviewInputPlaceholder')}
        multiline
        rows={4}
        fullWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAreaValue(e.currentTarget.value)}
      />
      <ReviewNameWrapper>
        <SmallText>{t('ugc.modal.textReview.usernameInputLabel')}</SmallText>
        <TextField
          value={nameValue}
          placeholder={t('ugc.modal.textReview.usernameInputPlaceholder')}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNameValue(e.currentTarget.value)}
        />
      </ReviewNameWrapper>
    </>
  );
});
