import { useRef } from 'react';
import { createPanResponder, PanResponderOptions } from 'helpers/pan-responder';
import { mapValues } from 'lodash';


function useExpensiveObject<T>(initializer: () => T): T {
  const instance = useRef<T>(null);
  if (instance.current === null) {
    instance.current = initializer();
  }
  return instance.current;
}

export function usePanResponder(opts: PanResponderOptions) {
  const optsRef = useRef<PanResponderOptions>(opts);
  optsRef.current = opts;

  const panHandlers = useExpensiveObject(() => {
    const wrappedOpts: PanResponderOptions = mapValues(opts, (_, key) => (
      (...args: unknown[]) => optsRef.current[key](...args)
    ));

    return createPanResponder(wrappedOpts);
  });

  return panHandlers;
}

