import React from 'react';
import { useLocale } from 'locale';
import { CheckedLinkText } from 'utils/checkLinksWrapper';
import {
  Wrapper,
  Main,
  ProfileItem,
  ProfilePhoto,
  LogoSection,
  DescriptionSection,
  Title,
  LinkTitle,
} from './styled';
import AgentPlaceholder from 'assets/svg/user-placeholder/agent.svg';
import UserPlaceholder from 'assets/svg/user-placeholder/user.svg';
import MadlanLogoIcon from 'assets/svg/il-logo-small.svg';

export interface SellerInfoProps {
  name: string;
  title?: string;
  avatarUrl?: string;
  avatarHref?: string;
  withAgentAvatar?: boolean;
  withTitle?: boolean;
  isExclusive?: boolean;
  withOfficeAvatar?: boolean;
  officeLogoUrl?: string;
  officeHref?: string;
  withRedesignedForm: boolean;
  withRedesignedSellerLinkColor: boolean;
  withRedesignedPlaceholder?: boolean;
  isModal?: boolean;
  isLargerProfileItem?: boolean;
}

const SellerInfo: React.FC<SellerInfoProps> = props => {
  const {
    name,
    title,
    avatarUrl,
    avatarHref,
    withAgentAvatar,
    withTitle,
    isExclusive,
    withOfficeAvatar,
    officeLogoUrl,
    officeHref,
    withRedesignedForm,
    withRedesignedSellerLinkColor,
    withRedesignedPlaceholder,
    isModal,
    isLargerProfileItem,
  } = props;
  const { t } = useLocale();
  const showOfficeLogo = (officeLogoUrl || isLargerProfileItem);

  return (
    <Wrapper data-auto="seller-info" isModal={isModal}>
      <Main>
        <LogoSection>
          {showOfficeLogo ? (
            <ProfileItem data-auto="seller-profile-item" withRedesignedForm={withRedesignedForm} isLarge={isLargerProfileItem}>
              {withOfficeAvatar ? (
                officeHref ? (
                  <a href={officeHref} target="_blank">
                    <ProfilePhoto
                      withRedesignedForm={withRedesignedForm}
                      isLarge={isLargerProfileItem}
                      imageURL={officeLogoUrl}
                    />
                  </a>
                ) : (
                  <ProfilePhoto
                    data-auto="agent-photo"
                    withRedesignedForm={withRedesignedForm}
                    isLarge={isLargerProfileItem}
                    imageURL={officeLogoUrl}
                  />
                )
              ) : (
                <MadlanLogoIcon/>
              )}
            </ProfileItem>
          ) : null}
          {withAgentAvatar ? (
            <ProfileItem data-auto="seller-profile-item" withRedesignedForm={withRedesignedForm}>
              {avatarUrl ? (
                avatarHref ? (
                  <a href={avatarHref} target="_blank">
                    <ProfilePhoto
                      withRedesignedForm={withRedesignedForm}
                      shape="circle"
                      imageURL={avatarUrl}
                    />
                  </a>
                ) : (
                  <ProfilePhoto
                    data-auto="agent-photo"
                    withRedesignedForm={withRedesignedForm}
                    shape="circle"
                    imageURL={avatarUrl}
                  />
                )
              ) : (
                withRedesignedPlaceholder
                  ? <UserPlaceholder />
                  : <AgentPlaceholder />
              )}
            </ProfileItem>
          ) : null}
        </LogoSection>
        <DescriptionSection data-auto="seller-description">
          <CheckedLinkText link={avatarHref}>
            <LinkTitle
              withRedesignedForm={withRedesignedForm}
              withRedesignSellerInfo={withRedesignedSellerLinkColor}
            >
              {name}
            </LinkTitle>
          </CheckedLinkText>
          {withTitle ? (
            <Title withRedesignedForm={withRedesignedForm} isModal={isModal}>
              <span>{title}</span>
              <span>{t('unitPage.contactAgentHebrewExclusive', { exclusive: isExclusive })}</span>
            </Title>
          ) : null}
        </DescriptionSection>
      </Main>
    </Wrapper>
  );
};

export default SellerInfo;


