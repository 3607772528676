import React from 'react';
import { UploadBulletinStepProps } from 'screens/UploadBulletinPage/steps';
import { InputWrapper, Section, SectionHeading } from 'screens/UploadBulletinPage/styled';
import { ITranslate, useLocale } from 'locale';
import { useField } from 'react-final-form-hooks';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { SpacingBlock } from 'components/spacing-block';
import { TextField } from 'ds/components/input/TextField';
import { RoomsSubtitle, SectionInlineInner } from 'screens/UploadBulletinPage/steps/MainInfoStep/sections/styled';
import { hideNumberOfEmployees, hideRoomsCount } from 'utils/uploadBulletin';
import NumberInput from 'screens/UploadBulletinPage/components/number-input';
import { isNotEmpty, isPositiveNumber } from 'utils/formValidationHelpers';

const TextFieldWithValidationMessage = withValidationMessage(TextField);

const MAX_VALUE = 10000;

const validateField = (t: ITranslate, maxValue: number) => (val: string) => {
  if (isNotEmpty(val)) {
    if (!isPositiveNumber(val)) {
      return t('uploadBulletinPage.emptyFieldValidationMessage');
    }
    else if (+val > maxValue) {
      return t('uploadBulletinPage.maxValueFieldValidationMessage', { forMoney: false });
    }
  }

  return undefined;
};

export const RoomsSection: React.FC<UploadBulletinStepProps> = ({
  formInstance,
  hideErrorOnFocus,
}) => {
  const { t } = useLocale();
  const buildingClass = formInstance.getState().values.buildingClass;
  const hideRooms = hideRoomsCount(buildingClass, true);
  const rooms = useField('rooms', formInstance, validateField(t, MAX_VALUE));
  const hideEmployees = hideNumberOfEmployees(buildingClass);
  const numberOfEmployees = useField('numberOfEmployees', formInstance, validateField(t, MAX_VALUE));

  if (hideEmployees && hideRooms) return null;

  return (
    <Section>
      <SectionInlineInner>
        {hideRooms ? null : (
          <SpacingBlock mLeft={5}>
            <SpacingBlock mBottom={1}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.mainInfoStep.roomsLabel')}
              </SectionHeading>
            </SpacingBlock>
            <InputWrapper error={rooms.meta.touched && rooms.meta.error}>
              <TextFieldWithValidationMessage
                {...rooms.input}
                {...rooms.meta}
                onFocus={() => hideErrorOnFocus(true)}
                onBlur={() => hideErrorOnFocus(false)}
                type="number"
                min="0"
                step="1"
                placeholder={t('uploadBulletinPage.mainInfoStep.roomsPlaceholder')}
              />
            </InputWrapper>
          </SpacingBlock>
        )}
        {hideEmployees ? null : (
          <div>
            <SpacingBlock mBottom={1}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.mainInfoStep.numberOfEmployeesTitle')}
                <RoomsSubtitle>
                  {t('uploadBulletinPage.mainInfoStep.numberOfEmployeesSubtitleTitle')}
                </RoomsSubtitle>
              </SectionHeading>
            </SpacingBlock>
            <InputWrapper>
              <InputWrapper error={numberOfEmployees.meta.touched && numberOfEmployees.meta.error}>
                <NumberInput
                  {...numberOfEmployees.input}
                  {...numberOfEmployees.meta}
                  onFocus={() => hideErrorOnFocus(true)}
                  onBlur={() => hideErrorOnFocus(false)}
                  placeholder={t('uploadBulletinPage.mainInfoStep.numberOfEmployeesPlaceholder')}
                />
              </InputWrapper>
            </InputWrapper>
          </div>
        )}
      </SectionInlineInner>
    </Section>
  );
};
