import React from 'react';
import styled from '@emotion/styled';

const ViewFaderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ViewFaderItem = styled.div<{ isActive: boolean, animationType: AnimationType }>`
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${({ isActive, animationType = 'default' }) => `

   ${animationType === 'default' ? (isActive ? `
      opacity: 1;
      visibility: visible;
      transition: visibility 0s 0s, opacity .2s ease 0s;
      height: 100%;
      width: 100%;
      position: relative;
    ` : `
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s .2s, opacity .2s ease 0s;
      height: 100%;
      width: 100%;
      position: absolute;
  `) : ``}

  ${animationType === 'homepage' ? (isActive ? `
      height: 100%;
      width: 100%;
      position: relative;
      animation: fadeInOut 4s linear forwards;
      visibility: visible;
    ` : `
      height: 100%;
      width: 100%;
      position: absolute;
      visibility: hidden;
    `) : ``}
`}`;


type AnimationType = 'homepage' | 'default';

interface ViewFaderProps {
  children: JSX.Element[];
  activeIndex: number;
  animationType?: AnimationType;
}

export class ViewFader extends React.PureComponent<ViewFaderProps, never> {
  private itemMapper = (item: JSX.Element, id: number) => {
    return (
      <ViewFaderItem animationType={this.props.animationType} isActive={this.props.activeIndex === id}>
        {item}
      </ViewFaderItem>
    );
  };

  public render () {
    return (
      <ViewFaderWrapper>
        {React.Children.map(this.props.children, this.itemMapper)}
      </ViewFaderWrapper>
    );
  }
}
