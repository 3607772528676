import { State } from 'store/state';
import {
  ratingsDataMapSelector,
  reviewWizardStepIndexSelector,
  textReviewInputSelector,
  userNameInputSelector,
  isReviewWizardOpenSelector,
  reviewWizardStepsSelector,
  reviewWizardAddressNameSelector,
} from 'store/state/UGC/selectors';
import { connect } from 'react-redux';
import {
  goToNextStep,
  goToPreviousStep,
  saveUserReview,
  setRating,
  setTextReviewInput,
  setUserNameInput,
  closeReviewWizard,
} from 'store/state/UGC/actions';
import { ReviewWizard, ReviewWizardProps } from '../../review-wizard';
import withEvents from 'analytics/withEvents';

const mapStateToProps = (state: State) => ({
  addressName: reviewWizardAddressNameSelector(state),
  isOpen: isReviewWizardOpenSelector(state),
  currentStepIndex: reviewWizardStepIndexSelector(state),
  ratingsDataMap: ratingsDataMapSelector(state),
  textReviewInput: textReviewInputSelector(state),
  userNameInput: userNameInputSelector(state),
  stepsOptions: reviewWizardStepsSelector(state),
});

const mapDispatchToProps = {
  onNextStep: goToNextStep,
  onPreviousStep: goToPreviousStep,
  onComplete: saveUserReview,
  onSelectRatingValue: setRating,
  onTextReviewInputChange: setTextReviewInput,
  onUserNameInputChange: setUserNameInput,
  onClose: closeReviewWizard,
};

const ReviewWizardWithEvents = withEvents<ReviewWizardProps>(sendEvent => ({
  onClose() {
    sendEvent('ugc_review_close_click', 'ugc');
  },
}))(ReviewWizard);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewWizardWithEvents);
