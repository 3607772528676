import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';

export const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(5)};
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const Breadcrumbs = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutrals.grey3};
    &:hover {
      color: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
`;

export const Arrow = styled.span<{specularY: boolean}>`
  margin: 0 ${({ theme }) => theme.spacing(1)};
  width: 12px;
  height: 20px;
  svg {
    ${({ specularY }) => specularY ? 'transform: rotate(180deg);' : ''}
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey3};
    }
  }
`;

export const LinksWrapper = styled.div<{ isWide?: boolean; gridAutoFill?: boolean; }>`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  &:empty {
    margin: 0;
  }
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(156px, 165px));
    grid-gap: ${theme.spacing(4)} ${theme.spacing(6)};
  `}
  ${({ theme, gridAutoFill }) => gridAutoFill ? '' : theme.media.showAt({ from: 3 }) `
    justify-content: space-between;
    grid-template-columns: repeat(3, minmax(156px, 165px));
    grid-gap: ${theme.spacing(4)} ${theme.spacing(1)};
  `}

  ${({ theme, isWide, gridAutoFill }) => isWide && !gridAutoFill ? theme.media.showAt({ from: 4 }) `
    justify-content: space-between;
    grid-template-columns: repeat(4, minmax(156px, 165px));
    grid-gap: ${theme.spacing(4)} ${theme.spacing(1)};
  ` : ''}
`;

export const LinksSection = styled.div`
  > div:first-of-type {
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(4)};
    }
    > div:first-of-type {
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`;

export const LinksItems = styled.div`
  > div:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    > div:last-of-type {
      margin-bottom: ${theme.spacing(1)};
    }
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const LinksItem = styled(SmallText)`
  > a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutrals.grey2};
    &:hover {
      color: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      &:after {
        content: '';
        margin: 0 ${theme.spacing(1.5)};
        height: 12px;
        width: 1px;
        background: ${theme.colors.neutrals.grey7};
      }
    }
  `}
`;

export const SocialLinks = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  display: flex;
  > a:not(:last-of-type) {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 14px;
  }
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    margin-bottom: ${theme.spacing(6)};
  `}
`;

export const SocialLink = styled.div<{ isSearch: boolean }>`
  background: ${({ theme, isSearch }) => isSearch ? theme.colors.neutrals.grey9 : theme.colors.neutrals.grey8};
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    background: ${theme.colors.neutrals.white};
  `}
  width: 34px;
  height: 34px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey3};
    }
  }
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.neutrals.grey1};
      }
    }
  }
`;

export const GeneralFooter = styled.div<{ isSearch: boolean }>`
  background: ${({ theme, isSearch }) => isSearch ? theme.colors.neutrals.grey10 : theme.colors.neutrals.grey9};
  padding: ${({ theme }) => theme.spacing(3)} 0;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    padding: ${ theme.spacing(4)} 0;
    > div {
      flex-direction: column;
      align-items: center;
    }
  `}
`;


export const GeneralLinks = styled.div`
  display: flex;
  align-items: center;
  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutrals.grey3};
    &:not(:last-of-type) {
      margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 32px;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin-bottom: ${theme.spacing(3)};
    > a {
      padding: 0 14px;
      white-space: nowrap;
    }
    > a:first-of-type {
      padding-right: 0;
    }
    > a:not(:last-of-type) {
      margin: 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 12px;
        width: 1px;
        left: 0;
        top: 50%;
        margin-top: -6px;
        background: ${theme.colors.neutrals.grey7};
      }
    }
  `}
`;

export const Copyright = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
`;

export const AddressPageFooterSection = styled.div`
  padding-bottom: 64px;
  background: ${({ theme }) => theme.colors.neutrals.grey11};
`;

export const CollapseButton = styled(SmallText)<{ isCollapsed: boolean }>`
  color: ${({ theme }) => theme.colors.brand.main};
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    transform: rotate(${({ isCollapsed }) => isCollapsed ? '0' : '180deg'});
    path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
  }
`;
