import React, { useCallback, useRef } from 'react';
import { useLocale } from 'locale';
import { noop } from 'lodash';
import { useForm } from 'react-final-form-hooks';
import { HebrewContactModal, RequestType } from 'components/contact-modal/HebrewContactModal';
import SellerInfo from 'components/inner-contact-form/contact/seller-info/SellerInfo';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { LeadType } from 'store/sagas/apiService/types';
import { IContactAgentValues } from 'helpers/contactFormStore';
import { createValidationFn } from './utils';
import { SubmitContactAgentPayload, ContactAgentSubmitterSource } from 'store/state/app/payloads';


export interface MadadContactModalInfo {
  id?: string;
  isAgent?: boolean;
  agentName?: string;
  avatarImage?: string;
  avatarHref?: string;
  officeId?: string;
  officeName?: string;
  officeLogoUrl?: string;
  officeHref?: string;
  phone?: string;
  eventSource?: string;
}

export interface MadadContactModalProps {
  isOpen: boolean;
  isContacted?: boolean;
  info: MadadContactModalInfo;
  formInitialValues: IContactAgentValues;
  onClose: () => void;
  contact: (payload: SubmitContactAgentPayload) => void;
  onPhoneShown?: () => void;
  onCallClick?: () => void;
}

export const MadadContactModal: React.FC<MadadContactModalProps> = props => {
  const {
    isOpen,
    isContacted,
    info,
    formInitialValues,
    onClose,
    contact,
    onPhoneShown = noop,
    onCallClick = noop,
  } = props;
  const {
    id,
    isAgent,
    agentName,
    avatarImage,
    avatarHref,
    officeName,
    officeLogoUrl,
    officeHref,
    phone,
  } = info || {};

  if (!isOpen) return null;

  const { t } = useLocale();
  const requestTypeRef = useRef<RequestType>(null);
  const constructImageURI = useImageUrlBuilder();
  const avatarUrl = avatarImage ? constructImageURI(avatarImage) : null;
  const officeLogo = officeLogoUrl ? constructImageURI(officeLogoUrl) : null;

  const onSubmit = useCallback((formValues: IContactAgentValues) => {
    contact({
      key: id,
      variables: {
        id,
        type: isAgent ? LeadType.Agent : LeadType.Office,
        message: t('madad.contactModal.message'),
        ...formValues,
      },
      source: ContactAgentSubmitterSource.Modal,
      requestType: requestTypeRef.current,
    });
  }, [ isAgent, id ]);

  const validate = useCallback(createValidationFn(t), [ t ]);
  const { form, handleSubmit: submit } = useForm({ onSubmit, initialValues: formInitialValues, validate });

  const handleSubmit = (requestType: RequestType = null) => {
    requestTypeRef.current = requestType;
    submit();
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const sellerInfo = (
    <SellerInfo
      name={isAgent ? agentName : officeName}
      avatarUrl={avatarUrl}
      avatarHref={avatarHref}
      withOfficeAvatar
      officeLogoUrl={officeLogo}
      officeHref={officeHref}
      isModal
      withAgentAvatar={isAgent}
      withRedesignedSellerLinkColor
      withRedesignedForm
      withRedesignedPlaceholder
    />
  );

  return (
    <HebrewContactModal
      formApi={form}
      sellerInfo={sellerInfo}
      contactName={null} // not required because using withRedesignedForm
      phone={phone}
      isContacted={isContacted}
      withRedesignedFormPhone
      withFormName={false}
      withCallDescription
      withRedesignedSubmit
      withRedesignedForm
      withAdditionalSubmitButton
      isPhoneBorderHidden
      onSubmit={handleSubmit}
      onClose={handleClose}
      onPhoneShown={onPhoneShown}
      onCallClick={onCallClick}
    />
  );
};
