import { createSelector } from 'reselect';
import {
  multipleDevelopersSelector,
  makeNullSafeDomainGetter,
  createIsLoadingSelector,
  makeByIdsDictionarySelector,
} from 'store/state/domainData/selectors/common';

export const baseMultipleDevelopersSelector = makeNullSafeDomainGetter(multipleDevelopersSelector, 'developersAndPromotedProjects');
export const multipleDevelopersLoadingSelector = createIsLoadingSelector(multipleDevelopersSelector);
export const promotedDevelopersSelector = createSelector(baseMultipleDevelopersSelector, data => data && data.developers || []);
export const promotedDevelopersProjectsSelector = createSelector(baseMultipleDevelopersSelector, data => data && data.poi || []);

export const hasPromotedDevelopersSelector = createSelector(promotedDevelopersSelector, (data): boolean => Boolean(data && data.length));

export const promotedDevelopersProjectsDictSelector = makeByIdsDictionarySelector(promotedDevelopersProjectsSelector);
