import config from 'config';
import { takeEvery, getContext, call, select } from 'redux-saga/effects';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { CompletionType, IAutocompleteAddress } from 'utils/entities';
import { TOGGLE_ADDRESS } from 'store/state/app/types';
import { toggleAddress } from 'store/state/app/actions';
import { RootAction } from 'store/state';
import { getAuthGateResponse, AuthGateResponse } from '../routing/handlers/utils';


export const mapAddressToAnalytics = (entity: IAutocompleteAddress) => ({
  borough: entity.borough,
  street_name: entity.street,
  street_number: entity.houseNumber,
  city: entity.city,
  country: config.country,
  location_type: 'address',
  neighborhood: entity.neighbourhood,
  longitude: entity.location && entity.location[0],
  latitude: entity.location && entity.location[1],
  zip_code: entity.zipcode,
  location_id: entity.docId,
});

function* toggleWorker(action: ReturnType<typeof toggleAddress>) {
  const { sendEvent } = yield getContext('analytics');

  if (action.payload.isSaved) {

    yield call(sendEvent, 'user_delete_tracked_address', 'user', {
      event: {
        location: {
          location_type: 'address',
          location_id: action.payload.docId,
        },
      },
    });
  }

  else {
    const entity: IAutocompleteAddress = yield select(addressDocEntrySelector);
    if (entity && entity.type === CompletionType.Address) {

      const payload = {
        event: {
          location: mapAddressToAnalytics(entity),
          source: action.meta.source,
        },
      };

      yield call(sendEvent, 'user_track_address_click', 'user', payload);

      const authGateResponse: AuthGateResponse = yield call(getAuthGateResponse);

      if (authGateResponse !== AuthGateResponse.Rejected) {
        yield call(sendEvent, 'user_track_address_success', 'user', payload);
      }
    }
  }
}

const togglePattern = (action: RootAction) => (
  action.type === TOGGLE_ADDRESS && !action.payload.isChanged
);

export function* followAddressEventsWorker() {
  yield takeEvery(togglePattern, toggleWorker);
}
