import React from 'react';
import { MapViewWrapper, OpenMapIconWrapper, TargetMarkerWrapper } from './styled';
import { MapSnapshot } from 'components/static-map/MapSnapshot';
import OpenMapIcon from 'assets/svg/zoom.svg';
import { connect } from 'react-redux';
import * as actions from 'store/state/router/actions';
import { State as RouteState, NavigateToFn } from 'config/routes';
import { State } from 'store/state';
import { routeSelector } from 'store/state/selectors/router';
import BulletinTargetMarkerILIcon from 'assets/svg/its_here_heb.svg';
import ProjectTargetMarkerIcon from 'assets/svg/its_here_heb_proj.svg';
import { PoiType } from 'utils/entities';

interface MapViewProps {
  lng: number;
  lat: number;
  navigateTo: NavigateToFn;
  route: RouteState;
  withMapAction?: boolean;
  type?: PoiType;
  isPromoted?: boolean;
}

export const MapView: React.FunctionComponent<MapViewProps> = ({ type, isPromoted, lng, lat, navigateTo, withMapAction, route }) => {
  const handleMapOpen = () => {
    if (withMapAction) {
      navigateTo(route.name, { ...route.params, isMapActive: 1 }, { replace: true });
    }
  };
  let targetMarker = null;

  if (type === 'project' || type === 'commercialProject') {
    targetMarker = isPromoted ? <ProjectTargetMarkerIcon /> : <BulletinTargetMarkerILIcon />;
  }
  else {
    targetMarker = <BulletinTargetMarkerILIcon />;
  }

  return (
    <MapViewWrapper onClick={handleMapOpen}>
      <MapSnapshot lng={lng} lat={lat} />
      <TargetMarkerWrapper>
        {targetMarker}
      </TargetMarkerWrapper>
      {withMapAction ? (
        <OpenMapIconWrapper>
          <OpenMapIcon width={16} height={16} />
        </OpenMapIconWrapper>
      ) : null}
    </MapViewWrapper>
  );
};

const mapDispatchToProps = {
  navigateTo: actions.navigateTo,
};

export default connect((state: State) => ({
  route: routeSelector(state),
}), mapDispatchToProps)(MapView);

