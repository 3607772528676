import config from 'config';

export const getDevAuthHeaders = () => {
  const headers: Record<string, string> = {};
  if (config.mode !== 'production') {
    headers['X-DD-AUTH'] = 'bGV0c3Rlc3Q6RG9Tb21lVGVzdHNZYWxs';
  }

  return headers;

};
