import styled from '@emotion/styled';
import React, { SyntheticEvent } from 'react';


export interface IconAmenityProps {
  title?: string;
  active?: boolean;
  icon?: React.ReactNode;
  onClick?: (e: SyntheticEvent<HTMLElement>) => void;
}

const IconAmenityWrapperStyled = styled('div')<{ active: boolean }>`
  display: flex;
  align-items: center;
  font-family: NovemberHebrew;
  font-size: 15px;
  line-height: 1.6;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  opacity: ${(props) => (props.active ? '1' : '.4')};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction:column;
  `}
`;

const IconWrapper = styled('div')`
  padding-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
  display: flex;
  align-items: center;
  > svg {
    height: 24px;
    width: 24px;
  }
`;
const TitleStyled = styled('div')`
  text-align: center;
  text-transform: capitalize;
  display: flex;
`;
export const IconAmenity: React.FunctionComponent<IconAmenityProps> = ({ onClick, active = true, icon, title, ...rest }) => (
  <IconAmenityWrapperStyled onClick={onClick} active={active} {...rest}>
    <IconWrapper>{icon}</IconWrapper>
    <TitleStyled>{title}</TitleStyled>
  </IconAmenityWrapperStyled>
);
