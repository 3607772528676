import { MarketplaceType, PoiType, IBulletin, IProject, ICommercialBulletin, ICommercialProject } from './entities';
import { Route } from 'config/routes';


export function searchRouteByMarketplace(marketplace: MarketplaceType) {
  return marketplace === MarketplaceType.Commercial ? Route.SearchCommercial : Route.Search;
}

export function unitRouteByMarketplace(marketplace: MarketplaceType) {
  return marketplace === MarketplaceType.Commercial ? Route.UnitPageCommercial : Route.UnitPage;
}

export function projectRouteByMarketplace(marketplace: MarketplaceType) {
  return marketplace === MarketplaceType.Commercial ? Route.ProjectPageCommercial : Route.ProjectPage;
}
export function homeRouteByMarketplace(marketplace: MarketplaceType) {
  return marketplace === MarketplaceType.Commercial ? Route.CommercialMarketLanding : Route.Home;
}

export function isSearchRoute(route: Route) {
  return [ Route.Search, Route.SearchCommercial ].includes(route);
}

export function isUnitPageRoute(route: Route) {
  return [ Route.UnitPage, Route.UnitPageCommercial ].includes(route);
}

export type Poi = IBulletin | IProject | ICommercialBulletin | ICommercialProject;

export const isCommercialProject = (poi: Poi): poi is ICommercialProject => poi.type === 'commercialProject';
export const isProject = (poi: Poi): poi is IProject => poi.type === 'project';
export const isCommercialBulletin = (poi: Poi): poi is ICommercialBulletin => poi.type === 'commercialBulletin';
export const isBulletin = (poi: Poi): poi is IBulletin => poi.type === 'bulletin';

export function isProjectPoiType(poi: Poi): poi is ICommercialProject | IProject {
  return isCommercialProject(poi) || isProject(poi);
}

export function isBulletinPoiType(poi: Poi): poi is ICommercialBulletin | IBulletin {
  return isCommercialBulletin(poi) || isBulletin(poi);
}

export function routeByPoiType(type: PoiType): Route {
  switch (type) {
    case 'bulletin':
      return Route.UnitPage;
    case 'project':
      return Route.ProjectPage;
    case 'commercialBulletin':
      return Route.UnitPageCommercial;
    case 'commercialProject':
      return Route.ProjectPageCommercial;
  }
  return null;
}

export function routeByPoiTypeAndMarketplace(type: PoiType, marketplace: MarketplaceType): Route {
  return type === 'bulletin' || type === 'commercialBulletin'
    ? unitRouteByMarketplace(marketplace)
    : projectRouteByMarketplace(marketplace);
}
