import React, { useEffect } from 'react';

export function useClickAwayListener(ref: React.MutableRefObject<any>, onClickAway: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ ref ]);
}
