import { createSelector } from 'reselect';
import {
  makeNullSafeDomainGetter,
  recommendedListingsDomainSelector,
  createIsLoadingSelector,
} from 'store/state/domainData/selectors/common';

export const recommendedListingsResponseSelector = makeNullSafeDomainGetter(recommendedListingsDomainSelector, 'getRecommendedListings', true);
export const recommendedListingsLoadingSelector = createIsLoadingSelector(recommendedListingsDomainSelector);
export const recommendedListingsListSelector = createSelector(recommendedListingsResponseSelector, response => response ? response.listings : null);
export const hasRecommendedListingsListSelector = createSelector(recommendedListingsListSelector, listings => Boolean(listings && listings.length > 0));
