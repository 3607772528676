import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import HeartIcon from 'assets/svg/navigation/heart.svg';
import { SavedListingsCount } from 'components/navigation/header/styled';
import { userFavoritesCounterSelector } from 'store/state/domainData/selectors/favorites';

interface HeartIconWithCounterProps {
  savedListingsCount: number;
}

const HeartIconWithCounter: React.FC<HeartIconWithCounterProps> = ({ savedListingsCount }) => {
  return (
    <>
      <HeartIcon width={24} height={24} />
      {Boolean(savedListingsCount) && <SavedListingsCount>{savedListingsCount < 100 ? savedListingsCount : 99}</SavedListingsCount>}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  savedListingsCount: userFavoritesCounterSelector(state),
});

export default connect(mapStateToProps, null)(HeartIconWithCounter);
