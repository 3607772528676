import styled from '@emotion/styled';
import { ResetButton } from './ResetButton';
import { SpacingScale } from 'ds/theme/spacing';
import { typographyFontSizeCfg } from '../typography/types';
import defaultTheme from 'ds/theme/default';

const HOMEPAGE_MODEB_DARK_COLOR = '#014547';

type Size = 'small' | 'medium' | 'large' | 'extraLarge' | 'extraXLarge';
type Mode = 'primary' | 'ghost' | 'primaryNegative' | 'dark' | 'ghost-dark' | 'ghost-dark-grey' | 'ghost-darker';
type State = 'default' | 'hovered' | 'selected' | 'disabled';

export interface BasicButtonProps {
  size?: Size;
  mode?: Mode;
  fullWidth?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  onlyIcon?: boolean; // used to make a round button with no additional margins on the icon
  iconPosition?: 'start' | 'end';
  withShadow?: boolean;
  isInitialIconColor?: boolean;
}

const sizeToHeight: Record<Size, SpacingScale> = {
  small: 3,
  medium: 4,
  large: 5,
  extraLarge: 6,
  extraXLarge: 7,
};

const sizeToSidePadding: Record<Size, SpacingScale> = {
  small: 1.5,
  medium: 2,
  large: 3,
  extraLarge: 3,
  extraXLarge: 3,
};

const sizeToFontSize: Record<Size, number> = {
  small: typographyFontSizeCfg.smallText,
  medium: typographyFontSizeCfg.smallText,
  large: typographyFontSizeCfg.text,
  extraLarge: typographyFontSizeCfg.text,
  extraXLarge: typographyFontSizeCfg.text,
};

const sizeToFontWeight: Record<Size, number> = {
  small: 500,
  medium: 500,
  large: 500,
  extraLarge: 500,
  extraXLarge: 500,
};

const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.neutrals.white,
    'primaryNegative': defaultTheme.colors.brand.main,
    'ghost': defaultTheme.colors.brand.main,
    'ghost-dark': defaultTheme.colors.brand.darker,
    'dark': defaultTheme.colors.neutrals.white,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey1,
    'ghost-darker': defaultTheme.colors.brand.main,
  },
  hovered: {
    'primary': defaultTheme.colors.neutrals.white,
    'primaryNegative': defaultTheme.colors.brand.main,
    'ghost': defaultTheme.colors.brand.main,
    'ghost-dark': HOMEPAGE_MODEB_DARK_COLOR,
    'dark': defaultTheme.colors.neutrals.white,
    'ghost-dark-grey': defaultTheme.colors.brand.main,
    'ghost-darker': defaultTheme.colors.brand.darker,
  },
  selected: {
    'primary': defaultTheme.colors.neutrals.white,
    'primaryNegative': defaultTheme.colors.neutrals.grey4,
    'ghost': defaultTheme.colors.brand.main,
    'ghost-dark': HOMEPAGE_MODEB_DARK_COLOR,
    'dark': defaultTheme.colors.neutrals.white,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey1,
    'ghost-darker': defaultTheme.colors.brand.darker,
  },
  disabled: {
    'primary': defaultTheme.colors.neutrals.white,
    'primaryNegative': defaultTheme.colors.neutrals.grey4,
    'ghost': defaultTheme.colors.neutrals.grey4,
    'ghost-dark': defaultTheme.colors.neutrals.grey4,
    'dark': defaultTheme.colors.neutrals.white,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey4,
    'ghost-darker': defaultTheme.colors.neutrals.grey4,
  },
};

const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.brand.main,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'ghost': defaultTheme.colors.neutrals.white,
    'ghost-dark': defaultTheme.colors.neutrals.white,
    'dark': defaultTheme.colors.brand.darker,
    'ghost-dark-grey': defaultTheme.colors.neutrals.white,
    'ghost-darker': defaultTheme.colors.neutrals.white,
  },
  hovered: {
    'primary': defaultTheme.colors.brand.dark,
    'primaryNegative': defaultTheme.colors.neutrals.grey10,
    'ghost': defaultTheme.colors.neutrals.white,
    'ghost-dark': defaultTheme.colors.neutrals.white,
    'dark': HOMEPAGE_MODEB_DARK_COLOR,
    'ghost-dark-grey': defaultTheme.colors.neutrals.white,
    'ghost-darker': defaultTheme.colors.neutrals.white,
  },
  selected: {
    'primary': defaultTheme.colors.brand.darker,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'ghost': defaultTheme.colors.neutrals.white,
    'ghost-dark': defaultTheme.colors.neutrals.white,
    'dark': HOMEPAGE_MODEB_DARK_COLOR,
    'ghost-dark-grey': defaultTheme.colors.neutrals.white,
    'ghost-darker': defaultTheme.colors.neutrals.white,
  },
  disabled: {
    'primary': defaultTheme.colors.neutrals.grey6,
    'primaryNegative': defaultTheme.colors.neutrals.grey10,
    'ghost': defaultTheme.colors.neutrals.white,
    'ghost-dark': defaultTheme.colors.neutrals.white,
    'dark': defaultTheme.colors.neutrals.grey6,
    'ghost-dark-grey': defaultTheme.colors.neutrals.white,
    'ghost-darker': defaultTheme.colors.neutrals.white,
  },
};

const modeToBorderColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.brand.main,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'ghost': defaultTheme.colors.neutrals.grey6,
    'ghost-dark': defaultTheme.colors.brand.darker,
    'dark': defaultTheme.colors.brand.darker,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey1,
    'ghost-darker': defaultTheme.colors.neutrals.grey6,
  },
  hovered: {
    'primary': defaultTheme.colors.brand.dark,
    'primaryNegative': defaultTheme.colors.neutrals.grey10,
    'ghost': defaultTheme.colors.neutrals.grey4,
    'ghost-dark': defaultTheme.colors.brand.darker,
    'dark': defaultTheme.colors.brand.darker,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey1,
    'ghost-darker': defaultTheme.colors.neutrals.grey6,
  },
  selected: {
    'primary': defaultTheme.colors.brand.darker,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'ghost': defaultTheme.colors.brand.main,
    'ghost-dark': defaultTheme.colors.neutrals.white,
    'dark': defaultTheme.colors.brand.darker,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey1,
    'ghost-darker': defaultTheme.colors.neutrals.grey6,
  },
  disabled: {
    'primary': defaultTheme.colors.neutrals.grey6,
    'primaryNegative': defaultTheme.colors.neutrals.grey10,
    'ghost': defaultTheme.colors.neutrals.grey7,
    'ghost-dark': defaultTheme.colors.neutrals.grey7,
    'dark': defaultTheme.colors.neutrals.grey6,
    'ghost-dark-grey': defaultTheme.colors.neutrals.grey6,
    'ghost-darker': defaultTheme.colors.neutrals.grey10,
  },
};

export const BasicButton = styled(ResetButton)<BasicButtonProps>`
  ${({ theme, mode = 'primary', size = 'medium', fullWidth, align = 'center', onlyIcon, withShadow, iconPosition = 'start', isInitialIconColor }) => `
    font-size: ${sizeToFontSize[size]}px;
    font-weight: ${sizeToFontWeight[size]};
    height: ${theme.spacing(sizeToHeight[size])};
    padding: 0 ${onlyIcon ? 0 : theme.spacing(sizeToSidePadding[size])};
    border-radius: ${onlyIcon ? theme.borderRadius.round : theme.borderRadius.smallestSideRound};
    ${size === 'small' ? 'text-transform: uppercase;' : ''}
    display: inline-flex;
    align-items: center;
    transition: all .2s ease;
    justify-content: ${onlyIcon ? 'center' : align};
    cursor: pointer;
    width: ${fullWidth ? '100%' : (onlyIcon ? theme.spacing(sizeToHeight[size]) : 'auto')};
    > svg {
      margin-${iconPosition === 'start'
        ? (theme.isRTL ? 'left' : 'right')
        : (theme.isRTL ? 'right' : 'left')}: ${onlyIcon ? 0 : theme.spacing(0.5)};
    }
    ${withShadow ? `box-shadow: ${theme.shadow.level5};` : ''}

    ${isInitialIconColor ? '' : `> svg path { fill: ${modeToFontColor.default[mode]}; }`}
    border: 1px solid ${modeToBorderColor.default[mode]};
    color: ${modeToFontColor.default[mode]};
    background: ${modeToBgColor.default[mode]};
  `}
  ${({ theme, mode = 'primary', isInitialIconColor }) => theme.media.showAt({ isTouch: false, to: 5 }) `
    &:hover {
      ${isInitialIconColor ? '' : `> svg path { fill: ${modeToFontColor.hovered[mode]}; }`}
      border: 1px solid ${modeToBorderColor.hovered[mode]};
      color: ${modeToFontColor.hovered[mode]};
      background: ${modeToBgColor.hovered[mode]};
    }
    &:active {
      ${isInitialIconColor ? '' : `> svg path { fill: ${modeToFontColor.selected[mode]}; }`}
      border: 1px solid ${modeToBorderColor.selected[mode]};
      color: ${modeToFontColor.selected[mode]};
      background: ${modeToBgColor.selected[mode]};
    }
  `}
  ${({ mode = 'primary', isInitialIconColor }) => `
    &[disabled] {
      pointer-events: none;
      ${isInitialIconColor ? '' : `> svg path { fill: ${modeToFontColor.disabled[mode]}; }`}
      border: 1px solid ${modeToBorderColor.disabled[mode]};
      color: ${modeToFontColor.disabled[mode]};
      background: ${modeToBgColor.disabled[mode]};
    }
  `}
`;

