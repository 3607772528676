import { createSelector } from 'reselect';
import {
  makeNullSafeDomainGetter,
  agentByIdSelector,
  agentBulletinsByIdSelector,
  agentContactInfoByIdSelector,
} from 'store/state/domainData/selectors/common';
import { IAgentOfficeDeal } from 'utils/entities';


export const agentPageDataSelector = makeNullSafeDomainGetter(agentByIdSelector, 'getRealEstateAgentById');
export const agentContactInfoDataSelector = makeNullSafeDomainGetter(agentContactInfoByIdSelector, 'getRealEstateAgentById');
export const agentBulletinsDataSelector = createSelector([ makeNullSafeDomainGetter(agentBulletinsByIdSelector, 'getRealEstateAgentById'), agentPageDataSelector ], (listings, agentData) => {
  if (!listings) return null;

  const dealsByAgent = listings.deals && listings.deals.length ? (
    listings.deals.reduce((acc, d: IAgentOfficeDeal) => {
      if (!d.location) return acc;

      const agentList = d.agents ? d.agents.filter(a => a.agentId === agentData.agentId) : [];
      if (!agentList.length) return acc;

      const dealsWithSameAgent = agentList.map(a => ({
        ...d,
        agents: [ a ],
      }));
      return [ ...acc, ...dealsWithSameAgent ];
    }, [])
  ) : [];
  return {
    ...listings,
    deals: dealsByAgent,
  };
});

export const agentPageBulletinSelector = createSelector([ agentBulletinsDataSelector, agentPageDataSelector ], (agent, agentPage) => {

  if (!agent) return [];
  const dealsEnriched = agent.deals ? agent.deals.reduce((acc, d: IAgentOfficeDeal) => {
    if (!d.location) return acc;

    const agentList = d.agents ? d.agents.filter(a => a.agentId === agentPage.agentId) : [];
    if (!agentList.length) return acc;

    const dealsWithSameAgent = agentList.map(a => ({
      ...d,
      agents: [ a ],
      type: 'sold',
      locationPoint: { lng: d.location.coordinates[0], lat: d.location.coordinates[1] },
      dealType: 'sold',
      id: d.saleId,
    }));
    return [ ...acc, ...dealsWithSameAgent ];
  }, []) : [];
  return [ ...(agent.bulletins || []), ...(agent.commercialBulletins || []), ...dealsEnriched ];
});
