import React from 'react';
import { useLocale } from 'locale';
import RangeSelect from 'ds/components/input/RangeSelect';
import { GenericFilterProps } from '../types';
import FilterSection from '../common/DropdownSection';
import { Section, SectionTitle, SectionBody, SectionScrollBody } from '../styled/common';
import { normalizeBathsRange, isFilterActiveByType } from '../utils';


interface BathsRangeProps extends GenericFilterProps<'bathsRange'> { }

const BathsRange: React.FunctionComponent<BathsRangeProps> = ({
  alignment,
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  isMobile,
  isInline,
  marketplace,
  config,
  type,
}) => {
  const { t } = useLocale();
  const normalizedCommitedValue = normalizeBathsRange(commitedValue, marketplace);
  const isActive = isFilterActiveByType.bathsRange(commitedValue, initialValue, marketplace);
  const valuesAreInitial = !isFilterActiveByType.bathsRange(intermediateValue, initialValue, marketplace);

  const [ normalizedFrom, normalizedTo ] = normalizedCommitedValue;
  const { bathsOptions, filtersIcons } = config;

  const options = bathsOptions.map((value) => ({
    value,
    label: t('filters.bathsOptions', { value }),
  }));

  const shortValueLabel = t('filters.bathsShortValue', {
    isActive,
    exactValue: normalizedFrom === normalizedTo ? normalizedFrom : null,
    isRange: normalizedFrom !== null && normalizedTo !== null,
    bathsFrom: normalizedFrom,
    bathsTo: normalizedTo,
  });


  const rangeSelect = (
    <RangeSelect
      values={intermediateValue}
      options={options}
      onChange={setIntermediateValue}
    />
  );

  return isMobile || isInline ? (
    <Section data-section-filter-name={type} data-auto-filter-name="bathsRange" data-auto={`filter-${type}`}>
      <SectionTitle>{t('filters.baths')}</SectionTitle>
      <SectionBody>
        {!isInline ? (
          <SectionScrollBody>
            {rangeSelect}
          </SectionScrollBody>
        ) : (
          <div>
            {rangeSelect}
          </div>
        )}
      </SectionBody>
    </Section>
  ) : (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      isActive={isActive}
      icon={filtersIcons[type]}
      title={t('filters.baths')}
      label={shortValueLabel}
      onClear={resetValue}
      onSubmit={commitValues}
    >
      {rangeSelect}
    </FilterSection>
  );

};

export default React.memo(BathsRange);
