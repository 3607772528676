import React from 'react';
import MediaQuery from 'react-responsive';
import { Theme } from 'ds';
import { css, Global } from '@emotion/core';
import { useTheme } from 'hooks/useTheme';
import Main from 'screens/Main';
import { STICKY_ROOT, SEARCH_BOTTOM_ROOT } from './consts/rootNodes';
import { OrientationBlock, MAX_HEIGHT, MAX_WIDTH } from 'components/orientation-block';
import Modals from './Modals';


const App: React.FC = () => {
  const { isRTL } = useTheme();
  const direction = isRTL ? 'rtl' : 'ltr';

  return (
    <div style={{ direction }}>
      <Global<Theme>
        styles={() => css`
          #${STICKY_ROOT}, #${SEARCH_BOTTOM_ROOT} {
            direction: ${direction};
          }
        `}
      />
      <Modals />
      <Main />
      <MediaQuery maxHeight={MAX_HEIGHT} maxWidth={MAX_WIDTH}>
        <MediaQuery query={`screen and (min-aspect-ratio: 13/9) and (pointer: coarse)`}>
          <OrientationBlock />
        </MediaQuery>
      </MediaQuery>
    </div>
  );
};

export default App;
