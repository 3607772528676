import {
  searchDocEntriesSelector,
  addressDocEntrySelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  searchMapDataLayersDataSelector,
  isUnitPageBulletinLoadingSelector,
  isProjectPageProjectLoadingSelector,
  searchMapDataLayersLoadingSelector,
  isAddressDocLoadingSelector,
  searchDocLoadingSelector,
  soldPageDealSelector,
  savedAddressesIdsSelector,
  searchMapClustersDataSelector,
  searchMapClustersLoadingSelector,
  resolvedStrictLocalDocSelector,
  isStrictLocalDocLoadingSelector,
  unitPageCommercialBulletinSelector,
  isUnitPageCommercialBulletinLoadingSelector,
  isCommercialProjectPageProjectLoadingSelector,
  projectPageCommercialProjectSelector,
  searchInfoEntriesSelector,
} from '../domainData/selectors';
import { flow, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import getBbox from '@turf/bbox';
import { multiPolygon, multiLineString, featureCollection } from '@turf/helpers';
import {
  BoundingBox,
  CompletionType,
  IAutocompleteAddress,
  IDataLayerItem,
  PoiId,
  IDeal,
  IClusterDataLayer,
  IAutocompleteEntry,
  IBulletin,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
  ContentWidthMode,
  DocId2InfoDocument,
  MarketplaceType,
} from 'utils/entities';
import { makeBufferAroundCenter, createPoly, addBufferToBounds } from 'utils/geo';
import { getRouteParams, isMobileItemMapActiveSelector, routeNameSelector, routeSelector } from './router';
import { Route } from 'config/routes';

import config from 'config';
import { mergeWithInitialState } from 'components/filters/utils';
import { State } from 'store/state';
import { config as sortConfig } from 'components/listing-sort/config';
import { makeSet } from 'components/listing-sort/utils';
import { currentZoomSelector, lastSearchBboxSelector } from '../searchContext/selectors';
import { createRefSelector } from 'utils';
import { MAX_ZOOM } from 'store/sagas/routing/handlers/search/utils';
import { FeatureDescriptor } from 'utils/insightsFeatureTypes';
import { marketplaceSelector } from 'store/state/selectors/router';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';

export const dealTypeSelector = createSelector([
  flow(getRouteParams, (params) => params.dealType),
  routeNameSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
], (routeDealType, routeName, bulletin, project, commercialBulletin, commercialProject) => {

  if (routeDealType) return routeDealType;

  switch (routeName) {
    case Route.UnitPage:
      if (bulletin) {
        return bulletin.dealType;
      }
      break;

    case Route.ProjectPage:
      if (project) {
        return project.dealType;
      }
      break;
    case Route.UnitPageCommercial:
      if (commercialBulletin) {
        return commercialBulletin.dealType;
      }
      break;
    case Route.ProjectPageCommercial:
      if (commercialProject) {
        return commercialProject.dealType;
      }
      break;
  }

  return undefined;
});

const sortBasicOptionsSetSelector = createSelector(() => sortConfig.basicOptions, makeSet);

const sortParamSelector = createSelector(getRouteParams, ({ sort = [] }) => sort);

export const smartSortValueSelector = createSelector([
  sortParamSelector,
  sortBasicOptionsSetSelector,
], (sort, basicSet) => (
  sort.filter((value) => !basicSet.has(value))
));

export const basicSortValueSelector = createSelector([
  sortParamSelector,
  sortBasicOptionsSetSelector,
], (sort, basicSet) => (
  sort.filter((value) => basicSet.has(value))
));

export const filtersStateSelector = createSelector([
  flow(getRouteParams, (p) => p.filters),
  flow(getRouteParams, (p) => p.dealType),
  marketplaceSelector,
], (filtersState, dealType, marketplace) => (
  dealType
    ? mergeWithInitialState(filtersState, dealType, marketplace)
    : null
  )
);

const EMPTY_DOCS: IAutocompleteEntry[] = [];

export const relevantDocsSelector = createSelector([
  searchDocEntriesSelector,
  resolvedStrictLocalDocSelector,
  routeNameSelector,
], (searchDocs, localDoc, routeName) => {
  let docs: IAutocompleteEntry[] = null;
  if (routeName === Route.LocalPage || routeName === Route.StreetPage) docs = localDoc && [ localDoc ];
  else docs = searchDocs;
  return docs || EMPTY_DOCS;
});

export const isSearchPageAddressSavedSelector = createSelector([
  getRouteParams,
  savedAddressesIdsSelector,
], ({ term }, savedIds) => term && savedIds && term.every(t => savedIds.has(t)));

export const bboxFromRouteSelector = flow(getRouteParams, (params) => params.bbox);

type Geometries = GeoJSON.MultiLineString | GeoJSON.MultiPolygon | GeoJSON.Polygon;
interface IFeatureProps {
  featureType: FeatureDescriptor;
}

const BUFFER_100_METERS = 0.0009;

const geometrySelector = createSelector([ relevantDocsSelector ], (searchDocs): GeoJSON.FeatureCollection<Geometries, IFeatureProps> => {
  if (!searchDocs || !searchDocs.length) return featureCollection([]);

  const features = searchDocs.map((searchDoc): GeoJSON.Feature<Geometries, IFeatureProps> => {

    if (searchDoc.type !== CompletionType.Street && 'geometry' in searchDoc && (searchDoc.geometry || []).length) {
      return multiPolygon(searchDoc.geometry);
    }

    if (searchDoc.type === CompletionType.SchoolDoc && 'zoneGeometry' in searchDoc && (searchDoc.zoneGeometry || []).length) {
      return multiPolygon(searchDoc.zoneGeometry, { featureType: FeatureDescriptor.ZoneGeometry });
    }

    if (searchDoc.type === CompletionType.Street) {
      let lineString: GeoJSON.Feature<GeoJSON.MultiLineString, IFeatureProps> = null;

      if (searchDoc.lineString.length) {
        lineString = multiLineString(searchDoc.lineString);
      }
      if (searchDoc.virtualLineString.length) {
        lineString = multiLineString(searchDoc.virtualLineString);
      }

      if (lineString) {
        // adding buffer to line strings is expensive,
        // so we are getting bounds and adding buffer to bounds
        const [ west, south, east, north ] = getBbox(lineString) as [ number, number, number, number ];
        const bbox = addBufferToBounds([ [ west, south ], [ east, north ] ], BUFFER_100_METERS);
        return createPoly(bbox);
      }

      return createPoly(makeBufferAroundCenter(searchDoc.location, BUFFER_200_METERS));
    }

    if (searchDoc.type === CompletionType.Address) {
      return createPoly(makeBufferAroundCenter(searchDoc.location, BUFFER_AROUND_POINT));
    }

    return null;
  }).filter(Boolean);

  return featureCollection(features);
});

export const BUFFER_AROUND_POINT = 0.005;
const MOBILE_ITEM_PAGE_BUFFER_AROUND_POINT = 0.00285;
const BUFFER_200_METERS = BUFFER_100_METERS * 2;
const CHECK_ADDRESS_BUFFER = 0.04;

const createTunedLoadingSelector = (
  loadingSelector: (state: State) => boolean,
  actualIdSelector: (state: State) => string,
  desiredIdSelector: (state: State) => string
) => createSelector([
  loadingSelector,
  actualIdSelector,
  desiredIdSelector,
], (isLoading, actualDocId, desiredDocId) => {
  if (isLoading || !actualDocId) return true;
  return !(actualDocId === desiredDocId);
});

const strictLocalDocLoadingTuned = createTunedLoadingSelector(
  isStrictLocalDocLoadingSelector,
  flow(resolvedStrictLocalDocSelector, (doc) => doc && doc.docId),
  flow(getRouteParams, (r) => r.id)
);

const searchDocLoadingTuned = createTunedLoadingSelector(
  searchDocLoadingSelector,
  flow(searchDocEntriesSelector, (docs) => docs && docs.map(d => d.docId).sort().join()),
  flow(getRouteParams, (r) => r.term && r.term.slice().sort().join())
);

const isAddressDocLoadingTuned = createTunedLoadingSelector(
  isAddressDocLoadingSelector,
  flow(addressDocEntrySelector, (d) => d && d.docId),
  flow(getRouteParams, (r) => r.address)
);

const tunedUnitPageLoading = createTunedLoadingSelector(
  isUnitPageBulletinLoadingSelector,
  flow(unitPageBulletinSelector, u => u && u.id),
  flow(getRouteParams, r => r.id)
);

const tunedUnitPageCommercialLoading = createTunedLoadingSelector(
  isUnitPageCommercialBulletinLoadingSelector,
  flow(unitPageCommercialBulletinSelector, u => u && u.id),
  flow(getRouteParams, r => r.id)
);
const tunedProjectCommercialLoading = createTunedLoadingSelector(
  isCommercialProjectPageProjectLoadingSelector,
  flow(projectPageCommercialProjectSelector, u => u && u.id),
  flow(getRouteParams, r => r.id)
);

const isLoadingSelector = createSelector([
  routeNameSelector,
  tunedUnitPageLoading,
  isProjectPageProjectLoadingSelector,
  isAddressDocLoadingTuned,
  searchDocLoadingTuned,
  strictLocalDocLoadingTuned,
  tunedUnitPageCommercialLoading,
  tunedProjectCommercialLoading,
], (routeName, isUnitPageBulletinLoading, isProjectPageProjectLoading, isAddressDocLoading, searchDocLoading, strictLocalDocLoading, commercialBulletinLoading, commercialProjectLoading) => {
  switch (routeName) {
    case Route.UnitPage:
      return isUnitPageBulletinLoading;
    case Route.UnitPageCommercial:
      return commercialBulletinLoading;
    case Route.AddressPage:
      return isAddressDocLoading;
    case Route.Search:
    case Route.SearchCommercial:
      return searchDocLoading;
    case Route.LocalPage:
    case Route.StreetPage:
      return strictLocalDocLoading;
    case Route.ProjectPageCommercial:
      return commercialProjectLoading;
    case Route.ProjectPage:
      return isProjectPageProjectLoading;
    default:
      return false;
  }
});

const makeRelevantPoiSelector = (
  route: Route,
  poiSelector: (state: State) => IBulletin | IProject | IAutocompleteEntry | IDeal | ICommercialBulletin | ICommercialProject
) => createSelector([
  poiSelector,
  routeNameSelector,
  flow(getRouteParams, (p) => p.id),
  flow(getRouteParams, (p) => p.address),
], (poi, routeName, routeId, routeAddress) => (
  poi && routeName === route && (poi.id === routeId || (poi as IAutocompleteAddress).identityDocId === routeAddress)
    ? poi
    : null
));

export const currentPoiPointSelector: (state: State) => [ number, number ] = createSelector([
  makeRelevantPoiSelector(Route.UnitPage, unitPageBulletinSelector),
  makeRelevantPoiSelector(Route.ProjectPage, projectPageProjectSelector),
  makeRelevantPoiSelector(Route.ProjectPageCommercial, projectPageCommercialProjectSelector),
  makeRelevantPoiSelector(Route.UnitPageCommercial, unitPageCommercialBulletinSelector),
  makeRelevantPoiSelector(Route.AddressPage, addressDocEntrySelector),
  makeRelevantPoiSelector(Route.Sold, soldPageDealSelector),
], (
  unitBulletin: IBulletin,
  project: IProject,
  commercialBulletin: ICommercialBulletin,
  commercialProject: ICommercialProject,
  address: IAutocompleteAddress,
  deal: IDeal
): [ number, number ] => {
  const poi = unitBulletin || project || deal || commercialBulletin || commercialProject;

  if (address) {
    return address.location;
  }

  return poi && poi.locationPoint ? [ poi.locationPoint.lng, poi.locationPoint.lat ] : null;

});

export const searchBboxSelector = createSelector([
  bboxFromRouteSelector,
  geometrySelector,
  addressDocEntrySelector,
  currentPoiPointSelector,
  routeNameSelector,
], (bboxFromRoute, geometry, addressDoc, poiPoint, routeName): BoundingBox => {

  if (bboxFromRoute) {
    return bboxFromRoute;
  }

  if (routeName === Route.CheckAddress || (routeName === Route.Sold && !poiPoint)) {
    const { lng, lat } = config.mapbox.centerCoordinate;
    return makeBufferAroundCenter([ lng, lat ], CHECK_ADDRESS_BUFFER);
  }

  const geometryExists = geometry.features.length !== 0;

  if (!geometryExists) {

    if (routeName === Route.AddressPage && addressDoc) {
      return makeBufferAroundCenter(addressDoc.location, BUFFER_AROUND_POINT);
    }

    if (poiPoint) return makeBufferAroundCenter(poiPoint, BUFFER_AROUND_POINT);

    return null;
  }

  const [ west, south, east, north ] = getBbox(geometry) as [ number, number, number, number ];

  return [ [ west, south ], [ east, north ] ];
});


interface Rect {
  right: number;
  left: number;
  top: number;
  bottom: number;
}

export function shiftBboxBy(bbox: BoundingBox, by: Partial<Rect>): BoundingBox {
  let [ [ x1, y1 ], [ x2, y2 ] ] = bbox;

  const xRange = Math.abs(x1 - x2);
  const yRange = Math.abs(y1 - y2);

  Object.keys(by).forEach((side: keyof Rect) => {
    const offset = by[side];
    const absXOffset = offset * xRange;
    const absYOffset = offset * yRange;

    switch (side) {
      case 'left':
        x1 -= absXOffset;
        x2 -= absXOffset;
        break;
      case 'right':
        x1 += absXOffset;
        x2 += absXOffset;
        break;
      case 'top':
        y1 += absYOffset;
        y2 += absYOffset;
        break;
      case 'bottom':
        y1 -= absYOffset;
        y2 -= absYOffset;
        break;
    }
  });

  return [ [ x1, y1 ], [ x2, y2 ] ];
}

function calcRect(point: [ number, number ], bbox: BoundingBox): Rect {
  const [ testX, testY ] = point;
  const [ [ x1, y1 ], [ x2, y2 ] ] = bbox;


  const left = testX - x1;
  const top = y2 - testY;
  const bottom = testY - y1;
  const right = x2 - testX;


  const xRange = Math.abs(x1 - x2);
  const yRange = Math.abs(y1 - y2);

  return {
    left: left / xRange,
    right: right / xRange,
    top: top / yRange,
    bottom: bottom / yRange,
  };

}

const THRESHOLDS: Rect = config.locale === 'he-IL' ? {
  left: 0.1,
  top: 0.1,
  right: 0.175,
  bottom: 0.1,
} : {
  left: 0.175,
  top: 0.1,
  right: 0.1,
  bottom: 0.1,
};

const freezedBboxSelector = createRefSelector(lastSearchBboxSelector);
const zoomRefSelector = createRefSelector(currentZoomSelector);

export const mapViewBboxSelector = createSelector([
  searchBboxSelector,
  currentPoiPointSelector,
  isMobileItemMapActiveSelector,
  isLoadingSelector,
  freezedBboxSelector,
  zoomRefSelector,
], (searchBbox, poiPoint, isMobileItemMapActive, isLoading, currentBbox, zoom) => {
  if (isLoading) return null;

  if (poiPoint) {
    if (isMobileItemMapActive) {
      return makeBufferAroundCenter(poiPoint, MOBILE_ITEM_PAGE_BUFFER_AROUND_POINT);
    }

    if (zoom.current < 14) {
      return makeBufferAroundCenter(poiPoint, BUFFER_AROUND_POINT);
    }

    const bbox = currentBbox.current || searchBbox;

    const rect = calcRect(poiPoint, bbox);
    const shift: Partial<Rect> = {};
    // tslint:disable-next-line: forin
    for (const side in rect) {
      const diff = THRESHOLDS[side] - rect[side];
      if (diff > 0) {
        shift[side] = diff;
      }
    }

    if (!isEmpty(shift)) return shiftBboxBy(bbox, shift);
    else return null;
  }

  return searchBbox;
});

export const allSearchDataLayersDictionarySelector = createSelector([
  searchMapDataLayersDataSelector,
], (data) => {
  const { ...dictionaryByZoom } = data || {};
  const result: Record<PoiId, IDataLayerItem> = {};
  // tslint:disable-next-line: forin
  for (const zoom in dictionaryByZoom) {
    Object.assign(result, dictionaryByZoom[zoom]);
  }
  return result;
});

export const dataLayersZoomSelector = flow(currentZoomSelector, Math.ceil, (zoom) => Math.min(zoom, MAX_ZOOM));


const createByCurrentZoomSelector = <T>(
  dataSelector: (state: State) => Record<number, Record<string, T>>,
  loadingSelector: (state: State) => boolean
) => {
  const EMPTY: Record<PoiId, T> = {};
  return createSelector([
    dataSelector,
    loadingSelector,
    dataLayersZoomSelector,
  ], (data, loading, zoom): Record<PoiId, T> => (
    loading || !data || !data[zoom]
        ? EMPTY
      : data[zoom]
  ));
};

export const searchDataLayersDictionarySelector = createByCurrentZoomSelector(
  searchMapDataLayersDataSelector,
  searchMapDataLayersLoadingSelector
);

export const searchMapClustersDictionarySelector = createByCurrentZoomSelector(
  searchMapClustersDataSelector,
  searchMapClustersLoadingSelector
);

export const searchMapClustersListSelector: (state: State) => IClusterDataLayer[] =
  createSelector(searchMapClustersDictionarySelector, Object.values);

export const searchListDataLayersPoiListSelector: (state: State) => IDataLayerItem[] =
  createSelector(searchDataLayersDictionarySelector, Object.values);

export const SEARCH_ROUTES = new Set([ Route.Search, Route.SearchCommercial ]);

export const desktopContentWidthModeSelector = createSelector([
  routeSelector,
], (route): ContentWidthMode => {
  const isSearch = SEARCH_ROUTES.has(route.name);
  switch (true) {
    case isSearch:
      return ContentWidthMode.Wide;
    case isUnitPageRoute(route.name):
      return ContentWidthMode.Narrow;
    default:
      return ContentWidthMode.Default;
  }
});

export const searchInfoEntriesByMarketplaceSelector = createSelector(
  [ searchInfoEntriesSelector, marketplaceSelector ],
  (searchInfoEntries, marketplace): DocId2InfoDocument[] => {
    const isResidential = marketplace === MarketplaceType.Residential;

    if (searchInfoEntries) {
      return isResidential
        ? searchInfoEntries.filter((entry) => entry.document.type !== CompletionType.EmploymentArea)
        : searchInfoEntries.filter((entry) => entry.document.type === CompletionType.EmploymentArea);
    }
    else return searchInfoEntries;
  }
);
