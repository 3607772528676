import styled from '@emotion/styled';
import { DESKTOP_HEADER_TOTAL_HEIGHT } from 'components/navigation/header.constants';
import { Button } from 'ds/components/button';
import { SmallText, Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';

export const StyledSupportButton = styled(Button)`
  display: flex;
  white-space: nowrap;
  line-height: ${typographyLineHeightCfg.smallText}px;
  font-size: ${typographyFontSizeCfg.smallText}px;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};

  svg {
    margin-left: ${({ theme }) => theme.spacing(1)};
    path {
      stroke: ${({ theme }) => theme.colors.brand.main};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutrals.grey6};
    color: ${({ theme }) => theme.colors.brand.darker};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.brand.darker};
      }
    }
  }

  &:focus {
    color: ${({ theme }) => theme.colors.brand.secondary};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.brand.secondary};
      }
    }
  }
`;

export const SupportPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: ${DESKTOP_HEADER_TOTAL_HEIGHT + 1}px;
  left: 24px;
  width: 288px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.neutrals.white};
`;

export const Header = styled(SmallText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.neutrals.white};
  background-color: ${({ theme }) => theme.colors.brand.madlanGreen};
  border-radius: ${({ theme }) => theme.borderRadius.small} ${({ theme }) => theme.borderRadius.small} 0 0;
  white-space: pre-line;

  div {
    display: inline;
  }

  svg {
    min-width: 24px;
    margin-right: ${({ theme }) => theme.spacing(1.5)};
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  cursor: pointer;

  &:hover {
    div {
      color: ${({ theme }) => theme.colors.brand.darker};
    }
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.brand.darker};
      }
    }
  }
`;

export const ItemRightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemHeader = styled(SmallText)`
  color: ${({ theme }) => theme.colors.brand.main};
  text-align: right;
`;

export const ItemSubheader = styled(Text.withComponent('span'))`
  display: contents;
  text-align: right;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  cursor: text;
`;
