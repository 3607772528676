import React, { Suspense } from 'react';
import { isServer } from 'utils';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactElement;
}

const DEFAULT_FALLBACK = <div />;

export const SafeSuspense: React.FC<Props> = ({ children, fallback = DEFAULT_FALLBACK }) =>
  isServer() ? null : (
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  );

