import { UploadBulletinStep } from './types';

type UploadBulletinAnalyticsSteps = Exclude<UploadBulletinStep,
  UploadBulletinStep.AgentResidential | UploadBulletinStep.Authentication | UploadBulletinStep.Preview>;

export const stepToAnalyticsStep: Record<UploadBulletinAnalyticsSteps, string> = {
  [UploadBulletinStep.BulletinType]: 'marketplace_type',
  [UploadBulletinStep.DealType]: 'bulletin_type',
  [UploadBulletinStep.Address]: 'address',
  [UploadBulletinStep.MainInfo]: 'overview',
  [UploadBulletinStep.AdditionalInfo]: 'more',
  [UploadBulletinStep.UploadImage]: 'photos',
  [UploadBulletinStep.Contacts]: 'contact',
  [UploadBulletinStep.SelectAgent]: 'choose_agent',
};
