import React, { ReactElement } from 'react';
import { useLocale } from 'locale';
import { CommutePreference, CommuteType, IAutocompleteEntry } from 'utils/entities';
import {
  CommuteLocationInputWrapper,
  CommutePreferenceForm,
  CommuteRushHour,
  CommuteTypePreferenceWrapper,
  CommuteTypeSelectItem,
  CommuteTypeSelectWrapper,
} from './styled';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import { AddressAutocompleteSource } from 'components/autocomplete/types';
import { FadeWrapper } from 'components/authentication/styled';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import BusIcon from 'assets/svg/transportation/bus.svg';
import BikeIcon from 'assets/svg/transportation/bike.svg';
import CarIcon from 'assets/svg/transportation/car.svg';
import WalkIcon from 'assets/svg/transportation/walk.svg';
import CommuteIcon from 'assets/svg/transportation/metro.svg';
import { ILatLngPoint } from 'utils/entities';
import { CompletionListGroup, config as completionConfig } from 'components/commute-popup/CompletionTypeConfig';
import { InitialSuggestion } from 'utils/initialSuggestionsConfig';


const initialSuggestions: InitialSuggestion[] = [];
const commuteTypeOptions: CommuteType[] = [ 'train', 'walk', 'car', 'bus' ];

export interface PreferredAddress {
  location: ILatLngPoint;
  text: string;
  docId: string;
}

export const commuteTypeToIcon: Record<CommuteType, JSX.Element> = {
  commute: <CommuteIcon width={24} height={24} />,
  train: <CommuteIcon width={24} height={24} />,
  bike: <BikeIcon width={24} height={24} />,
  car: <CarIcon width={24} height={24} />,
  walk: <WalkIcon width={24} height={24} />,
  bus: <BusIcon width={24} height={24} />,
};

interface GenericCommutePreferenceFormProps {
  onSetSearchStr: (v: string) => void;
  onSetCommuteType?: (v: CommuteType) => void;
  onSetRushHour?: (v: boolean) => void;
  commuteType?: CommuteType;
  rushHour?: boolean;
  searchStr: string;
  initialPreference?: CommutePreference;
  onAddressSelect: (v: PreferredAddress) => void;
  accentColor?: string;
  placeholder?: string;
  withoutCommuteType?: boolean;
  inputIcon?: ReactElement;
  autoFocus?: boolean;
  onBlur?: () => void;
  commuteContentByPreference?: React.ReactNode;
}

export const GenericCommutePreferenceForm: React.FC<GenericCommutePreferenceFormProps> = ({
  onSetSearchStr,
  onSetCommuteType,
  onSetRushHour,
  commuteType,
  rushHour,
  searchStr,
  initialPreference,
  onAddressSelect,
  accentColor,
  placeholder,
  withoutCommuteType,
  inputIcon,
  autoFocus,
  onBlur,
  commuteContentByPreference,
}) => {
  const { t } = useLocale();
  const completionTypes = completionConfig[CompletionListGroup.Commute];

  const makeCommuteTypeFieldChangeHandler = (nextType: CommuteType) => () => {
    if (nextType !== commuteType) {
      onSetCommuteType(nextType);
      onSetRushHour(nextType === 'car');
    }
  };

  const handleLocationInput = (value: IAutocompleteEntry) => {
    if (value) {
      onAddressSelect({
        location: {
          lng: value.location[0],
          lat: value.location[1],
        },
        docId: value.docId,
        text: value.name,
      });
    }
    else {
      onAddressSelect(null);
    }
  };

  const initialValue = initialPreference && initialPreference.location ? {
    location: [ initialPreference.location.lng, initialPreference.location.lat ],
    name: initialPreference.text,
  } : null;

  const commuteInput = (
    <CommuteLocationInputWrapper>
      <AddressAutocompleteInput
        initialSuggestions={initialSuggestions}
        autoFocus={autoFocus}
        onBlur={onBlur}
        completionTypes={completionTypes}
        searchStr={searchStr}
        inputIcon={inputIcon}
        onSearchStrChange={onSetSearchStr}
        placeholder={placeholder || t('commutePopup.inputPlaceholder')}
        onChange={handleLocationInput}
        initialValue={initialValue as IAutocompleteEntry}
        optionsCount={6}
        showClearButton
        source={AddressAutocompleteSource.CommuteSurveyPreferences}
      />
    </CommuteLocationInputWrapper>
  );

  const commuteContent = commuteContentByPreference ? commuteContentByPreference : (
    <>
      {commuteInput}
      {withoutCommuteType ? null : (
        <CommuteTypePreferenceWrapper>
          <CommuteTypeSelectWrapper>
            {commuteTypeOptions.map(option => (
              <CommuteTypeSelectItem
                data-auto={`commute-type-${option}`}
                data-auto-active={option === commuteType}
                accentColor={accentColor}
                key={option}
                isActive={option === commuteType}
                onClick={makeCommuteTypeFieldChangeHandler(option)}
              >
                {commuteTypeToIcon[option]}
              </CommuteTypeSelectItem>
            ))}
          </CommuteTypeSelectWrapper>
          <FadeWrapper isVisible={commuteType === 'car'}>
            <CommuteRushHour checked={rushHour} accentColor={accentColor}>
              <CheckboxField
                label={t('commutePopup.rushHour')}
                checked={rushHour}
                onClick={() => onSetRushHour(!rushHour)}
              />
            </CommuteRushHour>
          </FadeWrapper>
        </CommuteTypePreferenceWrapper>
      )}
    </>
  );

  return (
    <CommutePreferenceForm>
      {commuteContent}
    </CommutePreferenceForm>
  );
};
