export type Listener<T> = (action: T) => void;

export const createPubSub = <T>() => {
  const listeners: Array<Listener<T>> = [];

  return {
    subscribe: (listener: Listener<T>) => {
      const idx = listeners.push(listener) - 1;

      return () => {
        listeners.splice(idx, 1);
      };
    },
    dispatch: (action: T) => {
      listeners.forEach((listener) => listener(action));
    },
  };
};
