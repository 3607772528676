import { useContext } from 'react';
import React from 'react';
import defaultTheme from 'ds/theme/default';
import { ThemeProvider } from 'emotion-theming';
import { UserAgentContext } from 'hooks/useImageUrlBuilder';
import { isWindows } from 'utils/uaMatchers';
import { Global } from '@emotion/core';
import novemberFontPC from 'assets/css/novemberFontPC';
import novemberFont from 'assets/css/novemberFont';
import reset from 'assets/css/reset';
import app from 'assets/css/app';


export const ThemeApp: React.FunctionComponent = ({ children }) => {

  const userAgent = useContext(UserAgentContext);
  const isWin = isWindows(userAgent);

  return (
    <ThemeProvider
      theme={{
        ...defaultTheme,
        isWindows: isWin,
      }}
    >
      <Global styles={reset} />
      <Global styles={app} />
      <Global styles={isWin ? novemberFontPC : novemberFont} />
      {children}
    </ThemeProvider>
  );
};
