import React, { useRef, useState } from 'react';
import { useLocale } from 'locale';
import { getRouteConfig } from 'components/local-data/LocalData';
import {
  CloseIconWrapper,
  Label,
  LinkWrapper,
  ListItem,
  ListItemLinkWrapper,
  LocationName,
  MobileListHeader,
  MobileOverlay,
  MultiLocationList,
} from './styled';
import { getEntryName } from 'utils/texts';
import { upperFirst } from 'lodash';
import ChevronLeftIcon from 'assets/svg/chevron-left.svg';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useScreenBreakpoint } from 'consts/breakpoints';
import TreeIcon from 'assets/svg/tree.svg';
import SchoolIcon from 'assets/svg/graduation-cap.svg';
import CloseIcon from 'assets/svg/dropdown/close.svg';
import { ILocalDataResponse } from 'store/sagas/apiService/types';
import { Route } from 'config/routes';
import { CompletionType } from 'utils/entities';
import { Link } from 'components/link';
import config from 'config';

interface SubheaderLocalDataProps  {
  localData: ILocalDataResponse[];
  loading: boolean;
  routeName: Route;
  shouldDisplay: boolean;
}

export const SubheaderLocalData: React.FC<SubheaderLocalDataProps> = ({
  loading,
  localData,
  routeName,
  shouldDisplay,
}) => {
  const { t } = useLocale();
  const [ isMultiListOpen, setIsMultiListOpen ] = useState(false);
  const multiLocationListRef = useRef(null);
  const isMobile = useScreenBreakpoint() <= 2;

  useClickAwayListener(multiLocationListRef, () => setIsMultiListOpen(false));

  if (loading || !localData || localData.length === 0 || !shouldDisplay) return null;

  const isSingleLocation = localData.length === 1;
  const weight = isMobile ? 'normal' : 'medium';

  if (isSingleLocation) {
    const resolvedAddress = localData && localData[0].document;
    const { route, routeParams } = getRouteConfig(resolvedAddress, routeName);

    if (!route || !routeParams) return null;

    return (
      <LinkWrapper>
        {resolvedAddress.type === CompletionType.SchoolDoc ? (
          <a href={`${config.oldILRoot}/education/school/${resolvedAddress.id}`} target={isMobile ? '_self' : '_blank'}>
            <Label weight={weight}>
              {isMobile && <SchoolIcon />}
              {t('searchListView.subheaderLocalData.label', { completionType: resolvedAddress.type })}
            </Label>
          </a>
        ) : (
          <Link preserveParams routeName={route} routeParams={routeParams} data-auto="local-page-link">
            <Label weight={weight}>
              {isMobile && <TreeIcon />}
              {t('searchListView.subheaderLocalData.label', { completionType: resolvedAddress.type })}
            </Label>
          </Link>
        )}
      </LinkWrapper>
    );
  }
  else {
    const shouldRender = localData
      .map(entry => getRouteConfig(entry.document, routeName)).some(cfg => cfg.route && cfg.routeParams);

    if (!shouldRender) return null;

    const areasToDisplay = localData.map(entry => {
      const resolvedAddress = entry.document;
      const { route, routeParams } = getRouteConfig(resolvedAddress, routeName);

      if (!route || !routeParams) return null;

      const locationName = upperFirst(getEntryName(resolvedAddress));

      return (
        <ListItem key={locationName}>
          <LocationName weight="medium">{locationName}</LocationName>
          <ListItemLinkWrapper>
            {resolvedAddress.type === CompletionType.SchoolDoc ? (
              <a
                href={`${config.oldILRoot}/education/school/${resolvedAddress.id}`}
                target={isMobile ? '_self' : '_blank'}
              >
                {t('searchListView.subheaderLocalData.label', { completionType: resolvedAddress.type })}
                <ChevronLeftIcon />
              </a>
            ) : (
              <Link preserveParams routeName={route} routeParams={routeParams} data-auto="local-page-link">
                {t('searchListView.subheaderLocalData.label', { completionType: resolvedAddress.type })}
                <ChevronLeftIcon />
              </Link>
            )}
          </ListItemLinkWrapper>
        </ListItem>
      );
    });

    return (
      <>
        <Label onClick={() => setIsMultiListOpen(true)} weight={weight}>
          {isMobile && <TreeIcon />}
          {t('searchListView.subheaderLocalData.manyAreas.label')}
        </Label>
        {isMultiListOpen && (
          <>
            {isMobile && <MobileOverlay />}
            <MultiLocationList ref={multiLocationListRef}>
              {isMobile && (
                <>
                  <CloseIconWrapper onClick={() => setIsMultiListOpen(false)}>
                    <CloseIcon width={24} height={24} />
                  </CloseIconWrapper>
                  <MobileListHeader weight="bold">
                    {t('searchListView.subheaderLocalData.manyAreas.label')}
                  </MobileListHeader>
                </>
              )}
              {areasToDisplay}
            </MultiLocationList>
          </>
        )}
      </>
    );
  }
};
