import { LoadType, MutationType, ResponseByType } from 'store/sagas/apiService/types';
import { MutationsResponseAction } from 'store/state/mutationsResponse';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { Domain } from 'store/state/domainData/index';
import { domainInitialState } from 'store/state/domainData/utils';
import { isNil } from 'lodash';
import { responseDealToAgentDeal } from 'components/deal-affiliation-popup/helpers';

type DealsAffiliationRequestData = ResponseByType[LoadType.GetDealsAffiliationRequests];

const requestDealAffiliationReducer = (state: DealsAffiliationRequestData, action: SetMutationResponseAction<MutationType.RequestDealAffiliation>) => {
  const response = action.meta.response;
  const data = response.data && response.data.requestDealAffiliation;

  if (!data || data.errorMessage) return state;

  return {
    getDeals: {
      ...state.getDeals,
      total: state.getDeals.total + 1,
      deals: [
        ...state.getDeals.deals,
        (responseDealToAgentDeal(data)),
      ],
    },
  };
};

const editDealRequestReducer = (state: DealsAffiliationRequestData, action: SetMutationResponseAction<MutationType.EditDealAffiliationRequest>) => {
  const response = action.meta.response;
  const data = response.data && response.data.publicEditDealAffiliationRequest;
  if (!data || data.errorMessage) return state;

  const list = state.getDeals && state.getDeals.deals || [];
  const dealIndex = list.findIndex(deal => deal.affiliationRequestId === data.id);

  return {
    getDeals: {
      ...state.getDeals,
      deals: [
        ...list.slice(0, dealIndex),
        {
          ...list[dealIndex],
          lastUpdated: data.updatedAt,
          agentId: data.agentId,
          affiliationType: data.agentType,
          receiptUrls: data.receiptUrls,
          affiliationStatus: data.status,
          rejectionReason: data.rejectionReason,
          agentName: data.agentName,
        },
        ...list.slice(dealIndex + 1),
      ],
    },
  };
};

const affiliationRequestReducer = (state: DealsAffiliationRequestData, action: MutationsResponseAction): DealsAffiliationRequestData => {
  switch (action.type) {
    case SET_MUTATION_RESPONSE: {
      switch (action.mutationType) {
        case MutationType.EditDealAffiliationRequest:
          return editDealRequestReducer(state, action);
        case MutationType.RequestDealAffiliation:
          return requestDealAffiliationReducer(state, action);
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export function dealsAffiliationRequestsReducer(state: Domain<LoadType.GetDealsAffiliationRequests> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.GetDealsAffiliationRequests> {
  if (isNil(state.data)) return state;

  const reduced = affiliationRequestReducer(state.data, action);
  if (state.data === reduced) return state;

  return {
    ...state,
    data: reduced,
  };
}
