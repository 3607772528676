import { call, put, takeLatest } from 'redux-saga/effects';
import { transitionSuccess, transitionStart } from 'store/state/router/actions';
import { TRANSITION_START } from 'store/state/router/types';
import { scrollMiddleware } from './scrollMiddleware';
import { Saga } from 'redux-saga';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { reactNativeRouteNotifyMiddleware } from './reactNativeRouteNotifyMiddleware';

const MIDDLEWARES: Saga[] = [
  scrollMiddleware,
  reactNativeRouteNotifyMiddleware,
];

function* middlewareWorker({ payload }: ReturnType<typeof transitionStart>) {
  const { route, previousRoute, done } = payload;
  let meta: NavigationOptions['middlewareMeta'] = {};

  for (const middleware of MIDDLEWARES) {
    meta = yield call(middleware, route, previousRoute, meta);
  }

  yield put(transitionSuccess({
    route: {
      ...route,
      meta: {
        ...route.meta,
        middlewareMeta: meta,
      },
    },
    previousRoute,
  }));
  done();
}

export function* middlewareWatcher() {
  yield takeLatest(TRANSITION_START, middlewareWorker);
}

