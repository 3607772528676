import { Amenity, IGeneralCondition } from 'utils/entities';


export const RESIDENTIAL_GENERAL_CONDITION_OPTIONS = [
  IGeneralCondition.New,
  IGeneralCondition.AsNew,
  IGeneralCondition.Renovated,
  IGeneralCondition.Preserved,
  IGeneralCondition.ToRenovated,
];

export const COMMERCIAL_GENERAL_CONDITION_OPTIONS = [
  IGeneralCondition.Skin,
  IGeneralCondition.Finish,
  IGeneralCondition.FurnishedFinish,
  IGeneralCondition.FullFinish,
  IGeneralCondition.RequiresRenovation,
];

export const RESIDENTIAL_AMENITIES = [
  Amenity.Parking,
  Amenity.Elevator,
  Amenity.Storage,
  Amenity.SecureRoom,
  Amenity.AirConditioner,
  Amenity.Accessible,
  Amenity.Grating,
  Amenity.PandoorDoors,
  Amenity.Balcony,
  Amenity.Garden,
];

export const COMMERCIAL_AMENITIES = [
  Amenity.Doorman,
  Amenity.Storage,
  Amenity.AirConditioner,
  Amenity.Elevator,
  Amenity.OuterSpace,
  Amenity.SecureRoom,
  Amenity.ParkingEmployee,
  Amenity.ParkingBikes,
  Amenity.ParkingVisitors,
  Amenity.Accessible,
  Amenity.Kitchenette,
  Amenity.FullTimeAccess,
  Amenity.Alarm,
  Amenity.ConferenceRoom,
  Amenity.SubDivisible,
  Amenity.Reception,
  Amenity.ColdRoom,
  Amenity.LoadingRamp,
  Amenity.HighCeiling,
  Amenity.ActiveBusiness,
];

export const DESCRIPTION_MAX_LENGTH = 800;
