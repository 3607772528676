import { createSelector } from 'reselect';
import { routeNameSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';
import { addressDocEntrySelector, addressDocIdSelector, unitPageBulletinSelector } from 'store/state/domainData/selectors';
import { CompletionType, IAddressDetails, IAutocompleteEntry, ResolutionPreference } from 'utils/entities';
import { validTabuByDocIdSelector, tabuByDocIdLoadingSelector, tabuByDocIdHasErrorsSelector } from 'store/state/domainData/selectors/tabuByDocId';
import { tabuDictionarySelector } from 'store/state/app/selectors';
import { TabuState } from './Tabu';
import { tabuSubmittedSelector } from 'store/state/insightsContext/selectors';


export const docIdForTabuSelector = createSelector([
  routeNameSelector,
  addressDocIdSelector,
  unitPageBulletinSelector,
], (routeName, addressDocId, bulletin) => {
  switch (routeName) {
    case Route.AddressPage:
      return addressDocId;
    case Route.UnitPage:
      return bulletin ? bulletin.addressDetails.docId : null;
    default:
      return null;
  }
});

export interface AddressDetailsMeta {
  city: string;
  docId: string;
  streetName: string;
  streetNumber: string;
  isAccurate: boolean;
}

function getAddressDetailsMetaFromAutoComplete(data: IAutocompleteEntry): AddressDetailsMeta {
  const result = {
    docId: data.docId,
    city: data.city,
    // on address page we dont have resolutionPreferences so it always accurate
    isAccurate: true,
  };

  switch (data.type) {
    case CompletionType.Address:
      return {
        ...result,
        streetName: data.street,
        streetNumber: data.houseNumber,
      };
    case CompletionType.Street:
      return {
        ...result,
        streetName: data.street,
        streetNumber: null,
      };
    default:
      return {
        ...result,
        streetName: null,
        streetNumber: null,
      };
  }
}

function getAddressDetailsMetaFromBulletin(data: IAddressDetails): AddressDetailsMeta {
  const {
    city,
    docId,
    streetName,
    streetNumber,
    resolutionPreferences,
  } = data;

  const isAccurate = resolutionPreferences ? resolutionPreferences === ResolutionPreference.Accurate : false;

  return {
    city,
    docId,
    streetName,
    streetNumber,
    isAccurate,
  };
}

export const tabuAddressDetailsSelector = createSelector([
  routeNameSelector,
  unitPageBulletinSelector,
  addressDocEntrySelector,
], (routeName, bulletin, addressDetails): AddressDetailsMeta => {
  switch (routeName) {
    case Route.AddressPage: {
      if (!addressDetails) return null;
      return getAddressDetailsMetaFromAutoComplete(addressDetails);
    }
    case Route.UnitPage: {
      if (!bulletin) return null;
      return getAddressDetailsMetaFromBulletin(bulletin.addressDetails);
    }
    default:
      return null;
  }
});

export const tabuDocIdSelector = createSelector([
  tabuAddressDetailsSelector,
], (addressDetails): string => addressDetails ? addressDetails.docId : null);


export const tabuStateSelector = createSelector([
  validTabuByDocIdSelector,
  tabuByDocIdLoadingSelector,
  tabuSubmittedSelector,
  tabuByDocIdHasErrorsSelector,
  tabuDictionarySelector,
  tabuDocIdSelector,
], (tabu, loading, isTabuSubmitted, hasErrors, dictionary, docId): TabuState => {
  const {
    isSent,
  } = dictionary[docId] || {};

  if (isSent) {
    return TabuState.Sent;
  }

  if (isTabuSubmitted) {
    if (loading) {
      return TabuState.Loading;
    }
    if (hasErrors) {
      return TabuState.Fail;
    }
    return Boolean(tabu) ? TabuState.Exist : TabuState.Empty;
  }
  return TabuState.Initial;
});
