import baseStyled from '@emotion/styled';
import theme from './theme/default';
import { Interpolation, ComponentSelector } from '@emotion/styled-base';
import { PropsOf } from '@emotion/styled-base/types/helper';


interface StyledComponent<
  ComponentProps extends {},
  SpecificComponentProps extends {} = {}
  > extends React.FC<ComponentProps & SpecificComponentProps>, ComponentSelector {
  withComponent<C extends React.ComponentType<React.ComponentProps<C>>>(
    component: C
  ): StyledComponent<ComponentProps & PropsOf<C>>;
  withComponent<Tag extends keyof JSX.IntrinsicElements>(
    tag: Tag
  ): StyledComponent<ComponentProps, JSX.IntrinsicElements[ Tag ]>;
}


interface CreateStyledComponent<
  ComponentProps extends {},
  SpecificComponentProps extends {} = {},
  StyleProps extends {} = {}
  > {
  /**
   * @typeparam AdditionalProps  Additional props to add to your styled component
   */
  <AdditionalProps extends {} = {}>(
    ...styles: Array<
      Interpolation<
        ComponentProps & SpecificComponentProps & StyleProps & AdditionalProps
      >
    >
  ): StyledComponent<ComponentProps & AdditionalProps, SpecificComponentProps>;
  /**
   * @typeparam AdditionalProps  Additional props to add to your styled component
   */
  <AdditionalProps extends {} = {}>(
    template: TemplateStringsArray,
    ...styles: Array<
      Interpolation<ComponentProps & SpecificComponentProps & AdditionalProps>
    >
  ): StyledComponent<ComponentProps & AdditionalProps, SpecificComponentProps>;
}


interface StyledOptions {
  label?: string;
  shouldForwardProp?(propName: string): boolean;
  target?: string;
}

interface CreateStyledA<T extends {} = any> {
  <C extends React.ComponentType<React.ComponentProps<C>>>(
    component: C,
    options?: StyledOptions
  ): CreateStyledComponent<PropsOf<C> & { theme?: T }, {}, { theme: T }>;

  <Tag extends keyof JSX.IntrinsicElements>(
    tag: Tag,
    options?: StyledOptions
  ): CreateStyledComponent<
    { theme?: T },
    JSX.IntrinsicElements[ Tag ],
    { theme: T }
  >;
}

type CreateStyled<T extends {} = any> = CreateStyledA<T> & {
  [ Tag in keyof JSX.IntrinsicElements ]: CreateStyledComponent<
    { theme?: T },
    JSX.IntrinsicElements[ Tag ],
    { theme: T }
  >
};

export type Theme = typeof theme;

export const styled: CreateStyled<Theme> = baseStyled as any;
