import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';

export const SearchStringWrapper = styled(SmallText)`
  padding: 0 ${({ theme }) => theme.spacing(1)};
`;

export const SearchStringValue = styled(SmallText)`
  display: inline;
`;

export const AddressSuggestion = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => `
    margin-bottom: ${theme.spacing(1)};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    background: ${isActive ? theme.colors.neutrals.grey10 : theme.colors.neutrals.white};
    color: ${theme.colors.neutrals.grey1};
    border-radius: ${theme.borderRadius.tiny};
    svg {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
      min-width: 24px;
    }
    span {
      color: ${theme.colors.brand.main};
    }
  `}
  &:last-of-type {
    margin: 0;
  }
  height: 40px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const IconSuggestion = styled.div`
  display: inline-block;
  position: relative;
`;

export const AddressSuggestionText = styled(Text)`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export const CompletionTypeText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};
    padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(.5)};
  `}
  text-transform: capitalize;
  display: inline;
  &::before {
    content: '· ';
  }
`;
