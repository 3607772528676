import styled from '@emotion/styled';
import { Link } from 'components/link';


const LEFT_DIRECTION_ARROW_STYLES: string = `
  margin-right: 4px;
  transform: rotate(180deg) translateY(-2px);
`;

const RIGHT_DIRECTION_ARROW_STYLES: string = `
  margin-left: 4px;
`;

export interface IStyledLink {
  mode?: 'primary' | 'grey'; // primary is default
  withArrow?: boolean;
  simple?: boolean;
  small?: boolean;
}

export const LinkStyled = styled.a<IStyledLink>`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${({ mode = 'primary', withArrow, theme, small }) => `
    height: ${small ? 20 : 24}px;

    ${mode === 'primary' ? `
      color: ${theme.colors.brand.main};
      > svg {
        fill: ${theme.colors.brand.main};
      }
      &:hover {
        color: ${theme.colors.brand.dark};
        > svg {
          fill: ${theme.colors.brand.dark};
        }
      }
      &:focus {
        color: ${theme.colors.brand.darker}
      }
    ` : ''};

    ${mode === 'grey' ? `
      color: ${theme.colors.neutrals.grey2};
      > svg {
        fill: ${theme.colors.neutrals.grey2};
      }
      &:hover {
        color: ${theme.colors.neutrals.grey1};
        > svg {
          fill: ${theme.colors.neutrals.grey1};
        }
      }
      &:focus {
        color: ${theme.colors.neutrals.grey2};
      }
    ` : ''};

    ${withArrow ? `
      > svg:last-of-type {
        ${theme.isRTL ? LEFT_DIRECTION_ARROW_STYLES : RIGHT_DIRECTION_ARROW_STYLES}
      }
      ` : ''};
    `}
`;

export const ZoneLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brand.main};
  &:hover {
    color: ${({ theme }) => theme.colors.brand.dark};
    > svg {
      fill: ${({ theme }) => theme.colors.brand.dark};
    }
  }
  &:focus {
    color: ${({ theme }) => theme.colors.brand.darker}
  }
  > svg:last-of-type {
    fill: ${({ theme }) => theme.colors.brand.main};
    ${({ theme }) => theme.isRTL ? LEFT_DIRECTION_ARROW_STYLES : RIGHT_DIRECTION_ARROW_STYLES}
  }
`;

export const LinkStyledSimple = LinkStyled.withComponent('div');
