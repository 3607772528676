import config from 'config';
import { InfiniteStorage, overwriteMaps, sendEvent } from '@madlan145/client-toolbox';

const isDevelopmentMode = config.mode === 'development';

interface AbTestsContextType {
  context: { [index: string]: any };
  options: { [index: string]: string[] };
  useLocalCookie: boolean;
}

let browserInjectContext: AbTestsContextType;

function getSSRInjectedContext() {
  return (window as any).__LOCALIZE_SSR_CONFIG__.abTests;
}

const emptyContext = {
  context: {},
  options: {},
  useLocalCookie: true,
};

/*
 * Function loads params like &ab.test_1=some_value_1&ab.test_2=some_value_2
 * and injects them into window property.
 */
function createContextFromUrlParams() {
  const queryParams: any = new URLSearchParams(window.location.search);
  const context: any = {};
  for (const [ key, value ] of queryParams.entries()) {
    if (key.startsWith('ab.')) {
      context[key.replace('ab.', '')] = value;
    }
  }

  (window as any).__LOCALIZE_SSR_CONFIG__ = (window as any).__LOCALIZE_SSR_CONFIG__ || {};
  (window as any).__LOCALIZE_SSR_CONFIG__.abTests = { context };
}

export function getABTestsContext() {
  if (browserInjectContext) return browserInjectContext;

  if (isDevelopmentMode) {
    createContextFromUrlParams();
  }

  const currentContext = getSSRInjectedContext();
  let newContext;
  if (currentContext.useLocalCookie) {
    newContext = {
      context: { ...currentContext.context },
    };

    const previousContext = InfiniteStorage.get('ab_tests_context_v2') || emptyContext;

    overwriteMaps(newContext.context, previousContext.context, currentContext.options);
  }
  else {
    newContext = currentContext;
    delete newContext.useLocalCookie;
  }

  InfiniteStorage.set('ab_tests_context_v2', newContext);
  browserInjectContext = newContext;
  browserInjectContext.context = new (window as any).Proxy(browserInjectContext.context, {
    get(target: any, property: any) {
      const value = target[property];
      if (target.hasOwnProperty(property)) {
        sendEvent(property, 'ab_test', {
          label: value,
        });
      }
      return value;
    },
  });

  return newContext;
}

