import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { RootAction, State } from 'store/state';
import { bulletinToFormValues } from 'utils/uploadBulletin';
import { IEditBulletinInput } from 'utils/entities';
import { editListingSelector, isEditListingPoiLoadingSelector } from 'store/state/domainData/selectors/editListing';
import { userLoadingSelector } from 'store/state/domainData/selectors';
import { EditBulletinPage } from './EditBulletinPage';
import { editListingAction } from 'store/state/uploadBulletin/actions';
import {
  editCommercialListingResponseSelector,
  editListingResponseSelector,
} from 'store/state/mutationsResponse/selectors';

export const initialValuesSelector = createSelector([ editListingSelector ], poi => poi ? bulletinToFormValues(poi) : null);
const isLoadingSelector = createSelector([
  userLoadingSelector,
  isEditListingPoiLoadingSelector,
], (isUserLoading: boolean, isPoiLoading: boolean) => isUserLoading || isPoiLoading);

const submittingEditSelector = createSelector([
  editListingResponseSelector,
  editCommercialListingResponseSelector,
], (residentialResponse, commercialResponse) =>
    residentialResponse && residentialResponse.loading || commercialResponse && commercialResponse.loading
);

const mapStateToProps = (state: State) => ({
  initialValues: initialValuesSelector(state),
  isLoading: isLoadingSelector(state),
  submitting: submittingEditSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  submitForm: (listing: IEditBulletinInput) => dispatch(editListingAction({ listing })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBulletinPage);
