import React from 'react';
import styled from '@emotion/styled';
import { IThumbnail } from 'utils/entities';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';

const VERTICAL_WIDTH = 124;
const VERTICAL_HEIGHT = 140;
const HORIZONTAL_WIDTH = 192;
const HORIZONTAL_HEIGHT = 104;
const IMG_RATIO = 2;


type layoutType = 'horizontal' | 'vertical';

const ImageWrapper = styled.div<{ layout?: layoutType; }>`
  ${({ theme, layout }) => `
    border-radius: ${theme.borderRadius.tiny};
    border: 1px solid ${theme.colors.neutrals.grey8};
    overflow: hidden;

    ${layout === 'vertical' ? `
      width: ${VERTICAL_WIDTH}px;
      min-width: ${VERTICAL_WIDTH}px;
      height: ${VERTICAL_HEIGHT}px;
      min-height: ${VERTICAL_HEIGHT}px;
    ` : ''}

    ${layout === 'horizontal' ? `
      width: ${HORIZONTAL_WIDTH}px;
      min-width: ${HORIZONTAL_WIDTH}px;
      height: ${HORIZONTAL_HEIGHT}px;
      min-height: ${HORIZONTAL_HEIGHT}px;
    ` : ''}
  `}
`;


interface PropertyImageProps {
  layout?: layoutType;
  thumbnails: IThumbnail[];
  altText?: string;
}

const PropertyImage: React.FC<PropertyImageProps> = (props) => {
  const {
    layout = 'vertical',
    thumbnails,
    altText,
  } = props;

  const constructImageURI = useImageUrlBuilder();

  const imgSize = layout === 'horizontal' ? {
    height: HORIZONTAL_HEIGHT,
    width: HORIZONTAL_WIDTH,
  } : {
    height: VERTICAL_HEIGHT,
    width: VERTICAL_WIDTH,
  };

  const imgSrcSize = {
    width: imgSize.width * IMG_RATIO,
    height: imgSize.height * IMG_RATIO,
  };
  const placeholderUrl = 'placeholder_images2/placeholder_v2.png';
  const hasThumbnails = Boolean(thumbnails && thumbnails.length);
  const imgUrl = hasThumbnails && thumbnails[0].url || placeholderUrl;

  const imgSrc = constructImageURI(imgUrl, imgSrcSize);
  const alt = altText || hasThumbnails && thumbnails[0].description || '';

  return (
    <ImageWrapper layout={layout}>
      <img
        src={imgSrc}
        alt={alt}
        height={imgSize.height}
        width={imgSize.width}
        data-auto={hasThumbnails ? 'universal-card-image' : 'universal-card-image-placeholder'}
      />
    </ImageWrapper>
  );
};

export default PropertyImage;
