import { combineReducers } from 'redux';
import { reviewWizardReducer, ReviewWizardState } from './reviewWizard';
import { feedbackModalReducer, FeedbackModalState } from './feedbackModal';
import { UGCAction } from './actionType';


export interface UGCState {
  reviewWizard: ReviewWizardState;
  feedbackModal: FeedbackModalState;
}

export default combineReducers<UGCState, UGCAction>({
  reviewWizard: reviewWizardReducer,
  feedbackModal: feedbackModalReducer,
});
