import { ISOString } from 'locale/formatDate';
import { ResolutionPreference, DealAffiliationStatus, DealAffiliationType } from 'utils/entities';

export enum AffiliationPopupMode {
  New = 'new',
  Edit = 'edit',
  View = 'view',
}

export interface IAffiliationPopupDeal {
  soldId: string;
  dealDate: ISOString;
  price: number;
  structuredAddress: {
    city: string;
    streetName: string;
    streetNumber: string;
    neighbourhood: string;
    resolutionPreferences?: ResolutionPreference;
  };
  rooms: number;
  constructionYear: number;
  area: string;
  floor: number;
}

export interface IDealAffiliationPopupState {
  isOpen: boolean;
  mode: AffiliationPopupMode;
  deal: IAffiliationPopupDeal;
  forceDealSide?: DealAffiliationType;
  affiliationRequest?: {
    agentId?: string;
    agentName?: string;
    receiptAttached?: boolean;
    receiptUrls?: string[];
    affiliationStatus?: DealAffiliationStatus;
    affiliationType?: DealAffiliationType;
    affiliationRequestId?: string;
  };
}

export interface IDealAffiliationFormValues {
  soldId: string;
  agentId: string;
  dealSide: DealAffiliationType;
  dealReceiptLinks: string[];
}

export type IDealAffiliationUserRequestsToStatus = Record<DealAffiliationType, { status?: DealAffiliationStatus; side?: DealAffiliationType }>;
