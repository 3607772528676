import { BreakpointValue } from 'consts/breakpoints';
import styled from '@emotion/styled';

export const MAX_GALLERY_HEIGHT = 384;

export const rangeToGalleryHeight: Record<BreakpointValue, number> = {
  1: 224,
  2: 384,
  3: 344,
  4: 344,
  5: MAX_GALLERY_HEIGHT,
};


export const HeightAwareSliderItemContainer = styled.div<{isFloorPlan?: boolean}>`
  ${({ theme }) => Object.keys(rangeToGalleryHeight).map(key => (
    theme.media.showAt({ at: (+key as BreakpointValue) })`
      height: ${rangeToGalleryHeight[key]}px;
    `
  ))};
  position: relative;
  flex-grow: 1;
  & > div {
    border-radius: ${props => props.theme.borderRadius.tiny};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${props => props.theme.colors.neutrals.white};
    transform-origin: top left;
    background-size: ${({ isFloorPlan }) => isFloorPlan ? 'contain' : 'cover'};
  }
`;

export const GalleryWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme }) => Object.keys(rangeToGalleryHeight).map(key => (
    theme.media.showAt({ at: (+key as BreakpointValue) })`
      height: ${rangeToGalleryHeight[key]}px;
    `
  ))}
  & > span {
    transition: all .2s ease;
    opacity: 0;
  }
  &:hover {
    & > span {
      opacity: 1;
    }
  }
`;

export const ControlsLeftWrapper = styled.div`
  position: absolute;
  top: 24px;
  z-index: 2;
  right: 24px;
  display: flex;
`;

export const FavoriteButtonWrapper = styled.div`
  cursor: pointer;
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: ${({ theme }) => theme.spacing(2)};
  svg {
    background: hsla(0,0%,0%,0.4);
    border-radius: ${({ theme }) => theme.borderRadius.round};
    padding: ${({ theme }) => theme.spacing(0.5)};
    width: 32px;
    height: 32px;
  }
`;


export const CounterWrapper = styled.span`
  z-index: 2;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  & > button {
    pointer-events: none;
  }
`;

export const FloorplanButtonWrapper = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 24px;
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: 24px;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    ${theme.isRTL ? 'left' : 'right'}: 16px;
    bottom: 16px;
  `}
`;

export const ThumbnailsWrapperInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  direction: ltr;
  & > div > div > div > div {
    border-radius: 0;
  }
`;

export const PagerWrapper = styled.div`
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  direction: ltr;
`;

type Direction = 'next' | 'prev';

export interface ControlsArrowProps {
  dir: Direction;
  onClick(): void;
  disabled?: boolean;
}

export const ControlsArrowWrapper = styled.span<ControlsArrowProps>`
  cursor: pointer;
  height: 44px;
  transition: opacity .2s ease;
  ${({ disabled }) => disabled ? 'pointer-events: none' : ''};
  svg {
    opacity: ${({ disabled = false }) => disabled ? '.' : ''}1;
    transform: scaleX(${({ dir }) => dir === 'next' ? '-' : ''}1);
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ dir }) => dir === 'next' ? 'right' : 'left' }: 20px;
`;

export const MapViewButtonWrapper = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 8px;
  padding: 16px;
  ${({ theme }) => theme.isRTL ? 'right' : 'left'}: 8px;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    ${theme.isRTL ? 'right' : 'left'}: 0px;
    bottom: 0px;
  `}
`;

export const MapViewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.neutrals.grey7};
  z-index: 1;
`;

interface IPlaceholderImage {
  imageUrl: string;
}

export const PlaceholderImage = styled.div<IPlaceholderImage>`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const OpenMapIconWrapper = styled.div`
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(1)};
  top: ${({ theme }) => theme.spacing(1)};
`;

export const TargetMarkerWrapper = styled.div`
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    margin-top: -36px;
    height: 72px;
    width: 72px;
  }
`;
