import { getContext } from 'redux-saga/effects';

import { State as RouteState } from 'config/routes';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { ReactNativeClientTunnel, ROUTE_NAME } from 'react-native/reactNativeClientTunnel';


export function* reactNativeRouteNotifyMiddleware(route: RouteState, previousRoute: RouteState, meta: NavigationOptions['middlewareMeta']) {
  const reactNativeClientTunnel: ReactNativeClientTunnel = yield getContext('reactNativeClientTunnel');
  reactNativeClientTunnel.push({ type: ROUTE_NAME, payload: route.name });
  return meta;
}
