import { createStandardAction } from 'typesafe-actions';
import {
  SAVE_USER_REVIEW,
  SET_ACTIVE_REVIEW_RESULTS_PAGE,
  SET_EMOTION, SET_FEEBACK_MODAL,
  ASK_SUBMIT_FEEDBACK,
  OPEN_REVIEW_WIZARD,
  NEXT_STEP,
  PREVIOUS_STEP,
  SET_USER_NAME_INPUT,
  SET_TEXT_REVIEW_INPUT,
  SET_RATING,
  SET_RATING_FROM_PREVIEW,
  INITIALIZE_RATINGS,
  ASK_REVIEW_WIZARD_FOR_ADDRESS,
  CLOSE_REVIEW_WIZARD,
  RESET_WIZARD,
} from './types';
import {
  SetEmotionPayload,
  FeedbackModalPayload,
  AskSubmitFeedbackPayload,
  OpenReviewWizardPayload,
  SetRatingPayload,
  AskReviewWizardForAddressPayload,
} from './payloads';


export const saveUserReview = createStandardAction(SAVE_USER_REVIEW)();
export const setEmotion = createStandardAction(SET_EMOTION)<SetEmotionPayload>();
export const setActiveReviewResultPage = createStandardAction(SET_ACTIVE_REVIEW_RESULTS_PAGE)();

// FEEDBACK MODAL
export const setFeedbackModal = createStandardAction(SET_FEEBACK_MODAL)<FeedbackModalPayload>();
export const askSubmitFeedback = createStandardAction(ASK_SUBMIT_FEEDBACK)<AskSubmitFeedbackPayload>();

// REVIEW WIZARD
export const askReviewWizardForAddress = createStandardAction(ASK_REVIEW_WIZARD_FOR_ADDRESS)<AskReviewWizardForAddressPayload>();
export const closeReviewWizard = createStandardAction(CLOSE_REVIEW_WIZARD)();
export const openReviewWizard = createStandardAction(OPEN_REVIEW_WIZARD)<OpenReviewWizardPayload>();
export const goToNextStep = createStandardAction(NEXT_STEP)();
export const goToPreviousStep = createStandardAction(PREVIOUS_STEP)();
export const setTextReviewInput = createStandardAction(SET_TEXT_REVIEW_INPUT)<string>();
export const setUserNameInput = createStandardAction(SET_USER_NAME_INPUT)<string>();
export const setRating = createStandardAction(SET_RATING)<SetRatingPayload>();
export const setRatingFromPreview = createStandardAction(SET_RATING_FROM_PREVIEW)<SetRatingPayload>();
export const initializeRatings = createStandardAction(INITIALIZE_RATINGS)<SetRatingPayload[]>();
export const resetReviewWizard = createStandardAction(RESET_WIZARD)();
