import { flow, get } from 'lodash';
import { createSelector } from 'reselect';
import { tabuByDocIdDomainSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { dialogFromURLSelector, DialogWithURLType } from 'store/state/selectors/router';


const tabuByDocIdSelector = makeNullSafeDomainGetter(tabuByDocIdDomainSelector, 'docId2NesachTabu');
export const validTabuByDocIdSelector = createSelector([
  tabuByDocIdSelector,
  dialogFromURLSelector,
], (tabu, dialog) => {
  if (tabu && tabu.downloadToken) return tabu;

  if (dialog && dialog.type === DialogWithURLType.TabuWizard && get(dialog, [ 'meta', 'downloadToken' ])) return dialog.meta;

  return null;
});
export const tabuByDocIdLoadingSelector = createIsLoadingSelector(tabuByDocIdDomainSelector);
export const tabuByDocIdHasErrorsSelector = createSelector([ tabuByDocIdDomainSelector, tabuByDocIdSelector ], ({ errors }, tabu) => Boolean(errors && errors.length) || tabu === null);
export const tabuByDocIdVariablesSelector = flow(tabuByDocIdDomainSelector, ({ meta }) => meta && meta.variables ? meta.variables : null);
