import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';


export const Root = styled.div``;

export const Header = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const Body = styled.div<{ last: boolean }>`
  ${({ theme, last }) => `
    margin-bottom: ${last ? 0 : theme.spacing(4.5)}
  `}
`;

export const Item = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)}
  `}
`;

