import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';


export const Wrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-end;
    width: 100%;
    direction: ltr;
    margin-top: ${theme.spacing(3)};
  `}
`;

export const TextFieldWrapper = styled.div`
  ${({ theme }) => `
    flex-grow: 1;
    width: 142px;
    position: relative;
    input {
      height: 62px;
      border: 1px solid ${theme.colors.neutrals.grey6};
      padding-bottom: ${theme.spacing(1)};
      text-align: right;
      padding-top: 30px; /* custom padding for this input*/
      &:hover {
        border: 1px solid ${theme.colors.brand.main};
      }
      &:focus {
        border: 1px solid ${theme.colors.brand.main};
      }
    }
  `}
`;

export const TextFieldLabel = styled(SmallText)`
  ${({ theme }) => `
    z-index: 1;
    position: absolute;
    top: 10px;
    margin: 0;
    right: 12px;
    text-transform: lowercase;
    color: ${theme.colors.neutrals.grey2};
  `}
`;

export const Separator = styled(Text)`
  ${({ theme }) => `
    &:after {
      content: '-';
    }
    flex-shrink: 0;
    color: ${theme.colors.neutrals.grey4};
    margin: auto ${theme.spacing(1)};
  `}
`;



export const TextCells = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


export const PriceCell = styled.div<{ toRight? : boolean }>`
  ${({ toRight }) => `
    text-align: ${toRight ? 'right' : 'left'};
  `}
`;

export const Label = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
    margin-bottom: ${theme.spacing(0.25)};
  `}
`;
