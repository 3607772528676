import React from 'react';
import { useLocale } from 'locale';
import { SubheaderBasic } from './components/SubheaderBasic';
import { TextLabel } from 'ds/components/typography';
import styled from '@emotion/styled';

const ModifiedTextLabel = styled(TextLabel)`
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: ${({ theme }) => theme.spacing(4)};
`;

export const ExpandUGCReviewsSubheader: React.FC = React.memo(() => {
  const { t } = useLocale();
  return (
    <SubheaderBasic
      title={<ModifiedTextLabel caps>{t('ugc.expand.subheader.title')}</ModifiedTextLabel>}
    />
  );
});
