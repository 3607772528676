import React from 'react';
import { Link as BaseLink } from 'react-router5';
import { DecodedRouteParams, NavigateToFn, Route, State as RouteState } from 'config/routes';
import { BaseLinkProps } from 'react-router5/types/BaseLink';
import { Omit } from 'utility-types';
import { noop } from 'lodash';


export interface LinkProps extends Omit<BaseLinkProps, 'router'> {
  routeName: Route;
  routeParams?: Partial<DecodedRouteParams>;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  route: RouteState;
  preserveParams?: boolean;
  hideLinkParams?: boolean;
  navigate: NavigateToFn;
}

const ROUTE_WITH_HIDDEN_PARAMS = new Set([
  Route.UnitPage,
  Route.CommercialMarketLanding,
  Route.UnitPageCommercial,
  Route.SearchCommercial,
  Route.Search,
  Route.CheckAddress,
  Route.DevelopersSearchPage,
  Route.MadadPage,
]);

export const DumbLink: React.FC<LinkProps> = ({
  preserveParams,
  route,
  routeName,
  routeParams = {},
  children,
  onClick = noop,
  hideLinkParams,
  navigate,
  ...rest
}) => {
  const nextParams: Partial<DecodedRouteParams> = preserveParams
    ? {
      ...route.params,
      insightId: undefined,
      insightCollection: undefined,
      ...routeParams,
    }
    : routeParams;

  if (route.name === Route.Search && route.params.dealType && nextParams.dealType && !nextParams.tracking_search_source) {
    nextParams.tracking_search_source = 'type_change';
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClick(e);
    navigate(routeName, nextParams);

    return false;
  };

  const htmlRouteParamsByRouteName = React.useMemo(() => {
    if (!hideLinkParams) return nextParams;


    switch (routeName) {
      case Route.Search:
      case Route.SearchCommercial:
        return {
          dealType: nextParams.dealType,
          term: nextParams.term,
        };
      case Route.UnitPage:
      case Route.UnitPageCommercial:
        return {
          id: nextParams.id,
        };
      case Route.CommercialMarketLanding:
      case Route.CheckAddress:
      case Route.DevelopersSearchPage:
      case Route.MadadPage:
        return {};
      default:
        return nextParams;
    }
  }, [ hideLinkParams, nextParams, routeName ]);

  const clickHandlerResolver = ROUTE_WITH_HIDDEN_PARAMS.has(routeName) && hideLinkParams ? handleClick : onClick;

  return (
    <BaseLink
      {...rest}
      routeName={routeName}
      routeParams={htmlRouteParamsByRouteName}
      onClick={clickHandlerResolver}
    >
      {children}
    </BaseLink>
  );
};
