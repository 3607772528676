import { connect } from 'react-redux';
import { State, RootAction } from 'store/state';
import { Dispatch } from 'redux';
import HiddenListingDirectLinkPopup from './UndislayedDirectLinkPopup';
import { hiddenListingModalSelector, marketplaceSelector } from 'store/state/selectors/router';
import { DecodedRouteParams, NavigateToFn, Route } from 'config/routes';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { navigateTo } from 'store/state/router/actions';
import { MarketplaceType } from 'utils/entities';

export interface HiddenListingModalProps {
  hiddenListingModalOpen: boolean;
  onClose: NavigateToFn;
  marketplace: MarketplaceType;
}

const mapStateToProps = (state: State) => ({
  hiddenListingModalOpen: hiddenListingModalSelector(state),
  marketplace: marketplaceSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: (name: Route, params?: DecodedRouteParams, opts?: NavigationOptions) => dispatch(navigateTo(name, params, opts)),
});

export const HiddenListingModal = connect(mapStateToProps, mapDispatchToProps)(HiddenListingDirectLinkPopup);
