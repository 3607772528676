import React from 'react';
import { Wrapper } from './styled';
import { LabelDynamicContent } from '../styled';

export const NuisancesDynamicContent: React.FC<{ label?: string }> = ({ label }) => {

  if (!label) return null;

  return (
    <Wrapper>
      <LabelDynamicContent weight="bold">{label}</LabelDynamicContent>
    </Wrapper>
  );
};
