import React from 'react';
import styled from '@emotion/styled';
import BetaLogo from 'assets/svg/beta-badge.svg';

const BetaBadgeWrapper = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  top: 0;
  z-index: 13;
  direction: ltr;
  pointer-events: none;
  ${({ theme }) => theme.isRTL ? 'right: 0;' : 'left: 0; transform: rotate(270deg);' }
`;

const BetaBadge: React.FC = () => (
  <BetaBadgeWrapper>
    <BetaLogo />
  </BetaBadgeWrapper>
);

export default BetaBadge;
