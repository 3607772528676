import {
  ResolutionPreference,
  DealType,
  IPoiIdentifier,
  IBulletin,
  IProject,
} from 'utils/entities';


export type PoiGroupedByResolutionPreference = Partial<Record<DealType, Record<ResolutionPreference, IPoiIdentifier[]>>>;

const createPoiAccumulator = (): PoiGroupedByResolutionPreference => ({
  [DealType.Rent]: {
    [ResolutionPreference.Accurate]: [],
    [ResolutionPreference.Street]: [],
    [ResolutionPreference.Neighborhood]: [],
    [ResolutionPreference.City]: [],
  },
  [DealType.Buy]: {
    [ResolutionPreference.Accurate]: [],
    [ResolutionPreference.Street]: [],
    [ResolutionPreference.Neighborhood]: [],
    [ResolutionPreference.City]: [],
  },
});


const poiGroupFn = (acc: PoiGroupedByResolutionPreference, poi: PoiWithSearchFields): PoiGroupedByResolutionPreference => {
  /**
   * @todo fix grouping once projects have dealType field
   */
  const dealType = poi.type === 'bulletin' ? poi.dealType : DealType.Buy;

  // US doesn't ensure addressDetails and has no resolutionPreferences
  const isAccurate = !poi.addressDetails || !poi.addressDetails.resolutionPreferences
    || poi.addressDetails.resolutionPreferences === ResolutionPreference.Accurate;
  acc[dealType][isAccurate ? ResolutionPreference.Accurate : poi.addressDetails.resolutionPreferences].push({
    id: poi.id,
    type: poi.type,
  });

  return acc;
};


export type PoiWithSearchFields = IBulletin | IProject;

export function groupPoiByResolutionPreference(list: PoiWithSearchFields[]) {
  return list.reduce<PoiGroupedByResolutionPreference>(poiGroupFn, createPoiAccumulator());
}
