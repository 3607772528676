import styled from '@emotion/styled';
import { PROFILE_ITEM_SIZE_DEFAULT, PROFILE_ITEM_SIZE_LARGE } from 'components/inner-contact-form/const';
import { SmallText, Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';


export const Wrapper = styled.div<{ isModal?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  padding: 0 ${({ theme, isModal }) => isModal ? theme.spacing(5) : theme.spacing(3)};
`;

export const Main = styled.div`
  display: flex;
`;

export const LogoSection = styled.div`
  display: flex;
`;

export const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled(SmallText)<{ withRedesignedForm: boolean, isModal?: boolean }>`
  display: ${({ isModal }) => isModal ? 'block' : 'flex'};
  > span {
    color: ${({ theme }) => theme.colors.neutrals.grey1};
    &:last-child {
      color: ${({ theme }) => theme.colors.neutrals.grey3};
      ${({ theme }) => theme.media.showAt({ from: 2 }) `
        &:before {
          content: '\\00B7';
          color: ${theme.colors.neutrals.grey1};
          margin: 0 ${theme.spacing(0.5)};
        }
      `}
    }
  }
  ${({ withRedesignedForm }) => withRedesignedForm ? `
    > div {
      font-weight: bold;
    }
  ` : ''}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column;
  `}
`;


export const ProfileItem = styled.div<{ isLarge?: boolean, withRedesignedForm: boolean }>`
  ${({ theme, isLarge, withRedesignedForm }) => `
    display: flex;
    margin-left: ${theme.spacing(1.5)};

    > svg {
      width: ${isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT};
      height: ${isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT};
    }
    ${withRedesignedForm ? `
      > svg {
        width: ${PROFILE_ITEM_SIZE_DEFAULT};
        height: ${PROFILE_ITEM_SIZE_DEFAULT};
      }
    ` : ''}
  `}
`;

export const ProfilePhoto = styled.div<{ imageURL: string; shape?: 'circle'; isLarge?: boolean; withRedesignedForm: boolean }>`
  ${({ theme, imageURL, shape, isLarge, withRedesignedForm }) => `
    width: ${isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT};
    height: ${isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT};
    overflow: hidden;
    ${shape === 'circle' ? `
      border-radius: ${theme.borderRadius.round};
      border: 1px solid ${theme.colors.neutrals.grey6};
    ` : ''}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: ${theme.colors.neutrals.grey6};
    ${imageURL ? `background-image: url(${imageURL});` : ''}
    ${withRedesignedForm ? `
      width: ${PROFILE_ITEM_SIZE_DEFAULT};
      height: ${PROFILE_ITEM_SIZE_DEFAULT};
    ` : ''}
  `}
`;

export const LinkTitle = styled(Text)<{ withRedesignedForm: boolean, withRedesignSellerInfo: boolean }>`
  color: ${({ theme, withRedesignSellerInfo }) => withRedesignSellerInfo ? theme.colors.brand.main : theme.colors.neutrals.grey1};
  ${({ withRedesignedForm }) => withRedesignedForm ? `
    font-weight: bold;
    font-size: ${typographyFontSizeCfg.smallText}px;
    line-height: ${typographyLineHeightCfg.smallText}px;
  ` : ''}
`;
