import styled from '@emotion/styled';
import { InsightType } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';
import { Text, TextLabel } from './typography';

export const HEADER_HEIGHT = 164; // prev: 180

export const ListingCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
`;

export interface IListingCardsItem {
  onlyOneImageShouldBeDisplayed?: boolean;
  clickable?: boolean;
}

export const ListingCardsItem = styled.div<IListingCardsItem>`
  flex-grow: 0;
  flex-basis: calc(50% - 8px);
  margin: 0 4px 16px;
  min-width: 0;
  ${({ clickable }) => clickable ? 'cursor: pointer;' : ''}
  ${props => props.theme.media.showAt({ at: 1 }) `flex-basis: 100%`}
  ${props => props.onlyOneImageShouldBeDisplayed && props.theme.media.showAt({ at : 1 }) `
    flex-basis: initial;
    width: 280px;
  `};
  & > a {
    text-decoration: inherit;
    color: inherit;
  }
`;

export const ListingCardWrapper = styled.div<{ isActive?: boolean, isMinHeight?: boolean }>`
  position: relative;
  padding: ${props => props.theme.spacing(1)};
  padding-bottom: ${props => props.theme.spacing(3)};
  min-height: ${(({ isMinHeight }) => isMinHeight ? '340px' : 'initial')};
  background: ${props => props.theme.colors.neutrals.white};
  border-radius: ${props => props.theme.borderRadius.tiny};
  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: false }) `
    .universal-card-hover-show {
      display: none;
    }
  `}

  .universal-card-pager-hover {
    display: none;
  }

  ${props => props.isActive ? `
    box-shadow: ${props.theme.shadow.level1};
  ` : ''}

  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: false })`
    padding-bottom: ${theme.spacing(2)};

    &:hover {
      box-shadow: ${theme.shadow.level1};

      .universal-card-pager-hover {
        display: flex;
      }

      .universal-card-hover-show {
        display: flex;
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 1, isTouch: true }) `
    padding-bottom: ${theme.spacing(2)};

    .universal-card-pager-hover {
      display: flex;
    }
  `}
`;

interface RelevancyLabel {
  isNewOffer?: boolean;
  isPromoted?: boolean;
  isDark?: boolean;
}

export const RelevancyLabelWrapper = styled(TextLabel)<RelevancyLabel>`
  background-color: ${({ theme, isNewOffer, isDark }) => isNewOffer ? theme.colors.label.yellow : isDark ? 'rgba(0,0,0,0.5)' : theme.colors.neutrals.grey9};
  border-radius: ${props => props.theme.borderRadius.tiny};
  padding: 3px 4px 1px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  ${({ isDark }) => isDark ? 'height: 20px;' : ''};
  color: ${({ theme, isNewOffer, isDark }) => isNewOffer ? theme.colors.neutrals.grey1 : isDark ? theme.colors.neutrals.white : theme.colors.neutrals.grey2};
`;

export const LabelsContainer = styled.div<{ isCommercial: boolean }>`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 64px);
  top: ${({ theme, isCommercial }) => theme.spacing(isCommercial ? 1 : 1.5)};
  ${props => props.theme.isRTL ? 'right' : 'left'}: ${({ theme, isCommercial }) => theme.spacing(isCommercial ? 1 : 1.5)};
  > * {
    margin-${props => props.theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
  z-index: 1;
`;

export const PromotedProjectLabel = styled(RelevancyLabelWrapper) `
  padding: 2px 4px 1px;
  color: ${props => props.theme.colors.neutrals.white};
  background-color: ${props => props.theme.colors.neutrals.grey1};
`;

export const FavoriteWrapper = styled.div<{ isCommercial: boolean }>`
  position: absolute;
  ${({ theme, isCommercial }) => `${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(isCommercial ? 1 : 1.5)}`};
  top: ${({ theme, isCommercial }) => theme.spacing(isCommercial ? 1 : 1.5)};
`;

export const AbsoluteFill = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const THREE_BY_TWO = 'padding-top: 66.666%;';

export const ThumbnailsWrapper = styled.div<{ height?: number; }>`
  position: relative;
  flex-grow: 1;
  ${({ theme }) => theme.media.showAt({ from: 1, to: 2 })`${THREE_BY_TWO}`} // keeps 3:2 aspect ratio
  ${({ theme }) => theme.media.showAt({ from: 3 })`height: ${HEADER_HEIGHT}px;`}
  ${({ theme, height }) => height ? theme.media.showAt({ from: 1 })`
    height: ${height}px;
    padding-top: 0;
  ` : ''}
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius.tiny};
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.neutrals.grey8};
  justify-content: center;
`;

export const ThumbnailLogo = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  display: flex;
  bottom: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(1)};
  transition: opacity .2s ease;
  opacity: ${(props) => props.isHovered ? 1 : 0};
  border: 2px solid ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.micro};
  box-shadow: 0 0 1px 0 ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.1)};
`;

export const ThumbnailsBox = styled.div<{withHover: boolean; withLogo: boolean}>`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export const ThumbnailsWrapperInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .view-pager-rail,
  .view-pager-bow {
    height: 100%;
  }
`;

export const CarouselControlsWrapper = styled.div``;

export const CarouselControls = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1.5)};
  height: 13px;
  left: ${({ theme }) => theme.spacing(1.5)};
  right: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ltr;
  z-index: 9;
  ${({ theme }) => theme.media.showAt({ from: 1, to: 2 })`
    z-index: auto;
  `}
`;

export interface ICarouselControlsArrow {
  type: 'prev' | 'next';
  disabled?: boolean;
  size?: 'medium' | 'large';
}

export const CarouselControlsArrow = styled.div<ICarouselControlsArrow>`
  position: absolute;
  top: 50%;
  ${({ theme, type }) => `${type === 'next' ? 'right' : 'left'}: ${theme.spacing(1.5)}`};
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme, size, type, disabled }) => `
  ${size === 'large' ? `` : `
    width: 15px;
    height: 30px;
  `}
    & svg {
      max-width: 100%;
      ${size === 'large' ? '' : `
        path {
          fill: ${theme.colors.neutrals.white};
        }
      `}
      ${type === 'next' ? '' : 'transform: scale(-1, 1);'}
      ${disabled ? 'opacity: 0.2;' : ''}
      transition: all .2s ease;
    }
  `}

  ${props => props.disabled ? `
    pointer-events: none;
  ` : ''}
`;

export const Body = styled.div<{ withPaddingBottom?: boolean }>`
  position: relative;
  ${({ withPaddingBottom, theme }) =>
    withPaddingBottom
    && !theme.media.showAt({ from: 1, isTouch: true }) `padding-bottom: ${theme.spacing(0.5)};`}
`;

export const PropertyDetailsWrapper = styled.div`
  &:empty {
    display: none;
  }
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  min-height: 20px;
  display: block;
`;

interface IPropertyDetailsText {
  faded?: boolean;
  isPromoted?: boolean;
}

export const PropertyDetailsText = styled(Text)<IPropertyDetailsText>`
  display: inline-block;
  text-transform: capitalize;
  color: ${props => props.isPromoted
    ? props.theme.colors.brand.darker
    : (props.faded ? props.theme.colors.neutrals.grey3 : props.theme.colors.neutrals.grey1)
  };
`;

export const PropertyDetailsSeparator = styled.span<IPropertyDetailsText>`
  width: 3px;
  height: 3px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  margin: 0 ${({ theme }) => theme.spacing(0.5)};
  position: relative;
  top: -2px;
  background: ${props => props.isPromoted
  ? props.theme.colors.brand.darker
  : props.faded ? props.theme.colors.neutrals.grey3 : props.theme.colors.neutrals.grey1
  };
  display: inline-block;
`;

export const NewPropertyDetailsSeparator = styled(PropertyDetailsSeparator)`
  &&& {
    width: 2px;
    height: 2px;
    top: -3px;
    background: ${props => props.theme.colors.neutrals.grey1};
  }
`;

export const TagsWrapper = styled.div`
  margin-top: ${({ theme }) => `-${theme.spacing(0.5)}`};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(0.5)};
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
`;

interface ITag {
  type?: InsightType;
}

export const Tag = styled.div<ITag>`
  border-radius: ${props => props.theme.borderRadius.tiny};
  height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 4px 6px;
  text-transform: uppercase;
  font-size: ${props => props.theme.isRTL ? '11px' : '10px'};
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.5px;
  background: ${props => colorToAlpha(props.theme.colors.insights[props.type], 0.12)};
  color: ${props => props.theme.colors.insights[props.type]};
  svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    ${props => props.theme.isRTL ? 'margin-left: 4px' : 'margin-right: 4px'};
    path {
      fill: ${props => props.theme.colors.insights[props.type]};
    }
  }
`;

interface ISliderItemContainer {
  shouldRotate?: boolean;
  height?: number;
}

export const SliderItemContainer = styled.div<ISliderItemContainer>`
  ${({ theme, shouldRotate }) => `
    height: 100%;
    position: relative;
    flex-grow: 1;
    img {
      user-select: none;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
      ${shouldRotate ? 'transform: rotateZ(90deg) translateY(-100%);' : ''};
      background-color: ${theme.colors.neutrals.white};
      transform-origin: top left;
      border-radius: ${theme.borderRadius.tiny};
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    & > div {
      border-radius: ${theme.borderRadius.tiny};
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${theme.colors.neutrals.white};
      transform-origin: top left;
      ${shouldRotate ? 'transform: rotateZ(90deg) translateY(-100%);' : ''};
      background-size: cover;
    }
  `}
`;

export const PlaceholderWrapper = styled.div<{ disableAnimation?: boolean }>`
  background: ${props => props.theme.colors.neutrals.white};
  margin: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(0.5)} 0;
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1)} 0;
  position: relative;
  overflow: hidden;
  & > div {
    margin-bottom: 8px;
    border-radius: ${props => props.theme.borderRadius.tiny};
  }
  & > div:nth-of-type(2), & > div:nth-of-type(5) {
    width: 90px;
    height: 20px;
  }
  & > div:nth-of-type(3), & > div:nth-of-type(4) {
    width: 225px;
    height: 20px;
  }
  & > div:nth-of-type(1) {
    width: 100%;
    ${({ theme }) => theme.media.showAt({ from: 1, to: 2 })`${THREE_BY_TWO}`};
    ${({ theme }) => theme.media.showAt({ from: 3 })`height: ${HEADER_HEIGHT}px;`}
  }
  ${({ theme, disableAnimation }) => `
    & > div:nth-of-type(1), & > div:nth-of-type(2) {
      background: ${theme.colors.neutrals.grey9};
    }
    & > div:nth-of-type(n + 3) {
      background: ${theme.colors.neutrals.grey10};
    }

    &:after {
      content: '';
      height: 600px;
      width: 15px;
      position: absolute;
      top: -150px;
      left: -100px;
      background: ${theme.colors.neutrals.white};
      filter: blur(10px);
      ${disableAnimation ? '' : 'animation: highlight 1.5s ease infinite;'}
      ${theme.isRTL ? `
        animation-direction: reverse;
      ` : ''}
    }

    @keyframes highlight {
      from {
        transform: translateX(-150px) rotate(${theme.isRTL ? '-' : ''}24deg);
      }
      to {
        transform: translateX(450px) rotate(${theme.isRTL ? '-' : ''}24deg);
      }
    }
  `}
`;
