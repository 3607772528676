import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const Wrapper = styled.div`
  text-align: center;
`;

export const InfoBox = styled.div`
  ${({ theme }) => `
    background-color: ${colorToAlpha(theme.colors.brand.lighter, 0.05)};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    color: ${theme.colors.brand.darker};
  `}
`;

export const CloseModal = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const PhoneNumber = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.brand.light};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: ${theme.spacing(1.5)};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin: ${theme.spacing(1)};
  `}
`;

export const CodeWrapper = styled.div`
  direction: ltr;
`;

export const ResendCodeWrapper = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};
    margin-top: ${theme.spacing(3)};
  `}
`;

export const ResendCode = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.brand.lighter};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const BlockedError = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.alert.red};
    max-width: 338px;
    margin-bottom: ${theme.spacing(0.5)};
  `}
`;

export const BlockedContact = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};

    a, a:hover, a: active {
      color: ${theme.colors.neutrals.grey4};
      text-decoration: underline;
    }
  `}
`;
