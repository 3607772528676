import {
  makeByIdsDictionarySelector,
  makeNullSafeDomainGetter,
  projectMapLogoDomainSelector,
} from 'store/state/domainData/selectors/common';


export const projectMapLogoSelector = makeNullSafeDomainGetter(projectMapLogoDomainSelector, 'data');

export const projectMapLogoDictionarySelector = makeByIdsDictionarySelector(projectMapLogoSelector);
