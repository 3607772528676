import React from 'react';
import { useLocale } from 'locale';
import { Label, Text } from './styled';
import LiveTourIcon from 'assets/svg/special-label/live-tour.svg';
import VideoIcon from 'assets/svg/special-label/video.svg';
import PopularIcon from 'assets/svg/special-label/popular.svg';
import PriceDropIcon from 'assets/svg/special-label/pricedrop.svg';

export enum SpecialLabelType {
  LiveTour = 'LiveTour',
  Video = 'Video',
  Popular = 'Popular',
  PriceDrop = 'PriceDrop',
}

const iconsMap = {
  [SpecialLabelType.LiveTour]: LiveTourIcon,
  [SpecialLabelType.Video]: VideoIcon,
  [SpecialLabelType.Popular]: PopularIcon,
  [SpecialLabelType.PriceDrop]: PriceDropIcon,
};

interface SpecialLabelProps {
  type: SpecialLabelType;
  dropValue?: string;
}

const SpecialLabel: React.FC<SpecialLabelProps> = ({ type, dropValue }) => {
  const { t } = useLocale();
  const Icon = iconsMap[type];
  return (
    <Label className="special-label">
      <Icon />
      <Text weight="bold" small caps>{t('card.specialLabel', { type, dropValue })}</Text>
    </Label>
  );
};

export default SpecialLabel;
