import styled from '@emotion/styled';
import { SpacingScale } from 'ds/theme/spacing';
import { isNil } from 'lodash';

interface SpacingBlockProps {
  mTop?: SpacingScale;
  mBottom?: SpacingScale;
  mLeft?: SpacingScale;
  mRight?: SpacingScale;
  withRTL?: boolean;
}

export const SpacingBlock = styled.div<SpacingBlockProps>`
  ${({ theme, mTop, mBottom, mLeft, mRight, withRTL }) => `
    ${!isNil(mTop) ? `margin-top: ${theme.spacing(mTop)};` : ''}
    ${!isNil(mBottom) ? `margin-bottom: ${theme.spacing(mBottom)};` : ''}
    ${!isNil(mLeft) ? `
      margin-${withRTL && theme.isRTL ? 'right' : 'left'}: ${theme.spacing(mLeft)};
    ` : ''}
    ${!isNil(mRight) ? `
      margin-${withRTL && theme.isRTL ? 'left' : 'right'}: ${theme.spacing(mRight)};
    ` : ''}
  `};
`;
