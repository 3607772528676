import LocationIcon from 'assets/svg/location-gallery.svg';
import GalleryIcon from 'assets/svg/gallery.svg';
import { IThumbnail, PoiId, PoiType } from 'utils/entities';
import { ShowAt } from 'consts/breakpoints';
import React from 'react';
import { CleanButton } from 'ds/components/button';
import Share from 'components/navigation/share';
import {
  CounterWrapper,
  FavoriteButtonWrapper,
  MapViewButtonWrapper,
  ControlsLeftWrapper,
} from './styled';
import FavoritesToggler from 'components/favorites/FavoritesToggler';


interface GalleryControlsProps {
  handleOpenFullscreen(isMobile: boolean): () => void;

  images: IThumbnail[];
  activeSlide: number;

  toggleMapView(): void;

  isMapViewActive: boolean;
  id: PoiId;
  type: PoiType;
  enableShare: boolean;
}

export const GalleryControls: React.FunctionComponent<GalleryControlsProps> = ({
  handleOpenFullscreen,
  images,
  activeSlide,
  isMapViewActive,
  toggleMapView,
  id,
  type,
  enableShare,
}) => (
  <>
    <ShowAt from={3}>
      <ControlsLeftWrapper>
        {enableShare ? (
          <Share />
        ) : null}
        {id ? (
          <FavoriteButtonWrapper>
            <FavoritesToggler id={id} type={type} />
          </FavoriteButtonWrapper>
        ) : null}
      </ControlsLeftWrapper>
    </ShowAt>
    <ShowAt to={2}>
      <MapViewButtonWrapper>
        <CleanButton mode="secondaryNegative" onlyIcon onClick={toggleMapView}>
          {isMapViewActive ? <GalleryIcon width={24} height={24} /> : <LocationIcon width={24} height={24} />}
        </CleanButton>
      </MapViewButtonWrapper>
    </ShowAt>
    {images.length ? (
      <ShowAt from={3}>
        <CounterWrapper onClick={handleOpenFullscreen(false)}>
          <CleanButton mode="secondaryNegative">
            {activeSlide + 1}/{images.length}
          </CleanButton>
        </CounterWrapper>
      </ShowAt>
    ) : null}
  </>
);
