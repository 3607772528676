import { get } from 'lodash';
import { Platform } from 'react-native/config';
import { isServer } from 'utils';

export function getPlatformName(): Platform | null {
  if (!isServer()) {
    const platform = get(window, [ '__MADLAN_MOBILE_CONFIG__', 'platform' ]);
    return platform;
  }
  return null;
}

export function isAndroidPlatform(platform: string) {
  return platform === 'android';
}

export function isIOSPlatform(platform: string) {
  return platform === 'ios';
}

export function insideReactNative() {
  if (!isServer()) {
    const platform = get(window, [ '__MADLAN_MOBILE_CONFIG__', 'platform' ]);
    return isAndroidPlatform(platform) || isIOSPlatform(platform);
  }
  return false;
}
