import { HistoryState, BrowserPluginOptions } from 'router5-plugin-browser/types/types';


const supportsPopStateOnHashChange = () => window.navigator.userAgent.indexOf('Trident') === -1;

const safelyEncodePath = (path: string) => {
  try {
    return encodeURI(decodeURI(path));
  }
  catch (e) {
    return path;
  }
};

export default {
  pushState(state: HistoryState, title: string, path: string) {
    window.history.pushState(state, title, path);
  },

  replaceState(state: HistoryState, title: string, path: string) {
    window.history.replaceState(state, title, path);
  },

  addPopstateListener(fn: (ev: HashChangeEvent) => void, opts: BrowserPluginOptions) {
    const shouldAddHashChangeListener = opts.useHash && !supportsPopStateOnHashChange();
    window.addEventListener('popstate', fn);

    if (shouldAddHashChangeListener) {
      window.addEventListener('hashchange', fn);
    }

    return () => {
      window.removeEventListener('popstate', fn);
      if (shouldAddHashChangeListener) {
        window.removeEventListener('hashchange', fn);
      }
    };
  },

  getLocation(opts: BrowserPluginOptions) {
    const path = opts.useHash
      ? window.location.hash.replace(new RegExp('^#' + opts.hashPrefix), '')
      : window.location.pathname.replace(new RegExp('^' + opts.base), '');

    // Fix issue with browsers that don't URL encode characters (Edge)
    const correctedPath = safelyEncodePath(path);

    return (correctedPath || '/') + window.location.search;
  },

  getState() {
    return window.history.state;
  },

  getHash() {
    return window.location.hash;
  },

  getBase() {
    return window.location.pathname;
  },
};

