import { createSelector } from 'reselect';
import { flow } from 'lodash';

import { makeByIdsDictionarySelector, addressSearchSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { PoiWithSearchFields, groupPoiByResolutionPreference } from 'utils/resolutionPreferenceGroup';
import { State } from 'store/state/';


export const addressSearchListSelector = makeNullSafeDomainGetter(addressSearchSelector, 'searchPoiV2');
export const isAddressSearchLoadingSelector = createIsLoadingSelector(addressSearchSelector);

const EMPTY: PoiWithSearchFields[] = [];

const searchByAdressListSelector = flow(addressSearchListSelector, (search) => search && search.poi ? search.poi : EMPTY);
export const addressSearchPoiDictionarySelector = makeByIdsDictionarySelector(searchByAdressListSelector);

type DocIdSelector = (state: State) => string;

export const makeSearchByAddressPoiListSelector = (docIdSelector: DocIdSelector) => createSelector([
  searchByAdressListSelector,
  docIdSelector,
], (list, docId) => (
  list.filter(poi => poi.addressDetails.docId === docId)
));

export const makePoiByDealTypeSelector = (docIdSelector: DocIdSelector) => createSelector([
  makeSearchByAddressPoiListSelector(docIdSelector),
], groupPoiByResolutionPreference);
