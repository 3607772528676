import React from 'react';
import { PoiId, PoiType } from 'utils/entities';
import { useDisplayPhone } from 'hooks/useDisplayPhone';

interface DisplayPhoneProps {
  id: PoiId;
  type: PoiType;
  children: (phone: string) => React.ReactNode;
}

const DisplayPhone: React.FC<DisplayPhoneProps> = ({ id, type, children }) => {
  const displayPhone = useDisplayPhone({ id, type });
  return (
    displayPhone ? (
      <>{children(displayPhone)}</>
    ) : null
  );
};

export default DisplayPhone;
