import { takeLatest, call, select, put, fork } from 'redux-saga/effects';
import { SET_PRICES_LAST_EXPANDED_ROW_SALE_ID } from 'store/state/insightsContext/types';
import { setPricesLastExpandedRow } from 'store/state/insightsContext/actions';
import { queryData } from 'store/sagas/apiService';
import { LoadType, MutationType } from 'store/sagas/apiService/types';
import { isAgentManagerSelector, isAgentSelector } from 'store/state/domainData/selectors';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { resetDomainData } from 'store/state/domainData/actions';
import { SET_DEAL_AFFILIATION_POPUP } from 'store/state/app/types';
import { setDealAffiliationPopup } from 'store/state/app/actions';

function* fetchDealAffiliationRequestsBySaleId(action: ReturnType<typeof setPricesLastExpandedRow>) {
  const isAgent: boolean = yield select(isAgentSelector);
  const isAgentManager: boolean = yield select(isAgentManagerSelector);

  if (action.payload && (isAgent || isAgentManager)) {
    yield call(queryData, {
      loadType: LoadType.GetDealAffiliationRequestsBySaleId,
      meta: { variables: { saleId: action.payload } },
    });
  }
}

const requestDealAffiliationMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.RequestDealAffiliation
);

const editRequestDealAffiliationMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditDealAffiliationRequest
);

function* refetchDealAffiliationRequestsBySaleId(soldId: string) {
  yield put(resetDomainData({ loadType: LoadType.GetDealAffiliationRequestsBySaleId }));
  yield put(setPricesLastExpandedRow(soldId));
}

function* requestDealAffiliationWatcher(action: SetMutationResponseAction<MutationType.RequestDealAffiliation>) {
  const { response } = action.meta;
  if (response && response.data && (response.data.requestDealAffiliation && response.data.requestDealAffiliation.soldId )) {
    yield fork(refetchDealAffiliationRequestsBySaleId, response.data.requestDealAffiliation.soldId);
  }
}
function* editRequestDealAffiliationWatcher(action: SetMutationResponseAction<MutationType.EditDealAffiliationRequest>) {
  const { response } = action.meta;
  if (response && response.data && (response.data.publicEditDealAffiliationRequest && response.data.publicEditDealAffiliationRequest.soldId )) {
    yield fork(refetchDealAffiliationRequestsBySaleId, response.data.publicEditDealAffiliationRequest.soldId);
  }
}

function* dealOpenAffiliationPopupWatcher(action: ReturnType<typeof setDealAffiliationPopup>) {
  yield put(setPricesLastExpandedRow(action.payload.deal.soldId));
}

const dealOpenAffiliationPopupPattern = (action: RootAction) => (
  action.type === SET_DEAL_AFFILIATION_POPUP && !!action.payload
);


export function* pricesWatcher() {
  yield takeLatest(SET_PRICES_LAST_EXPANDED_ROW_SALE_ID, fetchDealAffiliationRequestsBySaleId);
  yield takeLatest(requestDealAffiliationMutationPattern, requestDealAffiliationWatcher);
  yield takeLatest(editRequestDealAffiliationMutationPattern, editRequestDealAffiliationWatcher);
  yield takeLatest(dealOpenAffiliationPopupPattern, dealOpenAffiliationPopupWatcher);
}
