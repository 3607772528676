import { TrackJS } from 'trackjs';
import config from 'config';
import { IEnhancer, Fetcher } from '.';

function logError(url: string, init: RequestInit) {
  try {
    const parsed = JSON.parse(init.body.toString());
    TrackJS.track(`Graphql error, url: ${url}, operation ${parsed.operationName}, variables: ${JSON.stringify(parsed.variables || {})}`);
  }
  catch (e) {
    TrackJS.track(`Can not parse ${e.message}`);
  }
}

export class ErrorDetailsEnhancer implements IEnhancer {

  public enhance = async (url: string, init: RequestInit, innerFetch: Fetcher) => {
    try {
      const result = await innerFetch(url, init);
      return result;
    }
    catch (e) {
      if (url === config.apiEndpoint || url === config.newApiEndpoint) {
        logError(url, init);
      }
      throw e;
    }
  }
}
