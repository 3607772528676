import React, { useState, useEffect } from 'react';
import {
  CommutePopupWrapper,
  CommutePreferenceFormSubmit,
} from './styled';
import { useLocale } from 'locale';
import { CommuteType, CommutePreference, User } from 'utils/entities';
import { Button } from 'ds/components/button';
import Modal from 'ds/components/modal';
import { GenericCommutePreferenceForm, PreferredAddress } from './GenericCommutePreferenceForm';
import { useTheme } from 'hooks/useTheme';
import { IUpdateUserProfileVariables } from 'store/sagas/apiService/types';

interface CommutePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateUserProfile: (data: IUpdateUserProfileVariables, key: string) => void;
  user: User;
}

export const CommutePopup: React.FC<CommutePopupProps> = ({
  onClose,
  isOpen,
  onUpdateUserProfile,
  user,
}) => {
  const { t } = useLocale();
  const theme = useTheme();

  const [ commuteType, setCommuteType ] = useState<CommuteType>('commute');
  const [ address, setAddress ] = useState<PreferredAddress>(null);
  const [ rushHour, setRushHour ] = useState<boolean>(false);
  const [ searchStr, setSearchStr ] = useState<string>('');

  const initialPreference: CommutePreference = (user && user.commutePreference && user.commutePreference.commuteType)
    ? user.commutePreference
    : null;

  useEffect(() => {
    if (initialPreference) {
      setRushHour(initialPreference.rushHour);
      setAddress({
        text: initialPreference.text,
        location: initialPreference.location,
        docId: initialPreference.docId,
      });
      setCommuteType(initialPreference.commuteType);
      setSearchStr(initialPreference.text);
    }
    else {
      setRushHour(false);
      setAddress(null);
      setCommuteType('commute');
      setSearchStr('');
    }
  }, [ isOpen ]);

  const handleSubmit = () => {
    const commutePreference: CommutePreference = {
      commuteType,
      rushHour,
      location: {
        lat: address.location.lat,
        lng: address.location.lng,
      },
      maxCommute: null,
      docId: address.docId,
      text: address.text,
    };
    onUpdateUserProfile({
      commutePreference,
    }, 'commutePopup');
  };

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('commutePopup.title')}
      buttons={
        <CommutePreferenceFormSubmit>
          <Button
            type="submit"
            fullWidth
            disabled={!address || !address.location}
            size="large"
            onClick={handleSubmit}
            data-auto="filterSubmitButton"
          >
            {t('commutePopup.submitButtonLabel')}
          </Button>
        </CommutePreferenceFormSubmit>
      }
    >
      <CommutePopupWrapper>
        <GenericCommutePreferenceForm
          searchStr={searchStr}
          commuteType={commuteType}
          rushHour={rushHour}
          accentColor={theme.colors.insights.transportation}
          initialPreference={initialPreference}
          onSetSearchStr={setSearchStr}
          onSetCommuteType={setCommuteType}
          onSetRushHour={setRushHour}
          onAddressSelect={setAddress}
        />
      </CommutePopupWrapper>
    </Modal>
  ) : null;
};
