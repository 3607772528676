import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const MapContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: ${theme.borderRadius.small};
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
  `}
`;

export const FindMistake = styled(Text)`
  ${({ theme }) => `
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${theme.colors.brand.main};
    text-decoration: underline;
    svg {
      margin-left: ${theme.spacing(1)};
      path {
        fill: ${theme.colors.brand.main};
      }
    }
  `}
`;

export const FieldsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const InputParcelWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    width: 48%;
    max-width: initial;
  `}
`;

export const ExpandLabel = styled(SmallText)<{isOpen?: boolean}>`
  ${({ theme, isOpen }) => `
    display: inline-block;
    position: relative;
    color: ${theme.colors.neutrals.grey2};
    padding-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};

    &::before {
      position: absolute;
      top: 2px;
      ${theme.isRTL ? 'left' : 'right'}: 0;
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-left: 2px solid ${theme.colors.neutrals.grey2};
      border-bottom: 2px solid ${theme.colors.neutrals.grey2};

      ${isOpen ? `
        top: 7px;
        transform: rotate(135deg);
      ` : `
        top: 2px;
        transform: rotate(-45deg);
      `}
    }
  `}
`;

export const AutocompleteWrapper = styled.div<{ error: boolean }>`
  ${({ theme, error }) => `
    ${error ? `
      .autocomplete-textfield-wrapper .textfield-input {
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      }
    ` : ''}
  `}
`;

export const ResolutionPrefTip = styled.div`
  ${({ theme }) => `
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.05)};
    border-radius: ${theme.borderRadius.small};
    display: flex;
    align-items: center;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: 0 ${theme.spacing(2.5)};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding: 0 ${theme.spacing(4)};
  `}
`;

export const ResolutionPrefTipLabel = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding: ${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(3)} 0;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: ${theme.spacing(2.5)} ${theme.spacing(3)} ${theme.spacing(2.5)};
  `}
`;

export const ResolutionPrefTitle = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
  `}
`;

export const ResolutionPrefOptions = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)} 0 ${theme.spacing(4)};
  `};

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    display: flex;
    max-width: 570px;
    justify-content: space-between;
  `}
`;

export const ResolutionPrefWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(4)} 0 ${theme.spacing(5)};
    border-top: 1px solid ${theme.colors.neutrals.grey8};
    border-bottom: 1px solid ${theme.colors.neutrals.grey8};
    margin-top: ${theme.spacing(5)};
  `}
`;
