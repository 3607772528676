import { LocalStorage } from 'utils/localStorage';
import { LS_UPLOAD_BULLETIN_KEY } from './UploadBulletinPage';
import { UploadBulletinStep, DraftState } from 'screens/UploadBulletinPage/types';
import { PropertyType } from 'components/filters/types';
import { PocType } from 'utils/entities';

export const hideBuildingDetails = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.ParkingLot,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

interface UploadBulletinDraft {
  clear(): void;
  get(): DraftState;
  set(val: DraftState): void;
}

export const uploadBulletinDraft: UploadBulletinDraft = {
  clear: () => LocalStorage.rm(LS_UPLOAD_BULLETIN_KEY),
  set: (val) => LocalStorage.set(LS_UPLOAD_BULLETIN_KEY, val),
  get: () => LocalStorage.get(LS_UPLOAD_BULLETIN_KEY),
};

interface UploadUserRolesProps {
  isAgent: boolean;
  isBulletinsAdmin: boolean;
  isConnectedUser: boolean;
  isAgentManager: boolean;
}

export const genUploadSteps = (isEdit: boolean, isCommercial: boolean, sellerType: PocType, userRolesProps: UploadUserRolesProps): UploadBulletinStep[] => {
  const { isAgent, isAgentManager, isConnectedUser, isBulletinsAdmin } = userRolesProps;

  let steps;
  switch (true) {
    case isBulletinsAdmin:
      steps = [
        (isEdit ? null : UploadBulletinStep.BulletinType),
        UploadBulletinStep.DealType,
        UploadBulletinStep.Address,
        UploadBulletinStep.MainInfo,
        UploadBulletinStep.AdditionalInfo,
        UploadBulletinStep.UploadImage,
        ((isCommercial && (sellerType === PocType.Agent)) ? null : UploadBulletinStep.Contacts),
        UploadBulletinStep.Preview,
      ];
      break;
    case isAgent || isAgentManager:
      steps = [
        (isEdit ? null : UploadBulletinStep.BulletinType),
        (isCommercial ? null : UploadBulletinStep.AgentResidential),
        UploadBulletinStep.DealType,
        UploadBulletinStep.Address,
        UploadBulletinStep.MainInfo,
        UploadBulletinStep.AdditionalInfo,
        UploadBulletinStep.UploadImage,
        (isAgentManager ? UploadBulletinStep.SelectAgent : null),
        UploadBulletinStep.Preview,
      ];
      break;
    case isConnectedUser:
      steps = [
        (isEdit ? null : UploadBulletinStep.BulletinType),
        UploadBulletinStep.DealType,
        UploadBulletinStep.Address,
        UploadBulletinStep.MainInfo,
        UploadBulletinStep.AdditionalInfo,
        UploadBulletinStep.UploadImage,
        UploadBulletinStep.Contacts,
        UploadBulletinStep.Preview,
      ];
      break;
    default:
      steps = [
        UploadBulletinStep.Authentication,
        UploadBulletinStep.DealType,
        UploadBulletinStep.Address,
        UploadBulletinStep.MainInfo,
        UploadBulletinStep.AdditionalInfo,
        UploadBulletinStep.UploadImage,
        UploadBulletinStep.Contacts,
        UploadBulletinStep.Preview,
      ];
  }
  return steps.filter(Boolean);
};
