import React from 'react';
import { connect } from 'react-redux';
import ListingsCarousel from 'components/listing-post-lead-carousel';
import { ContactAgentModalSource } from 'store/state/app';
import { State } from 'store/state';
import { hasPostLeadListingsSelector } from 'store/state/domainData/selectors/listingsPostLeadCarousel';
import DoneIcon from 'assets/svg/done.svg';
import { Text } from 'ds/components/typography';
import { useLocale } from 'locale';

const PostLeadListingsCarousel: React.FC<{ hasListings: boolean; }> = ({ hasListings }) => {
  const { t } = useLocale();

  if (!hasListings) {
    return (
      <>
        <DoneIcon width={62} height={62} />
        <Text>{t('contactForm.contact.form.withMortgage.succeedMessage.modeB')}</Text>
      </>
    );
  }
  return <ListingsCarousel source={ContactAgentModalSource.PostLead} />;
};

const mapStateToProps = (state: State) => ({
  hasListings: hasPostLeadListingsSelector(state),
});

export default connect(mapStateToProps)(PostLeadListingsCarousel);
