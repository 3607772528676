import {
  createIsLoadingSelector,
  makeNullSafeDomainGetter,
  searchDevelopersIndexSelector, searchDocSelector,
} from 'store/state/domainData/selectors/common';
import { createSelector } from 'reselect';
import { searchDocEntriesSelector } from 'store/state/domainData/selectors/searchDoc';

export const developersSearchSelector = makeNullSafeDomainGetter(searchDevelopersIndexSelector, 'searchDevelopersIndex', true);
export const developersSearchTotalCountSelector = createSelector(developersSearchSelector, data => data ? data.totalCount : null);
export const developersSearchDevelopersSelector = createSelector(developersSearchSelector, data => data ? data.developers : null);
export const docIdEntitySelector = createSelector(searchDocEntriesSelector, (data) => data && data[0]);
export const developersCityNameSelector = createSelector(docIdEntitySelector, document => document ? document.name : null);

export const isDeveloperSearchLoadingSelector = createIsLoadingSelector(searchDevelopersIndexSelector);
export const isSearchDocLoadingSelector = createIsLoadingSelector(searchDocSelector);

