import { values } from 'lodash';
import { DeveloperFiltersState } from 'store/state/app';
import { DealType, DeveloperProjectMarketplace, ProjectBuildingStage } from 'utils/entities';
import { ITranslate } from 'locale';
import { Item as CheckboxItem } from 'ds/components/input/CheckboxGroup';

export const countFilters = (filters: DeveloperFiltersState): number =>
  values(filters).map(value => Array.isArray(value) ? !!value.length : null).filter(Boolean).length;

export const genDealTypeOptions = (items: DealType[], t: ITranslate): Array<CheckboxItem<DealType>> =>
  items.map(value => ({
    value,
    label: t('developerPage.filters.dealType.options', { value }),
  }));

export const genMarketTypeOptions = (items: DeveloperProjectMarketplace[], t: ITranslate): Array<CheckboxItem<DeveloperProjectMarketplace>> =>
  items.map(value => ({
    value,
    label: t('developerPage.filters.marketType.options', { value }),
  }));

export const genBuildingStageOptions = (items: ProjectBuildingStage[], t: ITranslate): Array<CheckboxItem<ProjectBuildingStage>> =>
  items.map(value => ({
    value,
    label: t('developerPage.filters.buildingStage.options', { value }),
  }));
