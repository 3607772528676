import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { Button } from 'ds/components/button';
import { colorToAlpha } from 'helpers/insight';


export const Root = styled.div`
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.small};
    border: solid 1px ${theme.colors.neutrals.grey8};
    box-shadow: ${theme.shadow.level9};
    margin-bottom: ${theme.spacing(7)};
    overflow: hidden;
  `}
`;

export const ModalRoot = styled.div`
  min-width: 425px;
`;

export const Header = styled.div<{ isMobile: boolean; isProject?: boolean }>`
  ${({ theme, isMobile, isProject }) => `
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(isProject && isMobile ? 3 : 4)} ${theme.spacing(3)};
    ${isMobile ? `
      flex-direction: column;
      padding-bottom: ${theme.spacing(isProject ? 1 : 2)};
    ` : `
      align-items: center;
      border-bottom: solid 1px ${theme.colors.neutrals.grey8};
      > a {
        margin-right: ${theme.spacing(2)};
      }
    `}
  `}
`;

export const Main = styled.div<{ isMobile?: boolean; isProject?: boolean; }>`
  ${({ theme, isMobile, isProject }) => `
    padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(isMobile && isProject ? 4 : 3)};
  `}
`;

export const PhoneButton = styled(Button.withComponent('a'))`
  text-decoration: none;
  display: flex;
  flex-grow: 0;
  flex-basis: 185px;
  white-space: nowrap;
`;

export const FormTitle = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const FieldsContainer = styled.div<{ isMobile: boolean; largeInput: boolean; noTopMargin?: boolean; isProject?: boolean; isDesktop?: boolean }>`
  ${({ theme, isMobile, largeInput, noTopMargin, isProject, isDesktop }) => `
    margin: ${theme.spacing(isMobile || (isProject && isDesktop) ? 1 : 4)} 0 ${theme.spacing(isMobile && isProject ? 4 : 3)};
    display: flex;
    flex-grow: 1;
    ${largeInput ? `
      input {
        height: 48px;
      }
    ` : ``}
    ${isMobile ? `
      margin-top: ${theme.spacing(2)};
      flex-direction: column;
      > div {
        margin-bottom: ${theme.spacing(2)};
        :last-of-type {
          margin-bottom: 0;
        }
      }
    ` : ``}
    ${isMobile && isProject ? `
      > div:nth-last-of-type(3) {
        margin-bottom: ${theme.spacing(1.5)};
      }
    ` : ``}
    ${noTopMargin ? 'margin-top: 0;' : ''}
  `}
`;

export const Row = styled.div`
  ${({ theme }) => `
    margin: 0 -${theme.spacing(1)};
    display: flex;
    flex-grow: 1;
  `}
`;

export const Column = styled.div`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    padding: 0 ${theme.spacing(1)};
  `}
`;

export const SentContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.brand.main};
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.05)};
    svg {
      margin-left: ${theme.spacing(2)};
    }
  `}
`;

export const ModalFooter = styled.div<{ isProject?: boolean; isDesktop?: boolean }>`
  ${({ theme, isProject, isDesktop }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px ${theme.colors.neutrals.grey8};
    padding: ${theme.spacing(2.5)} ${theme.spacing(4)} 0;
    margin: ${theme.spacing(isProject && isDesktop ? 1 : 1.5)} -${theme.spacing(4)} -${theme.spacing(1.5)};
  `}
`;
