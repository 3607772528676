import React, { useContext } from 'react';
import { routeContext } from 'react-router5';
import { useLocale } from 'locale';
import { CardSeparator, ContactButton, FavoritesMobileButtonWrapper, Root, ShareWrapper, CardInner } from './styled';
import FavoritesToggler from 'components/favorites/FavoritesTogglerNew';
import { PoiId, PoiType } from 'utils/entities';
import HoverCard from './hover-card';
import { ContactAgentModalSource, ContactModalOpenMeta } from 'store/state/app';
import { ShareButton, ShareButtonSource } from 'components/navigation/share/ShareButton';
import { cardShareUrl, handleCardShare, ShareType } from 'helpers/share-helpers';
import { BasicButton } from 'ds/components/button/BasicButton';
import ShareIcon from 'assets/svg/share.svg';

export interface PreviewCardProps {
  id: string;
  type: PoiType;
  streetName: string;
  streetNumber: string;
  withSeparator?: boolean;
  onSetContactModalPoiId: (id: PoiId, meta: ContactModalOpenMeta) => void;
}

export const PreviewCard: React.FC<PreviewCardProps> = ({ id, type, streetName, streetNumber, withSeparator, onSetContactModalPoiId }) => {
  const { t } = useLocale();
  const router = useContext(routeContext);

  const url = cardShareUrl(router, id, type);
  const shouldResetSelectedValue = () => true;
  const shouldStayOpen = (value: ShareType) => value === ShareType.CopyLink;

  const openContactModal = () => onSetContactModalPoiId(id, { source: ContactAgentModalSource.ShortlistHoverPreview });

  return (
    <Root>
      <CardInner>
        <HoverCard id={id} />
        <FavoritesMobileButtonWrapper>
          <FavoritesToggler id={id} type={type} eventSource="favorites_hover" />
        </FavoritesMobileButtonWrapper>
        <ShareWrapper>
          <ShareButton
            poiId={id}
            shouldResetSelectedValue={shouldResetSelectedValue}
            shouldStayOpen={shouldStayOpen}
            handleShare={handleCardShare(streetNumber, streetName, url, t)}
            verticalPosition="bottom"
            alignment="right"
            source={ShareButtonSource.ShortlistHoverPreview}
            copyText={url}
            abTest
          >
            <BasicButton onlyIcon mode="ghost-darker">
              <ShareIcon width={22} height={22} />
            </BasicButton>
          </ShareButton>
        </ShareWrapper>
      </CardInner>
      <ContactButton onClick={openContactModal} size="medium">
        {t('shortlistHoverPreview.contactButton.label')}
      </ContactButton>
      {withSeparator && <CardSeparator />}
    </Root>
  );
};
