import React from 'react';
import { Route, useRoute } from 'config/routes';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { AuthTrapWrapper, ShortlistPreStepWrapper } from './styled';
import { useLocale } from 'locale';
import ShortlistPreStepIcon from 'assets/svg/save-listing.svg';
import { H3, Text } from 'ds/components/typography';
import { Button } from 'ds/components/button';

interface AuthTrapProps {
  children: React.ReactNode;
  isPreStep: boolean;
  onClosePreStep: () => void;
}

export const PRESTEP_ROUTES = new Set([ Route.Shortlist ]);

export const AuthTrap: React.FC<AuthTrapProps> = ({ children, isPreStep, onClosePreStep }) => {
  const { route } = useRoute();

  let content = children;
  let isVCentered = false;

  if (isPreStep) {
    switch (route.name) {
      case Route.Shortlist:
        content = <ShortlistPreStep onClick={onClosePreStep} />;
        isVCentered = true;
        break;
    }
  }

  return (
    <OverlayWrapper>
      <AuthTrapWrapper vCentered={isVCentered}>
        {content}
      </AuthTrapWrapper>
    </OverlayWrapper>
  );
};

const ShortlistPreStep: React.FC<{ onClick: () => void; }> = ({ onClick }) => {
  const { t } = useLocale();
  return (
    <ShortlistPreStepWrapper>
      <ShortlistPreStepIcon />
      <H3 weight="bold">{t('authTrap.shortlistPreStep.title')}</H3>
      <Text>{t('authTrap.shortlistPreStep.subtitle')}</Text>
      <Button size="large" onClick={onClick}>{t('authTrap.shortlistPreStep.buttonText')}</Button>
    </ShortlistPreStepWrapper>
  );
};
