import { AnalyticsContext, IAnalytics } from './index';
import { mapValues } from 'lodash';
import * as React from 'react';

type BIEvtsMap1<P> = {
  [key in keyof P]?: P[key] extends (...args: any[]) => any
    ? (...args: Parameters<P[key]>) => void
    : never
}[keyof P];


type BIEvtsMap<T> = Partial<Pick<T, Exclude<keyof T, BIEvtsMap1<T>>>>;

const withEvents = <P extends {}>(eventMapFactory: (se: IAnalytics['sendEvent'], props: P) => BIEvtsMap<P>) => (WrappedComponent: React.ComponentType<P>) => {
  const Wrapped: React.ComponentType<P> = class extends React.PureComponent<P, never> {
    public static displayName = `withEvents(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    public static contextType = AnalyticsContext;
    public context: IAnalytics;

    public render() {
      const { props } = this;
      const eventMap = eventMapFactory(this.context.sendEvent, props);

      const decoratedCallbacks = mapValues(eventMap, (callback: (...args: any[]) => void, key) => {
        return (...args: any[]) => {
          const ret = props[key] ? props[key](...args) : undefined;
          callback.apply(this, args);
          return ret;
        };
      });

      return <WrappedComponent {...props} {...decoratedCallbacks} />;
    }
  };
  return Wrapped;
};

export default withEvents;
