import React, { useState } from 'react';
import Filters from './Filters';
import { IFiltersState } from './types';
import FilterSummaryButton from './styled/FilterSummaryButton';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { filtersStateSelector } from 'store/state/selectors/search';
import { AppMode, appModeSelector, marketplaceSelector, routeSelector } from 'store/state/selectors/router';
import * as actions from 'store/state/router/actions';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { noop } from 'lodash';
import { getActiveFiltersNumber, setFiltersState } from './utils';
import { MarketplaceType } from 'utils/entities';
import withEvents from 'analytics/withEvents';
import { useLocale } from 'locale';
import { isHeaderTabSubmenuVisibleSelector } from 'store/state/app/selectors';
import { setMobileDiscoveryDisabled, setMobileDiscoveryEnabled } from 'store/state/app/actions';

interface FilterWrapperProps {
  screen: 'mobile' | 'desktop';
  marketplace: MarketplaceType;
  appMode: AppMode;
  setFilters: (value: IFiltersState) => void;
  filtersState: IFiltersState;
  onFilterClick?: (label: string, type: string) => void;
  isHeaderTabSubmenuVisible: boolean;
  makeMobileDiscoveryDisabled: () => void;
  makeMobileDiscoveryEnabled: () => void;
}

const FilterWrapper: React.FunctionComponent<FilterWrapperProps> = (props) => {
  const {
    setFilters,
    appMode,
    filtersState,
    screen,
    marketplace,
    onFilterClick,
    isHeaderTabSubmenuVisible,
    makeMobileDiscoveryDisabled,
    makeMobileDiscoveryEnabled,
  } = props;

  const { t } = useLocale();
  const [ mobileFiltersIsActive, setMobileFiltersIsActive ] = useState(false);

  const shouldDisableFilters = appMode === AppMode.Check;

  const hanldeClose = () => {
    makeMobileDiscoveryEnabled();
    setMobileFiltersIsActive(false);
  };

  const hanldeOpen = () => {
    makeMobileDiscoveryDisabled();
    setMobileFiltersIsActive(true);
    onFilterClick(t('filters.filters'), 'all');
  };

  if (screen === 'mobile') {
    return (
      <>
        {mobileFiltersIsActive ? (
          <Filters
            values={filtersState}
            setValues={setFilters}
            closeFiltersModal={hanldeClose}
            disabled={shouldDisableFilters}
            marketplace={marketplace}
            isHeaderTabSubmenuVisible={isHeaderTabSubmenuVisible}
          />
        ) : (
          <div>
            <FilterSummaryButton
              filtersNumber={getActiveFiltersNumber(filtersState, marketplace)}
              onClick={hanldeOpen}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <Filters
      values={filtersState}
      setValues={setFilters}
      closeFiltersModal={noop}
      disabled={shouldDisableFilters}
      marketplace={marketplace}
      isHeaderTabSubmenuVisible={isHeaderTabSubmenuVisible}
    />
  );
};

const FilterWrapperWithEvents = withEvents<FilterWrapperProps>((sendEvent, props) => ({
  onFilterClick(label, type) {
    sendEvent('search_filter_button_click', 'search', {
      event: {
        button_clicked: label,
        filter_category: type,
      },
    });
  },
}))(FilterWrapper);

const mapStateToProps = (state: State) => ({
  resolvedAddress: addressDocEntrySelector(state),
  marketplace: marketplaceSelector(state),
  route: routeSelector(state),
  appMode: appModeSelector(state),
  filtersState: filtersStateSelector(state),
  isHeaderTabSubmenuVisible: isHeaderTabSubmenuVisibleSelector(state),
});

const mapDispatchToProps = {
  navigate: actions.navigateTo,
  makeMobileDiscoveryDisabled: setMobileDiscoveryDisabled,
  makeMobileDiscoveryEnabled: setMobileDiscoveryEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps, ({ route, resolvedAddress, ...stateProps }, { navigate, ...rest }, ownProps) => ({
  ...stateProps,
  ...rest,
  ...ownProps,
  setFilters: (value: IFiltersState) => setFiltersState(value, route, resolvedAddress, navigate),
}))(FilterWrapperWithEvents);
