import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';
import { colorToAlpha } from 'helpers/insight';

export const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutrals.white};
  box-shadow: 0px 3px 8px ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.08)};
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    max-width: 390px;
  `}
`;

export const Header = styled.div<{ imageURL: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ imageURL }) => imageURL});
  background-size: cover;
  padding: ${({ theme }) => `${theme.spacing(4)} 0 ${theme.spacing(2)}`};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: ${theme.spacing(6)} 0 ${theme.spacing(3)};
  `}
`;

export const Content = styled.div`
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(5)}`};
  max-width: 605px;
  margin: 0 auto;
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    border-left: 1px solid ${theme.colors.neutrals.grey9};
    border-right: 1px solid ${theme.colors.neutrals.grey9};
    border-bottom: 1px solid ${theme.colors.neutrals.grey9};
    border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  `}
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: ${typographyFontSizeCfg.h1.mobile}px;
  line-height: ${typographyLineHeightCfg.h1.mobile}px;
  color: #343C4B;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    line-height: ${typographyLineHeightCfg.h1.desktop}px;
  `}
`;
export const Subtitle = styled(Text)`
  color: #343C4B;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    font-size: ${typographyFontSizeCfg.h3.mobile}px;
  `}
`;
export const Link = styled(Text.withComponent('a'))`
  color: ${({ theme }) => theme.colors.neutrals.white};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(9)}`};
  background: #E87721;
  cursor: pointer;
  display: block;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  margin: ${({ theme }) => `${theme.spacing(4)} 0 ${theme.spacing(1)}`};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: ${theme.spacing(6)} 0 ${theme.spacing(1)};
  `}
`;
export const Close = styled(Text)`
  cursor: pointer;
  color: #343C4B;
`;
