import React, { useRef, useState } from 'react';
import {
  DrawerLinksSection,
  DrawerLinksWrapper,
  DrawerSectionHeader,
  DrawerSectionMenuLinkWrapper,
  DrawerSectionsWrapper,
  MenuLinkWrapper,
} from './styled';
import ChevronDownIcon from 'assets/svg/navigation/chevron-down.svg';
import ChevronUpIcon from 'assets/svg/navigation/chevron-up.svg';
import {
  InformationAndServicesLinksSection,
  InformationAndServicesSectionToLinks,
  LinksSectionLink,
} from 'components/navigation/more-actions-menu/types';
import { MenuLink } from 'components/navigation/more-actions-menu/MenuLink';
import withEvents from 'analytics/withEvents';
import { noop } from 'lodash';
import { useLocale } from 'locale';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

interface LinksDrawerProps {
  children: React.ReactNode;
  links: LinksSectionLink[] | InformationAndServicesSectionToLinks;
  sections?: InformationAndServicesLinksSection[];
  onMadadLinkClick?: () => void;
}

const LinksDrawerBase: React.FC<LinksDrawerProps> = ({ children, links, sections, onMadadLinkClick }) => {
  const { t } = useLocale();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ isSectionLastElementVisible, setIsSectionLastElementVisible ] = useState(false);
  const lastElementRef = useRef(null);

  const onIntersect = ([ entry ]: IntersectionObserverEntry[]) =>
    setIsSectionLastElementVisible(entry.intersectionRatio === 1);

  const intersectionOptions = {
    threshold: [ 0, 1 ],
  };

  useIntersectionObserver(onIntersect, [ lastElementRef ], intersectionOptions);

  return (
    <>
      <MenuLinkWrapper
        isInitialIconColor
        onClick={() => setIsOpen(!isOpen)}
        sticky={Boolean(sections) && !isSectionLastElementVisible && isOpen}
        weight={isOpen ? 'bold' : 'normal'}
      >
        <div>{children}</div>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </MenuLinkWrapper>
      {sections ? (
        <>
          {isOpen && (
            <DrawerSectionsWrapper>
              {sections.map((section, sectionIndex) => (
                <DrawerLinksSection key={section}>
                  <DrawerSectionHeader>
                    {t('navigation.moreActionsMenu.informationAndServicesSection', { section })}
                  </DrawerSectionHeader>
                  {links[section].map((link: LinksSectionLink, linkIndex: number) => (
                    <DrawerSectionMenuLinkWrapper
                      key={link.label}
                      ref={(ref) => {
                        if (sectionIndex + 1 === sections.length && linkIndex + 1 === links[section].length) {
                          lastElementRef.current = ref;
                        }
                      }}
                    >
                      <MenuLink routeName={link.routeName} href={link.path} fullWidthAnchor isDrawerLink>
                        {link.label}
                      </MenuLink>
                    </DrawerSectionMenuLinkWrapper>
                  ))}
                </DrawerLinksSection>
              ))}
            </DrawerSectionsWrapper>
          )}
        </>
      ) : (
        <>
          {isOpen && (
            <DrawerLinksWrapper>
              {(links as LinksSectionLink[]).map((link) => (
                <MenuLink
                  key={link.label}
                  onClick={link.path === '/madad?source=left_menu' ? onMadadLinkClick : noop}
                  routeName={link.routeName}
                  href={link.path}
                  fullWidthAnchor
                  isDrawerLink
                >
                  {link.label}
                </MenuLink>
              ))}
            </DrawerLinksWrapper>
          )}
        </>
      )}
    </>
  );
};

export const LinksDrawer = withEvents<LinksDrawerProps>((sendEvent) => ({
  onMadadLinkClick() {
    sendEvent('madad_click', 'madad');
  },
}))(LinksDrawerBase);
