import React from 'react';
import {
  ICommercialProject,
  IProject,
  IBulletin,
  ICommercialBulletin,
  DealType,
  PromotionValues,
} from 'utils/entities';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps,
  getContactPageEventPayload,
  getPOCExclusive,
  getPOCName,
  getPOCTitle,
} from 'helpers/contact';
import withEvents from 'analytics/withEvents';
import { IFormFields } from 'store/sagas/apiService/types';
import { useLocale } from 'locale';
import { Button, FeedbackButton } from 'ds/components/button';
import Modal from 'ds/components/modal';
import ContactForm from './ContactForm';
import styled from '@emotion/styled';
import HebrewContactImage from 'components/unit-page/contact-agent/HebrewContactImage';
import { Text } from 'ds/components/typography';
import PhoneIcon from 'assets/svg/phone.svg';
import { FormApi } from 'final-form';
import { Logo } from 'components/unit-page/contact-agent/Logo';
import DisplayPhone from 'components/unit-page/contact-agent/DisplayPhone';
import { createPhoneURI } from 'hooks/useDisplayPhone';
import { isBulletinPoiType, isProjectPoiType } from 'utils/marketplaceRoutes';
import config from 'config';
import { ContactAgentModalSource } from 'store/state/app';
import { LeadContactTermsAndConditions } from 'components/term-and-conditions/LeadContactTermsAndConditions';
import { SpacingBlock } from 'components/spacing-block';


const ModalContentRoot = styled.div`
  ${props => props.theme.media.showAt({ to: 2 })`
    width: 338px;
    margin: 0 auto;
    max-width: 100%;
  `}
`;

const AgentDataWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NameText = styled(Text)`
  color: ${props => props.theme.colors.neutrals.grey1};
`;

const ContactDetailText = styled(Text)`
  color: ${props => props.theme.colors.neutrals.grey3};
`;

const ContactDetails = styled.div`
  margin-${props => props.theme.isRTL ? 'right' : 'left'}: ${({ theme }) => theme.spacing(2)};
`;

const ContactAgentButtonWrapper = styled.div`
  ${props => props.theme.media.showAt({ to: 3 }) `
    button {
      background: ${props.theme.colors.neutrals.white};
      color: ${props.theme.colors.brand.main};
      border-color: ${props.theme.colors.neutrals.grey6};
      &:hover {
        color: ${props.theme.colors.neutrals.white};
      }
    }
  `}
`;

const CallButton = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};

  button {
    height: 40px;
    padding: 0;
    ${props => props.theme.media.showAt({ to: 3 }) `border: none`}
    span {
      display: flex;
      width: 100%;
      a {
        width: 100%;
        justify-content: center;
        height: 40px;
        color: ${props => props.theme.colors.brand.main};
        flex-direction: ${props => props.theme.isRTL ? `row-reverse` : `row`};
        text-decoration: none;
        display: flex;
        align-items: center;
        ${props => props.theme.media.showAt({ to: 3 }) `
          background: ${props.theme.colors.brand.main};
          color: ${props.theme.colors.neutrals.white};
          border-color: ${props.theme.colors.brand.main};
          border-radius: ${props.theme.borderRadius.smallestSideRound};
          &:hover {
            background: ${props.theme.colors.brand.dark};
          }
        `}
        svg {
          margin-${props => props.theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(0.5)};
          > path {
            ${props => props.theme.media.showAt({ to: 3 }) `fill: ${props.theme.colors.neutrals.white}`}
            ${props => props.theme.media.showAt({ from: 3 }) `fill: ${props.theme.colors.brand.main}`}
          }
        }
      }
    }
  }
`;

interface HebrewContactModalMobileProps {
  poi: IBulletin | IProject | ICommercialProject | ICommercialBulletin;
  onClose: () => void;
  handleSubmit: () => void;
  formApi: FormApi;
  onCallClick?: (values: Partial<IFormFields>) => void;
  onAfterSubmit?: (values: IFormFields) => void;
  onPhoneClick?: () => void;
  onInputFieldClick: (event: string, source: string) => void;
  isLeadingAgent: boolean;
}

const HebrewContactModalMobile: React.FC<HebrewContactModalMobileProps> = ({
  poi,
  formApi,
  onCallClick,
  onPhoneClick,
  onClose,
  handleSubmit,
  onInputFieldClick,
}) => {
  const { t } = useLocale();

  const poc = isBulletinPoiType(poi) && poi.poc;
  const developers = isProjectPoiType(poi) && poi.developers && poi.developers[0];
  const name = isBulletinPoiType(poi) ? getPOCName(poc) : poi.projectName;
  const title = isBulletinPoiType(poi) ? getPOCTitle(poc) : developers && developers.name;
  const hidePhoneNumber = isProjectPoiType(poi) && (!poi.promotionStatus || poi.promotionStatus.status !== PromotionValues.Promoted);

  const { submitting, values } = formApi.getState();
  const onCall = () => {
    onCallClick(values);
    onPhoneClick();
  };

  const handleInputPhoneClick = () => {
    onInputFieldClick('property_lead_form_phone_text_box_click', ContactAgentModalSource.ContactSection);
  };

  const handleInputMessageClick = () => {
    onInputFieldClick('property_lead_form_messege_box_click', ContactAgentModalSource.ContactSection);
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={t('contactAgentModal.title')}
      buttons={
        <ContactAgentButtonWrapper>
          <FeedbackButton
            data-auto="agent-form-contact-button"
            type="submit"
            isLoading={submitting}
            onClick={handleSubmit}
            disabled={submitting}
            fullWidth
            size="large"
          >
            {t('contactAgentModal.button')}
          </FeedbackButton>
        </ContactAgentButtonWrapper>
      }
    >
      <ModalContentRoot>
        <AgentDataWrapper>
          {isBulletinPoiType(poi) ? <HebrewContactImage poc={poc} big/> : null}
          {isProjectPoiType(poi) && developers ? <Logo developerLogo={developers.logoPath} /> : null}
          <ContactDetails>
            <NameText weight="bold">{name}</NameText>
            {poc ? (
              <ContactDetailText>
                {t('unitPage.contactAgentHebrewExclusive', { exclusive: getPOCExclusive(poc) })}
              </ContactDetailText>
            ) : null}
            <ContactDetailText>{title}</ContactDetailText>
          </ContactDetails>
        </AgentDataWrapper>
        {hidePhoneNumber ? null : (
          <CallButton data-auto="agent-form-call">
            <Button mode="ghost" fullWidth>
              <DisplayPhone id={poi.id} type={poi.type}>
                {(phone) => (
                  <a
                    data-auto="agent-form-call-text"
                    href={createPhoneURI(phone)}
                    onClick={onCall}
                  >
                    <PhoneIcon width={16} height={16} />
                    <Text weight="bold">{phone}</Text>
                  </a>
                )}
              </DisplayPhone>
            </Button>
          </CallButton>
        )}
        <ContactForm
          formApi={formApi}
          handleSubmit={handleSubmit}
          onPhoneInputClick={handleInputPhoneClick}
          onMessageInputClick={handleInputMessageClick}
        />
        <SpacingBlock mTop={2}>
          <LeadContactTermsAndConditions />
        </SpacingBlock>
      </ModalContentRoot>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withEvents<HebrewContactModalMobileProps>((sendEvent, props) => ({
    onCallClick: (values) => sendEvent('property_lead_call_submit', 'property', {
      ...getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, values, true),
      bulletinId: props.poi.id,
    }),
    handleSubmit: () => {
      sendEvent(
        props.formApi.getState().hasValidationErrors ? 'property_lead_form_submit_attempt' : 'property_lead_form_submit',
        'property', {
          ...getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, props.formApi.getState().values),
          bulletinId: props.poi.id,
        });
    },
    onInputFieldClick: (event: string, source: string) => {
      const location = {
        borough: props.poi.addressDetails && props.poi.addressDetails.borough,
        street_name: props.poi.addressDetails && props.poi.addressDetails.streetName,
        street_number: props.poi.addressDetails && props.poi.addressDetails.streetNumber,
        unit_number: props.poi.addressDetails && props.poi.addressDetails.unitNumber,
        city: props.poi.addressDetails && props.poi.addressDetails.city,
        country: config.country,
        location_type: 'address',
        neighborhood: props.poi.addressDetails && props.poi.addressDetails.neighbourhood,
      };
      sendEvent(event, 'property', {
        event: {
          source,
        },
        location,
        forceProperty: true,
        property: {
          deal_type: props.poi.dealType === DealType.Buy ? 'for_sale' : 'for_rent',
          property_id: props.poi.id,
        },
      });
    },
  }))(HebrewContactModalMobile)
);
