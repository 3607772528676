import React from 'react';
import { useLocale } from 'locale';
import { SliderRange } from 'ds/components/slider';
import { Section, SectionTitle, SectionBody, SectionSubTitle } from '../styled/common';

import FilterSection from '../common/DropdownSection';
import { GenericFilterProps } from '../types';

import { isFilterActiveByType } from '../utils';

interface MonthlyTaxRangeProps extends GenericFilterProps<'monthlyTaxRange'> {}

const MonthlyTaxRange: React.FunctionComponent<MonthlyTaxRangeProps> = ({
  alignment,
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  isMobile,
  config,
  type,
}) => {
  const { t, formatMoneyWithMetricPrefix, formatMoney } = useLocale();
  const { monthlyTaxRange, filtersIcons } = config;

  if (!monthlyTaxRange) {
    return null;
  }

  const [ commitedFrom, commitedTo ] = commitedValue;
  const [ initialFrom, initialTo ] = initialValue;
  const [ currentFrom, currentTo ] = intermediateValue;

  const isActive = isFilterActiveByType.monthlyTaxRange(commitedValue, initialValue);
  const valuesAreInitial = !isFilterActiveByType.monthlyTaxRange(intermediateValue, initialValue);

  const txtShortValue = t('filters.monthlyTaxShortValue', {
    isActive,
    priceFrom: initialFrom === commitedFrom ? null : formatMoneyWithMetricPrefix(commitedFrom),
    priceTo: initialTo === commitedTo ? null : formatMoneyWithMetricPrefix(commitedTo),
  });

  const txtLongValue = t('filters.priceLongValue', {
    isMobile,
    isActive,
    priceFrom: formatMoney(currentFrom),
    priceTo: formatMoney(currentTo),
    isMaxedOut: initialTo === currentTo,
  });

  const slider = (
    <SliderRange
      {...monthlyTaxRange.budgetSliderProps}
      values={intermediateValue}
      mode="ltr"
      onChange={value => setIntermediateValue(value)}
    />
  );

  return isMobile ? (
    <Section data-section-filter-name={type} data-auto-filter-name="monthlyTaxRange" data-auto={`filter-${type}`}>
      <SectionTitle>{t('filters.monthlyTaxTitle')}</SectionTitle>
      <SectionBody>
        <SectionSubTitle
          data-auto="monthlyTaxRange"
          data-auto-filter-title={txtLongValue}
        >
          {txtLongValue}
        </SectionSubTitle>
        {slider}
      </SectionBody>
    </Section>
  ) : (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      title={txtLongValue}
      icon={filtersIcons[type]}
      onClear={resetValue}
      label={txtShortValue}
      onSubmit={commitValues}
      isActive={isActive}
    >
      {slider}
    </FilterSection>
  );
};

export default React.memo(MonthlyTaxRange);
