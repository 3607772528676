import { styled } from 'ds';
import { Text } from 'ds/components/typography';

export const ModalContentWrapper = styled(Text)`
  max-width: 356px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(3)};
  `}
`;

export const ButtonWrapper = styled.div`
  > a {
    text-decoration: none;
  }
`;
