export enum AddLastUpdateToWeights {
  modeB = 'modeB',
}

export enum RemoveWizard {
  modeB = 'modeB',
}

export enum ListingPageV5 {
  modeB = 'modeB',
}

export enum RecommendedListingsABTest {
  modeB = 'modeB',
}

export enum ProjectPopupDesign {
  modeB = 'modeB',
}

export interface IABTestContext {
  projectPopupDesign?: ProjectPopupDesign;
  _be_addLastUpdateToWeights?: AddLastUpdateToWeights;
  removeWizard?: RemoveWizard;
  listingPageV5?: ListingPageV5;
  recommendedListings?: RecommendedListingsABTest;
}
