import React from 'react';
import TabuIllustration from 'assets/svg/tabu-illustration.svg';
import * as Styled from './styled';
import { useLocale } from 'locale';


interface TabuSuccessProps {
  email: string;
}

export const TabuSuccess: React.FC<TabuSuccessProps> = (props) => {
  const { email } = props;

  const { t } = useLocale();

  return (
    <Styled.Root>
      <TabuIllustration width={72} height={72} />
      <Styled.Header weight="bold">{t('tabu.wizard.success.header')}</Styled.Header>
      <Styled.SimpleText>{t('tabu.wizard.success.info', { email })}</Styled.SimpleText>
    </Styled.Root>
  );
};
