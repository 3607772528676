import styled from '@emotion/styled';
import { H3, Text } from 'ds/components/typography';

export const Wrapper = styled.div<{ withRedesignedForm: boolean; noBottomMargin?: boolean }>`
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.neutrals.grey8};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
  ${({ theme, withRedesignedForm, noBottomMargin }) => withRedesignedForm ? `
    padding: 0;
    margin-bottom: ${noBottomMargin ? 0 : theme.spacing(7)};
    box-shadow: none;
  ` : ''}
`;

export const Title = styled(H3)<{ withRedesignedForm: boolean }>`
  color: #3f3f3f;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme, withRedesignedForm }) => withRedesignedForm ? `
    color: ${theme.colors.neutrals.grey1};
  ` : ''}
`;

export const Description = styled(Text)<{ withRedesignedForm: boolean }>`
  color: #373737;
  ${({ theme, withRedesignedForm }) => withRedesignedForm ? `
    color: ${theme.colors.neutrals.grey1};
  ` : ''}
`;
