import React from 'react';

import { Seller, GenericFilterProps } from '../types';
import { useLocale } from 'locale';
import { PerformantCheckboxGroup } from '../common/PerformantCheckbox';
import FilterSection from '../common/DropdownSection';
import { isFilterActiveByType } from '../utils';
import styled from '@emotion/styled';
import { Section, SectionBody, SectionTitle, SecondaryTitle, PropertyTypeWrapper } from '../styled/common';


const Description = styled.span`
  color: ${({ theme }) => theme.colors.neutrals.grey4};
`;

const Sellers: React.FC<GenericFilterProps <'seller'>> = ({
   intermediateValue,
   setIntermediateValue,
   isMobile,
   isInline,
   commitValues,
   commitedValue,
   resetValue,
   type,
   config,
   alignment,
   initialValue,
 }) => {
  const { t } = useLocale();
  const { seller, filtersIcons } = config;

  const checkboxLabelGetter = (value: Seller) => {
    return (
      <span>
        {t('filters.sellerOptions', { value })}
        {<Description>{t('filters.sellerOptionsDescription', { value })}</Description>}
      </span>
    );
  };

  const checkboxGroup = (
    <PerformantCheckboxGroup
      values={intermediateValue}
      options={seller}
      labelGetter={checkboxLabelGetter}
      valueSetter={setIntermediateValue}
    />
  );

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
        <SectionTitle isBold>
          <div>
            {t('filters.seller', { count: null })}
            {intermediateValue.length ? (
              <SecondaryTitle>
                {intermediateValue.length}
              </SecondaryTitle>
            ) : null}
          </div>
        </SectionTitle >
        <SectionBody data-auto="filterCheckboxes">
          <PropertyTypeWrapper isInline={!isMobile}>
            {checkboxGroup}
          </PropertyTypeWrapper>
        </SectionBody>
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={!isFilterActiveByType.seller(intermediateValue, initialValue)}
      type={type}
      alignment={alignment}
      icon={filtersIcons[type]}
      onClear={resetValue}
      label={t('filters.seller', { count: commitedValue.length })}
      onSubmit={commitValues}
      isActive={isFilterActiveByType.seller(commitedValue, initialValue)}
    >
      {checkboxGroup}
    </FilterSection>
  );
};

export default React.memo(Sellers);
