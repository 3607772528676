import { RootAction, State } from 'store/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import UploadBulletinPage, { UploadBulletinPageOwnProps } from './UploadBulletinPage';
import { IBulletinInput } from 'utils/entities';
import {
  isConnectedUserSelector,
  isAgentManagerSelector,
  userLoadingSelector,
  userProfileSelector,
  isAgentSelector,
  isBulletinsAdminSelector,
  isPrivateUserSelector,
} from 'store/state/domainData/selectors';
import { uploadBulletinDraft } from './helpers';
import { newListingAction } from 'store/state/uploadBulletin/actions';
import { DecodedRouteParams, Route } from 'config/routes';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { navigateTo } from 'store/state/router/actions';
import { routeSelector } from 'store/state/selectors/router';
import { isCommercialUploadEnabledSelector, submittingSelector } from './selectors';


const mapStateToProps = (state: State) => ({
  isLoading: userLoadingSelector(state),
  user: userProfileSelector(state),
  initialValues: uploadBulletinDraft.get(),
  route: routeSelector(state),
  isBulletinsAdmin: isBulletinsAdminSelector(state),
  isAgent: isAgentSelector(state),
  isAgentManager: isAgentManagerSelector(state),
  isConnectedUser: isConnectedUserSelector(state),
  isPrivateUser: isPrivateUserSelector(state),
  isUserCommercialAllowed: isCommercialUploadEnabledSelector(state),
  submitting: submittingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  submitForm: (listing: IBulletinInput) => dispatch(newListingAction({ listing })),
  navigate: (name: Route, params?: DecodedRouteParams, opts?: NavigationOptions) => dispatch(navigateTo(name, params, opts)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: UploadBulletinPageOwnProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UploadBulletinPage);
