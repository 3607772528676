import React from 'react';
import { StepHeading } from '../../styled';
import { UploadBulletinStepProps } from '../index';

import { BuildingClassSection } from './sections/BuildingClassSection';
import { BedsNumberSection } from './sections/BedsNumberSection';
import { RoomsSection } from './sections/RoomsSection';
import { AreaSection } from './sections/AreaSection';
import { FloorSection } from './sections/FloorSection';
import { AvailabilitySection } from './sections/AvailabilitySection';
import { PricesSection } from './sections/PriceSection';
import { useLocale } from 'locale';
import { COMMERCIAL_BUILDING_CLASSES, priceLimitsByMarketplaceType, RESIDENTIAL_BUILDING_CLASSES } from './constants';
import { MarketplaceType } from 'utils/entities';

const BulletinMainInfo: React.FC<UploadBulletinStepProps> = ({
  formInstance,
  isCommercial,
  hideErrorOnFocus,
}) => {
  const { t } = useLocale();

  const buildingClasses = isCommercial ? COMMERCIAL_BUILDING_CLASSES : RESIDENTIAL_BUILDING_CLASSES;
  const priceLimits = isCommercial
    ? priceLimitsByMarketplaceType[MarketplaceType.Commercial]
    : priceLimitsByMarketplaceType[MarketplaceType.Residential];
  return (
    <>
      <StepHeading>{t('uploadBulletinPage.mainInfoStep.heading')}</StepHeading>

      <div>
        <BuildingClassSection buildingClasses={buildingClasses} formInstance={formInstance} isCommercial={isCommercial} />
        <AreaSection formInstance={formInstance} hideErrorOnFocus={hideErrorOnFocus} />
        {isCommercial ? (
          <RoomsSection formInstance={formInstance} hideErrorOnFocus={hideErrorOnFocus} />
        ) : (
          <BedsNumberSection formInstance={formInstance} />
        )}

        <FloorSection isCommercial={isCommercial} formInstance={formInstance} />
        <AvailabilitySection formInstance={formInstance} />
        <PricesSection
          isCommercial={isCommercial}
          formInstance={formInstance}
          priceLimits={priceLimits}
          hideErrorOnFocus={hideErrorOnFocus}
        />
      </div>
    </>
  );
};

export default BulletinMainInfo;
