import { IAutocompleteEntry } from 'utils/entities';
import { mapLocationTypeForEvents } from 'components/search-widget/helpers';


export function mapAddressToAnalytics(entity: Partial<IAutocompleteEntry>) {
  if (!entity) return null;

  return {
    city: entity.city,
    location_type: mapLocationTypeForEvents(entity.type),
    longitude: entity.location && entity.location[0],
    latitude: entity.location && entity.location[1],
    location_id: entity.docId,
    street_number: 'houseNumber' in entity ? entity.houseNumber : undefined,
    street_name: 'street' in entity ? entity.street : undefined,
    borough: 'borough' in entity ? entity.borough : undefined,
    neighborhood: 'neighbourhood' in entity ? entity.neighbourhood : undefined,
    zip_code: 'zipcode' in entity ? entity.zipcode : undefined,
  };
}
