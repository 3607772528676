import {
  SET_DOMAIN_DATA,
  FETCH_MORE,
  INIT,
  RESET,
  SET_FETCH_MORE_RESULT,
  RESET_SSR_HYDRATION,
} from './types';
import { LoadType, ResponseByType, LoadOptions } from 'store/sagas/apiService/types';
import { ApolloQueryResult, NetworkStatus } from 'apollo-client';
import { createStandardAction } from 'typesafe-actions';


interface ActionParams<T extends LoadType> extends LoadOptions<T> {
  payload: ApolloQueryResult<ResponseByType[T]>;
}

interface Action<T extends LoadType, K extends string> extends ActionParams<T> {
  type: K;
}

type UpdateDomainDataActionMap = {
  [K in LoadType]: Action<K, typeof SET_DOMAIN_DATA>;
}[LoadType];

export const setDomainData = <T extends LoadType>(params: ActionParams<T>) => ({
  ...params,
  type: SET_DOMAIN_DATA,
} as UpdateDomainDataActionMap);

export const setFetchMoreResult = <T extends LoadType>(params: ActionParams<T>): Action<T, typeof SET_FETCH_MORE_RESULT> => ({
  ...params,
  type: SET_FETCH_MORE_RESULT,
});

export const resetDomainData = createStandardAction(RESET)<{ loadType: LoadType }>();
export const resetSSRHydration = createStandardAction(RESET_SSR_HYDRATION)<{ loadType: LoadType }>();

export const fetchMore = <T extends LoadType>(params: LoadOptions<T>) => ({
  type: FETCH_MORE as typeof FETCH_MORE,
  ...params,
});

export const init = createStandardAction(INIT)<{ loadType: LoadType, networkStatus: NetworkStatus }>();
