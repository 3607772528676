import React from 'react';
import { Wrapper, Title, Description } from './styled';
import { useLocale } from 'locale';
import { PHONE_TEXT_REGEXP } from 'utils/formValidationHelpers';

interface DescriptionApartmentProps {
  description: string;
  withRedesignedForm: boolean;
  noBottomMargin?: boolean;
}

const PHONE_NUMBER_MASK = 'XXX-XXXXXXX';

const DescriptionApartment: React.FC<DescriptionApartmentProps> = ({ description, withRedesignedForm, noBottomMargin }) => {
  const { t } = useLocale();
  const descriptionDisplayText = description.replace(PHONE_TEXT_REGEXP, PHONE_NUMBER_MASK);

  return (
    <Wrapper withRedesignedForm={withRedesignedForm} noBottomMargin={noBottomMargin}>
      <Title withRedesignedForm={withRedesignedForm} weight="bold">{t('contactForm.descriptionApartment.title', { isCommercial: !!withRedesignedForm })}</Title>
      {description ? <Description withRedesignedForm={withRedesignedForm}>{descriptionDisplayText}</Description> : null}
    </Wrapper>
  );
};

export default DescriptionApartment;
