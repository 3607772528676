import { EventPostProcessor } from '@madlan145/client-toolbox';

export class GoogleTagManager implements EventPostProcessor {
  public process(name: string, category: string, payload: any) {
    const win = (window as any);
    if (win.dataLayer) {
      win.dataLayer.push({
        event: name,
        category,
        payload,
      });
    }
  }
}
