import { connect } from 'react-redux';
import { Route } from 'config/routes';
import { RootAction, State } from 'store/state';
import { createSelector } from 'reselect';
import { prevRouteSelector, routeHistorySelector, routeSelector } from 'store/state/selectors/router';
import { projectPageProjectSelector, unitPageBulletinSelector, unitPageCommercialBulletinSelector, projectPageCommercialProjectSelector } from 'store/state/domainData/selectors';
import { BackLink } from './BackLink';
import { createBackLinkConfig } from './configFactory';
import { Dispatch } from 'redux';
import { setIntentModalOpen } from 'store/state/app/actions';
import { PoiId } from 'utils/entities';
import { ContactModalOpenMeta } from 'store/state/app';
import { intentModalSourceSelector } from 'store/state/selectors/intentModal';

const poiSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
  projectPageProjectSelector,
  projectPageCommercialProjectSelector,
], (route, bulletin, commercialBulletin, project, commercialProject) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
    case Route.ProjectPage:
      poi = project;
      break;
    case Route.ProjectPageCommercial:
      poi = commercialProject;
  }
  return poi;
});

export const backLinkConfigSelector = createSelector(
  prevRouteSelector,
  routeSelector,
  poiSelector,
  routeHistorySelector,
  (prevRoute, route, poi, routeHistory) => createBackLinkConfig({
    prevRoute,
    route,
    poi,
    routeHistory,
  })
);

const mapStateToProps = (state: State) => {
  const poi = poiSelector(state);
  return {
    config: backLinkConfigSelector(state),
    prevRoute: prevRouteSelector(state),
    intentModalSource: intentModalSourceSelector(state),
    poiId: poi ? poi.id : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  openIntentModal: (id: PoiId, meta: ContactModalOpenMeta) => dispatch(setIntentModalOpen(id, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackLink);
