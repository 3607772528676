import React from 'react';
import { IThumbnail } from 'utils/entities';
import { ShowAt } from 'consts/breakpoints';
import { Pager } from 'components/cards/listing/Pager';
import { PagerWrapper, ControlsArrowProps, ControlsArrowWrapper } from './styled';
import ControlsIcon from 'assets/svg/control-chevron.svg';

export const ControlsArrow: React.FunctionComponent<ControlsArrowProps> = (props) => (
  <ControlsArrowWrapper {...props}>
    <ControlsIcon />
  </ControlsArrowWrapper>
);

interface GalleryPagerProps {
  images: IThumbnail[];
  activeSlide: number;
  handleNextSlide(): void;
  handlePrevSlide(): void;
  handleSlideIndexChange(idx: number): void;
}

export const GalleryPager: React.FunctionComponent<GalleryPagerProps> = ({
  images,
  activeSlide,
  handleSlideIndexChange,
  handleNextSlide,
  handlePrevSlide,
}) => (
  <>
    <ShowAt to={2}>
      <PagerWrapper>
        <Pager
          total={images.length}
          onChangeIndex={handleSlideIndexChange}
          index={activeSlide}
        />
      </PagerWrapper>
    </ShowAt>
    <ShowAt from={3}>
      {images.length > 1 ? (
        <>
          <ControlsArrow dir="prev" onClick={handlePrevSlide}/>
          <ControlsArrow dir="next" onClick={handleNextSlide}/>
        </>
      ) : null}
    </ShowAt>
  </>
);
