import React from 'react';
import { lowerCase } from 'lodash';
import { MoreText, MoreInfo as MoreInfoWrapper } from 'screens/UnitPage/styled';
import IconEntry from 'assets/svg/project-16-entry.svg';
import ManagementFee from 'assets/svg/wrench.svg';
import { simpleDateFormatter } from 'components/unit-page/apartment-details';
import IconDocument from 'assets/svg/document.svg';
import { useLocale } from 'locale';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { CommercialMoreInfo, CommercialMoreInfoWrapper, CommercialSectionTitle } from './styled';
import { AvailabilityType } from 'utils/entities';


interface MoreInfoProps {
  availabilityType: AvailabilityType;
  availableDate: number;
  monthlyTaxes: number;
  monthlyManagementFee: number;
}

export const MoreInfo: React.FC<MoreInfoProps> = ({
  availabilityType,
  availableDate,
  monthlyTaxes,
  monthlyManagementFee,
}) => {
  const { t, formatNumber } = useLocale();
  const isMobile = useScreenBreakpoint() < 3;

  return (
    <CommercialMoreInfoWrapper>
      <CommercialSectionTitle weight="bold">{t('commercial.listing.moreInfo.title')}</CommercialSectionTitle>
      <CommercialMoreInfo isMobile={isMobile}>
        <MoreInfoWrapper>
          <IconEntry />
          <MoreText>
            {t('commercial.listing.moreInfo.entry', {
              entry: lowerCase(availabilityType),
              dateStr: availableDate ? simpleDateFormatter(availableDate) : undefined,
            })}
          </MoreText>
        </MoreInfoWrapper>
        {monthlyTaxes ? (
          <MoreInfoWrapper>
            <IconDocument />
            <MoreText>{t('commercial.listing.moreInfo.tax.2month', { price: formatNumber(monthlyTaxes) })}</MoreText>
          </MoreInfoWrapper>
        ) : null}
        {monthlyManagementFee ? (
          <MoreInfoWrapper>
            <ManagementFee />
            <MoreText>
              {t('commercial.listing.moreInfo.monthlyManagementFee', { price: formatNumber(monthlyManagementFee) })}
            </MoreText>
          </MoreInfoWrapper>
        ) : null}
      </CommercialMoreInfo>
    </CommercialMoreInfoWrapper>
  );
};
