import React, { useRef, useCallback } from 'react';
import { BottomSheet, BottomSheetRef } from 'components/bottom-sheet';
import SaveSearchIllustration from 'assets/svg/push-notifications-drawer/save-search.svg';
import EnablePushIllustration from 'assets/svg/push-notifications-drawer/enable-push.svg';
import { useLocale } from 'locale';
import { Text, H3 } from 'ds/components/typography';
import { Button } from 'ds/components/button';
import styled from '@emotion/styled';
import withEvents from 'analytics/withEvents';
import { BasicButtonProps } from 'ds/components/button/BasicButton';

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing(4)}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

const HeadingWrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacing(3)};
  background-color: #9DDEE3;
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => `${theme.borderRadius.extraLarge} ${theme.borderRadius.extraLarge} 0 0`};
`;

const Body = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  white-space: pre-line;
`;

interface SaveSearchDrawerProps {
  onClose: () => void;
  onSaveSearch: () => void;
}


export const SaveSearchDrawer: React.FC<SaveSearchDrawerProps> = (props) => {
  const { onSaveSearch, onClose } = props;
  const { t } = useLocale();
  const sheetRef = useRef<BottomSheetRef>(null);
  const handleSaveSearch = useCallback(() => {
    if (sheetRef.current) {
      sheetRef.current.collapse().then(onSaveSearch);
    }
  }, [ onSaveSearch ]);

  return (
    <BottomSheet ref={sheetRef} onCollapse={onClose}>
      <HeadingWrapper>
        <SaveSearchIllustration />
      </HeadingWrapper>
      <Container>
        <H3 weight="bold">{t('pushNotifications.saveSearchDrawer.title')}</H3>
        <Body>{t('pushNotifications.saveSearchDrawer.body')}</Body>
        <Button onClick={handleSaveSearch} fullWidth size="extraLarge">{t('pushNotifications.saveSearchDrawer.button')}</Button>
      </Container>
    </BottomSheet>
  );
};

interface EnablePushDrawerProps {
  onClose: () => void;
  requestPush: () => void;
}

const EnablePushButtonWithEvents = withEvents<BasicButtonProps & React.HTMLAttributes<HTMLButtonElement>>((sendEvent) => ({
  onClick() {
    sendEvent('user_push_activation_change', 'user', {
      event: {
        source: 'pop_up',
        action: 'enable',
      },
    });
  },
}))(Button);

export const EnablePushDrawer: React.FC<EnablePushDrawerProps> = (props) => {
  const { onClose, requestPush } = props;
  const { t } = useLocale();
  const sheetRef = useRef<BottomSheetRef>(null);
  const handleRequestPush = useCallback(() => {
    if (sheetRef.current) {
      sheetRef.current.collapse().then(requestPush);
    }
  }, [ requestPush ]);

  return (
    <BottomSheet ref={sheetRef} onCollapse={onClose}>
      <HeadingWrapper>
        <EnablePushIllustration />
      </HeadingWrapper>
      <Container>
        <H3 weight="bold">{t('pushNotifications.enablePush.title')}</H3>
        <Body>{t('pushNotifications.enablePush.body')}</Body>
        <EnablePushButtonWithEvents onClick={handleRequestPush} fullWidth size="extraLarge">
          {t('pushNotifications.enablePush.button')}
        </EnablePushButtonWithEvents>
      </Container>
    </BottomSheet>
  );
};

