import React from 'react';
import {
  DealType,
  IBulletin,
  ICommercialBulletin,
  ICommercialProject,
  IProject,
  MarketplaceType,
} from 'utils/entities';
import { NoListingsSubtitle, NoListingsTitle, NoListingsWrapper, Root, ShortlistButtonWrapper } from './styled';
import HeartExtraIcon from 'assets/svg/heart-extra.svg';
import SearchIcon from 'assets/svg/search.svg';
import { useLocale } from 'locale';
import { Button } from 'ds/components/button';
import { Link } from 'components/link';
import { Route } from 'config/routes';
import config from 'config';
import PreviewCard from 'components/navigation/shortlist-hover-preview/preview-card';

export interface ShortlistHoverPreviewProps {
  previewListings: Array<IBulletin | ICommercialBulletin | IProject | ICommercialProject>;
  isVisible: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onShortlistButtonClick?: () => void;
}

export const ShortlistHoverPreview: React.FC<ShortlistHoverPreviewProps> = ({
  previewListings,
  isVisible,
  onMouseLeave,
  onMouseEnter,
  onShortlistButtonClick,
}) => {
  const { t } = useLocale();

  if (!isVisible) return null;

  return (
    <Root onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {previewListings && previewListings.length ? (
        <div>
          {previewListings.map((poi, index) => (
            <PreviewCard
              key={poi.id}
              id={poi.id}
              type={poi.type}
              streetName={poi.addressDetails && poi.addressDetails.streetName}
              streetNumber={poi.addressDetails && poi.addressDetails.streetNumber}
              withSeparator={index === 0 && previewListings.length > 1}
            />
          ))}
          <ShortlistButtonWrapper>
            <Link routeName={Route.Shortlist} preserveParams onClick={onShortlistButtonClick}>
              <Button size="large" mode="ghost">
                {t('shortlistHoverPreview.shortlistButton.label')}
              </Button>
            </Link>
          </ShortlistButtonWrapper>
        </div>
      ) : (
        <NoListingsWrapper>
          <HeartExtraIcon width={65} height={69.5} />
          <NoListingsTitle weight="bold">{t('shortlistHoverPreview.noListings.title')}</NoListingsTitle>
          <NoListingsSubtitle>{t('shortlistHoverPreview.noListings.subtitle')}</NoListingsSubtitle>
          <Link
            routeName={Route.Search}
            routeParams={{
              dealType: DealType.Buy,
              term: [ config.cityTerm ],
              marketplace: MarketplaceType.Residential,
              tracking_search_source: 'favorites_hover',
            }}
          >
            <Button size="medium">
              <SearchIcon width={16} height={16} />
              {t('shortlistHoverPreview.noListings.startSearchButton.label')}
            </Button>
          </Link>
        </NoListingsWrapper>
      )}
    </Root>
  );
};
