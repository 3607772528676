import React from 'react';
import { Section as DropdownSection } from '../common/DropdownSection';
import { GenericFilterProps } from '../types';
import { ClickSection } from '../styled/dropdown';
import { ControlButton } from 'ds/components/button';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import styled from '@emotion/styled';
import { isFilterActiveByType } from '../utils';
import { SectionTitle, SectionBody, Section } from '../styled/common';
import { useLocale } from 'locale';

const FeeSectionWrapper = styled.div<{ isActive? : boolean}>`
  label > div > div {
    color: ${props => props.isActive ? props.theme.colors.brand.main : props.theme.colors.neutrals.grey2}!important;
  }
`;


interface FeeSectionProps extends GenericFilterProps<'fee'> {}

const FeeSection: React.FC<FeeSectionProps> = ({
  type,
  commitedValue,
  intermediateValue,
  setIntermediateValue,
  commitValues,
  initialValue,
  isMobile,
}) => {
  const isActive = isFilterActiveByType.fee(commitedValue, initialValue);
  const { t } = useLocale();
  const handleChange = () => {
    setIntermediateValue(!intermediateValue, isMobile ? undefined : commitValues);
  };

  return isMobile ? (
    <Section data-section-filter-name={type} data-button-filter-name="noFee" data-auto-filter-name="noFee" data-auto="noFeeFilter">
      <SectionTitle>{t('filters.fee')}</SectionTitle>
      <SectionBody>
        <CheckboxField checked={intermediateValue} onClick={handleChange} small label={t('filters.feeLabelMobile')} />
      </SectionBody>
    </Section>
  ) : (
    <DropdownSection>
      <ClickSection data-button-filter-name="noFee" data-auto-filter-name="noFeeOnly" data-auto="noFeeFilter">
        <FeeSectionWrapper isActive={isActive}>
          <ControlButton mode={isActive ? 'selected' : 'default'}>
            <CheckboxField checked={intermediateValue} onClick={handleChange} small label={t('filters.feeLabel')} />
          </ControlButton>
        </FeeSectionWrapper>
      </ClickSection>
    </DropdownSection>
  );
};

export default FeeSection;
