import { useLocale } from 'locale';
import React from 'react';
import { AnimatedEstimationWrapper, EstimatedPriceLabel, Label } from './styled';
import { animated, useSpring } from 'react-spring';
import { PriceRange } from '../types';
import Tooltip from 'ds/components/tooltip';
import { SmallText } from 'ds/components/typography';


export interface EstimatedPriceContentProps {
  estimatedPrice: number;
  priceRange: PriceRange;
  price: number;
  handleClick: () => void;
}

const EstimatedPriceContent: React.FC<EstimatedPriceContentProps> = ({ estimatedPrice, priceRange, price, handleClick }) => {
  const { t, formatMoney } = useLocale();

  const spring = useSpring({
    delay: 750,
    from: {
      opacity: 0,
      number: price,
    },
    to: [
      { opacity: 1 },
      { number: estimatedPrice },
    ],
  });


  const content = estimatedPrice ? (
    <>
      <Label data-auto="mad-madlan-link" isActive priceRange={priceRange}>
        {t('unitPage.priceEstimation.link', { isEstimatedPrice: true })}
      </Label>
      <Label isActive priceRange={priceRange} style={{ fontWeight: 'bold' }}>
        <animated.span>
          {spring.number.to(val => formatMoney(Math.floor(val)))}
        </animated.span>
      </Label>
    </>

  ) : (
      <Label data-auto="mad-madlan-link" priceRange={priceRange}>
      <Tooltip placement="bottom" tooltip={<SmallText>{t('unitPage.priceEstimation.tooltip')}</SmallText>}>
        {t('unitPage.priceEstimation.link', { isEstimatedPrice: false })}
      </Tooltip>
    </Label>
  );

  return (
    <AnimatedEstimationWrapper style={{ opacity: spring.opacity }}>
      <EstimatedPriceLabel onClick={handleClick} isActive={!!estimatedPrice} priceRange={priceRange}>
        {content}
      </EstimatedPriceLabel>
    </AnimatedEstimationWrapper>
  );
};

export default EstimatedPriceContent;
