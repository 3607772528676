import React from 'react';

import {
  CheckboxWrapper,
} from './Checkbox';
import {
  Switch,
  Toggle,
  Circle,
  ToggleSwitchSize,
} from './Switch';

import { InputProps } from './Input';
import { TextLabel, Text, SmallText } from '../typography';

export type LabelTextMode = 'default' | 'searchIL' | 'tinyBold';

export interface ToggleProps extends InputProps {
  label?: string;
  labelElement?: React.ReactNode;
  reversed?: boolean;
  labelTextMode?: LabelTextMode;
  toggleSize?: ToggleSwitchSize;
}

export const ToggleField: React.FC<ToggleProps> = (props) => {
  const { label, reversed, disabled, checked, labelTextMode, labelElement, toggleSize, ...rest } = props;

  return (
    <CheckboxWrapper reversed={reversed} disabled={disabled}>
      <Switch disabled={disabled} {...rest} />
      <Toggle size={toggleSize} data-auto="toggle-checkbox" disabled={disabled} checked={checked} reversed={reversed}>
        <Circle size={toggleSize} checked={checked} disabled={disabled} />
      </Toggle>
      {labelElement ? labelElement : null}
      {label ? (
        labelTextMode === 'tinyBold'
          ? <TextLabel small caps weight="bold">{label}</TextLabel>
          : labelTextMode === 'searchIL'
            ? <SmallText>{label}</SmallText>
            : <Text>{label}</Text>
      ) : null}
    </CheckboxWrapper>
  );
};

ToggleField.defaultProps = {
  disabled: false,
  label: '',
  checked: false,
  labelTextMode: 'default',
};
