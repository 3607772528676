import { State } from 'store/state';
import { flow } from 'lodash';
import { createSelector } from 'reselect';

export const DEFAULT_COMMUTE_VALUE = 'commute';

export const homepageWizardSelector = (state: State) => state.homepageWizard;
export const homepageWizardCurrentStepSelector = flow(homepageWizardSelector, (state) => state.currentStepIndex);
export const homePageWizardPreviewDocIdSelector = flow(homepageWizardSelector, (state) => state.previewDocId);
export const homePageWizardPurchasingReasonSelector = flow(homepageWizardSelector, (state) => state.purchasingReason);
export const homePageWizardSearchAddressSelector = flow(homepageWizardSelector, (state) => state.address);
export const homePageWizardDealTypeSelector = flow(homepageWizardSelector, (state) => state.dealType);
export const homePageWizardPriceRangeSelector = flow(homepageWizardSelector, (state) => state.priceRange);
export const homePageWizardRoomsRangeSelector = flow(homepageWizardSelector, (state) => state.roomsRange);
export const homepageWizardOpenSelector = flow(homepageWizardSelector, (s) => s.isOpen);
export const homePageWizardCommuteSelector = flow(homepageWizardSelector, (s) => s.commutePreference);
export const homepageWizardCommuteTypeSelector = createSelector([
  homepageWizardSelector,
], ({ commutePreference: { commuteType } }) => commuteType || DEFAULT_COMMUTE_VALUE);
