import { upperFirst } from 'lodash';

export const isServer = () => (
  typeof window === 'undefined'
);

export const removeTypeName = <T extends {}>(obj: T): T => {
  const { __typename, ...rest } = obj as any;
  return rest;
};

export const getDirection = (isRtl: boolean, isMob: boolean): 'right' | 'left' => {
  if (isRtl) return isMob ? 'right' : 'left';
  else return isMob ? 'left' : 'right';
};

export const capitalize = (str: string) => str.split(' ').map(upperFirst).join(' ');

/**
 * memoize with custom arguments equality checker
 * @param func A function to memoize
 * @param argsEqual A function that compares arguments
 * @returns memoized function
 */
export function shallowMemo<Args extends unknown[], RetVal>(
  func: (...args: Args) => RetVal,
  argsEqual: (prev: Args, current: Args) => boolean
): (...args: Args) => RetVal {
  let lastArgs: Args;
  let lastResult: RetVal;

  return (...args: Args) => {
    if (lastArgs !== undefined && argsEqual(args, lastArgs)) {
      return lastResult;
    }

    lastResult = func(...args);
    lastArgs = args;

    return lastResult;
  };
}
/**
 * @deprecated
 * Should be used extremely rarely
 * @param selector input selector
 */
export function createRefSelector<T extends unknown[], P>(selector: (...input: T) => P) {
  const ref: { current: P } = {
    current: null,
  };

  return (...input: T) => {
    ref.current = selector(...input);
    return ref;
  };
}

export const cleanNonAsci = (str: string) => str.replace( /[^\x00-\x7F]/g, '' );
