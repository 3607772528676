import { put, takeEvery, call } from 'redux-saga/effects';
import { LoadType, MutationType } from 'store/sagas/apiService/types';
import { mutate } from 'store/state/mutationsResponse/actions';
import { TOGGLE_FAVORITES } from 'store/state/app/types';
import { toggleFavorites } from 'store/state/app/actions';
import { authGate, AuthGateResponse } from './routing/handlers/utils';
import { PendingActionType, IPendingSaveBulletin } from 'components/authentication/types';
import { fetchMore } from 'store/state/domainData/actions';


function* listingCardWorker(action: ReturnType<typeof toggleFavorites>) {
  const { payload: { favorites, isFavorite } } = action;

  const mutationType = isFavorite ? MutationType.RemoveFavorites : MutationType.AddFavorites;

  const pendingAction: IPendingSaveBulletin = isFavorite ? null : {
    type: PendingActionType.SaveBulletin,
    poi: favorites[0],
  };

  const authGateResponse = yield call(authGate, mutationType === MutationType.AddFavorites ? pendingAction : null);

  if (authGateResponse === AuthGateResponse.Rejected) return;

  yield put(mutate({
    mutationType,
    meta: {
      variables: { favorites },
    },
  }));

  if (!isFavorite) {
    yield put(fetchMore({
      loadType: LoadType.FavoritesPois,
      meta: {
        variables: { ids: favorites },
      },
    }));
  }
}

export function* listingCardWatcher() {
  yield takeEvery(TOGGLE_FAVORITES, listingCardWorker);
}
