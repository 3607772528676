
const si: Array<{ value: number, symbol: string }> = [
  { value: 1, symbol: '' },
  { value: 1E3, symbol: 'k' },
  { value: 1E6, symbol: 'm' },
  { value: 1E9, symbol: 'G' },
  { value: 1E12, symbol: 'T' },
  { value: 1E15, symbol: 'P' },
  { value: 1E18, symbol: 'E' },
];

const hebrewSi: Array<{ value: number, symbol: string }> = [
  { value: 1, symbol: '' },
  { value: 1E3, symbol: 'א' },
  { value: 1E6, symbol: 'מ' },
  { value: 1E9, symbol: 'ג' },
  { value: 1E12, symbol: 'ט' },
  { value: 1E15, symbol: 'פ' },
  { value: 1E18, symbol: 'א' },
];

export enum priceGrid {
  none = 0,
  k = 1E3,
  m = 1E6,
  G = 1E9,
  T = 1E12,
  P = 1E15,
  E = 1E18,
}

export const lessThanM = (v: number): boolean => v < priceGrid.m;

export default function(value: number, digits: number = 2, enforcedSiValue?: number, isHebrew?: boolean) {
  // trimming off trailing zeroes
  const rx: RegExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i = -1;
  const symbols = isHebrew ? hebrewSi : si;

  if (enforcedSiValue) {
    i = symbols.findIndex((s) => s.value === enforcedSiValue);
  }

  if (i === -1) {
    for (i = symbols.length - 1; i > 0; i--) {
      if (Math.abs(value) >= symbols[i].value) {
        break;
      }
    }
  }
  const roundFactor = Math.pow(10, digits);
  return (Math.round(value / symbols[i].value * roundFactor) / roundFactor).toString(10).replace(rx, '$1') + (isHebrew ? ' ' : '') + symbols[i].symbol + (isHebrew ? '׳ ' : '');
}
