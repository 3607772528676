import {
  InsightType,
  ITagsValues,
  CommuteType,
  BestSchool,
  IBulletin,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
} from 'utils/entities';

export enum SortField {
  Price = 'price',
  Date = 'date',
  Beds = 'beds',
  Size = 'size',
  PricePerSqft = 'pricePerSqft',
  BestSchool = 'bestSchool',
  Commute = 'commute',
  Parks = 'parkAccess',
  Safety = 'safety',
  QuietStreets = 'quietStreet',
  FamilyFriendly = 'familyFriendly',
  DogParks = 'dogPark',
  Geometry = 'geometry',
  PriceEstimation = 'priceEstimation',
  BestSecular = 'bestSecular',
  BestReligious = 'bestReligious',
  LightRail = 'lightRail',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortValue = [ SortField, SortDirection ];

export interface ITagBySortValue {
  type: keyof ITagsValues;
  insightType: InsightType;
  value: number;
  school?: BestSchool;
  parkName?: string;
  parkWalkTime?: number;
  lightRail?: string;
  hours?: number;
  mins?: number;
  commuteType?: CommuteType;
}

export interface Config {
  basicOptions: SortValue[];
  advancedOptionsForPriorities: SortValue[];
  insightCategoryBySortField: Partial<Record<SortField, InsightType>>;
  defaultValue: SortValue[];
  getTags: (
    poi: IBulletin | IProject | ICommercialProject | ICommercialBulletin,
    sortFields: SortField[],
    commuteType?: CommuteType
  ) => ITagBySortValue[];
}
