import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const grow = css``;

export const Wrapper = styled.div<{ isFilled: boolean, withWobble: boolean, isTransparent: boolean }>`
  svg {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    path {
      ${({ theme, isFilled, isTransparent }) => `${isFilled ? `
        stroke: #FF6658;
        fill: #FF6658;
      ` : `
        fill: ${isTransparent ?  theme.colors.neutrals.transparent : theme.colors.neutrals.white};
        ${`${isTransparent ? `stroke: ${theme.colors.neutrals.white};` : ''}`};
      `}`};
    }

    &:hover {
      ${({ isFilled }) => `${isFilled && `
        path {
          stroke: #FF958C;
          fill: #FF958C;
        }
      `}`};
      ${({ isFilled, withWobble }) => `${!isFilled && withWobble && `
        animation-name: wobble;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
      `}`};
    }

    @keyframes wobble {
      20% {
        transform: rotate(10deg);
      }
      40% {
        transform: rotate(-10deg);
      }
      60% {
        transform: rotate(5deg);
      }
      80% {
        transform: rotate(-5deg);
      }
      100% {
        transform: rotate(0);
      }
    }
  }

  .grow {
    &:not(:active) {
      ${({ isFilled }) => `${isFilled && `
        animation-name: grow;
        animation-duration: 0.3s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        path {
          stroke: #FF6658;
          fill: #FF6658;
        }
      `}`};
    }
  }

  @keyframes grow {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    to {
      transform: scale(1);
    }
  }
`;
