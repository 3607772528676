import React, { useRef, useState, useMemo } from 'react';
import Modal from 'ds/components/modal';
import { useLocale } from 'locale';
import { useForm } from 'react-final-form-hooks';
import { FeedbackForm } from './feedback-form';
import {
  FeedbackFormFields,
  IFeedbackOption,
  FeedbackPopupOptionsValue,
  FeedbackPopupType,
} from './utils';
import { FeedbackPopupContentWrapper, Description } from './styled';
import { Button } from 'ds/components/button';
import { isEmailValid } from 'utils/formValidationHelpers';

export interface FeedbackPopupViewProps {
  isPopupModalOpen?: boolean;
  onSubmit: (submitPopupData: ISubmitPopupData) => void;
  onClosePopup: () => void;
  options: FeedbackPopupOptionsValue[];
  submitButtonText: string;
  popupTitle: string;
  feedbackType: FeedbackPopupType;
  description?: string;
}

export interface FeedbackFormValues {
  radioButtonOption: FeedbackPopupOptionsValue;
  commentMessage: string;
  userEmail: string;
}

export interface ISubmitPopupData {
  message: string;
  feedback: string;
  email: string;
}

const FormInitialValues = {
  radioButtonOption: '',
  commentMessage: '',
  userEmail: '',
};

export const FeedbackPopupView: React.FC<FeedbackPopupViewProps> = ({
  isPopupModalOpen,
  onClosePopup,
  onSubmit,
  popupTitle,
  options,
  submitButtonText,
  description,
}) => {
  const { t } = useLocale();
  const [ previousRadioButtonOption, setPreviousButtonOption ] = useState<string>(FormInitialValues.radioButtonOption);
  const commentMessageRef = useRef(null);

  const onSubmitForm = (values: any) => {
    // server wants us to send actual label strings
    const feedbackOptionValue = values.radioButtonOption ? t('feedback.popup.options', { option: values.radioButtonOption }) : null;
    const submitPopupDataObj: ISubmitPopupData = {
      message: values.commentMessage,
      feedback: feedbackOptionValue,
      email: values.userEmail,
    };

    onSubmit(submitPopupDataObj);
    onClose();
  };

  const { form, valid } = useForm<FeedbackFormFields>({
    onSubmit: onSubmitForm ,
    initialValues: FormInitialValues,
    validateOnBlur: true,
    validate: (values: FeedbackFormValues) => {
      const { radioButtonOption, commentMessage, userEmail } = values;

      if (radioButtonOption) {
        if (commentMessageRef.current && radioButtonOption !== previousRadioButtonOption) {
          setPreviousButtonOption(radioButtonOption);
          commentMessageRef.current.querySelector('textarea').focus();
        }

        if (radioButtonOption === FeedbackPopupOptionsValue.Other && !commentMessage) {
          return {
            commentMessage: t('feedback.popup.fieldNecessity'),
          };
        }

        if (userEmail && !isEmailValid(userEmail)) {
          return {
            userEmail: t('reportListing.popup.emailError'),
          };
        }
        return {};
      }
      else {
        return { radioButtonOption: t('feedback.popup.fieldNecessity') };
      }
    },
  });

  const createdOptions: IFeedbackOption[] = useMemo(() => (
    options.map(option => ({
      label: t('feedback.popup.options', { option }), value: option,
    }))
  ), [ options, t ]);

  if (!isPopupModalOpen) return null;

  const onClose = () => {
    onClosePopup();
    setTimeout(() => form.reset());
  };

  return (
    <Modal
      title={popupTitle}
      isOpen={isPopupModalOpen}
      onClose={onClose}
      buttons={(
        <Button
          data-auto="feedback-submit-button"
          disabled={!valid}
          onClick={form.submit}
          fullWidth
          size="large"
        >
          {submitButtonText}
        </Button>
      )}
    >
      <>
        {description ? <Description>{description}</Description> : null}
        <FeedbackPopupContentWrapper>
          <FeedbackForm
            inputWrapperRef={(el: HTMLDivElement) => commentMessageRef.current = el}
            options={createdOptions}
            formInstance={form}
          />
        </FeedbackPopupContentWrapper>
      </>
    </Modal>
  );
};
