import {
  makeNullSafeDomainGetter,
  additionalListingCarouselSearchSelector,
  createIsLoadingSelector,
} from 'store/state/domainData/selectors/common';
import { createSelector } from 'reselect';
import { IBulletin, ICommercialBulletin, PocType } from 'utils/entities';
import { shuffle } from 'lodash';

export const additionalListingCarouselSelector = makeNullSafeDomainGetter(additionalListingCarouselSearchSelector, 'searchPoiV2');
export const isAdditionalListingCarouselLoadingSelector = createIsLoadingSelector(additionalListingCarouselSearchSelector);


const EMPTY: Array<IBulletin | ICommercialBulletin> = [];
const relatedAgentListingsListSelector = createSelector(additionalListingCarouselSelector, (search) => search && search.poi ? search.poi : EMPTY);

export function filterRelevantAgentsListings(pois: Array<IBulletin | ICommercialBulletin>) {
  const uniqAgencyIds = new Set([]);
  const uniqListings = [];
  const restListings = [];
  const shuffledPois = shuffle(pois);

  for (const listing of shuffledPois) {
    const poc = listing && listing.poc;
    const officeId = poc && listing.poc.type === PocType.Agent && 'officeId' in poc && poc.officeId || null;

    if (officeId) {
      if (uniqAgencyIds.has(officeId)) {
        restListings.push(listing);
      }
      else {
        uniqAgencyIds.add(officeId);
        uniqListings.push(listing);
      }
    }
  }

  return [ ...uniqListings, ...restListings ].slice(0, 10);
}

export const relatedAgentListingsSelector = createSelector(relatedAgentListingsListSelector, filterRelevantAgentsListings);

export const hasRelatedAgentListingsSelector = createSelector(relatedAgentListingsSelector, list => Boolean(list && list.length));
