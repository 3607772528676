import {
  bannersByDocIdDomainSelector,
  makeNullSafeDomainGetter,
  createNullSafeDomainMetaVariablesSelector,
  additionalBannersByDocIdDomainSelector,
  searchPageBannersByDocIdDomainSelector,
} from './common';
import { createSelector } from 'reselect';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';

const bannersByDocIdSelector = makeNullSafeDomainGetter(bannersByDocIdDomainSelector, 'bannersByDocId');
const searchPageBannersByDocIdSelector = makeNullSafeDomainGetter(searchPageBannersByDocIdDomainSelector, 'bannersByDocId');
const additionalBannersByDocIdSelector = makeNullSafeDomainGetter(additionalBannersByDocIdDomainSelector, 'bannersByDocId');

export const searchPageBannersByDocIdVariablesSelector = createNullSafeDomainMetaVariablesSelector(bannersByDocIdDomainSelector);

export const bannersSelector = createSelector(
  bannersByDocIdSelector,
  bannerRecords => bannerRecords
    ? bannerRecords.filter(bannerRecord => bannerRecord.desktopImage || bannerRecord.mobileImage)
    : []
);

export const searchPageBannersSelector = createSelector(
  searchPageBannersByDocIdSelector,
  (banners) =>
    banners ? banners.filter((b) => b.desktopImage || b.mobileImage) : []
);

export const bannersByRouteSelector = createSelector(
  [ bannersSelector, searchPageBannersSelector, routeSelector ],
  (banners, searchPageBanners, route) =>
    [ Route.Search, Route.SearchCommercial ].includes(route && route.name)
      ? searchPageBanners
      : banners
);

export const additionalBannersSelector = createSelector(
  additionalBannersByDocIdSelector,
  bannerRecords => bannerRecords
    ? bannerRecords.filter(bannerRecord => bannerRecord.desktopImage || bannerRecord.mobileImage)
    : []
);

export const hasMobileAdditionalBannersSelector = createSelector(
  additionalBannersSelector,
  (banners): boolean => {
    if (!banners || !banners.length) return false;

    const mobileBanners = banners.filter(banner => !!banner.mobileImage);
    return !!mobileBanners.length;
  }
);
