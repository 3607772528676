import React, { ReactNode } from 'react';
import WidgetTitle from 'components/widget/common/widget-title';
import { ScrollSpyTarget } from 'components/scroll-spy';
import { WithContentSlotSidePaddingContainer } from 'ds/components/Layout';
import { InsightType } from 'utils/entities';
import { InsightSection } from 'store/state/insightsContext';
import { InsightCarouselShiftedPadding, InsightsSectionItemInnerPadding } from 'ds/components/Insights';

interface WidgetWrapperProps {
  styleType: InsightType;
  targetId: InsightSection;
  title: string;
  carousel?: ReactNode;
  transportationData?: ReactNode;
}

const WidgetWrapper: React.FC<WidgetWrapperProps> = ({
  styleType,
  title,
  targetId,
  children,
  carousel,
  transportationData,
}) => (
  <ScrollSpyTarget targetId={targetId}>
    {title ? (
      <WithContentSlotSidePaddingContainer>
        <WidgetTitle styleType={styleType} title={title} />
      </WithContentSlotSidePaddingContainer>
    ) : null}
    {transportationData ? (
      <InsightsSectionItemInnerPadding>
        {transportationData}
      </InsightsSectionItemInnerPadding>
    ) : null}
    {children ? (
      <WithContentSlotSidePaddingContainer noMobilePadding>
        {carousel ? (
          <InsightsSectionItemInnerPadding data-auto={`${styleType}-insight-container`}>
            {children}
          </InsightsSectionItemInnerPadding>
        ) : children}
      </WithContentSlotSidePaddingContainer>
    ) : null}
    {carousel ? (
      <InsightCarouselShiftedPadding data-auto={`${styleType}-insight-carousel`} side="both">
        {carousel}
      </InsightCarouselShiftedPadding>
    ) : null}
  </ScrollSpyTarget>
);

export default WidgetWrapper;
