import css from '@emotion/css';
import styled from '@emotion/styled';
import { Theme } from 'ds';
import { Interpolation } from '@emotion/serialize';
import {
  VisibilityProps,
  getMinMaxBreakpoints,
  setPointer,
  SingleRangeProps,
  PointerMode,
  DefinedRangeProps,
} from 'consts/breakpoints';

const showAt = (visibilityProps: VisibilityProps): any => {
  const { minWidthBreakpoint, maxWidthBreakpoint, isTouch } = getMinMaxBreakpoints(visibilityProps);

  return (...styles: Interpolation []) => css`
    @media screen and (min-width: ${minWidthBreakpoint}) and (max-width: ${maxWidthBreakpoint}) ${isTouch !== null ? `and (pointer: ${setPointer(isTouch, 'fine')})` : ''} {
      ${css(...styles)};
    }
  `;
};

const createObjectMediaQuery = (visibilityProps: VisibilityProps): string => {
  const { minWidthBreakpoint, maxWidthBreakpoint, isTouch } = getMinMaxBreakpoints(visibilityProps);

  return `@media screen and (min-width: ${minWidthBreakpoint}) and (max-width: ${maxWidthBreakpoint}) ${isTouch !== null ? `and (pointer: ${setPointer(isTouch, 'fine')})` : ''}`;
};

const createCustomObjectMediaQuery = (min: string, max: string): string => {
  return `@media screen and (min-width: ${min}) and (max-width: ${max})`;
};

export const media = {
  showAt,
  createObjectMediaQuery,
  // if its here it does not mean that you need to use it, it is just a requirement
  createCustomObjectMediaQuery,
};

export const getMediaQuery = (minWidthBreakpoint: number | null , maxWidthBreakpoint: number | null, isTouch: boolean | null) => {
  return [
    minWidthBreakpoint !== null && `(min-width: ${minWidthBreakpoint})`,
    maxWidthBreakpoint !== null && `(max-width: ${maxWidthBreakpoint})`,
    isTouch !== null && `(pointer: ${setPointer(isTouch, 'fine')})`,
  ].filter(Boolean).join(' and ');
};

const calculateRangeAndStyles = (props: (SingleRangeProps & PointerMode & { theme?: Theme }) | (DefinedRangeProps & PointerMode & { theme?: Theme })) => {
  const { minWidthBreakpoint, maxWidthBreakpoint, isTouch } = getMinMaxBreakpoints(props);
  const query = getMediaQuery(minWidthBreakpoint, maxWidthBreakpoint, isTouch);

  return () => css`
    display: none;
    @media screen and ${query} {
      display: block;
    }
  `;
};


export const StyledShowAt = styled.div<VisibilityProps> `
  ${(props) => calculateRangeAndStyles(props)};
`;
