import { RoomOption, IFiltersState, BathOption, RangeTuple, BudgetSliderProps } from './types';
import { isEqual, mapValues, flow } from 'lodash';
import { config } from './config';
import { DeepReadonly } from 'utility-types';
import { DealType, IAutocompleteEntry, MarketplaceType } from 'utils/entities';
import { DecodedRouteParams, Route, State as RouteState } from 'config/routes';
import { NavigationOptions } from 'router5';



const EMPTY_ARR: [] = [];
export const EMPTY_TUPLE: RangeTuple<null> = [ null, null ];
const DEFAULT_DEAL_TYPE = DealType.Buy;

export const makeCallAll = (...cbs: Array<() => void>) => () => cbs.forEach((cb) => cb());

export const mergeWithInitialState = (filters: DeepReadonly<Partial<IFiltersState>> = {}, dealType: DealType, marketplace: MarketplaceType): IFiltersState => {
  const initialState = getInitialState(dealType, marketplace);
  return mapValues(initialState, (value, key) => filters[key] || value);
};

const getExactValueFromRange = <T extends number>([ from, to ]: RangeTuple<T>): T => (
  // if one option selected, RangeSelect emits [ value, null ] type of tuple
  // exactValue indicates whether there's just one option selected
  from !== null && to === null ? from : null
);

const normalizeMoneyRange = (value: [ number, number ], budgetSliderProps: BudgetSliderProps): [ number, number ] => {
  const { min, max } = budgetSliderProps;
  let [ from, to ] = value;
  if (from === min) from = null;
  if (to === max) to = null;
  return [ from, to ];
};

type RangeNormalizerForBE<T> = (range: [ T, T ], marketplace: MarketplaceType) => [ T, T ];

export const makeNormalizeRangeForBE = <T extends number>(baseNormalizer: RangeNormalizerForBE<T>): RangeNormalizerForBE<T> => (inputRange, marketplace) => {
  const [ inputFrom ] = inputRange;
  // tslint:disable-next-line: prefer-const
  let [ f, t ] = baseNormalizer(inputRange, marketplace);
  // Backend does not understand what it means to have first tuple entry = null
  if (f === null) f = inputFrom;
  return [ f, t ];
};

export const normalizePriceRange = (value: [ number, number ], marketplace: MarketplaceType, dealType = DEFAULT_DEAL_TYPE): [ number, number ] => {
  return normalizeMoneyRange(value, config[marketplace].budgetByDealType[dealType].budgetSliderProps);
};

export const normalizePPMRange = (value: [ number, number ], marketplace: MarketplaceType, dealType = DEFAULT_DEAL_TYPE): [ number, number ] => {
  return normalizeMoneyRange(value, config[marketplace].budgetByPerSquareMeter[dealType].budgetSliderProps);
};

export const normalizeMonthlyTaxRange = (value: [ number, number ], marketplace: MarketplaceType): [ number, number ] => {
  const monthlyTaxRange = config[marketplace].monthlyTaxRange;
  if (!monthlyTaxRange) {
    return [ null, null ];
  }
  return normalizeMoneyRange(value, monthlyTaxRange.budgetSliderProps);
};

const rangeNormalizer = <T extends number>(range: RangeTuple<T>, options: T[], handleExactValue: boolean): RangeTuple<T> => {
  const [ from, to ] = range;
  const exactValue = getExactValueFromRange(range);
  const minOption = Math.min(...options);
  const maxOption = Math.max(...options);

  if (handleExactValue && exactValue !== null && exactValue !== maxOption) {
    return [ exactValue, exactValue ];
  }

  return [
    // if 'from' value is equal to minimum then we should consider it as null
    // for normalization. same is for 'to' value / maximum
    minOption === from ? null : from,
    maxOption === to ? null : to,
  ];
};

type RangeNormalizer<T extends number> = (inp: RangeTuple<T>, marketplace: MarketplaceType, handleExactValue?: boolean) => RangeTuple<T>;

export const normalizeRoomsRange: RangeNormalizer<RoomOption> = (inp, marketplace, handleExactValue = true) => rangeNormalizer(inp, config[marketplace].roomsOptions, handleExactValue);
export const normalizeBathsRange: RangeNormalizer<BathOption> = (inp, marketplace, handleExactValue = true) => rangeNormalizer(inp, config[marketplace].bathsOptions, handleExactValue);
export const normalizeFloorsRange: RangeNormalizer<number> = (inp, marketplace, handleExactValue = true) => rangeNormalizer(inp, config[marketplace].floorsOptions, handleExactValue);

const isRangeValueActiveFactory = <T extends number>(normalizer: RangeNormalizer<T>) => (value: RangeTuple<T>, initialValue: RangeTuple<T>, marketplace: MarketplaceType) => {
  const normalized = normalizer(value, marketplace);
  const exactValue = getExactValueFromRange(value);

  return !(
    exactValue === null && (
      isEqual(value, initialValue)
      || isEqual(normalized, initialValue)
    )
  );
};
export type FilterType = keyof IFiltersState;

type IsActivePredicate<P extends FilterType> = (value: IFiltersState[P], initialValue: IFiltersState[P], marketplace?: MarketplaceType) => boolean;

export type FnsMap = { [K in FilterType]: IsActivePredicate<K> };

const roomsRangeActiveGetter: IsActivePredicate<'roomsRange'> = (v, i, m) => {
  if (m === MarketplaceType.Residential) return isRangeValueActiveFactory(normalizeRoomsRange)(v, i, m);
  return !isEqual(v, i);
};

export const isFilterActiveByType: FnsMap = {
  amenities: (value) => value.length > 0,
  propertyTypes: (value) => value.length > 0,
  priceRange: (value, initialValue) => !isEqual(value, initialValue),
  ppmRange: (value, initialValue) => !isEqual(value, initialValue),
  monthlyTaxRange: (value, initialValue) => !isEqual(value, initialValue),
  roomsRange: roomsRangeActiveGetter,
  bathsRange: isRangeValueActiveFactory(normalizeBathsRange),
  floorRange: isRangeValueActiveFactory(normalizeFloorsRange),
  fee: value => !!value,
  projectDiscount: value => !!value,
  dealType: (value, initialValue) => !isEqual(value, initialValue),
  conditions: (value) => value.length > 0,
  seller: (value) => value.length > 0,
  areaRange: (value, initialValue) => !isEqual(value, initialValue),
  priceChanges: (value) => value.priceDrop || value.underPriceEstimation,
  numberOfEmployeesRange: (value, initialValue) => !isEqual(value, initialValue),
  qualityClass: (value, initialValue) => !isEqual(value, initialValue),
};

export const getActiveFilters = (state: IFiltersState, marketplace: MarketplaceType): FilterType[] => (
  Object.keys(isFilterActiveByType).reduce(<K extends FilterType>(acc: FilterType[], key: K) => {
    const checkerFn: IsActivePredicate<K> = isFilterActiveByType[key] as IsActivePredicate<K>;
    const isActive = checkerFn(state[key], getInitialState(state.dealType, marketplace)[key], marketplace);
    if (isActive) acc.push(key);
    return acc;
  }, [])
);

export const getActiveFiltersNumber = flow(getActiveFilters, (a) => a.length);


export const getInitialState = (dealType = DEFAULT_DEAL_TYPE, marketplace: MarketplaceType): IFiltersState => ({
  fee: false,
  dealType,
  projectDiscount: false,
  priceRange: config[marketplace].budgetByDealType[dealType].initialBudgetValue,
  ppmRange: config[marketplace].budgetByPerSquareMeter ? config[marketplace].budgetByPerSquareMeter[dealType].initialBudgetValue : null,
  monthlyTaxRange: config[marketplace].monthlyTaxRange ? config[marketplace].monthlyTaxRange.initialBudgetValue : null,
  roomsRange: EMPTY_TUPLE,
  bathsRange: EMPTY_TUPLE,
  floorRange: EMPTY_TUPLE,
  areaRange: EMPTY_TUPLE,
  propertyTypes: EMPTY_ARR,
  amenities: EMPTY_ARR,
  conditions: EMPTY_ARR,
  seller: EMPTY_ARR,
  priceChanges: config[marketplace].priceChanges,
  numberOfEmployeesRange: EMPTY_TUPLE,
  qualityClass: [],
});


export const parseRangeValues = (val: number) => val === -1 ? null : val;

export const convertToEventType = (val: string) => val === 'propertyTypes' ? 'propertyType' : val;

export const prepareRangeValues = (values: RangeTuple<number>) => values.map( val => val === null ? -1 : val);

export const isEmptyRange = <T extends number>([ from, to ]: RangeTuple<T>) =>
  from === null && to === null;

type Navigate = (name: string, params?: Record<string, any>, opts?: NavigationOptions) => {
  type: 'ROUTER/NAVIGATE';
  payload: {
    name: string;
    params: Record<string, any>;
    opts: NavigationOptions;
  };
};

export const setFiltersState = (value: IFiltersState, route: RouteState, resolvedAddress: IAutocompleteEntry, navigate: Navigate) => {
  let params: DecodedRouteParams = {
    ...route.params,
    filters: value,
    dealType: value.dealType,
    page: undefined,
    address: undefined,
  };

  if (route.params.dealType && route.name === Route.AddressPage) {
    params = {
      ...params,
      address: resolvedAddress.docId,
    };
  }

  navigate(
    route.name,
    route.name === Route.Search
      ? { ...params, tracking_search_source: 'filter_apply' }
      : params,
    { replace: route.name !== Route.Search, source: 'filters' }
    );
};
