import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import ShareIcon from 'assets/svg/share.svg';

export const EnhancedShareButton = styled(ShareIcon)`
  height: 32px;
  width: 32px;
  padding: 4px;
  ${({ theme }) => `background: ${colorToAlpha(theme.colors.neutrals.black, 0.4)}`};
  border-radius: ${({ theme }) => theme.borderRadius.round};
`;

export const ItemWrapper = styled.div<{ negativeMargin?: boolean }>`
  ${({ theme, negativeMargin }) => `
    ${negativeMargin && `margin: -8px`};
    display: flex;
    flex: 1;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    svg {
      margin: ${theme.spacing(0.5)} 0;
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    svg {
      margin-top: ${theme.spacing(0.25)};
    }
  `}
`;

export const IconWrapper = styled.div<{ isWhite?: boolean }>`
  ${({ theme, isWhite }) => `
    svg {
      vertical-align: middle;
      path {
        fill: ${isWhite ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
      }
    }
  `}
`;
