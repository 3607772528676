import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';

export const Wrapper = styled.div`
  padding: 56px 0 52px;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    z-index: 20;
    &:before {
      content: '';
      position: fixed;
      background: ${theme.colors.neutrals.white};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  `}
`;

export const MobileOverlayContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  min-height: calc(100vh - 108px);
`;

export const SecondaryTitle = styled.span`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  &:before {
    content: " · ";
    position: relative;
    top: 2px;
  }
`;

export const ColorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const CommonAmenitiesWrapper = styled.div`
  font-family: NovemberHebrew;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.neutrals.grey1};
  display: flex;
  flex-wrap: wrap;
`;

export const CommonAmenitiesCell = styled.div<{ isSelected?: boolean }>`
  border-${ props => props.theme.isRTL ? 'left' : 'right' }: 1px solid ${props => props.theme.colors.neutrals.grey8};
  border-top: 1px solid ${props => props.theme.colors.neutrals.grey8};
  padding: 24px;
  width: 50%;
  background-color: ${({ theme, isSelected }) => isSelected ? colorToAlpha(theme.colors.brand.main, 0.05) : 'transparent'};
  color: ${props => props.isSelected ? props.theme.colors.brand.main : props.theme.colors.neutrals.grey1};
  svg {
    & * {
      fill: ${props => props.isSelected ? props.theme.colors.brand.main : props.theme.colors.neutrals.grey1};
    }
  }
  &:nth-of-type(1), &:nth-of-type(2) {
    border-top: none;
  }
  &:nth-of-type(2n) {
    border-${ props => props.theme.isRTL ? 'left' : 'right' }: none;
  }
`;

export const Header = styled.h4`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NovemberHebrew;
  font-size: 15px;
  font-weight: normal;
  line-height: 1;
  width: 100%;
  height: 56px;
  background: ${({ theme }) => theme.colors.neutrals.white};
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  box-shadow: ${({ theme }) => theme.shadow.level0};
  svg {
    position: absolute;
    width: 36px;
    height: 36px;
    padding: 12px;
    right: 12px;
    top: 50%;
    margin-top: -18px;
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey5};
    }
  }
`;

interface SectionProps {
  isOpen?: boolean;
}

export const Section = styled.section<SectionProps>`
  ${({ theme, isOpen }) => `
    padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(1.5)};
    ${isOpen !== undefined ? (
      isOpen ? `
          & > header:first-of-type {
            & > svg:last-of-type {
              transform: rotate(180deg);
            }
          }
          & > *:last-child {
            max-height: 5000px;
            overflow: hidden;
            transition: max-height 1.5s ease, padding-top .4s ease, padding-bottom .4s ease;
          }
        ` : `
          & > *:last-child {
            max-height: 0px;
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
            transition: max-height .2s ease, padding-top .2s ease, padding-bottom .2s ease;
          }
        `
    ) : ''}
  `}
`;

export const SectionTitle = styled.header<{ isBold?: boolean }>`
  ${({ theme, isBold }) => `
    margin-bottom: 24px;
    font-family: NovemberHebrew;
    font-size: 17px;
    font-weight: ${isBold ? 700 : 500};
    line-height: 1;
    color: ${theme.colors.neutrals.grey1};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    svg {
      transition: all .2s ease;
      path {
        fill: ${theme.colors.neutrals.grey3};
      }
    }
  `}
`;

export const SmallSectionTitle = styled.header`
  margin-bottom: 8px;
  font-family: NovemberHebrew;
  font-size: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  text-align: center;
`;

export const SectionHeader = styled(SmallSectionTitle)<{ isInline?: boolean }>`
  margin-bottom: 16px;
  font-size: 17px;
  text-align: right;
  font-weight: ${({ isInline }) => isInline ? 700 : 500};
`;

export const SectionBody = styled.div`
  ${({ theme }) => `
    padding-bottom: ${theme.spacing(3)};
  `}
`;

export const SectionScrollBody = styled.div`
  overflow: auto;
`;

export const SectionSubTitle = styled.div`
  padding: 0 0 24px;
`;

export const CollapsableContainer = styled.div`
  & > .collapsible {
    overflow: hidden;
  }
`;

interface CollapsableButtonProps {
  isCollapsed: boolean;
}

export const CollapsableButton = styled.div<CollapsableButtonProps>`
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  color: ${({ theme }) => theme.colors.brand.main};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
  }
`;

export const controlsFooterHeight = '52px';
export const ControlsFooter = styled.div<{ hasScrollShadow?: boolean }>`
  ${({ theme, hasScrollShadow }) => `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.spacing(3)};
    height: ${controlsFooterHeight};
    background: ${theme.colors.neutrals.white};
    z-index: 3;
    border-top: 1px solid ${theme.colors.neutrals.grey8};
    ${hasScrollShadow ? `box-shadow: ${theme.shadow.level2Top}` : ''};
  `}
`;

export const CheckboxGroupTitle = styled.div`
  margin-top: 24px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  & span:before {
    content: " · ";
  }
`;

export const FilterDivide = styled.div`
  ${({ theme }) => `
    height: 1px;
    background-color: ${theme.colors.neutrals.grey8};
  `}
`;

export const FilterList = styled.div<{ disabled?: boolean }>`
  ${props => props.theme.media.showAt({ at: 1 })`
    overflow-x: auto;
    overflow-y: hidden;
  `}
  ${props => props.theme.media.showAt({ at: 2 })`
    position: relative;
  `}

  ${props => props.disabled && `
    overflow: hidden;
    > * {
      opacity: 0;
      pointer-events: none;
    }
  `}
  button,
  button > label {
    cursor: pointer;
  }
`;

export const AdditionalFiltersWrapper = styled.div`
  & > div > div > div {
    &:nth-of-type(2) {
      ${({ theme }) => theme.isRTL ? 'left' : 'right'}: 0;
    }
  }
`;

export const SizeFilterWrapper = styled.div``;

export const PriceFiltersWrapper = styled.div`
  & > div > div > div {
    &:nth-of-type(2) {
      & > div > div:nth-of-type(1) {
        > section:nth-of-type(2) > div:nth-of-type(1) {
          border: 0;
        }
      }
    }
  }
`;

export const PropertyTypeWrapper = styled.div<{isInline: boolean, long?: boolean; isStraight?: boolean}>`
  ${({ isInline, long, theme }) => isInline ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > div {
      flex-shrink: 0;
      flex-grow: 0;
      ${long ? `
        flex-basis: 252px;
        &:not(:nth-of-type(even)) {
          margin-${theme.isRTL ? 'left' : 'right'}: 42px;
        }
        &:not(:nth-of-type(n+3)) {
          padding-top: 0
        }
      ` : `
        flex-basis: 150px;
        &:not(:nth-of-type(3n)) {
          margin-${theme.isRTL ? 'left' : 'right'}: 42px;
        }
        &:nth-of-type(2) {
          padding-top: 0;
        }
        &:nth-of-type(3) {
          padding-top: 0;
        }
      `}
    }
  ` : ''}
`;

export const PriceWrapper = styled.div``;

export const CheckboxWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
