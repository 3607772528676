import React, { useMemo } from 'react';
import AgentTags from 'components/cards/new-layout/components/AgentTags';
import Price from 'components/cards/new-layout/components/Price';
import { getBulletinInfoConfig } from 'components/cards/new-layout/components/info-line-config';
import { useLocale } from 'locale';
import { PropertyPrimaryLine, PropertySecondaryLine } from 'components/cards/new-layout/styled';
import renderLineItem from 'components/labelLineItem';
import { useUniversalCardAddress } from 'components/cards/listing/UniversalCard/utils';
import { getBuildingClassKey } from 'utils/entities';
import { SpacingBlock } from 'components/spacing-block';
import { InfoSidePaddingContainer, LastInfoLine } from './styled';
import { BulletinUniversalCardProps } from 'components/cards/types';

const BulletinCard: React.FC<BulletinUniversalCardProps> = (props) => {
  const {
    isAgent,
    isExclusive,
    madadSearchResult,
    price,
    eventsHistory,
    bedsCount,
    size,
    floor,
    buildingClass,
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
  } = props;
  const localeProps = useLocale();
  const { t } = localeProps;

  const infoLineConfig = useMemo(() => {
    return getBulletinInfoConfig(bedsCount, size, floor, buildingClass, localeProps).filter(({ showCondition }) => showCondition);
  }, [ bedsCount, size, buildingClass, localeProps,  getBulletinInfoConfig ]);

  const propertyTypeLabel = !!buildingClass ? (
    t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial: false })
  ) : null;

  const primaryAddress = useUniversalCardAddress(
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    true
  );

  const secondaryLine = [ propertyTypeLabel, primaryAddress ].filter(Boolean).join(', ');

  return (
    <InfoSidePaddingContainer>
      <Price price={price} eventsHistory={eventsHistory} hidePriceDrop size="small" />
      <SpacingBlock mTop={0.5}>
        <PropertyPrimaryLine>
          {infoLineConfig.map(renderLineItem)}
        </PropertyPrimaryLine>
      </SpacingBlock>
      <SpacingBlock mTop={0.5}>
        <PropertySecondaryLine data-auto="property-address">
          {secondaryLine}
        </PropertySecondaryLine>
      </SpacingBlock>
      {isAgent ? (
        <LastInfoLine>
          <AgentTags isExclusive={isExclusive} madadSearchResult={madadSearchResult} />
        </LastInfoLine>
      ) : null}
    </InfoSidePaddingContainer>
  );
};

export default BulletinCard;
