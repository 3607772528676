import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from 'ds/components/button';
import { useLocale } from 'locale';
import { ContactAgentModalSource, ContactModalOpenMeta } from 'store/state/app';
import { setContactAgentOpen, submitContactAgent } from 'store/state/app/actions';
import { PoiId } from 'utils/entities';
import { contactPageResponseSelector, contactAgentResponseSelector } from 'store/state/mutationsResponse/selectors';
import { State } from 'store/state';
import CheckIcon from 'assets/svg/ok-check.svg';
import { LoadingDots } from 'ds/components/button/FeedbackButton';
import { SubmitContactAgentPayload, ContactAgentSubmitterSource } from 'store/state/app/payloads';
import { contactFormInitialValuesSelector } from 'helpers/contact';
import { IContactAgentValues } from 'helpers/contactFormStore';
import { LeadType } from 'store/sagas/apiService/types';
import withEvents from 'analytics/withEvents';
import { RequestType } from 'components/contact-modal/HebrewContactModal';

const Root = styled.div`
  ${({ theme }) => `
    &&& {
      margin-top: -${theme.spacing(2.5)};
      z-index: 1;
      position: relative;
    }
  `}
`;

const ButtonWrapper = styled.div`
  text-align: center;
  > button {
    min-width: 140px;
  }
`;

export interface ContactButtonOwnProps {
  id: PoiId;
  source: ContactAgentModalSource;
  type?: LeadType;
}

interface ContactButtonProps extends ContactButtonOwnProps {
  isContacted: boolean;
  isLoading: boolean;
  contactDetails: IContactAgentValues;
  openContactModal: (id: PoiId, meta: ContactModalOpenMeta) => void;
  onContactPageSubmit: (payload: SubmitContactAgentPayload) => void;
}


export const ContactButton: React.FC<ContactButtonProps> = (props) => {
  const { id, source, isContacted, isLoading, openContactModal,
    contactDetails, onContactPageSubmit, type = LeadType.Project } = props;
  const { t } = useLocale();
  const hasContactDetails = Boolean(contactDetails && contactDetails.phone);
  const onContactClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (isLoading) return;
    if (hasContactDetails) {
      onContactPageSubmit({ key: id, source: ContactAgentSubmitterSource.Form, variables: {
        id,
        isMortgageConsult: false,
        phone: contactDetails.phone,
        name: contactDetails.name,
        type,
        eventSource: source,
      }});
      return;
    }

    openContactModal(id, { source });
  };

  return (
    <Root>
      <ButtonWrapper>
        {isContacted ? (
          <Button data-auto="button-contact-seller" disabled size="large" mode="ghost">
            <CheckIcon width={24} height={24} />
            {t('nearbyProjectsSection.contacted')}
          </Button>
        ) : (
          <Button data-auto="button-contact-seller" onClick={onContactClick} size="large" >
            {isLoading ? <LoadingDots /> : t('nearbyProjectsSection.contact', { hasContactDetails })}
          </Button>
        )}
      </ButtonWrapper>
    </Root>
  );
};

const mapStateToProps = (state: State, props: ContactButtonOwnProps) => {
  const contactResponse = contactPageResponseSelector(state, props.id);
  const isContacted = Boolean(contactResponse && contactResponse.data && contactResponse.data.contactV4);
  const isLoading = Boolean(contactResponse && contactResponse.loading);
  const lastContactedAgentResponse = contactAgentResponseSelector(state);
  const lastContactedPoiId = lastContactedAgentResponse && lastContactedAgentResponse.meta.variables.contactId.id;
  let contactDetails = contactFormInitialValuesSelector(state);
  if (lastContactedPoiId) {
    const lastContactResponse = contactPageResponseSelector(state, lastContactedPoiId);
    if (lastContactResponse) {
      contactDetails = {
        ...contactDetails,
        name: lastContactResponse.meta.variables.name,
        phone: lastContactResponse.meta.variables.phone,
        email: lastContactResponse.meta.variables.email,
      };
    }
  }

  return {
    ...props,
    isContacted,
    isLoading,
    contactDetails,
  };
};

const ContactButtonWithEvents = withEvents<ContactButtonProps>((sendEvent, props) => ({
  onContactPageSubmit() {
    sendEvent('property_lead_form_submit', 'property', {
      event: {
        contact_details: {
          email: props.contactDetails.email,
          phone: props.contactDetails.phone,
        },
        source: props.source,
        request_type: RequestType.RequestInfo,
        message: '',
      },
      bulletinId: props.id,
    });
  },
}))(ContactButton);

const mapDispatchToProps = {
  openContactModal: setContactAgentOpen,
  onContactPageSubmit: submitContactAgent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactButtonWithEvents);
