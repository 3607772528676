import React, { useMemo } from 'react';
import { useLocale, WithLocaleProps, ITranslate } from 'locale';
import { IApartmentType } from 'utils/entities';
import {
  Body,
  PropertyDetailsText,
  NewPropertyDetailsSeparator,
  PropertyDetailsSeparator,
} from 'ds/components/ListingCard';
import {
  FirstLineLabel,
  LineLabels,
  SecondLineLabel,
  ThirdLineLabel,
} from '../styled';
import { BreakpointValue } from 'consts/breakpoints';
import NotificationTag from '../NotificationTag';
import renderLineItem from 'components/labelLineItem';
import { UniversalCardLineLabels } from '../utils';
import { head } from 'lodash';
import { CommercialProjectUniversalCardProps, RangeValues, ExtraProps } from 'components/cards/types';


export interface UniversalCommercialProjectCardProps extends CommercialProjectUniversalCardProps, ExtraProps {
  breakpoint: BreakpointValue;
  carousel: JSX.Element;
  handleOnMouseEnter: () => void;
  handleOnMouseLeave: () => void;
}

const UP_CASE_APARTMENT_TYPE_RANK_MAP = {
  OFFICE: 1000,
  SHOP: 900,
  CLINIC: 800,
  INDUSTRIAL: 700,
  HOTEL: 600,
  LAND: 500,
  HALL: 400,
  STOREROOM: 300,
  PARKING: 200,
};

function getApartmentTypeText(apartmentTypes: IApartmentType[], t: ITranslate): string {

  if (apartmentTypes.length > 0) {
    const headType = apartmentTypes[0].type;
    const onlyOneKind = apartmentTypes.every(({ type }) => type === headType);

    if (onlyOneKind) {
      return t('universalCard.project.apartmentType', { apartmentType: headType.toLocaleLowerCase() });
    }
    else {
      const sortedApartments = apartmentTypes
        .map((ap) => ({ ...ap, rank: UP_CASE_APARTMENT_TYPE_RANK_MAP[ap.type] }))
        .filter(({ rank }) => rank)
        .sort(({ rank: ARank }, { rank: BRank }) => BRank - ARank);

      if (sortedApartments.length) {
        const text = t('universalCard.project.apartmentType', { apartmentType: head(sortedApartments).type.toLocaleLowerCase() });
        return t('and.more.other', { text });
      }
      return null;
    }
  }
  return null;
}

const getInfoLineConfig = (apartmentType: IApartmentType[] = [], isPromoted: boolean, priceRange: RangeValues, floorRange: RangeValues, { t, formatMoney }: WithLocaleProps, breakpoint: BreakpointValue) => {
  const isMobile = breakpoint <= 2;
  const safeApartmentType = apartmentType.filter(({ type }) => type);
  const apartmentTypeText = getApartmentTypeText(safeApartmentType, t);

  return [
    {
      label: UniversalCardLineLabels.ApartmentType,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: Boolean(apartmentTypeText),
      render: () => (
        <PropertyDetailsText isPromoted={isPromoted}>
          {apartmentTypeText}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: (Boolean(apartmentTypeText) || Boolean(priceRange && priceRange.min)) && Boolean(floorRange && floorRange.max),
      render: () => (
        <PropertyDetailsText data-auto="property-floor" isPromoted={isPromoted}>
          {t('universalCard.project.floor', { floor: floorRange.max })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Price,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: Boolean(priceRange && priceRange.min),
      render: () => (
        <PropertyDetailsText isPromoted={isPromoted}>
          {t('universalCard.project.minPrice', { minPrice: formatMoney(priceRange.min) })}
        </PropertyDetailsText>
      ),
    },
  ];
};

const UniversalCommercialProjectCard: React.FC<UniversalCommercialProjectCardProps> = (props) => {

  const localeProps = useLocale();
  const { t } = localeProps;
  const {
    withPaddingBottom,
    city,
    notificationTags,
    carousel,
    breakpoint,
    handleOnMouseEnter,
    handleOnMouseLeave,
    projectName,
    apartmentType,
    isPromoted,
    priceRange,
    floorRange,
  } = props;

  const [ notificationTag ] = notificationTags || [];
  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');

  const infoLineConfig = useMemo(() => {
    return getInfoLineConfig(apartmentType, isPromoted, priceRange, floorRange, localeProps, breakpoint).filter(({ showCondition }) => showCondition);
  }, [ apartmentType, isPromoted, priceRange, localeProps, breakpoint, getInfoLineConfig ]);

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {carousel}
      <Body withPaddingBottom={withPaddingBottom} className="universal-card-body-wrapper">
        <LineLabels data-auto="property-details">
          <FirstLineLabel isHighlighted={isPromoted} weight="bold">
            {t('universalCard.project.name', { projectName: name })}
          </FirstLineLabel>
          <SecondLineLabel>
            {infoLineConfig.map(renderLineItem)}
          </SecondLineLabel>
          <ThirdLineLabel>
            <PropertyDetailsText>
              {t('universalCard.project.address', {
                city: props.city || null,
                streetName: props.streetName || null,
                streetNumber: props.streetNumber || null,
              })}
            </PropertyDetailsText>
          </ThirdLineLabel>
          {notificationTag && city ? <PropertyDetailsText faded>{city}</PropertyDetailsText> : null}
        </LineLabels>
        {notificationTag ? <NotificationTag tag={notificationTag} /> : null}
      </Body>
    </div>
  );
};

export default UniversalCommercialProjectCard;
