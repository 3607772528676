import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { State } from 'store/state';
import { isMadadContactModalStateOpenSelector, madadContactModalStateSelector } from 'store/state/app/selectors';
import { setMadadContactModalState, submitUserContactAgent } from 'store/state/app/actions';
import { contactFormInitialValuesSelector } from 'helpers/contact';
import { MutationType } from 'store/sagas/apiService/types';
import { MadadContactModal, MadadContactModalProps } from './ContactModal';
import withEvents from 'analytics/withEvents';


const isContactedSelector = createSelector(
  madadContactModalStateSelector,
  state => state.mutationsResponse[MutationType.AgentOfficeContactPageSubmit],
  (info, responses) => {
    if (info && responses) {
      const { id: mutationKey } = info;
      const response = responses[mutationKey];
      return Boolean(response && response.data && response.data.contactV4);
    }
    return false;
  }
);

const mapStateToProps = (state: State) => ({
  isOpen: isMadadContactModalStateOpenSelector(state),
  info: madadContactModalStateSelector(state),
  formInitialValues: contactFormInitialValuesSelector(state),
  isContacted: isContactedSelector(state),
});

const mapDispatchToProps = {
  onClose: () => setMadadContactModalState(null),
  contact: submitUserContactAgent,
};

const MadadContactModalWithEvents = withEvents<MadadContactModalProps>((sendEvent, props) => ({
  onCallClick() {
    sendEvent('user_contact_me_call_submit', 'user');
  },
  onPhoneShown() {
    sendEvent('user_contact_me_phone_expose', 'user', {
      event: {
        agent_details: {
          id: props.info.id,
          office_id: props.info.officeId,
        },
        source: props.info.eventSource || undefined,
      },
    });
  },
}))(MadadContactModal);

export default connect(mapStateToProps, mapDispatchToProps)(MadadContactModalWithEvents);
