import { useMemo } from 'react';
import { useLocale } from 'locale';
import { ResolutionPreference, CompletionType } from 'utils/entities';
import {
  getAddress,
  getUnitCardPrimaryParams,
  renderAddress,
  UnitCardPrimaryParams,
  unitCardTranslateKeys,
} from 'utils/addressFormatter';
import { isSearchRoute } from 'utils/marketplaceRoutes';
import { searchDocEntriesSelector } from 'store/state/domainData/selectors';
import { useSelector } from 'react-redux';
import { useRoute } from 'config/routes';

export enum UniversalCardLineLabels {
  Name = 'name',
  BuildingClass = 'buildingClass',
  Size = 'size',
  PPM = 'ppm',
  Beds = 'beds',
  Price = 'price',
  ApartmentType = 'apartmentType',
  Address = 'address',
  Floor = 'floor',
}

export const searchInWholeCountryResolutionPreferences = [ ResolutionPreference.Accurate, ResolutionPreference.Street, ResolutionPreference.Neighborhood ];

export enum SchoolRated {
  Low = 3,
  VeryLow = 4,
}

export const lowSchoolRatingList = new Set([ SchoolRated.Low, SchoolRated.VeryLow ]);

export const useUniversalCardAddress = (streetName: string, streetNumber: string, neighbourhood: string, unitNumber: string, city: string, resolutionPreferences: ResolutionPreference, isSearchInWholeCountry?: boolean): string => {
  const { t } = useLocale();
  const { route } = useRoute(); // temp
  const resolvedAddresses = useSelector(searchDocEntriesSelector);
  const searchAreasCount = resolvedAddresses && resolvedAddresses.length;
  const isSingleAddress = searchAreasCount === 1;
  const resolvedAddress = isSingleAddress && resolvedAddresses[0];
  const isSearchByArea = resolvedAddress && resolvedAddress.type === CompletionType.CustomZone && isSearchRoute(route.name);
  const addressCity = searchInWholeCountryResolutionPreferences.includes(resolutionPreferences)
    ? (isSearchInWholeCountry || isSearchByArea || !isSingleAddress) ? city : null
    : city;
  return useMemo(
    () => getAddress<UnitCardPrimaryParams>(t, renderAddress)({ streetName, streetNumber, neighbourhood, unitNumber, city: addressCity }, unitCardTranslateKeys, resolutionPreferences, getUnitCardPrimaryParams),
    [ t, renderAddress, streetName, streetNumber, neighbourhood, unitNumber, addressCity, unitCardTranslateKeys, resolutionPreferences, getUnitCardPrimaryParams ]
  );
};
