import React from 'react';
import ShowPasswordIcon from 'assets/svg/show-password.svg';
import HidePasswordIcon from 'assets/svg/hide-password.svg';
import {
  InputWrapper,
  InputField,
  ShowPasswordButton,
} from 'ds/components/input/styled';

export interface InputProps<T extends HTMLElement = HTMLInputElement> extends React.InputHTMLAttributes<T> {
  hasError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  innerRef?: React.MutableRefObject<HTMLInputElement>;
}

interface InputState {
  isPasswordShown: boolean;
}

export class Input extends React.Component<InputProps, InputState> {
  public state: InputState = {
    isPasswordShown: false,
  };

  public static defaultProps: Partial<InputProps> = {
    hasError: false,
  };

  public toggleIsPasswordShown = () => this.setState(prevState => ({
    isPasswordShown: !prevState.isPasswordShown,
  }));

  public render () {
    const { isPasswordShown } = this.state;
    const { placeholder, hasError, type, disabled, value, innerRef, ...rest } = this.props;
    return (
      <InputWrapper type={type}>
        <InputField
          placeholder={placeholder}
          hasError={hasError}
          type={isPasswordShown ? 'text' : type}
          disabled={disabled}
          value={value}
          ref={innerRef}
          {...rest}
        />
        {(type === 'password' && value) ? (
          <ShowPasswordButton
            onClick={this.toggleIsPasswordShown}
            isPasswordShown={isPasswordShown}
          >
            {isPasswordShown
              ? <ShowPasswordIcon width={22} height={22} />
              : <HidePasswordIcon width={22} height={22} />}
          </ShowPasswordButton>
        ) : null}
      </InputWrapper>
    );
  }
}
