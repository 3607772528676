import React from 'react';
import { head } from 'lodash';
import { ItemPageNavigationSubheaderWrapper, SubheaderWrapper } from './styled';
import SectionsNavigation, { Section } from './SectionsNavigation';
import { ShowAt } from 'consts/breakpoints';
import { MobileSubmenu } from './MobileSubmenu';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { routeSelector } from 'store/state/selectors/router';
import {
  hasTradeoffsListSelector,
  insightsLoadingSelector,
  addressDocEntrySelector,
  hasBuildingPermitRequestsSelector,
  hasPricesEstimationSectionSelector,
  hasBuildingDetailsSectionSelector,
  localPageMadadWinnersSelector,
  localPageMadadReviewsSelector,
  hasGoodToKnowInsightsSelector,
  resolvedStrictLocalDocSelector,
} from 'store/state/domainData/selectors';
import { Route } from 'config/routes';
import { createSelector } from 'reselect';
import { pick, values } from 'lodash';
import { withABTests } from 'hocs/withABTests';
import { hasPricesDataSelector, isPricesListingsTabsWithDataSelector } from 'store/state/selectors/insights/prices';
import { DealType, IAutocompleteEmploymentArea } from 'utils/entities';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { activeSectionSelector } from 'store/state/insightsContext/selectors';
import { hasTransportationWidgetDataSelector } from 'store/state/selectors/insights/transportation';
import { hasSchoolsDataSelector } from 'store/state/selectors/insights/schools';
import { hasNuisancesDataSelector } from 'store/state/selectors/insights/nuisances';
import { hasQuietStreetsDataSelector } from 'store/state/selectors/insights/quietStreets';
import { createConfig as createWidgetConfig, widgetAliases } from 'utils/widgetsConfig';
import { LivabilityCarouselDataSelectorByType } from 'components/widget/quiet-streets';
import { useCheckUnitPage, useCheckCommercialUnitPage } from 'hooks/useCheckUnitPage';
import { InsightSection } from 'store/state/insightsContext';
import { addressPageMadadWinnersSelector } from 'store/state/domainData/selectors/searchLocalAddressPage';
import { commercialLocalInfoSelector } from 'store/state/domainData/selectors/commercialLocalInfo';
import { hasPromotedDevelopersSelector } from 'store/state/domainData/selectors/developersAndPromotedProjects';
import { useCheckProjectPage } from 'hooks/useCheckProjectPage';
import { showAboutLeadingAgent } from 'store/state/domainData/selectors/unit-page-leading-data';
import { showAboutTheOfficeSectionSelector } from 'store/state/domainData/selectors/agentWithBulletinsByIds';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';

interface ItemPageNavigationSubheaderProps {
  navigationSections: Section[];
  activeSection: string;
  isVisible?: boolean;
}

function getMenuHighlightMappingList(isUnitPage: boolean, isCommercialUnitPage: boolean, isProjectPage: boolean, activeSection: string) {
  const isListingPage = isCommercialUnitPage || isUnitPage;
  if (isListingPage) {
    return {
      [Section.PRICES_ESTIMATION]: isListingPage ? activeSection : Section.OVERVIEW,
      [Section.PRICES]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.TRANSPORTATION]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.SCHOOLS]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.PLANNING]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.SAFETY]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.NEIGHBOURHOOD_LIFE]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.NUISANCES]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.QUIET_STREETS]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.EXPLORE_THE_AREA]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [InsightSection.Neighbors]: isListingPage ? Section.IMPORTANT_INFO : activeSection,
      [Section.OVERVIEW_LOCATION]: isProjectPage ? Section.ABOUT : activeSection,
      [Section.OVERVIEW_DESCRIPTION]: isProjectPage ? Section.ABOUT : activeSection,
    };
  }
  return {
    [Section.PRICES_ESTIMATION]: isUnitPage ? activeSection : Section.SHOW_MORE,
    [Section.PRICES]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.TRANSPORTATION]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.SCHOOLS]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.PLANNING]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.SAFETY]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.NEIGHBOURHOOD_LIFE]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.QUIET_STREETS]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.EXPLORE_THE_AREA]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [InsightSection.Neighbors]: isProjectPage ? Section.AREA_INSIGHTS : activeSection,
    [Section.OVERVIEW_LOCATION]: isProjectPage ? Section.ABOUT : activeSection,
    [Section.OVERVIEW_DESCRIPTION]: isProjectPage ? Section.ABOUT : activeSection,
  };
}

const ItemPageNavigationSubheader: React.FC<ItemPageNavigationSubheaderProps> = props => {
  const { activeSection, navigationSections, isVisible } = props;
  const isUnitPage = useCheckUnitPage();
  const isCommercialUnitPage = useCheckCommercialUnitPage();
  const isProjectPage = useCheckProjectPage();

  // Mapping between the active section and the menu item to highlight
  const menuHighlightMappingList = getMenuHighlightMappingList(isUnitPage, isCommercialUnitPage, isProjectPage, activeSection);

  const activeMainSection = head((activeSection || '').split('/'));
  const activeItem = menuHighlightMappingList[activeSection] || activeSection
    || menuHighlightMappingList[activeMainSection] || activeMainSection;
  return (
    <SubheaderWrapper isVisible={isVisible}>
      <ShowAt to={2}>
        <MobileSubmenu />
        <SectionsNavigation
          items={navigationSections}
          activeItem={activeItem}
        />
      </ShowAt>
      <ShowAt from={3}>
        <ItemPageNavigationSubheaderWrapper>
          <SectionsNavigation
            items={navigationSections}
            activeItem={activeItem}
          />
        </ItemPageNavigationSubheaderWrapper>
      </ShowAt>
    </SubheaderWrapper>
  );
};

const widgetToNavKey: Partial<Record<widgetAliases, Section>> = {
  transportation: Section.TRANSPORTATION,
  schools: Section.SCHOOLS,
  ilSchools: Section.SCHOOLS,
  madad: Section.MADAD,
  safety: Section.SAFETY,
  newConstructions: Section.PLANNING,
  prices: Section.PRICES,
  nuisances: Section.NUISANCES,
  neighbourhoodLife: Section.NEIGHBOURHOOD_LIFE,
  quietStreets: Section.QUIET_STREETS,
  trends: Section.TRENDS,
  buildingPermit: Section.BUILDING_PERMIT,
};

const getPageToNavKeys = (route: Route, dealType: DealType): Section[] => {
  const aliasList = createWidgetConfig(route, dealType);
  if (!aliasList) return null;
  const items = values(pick(widgetToNavKey, aliasList));

  return [ Section.OVERVIEW, ...items ];
};

const dealTypeSelector = createSelector([
  routeSelector,
  currentPoiSelector,
], (route, currentPoi) => {
  if (route.params.dealType) return route.params.dealType;
  if (currentPoi) {
    if (currentPoi.type === 'deal') return DealType.Buy;
    return currentPoi.dealType;
  }

  return DealType.Buy;
});

const hasSummaryQuietStreetDataSelector = createSelector([
  LivabilityCarouselDataSelectorByType,
  hasQuietStreetsDataSelector,
], (LivabilityData, quietStreetsData) => {
  return Boolean(LivabilityData && LivabilityData.length) || quietStreetsData;
});

const navigationSectionsSelector = createSelector([
  routeSelector,
  hasTransportationWidgetDataSelector,
  hasSchoolsDataSelector,
  hasPricesDataSelector,
  hasNuisancesDataSelector,
  dealTypeSelector,
  hasSummaryQuietStreetDataSelector,
  hasTradeoffsListSelector,
  insightsLoadingSelector,
  localPageMadadWinnersSelector,
  (state: State) => ({
    withGoodToKnowInsigths: hasGoodToKnowInsightsSelector(state),
    hasPricesEstimationSection: hasPricesEstimationSectionSelector(state),
    hasBuildingDetailsSection: hasBuildingDetailsSectionSelector(state),
    searchArea: addressDocEntrySelector(state),
    strictLocalDoc: resolvedStrictLocalDocSelector(state),
    commercialLocalInfo: commercialLocalInfoSelector(state),
    hasBuildingPermitSection: hasBuildingPermitRequestsSelector(state),
    reviews: localPageMadadReviewsSelector(state),
    addressMadadWinners: addressPageMadadWinnersSelector(state),
    hasProjectsCarousel: hasPromotedDevelopersSelector(state),
    hasPricesHistory: isPricesListingsTabsWithDataSelector(state),
    hasAboutAgent: showAboutLeadingAgent(state),
    hasAboutOffice: showAboutTheOfficeSectionSelector(state),
    abtests: abTestsStateSelector(state),
  }),
], (
  route,
  transportationData,
  schoolsData,
  pricesData,
  nuisancesData,
  dealType,
  quietStreetsData,
  tradeoffsListData,
  hideNeighbourhood,
  hasMadad,
  {
    withGoodToKnowInsigths,
    hasPricesEstimationSection,
    hasBuildingDetailsSection,
    searchArea,
    strictLocalDoc,
    commercialLocalInfo,
    hasBuildingPermitSection,
    reviews,
    addressMadadWinners,
    hasProjectsCarousel,
    hasPricesHistory,
    hasAboutAgent,
    hasAboutOffice,
    abtests,
  }
) => {
  if (route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial) {
    return [
      Section.OVERVIEW,
      Section.ABOUT,
      hasPricesHistory && Section.PRICE_HISTORY,
      tradeoffsListData && Section.AREA_INSIGHTS,
      hasProjectsCarousel && Section.DEVELOPER_ABOUT,
    ].filter(Boolean);
  }
  if (route.name === Route.UnitPage) {
    return [
      Section.OVERVIEW,
      Section.SHOW_MORE,
      Section.IMPORTANT_INFO,
      hasAboutAgent ? Section.ABOUT_THE_AGENT : null,
      hasAboutOffice ? Section.ABOUT_THE_OFFICE : null,
    ].filter(Boolean);
  }
  if (route.name === Route.UnitPageCommercial) {
    return [
      Section.OVERVIEW,
      Section.SHOW_MORE,
      Section.IMPORTANT_INFO,
      hasAboutAgent ? Section.ABOUT_THE_AGENT : null,
      hasAboutOffice ? Section.ABOUT_THE_OFFICE : null,
    ].filter(Boolean);
  }
  if (route.name === Route.LocalPage) {
    return [
      Section.OVERVIEW_AREA,
      Section.TRENDS,
      Section.DEALS,
      Boolean(hasMadad) ? Section.MADAD : null,
      searchArea && searchArea.type === 'neighbourhood' ? Section.INSIGHTS : null,
      Boolean(reviews) ? Section.REVIEWS : null,
      Section.EDUCATION,
      Section.SOCIAL_INDEX,
    ].filter(Boolean);
  }
  if (route.name === Route.EmploymentAreaPage) {
    const hasOverviewSection = strictLocalDoc &&
      (strictLocalDoc as IAutocompleteEmploymentArea).description &&
      (strictLocalDoc as IAutocompleteEmploymentArea).description.trim();
    const { nearbyRoads, nearbyIntersections } = commercialLocalInfo || {};
    const hasNearbyRoadsSection = (nearbyRoads && nearbyRoads.length) || (nearbyIntersections && nearbyIntersections.length);

    return [
      hasOverviewSection && Section.OVERVIEW_AREA,
      hasNearbyRoadsSection && Section.NEARBY_ROADS,
      withGoodToKnowInsigths && Section.AREA_INSIGHTS,
    ].filter(Boolean);
  }

  const resolve: Partial<Record<Section, boolean>> = {
    [Section.OVERVIEW]: true,
    [Section.PRICES_ESTIMATION]: true,
    [Section.MADAD]: Boolean(addressMadadWinners),
    [Section.TRANSPORTATION]: transportationData,
    [Section.SCHOOLS]: schoolsData,
    [Section.PLANNING]: true,
    [Section.PRICES]: pricesData && route.params.dealType === DealType.Buy,
    [Section.TRENDS]: true,
    [Section.NUISANCES]: nuisancesData,
    [Section.QUIET_STREETS]: quietStreetsData,
    [Section.NEIGHBOURHOOD_LIFE]: !hideNeighbourhood,
    [Section.EXPLORE_THE_AREA]: tradeoffsListData,
    [Section.BUILDING_PERMIT]: hasBuildingPermitSection,
  };

  const orderedSections = getPageToNavKeys(route.name, dealType);

  return orderedSections ? orderedSections.filter((section) => resolve[section]) : [];
});

const mapStateToProps = (state: State) => ({
  activeSection: activeSectionSelector(state),
  navigationSections: navigationSectionsSelector(state),
});

export default withABTests(connect(mapStateToProps)(ItemPageNavigationSubheader));
