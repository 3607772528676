import { PropertyType, RangeTuple } from 'components/filters/types';
import { DealType, MarketplaceType } from 'utils/entities';

export const RESIDENTIAL_BUILDING_CLASSES = [
  PropertyType.Flat,
  PropertyType.PrivateHouse,
  PropertyType.GardenApartment,
  PropertyType.Cottage,
  PropertyType.TwoFamilyDwelling,
  PropertyType.Duplex,
  PropertyType.Land,
  PropertyType.PenthouseApp,
  PropertyType.RoofFlat,
  PropertyType.MiniPenthouse,
  PropertyType.ResidentialUnit,
  PropertyType.SerialCottage,
  PropertyType.Studio,
  PropertyType.Building,
  PropertyType.AgricultureFarm,
  PropertyType.Triplex,
  PropertyType.Basement,
  PropertyType.Storeroom,
  PropertyType.SplitFlat,
  PropertyType.AgricultureLand,
  PropertyType.VacationProperty,
  PropertyType.BuyerTeam,
  PropertyType.ProfessionalFarm,
  PropertyType.Parking,
  PropertyType.Other,
];

export const COMMERCIAL_BUILDING_CLASSES = [
  PropertyType.Office,
  PropertyType.Shop,
  PropertyType.SharedWorkSpace,
  PropertyType.Studio,
  PropertyType.Hall,
  PropertyType.Clinic,
  PropertyType.Restaurant,
  PropertyType.Hotel,
  PropertyType.Building,
  PropertyType.IndustrialBuilding,
  PropertyType.Storeroom,
  PropertyType.Basement,
  PropertyType.ParkingLot,
  PropertyType.Land,
  PropertyType.IncomeProducingProperty,
  PropertyType.AgricultureLand,
  PropertyType.FarmLand,
  PropertyType.Other,
];

export const priceLimitsByMarketplaceType: Record<MarketplaceType, Record<DealType, RangeTuple<number>>> = {
  [MarketplaceType.Residential]: {
    [DealType.Rent]: [ 500, 100000 ],
    [DealType.Buy]: [ 100000, 100000000 ],
  },
  [MarketplaceType.Commercial]: {
    [DealType.Rent]: [ 500, 10000000 ],
    [DealType.Buy]: [ 50000, 100000000 ],
  },
};
