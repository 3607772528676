import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';

export const ApartmentDetailsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

export const ApartmentDetailsRow = styled.div`
  display: flex;
  flex-shrink: 0;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `width: 50%;`}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    width: 100%;
    &:first-of-type {
      margin-top: 8px;
    }
  `}
  margin-bottom: 8px;
  text-align: ${({ theme }) => theme.isRTL ? 'left' : 'right'};

  ::before {
    content: '';
    position: relative;
    top: 9px;
    width: 5px;
    height: 5px;
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 5px;
    border-radius: ${({ theme }) => theme.borderRadius.round};
    background: ${({ theme }) => theme.colors.neutrals.grey1};
  }
`;

export const ApartmentDetailsTitle = styled(Text)`
  font-weight: 600;
  padding-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 4px;
`;


export const ApartmentDetailsValue = styled(Text)`
  text-align: right;
`;
