/* tslint:disable */
import config from 'config';
import { disableEvents, enableEvents } from '@madlan145/client-toolbox';
import { getSecondaryUserId, getSessionId, getSecondaryUserIdAsNumber } from '.';
import { isServer } from 'utils';
import { getABTestsContext } from 'utils/abtests';
import { isBot } from 'utils/bot';
import { isStandaloneMode } from 'utils/uaMatchers';

let fullStoryInitialized = false;

export function isFullStoryInitialized() {
  return fullStoryInitialized;
}

export const initFullstory = () => {
  if (!config.disableFullStory && !isServer() && !fullStoryInitialized) {
    let shouldRecordFS = false;
    if (config.mode === 'production') {
      const userId = getSecondaryUserIdAsNumber();
      shouldRecordFS = userId % 100 < 20;
    }

    if (shouldRecordFS && !isBot()) {
      (window as any)._fs_debug = false;
      (window as any)._fs_host = 'fullstory.com';
      (window as any)._fs_script = 'edge.fullstory.com/s/fs.js';
      (window as any)._fs_org = '8AD7Y';
      (window as any)._fs_namespace = 'FS';
      (function (m?: any, n?: any, e?: any, t?: any, l?: any, o?: any, g?: any, y?: any) {

        fullStoryInitialized = true;

        if (e in m) {
          if(m.console && m.console.log) {
            m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
          } return;
        }

        g=m[e]=function(a:any,b:any,s:any){
          g.q?g.q.push([ a, b, s ]):g._api(a,b,s);
        };
        g.q=[];

        o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src=`https://${(window as any)._fs_script}`;

        o.onload = () => {
          const camelize = (s:string) => s.replace(/(\_\w)/g, function(m){return m[1].toUpperCase();});
          const fullStory = (window as any).FS;
          if (fullStory && fullStory.setUserVars) {
            disableEvents();
            try {
              const abContext = getABTestsContext().context;
              let userVars:any = {};
              Object.keys(abContext).forEach((k)=>{
                  userVars[camelize(k) + '_str'] = JSON.stringify(abContext[k]);
              });
              userVars["sessionId_str"] = getSessionId();
              userVars["beta_str"] = "1";
              userVars["version_str"] = config.version;
              userVars["secondaryUserId_str"] = getSecondaryUserId();
              userVars["pwa_bool"] = isStandaloneMode();
              fullStory.setUserVars(userVars);
            }
            finally {
              enableEvents();
            }
          }
        }

        y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);

        g.identify=function(i:any,v:any,s:any){
          g(l,{ uid:i },s);if(v)g(l,v,s);
        };g.setUserVars=function(v:any,s:any){
          g(l,v,s);
        };g.event=function(i:any,v:any,s:any){
          g('event',{ n:i,p:v },s);
        };
        g.anonymize=function(){
          g.identify(!!0);
        };
        g.shutdown=function(){
          g('rec',!1);
        };g.restart=function(){
          g('rec',!0);
        };
        g.log = function(a:any,b:any){
          g('log',[ a,b ]);
        };
        g.consent=function(a:any){
          g('consent',!arguments.length||a);
        };
        g.identifyAccount=function(i:any,v:any){
          o='account';v=v||{};v.acctId=i;g(o,v);
        };
        g.clearUserCookie=function(){};
        g.setVars=function(n:any, p:any){
          g('setVars',[ n,p ]);
        };
        g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
        if(m[y]){
          m[y]=function(){
            return g._w[y].apply(this,arguments);
          };
        }
        g._v='1.3.0';
      })(window, document, (window as any)._fs_namespace, 'script', 'user');
    }
  }
}