import { SmallText } from 'ds/components/typography';
import React, { MutableRefObject } from 'react';
import { useLocale } from 'locale';
import { Body, Header, ItemHeader, ItemRightSide, ItemSubheader, ItemWrapper, SupportPopupWrapper } from './styled';
import MadlanIcon from 'assets/svg/feed-madlan-filled.svg';
import WhatsappIcon from 'assets/svg/whatsapp-logo.svg';
import GoogleIcon from 'assets/svg/google-logo.svg';
import { createEmailLink, createWhatsAppLink } from 'utils/shareLinksUtils';

const SUPPORT_WHATSAPP_DISPLAY_NUMBER = '073-2190522';
const SUPPORT_WHATSAPP_NUMBER = '+972732190522';
const SUPPORT_EMAIL = 'agents@madlan.co.il';


interface SupportPopupProps {
  popupRef: MutableRefObject<HTMLDivElement>;
}

export const SupportPopup: React.FC<SupportPopupProps> = ({ popupRef }) => {
  const { t } = useLocale();
  const body = t('navigation.subheader.supportPopup.message.body');
  const subject = t('navigation.subheader.supportPopup.message.subject');

  const handleWhatsAppButtonClick = () => {
    const urlObj = new URL(window.location.href);
    urlObj.search = '';

    const href = createWhatsAppLink({ phone: SUPPORT_WHATSAPP_NUMBER, text: body });

    window.open(href);
  };

  const handleEmailClick = () => {
    const href = createEmailLink({ subject, body, email: SUPPORT_EMAIL });
    window.location.href = href;
  };

  const items = [
    {
      header: t('navigation.subheader.supportPopup.option.whatsapp.header'),
      subheader: SUPPORT_WHATSAPP_DISPLAY_NUMBER,
      icon: <WhatsappIcon />,
      onClick: handleWhatsAppButtonClick,
    },
    {
      header: t('navigation.subheader.supportPopup.option.email.header'),
      subheader: SUPPORT_EMAIL,
      icon: <GoogleIcon />,
      onClick: handleEmailClick,
    },
  ];

  const handleSubheaderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <SupportPopupWrapper ref={popupRef}>
      <Header>
        <span>
          {t('navigation.subheader.supportPopup.headerPart1')}{' '}
          <SmallText weight="bold">{t('navigation.subheader.supportPopup.headerPart2')}</SmallText>{' '}
          {t('navigation.subheader.supportPopup.headerPart3')}{' '}
          <SmallText weight="bold">{t('navigation.subheader.supportPopup.headerPart4')}</SmallText>
        </span>
        <MadlanIcon width={24} height={24} />
      </Header>
      <Body>
        {items.map((item) => (
          <ItemWrapper onClick={item.onClick}>
            <ItemRightSide>
              <ItemHeader weight="medium">{item.header}</ItemHeader>
              <ItemSubheader onClick={handleSubheaderClick}>{item.subheader}</ItemSubheader>
            </ItemRightSide>
            {item.icon}
          </ItemWrapper>
        ))}
      </Body>
    </SupportPopupWrapper>
  );
};
