import React from 'react';
import { Amenity } from 'utils/entities';
import {
  AmenitySectionTitle,
  AmenitySectionWrapper,
  AmenityText,
  AmenityWrapper,
  ListTitle,
  ListWrapper,
  MoreInfoWrapper,
  MoreText,
  WhatElse,
} from './styled';
import { useLocale } from 'locale';
import Project16AirCondition from 'assets/svg/filters/project-16-air-condition.svg';
import Project16Pending from 'assets/svg/filters/project-16-pending.svg';
import Project16Sold from 'assets/svg/filters/project-16-sold.svg';
import Project16List from 'assets/svg/filters/project-16-list.svg';
import Project16Doorman from 'assets/svg/filters/project-16-doorman.svg';
import Project16WareHouseIl from 'assets/svg/filters/project-16-warehouse.svg';
import Project16SafetyUnit from 'assets/svg/filters/project-16-saftey-unit.svg';
import Project16Wheelchair from 'assets/svg/filters/project-16-wheelchair.svg';
import Project16ElevatorIl from 'assets/svg/filters/project-16-elevator-il.svg';
import IconOk from 'assets/svg/ok-v-5.svg';
import { some } from 'lodash';
import { useScreenBreakpoint } from 'consts/breakpoints';

const amenityBase: Array<{ icon: React.ReactNode; type: Amenity }> = [
  { icon: <Project16AirCondition />, type: Amenity.AirConditioner },
  { icon: <Project16SafetyUnit />, type: Amenity.SecureRoom },
  { icon: <Project16Sold />, type: Amenity.Alarm },
  { icon: <Project16List />, type: Amenity.SubDivisible },
  { icon: <Project16Wheelchair />, type: Amenity.Accessible },
  { icon: <Project16Pending />, type: Amenity.FullTimeAccess },
  { icon: <Project16Doorman />, type: Amenity.Doorman },
  { icon: <Project16WareHouseIl />, type: Amenity.Storage },
  { icon: <Project16ElevatorIl />, type: Amenity.Elevator },
];

const amenitiesWhatElse: Amenity[] = [
  Amenity.Reception,
  Amenity.Kitchenette,
  Amenity.ConferenceRoom,
  Amenity.Balcony,
  Amenity.OuterSpace,
  Amenity.ColdRoom,
  Amenity.LoadingRamp,
  Amenity.HighCeiling,
  Amenity.ActiveBusiness,
];

const amenitiesParking: Amenity[] = [
  Amenity.ParkingEmployee,
  Amenity.ParkingVisitors,
  Amenity.ParkingBikes,
];

export const AmenitiesNew: React.FC<{ amenities: Record<Amenity, boolean> }> = React.memo(({ amenities }) => {
  const { t } = useLocale();
  const breakpoint = useScreenBreakpoint();

  const isMobile = breakpoint <= 2;
  const columnLayoutCount = isMobile ? 2 : 3;
  const withWhatElse = some(amenitiesWhatElse, type => amenities[type]);
  const withParking = some(amenitiesParking, type => amenities[type]);

  return (
    <>
      <div>
        <ListTitle weight="bold">{t('commercial.listing.amenities.list.title')}</ListTitle>
        <ListWrapper>
          {amenityBase.map(({ icon, type }) => (
            <AmenityWrapper key={type} columnLayoutCount={columnLayoutCount} isActive={amenities[type]}>
              {icon}
              <AmenityText>{t('filters.amenitiesOptions', { value: type })}</AmenityText>
            </AmenityWrapper>
          ))}
        </ListWrapper>
      </div>
      {withWhatElse ? (
        <AmenitySectionWrapper>
          <AmenitySectionTitle weight="bold">{t('commercial.listing.whatElse.title')}</AmenitySectionTitle>
          <MoreInfoWrapper>
            {amenitiesWhatElse.map(value => amenities[value] ? (
              <WhatElse key={value} columnLayoutCount={columnLayoutCount}>
                <IconOk />
                <MoreText>{t('filters.amenitiesOptions', { value })}</MoreText>
              </WhatElse>
            ) : null)}
          </MoreInfoWrapper>
        </AmenitySectionWrapper>
      ) : null}
      {withParking ? (
        <AmenitySectionWrapper>
          <AmenitySectionTitle weight="bold">{t('commercial.listing.parking.title')}</AmenitySectionTitle>
          <MoreInfoWrapper>
            {amenitiesParking.map(value => amenities[value] ? (
              <WhatElse key={value} columnLayoutCount={columnLayoutCount}>
                <IconOk />
                <MoreText>{t('filters.amenitiesOptions', { value })}</MoreText>
              </WhatElse>
            ) : null)}
          </MoreInfoWrapper>
        </AmenitySectionWrapper>
      ) : null}
    </>
  );
});
