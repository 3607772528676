import { InsightType } from 'utils/entities';
import { media } from './mediaBreakpoints';
import config from 'config';
import { colorToAlpha } from 'helpers/insight';
import { getSpacing } from './spacing';


const insights: Record<InsightType, string> = {
  [InsightType.Education]: '#B90BDB',
  [InsightType.Prices]: '#7C54FF',
  [InsightType.Transportation]: '#4066FF',
  [InsightType.Livability]: '#258518',
  [InsightType.BuildingPermit]: '#827415',
  [InsightType.Planning]: '#827415',
  [InsightType.Safety]: '#BF4000',
  [InsightType.Nuisances]: '#9E643E',
  [InsightType.FuturePlans]: '#C1F2D4',
  [InsightType.DivisionAreaPlan]: '#C1F2D4',
};

export const headerHeightConsts = {
  desktop: {
    autocomplete: 72,
    filters: 48,
    menu: 48,
  },
  mobile: {
    autocomplete: 64,
    filters: 44,
    menu: 48,
  },
};

const alert = {
  blue: '#018489',
  red: '#D0021B',
  yellow: '#827415',
  green: '#339900',
};

const ALERT_ALPHA = 0.07;

export default {

  isWindows: null as boolean,

  colors: {

    neutrals: {
      grey1: '#303030',
      grey2: '#606060',
      grey3: '#767676',
      grey4: '#9b9b9b',
      grey5: '#b2b2b2',
      grey6: '#d3d3d3',
      grey7: '#e0e0e0',
      grey8: '#ececec',
      grey9: '#f3f3f3',
      grey10: '#f8f8f8',
      white: '#fff',
      black: '#000',
      grey11: '#fafafa', // should be used rarely
      transparent: 'rgba(255, 255, 255, 0)',
    },

    brand: {
      darker: '#015E61',
      dark: '#017175',
      light: '#029499',
      lighter: '#03AEB4',
      lightest: '#E1F7F7',
      lightBlue: '#f0f5f7',
      theLightest: '#6ef4f9',
      main: '#018489',
      secondary: '#003A42',
      madlanGreen: '#14c17b',
      mintieWhite: '#EDFCF1',
      purpleWhite: '#F7FBFE',
      ocean: '#25C2CF',
      irisPurple: '#7E78CE',
    },

    alert: {
      greenLight: '#3bc17b',
      ...alert,
      blueBackground: colorToAlpha(alert.blue, ALERT_ALPHA),
      redBackground: colorToAlpha(alert.red, ALERT_ALPHA),
      yellowBackground: colorToAlpha(alert.yellow, ALERT_ALPHA),
      greenBackground: colorToAlpha(alert.green, ALERT_ALPHA),
    },

    label: {
      peach: '#ffceb0',
      yellow: '#fae998',
      cons: '#ff4f4f',
      blue: '#8ebfc1',
      violet: '#5d38ad',
      gold: '#dda73d',
    },
    insights,
  },

  shadow: {
    level0: '0 1px 0 0 rgba(0, 0, 0, 0.12)',
    level1: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    level2: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
    level3: '0 3px 8px 0 rgba(0, 0, 0, 0.08)',
    level4: '0 6px 10px 0 rgba(0, 0, 0, 0.06)',
    level5: '0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.15)',
    level6: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    level7: '0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 20px 0 rgba(0, 0, 0, 0.1)',
    level8: '0 12px 20px 0 rgba(0, 0, 0, 0.05)',
    level9: '0 4px 12px 0 rgba(0, 0, 0, 0.1)',
    level11: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
    negative: '0 -1px 0 0 #e0e0e0',
    level2Top: '0 -2px 5px 0 rgba(0, 0, 0, 0.1)',
    text: '0 2px 4px rgba(0, 0, 0, 0.2)',
    icon: '0 2px 4px rgba(0, 0, 0, 0.5)',
  },

  borderRadius: {
    micro: '2px',
    tiny: '4px',
    small: '8px',
    medium: '12px',
    large: '16px',
    extraLarge: '20px',
    extraXLarge: '40px',
    round: '50%',
    smallestSideRound: '9999px', // for pill-like rounding
  },

  spacing: getSpacing,

  media,

  isRTL: config.isRTL,
};
