import React, { useState, useEffect } from 'react';
import { requestIdleCallback } from 'utils/requestIdleCallback';

/**
 * @todo check if context is hydration
 */
export function withIdleCallback<T>(Comp: React.ComponentType<T>) {
  const Wrapped: React.FC<T> = (props) => {

    const [ mounted, setMounted ] = useState(false);

    useEffect(() => {
      requestIdleCallback(() => {
        setMounted(true);
      });
    }, []);

    const content = <Comp {...props} />;

    return mounted ? content : null;
  };

  Wrapped.displayName = `withIdleCallback(${Comp.displayName})`;

  return Wrapped;
}
