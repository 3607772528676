import { fork, select, put, call } from 'redux-saga/effects';
import { LoadType } from 'store/sagas/apiService/types';
import { DatedPoi, IPoiIdentifier } from 'utils/entities';
import { fetchMore } from 'store/state/domainData/actions';
import {
  shortlistPoisIdsSelector,
  favoritesAndShortlistDictSelector,
  baseShortlistPoisSelector,
} from 'store/state/domainData/selectors/favorites';
import { queryData } from 'store/sagas/apiService';


export const poiMapper = ({ poiId }: DatedPoi): IPoiIdentifier => ({
  id: poiId.id,
  type: poiId.type,
});


export function* maybeFetchShortlistPois() {
  const datedPois: IPoiIdentifier[] = yield select(shortlistPoisIdsSelector);
  const poisMap = yield select(favoritesAndShortlistDictSelector);
  const ids = datedPois.filter(p => !poisMap[p.id]);

  const data = yield select(baseShortlistPoisSelector);

  if (!data) {
    yield call(queryData, {
      loadType: LoadType.ShortlistPois,
      meta: { variables: { ids } },
    });
    return;
  }

  if (!datedPois || !datedPois.length) return;


  if (ids.length) {
    yield put(fetchMore({
      loadType: LoadType.ShortlistPois,
      meta: {
        variables: { ids },
      },
    }));
  }
}

export function* shortlistPageHandler() {
  yield fork(maybeFetchShortlistPois);
}

