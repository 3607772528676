import { ResponseByType, LoadType, MutationType, ISaveSearch, AuthError, IError } from 'store/sagas/apiService/types';

import { MutationsResponseAction } from 'store/state/mutationsResponse';
import { SET_MUTATION_RESPONSE, MUTATE } from '../mutationsResponse/types';
import { User } from 'utils/entities';
import { domainInitialState } from './utils';
import { Domain } from './index';
import { isSearchEqual } from 'utils/saveSearch';

type UserData = ResponseByType[LoadType.CurrentUser];

function mergeProfile(state: UserData, updated: Partial<User>): UserData {
  return {
    ...state,
    me: {
      ...state.me,
      ...updated,
    },
  };
}

function removeSaveSearchFromList (list: ISaveSearch[], searchIds: Array<Partial<ISaveSearch>>) {
  return searchIds.reduce((acc, search) => {
    const { searchId } = search;
    let index;
    if (searchId) {
      index = acc.findIndex((s) => s.searchId === searchId);
    }
    else {
      index = acc.findIndex((s) => isSearchEqual(s, search));
    }

    if (index === -1) return acc;

    return [
      ...acc.slice(0, index),
      ...acc.slice(index + 1),
    ];
  }, list);
}

function addSaveSearchLists(list: ISaveSearch[], newSearches: ISaveSearch[]) {
  return newSearches.reduce((acc, newSearch) => {
    const index = acc.findIndex((s) => s.searchId === newSearch.searchId || isSearchEqual(s, newSearch));

    if (index === -1) {
      return [ newSearch, ...acc ];
    }

    return [
      ...acc.slice(0, index),
      { ...acc[index], ...newSearch },
      ...acc.slice(index + 1),
    ];
  }, list);
}

function userDataReducer(state: UserData, action: MutationsResponseAction): UserData {
  // can't de-dupe cause of type guards
  switch (action.type) {
    case MUTATE: {
      switch (action.mutationType) {
        case MutationType.UpdateUserProfile: {
          const { commutePreference, firstName, lastName, phone } = action.meta.variables;
          return mergeProfile(state, { commutePreference, firstName, lastName, phone });
        }

        case MutationType.DeleteSaveSearch:
          return mergeProfile(state, {
            savedSearches: removeSaveSearchFromList(
              state.me.savedSearches,
              [ { searchId: action.meta.variables.searchId } ]
            ),
          });

        case MutationType.SaveSearchQuery:
          return mergeProfile(state, {
            savedSearches: addSaveSearchLists(
              state.me.savedSearches,
              [ action.meta.variables.search ] as ISaveSearch[]
            ),
            searchHistory: removeSaveSearchFromList(
              state.me.searchHistory,
              [ { searchId: action.meta.variables.search.searchId } ]
            ),
          });

        default: return state;
      }
    }
    case SET_MUTATION_RESPONSE: {
      switch (action.mutationType) {
        case MutationType.AddRecentlyViewed:
          return mergeProfile(state, { lastViewed: action.meta.response.data.addRecentlyViewed });

        case MutationType.SaveAddress:
          return mergeProfile(state, { savedAddresses: action.meta.response.data.saveAddress });

        case MutationType.DeleteAddress:
          return mergeProfile(state, { savedAddresses: action.meta.response.data.deleteAddress });

        case MutationType.AddContactAgent:
          return mergeProfile(state, { lastContacted: action.meta.response.data.addContactAgent });

        case MutationType.AddFavorites:
          return mergeProfile(state, { favorites: action.meta.response.data.addFavorites });

        case MutationType.RemoveFavorites:
          return mergeProfile(state, { favorites: action.meta.response.data.removeFavorites });

        case MutationType.UpdateUserProfile: {
          const { commutePreference, firstName, lastName, phone } = action.meta.response.data.updateUserProfile;
          return mergeProfile(state, { commutePreference, firstName, lastName, phone });
        }

        case MutationType.FacebookLogin:
          return {
            ...state,
            me: action.meta.response.data.facebookLoginV3.user,
          };
        case MutationType.GoogleLogin:
          return {
            ...state,
            me: action.meta.response.data.googleLoginV3.user,
          };
        case MutationType.LoginWithEmailAndPassword:
          return {
            ...state,
            me: action.meta.response.data.passwordLoginV3.user,
          };
        case MutationType.TempTokenAuth:
          return {
            ...state,
            me: action.meta.response.data.completeLoginV3.user,
          };
        case MutationType.SignInSignUpWithEmail:
          return {
            ...state,
            me: action.meta.response.data.loginV3.user,
          };
        case MutationType.RegisterVisitor:
          return {
            ...state,
            me: action.meta.response.data.registerVisitor.user,
          };
        case MutationType.SaveSearchQuery:
          return mergeProfile(state, {
            savedSearches: addSaveSearchLists(
              state.me.savedSearches,
              [ action.meta.response.data.saveSearchQuery ]
            ),
          });
        case MutationType.DeleteSaveSearch:
          return mergeProfile(state, {
            savedSearches: removeSaveSearchFromList(
              state.me.savedSearches,
              [ { searchId: action.meta.variables.searchId } ]
            ),
          });
        case MutationType.SaveRecentSearch:
          return mergeProfile(state, {
            searchHistory: addSaveSearchLists(
              state.me.searchHistory,
              [ action.meta.response.data.saveRecentSearch ]
            ),
          });

        default:
          return state;
      }
    }
    default: return state;
  }
}

const ERRORS_NOT_RESETTING_DATA = new Set([ AuthError.NoNeedToRegister, AuthError.NeedPassword, AuthError.LoginFailed ]);

export function userDomainReducer(state: Domain<LoadType.CurrentUser> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.CurrentUser> {
  if (action.type === SET_MUTATION_RESPONSE && action.meta.response.errors && action.meta.response.errors.length) {
    const { errors } = action.meta.response;
    const shouldKeepData = (errors as IError[]).some(error => ERRORS_NOT_RESETTING_DATA.has(error.reason));

    return {
      ...state,
      data: shouldKeepData ? state.data : null,
      errors: action.meta.response.errors,
    };
  }

  const reduced = userDataReducer(state.data, action);
  if (state.data === reduced) return state;

  return {
    ...state,
    data: reduced,
    loading: false,
  };
}
