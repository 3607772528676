import { ResolutionPreference } from 'utils/entities';
import { HebrewKeys, ITranslate } from 'locale';
import { flow, curry } from 'lodash';


export interface PrimaryAccurateParams {
  streetNumber?: string;
  streetName?: string;
  city?: string;
  unitNumber?: string;
  neighbourhood?: null;
}

export interface PrimaryStreetParams {
  streetName?: string;
  streetNumber: null;
  neighbourhood: null;
}
export interface PrimaryNeighborhoodParams {
  neighbourhood: string;
}

export interface SecondaryAccurateParams {
  neighbourhood: string;
  city?: string;
}
export interface SecondaryStreetParams {
  neighbourhood?: string;
  city?: string;
}

export interface SecondaryNeighborhoodParams {
  city: string;
}

export interface UnitCardAccurateParams {
  streetName: string;
  streetNumber: string;
  neighbourhood?: string;
  unitNumber?: string;
  city?: string;
}

export interface UnitCardStreetParams {
  unitNumber?: string;
  streetName: string;
  streetNumber: null;
  neighbourhood?: string;
}

export interface UnitCardNeighborhoodParams {
  neighbourhood: string;
}

export interface OneLineAddressAccurateParams {
  unitNumber?: string;
  streetName: string;
  streetNumber: string;
  city: string;
}

export interface OneLineAddressStreetParams {
  streetName: string;
  streetNumber: null;
  city: string;
}

export interface OneLineAddressNeighborhoodParams {
  neighbourhood: string;
}

export const removeMultiSpaces = (str: string): string => str.replace(/ +(?= )/g, '' );

export type OneLineAddressParams = OneLineAddressAccurateParams | OneLineAddressStreetParams | OneLineAddressNeighborhoodParams;
export type UnitCardPrimaryParams = UnitCardAccurateParams | UnitCardStreetParams | UnitCardNeighborhoodParams;
export type DefaultPrimaryParams = PrimaryAccurateParams | PrimaryStreetParams | PrimaryNeighborhoodParams;
export type DefaultSecondaryParams = SecondaryAccurateParams | SecondaryStreetParams | SecondaryNeighborhoodParams;

type CommonParams = DefaultPrimaryParams | DefaultSecondaryParams | UnitCardPrimaryParams | OneLineAddressParams;

export type CommonKeyMap = Record<ResolutionPreference, HebrewKeys>;

type RenderAddress<T> = (t: ITranslate, tkey: HebrewKeys, params: T) => string;

export const unitPagePrimaryTranslateKeys: CommonKeyMap = {
  accurate: 'common.primaryAddress',
  street: 'common.primaryAddress',
  neighborhood: 'common.primaryAddressMaskedStreet',
  city: 'common.secondaryAddressMaskedStreet',
};

export const mapUniversalCardTranslateKeys: CommonKeyMap = {
  accurate: 'mapUniversalCard.addressAccurate',
  street: 'mapUniversalCard.addressAccurate',
  neighborhood: 'common.primaryAddressMaskedStreet',
  city: 'common.secondaryAddressMaskedStreet',
};

export const unitPageSecondaryTranslateKeys: CommonKeyMap = {
  accurate: 'common.secondaryAddress',
  street: 'common.secondaryAddress',
  neighborhood: 'common.secondaryAddressMaskedStreet',
  city: 'common.secondaryAddressMaskedStreet',
};

export const unitCardTranslateKeys: CommonKeyMap = {
  accurate: 'unitCard.primaryAddress',
  street: 'unitCard.primaryAddress',
  neighborhood: 'unitCard.neighbourhood',
  city: 'common.secondaryAddressMaskedStreet',
};

export const oneLineAddressTranslateKeys: CommonKeyMap = {
  accurate: 'unitPage.footerAddress',
  street: 'unitPage.footerAddress',
  neighborhood: 'unitPage.footerNeighborhoodAddress',
  city: 'common.secondaryAddressMaskedStreet',
};

export const shortlistContactFormTranslateKeys: CommonKeyMap = {
  accurate: 'shortlist.contactForm.address',
  street: 'shortlist.contactForm.address',
  neighborhood: 'shortlist.contactForm.neighbourhoodMask',
  city: 'common.secondaryAddressMaskedStreet',
};

export const agentContactFormTranslateKeys: CommonKeyMap = {
  accurate: 'agent.contactForm.address',
  street: 'agent.contactForm.address',
  neighborhood: 'agent.contactForm.neighbourhoodMask',
  city: 'common.secondaryAddressMaskedStreet',
};

export const getBedsRangeParams = (min: number, max: number): { min: number, max?: number } => {
  return {
    min,
    max: min === max ? null : max,
  };
};

export const getDefaultPrimaryParams = (params: PrimaryAccurateParams & PrimaryStreetParams & PrimaryNeighborhoodParams, key: ResolutionPreference): DefaultPrimaryParams => {
  switch (key) {
    case ResolutionPreference.Accurate:
      return { streetNumber: params.streetNumber, streetName: params.streetName, unitNumber: params.unitNumber, neighbourhood: null, city: params.city };
    case ResolutionPreference.Street:
      return { streetName: params.streetName, streetNumber: null, neighbourhood: null };
    case ResolutionPreference.Neighborhood:
      return { neighbourhood: params.neighbourhood };
    case ResolutionPreference.City:
      return { city: params.city };
    default:
      return params;
  }
};

export const getDefaultSecondaryParams = (params: SecondaryAccurateParams & SecondaryStreetParams & SecondaryNeighborhoodParams, key: ResolutionPreference): DefaultSecondaryParams => {
  switch (key) {
    case ResolutionPreference.Accurate:
      return { neighbourhood: params.neighbourhood, city: params.city };
    case ResolutionPreference.Street:
      return { neighbourhood: params.neighbourhood, city: params.city };
    case ResolutionPreference.Neighborhood:
      return { city: params.city };
    default:
      return params;
  }
};

export const getUnitCardPrimaryParams = (params: UnitCardAccurateParams & UnitCardStreetParams & UnitCardNeighborhoodParams, key: ResolutionPreference): UnitCardPrimaryParams => {
  switch (key) {
    case ResolutionPreference.Accurate:
      return { city: params.city || null, streetNumber: params.streetNumber, streetName: params.streetName, neighbourhood: params.neighbourhood, unitNumber: params.unitNumber };
    case ResolutionPreference.Street:
      return { city: params.city || null, streetNumber: null, streetName: params.streetName, neighbourhood: params.neighbourhood, unitNumber: params.unitNumber };
    case ResolutionPreference.Neighborhood:
      return { city: params.city || null, neighbourhood: params.neighbourhood };
    case ResolutionPreference.City:
      return { city: params.city, neighbourhood: params.neighbourhood };
    default:
      return params;
  }
};

export const getShortlistContactFormParams = (params: UnitCardAccurateParams & UnitCardStreetParams & UnitCardNeighborhoodParams, key: ResolutionPreference): UnitCardPrimaryParams => {
  switch (key) {
    case ResolutionPreference.Accurate:
      return { streetNumber: params.streetNumber, streetName: params.streetName, neighbourhood: params.neighbourhood, unitNumber: params.unitNumber };
    case ResolutionPreference.Street:
      return { streetNumber: null, streetName: params.streetName, neighbourhood: params.neighbourhood, unitNumber: params.unitNumber };
    case ResolutionPreference.Neighborhood:
      return { neighbourhood: params.neighbourhood };
    case ResolutionPreference.City:
      return { city: params.city, neighbourhood: params.neighbourhood };
    default:
      return params;
  }
};

export const getOneLineAddressParams = (params: OneLineAddressAccurateParams & OneLineAddressStreetParams & OneLineAddressNeighborhoodParams, key: ResolutionPreference): OneLineAddressParams => {
  switch (key) {
    case ResolutionPreference.Accurate:
      return { streetNumber: params.streetNumber, streetName: params.streetName, neighbourhood: params.neighbourhood, city: params.city, unitNumber: params.unitNumber  };
    case ResolutionPreference.Street:
      return { streetNumber: null, streetName: params.streetName, neighbourhood: params.neighbourhood, city: params.city  };
    case ResolutionPreference.Neighborhood:
      return { neighbourhood: params.neighbourhood };
    default:
      return params;
  }
};

export const renderAddress = (t: ITranslate, tkey: HebrewKeys, params: CommonParams): string => t(tkey, { ...params });

const curr = curry((t: ITranslate, fn: RenderAddress<CommonParams>, data: { key: HebrewKeys, params: CommonParams }) => fn(t, data.key, data.params));

const getTranslateKey = (params: CommonParams, keyMap: CommonKeyMap, resolution: ResolutionPreference, paramsFn: (p: CommonParams, k: ResolutionPreference) => CommonParams): { key: HebrewKeys, params: CommonParams } => {
  const preference = resolution || ResolutionPreference.Accurate;
  return { key: keyMap[preference], params: paramsFn(params, preference) };
};

export const getAddress = <T>(t: ITranslate, fn: RenderAddress<T>) => flow(getTranslateKey, curr(t, fn));
