import React from 'react';
import { ManageListingsErrorModal } from 'store/state/app';
import { useLocale } from 'locale';
import Modal from 'ds/components/modal';
import { Button } from 'ds/components/button';
import ErrorIcon from 'assets/svg/manage-bulletin-error.svg';
import { SpacingBlock } from 'components/spacing-block';
import { H3, Text } from 'ds/components/typography';
import { Link } from 'components/link';
import { Wrapper } from './styled';
import { ListingResponseType, UserCannotAddListingErrorField } from 'store/sagas/apiService/types';
import { NavigateToFn, Route, useRoute } from 'config/routes';
import { DraftState } from '../types';

interface CloseUploadErrorModalVariables {
  noRedirect?: boolean;
}

interface ErrorModalProps {
  data: ManageListingsErrorModal;
  navigateTo: NavigateToFn;
  onClose: (data?: CloseUploadErrorModalVariables) => void;
  userWithReducedListingLimit: boolean;
  initialUploadValues: DraftState;
  initialEditValues: DraftState;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  data,
  onClose,
  navigateTo,
  userWithReducedListingLimit,
  initialUploadValues,
  initialEditValues,
}) => {
  const { t } = useLocale();
  const { route } = useRoute();

  if (!data) return null;

  const uploadBulletinType = route.name === Route.EditBulletin ? initialEditValues : initialUploadValues;
  const withReducedLimit = uploadBulletinType.formValues.type === 'bulletin' && userWithReducedListingLimit;

  const { type, field } = data;
  const showManagementLink = type === ListingResponseType.UserCannotAddListingError
    && field === UserCannotAddListingErrorField.UserId;
  const showUploadButton = type === ListingResponseType.GeneralListingError
    || type === ListingResponseType.InvalidInputError;

  const onUploadClick = () => {
    if (route.name === Route.UploadBulletin || route.name === Route.EditBulletin) {
      navigateTo(route.name, { id: route.params.id });
    }
    onClose({ noRedirect: true });
  };

  const onModalClose = () => onClose();

  return (
    <Modal
      isOpen
      onClose={onModalClose}
      mobileModalPositionBottom
    >
      <Wrapper>
        <SpacingBlock mBottom={4}>
          <ErrorIcon />
        </SpacingBlock>
        <SpacingBlock mBottom={1}>
          <H3 weight="bold">
            {t('uploadBulletin.errorModal.title', { type })}
          </H3>
        </SpacingBlock>
        <Text>
          {t('uploadBulletin.errorModal.subtitle', { type, field, withReducedLimit })}
          {showManagementLink ? (
            <Link onClick={onModalClose} routeName={Route.ManageBulletins} routeOptions={{ replace: true }}>
              {t('uploadBulletin.errorModal.managePageLink')}
            </Link>
          ) : null}
          {showUploadButton ? (
            <SpacingBlock mTop={4}>
              <Button size="large" onClick={onUploadClick}>
                {t('uploadBulletin.errorModal.uploadButton', { isEdit: route.name === Route.EditBulletin })}
              </Button>
            </SpacingBlock>
          ) : null}
        </Text>
      </Wrapper>

    </Modal>
  );
};
