import { createSelector } from 'reselect';
import { IInsight, InsightDescriptor, InsightType, MarketplaceType } from 'utils/entities';
import { insightsListSelector } from 'store/state/domainData/selectors';
import { ConstructionTypes, INewDevelopmentJob } from 'store/state/selectors/insights/summaryTypes';
import { carouselDataMapper } from 'store/state/selectors/utils';
import { marketplaceSelector } from '../../router';


const NEW_JOBS_INSIGHTS_TYPES = new Set([ InsightDescriptor.NewDevelopmentJobs, InsightDescriptor.NewDevelopmentJobsArea ]);
const PERMITS_INSIGHTS_TYPES = new Set([ InsightDescriptor.BuildingPermitRequests ]);
export const FORBIDDEN_COMMERCIAL_INSIGHTS_TYPES = new Set([ InsightDescriptor.ApartmentsType ]);

const findNewDevelopmentJobs = (insights: IInsight[], type: InsightDescriptor) => {
  const obj = insights.find((o: IInsight) => o.type === type);

  if (obj) return obj.summary && obj.summary.nonText && obj.summary.nonText.data;
  return [];
};

const findInFilingFn = (insights: IInsight[], type: InsightDescriptor) => {
  const constructionObject = findNewDevelopmentJobs(insights, type);

  if (constructionObject && ConstructionTypes.IN_FILING in constructionObject) {
    return constructionObject[ConstructionTypes.IN_FILING];
  }
  return [];
};

const findInFilingApprovedFn = (insights: IInsight[], type: InsightDescriptor) => {
  const constructionObject = findNewDevelopmentJobs(insights, type);

  if (constructionObject && ConstructionTypes.IN_FILING_APPROVED in constructionObject) {
    return constructionObject[ConstructionTypes.IN_FILING_APPROVED];
  }
  return [];
};

const findInConstructionFn = (insights: IInsight[], type: InsightDescriptor) => {
  const constructionObject = findNewDevelopmentJobs(insights, type);

  if (constructionObject && ConstructionTypes.IN_CONSTRUCTION in constructionObject) {
    return constructionObject[ConstructionTypes.IN_CONSTRUCTION];
  }
  return [];
};

const makeConstructionSelector = (findFn: (insights: IInsight[], type: InsightDescriptor) => INewDevelopmentJob[]) => createSelector([
  insightsListSelector,
], (insights) => {
  if (!insights) return [];
  return findFn(insights, InsightDescriptor.NewDevelopmentJobs);
});

const makeInsightsSelector = (filterFn: (i: IInsight, marketplace: MarketplaceType) => boolean) => createSelector([
  insightsListSelector,
  marketplaceSelector,
], (insights: IInsight[], marketplace: MarketplaceType) => insights ? insights.filter(i => filterFn(i, marketplace)) : []);

const getPlanningInsights = makeInsightsSelector(
  (i, marketplace) => {
    const baseCondition = i.category === InsightType.Planning && !PERMITS_INSIGHTS_TYPES.has(i.type) && !NEW_JOBS_INSIGHTS_TYPES.has(i.type);
    const commercialCondition = marketplace === MarketplaceType.Commercial ? !FORBIDDEN_COMMERCIAL_INSIGHTS_TYPES.has(i.type) : true;

    return baseCondition && commercialCondition;
  }
);

export const planningIdsSelector = createSelector([
  getPlanningInsights,
], (insights) => insights.map(({ id }) => id));

export const constructionCarouselDataSelector = createSelector([
  getPlanningInsights,
], carouselDataMapper);

export const constructionsInFiling = makeConstructionSelector(findInFilingFn);
export const constructionsInFilingApproved = makeConstructionSelector(findInFilingApprovedFn);
export const constructionsInConstruction = makeConstructionSelector(findInConstructionFn);
