import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import {
  SET_DOMAIN_DATA,
  FETCH_MORE,
  INIT,
  RESET,
  SET_FETCH_MORE_RESULT,
  RESET_SSR_HYDRATION,
} from './types';
import { ResponseByType, LoadType, VariablesByType, IError } from 'store/sagas/apiService/types';
import { NetworkStatus, ApolloQueryResult } from 'apollo-client';
import { getUpdateFn, domainInitialState, fillLoadTypes, getLoadUpdateFn } from './utils';
import { MutationsResponseAction } from 'store/state/mutationsResponse';
import { SET_MUTATION_RESPONSE, MUTATE } from '../mutationsResponse/types';
import { userDomainReducer } from './currentUser';
import { combineReducers } from 'redux';
import { usersListingsDomainReducer, adminSearchDomainReducer } from './usersListings';
import { userContentTextReviewsReducer } from './userContentTextReviews';
import { userNotificationDevicesReducer } from './userNotificationDevices';
import { dealsAffiliationRequestsReducer } from './dealsAffiliationRequests';


export type Domain<T extends LoadType> = ApolloQueryResult<ResponseByType[T]> & {
  meta: {
    wasSSRHydrated: boolean;
    variables: VariablesByType[T];
  };
  pendingCount: number;
  errors?: ReadonlyArray<IError>;
};

export type DomainDataAction = ActionType<typeof actions>;
export type DomainDataState = {
  [K in LoadType]: Domain<K>;
};

export const initialState: DomainDataState = fillLoadTypes(domainInitialState);
const noopMutationReducers = fillLoadTypes(<T extends LoadType>(state: Domain<T> = domainInitialState) => state);

const mutationReducer = combineReducers<DomainDataState, MutationsResponseAction>({
  ...noopMutationReducers,
  [LoadType.CurrentUser]: userDomainReducer,
  [LoadType.UserAllListings]: usersListingsDomainReducer,
  [LoadType.AdminSearch]: adminSearchDomainReducer,
  [LoadType.UserContentTextReviews]: userContentTextReviewsReducer,
  [LoadType.UserNotificationDevices]: userNotificationDevicesReducer,
  [LoadType.GetDealsAffiliationRequests]: dealsAffiliationRequestsReducer,
});

export default (state: DomainDataState = initialState, action: DomainDataAction | MutationsResponseAction): DomainDataState => {
  switch (action.type) {
    case SET_MUTATION_RESPONSE:
    case MUTATE: {
      const reduced = mutationReducer(state, action);

      return reduced === state ? state : {
        ...state,
        ...reduced,
      };
    }
    case SET_FETCH_MORE_RESULT: {
      const queryUpdater = getUpdateFn(action.loadType);
      return {
        ...state,
        [action.loadType]: {
          ...state[action.loadType],
          data: queryUpdater(state[action.loadType].data, {
            fetchMoreResult: action.payload.data,
            fetchMoreVariables: action.meta.variables,
          }),
          loading: false,
          pendingCount: state[action.loadType].pendingCount - 1,
        },
      };
    }
    case SET_DOMAIN_DATA:
      const updateFn = getLoadUpdateFn(action.loadType);

      return {
        ...state,
        [action.loadType]: {
          ...action.payload,
          data: updateFn(action.payload.data),
          meta: {
            variables: action.meta.variables,
            wasSSRHydrated: action.meta.wasSSRHydrated,
          },
        },
      };
    case RESET_SSR_HYDRATION: {
      const { loadType } = action.payload;
      return {
        ...state,
        [loadType]: {
          ...state[loadType],
          meta: {
            ...state[loadType].meta,
            wasSSRHydrated: false,
          },
        },
      };
    }
    case INIT:
      return {
        ...state,
        [action.payload.loadType]: {
          ...state[action.payload.loadType],
          loading: true,
          networkStatus: action.payload.networkStatus || NetworkStatus.loading,
        },
      };
    case RESET:
      return {
        ...state,
        [action.payload.loadType]: {
          ...domainInitialState,
          loading: false,
        },
      };
    case FETCH_MORE:
      return {
        ...state,
        [action.loadType]: {
          ...state[action.loadType],
          loading: true,
          pendingCount: state[action.loadType].pendingCount + 1,
        },
      };

    default:
      return state;
  }
};
