import React, { useState } from 'react';
import { StyledLink } from 'components/static-blog-section/styled';
import * as Styled from '../styled';
import { Root, Header, MobileList } from './styled';
import { useLocale } from 'locale';
import { BaseCarousel } from 'ds/components/carousels/base-carousel';
import BlogCard from 'components/blog-card';
import GlassesIcon from 'assets/svg/glasses.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { BlogCarouselProps, isEmpty, getDirection } from '../helper';
import { noop } from 'lodash';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';


export const BlogSection: React.FC<BlogCarouselProps> = ({ articles, loading, onCarouselScroll = noop }) => {
  const { t } = useLocale();
  const [ slideIndex, setSlideIndex ] = useState(0);
  const carouselSectionNode = React.useRef<HTMLDivElement>(null);
  const isMobile = useScreenBreakpoint() < 3;
  const onCarouselSlideChange = (idx: number) => {
    onCarouselScroll(getDirection(slideIndex, idx), false);
    setSlideIndex(idx);
  };

  const [ sectionInView, setSectionInView ] = useState(false);
  useIntersectionObserver(([ entry ]) => {
    setSectionInView(entry.isIntersecting);
  }, [ carouselSectionNode ], { threshold: 1 });

  if (isEmpty(articles, loading)) return null;

  return (
    <Root ref={carouselSectionNode} data-auto="articles-carousel">
      <Header>
        <Styled.InfoWrapper>
          <GlassesIcon height={40} width={40} />
          <Styled.InfoBox>
            <Styled.Title data-auto="articles-carousel-title" weight="bold">{t('checkAddress.blog.title')}</Styled.Title>
            <Styled.SubTitle data-auto="articles-carousel-subtitle">{t('checkAddress.blog.subtitle')}</Styled.SubTitle>
          </Styled.InfoBox>
        </Styled.InfoWrapper>
        <StyledLink href="https://www.madlan.co.il/blog" target="_blank" withArrow weight="normal">{t('landingPage.blogSection.link')}</StyledLink>
      </Header>
      {isMobile ? (
        <MobileList>
          {articles.map((el, k) => (
            <BlogCard
              key={k}
              articleId={el.id}
              sectionInView
              title={el.title}
              imageUrl={el.image}
              developerId={el.owner.id}
              developerName={el.owner.name}
              postUrl={el.link}
            />
          ))}
        </MobileList>
      ) : (
        <BaseCarousel
          hideShadow
          entryContentDXOverride={24}
          onSlideIndexChange={onCarouselSlideChange}
          activeCarouselSlide={slideIndex}
        >
          {articles.map((el, k, arr) => (
            <BlogCard
              key={k}
              articleId={el.id}
              rootNode={carouselSectionNode}
              sectionInView={sectionInView}
              title={el.title}
              imageUrl={el.image}
              developerId={el.owner.id}
              developerName={el.owner.name}
              postUrl={el.link}
              withLeftMargin={k !== arr.length - 1}
            />
          ))}
        </BaseCarousel>
      )}
    </Root>
  );
};
