import { MIN_OFFSET, TABS_WITH_HANDLE_HEIGHT, THRESHOLD } from './magicNumbers';


export enum SnapPosition {
  Expanded = 'expanded',
  Default = 'default',
  Collapsed = 'collapsed',
}

export const almostEqual = (a: number, b: number, phi: number) => Math.abs(a - b) < phi;

const SNAP_ORDER = [
  SnapPosition.Expanded,
  SnapPosition.Default,
  SnapPosition.Collapsed,
];

export function getNextSnapOnBarClick(current: SnapPosition, isExpandable: boolean) {
  switch (current) {
    case SnapPosition.Collapsed:
      return SnapPosition.Default;

    case SnapPosition.Default:
      return isExpandable ? SnapPosition.Expanded : SnapPosition.Collapsed;

    case SnapPosition.Expanded:
      return SnapPosition.Default;
  }

  return current;
}

export function getOffset(snap: SnapPosition, windowHeight: number, bottomOffset: number, contentHeight: number) {

  if (windowHeight === null) return null;

  switch (snap) {
    case SnapPosition.Expanded:
      return Math.max(MIN_OFFSET, windowHeight - (contentHeight + TABS_WITH_HANDLE_HEIGHT));
    case SnapPosition.Collapsed:
      return windowHeight - TABS_WITH_HANDLE_HEIGHT;
    default:
      return windowHeight - bottomOffset;
  }
}

const interpolator = (x: number) => (1 - (1 / (x + 1)));

export function momentum(value: number, min: number, max: number, tolerance = 100) {
  const clamped = Math.min(Math.max(value, min), max);

  const diff = value - clamped;
  const absDiff = Math.abs(diff);
  const deabs = (diff < 0 ? -1 : 1);

  return clamped + interpolator(absDiff / tolerance) * tolerance * deabs;
}

export function getNextSnap(currentSnap: SnapPosition, dy: number, mayBeExpanded: boolean): SnapPosition {
  let idx = SNAP_ORDER.indexOf(currentSnap);
  if (dy >= THRESHOLD) {
    idx++;
  }
  if (dy <= -THRESHOLD) {
    idx--;
  }

  const nextSnap = SNAP_ORDER[idx];

  if (nextSnap === SnapPosition.Expanded && !mayBeExpanded) {
    return currentSnap;
  }

  return nextSnap || currentSnap;
}

export function isScroll(dy: number, windowScrollY: number, isExpanded: boolean) {
  return (dy < 0 || (dy > 0 && windowScrollY > 3)) && isExpanded;
}
