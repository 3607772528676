import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {
  SaveSearchDrawer as SaveSearchDrawerImpl,
  EnablePushDrawer as EnablePushDrawerImpl,
} from './PushNotificationsDrawer';
import { State } from 'store/state';
import { pushNotificationsModalTypeSelector } from 'store/state/app/selectors';
import { PushNotificationsModalType } from 'store/state/app';
import { setPushNotificationsModalType, saveSearch } from 'store/state/app/actions';
import { addSaveSearchData } from 'store/state/selectors/savedSearchPage';
import { UpdateFrequency } from 'components/saved-search/config';
import { ReactNativeClientTunnelContext, REQUEST_PUSH } from 'react-native/reactNativeClientTunnel';


interface PushNotificationsDrawerProps {
  pushNotificationsModalType: PushNotificationsModalType;
  closeDrawer: () => void;
  onSaveSearch: (saveSilently: boolean) => void;
}

const PushNotificationsDrawer: React.FC<PushNotificationsDrawerProps> = (props) => {
  const { pushNotificationsModalType, closeDrawer, onSaveSearch } = props;
  const tunnel = useContext(ReactNativeClientTunnelContext);

  const handleRequestPush = () => {
    tunnel.push({ type: REQUEST_PUSH, payload: null });
    onSaveSearch(true);
  };

  switch (pushNotificationsModalType) {
    case PushNotificationsModalType.SaveSearch:
      return (
        <SaveSearchDrawerImpl
          onClose={closeDrawer}
          onSaveSearch={() => onSaveSearch(false)}
        />
      );
    case PushNotificationsModalType.EnablePushNotifications:
      return (
        <EnablePushDrawerImpl
          onClose={closeDrawer}
          requestPush={handleRequestPush}
        />
      );
    default:
      return null;
  }

};

const mapDispatchToProps = {
  onSaveSearch: saveSearch,
  closeDrawer: () => setPushNotificationsModalType(null),
};

const mapStateToProps = (state: State) => ({
  saveData: addSaveSearchData(state),
  pushNotificationsModalType: pushNotificationsModalTypeSelector(state),
});

export const PushNotificationsDrawerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  ({ saveData, ...stateProps }, { onSaveSearch, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    onSaveSearch: (saveSilently: boolean) => onSaveSearch({
      ...saveData,
      updateFrequency: UpdateFrequency.Daily,
    }, { source: 'pop_up', saveSilently }),
    ...ownProps,
  })
)(PushNotificationsDrawer);
