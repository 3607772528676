import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ISaveSearch, MutationType } from 'store/sagas/apiService/types';
import { DELETE_SAVE_SEARCH, SAVE_SEARCH, UPDATE_SAVED_SEARCH } from 'store/state/app/types';
import {
  deleteSaveSearch,
  saveSearch,
  setToastMessage,
  updateSaveSearch,
} from 'store/state/app/actions';
import { authGate, AuthGateResponse } from 'store/sagas/routing/handlers/utils';
import { mutate } from 'store/state/mutationsResponse/actions';
import { IPendingSaveSearch, PendingActionType } from 'components/authentication/types';
import { savedSearchesListSelector } from 'store/state/domainData/selectors';
import { isSearchEqual } from 'utils/saveSearch';
import { navigateTo } from 'store/state/router/actions';
import { Route } from 'config/routes';
import { pushNotificationsFeatureEnabled } from 'utils/pushNotificationsFeatureEnabled';


function* saveSearchWorker(action: ReturnType<typeof saveSearch>) {
  const { payload: search } = action;
  const { meta } = action;

  const pendingAction: IPendingSaveSearch = {
    type: PendingActionType.SaveSearch,
    meta,
    ...search,
  };

  const authGateResponse: AuthGateResponse = yield call(authGate, pendingAction);

  if (authGateResponse === AuthGateResponse.Rejected) {
    return;
  }

  const savedSearches: ISaveSearch[] = yield select(savedSearchesListSelector);

  const hasSaved = savedSearches.find((s) => isSearchEqual(s, search)) !== undefined;

  if (!meta.saveSilently) {
    const shouldUsePushFlow = yield call(pushNotificationsFeatureEnabled);

    if (shouldUsePushFlow) {
      yield put(navigateTo(Route.SavedSearchesPage));
    }
    else {
      yield put(setToastMessage({ term: 'savedSearch.addMessage', params: { frequency: search.updateFrequency } }));
    }

    if (hasSaved) {
      yield put(setToastMessage({ term: 'savedSearch.duplicateMessage' }));
    }
  }

  if (!hasSaved) {
    yield put(mutate({
      mutationType: MutationType.SaveSearchQuery,
      meta: {
        variables: {
          search,
        },
      },
    }));
  }
}

function* updateSearchWorker(action: ReturnType<typeof updateSaveSearch>) {
  const { payload: search } = action;

  const isAuthenticated = yield call(authGate);
  if (isAuthenticated === AuthGateResponse.Rejected) return;

  yield put(mutate({
    mutationType: MutationType.SaveSearchQuery,
    meta: {
      variables: { search },
    },
  }));
}

function* deleteSaveSearchWorker(action: ReturnType<typeof deleteSaveSearch>) {
  const isAuthenticated = yield call(authGate);
  if (isAuthenticated === AuthGateResponse.Rejected) return;

  yield put(setToastMessage({ term: 'savedSearch.deleteMessage' }));

  const searchId = action.payload;

  if (searchId) {
    yield put(mutate({
      mutationType: MutationType.DeleteSaveSearch,
      meta: {
        variables: { searchId },
      },
    }));
  }

}

export function* saveSearchWatcher() {
  yield takeEvery(SAVE_SEARCH, saveSearchWorker);
  yield takeEvery(UPDATE_SAVED_SEARCH, updateSearchWorker);
  yield takeEvery(DELETE_SAVE_SEARCH, deleteSaveSearchWorker);
}
