import { MultiInputValue } from '../types';
import { CompletionType, IAutocompleteEntry } from 'utils/entities';
import { last } from 'lodash';
import config from 'config';

const EMPTY: IAutocompleteEntry[] = [];

export default (values: MultiInputValue): MultiInputValue => {
  const value = values.length && last(values);

  if (values.length === 1 && value.docId === config.cityTerm) return EMPTY;

  const countryValue = values.find(v => v.type === CompletionType.Country);
  if (countryValue) return [ countryValue ];

  if (value.type === CompletionType.Project) return [ value ];

  return values.filter(v => {
    if (v.docId === value.docId) return v;
    else if (value.type === CompletionType.Borough || value.type === CompletionType.City) {
      return !v.hierarchyLevel.find(item => item.docId === value.docId);
    }
    else if (value.type === CompletionType.Neighbourhood) {
      return !value.hierarchyLevel.find(item => item.docId === v.docId);
    }
    return v;
  });
};
