import { Dispatch } from 'redux';
import { RootAction } from 'store/state';
import { setAuthModal } from 'store/state/app/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import React from 'react';
import { useLocale } from 'locale';
import { connect } from 'react-redux';
import { DefaultLink } from 'components/static-footer/styled';

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onOpenResetPasswordModal: () => dispatch(setAuthModal({ type: AuthenticationModalType.ResetPassword }, { isUserInitiated: false })),
});

const ResetPassword: React.FC<{onOpenResetPasswordModal: () => void}> = (props) => {
  const { t } = useLocale();
  const handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    props.onOpenResetPasswordModal();
  };

  return <DefaultLink href="#" onClick={handleClick}>{t('resetPassword.title')}</DefaultLink>;
};

export default connect(null, mapDispatchToProps)(ResetPassword);
