import ApolloClient, { ApolloQueryResult, NetworkStatus, FetchPolicy, QueryOptions } from 'apollo-client';
import { call, put, select, getContext, takeEvery, take, fork } from 'redux-saga/effects';

import { setDomainData, init, setFetchMoreResult, resetSSRHydration } from 'store/state/domainData/actions';

import { LoadType, LoadOptions, ResponseByType, VariablesByType } from './types';
import { FETCH_MORE } from 'store/state/domainData/types';
import { RootAction } from 'store/state';
import { isServer } from 'utils';
import { makeDomainGetter } from 'store/state/domainData/selectors/common';
import { Domain } from 'store/state/domainData';
import { isEqual, pickBy } from 'lodash';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { TRANSITION_START } from 'store/state/router/types';
import { enrichGQLVariables, getOperationByType } from './utils';
import { mutationsWatcher } from './mutationsWatcher';
import { LoadStrategy } from './LoadStrategy';
import { Fetcher } from 'utils/fetch-middleware';
import { Saga } from 'redux-saga';


type QueryResult<T extends LoadType> = ApolloQueryResult<ResponseByType[T]>;

function* responseHandler<T extends LoadType>(loadOpts: LoadOptions<T>, result: QueryResult<T>) {
  const ssr = yield call(isServer);
  yield put(setDomainData({
    loadType: loadOpts.loadType,
    payload: result,
    meta: {
      ...loadOpts.meta,
      wasSSRHydrated: ssr,
    },
  }));
}

function* apolloAdapter<T extends LoadType>(
  resolver: ((fetcher: Fetcher, variables: VariablesByType[T]) => Promise<ResponseByType[T]>) | Saga,
  options: QueryOptions<VariablesByType[T]>
) {

  const { fetchPolicy, errorPolicy = 'none', variables } = options;

  if (fetchPolicy !== 'no-cache') {
    throw new Error('[apiService] Custom resolvers only support "no-cache" fetchPolicy');
  }

  const queryResult: QueryResult<T> = {
    data: null,
    loading: false,
    networkStatus: NetworkStatus.ready,
    stale: false,
  };

  const fetcher = yield getContext('fetcher');

  try {
    queryResult.data = yield call(resolver, fetcher, variables);
  }
  catch (e) {
    switch (errorPolicy) {
      case 'all':
        queryResult.errors = [ e ];
        queryResult.networkStatus = NetworkStatus.error;
        break;
      case 'ignore':
        break;
      case 'none':
        throw e;
    }
  }

  return queryResult;
}

const fetchMorePattern = <T extends LoadType>(action: RootAction): action is LoadOptions<T> & { type: typeof FETCH_MORE } => (
  action.type === FETCH_MORE
);

function* getQueryResult<T extends LoadType>(queryOptions: QueryOptions<VariablesByType[T]>, query: LoadStrategy<T>) {
  switch (query.kind) {
    case 'Document': {
      const client: ApolloClient<unknown> = yield getContext('client');
      return yield call(client.query, { ...queryOptions, query });
    }
    case 'CustomResolver':
      return yield call(apolloAdapter, query.resolver, queryOptions);
  }
}

function* fetchMoreHandler<T extends LoadType>(action: LoadOptions<T>) {
  const { loadType, meta } = action;
  const query: LoadStrategy<T> = yield call(getOperationByType, 'query', loadType);

  const queryOptions: QueryOptions<VariablesByType[T]> = {
    variables: meta.variables,
    query: null,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  };

  const payload: QueryResult<T> = yield call(getQueryResult, queryOptions, query);

  if (payload.errors && payload.errors.length) {
    // tslint:disable-next-line: no-console
    console.error(...payload.errors);
  }
  else yield put(setFetchMoreResult({ loadType, payload, meta }));
}

const defaultFetchPolicy: FetchPolicy = 'no-cache';

/**
 * @param loadOptions describes query to query
 * @returns void
 * @todo test this saga
 */
export function* queryData<T extends LoadType>(loadOptions: LoadOptions<T>) {
  const { loadType, meta } = loadOptions;
  const query: LoadStrategy<T> = yield call(getOperationByType, 'query', loadType);

  const variables: VariablesByType[T] = yield call(enrichGQLVariables, query, meta.variables);

  const selector = yield call(makeDomainGetter, loadType);
  const currentData: Domain<LoadType> = yield select(selector);
  const hasData = Boolean(currentData && currentData.meta);
  const hadSSRHydration = hasData && currentData.meta.wasSSRHydrated;

  const areVariablesEqual = hasData && isEqual(
    pickBy(currentData.meta.variables, (v) => v !== undefined),
    pickBy(meta.variables, (v) => v !== undefined)
  );

  // TODO: maybe remove hadSSRHydration thing when insights geodata and data get decoupled
  if (!areVariablesEqual) {
    if (!hadSSRHydration) {
      yield put(init({
        loadType,
        networkStatus: meta.initialize ? NetworkStatus.loading : NetworkStatus.setVariables,
      }));
    }

    const queryOptions: QueryOptions<VariablesByType[ T ]> = {
      variables,
      fetchPolicy: meta.__fetchPolicy || defaultFetchPolicy,
      query: null,
    };

    if (meta.errorPolicy) {
      queryOptions.errorPolicy = meta.errorPolicy;
    }

    const queryResult: QueryResult<T> = yield call(getQueryResult, queryOptions, query);

    yield call(responseHandler, loadOptions, queryResult);
  }
  else if (hadSSRHydration) {
    yield put(resetSSRHydration({ loadType }));
  }
}

const MAX_ENTRIES_NUM = 2000;
const getCacheSize = (client: ApolloClient<unknown>) => Object.keys((client.cache as any).data.data).length;

export function* apiServiceWorker() {
  yield takeEvery(fetchMorePattern, fetchMoreHandler);
  yield fork(mutationsWatcher);
  const client: ApolloClient<NormalizedCacheObject> = yield getContext('client');

  while (true) {
    yield take(TRANSITION_START);
    const num = yield call(getCacheSize, client);
    if (num > MAX_ENTRIES_NUM) {
      yield call([ client.cache, client.cache.reset ]);
    }
  }
}
