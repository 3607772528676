import styled from '@emotion/styled';

export const AgentPhoto = styled.div<{ imageURL: string }>`
  ${({ theme, imageURL }) => `
    width: 40px;
    min-width: 40px;
    height: 40px;
    border: 1px solid ${theme.colors.neutrals.grey6};
    border-radius: ${theme.borderRadius.round};
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-${theme.isRTL ? 'left' : 'right' }: ${theme.spacing(2)};
    background-color: ${theme.colors.neutrals.grey6};
    ${imageURL ? `background-image: url(${imageURL});` : ''}
  `}
`;
