import { styled } from 'ds';
import { H3, Text } from 'ds/components/typography';

export const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(4)} 0;
    width: 100%;
    .carousel-shadow-root div.view-pager-rail {
      padding-right: 0;
    }

    .slide-btn[dir="next"] {
      left: -${theme.spacing(2)};
    }
    .slide-btn[dir="prev"] {
      right: -${theme.spacing(2)};
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)};
    svg {
      display: block;
    }
  `}
`;

export const Title = styled(H3.withComponent('h2'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const SubTitle = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
  `}
`;
