import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { InsightType } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';

export const Wrapper = styled.div`

`;

export const AdditionalDataWrapper = styled.div`

`;

export const AdditionalData = styled.div`
  display: flex;
  align-items: center;
  > div:first-of-type {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 32px;
  }
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    justify-content: center;
  `}
`;

export const AdditionalDataItem = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    > div {
      margin-${theme.isRTL ? 'right' : 'left'}: 16px;
      width: 136px;
      color: ${theme.colors.neutrals.grey2};
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column;
    > div {
      margin-top: 12px;
      text-align: center;
      width: 124px;
      color: ${theme.colors.neutrals.grey2};
    }
  `}
`;

export const AdditionalDataTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  margin-bottom: 24px;
  font-weight: 700;
`;

export const CelebrationWrapper = styled.div`
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${({ theme }) => theme.colors.insights[InsightType.Livability]};
  background-color: ${({ theme }) => colorToAlpha(theme.colors.insights[InsightType.Livability], 0.05)};
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 16px;
    flex-shrink: 0;
  }
`;

export const QuietnessMeterWrapper = styled.div`
  padding: 24px 0 64px;
`;

export const QuietnessMeterBarIndicators = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const QuietnessMeterBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    &:nth-of-type(1) {
      background-color: #b5e394;
      border-top-${({ theme }) => theme.isRTL ? 'right' : 'left'}-radius: 2px;
      border-bottom-${({ theme }) => theme.isRTL ? 'right' : 'left'}-radius: 2px;
    }
    &:nth-of-type(2) {
      background-color: #7abe5c;
    }
    &:nth-of-type(3) {
      background-color: #5c946d;
    }
    &:nth-of-type(4) {
      background-color: #506b63;
      border-top-${({ theme }) => theme.isRTL ? 'left' : 'right'}-radius: 2px;
      border-bottom-${({ theme }) => theme.isRTL ? 'left' : 'right'}-radius: 2px;
    }
  }
`;

export const QuietnessMeterStage = styled.div<{ isActive: boolean }>`
  flex-basis: 25%;
  height: 10px;
  position: relative;
  ${({ isActive, theme }) => isActive ? `
    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 2px solid ${theme.colors.neutrals.white};
      border-radius: ${theme.borderRadius.tiny};
    }
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border: 2px solid #69a160;
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.shadow.level2};
    }
  ` : ''}
`;
