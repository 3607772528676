import React from 'react';
import { QuietnessMeter } from 'components/insight-summary-renderer/QuietStreet/QuietnessMeter';
import WidgetBody from 'components/widget/common/widget-body';
import WidgetWrapper from 'components/widget/common/widget-wrapper';
import { Title, Message } from './styled';
import { Celebration } from 'components/insight-summary-renderer/QuietStreet/Celebration';
import { InsightSection } from 'store/state/insightsContext';
import { InsightType } from 'utils/entities';
import { useLocale } from 'locale';
import { IQuietStreet } from 'store/state/selectors/insights/summaryTypes';
import { IInsightPreview } from 'components/widget/common/styled';
import { useTheme } from 'hooks/useTheme';
import CarouselStories from 'components/view-pager-to-stories';
import WidgetCollapsedWrapper from 'components/widget/common/widget-collapsed-wrapper';
import { useCheckProjectPage } from 'hooks/useCheckProjectPage';

export interface QuietStreetsWidgetProps {
  preview?: string;
  data?: IQuietStreet;
  onSeeAllClick?: (id: string) => void;
  id?: string;
  onReadMoreCarouselClick: (id: string) => void;
  carouselData: IInsightPreview[];
  carouselIdx: number;
  setActiveCarouselIdx: (index: number) => void;
  isCollapsed: boolean;
  showCarousel: boolean;
}

const QuietStreetsWidget: React.FC<QuietStreetsWidgetProps> = ({
  preview,
  data,
  onSeeAllClick,
  id,
  onReadMoreCarouselClick,
  carouselData,
  carouselIdx,
  setActiveCarouselIdx,
  isCollapsed,
  showCarousel,
}) => {
  const { colors } = useTheme();
  const { t } = useLocale();
  const isProjectPage = useCheckProjectPage();

  const widgetHasCarouselData = (!isCollapsed || isProjectPage) && showCarousel && carouselData && carouselData.length;

  if (!data && !widgetHasCarouselData) return null;

  const carousel = (
    <CarouselStories
      insights={carouselData}
      onReadMoreClick={onReadMoreCarouselClick}
      slideIndex={carouselIdx}
      onSlideIndexChange={(idx) => setActiveCarouselIdx(idx)}
      color={colors.insights.livability}
    />
  );

  const wrapperProps = {
    carousel: widgetHasCarouselData ? carousel : null,
    targetId: InsightSection.QuietStreets,
    styleType: InsightType.Livability,
    title: t('widgetTitle.quietStreets'),
  };

  const child = data ? (
    <WidgetBody
      styleType={InsightType.Livability}
      seeAllTitle={t('widgetSeeAll.quietStreets')}
      onSeeAllClick={() => onSeeAllClick(id)}
      isActive
    >
      <Title>{preview}</Title>
      <Message>
        {data.celebration.type ? (
          <Celebration generalText={data.generalText} {...data.celebration} />
        ) : null}
      </Message>
      <QuietnessMeter value={data.bin} />
    </WidgetBody>
  ) : null;

  return isCollapsed ? (
    <WidgetCollapsedWrapper {...wrapperProps} dynamicContentData={data}>
      {child}
    </WidgetCollapsedWrapper>
  ) : <WidgetWrapper {...wrapperProps}>{child}</WidgetWrapper>;
};

export default QuietStreetsWidget;
