import React from 'react';

import { ItemStyledProps, ItemStyled } from './styled';
import { toBreakpoints } from 'consts/breakpoints';


export default class Item extends React.Component<ItemStyledProps, never> {
  private node = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    if (this.props.selected && window.matchMedia(`(max-width: ${toBreakpoints[1]})`).matches) {
      (this.node.current as HTMLElement).scrollIntoView();
    }
  }

  public render() {
    return (
      <ItemStyled {...this.props} ref={this.node} />
    );
  }
}
