import React, { useEffect, useRef, useState } from 'react';
import { KeyboardKeys } from 'utils/keyboardKeys';
import { InputWrapper, CodeInputsWrapper, ErrorText } from './styled';

interface CodeInputProps {
  value: string;
  setValue: (value: string) => void;
  inputParser?: (value: string) => string;
  error?: React.ReactNode;
  codeLength?: number;
  resetFocus?: boolean;
  numerical?: boolean;
  disabled?: boolean;
}

const DEFAULT_CODE_LENGTH = 4;

const defaultInputParser = (val: string) => val.replace(/\D+/, '');
const parse = (val: string, len: number) => {
  const emptyArr = new Array(len).fill('');
  if (val) {
    return [ ...val.split(''), ...emptyArr ].slice(0, len);
  }

  return [ ...emptyArr ];
};

const CodeInput: React.FC<CodeInputProps> = ({
  value,
  error,
  setValue,
  resetFocus,
  inputParser = defaultInputParser,
  numerical = true,
  codeLength = DEFAULT_CODE_LENGTH,
  disabled = false,
}) => {
  const [ code, setCode ] = useState(parse(value, codeLength));
  const inputsRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (resetFocus) {
      updateCode(new Array(codeLength).fill(''));
      const el = inputsRefs.current[0];
      if (el) el.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ resetFocus ]);

  const updateCode = (val: string[]) => {
    setCode(val);
    setValue(val.join(''));
  };

  const onCodeClick = (pos: number) => () => {
    const el = inputsRefs.current[pos];
    el.setSelectionRange(0, 1);
  };

  const onKeyDown = (pos: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Backspace && e.currentTarget.value) {
      updateCode([ ...code.slice(0, pos), '', ...code.slice(pos + 1) ]);
    }
    else if (e.key === KeyboardKeys.Backspace && e.currentTarget.value === '' || e.key === KeyboardKeys.Left) {
      const el = inputsRefs.current[pos - 1];
      if (el) {
        el.focus();
        if (e.key === KeyboardKeys.Left) {
          el.setSelectionRange(1, 1);
        }
      }
    }
    if (e.key === KeyboardKeys.Right) {
      const el = inputsRefs.current[pos + 1];
      if (el) {
        el.focus();
        el.setSelectionRange(0, 0);
      }
    }
  };

  const onCodeChange = (pos: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = e.currentTarget;
    const data = inputParser ? inputParser(currentValue) : currentValue;

    if (data.length === codeLength) {
      updateCode(data.toString().split(''));
    }
    else if (data.length <= 1) {
      updateCode([ ...code.slice(0, pos), data.toString(), ...code.slice(pos + 1) ]);
      let n = 0;
      if (!code[pos] && !data && !isNaN(+currentValue)) {
        n = -1;
      }
      else if (data) {
        n = 1;
      }

      const el = inputsRefs.current[pos + n];
      if (el && n) {
        el.focus();
        el.setSelectionRange(0, 1);
      }
    }
    else {
      const [ first, second ] = data.split('');
      updateCode([ ...code.slice(0, pos), code[0] === first ? second : first, ...code.slice(pos + 1) ]);

      const el = inputsRefs.current[pos + 1];
      if (el) {
        el.focus();
        el.setSelectionRange(0, 1);
      }
    }
  };

  return (
    <>
      <CodeInputsWrapper hasBottomMargin={!error}>
        {code.map((val, key) => (
          <InputWrapper data-auto="verification-input" key={key} hasError={!!error} onClick={onCodeClick(key)}>
            <input
              disabled={disabled}
              ref={(r) => inputsRefs.current[key] = r}
              onKeyDown={onKeyDown(key)}
              value={val || ''}
              onChange={onCodeChange(key)}
              autoComplete={key === 0 ? 'one-time-code' : undefined}
              size={1}
              type={numerical ? 'tel' : 'text'}
            />
          </InputWrapper>
        ))}
      </CodeInputsWrapper>
      {error ? (<ErrorText weight="medium">{error}</ErrorText>) : null}
    </>
  );
};

export default CodeInput;
