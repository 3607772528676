import styled from '@emotion/styled';


interface FooterStickyContainerProps {
  withAdsBanner: boolean;
  withLayoutIndex: boolean;
}

export const FooterStickyContainer = styled.div<FooterStickyContainerProps>`
  ${({ withAdsBanner, withLayoutIndex }) => `
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
    transition: transform 300ms;
    will-change: transform;
    ${withAdsBanner ? 'transform: translateY(-58px);' : ''}
    ${withLayoutIndex ? 'z-index: 1000;' : ''}
  `}
`;
