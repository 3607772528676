import { Route, State as RouteState } from 'config/routes';
import { UserTokenStore } from 'helpers/userToken';
import { call, fork, getContext, put, select, spawn } from 'redux-saga/effects';
import { AuthenticationModalType } from 'components/authentication/types';
import { setAuthModal } from 'store/state/app/actions';
import { verifyRessetPasswordToken, withAuthRestrictor } from '../utils';
import { navigateTo } from 'store/state/router/actions';
import { agentLoginFlow } from '../agentProfilePage';
import { authModalStatusSelector } from 'store/state/app/selectors';
import { isServer } from 'utils';


export function* profilePageHandler(initialState: RouteState) {
  const activeAuthModal: AuthenticationModalType = yield select(authModalStatusSelector);
  if (initialState.params.agentLoginToken) {
    yield spawn(agentLoginFlow, initialState);
  }
  else if (activeAuthModal !== AuthenticationModalType.AgentSetPassword) {
    yield fork(handlerWithAuthRestrictor, initialState);
  }
}

const handlerWithAuthRestrictor = withAuthRestrictor(handler);

function* handler(initialState: RouteState) {
  const tokenStore: UserTokenStore = yield getContext('tokenStore');
  const token = tokenStore.get();
  const resetPasswordToken = initialState.params.token;

  if (token && resetPasswordToken) {
    const isTokenValid: boolean = yield call(verifyRessetPasswordToken, resetPasswordToken);

    if (isTokenValid) {
      yield put(setAuthModal({ type: AuthenticationModalType.SetPassword }, { isUserInitiated: false }));
      const ssr = yield call(isServer);
      if (!ssr) {
        yield put(navigateTo(Route.Profile, { ...initialState.params, token: undefined }, { replace: true }));
      }
    }
    else {
      yield put(setAuthModal({ type: AuthenticationModalType.ResetPassword }, { isUserInitiated: false }));
      yield put(navigateTo(Route.Home, null, { replace: true }));
    }
  }
}
