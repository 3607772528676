import React from 'react';
import { useField, useFormState } from 'react-final-form-hooks';
import { ITranslate, useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import RadioGroup from 'ds/components/input/RadioGroup';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { Amenity } from 'utils/entities';
import CheckboxChip from 'ds/components/chips/CheckboxChip';
import { InputWrapper, Section, SectionHeading, SelectWrapper, StepHeading, RadioGroupWrapper } from 'screens/UploadBulletinPage/styled';
import { SpacingBlock } from 'components/spacing-block';
import { UploadBulletinStepProps } from '../';
import { hideBuildingDetails } from 'screens/UploadBulletinPage/helpers';
import { DESCRIPTION_MAX_LENGTH } from './constants';
import {
  buildingYearValidationMessage,
  genAmenitiesOptions,
  genFurnitureOptions,
  genGeneralConditionOptions,
  genQualityClassOptions,
  validateField,
} from './helpers';
import { CheckBoxWrapper, SecondSectionWrapper } from './styled';
import { isNotEmpty, PHONE_TEXT_REGEXP } from 'utils/formValidationHelpers';


const TextareaWithValidationMessage = withValidationMessage(TextField);
const RadioGroupWithValidationMessage = withValidationMessage(RadioGroup);
const TextFieldWithValidationMessage = withValidationMessage(TextField);

const validateDescription = (t: ITranslate) => (val: string) => {
  return isNotEmpty(val) && val.match(PHONE_TEXT_REGEXP)
    ? t('uploadBulletinPage.descriptionValidationMessage')
    : undefined;
};

const AdditionalInfoStep: React.FC<UploadBulletinStepProps> = ({
  formInstance,
  isCommercial,
  hideErrorOnFocus,
}) => {
  const { t } = useLocale();

  const buildingClass = formInstance.getState().values.buildingClass;
  const hideFields = hideBuildingDetails(buildingClass, isCommercial);

  const buildingYearField = useField('buildingYear', formInstance, buildingYearValidationMessage(t));
  const generalConditionField = useField('generalCondition', formInstance, isCommercial || hideFields ? undefined : validateField(t));
  const qualityClassField = useField('qualityClass', formInstance);
  const descriptionField = useField('description', formInstance, validateDescription(t));
  const furnitureField = useField('furniture', formInstance);
  const fState = useFormState(formInstance);


  const onAmenityClick = (value: Amenity) => {
    const list = new Set(fState.values.amenities);
    if (list.has(value)) {
      list.delete(value);
    }
    else {
      list.add(value);
    }
    formInstance.change('amenities', [ ...list ]);
  };

  return (
    <>
      <StepHeading>{t('uploadBulletinPage.buildingDescriptionStep.heading')}</StepHeading>

      <Section>
        <SpacingBlock mBottom={2}>
          <SectionHeading weight="bold">
            {t('uploadBulletinPage.buildingDescriptionStep.amenitiesHeading')}
          </SectionHeading>
        </SpacingBlock>

        <SelectWrapper>
          {genAmenitiesOptions(t, isCommercial).map(el => (
            <CheckBoxWrapper key={el.value}>
              <CheckboxChip
                mode={fState.values.amenities.includes(el.value as Amenity) ? 'selected' : undefined}
                onClick={() => onAmenityClick(el.value as Amenity)}
                {...el}
              />
            </CheckBoxWrapper>
          ))}
        </SelectWrapper>
      </Section>

      {hideFields ? null : (
        <Section>
          <SpacingBlock mBottom={3}>
            <SectionHeading weight="bold">
              {t('uploadBulletinPage.buildingDescriptionStep.propertyStatusHeading')}
            </SectionHeading>
          </SpacingBlock>

          <RadioGroupWrapper error={generalConditionField.meta.error && generalConditionField.meta.touched}>
            <RadioGroupWithValidationMessage
              {...generalConditionField.input}
              {...generalConditionField.meta}
              options={genGeneralConditionOptions(t, isCommercial)}
            />
          </RadioGroupWrapper>

          {isCommercial ? (
            <>
              <SpacingBlock mTop={3} mBottom={2}>
                <SectionHeading weight="bold">
                  {t('uploadBulletinPage.buildingDescriptionStep.qualityClassHeading')}
                </SectionHeading>
              </SpacingBlock>

              <RadioGroupWithValidationMessage
                {...qualityClassField.input}
                {...qualityClassField.meta}
                options={genQualityClassOptions(t)}
              />
            </>
          ) : null}

          <SecondSectionWrapper>
            <SpacingBlock mBottom={2}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.buildingDescriptionStep.buildingYear')}
              </SectionHeading>
            </SpacingBlock>
            <InputWrapper error={buildingYearField.meta.touched && buildingYearField.meta.error}>
              <TextFieldWithValidationMessage
                {...buildingYearField.input}
                {...buildingYearField.meta}
                onFocus={() => hideErrorOnFocus(true)}
                onBlur={() => hideErrorOnFocus(false)}
                type="number"
                min="0"
                step="1"
              />
            </InputWrapper>
          </SecondSectionWrapper>
        </Section>
      )}

      <Section>
        {hideFields ? null : (
          <>
            <SpacingBlock mBottom={2}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.buildingDescriptionStep.furnitureTextHeading')}
              </SectionHeading>
            </SpacingBlock>

            <RadioGroupWithValidationMessage
              {...furnitureField.input}
              {...furnitureField.meta}
              options={genFurnitureOptions(t)}
            />
          </>
        )}
        <SecondSectionWrapper>
          <SpacingBlock mBottom={2}>
            <SectionHeading weight="bold">
              {t('uploadBulletinPage.buildingDescriptionStep.textHeading')}
            </SectionHeading>
          </SpacingBlock>

          <InputWrapper fullWidth error={descriptionField.meta.touched && descriptionField.meta.error}>
            <TextareaWithValidationMessage
              {...descriptionField.input}
              {...descriptionField.meta}
              onFocus={() => hideErrorOnFocus(true)}
              onBlur={() => hideErrorOnFocus(false)}
              charactersCounterMax={DESCRIPTION_MAX_LENGTH}
              maxLength={DESCRIPTION_MAX_LENGTH}
              type="text"
              multiline
              rows={4}
              fullWidth
              placeholder={t('uploadBulletinPage.buildingDescriptionStep.textareaPlaceholder')}
            />
          </InputWrapper>
        </SecondSectionWrapper>
      </Section>
    </>
  );
};

export default AdditionalInfoStep;
