import { createSelector } from 'reselect';
import { prevRouteSelector, routeSelector } from './router';
import { Route } from 'config/routes';
import config from 'config';
import { ContactAgentModalSource } from '../app';

const campaignProjectId = config.campaignProjectId;

const showIntentModalSelector = createSelector(
  routeSelector,
  (route) => (route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial)
  && (
    (
      route.params.utm_campaign
      && route.params.utm_campaign.toLowerCase().startsWith('remarketing_')
      && route.params.id === campaignProjectId
    )
    || (route.params.mp_remarketing && route.params.mp_remarketing === 'true')
  )
);

const showLandingIntentModalSelector = createSelector(
  routeSelector,
  prevRouteSelector,
  (route, prevRoute) => (route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial) && !prevRoute
);

export const intentModalSourceSelector = createSelector(
  showIntentModalSelector,
  showLandingIntentModalSelector,
  (showIntentModal, showIntentLandingModal) => showIntentModal
    ? ContactAgentModalSource.ExitModal
    : (showIntentLandingModal ? ContactAgentModalSource.ExitLandingModal : null)
);
