import styled from '@emotion/styled';
import { ResetButton } from './ResetButton';
import { SpacingScale } from 'ds/theme/spacing';
import { typographyFontSizeCfg } from '../typography/types';
import defaultTheme from 'ds/theme/default';
import { colorToAlpha } from 'helpers/insight';

type Size = 'medium' | 'large';
type Type = 'default' | 'important';
export type Mode = 'default' | 'selected' | 'priority' | 'filter' | 'transparent';
type State = 'default' | 'hovered' | 'selected' | 'disabled';

export interface ControlButtonProps {
  size?: Size;
  mode?: Mode;
  fontType?: Type;
  onlyIcon?: boolean; // used to make a rounded corners button with no additional margins on the icon
  isRound?: boolean;
  withShadow?: boolean;
  fullWidth?: boolean;
  iconPosition?: 'start' | 'end';
  isInitialIconColor?: boolean;
}

const sizeToHeight: Record<Size, SpacingScale> = {
  medium: 4,
  large: 5,
};

const sizeToSidePadding: Record<Size, SpacingScale> = {
  medium: 1.5,
  large: 2,
};

const sizeToFontSize = {
  medium: typographyFontSizeCfg.smallText,
  large: typographyFontSizeCfg.smallText,
};

const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey2,
  },
  hovered: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey2,
  },
  selected: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey2,
  },
  disabled: {
    'transparent': defaultTheme.colors.neutrals.grey4,
    'default': defaultTheme.colors.neutrals.grey4,
    'selected': defaultTheme.colors.neutrals.grey4,
    'priority': defaultTheme.colors.neutrals.grey4,
    'filter': defaultTheme.colors.neutrals.grey4,
  },
};

const modeToIconColor: Record<State, Record<Mode, string>> = {
  default: {
    'transparent': defaultTheme.colors.neutrals.transparent,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey1,
  },
  hovered: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey1,
  },
  selected: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey2,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.main,
    'filter': defaultTheme.colors.neutrals.grey1,
  },
  disabled: {
    'transparent': defaultTheme.colors.neutrals.grey4,
    'default': defaultTheme.colors.neutrals.grey4,
    'selected': defaultTheme.colors.neutrals.grey4,
    'priority': defaultTheme.colors.neutrals.grey4,
    'filter': defaultTheme.colors.neutrals.grey4,
  },
};

const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    'transparent': defaultTheme.colors.neutrals.transparent,
    'default': defaultTheme.colors.neutrals.white,
    'selected': defaultTheme.colors.neutrals.white,
    'priority': colorToAlpha(defaultTheme.colors.brand.lightest, 0.7),
    'filter': defaultTheme.colors.neutrals.white,
  },
  hovered: {
    'transparent': defaultTheme.colors.neutrals.transparent,
    'default': defaultTheme.colors.neutrals.grey9,
    'selected': defaultTheme.colors.neutrals.grey9,
    'priority': colorToAlpha(defaultTheme.colors.brand.lightest, 0.9),
    'filter': defaultTheme.colors.neutrals.grey9,
  },
  selected: {
    'transparent': defaultTheme.colors.neutrals.transparent,
    'default': defaultTheme.colors.neutrals.grey9,
    'selected': defaultTheme.colors.neutrals.grey9,
    'priority': colorToAlpha(defaultTheme.colors.brand.lightest, 0.9),
    'filter': defaultTheme.colors.neutrals.grey9,
  },
  disabled: {
    'transparent': defaultTheme.colors.neutrals.transparent,
    'default': defaultTheme.colors.neutrals.grey10,
    'selected': defaultTheme.colors.neutrals.grey10,
    'priority': defaultTheme.colors.neutrals.grey10,
    'filter': defaultTheme.colors.neutrals.grey10,
  },
};

const modeToBorderColor: Record<State, Record<Mode, string>> = {
  default: {
    'transparent': defaultTheme.colors.neutrals.grey2,
    'default': defaultTheme.colors.neutrals.grey7,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.lighter,
    'filter': defaultTheme.colors.neutrals.grey7,
  },
  hovered: {
    'transparent': defaultTheme.colors.neutrals.grey9,
    'default': defaultTheme.colors.neutrals.grey9,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.lighter,
    'filter': defaultTheme.colors.neutrals.grey9,
  },
  selected: {
    'transparent': defaultTheme.colors.neutrals.grey9,
    'default': defaultTheme.colors.neutrals.grey9,
    'selected': defaultTheme.colors.brand.main,
    'priority': defaultTheme.colors.brand.lighter,
    'filter': defaultTheme.colors.neutrals.grey9,
  },
  disabled: {
    'transparent': defaultTheme.colors.neutrals.grey7,
    'default': defaultTheme.colors.neutrals.grey7,
    'selected': defaultTheme.colors.neutrals.grey7,
    'priority': defaultTheme.colors.neutrals.grey7,
    'filter': defaultTheme.colors.neutrals.grey7,
  },
};

export const ControlButton = styled(ResetButton)<ControlButtonProps>`
  ${({ theme, mode = 'default', size = 'medium', onlyIcon, isRound, fontType = 'default', withShadow, fullWidth, iconPosition = 'start', isInitialIconColor }) => `
    font-size: ${sizeToFontSize[size]}px;
    font-weight: ${fontType === 'important' ? 500 : 400};
    height: ${theme.spacing(sizeToHeight[size])};
    padding: 0 ${onlyIcon ? 0 : theme.spacing(sizeToSidePadding[size])};
    border-radius: ${theme.borderRadius.small};
    display: inline-flex;
    align-items: center;
    transition: all .2s ease;
    justify-content: center;
    cursor: pointer;
    width: ${fullWidth ? '100%' : (onlyIcon ? theme.spacing(sizeToHeight[size]) : 'auto')};
    > svg {
      margin-${iconPosition === 'start'
        ? (theme.isRTL ? 'left' : 'right')
        : (theme.isRTL ? 'right' : 'left')}: ${onlyIcon ? 0 : theme.spacing(1)};
    }
    ${withShadow ? `box-shadow: ${theme.shadow.level2};` : ''}

    ${isInitialIconColor ? '' : `
      > svg path { fill: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToIconColor.default[mode]}; }
    `}

    color: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToFontColor.default[mode]};
    background: ${modeToBgColor.default[mode]};
    border: 1px solid ${modeToBorderColor.default[mode]};

    ${isRound && `
      width: 39px;
      height: 39px;
      border-radius: ${theme.borderRadius.round};
    `}
  `}
  ${({ theme, mode = 'default', fontType, isInitialIconColor }) => theme.media.showAt({ isTouch: false, to: 5 }) `
    &:hover {
      ${isInitialIconColor ? '' : `
        > svg path { fill: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToIconColor.hovered[mode]}; }
      `}

      color: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToFontColor.hovered[mode]};
      background: ${modeToBgColor.hovered[mode]};
      border: 1px solid ${modeToBorderColor.hovered[mode]};
    }
    &:active {
      ${isInitialIconColor ? '' : `
        > svg path { fill: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToIconColor.selected[mode]}; }
      `}

      color: ${fontType === 'important' ? theme.colors.neutrals.grey1 : modeToFontColor.selected[mode]};
      background: ${modeToBgColor.selected[mode]};
      border: 1px solid ${modeToBorderColor.selected[mode]};
    }
  `}
  ${({ mode = 'default', isInitialIconColor }) => `
    &[disabled] {
      pointer-events: none;

      ${isInitialIconColor ? '' : `
        > svg path { fill: ${modeToIconColor.disabled[mode]}; }
      `}

      border: 1px solid ${modeToBorderColor.disabled[mode]};
      color: ${modeToFontColor.disabled[mode]};
      background: ${modeToBgColor.disabled[mode]};
    }
  `}
`;

export const CircularControlButton = styled(ControlButton)`
  width: 39px;
  height: 39px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
`;

