import { animated } from 'react-spring';
import styled from '@emotion/styled';
import { H3 } from 'ds/components/typography';

export const ReviewStepTitle = styled(H3)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    width: 100%;
  `}
`;

export const ReviewNameWrapper = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(5)};

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 338px;
  margin: 0 auto;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    max-width: 100%;
    padding-top: ${theme.spacing(11)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    height: 354px;
  `}
`;

export const ProgressLine = styled.div<{ width: number }>`
  ${({ theme, width }) => `
    width: 100%;
    border-radius: ${theme.borderRadius.micro};
    background-color: ${theme.colors.neutrals.grey9};
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      content: "";
      display: block;
      border-radius: 0 ${theme.borderRadius.micro} ${theme.borderRadius.micro} 0;
      background-color: ${theme.colors.brand.main};
      height: 4px;
      width: ${width || 0}%;
      min-width: 1%;
      transition: width .35s ease-in;
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    top: 80px;
  `}
`;

export const AnimatedStepWrapper = animated(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`);

export const StepsNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

export const NextStepBtn = styled.div`
  cursor: pointer;
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: auto;
`;
