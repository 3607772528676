import { makeNullSafeDomainGetter, makeByIdsDictionarySelector, createIsLoadingSelector, streetSearchSelector } from './common';
import { flow } from 'lodash';
import { IBulletin, IProject } from 'utils/entities';
import { createSelector } from 'reselect';
import { ResolutionPreference } from 'utils/entities';


type Poi = IBulletin | IProject;
const EMPTY: Poi[] = [];

const streetSearchListSelector = makeNullSafeDomainGetter(streetSearchSelector, 'poiByDocId');
export const isStreetSearchLoadingSelector = createIsLoadingSelector(streetSearchSelector);

export const streetListSelector = flow(streetSearchListSelector, (search) => search && search.poi ? search.poi : EMPTY);

export const filterStreetListSelector = createSelector([
  streetListSelector,
], (list) => (
  list.filter(poi => {
    if (poi.type === 'project') return true;
    const { addressDetails: { resolutionPreferences: resPref } } = poi;
    return resPref === ResolutionPreference.Accurate || resPref === ResolutionPreference.Street;
  })
));

export const streetListCountSelector = flow(filterStreetListSelector, (search) => search ? search.length : 0);

export const streetSearchPoiDictionarySelector = makeByIdsDictionarySelector(streetListSelector);
