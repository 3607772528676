import { isServer } from '@madlan145/client-toolbox';

export type Platform = 'ios' | 'android';

interface MobileAppConfig {
  hideNesachTabu?: boolean;
  platform?: Platform;
}

const mobileAppConfig: MobileAppConfig = isServer ? {} : ((window as any).__MADLAN_MOBILE_CONFIG__ || {});

export default mobileAppConfig;
