import { connect } from 'react-redux';
import withEvents from 'analytics/withEvents';
import { State } from 'store/state';
import { setGalleryStatus } from 'store/state/app/actions';
import { galleryPopupSlideIndexSelector, galleryPopupIsOpenSelector } from 'store/state/app/selectors';
import Gallery, { GalleryOwnProps, GalleryStateProps } from './Gallery';

const mapStateToProps = (state: State) => ({
  isOpen: galleryPopupIsOpenSelector(state),
  slideIndex: galleryPopupSlideIndexSelector(state),
});

const mapDispatchToProps = {
  setGalleryStatus,
};

const withEventsGallery = withEvents<GalleryOwnProps & GalleryStateProps>((sendEvent) => ({
  eventPropertyFloorPlanOpen() {
    sendEvent('property_floor_plan_open', 'property');
  },
  eventViewPhotoScroll({ dir, image, index, total }) {
    const isFloorPlanPhoto = Boolean(image.isFloorPlan);
    sendEvent('property_view_photo_scroll', 'property', {
      event: {
        direction_scrolled: dir,
        image: {
          description: image.description,
          image_url: image.url,
          isFloorPlanPhoto,
        },
        image_index: index,
        image_source: 'property_page',
        index_total: total,
      },
    });
  },
  eventViewPhotoGalleryOpen({ image, index, total }) {
    const isFloorPlanPhoto = Boolean(image.isFloorPlan);
    const eventAction = isFloorPlanPhoto ? 'property_floor_plan_open' : 'property_view_photo_gallery_open';
    sendEvent(eventAction, 'property', {
      event: {
        image: {
          description: image.description,
          image_url: image.url,
          isFloorPlanPhoto,
        },
        image_index: index,
        index_total: total,
      },
    });
  },
}))(Gallery);

export default connect(mapStateToProps, mapDispatchToProps)(withEventsGallery);

