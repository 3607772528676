import { useEffect, useRef } from 'react';
import { addEventListenerFn, EventListenerObject } from 'helpers/pan-responder';

export type EventsMap = (HTMLElementEventMap & WindowEventMap);
type ListenerFn<K extends keyof EventsMap> = (ev: EventsMap[K]) => any;

export const useGlobalEventListener = <K extends keyof EventsMap>(
  node: HTMLElement | Window,
  type: K,
  listener: ListenerFn<K>,
  options?: boolean | AddEventListenerOptions
) => {
  const listenerObjRef = useRef<EventListenerObject>(null);
  const listenerRef = useRef<ListenerFn<K>>(null);

  useEffect(() => {
    listenerRef.current = listener;
  }, [ listener ]);

  const deps: any[] = [ node, type ];

  if (typeof options === 'object') {
    deps.push(options.capture, options.once, options.passive);
  }
  else deps.push(options);

  useEffect(() => {
    listenerObjRef.current = addEventListenerFn(
      node,
      type,
      (...args) => listenerRef.current(...args),
      options
    );

    return listenerObjRef.current.remove;

  }, deps);
};
