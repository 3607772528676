import styled from '@emotion/styled';
import { FavouritesIconMode } from 'components/favorites/FavoritesToggler';

export const BookmarkIconWrapper = styled.div`
  cursor: pointer;
  width: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.10)) drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
  }
`;

export const DefaultComponent = styled.div``;

export const Wrapper = styled.div<{ size?: 'medium' | 'large'}>`
  ${({ theme, size }) => `
    ${size === 'large' ? `
      min-width: 40px;
      height: 40px;
    ` : `
      min-width: 32px;
      height: 32px;
    `}

    border-radius: ${theme.borderRadius.round};
    background-color: ${theme.colors.neutrals.white};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      filter: none;
    }
  `}
`;

export const IconWrapper = styled.span<{ mode?: FavouritesIconMode; isFavorite?: boolean; }>`
  ${({ theme, mode = FavouritesIconMode.Default, isFavorite }) => mode === FavouritesIconMode.White ? `
    svg > path {
      stroke: ${theme.colors.neutrals.white};
      ${isFavorite ? `fill: ${theme.colors.neutrals.white};` : ''}
    }
  ` : ''}
`;
