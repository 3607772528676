import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';


export const WidthLimiter = styled.div<{ noBG?: boolean; }>`
  justify-content: center;
  display: flex;
  transition: background-color .2s ease;
  background: ${({ theme }) => theme.colors.neutrals.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  ${({ noBG }) => noBG ? `
    background: none;
    border-bottom: none;
  ` : null};
  > div {
    flex-grow: 1;
  }
`;

export const LogoLinkWrapper = styled.div`
  a {
    text-decoration: none;
    display: flex;
  }
`;

export const HeaderAlignment = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '[data-auto="back-link"]': {
    display: 'flex',
    alignItems: 'center',
    svg: {
      transform: 'scaleX(-1)',
    },
  },
}));

export const Wrapper = styled.div<{ disableRightPadding?: boolean, small?: boolean }>`
  height: ${({ small }) => small ? '48px' : '56px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  ${({ disableRightPadding }) => disableRightPadding ? 'padding-right: 0;' : ''}
`;

export const GrowSpan = styled.div`
  flex: 1;
`;

export const LogoWrapper = styled.div<{ alignStart?: boolean }>`
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    display: flex;
  `}
  ${({ alignStart }) => alignStart ? `align-self: flex-start;` : ''}

  > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  a {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      justify-content: center;
      flex-grow: 1;
    `}
  }
`;

export const SmallLogoWrapper = styled.div`
  height: 30px;

  svg {
    height: 30px;
  }
`;

export const ModesWrapper = styled.div<{ isMiddleScreen: boolean }>`
  margin-right: ${({ theme, isMiddleScreen }) => theme.spacing(isMiddleScreen ? 3 : 6)};
  display: flex;
  height: 100%;
  > div:not(:last-of-type), > a:not(:last-of-type) {
    margin-left: ${({ theme, isMiddleScreen }) => theme.spacing(isMiddleScreen ? 2 : 4)};
  }
  a {
    text-decoration: none;
  }
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    &:first-of-type {
      flex-grow: 1;
      margin: 0;
      padding: 0 24px;
    }
    &:not(:first-of-type) {
      margin-${theme.isRTL ? 'right' : 'left'}: 28px;
      margin-${theme.isRTL ? 'left' : 'right'}: 0;
      > a:not(:last-of-type) {
        margin-${theme.isRTL ? 'left' : 'right'}: 28px;
      }
    }
  `}
`;

export const HeaderLinkItem = styled(SmallText)<{ isActive: boolean; isDesktop?: boolean; noSvgMargin?: boolean }>`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  position: relative;
  white-space: nowrap;
  height: 100%;
  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({ theme, isActive }) => isActive ? theme.colors.neutrals.grey1 : 'rgba(255, 255, 255, 0)'};
  }
  transition: all .2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: ${({ theme, noSvgMargin }) => noSvgMargin ? '0' : theme.spacing(1)};
    ${({ isDesktop, theme }) => isDesktop ? '' : `
      > svg > path {
        fill: ${theme.colors.neutrals.grey1}
      }
    `}
  }
  a.tab-link {
    display: flex;
    color: ${({ theme }) => theme.colors.neutrals.grey1};
  }

  ${({ isDesktop, theme }) => isDesktop && `
    :hover {
      ::after {
      background-color: ${theme.colors.neutrals.grey6};
    }
  `}

  ${({ theme, isActive }) => isActive && `
    &&::after {
      background-color: ${theme.colors.neutrals.grey1};
    }
  `}
`;

export const LinksWrapper = styled.div<{ marginAuto: boolean; isMiddleScreen: boolean }>`
  margin-right: ${({ theme, marginAuto, isMiddleScreen }) => marginAuto ? 'auto' : (theme.spacing(isMiddleScreen ? 2 : 5))};
  display: flex;
  height: 100%;
  a {
    text-decoration: none;
  }
`;

export const MoreActionsButton = styled.div<{ isWhiteText?: boolean; isMoreColorTile?: boolean; }>`
  cursor: pointer;
  height: 24px;
  width: 24px;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-${theme.isRTL ? 'right' : 'left'}: auto;
  `}
  ${({ theme }) => theme.media.showAt({ at: 2 })`
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.isRTL ? '0' : 'auto'};
  `}

  ${({ theme, isMoreColorTile }) => `
    svg {
    path {
      fill: ${isMoreColorTile ? theme.colors.brand.main : theme.colors.neutrals.grey1};
    }
  }
  `}

  ${({ isWhiteText, theme }) => isWhiteText ? `
    color: ${theme.colors.neutrals.white};
    svg {
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  ` : null}

`;

export const AddListingButton = styled(SmallText)<{ isWhiteText?: boolean, isDesktop: boolean; withUploadButtonAnimation: boolean; }>`
  cursor: pointer;
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: auto;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  border: solid 1px ${({ theme, isWhiteText }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
  color: ${({ theme, isWhiteText }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
  padding: 4px 10px 4px 16px;
  position: ${({ withUploadButtonAnimation }) => withUploadButtonAnimation ? 'relative' : 'static'};
  svg {
    margin: 2px 2px 2px 4px;
    path {
      fill: ${({ theme, isWhiteText }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
    }
  }
  > a {
    display: flex;
    outline: none;
    color: ${({ theme, isWhiteText }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
    text-decoration: none;
  }

  ${({ isDesktop, theme, isWhiteText }) => isDesktop && !isWhiteText && `
    :hover {
      border: solid 1px ${theme.colors.brand.main};
      > a {
        color: ${theme.colors.brand.main};
      }
      svg {
        path {
          fill: ${theme.colors.brand.main};
        }
      }
    }
  `}

  ${({ isDesktop, theme, isWhiteText }) => isDesktop && isWhiteText && `
    :hover {
      border: solid 1px ${theme.colors.neutrals.grey9};
      > a {
        color: ${theme.colors.neutrals.grey9};
      }
      svg {
        path {
          fill: ${theme.colors.neutrals.grey9};
        }
      }
    }
  `}
  ${({ withUploadButtonAnimation }) => withUploadButtonAnimation && `
    border-color: transparent!important;
  `}
  ${({ theme, withUploadButtonAnimation, isWhiteText }) => withUploadButtonAnimation && `
    :before {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border: solid 1px ${isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
      transform: translateX(-50%) translateY(-50%);
      border-radius: ${theme.borderRadius.smallestSideRound};
      animation: easeInOut 1.5s linear;
    }
    @keyframes easeInOut {
      0% {
        width: 100%;
        height: 100%;
      }
      16.66% {
        width: 107%;
        height: 115%;
      }
      27.77% {
        width: 107%;
        height: 115%;
      }
      44.43% {
        width: 100%;
        height: 100%;
      }
      55.54% {
        width: 100%;
        height: 100%;
      }
      72.2% {
        width: 107%;
        height: 115%;
      }
      83.31% {
        width: 107%;
        height: 115%;
      }
      100% {
        width: 100%;
        height: 100%;
      }
    }
  `}
`;

export const AuthButton = styled(SmallText)<{ isWhiteText?: boolean, isDesktop: boolean, isMiddleScreen: boolean }>`
  cursor: pointer;
  ${({ theme, isMiddleScreen }) => `
    margin: 0 ${theme.spacing(isMiddleScreen ? 2 : 4)};
  `}
  display: flex;
  align-items: center;
  color: ${({ isWhiteText, theme }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
  ${({ theme, isMiddleScreen }) => !isMiddleScreen && `
    margin-right: ${theme.spacing(5)};
  `};
  svg {
    margin-left: ${({ theme, isMiddleScreen }) => isMiddleScreen ? '0' : theme.spacing(0.5)};
    path {
      fill: ${({ isWhiteText, theme }) => isWhiteText ? theme.colors.neutrals.white : theme.colors.neutrals.grey1};
    }
  }
  ${({ theme, isDesktop, isWhiteText }) => isDesktop && !isWhiteText && `
    :hover {
      color: ${theme.colors.brand.main};
      svg {
        path {
          fill: ${theme.colors.brand.main};
        }
      }
    }
  `}

  ${({ isDesktop, theme, isWhiteText }) => isDesktop && isWhiteText && `
    :hover {
      color: ${theme.colors.neutrals.grey9};
      svg {
        path {
          fill: ${theme.colors.neutrals.grey9};
        }
      }
    }
  `}
`;

export const UserActionsWrapper = styled.div<{ isMiddleScreen: boolean }>`
  ${({ theme, isMiddleScreen }) => `
    margin: 0 ${theme.spacing(isMiddleScreen ? 2 : 2.5)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin: unset;
  `};
  position: relative;
`;

export const Avatar = styled(SmallText)<{ imageURL: string,  isWhite?: boolean, userInitials: string, large?: boolean }>`
  cursor: pointer;
  height: ${({ large }) => large ? '45' : '32'}px;
  width: ${({ large }) => large ? '45' : '32'}px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  position: relative;
  ${({ imageURL }) => imageURL ? `background-image: url(${imageURL});` : ''};
  background-color: ${({ theme }) => theme.colors.neutrals.grey10};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  ${({ userInitials, imageURL, theme, isWhite }) => imageURL ? '' : `
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.12)};
    &:after {
      content: '${userInitials}';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color: ${isWhite ? theme.colors.neutrals.white : theme.colors.brand.main};
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const UserActionsMenu = styled.div`
  z-index: 1;
  min-width: 225px;
  position: absolute;
  top: calc(100% + 24px);
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: 0;
  background: ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const UserInfo = styled.div`
  padding: 16px 32px;
  > div:last-of-type {
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.neutrals.grey3};
  }
  border-bottom: 1px solid ${({ theme }) => colorToAlpha(theme.colors.neutrals.black, 0.12)};
`;

export const Separator = styled.div`
  margin: 12px 32px;
  background: ${({ theme }) => theme.colors.neutrals.grey8};
  height: 1px;
`;

export const Actions = styled.div`
  padding: 12px 0;
`;

export const Action = styled.div`
  cursor: pointer;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutrals.grey1};
  }
  svg {
    flex-shrink: 0;
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
`;

export const SimpleHeader = styled.div<{position?: 'left' | 'right'}>`
  display: flex;
  flex-grow: 1;
  height: 48px;
  align-items: center;
  justify-content: center;
  position: relative;
  > div:first-of-type {
    position: absolute;
    top: 50%;
    ${({ position = 'right' }) => position}: 0;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scaleX(${({ theme }) => theme.isRTL ? '-' : ''}1);
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey1};
    }
  }
`;

export const SectionLinkWrapper = styled.div`
  display: flex;
  cursor: auto;
  ${({ theme }) => `
    padding: ${theme.spacing(5)} ${theme.spacing(7)};
  `}
`;

export const LinkGroupWrapper = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.spacing(2)};

    &:not(:last-of-type) {
      border-left: 1px solid ${theme.colors.neutrals.grey7};
    }
    &:first-of-type {
      padding-right: 0;
    }

    && a {
      color: ${theme.colors.neutrals.grey1};
      line-height: ${typographyLineHeightCfg.smallText}px;
      font-size: ${typographyFontSizeCfg.smallText}px;
      font-weight: 400;
      display: flex;
      align-items: center;
      height: 40px;

      &:not(:last-of-type) {
        margin-bottom: ${theme.spacing(0.5)};
      }

      svg {
        margin-left: ${theme.spacing(1)};
      }

      &:hover {
        color: ${theme.colors.brand.main};
      }
    }
  `}
`;

export const GroupHeader = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    margin: 0 ${theme.spacing(1)} ${theme.spacing(2)};
  `}
`;

export const GroupLink = styled.a`
  ${({ theme }) => `
    line-height: ${typographyLineHeightCfg.smallText}px;
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const InnerLink = styled(GroupLink.withComponent('div'))`
  ${({ theme }) => `
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(0.5)};
    }
  `}
`;

export const Label = styled(SmallText)`
  ${({ theme }) => `
    font-size: ${typographyFontSizeCfg.textLabel.small}px;
    line-height: ${typographyLineHeightCfg.textLabel.small}px;
    color: ${theme.colors.neutrals.grey1};
    background-color: ${theme.colors.label.yellow};
    border-radius: ${theme.borderRadius.tiny};
    padding: ${theme.spacing(0.25)} ${theme.spacing(0.5)};
    margin-right: ${theme.spacing(0.5)};
    min-height: auto;
    height: 18px;
  `}
`;


export const TooltipArrow = styled.div`
  ${({ theme }) => `
    position: absolute;
    z-index:-1;
    bottom: 100%;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      bottom: -6px;
      left: calc(50% - 12px);
      background: ${theme.colors.label.yellow};
      transform: rotate(45deg);
    }
  `}
`;

export const LabelTooltip = styled(SmallText)`
  ${({ theme }) => `
    display: flex;
    cursor: pointer;
    color: ${theme.colors.neutrals.grey1};
    background-color: ${theme.colors.label.yellow};
    border-radius: ${theme.borderRadius.tiny};
    padding: ${theme.spacing(1)} ${theme.spacing(1.5)};
    width: 208px;
    position: relative;
    top: 23px;
    right: -117px;

    svg {
      flex-basis: 20px;
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
`;

export const SeoColumnsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;

    & > div {
      margin: 0 ${theme.spacing(1)};
      width: 168px;
      box-sizing: content-box;
      &:first-of-type {
        padding-right: 0;
      }
    }
  `}
`;

export const SavedListingsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: -6px;
  width: 15px;
  height: 15px;
  background: #FF6658;
  color: ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.white};
  font-size: 10px;
  line-height: 12px;
`;
