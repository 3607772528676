import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';

export const SectionInformBlock = styled.div<{ ppa: number, priceTo: number }>`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 12px 16px;
  background-color: ${({ theme }) => colorToAlpha(theme.colors.brand.main, 0.05)};
  display: flex;
  margin: 24px 0;
  > div {
    white-space: normal;
    max-width: 190px;
    color: ${({ theme }) => theme.colors.brand.main};
    margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: 16px;
  }
  > svg path {
    fill: ${props => props.theme.colors.brand.main};
  }
  &:before {
    content: '';
    position: relative;
    bottom: 50px;
    height: 24px;
    right: ${({ ppa, priceTo }) => 100 - (Math.round(ppa / 1000) * 1000) / priceTo * 100}%;
    border: dashed 1px ${({ theme }) => theme.colors.brand.main};
  }
`;
