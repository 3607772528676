import { Route } from 'config/routes';

export const ROUTES_WITH_MAP = new Set([
  Route.UnitPage,
  Route.UnitPageCommercial,
  Route.ProjectPage,
  Route.ProjectPageCommercial,
  Route.AddressPage,
  Route.Sold,
  Route.LocalPage,
  Route.StreetPage,
  Route.Search,
  Route.SearchCommercial,
  Route.EmploymentAreaPage,
]);

export const ROUTES_WITH_SUBHEADER = new Set([
  Route.ProjectPage,
  Route.ProjectPageCommercial,
  Route.AddressPage,
  Route.Sold,
  Route.LocalPage,
  Route.StreetPage,
  Route.Search,
  Route.SearchCommercial,
  Route.EmploymentAreaPage,
]);
