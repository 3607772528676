import React, { useState, useEffect } from 'react';
import Modal from 'ds/components/modal';
import { Content, IconWrapper, Header, Description, ShareActionsWrapper } from './styled';
import { ShareModalOwnProps, ShareModalType } from '.';


export interface ShareModalProps extends ShareModalOwnProps {
  isOpen: boolean;
  setOpen: (isOpen: ShareModalType) => void;
}

export const ShareModal: React.FC<ShareModalProps> = (props) => {
  const { isOpen, setOpen, mainIcon, header, description, renderActions, getBaseLink } = props;
  const [ isOpenMounted, setIsOpenMounted ] = useState(false);

  useEffect(() => {
    setIsOpenMounted(isOpen);
  }, [ isOpen ]);

  if (!isOpenMounted) return null;

  const onClose = () => {
    setOpen(null);
  };

  return (
    <Modal
      isOpen={isOpenMounted}
      onClose={onClose}
      mobileModalPositionBottom
    >
      <Content>
        {mainIcon ? <IconWrapper>{mainIcon}</IconWrapper> : null}
        <Header weight="bold">{header}</Header>
        {description ? <Description>{description}</Description> : null}
        <ShareActionsWrapper>
          {renderActions(getBaseLink())}
        </ShareActionsWrapper>
      </Content>
    </Modal>
  );
};
