import { connect } from 'react-redux';
import Navigation from './Navigation';
import { State, RootAction } from 'store/state';
import { Dispatch } from 'redux';
import { setAuthModal, logout, setIsMobileMenuHidden, setSidebarOpen } from 'store/state/app/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import {
  routeSelector,
  appModeSelector,
  prevRouteSelector,
  marketplaceSelector,
} from 'store/state/selectors/router';
import { isVisitorUserSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { navigateTo } from 'store/state/router/actions';
import { DecodedRouteParams, Route } from 'config/routes';
import { AuthModalSource } from 'store/state/app';
import {
  isUserMobileProfileOpenSelector,
  authModalStatusSelector,
  isMobileMenuHiddenSelector, isSidebarOpenSelector,
} from 'store/state/app/selectors';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { isMobileLayersToggleOpenSelector } from 'store/state/insightsContext/selectors';
import { hasMobileAdditionalBannersSelector } from 'store/state/domainData/selectors/bannersByDocId';


const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
  prevRoute: prevRouteSelector(state),
  user: userProfileSelector(state),
  mode: appModeSelector(state),
  marketplace: marketplaceSelector(state),
  isVisitor: isVisitorUserSelector(state),
  isUserMobileProfileOpen: isUserMobileProfileOpenSelector(state),
  authModalType: authModalStatusSelector(state),
  isMobileMenuHidden: isMobileMenuHiddenSelector(state),
  hasMobileAdditionalBanners: hasMobileAdditionalBannersSelector(state),
  isMobileLayersToggleOpen: isMobileLayersToggleOpenSelector(state),
  isMenuOpen: isSidebarOpenSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onOpenAuthModal: () => dispatch(setAuthModal({ type: AuthenticationModalType.SignUp }, { isUserInitiated: true, source: AuthModalSource.SignUpButton })),
  onLogout: () => dispatch(logout()),
  navigateTo: (name: Route, params?: DecodedRouteParams, opts?: NavigationOptions) =>
    dispatch(navigateTo(name, params, opts)),
  setIsMobileMenuHidden: (isHidden: boolean) => dispatch(setIsMobileMenuHidden(isHidden)),
  setIsMenuOpen: (isOpen: boolean) => dispatch(setSidebarOpen(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
