import React from 'react';
import styled from '@emotion/styled';
import { ControlButton, Mode } from './ControlButton';

export const ControlButtonGroupWrapper = styled.div`
  ${({ theme }) => `
    && > * {
      min-width: 70px;
      border-radius: 0;
      margin-left: -1px;
      z-index: 0;
      position: relative;
      &:first-of-type {
        border-radius: 0 ${theme.borderRadius.small} ${theme.borderRadius.small} 0;
      }
      &:last-of-type {
        border-radius: ${theme.borderRadius.small} 0 0 ${theme.borderRadius.small};
      }
      &.selected, &.priority {
        z-index: 1;
      }
    }
  `}
`;

interface GroupOptionParams {
  value: any;
  label: string;
  onClick?: () => void;
}

interface ControlButtonGroupParams {
  options: GroupOptionParams[];
  currentValue?: any;
  selectedMode?: Mode;
}

export const ControlButtonGroup: React.FC<ControlButtonGroupParams> = ({
  options,
  currentValue,
  selectedMode = 'selected',
}) => (
  <ControlButtonGroupWrapper>
    {options.map(({ value, label, onClick }, i) => {
      const mode = currentValue === value ? selectedMode : 'default';
      return (
        <ControlButton
          key={i}
          onClick={onClick}
          className={mode}
          mode={mode}
        >
          <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
        </ControlButton>
      );
    })}
  </ControlButtonGroupWrapper>
);
