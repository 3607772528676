import { Fetcher, IEnhancer } from '.';

export class DisableCacheFetchEnhancer implements IEnhancer {
  private disableCache = false;

  constructor() {
    const { searchParams } = new URL(window.location.href);
    this.disableCache = Boolean(searchParams.get('devDisableCache'));
  }

  public enhance = (url: string, init: RequestInit, innerFetch: Fetcher) => {
    if (this.disableCache) {

      return innerFetch(url, {
        ...init,
        headers: {
          ...init.headers,
          'X-Disable-Cache': 'true',
        },
      });
    }

    return innerFetch(url, init);
  }
}
