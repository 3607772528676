import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useField } from 'react-final-form-hooks';

import { RootAction } from 'store/state';
import { ITranslate, useLocale } from 'locale';
import LocationIcon from 'assets/svg/location-gallery.svg';
import ReportIcon from 'assets/svg/interface-16-report.svg';
import WarningIcon from 'assets/svg/warning.svg';
import { CompletionType, IAutocompleteAddress } from 'utils/entities';
import { isNotEmpty, isPositiveNumber } from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import RadioGroup from 'ds/components/input/RadioGroup';
import { StaticMap } from 'components/static-map/StaticMap';
import { TextField } from 'ds/components/input/TextField';
import { setReportUploadBulletinPopupOpen } from 'store/state/app/actions';
import { SpacingBlock } from 'components/spacing-block';
import { UploadBulletinStepProps } from '../';
import { InputWrapper, Section, SectionHeading, StepHeading } from '../../styled';
import {
  FindMistake,
  Footer,
  MapContainer,
  FieldsWrapper,
  InputParcelWrapper,
  AutocompleteWrapper,
  ResolutionPrefTip,
  ResolutionPrefTipLabel,
  ResolutionPrefTitle,
  ResolutionPrefOptions,
  ResolutionPrefWrapper,
} from './styled';
import { genAddressResolution } from 'screens/UploadBulletinPage/steps/AddressStep/helpers';
import { AddressAutocompleteSource } from 'components/autocomplete/types';


interface AddressStepProps extends UploadBulletinStepProps {
  openReportPopup: () => void;
  withResolutionPref: boolean;
}

const MAX_RESULTS = 5;
const COMMON_MAX_VALUE = 999999;

const AddressAutocompleteWithValidationMessage = withValidationMessage(AddressAutocompleteInput);
const WithErrorTextField = withValidationMessage(TextField);

const validateAddressField = (t: ITranslate) => (val: IAutocompleteAddress) =>
  isNotEmpty(val && val.docId) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');
const maxValueValidate = (t: ITranslate, maxValue: number) => (val: string) =>
  isNotEmpty(val) && isPositiveNumber(+val) && (+val > maxValue) ? t('uploadBulletinPage.maxValueFieldValidationMessage', { forMoney: false }) : undefined;

const AddressStep: React.FC<AddressStepProps> = ({ formInstance, openReportPopup, withResolutionPref, hideErrorOnFocus }) => {
  const { t } = useLocale();
  const [ searchStr, setSearchStr ] = useState<string>('');

  const addressField = useField('addressDetails', formInstance, validateAddressField(t));
  const blockField = useField('block', formInstance, maxValueValidate(t, COMMON_MAX_VALUE));
  const parcelField = useField('parcel', formInstance, maxValueValidate(t, COMMON_MAX_VALUE));
  const resolutionPreferences = useField('resolutionPreferences', formInstance);
  const error = addressField.meta.error && addressField.meta.touched;

  const handleChange = (selectedAddress: IAutocompleteAddress) => {
    if (selectedAddress) {
      addressField.input.onChange(selectedAddress);
    }
  };

  const location = addressField.input.value && addressField.input.value.location;

  useEffect(() => {
    if (addressField.input.value) {
      setSearchStr(addressField.input.value.name);
    }
  }, [ addressField.input.value.name ]);

  return (
    <>
      <StepHeading>{t('uploadBulletinPage.addressStep.heading')}</StepHeading>
      <Section noBorder>
        <InputWrapper maxWidth={380}>
          <AutocompleteWrapper error={error}>
            <AddressAutocompleteWithValidationMessage
              showType
              optionsCount={MAX_RESULTS}
              completionTypes={[ CompletionType.Address ]}
              searchStr={searchStr}
              onSearchStrChange={setSearchStr}
              placeholder={t('uploadBulletinPage.addressStep.placeholder')}
              inputIcon={<LocationIcon width={20} height={20} />}
              onChange={handleChange}
              initialValue={null}
              {...addressField.meta}
              source={AddressAutocompleteSource.NewBulletin}
              suggestionMessage={t('uploadBulletinPage.addressStep.suggestionMessage')}
            />
          </AutocompleteWrapper>
        </InputWrapper>
      </Section>
      {location ? (
        <>
          <SpacingBlock mTop={1}>
            <SpacingBlock mBottom={2}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.addressStep.location')}
              </SectionHeading>
            </SpacingBlock>
            <MapContainer>
              <StaticMap lng={location[0]} lat={location[1]} />
            </MapContainer>
            <Footer>
              <FindMistake onClick={openReportPopup}><ReportIcon /> {t('uploadBulletinPage.addressStep.locationReport')}</FindMistake>
            </Footer>
          </SpacingBlock>
          {withResolutionPref ? (
            <ResolutionPrefWrapper>
              <ResolutionPrefTitle>
                {t('uploadBulletinPage.addressStep.resolutionPreferences.title')}
              </ResolutionPrefTitle>
              <ResolutionPrefOptions>
                <RadioGroup
                  {...resolutionPreferences.input}
                  {...resolutionPreferences.meta}
                  options={genAddressResolution((t))}
                />
              </ResolutionPrefOptions>
              <ResolutionPrefTip>
                <div><WarningIcon /></div>
                <ResolutionPrefTipLabel>
                  {t('uploadBulletinPage.addressStep.resolutionPreferences.tip')}
                </ResolutionPrefTipLabel>
              </ResolutionPrefTip>
            </ResolutionPrefWrapper>
          ) : null}
          <Section>
            <SectionHeading weight="bold">
              {t('uploadBulletinPage.addressStep.blockAndParcelMessage')}
            </SectionHeading>
            <SectionHeading>
              {t('uploadBulletinPage.addressStep.blockAndParcelSubMessage')}
            </SectionHeading>
            <SpacingBlock mTop={2}>
              <FieldsWrapper>
                <InputParcelWrapper>
                  <InputWrapper maxWidth={288} error={blockField.meta.touched && blockField.meta.error}>
                    <WithErrorTextField
                      type="number"
                      min="0"
                      {...blockField.input}
                      {...blockField.meta}
                      onFocus={() => hideErrorOnFocus(true)}
                      onBlur={() => hideErrorOnFocus(false)}
                      placeholder={t('uploadBulletinPage.addressStep.blockInputPlaceholder')}
                    />
                  </InputWrapper>
                </InputParcelWrapper>
                <InputParcelWrapper>
                  <InputWrapper maxWidth={288} error={parcelField.meta.touched && parcelField.meta.error}>
                    <WithErrorTextField
                      type="number"
                      min="0"
                      {...parcelField.input}
                      {...parcelField.meta}
                      onFocus={() => hideErrorOnFocus(true)}
                      onBlur={() => hideErrorOnFocus(false)}
                      placeholder={t('uploadBulletinPage.addressStep.parcelInputPlaceholder')}
                    />
                  </InputWrapper>
                </InputParcelWrapper>
              </FieldsWrapper>
            </SpacingBlock>
          </Section>
        </>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  openReportPopup: () => dispatch(setReportUploadBulletinPopupOpen(true)),
});

export default connect(null, mapDispatchToProps)(AddressStep);
