import styled from '@emotion/styled';
const GRADIENT_WIDTH = 81;

export const ScrollableContentWrapperRoot = styled.div`
  position: relative;
  display: flex;
  min-width: 0;
  padding: 0 24px;
  width: 100%;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    border-bottom: 1px solid ${theme.colors.neutrals.grey8};
  `}
`;

export const ScrollableContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
`;

export const ScrollableContent = styled.div `
  position: relative;
  top: 0;
  white-space: nowrap;
  box-sizing: content-box;
  display: flex;
`;

export const RightGradient = styled.div`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  top: 0;
  right: 0;
  width: ${GRADIENT_WIDTH}px;
  height: 40px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), ${props => props.theme.colors.neutrals.white});
  backface-visibility: hidden;
`;


export const LeftGhostItem = styled.div `
  left: 0;
  width: ${GRADIENT_WIDTH}px;
  height: 100%;
  position: absolute;
  visibility: hidden;
`;

export const RightGhostItem = styled.div `
  right: 0;
  top: 0;
  width: ${GRADIENT_WIDTH}px;
  height: 100%;
  position: absolute;
  visibility: hidden;
`;

export const LeftGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: ${GRADIENT_WIDTH}px;
  height: 40px;
  pointer-events: none;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), ${props => props.theme.colors.neutrals.white});
  backface-visibility: hidden;
`;
