import { all, put, select, takeEvery, call, takeLatest, delay } from 'redux-saga/effects';
import { LoadType, MutationType, UgcRatingReviewEntry } from '../../../apiService/types';
import { queryData } from '../../../apiService';
import { RootAction } from 'store/state';
import {
  addressDocIdSelector,
  isUserSelector,
} from 'store/state/domainData/selectors';
import {
  SET_ACTIVE_REVIEW_RESULTS_PAGE,
  SET_EMOTION, ASK_SUBMIT_FEEDBACK,
  SET_RATING_FROM_PREVIEW,
} from 'store/state/UGC/types';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { resetDomainData } from 'store/state/domainData/actions';
import { routeSelector } from 'store/state/selectors/router';
import { navigateTo } from 'store/state/router/actions';
import { mutate } from 'store/state/mutationsResponse/actions';
import { setEmotion, askSubmitFeedback, goToNextStep, initializeRatings, resetReviewWizard } from 'store/state/UGC/actions';
import { LOGOUT } from 'store/state/app/types';
import { DecodedRouteParams, State as RouteState, Route } from 'config/routes';
import {
  feedbackModalUGCIdSelector,
  hasNextStepSelector,
  userContentSelector,
} from 'store/state/UGC/selectors';
import { FeedbackPopupType as FeedbackType  } from 'components/common-feedback-popup-view/utils';
import { Topic } from 'components/user-generated-content/utils/initialUGCConfig';
import { SET_DOMAIN_DATA, RESET } from 'store/state/domainData/types';
import { InputUGCEntry } from 'store/sagas/apiService/types';
import { SetRatingPayload } from 'store/state/UGC/payloads';
import { TRANSITION_START } from 'store/state/router/types';


function* submitFeedbackWorker({ payload }: ReturnType<typeof askSubmitFeedback>) {
  const { message, feedback, email } = payload;
  const ugcId = yield select(feedbackModalUGCIdSelector);
  const variables = {
    address: '',
    ugcId,
    message,
    feedback,
    email,
    feedbackType: FeedbackType.UGC,
  };
  yield put(mutate({
    mutationType: MutationType.FeedbackUGC,
    meta: { variables },
  }));
}

function* queryUserContent(docId: string) {
  const isUser: boolean = yield select(isUserSelector);
  if (isUser) {
    yield call(queryData, {
      loadType: LoadType.UserContent,
      meta: { variables: { docId } },
    });
  }
}

function* queryTextReviews(docId: string) {
  yield call(queryData, {
    loadType: LoadType.UserContentTextReviews,
    meta: { variables: { docId } },
  });
}

function* quertRatingsReviews(docId: string) {
  yield call(queryData, {
    loadType: LoadType.UserContentRatingReviews,
    meta: { variables: { docId } },
  });
}

function* reloadUserContent(docId: string) {
  yield put(resetDomainData({ loadType: LoadType.UserContent }));
  yield call(queryUserContent, docId);
}

function* reloadTextReviews(docId: string) {
  yield put(resetDomainData({ loadType: LoadType.UserContentTextReviews }));
  yield call(queryTextReviews, docId);
}

function* reloadRatingsReviews(docId: string) {
  yield put(resetDomainData({ loadType: LoadType.UserContentRatingReviews }));
  yield call(quertRatingsReviews, docId);
}

function* setActiveReviewResultPageWorker() {
  const route: RouteState = yield select(routeSelector);
  const params: DecodedRouteParams = {
    ...route.params,
    isActiveReviewResultPage: 1,
  };
  const shouldReplace = Boolean(route.params.isActiveReviewResultPage);
  yield put(navigateTo(route.name, params, { replace: shouldReplace }));
}

function* logoutWorker() {
  yield put(resetDomainData({ loadType: LoadType.UserContent }));
}

export function* queryUGCData(docId: string) {
  yield all([
    call(queryUserContent, docId),
    call(queryTextReviews, docId),
    call(quertRatingsReviews, docId),
  ]);
}

function* reloadUGCData() {
  const docId = yield select(addressDocIdSelector);
  yield all([
    call(reloadUserContent, docId),
    call(reloadTextReviews, docId),
    call(reloadRatingsReviews, docId),
  ]);
}

function* setEmotionWorker({ payload: variables }: ReturnType<typeof setEmotion>) {
  yield put(mutate({
    mutationType: MutationType.AddUGCEmotion,
    meta: { variables },
  }));
}

function* setRatingWorker() {
  const hasNextStep = yield select(hasNextStepSelector);
  if (!hasNextStep) return;
  // It is requirement to display next step after timeout
  yield delay(1000);
  yield put(goToNextStep());
}

const addUGCResponsePattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.AddUGC
);

const updateUserGeneratedContentPattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.UserContent
  || action.type === RESET && action.payload.loadType === LoadType.UserContent
);

function* onUpdateUserGeneratedContentWorker() {
  const userGeneratedContent: InputUGCEntry[] = yield select(userContentSelector);
  const userFilledRatings = userGeneratedContent && userGeneratedContent.filter(el => el.topic !== Topic.TextReview) || [];
  const payload: SetRatingPayload[] = (userFilledRatings as UgcRatingReviewEntry[]).map(el => ({ topic: el.topic, value: el.numericReview }));
  yield put(initializeRatings(payload));
}

const exitedAddressPagePattern = (action: RootAction) => {
  return action.type === TRANSITION_START
    && action.payload.previousRoute
    && action.payload.previousRoute.name === Route.AddressPage
    && action.payload.route.name !== Route.AddressPage;
};

function* resetReviewWizardWorker() {
  yield put(resetReviewWizard());
}

export function* ugcAddressPageWatcher() {
  yield takeEvery(ASK_SUBMIT_FEEDBACK, submitFeedbackWorker);
  yield takeEvery(LOGOUT, logoutWorker);
  yield takeEvery(addUGCResponsePattern, reloadUGCData);
  yield takeEvery(SET_ACTIVE_REVIEW_RESULTS_PAGE, setActiveReviewResultPageWorker);
  yield takeLatest(SET_EMOTION, setEmotionWorker);
  yield takeLatest(SET_RATING_FROM_PREVIEW, setRatingWorker);
  yield takeEvery(updateUserGeneratedContentPattern, onUpdateUserGeneratedContentWorker);
  yield takeEvery(exitedAddressPagePattern, resetReviewWizardWorker);
}
