import { connect } from 'react-redux';

import { State } from 'store/state';
import {
  setActiveLivabilityCarousel,
  setActiveLivabilityCarouselIdx,
  setActiveQuietStreets,
} from 'store/state/insightsContext/actions';
import {
  quietStreetsDataSelector,
  quietStreetsIdSelector,
  quietStreetsPreviewSelector,
} from 'store/state/selectors/insights/quietStreets';
import { makeCarouselDataSelectorByType } from 'store/state/selectors/utils';
import { InsightType } from 'utils/entities';
import { activeLivabilityCarouselIdxSelector } from 'store/state/insightsContext/selectors';

import QuietStreets from './quiet-streets-widget';
import {
  isCollapsedInsightsSelector,
  showInsightsCarouselSelector,
} from 'store/state/selectors/insights/insightSections';


export const LivabilityCarouselDataSelectorByType = makeCarouselDataSelectorByType(InsightType.Livability);

const mapStateToProps = (state: State) => ({
  data: quietStreetsDataSelector(state),
  id: quietStreetsIdSelector(state),
  preview: quietStreetsPreviewSelector(state),
  carouselData: LivabilityCarouselDataSelectorByType(state),
  carouselIdx: activeLivabilityCarouselIdxSelector(state),
  isCollapsed: isCollapsedInsightsSelector(state),
  showCarousel: showInsightsCarouselSelector(state),
});

const mapDispatchToProps = {
  onSeeAllClick: setActiveQuietStreets,
  onReadMoreCarouselClick: setActiveLivabilityCarousel,
  setActiveCarouselIdx: setActiveLivabilityCarouselIdx,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuietStreets);
