import { SpringConfig, UseSpringProps } from 'react-spring';
import { EventsMap } from 'hooks/useGlobalEventListener';

export interface GestureState {
  /** the time of event */
  time: number;
  /** true if pointer is down */
  down: boolean;
  /** the latest X screen coordinates of the recently - moved touch */
  moveX: number;
  /** the latest Y screen coordinates of the recently - moved touch */
  moveY: number;
  /** the screen X coordinates of the responder grant */
  x0: number;
  /** the screen Y coordinates of the responder grant */
  y0: number;
  /** accumulated X distance of the gesture since the touch started */
  dx: number;
  /** accumulated Y distance of the gesture since the touch started */
  dy: number;
  /** current X velocity of the gesture */
  vx: number;
  /** current Y velocity of the gesture */
  vy: number;
  __granted: boolean;
}

export const gestureInitialState: GestureState = Object.freeze({
  moveX: null,
  moveY: null,
  x0: null,
  y0: null,
  dx: null,
  dy: null,
  vx: null,
  vy: null,
  time: null,
  down: false,
  __granted: false,
});

type Event = MouseEvent | TouchEvent;

export interface PanResponderOptions {
  onStart: (state: GestureState, e: React.SyntheticEvent) => void;
  onMove: (state: GestureState, e: Event) => void;
  onEnd: (state: GestureState, e: Event) => void;
  shouldPreventDefault?: (state: GestureState, e: Event) => boolean;
  shouldTerminate?: (state: GestureState, e: Event) => boolean;
  shouldGrant?: (state: GestureState, e: Event) => boolean;
}

type XYValue = [ number, number ];

export function isTouchEvt(e: Event): e is TouchEvent {
  return 'touches' in e;
}

export const getXY = (e: Event): XYValue => {
  if (isTouchEvt(e)) {
    const touch = e.touches[0];
    return [ touch.clientX, touch.clientY ];
  }
  return [ e.clientX, e.clientY ];
};



export interface EventListenerObject {
  remove: () => void;
}


export function addEventListenerFn<K extends keyof EventsMap>(
  node: HTMLElement | Window | AbortSignal,
  type: K,
  listener: (this: HTMLElement, ev: EventsMap[ K ]) => any,
  options?: boolean | AddEventListenerOptions
): EventListenerObject {
  node.addEventListener(type, listener, options);
  return {
    remove: () => {
      node.removeEventListener(type, listener, options);
    },
  };
}

type Handlers = Pick<React.HTMLAttributes<HTMLElement>, 'onTouchStartCapture' | 'onMouseDownCapture'>;

export const createPanResponder = (opts: PanResponderOptions): Handlers => {

  let nonPassiveMoveListener: EventListenerObject = null;
  let moveListener: EventListenerObject = null;
  let endListener: EventListenerObject = null;
  let internalState: GestureState = null;

  const onEnd = (e: Event) => {
    moveListener.remove();
    endListener.remove();
    if (nonPassiveMoveListener) nonPassiveMoveListener.remove();
    internalState = { ...internalState, down: false };
    opts.onMove(internalState, e);
    opts.onEnd(gestureInitialState, e);
    internalState = gestureInitialState;
  };

  const onNonPassiveMove = (e: Event) => {
    if (opts.shouldGrant && !internalState.__granted) {
      internalState = {
        ...internalState,
        __granted: opts.shouldGrant(internalState, e),
      };
    }
    if (opts.shouldPreventDefault && opts.shouldPreventDefault(internalState, e)) {
      e.preventDefault();
    }

    if (opts.shouldTerminate && opts.shouldTerminate(internalState, e)) {
      internalState = {
        ...internalState,
        __granted: false,
      };
      opts.onMove(internalState, e);
      onEnd(e);
    }
  };

  const onMove = (e: Event) => {

    const [ moveX, moveY ] = getXY(e);

    const dx = moveX - internalState.x0;
    const dy = moveY - internalState.y0;
    const dt = e.timeStamp - internalState.time;

    internalState = {
      ...internalState,
      moveX,
      moveY,
      dx,
      dy,
      vx: dx / dt,
      vy: dy / dt,
    };

    opts.onMove(internalState, e);

  };

  const onStart = (e: React.TouchEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
    const [ x0, y0 ] = getXY(e.nativeEvent);
    const time = e.nativeEvent.timeStamp;
    // we need to be granted anytime MOUSE is down
    let grantedInitially = false;

    if (isTouchEvt(e.nativeEvent)) {
      // multiple touches - not a pan gesture
      if (e.nativeEvent.touches.length !== 1) return;
    }
    else {
      // not main mouse button - not a pan gesture
      if (e.nativeEvent.button !== 0) return;
      grantedInitially = true;
    }

    internalState = {
      ...internalState,
      down: true,
      __granted: grantedInitially,
      x0,
      y0,
      time,
    };
    opts.onStart(internalState, e);

    if (isTouchEvt(e.nativeEvent)) {
      if (opts.shouldPreventDefault || opts.shouldTerminate) {
        nonPassiveMoveListener = addEventListenerFn(window, 'touchmove', onNonPassiveMove, { passive: false });
      }

      moveListener = addEventListenerFn(document.documentElement, 'touchmove', onMove);
      endListener = addEventListenerFn(document.documentElement, 'touchend', onEnd);
    }
    else {
      moveListener = addEventListenerFn(document.documentElement, 'mousemove', onMove);
      endListener = addEventListenerFn(document.documentElement, 'mouseup', onEnd);
    }

  };

  return {
    onTouchStartCapture: onStart,
    onMouseDownCapture: onStart,
  };

};

const immediateSpringConfig: SpringConfig = { tension: 2000, friction: 72, mass: 1 };

/**
 * On iOS touchmove events always have integer coordinates for some reason.
 * Because of this and high pixel ratio, such movement looks choppy
 * @param springProps props passed to Spring component or useSpring hook
 * @param down whether pointer is down
 * @param gestureCoord coordinate toWatch
 * @returns spring props with the applied hack
 */
export function applyIOSImmediateHack<T extends {}>(
  springProps: T & UseSpringProps<T>,
  down: boolean,
  gestureCoord: number
): T & UseSpringProps<T> {

  if (down && Number.isInteger(gestureCoord) && springProps.immediate) {
    return {
      ...springProps,
      immediate: false,
      config: immediateSpringConfig,
    };
  }

  return springProps;
}

export const calcAngle = (x: number, y: number) => Math.atan(y / x);

const VELOCITY_ANGLE_THRESHOLD = 0.7;
const DX_TERMINATE_THRESHOLD = 33;
const DELTA_ANGLE_THRESHOLD = 0.4;

export function isHorizontal(state: GestureState) {
  const vAngle = calcAngle(state.vx, state.vy);
  const dAngle = calcAngle(state.dx, state.dy);
  return Math.abs(vAngle) < VELOCITY_ANGLE_THRESHOLD
    || Math.abs(dAngle) < DELTA_ANGLE_THRESHOLD
    || Math.abs(state.dx) > DX_TERMINATE_THRESHOLD;
}
