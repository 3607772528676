import React from 'react';
import { useLocale } from 'locale';
import { FormApi } from 'final-form';
import { TextField } from 'ds/components/input/TextField';
import RadioGroup from 'ds/components/input/RadioGroup';
import { useField } from 'react-final-form-hooks';
import { FeedbackFormFields, IFeedbackOption } from './utils';
import {
  FeedbackFormWrapper,
  CommentTextWrapper,
  EmailWrapper,
} from './styled';
import { isEmailValid } from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';
import replaceEmoji from 'utils/emojis';

interface FeedbackFormProps {
  options: IFeedbackOption[];
  formInstance: FormApi<FeedbackFormFields>;
  inputWrapperRef: (el: HTMLDivElement) => void;
}

const WithValidationTextField = withValidationMessage(TextField);
const RadiogroupWithValidation = withValidationMessage(RadioGroup);

export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  options,
  formInstance,
  inputWrapperRef,
}) => {
  const { t } = useLocale();
  const radioButtonOptionField = useField('radioButtonOption', formInstance);
  const commentMessage = useField('commentMessage', formInstance);
  const userEmail = useField('userEmail', formInstance);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    commentMessage.input.onChange(replaceEmoji(e.currentTarget.value));
  };

  return (
    <FeedbackFormWrapper>
      <RadiogroupWithValidation
        {...radioButtonOptionField.input}
        {...radioButtonOptionField.meta}
        options={options}
      />
      <CommentTextWrapper>
        <div ref={inputWrapperRef}>
          <WithValidationTextField
            multiline
            fullWidth
            {...commentMessage.input}
            {...commentMessage.meta}
            placeholder={t('feedback.popup.tellUsMore')}
            type="text"
            onChange={handleOnChange}
            rows={5}
          />
        </div>
      </CommentTextWrapper>
      <EmailWrapper>
        <WithValidationTextField
          {...userEmail.input}
          {...userEmail.meta}
          type="email"
          hasError={!isEmailValid}
          errorMessage={t('reportListing.popup.emailError')}
          fullWidth
          label={t('feedback.popup.emailLabel')}
          placeholder={t('feedback.popup.emailPlaceholder')}
        />
      </EmailWrapper>
    </FeedbackFormWrapper>
  );
};
