export const SAVE_USER_REVIEW = 'UGC/SAVE_USER_REVIEW';
export const SET_EMOTION = 'UGC/SET_EMOTION';
export const SET_ACTIVE_REVIEW_RESULTS_PAGE = 'UGC/SET_ACTIVE_REVIEW_RESULTS_PAGE';

// FEEDBACK MODAL
export const SET_FEEBACK_MODAL = 'UGC/SET_FEEBACK_MODAL';
export const ASK_SUBMIT_FEEDBACK = 'UGC/ASK_SUBMIT_FEEDBACK';

// REVIEW WIZARD
export const CLOSE_REVIEW_WIZARD = 'UGC/CLOSE_REVIEW_WIZARD';
export const OPEN_REVIEW_WIZARD = 'UGC/OPEN_REVIEW_WIZARD';
export const ASK_REVIEW_WIZARD_FOR_ADDRESS = 'UGC/ASK_REVIEW_WIZARD_FOR_ADDRESS';
export const NEXT_STEP = 'UGC/NEXT_STEP';
export const PREVIOUS_STEP = 'UGC/PREVIOUS_STEP';
export const SET_RATING = 'UGC/SET_RATING';
export const SET_RATING_FROM_PREVIEW = 'UGC/SET_RATING_FROM_PREVIEW';
export const INITIALIZE_RATINGS = 'UGC/INITIALIZE_RATINGS';
export const SET_USER_NAME_INPUT = 'UGC/SET_USER_NAME_INPUT';
export const SET_TEXT_REVIEW_INPUT = 'UGC/SET_TEXT_REVIEW_INPUT';
export const RESET_WIZARD = 'UGC/RESET_WIZARD';
