import styled from '@emotion/styled';
import { ResetButton } from './ResetButton';
import { SpacingScale } from 'ds/theme/spacing';
import { typographyFontSizeCfg } from '../typography/types';
import defaultTheme from 'ds/theme/default';
import { colorToAlpha } from 'helpers/insight';

type Size = 'medium' | 'large';
type Mode = 'primary' | 'primaryNegative' | 'secondary' | 'secondaryNegative';
type State = 'default' | 'hovered' | 'selected';

export interface CleanButtonProps {
  size?: Size;
  mode?: Mode;
  onlyIcon?: boolean; // used to make a round button with no additional margins on the icon
  iconPosition?: 'start' | 'end';
}

const sizeToHeight: Record<Size, SpacingScale> = {
  medium: 4,
  large: 5,
};

const sizeToSidePadding: Record<Size, SpacingScale> = {
  medium: 2,
  large: 2,
};

const sizeToFontSize = {
  medium: typographyFontSizeCfg.smallText,
  large: typographyFontSizeCfg.text,
};

const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.brand.main,
    'secondary': defaultTheme.colors.neutrals.grey3,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
  hovered: {
    'primary': defaultTheme.colors.brand.main,
    'secondary': defaultTheme.colors.neutrals.grey3,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
  selected: {
    'primary': defaultTheme.colors.brand.main,
    'secondary': defaultTheme.colors.neutrals.grey3,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
};

const onlyIconModeToIconColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.neutrals.grey1,
    'secondary': defaultTheme.colors.neutrals.grey1,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
  hovered: {
    'primary': defaultTheme.colors.brand.main,
    'secondary': defaultTheme.colors.neutrals.grey3,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
  selected: {
    'primary': defaultTheme.colors.brand.main,
    'secondary': defaultTheme.colors.neutrals.grey3,
    'primaryNegative': defaultTheme.colors.neutrals.white,
    'secondaryNegative': defaultTheme.colors.neutrals.white,
  },
};

const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    'primary': defaultTheme.colors.neutrals.white,
    'secondary': defaultTheme.colors.neutrals.white,
    'primaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.4),
    'secondaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.4),
  },
  hovered: {
    'primary': defaultTheme.colors.neutrals.grey9,
    'secondary': defaultTheme.colors.neutrals.grey9,
    'primaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.8),
    'secondaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.8),
  },
  selected: {
    'primary': defaultTheme.colors.neutrals.grey8,
    'secondary': defaultTheme.colors.neutrals.grey8,
    'primaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.4),
    'secondaryNegative': colorToAlpha(defaultTheme.colors.neutrals.black, 0.4),
  },
};

const modeToFontWeight: Record<Mode, number> = {
  'primary': 500,
  'primaryNegative': 500,
  'secondary': 400,
  'secondaryNegative': 400,
};

export const CleanButton = styled(ResetButton)<CleanButtonProps>`
  ${({ theme, mode = 'primary', size = 'medium', onlyIcon, iconPosition = 'start' }) => `
    font-size: ${sizeToFontSize[size]}px;
    font-weight: ${modeToFontWeight[mode]};
    height: ${theme.spacing(sizeToHeight[size])};
    padding: 0 ${onlyIcon ? 0 : theme.spacing(sizeToSidePadding[size])};
    border-radius: ${onlyIcon ? theme.borderRadius.round : theme.borderRadius.smallestSideRound};
    display: inline-flex;
    align-items: center;
    transition: all .2s ease;
    justify-content: center;
    cursor: pointer;
    width: ${onlyIcon ? theme.spacing(sizeToHeight[size]) : 'auto'};
    > svg {
      position: relative;
      ${size === 'large' ? `
        margin-${iconPosition === 'start'
          ? (theme.isRTL ? 'left' : 'right')
          : (theme.isRTL ? 'right' : 'left')}: ${onlyIcon ? 0 : theme.spacing(0.5)};
      ` : `
        ${iconPosition === 'start'
          ? (theme.isRTL ? 'right' : 'left')
          : (theme.isRTL ? 'left' : 'right')}: ${onlyIcon ? 0 : `-${theme.spacing(0.5)}`};
      `}
    }

    > svg path { fill: ${onlyIcon ? onlyIconModeToIconColor.default[mode] : modeToFontColor.default[mode]}; }
    color: ${modeToFontColor.default[mode]};
    background: ${modeToBgColor.default[mode]};
  `}
  ${({ theme, mode = 'primary', onlyIcon }) => theme.media.showAt({ isTouch: false, to: 5 }) `
    &:hover {
      > svg path { fill: ${onlyIcon ? onlyIconModeToIconColor.hovered[mode] : modeToFontColor.hovered[mode]}; }
      color: ${modeToFontColor.hovered[mode]};
      background: ${modeToBgColor.hovered[mode]};
    }
    &:active {
      > svg path { fill: ${onlyIcon ? onlyIconModeToIconColor.selected[mode] : modeToFontColor.selected[mode]}; }
      color: ${modeToFontColor.selected[mode]};
      background: ${modeToBgColor.selected[mode]};
    }
  `}
`;

