import styled from '@emotion/styled';
import { BaseFlexColumnWrapper } from '../../styled';

export const ContactsStepWrapper = styled(BaseFlexColumnWrapper)`
  div[data-auto="textfield-wrapper"] {
    width: 320px;

    ${({ theme }) => theme.media.showAt({ at: 1 })`
      width: 312px;
    `}
  }

  [data-auto="textfield-label-text"] {
    font-weight: bold;
  }
`;
