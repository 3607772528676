import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State, RootAction } from 'store/state';
import { PhoneValidationStatus, ICheckValidationCodeVariables, MutationType } from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import ValidatePhoneModal from './ValidatePhoneModal';
import {
  generateValidationCodeResponseSelector,
  validationCodeResponseSelector,
} from 'store/state/mutationsResponse/selectors';

interface ValidatePhoneOwnProps {
  phoneNumber: string;
  closeModal: () => void;
  goToNextStep: () => void;
}

interface ValidatePhoneProps extends ValidatePhoneOwnProps{
  resendCodeStatus: PhoneValidationStatus;
  validateCodeStatus: PhoneValidationStatus;
  isResendSendCodeLoading: boolean;
  isValidateCodeLoading: boolean;
  resendCode: (phone: string) => void;
  checkCode: (val: ICheckValidationCodeVariables) => void;
}

const ValidatePhone: React.FC<ValidatePhoneProps> = (props) => {
  const {
    phoneNumber,
    closeModal,
    resendCode,
    checkCode,
    isResendSendCodeLoading,
    isValidateCodeLoading,
    resendCodeStatus,
    validateCodeStatus,
    goToNextStep,
  } = props;
  const [ isCodeResending, setCodeResending ] = useState(false);
  const [ isCodeValidating, setCodeValidating ] = useState(false);
  const [ error, setError ] = useState<PhoneValidationStatus>(null);

  const onClose = () => {
    setError(null);
    setCodeResending(false);
    setCodeValidating(false);
    closeModal();
  };

  useEffect(() => {
    if (!isResendSendCodeLoading) {
      setCodeResending(false);
      if (resendCodeStatus === PhoneValidationStatus.Blocked) {
        setError(PhoneValidationStatus.Blocked);
      }
    }
  }, [ isResendSendCodeLoading, resendCodeStatus ]);

  useEffect(() => {
    if (!isValidateCodeLoading) {
      setCodeValidating(false);

      switch (validateCodeStatus) {
        case PhoneValidationStatus.Blocked:
        case PhoneValidationStatus.Failure:
          setError(validateCodeStatus);
          break;

        case PhoneValidationStatus.Success:
          onClose();
          goToNextStep();
          break;

        default:
      }
    }
  }, [ isValidateCodeLoading, validateCodeStatus ]);

  const onResendCode = () => {
    if (!isCodeResending && !isCodeValidating) {
      setCodeResending(true);
      resendCode(phoneNumber);
      setError(null);
    }
  };

  const onCheckCode = (val: ICheckValidationCodeVariables) => {
    if (!isCodeResending && !isCodeValidating) {
      setCodeValidating(true);
      checkCode(val);
      setError(null);
    }
  };

  return (
    <ValidatePhoneModal
      phoneNumber={phoneNumber}
      onClose={onClose}
      error={error}
      resendCode={onResendCode}
      submitCode={onCheckCode}
      isLoading={isCodeResending || isCodeValidating}
    />
  );
};


const mapStateToProps = (state: State, ownProps: ValidatePhoneOwnProps) => {
  const resendResponse = generateValidationCodeResponseSelector(state);
  const isResendSendCodeLoading = resendResponse && resendResponse.loading;
  const resendCodeStatus = !isResendSendCodeLoading && resendResponse && resendResponse.data
    && resendResponse.data.generateValidationCode && resendResponse.meta.variables.phoneNumber === ownProps.phoneNumber
    ? resendResponse.data.generateValidationCode
    : null;

  const checkCodeResponse = validationCodeResponseSelector(state);
  const isValidateCodeLoading = checkCodeResponse && checkCodeResponse.loading;
  const validateCodeStatus = !isValidateCodeLoading && checkCodeResponse && checkCodeResponse.data
    && checkCodeResponse.data.checkValidationCode && checkCodeResponse.meta.variables.phoneNumber === ownProps.phoneNumber
    ? checkCodeResponse.data.checkValidationCode : null ;

  return {
    resendCodeStatus,
    isResendSendCodeLoading,
    validateCodeStatus,
    isValidateCodeLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  resendCode: (phoneNumber: string) => dispatch(inlineMutate(MutationType.GenerateValidationCode, { phoneNumber })),
  checkCode: (data: ICheckValidationCodeVariables) => dispatch(inlineMutate(MutationType.CheckValidationCode, data )),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidatePhone);
