export interface HashSet<T> {
  has: (entry: T) => boolean;
  values: () => T[];
  add: (entry: T) => void;
  delete: (entry: T) => void;
  readonly size: number;
  clear: () => void;
  [Symbol.iterator](): IterableIterator<T>;
}

export const createHashSetFactory = <T>(hashFn: (entry: T) => string) => (entries: T[] = []): HashSet<T> => {
  let set = entries.reduce<Record<string, T>>((acc, val) => {
    acc[hashFn(val)] = val;
    return acc;
  }, {});

  return {
    *[Symbol.iterator]() {
      for (const e of Object.values(set)) yield e;
    },
    has: (e) => set.hasOwnProperty(hashFn(e)),
    values: () => Object.values(set),
    add: (e) => {
      set[hashFn(e)] = e;
    },
    delete: (e) => {
      delete set[hashFn(e)];
    },
    clear: () => {
      set = {};
    },
    get size() {
      return Object.keys(set).length;
    },
  };
};
