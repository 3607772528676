import React from 'react';
import { useLocale } from 'locale';

import { SliderRange } from 'ds/components/slider';
import {
  Section,
  SectionBody,
  PriceWrapper,
  SectionHeader,
} from 'components/filters/styled/common';

import FilterSection from 'components/filters/common/DropdownSection';
import { GenericFilterProps } from 'components/filters/types';

import { isFilterActiveByType } from 'components/filters/utils';
import { DealType, MarketplaceType } from 'utils/entities';
import PPMAverage from './ppm-average';
import RangeInputs from 'ds/components/input/RangeInputs';
import { marketplaceSelector } from 'store/state/selectors/router';
import { useSelector } from 'react-redux';


interface PricePerSquareMeterProps extends GenericFilterProps<'ppmRange'> {
  includeAverage?: boolean;
}

const PricePerSquareMeter: React.FunctionComponent<PricePerSquareMeterProps> = ({
  alignment,
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  config,
  dealType,
  type,
  isInline,
  includeAverage = true,
}) => {
  const { t, formatMoneyWithMetricPrefix, formatMoney } = useLocale();
  const { budgetByPerSquareMeter, filtersIcons } = config;

  const [ commitedFrom, commitedTo ] = commitedValue;
  const [ initialFrom, initialTo ] = initialValue;
  const [ currentFrom, currentTo ] = intermediateValue;

  const isActive = isFilterActiveByType.priceRange(commitedValue, initialValue);
  const valuesAreInitial = !isFilterActiveByType.priceRange(intermediateValue, initialValue);

  const formatLabel = (label: number) => {
    if (dealType === DealType.Buy) return formatMoneyWithMetricPrefix(label, 6, 1E6);
    return formatMoneyWithMetricPrefix(label);
  };

  const txtShortValue = t('filters.priceShortValue', {
    isActive,
    priceFrom: initialFrom === commitedFrom ? null : formatLabel(commitedFrom),
    priceTo: initialTo === commitedTo ? null : formatLabel(commitedTo),
  });

  const txtLongValue = t('filters.priceLongValue', {
    isMobile: false,
    isActive,
    priceFrom: formatMoney(currentFrom),
    priceTo: formatMoney(currentTo),
    isMaxedOut: initialTo === currentTo,
  });

  const slider = (
    <SliderRange
      {...budgetByPerSquareMeter[dealType].budgetSliderProps}
      values={intermediateValue}
      mode="ltr"
      onChange={value => setIntermediateValue(value)}
    />
  );

  const inputs = (
    <RangeInputs
      {...budgetByPerSquareMeter[dealType].budgetSliderProps}
      value={intermediateValue}
      onChange={value => setIntermediateValue(value)}
      formatter={(v) => formatMoney(v, false)}
    />
  );

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
        <SectionHeader isInline>
          {t('filters.ppmRange.secondaryTitle')}
        </SectionHeader >
        <SectionBody data-auto="filterCheckboxes">
          <PriceWrapper>
            {slider}
            {inputs}
          </PriceWrapper>
        </SectionBody>
        {includeAverage && dealType === DealType.Buy ? <PPMAverage priceTo={initialTo} /> : null}
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      title={txtLongValue}
      icon={filtersIcons[type]}
      onClear={resetValue}
      label={txtShortValue}
      onSubmit={commitValues}
      isActive={isActive}
    >
      {slider}
    </FilterSection>
  );
};

const PricePerSquareMeterSwitcher: React.FC<PricePerSquareMeterProps> = React.memo((props) => {
  const marketplace = useSelector(marketplaceSelector);
  const isResidential = marketplace === MarketplaceType.Residential;

  return (
    <PricePerSquareMeter {...props} includeAverage={isResidential} />
  );
});

export default PricePerSquareMeterSwitcher;
