import React, { ReactNode } from 'react';
import { RadioButtonContainer, RadioMark } from './styled';

interface RadioButtonProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  value: string;
  checked: boolean;
  children: ReactNode;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, value, children, checked, onChange }) => {
  return (
    <RadioButtonContainer data-auto="radio-button" inTest>
      <input type="radio" name={name} value={value} onChange={onChange} checked={checked} />
      <RadioMark className="radio-input-inner"/>
      {children}
    </RadioButtonContainer>
  );
};

export default RadioButton;
