import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';

export const ManageLinkWrapper = styled(SmallText)`
  ${({ theme }) => `
    display: flex;
    align-items: center;

    a {
      color: ${theme.colors.neutrals.grey1};
      text-decoration: none;
      display: flex;
    }

    svg {
      margin-left: ${theme.spacing(1)};
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    position: absolute;
    right: 24px;
    bottom: 28px;
  `}
`;
