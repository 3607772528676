import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';
import styled from '@emotion/styled';
import { Link } from 'components/link';
import { Text } from 'ds/components/typography';

export const TooltipText = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  white-space: nowrap;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(1.5)};
  `};
`;

export const TooltipLink = styled(Link)`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin-left: ${theme.spacing(3)};
  `};
  color: ${({ theme }) => theme.colors.brand.ocean};
  font-size: ${typographyFontSizeCfg.text}px;
  line-height: ${typographyLineHeightCfg.text}px;
`;

export const ToastContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 16px;
  width: 100%;
`;

export const ToastContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 343px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.colors.neutrals.grey1};
  color: ${({ theme }) => theme.colors.neutrals.white};
`;
