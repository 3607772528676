import { Config, SortField, SortDirection, SortValue, ITagBySortValue } from './types';
import { InsightType } from 'utils/entities';
import { sortValueToTagType, getBestSchoolTypeIL } from './utils';
import { nonNull } from 'helpers';
import { isNumber } from 'lodash';
import { isCommercialBulletin } from 'utils/marketplaceRoutes';


const bestForYou: SortValue = null;

const minutesToHours = (value: number) => Math.floor(value / 60);
const minutesToMinutesRemainder = (value: number) => value % 60;

export const config: Config = {
  basicOptions: [
    bestForYou,
    [ SortField.Price, SortDirection.Asc ],
    [ SortField.Date, SortDirection.Desc ],
    [ SortField.Size, SortDirection.Desc ],
  ],
  insightCategoryBySortField: {
    [SortField.BestSecular]: InsightType.Education,
    [SortField.BestReligious]: InsightType.Education,
    [SortField.Commute]: InsightType.Transportation,
    [SortField.Parks]: InsightType.Livability,
    [SortField.LightRail]: InsightType.Transportation,
    [SortField.QuietStreets]: InsightType.Livability,
    [SortField.FamilyFriendly]: InsightType.Livability,
    [SortField.DogParks]: InsightType.Livability,
  },
  advancedOptionsForPriorities: [
    [ SortField.BestSecular, SortDirection.Asc ],
    [ SortField.BestReligious, SortDirection.Asc ],
    [ SortField.Parks, SortDirection.Asc ],
    [ SortField.Commute, SortDirection.Asc ],
    [ SortField.LightRail, SortDirection.Asc ],
    [ SortField.QuietStreets, SortDirection.Asc ],
    [ SortField.FamilyFriendly, SortDirection.Asc ],
    [ SortField.DogParks, SortDirection.Asc ],
  ],
  defaultValue: [ bestForYou ],
  getTags: (poi, sortFields, commuteType) => {
    return sortFields.reduce<ITagBySortValue[]>((acc, field) => {
      if (!isCommercialBulletin(poi) && !poi.tags) {
        return acc;
      }
      const tag: ITagBySortValue = {
        value: null,
        type: sortValueToTagType[field],
        insightType: config.insightCategoryBySortField[field],
      };

      if (!isCommercialBulletin(poi)) {

        const { tags } = poi;
        switch (field) {
          case SortField.BestSecular:
          case SortField.BestReligious: {
            const bothTypesSelected = (
              sortFields.includes(SortField.BestSecular) && sortFields.includes(SortField.BestReligious)
            );
            const bestSchoolType = bothTypesSelected
              ? getBestSchoolTypeIL(tags.bestSecular, tags.bestReligious)
              : field;

            if (field !== bestSchoolType) {
              return acc;
            }

            tag.value = bestSchoolType === SortField.BestSecular ? tags.bestSecular : tags.bestReligious;
            if ('bestSchool' in poi && poi.bestSchool) {
              const { bestSchool } = poi;
              const { bestReligious, ...other } = bestSchool;
              tag.school = {
                ...other,
                schoolName: SortField.BestSecular ? bestSchool.schoolName : bestSchool.bestReligious,
              };
            }
            break;
          }
          case SortField.Commute: {
            tag.value = tags.commute;
            tag.commuteType = commuteType;
            if (poi.type === 'bulletin' && isNumber(poi.commuteTime)) {
              tag.hours = minutesToHours(poi.commuteTime);
              tag.mins = minutesToMinutesRemainder(poi.commuteTime);
            }
            break;
          }
          case SortField.Parks: {
            tag.value = tags.parkAccess;
            if ('parkName' in poi && 'parkWalkTime' in poi) {
              tag.parkName = poi.parkName;
              tag.parkWalkTime = poi.parkWalkTime;
            }
            break;
          }
          case SortField.QuietStreets: {
            tag.value = tags.quietStreet;
            break;
          }
          case SortField.FamilyFriendly: {
            tag.value = tags.familyFriendly;
            break;
          }
          case SortField.DogParks: {
            tag.value = tags.dogPark;
            break;
          }
          case SortField.LightRail: {
            tag.value = tags.lightRail;
            if ('closestRailStation' in poi && poi.closestRailStation && poi.closestRailStation.lightRail) {
              tag.lightRail = poi.closestRailStation.lightRail.stationName;
            }
            break;
          }
        }
      }

      if (nonNull(tag.value)) {
        acc.push(tag);
      }

      return acc;
    }, []);
  },
};
