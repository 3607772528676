import { memoize } from 'lodash';
import React from 'react';
import { Amenity, IFurniture } from 'utils/entities';
import {
  COMMERCIAL_AMENITIES,
  RESIDENTIAL_AMENITIES,
  COMMERCIAL_GENERAL_CONDITION_OPTIONS,
  RESIDENTIAL_GENERAL_CONDITION_OPTIONS,
} from './constants';
import { StatusDescription } from './styled';
import { ITranslate } from 'locale';
import { isNotEmpty } from 'utils/formValidationHelpers';
import { QualityClassOption } from 'components/filters/types';


export const validateField = (t: ITranslate) => (val: string) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');

export const buildingYearValidationMessage = (t: ITranslate) => (val: string) => {
  const MIN_YEAR = 1900;
  const MAX_YEAR = (new Date()).getFullYear() + 10;
  return isNotEmpty(val) && (!/\d{4}/.test(val) || (+val <= MIN_YEAR || +val > MAX_YEAR))
    ? t('uploadBulletinPage.yearValidationMessage')
    : undefined;
};

export const genAmenitiesOptions = memoize((t, isCommercial) => (
  isCommercial ? COMMERCIAL_AMENITIES : RESIDENTIAL_AMENITIES
).map( amenity => ({
  label: t(`unitPage.amenities.${amenity}`, (amenity === Amenity.Elevator ? { has: true } : {})),
  value: amenity,
})));

export const genGeneralConditionOptions = memoize((t, isCommercial) => (
  isCommercial ? COMMERCIAL_GENERAL_CONDITION_OPTIONS : RESIDENTIAL_GENERAL_CONDITION_OPTIONS
).map(status => ({
  label: (
    <>
      {t('filters.conditionsOptions', { value: status })}
      {isCommercial ? null : (
        <StatusDescription>
          {t('filters.conditionsOptionsDescription', { value: status })}
        </StatusDescription>
      )}
    </>
  ), value: status,
})));

export const genFurnitureOptions = memoize(t => Object.values(IFurniture).map(status => ({
  label: (
    <>
      {t('filters.furnitureOptions', { value: status })}
    </>
  ), value: status,
})));

export const genQualityClassOptions = memoize(t => Object.values(QualityClassOption).map(qualityClass => ({
  label: t('uploadBulletinPage.buildingDescriptionStep.qualityClassOptions', { value: qualityClass }),
  value: qualityClass,
})));

