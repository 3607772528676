import styled from '@emotion/styled';
import { SmallText, TextLabel } from 'ds/components/typography';
import { breakpointToContentWidth, HEADER_HEIGHT } from 'ds/components/Layout';
import { BreakpointValue } from 'consts/breakpoints';

export const PreviewPageWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: hidden;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    min-height: calc(100vh - ${HEADER_HEIGHT}px);
  `}
`;

export const MapWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.neutrals.grey10};
    position: relative;
    max-width: 1280px;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    display: none;
  `}
`;

export const BulletinPreviewWrapper = styled.div`
  height: 100%;
  min-width: 0;
  overflow-y: auto;

  ${({ theme }) => {
    return Object.keys(breakpointToContentWidth).map(key =>
      theme.media.showAt({ at: +key as BreakpointValue })` width: ${breakpointToContentWidth[key]};`
    );
  }}
`;

export const InfoWrapper = styled.div<{ isMobile: boolean }>`
  ${({ theme, isMobile }) => `
    padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(isMobile ? 15 : 10)};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

export const PreviewControlsWrapper = styled(SmallText)`
  ${({ theme }) => `
    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 0;
    background-color: ${theme.colors.neutrals.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(4)};
    box-shadow: ${theme.shadow.level5};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    width: 624px;
    height: 72px;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-around;
    z-index: 1;
  `}

  ${({ theme }) => {
    return Object.keys(breakpointToContentWidth).map(key =>
        theme.media.showAt({ at: +key as BreakpointValue })` width: ${breakpointToContentWidth[key]};`
    );
  }}
`;

export const PreviewControlsHeader = styled(TextLabel)`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    text-align: center;
    margin-top: ${theme.spacing(1)};
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(1)};
  `}
`;

export const ImageGalleryWrapper = styled.div`
  position: relative;
`;

export const PreviewWarningMessage = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.grey1};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.neutrals.white};
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

export const WarningLinkWrapper = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  `}
`;

export const DetailsWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(3)} 0;
`;
