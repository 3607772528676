import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { AppMode } from 'store/state/selectors/router';
import { DealType, User, MarketplaceType } from 'utils/entities';
import { Route, State as RouteState, NavigateToFn, AGENT_CONSOLE_ROUTES } from 'config/routes';
import styled from '@emotion/styled';
import { Header } from './header/Header';
import SearchSubheader from './subheader/SearchSubheader';
import ItemPageNavigationSubheader from './subheader/ItemPageNavigationSubheader';
import { MoreActionsMenu } from './more-actions-menu/MoreActionsMenu';
import { ShowAt } from 'consts/breakpoints';
import { FooterStickyContainer } from './user-mobile-navigation/styled';
import MaybeMobileMapSwitcher from 'components/mobile-map-switcher';
import { SEARCH_BOTTOM_ROOT } from 'consts/rootNodes';
import config from 'config';
import ExpandSubheader from './subheader/ExpandSubheader';
import useScrollDirection, { ScrollDirection } from 'hooks/useScrollDirection';
import withEvents from 'analytics/withEvents';
import { createPortal } from 'utils/universalPortal';
import { ExpandUGCReviewsSubheader } from './subheader/ExpandUGCReviewsSubheader';
import { PRESTEP_ROUTES } from 'components/authentication/AuthTrap';
import UserMobileProfileMenu from './more-actions-menu/UserMobileProfileMenu';
import { AuthenticationModalType } from 'components/authentication/types';
import { searchRouteByMarketplace, isUnitPageRoute } from 'utils/marketplaceRoutes';
import { CompletionType } from 'utils/entities';
import { useTransition, animated } from 'react-spring';
import AgentConsoleNavigationSubheader from 'components/navigation/subheader/AgentConsoleNavigationSubheader';


const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

interface NavigationProps {
  isVisitor: boolean;
  useAnimatedSubheader?: boolean;
  route: RouteState;
  prevRoute: RouteState;
  onOpenAuthModal: () => void;
  user: User;
  mode: AppMode;
  marketplace: MarketplaceType;
  onLogout: () => void;
  isMobileMapViewActive: boolean;
  setMobileMapViewActive: Dispatch<SetStateAction<boolean>>;
  navigateTo: NavigateToFn;
  hideHeader?: boolean;
  isUserMobileProfileOpen: boolean;
  authModalType: AuthenticationModalType;
  isNavigationSubheaderVisible: boolean;
  setIsMobileMenuHidden: (isHidden: boolean) => void;
  isMobileMenuHidden: boolean;
  hasMobileAdditionalBanners: boolean;
  isMobileLayersToggleOpen: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
  tooltipStyle?: React.CSSProperties;
}

const MADAD_PAGES = new Set([
  Route.MadadPage,
  Route.MadadCity,
  Route.MadadArchivePage,
  Route.MadadCityArchive,
  Route.MadadArchive2020Page,
]);
const ROUTES_WITH_MOBILE_MENU = new Set([
  Route.Sold,
  Route.Search,
  Route.Shortlist,
  Route.CheckAddress,
  Route.SearchCommercial,
  Route.Home,
  Route.CommercialMarketLanding,
  ...MADAD_PAGES,
]);
const ITEM_ROUTES = new Set([
  Route.Sold,
  Route.AddressPage,
  Route.ProjectPage,
  Route.ProjectPageCommercial,
  Route.UnitPage,
  Route.UnitPageCommercial,
  Route.LocalPage,
  Route.StreetPage,
  Route.EmploymentAreaPage,
]);

const Navigation: React.FC<NavigationProps> = ({
  isVisitor,
  prevRoute,
  navigateTo,
  route,
  user,
  mode,
  marketplace,
  onOpenAuthModal,
  onLogout,
  useAnimatedSubheader,
  setMobileMapViewActive,
  isMobileMapViewActive,
  hideHeader,
  isUserMobileProfileOpen,
  authModalType,
  isNavigationSubheaderVisible,
  setIsMobileMenuHidden,
  isMobileMenuHidden,
  isMobileLayersToggleOpen,
  isMenuOpen,
  setIsMenuOpen,
  tooltipStyle,
  hasMobileAdditionalBanners,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollDir = useScrollDirection();

  const handleBackToMenu = useCallback(() => {
    if (prevRoute) {
      window.history.back();
    }
    else {
      navigateTo(searchRouteByMarketplace(marketplace), {
        dealType: DealType.Buy,
        term: [ config.cityTerm ],
      });
    }

  }, [ prevRoute, navigateTo, setIsMenuOpen, marketplace ]);

  const transition = useTransition(useAnimatedSubheader, null, {
    from: {
      transform: 'translateY(-100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(-100%)',
    },
  });

  const openMenu = useCallback(() => setIsMenuOpen(true), [ setIsMenuOpen ]);
  const closeMenu = useCallback(() => setIsMenuOpen(false), [ setIsMenuOpen ]);
  const toggleMap = useCallback(() => setMobileMapViewActive(!isMobileMapViewActive), [ setMobileMapViewActive, isMobileMapViewActive ]);

  const handleSearch = useCallback((value: string[], projectType?: CompletionType) => {
    if (projectType) {
      const params = { id: value[0] };
      navigateTo(projectType === CompletionType.CommercialProject ? Route.ProjectPageCommercial :  Route.ProjectPage, params);
    }
    else {
      navigateTo(searchRouteByMarketplace(marketplace), {
        ...route.params,
        ...{ term: value && value.length ? value : [ config.cityTerm ] },
        page: undefined,
        bbox: undefined,
        tracking_search_source: 'new_search',
      });
    }
  }, [ route, navigateTo, marketplace ]);

  const isSearch = route.name === Route.Search || route.name === Route.SearchCommercial;
  const showMobileUserMenu = (ROUTES_WITH_MOBILE_MENU.has(route.name) || isUserMobileProfileOpen) && !isMobileLayersToggleOpen;
  const showHeaderMenuForAddress = user === null || (isVisitor && route.name === Route.AddressPage);
  const isItemPage = ITEM_ROUTES.has(route.name) && !isUnitPageRoute(route.name);
  const isAgentConsolePage = AGENT_CONSOLE_ROUTES.has(route.name);
  const isExpandActive = isItemPage && route.params.insightId && route.params.insightCollection;
  const isUGCReviewResultActive = route.params.isActiveReviewResultPage;

  useEffect(() => {
    setIsMobileMenuHidden(showMobileUserMenu && scrollDir === ScrollDirection.Down);
  }, [ showMobileUserMenu, scrollDir ]);

  const mapSwitcher = (isMenuOpen || isUserMobileProfileOpen || authModalType !== null) ? null : (
    <MaybeMobileMapSwitcher
      isMobileMapViewActive={isMobileMapViewActive}
      isMobile
      isSearch={isSearch}
      toggleMap={toggleMap}
    />
  );

  const maybeRenderHeader = (isMobile: boolean) => {
    const isSearchReferer = route.params.term || route.params.dealType || route.params.address;
    const isFeedOrShortlistReferer = prevRoute && (prevRoute.name === Route.Shortlist);

    if (isMobile && isVisitor && PRESTEP_ROUTES.has(route.name)) return null;

    return isMobile && isItemPage && (isSearchReferer || isFeedOrShortlistReferer) && !showHeaderMenuForAddress
      || (isMobile && isUGCReviewResultActive)
      || (isMobile && route.name === Route.MadadSearchPage)
      ? null
      : (
        <Header
          mode={mode}
          onMore={openMenu}
          onLogout={onLogout}
          onOpenAuthModal={onOpenAuthModal}
          route={route}
          tooltipStyle={tooltipStyle}
          onBackToMenu={handleBackToMenu}
        />
      );
  };

  let maybeSubheader = null;

  if (isSearch) {
    maybeSubheader = (
      <SearchSubheader
        onSearch={handleSearch}
        setMobileMapViewActive={setMobileMapViewActive}
      />
    );
  }
  else if (isExpandActive) {
    maybeSubheader = (
      <ShowAt from={3}>
        <ExpandSubheader />
      </ShowAt>
    );
  }
  else if (isUGCReviewResultActive) {
    maybeSubheader = (
      <ShowAt from={3}>
        <ExpandUGCReviewsSubheader />
      </ShowAt>
    );
  }
  else if (isItemPage) {
    maybeSubheader = (
      <ItemPageNavigationSubheader isVisible={isNavigationSubheaderVisible} />
    );
  }
  else if (isAgentConsolePage) {
    maybeSubheader = (
      <ShowAt from={3}>
        <AgentConsoleNavigationSubheader />
      </ShowAt>
    );
  }


  return (
    <>
      {hideHeader ? null : (
        <Wrapper ref={wrapperRef}>
          <ShowAt to={2}>
            {maybeRenderHeader}
          </ShowAt>
          {maybeSubheader}
          {transition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                style={{ transform: props.transform, position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999 }}
                key={key}
              >
                <ItemPageNavigationSubheader isVisible={isNavigationSubheaderVisible} />
              </animated.div>
            )
        )}
        </Wrapper>
      )}
      <MoreActionsMenu
        isOpen={isMenuOpen}
        onClose={closeMenu}
      />
      <ShowAt to={2}>
        {isVisitor === false && isUserMobileProfileOpen ? <UserMobileProfileMenu /> : null}
      </ShowAt>
      {isSearch ? (
        <ShowAt to={2}>
          {createPortal(
            <FooterStickyContainer
              withAdsBanner={isMobileMenuHidden && hasMobileAdditionalBanners}
              data-auto="search-bottom-root"
              withLayoutIndex={isMenuOpen || isUserMobileProfileOpen}
            >
              {mapSwitcher}
            </FooterStickyContainer>
            , SEARCH_BOTTOM_ROOT)}
        </ShowAt>
      ) : null}
    </>
  );
};

const NavigationWithEvents = withEvents<NavigationProps>((sendEvent, props) => ({
  setMobileMapViewActive() {
    sendEvent('map_toggle_click', 'map', {
      event: {
        'toggle_direction': props.isMobileMapViewActive ? 'collapse' : 'expand',
      },
    });
  },
}))(Navigation);

export default NavigationWithEvents;
