import { State } from 'store/state';
import { connect } from 'react-redux';
import { isAddressPageAddressSavedSelector } from 'store/state/selectors/addressPage';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { toggleAddress } from 'store/state/app/actions';
import { ToggleAddressButton } from './ToggleAddress';


const mapStateToProps = (state: State) => ({
  isSaved: isAddressPageAddressSavedSelector(state),
  resolvedAddress: addressDocEntrySelector(state),
});

const mapDispatchToProps = {
  toggleAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleAddressButton);
