import React from 'react';
import { useLocale, withLocale, WithLocaleProps } from 'locale';
import { CheckboxWrapper, Section, SectionBody, SectionTitle } from '../styled/common';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { GenericFilterProps, IPriceChanges } from '../types';
import { BaseProjectDiscount } from './ProjectDiscount';

interface PriceChangesProps extends GenericFilterProps<'priceChanges'>, WithLocaleProps {}

const PriceChanges: React.FC<PriceChangesProps> = ({
  intermediateValue,
  setIntermediateProjectDiscountValue,
  projectDiscount,
  setIntermediateValue,
  type,
}) => {
  const { t } = useLocale();
  const handleChange = (filterKey: keyof IPriceChanges) => {
    setIntermediateValue({
      ...intermediateValue,
      [filterKey]: !intermediateValue[filterKey],
    });
  };

  const handleProjectDiscountChange = () => {
    setIntermediateProjectDiscountValue(!projectDiscount);
  };

  return (
    <Section>
      <SectionTitle isBold>
        <div>
          {t('filters.amenitiesSections', { type })}
        </div>
      </SectionTitle>
      <SectionBody>
        <BaseProjectDiscount value={projectDiscount} handleChange={handleProjectDiscountChange} />
        <CheckboxWrapper>
          <CheckboxField
            checked={intermediateValue.underPriceEstimation}
            onClick={() => handleChange('underPriceEstimation')}
            label={t('filter.priceChanging.underPriceEstimation')}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            checked={intermediateValue.priceDrop}
            onClick={() => handleChange('priceDrop')}
            label={t('filter.priceChanging.priceDrop')}
          />
        </CheckboxWrapper>
      </SectionBody>
    </Section>
  );
};

export default withLocale(PriceChanges);
