import { call, put, select } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { rawDeveloperSelector } from 'store/state/domainData/selectors/developerById';
import { navigateTo } from 'store/state/router/actions';

function* loadDeveloperProfile(route: RouteState) {
  const { id } = route.params;

  const loadOptions: LoadOptions<LoadType.DeveloperById> = {
    loadType: LoadType.DeveloperById,
    meta: {
      variables: { id },
    },
  };
  yield call(queryData, loadOptions);

  const developerData = yield select(rawDeveloperSelector);
  if (!developerData) {
    yield put(navigateTo(Route.DevelopersSearchPage, {}, { replace: true }));
  }
}

export function* developerPageHandler(params: RouteState) {
  yield call(loadDeveloperProfile, params);
}
