import { SET_FEEBACK_MODAL } from './types';
import { FeedbackModalPayload } from './payloads';
import { UGCAction } from './actionType';

export type FeedbackModalState = FeedbackModalPayload;

const INITIAL_STATE: FeedbackModalState = {
  isOpen: false,
};

export const feedbackModalReducer = (state: FeedbackModalState = INITIAL_STATE, action: UGCAction) => {
  return action.type === SET_FEEBACK_MODAL ? { ...action.payload } : state;
};
