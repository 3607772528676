import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { RootAction } from 'store/state';
import { MutationType } from 'store/sagas/apiService/types';


export const createMutationResponsePattern = <T extends MutationType>(mutationType: T) => (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE
  && action.mutationType === mutationType
  && Boolean(action.meta.response.data)
);
