import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';

export const AutocompleteInputWrapper = styled.div`
  position: relative;
`;

export const InputWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const TextFieldWrapper = styled.div<{ isOpen: boolean, isMobile?: boolean, showClearButton?: boolean }>`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  position: relative;

  input {
    transition: all 0s;
  }

  ${({ theme, isOpen, isMobile }) => (isMobile && isOpen) ? `
    input {
      background: ${theme.colors.neutrals.grey9};
      border-color: ${theme.colors.neutrals.grey9} !important;
      color: ${theme.colors.neutrals.grey3};
    }
  ` : `
    input {
      background: ${theme.colors.neutrals.white};
    }
  `}

  ${({ theme, showClearButton, isOpen }) => showClearButton && isOpen ? `
    input {
      padding-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(4)};
    }
  ` : ''}
`;

export const LocationSectionName = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing(1.5)};
`;

export const ClearButton = styled.div`
  ${({ theme }) => `
    ${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1.5)};
    margin-top: -${theme.spacing(1)};
  `}
  position: absolute;
  top: 50%;
  height: 16px;
  width: 16px;
  cursor: pointer;
  z-index: 1;
`;


export const CloseButton = styled(SmallText)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)} 0;
    padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(2)};
  `}
`;

export const AddressSuggestionsWrapper = styled.div<{ isMobile?: boolean }>`
  background: ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  z-index: 3;

  ${({ isMobile, theme }) => isMobile ? `
    border: none;
    padding: ${theme.spacing(3)} 0 0;
    box-shadow: none;
  ` : `
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: ${theme.spacing(1)};
    margin-top: ${theme.spacing(0.5)};
    border-top: 1px solid ${theme.colors.neutrals.grey8};
    border: 1px solid ${theme.colors.neutrals.grey6};
    box-shadow: ${theme.shadow.level2};`
  }
`;

export const MobileSuggestionsWrapper = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.neutrals.white};
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
  `}
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const SuggestionMessageWrapper = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.brand.main};
    padding-bottom: ${theme.spacing(4)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding-top: ${theme.spacing(2)};
  `}
`;
