import React from 'react';
import Share from 'components/navigation/share';
import BackLink from 'components/navigation/BackLink';
import ArrowIcon from 'assets/svg/navigation/chevron-left.svg';
import { MobileSubmenuWrapper, SubMobileBackButton, MobileShareWrapper } from './styled';
import MaybeFavoritesTogglerWithRoute from './MaybeFavoritesTogglerWithRoute';
import MaybeEditStatusWithRoute from './MaybeEditStatusWithRoute';
import { State } from 'store/state';
import { AppMode, appModeSelector, routeNameSelector } from 'store/state/selectors/router';
import { connect } from 'react-redux';
import { Route } from 'config/routes';
import ToggleAddressButton from 'components/toggle-address';
import NavigationSearch from './components/navigationSearch';
import AccessibilityIcon from 'assets/svg/navigation/accessibility.svg';
import SubheaderControls from './SubheaderControls';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';

const isCheckMode = (mode: AppMode) => {
  return mode === AppMode.Check;
};

const MobileSubmenuBasic: React.FC<{
  routeName: Route;
  mode: AppMode;
}> = ({ routeName, mode }) => {
  const isUnitPage = isUnitPageRoute(routeName);
  const handleAccessibilityClick = () => {
    const accessibilityButton = document.getElementById('INDmenu-btn');
    if (accessibilityButton) {
      accessibilityButton.click();
    }
  };

  return (
    <MobileSubmenuWrapper>
      <div>
        <SubMobileBackButton data-auto="sub-mobile-back-button" isCheckMode={isCheckMode(mode)}>
          <BackLink>
            <ArrowIcon style={{ width: 8, height: 16 }} />
          </BackLink>
        </SubMobileBackButton>
        {isCheckMode(mode) ? <NavigationSearch /> : null}
      </div>
      <div>
        <div>
          <AccessibilityIcon width={24} height={24} onClick={handleAccessibilityClick} />
        </div>
        {isUnitPage ? <SubheaderControls /> : (
          <MobileShareWrapper>
            <Share />
          </MobileShareWrapper>
        )}
        {routeName === Route.AddressPage ? <ToggleAddressButton onlyIcon /> : null}
        <MaybeFavoritesTogglerWithRoute />
        <MaybeEditStatusWithRoute />
      </div>
    </MobileSubmenuWrapper>
  );
};

const mapStateToProps = (state: State) => ({
  routeName: routeNameSelector(state),
  mode: appModeSelector(state),
});

export const MobileSubmenu = connect(mapStateToProps)(MobileSubmenuBasic);
