import { SortField } from 'components/listing-sort/types';
import { Priority } from 'utils/entities';
import invert from 'lodash/invert';

export const PRIORITY_BY_SORT_FIELD: Partial<Record<SortField, Priority>> = {
  bestSchool: 'schools',
  bestSecular: 'bestSecular',
  bestReligious: 'bestReligious',
  lightRail: 'lightRail',
  commute: 'commuteTime',
  dogPark: 'dogFriendly',
  familyFriendly: 'familyFriendly',
  parkAccess: 'parksAccess',
  quietStreet: 'tranquilStreet',
  safety: 'lowCrime',
};

export const SORT_FIELD_BY_PRIORITY: Partial<Record<Priority, SortField>> = invert(PRIORITY_BY_SORT_FIELD);
