import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';

export const ListWidgetWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3)} 0;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  ${({ theme }) => theme.isRTL && theme.media.showAt({ to: 3 })`
    padding: 0;
  `}
`;

export const TabuWidgetWrapper = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing(1)};
  `}
`;

export const InsightsCollapsedSectionItem = styled.article``;

export const InsightsSectionWrapper = styled.div<{ skipPadding?: boolean }>`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-left: -${theme.spacing(0.5)};
    margin-right: -${theme.spacing(0.5)};
  `}
  padding-bottom: ${({ theme, skipPadding }) => skipPadding ? 0 : theme.spacing(7)};
  background-color: ${({ theme }) => theme.colors.neutrals.white};
`;

export const ReportListingV1Wrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(4)} 0 ${theme.spacing(5)};
  `}
`;

export const ReportListingV2Wrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3.5)};
  `}
`;

export const ImpactInsightsWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    .scrollBox {
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 2 })`
    .scrollBox {
      padding: ${theme.spacing(1)} ${theme.spacing(5)};
    }
  `}
`;

export const MoreInfo = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
  &:last-of-type {
    padding-left: 0;
  }
  &:first-of-type {
    padding-right: 0;
  }
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    &::after {
      content: '';
      display: block;
      height: 14px;
      margin: 0 ${theme.spacing(2)};
      width: 1px;
      background: ${theme.colors.neutrals.grey6};
    }
    &:last-child::after {
      display: none;
    }
  `}
`;

export const MoreText = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const AmenitiesWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2.5)};
  `}
`;

export const MobileMapWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const ILContactWrapper = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing(7)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-top: ${theme.spacing(6)};
  `};
`;
