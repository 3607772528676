import styled from '@emotion/styled';
import { BasicButton } from 'ds/components/button/BasicButton';


export const DealTypeOption = styled(BasicButton)<{ active: boolean }>`
  ${({ theme, active }) => `
    background: ${theme.colors.neutrals.white};
    color: ${active ? theme.colors.brand.main : theme.colors.neutrals.grey1};
    border: 1px solid ${active ? theme.colors.brand.main : theme.colors.neutrals.grey6};
    :hover {
      background: inherit;
      color: ${active ? theme.colors.brand.main : theme.colors.neutrals.grey1};
      border: 1px solid ${active ? theme.colors.brand.main : theme.colors.neutrals.grey6};
    }
  `}
`;

export const OptionsList = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    padding-top: ${theme.spacing(1)};
    > button {
      flex-basis: calc(50% - 5px);
      width: 100%;
    }
  `}
`;

export const ControlButtonGroupWithStyles = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
