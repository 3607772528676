import React from 'react';
import { connect } from 'react-redux';
import EditIcon from 'assets/svg/interface-16-edit.svg';
import { State } from 'store/state';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { Text } from 'ds/components/typography';
import { isAgentSelector, isConnectedUserSelector, isBulletinsAdminSelector } from 'store/state/domainData/selectors';
import { ControlButton } from 'ds/components/button';
import { useLocale } from 'locale';
import { MarketplaceType, PoiId } from 'utils/entities';
import { Link } from 'components/link';
import { Route } from 'config/routes';
import { IconWrapper, Wrapper } from './styled';
import { marketplaceSelector } from 'store/state/selectors/router';
import { createSelector } from 'reselect';


interface EditBulletinButtonOwnProps {
  isCommercial?: boolean;
  id: PoiId;
  small?: boolean;
  isRound?: boolean;
}

interface EditBulletinButtonProps extends EditBulletinButtonOwnProps {
  isAgent: boolean;
  isAdmin: boolean;
  isConnectedUser: boolean;
  ignoreMobileStyles?: boolean;
}

const EditBulletinButton: React.FC<EditBulletinButtonProps> = ({
  id,
  isConnectedUser,
  isAgent,
  isAdmin,
  isCommercial,
  small,
  isRound,
  ignoreMobileStyles,
}) => {
  const { t } = useLocale();
  const isMobile = !ignoreMobileStyles && useScreenBreakpoint() < 3;

  if (!isConnectedUser) return null;

  let inner;
  switch (true) {
    case small:
      inner = (
        <ControlButton>
          <EditIcon width={16} height={17} />
          {t('manageBulletinPage.bulletin.edit')}
        </ControlButton>
      );
      break;

    case isMobile:
      inner = <EditIcon height={20} width={20}/>;
      break;

    default:
      inner = (
        <ControlButton size="large" fontType="important" data-auto="edit-listing-button" isRound={isRound}>
          {isRound ? (
            <IconWrapper>
              <EditIcon height={24} width={24} />
            </IconWrapper>
          ) : (
            <>
              <EditIcon height={20} width={20} />
              <Text weight="medium">{t('manageBulletinPage.bulletin.edit')}</Text>
            </>
          )}
        </ControlButton>
      );
  }

  if (isAgent && !isAdmin && !isCommercial) {
    return <Wrapper><a rel="nofollow" href={`/bulletin/${id}/edit`} target="_blank">{inner}</a></Wrapper>;
  }

  return (
    <Wrapper>
      <Link routeName={isCommercial ? Route.EditCommercialBulletin : Route.EditBulletin} routeParams={{ id }}>
        {inner}
      </Link>
    </Wrapper>
  );
};

const isCommercialRouteSelector = createSelector(marketplaceSelector, (marketType => marketType === MarketplaceType.Commercial));

const mapStateToProps = (state: State) => ({
  isAgent: isAgentSelector(state),
  isAdmin: isBulletinsAdminSelector(state),
  isConnectedUser: isConnectedUserSelector(state),
  isCommercial: isCommercialRouteSelector(state),
});

export default connect(mapStateToProps, null, (stateProps, _, ownProps: EditBulletinButtonOwnProps) => ({
  ...stateProps,
  ...ownProps,
}))(EditBulletinButton);
