import React, { useEffect, useState } from 'react';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { BudgetSliderProps } from 'components/filters/types';
import {
  Wrapper,
  TextFieldWrapper,
  TextFieldLabel,
  Separator,
  TextCells,
  PriceCell,
  Label,
} from './styled';
import { Text } from 'ds/components/typography';


interface RangeInputsProps extends BudgetSliderProps {
  value: [number, number];
  onChange: (value: [number, number]) => void;
  minLabel?: string;
  maxLabel?: string;
  formatter?: (value: number) => string;
  displayAsText?: boolean;
}

type PriceFieldType = 'min' | 'max';

const RangeInputs: React.FC<RangeInputsProps> = (props) => {
  const [ focusedField, setFocusedField ] = useState<PriceFieldType>(null);
  const { t } = useLocale();

  const {
    displayAsText,
    value,
    onChange,
    min,
    max,
    minDelta,
    minLabel = t('filters.priceRange.minPrice'),
    maxLabel = t('filters.priceRange.maxPrice'),
    formatter,
  } = props;
  const [ internalValue, setInternalValue ] = useState<[number, number]>(value);
  const [ internalFrom, internalTo ] = internalValue;

  useEffect(() => {
    setInternalValue(value);
  }, [ value ]);

  const typeToValue = (type: PriceFieldType) => type === 'min' ? internalFrom : internalTo;

  const getFieldValue = (type: PriceFieldType) => {
    const val = typeToValue(type);
    if (focusedField === type) return val;
    const formattedVal = typeof formatter === 'function' ? formatter(val) : val;
    return `₪ ${formattedVal}${(type === 'max' && internalTo === max ? '+' : '')}`;
  };

  const makeFocusHandler = (type: PriceFieldType) => () => {
    setFocusedField(type);
  };

  const makeBlurHandler = (type: PriceFieldType) => () => {
    const validatedValue: [number, number] = [ internalFrom, internalTo ];

    if (type === 'min') {
      let validFrom = Math.max(min, internalFrom);
      if (validFrom > internalTo) validFrom = Math.max(internalTo - minDelta, min);
      validatedValue[0] = validFrom;
    }
    else {
      let validTo = Math.min(max, internalTo);
      if (validTo < internalFrom) validTo = Math.min(internalFrom + minDelta, max);
      validatedValue[1] = validTo;
    }

    onChange(validatedValue);
    setFocusedField(null);
  };

  const makeChangeHandler = (type: PriceFieldType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.currentTarget.value ? +e.currentTarget.value.replace(/\D+/, '') : 0;
    setInternalValue(type === 'min' ? [ nextValue, internalTo ] : [ internalFrom, nextValue ]);
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  return (
    <Wrapper>
      {displayAsText ? (
        <TextCells>
          <PriceCell>
            <Label>{minLabel}</Label>
            <Text>{getFieldValue('min')}</Text>
          </PriceCell>
          <PriceCell toRight>
            <Label>{maxLabel}</Label>
            <Text>{getFieldValue('max')}</Text>
          </PriceCell>
        </TextCells>
      ) : (
        <>
          <TextFieldWrapper>
            <TextFieldLabel>{minLabel}</TextFieldLabel>
            <TextField
              label={null}
              value={getFieldValue('min')}
              onFocus={makeFocusHandler('min')}
              onBlur={makeBlurHandler('min')}
              onChange={makeChangeHandler('min')}
              onClick={handleClick}
              fullWidth
              type="tel"
            />
          </TextFieldWrapper>
          <Separator />
          <TextFieldWrapper>
            <TextFieldLabel>{maxLabel}</TextFieldLabel>
            <TextField
              label={null}
              value={getFieldValue('max')}
              onFocus={makeFocusHandler('max')}
              onBlur={makeBlurHandler('max')}
              onChange={makeChangeHandler('max')}
              onClick={handleClick}
              fullWidth
              type="tel"
            />
          </TextFieldWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(RangeInputs);
