import CrossIcon from 'assets/svg/close-modal.svg';
import { FadeWrapper } from 'components/authentication/styled';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { ShowAt } from 'consts/breakpoints';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { noop } from 'lodash';
import { isServer } from 'utils';
import React, { useRef, useState, useLayoutEffect } from 'react';
import { useGlobalEventListener } from 'hooks/useGlobalEventListener';
import { KeyboardKeys } from 'utils/keyboardKeys';
import {
  CloseButton,
  ContentWrapper,
  MainContentWrapper,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Root,
} from './styled';


interface ModalProps {
  mobileModalPositionBottom?: boolean;
  desktopModalPositionCenter?: boolean;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: (e?: any) => void;
  title?: React.ReactNode;
  buttons?: React.ReactNode;
  hideCloseButton?: boolean;
  footerStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  contentWrapperStyle?: React.CSSProperties;
  closeButtonStyle?: React.CSSProperties;
  isFullWidthMainContent?: boolean;
}

/*
  NOTE: Don't use ClickAwayListener in order to close modal.
  ClickAwayListener registers events on Document (out of React) and React uses synthetic events internally.
  So, it's problematically to stop event propagation to Document because the real event has already propagated
  by the time you interact with it in React.
*/

const Modal: React.FC<ModalProps> = ({
  mobileModalPositionBottom,
  desktopModalPositionCenter,
  isOpen,
  children,
  onClose,
  title,
  buttons,
  hideCloseButton = false,
  footerStyle,
  titleStyle,
  headerStyle,
  contentWrapperStyle,
  closeButtonStyle,
  isFullWidthMainContent,
}) => {
  const topGhostNode = useRef<HTMLDivElement>(null);
  const bottomGhostNode = useRef<HTMLDivElement>(null);
  const [ showHeaderShadow, setShowHeaderShadow ] = useState(false);
  const [ showFooterShadow, setShowFooterShadow ] = useState(false);
  const [ footerHeight, setFooterHeight ] = useState(0);
  const footerRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: KeyboardEvent) => {
    if (e.code === KeyboardKeys.ESC) {
      onClose();
    }
  };

  useGlobalEventListener(isServer() ? null : window, 'keydown',  handleClose);

  useIntersectionObserver(
    ([ entry ]) => setShowHeaderShadow(!entry.isIntersecting),
    [ topGhostNode ],
    { threshold: 0, rootMargin: '-72px' }
  );

  useIntersectionObserver(
    ([ entry ]) => setShowFooterShadow(!entry.isIntersecting),
    [ bottomGhostNode ],
    { threshold: 0, rootMargin: '-72px' }
  );

  useLayoutEffect(() => {
    if (footerRef.current) {
      setFooterHeight(mobileModalPositionBottom ? 0 : footerRef.current.clientHeight);
    }
    else if (footerHeight) {
      setFooterHeight(0);
    }
  }, [ footerRef.current ]);

  return (
    <OverlayWrapper>
      <FadeWrapper isVisible={isOpen}>
        <ShowAt to={2}>
          {matches => (
            <>
              <ModalOverlay
                mobileModalPositionBottom={mobileModalPositionBottom}
                onClick={(matches && !mobileModalPositionBottom) ? noop : onClose}
              />
              <Root mobileModalPositionBottom={mobileModalPositionBottom} desktopModalPositionCenter={desktopModalPositionCenter}>
                <ContentWrapper
                  mobileModalPositionBottom={mobileModalPositionBottom}
                  desktopModalPositionCenter={desktopModalPositionCenter}
                  data-auto="modal-popup"
                  data-pop-up-container
                  style={{ marginBottom: `${footerHeight}px`, ...contentWrapperStyle }}
                >
                  <ModalHeader
                    mobileModalPositionBottom={mobileModalPositionBottom}
                    hasShadow={showHeaderShadow}
                    hasTitle={Boolean(title)}
                    style={headerStyle}
                  >
                    {hideCloseButton ? null : (
                      <CloseButton
                        mobileModalPositionBottom={mobileModalPositionBottom}
                        onClick={onClose}
                        className="modal-close-button"
                        data-auto="modal-close-button"
                        style={closeButtonStyle}
                      >
                        <CrossIcon width={24} height={24} />
                      </CloseButton>
                    )}
                    {title ? (
                      <ModalTitle data-auto="modal-title" style={titleStyle}>
                        {title}
                      </ModalTitle>
                    ) : null}
                  </ModalHeader>
                  <MainContentWrapper isFullWidthMainContent={isFullWidthMainContent}>
                    <div ref={topGhostNode} />
                    {children}
                    <div ref={bottomGhostNode} />
                  </MainContentWrapper>
                  {buttons ? (
                    <ModalFooter
                      data-auto="modal-footer"
                      mobileModalPositionBottom={mobileModalPositionBottom}
                      style={footerStyle}
                      hasShadow={showFooterShadow}
                      ref={footerRef}
                    >
                      {buttons}
                    </ModalFooter>
                  ) : null}
                </ContentWrapper>
              </Root>
            </>
          )}
        </ShowAt>

      </FadeWrapper>
    </OverlayWrapper>
  );
};

export default Modal;
