import { State } from 'store/state';
import { SearchViewMode, DEFAULT_SEARCH_VIEW } from 'components/list-view-switcher/constants';

export function maybeApplySearchView(state: State, view: SearchViewMode): State {
  return {
    ...(state || null),
    app: {
      ...(state && state.app || null),
      searchViewMode: view || DEFAULT_SEARCH_VIEW,
    },
  };
}
