import React from 'react';
import Modal from 'ds/components/modal';
import CrossIcon from 'assets/svg/close-modal.svg';
import WarningIcon from 'assets/svg/warning-icon.svg';
import { useLocale } from 'locale';
import * as Styled from './styled';


interface DeleteUserPopupProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}


export const DeleteUserPopup: React.FC<DeleteUserPopupProps> = (props) => {
  const {
    isOpen,
    onDelete,
    onClose,
  } = props;

  const { t } = useLocale();

  if (!isOpen) return null;

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      isOpen
      hideCloseButton={true}
      onClose={onClose}
    >
      <Styled.Root>
        <Styled.CloseWrapper onClick={onClose}>
          <CrossIcon width={24} height={24} />
        </Styled.CloseWrapper>
        <Styled.WarningWrapper>
          <WarningIcon />
        </Styled.WarningWrapper>
        <Styled.Title weight="bold">{t('profile.removeUser.title')}</Styled.Title>
        <Styled.DeleteButton onClick={handleDelete} fullWidth size="large">
          {t('manageBulletinPage.bulletin.deleteOverlay.button')}
        </Styled.DeleteButton>
        <Styled.CancelButtonWrapper>
          <Styled.CancelButton mode="secondary" onClick={onClose}>
            {t('manageBulletinPage.bulletin.deleteOverlay.cancel')}
          </Styled.CancelButton>
        </Styled.CancelButtonWrapper>
      </Styled.Root>
    </Modal>
  );
};
