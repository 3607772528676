
import withEvents from 'analytics/withEvents';
import { ShortlistHoverPreview, ShortlistHoverPreviewProps } from 'components/navigation/shortlist-hover-preview/ShortlistHoverPreview';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { userFavoritesPreviewPoisSelector } from 'store/state/domainData/selectors/favorites';

const ShortlistHoverPreviewWithEvents = withEvents<ShortlistHoverPreviewProps>(sendEvent => ({
  onShortlistButtonClick() {
    sendEvent('page_navigation_bar_icon_click', 'page', { event: { icon: 'shortlist_hover' } });
  },
}))(ShortlistHoverPreview);

const mapStateToProps = (state: State) => ({
  previewListings: userFavoritesPreviewPoisSelector(state),
});

export default connect(mapStateToProps, null)(ShortlistHoverPreviewWithEvents);
