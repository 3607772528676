import { takeEvery } from 'redux-saga/effects';
import { SET_SEARCH_VIEW_MODE } from 'store/state/app/types';
import { setSearchViewMode } from 'store/state/app/actions';
import Cookie from 'js-cookie';
import { SEARCH_VIEW_KEY } from 'components/list-view-switcher/constants';


function* syncSearchViewMode({ payload }: ReturnType<typeof setSearchViewMode>) {
  Cookie.set(SEARCH_VIEW_KEY, payload);
}

export function* searchViewWatcher() {
  yield takeEvery(SET_SEARCH_VIEW_MODE, syncSearchViewMode);
}
