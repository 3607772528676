import { createSelector } from 'reselect';
import { flow, head, keyBy } from 'lodash';
import { getSaveSearchRouteParams } from './router';
import { ISaveSearch, ISaveSearchFromState, ISaveSearchQueryProp } from 'store/sagas/apiService/types';
import { searchParamsSelector, searchParamsSelectorResult } from 'store/sagas/routing/handlers/search/selectors';
import { State } from 'store/state';
import { savedSearchesListSelector, searchDocEntriesSelector } from 'store/state/domainData/selectors';
import { DealType, IAutocompleteEntry, ISearchParameters, MarketplaceType } from 'utils/entities';
import config from 'config';
import { config as filtersConfig } from 'components/filters/config';
import { Amenity, BathOption, IFiltersState, RoomOption } from 'components/filters/types';
import { isEmptyRange, normalizeBathsRange, normalizeFloorsRange, normalizeRoomsRange } from 'components/filters/utils';
import { isSearchEqual } from 'utils/saveSearch';
import { removeTypeName } from 'utils';
import { DecodedRouteParams } from 'config/routes';
import { homepageWizardSelector } from 'store/state/homepageWizard/selectors';
import { makeSet } from 'components/listing-sort/utils';
import { SortDirection, SortField } from 'components/listing-sort/types';
import { marketplaceSelector } from 'store/state/selectors/router';


export const formatQueryToFilters = (query: ISaveSearchQueryProp, marketplace: MarketplaceType) => {
  const {
    dealType,
    generalCondition: conditions,
    buildingClass: propertyTypes,
    amenities: amenitiesRaw,
    sellerType: seller,
    noFee: fee,
    priceRange: [ priceRangeFrom, priceRangeTo ],
    roomsRange: roomsRangeRaw,
    areaRange,
    bathsRange: bathsRangeRaw,
    floorRange: floorRangeRaw,
  } = query;

  const [ roomsRangeFrom, roomsRangeTo ] = normalizeRoomsRange(roomsRangeRaw, marketplace, false);
  const [ bathsRangeFrom, bathsRangeTo ] = normalizeBathsRange(bathsRangeRaw, marketplace, false);
  const [ floorRangeFrom, floorRangeTo ] = normalizeFloorsRange(floorRangeRaw, marketplace, false);

  const roomsRange: [ RoomOption, RoomOption ] = roomsRangeTo === 0 ? [ 0, null ] : [ roomsRangeFrom, roomsRangeTo ];
  const bathsRange: [ BathOption, BathOption ] = bathsRangeTo === 1 ? [ 1, null ] : [ bathsRangeFrom, bathsRangeTo ];
  const floorRange: [ number, number ] = [ floorRangeFrom, floorRangeTo ];

  const marketSpecificFields: Partial<IFiltersState> = {};

  if (filtersConfig[marketplace].budgetByPerSquareMeter) {
    const [ ppmRangeFrom, ppmRangeTo ] = query.ppmRange && query.ppmRange.length ? query.ppmRange : [ null, null ];
    const [ minPPMPrice, maxPPMPrice ] = filtersConfig[marketplace].budgetByPerSquareMeter[dealType].initialBudgetValue;
    marketSpecificFields.ppmRange = [
      ppmRangeFrom === null ? minPPMPrice : ppmRangeFrom,
      ppmRangeTo === null ? maxPPMPrice : ppmRangeTo,
    ];
  }

  const amenities = Object.keys(removeTypeName(amenitiesRaw)).filter((val): val is Amenity => query.amenities[val]);
  const [ minPriceRange, maxPriceRange ] = filtersConfig[marketplace].budgetByDealType[dealType].initialBudgetValue;
  const priceRange: [ number, number ] = [ priceRangeFrom === null ? minPriceRange : priceRangeFrom, priceRangeTo === null ? maxPriceRange : priceRangeTo ];
  const filters: Partial<IFiltersState> = {
    propertyTypes,
    priceRange,
    roomsRange,
    bathsRange,
    conditions,
    floorRange,
    areaRange,
    amenities,
    dealType,
    seller,
    fee,
    ...marketSpecificFields,
  };

  return filters;
};

export type SaveSearchGetter = (state: State, searchId: string) => ISaveSearch;

export const saveSearchSelectorResult = (
  routeParams: DecodedRouteParams,
  searchDoc: Array<Partial<IAutocompleteEntry>>,
  searchParams: ISearchParameters,
  isStrict: boolean
): ISaveSearchFromState => {
  const docIds = new Set(searchDoc && searchDoc.length ? searchDoc.map(s => s.docId) : []);

  if (isStrict && (!searchDoc || (!routeParams.term || !routeParams.term.every(t => docIds.has(t))) || !searchParams.dealType)) return null;
  const term = routeParams.term || docIds || config.cityTerm;
  const location = searchDoc && searchDoc.length ? searchDoc[0].reference : null;
  const title = searchDoc && searchDoc.length ? searchDoc[0].name : null;

  const {
    noFee,
    dealType,
    roomsRange,
    bathsRange,
    monthlyTaxRange,
    floorRange,
    areaRange,
    buildingClass,
    sellerType,
    generalCondition,
    priceRange,
    ppmRange,
    amenities,
  } = searchParams;

  return {
    title,
    term: head([ ...term ]),
    locationDocIds: [ ...term ],
    query: {
      location,
      noFee,
      dealType,
      roomsRange,
      bathsRange,
      monthlyTaxRange,
      floorRange,
      areaRange,
      buildingClass,
      sellerType,
      generalCondition,
      priceRange,
      ppmRange,
      amenities,
    },
  };
};

export const addSaveSearchData = (state: State) => baseAddSaveSearchData(state, false);
export const strictAddSaveSearchData = (state: State) => baseAddSaveSearchData(state, true);

const baseAddSaveSearchData = createSelector([
  getSaveSearchRouteParams,
  flow(searchDocEntriesSelector, docs => docs),
  flow(searchParamsSelector, (params) => params.searchParams),
  (_: State, isStrict: boolean) => isStrict,
], saveSearchSelectorResult);

export const homepageWizardSortSelector = createSelector([
  homepageWizardSelector,
], (wizard) => {
  const sort = makeSet(wizard.sort);
  if (wizard.commutePreference.location || wizard.commutePreference.commuteType === 'train') {
    sort.add([ SortField.Commute, SortDirection.Asc ]);
  }
  return sort.values();
});

export const homepageWizardRouteParamsSelector = createSelector(homepageWizardSelector, (wizard) => {
  const { docId, dealType, priceRange, roomsRange } = wizard;
  const routeParams: DecodedRouteParams = {
    term: [ (docId || config.cityTerm) ],
    dealType,
    filters: {
      priceRange: isEmptyRange(priceRange) ? undefined : priceRange,
      roomsRange: isEmptyRange(roomsRange) ? undefined : roomsRange,
      amenities: undefined,
    },
  };
  return routeParams;
});

export const addSaveSearchDataFeed = createSelector([
  homepageWizardRouteParamsSelector,
  homepageWizardSortSelector,
  homepageWizardSelector,
  marketplaceSelector,
], (routeParams, sortValues, wizard, marketplace) => {

  const searchParams = searchParamsSelectorResult(routeParams, [], sortValues, wizard.dealType || undefined, marketplace);

  return saveSearchSelectorResult(routeParams, wizard.address && [ wizard.address ], searchParams.searchParams, false);
});

export const addSaveSearchFromDocument = (document: IAutocompleteEntry, dealType: DealType, marketplace: MarketplaceType) => {
  const routeParams = {
    dealType,
    term: [ document.docId ],  // // single location only [temp]
  };

  const searchParams = searchParamsSelectorResult(routeParams, [], [], dealType, marketplace);

  return saveSearchSelectorResult(routeParams, document && [ document ], searchParams.searchParams, true);
};

export const savedSearchesIdsListSelector = flow(savedSearchesListSelector, (data) => {
  return data.map(val => val.searchId);
});

export const savedSearchDictionarySelector = createSelector([
  savedSearchesListSelector,
], (items) => keyBy(items, (p) => p.searchId));

export const saveSearchByIdSelector: SaveSearchGetter = (state, id) => savedSearchDictionarySelector(state)[id];

export const makeMapStateToPropsFactory = (saveSearchById: SaveSearchGetter) => () => {
  return (state: State, props: any) => {

    const data = saveSearchById(state, props.searchId);
    const {
      notificationsCount,
      updateFrequency,
      searchId,
      title,
      query,
      term,
      locationDocIds,
    } = data;

    return {
      ...props,
      notificationsCount,
      updateFrequency,
      searchId,
      dealType: query.dealType,
      title,
      term,
      locationDocIds,
      // TODO: "MarketplaceType.Residential" will be fixed after the BE part supports the commercial marketplace for saved searches
      filters: formatQueryToFilters(query, MarketplaceType.Residential),
    };
  };
};

export const findSavedSearch = createSelector([
  addSaveSearchData,
  savedSearchesListSelector,
], (newItem, allItems) => allItems && allItems.find((s) => isSearchEqual(s, newItem)));

export const hasSavedSearch = flow(findSavedSearch, (data) => !!data);
