import React, { useState } from 'react';
import Modal from 'ds/components/modal';
import { Tabu } from 'utils/entities';
import TabuLogin from './login/Login';
import TabuPayment from './payment/Payment';
import { TabuSuccess } from './success/Success';
import { TabuFail } from './fail/Fail';
import Feedback from './feedback';
import { FeedbackPopupOptionsValue, FeedbackPopupType } from 'components/common-feedback-popup-view/utils';
import { useLocale } from 'locale';
import { TabuSource } from '../Tabu';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { SetTabuWizardOpen } from '..';


export enum TabuWizardStep {
  Login,
  Payment,
  Success,
  Fail,
  Feedback,
}

interface TabuWizardProps {
  address: string;
  city: string;
  docId: string;
  tabu: Tabu;
  source: TabuSource;
  setWizardOpen: SetTabuWizardOpen;
  setSent: React.Dispatch<React.SetStateAction<boolean>>;
}


const titleTermToStep = {
  [TabuWizardStep.Login]: 'tabu.wizard.login.header',
  [TabuWizardStep.Payment]: 'tabu.wizard.payment.header',
};

const TabuWizard: React.FC<TabuWizardProps> = (props) => {
  const {
    tabu,
    city,
    docId,
    source,
    address,
    setWizardOpen,
    setSent,
  } = props;

  const { t } = useLocale();
  const breakPoint = useScreenBreakpoint();
  const isMobile = breakPoint <= 2;
  const [ tabuStep, setTabuStep ] = useState<TabuWizardStep>(TabuWizardStep.Login);
  const [ name, setName ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const titleTerm = titleTermToStep[tabuStep];
  const title = titleTerm ? t(titleTerm) : ' ';

  const isFeedback = tabuStep === TabuWizardStep.Feedback;
  const handleClose = () => {
    if (isFeedback) {
      setTabuStep(TabuWizardStep.Login);
    }
    else {
      setWizardOpen(false, null);
    }
  };

  if (isFeedback) {
    const feedbackOptionsValues = [
      FeedbackPopupOptionsValue.IncorrectInfo,
      FeedbackPopupOptionsValue.Fraud,
      FeedbackPopupOptionsValue.Other,
    ];

    return (
      <Feedback
        isPopupModalOpen
        onClosePopup={handleClose}
        options={feedbackOptionsValues}
        feedbackType={FeedbackPopupType.Tabu}
        popupTitle={t('reportListing.popup.title')}
        submitButtonText={t('reportListing.popup.button.text')}
      />
    );
  }

  const renderStep = (step: TabuWizardStep) => {
    if (!tabu) return null;

    switch (step) {
      case TabuWizardStep.Login:
        return (
          <TabuLogin
            tabu={tabu}
            name={name}
            email={email}
            source={source}
            setName={setName}
            setEmail={setEmail}
            setStep={setTabuStep}
            address={address}
            city={city}
          />
        );
      case TabuWizardStep.Payment:
        return (
          <TabuPayment
            name={name}
            tabu={tabu}
            email={email}
            docId={docId}
            source={source}
            setStep={setTabuStep}
          />
        );
      case TabuWizardStep.Success:
        setSent(true);
        return <TabuSuccess email={email} />;
      case TabuWizardStep.Fail:
        return <TabuFail setStep={setTabuStep} />;
      case TabuWizardStep.Feedback:
        return null;
    }
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title={isMobile ? title : null}
      headerStyle={{ padding: 0 }}
      footerStyle={{ padding: 0 }}
    >
      {renderStep(tabuStep)}
    </Modal>
  );
};

export default TabuWizard;
