import React, { MutableRefObject } from 'react';
import { connect } from 'react-redux';
import { Mortgage as MortgageForm } from './MortgageForm';
import { Mortgage as MortgageFormProjectPage } from './MortgageFormProjectPage';
import { IProject, ICommercialProject, IBulletin, ICommercialBulletin, IAddressDetails, PoiId } from 'utils/entities';
import { ContactAgentModalSource, ContactModalOpenMeta } from 'store/state/app';
import { IContactAgentValues } from 'helpers/contactFormStore';
import { SubmitContactAgentPayload } from 'store/state/app/payloads';
import { IContactPageSubmitVariables } from 'store/sagas/apiService/types';
import { FullPoiType } from 'components/inner-contact-form/mortgage-form/modeB/index';
import { useRoute, Route } from 'config/routes';
import { hasPostLeadListingsSelector } from 'store/state/domainData/selectors/listingsPostLeadCarousel';
import { State } from 'store/state';
import { currentPoiSelector } from 'store/state/selectors/poi';


export interface MortgageFormProps {
  contactFormRef?: MutableRefObject<HTMLDivElement>;
  isLeadingAgent: boolean;
  description?: string;
  isContacted: boolean;
  poi: IProject | ICommercialProject | IBulletin | ICommercialBulletin;
  isModal: boolean;
  source?: ContactAgentModalSource;
  addressDetails?: IAddressDetails;
  contactFormInitialValues?: IContactAgentValues;
  onContactPageSubmit: (payload: SubmitContactAgentPayload) => void;
  handleSubmitEvent?: (hasErrors: boolean, formValues: IContactPageSubmitVariables) => void;
  onCallClickEvent?: (isVisible: boolean, formValues: IContactPageSubmitVariables) => void;
  onMortgageCheckboxExpose?: () => void;
  onSetContactModalPoiId: (id: PoiId, meta: ContactModalOpenMeta) => void;
  mortgageExpand?: boolean;
  hidePhoneNumber?: boolean;
  onContactAgentInputClick?: (eventName: string, poi: FullPoiType) => void;
  hasListingsCarousel?: boolean;
  currentPoi?: IBulletin | ICommercialBulletin | IProject | ICommercialProject;
}

const MortgageComp: React.FC<MortgageFormProps> = (props) => {
  const { route } = useRoute();
  const { currentPoi, ...rest } = props;

  const relevantRoutes = new Set([ Route.ProjectPage, Route.UnitPage, Route.UnitPageCommercial ]);
  const isNewPopup = relevantRoutes.has(route.name) && currentPoi.id === route.params.id;

  return isNewPopup ? <MortgageFormProjectPage {...rest} /> : <MortgageForm {...rest} />;
};

const mapStateToProps = (state: State) => ({
  hasListingsCarousel: hasPostLeadListingsSelector(state),
  currentPoi: currentPoiSelector(state),
});

export const Mortgage = connect(mapStateToProps)(MortgageComp);
