import { put, call, take } from 'redux-saga/effects';
import { LoadType, MutationType } from 'store/sagas/apiService/types';
import { State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { mutate } from 'store/state/mutationsResponse/actions';
import { setAuthModal } from 'store/state/app/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import { mutateWorker } from 'store/sagas/apiService/mutationsWatcher';
import { resetDomainData } from 'store/state/domainData/actions';
import { createMutationResponsePattern } from 'utils/createMutationResponsePattern';


const resetPasswordUpdatePattern = createMutationResponsePattern(MutationType.ResetPasswordUpdate);

export function* agentLoginFlow(route: RouteState) {
  yield put(resetDomainData({ loadType: LoadType.CurrentUser }));
  yield put(setAuthModal({ type: AuthenticationModalType.AgentSetPassword }, { isUserInitiated: false }));
  yield put(navigateTo(route.name, { ...route.params, token: undefined, agentLoginToken: undefined }, { replace: true }));

  yield take(resetPasswordUpdatePattern);

  yield call(mutateWorker, mutate({
    mutationType: MutationType.TempTokenAuth,
    meta: {
      variables: {
        loginToken: route.params.agentLoginToken,
      },
    },
  }));
}
