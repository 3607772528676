import React from 'react';
import { useScreenBreakpoint } from 'consts/breakpoints';
import Sidebar from './Sidebar';
import UserMobileMoreMenu  from './UserMobileMoreMenu';
import { SidebarOverlay, SidebarWrapper } from './styled';
import { useTransition, animated } from 'react-spring';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { STICKY_ROOT } from 'consts/rootNodes';
import { createPortal } from 'utils/universalPortal';

const AnimatedSidebarWrapper = animated(SidebarWrapper);

interface MoreActionsMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({
  isOpen,
  onClose,
}) => {
  const range = useScreenBreakpoint();
  const transitions = useTransition(isOpen, null, {
    from: { v: 0 },
    enter: { v: 1 },
    leave: { v: 0 },
  });

  if (!isOpen) return null;

  const isDesktop = range > 2;
  const content = isDesktop
    ? <Sidebar onClose={onClose} />
    : <UserMobileMoreMenu onClose={onClose} />;

  return isDesktop ? (
    <>
      <OverlayWrapper>
        <SidebarOverlay onClick={onClose} />
      </OverlayWrapper>
      {createPortal(
        transitions.map(({ item, key, props }) => item ? (
          <AnimatedSidebarWrapper
            isOpen={isOpen}
            key={key}
            style={{
              transform: props.v.to(a => `translateX(${(1 - a) * 100 * -1}%)`),
            }}
          >
            {content}
          </AnimatedSidebarWrapper>
        ) : null),
        STICKY_ROOT
      )}
    </>
  ) : content;
};
