import React from 'react';

import Follow from 'assets/svg/follow-negative.svg';
import FollowThin from 'assets/svg/follow-thin.svg';
import FollowedThin from 'assets/svg/followed-thin.svg';
import Followed from 'assets/svg/follow-negative-filed.svg';

import { useLocale } from 'locale';
import { IAutocompleteAddress } from 'utils/entities';
import { ISavedAddressToggle, ToggleAddressMeta, ToggleAddressTriggers } from 'store/sagas/apiService/types';
import { Text } from 'ds/components/typography';
import { Root } from './styled';
import { ControlButton } from 'ds/components/button';

interface ToggleAddressButtonProps {
  resolvedAddress: IAutocompleteAddress;
  toggleAddress: (props: ISavedAddressToggle, meta: ToggleAddressMeta) => void;
  isSaved: boolean;
  onlyIcon?: boolean;
  thinIcon?: boolean;
}

const initialSavedAddressSettings = {
  insightsUpdates: true,
  isHome: false,
  newListingUpdates: true,
};

export const ToggleAddressButton: React.FC<ToggleAddressButtonProps> = props => {
  const { isSaved, toggleAddress, resolvedAddress, onlyIcon = false, thinIcon } = props;
  const { t } = useLocale();
  const handleBtnClick = (source: ToggleAddressTriggers) => {
    return () => {
      const { docId } = resolvedAddress;
      toggleAddress({
        docId,
        isSaved,
        settings: initialSavedAddressSettings,
      }, { source });
    };
  };

  const followedIcon = thinIcon ? <FollowedThin /> : <Followed />;
  const followIcon = thinIcon ? <FollowThin /> : <Follow />;
  const icon = isSaved ? followedIcon : followIcon;

  return onlyIcon ? (
    <Root data-auto="header-follow-button" data-auto-active={isSaved} onClick={handleBtnClick(null)}>
      {icon}
    </Root>
  ) : (
      <Root>
        <ControlButton
          data-auto="address-gallery-follow-button"
          data-auto-active={isSaved}
          onClick={handleBtnClick(null)}
          size="large"
          fontType="important"
        >
          {icon}
          <Text weight="medium">{t('addressPage.gallery.follow', { isSaved })}</Text>
        </ControlButton>
      </Root>
    );
};
