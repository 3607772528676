import { InsightType } from 'utils/entities';
import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';

interface IIconWrapper {
  type: InsightType;
}

interface ITitle {
  type?: InsightType;
}

const noTransparancyInsightType = new Set([
  InsightType.FuturePlans,
  InsightType.DivisionAreaPlan,
]);

export const IconWrapper = styled.div<IIconWrapper>`
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ type = InsightType.Transportation , theme }) => `background:  ${noTransparancyInsightType.has(type) ? theme.colors.insights[type] : colorToAlpha(theme.colors.insights[type], 0.12)};`}
  ${props => props.theme.isRTL ? 'margin-left: 12px;' : 'margin-right: 12px;'};
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin: 0 ${theme.spacing(3)} ${theme.spacing(1)} 0;
  `}
`;

export const Title = styled.div<ITitle>`
  display: flex;
  align-items: center;
  color: ${({ type = InsightType.Transportation, theme }) => theme.colors.insights[type]};
  svg, svg path {
    fill: ${({ type = InsightType.Transportation, theme }) => theme.colors.insights[type]};
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  `}
`;

export const WidgetTitleWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(6)};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin-bottom: ${theme.spacing(5)};
  `}
`;
