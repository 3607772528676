import { takeEvery, getContext, select, call } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import { Route, State as RouteState } from 'config/routes';
import { getRouteParams, prevRouteSelector } from 'store/state/selectors/router';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from '../apiService/types';
import { developerSelector } from 'screens/DeveloperPage/selectors';
import { IDeveloperProfile } from 'utils/entities';
import { TRANSITION_SUCCESS } from 'store/state/router/types';


const developerPagePattern = (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.DeveloperById)
  || (action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.DeveloperPage)
);

function* openDeveloperPageWorker() {
  const { sendEvent } = yield getContext('analytics');
  const prevRoute: RouteState | null = yield select(prevRouteSelector);
  const { source: paramsSource } = yield select(getRouteParams);
  const developer: IDeveloperProfile = yield select(developerSelector);

  let source;

  switch (true) {
    case prevRoute && prevRoute.name === Route.ProjectPage:
      source = 'project_page';
      break;
    case prevRoute && prevRoute.name === Route.DevelopersSearchPage:
      source = 'developer_index';
      break;
    case paramsSource === 'article':
      source = 'article';
      break;
    case !prevRoute:
      source = 'direct';
      break;

    default:
      source = null;
  }

  if (source && developer) {
    yield call(sendEvent, 'developer_page_view', 'developer', {
      event: {
        source,
      },
      developer: {
        id: developer.id,
        name: developer.name,
      },
    });
  }
}

export function* developerPageWorker() {
  yield takeEvery(developerPagePattern, openDeveloperPageWorker);
}
