import React, { useState, useRef, useContext, useEffect } from 'react';
import { isServer } from 'utils';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';


const HydrationContext = React.createContext<React.MutableRefObject<boolean>>({ current: false });

/**
 * @todo check if context is hydration
 */
export function withProgressiveHydration<T>(Comp: React.ComponentType<T>, tagName: keyof JSX.IntrinsicElements) {
  const Wrapped: React.FC<T> = (props) => {
    const isHydrating = useContext(HydrationContext);
    const [ hydrated, setHydrated ] = useState(!isHydrating.current);
    const containerRef = useRef<HTMLElement>(null);
    useIntersectionObserver(([ e ]) => {
      if (e.isIntersecting && !hydrated) setHydrated(true);
    }, [ containerRef ]);

    const content = <Comp {...props} />;

    return (hydrated || isServer())
      ? content
      : React.createElement(tagName, { ref: containerRef, dangerouslySetInnerHTML: { __html: '' }, suppressHydrationWarning: true });
  };

  Wrapped.displayName = `withProgressiveHydration(${Comp.displayName})`;

  return Wrapped;
}

export const HydrationProvider: React.FC<{ hydrating: boolean }> = (props) => {
  const isHydrating = useRef(props.hydrating);

  useEffect(() => {
    isHydrating.current = false;
  }, [ isHydrating ]);

  return (
    <HydrationContext.Provider value={isHydrating}>
      {props.children}
    </HydrationContext.Provider>
  );
};
