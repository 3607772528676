import React from 'react';
import { connect } from 'react-redux';
import { deleteSaveSearch, saveSearch } from 'store/state/app/actions';
import { useLocale } from 'locale';

import { UpdateFrequency } from '../config';
import { ToggleField, LabelTextMode } from 'ds/components/input/ToggleField';
import { SaveSearchButtonWrapper } from './styled';
import { State } from 'store/state';
import { addSaveSearchData, findSavedSearch, hasSavedSearch } from 'store/state/selectors/savedSearchPage';
import { isSearchListLoadingSelector } from 'store/state/domainData/selectors';
import SaveSearchMobileButton from './SaveSearchMobileButton';


interface SaveSearchButtonProps {
  onChange: (saveSilently?: boolean) => void;
  disabled: boolean;
  isToggled: boolean;
  reversed?: boolean;
  label?: string;
  isButton?: boolean;
  labelTextMode?: LabelTextMode;
  noLabel?: boolean;
  noMargin?: boolean;
  isLowerDynamicButton?: boolean;
}

const SaveSearchButton: React.FC<SaveSearchButtonProps> = ({
  onChange,
  disabled,
  isButton,
  isToggled,
  reversed,
  label,
  labelTextMode,
  noLabel,
  noMargin,
}) => {
  const { t } = useLocale();

  if (isButton) return <SaveSearchMobileButton isSaved={isToggled} onClick={onChange} disabled={disabled} />;

  const onToggleChange = () => onChange();

  return (
    <SaveSearchButtonWrapper data-auto="save-search-button-wrapper" data-auto-active={isToggled} noMargin={noMargin}>
      <ToggleField
        label={noLabel ? null : label || t('savedSearch.saveButton.labelDefault')}
        disabled={disabled}
        checked={isToggled}
        onChange={onToggleChange}
        reversed={reversed}
        labelTextMode={labelTextMode}
      />
    </SaveSearchButtonWrapper>
  );
};

const mapStateToProps = (state: State) => ({
  saveData: addSaveSearchData(state),
  disabled: isSearchListLoadingSelector(state),
  isToggled: hasSavedSearch(state),
  maybeSaved: findSavedSearch(state),
});

const mapDispatchToProps = {
  onSaveSearch: saveSearch,
  onDeleteSaveSearch: deleteSaveSearch,
};

type SaveSearchButtonOwnProps = Pick<SaveSearchButtonProps, 'isButton' | 'reversed' | 'label' | 'labelTextMode' | 'noLabel' | 'noMargin' | 'isLowerDynamicButton'>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ({ maybeSaved, saveData, ...stateProps }, dispatchProps, ownProps: SaveSearchButtonOwnProps): SaveSearchButtonProps => ({
    onChange: (saveSilently?: boolean) => {
      if (stateProps.isToggled) {
        dispatchProps.onDeleteSaveSearch(maybeSaved.searchId, { source: 'search_result_page' });
      }
      else {
        dispatchProps.onSaveSearch({
          ...saveData,
          updateFrequency: UpdateFrequency.Daily,
        }, { source: ownProps.isLowerDynamicButton ? 'lower_dynamic_button' : 'top_fixed_button', saveSilently });
      }
    },
    ...stateProps,
    ...ownProps,
  })
)(SaveSearchButton);
