import React from 'react';
import { ShareButton, ShareButtonSource } from './ShareButton';
import { useLocale } from 'locale';
import {
  IAutocompleteAddress,
  IBulletin,
  IProject,
  IDeal,
} from 'utils/entities';
import { State } from 'store/state';
import { connect } from 'react-redux';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { SmallText } from 'ds/components/typography';
import Tooltip from 'ds/components/tooltip';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { shareHandler, ShareType, clearParamsBeforeShare } from 'helpers/share-helpers';
import { Mode } from 'ds/components/button/ControlButton';

interface ShareProps {
  addressDocEntry: IAutocompleteAddress;
  poi: IBulletin | IProject | IDeal;
  tooltip?: boolean;
  copyText?: string;
  url?: string;
  isRound?: boolean;
  alignment?: 'right' | 'left';
  source?: ShareButtonSource;
  controlButtonMode?: Mode;
  menuZIndex?: number;
}

const Share: React.FC<ShareProps> = props => {
  const { children, addressDocEntry, poi, tooltip = true, copyText, url, isRound, alignment, source, controlButtonMode, menuZIndex } = props;
  const { t } = useLocale();

  const shouldResetSelectedValue = () => true;
  const shouldStayOpen = (value: ShareType) => value === ShareType.CopyLink;

  const handleShare = (type: ShareType) => {
    const clearUrl = clearParamsBeforeShare(url || window.location.href);
    shareHandler({ type, url: clearUrl, shareViaEmailOptions: { poi, addressDocEntry, t, url: clearUrl } });
  };


  return (
    <div>
      {tooltip ? (
        <Tooltip
          size="small"
          placement="bottom"
          tooltip={<SmallText>{t('tooltip.share')}</SmallText>}
          hideArrow
        >
          <ShareButton
            shouldResetSelectedValue={shouldResetSelectedValue}
            shouldStayOpen={shouldStayOpen}
            handleShare={handleShare}
            copyText={copyText}
            isRound={isRound}
            source={source}
            controlButtonMode={controlButtonMode}
            menuZIndex={menuZIndex}
          >
            {children}
          </ShareButton>
        </Tooltip>
      ) : (
        <ShareButton
          abTest
          shouldResetSelectedValue={shouldResetSelectedValue}
          shouldStayOpen={shouldStayOpen}
          handleShare={handleShare}
          copyText={copyText}
          isRound={isRound}
          alignment={alignment}
          source={source}
          controlButtonMode={controlButtonMode}
          menuZIndex={menuZIndex}
        >
          {children}
        </ShareButton>
      )}
    </div>
  );
};

const makeCurrentPoiSelector = () => currentPoiSelector;

const mapStateToProps = () => {
  const poiSelector = makeCurrentPoiSelector();
  return (state: State, ownProps: { poi?: IBulletin | IProject }) => ({
    addressDocEntry: addressDocEntrySelector(state),
    poi: ownProps.poi || poiSelector(state),
  });
};

export default connect(mapStateToProps)(Share);
