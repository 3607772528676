import { getContext, takeEvery, call, select } from 'redux-saga/effects';
import { NEW_LISTING } from 'store/state/uploadBulletin/types';
import { RootAction } from 'store/state';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { getRouteParams } from 'store/state/selectors/router';

function* createListingWatcher() {
  const { sendEvent } = yield getContext('analytics');
  const { source } = yield select(getRouteParams);

  yield call(sendEvent, 'bulletin_upload_wizard_submit', 'bulletin_upload_wizard', {
    event: {
      source: source || 'other',
    },
  });
}

const openUploadBulletinPagePattern = (action: RootAction) => (
  (action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.UploadBulletin
    && (!action.payload.previousRoute || action.payload.previousRoute.name !== Route.UploadBulletin))
);

function* openUploadBulletinPageWatcher() {
  const { sendEvent } = yield getContext('analytics');
  const { source } = yield select(getRouteParams);

  yield call(sendEvent, 'bulletin_upload_wizard_open', 'bulletin_upload_wizard', {
    event: {
      source: source || 'other',
    },
  });
}

export function* uploadBulletinEventsWorker() {
  yield takeEvery(NEW_LISTING, createListingWatcher);
  yield takeEvery(openUploadBulletinPagePattern, openUploadBulletinPageWatcher);
}
