import { call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import { User } from 'utils/entities';
import { waitForUserChange } from '../routing/handlers/utils';
import { userProfileSelector } from 'store/state/domainData/selectors';
import { isFullStoryInitialized } from 'analytics/fullStory';
import { RegisterType } from 'utils/entities';

let identityConnected = false;

export function* userLoginChangeWorker() {
  while (true) {
    yield call(waitForUserChange);
    const fullStory = (window as any).FS;
    if (isFullStoryInitialized() && fullStory && fullStory.identify) {
      const user: User = yield select(userProfileSelector);
      if (user && user.uid && user.email && user.registrationType !== RegisterType.Visitor && !identityConnected) {
        identityConnected = true;
        fullStory.identify(user.uid, {
          uid: user.uid,
          email: user.email,
        });
      }
      else if (identityConnected) {
        identityConnected = false;
        fullStory.identify(false);
      }
    }
  }
}
