import React, { useMemo } from 'react';
import FilterSection from 'components/filters/common/DropdownSection';
import { useLocale } from 'locale';
import { FiltersSingleComponentsMap } from 'components/filters/config';
import { GenericFilterProps, GroupFilterProps } from 'components/filters/types';
import { ShowAt } from 'consts/breakpoints';
import { PriceFiltersWrapper } from 'components/filters/styled/common';


const Price: React.FC<GroupFilterProps> = (props) => {
  const {
    getProps,
    commitValues,
    isFilterActive,
    filters,
    marketplace,
    commitedValues,
    intermediateValues,
    makeMultipleResetValue,
    config,
  } = props;

  const { t } = useLocale();
  const filtersAliases = useMemo(() => filters.map(({ filterType }) => filterType), [ filters ]);

  const reset = () => makeMultipleResetValue(filtersAliases);
  const isActive = isFilterActive(filtersAliases, commitedValues);

  return (
    <PriceFiltersWrapper>
      <FilterSection
        isClearButtonDisabled={!isActive}
        type={'priceRange'}
        ignoreWrapperPadding
        icon={config.filtersIcons.price}
        onClear={reset}
        label={t('filters.priceTitle')}
        onSubmit={commitValues}
        isActive={isActive}
      >
        <ShowAt to={2}>
          {isMobile => filters.map(({ filterType, isInline }) => {
            const Component: React.ComponentType<GenericFilterProps<typeof filterType>> = FiltersSingleComponentsMap[marketplace][filterType];
            return <Component key={filterType} {...getProps(filterType, intermediateValues, commitedValues)} isInline={isInline} isMobile={isMobile} />;
          })}
        </ShowAt>
      </FilterSection>
    </PriceFiltersWrapper>
  );
};

export default Price;
