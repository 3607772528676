import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { DealAffiliationStatus } from 'utils/entities';
import { typographyFontSizeCfg } from 'ds/components/typography/types';

export const Label = styled(Text)<{ status: DealAffiliationStatus }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(1)};
  justify-content: center;
  align-items: center;
  font-size: ${typographyFontSizeCfg.textLabel.small}px;
  font-weight: 700;
  ${({ status }) => `
    ${status === DealAffiliationStatus.Pending ? `
      color: #EC8537;
      background: #FDF2DC;
    ` : ''}
    ${status === DealAffiliationStatus.Approved ? `
      color: #0EAA5E;
      background: #EFF9F0;
    ` : ''}
    ${status === DealAffiliationStatus.Rejected ? `
      color: #DA3939;
      background: #F9F0F0;
    ` : ''}
  `}

  gap: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(0.75)};;
`;
