import React, { useRef } from 'react';
import { SearchTypeSelectWrapper, SearchTypeSelectLabel, Separator, SearchBlockRoot } from 'components/check-address/styled';
import { State as RouteState, NavigateToFn  } from 'config/routes';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { useLocale } from 'locale';
import LocationIcon from 'assets/svg/location-gallery.svg';
import Autocomplete from 'components/check-address/search-block/Autocomplete';
import DropdownList from 'components/check-address/search-block/DropdownList';
import { Area, MarketplaceType } from 'utils/entities';

interface SearchBlockProps {
  route: RouteState;
  areasWithCommercialInfoPage: Area[];
  isCommercial: boolean;
  navigate: NavigateToFn;
}


const SearchBlock: React.FC<SearchBlockProps> = ({ route, areasWithCommercialInfoPage, isCommercial, navigate }) => {
  const { t } = useLocale();
  const inputRef = useRef(null);
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobileOrTablet = mediaBreakpoint <= 2;
  const isMobile = mediaBreakpoint === 1;

  const handleTypeSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const marketplace = e.currentTarget.value as MarketplaceType;
    navigate(route.name, { ...route.params, marketplace }, { replace: true });
  };

  const selectItems = areasWithCommercialInfoPage && areasWithCommercialInfoPage.map(area => ({
    value: area.docId,
    label: area.areaName,
    icon: <LocationIcon />,
  }));

  return (
    <SearchBlockRoot ref={inputRef}>
      <SearchTypeSelectWrapper>
        {isMobileOrTablet ? null : (
          <SearchTypeSelectLabel>
            {t('checkAddress.autocomplete.typeSelect.label')}
          </SearchTypeSelectLabel>
        )}
        <input
          name="typeSelect"
          type="radio"
          id="typeSelectOption1"
          checked={!isCommercial}
          value={MarketplaceType.Residential}
          onChange={handleTypeSelect}
        />
        <label htmlFor="typeSelectOption1">
          {t('checkAddress.autocomplete.typeSelect.option1Label')}
        </label>
        <Separator>/</Separator>
        <input
          name="typeSelect"
          type="radio"
          id="typeSelectOption2"
          checked={isCommercial}
          value={MarketplaceType.Commercial}
          onChange={handleTypeSelect}
        />
        <label htmlFor="typeSelectOption2">
          {t('checkAddress.autocomplete.typeSelect.option2Label')}
        </label>
      </SearchTypeSelectWrapper>
        { isCommercial ?
          <DropdownList
            isMobileOrTablet={isMobileOrTablet}
            isMobile={isMobile}
            items={selectItems}
            navigate={navigate}
          /> :
          <Autocomplete
            isMobileOrTablet={isMobileOrTablet}
            isMobile={isMobile}
            route={route}
            navigate={navigate}
          />
        }
    </SearchBlockRoot>
  );
};

export default SearchBlock;
