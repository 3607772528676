import React from 'react';


type XYValue = [ number, number ];

interface ScrollRestorerProps {
  restore: boolean;
}

export class ScrollRestorer extends React.PureComponent<ScrollRestorerProps, never> {
  private lastScroll: XYValue = null;

  public getSnapshotBeforeUpdate(): XYValue {
    return [ window.scrollX, window.scrollY ];
  }

  public componentDidUpdate(prevProps: ScrollRestorerProps, _: never, snapshot: XYValue) {
    if (!prevProps.restore && this.props.restore && this.lastScroll) {
      window.scrollTo(...this.lastScroll);
    }
    if (prevProps.restore && !this.props.restore) {
      this.lastScroll = snapshot;
    }
  }

  public render(): React.ReactNode {
    return null;
  }
}
