import { createSelector } from 'reselect';
import { head, groupBy } from 'lodash';
import {
  makeNullSafeDomainGetter,
  searchAgentAwardsByIds,
} from 'store/state/domainData/selectors/common';
import { LeadingAgentData, LeadingAgentFeature } from 'utils/entities';
import { checkEnabledLeadingAgentFeature } from 'helpers/contact';


export const searchAgentAwardsByIdsSelector = makeNullSafeDomainGetter(searchAgentAwardsByIds, 'searchAwardsByIds');


export const leadingAgentsMap = createSelector([
  searchAgentAwardsByIdsSelector,
], (awards) => {
  if (awards && awards.length) {
    return groupBy(awards, (award) => {
      const isEnabled = checkEnabledLeadingAgentFeature(award, LeadingAgentFeature.LeadingAgentLabelOnOfficePage);
      return isEnabled && award && award.id ? award.id : null;
    });
  }
  return {};
});

export const leadingAgentAwardDataSelector = createSelector([
  searchAgentAwardsByIdsSelector,
], (awards): LeadingAgentData => {
  const award = head(awards);

  if (award) {
    const hoodMap = groupBy(award.neighborhoods, (hood) => hood && hood.ref ? hood.ref.docId : null);
    return {
      id: award.id,
      isLeadingAgent: true,
      award,
      leadingStatement: award.leadingStatement,
      neighborhoods: (award.docIds || []).reduce((acc, docId) => {
        if (hoodMap[docId]) acc.push(head(hoodMap[docId]).ref.name);
        return acc;
      }, []),
    };
  }
  return {
    isLeadingAgent: false,
  };
});
