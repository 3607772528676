import { fork, all, setContext } from 'redux-saga/effects';
import { userWatcher } from './userWatcher';
import { routingWatcher } from './routing';
import { analyticsWatcher } from './analytics';
import { contactPageWatcher } from './contactPageWatcher';
import { homepageWatcher } from './homepageWatcher';
import { listingCardWatcher } from './listingCardWatcher';
import { apiServiceWorker } from './apiService';
import { saveSearchWatcher } from './saveSearchWatcher';
import { viewedPoiWatcher } from './viewedPoiPersistance';
import { toggleAddressWatcher } from 'store/sagas/toggleAddressWatcher';
import { ugcReviewWizardWatcher } from './ugcWizardWatcher';
import { addEditBulletinWatcher } from './addEditBulletinWatcher';
import { busLineWatcher } from './busLineWatcher';
import { toggleFavoritesWatcher } from 'store/sagas/favoritesWatcher';
import { fetchFavoritesWatcher } from 'store/sagas/fetchFavoritesWatcher';
import { reactNativeWatcher } from 'react-native/sagas';
import { searchViewWatcher } from 'store/sagas/searchViewWatcher';
import { mobileDiscoveryPopupWatcher } from './mobileDiscoveryWatcher';
import { pushNotificationsModalWatcher } from './pushNotificationsModalWatcher';
import { pricesWatcher } from 'store/sagas/pricesWatcher';

export default function* rootSaga(logger = console) {
  yield setContext({ logger });
  yield all([
    fork(viewedPoiWatcher),
    fork(userWatcher),
    fork(routingWatcher),
    fork(analyticsWatcher),
    fork(contactPageWatcher),
    fork(homepageWatcher),
    fork(listingCardWatcher),
    fork(apiServiceWorker),
    fork(toggleAddressWatcher),
    fork(saveSearchWatcher),
    fork(ugcReviewWizardWatcher),
    fork(addEditBulletinWatcher),
    fork(busLineWatcher),
    fork(toggleFavoritesWatcher),
    fork(fetchFavoritesWatcher),
    fork(reactNativeWatcher),
    fork(searchViewWatcher),
    fork(mobileDiscoveryPopupWatcher),
    fork(pushNotificationsModalWatcher),
    fork(pricesWatcher),
  ]);
}
