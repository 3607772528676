import React from 'react';
import {
  CollapsedArrow,
  CollapsedContent,
  CollapsedHeader,
  DynamicContent,
  HeaderBackground,
  HeaderContent,
  Title,
} from './styled';
import IlNeighbourhoodLifeIcon from 'assets/svg/area-highlights/everyday-life.svg';
import NeighborsIcon from 'assets/svg/insight-card/neighbours.svg';
import Arrow from 'assets/svg/chevron-down.svg';
import { ShowAt } from 'consts/breakpoints';
import { RootAction, State } from 'store/state';
import { openedInsightSectionsSelector } from 'store/state/insightsContext/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { collapseInsightSection } from 'store/state/insightsContext/actions';
import withEvents from 'analytics/withEvents';
import { ANALYTICS_MAPPING } from '../navigation/subheader/SectionsNavigation';
import { InsightSection } from 'store/state/insightsContext';
import { InsightType } from 'utils/entities';
import { IconWrapper } from 'components/widget/common/widget-title/styled';
import { insightTypeToIcon } from 'helpers/insight';


const insightSectionToIcon: Partial<Record<InsightSection, JSX.Element>> = {
  [InsightSection.Neighbors]: <NeighborsIcon />,
  [InsightSection.NeighbourhoodLife]: <IlNeighbourhoodLifeIcon />,
};

interface CollapsedInsightWidget {
  type: InsightSection;
  styleType: InsightType;
  dynamic: DynamicContentResult;
  setOpenSection: (type: InsightSection) => void;
  openedSections: InsightSection[];
  title: string;
  onWidgetCollapsed?: (type: string, isOpen: boolean) => void;
}

export interface DynamicContentResult {
  toRender: JSX.Element;
  withContent: boolean;
}

const CollapsedInsightWidgetNotConnected: React.FC<CollapsedInsightWidget> = ({ children, type, dynamic, setOpenSection, openedSections, title, onWidgetCollapsed, styleType }) => {

  if (dynamic && !dynamic.withContent) return null;

  const isOpened = openedSections.indexOf(type) !== -1;

  const handleClick = () => {
    if (dynamic && dynamic.withContent) {
      onWidgetCollapsed(ANALYTICS_MAPPING[type], !isOpened);
      setOpenSection(type);
    }
  };

  const icon = insightSectionToIcon[type] || insightTypeToIcon[styleType];

  return (
    <div>
      <HeaderBackground onClick={handleClick}>
        <CollapsedHeader opened={isOpened}>
          <IconWrapper type={styleType}>
            {icon}
          </IconWrapper>
          <HeaderContent>
            <Title weight="bold">{title}</Title>
            {dynamic && dynamic.toRender ? (
              <ShowAt at={1}>
                {(isMobile) => {
                  if (isMobile) {
                    return <DynamicContent type={type}>{dynamic.toRender}</DynamicContent>;
                  }
                  else {
                    return isOpened ? null : <DynamicContent type={type}>{dynamic.toRender}</DynamicContent>;
                  }
                }}
              </ShowAt>
            ) : null}
          </HeaderContent>
          <CollapsedArrow opened={isOpened} isVisible={!!(dynamic && dynamic.withContent)}>
            <Arrow />
          </CollapsedArrow>
        </CollapsedHeader>
      </HeaderBackground>
      {isOpened ? <CollapsedContent>{children}</CollapsedContent> : null}
    </div>
  );
};

const CollapsedInsightWidgetWithEvents = withEvents<CollapsedInsightWidget>((sendEvent, props) => ({
  onWidgetCollapsed: (type: string, isOpen: boolean) => {
    sendEvent('property_collapsed_section_click', 'property', {
      event: {
        property_page_sections: type,
        action: isOpen ? 'expand' : 'collapse',
      },
    });
  },
}))(CollapsedInsightWidgetNotConnected);

const mapStateToProps = (state: State) => ({
  openedSections: openedInsightSectionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  setOpenSection: (type: InsightSection) => dispatch(collapseInsightSection(type)),
});

export const CollapsedInsightWidget = connect(mapStateToProps, mapDispatchToProps)(CollapsedInsightWidgetWithEvents);
