import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { setDomainData } from 'store/state/domainData/actions';
import { isServer } from 'utils';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { RootAction } from 'store/state';
import { FETCH_MORE } from 'store/state/domainData/types';
import { tabuByDocIdVariablesSelector } from 'store/state/domainData/selectors/tabuByDocId';


function* setTabuDomainData() {
  const variables = yield select(tabuByDocIdVariablesSelector);
  yield put(setDomainData({
    loadType: LoadType.Tabu,
    payload: {
      networkStatus: 1,
      stale: false,
      data: null,
      loading: true,
    },
    meta: {
      variables,
    },
  }));
}

const fetchMoreTabuPattern = (action: RootAction) => (
  action.type === FETCH_MORE && action.loadType === LoadType.Tabu
);

export function* resetTabuDomainDataHandler() {
  yield takeEvery(fetchMoreTabuPattern, setTabuDomainData);
}

export function* tabuByDocIdHandler(docId: string) {
  const ssr = yield call(isServer);

  if (ssr || !docId) return;

  const meta: LoadOptions<LoadType.Tabu>['meta'] = { variables: { docId, timestamp: Date.now() } };

  try {
    const loadOptions: LoadOptions<LoadType.Tabu> = {
      loadType: LoadType.Tabu,
      meta,
    };

    yield call(queryData, loadOptions);
  }
  catch (e) {
    yield put(setDomainData({
      loadType: LoadType.Tabu,
      payload: {
        networkStatus: 1,
        stale: false,
        loading: false,
        errors: [ new GraphQLError('nginxTimeOut') ],
        data: {
          docId2NesachTabu: null,
        },
      },
      meta,
    }));
    const logger = yield getContext('logger');
    logger.error(`Tabu by docId query failed, docId - ${docId}`, e);
    return;
  }
  yield call(resetTabuDomainDataHandler);
}
