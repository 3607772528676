import styled from '@emotion/styled';


export const BaseRoot = styled.div`
  ${({ theme }) => `
    max-width: 338px;
    margin: 0 auto;
    padding: 0 ${theme.spacing(1)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    max-width: 100%;
    padding: 0;
  `}
`;
