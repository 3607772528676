import { insightsLoadingSelector, makeInsightsByTypeSelector } from 'store/state/domainData/selectors';
import { InsightDescriptor } from 'utils/entities';
import { flow, head } from 'lodash';
import { createSelector } from 'reselect';

import { IQuietStreet } from './summaryTypes';


export const quietnessValueSelector = flow(
  makeInsightsByTypeSelector(InsightDescriptor.QuietStreet),
  head,
  (insight) => insight ? (insight) : null
);

const nonLoadingInsightSelector = createSelector([
  quietnessValueSelector,
  insightsLoadingSelector,
], (insight, isLoading) => {
  if (!insight || isLoading) return null;
  return insight;
});

export const quietStreetsDataSelector = flow(nonLoadingInsightSelector, (insight) => insight && insight.summary.nonText.data as IQuietStreet);
export const quietStreetsPreviewSelector = flow(nonLoadingInsightSelector, (insight) => insight && insight.preview);
export const quietStreetsIdSelector = flow(nonLoadingInsightSelector, (insight) => insight && insight.id);
export const hasQuietStreetsDataSelector = flow(nonLoadingInsightSelector, (insight) => Boolean(insight));
