import { createSelector } from 'reselect';
import { poiSearchProjectListSelector } from 'store/state/domainData/selectors';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { MutationType } from 'store/sagas/apiService/types';

export const projectsSelector = createSelector([
  poiSearchProjectListSelector,
  currentPoiSelector,
  state => state.mutationsResponse[MutationType.ContactPageSubmit],
], (searchProjects, currentPoi, contactedMap) => {
  const currentPoiId = currentPoi ? currentPoi.id : null;

  return searchProjects.filter(poi => {
    const isContacted = Boolean(contactedMap && contactedMap[poi.id] && contactedMap[poi.id].data && contactedMap[poi.id].data.contactV4);
    return poi.id !== currentPoiId && !isContacted;
  });
});
