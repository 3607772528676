import React, { FC, useState } from 'react';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { IntersectionSpy } from 'components/intersection-spy';
import { Header } from './common/Header';
import { MobileOverlayContainer, ControlsFooter, Wrapper, controlsFooterHeight } from './styled/common';
import { CleanButton } from 'ds/components/button';
import { withLocale, WithLocaleProps } from 'locale';
import { makeCallAll } from './utils';


interface MobileExpandedWrapperProps extends WithLocaleProps {
  onClose: () => void;
  onCommit: () => void;
  onReset: () => void;
}

const intersectionOptions = {
  threshold: [ 0, 1 ],
  rootMargin: `-${controlsFooterHeight}`,
};

const MobileExpandedWrapperUnwrapped: FC<MobileExpandedWrapperProps> = props => {
  const { children, t, onClose, onReset, onCommit } = props;

  const [ hasBottomScrollShadow, setBottomScrollShadow ] = useState(false);

  const handleBottomIntersectionSpy = (entry: IntersectionObserverEntry) => {
    setBottomScrollShadow(!entry.isIntersecting);
  };

  return (
    <OverlayWrapper data-auto="mobile-filter-popup">
      <Wrapper>
        <Header onClose={onClose}>
          {t('filters.filters')}
        </Header>
        <MobileOverlayContainer>
          {children}
          <IntersectionSpy onIntersection={handleBottomIntersectionSpy} intersectionOptions={intersectionOptions} />
          <ControlsFooter hasScrollShadow={hasBottomScrollShadow}>
            <CleanButton data-auto="filterClearButton" mode="secondary" onClick={makeCallAll(onReset)}>
              {t('filters.clearAll')}
            </CleanButton>
            <CleanButton data-auto="filterSubmitButton" onClick={makeCallAll(onCommit, onClose)}>
              {t('filters.apply')}
            </CleanButton>
          </ControlsFooter>
        </MobileOverlayContainer>
      </Wrapper>
    </OverlayWrapper>
  );
};

export const MobileExpandedWrapper = withLocale(MobileExpandedWrapperUnwrapped);
