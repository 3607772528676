import styled from '@emotion/styled';
import { Text, H3 } from 'ds/components/typography';
import { ModalRoot as ModalRootBase } from '../styled';

export const SuccessSentMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    text-align: center;
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin: -${theme.spacing(4)} -${theme.spacing(4)} 0 -${theme.spacing(4)};
    background: ${theme.colors.neutrals.grey9};
    height: 106px;

    > svg {
      margin-left: ${theme.spacing(2)};
    }
  `}
`;

export const SuccessHeader = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    max-width: 244px;
  `}
`;

export const SuccessHeaderName = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.brand.main};
    font-weight: 500;
  `}
`;

export const SentContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    color: ${theme.colors.neutrals.black};
    text-align: center;
    gap: ${theme.spacing(1)};
  `}
`;

export const ProjectsHeader = styled(H3.withComponent('div'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-top: ${theme.spacing(2)};
    margin-bottom: -${theme.spacing(2)};
  `}
`;

export const ProjectsCarouselWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin: 0 -${theme.spacing(4)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin: 0 -${theme.spacing(3)} ${theme.spacing(4)};
  `}
`;

export const ModalRoot = styled(ModalRootBase)<{ isWide?: boolean; }>`
  ${({ isWide }) => isWide ? `
    width: 620px;
  ` : ''}
`;
