import React from 'react';
import { POC, PocType } from 'utils/entities';
import styled from '@emotion/styled';
import { AgentPhoto } from './styled';
import AgentPlaceholder from 'assets/svg/user-placeholder/agent.svg';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { Link } from 'components/link';
import { Route } from 'config/routes';


const AgencyPhoto = styled.div<{ imageURL: string }>`
  width: 64px;
  min-width: 64px;
  height: 64px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-color: ${({ theme }) => theme.colors.neutrals.grey6};
  ${({ imageURL }) => imageURL ? `background-image: url(${imageURL});` : ''}
`;

const AgentPhotoAdapted = styled(AgentPhoto)<{ big?: boolean }>`
  margin: 0;
  ${props => props.big ? `
    width: 64px;
    height: 64px;
  ` : ''}
`;

const PhotoWrapper = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;


interface HebrewContactImageProps {
  poc: POC;
  big?: boolean;
  showOnlyOffice?: boolean;
}

const HebrewContactImage: React.FC<HebrewContactImageProps> = ({ poc, big, showOnlyOffice }) => {
  const constructImageURI = useImageUrlBuilder();
  let agentImageUrl;
  let agencyImageUrl;
  let officeId: string;
  let agentId: string;
  if (poc.type === PocType.Agent) {

    const { agentContact, officeContact } = poc;
    officeId = poc.officeId;
    agentId = poc.agentId;
    agentImageUrl = agentContact && constructImageURI(agentContact.imageUrl);
    agencyImageUrl = officeContact && constructImageURI(officeContact.imageUrl, {
      width: 64,
      height: 64,
      fit: 'contain',
      padding: 8,
    });
  }
  else if (poc.contactInfo) {
    agentImageUrl = constructImageURI(poc.contactInfo.imageUrl);
  }

  const agentLink = <Link routeName={Route.AgentPage} routeParams={{ id: agentId, source: 'listing_page' }}><AgentPhotoAdapted imageURL={agentImageUrl} big={big} /></Link>;

  const officeLink = <Link routeName={Route.OfficePage} routeParams={{ id: officeId, source: 'listing_page' }}><AgencyPhoto imageURL={agencyImageUrl} /></Link>;

  const contactImage = agentImageUrl && !showOnlyOffice
    ? agentLink
    : (
        agencyImageUrl
          ? officeLink
          : <AgentPlaceholder data-auto="agent-image-placeholder" width={big ? 64 : 48} height={big ? 64 : 48} />
      );

  return (
    <PhotoWrapper>
      {contactImage}
    </PhotoWrapper>
  );
};

export default HebrewContactImage;
