import withEvents from 'analytics/withEvents';
import Arrow from 'assets/svg/left.svg';
import Preview, { IInsightPreview, CarouselNegativeWrapper } from 'components/widget/common/styled';
import { ShowAt } from 'consts/breakpoints';
import { useTheme } from 'hooks/useTheme';
import { noop } from 'lodash';
import React from 'react';
import { ViewPager } from 'components/view-pager';
import { SlideBtn, Wrapper } from './styled';
import { WithContentSlotSidePaddingContainer } from 'ds/components/Layout';
import { Stories } from 'ds/components/stories';


export interface IStoryEventChangeMeta {
  direction: 'left' | 'right';
  index: number;
  total: number;
  insightId: string;
  scrollType?: 'automatic' | 'manual';
}

interface CarouselStoriesProps {
  insights: IInsightPreview[];
  onReadMoreClick: (id: string) => void;
  color: string;
  title?: string;
  slideIndex: number;
  onSlideIndexChange: (idx: number, meta?: IStoryEventChangeMeta) => void;
  /** this is EFFECT! is just fired when index changes. state is managed from inside */
  onStoryIndexChangeNotify?: (idx: number, meta?: IStoryEventChangeMeta) => void;
}

const CarouselStories: React.FunctionComponent<CarouselStoriesProps> = (props) => {
  const { insights, color, title = '', onReadMoreClick, onSlideIndexChange, slideIndex: activeCarouselSlide, onStoryIndexChangeNotify = noop } = props;
  const { isRTL } = useTheme();

  const stories = insights.map((insight, index) => (
    <Preview
      title={title}
      key={insight.id}
      insight={insight}
      color={color}
      onReadMoreClick={onReadMoreClick}
      __listPositionIndex={index}
      __listPositionTotal={insights.length}
    />
  ));

  const handleCarouselIndexChange = (idx: number) => {
    if (idx !== activeCarouselSlide) {
      onSlideIndexChange(idx, {
        insightId: insights[idx].id,
        direction: idx > activeCarouselSlide ? 'right' : 'left',
        index: idx,
        total: stories.length,
      });
    }
  };

  return (
    <ShowAt to={2}>
      {(isMobile) => (
        <Wrapper>
          {isMobile ? (
            <WithContentSlotSidePaddingContainer noMobilePadding>
              <CarouselNegativeWrapper>
                <Stories
                  dotColor={props.color}
                  onStoryIndexChangeNotify={(newValue, activeStory, isAutomatic) => {
                    if (newValue !== activeStory && onStoryIndexChangeNotify) {
                      onStoryIndexChangeNotify((activeStory), {
                        insightId: insights[activeStory].id,
                        direction: newValue > activeStory ? 'right' : 'left',
                        index: newValue,
                        total: stories.length,
                        scrollType: isAutomatic ? 'automatic' : 'manual',
                      });
                    }
                  }}
                >
                  {stories}
                </Stories>
              </CarouselNegativeWrapper>

            </WithContentSlotSidePaddingContainer>
          ) : (
            <React.Fragment key="desktopFragment">
              <ViewPager
                onSlideIndexChange={handleCarouselIndexChange}
                slideIndex={activeCarouselSlide}
                align="left"
                isRTL={isRTL}
              >
                {stories}
              </ViewPager>

              {activeCarouselSlide === 0 ? null : (
                <SlideBtn dir="prev" onClick={() => handleCarouselIndexChange(activeCarouselSlide - 1)}>
                  <Arrow/>
                </SlideBtn>
              )}

              {activeCarouselSlide === stories.length - 1 ? null : (
                <SlideBtn dir="next" onClick={() => handleCarouselIndexChange(activeCarouselSlide + 1)}>
                  <Arrow/>
                </SlideBtn>
              )}
            </React.Fragment>
          )}
        </Wrapper>
      )}
    </ShowAt>

  );
};


export default withEvents<CarouselStoriesProps>((se) => ({
  onSlideIndexChange: (_: number, meta: IStoryEventChangeMeta) => se('insight_scroll', 'insight', {
    event: {
      direction: meta.direction,
      insight_index: meta.index,
      insight_index_total: meta.total,
      scroll_type: 'manual',
      view_mode: 'carousel',
    },
    insight: {
      insight_id: meta.insightId,
    },
  }),

  onStoryIndexChangeNotify: (_: number, meta: IStoryEventChangeMeta) => se('insight_scroll', 'insight', {
    event: {
      direction: meta.direction,
      insight_index: meta.index,
      insight_index_total: meta.total,
      scroll_type: meta.scrollType,
      view_mode: 'story',
    },
    insight: {
      insight_id: meta.insightId,
    },
  }),
}))(CarouselStories);
