import { createSelector } from 'reselect';
import { routeSelector } from './router';
import { Route } from 'config/routes';
import {
  unitPageBulletinSelector,
  projectPageProjectSelector,
  soldPageDealSelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
} from '../domainData/selectors';


export const currentPoiSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  soldPageDealSelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
], (route, bulletin, project, deal, commercialBulletin, commercialProject) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.ProjectPage:
      poi = project;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
    case Route.ProjectPageCommercial:
      poi = commercialProject;
      break;
    case Route.Sold:
      poi = deal;
      break;
  }
  return poi;
});
