import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { Text, H2, SmallText } from 'ds/components/typography';
import { HEADER_HEIGHT } from 'ds/components/Layout';

export const BaseFlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    min-height: calc(100vh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
  `}
`;

export const StepsProgressWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    flex-direction: column-reverse;
    margin: -${theme.spacing(3)} -${theme.spacing(3)} ${theme.spacing(3)};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    flex-direction: column;
    margin-bottom: ${theme.spacing(6)};
  `}
`;

export const FormWrapper = styled.div`
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(10)};
`;

export const FormInner = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin: ${theme.spacing(3)} auto;
    width: 648px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: ${theme.spacing(3)};
  `}
`;

export const FormControlButtonsWrapper = styled.div`
  ${({ theme }) => `
    box-shadow: ${theme.shadow.level5};
    background: ${theme.colors.neutrals.white};
    z-index: 1002;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    min-height: 64px;
    flex-basis: 64px;
  `}

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    min-height: 80px;
    flex-basis: 80px;
  `}
  position: fixed;
  bottom: 0;
  width: 100%;
`;


export const StepHeading = styled(H2)`
  ${({ theme }) => `
    font-weight: bold;
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const Section = styled.div<{noBorder?: boolean}>`
  ${({ theme, noBorder }) => `
    padding: ${theme.spacing(4)} 0;
    ${noBorder ? '' : `border-bottom: 1px solid ${theme.colors.neutrals.grey8};`}
  `}

  :first-of-type {
    padding-top: 0;
  }

  :last-of-type {
    border-bottom: 0;
  }
`;

export const SectionHeading = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const HeadingHint = styled.span`
  ${({ theme }) => `
    display: inline-block;
    margin: 0 ${theme.spacing(1.5)};
    color: ${theme.colors.neutrals.grey3};
    position: relative;
    font-weight: normal;

    ::after {
      display: block;
      position: absolute;
      content: '';
      width: 2px;
      height: 2px;
      background-color: ${theme.colors.neutrals.grey3};
      top: 50%;
      transform: translateY(-50%);
      right: -${theme.spacing(1)};
    }
  `}
`;

export const InputWrapper = styled.div<{ error?: boolean; maxWidth?: number; fullWidth?: boolean; }>`
  ${({ theme, error, maxWidth, fullWidth }) => `
    ${fullWidth ? '' : `max-width: ${maxWidth || '150'}px;`}
    width: 100%;
    display: inline-block;
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};

    :last-of-type {
      margin: 0;
    }
    ${error ? `
      .textfield-input, .textarea-input {
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      }
    ` : ''}
  `}
`;

export const InputWithValidation = styled.div<{ error?: boolean; }>`
  ${({ theme, error }) => `
    ${error ? `
      .textfield-input {
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      }
    ` : ''}
  `}
`;

export const RadioGroupWrapper = styled.div<{ error?: boolean; }>`
  ${({ theme, error }) => `
    ${error ? `
      .radio-input-inner {
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      }
    ` : ''}
  `}
`;

export const SelectWrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(3)} 0;
  `}
`;

export const AdditionalBedsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;

    > * {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};
    }
  `}
`;

export const TipBlock = styled.div`
  display: flex;
  align-items: flex-start;
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)};
    background-color:  ${colorToAlpha(theme.colors.brand.main, 0.05)};
    color: ${theme.colors.neutrals.grey2};
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.small};
  `}
`;

export const TipIconWrapper = styled.div`
  ${({ theme }) => `
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};

    > svg {
      height: 40px;
      width: 40px;
    }
  `}
`;

export const TipContentWrapper = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
`;

export const TipRecommendation = styled(TipContentWrapper)`
  a {
    color: inherit;
  }
`;

export const FormControlButtonsInner = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: ${theme.spacing(2.5)} 0;
  `}

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    width: 648px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    width: 100%;
  `}
`;

export const SelectFieldWrapper = styled.div<{ error?: boolean; isEmpty?: boolean; isWide?: boolean }>`
  display: flex;
  align-items: baseline;
  ${({ theme, error, isEmpty, isWide = true }) => `
    ${error ? `
      .select-value-button {
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      }
    ` : ''}

    ${isEmpty ? `
      .select-value-text {
        color: ${theme.colors.neutrals.grey4};
      }
    ` : ''}

    button > div {
      text-align: right;
      min-width: ${isWide ? 107 : 32}px;
    }
  `}
`;

export const NextButtonWrapper = styled.div<{ isValidatePhone?: boolean; }>`
  display: flex;

  ${({ theme, isValidatePhone }) => theme.media.showAt({ from: 3 })`
    ${isValidatePhone ? '' : 'width: 131px;'}
  `}
`;

export const ProgressHint = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: ${theme.spacing(1)};
    margin-right: ${theme.spacing(3)};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin: ${theme.spacing(5)} 0 ${theme.spacing(1)};
  `}
`;

export const ErrorContainer = styled(SmallText)`
  ${({ theme }) => `
    background-color: ${theme.colors.neutrals.white};
    position: fixed;
    z-index: 2;

    svg {
      margin: 0 0 -${theme.spacing(0.5)} ${theme.spacing(1.5)};
      > path {
        fill: ${theme.colors.alert.red};
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    bottom: 90px;
    left: 70px;
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    bottom: 70px;
    width: 100vw;
    text-align: center;
  `}
`;

export const ErrorInner = styled.div`
${({ theme }) => `
    background-color: ${colorToAlpha(theme.colors.alert.red, 0.07)};
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    color: ${theme.colors.alert.red};
  `}
`;
