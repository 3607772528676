import React from 'react';
import {
  _addEventListener,
  Unsubscribe,
  toRelative,
  toAbsolute,
  noOp,
  calculateRelative,
  roundByStep,
  MouseOrTouchEvent,
  ValuesTuple,
  setValueByIdx,
  isDeltaValid,
  getClosestIdx,
  areTuplesEqual,
} from './utils';
import styled from '@emotion/styled';
import { Theme } from 'ds';
import { withTheme } from 'emotion-theming';


export interface SliderRangeProps {
  disabled?: boolean;
  min: number;
  max: number;
  values: ValuesTuple;
  minDelta?: number;
  step?: number;
  mode?: 'rtl' | 'ltr';
  onChange: (values: ValuesTuple, evt: MouseOrTouchEvent) => void;
  onDragStart?: (evt: MouseOrTouchEvent) => void;
  onDragEnd?: (evt: MouseOrTouchEvent) => void;
}
const valuesIndexes = [ 0, 1 ];

const POINT_SIZE_PX = 29;
const RAIL_BG_HEIGHT_PX = 4;
const RAIL_FILL_HEIGHT_PX = 4;
const FINGER_FATNESS = 16;

// we need this one as due to slider's geomentry
// it overflows from the left bound which is ok unless RTL (creates horizontal scroll)
// padding + negative margin prevent shadows from being cut by overflow
const OverflowWrapper = styled.div`
  padding: ${FINGER_FATNESS}px 8px;
  margin: ${-FINGER_FATNESS}px -8px;
  overflow: hidden;
`;

const Container = styled.div`
  /* remove dark box on tapping somewhere in iOS */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  user-select: none;
  position: relative;
  cursor: pointer;
  height: ${POINT_SIZE_PX}px;
  padding: ${(POINT_SIZE_PX - RAIL_FILL_HEIGHT_PX) / 2}px 0;
  box-sizing: border-box;
`;
const RailsWrapper = styled.div`
  height: ${RAIL_FILL_HEIGHT_PX}px;
  position: relative;
  overflow: hidden;
  border-radius: ${RAIL_FILL_HEIGHT_PX / 2}px;
  /* enable hardware acceleration */
  transform: translateZ(0);
`;
const ClickableArea = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
const RailBase = `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* enable hardware acceleration */
  transform: scaleZ(1);
  will-change: transform;
`;
const RailsFill = styled.div`
  ${RailBase};
  transform-origin: left;
  background-color: ${props => props.theme.colors.brand.main};

  height: ${RAIL_FILL_HEIGHT_PX}px;
`;
const RailsBg = styled.div`
  ${RailBase};
  border-radius: ${RAIL_BG_HEIGHT_PX / 2}px;
  background-color: ${props => props.theme.colors.neutrals.grey7};
  transform-origin: right;
  height: ${RAIL_BG_HEIGHT_PX}px;
  margin: ${(RAIL_FILL_HEIGHT_PX - RAIL_BG_HEIGHT_PX) / 2}px 0;
`;

const PointWrapper = styled.div<{ index: number }>`
  position: absolute;
  right: ${Math.round(-POINT_SIZE_PX / 2 - FINGER_FATNESS)}px;
  /* increase touchable area of the point for fat fingers */
  padding: ${FINGER_FATNESS}px;
  margin-top: -${FINGER_FATNESS}px;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: ${POINT_SIZE_PX}px;
  width: ${POINT_SIZE_PX}px;
  background-color: ${props => props.theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  border: 1px solid ${props => props.theme.colors.neutrals.grey7};
  box-shadow: ${props => props.theme.shadow.level2};
  background-color:  ${props => props.theme.colors.neutrals.white};
  &:hover {
    border: 1px solid ${props => props.theme.colors.neutrals.grey6};
    box-shadow: ${props => props.theme.shadow.level3};
  }
`;

const Line = styled.div`
  width: 1px;
  height: 7px;
  margin: 0 1px;
  border-left: 1px solid ${props => props.theme.colors.neutrals.grey7};

  &:hover {
    border-left: 1px solid ${props => props.theme.colors.neutrals.grey6};
  }
`;
const ThumbWrapper = styled.div`
  margin: 0 ${POINT_SIZE_PX / 2}px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;
const Thumb = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  will-change: transform;
  transform: translate3d(0, 0, 0);
`;

interface InternalSliderRangeProps extends SliderRangeProps {
  drawHandles?: Set<number>;
}

export class InternalSliderRange extends React.Component<InternalSliderRangeProps, never> {

  public static defaultProps: Partial<InternalSliderRangeProps> = {
    disabled: false,
    step: null,
    onDragEnd: noOp,
    onDragStart: noOp,
    minDelta: 0,
    drawHandles: new Set([ 0, 1 ]),
  };

  public globalUpListener: Unsubscribe = null;
  public globalMoveListener: Unsubscribe = null;
  public containerRef: HTMLElement = null;

  public setContainerRef = (ref: HTMLElement) => {
    this.containerRef = ref;
  }

  public getValueFromEvt(evt: MouseOrTouchEvent) {
    const { min, max, step } = this.props;
    const relativeValue = calculateRelative(this.containerRef, evt);
    const absValue = toAbsolute(relativeValue, min, max);

    return roundByStep(absValue, step);
  }

  public emitChange(newValues: ValuesTuple, evt: MouseOrTouchEvent) {
    const { values, onChange, minDelta } = this.props;

    if (!areTuplesEqual(values, newValues) && isDeltaValid(newValues, minDelta)) {
      onChange(newValues, evt);
    }
  }

  // universal handler for both touch and mouse events
  public makeMoveHandler = (idx: number) => (evt: MouseOrTouchEvent) => {
    const { values } = this.props;
    const nextValue = this.getValueFromEvt(evt);
    const newValues = setValueByIdx(values, idx, nextValue);

    this.emitChange(newValues, evt);
  }
  public moveHandlers = valuesIndexes.map(this.makeMoveHandler);

  // mouse handlers
  public handleMouseUp = (evt: MouseEvent) => {
    evt.stopPropagation();
    this.globalMoveListener.remove();
    this.globalUpListener.remove();
    this.props.onDragEnd(evt);
  }

  public makeMouseDownHandler = (idx: number) => (evt: React.MouseEvent<HTMLElement>) => {
    if (evt.button !== 0) return;
    const moveHandler = this.makeMouseMoveHandler(idx);
    this.globalUpListener = _addEventListener(document.documentElement, 'mouseup', this.handleMouseUp);
    this.globalMoveListener = _addEventListener(document.documentElement, 'mousemove', moveHandler);
    this.props.onDragStart(evt.nativeEvent);
  }
  public mouseDownHandlers = valuesIndexes.map(this.makeMouseDownHandler);

  public makeMouseMoveHandler = (idx: number) => (evt: MouseEvent) => {
    evt.preventDefault();
    this.moveHandlers[idx](evt);
  }

  public handleRailClick = (evt: React.MouseEvent<HTMLElement>) => {

    const { values } = this.props;
    const nextValue = this.getValueFromEvt(evt.nativeEvent);
    const idx = getClosestIdx(values, nextValue);
    const newValues = setValueByIdx(values, idx, nextValue);

    this.emitChange(newValues, evt.nativeEvent);
  }

  // touch handlers
  public handleTouchEnd = (evt: TouchEvent) => {
    this.globalUpListener.remove();
    this.props.onDragEnd(evt);
  }

  public handleTouchStart = (evt: React.TouchEvent<HTMLElement>) => {
    this.globalUpListener = _addEventListener(document.documentElement, 'touchend', this.handleTouchEnd);
    this.props.onDragStart(evt.nativeEvent);
  }

  public makeTouchMoveHandler = (idx: number) => (evt: React.TouchEvent<HTMLElement>) => {
    this.moveHandlers[idx](evt.nativeEvent);
  }
  public touchMoveHandlers = valuesIndexes.map(this.makeTouchMoveHandler);

  public renderThumb = (relativeValue: number, idx: number) => this.props.drawHandles.has(idx) ? (
    <Thumb
      key={idx}
      style={{ transform: `translateX(${relativeValue * 100}%)` }}
    >
      <PointWrapper
        index={idx}
        onTouchStartCapture={this.handleTouchStart}
        onTouchMove={this.touchMoveHandlers[idx]}
        onMouseDown={this.mouseDownHandlers[idx]}
      >
        <Point data-auto="priceSlidePoints">
          <Line/>
          <Line/>
          <Line/>
        </Point>
      </PointWrapper>
    </Thumb>
  ) : null

  public render () {
    const { values, min, max } = this.props;
    const relativeValues = toRelative(values, min, max);
    const [ a, b ] = relativeValues;

    const railFillStyle = { transform: `translateX(${a * 100}%) scaleX(${b - a})` };

    return (
      <OverflowWrapper>
        <Container data-auto="priceSlider" ref={this.setContainerRef}>
          <RailsWrapper>
            <RailsBg/>
            <RailsFill style={railFillStyle}/>
          </RailsWrapper>
          <ClickableArea onClick={this.handleRailClick} />
          <ThumbWrapper>
            {relativeValues.map(this.renderThumb)}
          </ThumbWrapper>
        </Container>
      </OverflowWrapper>
    );
  }
}

interface SliderRangePropsWithTheme extends SliderRangeProps {
  theme: Theme;
}

export const SliderRange: React.FunctionComponent<SliderRangeProps> = withTheme(
  ({ theme, mode, ...originalProps }: SliderRangePropsWithTheme) => {
    const isRTL = mode ? mode === 'rtl' : theme.isRTL;
    if (isRTL) {

      const { values: [ v1, v2 ], min, max, onChange, ...props } = originalProps;

      return (
        <InternalSliderRange
          {...props}
          onChange={([ nv1, nv2 ], e) => onChange([ -nv2, -nv1 ], e)}
          min={-max}
          max={-min}
          values={[ -v2, -v1 ]}
        />
      );
    }

    return <InternalSliderRange {...originalProps}/>;
  }
);
