import { connect } from 'react-redux';
import { setToastMessage } from 'store/state/app/actions';
import { FeedbackPopupView, ISubmitPopupData } from 'components/common-feedback-popup-view/feedback-popup-view';
import { State } from 'store/state';
import { mutate } from 'store/state/mutationsResponse/actions';
import { ISendFeedbackVariables, MutationType } from 'store/sagas/apiService/types';
import { FeedbackPopupType } from 'components/common-feedback-popup-view/utils';
import { docIdForTabuSelector } from 'components/tabu/selectors';


interface FeedbackPopupOwnProps {
  popupTitle: string;
  submitButtonText: string;
}

const mapStateToProps = (state: State) => {
  const docId = docIdForTabuSelector(state);
  const variables: ISendFeedbackVariables = {
    insightType:  null,
    insightRecordId: null,
    message: null,
    bulletinId: null,
    feedback: null,
    email: null,
    address: docId,
    sourceType: 'tabu',
  };

  return {
    activeInsightId: docId,
    variables,
  };
};

const mapDispatchToProps = {
  mutate,
  setToastMessage,
};

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps: FeedbackPopupOwnProps) => ({
  ...ownProps,
  onSubmit: (submitPopupData: ISubmitPopupData) => {
    dispatchProps.setToastMessage({ term: 'feedback.popup.toasterMessageConfirmation' });
    dispatchProps.mutate({
      mutationType: MutationType.SendFeedback,
      meta: {
        key: stateProps.activeInsightId,
        variables: {
          ...stateProps.variables,
          ...submitPopupData,
          feedbackType: FeedbackPopupType.Tabu,
        },
      },
    });
  },
}))(FeedbackPopupView);
