import { connect } from 'react-redux';
import { State } from 'store/state';
import { isAfterUploadBulletinModalOpenSelector } from 'store/state/app/selectors';
import { setAfterUploadBulletinModalOpen } from 'store/state/app/actions';
import { AfterUploadModalView } from './AfterUploadBulletinPopup';
import { unitPageBulletinSelector, unitPageCommercialBulletinSelector } from 'store/state/domainData/selectors';
import { createSelector } from 'reselect';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';


const poiSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
], (route, bulletin, commercialBulletin) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
  }
  return poi;
});

const mapStateToProps = (state: State) => ({
  isModalOpen: isAfterUploadBulletinModalOpenSelector(state),
  poi: poiSelector(state),
});

const mapDispatchToProps = {
  onClosePopup: () => setAfterUploadBulletinModalOpen(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterUploadModalView);
