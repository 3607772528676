import { css } from '@emotion/core';

const baseUrl = 'https://assets.localize.city';

/* tslint:disable */
export default css`
   @font-face
   {
       font-family: "NovemberHebrew";
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002147.eot');
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002147.eot?#iefix') format('embedded-opentype'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002147.woff') format('woff'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002147.ttf') format('truetype');
       font-weight: 300;
       font-style: normal;
   }
   @font-face
   {
       font-family: "NovemberHebrew";
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002149.eot');
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002149.eot?#iefix') format('embedded-opentype'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002149.woff') format('woff'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002149.ttf') format('truetype');
       font-weight: 400;
       font-style: normal;
   }
   @font-face
   {
       font-family: "NovemberHebrew";
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002151.eot');
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002151.eot?#iefix') format('embedded-opentype'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002151.woff') format('woff'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002151.ttf') format('truetype');
       font-weight: 500;
       font-style: normal;
   }
   @font-face
   {
       font-family: "NovemberHebrew";
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002155.eot');
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002155.eot?#iefix') format('embedded-opentype'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002155.woff') format('woff'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002155.ttf') format('truetype');
       font-weight: 700;
       font-style: normal;
   }
   }
   @font-face
   {
       font-family: "NovemberHebrew";
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002157.eot');
       src: url('${baseUrl}/novemberHebrew/WF-030306-010351-002157.eot?#iefix') format('embedded-opentype'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002157.woff') format('woff'), url('${baseUrl}/novemberHebrew/WF-030306-010351-002157.ttf') format('truetype');
       font-weight: 900;
       font-style: normal;
   }
`;
