import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import SaveSearchButton from 'components/saved-search/save-button';
import { activePoiIdsSelector } from 'store/state/searchContext/selectors';
import {
  hoveredDataLayerIdSelector,
  isInfoBoxOpenSelector,
  isMobileLayersToggleOpenSelector,
} from 'store/state/insightsContext/selectors';
import { marketplaceSelector } from 'store/state/selectors/router';
import { MarketplaceType } from 'utils/entities';

export interface MaybeMobileMapSwitcherProps {
  anyActive: boolean;
  isMobileMapViewActive: boolean;
  isMobile: boolean;
  isSearch: boolean;
  isInfoBoxOpen: boolean;
  isMobileLayersToggleOpen: boolean;
  marketplace: MarketplaceType;
  toggleMap: () => void;
}

const MaybeMobileMapSwitcher: React.FC<MaybeMobileMapSwitcherProps> = (props) => {
  const { isMobileMapViewActive, isMobile, marketplace, isSearch, isInfoBoxOpen, isMobileLayersToggleOpen, anyActive } = props;
  const shouldShowBtnOnMap = isMobileMapViewActive ? anyActive : true;
  const isCommercial = marketplace === MarketplaceType.Commercial;

  if (isCommercial || isMobileMapViewActive || !isMobile || !shouldShowBtnOnMap || !isSearch || isMobileLayersToggleOpen || isInfoBoxOpen) return null;

  return <SaveSearchButton isButton />;
};

const mapStateToProps = (state: State) => ({
  anyActive: activePoiIdsSelector(state).size === 0 && hoveredDataLayerIdSelector(state) === null,
  marketplace: marketplaceSelector(state),
  isInfoBoxOpen: isInfoBoxOpenSelector(state),
  isMobileLayersToggleOpen: isMobileLayersToggleOpenSelector(state),
});

export default connect(mapStateToProps)(MaybeMobileMapSwitcher);
