import { makeNullSafeDomainGetter, getUnitPageLeadingDataSelector } from './common';
import { flow, head } from 'lodash';
import { createSelector } from 'reselect';
import { checkEnabledLeadingAgentFeature } from 'helpers/contact';
import { LeadingAgentFeature, PocType } from 'utils/entities';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';
import { unitPageBulletinSelector, unitPageCommercialBulletinSelector } from './unitPageBulletin';

const unitPageLeadingDataSelector = makeNullSafeDomainGetter(getUnitPageLeadingDataSelector, 'searchAwardsByIds');
export const unitPageLeadingAgentSelector = flow(unitPageLeadingDataSelector, head);


const commonBulletinSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
], (route, bulletin, commercialBulletin) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
  }
  return poi;
});

export const showAboutLeadingAgent = createSelector([
  unitPageLeadingAgentSelector,
  commonBulletinSelector,
], (data, poi) => {
  if (data && poi && poi.poc && poi.poc.type === PocType.Agent && poi.poc.agentId) {
    return checkEnabledLeadingAgentFeature(data, LeadingAgentFeature.AboutTheAgentInListingPage);
  }
  return false;
});
