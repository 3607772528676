import { flow } from 'lodash';
import { createSelector } from 'reselect';
import {
  makeNullSafeDomainGetter,
  userContentDomainSelector,
  userContentTextReviewsDomainSelector,
  userContentRatingReviewsDomainSelector,
} from '../domainData/selectors/common';
import { State } from '..';
import { RatingTopic } from 'components/user-generated-content/utils/initialUGCConfig';
import { RatingsDataMap } from './reviewWizard';
import { UgcRatingReviewEntry } from 'store/sagas/apiService/types';

export const userContentSelector = makeNullSafeDomainGetter(userContentDomainSelector, 'userContent');
export const userContentTextReviewsSelector = makeNullSafeDomainGetter(userContentTextReviewsDomainSelector, 'docId2TextReviews');
export const userContentRatingReviewsSelector = makeNullSafeDomainGetter(userContentRatingReviewsDomainSelector, 'docId2NumericReviews');

export const orderedTextReviewsSelector = createSelector([
  userContentTextReviewsSelector,
], (textReviews) => textReviews && textReviews.slice().reverse()
);

export const ugcStateSelector = (state: State) => state.ugc;

// FEEDBACK MODAL
export const feedbackModalSelector = flow(ugcStateSelector, ({ feedbackModal }) => feedbackModal);
export const isFeedbackModalOpenSelector = flow(feedbackModalSelector, ({ isOpen }) => isOpen);
export const feedbackModalUGCIdSelector = flow(feedbackModalSelector, ({ ugcId }) => ugcId);

// REVIEW WIZARD
export const reviewWizardSelector = flow(ugcStateSelector, ({ reviewWizard }) => reviewWizard);
export const isReviewWizardOpenSelector = flow(reviewWizardSelector, ({ isOpen }) => isOpen);
export const reviewWizardAddressNameSelector = flow(reviewWizardSelector, ({ addressName }) => addressName);
export const reviewWizardDocIdSelector = flow(reviewWizardSelector, ({ docId }) => docId);
export const reviewWizardStepIndexSelector = flow(reviewWizardSelector, ({ currentStepIndex }) => currentStepIndex);
export const reviewWizardStepsSelector = flow(reviewWizardSelector, ({ steps }) => steps);
export const hasNextStepSelector = createSelector([
  reviewWizardStepIndexSelector,
  reviewWizardStepsSelector,
], (currentStepIndex, steps) => currentStepIndex + 1 < steps.length
);
export const textReviewInputSelector = flow(reviewWizardSelector, ({ textReviewInput }) => textReviewInput);
export const userNameInputSelector = flow(reviewWizardSelector, ({ userNameInput }) => userNameInput);
export const ratingsDataMapSelector = flow(reviewWizardSelector, ({ ratingsDataMap }) => ratingsDataMap);
export const changedNumericUserInputSelector = flow(ratingsDataMapSelector, (ratingsDataMap: RatingsDataMap): UgcRatingReviewEntry[] => (
  Object.keys(ratingsDataMap).reduce((acc: UgcRatingReviewEntry[], topic: RatingTopic) => {
    const data = ratingsDataMap[topic];
    return data.hasChanged ? [ ...acc, { topic, numericReview: data.modified } ] : acc;
  }, [])
));
