import React from 'react';

interface SideClickListenerProps {
  children: React.ReactNode;
  onLeftSideClick: () => void;
  onRightSideClick: () => void;
}

export const SideClickListener: React.FC<SideClickListenerProps> = ({ children, onLeftSideClick, onRightSideClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    if (e.clientX > boundingRect.left && e.clientX < boundingRect.right) {
      const callbackFn = (e.clientX < boundingRect.left + boundingRect.width / 2)
        ? onLeftSideClick
        : onRightSideClick;
      callbackFn();
    }
  };

  return (
    <div onClick={handleClick}>
      {children}
    </div>
  );
};

