import styled from '@emotion/styled';
import { H3 } from 'ds/components/typography';

export const Title = styled(H3)`
  padding-top: 24px;
`;

export const Message = styled.div`
  margin-top: 40px;
`;
