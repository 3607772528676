import { createSelector } from 'reselect';
import { MadadParticipantPlace } from 'utils/entities';
import { LoadType } from 'store/sagas/apiService/types';
import { madadParticipantsDomainSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { SelectItemProps } from 'ds/components/input/Select';
import { flow, head } from 'lodash';


export const DEFAULT_CITY_DOC_ID = 'תל-אביב-יפו-ישראל';
export const DEFAULT_2024_CITY_DOC_ID = 'תל-אביב-יפו-מרכז';

export interface MadadParticipantFilterItem {
  city: SelectItemProps;
  neighbourhoods: SelectItemProps[];
}

const madadParticipantsSelector = makeNullSafeDomainGetter(madadParticipantsDomainSelector, LoadType.MadadParticipants);
export const isLoadingMadadParticipantsSelector = createIsLoadingSelector(madadParticipantsDomainSelector);

export const madadParticipantsFilterData = createSelector(
  [ madadParticipantsSelector ],
  (records): MadadParticipantFilterItem[] => {
    if (records && records.length) {
      return records
        .filter(({ city }) => madadPlaceFilter(city))
        .map(({ city, neighbourhoods }) => {
          return {
            city: mapPlaceToItemProp(city),
            neighbourhoods: neighbourhoods.filter(madadPlaceFilter).map(mapPlaceToItemProp),
          };
        });
    }
    return [];
  }
);

export const headParticipantsData = flow(madadParticipantsFilterData, (list) => {
  if (list.length) {
    return list.find(({ city }) => city.value === DEFAULT_CITY_DOC_ID) || head(list);
  }
  return null;
});

function madadPlaceFilter(v: MadadParticipantPlace) {
  return Boolean(v && v.docId && v.name);
}

function mapPlaceToItemProp({ docId, name }: MadadParticipantPlace): SelectItemProps {
  return {
    value: docId,
    label: name,
  };
}
