import { Saga, Task } from 'redux-saga';
import { take, fork, cancel, all } from 'redux-saga/effects';

import { Route, State as RouteState } from 'config/routes';
import { TRANSITION_SUCCESS } from 'store/state/router/types';

import { middlewareWatcher } from './middlewares';
import { scrollToElement } from './handlers/scrollToElement';
import { searchHandler } from './handlers/search';
import { addressPageClientHandler } from './handlers/address';
import { commercialUnitPageHandler, residentialUnitPageHandler } from './handlers/unitPage';
import { projectPageHandler, residentialSSRProjectHandler, commercialSSRProjectHandler } from './handlers/projectPage';
import { homeHandler } from './handlers/home';
import { savedSearchesPageHandler } from './handlers/savedSearchesPage';
import { savedSearchHandler } from './handlers/savedSearch';
import { localPageHandler } from './handlers/localPage';
import { unsubscribePageHandler } from './handlers/unsubscribePage';
import { withAuthRestrictor } from './handlers/utils';
import { uploadBulletinHandler } from './handlers/uploadBulletin';
import { manageBulletinsPageHandler } from './handlers/manageBulletinsPage';
import { editCommercialBulletinPageHandler, editResidentialBulletinPageHandler } from './handlers/editBulletinPage';
import { profilePageHandler } from './handlers/profilePage';
import { useRouterMiddleware } from './handlers/useRouterMiddleware';
import { soldPageHandler } from './handlers/soldPage';
import { streetHandler } from './handlers/streetPage';
import { madadSearchPageHandler } from './handlers/madadSearchPage';
import { madadPageHandler, madadPageArchiveHandler, madadPageArchive2020Handler } from './handlers/madadPage';
import { residentialSearchPageHandler, commercialSearchPageHandler } from './handlers/search/searchPageHandler';
import { accessibilityPageHandler } from './handlers/accessibilityPage';
import { routeResultFn } from 'store/state/selectors/router';
import { developerPageHandler } from './handlers/developerPage';
import { developersCitySearchPageHandler } from 'store/sagas/routing/handlers/developersCitySearchPage';
import { employmentAreaPageHandler } from './handlers/employmentAreaPage';
import { commercialMarketLandingPageHandler } from './handlers/commercialMarketLandingPage';
import { checkAddressHandler } from './handlers/checkAddress';
import { officePageHandler } from './handlers/officePage';
import { mortgageOfficePageHandler } from './handlers/mortgageOfficePage';
import { mortgageOfficesSearchPageHandler } from 'store/sagas/routing/handlers/mortgageOfficesSearchPage';
import { agentPageHandler } from './handlers/agentPage';
import { shortlistPageHandler } from 'store/sagas/routing/shortlistPage';
import { dealsPageHandler } from 'store/sagas/routing/handlers/dealsPage';

// todo: move to store/state/router and replace with ActionType<typeof transitionSuccess>
export interface TransitionAction {
  type: typeof TRANSITION_SUCCESS;
  payload: {
    previousRoute: RouteState;
    route: RouteState;
  };
}

const routeHandlers: Partial<Record<Route, Saga>> = {
  [Route.AddressPage]: addressPageClientHandler,
  [Route.UnitPage]: residentialUnitPageHandler,
  [Route.UnitPageCommercial]: commercialUnitPageHandler,
  [Route.Sold]: soldPageHandler,
  [Route.ProjectPage]: projectPageHandler,
  [Route.ProjectPageCommercial]: commercialSSRProjectHandler,
  [Route.CommercialMarketLanding]: commercialMarketLandingPageHandler,
  [Route.CheckAddress]: checkAddressHandler,
  [Route.Home]: homeHandler,
  [Route.MyHomes]: withAuthRestrictor(),
  [Route.Profile]: profilePageHandler,
  [Route.Shortlist]: withAuthRestrictor(shortlistPageHandler),
  [Route.SavedSearchesPage]: withAuthRestrictor(savedSearchesPageHandler),
  [Route.SavedSearch]: withAuthRestrictor(savedSearchHandler),
  [Route.Search]: residentialSearchPageHandler,
  [Route.SearchCommercial]: commercialSearchPageHandler,
  [Route.LocalPage]: localPageHandler,
  [Route.UnsubscribePage]: unsubscribePageHandler,
  [Route.AriaUnit]: residentialUnitPageHandler,
  [Route.AriaProject]: residentialSSRProjectHandler, // TODO CHECK
  [Route.ManageBulletins]: withAuthRestrictor(manageBulletinsPageHandler),
  [Route.UploadBulletin]: uploadBulletinHandler,
  [Route.EditBulletin]: withAuthRestrictor(editResidentialBulletinPageHandler),
  [Route.EditCommercialBulletin]: withAuthRestrictor(editCommercialBulletinPageHandler),
  [Route.StreetPage]: streetHandler,
  [Route.MadadPage]: madadPageHandler,
  [Route.MadadCity]: madadPageHandler,
  [Route.MadadArchivePage]: madadPageArchiveHandler,
  [Route.MadadCityArchive]: madadPageArchiveHandler,
  [Route.MadadArchive2020Page]: madadPageArchive2020Handler,
  [Route.MadadSearchPage]: madadSearchPageHandler,
  [Route.Accessibility]: accessibilityPageHandler,
  [Route.DeveloperPage]: developerPageHandler,
  [Route.MortgageOfficePage]: mortgageOfficePageHandler,
  [Route.DevelopersCitySearchPage]: developersCitySearchPageHandler,
  [Route.DevelopersSearchPage]: developersCitySearchPageHandler,
  [Route.MortgageOfficesSearchPage]: mortgageOfficesSearchPageHandler,
  [Route.MortgageOfficesCitySearchPage]: mortgageOfficesSearchPageHandler,
  [Route.EmploymentAreaPage]: employmentAreaPageHandler,
  [Route.OfficePage]: officePageHandler,
  [Route.AgentPage]: agentPageHandler,
  [Route.Deals]: withAuthRestrictor(dealsPageHandler, 'agentConsoleUser'),
};

function* routeWorker() {
  let currentTask: Task = null;

  while (true) {
    const action: TransitionAction = yield take(TRANSITION_SUCCESS);
    const currentRoute = routeResultFn(action.payload.route);

    if (currentTask) yield cancel(currentTask);
    const saga = routeHandlers[currentRoute.name];
    if (saga) {
      currentTask = yield fork(saga, currentRoute);
    }
    else currentTask = null;
  }
}

export function* routingWatcher() {
  yield all([
    fork(scrollToElement),
    fork(middlewareWatcher),
    fork(useRouterMiddleware),
    fork(searchHandler),
    fork(routeWorker),
  ]);
}
