import { IEnhancer, Fetcher } from '.';

export class WebSourceEnhancer implements IEnhancer {
  public enhance = (url: string, init: RequestInit, innerFetch: Fetcher) => innerFetch(url, {
    ...init,
    headers: {
      ...init.headers,
      'X-Original-Url': window.location.pathname + window.location.search,
      'X-Original-Hostname': window.location.hostname,
      'X-Source': 'web',
    },
  })
}
