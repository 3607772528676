import React from 'react';
import ManageBulletinsIcon from 'assets/svg/feed.svg';
import UploadBulletinIcon from 'assets/svg/plus-with-border.svg';
import MyHomeIcon from 'assets/svg/myhomes.svg';
import BlogIcon from 'assets/svg/blog-icon.svg';
import AgencyIcon from 'assets/svg/agency-icon.svg';
import VerifiedIcon from 'assets/svg/verified-empty.svg';
import CommercialIcon from 'assets/svg/commercial.svg';
import KeyIcon from 'assets/svg/key.svg';
import RentIcon from 'assets/svg/living-room.svg';
import NeighbourhoodIcon from 'assets/svg/neighborhood.svg';
import { COMMERCIAL_INFO_PAGES_ROUTES, COMMERCIAL_ROUTES, Route, State as RouteState } from 'config/routes';
import { ITranslate } from 'locale';
import { AppMode, marketplaceByRouteName } from 'store/state/selectors/router';
import config from 'config';
import { DealType, MarketplaceType } from 'utils/entities';
import { ResidentialBuyRentHistoryFilters, HeaderLinkItem, HeaderLinkType, SeoLinksSection, TopNavLinksType } from './types';
import { config as filtersConfig } from 'components/filters/config';
import { PropertyType, Seller } from 'components/filters/types';
import HeartIconWithCounter from 'components/navigation/header/HeartIconWithCounter';

export const DEVELOPER_SEARCH_PAGES = new Set([ Route.DevelopersSearchPage, Route.DevelopersCitySearchPage ]);
export const HOME_PAGES = new Set([ Route.Home, Route.CommercialMarketLanding, ...DEVELOPER_SEARCH_PAGES ]);

export const SEARCH_MODE_PAGES = new Set([
  Route.Sold,
  Route.Search,
  Route.SearchCommercial,
  Route.UnitPage,
  Route.UnitPageCommercial,
  Route.AddressPage,
  Route.CheckAddress,
  Route.LocalPage,
  Route.MadadPage,
]);

export const ADD_EDIT_BULLETIN_PAGES = new Set([
  Route.UploadBulletin,
  Route.EditBulletin,
  Route.EditCommercialBulletin,
]);

export const PROFILE_MENU_INNER_PAGES = new Set([
  Route.SavedSearchesPage,
  Route.Profile,
  Route.UploadBulletin,
  Route.MyHomes,
  Route.EditBulletin,
  Route.EditCommercialBulletin,
  Route.ManageBulletins,
  Route.PushSettings,
]);

export const linkToIcon: Partial<Record<Route, React.ReactNode>> = {
  [Route.UploadBulletin]: <UploadBulletinIcon width={24} height={24} />,
  [Route.ManageBulletins]: <ManageBulletinsIcon width={24} height={24} />,
  [Route.Shortlist]: <HeartIconWithCounter />,
  [Route.MyHomes]: <MyHomeIcon width={24} height={24} />,
};

const SIMPLE_HEADER_ROUTES = new Set([
  Route.Home,
  Route.CommercialMarketLanding,
  Route.DeveloperPage,
  Route.DevelopersSearchPage,
  Route.DevelopersCitySearchPage,
  Route.MortgageOfficePage,
]);


export const SEO_LINKS: Partial<Record<TopNavLinksType, SeoLinksSection[]>> = {
  [AppMode.Buy]: [
    {
      title: 'ראשי',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'דירות למכירה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ישראל' ], dealType: DealType.Buy },
            icon: <KeyIcon />,
          },
          {
            title: 'פרויקטים חדשים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: {
              term: [ 'ישראל' ],
              dealType: DealType.Buy,
              tracking_search_source: 'filter_apply',
              filters: {
                seller: [ Seller.Developer ],
                ppmRange: filtersConfig.residential.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.residential.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
            icon: <NeighbourhoodIcon height={17} width={17} />,
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · דירות למכירה',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'דירות למכירה בחיפה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'חיפה-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בירושלים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ירושלים-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בחולון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'חולון-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בפתח תקווה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'פתח-תקווה-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בראשון לציון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ראשון-לציון-ישראל' ], dealType: DealType.Buy },
          },
        ],
        [
          {
            title: 'דירות למכירה באשדוד',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'אשדוד-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בבת ים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'בת-ים-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה ברחובות',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'רחובות-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'תל-אביב-יפו-ישראל' ], dealType: DealType.Buy },
          },
          {
            title: 'דירות למכירה בגבעתיים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'גבעתיים-ישראל' ], dealType: DealType.Buy },
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · פרויקטים חדשים',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'פרויקטים חדשים בחיפה',
            type: 'old',
            href: '/projects-for-sale/חיפה-ישראל',
          },
          {
            title: 'פרויקטים חדשים בירושלים',
            type: 'old',
            href: '/projects-for-sale/ירושלים-ישראל',
          },
          {
            title: 'פרויקטים חדשים בחולון',
            type: 'old',
            href: '/projects-for-sale/חולון-ישראל',
          },
          {
            title: 'פרויקטים חדשים בפתח תקווה',
            type: 'old',
            href: '/projects-for-sale/פתח-תקווה-ישראל',
          },
          {
            title: 'פרויקטים חדשים בראשון לציון',
            type: 'old',
            href: '/projects-for-sale/ראשון-לציון-ישראל',
          },
        ],
        [
          {
            title: 'פרויקטים חדשים בנתניה',
            type: 'old',
            href: '/projects-for-sale/נתניה-ישראל',
          },
          {
            title: 'פרויקטים חדשים בבת ים',
            type: 'old',
            href: '/projects-for-sale/בת-ים-ישראל',
          },
          {
            title: 'פרויקטים חדשים באשקלון',
            type: 'old',
            href: '/projects-for-sale/אשקלון-ישראל',
          },
          {
            title: 'פרויקטים חדשים בתל אביב',
            type: 'old',
            href: '/projects-for-sale/תל-אביב-יפו-ישראל',
          },
          {
            title: 'פרויקטים חדשים בגבעתיים',
            type: 'old',
            href: '/projects-for-sale/גבעתיים-ישראל',
          },
        ],
      ],
    },
  ],
  [AppMode.Rent]: [
    {
      title: 'ראשי',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'דירות להשכרה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ישראל' ], dealType: DealType.Rent },
            icon: <RentIcon />,
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · דירות להשכרה',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'דירות להשכרה בחיפה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'חיפה-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בירושלים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ירושלים-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בחולון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'חולון-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בפתח תקווה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'פתח-תקווה-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בראשון לציון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ראשון-לציון-ישראל' ], dealType: DealType.Rent },
          },
        ],
        [
          {
            title: 'דירות להשכרה באשדוד',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'אשדוד-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בבת ים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'בת-ים-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה ברחובות',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'רחובות-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בראש העין',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ראש-העין-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בהוד השרון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'הוד-השרון-ישראל' ], dealType: DealType.Rent },
          },
        ],
        [
          {
            title: 'דירות להשכרה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'תל-אביב-יפו-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה ברמת גן',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'רמת-גן-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה ברעננה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'רעננה-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בכפר סבא',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'כפר-סבא-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בחדרה',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'חדרה-ישראל' ], dealType: DealType.Rent },
          },
        ],
        [
          {
            title: 'דירות להשכרה בבאר שבע',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'באר-שבע-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בגבעתיים',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'גבעתיים-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה באשקלון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'אשקלון-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה ברמת השרון',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'רמת-השרון-ישראל' ], dealType: DealType.Rent },
          },
          {
            title: 'דירות להשכרה בכל הארץ',
            type: HeaderLinkType.Inner,
            routeName: Route.Search,
            params: { term: [ 'ישראל' ], dealType: DealType.Rent },
          },
        ],
      ],
    },
  ],
  commercial: [
    {
      title: 'ראשי',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'נדל״ן מסחרי להשכרה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: { term: [ 'ישראל' ], dealType: DealType.Rent },
            icon: <CommercialIcon />,
          },
          {
            title: 'נדל״ן מסחרי לקנייה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: { term: [ 'ישראל' ], dealType: DealType.Buy },
            icon: <KeyIcon />,
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · נדל״ן מסחרי להשכרה',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'משרדים להשכרה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'תל-אביב-יפו-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'חנויות להשכרה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'תל-אביב-יפו-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Shop ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה ברמת גן',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רמת-גן-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה בבת ים',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'בת-ים-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה בנתניה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'נתניה-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
        ],
        [
          {
            title: 'משרדים להשכרה בחולון',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'חולון-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה בחיפה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'חיפה-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה ברעננה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רעננה-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה ברחובות',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רחובות-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים להשכרה בפתח תקווה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'פתח-תקווה-ישראל' ],
              dealType: DealType.Rent,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitRent.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitRent.initialBudgetValue,
              },
            },
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · נדל״ן מסחרי לקנייה',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'משרדים למכירה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'תל-אביב-יפו-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'חנויות למכירה בתל אביב',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'תל-אביב-יפו-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Shop ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה ברמת גן',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רמת-גן-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה בבת ים',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'בת-ים-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה בנתניה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'נתניה-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
        ],
        [
          {
            title: 'משרדים למכירה בחולון',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'חולון-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה בחיפה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'חיפה-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה ברעננה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רעננה-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה ברחובות',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'רחובות-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
          {
            title: 'משרדים למכירה בפתח תקווה',
            type: HeaderLinkType.Inner,
            routeName: Route.SearchCommercial,
            params: {
              term: [ 'פתח-תקווה-ישראל' ],
              dealType: DealType.Buy,
              filters: {
                propertyTypes: [ PropertyType.Office ],
                ppmRange: filtersConfig.commercial.budgetByPerSquareMeter.unitBuy.initialBudgetValue,
                priceRange: filtersConfig.commercial.budgetByDealType.unitBuy.initialBudgetValue,
              },
            },
          },
        ],
      ],
    },
  ],
  blog: [
    {
      title: 'ראשי',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'הבלוק - הבלוג של מדלן',
            type: HeaderLinkType.Outer,
            icon: <BlogIcon />,
            href: 'https://www.madlan.co.il/blog/',
          },
        ],
      ],
    },
    {
      title: 'מתוך הבלוג',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'מדריכים ',
            type: HeaderLinkType.Outer,
            href: 'https://www.madlan.co.il/blog/guides/',
          },
          {
            title: 'מאמרים',
            type: HeaderLinkType.Outer,
            href: 'https://www.madlan.co.il/blog/',
          },
          {
            title: 'מחשבון מס רכישה',
            type: HeaderLinkType.Outer,
            href: 'https://www.madlan.co.il/blog/מחשבון-מס-רכישה-2020/',
          },
          {
            title: 'מחשבון תשואת שכירות',
            type: HeaderLinkType.Outer,
            href: 'https://www.madlan.co.il/blog/מחשבון-תשואת-שכירות-2021/',
          },
          {
            title: 'שכונות להשקעה ',
            type: HeaderLinkType.Outer,
            href: 'https://www.madlan.co.il/blog/מדד-שכונות-להשקעה/',
          },
        ],
      ],
    },
  ],
  madad: [
    {
      title: 'ראשי',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'מאגר המתווכים',
            type: HeaderLinkType.Outer,
            href: '/search-agent',
            icon: <AgencyIcon />,
          },
          {
            title: 'מדד המתווכים',
            type: HeaderLinkType.Outer,
            href: '/madad',
            icon: <VerifiedIcon />,
          },
        ],
      ],
    },
    {
      title: 'חיפושים פופולריים · משרדי תיווך',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'משרדי תיווך בתל אביב',
            type: HeaderLinkType.Outer,
            href: '/madad-search/תל-אביב-יפו-ישראל',
          },
          {
            title: 'משרדי תיווך בנתניה',
            type: HeaderLinkType.Outer,
            href: '/madad-search/נתניה-ישראל',
          },
          {
            title: 'משרדי תיווך בחדרה',
            type: HeaderLinkType.Outer,
            href: '/madad-search/חדרה-ישראל',
          },
          {
            title: 'משרדי תיווך בחולון',
            type: HeaderLinkType.Outer,
            href: '/madad-search/חולון-ישראל',
          },
          {
            title: 'משרדי תיווך בבת ים',
            type: HeaderLinkType.Outer,
            href: '/madad-search/בת-ים-ישראל',
          },
        ],
        [

          {
            title: 'משרדי תיווך ברמת גן',
            type: HeaderLinkType.Outer,
            href: '/madad-search/רמת-גן-ישראל',
          },
          {
            title: 'משרדי תיווך בראשון לציון',
            type: HeaderLinkType.Outer,
            href: '/madad-search/ראשון-לציון-ישראל',
          },
          {
            title: 'משרדי תיווך בחיפה',
            type: HeaderLinkType.Outer,
            href: '/madad-search/חיפה-ישראל',
          },
          {
            title: 'משרדי תיווך בירושלים',
            type: HeaderLinkType.Outer,
            href: '/madad-search/ירושלים-ישראל',
          },
          {
            title: 'משרדי תיווך בהרצליה',
            type: HeaderLinkType.Outer,
            href: '/madad-search/הרצליה-ישראל',
          },
        ],
      ],
    },
    {
      title: 'מדד המתווכים · לפי שנים',
      type: HeaderLinkType.None,
      children: [
        [
          {
            title: 'מדד המתווכים 2023/24',
            href: '/madad',
            type: HeaderLinkType.Outer,
          },
          {
            title: 'מדד המתווכים 2022/23',
            href: '/madad/2023',
            type: HeaderLinkType.Outer,
          },
          {
            title: 'מדד המתווכים 2021/22',
            href: '/madad/2022',
            type: HeaderLinkType.Outer,
          },
          {
            title: 'מדד המתווכים 2020/21',
            href: '/madad/2021',
            type: HeaderLinkType.Outer,
          },
          {
            title: 'מדד המתווכים 2019/20',
            href: '/madad/2020',
            type: HeaderLinkType.Outer,
          },
        ],
      ],
    },
  ],
};

function marketplaceChanged(prevRoute: RouteState, linkToGo: Route) {
  return prevRoute ? marketplaceByRouteName(prevRoute.name) !== marketplaceByRouteName(linkToGo) : false;
}

function isDeveloperIndexPrevRoute(prevRoute: RouteState) {
  return prevRoute && [ Route.DevelopersSearchPage, Route.DevelopersCitySearchPage ].includes(prevRoute.name);
}

export function getHeaderLinks(route: RouteState, isDesktop: boolean, t: ITranslate, savedPriceFilter: ResidentialBuyRentHistoryFilters): HeaderLinkItem[] {
  const {
    name: routeName,
    params,
  } = route;

  const isCommercial = COMMERCIAL_ROUTES.has(routeName) || (params.marketplace && params.marketplace === MarketplaceType.Commercial);

  if (SIMPLE_HEADER_ROUTES.has(routeName) && !isDesktop) {
    return [
      {
        type: HeaderLinkType.Inner,
        label: t('navigation.header.commercial.link.home'),
        routeName: Route.Home,
        activePredicate: (r) => r === Route.Home,
        params: {
          marketplace: MarketplaceType.Residential,
        },
      },
      {
        type: HeaderLinkType.Inner,
        label: t('navigation.header.commercial.link.landing'),
        routeName: Route.CommercialMarketLanding,
        activePredicate: (r) => r === Route.CommercialMarketLanding,
        params: {
          marketplace: MarketplaceType.Commercial,
        },
      },
    ];
  }

  if (isCommercial && !isDesktop) {
    return [
      {
        type: HeaderLinkType.Inner,
        label: t('navigation.header.commercial.landing.link'),
        routeName: Route.SearchCommercial,
        activePredicate: (r) => COMMERCIAL_ROUTES.has(r),
        params: {
          filters: marketplaceChanged(route, Route.CommercialMarketLanding) ? {} : params.filters,
          dealType: params.dealType || DealType.Rent,
          marketplace: MarketplaceType.Commercial,
          term: params.term || [ config.cityTerm ],
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.CheckAddress,
        activePredicate: (r, mode) => mode === AppMode.Check && !COMMERCIAL_ROUTES.has(r),
        label: t('navigation.header.mode', { mode: AppMode.Check, isMobile: !isDesktop }),
        params: {
          term: params.term,
          id: undefined,
          address: undefined,
          page: undefined,
          dealType: undefined,
          filters: undefined,
        },
      },
    ];
  }

  if (isDesktop) {
    return [
      {
        type: HeaderLinkType.Inner,
        routeName: Route.Search,
        activePredicate: (r, mode) => mode === AppMode.Buy && !COMMERCIAL_ROUTES.has(r),
        label: t('navigation.header.modeNew', { mode: AppMode.Buy, isMobile: !isDesktop }),
        seoLinks: SEO_LINKS[AppMode.Buy],
        params: {
          term: COMMERCIAL_ROUTES.has(route.name) ? [ config.cityTerm ] : params.term || [ config.cityTerm ],
          id: undefined,
          address: undefined,
          page: DealType.Buy !== params.dealType || marketplaceChanged(route, Route.Search) || isDeveloperIndexPrevRoute(route) ? undefined : params.page,
          dealType: DealType.Buy,
          marketplace: MarketplaceType.Residential,
          filters: marketplaceChanged(route, Route.Search) ? {} : (
            savedPriceFilter ? {
              ...params.filters,
              ...savedPriceFilter.residentialBuyFilter,
              dealType: DealType.Buy,
            } : params.filters
          ),
          isMapExpanded: undefined,
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.Search,
        activePredicate: (r, mode) => mode === AppMode.Rent && !COMMERCIAL_ROUTES.has(r),
        label: t('navigation.header.modeNew', { mode: AppMode.Rent, isMobile: !isDesktop }),
        seoLinks: SEO_LINKS[AppMode.Rent],
        params: {
          term: COMMERCIAL_ROUTES.has(route.name) ? [ config.cityTerm ] : params.term || [ config.cityTerm ],
          id: undefined,
          address: undefined,
          page: DealType.Rent !== params.dealType || marketplaceChanged(route, Route.Search) || isDeveloperIndexPrevRoute(route) ? undefined : params.page,
          dealType: DealType.Rent,
          marketplace: MarketplaceType.Residential,
          filters: marketplaceChanged(route, Route.Search) ? {} : (
            savedPriceFilter ? {
              ...params.filters,
              ...savedPriceFilter.residentialRentFilter,
              dealType: DealType.Rent,
            } : params.filters
          ),
          isMapExpanded: undefined,
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.CommercialMarketLanding,
        activePredicate: (r) => COMMERCIAL_ROUTES.has(r) && !COMMERCIAL_INFO_PAGES_ROUTES.has(r),
        label: t('navigation.header.commercial.landing.link'),
        seoLinks: SEO_LINKS.commercial,
        params: {
          filters: marketplaceChanged(route, Route.CommercialMarketLanding) ? {} : params.filters,
          marketplace: MarketplaceType.Commercial,
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.CheckAddress,
        activePredicate: (r, mode) => (mode === AppMode.Check && !COMMERCIAL_ROUTES.has(r)) || COMMERCIAL_INFO_PAGES_ROUTES.has(r),
        label: t('navigation.header.modeNew', { mode: AppMode.Check, isMobile: !isDesktop }),
        params: {
          term: params.term,
          id: undefined,
          address: undefined,
          page: undefined,
          dealType: undefined,
          filters: undefined,
        },
      },
      {
        type: HeaderLinkType.Inner,
        label: t('navigation.header.madadLink'),
        activePredicate: (r) => r === Route.MadadPage,
        routeName: Route.MadadPage,
        seoLinks: SEO_LINKS.madad,
        params: {},
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.DevelopersSearchPage,
        activePredicate: (r) => r === Route.DevelopersSearchPage,
        label: t('navigation.header.developersLink'),
        params: {
          id: undefined,
          page: undefined,
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.MortgageOfficesSearchPage,
        activePredicate: (r) => r === Route.MortgageOfficesSearchPage,
        label: t('mortgage.officePage.header.link'),
        params: {
          term: undefined,
        },
      },
      {
        type: HeaderLinkType.Outer,
        name: 'blog',
        seoLinks: SEO_LINKS.blog,
        label: t('navigation.header.modeNew', { mode: 'blog', isMobile: false }),
        href: '//www.madlan.co.il/blog/',
      },

    ];
  }
  else {
    return [
      {
        type: HeaderLinkType.Inner,
        routeName: Route.Search,
        activePredicate: (r, mode) => mode === AppMode.Buy && !COMMERCIAL_ROUTES.has(r),
        label: t('navigation.header.mode', { mode: AppMode.Buy, isMobile: !isDesktop }),
        seoLinks: SEO_LINKS[AppMode.Buy],
        params: {
          term: route.name === Route.CheckAddress ? [ config.cityTerm ] : params.term || [ config.cityTerm ],
          id: undefined,
          address: undefined,
          page: DealType.Buy !== params.dealType || marketplaceChanged(route, Route.Search) ? undefined : params.page,
          dealType: DealType.Buy,
          marketplace: MarketplaceType.Residential,
          filters: marketplaceChanged(route, Route.Search) ? {} : (
            savedPriceFilter ? {
              ...params.filters,
              ...savedPriceFilter.residentialBuyFilter,
              dealType: DealType.Buy,
            } : params.filters
          ),
        },
      },
      {
        type: HeaderLinkType.Inner,
        routeName: Route.Search,
        activePredicate: (r, mode) => mode === AppMode.Rent && !COMMERCIAL_ROUTES.has(r),
        label: t('navigation.header.mode', { mode: AppMode.Rent, isMobile: !isDesktop }),
        seoLinks: SEO_LINKS[AppMode.Rent],
        params: {
          term: route.name === Route.CheckAddress ? [ config.cityTerm ] : params.term || [ config.cityTerm ],
          id: undefined,
          address: undefined,
          page: DealType.Rent !== params.dealType || marketplaceChanged(route, Route.Search) ? undefined : params.page,
          dealType: DealType.Rent,
          marketplace: MarketplaceType.Residential,
          filters: marketplaceChanged(route, Route.Search) ? {} : (
            savedPriceFilter ? {
              ...params.filters,
              ...savedPriceFilter.residentialRentFilter,
              dealType: DealType.Rent,
            } : params.filters
          ),
        },
      },
    ];
  }
}
