import styled from '@emotion/styled';
import { BreakpointValue } from 'consts/breakpoints';
import { breakpointToContentSlotPadding } from './Layout';
import { SpacingScale } from 'ds/theme/spacing';

const INSIGHTS_SECTION_MOBILE_PADDING = 4;

export const InsightsSection = styled.div<{ isSolidGrey?: boolean; mBottom?: SpacingScale; mTop?: SpacingScale; isTransparent?: boolean }>`
  box-sizing: border-box;
  background: ${({ theme, isSolidGrey, isTransparent }) => isTransparent ? 'transparent' : isSolidGrey
    ? theme.colors.neutrals.grey9
    : `linear-gradient(to bottom, ${theme.colors.neutrals.white}, ${theme.colors.neutrals.grey9} 15%)`
  };
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    padding: 0 ${INSIGHTS_SECTION_MOBILE_PADDING}px;
  `}
  margin-top: ${({ theme, mTop }) => theme.spacing(mTop || 7)};
  ${({ mBottom, theme }) => mBottom ? `margin-bottom: ${theme.spacing(mBottom)};` : ''}
`;

type Side = 'left' | 'both';

export const InsightCarouselShiftedPadding = styled.div<{ side?: Side, hideShadow?: boolean, customGradientColor?: string }>`
  min-width: 0;
  width: 100%;
  ${({ theme, side = 'left', hideShadow, customGradientColor }) => Object.keys(breakpointToContentSlotPadding).slice(2).map(key => {
    const padding = theme.spacing(breakpointToContentSlotPadding[key]);
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
      .view-pager-rail {
        ${side === 'left' ? `
          padding-${theme.isRTL ? 'right' : 'left'}: ${padding};
        ` : `
          padding: 0 ${padding};
        `}
      }
      position: relative;
      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 75px;
        pointer-events: none;
        z-index: 1;
      }
      &:before {
        ${hideShadow ? '' : `background: linear-gradient(to right, ${customGradientColor ? customGradientColor : theme.colors.neutrals.grey9}, rgba(255,255,255,0) 50%);` }
        left: 0;
      }
      &:after {
        ${hideShadow ? '' : `background: linear-gradient(to left, ${customGradientColor ? customGradientColor : theme.colors.neutrals.grey9}, rgba(255,255,255,0) 50%);` }
        right: 0;
      }
    `;
  })}
`;

export const breakpointToCategoryPadding: Record<BreakpointValue, number> = {
  1: 96,
  2: 112,
  3: 128,
  4: 152,
  5: 168,
};

export const InsightsSectionItem = styled.article<{ staticPadding?: boolean; }>`
  ${({ theme, staticPadding }) => Object.keys(breakpointToCategoryPadding).map(key => {
    const padding = breakpointToCategoryPadding[key];
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
      padding-bottom: ${staticPadding ? theme.spacing(5) : `${padding}px`};
    `;
  })}
  &:empty {
    padding: 0;
  }
`;

const breakpointToItemInnerMargin: Record<BreakpointValue, number> = {
  1: 16,
  2: 24,
  3: 48,
  4: 48,
  5: 48,
};

export const InsightsSectionItemInnerPadding = styled.div`
  ${({ theme }) => Object.keys(breakpointToItemInnerMargin).map(key => {
    const margin = breakpointToItemInnerMargin[key];
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
      margin-bottom: ${margin}px;
    `;
  })}
`;

export const ComplianceSection = styled.div<{ isInsightSectionPresent?: boolean, collapsedWrapper: boolean, isContactModeB?: boolean }>`
  padding-bottom: ${({ theme, isContactModeB }) => isContactModeB ? '81px' : theme.spacing(8)};
  background: ${({ theme, isInsightSectionPresent, collapsedWrapper }) => isInsightSectionPresent && !collapsedWrapper ? theme.colors.neutrals.grey11 : theme.colors.neutrals.white};
`;
