import { createSelector } from 'reselect';

import { IInsight, InsightDescriptor, InsightType } from 'utils/entities';
import { makeInsightsByTypeSelector } from 'store/state/domainData/selectors';


export const carouselDataMapper = (insights: IInsight[]) => (
  insights.map(i => ({
    id: i.id,
    preview: i.preview,
  }))
);

const makeInsightsSelector = (insightType: InsightType) => (insights: IInsight[]) => (
  insights ? insights.filter(i => i.category === insightType) : []
);

const makeILCarouselInsightsDescriptorSelector = (insightType: InsightType) => createSelector([
  makeInsightsByTypeSelector(InsightDescriptor.AnalystInsight),
  makeInsightsByTypeSelector(InsightDescriptor.DangerousIntersections),
], (analystInsights = [], dangerousIntersectionsInsights = []) => (
  makeInsightsSelector(insightType)([ ...analystInsights, ...dangerousIntersectionsInsights ])
));

export const makeCarouselDataSelectorByType = (insightType: InsightType) => createSelector([
  makeILCarouselInsightsDescriptorSelector(insightType),
], carouselDataMapper);

export const hasInsightsCarouselDataByTypeSelector = (insightType: InsightType) => createSelector([
  makeILCarouselInsightsDescriptorSelector(insightType),
], (insights) => Boolean(insights) && insights.length > 0);
