import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import Modal from 'ds/components/modal';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { H3, Text } from 'ds/components/typography';
import { SpacingBlock } from 'components/spacing-block';
import BellIcon from 'assets/svg/notification.svg';
import CheckIcon from 'assets/svg/success-ic.svg';
import { BasicButton } from 'ds/components/button/BasicButton';
import { Route } from 'config/routes';
import { routeSelector } from 'store/state/selectors/router';
import { State as RouteState, NavigateToFn } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';

const Root = styled.div`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.neutrals.grey1};
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(6)};
  `}
`;

const IconWrapper = styled.div`
  ${({ theme }) => `
    height: 80px;
    width: 80px;
    border-radius: ${theme.borderRadius.round};
    background-color: #E9F1F2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  `}
`;

const SubHeader = styled(Text)`
  white-space: pre;
`;

const ButtonWrapper = styled.div`
  width: 214px;
  margin: 0 auto;
`;

interface SuccessPopupProps {
  isEdit: boolean;
  route: RouteState;
  navigate: NavigateToFn;
  onClose: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ isEdit, route, navigate, onClose }) => {
  const { t } = useLocale();

  const isDealsRoute = route.name === Route.Deals;

  const onButtonClick = isDealsRoute
    ? onClose
    : () => {
      onClose();
      navigate(Route.Deals, {});
    };

  return (
    <Modal
      isOpen
      desktopModalPositionCenter
      onClose={onClose}
    >
      <Root>
        <IconWrapper>
          {isEdit ? <BellIcon height={60} width={60} /> : <CheckIcon />}
        </IconWrapper>
        <SpacingBlock mTop={3}>
          <H3 weight="bold" data-auto="popup-header">{t('dealAffiliationPopup.success.header', { isEdit })}</H3>
        </SpacingBlock>
        <SpacingBlock mTop={0.5} mBottom={4}>
          <SubHeader data-auto="popup-subheader">{t('dealAffiliationPopup.success.subheader', { isEdit })}</SubHeader>
        </SpacingBlock>
        <ButtonWrapper>
          <BasicButton onClick={onButtonClick} fullWidth size="large" data-auto="popup-button">
            {t('dealAffiliationPopup.success.button', { isDealsRoute })}
          </BasicButton>
        </ButtonWrapper>
      </Root>
    </Modal>
  );
};

const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
});

const mapDispatchToProps = {
  navigate: navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPopup);
