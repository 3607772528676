import styled from '@emotion/styled';
import { H3, H2 } from 'ds/components/typography';
import { Link } from 'ds/components/link';
import { typographyFontSizeCfg } from 'ds/components/typography/types';


export const SectionHeader = styled.div<{ withTitle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ withTitle }) => withTitle ? 'space-between' : 'flex-end'};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  white-space: nowrap;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column;
    align-items: baseline;
  `}
`;

export const SectionTitle = styled(H2.withComponent('div'))`
  ${({ theme }) => `
    && {
      font-size: ${typographyFontSizeCfg.h2.mobile}px;
      color: ${theme.colors.neutrals.grey1};
    }
  `}
`;

export const StyledLink = styled(Link)<{ weight?: string }>`
  font-weight: ${({ weight = 'bold' }) => weight};
`;

export const Wrapper = styled.div<{ isLanding?: boolean }>`
  margin: 0 auto;
  ${({ theme, isLanding }) => isLanding ? theme.media.showAt({ at: 1 })`padding: 0 ${theme.spacing(2)};` : ''};
  ${({ theme, isLanding }) => isLanding ? theme.media.showAt({ at: 2 })`padding: 0 ${theme.spacing(5)};` : ''};
  ${({ theme }) => theme.media.showAt({ at: 3 })`max-width: 864px;`};
  ${({ theme }) => theme.media.showAt({ from: 4 })`max-width: 1144px;`};
`;

export const BlogWrapper = styled.div<{ isMobile: boolean }>`
  ${({ theme, isMobile }) => `
    padding: ${theme.spacing(isMobile ? 4 : 9)} 0;
    background: ${theme.colors.neutrals.grey10};
  `};
  width: 100%;
`;

export const BlogCart = styled.a`
  width: 280px;
  height: 300px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  ${({ theme }) => `
    background-color: ${theme.colors.neutrals.white};
    border: solid 1px ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.small};
    margin: 0 ${theme.spacing(0.5)};
  `}
`;

export const BlogImg = styled.div<{ src: string }>`
  height: 136px;
  background-image: url(${({ src }) => src});
  background-position: 50%;
  background-size: cover;
`;

export const BlogContent = styled(H3.withComponent('div'))`
  padding: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const RowSectionWrapper = styled.div<{ isLanding?: boolean }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.showAt({ at: 1 })`margin: 0 ${theme.spacing(2)} ${theme.spacing(9)} auto; justify-content: start;`};
  ${({ theme }) => theme.media.showAt({ at: 2 })`margin-bottom: ${theme.spacing(5)};margin-right: ${theme.spacing(2)};justify-content: start;`};

  & > * {
    :first-of-type {
        ${({ theme }) => theme.media.showAt({ from: 1, to: 2 })`flex-basis: 100% !important;`};
    }

    :last-of-type {
      ${({ theme, isLanding }) => theme.media.showAt({ from: 2, to: 3 })`display: ${isLanding ? 'none' : 'flex'}`};
    }
  }
`;
