import React from 'react';

import styled from '@emotion/styled';

import { BasicButton, BasicButtonProps } from './BasicButton';

import CheckmarkIcon from 'assets/svg/checkmark.svg';

const DotsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  & > div:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
  & > div:nth-of-type(1) {
    background: rgba(255, 255, 255, .5);
    animation: fadeIn 1s ease 0s infinite;
  }
  & > div:nth-of-type(2) {
    background: rgba(255, 255, 255, .75);
    animation: fadeIn 1s ease .1s infinite;
  }
  & > div:nth-of-type(3) {
    background: rgba(255, 255, 255, 1);
    animation: fadeIn 1s ease .2s infinite;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  background: ${props => props.theme.colors.neutrals.white};
`;

export const LoadingDots: React.FunctionComponent<{}> = () => (
  <DotsWrapper>
    {Array(3).fill(null).map((_, id) => <Dot key={id} />)}
  </DotsWrapper>
);

export interface FeedbackButtonProps extends BasicButtonProps {
  feedbackText?: string;
  isLoading?: boolean;
  onClick?: (e?: React.MouseEvent<EventTarget>) => void;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
}

export const FeedbackButton: React.FC<FeedbackButtonProps> = ({ children, feedbackText, isLoading, ...rest }) => {
  let content = null;

  content = (
    <>
      {children ? children : null}
    </>
  );

  if (feedbackText) {
    content = (
      <>
        <CheckmarkIcon />
        {feedbackText}
      </>
    );
  }

  if (isLoading) content = <LoadingDots />;

  return (
    <BasicButton {...rest}>
      {content}
    </BasicButton>
  );
};
