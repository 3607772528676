import withEvents from 'analytics/withEvents';
import Carousel, { CarouselProps } from './Carousel';

const CarouselWithEvents = withEvents<CarouselProps>((sendEvent, props) => ({
  setSlideIndex(index) {

    if (index === props.slideIndex) {
      return null;
    }

    if (index < props.thumbnails.length) {

      let dir = props.slideIndex < index ? 'right' : 'left';

      const thumbnailsLength = Math.max(props.thumbnails.length - 1, 0);
      const length = thumbnailsLength;

      if (index === 0 && props.slideIndex === length) {
        dir = 'left';
      }
      else if (props.slideIndex === 0 && index === thumbnailsLength) {
        dir = 'right';
      }

      const image = props.thumbnails[index];

      sendEvent('property_view_photo_scroll', 'property', {
        bulletinId: props.id,
        event: {
          direction_scrolled: dir,
          image: {
            description: image.description,
            image_url: image.url,
            isFloorPlanPhoto: Boolean(image.isFloorPlan),
          },
          image_index: index,
          index_total: props.thumbnails.length,
        },
      });
    }
  },
}))(Carousel);

export default CarouselWithEvents;
