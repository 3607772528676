import React from 'react';
import styled from '@emotion/styled';
import { InsightCarouselShiftedPadding } from 'ds/components/Insights';
import { Align, ViewPager } from 'components/view-pager';
import Arrow from 'assets/svg/left.svg';
import ArrowIcon from 'assets/svg/control-chevron.svg';
import { AlignItemsProperty } from 'csstype';
import { useTheme } from 'hooks/useTheme';
import { SlideBtn, SlideArrow } from 'ds/components/carousels/styled';
import { Pager } from 'components/cards/listing/Pager';
import { ShowAt } from 'consts/breakpoints';
import { BaseCarouselPagerWrapper } from './styled';

export interface BaseCarouselProps {
  noPadding?: boolean;
  children: React.ReactNode[];
  align?: Align;
  verticalAlign?: AlignItemsProperty;
  hideControls?: boolean;
  hideShadow?: boolean;
  activeCarouselSlide?: number;
  onSlideIndexChange?: (slideIndex: number) => void;
  withPagerBullets?: boolean;
  disableSwipe?: boolean;
  slideWithArrow?: boolean;
  customGradientColor?: string;
  entryContentDXOverride?: number;
  buttonShadowLevel?: string;
  gap?: number; // Space between carousel items
}

export const BaseCarouselWrapper = styled.div<{noPadding?: boolean}>`
  position: relative;
  display: flex;
  width: 100%;

  .slide-btn {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
  }

  &:hover .slide-btn {
    opacity: 1;
    visibility: visible;
  }

  ${({ noPadding = false }) => noPadding ? `
    > div > .view-pager-rail {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  ` : ''}
`;

export const BaseCarousel: React.FC<BaseCarouselProps> = (props) => {
  const { isRTL } = useTheme();
  const { children, entryContentDXOverride, align, activeCarouselSlide, onSlideIndexChange, hideControls, verticalAlign, hideShadow, withPagerBullets, disableSwipe, slideWithArrow, customGradientColor, buttonShadowLevel, gap } = props;
  const childrenCount = React.Children.count(children);
  const SlideControls = slideWithArrow ? SlideArrow : SlideBtn;

  return (
    <BaseCarouselWrapper>
      <InsightCarouselShiftedPadding hideShadow={hideShadow} className="carousel-shadow-root" customGradientColor={customGradientColor}>
        <ViewPager
          onSlideIndexChange={onSlideIndexChange}
          entryContentDXOverride={entryContentDXOverride}
          slideIndex={activeCarouselSlide}
          isRTL={isRTL}
          align={align}
          verticalAlign={verticalAlign}
          disableSwipe={disableSwipe}
          gap={gap}
        >
          {children}
        </ViewPager>
      </InsightCarouselShiftedPadding>

      {hideControls || activeCarouselSlide === 0 ? null : (
        <SlideControls className="slide-btn" dir="prev" onClick={() => onSlideIndexChange(activeCarouselSlide - 1)} buttonShadowLevel={buttonShadowLevel}>
          {slideWithArrow ? <ArrowIcon/>  : <Arrow/>}
        </SlideControls>
      )}

      {hideControls || (activeCarouselSlide === childrenCount - 1) ? null : (
        <SlideControls className="slide-btn" dir="next" onClick={() => onSlideIndexChange(activeCarouselSlide + 1)} buttonShadowLevel={buttonShadowLevel}>
          {slideWithArrow ? <ArrowIcon/> : <Arrow/>}
        </SlideControls>
      )}

      <ShowAt at={1}>
        {withPagerBullets ? (<BaseCarouselPagerWrapper>
          <Pager
            total={childrenCount}
            onChangeIndex={onSlideIndexChange}
            index={activeCarouselSlide}
          />
        </BaseCarouselPagerWrapper>) : null}
      </ShowAt>
    </BaseCarouselWrapper>
  );
};

BaseCarousel.defaultProps = {
  align: 'left',
};
