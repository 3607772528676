import styled from '@emotion/styled';
import { H1, Text } from 'ds/components/typography';

export const MainInfoWrapper = styled.section`
  display: flex;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  justify-content: space-between;
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    display: block;
    margin-bottom: ${theme.spacing(3)};
  `};
`;

export const AddressWrapper = styled.div`
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 32px;
  flex-grow: 1;
  text-transform: capitalize;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    width: 100%;
  `};
`;

const PrimaryAddressDefault = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(0.25)};
  `}
`;

export const PrimaryAddress = styled(PrimaryAddressDefault)`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(0.5)};
  `};
`;

export const PrimaryAddressText = styled(H1)<{isCommercial?: boolean}>`
  ${({ theme }) => `
    font-weight: bold;
    color: ${theme.colors.neutrals.grey1};
  `}
  ${({ isCommercial }) => isCommercial ? `
    & {
      font-size: 24px;
    }
  ` : ''}
`;

export const SecondaryAddress = styled.div`
  display: flex;
`;

export const SecondaryAddressText = styled(Text)<{isCommercial?: boolean}>`
  display: inline-block;
  color: ${({ theme, isCommercial }) => isCommercial ? theme.colors.neutrals.grey3 : theme.colors.neutrals.grey1};
`;

const PriceWrapperDefault = styled.div`
  text-align: ${({ theme }) => theme.isRTL ? 'left' : 'right'};
  flex-shrink: 0;
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin-top: ${theme.spacing(3)};
    width: 100%;
    text-align: ${theme.isRTL ? 'right' : 'left'};
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const PriceWrapper = styled(PriceWrapperDefault)`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-top: ${theme.spacing(2)};
  `}
`;

export const CurrentPrice = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  display: inline-block;
  ${({ theme }) => theme.isRTL ? '' : 'margin-left: 8px;'}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    order: 0;
    ${theme.isRTL ? `
      margin-left: 8px;
    ` : `
      margin-left: 0px;
    `}
  `};
`;

export const CurrentPriceText = styled(H1.withComponent('div'))<{isCommercial?: boolean}>`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  ${({ isCommercial }) => isCommercial ? `
    & {
      font-size: 24px;
    }
  ` : ''}
`;

export const DealTypeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin-bottom: 8px;
  `};
`;

export const DealTypeInfo = styled.div`
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const OfferRelevancyLabel = styled.div<{ isNew: boolean }>`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  padding: 3px 4px 1px;
  background: ${({ theme, isNew }) => isNew ? theme.colors.label.yellow : theme.colors.neutrals.grey8};
`;

export const OfferDetails = styled.div<{isMobile?: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme, isMobile }) => isMobile && `
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const OfferDetailWrapper = styled.div<{ isDense?: boolean }>`
  ${({ theme, isDense }) => `
    height: 24px;
    display: flex;
    align-items: center;
    ${isDense ? `margin-bottom: ${theme.spacing(0.5)};` : ''}
    svg {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }

    sup {
      position: relative;
      top: -5px;
      font-size: smaller;
    }

    &::after {
      content: '';
      display: block;
      height: 14px;
      margin: 0 ${isDense ? theme.spacing(1) : theme.spacing(2)};
      width: 1px;
      background: ${theme.colors.neutrals.grey6};
    }
    &:last-child::after {
      display: none;
    }
    margin-bottom: ${theme.spacing(1)};
  `}
`;

export const PriceFirstLine = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  `};
`;

export const PreviousPrice = styled(Text)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(0.5)};
    color: ${theme.colors.neutrals.grey3};
    text-decoration-line: line-through;
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin-right: ${theme.spacing(2)};
  `}
`;
