import React, { useCallback } from 'react';
import { NavigateToFn, Route, State as RouteState } from 'config/routes';
import { useLocale } from 'locale';
import styled from '@emotion/styled';
import { State } from 'store/state';
import { prevRouteSelector } from 'store/state/selectors/router';
import { navigateTo } from 'store/state/router/actions';
import { connect } from 'react-redux';
import CloseIcon from 'assets/svg/close-modal.svg';

const BackNavigationHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  & svg {
    position: absolute;
    left: 0;
  }
`;

const ShortlistMobileHeaderBase: React.FC<{prevRoute?: RouteState; navigate?: NavigateToFn }> = (({ prevRoute, navigate }) => {
  const { t } = useLocale();
  const handleClick = useCallback(() => {
    if (prevRoute) {
      window.history.back();
    }
    else {
      navigate(Route.Home, {}, { replace: true });
    }
  }, [ prevRoute, navigate ]);
  return (
    <BackNavigationHeaderWrapper>
      {t('navigation.header.actions', { action: 'savedListings' })}
      <CloseIcon onClick={handleClick} width={24} height={24} />
    </BackNavigationHeaderWrapper>
  );
});

const mapStateToProps = (state: State) => ({
  prevRoute: prevRouteSelector(state),
});

const mapDispatchToProps = {
  navigate: navigateTo,
};

export const ShortlistMobileHeader = connect(mapStateToProps, mapDispatchToProps)(ShortlistMobileHeaderBase);
