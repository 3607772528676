import React from 'react';
import {
  InputWrapper,
  TextAreaField,
  AssistiveText,
} from 'ds/components/input/styled';
import { InputProps } from './Input';

export interface TextareaProps extends InputProps<HTMLTextAreaElement> {
  rows?: number;
  charactersCounterMax?: number;
}


export class Textarea extends React.Component<TextareaProps, never> {
  public render () {
    const { placeholder, hasError, rows, disabled, charactersCounterMax, ...rest } = this.props;
    return (
      <InputWrapper>
        <TextAreaField
          style={{ resize: 'none' }}
          placeholder={placeholder}
          hasError={hasError}
          disabled={disabled}
          rows={rows}
          {...rest}
        />
        {charactersCounterMax ? <AssistiveText data-auto="textfield-assistive-text">{rest.value ? rest.value.toString().length : 0}/{charactersCounterMax}</AssistiveText> : null}
      </InputWrapper>
    );
  }
}
