import React from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { State } from 'store/state';
import withEvents from 'analytics/withEvents';
import { LinksSection, SectionToLinks } from './types';
import { LinksSectionWrapper } from './styled';
import { MenuLink } from './MenuLink';
import { staticLinksSelector } from 'components/navigation/more-actions-menu/staticLinks';
import { insideReactNative } from 'react-native/lib/platform';

interface StaticLinksSectionsProps {
  onMadadLinkClick?: () => void;
  sections: SectionToLinks;
}

const StaticLinksSectionsBase: React.FC<StaticLinksSectionsProps> = ({ onMadadLinkClick, sections }) => {
  const staticLinksSections: LinksSection[] = [
    LinksSection.ManageBulletin,
    LinksSection.Tools,
    LinksSection.Guides,
    LinksSection.Localize,
  ];

  const isInsideRN = insideReactNative();

  return (
    <>
      {staticLinksSections.map(section => (
        <LinksSectionWrapper key={section}>
          {sections[section].map(link => (link.hideInApp && isInsideRN) ? null : (
            <MenuLink
              onClick={link.path === '/madad?source=left_menu' ? onMadadLinkClick : noop}
              target={link.path === '/upload-bulletin?source=left_menu' ? '_self' : '_blank'}
              routeName={link.routeName}
              key={link.label}
              href={link.path}
            >
              {link.label}
            </MenuLink>
          ))}
        </LinksSectionWrapper>
      ))}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  sections: staticLinksSelector(state),
});

const StaticLinksSectionsWithEvents = withEvents<StaticLinksSectionsProps>((sendEvent) => ({
  onMadadLinkClick() {
    sendEvent('madad_click', 'madad');
  },
}))(StaticLinksSectionsBase);

export const StaticLinksSections = connect(mapStateToProps)(StaticLinksSectionsWithEvents);
