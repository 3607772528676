import React from 'react';
import { withLocale, WithLocaleProps } from 'locale';
import { CleanButton } from 'ds/components/button';
import { FloorplanButtonWrapper } from './styled';

interface FloorplanButtonProps extends Pick<WithLocaleProps, 't'> {
  openFullscreen(isMobile: boolean): () => void;
}

const FloorplanButton: React.FunctionComponent<FloorplanButtonProps> = ({ openFullscreen, t }) => (
  <FloorplanButtonWrapper>
    <CleanButton mode="secondaryNegative" onClick={openFullscreen(true)}>
      {t('unitPage.floorPlan')}
    </CleanButton>
  </FloorplanButtonWrapper>
);

export default  withLocale(FloorplanButton);
