import React from 'react';
import Modal from 'ds/components/modal';
import { Text } from 'ds/components/typography';
import DoneIcon from 'assets/svg/done.svg';
import { SuccessPopupContent, IconWrapper, Title } from './styled';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { setContactAgentSuccessPoiId } from 'store/state/app/actions';
import { useLocale } from 'locale';
import { IBulletin, IProject, PoiId, ICommercialProject, ICommercialBulletin } from 'utils/entities';
import { poiFromContactAgentSuccessSelector } from '../selectors';
import { getPOCName } from 'helpers/contact';
import { useInterval } from 'hooks/useInterval';
import { isBulletinPoiType } from 'utils/marketplaceRoutes';

export interface SuccessPopupProps {
  poi: IBulletin | IProject | ICommercialProject | ICommercialBulletin;
  setOpenPoiId: (poiId: PoiId) => void;
}

const ContactAgentSuccessPopupView: React.FC<SuccessPopupProps> = ({
  poi,
  setOpenPoiId,
}) => {
  if (!poi) return null;

  const { t } = useLocale();
  const poc = isBulletinPoiType(poi) && poi.poc;

  useInterval(() => setOpenPoiId(null), 2000, []);

  return (
    <Modal
      isOpen={true}
      onClose={() => setOpenPoiId(null)}
      mobileModalPositionBottom
    >
      <SuccessPopupContent>
        <IconWrapper>
          <DoneIcon data-auto="done-icon" width={60} height={60} />
        </IconWrapper>
        <Title weight="bold">
          {t('contactAgentModal.submitSuccessMessage')}
        </Title>
        <Text>
          {t('contactAgentModal.submitSuccessDescription', { agentName: getPOCName(poc) })}
        </Text>
      </SuccessPopupContent>
    </Modal>
  );
};

const mapStateToProps = (state: State) => ({
  poi: poiFromContactAgentSuccessSelector(state),
});

const mapDispatchToProps = {
  setOpenPoiId: setContactAgentSuccessPoiId,
};

export const ContactAgentSuccessPopup = connect(mapStateToProps, mapDispatchToProps)(ContactAgentSuccessPopupView);
