import React, { useRef } from 'react';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

interface InViewportListenerProps {
  children: React.ReactNode;
  onEnterViewport?: () => void;
  onLeaveViewport?: () => void;
}

export const InViewportListener: React.FC<InViewportListenerProps> = ({ children, onEnterViewport, onLeaveViewport }) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver(([ entry ]) => {
    if (entry.intersectionRatio === 1) {
      if (onEnterViewport) onEnterViewport();
    }
    else {
      if (onLeaveViewport) onLeaveViewport();
    }
  }, [ targetRef ]);

  return (
    <div ref={targetRef}>
      {children}
    </div>
  );
};
