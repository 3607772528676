import React from 'react';
import { reduce } from 'lodash';
import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { useLocale } from 'locale';
import { amenityToIconMap, extraAmenityToIconMap, commercialAmenities } from 'components/unit-page/amenities/config';
import { Amenity } from 'utils/entities';
import { AmenityChip } from './blocks/amenity-chip';


export const Root = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const Header = styled(Text.withComponent('h2'))(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
}));

export const AmenitiesContainer = styled(Text)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export enum ExtraAmenityType {
  People = 'people',
  GeneralCondition = 'generalCondition',
  QualityClass = 'qualityClass',
}

interface ExtraAmenity {
  type: ExtraAmenityType;
  value: string | number;
}

interface AmenitiesProps {
  isCommercial?: boolean;
  amenities: Record<Amenity, boolean>;
  extraAmenities?: ExtraAmenity[];
  children?: React.ReactNode;
}

type AmenitiesList = Array<{ icon: JSX.Element; title: string; locator: string }>;

const AmenitiesChips: React.FC<AmenitiesProps> = (props) => {
  const { t, formatNumber } = useLocale();
  const { amenities, extraAmenities = [], isCommercial = false, children } = props;

  const amenitiesList = reduce<Record<Amenity, boolean>, AmenitiesList>(amenities, (acc, value, amenity) => {
    const icon = amenityToIconMap[amenity];
    if (isCommercial && !commercialAmenities.has(amenity)) return acc;

    if (value && icon) {
      acc.push({
        locator: amenity,
        icon,
        title: t('unitPage.amenities.chip.title', { amenity }),
      });
    }
    return acc;
  }, []);

  const extraAmenitiesList: AmenitiesList = extraAmenities.map(({ type, value }) => {
    switch (type) {
      case ExtraAmenityType.People:
        return Boolean(value) ? {
          locator: type,
          icon: extraAmenityToIconMap[type],
          title: t('unitPage.commercial.amenities.chip.title', { type, value: formatNumber(+value) }),
        } : null;
      case ExtraAmenityType.GeneralCondition:
        return Boolean(value) ? {
          locator: type,
          icon: extraAmenityToIconMap[type],
          title: t('unitPage.buildingDetails.value', { value }),
        } : null;
      case ExtraAmenityType.QualityClass:
        return Boolean(value) ? {
          locator: type,
          icon: extraAmenityToIconMap[type],
          title: t('commercial.listing.offerDetails.qualityClass', { value }),
        } : null;
      default:
        return null;
    }
  }).filter(Boolean);

  if ((amenitiesList.length + extraAmenitiesList.length) <= 1) return null;

  return (
    <>
      <Root data-auto="amenities-block">
        <Header weight="bold">{t('unitPage.amenities.header')}</Header>
        <AmenitiesContainer>
          {[ ...extraAmenitiesList, ...amenitiesList ].map(chipProps => <AmenityChip key={chipProps.locator} {...chipProps} />)}
        </AmenitiesContainer>
      </Root>
      {children}
    </>
  );
};

export default React.memo(AmenitiesChips);
