import { useEffect, useRef } from 'react';


export interface IIntesertionObserverOptions {
  root?: React.MutableRefObject<HTMLElement>;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  targets: Array<(React.MutableRefObject<HTMLElement>)>,
  options: IIntesertionObserverOptions = {}
) => {
  const observer = useRef<IntersectionObserver>(null);
  const callbackRef = useRef<IntersectionObserverCallback>(callback);

  useEffect(() => {
    observer.current = new IntersectionObserver((...args) => callbackRef.current(...args), {
      root: options.root ? options.root.current : null,
      rootMargin: options.rootMargin,
      threshold: options.threshold,
    });

    return () => {
      observer.current.disconnect();
      // in order to assist GC
      observer.current = null;
    };
  }, [ options.root && options.root.current, options.rootMargin, options.threshold && options.threshold.toString() ]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [ callback ]);

  useEffect(() => {
    targets.forEach((target) => {
      if (target.current) observer.current.observe(target.current);
    });
  }, [ targets ]);

};
