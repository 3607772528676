import { all, call, put, getContext } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { navigateTo } from 'store/state/router/actions';

export function* madadSearchPageHandler(params: RouteState) {
  try {
    const docId = params.params.id;

    const loadLocalDocOptions: LoadOptions<LoadType.StrictLocalDoc> = {
      loadType: LoadType.StrictLocalDoc,
      meta: {
        variables: { docId },
      },
    };

    // const loadMadadAgentsOfficesOptions: LoadOptions<LoadType.MadadAgentsOffices> = {
    //   loadType: LoadType.MadadAgentsOffices,
    //   meta: {
    //     variables: {
    //       docIds: [ docId ],
    //       onlyWinners: false,
    //     },
    //   },
    // };

    const loadMadadAgentsOfficesOptions: LoadOptions<LoadType.MadadSearchAgentsOffices> = {
      loadType: LoadType.MadadSearchAgentsOffices,
      meta: {
        variables: {
          docIds: [ docId ],
          onlyWinners: false,
        },
      },
    };

    yield all([
      call(queryData, loadLocalDocOptions),
      call(queryData, loadMadadAgentsOfficesOptions),
    ]);
  }
  catch (e) {
    const logger = yield getContext('logger');
    logger.error('One of queries failed in madad search page handler, redirecting to madad landing.', e);
    yield put(navigateTo(Route.MadadPage, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }
}
