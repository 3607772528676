import React from 'react';
import { singleAutocompleteEnhancer } from '../gqlEnhancer';
import withEvents from 'analytics/withEvents';
import { SingleInputProps, AddressAutocompleteSource } from '../types';
import { SingleLocationAutocomplete } from './SingleLocationAutocomplete';

export const AriaSingleLocationAutocomplete = singleAutocompleteEnhancer(SingleLocationAutocomplete);

const SingleLocationAutocompleteWithEvents = React.memo(singleAutocompleteEnhancer(
  withEvents<SingleInputProps>((sendEvent) => ({
    sendOnClickEvent(source: AddressAutocompleteSource) {
      sendEvent('search_bar_text_click', 'search', { event: { source } });
    },
  }))(SingleLocationAutocomplete)));

export default SingleLocationAutocompleteWithEvents;

