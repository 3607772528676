import React from 'react';
import { flattenDeep, maxBy } from 'lodash';
import { PlaningDynamicContent } from './planing';
import { DynamicContentResult } from 'components/insight-widget-collapse';
import { SchoolsAccordionSection, ILSchoolsAccordionSection } from 'store/state/selectors/insights/schools/types';
import { INewDevelopmentJob, IQuietStreet } from 'store/state/selectors/insights/summaryTypes';
import { InsightSection } from 'store/state/insightsContext';
import { TransportationAccordionSection } from 'store/state/selectors/insights/transportation';
import { PricingDynamicContent } from './pricing';
import { NeighborsDynamicContent, NeighborsDynamicContentProps } from './neighbors';
import { PriceCollapsedDynamic } from 'components/widget/prices/section/selectors';
import { NuisancesDynamicContent } from './nuisances';
import { QuietStreetsDynamicContent } from './quiet-streets';
import { ILEducationDynamicContent } from './ilSchools';


export type DynamicData =
  INewDevelopmentJob[][]
  | SchoolsAccordionSection[]
  | TransportationAccordionSection[]
  | PriceCollapsedDynamic
  | IQuietStreet
  | NeighborsDynamicContentProps;

const safetyDataResolver = (data: null, widgetHasCarouselData: boolean): DynamicContentResult => {
  return {
    toRender: null,
    withContent: widgetHasCarouselData,
  };
};

const transportationDataResolver = (data: TransportationAccordionSection[], widgetHasCarouselData: boolean): DynamicContentResult => {
  return {
    toRender: null,
    withContent: widgetHasCarouselData,
  };
};

const newConstructionDataResolver = (data: INewDevelopmentJob[][], widgetHasCarouselData: boolean): DynamicContentResult => {
  const flattenData = data ? flattenDeep(data) : null;
  const getTimeLine = (o: { timeLine: { end: string } }) => (+o.timeLine.end.split(' ')[1]);
  const maxDataByTimeLine = flattenData ? maxBy(flattenData, getTimeLine) : null;
  const estEnd = maxDataByTimeLine ? maxDataByTimeLine.timeLine.end : null;

  return {
    toRender: <PlaningDynamicContent {...{ count: flattenData && flattenData.length, year: estEnd }} />,
    withContent: widgetHasCarouselData || !!(data && data.length),
  };
};

const ilEducationDataResolver = (data: ILSchoolsAccordionSection[], widgetHasCarouselData: boolean, onlyAnalystInsight: boolean): DynamicContentResult => {
  const hasSchools = Boolean(data && data.length);

  return {
    toRender: onlyAnalystInsight ? null : <ILEducationDynamicContent />,
    withContent: hasSchools || widgetHasCarouselData,
  };
};

const pricingDataResolver = (data: PriceCollapsedDynamic, widgetHasCarouselData: boolean, onlyAnalystInsight: boolean): DynamicContentResult => {

  const avgPrice = data && data.avgPrice;
  const yieldPrice = data && data.yieldPrice;

  return {
    toRender: onlyAnalystInsight ? null : (
      <PricingDynamicContent {...{ avgPrice, yieldPrice }} />
    ),
    withContent: !!avgPrice || !!yieldPrice || widgetHasCarouselData,
  };
};

const neighbourhoodDataResolver = (data: null, widgetHasCarouselData: boolean): DynamicContentResult => {
  return {
    toRender: null,
    withContent: widgetHasCarouselData,
  };
};

const nuisancesDataResolver = (data: null, widgetHasCarouselData: boolean): DynamicContentResult => {
  return {
    toRender: <NuisancesDynamicContent />,
    withContent: widgetHasCarouselData,
  };
};

const quietStreetsDataResolver = (data: IQuietStreet): DynamicContentResult => {
  return {
    toRender: <QuietStreetsDynamicContent />,
    withContent: !!data,
  };
};

const neighborsDataResolver = (data: NeighborsDynamicContentProps): DynamicContentResult => {
  const { text } = data;
  return {
    toRender: <NeighborsDynamicContent text={text} />,
    withContent: Boolean(text),
  };
};

const futurePlansResolver = (data: { text: string }): DynamicContentResult => {
  const { text } = data;
  return {
    toRender: null,
    withContent: Boolean(text),
  };
};

const divisionAreaPlanResolver = (data: { text: string }): DynamicContentResult => {
  const { text } = data;
  return {
    toRender: null,
    withContent: Boolean(text),
  };
};

const DYNAMIC_CONTENT_LIST = {
  [InsightSection.Safety]: safetyDataResolver,
  [InsightSection.Transportation]: transportationDataResolver,
  [InsightSection.Planning]: newConstructionDataResolver,
  [InsightSection.Schools]: ilEducationDataResolver,
  [InsightSection.Prices]: pricingDataResolver,
  [InsightSection.NeighbourhoodLife]: neighbourhoodDataResolver,
  [InsightSection.Nuisances]: nuisancesDataResolver,
  [InsightSection.QuietStreets]: quietStreetsDataResolver,
  [InsightSection.Neighbors]: neighborsDataResolver,
  [InsightSection.FuturePlans]: futurePlansResolver,
  [InsightSection.DivisionAreaPlan]: divisionAreaPlanResolver,
};

export const renderInsightCollapsedDynamicContent = (targetId: InsightSection, dynamicData: DynamicData, widgetHasCarouselData?: boolean, onlyAnalystInsight?: boolean) => {
  return DYNAMIC_CONTENT_LIST[targetId](dynamicData, widgetHasCarouselData, onlyAnalystInsight);
};
