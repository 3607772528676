import styled from '@emotion/styled';
import { Input } from 'ds/components/input/Input';
import { SmallText } from 'ds/components/typography';
import { ISelectSize } from './index';
import { typographyFontSizeCfg } from 'ds/components/typography/types';

export const Wrapper = styled.div`
  position: relative;
  color: ${props => props.theme.colors.neutrals.grey1};
  display: inline-flex; /* makes the menu follow the width of the wrapper */
`;

interface HidebaleProps {
  isSearchable?: boolean;
  isVisible: boolean;
  alignment?: 'left' | 'right';
  verticalPosition?: 'top' | 'bottom';
}

export const Hideable = styled.div<HidebaleProps>`
  display: ${props => props.isVisible ? 'inline-flex' : 'none'};
  ${props => {
    if (props.alignment === 'left') return 'right: 0';
    else if (props.alignment === 'right') return 'left: 0';
    else return props.theme.isRTL ? 'right: 0' : 'left: 0';
  }};
  position: absolute;
  min-width: 100%;
  ${props => props.verticalPosition === 'top' ? 'bottom' : 'top'}: 0;

  ${({ isSearchable }) => isSearchable ? `top: 42px` : ''};
`;

interface IToggleButton extends ISelectSize {
  noBorders?: boolean;
  greenBorder?: boolean;
}

export const ToggleButton = styled.button<IToggleButton>`
  border: ${({ noBorders, theme, disabled }) => noBorders ? 'none' : `1px solid ${theme.colors.neutrals.grey6}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${props => props.theme.colors.neutrals.white};
  box-sizing: border-box;
  height: ${props => props.size === 'large' ?  '40px' : '32px'};
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  ${props => props.theme.isRTL ? 'padding-left: 6px' : 'padding-right: 6px'};
  color: ${props => props.theme.colors.neutrals.grey1};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: 0;

  ${({ theme, noBorders }) => !noBorders ? `
    &:hover {
      box-shadow: ${theme.shadow.level2};
    }

    &:focus {
      border: 1px solid ${theme.colors.brand.main};
    }

    svg {
      fill: ${theme.colors.neutrals.grey1};
    }
  ` : ``}
  ${({ theme, disabled }) => disabled ? `
    &:hover {
      box-shadow: none;
    }
    background-color: ${theme.colors.neutrals.grey11};
    color: ${theme.colors.neutrals.grey4};
    cursor: not-allowed;
  ` : ''}

  ${({ theme, greenBorder }) => greenBorder ? `
    border: 1px solid ${theme.colors.brand.main};
  ` : ''}
`;

export const Menu = styled.div<{fullScreenMobileMenu?: boolean, zIndex: number}>`
  z-index: ${props => props.zIndex};
  background: ${props => props.theme.colors.neutrals.white};
  box-shadow: ${props => props.theme.shadow.level7};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing(1)};
  min-width: 100%;

  ${({ theme, fullScreenMobileMenu }) => theme.media.showAt({ to: 1 }) `
    top: ${fullScreenMobileMenu ? 0 : 'auto'};
    bottom: 0;
    left: 0;
    position: fixed;
    border-radius: ${theme.borderRadius.small} ${theme.borderRadius.small} 0 0;
    padding: 0;
  `}
`;

export interface ItemStyledProps extends ISelectSize {
  highlighted: boolean;
  selected: boolean;
}

export const ItemStyled = styled.div<ItemStyledProps>`
  padding: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    border-radius: ${({ theme }) => theme.borderRadius.tiny};
    background: ${props => props.theme.colors.neutrals.grey9};
  }

  ${props => props.highlighted && `
    background: ${props.theme.colors.neutrals.grey9};
  `}

  ${props => props.theme.media.showAt({ to: 1 }) `
    padding: ${props.theme.spacing(2)} ${props.theme.spacing(3)};
    justify-content: flex-start;
    border-top: 1px solid ${props.theme.colors.neutrals.grey8};
    ${props.selected && `
      color: ${props.theme.colors.brand.main};
    `}
  `}
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(248, 248, 249, 0.9);
  z-index: 1002;

  ${props => props.theme.media.showAt({ from: 2 }) `
    display: none;
  `}
`;

export const MenuHeader = styled.div<{ hasShadow: boolean }>`
  ${props => props.theme.media.showAt({ from: 2 }) `
    display: none;
  `}

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
  ${props => props.hasShadow && `box-shadow: ${props.theme.shadow.level2};`}
`;

export const CloseMenuButton = styled.div`
  display: flex;
`;

export const SelectedIcon = styled.div`
  display: flex;
  ${props => props.theme.isRTL ? 'margin-right: auto' : 'margin-left: auto'};

  ${props => props.theme.media.showAt({ from: 2 }) `
    display: none;
  `}

  > svg path {
    fill: ${props => props.theme.colors.brand.main};
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(2)};
  ${props => props.theme.media.showAt({ from: 2 }) `
    margin-${props.theme.isRTL ? 'left' : 'right'}: ${props.theme.spacing(1)};
  `}
`;

const GAP_WITH_TOP_OF_PAGE = 112; // organically grown and calculated constant
const SPACE_PER_LIST_ITEM = 36;

export const ItemList = styled.div<{ alignment?: 'left', visibleItemsCount?: number, fullScreenMobileMenu?: boolean  }>`
  ${({ theme, fullScreenMobileMenu }) => theme.media.showAt({ to: 1 }) `
    max-height: ${fullScreenMobileMenu ? `calc(100vh - ${GAP_WITH_TOP_OF_PAGE / 2}px)` : `calc(100vh - ${GAP_WITH_TOP_OF_PAGE}px)`};
    overflow-y: auto;
  `}
  ${({ theme, visibleItemsCount }) => theme.media.showAt({ from: 2 }) `
    max-height: ${visibleItemsCount ? SPACE_PER_LIST_ITEM * visibleItemsCount : 270}px;
    overflow-y: auto;
  `}
`;

export const DisplayInlineOption = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const RightIconWrapper = styled.span`
  ${({ theme }) => `
    margin: 0 ${theme.spacing(0.5)};
  `}
`;

export const SearchSelectInput = styled(Input)`
  border: none;
  height: 38px;
  padding-left: 0;
  padding-right: 0;

  input {
    font-size: ${typographyFontSizeCfg.text}px;
  }
`;

export const NoResults = styled(SmallText)`
  padding: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(5)};
`;
