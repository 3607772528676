import { put, call, select } from '@redux-saga/core/effects';
import { navigateTo } from 'store/state/router/actions';

import { LoadType, ISaveSearch } from 'store/sagas/apiService/types';
import { Route } from 'config/routes';
import { routeSelector } from 'store/state/selectors/router';
import { queryData } from 'store/sagas/apiService';
import { searchByIdSelector } from 'store/state/domainData/selectors';
import { SortField, SortDirection } from 'components/listing-sort/types';
import { formatQueryToFilters } from 'store/state/selectors/savedSearchPage';
import { marketplaceSelector } from 'store/state/selectors/router';


export function* savedSearchHandler() {

  const router = yield select(routeSelector);
  const marketplace = yield select(marketplaceSelector);
  if (router.params.id) {

    yield call(queryData, {
      loadType: LoadType.SearchBySearchId,
      meta: {
        variables: {
          searchId: router.params.id,
        },
      },
    });
    const searchBySearchId: ISaveSearch = yield select(searchByIdSelector);
    if (searchBySearchId.searchId) {
      const { query, term } = searchBySearchId;
      yield put(navigateTo(Route.Search, {
        term: Array.isArray(term) ? term : [ term ],
        filters: formatQueryToFilters(query, marketplace),
        dealType: query.dealType,
        sort: [ [ SortField.Date, SortDirection.Desc ] ],
      }, { replace: true, ssrHttpStatus: 301 }));
    }
    else {
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 301 }));
    }
  }
  else {
    yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 301 }));
  }
}
