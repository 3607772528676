import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { SmallText, Text } from 'ds/components/typography';

export const FreeUserMessageWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.05)};
    color: ${theme.colors.neutrals.grey2};
    padding: ${theme.spacing(2.5)};
    margin-top: ${theme.spacing(5)};
    border-radius: ${theme.borderRadius.small};
    display: flex;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    white-space: pre;
  `}
`;

export const ContactsWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    display: flex;
  `}
`;

export const ContactsItem = styled(SmallText)<{ withMargin?: boolean; }>`
  ${({ theme, withMargin }) => `
    ${withMargin ? `margin-left: ${theme.spacing(3)};` : ''}
    margin-top: ${theme.spacing(1)};
    a {
      text-decoration: none;
      color: ${theme.colors.neutrals.grey2};
    }
  `}
`;

export const ContactLabel = styled(Text)`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: ${theme.spacing(2.5)};
  `}
`;

export const ContactsItemInner = styled(SmallText)`
  ${({ theme }) => `
    display: flex;
    align-items: center;

    svg {
        margin-left: ${theme.spacing(0.5)};

        > path {
          fill: ${theme.colors.neutrals.grey2};
        }
      }
  `}
`;
