import Lockr from 'lockr';
import { TrackJS } from 'trackjs';

const shallowWrap = <T extends {}>(lib: T): T => {
  const result = {} as T;

  for (const prop in lib) {
    if (lib.hasOwnProperty(prop)) {
      if (typeof lib[prop] === 'function') {
        result[prop] = ((...args: any) => {
          try {
            return (lib[prop] as any).apply(result, args);
          }
          catch (e) {
            return null;
          }
        }) as any;
      }
      else {
        result[prop] = lib[prop];
      }
    }
  }

  return result;
};

const LocalStorage = shallowWrap(Lockr);


function testLocalStorage(testNum: number) {
  try {
    window.localStorage.setItem('__LS_PING__', '1');
    window.localStorage.removeItem('__LS_PING__');
  }
  catch (e1) {
    TrackJS.track([ `[LSTEST-${testNum}] Ping LS error`, e1, e1.toString() ]);

    try {
      const keys = Object.keys(window.localStorage);
      const entries = keys.map((key): [ string, string ] => [ key, window.localStorage.getItem(key) ]);
      const sortedEntries = entries.sort(([ , v1 ], [ , v2 ]) => v2.length - v1.length);
      const topLargestEntries = sortedEntries.slice(0, 5);

      TrackJS.track([ `[LSTEST-${testNum}] top entries`, topLargestEntries ]);
      TrackJS.track([ `[LSTEST-${testNum}] total entries`, window.localStorage.length ]);
    }
    catch (e2) {
      TrackJS.track([ `[LSTEST-${testNum}] LS top entries test error`, e2, e2.toString() ]);

      try {
        window.localStorage.clear();
        TrackJS.track([ `[LSTEST-${testNum}] LS cleared` ]);
      }
      catch (e3) {
        TrackJS.track([ `[LSTEST-${testNum}] LS clear error`, e3, e3.toString() ]);

      }
    }
  }
}
try {
  testLocalStorage(1);
  testLocalStorage(2);
}
catch (e) {
  TrackJS.track([ `[LSTEST] LS test error`, e, e.toString() ]);
}

export { LocalStorage };
