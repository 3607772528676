import React from 'react';
import { WithLocaleProps } from 'locale';
import { UniversalCardLineLabels } from 'components/cards/listing/UniversalCard/utils';
import { getBedsRangeParams } from 'utils/addressFormatter';
import { PropertyDetailsText, PropertyDetailsSeparator } from './styled';
import { RangeValues } from 'components/cards/types';

export const getProjectInfoLineConfig = (bedsRange: RangeValues, isPromoted: boolean, priceRange: RangeValues, floorRange: RangeValues, { t, formatMoney }: WithLocaleProps) => {
  const showBeds = bedsRange && typeof bedsRange.min === 'number' && typeof bedsRange.max === 'number';
  const showPrice = Boolean(priceRange && priceRange.min);
  const showFloor = floorRange && typeof floorRange.max === 'number' && (showPrice || showBeds);
  const separator = <PropertyDetailsSeparator />;

  return [
    {
      label: UniversalCardLineLabels.Beds,
      separator,
      showCondition: showBeds,
      render: () => (
        <PropertyDetailsText data-auto="property-rooms">
          {t('universalCard.project.listing.bedsRangeLabel', getBedsRangeParams(bedsRange.min, bedsRange.max))}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator,
      showCondition: showFloor,
      render: () => (
        <PropertyDetailsText data-auto="property-floor">
          {t('universalCard.project.floor', { floor: floorRange.max })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Price,
      separator,
      showCondition: showPrice,
      render: () => (
        <PropertyDetailsText data-auto="property-min-price">
          {t('universalCard.project.minPrice', { minPrice: formatMoney(priceRange.min) })}
        </PropertyDetailsText>
      ),
    },
  ];
};
