import React from 'react';
import styled from '@emotion/styled';
import Discount from 'assets/svg/project/discount.svg';
import DiscountMobile from 'assets/svg/project/discount.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';


const DiscountLabel = styled.div`
  position: absolute;
  right: -1px;
  top: -1px;
`;
export default () => {
  const isMobile = useScreenBreakpoint() < 3;
  return (
    <DiscountLabel>
      {isMobile ? <DiscountMobile /> : <Discount />}
    </DiscountLabel>
  );
};
