import { createSelector } from 'reselect';
import { userNotificationDevicesSelector } from '../domainData/selectors/userNotificationDevices';
import { devicePushStatusSelector } from '../app/selectors';
import { flow } from 'lodash';
import { addNotificationDeviceResponseSelector, removeNotificationDeviceResponseSelector } from '../mutationsResponse/selectors';
import { userNotificationDevicesDomainSelector } from '../domainData/selectors/common';

export const pushNotificationsEnabledSelector = createSelector([
  userNotificationDevicesSelector,
  devicePushStatusSelector,
], (devices, currentDevice) => {
  const currentToken = currentDevice && currentDevice.deviceToken;
  return (devices || []).find(device => device.deviceId === currentToken) !== undefined;
});

export const userNotificationDevicesLoadingSelector = createSelector([
  flow(addNotificationDeviceResponseSelector, (s) => s && s.loading),
  flow(removeNotificationDeviceResponseSelector, (s) => s && s.loading),
  flow(userNotificationDevicesDomainSelector, (s) => s.loading),
  devicePushStatusSelector,
], (addLoading, removeLoading, domainLoading, status) => addLoading || removeLoading || domainLoading || status === null);
