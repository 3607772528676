import React, { ReactNode } from 'react';
import RadioButton from '../RadioButton';

interface Item {
  label: ReactNode | string;
  value: string;
}

interface RadioGroupProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  options: Item[];
  value: string;
  name: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, name, value, onChange }) => {
  return (
    <>
      {options.map(item => (
        <RadioButton
          key={item.value}
          value={item.value}
          onChange={onChange}
          name={name}
          checked={item.value === value}
        >
          {item.label}
        </RadioButton>
      ))}
    </>
  );
};

export default RadioGroup;
