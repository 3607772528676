import { LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { put, call, select, all } from 'redux-saga/effects';
import { navigateTo } from 'store/state/router/actions';
import { Route } from 'config/routes';
import { getRouteParams, prevRouteSelector } from 'store/state/selectors/router';
import { editListingSelector } from 'store/state/domainData/selectors/editListing';
import {
  isAgentSelector,
  userListingsByIdsSelector,
  isBulletinsAdminSelector,
  userIdSelector,
} from 'store/state/domainData/selectors';
import { UploadBulletinStep } from 'screens/UploadBulletinPage/types';


function *loadListingsDataWorker (id: string, isCommercial?: boolean) {
  if (!id) return;

  const isAgent = yield select(isAgentSelector);
  const type = isCommercial ? 'commercialBulletin' : 'bulletin';
  try {
    yield all([
      call(queryData, {
        loadType: LoadType.EditListingPoi,
        meta: {
          variables: {
            ids: [ { type, id } ],
            includeFrozen: true,
            forEdit: true,
          },
        },
      }),
      call(queryData, {
        loadType: LoadType.UserAllListings,
        meta: {
          variables: {
            includeResidential: !isAgent,
          },
        },
      }),
    ]);
  }
  catch (error) {
    yield put(navigateTo(Route.ManageBulletins));
  }
}

function* maybeNavigateToFirstStep(isCommercial?: boolean) {
  const { step, id } = yield select(getRouteParams);
  const previousRoute = yield select(prevRouteSelector);
  const isPreviewStep = step === UploadBulletinStep.Preview;
  const editRoute = isCommercial ? Route.EditCommercialBulletin : Route.EditBulletin;

  if (step && (
    !previousRoute
    || (previousRoute.name === Route.ManageBulletins && !isPreviewStep)
    || (previousRoute.name !== editRoute && !(previousRoute.name === Route.ManageBulletins && isPreviewStep))
  )) {
    yield put(navigateTo(editRoute, { id, step: undefined }, { replace: true }));
  }
}

function* editBulletinWorker(isCommercial?: boolean) {
  yield call(maybeNavigateToFirstStep, isCommercial);
  const { id } = yield select(getRouteParams);
  yield call(loadListingsDataWorker, id, isCommercial);
  const poi = yield select(editListingSelector);
  const usersListingsDict = yield select(userListingsByIdsSelector);
  const isBulletinsAdmin = yield select(isBulletinsAdminSelector);
  const uid = yield select(userIdSelector);
  const userId = poi && poi.poc && poi.poc.userId || null;

  if ((!poi || !(Boolean(usersListingsDict[id]) || uid === userId)) && !isBulletinsAdmin) {
    yield put(navigateTo(Route.ManageBulletins, {}, { replace: true }));
  }
}


export function* editResidentialBulletinPageHandler() {
  yield call(editBulletinWorker);
}

export function* editCommercialBulletinPageHandler() {
  yield call(editBulletinWorker, true);
}
