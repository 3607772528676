import { Button } from 'ds/components/button';
import { HebrewKeys, ITranslate, useLocale } from 'locale';
import React from 'react';
import * as Styled from './styled';
import TabuIllustration from 'assets/svg/tabu-illustration.svg';
import TabuLoader from 'assets/svg/tabu-loader.svg';
import HintIcon from 'assets/svg/interface-16-info.svg';
import VerifiedIcon from 'assets/svg/verified.svg';
import Tooltip from 'ds/components/tooltip';
import { Tabu } from 'utils/entities';
import TabuWizard from './tabu-wizard/TabuWizard';
import { noop } from 'lodash';
import { TextInterval } from './TextInterval';
import { AddressDetailsMeta } from './selectors';
import { DialogWithURLType } from 'store/state/selectors/router';
import { SetTabuWizardOpen } from '.';
import { useScreenBreakpoint } from 'consts/breakpoints';


export enum TabuState {
  Initial,
  Loading,
  Exist,
  Empty,
  Sent,
  Fail,
}

const headerTermToTabu: (isListingModeB: boolean) => Record<TabuState, HebrewKeys> = (isListingModeB) => ({
  [TabuState.Initial]: isListingModeB ? 'tabu.initial.header.copy.modeB' : 'tabu.initial.header.copy',
  [TabuState.Loading]: 'tabu.loading.header.copy',
  [TabuState.Exist]: 'tabu.loaded.header.copy',
  [TabuState.Empty]: 'tabu.empty.header.copy',
  [TabuState.Sent]: 'tabu.sent.header.copy',
  [TabuState.Fail]: 'tabu.fail.header.copy',
});

const infoTermToTabu: (isListingModeB: boolean) => Record<TabuState, HebrewKeys> = (isListingModeB) => ({
  [TabuState.Initial]: isListingModeB ? 'tabu.initial.info.copy.modeB' : 'tabu.initial.info.copy',
  [TabuState.Loading]: 'tabu.loading.info.copy',
  [TabuState.Exist]: 'tabu.loaded.info.copy',
  [TabuState.Empty]: 'tabu.empty.info.copy',
  [TabuState.Sent]: 'tabu.sent.info.copy',
  [TabuState.Fail]: 'tabu.fail.info.copy',
});

const submitTermToTabu: Record<TabuState, HebrewKeys> = {
  [TabuState.Initial]: 'tabu.initial.submit.copy',
  [TabuState.Loading]: null,
  [TabuState.Exist]: 'tabu.loaded.submit.copy',
  [TabuState.Empty]: null,
  [TabuState.Sent]: null,
  [TabuState.Fail]: 'tabu.fail.submit.copy',
};

function createTexts(count: number, term: HebrewKeys, t: ITranslate) {
  return Array(count).fill(null).map((_, i) => t(term, { i }));
}

function renderButton(s: TabuState, isListingModeB: boolean, isMobile: boolean, t: ITranslate, onClick: () => void) {
  const term = submitTermToTabu[s];
  if (term) {
    // add ghost tile from localize ds
    return (
      <Styled.ButtonWrapper onClick={onClick}>
        <Button data-auto={`tabu-${TabuState[s].toLowerCase()}-button`} fullWidth={isMobile} mode={isListingModeB ? 'ghost' : 'ghost-dark'} size="large">{t(term)}</Button>
      </Styled.ButtonWrapper>
    );
  }
  return null;
}

function renderInfoTitle(state: TabuState, t: ITranslate, isListingModeB: boolean) {
  const infoTerm = infoTermToTabu(isListingModeB)[state];

  switch (state) {
    case TabuState.Initial:
      return (
        <Styled.InitialInfoContainer>
          {t(infoTerm)}
          <Tooltip
            tapThreshold={0}
            placement="bottom"
            tooltip={<Styled.TooltipContainer>{t('tabu.loaded.tooltip.copy')}</Styled.TooltipContainer>}
          >
            <HintIcon data-auto="tabu-tooltip-icon" />
          </Tooltip>
        </Styled.InitialInfoContainer>
      );
    case TabuState.Loading:
      return <TextInterval stopOnLast texts={createTexts(5, infoTerm, t)} />;
    default:
      return t(infoTerm);
  }
}

export type TabuSource = 'page' | 'insight';

export interface TabuProps {
  tabuState: TabuState;
  tabu: Tabu;
  source: TabuSource;
  addressDetails: AddressDetailsMeta;
  isTabuWizardOpen: boolean;
  reFetchTabu: () => void;
  requestTabu: () => void;
  tabuFoundEvent?: () => void;
  setSent: (v: boolean, id: string) => void;
  setTabuSubmitted: (v: boolean) => void;
  setTabuWizardOpen: SetTabuWizardOpen;
}

export const TabuWidget: React.FC<TabuProps> = (props) => {
  const {
    tabu,
    source,
    setSent,
    tabuState,
    requestTabu,
    reFetchTabu,
    addressDetails,
    tabuFoundEvent,
    isTabuWizardOpen,
    setTabuSubmitted,
    setTabuWizardOpen,
  } = props;

  const {
    city,
    docId,
    streetName,
    streetNumber,
    isAccurate,
  } = addressDetails || {};

  const { t } = useLocale();
  const breakpoint = useScreenBreakpoint();

  const address = [ streetName, streetNumber ].filter(Boolean).join(' ') || null;

  const isMobile = breakpoint === 1;
  const isLoading = tabuState === TabuState.Loading;
  const headerTerm = headerTermToTabu(true)[tabuState];

  const handleSubmit = () => {
    switch (tabuState) {
      case TabuState.Initial:
        requestTabu();
        setTabuSubmitted(true);
        break;
      case TabuState.Exist:
        setTabuWizardOpen(true, { ...tabu, dialog: DialogWithURLType.TabuWizard });
        tabuFoundEvent();
        break;
      case TabuState.Fail:
        reFetchTabu();
        break;
      default:
        break;
    }
  };

  if (!isAccurate) return null;

  const titles: JSX.Element = (
    <div>
      <Styled.HeaderWrapper>
        <Styled.FitText weight="bold">{t(headerTerm)}</Styled.FitText>
        <VerifiedIcon className="verified-icon" />
        {tabuState === TabuState.Exist ? (
          <Tooltip
            tapThreshold={0}
            placement="bottom"
            tooltip={<Styled.TooltipContainer>{t('tabu.loaded.tooltip.copy')}</Styled.TooltipContainer>}
          >
            <HintIcon data-auto="tabu-tooltip-icon" />
          </Tooltip>
        ) : null}
      </Styled.HeaderWrapper>
      <Styled.FitText>
        {renderInfoTitle(tabuState, t, true)}
      </Styled.FitText>
    </div>
  );

  const submitBtn: JSX.Element = renderButton(tabuState, true, isMobile, t, handleSubmit);

  return (
    <>
      {isMobile ? (
        <Styled.MobileRoot>
          <Styled.MobileContent withMargin={isLoading || Boolean(submitBtn)}>
            <Styled.IconWrapper>
              <TabuIllustration width={40} height={40} />
            </Styled.IconWrapper>
            {titles}
          </Styled.MobileContent>
          {isLoading ? (
            <Styled.LoadingIconWrapper>
              <TabuLoader width={40} height={40} />
            </Styled.LoadingIconWrapper>
          ) : null}
          {submitBtn}
        </Styled.MobileRoot>
      ) : (
        <Styled.DesktopRoot>
          <Styled.Content>
            <Styled.IconWrapper>
              {isLoading ? (
                <Styled.LoadingIconWrapper>
                  <TabuLoader width={48} height={48} />
                </Styled.LoadingIconWrapper>
              ) : <TabuIllustration width={48} height={48} />}
            </Styled.IconWrapper>
            {titles}
          </Styled.Content>
          {submitBtn}
        </Styled.DesktopRoot>
      )}
      {isTabuWizardOpen ? (
        <TabuWizard
          source={source}
          setWizardOpen={setTabuWizardOpen}
          setSent={(v: boolean) => setSent(v, docId)}
          tabu={tabu}
          docId={docId}
          address={address}
          city={city}
        />
      ) : null}
    </>
  );
};

TabuWidget.defaultProps = {
  tabuFoundEvent: noop,
};
