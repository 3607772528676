import { createStandardAction } from 'typesafe-actions';
import {
  SET_ACTIVE_POI_IDS,
  SET_BBOX, ADD_VIEWED_POI,
  DATA_LAYERS_BBOX_UPDATE,
} from './types';
import { PoiId, BoundingBox, DatedPoi } from 'utils/entities';


export const addViewedPoi = createStandardAction(ADD_VIEWED_POI)<DatedPoi>();
export const setBbox = createStandardAction(SET_BBOX)<BoundingBox>();
export const updateDataLayersBbox = createStandardAction(DATA_LAYERS_BBOX_UPDATE)<{ bbox: BoundingBox, zoom: number }>();
export const setActivePoiIds = createStandardAction(SET_ACTIVE_POI_IDS)<PoiId[], { source: 'map' | 'list' }>();
