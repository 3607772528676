import { call, getContext, take, select } from 'redux-saga/effects';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { addressDocEntrySelector } from 'store/state/domainData/selectors';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from 'store/sagas/apiService/types';
import { mapAddressToAnalytics } from './followAddressEvents';
import { IAutocompleteAddress } from 'utils/entities';
import { getRouteParams } from 'store/state/selectors/router';
import { RootAction } from 'store/state';


const addressPagePattern = (action: RootAction) =>
  action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.AddressPage;

const addressLoadPattern = (action: RootAction) =>
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.AddressDoc;

function* waitForAddressLoad() {
  yield take(addressLoadPattern);
}

export function* addressPageEventsWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    yield take(addressPagePattern);
    yield call(waitForAddressLoad);

    const address: IAutocompleteAddress = yield select(addressDocEntrySelector);
    const { tracking_search_source } = yield select(getRouteParams);

    const eventParams = {
      location: mapAddressToAnalytics(address),
      event: tracking_search_source ? { source: tracking_search_source } : undefined,
    };

    yield call(sendEvent, 'address_view', 'address', eventParams);
  }
}
