import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  white-space: nowrap;
`;

export const SchoolType = styled(SmallText)`
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: ${({ theme }) => theme.spacing(0.25)};
`;
