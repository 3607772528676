import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ShowAt } from 'consts/breakpoints';
import styled from '@emotion/styled';
import { Button } from 'ds/components/button';

import Loader from 'assets/svg/loader.svg';
import { BasicButtonProps } from 'ds/components/button/BasicButton';

interface DropZoneProps<T extends unknown> {
  isDragNDropAvailable: boolean;
  isMultiple: boolean;
  isClickAvailable: boolean;
  onDropFiles: (uploadedFiles: File[], rejectedFiles: File[]) => void;
  uploadedFiles: T[];
  renderPreview?: (elt: T, index: number) => React.ReactNode;
  uploadButtonText?: string;
  uploadButtonProps?: BasicButtonProps;
  uploadMessage?: React.ReactNode;
  withDropzonePlaceholders?: boolean;
  previewPlaceholder?: React.ReactNode;
  accept?: string;
  previewHeader?: React.ReactNode;
  isLoading?: boolean;
  uploadedMain?: boolean;
}

const StyledDropContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .dropzone-placeholders-wrapper {
    position: absolute;
    flex-wrap: wrap;
    display: flex;
    align-content: space-between;
    padding: 15px;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
`;
const PreviewFilesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    & > div {
      margin-left: 2%;

      &:nth-of-type(3n) {
        margin-left: 0;
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    & > div {
      margin-left: 4%;

      &:nth-of-type(2n) {
        margin-left: 0;
      }
    }
  `}
`;

const DropZoneInfo = styled.div`
  position: relative;
  display: flex;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

const UploadButton = styled(Button.withComponent('span'))`
  white-space: nowrap;
`;

export const DropZone = <T extends unknown>({
  isDragNDropAvailable,
  isMultiple,
  isClickAvailable,
  onDropFiles,
  uploadedFiles,
  uploadButtonText,
  uploadButtonProps,
  uploadMessage,
  withDropzonePlaceholders,
  renderPreview,
  previewPlaceholder,
  accept,
  previewHeader,
  isLoading,
  uploadedMain,
}: DropZoneProps<T>) => {


  const generatePlaceholders = (quantity: number): JSX.Element[] => {
    return new Array(quantity).fill(null).map((_, index) => (
      <div className="dropzone-placeholder" key={index}/>
    ));
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: isMultiple,
    noDrag: !isDragNDropAvailable,
    noClick: !isClickAvailable,
    onDrop: onDropFiles,
    accept,
  });
  const loader = uploadedMain ? null : <LoaderWrapper><Loader/></LoaderWrapper>;

  const previewUploadedFiles = (renderPreview && uploadedFiles)
    ? uploadedFiles.map(renderPreview)
    : null;

  return (
      <section className="dropzone-container">
        {(previewHeader && uploadedFiles.length) ? previewHeader : null}
        <StyledDropContainer {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
            {withDropzonePlaceholders ? (
              <div className="dropzone-placeholders-wrapper">
                <ShowAt from={2} to={5}>
                    {generatePlaceholders(6)}
                </ShowAt>
                <ShowAt at={1}>
                    {generatePlaceholders(4)}
                </ShowAt>
              </div>
            ) : null}
          {previewUploadedFiles ? (
            <PreviewFilesContainer className="dropzone-preview-files-container">
                {previewUploadedFiles}
                {previewPlaceholder ? previewPlaceholder : null}
            </PreviewFilesContainer>
          ) : null}

          {isLoading ? loader : (
            <DropZoneInfo className="dropzone-info">
              {uploadButtonText ? (<UploadButton {...uploadButtonProps} >{uploadButtonText}</UploadButton>) : null}
              <div>{uploadMessage}</div>
            </DropZoneInfo>
          )}
        </StyledDropContainer>
      </section>
  );
};
