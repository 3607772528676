import { makeInsightsByTypeSelector, unitPageBulletinSelector } from 'store/state/domainData/selectors';
import { InsightDescriptor } from 'utils/entities';
import { PriceTrendsNewSummary } from 'store/state/selectors/insights/summaryTypes';
import { flow, first, last } from 'lodash';
import { createSelector } from 'reselect';
import { priceEstimationRecordsSelector } from 'store/state/selectors/insights/prices';


export enum CollapsedDynamicType {
  Country = 'country',
  City = 'city',
  Neighbourhood = 'neighbourhood',
}

export interface PriceCollapsedDynamic {
  avgPrice?: number;
  yieldPrice?: number;
  type?: CollapsedDynamicType;
  ppa?: number;
}

export const responseDataSelector = createSelector([
  flow(makeInsightsByTypeSelector(InsightDescriptor.PriceTrendsNew), first),
], priceTrendsInsight => {

  if (!priceTrendsInsight) {
    return {
      dynamicContentData: null,
    };
  }
  const { data } = priceTrendsInsight.summary.nonText.data as PriceTrendsNewSummary;

  let topDataSource = last(data.all.all);
  const dataLength = data.all.all.length;

  if (dataLength && topDataSource.type === CollapsedDynamicType.Neighbourhood && !topDataSource.buyPrice && !topDataSource.yield) {
    topDataSource = data.all.all[dataLength - 2];
  }

  let priceStatsData: PriceCollapsedDynamic = null;

  if (topDataSource) {
    priceStatsData = { avgPrice: topDataSource.ppa, yieldPrice: topDataSource.yield };
  }

  return { dynamicContentData: priceStatsData };
});

export const hasEstimationDataSelector = flow(priceEstimationRecordsSelector, (d) => d.length > 0);

export const bulletinIdSelector = flow(unitPageBulletinSelector, u => u && u.id);
