import { Route, State as RouteState } from 'config/routes';
import { call, select, put, takeEvery } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadType } from 'store/sagas/apiService/types';
import { navigateTo } from 'store/state/router/actions';
import { searchInfoEntriesSelector } from 'store/state/domainData/selectors';
import { SEARCH_DEVELOPER } from 'store/state/searchDeveloper/types';
import { searchDeveloper } from 'store/state/searchDeveloper/actions';

export const PER_PAGE = 48;

function* searchDevelopers(action: ReturnType<typeof searchDeveloper>) {
  const { payload: { cityDocId, page = 1 } } = action;
  const offset = (page - 1) * PER_PAGE;

  yield call(queryData, {
    loadType: LoadType.SearchDevelopers,
    meta: {
      variables: { query: { cityDocId, limit: PER_PAGE, offset } },
    },
  });
}

function* loadData(params: RouteState) {
  const docId = params.params.id;
  const page = params && params.params && params.params.page ? params.params.page : 0;
  const offset = page > 0 ? (page - 1) * PER_PAGE : 0;

  yield call(queryData, {
    loadType: LoadType.SearchDevelopers,
    meta: {
      variables: { query: { cityDocId: docId, limit: PER_PAGE, offset  } },
    },
  });

  yield call(queryData, {
    loadType: LoadType.SearchDoc,
    meta: {
      variables: { docIds: [ docId ] },
    },
  });

  const docs = yield select(searchInfoEntriesSelector);
  if (docs && docs[0]) {
    const isCity = docs[0].document.type === 'city';
    if (!isCity) {
      yield put(navigateTo(Route.DevelopersSearchPage));
    }
  }
}


export function* ssrDevelopersCitySearchPageHandler(params: RouteState) {
  yield call(loadData, params);
}

export function* developersCitySearchPageHandler(params: RouteState) {
  yield call(loadData, params);
  yield takeEvery(SEARCH_DEVELOPER, searchDevelopers);
}
