import React, { useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { WithLocaleProps, withLocale, ITranslate } from 'locale';
import { Route } from 'config/routes';
import { backLinkConfigSelector } from 'components/navigation/BackLink';
import { BackButton } from './BackButton';
import { addressDocEntrySelector, resolvedStrictLocalDocSelector } from 'store/state/domainData/selectors';
import { IAutocompleteStreet, CompletionType } from 'utils/entities';


type BackButtonProps = WithLocaleProps & { destination?: string; };

const InsightBackButton: React.FC<BackButtonProps> = React.memo(props => {
  const { destination, t } = props;
  const [ isHover, setHover ] = useState(false);
  const label = destination && isHover ? t('backButtonLabel.withDestination', { destination }) : t('backButtonLabel.default');

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <BackButton label={label} />
    </div>
  );
});

const destinationSelector = createSelector([
  (state: State, t: ITranslate) => t,
  backLinkConfigSelector,
  addressDocEntrySelector,
  resolvedStrictLocalDocSelector,
],
  (t, backLinkConfig, addressPageDoc, localPageDoc) => {
    const { routeName } = backLinkConfig;
    switch (routeName) {
      case Route.ProjectPage:
      case Route.UnitPage:
        return t('insightBackButton.destination.unitPage');
      case Route.AddressPage:
        if (addressPageDoc && addressPageDoc.type === CompletionType.Address) {
          return t('addressPage.newAddressLine1', { houseNumber: addressPageDoc.houseNumber, street: addressPageDoc.street });
        }
        break;
      case Route.StreetPage:
        if (addressPageDoc) {
          return (addressPageDoc as IAutocompleteStreet).street;
        }
        break;
      case Route.LocalPage:
        if (localPageDoc) {
          return localPageDoc.type === CompletionType.Neighbourhood ? localPageDoc.neighbourhood : localPageDoc.name;
        }
        break;
    }
    return undefined;
  }
);

const mapStateToProps = (state: State, props: BackButtonProps) => ({
  destination: destinationSelector(state, props.t),
});

export default withLocale(connect(mapStateToProps)(InsightBackButton));
