import React from 'react';
import { ButtonSet, ButtonItem, Size } from './ButtonSet';
import styled from '@emotion/styled';

export type SelectValue = string | number | boolean;

export type SelectValues = [ SelectValue, SelectValue ];

export interface SelectOption {
  label: React.ReactNode;
  value: SelectValue;
}

export interface RangeSelectProps {
  options: SelectOption[];
  values: SelectValues;
  onChange?: (values: SelectValues) => void;
  notRoundedButton?: Set<SelectValue>;
  size?: Size;
}

const GroupedLabel = styled.span`
  ${({ theme }) => `
    display: inline-block;
    width: 40px;
    padding: 0 ${theme.spacing(0.5)};
    box-sizing: content-box;
    &:first-of-type {
      padding-${theme.isRTL ? 'right' : 'left'}: 0;
      padding-${theme.isRTL ? 'left' : 'right'}: 3px;
    }
    &:last-of-type {
      padding-${theme.isRTL ? 'left' : 'right'}: 0;
      padding-${theme.isRTL ? 'right' : 'left'}: 3px;
    }
  `}
`;

const findIndex = (options: SelectOption[], v: SelectValue) => (
  options.findIndex(({ value }) => value === v)
);

const withinRange = (range: SelectValues, options: SelectOption[], index: number) => {
  const [ f, t ] = range;

  const fIdx = findIndex(options, f);
  if (t === null) return fIdx === index;
  const tIdx = findIndex(options, t);

  return index >= fIdx && index <= tIdx;
};

const getLabelsFromRange = (range: SelectValues, options: SelectOption[]) => {
  const [ f, t ] = range;

  const fIdx = findIndex(options, f);
  if (t === null) return options[fIdx].label;

  const tIdx = findIndex(options, t);

  const acc = [];

  for (let i = fIdx; i <= tIdx; i++) {
    acc.push(options[i]);
  }

  return acc.map(({ label, value }) => (
    <GroupedLabel key={`${value}`}>{label}</GroupedLabel>
  ));
};

class RangeSelect extends React.Component<RangeSelectProps, never> {

  public makeClickHandler = (newValue: SelectValue) => () => {
    const { values, options } = this.props;
    let [ left, right ] = values;
    const resetRange = newValue === null;
    const isRangeEmpty = left === null && right === null;
    const rangeHasLeft = left !== null && right === null;

    if (resetRange) {
      left = null;
      right = null;
    }
    else if (isRangeEmpty) {
      left = newValue;
    }
    else if (rangeHasLeft) {
      right = newValue;
    }
    else {
      left = newValue;
      right = null;
    }

    const nextValues: SelectValues = [ left, right ];
    if (left !== null && right !== null && findIndex(options, left) > findIndex(options, right)) nextValues.reverse();

    this.props.onChange(nextValues);
  };

  private optionReducer = (acc: ButtonItem[], { label, value }: SelectOption, idx: number) => {
    const { options, values, notRoundedButton, size } = this.props;
    const inRange = withinRange(values, options, idx);
    const [ f ] = values;
    const isRound = !notRoundedButton || !notRoundedButton.has(value);

    if (!inRange) {
      acc.push({
        label,
        click: this.makeClickHandler(value),
        active: false,
        isRound,
        size,
      });
    }
    else if (f === value) {
      acc.push({
        label: getLabelsFromRange(values, options),
        click: this.makeClickHandler(null),
        active: true,
        isRound,
        size,
      });
    }

    return acc;
  };

  public render() {
    const { options } = this.props;

    return <ButtonSet buttons={options.reduce(this.optionReducer, [])} />;
  }
}

export default RangeSelect;

