import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const StyledSelectItem = styled(Text.withComponent('button'))<{isActive: boolean; error?: boolean; }>`
  ${({ theme, isActive, error }) => `
    border: 1px solid ${theme.colors.neutrals.grey6};
    cursor: pointer;
    margin: 0 ${theme.spacing(1.25)} ${theme.spacing(1.25)} 0;
    color: ${theme.colors.neutrals.grey1};

    svg {
      width: 20px;
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)};
    }

    path {
      fill: ${theme.colors.brand.main};
    }

    circle {
      stroke: ${theme.colors.brand.main};
    }

    ${error
      ? `
        background-color: ${colorToAlpha(theme.colors.alert.red, 0.05)};
        border-color: ${colorToAlpha(theme.colors.alert.red, 0.5)};
        color: ${theme.colors.neutrals.grey1};
      ` : isActive
        ? `
            background-color: ${theme.colors.brand.lightest};
            color: ${theme.colors.brand.main};
            border-color: transparent;
          ` : `
            background-color: ${theme.colors.neutrals.white};
          `
    }

    :focus {
      outline: none;
    }
  `}
`;

export const StyledSelectItemCircle = styled(StyledSelectItem)`
  ${({ theme }) => `
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.borderRadius.extraLarge};
  `}
`;

export const StyledSelectItemSquare = styled(StyledSelectItem)`
  ${({ theme }) => `
    min-height: 40px;
    min-width: 70px;
    border-radius: ${theme.borderRadius.smallestSideRound};
  `}
`;

export const SelectItemInnerMarkup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectItemsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: start;
    margin-right: -${theme.spacing(1.25)};
    flex-wrap: wrap;
  `}
`;
