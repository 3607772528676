import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { List, ListItem, ShareAction } from './styled';
import { ShareType, shareActionTypeToIcon } from 'helpers/share-helpers';


interface ShareActionsProps {
  types: ShareType[];
  onShare: (type: ShareType) => void;
  copyText?: string;
}

export const ShareActions: React.FC<ShareActionsProps> = props => {
  const { types, onShare, copyText } = props;

  return (
    <List>
      {types.map(type => {
        const shareAction = (
          <ShareAction onClick={() => onShare(type)}>
            {shareActionTypeToIcon[type]}
          </ShareAction>
        );
        return (
          <ListItem key={type}>
            {type === 'copyLink'
              ? <CopyToClipboard text={copyText}>{shareAction}</CopyToClipboard>
              : shareAction
            }
          </ListItem>
        );
      })}
    </List>
  );
};
