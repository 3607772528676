import { createSelector } from 'reselect';
import { ilHomePageBulletinsSelector as baseIlHomePageBulletinsSelector, makeByIdsDictionarySelector } from './common';
import { IBulletin } from 'utils/entities';


export const ilHomePageBulletinsTotalSelector = createSelector(
  baseIlHomePageBulletinsSelector,
  (bulletinsDomainData) => {
    if (bulletinsDomainData.data && ('totalBulletinsSection1' in bulletinsDomainData.data)) {
      const { totalBulletinsSection1, totalBulletinsSection2, totalBulletinsSection3 } = bulletinsDomainData.data;

      return {
        totalBulletinsSection1: totalBulletinsSection1.total,
        totalBulletinsSection2: totalBulletinsSection2.total,
        totalBulletinsSection3: totalBulletinsSection3.total,
      };
    }

    return null;
  }
);

type LocalBulletin = IBulletin;
const EMPTY_ARRAY: LocalBulletin[] = [];

const NUMBER_OF_BULLETINS_QUERIES = 10;

export const ilHomePageBulletinsSelector = createSelector(
  baseIlHomePageBulletinsSelector,
  (bulletinsDomainData): LocalBulletin[] => {
    if (bulletinsDomainData.data && ('totalBulletinsSection1' in bulletinsDomainData.data)) {
      const { totalBulletinsSection1, totalBulletinsSection2, totalBulletinsSection3, ...bulletins } = bulletinsDomainData.data;
      const allBulletinsResponses = new Array(NUMBER_OF_BULLETINS_QUERIES).fill('bulletin').map((b, i) => b + (i + 1)).map(b => bulletins[b]).filter(Boolean);
      return allBulletinsResponses.reduce((prev, current) => [ ...prev, ...current.poi ], []);
    }
    return EMPTY_ARRAY;
  }
);

export const ilHomePageBulletinsByIdsSelector = makeByIdsDictionarySelector(ilHomePageBulletinsSelector);
