import React from 'react';

import { Header as StyledHeader } from '../styled/common';
import CloseIcon from 'assets/svg/close.svg';


interface HeaderProps {
  onClose(): void;
}

export const Header: React.FunctionComponent<HeaderProps> = ({ onClose, children }) => (
  <StyledHeader>
    {children}
    <CloseIcon onClick={onClose} width={12} height={12} />
  </StyledHeader>
);
