import styled from '@emotion/styled';
import { H3 } from 'ds/components/typography';


export const Header = styled(H3)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(3)};
  `}
`;
