import { Main } from './Main';
import { State } from 'store/state';
import { routeSelector, isMobileItemMapActiveSelector, appModeSelector } from 'store/state/selectors/router';
import { connect } from 'react-redux';
import { flow } from 'lodash';
import { insightsMobileMapTypeSelector, isMobileLayersToggleOpenSelector } from 'store/state/insightsContext/selectors';
import { isMobileSearchMapViewActiveSelector } from 'store/state/app/selectors';
import { isMapExpandedSelector } from 'store/state/selectors/router';
import { setMobileSearchMapViewActive } from 'store/state/app/actions';
import { desktopContentWidthModeSelector } from 'store/state/selectors/search';


const isMobileInsightsMapViewSelector = flow(insightsMobileMapTypeSelector, Boolean);

const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
  isMobileInsightsMapView: isMobileInsightsMapViewSelector(state),
  isMobileSearchMapViewActive: isMobileSearchMapViewActiveSelector(state),
  isMobileMapViewActive: isMobileItemMapActiveSelector(state),
  isMobileLayersToggleOpen: isMobileLayersToggleOpenSelector(state),
  isMapExpanded: isMapExpandedSelector(state),
  mode: appModeSelector(state),
  contentWidthMode: desktopContentWidthModeSelector(state),
});

const mapDispatchToProps = {
  setMobileSearchMapViewActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
