import { connect } from 'react-redux';
import { State } from 'store/state';
import { isMizrahiBankModalOpenSelector } from 'store/state/app/selectors';
import { setIsMizrahiBankModalOpen } from 'store/state/app/actions';
import MizrahiBankModal, { MizrahiBankModalProps } from './MizrahiBankModal';
import withEvents from 'analytics/withEvents';


const mapStateToProps = (state: State) => ({
  isOpen: isMizrahiBankModalOpenSelector(state),
});

const MizrahiBankModalWithEvents =  withEvents<MizrahiBankModalProps>((sendEvent) => ({
  onFormOpenEvent: () => sendEvent('popup_exposed', 'popup', {
    event: {
      type: 'mizrahi_mortgage_popup',
    },
  }),
  onFormCloseEvent: () => sendEvent('popup_close', 'popup', {
    event: {
      type: 'mizrahi_mortgage_popup',
    },
  }),
  onExternalLinkClick: (url: string) => sendEvent('mortgage_external_link_click', 'mortgage', {
    event: {
      external_link_type: 'mizrahi_mortgage',
      external_link_url: url,
    },
  }),
}))(MizrahiBankModal);

const mapDispatchToProps = {
  setIsOpen: setIsMizrahiBankModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MizrahiBankModalWithEvents);
