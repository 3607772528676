import styled from '@emotion/styled';

export const StatusDescription = styled.span`
  ${({ theme }) => `
    color: ${ theme.colors.neutrals.grey4};
    margin-right: ${theme.spacing(0.5)};
  `}
`;

export const CheckBoxWrapper = styled.div`
  ${({ theme }) => `
    display: inline-block;
    margin: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
  `}
`;

export const SecondSectionWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3)};
  `}
`;
