import React from 'react';
import { useField } from 'react-final-form-hooks';
import { memoize } from 'lodash';
import { ITranslate, useLocale } from 'locale';
import { UploadBulletinStepProps } from '../';
import { ListingPoiType } from 'utils/entities';
import { isNotEmpty } from 'utils/formValidationHelpers';
import { StepHeading } from 'screens/UploadBulletinPage/styled';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { Select } from 'screens/UploadBulletinPage/components/select';
import { DealTypeSelectWrapper } from 'screens/UploadBulletinPage/steps/DealTypeStep/styled';

class BulletinTypeSelect extends Select<ListingPoiType> {}
const SelectWithValidationMessage = withValidationMessage(BulletinTypeSelect);


const BULLETIN_TYPES: ListingPoiType[] = [ 'bulletin', 'commercialBulletin' ];

const genMarketplaceTypeOptions = memoize((t) => BULLETIN_TYPES.map( value => ({
  label: t('uploadBulletinPage.bulletinTypeStep.options', { value }),
  value,
})));

const validateField = (t: ITranslate) => (val: ListingPoiType) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');


interface BulletinTypeStepProps extends UploadBulletinStepProps {
  goToNextStep: (changed: boolean) => void;
}

const BulletinTypeStep: React.FC<BulletinTypeStepProps> = ({
  formInstance,
  goToNextStep,
}) => {
  const { t } = useLocale();

  const bulletinType = useField('type', formInstance, validateField(t));

  const onChange = (val: ListingPoiType) => {
    bulletinType.input.onChange(val);
    goToNextStep(val !== bulletinType.input.value);
  };

  return (
    <>
      <StepHeading>{t('uploadBulletinPage.bulletinTypeStep.heading')}</StepHeading>
      <DealTypeSelectWrapper>
        <SelectWithValidationMessage
          {...bulletinType.meta}
          {...bulletinType.input}
          onChange={onChange}
          isMultiple={false}
          options={genMarketplaceTypeOptions(t)}
        />
      </DealTypeSelectWrapper>
    </>
  );
};

export default BulletinTypeStep;
