import { omitBy, isNil, isEqual } from 'lodash';
import { call } from 'redux-saga/effects';
import { DecodedRouteParams, State as RouteState, Route } from 'config/routes';
import { LimitedSizeStack } from 'utils/limitedSizeStack';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { MADADS, MADADS_ARCHIVE } from 'store/state/selectors/madad';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';


interface VisitedRoute extends RouteState {
  scrollY: number;
}

const visitedRoutes: LimitedSizeStack<VisitedRoute> = new LimitedSizeStack<VisitedRoute>(50);

function areRouteParamsEqual(params1: DecodedRouteParams, params2: DecodedRouteParams): boolean {
  return isEqual(omitBy(params1, isNil), omitBy(params2, isNil));
}

function getScrollY(): number {
  return window.scrollY;
}

function isTogglingInsigtExpand(nextParams: DecodedRouteParams, prevParams: DecodedRouteParams): boolean {
  return Boolean((nextParams.insightId && !prevParams.insightId) || (!nextParams.insightId && prevParams.insightId));
}

export function* scrollMiddleware(route: RouteState, previousRoute: RouteState, meta: NavigationOptions['middlewareMeta']) {
  if (!previousRoute) return meta;

  if (route.meta.options && route.meta.options.bypassScrollMiddleware) {
    return meta;
  }

  let visitedRoute: VisitedRoute | undefined;

  if (
      isUnitPageRoute(route.name)
      && previousRoute.name === route.name
      && isTogglingInsigtExpand(route.params, previousRoute.params)
  ) {
    return meta;
  }

  if (previousRoute.params.insightId) {
    visitedRoute = visitedRoutes.value.find((item: VisitedRoute) => (
      item.name === route.name && areRouteParamsEqual(item.params, route.params)
    ));
  }
  else {
    visitedRoute = visitedRoutes.value.find((item: VisitedRoute) => item.meta.id === route.meta.id);
  }

  if (
    (previousRoute.name !== Route.MortgageOfficePage && route.name !== Route.MortgageOfficePage) && (
      (previousRoute.params.page !== route.params.page && route.params.page)
      || !isEqual(previousRoute.params.term, route.params.term)
      || !isEqual(previousRoute.params.filters, route.params.filters)
    )
  ) {
    meta = {
      ...meta,
      scrollY: 0,
    };
    return meta;
  }

  // cases where components take the control over scroll
  if (
    (previousRoute.params.insightId && route.params.insightId)
    || (previousRoute.name === Route.Search && route.name === Route.Search)
    || (previousRoute.name === Route.SearchCommercial && route.name === Route.SearchCommercial)
  ) {
    return meta;
  }

  if (previousRoute && route.name === previousRoute.name && !route.params.isMapActive && previousRoute.params.isMapActive) {
    const lastItem = visitedRoutes.value[visitedRoutes.value.length - 1];
    if (lastItem) {
      meta = {
        ...meta,
        scrollY: lastItem.scrollY,
      };
      return meta;
    }
  }

  if (
    MADADS.has(previousRoute.name) && MADADS.has(route.name) ||
    MADADS_ARCHIVE.has(previousRoute.name) && MADADS_ARCHIVE.has(route.name)
  ) {
    return meta;
  }

  if (!visitedRoute) {
    // Add the route only if it's a transition to a deeper route
    const scrollY: number = yield call(getScrollY);
    visitedRoutes.add({ ...previousRoute, scrollY });
    meta = {
      ...meta,
      scrollY: 0,
    };
  }
  else {
    meta = {
      ...meta,
      scrollY: visitedRoute.scrollY,
    };
  }

  return meta;
}
