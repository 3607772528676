import { LoadType, ISearchNotificationJar } from 'store/sagas/apiService/types';
import { makeDomainGetter, makeNullSafeDomainGetter } from './common';
import { createSelector } from 'reselect';
import { flow, keyBy } from 'lodash';


const savedSearchesNotificationsSelector = makeDomainGetter(LoadType.SavedSearchesNotifications);
const searchHistoryNotificationsSelector = makeDomainGetter(LoadType.SearchHistoryNotifications);

const EMPTY: ISearchNotificationJar[] = [];

const savedSearchesNotificationsListSelector = flow(makeNullSafeDomainGetter(savedSearchesNotificationsSelector, 'me'), (u) => u && u.savedSearches || EMPTY);
const searchHistoryNotificationsListSelector = flow(makeNullSafeDomainGetter(searchHistoryNotificationsSelector, 'me'), (u) => u && u.searchHistory || EMPTY);

export const notificationsBySearchIdSelector = createSelector([
  savedSearchesNotificationsListSelector,
  searchHistoryNotificationsListSelector,
], (saved, history): Record<string, ISearchNotificationJar> => keyBy([ ...saved, ...history ], (s) => s.searchId));
