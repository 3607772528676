export type TextWeight = 'light' | 'normal' | 'medium' | 'bold' | 'extraBold';

export interface TextProps {
  weight?: TextWeight;
  caps?: boolean;
}

export interface LabelProps extends TextProps {
  small?: boolean;
}

export const weightStringToNumber = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  extraBold: 900,
};

export const typographyFontSizeCfg = {
  h1: {
    mobile: 25,
    desktop: 28,
  },
  h2: {
    mobile: 21,
    desktop: 24,
  },
  h3: {
    mobile: 17,
    desktop: 17,
  },
  h4: {
    mobile: 13,
    desktop: 13,
  },
  text: 15,
  smallText: 13,
  textLabel: {
    small: 11,
    normal: 13,
  },
  display: {
    mobile: 25,
    desktop: 52,
  },
};

export const typographyLineHeightCfg = {
  h1: {
    mobile: 32,
    desktop: 36,
  },
  h2: {
    mobile: 32,
    desktop: 34,
  },
  h3: {
    mobile: 24,
    desktop: 24,
  },
  h4: {
    mobile: 24,
    desktop: 24,
  },
  text: 24,
  smallText: 20,
  textLabel: {
    small: 14,
    normal: 16,
  },
  display: {
    mobile: 1.28,
    desktop: 1.15,
  },
};
