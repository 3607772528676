import { memoize } from 'lodash';
import { ResolutionPreference } from 'utils/entities';


const resolutionPrefOptions = [
  ResolutionPreference.Accurate,
  ResolutionPreference.Street,
  ResolutionPreference.Neighborhood,
];

export const genAddressResolution = memoize(t => resolutionPrefOptions.map(resolution => ({
  label: t('uploadBulletinPage.addressStep.resolutionPreferences.options', { value: resolution }),
  value: resolution,
})));
