import { socialTypeToIconMap } from 'components/authentication/social-buttons/social-button';
import { Button } from 'ds/components/button';
import { TextField } from 'ds/components/input/TextField';
import { useLocale } from 'locale';
import React, { useEffect, useState } from 'react';
import { IUpdateUserProfileVariables } from 'store/sagas/apiService/types';
import { LabelText } from 'ds/components/input/styled';
import { H3, Text } from 'ds/components/typography';
import { User, RegisterType, UserRole } from 'utils/entities';
import { PasswordButtonWrapper, PasswordField, PasswordWrapper, ProfileButtonWrapper, ProfileDataWrapper, ProfileEmail, ProfileFormWrapper, ProfileInfoWrapper, ProfileName, ProfilePhotoWrapper, DeleteButton, LoadingIconWrapper } from './styled';
import { Avatar } from 'components/navigation/header/styled';
import { FULL_NAME_REGEXP } from 'utils/formValidationHelpers';
import { DeleteUserPopup } from './DeleteUserPopUp';
import Loader from 'assets/svg/tabu-loader.svg';

interface ProfileSettingsProps {
  user: User;
  onChangePassword: () => void;
  onUserDelete: () => void;
  onSaveChanges: (data: IUpdateUserProfileVariables) => void;
  isAgent: boolean;
  deregisterUserIsLoading: boolean;
}

interface FormValues {
  phone: string;
  firstName: string;
}

function isUserOnly(user: User): boolean {
  if (user && user.roles) {
    const { roles } = user;
    return ((roles.length === 1) && (roles[0].toLowerCase() === UserRole.User));
  }
  return false;
}

export const ProfileSettings: React.FC<ProfileSettingsProps> = ({
  user = {},
  onChangePassword,
  onUserDelete,
  onSaveChanges,
  deregisterUserIsLoading,
  isAgent,
}) => {
  const [ isOpen, setIsOpen ] = useState();
  const isUser = isUserOnly(user);
  const [ formValues, setFormValues ] = useState<FormValues>(() => ({
    phone: user.phone,
    firstName: user.firstName,
  }));
  const { t } = useLocale();

  useEffect(() => {
    if (user) {
      setFormValues({
        phone: user.phone,
        firstName: user.firstName,
      });
    }
  }, [ user ]);

  const validateField = (regexp: RegExp) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value.replace(regexp, ''),
      });

  const setFullNameWithValidation = validateField(FULL_NAME_REGEXP);

  const setPhoneNumberWithValidation = validateField(/\D/g);

  const userInitials = user.firstName && user.lastName
    ? (user.firstName[0] + user.lastName[0])
    : (user.email ? user.email.substr(0, 2) : '');

  const { firstName, phone } = formValues;
  const hasChanges = equalByFirstObjProps(formValues, user);
  const handleSaveChanges = () => onSaveChanges(formValues);
  return (
    <>
      <ProfileDataWrapper>
        <ProfilePhotoWrapper>
          <Avatar userInitials={userInitials} imageURL={user.avatar} style={{ height: 56, width: 56, fontSize: 15 }} />
        </ProfilePhotoWrapper>
        <ProfileInfoWrapper>
          <ProfileName>
            <H3 weight="bold">{user.firstName || t('profile.usernamePlaceholder')}</H3>
          </ProfileName>
          <ProfileEmail>
            {user.registrationType !== RegisterType.Local && socialTypeToIconMap[user.registrationType]}
            <Text>
              {user.registrationType === RegisterType.Local
                ? user.email || t('profile.emailPlaceholder')
                : t('profile.connectedWith', { vendor: user.registrationType })
              }
            </Text>
          </ProfileEmail>
        </ProfileInfoWrapper>
      </ProfileDataWrapper>
      <ProfileFormWrapper>
        <TextField
          disabled={isAgent}
          data-auto="full-name-textfield"
          fullWidth
          type="text"
          label={t('profile.fullNameLabel')}
          onChange={setFullNameWithValidation}
          name="firstName"
          value={firstName}
        />
        <TextField
          disabled={isAgent}
          data-auto="phone-number-textfield"
          fullWidth
          type="tel"
          label={t('profile.phoneLabel')}
          onChange={setPhoneNumberWithValidation}
          name="phone"
          value={phone}
        />
      </ProfileFormWrapper>
      {user.registrationType === RegisterType.Local ? (
        <PasswordWrapper>
          <PasswordField>
            <label>
              <LabelText disabled={false}>{t('profile.passwordLabel')}</LabelText>
            </label>
            <Text>{t('profile.valuePlaceholder')}</Text>
          </PasswordField>
          <PasswordButtonWrapper>
            <Button
              data-auto="reset-password-button"
              mode="ghost"
              size="large"
              onClick={onChangePassword}
            >
              {t('profile.changePasswordButton')}
            </Button>
          </PasswordButtonWrapper>
        </PasswordWrapper>
      ) : null}
      <ProfileButtonWrapper>
        <Button
          data-auto="save-changes-button"
          size="large"
          mode="primary"
          fullWidth
          onClick={handleSaveChanges}
          disabled={!hasChanges}
        >
          {t('profile.saveChanges')}
        </Button>
      {isUser ? (
        <>
          <DeleteButton
            data-auto="delete-account-button"
            size="large"
            mode="primary"
            fullWidth
            disabled={deregisterUserIsLoading}
            onClick={() => setIsOpen(true)}
          >
            {t('profile.removeUser')}
            {deregisterUserIsLoading ? (
              <LoadingIconWrapper>
                <Loader width={18} />
              </LoadingIconWrapper>
            ) : null}
          </DeleteButton>
          <DeleteUserPopup
            onDelete={onUserDelete}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        </>
      ) : null}
      </ProfileButtonWrapper>
    </>
  );
};

const equalByFirstObjProps = (a: any, b: any): boolean => {
  for (const k in a) {
    if (a[k] !== b[k]) {
      return true;
    }
  }
  return false;
};
