import React, { useCallback } from 'react';
import { useLocale } from 'locale';
import { GenericFilterProps, RangeTuple } from '../types';
import FilterSection from '../common/DropdownSection';
import { Section, SectionTitle, SectionBody } from '../styled/common';
import RangeSelect from 'ds/components/input/RangeSelect';
import { normalizeRoomsRange, isFilterActiveByType } from '../utils';
import Range from 'ds/components/input/RangeField/RangeField';
import { parseRangeValues, prepareRangeValues } from '../utils';
import { MarketplaceType } from 'utils/entities';


interface RoomsRangeProps extends GenericFilterProps<'roomsRange'> {}

const VISIBLE_ITEMS_COUNT = 5;

const RoomsRange: React.FunctionComponent<RoomsRangeProps> = ({
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  marketplace,
  config,
  alignment,
  type,
  isInline,
}) => {
  const { t } = useLocale();
  const normalizedCommitedValue = normalizeRoomsRange(commitedValue, marketplace);
  const isActive = isFilterActiveByType.roomsRange(commitedValue, initialValue, marketplace);
  const valuesAreInitial = !isFilterActiveByType.roomsRange(intermediateValue, initialValue, marketplace);

  const isCommercial = marketplace === MarketplaceType.Commercial;
  const [ nomalizedFrom, normalizedTo ] = normalizedCommitedValue;
  const { roomsOptions, filtersIcons } = config;

  const options = roomsOptions.map((value) => ({
    value,
    label: t('filters.roomsOptions', { value, isCommercial }),
  }));

  const shortValueLabel = t('filters.roomsShortValue', {
    isActive,
    exactValue: nomalizedFrom === normalizedTo ? nomalizedFrom : null,
    isRange: nomalizedFrom !== null && normalizedTo !== null,
    bedsFrom: nomalizedFrom,
    bedsTo: normalizedTo,
  });

  const onDropdownChange = useCallback(([ from, to ]: RangeTuple<number>) => {
    setIntermediateValue([ parseRangeValues(from), parseRangeValues(to) ]);
  }, []);

  const select = isCommercial ? (
    <Range
      expandOptionsPosition="bottom"
      onChange={onDropdownChange}
      values={prepareRangeValues(intermediateValue)}
      options={options}
      readOnly
      fullWidth
      visibleItemsCount={VISIBLE_ITEMS_COUNT}
    />
  ) : (
    <RangeSelect
      values={intermediateValue}
      options={options}
      onChange={setIntermediateValue}
    />
  );

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto-filter-name="roomsRange" data-auto={`filter-${type}`}>
        <SectionTitle isBold>{t('filters.rooms')}</SectionTitle>
        <SectionBody>
          {select}
        </SectionBody>
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      isActive={isActive}
      icon={filtersIcons[type]}
      title={t('filters.rooms')}
      label={shortValueLabel}
      onClear={resetValue}
      onSubmit={commitValues}
    >
      {select}
    </FilterSection>
  );
};

export default React.memo(RoomsRange);
