import React from 'react';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { HEADER_HEIGHT, SliderItemContainer } from 'ds/components/ListingCard';

interface SliderItemProps {
  path: string;
  height?: number;
  rotateDegrees?: number;
  altText?: string;
  dataAuto?: string;
}

const SliderItem: React.FC<SliderItemProps> = React.memo((props) => {
  const { path, height, rotateDegrees, altText } = props;
  const constructImageURI = useImageUrlBuilder();
  const imageUrl = constructImageURI(path, {
    height: HEADER_HEIGHT * 2,
    rotateDegrees,
  });

  const autoTag = props.dataAuto ? { 'data-auto': props.dataAuto } : null;

  return (
    <SliderItemContainer height={height}>
      <img src={imageUrl} alt={altText} {...autoTag} />
    </SliderItemContainer>
  );
});

export default SliderItem;
