import React from 'react';
import { capitalize } from 'utils';
import { useLocale } from 'locale';
import Modal from 'ds/components/modal';
import { AnswerItem, UgcRatingStep } from 'components/user-generated-content/rating-step';
import { Navigation } from 'components/user-generated-content/review-wizard/Navigation';
import { Stepper } from 'components/user-generated-content/review-wizard/Stepper';
import { AnswerIcon, QuestionIcon } from './icons-config';
import { UgcTextReviewStep } from './TextReviewStep';
import { Step, Topic, RatingTopic } from 'components/user-generated-content/utils/initialUGCConfig';
import { SetRatingPayload } from 'store/state/UGC/payloads';
import { RatingsDataMap } from 'store/state/UGC/reviewWizard';


export interface ReviewWizardProps {
  currentStepIndex: number;
  isOpen: boolean;
  userNameInput: string;
  textReviewInput: string;
  addressName: string;
  ratingsDataMap: RatingsDataMap;
  stepsOptions: Step[];
  onClose: () => void;
  onComplete: () => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onTextReviewInputChange: (value: string) => void;
  onUserNameInputChange: (value: string) => void;
  onSelectRatingValue: (value: SetRatingPayload) => void;
}

export const ReviewWizard: React.FC<ReviewWizardProps> = props => {
  const {
    isOpen,
    onClose,
    onNextStep,
    onPreviousStep,
    onComplete,
    userNameInput,
    textReviewInput,
    onSelectRatingValue,
    addressName,
    ratingsDataMap,
    stepsOptions,
    onTextReviewInputChange,
    onUserNameInputChange,
    currentStepIndex,
  } = props;

  if (!isOpen) return null;

  const { t } = useLocale();

  const textReviewReady = Boolean(textReviewInput);
  const stepType = stepsOptions.length ? stepsOptions[currentStepIndex].type : null;
  const isLastStep = (currentStepIndex + 1) === stepsOptions.length;
  const isSkipStep = !(stepType === Topic.TextReview && textReviewReady);

  const handleAnswerRatingStep = ({ value }: AnswerItem) => {
    onSelectRatingValue({ topic: stepType as RatingTopic, value });
  };

  const stepperSteps = stepsOptions.map(step => {
    if (step.type === Topic.TextReview) {
      return (
        <UgcTextReviewStep
          key={step.type}
          nameValue={userNameInput}
          areaValue={textReviewInput}
          setAreaValue={onTextReviewInputChange}
          setNameValue={onUserNameInputChange}
        />
      );
    }

    const activeAnswer = ratingsDataMap[step.type].modified;
    const answers = step.answerTypes.map(answerType => ({
      label: t('addressPage.ugc.answer', { topic: stepType, answerType }),
      value: +answerType,
      icon: AnswerIcon[answerType],
    }));

    return (
      <UgcRatingStep
        key={step.type}
        question={t('addressPage.ugc.question', { topic: step.type })}
        questionDescription={t('addressPage.ugc.questionDescription', { topic: step.type })}
        questionIcon={QuestionIcon[step.type]}
        activeAnswer={activeAnswer}
        answers={answers}
        onSelectAnswer={handleAnswerRatingStep}
      />
    );
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={capitalize(t('ugc.modal.title', { addressName }))}
      buttons={(
        <Navigation
          isBackStep={currentStepIndex > 0}
          isLastStep={isLastStep}
          isSkipStep={isSkipStep}
          onForward={onNextStep}
          onBackward={onPreviousStep}
          onComplete={onComplete}
        />
      )}
    >
      {stepsOptions ? <Stepper steps={stepperSteps} currentIndex={currentStepIndex} /> : null}
    </Modal>
  );
};

