import React, { useState } from 'react';
import { useLocale } from 'locale';
import {
  Divider,
  LocalDataImage,
  LocalDataInfo,
  LocalDataInfoWrapper,
  LocalDataLabel,
  LocalDataLink,
  LocalDataPPS,
  LocalDataRoot,
  LocalDataTransactions,
  SchoolBinWrapper,
  SchoolTypeWrapper,
  MultiLocalDataRoot,
  MultiLocalDataCarouselItem,
  MultiLocalDataLink,
  LocalDataCarouselLocation,
} from './styled';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { CompletionType, IAutocompleteEntry, MarketplaceType } from 'utils/entities';
import UnitPageEntryIcon from 'assets/svg/unit-page-entry.svg';
import NeighbourhoodIcon from 'assets/svg/neighbourhood2.svg';
import CityIcon from 'assets/svg/city2.svg';
import StreetIcon from 'assets/svg/street2.svg';
import SchoolIcon from 'assets/svg/school.svg';
import EmploymentAreaIcon from 'assets/svg/employment-area.svg';
import { Link } from 'components/link';
import { DecodedRouteParams, Route } from 'config/routes';
import { Link as StyledLink } from 'ds/components/link';
import config from 'config';
import { ILocalDataResponse } from 'store/sagas/apiService/types';
import { nonNull } from 'helpers';
import { getEntryName } from 'utils/texts';
import { upperFirst } from 'lodash';
import { BaseCarousel } from 'ds/components/carousels/base-carousel';
import theme from 'ds/theme/default';


export interface LocalDataProps {
  localData: ILocalDataResponse[];
  loading: boolean;
  marketplace: MarketplaceType;
  routeName: Route;
}

const getIconForCompletionType = (completionType: CompletionType) => {
  switch (completionType) {
    case CompletionType.Borough:
    case CompletionType.City:
      return <CityIcon height={72} width={72} />;
    case CompletionType.Zipcode:
    case CompletionType.Neighbourhood:
      return <NeighbourhoodIcon height={72} width={72} />;
    case CompletionType.Address:
      return <NeighbourhoodIcon height={72} width={72} />;
    case CompletionType.Street:
      return <StreetIcon height={72} width={72} />;
    case CompletionType.SchoolDoc:
      return <SchoolIcon height={72} width={72} />;
    case CompletionType.EmploymentArea:
      return <EmploymentAreaIcon height={72} width={72} />;
    default:
      return <UnitPageEntryIcon height={72} width={72} />;
  }
};

type GetRouteConfig = (resolvedAddress: IAutocompleteEntry, routeName: Route) => {
  route: Route,
  routeParams: Partial<DecodedRouteParams>,
};

export const getRouteConfig: GetRouteConfig = (resolvedAddress, routeName) => {
  const { relevantDocIds, type, docId } = resolvedAddress;

  switch (type) {
    case CompletionType.Street:
      return { route: Route.StreetPage, routeParams: { id: docId } };
    case CompletionType.City:
    case CompletionType.Neighbourhood:
    // Should be SchoolPage when new feature arrives
    case CompletionType.SchoolDoc:
      return { route: Route.LocalPage, routeParams: { id: docId } };
    case CompletionType.Address: {
      const route = routeName === Route.Search ? Route.AddressPage : Route.LocalPage;
      const neighbourhoodData = relevantDocIds && relevantDocIds.find(item => item.type === CompletionType.Neighbourhood);
      const routeParams: Partial<DecodedRouteParams> = routeName !== Route.Search ? neighbourhoodData ? { id: neighbourhoodData.docId } : null : { address: docId };
      routeParams.tracking_search_source = 'results_learn_more';
      return { route, routeParams };
    }
    case CompletionType.EmploymentArea:
      return {
        route: Route.EmploymentAreaPage,
        routeParams: {
          id: docId,
          term: [ docId ],
          marketplace: MarketplaceType.Commercial,
        },
      };
    default:
      return { route: null, routeParams: null };
  }
};

const DealsInfo: React.FC<{ localData: ILocalDataResponse }> = ({ localData }) => {
  const { t, formatMoney } = useLocale();
  const isDesktop = useScreenBreakpoint() >= 3;

  const { ppa, yearNumberOfDeals } = localData;

  return (
    <>
      {ppa ? (
        <>
          <LocalDataPPS>
            <span>{formatMoney(ppa)}</span>
            {t('searchListView.localData.pricePerSquare')}
          </LocalDataPPS>
          {isDesktop ? <Divider /> : null}
        </>
      ) : null}

      {yearNumberOfDeals ? (
        <LocalDataTransactions>
          <span>{yearNumberOfDeals}</span>
          {t('searchListView.localData.transactions')}
        </LocalDataTransactions>
      ) : null}
    </>
  );
};

interface SchoolInfoProps {
  schoolType: string;
  classRange: string;
  schoolBin: number;
}

const SchoolInfo = (props: SchoolInfoProps) => {
  const { t } = useLocale();
  const { schoolType, classRange, schoolBin } = props;

  return (
    <>
      <SchoolTypeWrapper>
        <span>{schoolType}</span>
        {classRange}
      </SchoolTypeWrapper>
      {nonNull(schoolBin) ? (
        <SchoolBinWrapper>
            <span>{t('dataLayerPreview.ilSchools.quality', { rate: schoolBin })}</span>
            {t('searchListView.localData.schoolInfo')}
        </SchoolBinWrapper>
      ) : null}
    </>
  );
};

export const LocalData: React.FC<LocalDataProps> = ({ loading, localData, marketplace, routeName }) => {
  const { t } = useLocale();
  const [ slideIndex, setSlideIndex ] = useState(0);
  const isDesktop = useScreenBreakpoint() >= 3;
  const isResidential = marketplace === MarketplaceType.Residential;

  if (!loading && localData) {
    localData = isResidential
      ? localData.filter(item => item.document.type !== CompletionType.EmploymentArea)
      : localData.filter(item => item.document.type === CompletionType.EmploymentArea);
  }

  if (loading || !localData || localData.length === 0) return null;

  let localDataInfoContent: JSX.Element = null;

  const isSingleLocation = localData.length === 1;

  if (isSingleLocation) {
    const resolvedAddress = localData && localData[0].document;
    const { route, routeParams } = getRouteConfig(resolvedAddress, routeName);

    if (!route || !routeParams) return null;

    const [ singleLocalData ] = localData;

    switch (resolvedAddress.type) {
      case CompletionType.Street:
        localDataInfoContent = null;
        break;
      case CompletionType.SchoolDoc:
        localDataInfoContent = (
          <SchoolInfo
            schoolType={resolvedAddress.schoolType}
            classRange={resolvedAddress.classRange}
            schoolBin={resolvedAddress.schoolBin}
          />
        );
        break;
      default:
        localDataInfoContent = <DealsInfo localData={singleLocalData} />;
    }

    return (
      <LocalDataRoot>
        <LocalDataImage>
          {getIconForCompletionType(resolvedAddress.type)}
        </LocalDataImage>
        <LocalDataInfoWrapper>
          <LocalDataLabel weight="medium" alwaysDisplayed={resolvedAddress.type === CompletionType.EmploymentArea}>
            {t('searchListView.localData.label', { completionType: resolvedAddress.type, routeName })}
          </LocalDataLabel>
          {resolvedAddress.type !== CompletionType.Street ? (
            <LocalDataInfo>
              {localDataInfoContent}
            </LocalDataInfo>
          ) : null}
          <LocalDataLink>
            {resolvedAddress.type === CompletionType.SchoolDoc ? (
              <a
                href={`${config.oldILRoot}/education/school/${resolvedAddress.id}`}
                target={isDesktop ? '_blank' : '_self'}
              >
                <StyledLink simple withArrow>
                  {t('searchListView.localPageLink', { type: resolvedAddress.type, schoolName: resolvedAddress.schoolName })}
                </StyledLink>
              </a>
            ) : (
              <Link
                preserveParams
                routeName={route}
                routeParams={routeParams}
                data-auto="local-page-link"
              >
                <StyledLink simple withArrow>
                  {t('searchListView.localPageLink', { type: resolvedAddress.type, name: resolvedAddress.name, routeName })}
                </StyledLink>
              </Link>
            )}
          </LocalDataLink>
        </LocalDataInfoWrapper>
      </LocalDataRoot>
    );
  }
  else {
    const shouldRender = localData
      .map(entry => getRouteConfig(entry.document, routeName)).some(cfg => cfg.route && cfg.routeParams);

    if (!shouldRender) return null;

    return (
      <MultiLocalDataRoot>
        <BaseCarousel
          customGradientColor={theme.colors.neutrals.white}
          onSlideIndexChange={setSlideIndex}
          activeCarouselSlide={slideIndex}
          noPadding
          disableSwipe={isDesktop}
          hideControls={!isDesktop || localData.length < 4}
        >
          {localData.map(entry => {
            const resolvedAddress = entry.document;
            const { route, routeParams } = getRouteConfig(resolvedAddress, routeName);

            if (!route || !routeParams) return null;

            const styledLink = (
              <StyledLink simple withArrow small>
                {t('unitPage.buildingDetails.moreInfo')}
              </StyledLink>
            );

            const locationName = upperFirst(getEntryName(resolvedAddress));

            return (
              <div key={resolvedAddress.docId}>
                <MultiLocalDataCarouselItem>
                  <MultiLocalDataLink>
                    <LocalDataCarouselLocation weight="medium" title={locationName}>
                      {locationName}
                    </LocalDataCarouselLocation>
                    <LocalDataLink>
                      {resolvedAddress.type === CompletionType.SchoolDoc ? (
                        <a
                          href={`${config.oldILRoot}/education/school/${resolvedAddress.id}`}
                          target={isDesktop ? '_blank' : '_self'}
                        >
                          {styledLink}
                        </a>
                      ) : (
                        <Link
                          preserveParams
                          routeName={route}
                          routeParams={routeParams}
                          data-auto="local-page-link"
                        >
                          {styledLink}
                        </Link>
                      )}
                    </LocalDataLink>
                  </MultiLocalDataLink>
                </MultiLocalDataCarouselItem>
              </div>
            );
          })}
        </BaseCarousel>
      </MultiLocalDataRoot>
    );
  }
};
