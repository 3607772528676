import React, { useState } from 'react';
import { useLocale } from 'locale';
import { memoize } from 'lodash';
import { useField } from 'react-final-form-hooks';
import { DropZone } from 'ds/components/dropzone';
import { Button } from 'ds/components/button';
import { H3, Text } from 'ds/components/typography';
import { ImagesCategory } from './ImagesCategory';
import DeleteUploadedFileIcon from 'assets/svg/delete-uploaded-file-bulletin-icon.svg';
import { UploadBulletinStepProps } from '../';
import { withValidationMessage } from 'hocs/withValidationMessage';

import {
  PreviewFile,
  PreviewFileWrapper,
  UploadBulletinWrapper,
  ChangeMainBulletinButton,
  MainBulletinWrapper,
  BulletinImagePreview,
  PreviewImagePlaceholder,
  UploadImageStepWrapper,
  RecommendedImages,
  PreviewImagePlaceholderInfo,
  PreviewHeaderWrapper,
  MainBulletinControlsWrapper,
  MainBulletinHeaderWrapper,
  LoaderWrapper,
  PreviewHeaderCounter,
  EditModeToggle,
  UploadLimitText,
} from './styled';

import { SectionHeading, StepHeading, TipBlock, TipContentWrapper, TipIconWrapper } from '../../styled';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';

import AddBulletinPictureIcon from 'assets/svg/add.svg';
import TipIcon from 'assets/svg/tip.svg';
import Loader from 'assets/svg/loader.svg';

import Balcony from 'assets/svg/balcony.svg';
import Bath from 'assets/svg/bath.svg';
import Bed from 'assets/svg/bed.svg';
import Kitchen from 'assets/svg/kitchen.svg';
import LivingRoom from 'assets/svg/living-room.svg';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { SpacingBlock } from 'components/spacing-block';
import { ShowAt } from 'consts/breakpoints';

const MAX_UPLOAD_IMAGES = 20;

const DropZoneWithValidationMessage = withValidationMessage(DropZone);

const createRecommendedImagesCategories = memoize((t) => [
  { name: t('uploadBulletinPage.uploadImageStep.terrace'), icon: <Balcony/> },
  { name: t('uploadBulletinPage.uploadImageStep.bathroom'), icon: <Bath/> },
  { name: t('uploadBulletinPage.uploadImageStep.bed'), icon: <Bed/> },
  { name: t('uploadBulletinPage.uploadImageStep.kitchen'), icon: <Kitchen/> },
  { name: t('uploadBulletinPage.uploadImageStep.livingRoom'), icon: <LivingRoom/> },
]);


const changeMainBulletinLogic = (images: string[]) => {
  if (images.length > 1) {
    const [ first, ...others ] = images;
    return [ ...others, first ];
  }
  return images;
};

export enum UploadError {
  ReachedLimit = 'reachedLimit',
  Format = 'format',
}
const TWENTY_MB_IN_BYTES = 20971520;

interface UploadImageStepProps extends UploadBulletinStepProps {
  showFormError: (error: boolean) => void;
}

export const UploadImageStep: React.FC<UploadImageStepProps> = ({
  formInstance,
  showFormError,
  isCommercial,
}) => {
  const { t } = useLocale();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ editingMode, setEditingMode ] = useState(false);
  const [ error, setError ] = useState(null);
  const uploadFiles = useUploadFiles();

  const constructImageURI = useImageUrlBuilder();
  const imagesFormField = useField('images', formInstance);
  const { value, onChange } = imagesFormField.input;
  const [ mainImage, ...otherImages ] = value;
  const otherImagesCount = otherImages.length;
  const showOtherImagesCount = otherImagesCount > 1;
  const allowUpload = value.length < MAX_UPLOAD_IMAGES;

  const onDropFiles = async (files: File[]) => {
    const acceptedFiles = files.filter(file => file.size < TWENTY_MB_IN_BYTES);
    const possibleUploadCount = MAX_UPLOAD_IMAGES - value.length;
    let validationError;

    switch (true) {
      case acceptedFiles.length > possibleUploadCount:
        validationError = UploadError.ReachedLimit;
        break;
      case acceptedFiles.length !== files.length:
      case !files.length:
        validationError = UploadError.Format;
        break;
      default:
        validationError = null;
    }

    setError(validationError);

    if (possibleUploadCount < 0 || !acceptedFiles.length ) return;

    const filteredAcceptedFiles = acceptedFiles.slice(0, possibleUploadCount);

    try {
      setIsLoading(true);
      const fileUrls = await uploadFiles(filteredAcceptedFiles, 'bulletin');
      const allFiles = [ ...value, ...fileUrls ];
      onChange(allFiles);
      showFormError(!allFiles.length);
    }
    catch (e) {
      // tslint:disable-next-line: no-console
      console.error('image upload url error', e);
    }
    finally {
      setIsLoading(false);
    }
  };

  const changeMainBulletin = (): void => {
    onChange(changeMainBulletinLogic(value));
  };

  const deleteUploadedImage = (e: React.SyntheticEvent<EventTarget>, imageURI: string) => {
    e.stopPropagation();
    e.preventDefault();

    onChange(value.filter((image: string) => image !== imageURI));
    setError(null);
  };

  const renderPreviewHeader = () => (
    <PreviewHeaderWrapper>
      <ShowAt at={1}>
        <Text weight="bold">
          {t('uploadBulletinPage.uploadImageStep.additionalPictures', { count: otherImagesCount })}
          {showOtherImagesCount ? otherImagesCount : null}
        </Text>
        <EditModeToggle onClick={() => setEditingMode(!editingMode)}>
          {t('uploadBulletin.uploadImageStep.toggleEditing', { editingMode })}
        </EditModeToggle>
      </ShowAt>
      <ShowAt from={2}>
        <H3>
          {t('uploadBulletinPage.uploadImageStep.additionalPictures', { count: otherImagesCount })}
          {showOtherImagesCount ? <PreviewHeaderCounter>{otherImagesCount}</PreviewHeaderCounter> : null}
        </H3>
      </ShowAt>
    </PreviewHeaderWrapper>
  );

  const renderPreviewPlaceHolder = () => (
    <PreviewImagePlaceholder className="dropzone-preview-file-placeholder">
      {!isLoading ? (
        <PreviewImagePlaceholderInfo>
          <AddBulletinPictureIcon />
          {t('dragNDrop.dragNDropAreaMessage')}
        </PreviewImagePlaceholderInfo>
      ) : <LoaderWrapper><Loader/></LoaderWrapper>}
    </PreviewImagePlaceholder>
  );

  const renderPreview = (imageURI: string) => (
    <PreviewFileWrapper onClick={(e) => e.stopPropagation()} className="dropzone-preview-files-wrapper" key={imageURI}>
      <PreviewFile className="dropzone-preview-file">
        <BulletinImagePreview src={constructImageURI(imageURI)} />
        <Button
          onlyIcon
          onClick={(e: React.SyntheticEvent<EventTarget>) => deleteUploadedImage(e, imageURI)}
        >
          <DeleteUploadedFileIcon />
        </Button>
      </PreviewFile>
    </PreviewFileWrapper>
  );

  return (
    <UploadImageStepWrapper>
      <StepHeading>{t('uploadBulletinPage.uploadImageStep.heading')}</StepHeading>
      {mainImage ? null : (
        <SpacingBlock mBottom={2}>
          <Text>{t('uploadBulletinPage.uploadImageStep.subHeading')}</Text>
        </SpacingBlock>
      )}
      <UploadBulletinWrapper editingMode={editingMode}>
        {mainImage ? (
            <>
              <MainBulletinControlsWrapper>
                <MainBulletinHeaderWrapper>
                  <SpacingBlock mBottom={1}>
                    <SectionHeading weight="bold">
                      {t('uploadBulletin.uploadImageStep.header')}
                    </SectionHeading>
                  </SpacingBlock>
                  <SectionHeading>{t('uploadBulletin.uploadImageStep.subHeader')}</SectionHeading>
                </MainBulletinHeaderWrapper>
                {otherImagesCount ? (
                  <ChangeMainBulletinButton onClick={changeMainBulletin}>
                    {t('uploadBulletins.changeMainBulletin')}
                  </ChangeMainBulletinButton>
                ) : null}
              </MainBulletinControlsWrapper>
              <MainBulletinWrapper className="main-bulletin-wrapper">
                <BulletinImagePreview src={constructImageURI(mainImage)} />
                <Button
                  onlyIcon
                  onClick={(e: React.MouseEvent) => deleteUploadedImage(e, mainImage)}
                >
                  <DeleteUploadedFileIcon/>
                </Button>
              </MainBulletinWrapper>
            </>
          ) : null}
        <DropZoneWithValidationMessage
          {...imagesFormField.meta}
          isLoading={isLoading}
          isMultiple
          isDragNDropAvailable={allowUpload}
          isClickAvailable={allowUpload}
          onDropFiles={onDropFiles}
          uploadButtonText={t('dragNDrop.dragNDropButtonMessage')}
          uploadMessage={t('uploadBulletinPage.uploadImageStep.dropzoneUploadMessage')}
          uploadedFiles={otherImages}
          renderPreview={renderPreview}
          previewPlaceholder={mainImage && allowUpload ? renderPreviewPlaceHolder() : null}
          withDropzonePlaceholders={true}
          previewHeader={renderPreviewHeader()}
          accept="image/jpeg, image/png, image/jpg, image/gif, image/bmp, image/tif"
          uploadedMain={!!mainImage}
        />
        <UploadLimitText hasError={Boolean(error)}>
          {t('uploadBulletinPage.uploadImageStep.errorText', { error })}
        </UploadLimitText>
      </UploadBulletinWrapper>
      {isCommercial ? null : (
        <>
          <SpacingBlock mBottom={5}>
            <SpacingBlock mBottom={3}>
              <SectionHeading weight="bold">
                {t('uploadBulletinPage.uploadImageStep.recommendedImagesHeader')}
              </SectionHeading>
            </SpacingBlock>
            <RecommendedImages>
              {createRecommendedImagesCategories(t).map(category => (
                <ImagesCategory key={category.name} name={category.name} icon={category.icon} />
              ))}
            </RecommendedImages>
          </SpacingBlock>
          <TipBlock>
            <TipIconWrapper>
              <TipIcon />
            </TipIconWrapper>
            <TipContentWrapper>
              <SpacingBlock mBottom={1}>
                <Text weight="bold">{t('uploadBulletinPage.uploadImageStep.tip.header')}</Text>
              </SpacingBlock>
              <Text>{t('uploadBulletinPage.uploadImageStep.tip.recommendation1')}</Text>
              <Text>{t('uploadBulletinPage.uploadImageStep.tip.recommendation2')}</Text>
              <Text>{t('uploadBulletinPage.uploadImageStep.tip.recommendation3')}</Text>
              <Text>{t('uploadBulletinPage.uploadImageStep.tip.recommendation4')}</Text>
            </TipContentWrapper>
          </TipBlock>
        </>
      )}
    </UploadImageStepWrapper>
  );
};

export default UploadImageStep;
