import React, { useEffect } from 'react';
import { ITranslate, useLocale } from 'locale';
import { useField } from 'react-final-form-hooks';
import { Section, SectionHeading, SelectFieldWrapper } from 'screens/UploadBulletinPage/styled';
import { UploadBulletinStepProps } from '../../';
import { isNotEmpty } from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { MarketplaceType } from 'utils/entities';
import Select from 'ds/components/input/Select';
import { SpacingBlock } from 'components/spacing-block';
import { Text } from 'ds/components/typography';
import { FloorSelectWrapper } from './styled';
import { genFloorOptions } from './helpers';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { hideTotalFloors, hideUnitFloor } from 'utils/uploadBulletin';


const EMPTY_STR = '';
const SelectWithValidationMessage = withValidationMessage(Select);

const validateField = (t: ITranslate) => (val: string) =>
  isNotEmpty(val) || Number(val) === 0 ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');

export const FloorSection: React.FC<UploadBulletinStepProps> = ({
  formInstance,
  isCommercial,
}) => {
  const { t } = useLocale();
  const marketplace = isCommercial ? MarketplaceType.Commercial : MarketplaceType.Residential;
  const isMobile = useScreenBreakpoint() < 3;

  const { buildingClass } = formInstance.getState().values;
  const hidePropertyFloor = hideUnitFloor(buildingClass, isCommercial);
  const hideFloors = hideTotalFloors(buildingClass, isCommercial);
  const floors = useField('floors', formInstance);
  const floor = useField('floor', formInstance, hidePropertyFloor ? undefined : validateField(t));
  const floorOptions = genFloorOptions(t, marketplace);

  useEffect(() => {
    if (hidePropertyFloor) {
      try {
        floor.input.onChange(undefined);
      }
      // tslint:disable-next-line: no-empty
      catch (e) { }
    }
    if (hideFloors) {
      try {
        floors.input.onChange(undefined);
      }
      // tslint:disable-next-line: no-empty
      catch (e) { }
    }
  }, [ hidePropertyFloor, hideFloors, floors.input.value, floor.input.value ]);

  if (hidePropertyFloor && hideFloors) return null;

  return (
    <Section>
      <SpacingBlock mBottom={1}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.mainInfoStep.floorHeading')}
        </SectionHeading>
      </SpacingBlock>

      <FloorSelectWrapper>
        {hidePropertyFloor ? null : (
          <SelectFieldWrapper
            error={floor.meta.error && floor.meta.touched}
            isEmpty={floor.input.value === EMPTY_STR}
            isWide={!isMobile}
          >
            <SpacingBlock mLeft={5}>
              <SelectWithValidationMessage
                size="large"
                items={floorOptions}
                {...floor.meta}
                {...floor.input}
                selectionPromptText={t('uploadBulletinPage.mainInfoStep.floorPrompt')}
              />
            </SpacingBlock>
          </SelectFieldWrapper>
        )}
        {hideFloors ? null : (
          <SelectFieldWrapper error={floors.meta.error && floors.meta.touched} isEmpty={floors.input.value === EMPTY_STR}>
            {hidePropertyFloor ? null : (
              <SpacingBlock mLeft={1}>
                <Text>{t('uploadBulletinPage.mainInfoStep.floorLabel')}</Text>
              </SpacingBlock>
            )}
            <SelectWithValidationMessage
              size="large"
              items={floorOptions}
              {...floors.meta}
              {...floors.input}
              selectionPromptText={t('uploadBulletinPage.mainInfoStep.totalFloorsPrompt')}
            />
          </SelectFieldWrapper>
        )}
      </FloorSelectWrapper>
    </Section>
  );
};
