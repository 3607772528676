import React from 'react';
import { Text } from 'ds/components/typography';
import { CelebrationWrapper } from './styled';

import CrystalIcon from 'assets/svg/quiet-street/crystal.svg';
import TreeIcon from 'assets/svg/quiet-street/tree.svg';
import { ICelebration, CelebrationType } from 'store/state/selectors/insights/summaryTypes';

export const Celebration: React.FC<ICelebration> = ({ type, header, body, generalText }) => {
  if (type === CelebrationType.General) {
    return <Text>{generalText}</Text>;
  }

  return (
    <CelebrationWrapper>
      {type === CelebrationType.GreenAvenues ? <TreeIcon /> : <CrystalIcon />}
      <div>
        <Text>{header}</Text>
        <Text>{body}</Text>
      </div>
    </CelebrationWrapper>
  );
};
