import React from 'react';
import { addEventListenerFn, EventListenerObject } from 'helpers/pan-responder';

interface ClickAwayListenerProps {
  onClickAway?(): void;
  children: React.ReactNode;
}

export class ClickAwayListener extends React.Component<ClickAwayListenerProps, never> {
  public componentDidMount () {
    this.outerClickHandler = addEventListenerFn(document.documentElement, 'click', this.handleOuterClick);
  }

  public componentWillUnmount () {
    this.outerClickHandler.remove();
  }

  private handleOuterClick = (e: MouseEvent) => {
    if (!this.props.onClickAway) {
      return;
    }
    if (!this.elementRef.current.contains(e.target as HTMLElement)) {
      this.props.onClickAway();
    }
  };

  private elementRef = React.createRef<HTMLDivElement>();

  private outerClickHandler: EventListenerObject = null;

  public render () {
    return (
      <div ref={this.elementRef}>
        {this.props.children}
      </div>
    );
  }
}
