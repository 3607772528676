import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'hooks/useTheme';
import { useLocale } from 'locale';
import { SmallText } from 'ds/components/typography';

const Steps = styled.div<{ withoutGaps?: boolean, withRoundCorners?: boolean }>`
  display: flex;

  > div {
    ${({ withoutGaps }) => withoutGaps ? 'margin: 0;' : null}

     ${({ withRoundCorners, theme }) => withRoundCorners ? `
       :first-of-type {
         border-radius: ${theme.borderRadius.micro} 0 0 ${theme.borderRadius.micro};
       }

       :last-of-type {
         border-radius: 0 ${theme.borderRadius.micro} ${theme.borderRadius.micro} 0;
       }
     ` : null}
  }
`;

const Step = styled.div<Pick<StepsProgressInterface, 'height' | 'background'> & { passed: boolean, isCurrent: boolean }>`
  background-color: ${({ passed, theme, background }) => passed
    ? theme.colors.brand.main
    : background
  };
  ${({ isCurrent, theme }) => isCurrent ? `
    border-radius: ${theme.isRTL ? '3px 0 0 3px' : '0 3px 3px 0'};
  ` : ''}
  height: ${({ height }) => height}px;
  flex-grow: 1;
  transition: background-color .2s;
  ${({ theme }) => `margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(0.5)}`};

  :last-of-type {
    ${props => props.theme.isRTL ? 'margin-left: 0;' : 'margin-right: 0;'};
  }
`;

const StepText = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin-bottom: ${theme.spacing(3)};
  `}
`;

const SpacingBlock = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin-top: ${theme.spacing(1)};
  `}
`;

interface StepsProgressInterface {
  count: number;
  current?: number;
  withoutGaps?: boolean;
  withRoundCorners?: boolean;
  height?: number;
  background?: string;
  withStepInfo?: boolean;
}

const BASIC_HEIGHT = 4;

export const StepsProgress: React.FC<StepsProgressInterface> = ({
  count,
  current = 0,
  withoutGaps,
  withRoundCorners = false,
  height = BASIC_HEIGHT,
  background,
  withStepInfo,
}) => {
  const { colors } = useTheme();
  const { t } = useLocale();
  const BASIC_BACKGROUND = colors.neutrals.grey8;
  return (
    <div>
      {withStepInfo ? (
        <SpacingBlock>
          <StepText>{t('stepProgress.count.info', { current: current + 1, count })}</StepText>
        </SpacingBlock>
      ) : null}
      <Steps withoutGaps={withoutGaps} withRoundCorners={withRoundCorners} className="wizard-steps-line">
        {Array(count).fill(null).map((item, index) => (
          <Step
            key={index}
            passed={index <= current}
            isCurrent={index === current && current !== count - 1}
            height={height}
            background={background || BASIC_BACKGROUND}
          />
        ))}
      </Steps>
    </div>
  );
};

