import React from 'react';
import { Wrapper } from './styled';
import { SmallText } from 'ds/components/typography';
import { useLocale } from 'locale';
import { connect } from 'react-redux';
import { quietStreetsPreviewSelector } from 'store/state/selectors/insights/quietStreets';
import { State } from 'store/state';

const QuietStreetsDynamicContentNoConnected: React.FC<{ preview?: string }> = ({ preview }) => {
  const { t } = useLocale();

  if (!preview) return null;

  return (
    <Wrapper>
      <SmallText>{t('widget.collapsed.preview', { preview })}</SmallText>
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => ({
  preview: quietStreetsPreviewSelector(state),
});

export const QuietStreetsDynamicContent = connect(mapStateToProps)(QuietStreetsDynamicContentNoConnected);
