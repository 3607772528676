import { IApartmentType } from 'utils/entities';
import { WithLocaleProps, ITranslate } from 'locale';
import { UniversalCardLineLabels } from 'components/cards/listing/UniversalCard/utils';
import { PropertyDetailsSeparator, PropertyDetailsText } from './styled';
import React from 'react';
import { head } from 'lodash';
import { RangeValues } from 'components/cards/types';


const UP_CASE_APARTMENT_TYPE_RANK_MAP = {
  OFFICE: 1000,
  SHOP: 900,
  CLINIC: 800,
  INDUSTRIAL: 700,
  HOTEL: 600,
  LAND: 500,
  HALL: 400,
  STOREROOM: 300,
  PARKING: 200,
};

function getApartmentTypeText(apartmentTypes: IApartmentType[], t: ITranslate): string {

  if (apartmentTypes.length > 0) {
    const headType = apartmentTypes[0].type;
    const onlyOneKind = apartmentTypes.every(({ type }) => type === headType);

    if (onlyOneKind) {
      return t('universalCard.project.apartmentType', { apartmentType: headType.toLocaleLowerCase() });
    }
    else {
      const sortedApartments = apartmentTypes
      .map((ap) => ({ ...ap, rank: UP_CASE_APARTMENT_TYPE_RANK_MAP[ap.type] }))
      .filter(({ rank }) => rank)
      .sort(({ rank: ARank }, { rank: BRank }) => BRank - ARank);

      if (sortedApartments.length) {
        const text = t('universalCard.project.apartmentType', { apartmentType: head(sortedApartments).type.toLocaleLowerCase() });
        return t('and.more.other', { text });
      }
      return null;
    }
  }
  return null;
}

export const getCommercialProjectInfoLineConfig = (apartmentType: IApartmentType[] = [], isPromoted: boolean, priceRange: RangeValues, floorRange: RangeValues, { t, formatMoney }: WithLocaleProps) => {
  const safeApartmentType = apartmentType.filter(({ type }) => type);
  const apartmentTypeText = getApartmentTypeText(safeApartmentType, t);
  const separator = <PropertyDetailsSeparator />;

  return [
    {
      label: UniversalCardLineLabels.ApartmentType,
      separator,
      showCondition: Boolean(apartmentTypeText),
      render: () => (
        <PropertyDetailsText data-auto="property-type">
          {apartmentTypeText}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator,
      showCondition: (Boolean(apartmentTypeText) || Boolean(priceRange && priceRange.min)) && Boolean(floorRange && floorRange.max),
      render: () => (
        <PropertyDetailsText data-auto="property-floor">
          {t('universalCard.project.floor', { floor: floorRange.max })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Price,
      separator,
      showCondition: Boolean(priceRange && priceRange.min),
      render: () => (
        <PropertyDetailsText data-auto="property-min-price">
          {t('universalCard.project.minPrice', { minPrice: formatMoney(priceRange.min) })}
        </PropertyDetailsText>
      ),
    },
  ];
};
