import { Route } from 'config/routes';

export enum LinksSection {
  Agent = 'agent',
  Profile = 'profile',
  MyProfile = 'myProfile',
  Localize = 'localize',
  Guides = 'guides',
  Tools = 'tools',
  ManageBulletin = 'manageBulletin',
}

export enum InformationAndServicesLinksSection {
  Main = 'main',
  GuidesAndTools = 'guidesAndTools',
  ManagementSystems = 'managementSystems',
}

export interface LinksSectionLink {
  path?: string;
  label: string;
  hideInApp?: boolean;
  routeName?: Route;
}

export type SectionToLinks = Partial<Record<LinksSection, LinksSectionLink[]>>;

export type InformationAndServicesSectionToLinks = Record<InformationAndServicesLinksSection, LinksSectionLink[]>;
