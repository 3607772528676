import React from 'react';
import { BuildingClass } from 'utils/entities';
import { WithLocaleProps } from 'locale';
import { UniversalCardLineLabels } from 'components/cards/listing/UniversalCard/utils';
import { PropertyDetailsText, PropertyDetailsSeparator } from './styled';

export const getCommercialBulletinInfoConfig = (area: number, price: number, size: number, floor: number, buildingClass: BuildingClass, { t, formatArea, formatMoney }: WithLocaleProps) => {
  const separator = <PropertyDetailsSeparator />;
  return [
    {
      label: UniversalCardLineLabels.Floor,
      separator,
      showCondition: Boolean(floor),
      render: () => (
        <PropertyDetailsText data-auto="property-floor">
          {t('listingCard.floorLabel', { floor })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Size,
      separator,
      showCondition: Boolean(size),
      render: () => (
        <PropertyDetailsText data-auto="property-size">
          {formatArea(size)}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.PPM,
      separator,
      showCondition: Boolean(area && price),
      render: () => (
        <PropertyDetailsText data-auto="property-ppm">
          {t('widget.prices.pricePerSqMeter', { price: formatMoney(price / area) })}
        </PropertyDetailsText>
      ),
    },
  ];
};
