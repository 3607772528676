import styled from '@emotion/styled';

export const SubMenuItem = styled.span<{ isActive: boolean; withBorder: boolean; }>`
  ${({ theme, isActive, withBorder }) => `
    position: relative;
    cursor: pointer;
    user-select: none;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    color: ${isActive ? theme.colors.brand.main : theme.colors.neutrals.grey1};
    ${isActive ? `
      svg path:nth-of-type(odd) {
        stroke: ${theme.colors.brand.main};
      }
      svg path:nth-of-type(even) {
        fill: ${theme.colors.brand.main};
      }
    ` : ''}
    ${withBorder ? `
      height: 64px;
      ::after {
        content: " ";
        display: block;
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.colors.brand.main};
      }
    ` : ''}
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    &:first-of-type {
      margin-right: ${theme.spacing(4)};
    }
  `}
  ${({ theme, withBorder }) => theme.media.showAt({ to: 2 })`
    ${withBorder ? `
      height: 48px;
    ` : ''}
  `}
`;

export const SubMenuItemSeparator = styled.div`
  ${({ theme }) => `
    margin: 0 ${theme.spacing(1)};
    height: 1px;
    width: 1px;
    background: ${theme.colors.neutrals.grey1};
    border-radius: ${theme.borderRadius.round};
  `}
`;

export const SubMenuItemSeparatorWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;
