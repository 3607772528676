import withEvents from 'analytics/withEvents';
import ReadMoreIcon from 'assets/svg/chevron-down.svg';
import { useLocale } from 'locale';
import React from 'react';
import styled from '@emotion/styled';
import { H2, H3, H4, Text } from 'ds/components/typography';
import { ShowAt } from 'consts/breakpoints';
import { InsightType } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';

export const CarouselNegativeWrapper = styled.div`
  margin: 0 -4px;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const InsightPreviewWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.white};
  padding: 24px 32px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  box-shadow: ${({ theme }) => theme.shadow.level2};
  margin: 4px;
  position: relative;
  width: auto;
  min-height: 178px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.showAt({ at: 2 })`
    min-height: 296px;
    padding: 32px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    min-height: 354px;
    padding: 32px 24px;
  `}
`;

export const ReadMoreButton = styled.div<{ color: string }>`
  display: inline-flex;
  color: ${({ color }) => color};
  align-items: center;
  cursor: pointer;
  svg {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
    transform: rotate(${({ theme }) => theme.isRTL ? '' : '-'}90deg);
    fill: ${({ color }) => color};
  }
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    margin-top: auto;
  `}
`;

export const InsightPreview = styled.div`
  width: 100%;
  margin-bottom: 16px;
  & > h3 {
    line-height: 1.57;
  }
`;

export const InsightTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  margin-bottom: 16px;
`;

export interface IInsightPreview {
  preview: string;
  id: string;
}

interface PreviewProps {
  insight: IInsightPreview;
  title: string;
  color: string;
  onReadMoreClick: (id: string) => void;
  __listPositionIndex?: number;
  __listPositionTotal?: number;
}

const Preview: React.FC<PreviewProps> = ({ insight, color, onReadMoreClick, title }): JSX.Element => {
  const { t } = useLocale();
  const handleReadMoreClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onReadMoreClick(insight.id);
  };
  return (
    <ItemWrapper>
      <InsightPreviewWrapper>
        <ShowAt to={2}>
          {title ? <InsightTitle>{title}</InsightTitle> : null}
        </ShowAt>
        <InsightPreview data-auto="insight-carousel-preview">
          <ShowAt to={2}>
            <H2 data-auto="insight-carousel-preview-text">{insight.preview}</H2>
          </ShowAt>
          <ShowAt from={3}>
            <H3 data-auto="insight-carousel-preview-text">{insight.preview}</H3>
          </ShowAt>
        </InsightPreview>
        <ReadMoreButton onClick={handleReadMoreClick} color={color}>
          <Text>
            {t('widgetTitle.readMore')}
          </Text>
          <ReadMoreIcon height={24} width={24}/>
        </ReadMoreButton>
      </InsightPreviewWrapper>
    </ItemWrapper>
  );
};

export default withEvents<PreviewProps>((sendEvent, props) => ({
  onReadMoreClick: () => sendEvent('insight_expand', 'insight', {
    event: {
      insight_index: props.__listPositionIndex,
      insight_index_total: props.__listPositionTotal,
      source: 'carousel',
    },
    insight: {
      insight_id: props.insight.id,
    },
  }),
}))(Preview);

export const SectionWrapper = styled.div<{ type: InsightType }>`
  & > section {
    padding: 0;
  }
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    &:not(:last-of-type) > section > div:last-of-type {
      border-bottom: 1px solid ${theme.colors.neutrals.grey8};
    }
  `}
  ${({ theme, type }) => theme.media.showAt({ from: 2 }) `
    &:not(:last-of-type):before {
      position: absolute;
      content: '';
      bottom: 0;
      right: -24px;
      left: -24px;
      height: 1px;
      background: ${theme.colors.neutrals.grey8};
    }
    &.section-wrapper-active {
      &:after {
        content: '';
        position: absolute;
        ${theme.isRTL ? 'right' : 'left'}: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: ${colorToAlpha(theme.colors.insights[type], 0.2)};
      }
    }
  `}
`;

export const LimitedText = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : '99%'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > * {
    display: inline !important;
  }
`;


export const SectionTitle = styled.header<{ type: InsightType, isActive: boolean }>`
  position: relative;
  .highlight {
    background: ${({ theme, type }) => colorToAlpha(theme.colors.insights[type], 0.08)};
    border-radius: ${({ theme }) => theme.borderRadius.tiny};
    padding: 0 2px;
  }
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  cursor: pointer;
  & > svg:last-of-type {
    transition: all .4s ease;
    min-width: 22px;
    margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: auto;
    path {
      fill: ${({ theme }) => theme.colors.neutrals.grey5};
    }
  }
  ${({ theme, isActive }) => theme.media.showAt({ from: 2 }) `
    transition: all .3s ease;
  `}
`;

export const SectionType = styled.div<{ isActive?: boolean, type?: InsightType }>`
  color: ${({ theme, type }) => type ? theme.colors.insights[type] : theme.colors.neutrals.grey1};
  display: flex;
  min-width: 112px;
  align-items: center;
  svg {
    ${({ theme }) => theme.media.showAt({ to: 1 }) `
      margin-${theme.isRTL ? 'left' : 'right'}: 4px;
    `}
    ${({ theme }) => theme.media.showAt({ from: 2 }) `
      margin-${theme.isRTL ? 'left' : 'right'}: 5px;
    `}
    path {
      fill: ${({ theme, type }) => type ? theme.colors.insights[type] : theme.colors.neutrals.grey1};
    }
  }

  ${({ theme, isActive, type }) => theme.media.showAt({ from: 2 }) `
    position: relative;
    ${isActive !== undefined
  ? (isActive
    ? `
          padding-bottom: 8px;
          transition: all .2s ease;
          cursor: pointer;
          &:after {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: ${ type ? theme.colors.insights[type] : theme.colors.neutrals.grey1};
            content: '';
          }
        `
    : `
          cursor: pointer;
          padding-bottom: 8px;
          transition: all .2s ease;
          color: ${theme.colors.neutrals.grey3};
          svg {
            path {
              fill: ${theme.colors.neutrals.grey3};
            }
          }
        `)
  : ''}
  `}

  ${({ theme, isActive }) => theme.media.showAt({ to: 1 }) `
    ${isActive !== undefined
  ? (isActive
    ? `
          padding: 4px 16px;
          transition: all .2s ease;
          border: 1px solid ${theme.colors.neutrals.grey8};
          border-radius: ${theme.borderRadius.smallestSideRound};
          background: ${theme.colors.neutrals.white};
        `
    : `
          padding: 4px 16px;
          transition: all .2s ease;
          border-radius: ${theme.borderRadius.smallestSideRound};
          border: 1px solid rgba(255, 255, 255, 0);
          color: ${theme.colors.neutrals.grey3};
          svg {
            path {
              fill: ${theme.colors.neutrals.grey3};
            }
          }
        `)
  : ''}
  `}
`;

export const ExpandedWidgetContent = styled.div`
  ${({ theme }) => `
    position: relative;
    padding: ${theme.spacing(3)};
    border-radius: ${theme.borderRadius.tiny};
    border: 1px solid ${theme.colors.neutrals.grey8};
    background: ${theme.colors.neutrals.white};
  `}
`;
