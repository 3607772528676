import React from 'react';

import EducationIcon from 'assets/svg/area-highlights/education.svg';
import TransportIcon from 'assets/svg/area-highlights/transportation.svg';
import LivabilityIcon from 'assets/svg/area-highlights/livability.svg';
import PricesIcon from 'assets/svg/area-highlights/price.svg';
import SafetyIcon from 'assets/svg/area-highlights/safety.svg';
import NuisancesIcon from 'assets/svg/area-highlights/nuisance.svg';
import PlanningIcon from 'assets/svg/area-highlights/construction.svg';
import SimplexLogoIcon from 'assets/svg/simplex-logo.svg';
import { InsightType } from 'utils/entities';
import Color from 'color';

export const insightTypeToIcon: Record<InsightType, JSX.Element> = {
  [InsightType.Education]: <EducationIcon />,
  [InsightType.Prices]: <PricesIcon />,
  [InsightType.Safety]: <SafetyIcon />,
  [InsightType.Transportation]: <TransportIcon />,
  [InsightType.Livability]: <LivabilityIcon />,
  [InsightType.Nuisances]: <NuisancesIcon />,
  [InsightType.Planning]: <PlanningIcon />,
  [InsightType.BuildingPermit]: <PlanningIcon />,
  [InsightType.FuturePlans]: <SimplexLogoIcon />,
  [InsightType.DivisionAreaPlan]: <SimplexLogoIcon />,
};

export const colorToAlpha = (color: string, alpha: number) => Color(color).alpha(alpha).hsl().toString();
