import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';


export const CommutePopupWrapper = styled.div`
  width: 338px;
  max-width: 100%;
  margin: 0 auto;
`;

export const CommutePreferenceForm = styled.form`

`;

export const CommuteTypeSelectWrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey9};
  align-items: center;
`;

export const CommuteTypeSelectItem = styled.div<{ isActive: boolean, accentColor?: string }>`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  border: 1px solid ${({ theme, isActive, accentColor }) => isActive ? (accentColor || theme.colors.brand.main) : 'rgba(255, 255, 255, 0)'};
  svg {
    transition: all .2s ease;
    path {
      fill: ${({ theme, isActive, accentColor }) => isActive ? (accentColor || theme.colors.brand.main) : theme.colors.neutrals.grey3};
    }
  }
`;

export const CommuteRushHour = styled.div<{ checked: boolean, accentColor?: string }>`
  ${({ theme, checked, accentColor }) => checked ? `
    & input + div {
      background-color: ${accentColor || theme.colors.brand.main} !important;
    }
  ` : ''}
`;

export const CommuteLocationInputWrapper = styled.div`

`;

export const CommutePreferenceFormSubmit = styled.div`

`;

export const CommuteTypePreferenceWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 16px auto 0;
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin: 24px auto 0;
  `}
`;

export const CommuteSoonWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    background-color: ${theme.colors.neutrals.grey10};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.neutrals.grey2};
    align-items: center;
  `};
`;

export const CommuteSoonIcon = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const CommuteEmptyStateText = styled(Text)`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    white-space: pre-wrap;
  `};
`;

export const CommuteDistanceText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CommuteTimeToTrainWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const CommuteTimeToTrainLine = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1.5)};
    display: flex;
    color: ${theme.colors.neutrals.grey3};

    > svg {
      margin-left: ${theme.spacing(1)};
      width: 16px;
    }
  `};

  > svg path, > svg {
    fill: currentColor;
  }
`;
