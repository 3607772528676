import { isEqual } from 'lodash';
import { ISaveSearch, ISaveSearchFromState } from 'store/sagas/apiService/types';
import { removeTypeName } from 'utils';

export const parseAmenities = (data: any) => Object.keys(removeTypeName(data)).filter(val => data[val]);

export const isSearchEqual = (base: ISaveSearch | ISaveSearchFromState, item: ISaveSearch | ISaveSearchFromState) => {
  const baseDocIds = new Set(base.locationDocIds);
  if (!item.locationDocIds || item.locationDocIds.length !== base.locationDocIds.length || !item.locationDocIds.every(t => baseDocIds.has(t))) return false;

  const { amenities: rawBaseAmenities, notificationsCount: n, ...baseQuery } = removeTypeName(base.query);
  const { amenities: rawItemAmenities, notificationsCount: n1, ...itemQuery } = removeTypeName(item.query);

  const { location: l1, ...baseQueryWithoutLocation } = baseQuery;
  const { location: l2, ...itemQueryWithoutLocation } = itemQuery;

  if (!isEqual(baseQueryWithoutLocation, itemQueryWithoutLocation)) return false;

  const baseAmenities = parseAmenities(rawBaseAmenities);
  const newAmenities = parseAmenities(rawItemAmenities);

  return isEqual(baseAmenities, newAmenities);
};
