import React from 'react';
import { ExpandSubheaderWrapper, SubheaderWrapper, SubMenuBackButton } from '../styled';
import { BackButton } from './BackButton';

interface SubheaderBasicProps {
  title: React.ReactNode;
  back?: React.ReactNode;
}

export const SubheaderBasic: React.FC<SubheaderBasicProps> = React.memo(props => {
  const { title, back } = props;
  return (
    <SubheaderWrapper>
      <ExpandSubheaderWrapper>
        <SubMenuBackButton data-auto="sub-menu-back-button">
          {back}
        </SubMenuBackButton>
        {title}
      </ExpandSubheaderWrapper>
    </SubheaderWrapper>
  );
});

SubheaderBasic.defaultProps = {
  back: <BackButton />,
};
