import React from 'react';
import { AgentConsoleNavigationSubheaderWrapper, SubheaderWrapper } from './styled';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { State as RouteState } from 'config/routes';
import { prevRouteSelector, routeSelector } from 'store/state/selectors/router';
import { SupportButton } from 'components/support/SupportButton';
// import Breadcrumbs from 'components/agent-console/common/breadcrumbs';


interface AgentConsoleNavigationSubheaderProps {
  route: RouteState;
  prevRoute: RouteState;
}

const AgentConsoleNavigationSubheader: React.FC<AgentConsoleNavigationSubheaderProps> = ({ route, prevRoute }) => {
  return (
    <SubheaderWrapper>
      <AgentConsoleNavigationSubheaderWrapper>
        <SupportButton />
        {/* hide for deals release */}
        {/*<Breadcrumbs />*/}
      </AgentConsoleNavigationSubheaderWrapper>
    </SubheaderWrapper>
  );
};

const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
  prevRoute: prevRouteSelector(state),
});

export default connect(mapStateToProps)(AgentConsoleNavigationSubheader);
