import React, { useMemo } from 'react';
import ProjectTag from 'components/cards/new-layout/components/ProjectTag';
import { TagsWrapper, PropertyPrimaryLine, PropertySecondaryLine } from 'components/cards/new-layout/styled';
import { useLocale } from 'locale';
import { ProjectNameText, InfoSidePaddingContainer, LastInfoLine } from './styled';
import renderLineItem from 'components/labelLineItem';
import { getProjectInfoLineConfig } from 'components/cards/new-layout/components/info-line-config';
import { SpacingBlock } from 'components/spacing-block';
import { ProjectUniversalCardProps } from 'components/cards/types';

const ProjectCard: React.FC<ProjectUniversalCardProps> = (props) => {
  const {
    hasDiscount,
    isPromoted,
    projectName,
    bedsRange,
    priceRange,
    floorRange,
  } = props;

  const localeProps = useLocale();
  const { t } = localeProps;

  const infoLineConfig = useMemo(() => {
    return getProjectInfoLineConfig(bedsRange, isPromoted, priceRange, floorRange, localeProps).filter(({ showCondition }) => showCondition);
  }, [ bedsRange, isPromoted, priceRange, localeProps, getProjectInfoLineConfig ]);

  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');
  return (
    <InfoSidePaddingContainer>
      {hasDiscount || isPromoted ? (
        <SpacingBlock mBottom={0.5}>
          <TagsWrapper>
            <ProjectTag hasDiscount={hasDiscount} isPromoted={isPromoted} />
          </TagsWrapper>
        </SpacingBlock>
      ) : null}
      <ProjectNameText size="small" isPromoted={isPromoted}>
        {name}
      </ProjectNameText>
      <PropertySecondaryLine data-auto="property-address">
        {t('universalCard.project.address', {
          city: props.city || null,
          streetName: props.streetName || null,
          streetNumber: props.streetNumber || null,
        })}
      </PropertySecondaryLine>
      <LastInfoLine>
        <PropertyPrimaryLine>
          {infoLineConfig.map(renderLineItem)}
        </PropertyPrimaryLine>
      </LastInfoLine>
    </InfoSidePaddingContainer>
  );
};

export default ProjectCard;
