import { call, getContext, takeEvery } from 'redux-saga/effects';


import { DELETE_SAVE_SEARCH, SAVE_SEARCH } from 'store/state/app/types';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { MutationType } from '../apiService/types';
import { deleteSaveSearch, saveSearch } from 'store/state/app/actions';
import { SaveSearchMetaSource } from 'utils/entities';

let saveSearchSource: SaveSearchMetaSource = null;

function* toggleWorker(action: ReturnType<typeof saveSearch> | ReturnType<typeof deleteSaveSearch>) {
  const { sendEvent } = yield getContext('analytics');
  if (action.type === SAVE_SEARCH) {
    saveSearchSource = action.meta.source;
    yield call(sendEvent, 'search_save_search_toggle_click', 'search', {
      event: { source: saveSearchSource },
    });
  }
  else {
    yield call(sendEvent, 'search_unsave_search_toggle_click', 'search', { event: { source: action.meta.source } });
  }
}

function* mutationWorker(action: SetMutationResponseAction<MutationType.SaveSearchQuery>) {
  const { data } = action.meta.response;
  if (data.saveSearchQuery) {
    const { sendEvent } = yield getContext('analytics');
    yield call(
      sendEvent,
      'search_save_search_success', 'search',
      { event: saveSearchSource ? { source: saveSearchSource } : null }
    );
    saveSearchSource = null;
  }
}

const savedSearchPattern = (action: RootAction) => action.type === SAVE_SEARCH || action.type === DELETE_SAVE_SEARCH;
const savedSearchMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.SaveSearchQuery
);

export function* savedSearchEventsWorker() {
  yield takeEvery(savedSearchPattern, toggleWorker);
  yield takeEvery(savedSearchMutationPattern, mutationWorker);
}
