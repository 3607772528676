import { flow } from 'lodash';
import { createSelector } from 'reselect';
import { searchLocalAddressPageDomainSelector, makeNullSafeDomainGetter } from './common';
import { createAgentsCardsSelector, createAgenciesCardsSelector } from './madad';
import { neighborhoodDocIdSelector } from './addressDoc';

const searchLocalAddressPageSelector = makeNullSafeDomainGetter(searchLocalAddressPageDomainSelector, 'searchLocal2');

export const addressPageMadadWinnersSelector = createSelector([
  searchLocalAddressPageSelector,
  neighborhoodDocIdSelector,
],
  (data, neighborhoodDocId) => data && data.docId === neighborhoodDocId ? data.madadWinners : null
);
export const addressPageMadadAgentsCardsSelector = createAgentsCardsSelector(
  flow(addressPageMadadWinnersSelector, winnersGroup => winnersGroup ? winnersGroup.agent : null)
);
export const addressPageMadadAgenciesCardsSelector = createAgenciesCardsSelector(
  flow(addressPageMadadWinnersSelector, winnersGroup => winnersGroup ? winnersGroup.office : null)
);
