import React from 'react';
import { flow } from 'lodash';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import Share from 'components/navigation/share';
import FavoritesToggler from 'components/favorites/FavoritesToggler';
import { ShareButtonSource } from '../share/ShareButton';
import { PoiId } from 'utils/entities';
import { State } from 'store/state';
import { currentPoiSelector } from 'store/state/selectors/poi';

const Root = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.media.createObjectMediaQuery({ to: 2 })]: {
    paddingLeft: 0,
    'button': {
      height: '36px',
      width: '36px',
    },
  },
}));

const idSelector = flow(currentPoiSelector, (poi) => {
  if (!poi) return null;

  switch (poi.type) {
    case 'bulletin':
    case 'commercialBulletin':
      return poi.id;
    default:
      return null;
  }
});

const mapStateToProps = (state: State) => ({
  id: idSelector(state),
});

interface SubheaderControlsProps {
  id: PoiId;
}

const SubheaderControls: React.FC<SubheaderControlsProps> = (props) => {
  const {
    id,
  } = props;

  if (!id) return null;

  return (
    <Root>
      <Share alignment="right" tooltip={false} isRound source={ShareButtonSource.ListingPage}/>
      <FavoritesToggler id={id} type="bulletin" tooltip={false} withButton noText isRound />
    </Root>
  );
};

export default connect(mapStateToProps)(SubheaderControls);
