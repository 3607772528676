
import React from 'react';
import { connect } from 'react-redux';
import { priceEstimationDataSelector } from './selectors';
import { EstimatedPriceProps, PriceRange } from './types';
import EstimatedPriceContent from './estimated-price-content';

const EstimatedPrice: React.FC<EstimatedPriceProps> = (props) => {
  const { estimatedPrice, price, maxEstimation, minEstimation, isLoading, handleScroll } = props;

  const priceRange = price > maxEstimation ? PriceRange.Top : price <= maxEstimation && price >= minEstimation ? PriceRange.Range : PriceRange.Bottom;

  const handleClick = () => {
    if (!estimatedPrice) return;
    handleScroll();
  };

  if (!price || isLoading) return null;

  return (
    <EstimatedPriceContent priceRange={priceRange} handleClick={handleClick} price={price} estimatedPrice={estimatedPrice} />
  );

};

export default connect(priceEstimationDataSelector)(EstimatedPrice);
