import React from 'react';
import { SectionInformBlock } from './styled';
import { SmallText } from 'ds/components/typography';
import StarsIcon from 'assets/svg/stars.svg';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { State } from 'store/state';
import { searchInfoEntriesSelector } from 'store/state/domainData/selectors';
import { DocId2InfoDocument } from 'utils/entities';

interface PPMAverageProps {
  priceTo: number;
  data: DocId2InfoDocument[];
}

const PPMAverage: React.FC<PPMAverageProps> = ({ priceTo, data }) => {
  const { t, formatMoney } = useLocale();
  const isSingleLocation = data && data.length === 1;

  return isSingleLocation && data[0].ppa ? (
		<SectionInformBlock priceTo={priceTo} ppa={data[0].ppa}>
			<StarsIcon/>
			<SmallText>
				{t('filters.ppmRange.note', { type: data[0].document.type, name: data[0].document.name, ppa: formatMoney(data[0].ppa) })}
			</SmallText>
		</SectionInformBlock>
  ) : null; // no designs for multi-search provided so far
};

export default connect((state: State) => ({
  data: searchInfoEntriesSelector(state),
}))(PPMAverage);
