import { connect } from 'react-redux';
import { State } from 'store/state';
import { isUploadBulletinReportModalOpenSelector } from 'store/state/app/selectors';
import { setReportUploadBulletinPopupOpen } from 'store/state/app/actions';
import { ReportPopupView } from './ReportUploadBulletinPopup';
import { reportAddressErrorAction } from 'store/state/uploadBulletin/actions';

const mapStateToProps = (state: State) => ({
  isPopupModalOpen: isUploadBulletinReportModalOpenSelector(state),
});

const mapDispatchToProps = {
  onClosePopup: () => setReportUploadBulletinPopupOpen(false),
  onSubmit: reportAddressErrorAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPopupView);
