import { keyBy, flow, head } from 'lodash';
import { createSelector } from 'reselect';

import { SchoolsType, ILSchoolsType } from 'store/state/insightsContext';
import {
  schoolsOverviewSelector,
  schoolsDetailsListSelector,
  makeInsightsByTypeSelector,
  insightsLoadingSelector,
} from 'store/state/domainData/selectors';
import { InsightDescriptor } from 'utils/entities';
import { SchoolsAccordionSection, SchoolInsightSummary, SchoolInsightSpecific, School, ILSchoolsTab, ILSchoolsResponse, ILSchoolsAccordionSection, ILSchoolGroupsSummary } from './types';



export const ILSchoolInsightSelector = flow(
  makeInsightsByTypeSelector(InsightDescriptor.ILSchools),
  head
);

const mapSchoolData = (data: ILSchoolsResponse, type: ILSchoolsType): ILSchoolsTab => ({
  tabId: type,
  schools: data.schools,
  scores: {
    citySchoolsAvg: data.citySchoolsAvg,
    nationalSchoolsAvg: data.nationalSchoolsAvg,
  },
});

export const ilSchoolsDataSelector = createSelector([
  ILSchoolInsightSelector,
  insightsLoadingSelector,
], (insight, isLoading): ILSchoolsAccordionSection[] => {
  if (!insight || isLoading) return null;

  const data: ILSchoolGroupsSummary = insight.summary.nonText.data as any;

  return [ SchoolsType.Elementary, SchoolsType.Middle, SchoolsType.High ].map((type) => ({
    sectionId: type,
    tabs: type === SchoolsType.Elementary ? ([
      mapSchoolData(data[ILSchoolsType.Secular], ILSchoolsType.Secular),
      mapSchoolData(data[ILSchoolsType.Orthodoxy], ILSchoolsType.Orthodoxy),
    ]) : [],
  }));
});


export const schoolsAccordionDataSelector = createSelector([
  schoolsOverviewSelector,
  schoolsDetailsListSelector,
], (schoolInsight, detailedSchoolInsights): SchoolsAccordionSection[] => {
  if (!schoolInsight || !detailedSchoolInsights.length) return [];


  const { schoolItems, areaOverall } = schoolInsight.summary.nonText.data as SchoolInsightSummary;

  const insightsByGeoId = keyBy(detailedSchoolInsights, insight => {
    const data = insight.summary.nonText.data as SchoolInsightSpecific;
    return data.geoId;
  });

  const agumentWithSpecificSchoolInsight = (school: School) => ({
    ...school,
    schoolInsightId: insightsByGeoId[school.geoId] ? insightsByGeoId[school.geoId].id : undefined,
  });

  return [ SchoolsType.Elementary, SchoolsType.Middle, SchoolsType.High ].map((type) => ({
    sectionId: type,
    sectionResume: areaOverall || '',
    schools: (schoolItems[type] || []).map(agumentWithSpecificSchoolInsight),
  }));

});

export const hasSchoolsDataSelector = createSelector([
  schoolsAccordionDataSelector,
  ilSchoolsDataSelector,
], (schoolsData, ilSchoolsData): boolean => Boolean(schoolsData.length || ilSchoolsData));

export const allSchoolsSelector = createSelector([
  schoolsDetailsListSelector,
  makeInsightsByTypeSelector(InsightDescriptor.ILSyntheticSchools),
], (schoolList, schoolILList) => ([
  ...schoolList.map(({ id }) => id),
  ...(schoolILList || []).map(({ id }) => id),
]));
