import { takeEvery, getContext, select, call, fork } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import { Route } from 'config/routes';
import { prevRouteSelector } from 'store/state/selectors/router';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { ContactV4MortgageOfficeVariables, ContactV4Response, LoadType, MutationType } from '../apiService/types';
import { mortgageOfficeSelector } from 'store/state/domainData/selectors/mortgageOffice';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { setIsMortgageOfficeContactPopupOpen } from 'store/state/app/actions';
import { SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN } from 'store/state/app/types';

const PREVIOUS_ROUTE_TO_SOURCE_MAP = {
  [Route.MortgageOfficesCitySearchPage]: 'mortgage_index',
  [Route.MortgageOfficesSearchPage]: 'mortgage_index',
  [Route.Search]: 'search_result_banner',
};

const mortgageAdvisorPagePattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.MortgageOfficeById
);

const contactSubmitMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.ContactV4MortgageOffice
);

function* openMortgageAdvisorPageWorker () {
  const { sendEvent } = yield getContext('analytics');
  const office = yield select(mortgageOfficeSelector);
  const prevRoute = yield select(prevRouteSelector);

  if (office) {
    const prevRouteName = prevRoute && prevRoute.name;
    yield call(sendEvent, 'mortgage_consult_page_view', 'mortgage', {
      event: {
        mortgage_office: {
          id: office.id,
          office_name: office.name,
          phone_number: office.phone,
        },
        number_of_reviews: (office.recommendations && office.recommendations.length) || 0,
        source: PREVIOUS_ROUTE_TO_SOURCE_MAP[prevRouteName] || 'direct',
      },
    });
  }
}

function* handleContactSubmit(action: SetMutationResponseAction<MutationType.ContactV4MortgageOffice>) {
  const { variables, response: { data } } = action.meta;
  if (data && data.contactV4) {
    const { sendEvent } = yield getContext('analytics');
    const { name, email, phone } = variables;
    const payload = {
      event: {
        source: 'mortgage_office_page',
        contact_details: {
          email,
          full_name: name,
          phone,
        },
      },
    };
    yield call(sendEvent, 'mortgage_consult_form_submit', 'mortgage', payload);
    yield fork(sendLeadFormSubmitSuccessEvent, variables, data.contactV4);
  }
}

function* sendLeadFormSubmitSuccessEvent(
  variables: ContactV4MortgageOfficeVariables,
  responseData: ContactV4Response['contactV4']
) {
  const leadId = responseData && responseData.lead && responseData.lead.leadId;

  if (leadId) {
    const { sendEvent } = yield getContext('analytics');
    const { name, email, phone } = variables;
    const payload = {
      event: {
        source: 'mortgage_office_page',
        contact_details: {
          email,
          full_name: name,
          phone,
        },
        lead_id: leadId,
      },
    };
    yield call(sendEvent, 'mortgage_consult_form_submit_success', 'mortgage', payload);
  }
}

function* handleOpenCloseModalEvent(action: ReturnType<typeof setIsMortgageOfficeContactPopupOpen>) {
  const { sendEvent } = yield getContext('analytics');
  yield call(sendEvent, action.payload ? 'mortgage_consult_form_open' : 'mortgage_consult_form_close', 'mortgage', {
    event: {
      source: 'mortgage_office_page',
    },
  });
}

export function* mortgageAdvisorPageWorker() {
  yield takeEvery(mortgageAdvisorPagePattern, openMortgageAdvisorPageWorker);
  yield takeEvery(contactSubmitMutationPattern, handleContactSubmit);
  yield takeEvery(SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN, handleOpenCloseModalEvent);
}
