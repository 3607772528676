import { PriceEvent, PriceEventType } from './entities';

export const getPriceHistoryDifference = (eventsHistory: PriceEvent[]) => (
  eventsHistory[eventsHistory.length - 1].price - eventsHistory[eventsHistory.length - 2].price
);

export const getCurrPrice =  (eventsHistory: PriceEvent[]) =>
  eventsHistory && eventsHistory.length >= 2 ? eventsHistory[eventsHistory.length - 1].price : null;

export const getPrevPrice =  (eventsHistory: PriceEvent[]) =>
  eventsHistory && eventsHistory.length >= 2 ? eventsHistory[eventsHistory.length - 2].price : null;

export const isPriceDropped = (eventsHistory: PriceEvent[]) => {
  const hasPriceDrop = eventsHistory && eventsHistory.length > 1 && eventsHistory[eventsHistory.length - 1].eventType === PriceEventType.priceDrop;
  const prevPrice = getPrevPrice(eventsHistory);
  const currPrice = getCurrPrice(eventsHistory);
  const DROP_LIMIT = 0.55;
  const isDropped = hasPriceDrop && (currPrice / prevPrice > DROP_LIMIT);

  return hasPriceDrop && isDropped;
};
