import { createStandardAction } from 'typesafe-actions';
import {
  SET_TRANSPORTATION_STATE,
  SET_ACTIVE_NUISANCE_CAROUSEL_IDX,
  SET_ACTIVE_CONSTRUCTION_ID,
  SET_ACTIVE_INSIGHT,
  INIT,
  SET_SCHOOLS_ACCORDION,
  SET_PRICES_STATE,
  SET_PRICES_TAB,
  SET_PRICES_ESTIMATION_INDEX,
  SET_PRICES_HISTORY_INDEX,
  SET_PRICES_BEDS_FILTER,
  SET_PRICES_YEAR_FILTER,
  SET_PRICES_FINAL_TRANSACTIONS_FILTER,
  SET_HOVERED_SCHOOL_ID,
  SET_SCHOOLS_TAB,
  SET_HOVERED_TRANSPORTATION_STATION,
  SET_MOBILE_MAP_TYPE,
  SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX,
  SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX,
  SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX,
  SET_HOVERED_DATA_LAYER_ID,
  SET_ACTIVE_PRICE_CAROUSEL_IDX,
  SET_ACTIVE_SCHOOL_CAROUSEL_IDX,
  SET_ACTIVE_SAFETY_CAROUSEL_IDX,
  SET_ACTIVE_LIVABILITY_CAROUSEL_IDX,
  SET_BUILDING_PERMIT_INDEX,
  COLLAPSE_INSIGHT_SECTION,
  SET_TABU_SUBMITTED,
  SET_ACTIVE_MAP_TRANSPORTATION,
  SET_IS_MOBILE_LAYERS_TOGGLE_OPEN,
  SET_IS_INFO_BOX_OPEN,
  SET_ACTIVE_BUS_LINE,
  SET_PRICES_LAST_EXPANDED_ROW_SALE_ID,
} from './types';
import { ExpandCollection, TransportationSectionType, InsightSection, SchoolsType, MapTransportationState, BusLine } from './index';
import { PricesBedsFilter, PricesState, PricesTabType, PricesYearFilter } from '../selectors/insights/prices';


interface SetActiveInsightPayload {
  insightId: string;
  collection?: ExpandCollection;
}

const baseSetActiveInsight = createStandardAction(SET_ACTIVE_INSIGHT)<SetActiveInsightPayload>();

export const setMobileMapType = createStandardAction(SET_MOBILE_MAP_TYPE)<InsightSection>();
export const setPricesState = createStandardAction(SET_PRICES_STATE)<Partial<PricesState>>();
export const setPricesTab = createStandardAction(SET_PRICES_TAB)<PricesTabType>();
export const setPricesEstimationIndex = createStandardAction(SET_PRICES_ESTIMATION_INDEX)<number[]>();
export const setPricesHistoryIndex = createStandardAction(SET_PRICES_HISTORY_INDEX)<string[]>();
export const setPricesBedsFilter = createStandardAction(SET_PRICES_BEDS_FILTER)<PricesBedsFilter>();
export const setPricesYearFilter = createStandardAction(SET_PRICES_YEAR_FILTER)<PricesYearFilter>();
export const setPricesFinalTransactionsFilter = createStandardAction(SET_PRICES_FINAL_TRANSACTIONS_FILTER)<boolean>();
export const setPricesLastExpandedRow = createStandardAction(SET_PRICES_LAST_EXPANDED_ROW_SALE_ID)<string>();
export const setBuildingPermitIndex = createStandardAction(SET_BUILDING_PERMIT_INDEX)<string[]>();
export const setTransportationState = createStandardAction(SET_TRANSPORTATION_STATE)<{ accordion?: TransportationSectionType, transitTab?: TransportationSectionType }>();
export const setActiveNuisanceCarouselIdx = createStandardAction(SET_ACTIVE_NUISANCE_CAROUSEL_IDX)<number>();
export const setActivePriceCarouselIdx = createStandardAction(SET_ACTIVE_PRICE_CAROUSEL_IDX)<number>();
export const setActiveSchoolCarouselIdx = createStandardAction(SET_ACTIVE_SCHOOL_CAROUSEL_IDX)<number>();
export const setActiveSafetyCarouselIdx = createStandardAction(SET_ACTIVE_SAFETY_CAROUSEL_IDX)<number>();
export const setActiveLivabilityCarouselIdx = createStandardAction(SET_ACTIVE_LIVABILITY_CAROUSEL_IDX)<number>();
export const setActiveConstructionId = createStandardAction(SET_ACTIVE_CONSTRUCTION_ID)<string | number>();
export const setSchoolsAccordion = createStandardAction(SET_SCHOOLS_ACCORDION)<SchoolsType>();
export const setActiveImpactInsightCarouselIdx = createStandardAction(SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX)<number>();
export const setActiveAboutToComeCarouselIdx = createStandardAction(SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX)<number>();
export const setActiveGoodToKnowCarouselIdx = createStandardAction(SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX)<number>();
export const setHoveredSchoolId = createStandardAction(SET_HOVERED_SCHOOL_ID)<number>();
export const setSchoolsTab = createStandardAction(SET_SCHOOLS_TAB)<number>();
export const setTabuSubmitted = createStandardAction(SET_TABU_SUBMITTED)<boolean>();
export const setHoveredTransportationStation = createStandardAction(SET_HOVERED_TRANSPORTATION_STATION)<string>();
export const setHoveredDataLayerId = createStandardAction(SET_HOVERED_DATA_LAYER_ID)<string>();
export const collapseInsightSection = createStandardAction(COLLAPSE_INSIGHT_SECTION)<InsightSection>();
export const setActiveMapTransportation = createStandardAction(SET_ACTIVE_MAP_TRANSPORTATION)<MapTransportationState | null>();
export const setIsMobileLayersToggleOpen = createStandardAction(SET_IS_MOBILE_LAYERS_TOGGLE_OPEN)<boolean>();
export const setIsInfoBoxOpen = createStandardAction(SET_IS_INFO_BOX_OPEN)<boolean>();
export const setActiveBusLine = createStandardAction(SET_ACTIVE_BUS_LINE)<BusLine>();


export const setActiveInsight = (insightId: string) => baseSetActiveInsight({ insightId });
export const setActiveBuildingPermit = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Tradeoff });
export const setActiveTradeoff = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Tradeoff });
export const setActiveNuisance = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Nuisances });
export const setActivePlanning = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Planning });
export const setActiveTransportation = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Transportation });
export const setActiveSchools = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Schools });
export const setActiveSchoolsCarousel = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.SchoolsCarousel });
export const setActivePrices = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Prices });
export const setActivePricesCarousel = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.PricesCarousel });
export const setActiveSafetyCarousel = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.SafetyCarousel });
export const setActiveLivabilityCarousel = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.LivabilityCarousel });
export const setActiveQuietStreets = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.QuietStreets });
export const setActiveNeighbourhood = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Neighbourhood });
export const setActiveVerified = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.Verified });
export const setActiveNonVerified = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.NonVerified });
export const setActiveAreaHighlights = (insightId: string) => baseSetActiveInsight({ insightId, collection: ExpandCollection.AreaHighlights });

export const initialize = createStandardAction(INIT)<undefined>();
