import React from 'react';
import { Text } from 'ds/components/typography';
import ChevronDown from 'assets/svg/chevron-down.svg';
import { SeeAllButton, Wrapper, Content } from './styled';
import { InsightType } from 'utils/entities';

interface WidgetBodyProps {
  isActive?: boolean;
  onSeeAllClick: () => void;
  seeAllTitle: string;
  styleType: InsightType;
}

const WidgetBody: React.FC<WidgetBodyProps> = ({ isActive, styleType, seeAllTitle, children, onSeeAllClick }) => {
  return (
    <Wrapper>
      <Content
        type={styleType}
        isActive={isActive}
      >
        {children}
        <SeeAllButton type={styleType} onClick={onSeeAllClick}>
          <Text>{seeAllTitle}</Text>
          <ChevronDown width={24} height={24} />
        </SeeAllButton>
      </Content>
    </Wrapper>
  );
};

export default WidgetBody;
