import React from 'react';
import { AnswerType, Topic, RatingTopic } from 'components/user-generated-content/utils/initialUGCConfig';
import ServiceIcon from 'assets/svg/brush.svg';
import DoormanIcon from 'assets/svg/doorman.svg';
import SafetyUnitIcon from 'assets/svg/saftey-unit.svg';
import RoomatesIcon from 'assets/svg/roomates.svg';
import RateBadIcon from 'assets/svg/rate-bad.svg';
import RateSosoIcon from 'assets/svg/rate-soso.svg';
import RateGoodIcon from 'assets/svg/rate-good.svg';
import RateGreatIcon from 'assets/svg/rate-great.svg';

export const QuestionIcon: Record<RatingTopic, JSX.Element> = {
  [Topic.Service]: <ServiceIcon />,
  [Topic.Doorman]: <DoormanIcon />,
  [Topic.SafetyUnit]: <SafetyUnitIcon />,
  [Topic.Roomates]: <RoomatesIcon />,
};

export const AnswerIcon: Record<AnswerType, JSX.Element> = {
  [AnswerType.Bad]: <RateBadIcon />,
  [AnswerType.SoSo]: <RateSosoIcon />,
  [AnswerType.Good]: <RateGoodIcon />,
  [AnswerType.Great]: <RateGreatIcon />,
};
