import React from 'react';
import { useLocale } from 'locale';

import { SliderRange } from 'ds/components/slider';
import {
  Section,
  SectionBody,
  PriceWrapper,
  SectionHeader,
} from 'components/filters/styled/common';

import FilterSection from 'components/filters/common/DropdownSection';
import { GenericFilterProps } from 'components/filters/types';

import { isFilterActiveByType } from 'components/filters/utils';
import { DealType } from 'utils/entities';
import RangeInputs from 'ds/components/input/RangeInputs';


interface PriceRangeProps extends GenericFilterProps<'priceRange'> {}

const PriceRange: React.FunctionComponent<PriceRangeProps> = ({
  alignment,
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  config,
  dealType,
  type,
  isInline,
}) => {
  const { t, formatMoneyWithMetricPrefix, formatMoney } = useLocale();
  const { budgetByDealType, filtersIcons } = config;

  const [ commitedFrom, commitedTo ] = commitedValue;
  const [ initialFrom, initialTo ] = initialValue;

  const isActive = isFilterActiveByType.priceRange(commitedValue, initialValue);
  const valuesAreInitial = !isFilterActiveByType.priceRange(intermediateValue, initialValue);

  const formatShortLabel = (label: number) => {
    if (dealType === DealType.Buy) return formatMoneyWithMetricPrefix(label, 6, 1E6);
    return formatMoneyWithMetricPrefix(label);
  };

  const txtShortValue = t('filters.priceShortValue', {
    isActive,
    priceFrom: initialFrom === commitedFrom ? null : formatShortLabel(commitedFrom),
    priceTo: initialTo === commitedTo ? null : formatShortLabel(commitedTo),
  });

  const slider = (
    <SliderRange
      {...budgetByDealType[dealType].budgetSliderProps}
      values={intermediateValue}
      mode="ltr"
      onChange={value => setIntermediateValue(value)}
    />
  );

  const inputs = (
    <RangeInputs
      {...budgetByDealType[dealType].budgetSliderProps}
      value={intermediateValue}
      onChange={value => setIntermediateValue(value)}
      formatter={(v) => formatMoney(v, false)}
    />
  );

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
        <SectionHeader isInline>
          {t('filters.priceRange.secondaryTitle')}
        </SectionHeader >
        <SectionBody data-auto="filterCheckboxes">
          <PriceWrapper>
            {slider}
          </PriceWrapper>
          {inputs}
        </SectionBody>
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      title={t('filters.priceRange.secondaryTitle')}
      icon={filtersIcons[type]}
      onClear={resetValue}
      label={txtShortValue}
      onSubmit={commitValues}
      isActive={isActive}
    >
      {slider}
      {inputs}
    </FilterSection>
  );
};

export default React.memo(PriceRange);
