import React from 'react';
import { H3, H2 } from 'ds/components/typography';
import { InsightType } from 'utils/entities';
import { ShowAt } from 'consts/breakpoints';
import { IconWrapper, Title, WidgetTitleWrapper } from './styled';
import { insightTypeToIcon } from 'helpers/insight';

interface PageTitleProps {
  styleType: InsightType;
  title: string;
}

const WidgetTitle: React.FunctionComponent<PageTitleProps> = ({ title, styleType }) => {
  return (
    <WidgetTitleWrapper>
      <Title type={styleType}>
        <IconWrapper type={styleType}>
          {insightTypeToIcon[styleType]}
        </IconWrapper>
        <ShowAt at={1}>
          <H2 data-auto={`${styleType}-insight-title`} weight="bold">{title}</H2>
        </ShowAt>
        <ShowAt from={2}>
          <H3 data-auto={`${styleType}-insight-title`} weight="bold">{title}</H3>
        </ShowAt>
      </Title>
    </WidgetTitleWrapper>
  );
};

export default WidgetTitle;
