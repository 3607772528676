import React from 'react';
import { Text } from 'ds/components/typography';
import { OfferDetailWrapper } from './styled';

interface OfferDetailProps {
  locator?: string;
  icon?: JSX.Element;
  hideIcon?: boolean;
}

export const OfferDetail: React.FC<OfferDetailProps> = ({ children, locator, icon, hideIcon }) => (
  <OfferDetailWrapper data-auto={locator} isDense={hideIcon}>
    {hideIcon ? null : icon}
    <Text>{children}</Text>
  </OfferDetailWrapper>
);
