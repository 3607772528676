import { IAnalytics } from 'analytics';
import { ANALYTICS_MAPPING } from 'components/navigation/subheader/SectionsNavigation';
import { Section } from 'components/navigation/subheader/SectionsNavigation';

export enum DetailPageEventCategory {
  Property = 'property',
  Address = 'address',
  LocalPage = 'local_page',
}

export const createDetailPageSectionHandlers = (category: DetailPageEventCategory) => (sendEvent: IAnalytics['sendEvent']) => ({
  setVisibleSection(this: { isSectionFirstCall?: boolean }, section: Section) {
    // intersection observer emits change after initialization on observe() call
    // we need to skip this event
    if (!this.isSectionFirstCall) {
      this.isSectionFirstCall = true;
      return;
    }

    // don't send event for footer;
    if (section === 'Footer') {
      return;
    }

    if (ANALYTICS_MAPPING[section]) {

      sendEvent(`${category}_scroll_to_section`, category, {
        event: { section },
      });
    }
  },
});
