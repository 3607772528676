import styled from '@emotion/styled';
import { FitTextFactory, SmallText } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

const Root = styled.div`
  ${({ theme }) => `
    display: flex;
    border-radius: ${theme.borderRadius.tiny};
    border: solid 1px ${theme.colors.neutrals.grey8};
    background-color: ${theme.colors.neutrals.grey10};
  `}
`;

export const MobileRoot = styled(Root)`
  ${({ theme }) => `
    flex-direction: column;
    padding: ${theme.spacing(3)};
    border: 1px solid ${colorToAlpha(theme.colors.brand.main, 0.1)};
  `}
`;

export const DesktopRoot = styled(Root)`
  ${({ theme }) => `
    justify-content: space-between;
    align-items: center;
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(2, 3)};
    border: 1px solid ${theme.colors.neutrals.grey8};
  `}
`;

export const InitialInfoContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin-right: ${theme.spacing(1)};
    }
  `}
`;

export const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  svg {
    animation: rotating 2s linear infinite;
  }
`;

export const IconWrapper = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(2)};

    svg {
      display: block;
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    margin-left: ${theme.spacing(1.5)};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileContent = styled(Content)<{ withMargin: boolean }>`
  ${({ theme, withMargin }) => `
    margin-bottom: ${withMargin ? theme.spacing(3) : 0};
  `}
`;

export const ButtonWrapper = styled.div(({ theme }) => ({
  'button': {
    borderColor: theme.colors.brand.main,
  },
}));

export const HeaderWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
    }
    svg {
      margin-right: ${theme.spacing(1)};
    }
  `}
  .verified-icon path {
    fill: ${({ theme }) => theme.colors.brand.main};
  }
`;

export const TooltipContainer = styled(SmallText)`
  ${({ theme: { spacing } }) => `
    padding: ${spacing(1)} ${spacing(.5)} ${spacing(.5)} ${spacing(.5)};
  `}
`;

export const FitText = FitTextFactory(2);
