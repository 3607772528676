import { styled } from 'ds';

export const Wrapper = styled.div`
  height: 100%;

  position: relative;
  z-index: 99;
  .tooltip-arrow:before, .tooltip-wrapper  {
    background: ${({ theme }) => theme.colors.neutrals.white};
  }

  .tooltip-arrow:before {
    box-shadow: -1px -1px 1px 0px rgba(0,0,0,0.08);
  }

  .tooltip-wrapper {
    ${({ theme }) => `
      padding: 0;
      max-width: unset;
      border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
      box-shadow: ${theme.shadow.level2};
      width: 100%;
    `}
  }
`;

export const InnerRefEl = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
