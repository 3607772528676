import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, intervalTime: number, dependencies: ReadonlyArray<any>) => {

  const callbackRef = useRef(null);
  useEffect(() => {
    callbackRef.current = callback;
  }, [ ...dependencies ]);

  useEffect(() => {
    const intervalId = setTimeout(() => callbackRef.current(), intervalTime);
    return () => clearTimeout(intervalId);
  }, [ intervalTime, ...dependencies ]);

};
