import { memoize } from 'lodash';
import React from 'react';
import { ShowBreakpoint, BreakpointValue } from 'consts/breakpoints';
import withEvents from 'analytics/withEvents';
import { withLocale, WithLocaleProps } from 'locale';
import { config as filtersConfig, FiltersSingleComponentsMap, FiltersGroupComponentsMap } from 'components/filters/config';
import { MobileExpandedWrapper } from './MobileWrapper';
import { FilterList } from './styled/common';
import { GenericFilterProps, IFiltersState, FilterType, BaseConfig, GetFilterProps, Filter, GroupFilterProps, Seller } from './types';
import { getInitialState, isFilterActiveByType } from './utils';
import { withMarketplace, WithMarketplaceProps } from 'hocs/withMarketplace';
import { DealType } from 'utils/entities';


interface FiltersProps extends WithLocaleProps, WithMarketplaceProps {
  values: IFiltersState;
  closeFiltersModal: (commitedValues: IFiltersState) => void;
  setValues: (values: IFiltersState, meta?: 'reset' | 'commit') => void;
  disabled?: boolean;
  isHeaderTabSubmenuVisible: boolean;
}

interface FiltersState {
  intermediateValues: IFiltersState;
  commitedValues: IFiltersState;
}

class Filters extends React.Component<FiltersProps, FiltersState> {

  public state: FiltersState = {
    intermediateValues: null,
    commitedValues: null,
  };

  public static getDerivedStateFromProps(props: FiltersProps, state: FiltersState): Partial<FiltersState> {
    if (state.commitedValues !== props.values) {
      return {
        commitedValues: props.values,
        intermediateValues: props.values,
      };
    }
    return null;
  }

  private getConfig(): BaseConfig {
    return filtersConfig[this.props.marketplace];
  }

  private makeIntermediateValueSetter = memoize(<K extends keyof IFiltersState>(type: K) => (value: IFiltersState[K], callback?: () => void) => {
    this.setState((state) => {
      if (type === 'dealType' && state.intermediateValues.dealType !== value) {
        const dealType = value as DealType;

        const initialState = getInitialState(dealType, this.props.marketplace);

        return {
          intermediateValues: {
            ...initialState,
            [type]: value,
          },
        };
      }

      return {
        intermediateValues: {
          ...state.intermediateValues,
          [type]: value,
        },
      };
    }, callback);
  });

  private intermediateProjectDiscountSetter = memoize(() => (value: boolean, callback?: () => void) => {
    this.setState((state) => {
      return {
        intermediateValues: {
          ...state.intermediateValues,
          projectDiscount: value,
        },
      };
    }, callback);
  });

  private makeResetValue = memoize(<K extends keyof IFiltersState>(type: K) => () => {
    this.props.setValues({
      ...this.state.intermediateValues,
      [type]: getInitialState(this.state.commitedValues.dealType, this.props.marketplace)[type],
    });
  });

  private sellerMemory: Seller[] = [];

  private makeMultipleResetValue = (arr: FilterType[]) => {
    const initialState = getInitialState(this.state.commitedValues.dealType, this.props.marketplace);
    const state = arr.reduce((res, val) => {
      res[val] = initialState[val];
      return res;
    }, {});
    this.props.setValues({
      ...this.state.intermediateValues,
      ...state,
    });
  };

  private overrideIntermediateValues = (intermediateValues: IFiltersState, commitedValues: IFiltersState): IFiltersState => {
    const prevProjectDiscount = commitedValues.projectDiscount;
    const nextProjectDiscount = intermediateValues.projectDiscount;

    const prevSellersSet = new Set(commitedValues.seller);
    const nextSellersSet = new Set(intermediateValues.seller);
    if (prevProjectDiscount !== nextProjectDiscount) {
      if (nextProjectDiscount) {
        this.sellerMemory = intermediateValues.seller;
        return {
          ...intermediateValues,
          seller: [ Seller.Developer ],
        };
      }
      else {
        return {
          ...intermediateValues,
          seller: this.sellerMemory,
        };
      }
    }
    else if (nextProjectDiscount
      && ((prevSellersSet.has(Seller.Agent) !== nextSellersSet.has(Seller.Agent))
      || (prevSellersSet.has(Seller.Private) !== nextSellersSet.has(Seller.Private)))
    ) {
      return {
        ...intermediateValues,
        projectDiscount: false,
      };
    }

    return intermediateValues;
  }

  private commitValues = () => {
    const maybeOverriddenIntermediateValues = this.overrideIntermediateValues(this.state.intermediateValues, this.state.commitedValues);

    this.props.setValues(maybeOverriddenIntermediateValues, 'commit');
  };

  private resetAll = (withDealType?: DealType) => {
    const dealType = withDealType ? withDealType : this.state.commitedValues.dealType;
    this.props.setValues(getInitialState(dealType, this.props.marketplace), 'reset');
  };

  private getProps: GetFilterProps = (type, intermediateValues, commitedValues) => {
    const initialValue = getInitialState(commitedValues.dealType, this.props.marketplace)[type];
    const commitedValue = commitedValues[type];
    return {
      resetValue: this.makeResetValue(type),
      commitValues: this.commitValues,
      setIntermediateValue: this.makeIntermediateValueSetter(type),
      setIntermediateProjectDiscountValue: this.intermediateProjectDiscountSetter(),
      resetAll: this.resetAll,
      marketplace: this.props.marketplace,
      initialValue,
      commitedValue,
      intermediateValue: intermediateValues[type],
      dealType: intermediateValues.dealType,
      projectDiscount: intermediateValues.projectDiscount,
      config: this.getConfig(),
      type,
    };
  };

  private isGroupFilterActive = (filtersAliases: string[], commitedValues: IFiltersState) => {
    for (const filterName of filtersAliases) {
      const initialValue = getInitialState(commitedValues.dealType, this.props.marketplace)[filterName];
      const commitedValue = commitedValues[filterName];

      if (isFilterActiveByType[filterName](commitedValue, initialValue, this.props.marketplace)) {
        return true;
      }
    }
    return false;
  };

  private countActiveFilters = (filtersAliases: string[], commitedValues: IFiltersState) => {
    return filtersAliases.reduce((sum, filterName) => {
      const initialValue = getInitialState(commitedValues.dealType, this.props.marketplace)[filterName];
      const commitedValue = commitedValues[filterName];

      if (isFilterActiveByType[filterName](commitedValue, initialValue, this.props.marketplace)) {
        sum++;
      }
      return sum;
    }, 0);
  };

  private renderFilters = (filters: Filter[], breakpoint: BreakpointValue) => filters.map((filter: Filter, idx: number) => {
    const { intermediateValues, commitedValues } = this.state;
    switch (filter.type) {
      case 'divider': {
        return <React.Fragment key={idx}>{filter.item}</React.Fragment>;
      }
      case 'single': {
        const Component: React.ComponentType<GenericFilterProps<typeof filter.item.filterType>> = FiltersSingleComponentsMap[this.props.marketplace][filter.item.filterType];
        return (
          <Component
            key={filter.item.filterType}
            isMobile={breakpoint <= 2}
            isInline={filter.item.isInline}
            {...this.getProps(filter.item.filterType, intermediateValues, commitedValues)}
          />
        );
      }
      case 'group': {
        const Component: React.ComponentType<GroupFilterProps> = FiltersGroupComponentsMap[this.props.marketplace][filter.groupType];

        return (
          <Component
            key={filter.groupType}
            getProps={this.getProps}
            filters={filter.items}
            config={this.getConfig()}
            commitedValues={commitedValues}
            commitValues={this.commitValues}
            marketplace={this.props.marketplace}
            intermediateValues={intermediateValues}
            isFilterActive={this.isGroupFilterActive}
            countActiveFilters={this.countActiveFilters}
            makeMultipleResetValue={this.makeMultipleResetValue}
            isHeaderTabSubmenuVisible={this.props.isHeaderTabSubmenuVisible}
          />
        );
      }
      default:
        return null;
    }
  });

  private getFilterConfigByBreakPoint(breakpoint: BreakpointValue = 5, conf: BaseConfig, dealType: DealType): Filter[] {
    let data = conf.filtersByDealType[dealType][breakpoint];
    let maybePrevBreakpoint = breakpoint - 1;

    while (!data) {
      data = conf.filtersByDealType[dealType][maybePrevBreakpoint--];
    }

    return data;
  }

  private renderContent = (breakpoint: BreakpointValue) => {
    const filtersCollection = this.getFilterConfigByBreakPoint(breakpoint, this.getConfig(), this.state.commitedValues.dealType);

    return this.renderFilters(filtersCollection, breakpoint);
  };

  public render() {

    const { commitedValues, intermediateValues } = this.state;
    const { disabled, closeFiltersModal } = this.props;

    return (
      <FilterList disabled={disabled} data-auto="filtersRoot">
        <ShowBreakpoint>
          {(breakpoint: BreakpointValue) => (
            breakpoint <= 2 ? (
              <MobileExpandedWrapper
                onReset={this.resetAll}
                onClose={() => closeFiltersModal(commitedValues)}
                onCommit={() => (this.commitValues(), closeFiltersModal(intermediateValues))}
              >
                {this.renderContent(breakpoint)}
              </MobileExpandedWrapper>
            ) : (
              <div data-auto="filters" style={{ display: 'flex' }}>
                {this.renderContent(breakpoint)}
              </div>
            )
          )}
        </ShowBreakpoint>
      </FilterList>
    );
  }
}

export default withMarketplace(withLocale(withEvents<FiltersProps>(sendEvent => ({
  setValues(_, type) {
    switch (type) {
      case 'commit':
        sendEvent('search_filter_submit_click', 'search');
        break;
      case 'reset':
        sendEvent('search_filter_clear', 'search', {
          event: {
            filter_category: 'all',
          },
        });
        break;
    }
  },
}))(Filters)));
