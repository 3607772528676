import React from 'react';
import { LogoLinkWrapper, LogoWrapper, SmallLogoWrapper } from './header/styled';
import MadlanLogoIcon from 'assets/svg/il-logo.svg';
import MadlanLogoSmallIcon from 'assets/svg/il-logo-small.svg';
import { Link } from 'components/link';
import { Route } from 'config/routes';


interface LogoProps {
  small?: boolean;
}

const Logo: React.FC<LogoProps> = props => {
  const { small } = props;

  return small ? (
    <SmallLogoWrapper data-auto="main-logo">
      <LogoLinkWrapper>
        <Link routeName={Route.Home} data-auto="main-logo-link">
          <MadlanLogoSmallIcon />
        </Link>
      </LogoLinkWrapper>
    </SmallLogoWrapper>
  ) : (
    <LogoWrapper data-auto="main-logo" >
      <LogoLinkWrapper>
        <Link routeName={Route.Home} data-auto="main-logo-link">
          <MadlanLogoIcon />
        </Link>
      </LogoLinkWrapper>
    </LogoWrapper>
  );
};

export default Logo;
