import styled from '@emotion/styled';
import { BreakpointValue } from 'consts/breakpoints';
import { TextProps, weightStringToNumber, LabelProps, typographyFontSizeCfg, typographyLineHeightCfg } from './types';

export const DefaultText = styled.div<TextProps>`
  letter-spacing: normal;
  color: inherit;
  font-family: NovemberHebrew;
  font-weight: ${({ weight = 'normal' }) => weightStringToNumber[weight]};
  ${({ caps }) => caps ? 'text-transform: uppercase;' : ''}
`;

export const H1 = styled(DefaultText.withComponent('h1'))`
  font-size: ${typographyFontSizeCfg.h1.mobile}px;
  line-height: ${typographyLineHeightCfg.h1.mobile}px;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    font-size: ${typographyFontSizeCfg.h1.desktop}px;
    line-height: ${typographyLineHeightCfg.h1.desktop}px;
  `}
`;

export const H2 = styled(DefaultText.withComponent('h2'))`
  font-size: ${typographyFontSizeCfg.h2.mobile}px;
  line-height: ${typographyLineHeightCfg.h2.mobile}px;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    font-size: ${typographyFontSizeCfg.h2.desktop}px;
    line-height: ${typographyLineHeightCfg.h2.desktop}px;
  `}
`;

export const H3 = styled(DefaultText.withComponent('h3'))`
  font-size: ${typographyFontSizeCfg.h3.mobile}px;
  line-height: ${typographyLineHeightCfg.h3.mobile}px;
`;

export const H4 = styled(DefaultText.withComponent('h4'))`
  font-size: ${typographyFontSizeCfg.h4.mobile}px;
  line-height: ${typographyLineHeightCfg.h4.mobile}px;
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    font-size: ${typographyFontSizeCfg.h4.desktop}px;
    line-height: ${typographyLineHeightCfg.h4.desktop}px;
  `}
`;

const TEXT_TEMPLATE = `
  font-size: ${typographyFontSizeCfg.text}px;
  line-height: ${typographyLineHeightCfg.text}px;
`;

export const Text = styled(DefaultText)`${TEXT_TEMPLATE}`;

const SMALL_TEXT_TEMPLATE = `
  font-size: ${typographyFontSizeCfg.smallText}px;
  line-height: ${typographyLineHeightCfg.smallText}px;
  min-height: 20px;
`;

export const SmallText = styled(DefaultText)`${SMALL_TEXT_TEMPLATE}`;

export const FitTextFactory = (breakPoint: BreakpointValue) => {
  const to = (breakPoint === 1 ? 1 : breakPoint - 1) as BreakpointValue;

  return styled(DefaultText)`
    ${({ theme }) => theme.media.showAt({ from: breakPoint })`${TEXT_TEMPLATE};`}
    ${({ theme }) => theme.media.showAt({ to })`${SMALL_TEXT_TEMPLATE}`}
  `;
};

export const TextLabel = styled(DefaultText)<LabelProps>`
  ${({ small }) => `
    font-size: ${typographyFontSizeCfg.textLabel[small ? 'small' : 'normal']}px;
    line-height: ${typographyLineHeightCfg.textLabel[small ? 'small' : 'normal']}px;
  `}
`;

export const H1SEO = H2.withComponent('h1');

export const HrefText = styled(Text)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brand.main};
  &:hover {
    color: ${({ theme }) => theme.colors.brand.dark};
  }
  &:active {
    color: ${({ theme }) => theme.colors.brand.darker};
  }
`;
