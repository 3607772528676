import { InsightType, InsightDescriptor, IInsight, InterestPoint } from 'utils/entities';
import { createSelector } from 'reselect';
import { ConnectivityInsightSummary, BikesInsightSummary, IHub } from 'store/state/selectors/insights/summaryTypes';
import { rtSymbolToColor, stationColorToColor, serviceToColor } from './utils';


import { makeDefaultStreetStaticUrl } from 'helpers/defaultStaticStreetView';
import { TransportationSectionType, InsightSection } from 'store/state/insightsContext';
import { interestPointsSelector, insightsListSelector } from 'store/state/domainData/selectors';
import { carouselDataMapper } from 'store/state/selectors/utils';
import { rawActiveTransportationStationSelector, insightsMobileMapTypeSelector, rawTransportationAccordionSelector, rawTransportationTransitTabSelector } from 'store/state/insightsContext/selectors';
import { flow, head } from 'lodash';


export interface TransportationAccordionSection {
  sectionId: TransportationSectionType;
  sectionResume?: string;
  routes?: TransportationAccordionRoute[];
  tabs?: TransportationAccordionSection[];
}

export interface TransportationAccordionRoute {
  time: number;
  description: string;
  lines?: TransportationAccordionLine[];
  accessible?: boolean;
  routeLongName?: string;
}

export type TransportationLineShape = 'square' | 'circle';

export interface TransportationAccordionLine {
  label: string;
  color: string;
  shape?: TransportationLineShape;
}

const specialWidgetTypes = [ InsightDescriptor.Bikes, InsightDescriptor.Connectivity ];

const makeInsightsSelector = (filterFn: (i: IInsight) => boolean) => createSelector([
  insightsListSelector,
], ( insights: IInsight[] ) => insights.filter(filterFn));

const getTransportationAnalystInsights = makeInsightsSelector((i => !specialWidgetTypes.includes(i.type as InsightDescriptor) && i.category === InsightType.Transportation));
export const getTransportationWidgetInsights = makeInsightsSelector((i => specialWidgetTypes.includes(i.type as InsightDescriptor)));

export const transportationIdsSelector = createSelector([
  getTransportationAnalystInsights,
], (insights) => insights.map(({ id }) => id));

const trainsCompanyNamesList = new Set([ 'PATH', 'LIRR', 'METRO-NORTH' ]);

const accordionDataMapper = (connectivityInsight: IInsight, bikesInsight: IInsight): TransportationAccordionSection[] => {
  const bikesInsightSummary = bikesInsight ? (bikesInsight.summary.nonText.data as BikesInsightSummary) : null;
  const connectivityInsightSummary = connectivityInsight ? (connectivityInsight.summary.nonText.data as ConnectivityInsightSummary) : null;
  const formattedData = [];
  if (connectivityInsight) {
    formattedData.push({
      sectionId: (TransportationSectionType.Transit),
      sectionResume: connectivityInsight.preview || '',
      tabs: [
        {
          sectionId: TransportationSectionType.Train,
          routes: connectivityInsightSummary.railStations.length ? connectivityInsightSummary.railStations.map(st => ({
            time: st.walkTime,
            description: st.stopName,
            accessible: Boolean(st.access),
            routeLongName: st.routeLongName || null,
            lines: st.lines ? st.lines.reduce((acc, l) => {
              if (trainsCompanyNamesList.has(l.service)) {
                if (!acc[l.service]) {
                  acc[l.service] = true;
                  acc.lines.push({
                    color: serviceToColor[l.service] || serviceToColor.default,
                    label: l.service,
                    shape: 'square',
                  });
                }
                return acc;
              }
              acc.lines.push({
                color: l.routeColor ? `#${l.routeColor}` : rtSymbolToColor[l.routeName],
                label: l.routeName,
              });
              return acc;
            }, { lines: [] }).lines : [],
          })) : null,
        },
        {
          sectionId: TransportationSectionType.Bus,
          routes: connectivityInsightSummary.busStations.length ? connectivityInsightSummary.busStations.map(st => ({
            time: st.walkTime,
            description: st.stopName,
            lines: st.lines ? st.lines.map((l) => ({
              color: l.routeColor ?  `#${l.routeColor}` : stationColorToColor.default,
              label: l.routeName,
            })) : [],
          })) : null,
        },
      ],
    });
  }

  if (bikesInsight) {
    formattedData.push({
      sectionId: TransportationSectionType.Bike,
      sectionResume: bikesInsight.preview || '',
      routes: bikesInsightSummary.length ? bikesInsightSummary.map((route) => ({
        time: route.time,
        description: route.text,
      })) : null,
    });
  }

  return formattedData;
};

const DEFAULT_STREETS_IMG_SIZE = { width: 1280, height: 720 };



const addPersonalHubImage = (interestPoints: InterestPoint[]) => (hub: IHub) => {
  const relatedInterestPoint = interestPoints.find(ip => ip.name.toLocaleLowerCase() === hub.name.toLocaleLowerCase());

  if (!relatedInterestPoint) return null;

  return ({
    ...hub,
    image: makeDefaultStreetStaticUrl({
      ...DEFAULT_STREETS_IMG_SIZE,
    }),
  });
};

const transportationHubsDataMapper = (connectivityInsight: IInsight, interestPoints: InterestPoint[]) => {
  const connectivityInsightSummary = (connectivityInsight.summary.nonText.data as ConnectivityInsightSummary);
  const mapper = (hub: IHub) => ({
    destination: hub.name,
    imgUrl: hub.image,
    time: hub.commuteTime,
  });

  const personalHubs = connectivityInsightSummary.hubs.personal && interestPoints.length
    ? connectivityInsightSummary.hubs.personal.map(addPersonalHubImage(interestPoints)).filter(Boolean).map(mapper)
    : [];
  const connectivityHubs = connectivityInsightSummary.hubs.general.map(mapper);
  return personalHubs.length
    ? [ connectivityHubs[0], ...personalHubs, ...connectivityHubs.slice(1) ]
    : [ connectivityHubs[0], null, ...connectivityHubs.slice(1) ];
};

export const transportationHubsDataSelector = createSelector([
  getTransportationWidgetInsights,
  interestPointsSelector,
], (widgetInsights, interestPoints) => {

  if (widgetInsights.length !== 2) {
    return [];
  }

  const connectivityInsight = widgetInsights.find(i => i.type === InsightDescriptor.Connectivity);

  return transportationHubsDataMapper(connectivityInsight, interestPoints);
});

export const transportationCarouselDataSelector = createSelector([
  getTransportationAnalystInsights,
], (analystInsights) => carouselDataMapper(analystInsights));

export const transportationAccordionDataSelector = createSelector([
  getTransportationWidgetInsights,
], (widgetInsights) => {
  if (!widgetInsights.length) return [];

  const connectivityInsight = widgetInsights.find(i => i.type === InsightDescriptor.Connectivity);
  const bikesInsight = widgetInsights.find(i => i.type === InsightDescriptor.Bikes);

  return accordionDataMapper(connectivityInsight, bikesInsight);
});

const isMobileModeSelector = flow(insightsMobileMapTypeSelector, type => type === InsightSection.Transportation);
export const activeTransportationStationSelector = createSelector([
  rawActiveTransportationStationSelector,
  flow(transportationAccordionDataSelector, head, (section) => {
    const hasActiveSectionRoute =
      (section && section.tabs && section.tabs[0]) &&
      (section.tabs[0].routes && section.tabs[0].routes[0]);

    if (!hasActiveSectionRoute) return null;

    return section.tabs[0].routes[0].description;
  }),
  isMobileModeSelector,
], (value, defaultValue, shouldUseDefault) => (
  (shouldUseDefault && value === null) ? defaultValue : value
));

export interface MobileTransportationCardItem {
  route: TransportationAccordionRoute;
  sectionId: TransportationSectionType;
}

const mapSectionToItem = (section: TransportationAccordionSection) => (
  section.routes ? section.routes.map(route => ({
    route,
    sectionId: section.sectionId,
  })) : []
);

export const itemsSelector = createSelector([
  transportationAccordionDataSelector,
], (sections): MobileTransportationCardItem[] => {

  const items: MobileTransportationCardItem[] = [];

  for (const section of sections) {
    if (section.tabs) {
      for (const tab of section.tabs) {
        items.push(...mapSectionToItem(tab));
      }
    }
    else if (section.routes) {
      items.push(...mapSectionToItem(section));
    }
  }

  return items;
});

export const accordionItemsExist = flow(itemsSelector, items => Boolean(items.length));

export const activeStationWithDefault = createSelector([
  activeTransportationStationSelector,
  flow(itemsSelector, head, (i) => i ? i.route.description : null),
], (value, defaultValue) => value || defaultValue);


const activeMobileItemSelector = createSelector([
  activeStationWithDefault,
  itemsSelector,
], (activeStation, items) => {
  return items.find(item => item.route.description === activeStation);
});

export const transportationAccordionSelector = createSelector([
  rawTransportationAccordionSelector,
  isMobileModeSelector,
  activeMobileItemSelector,
], (value, isMobile, activeMobileItem) => {
  if (!isMobile) return value;

  switch (activeMobileItem.sectionId) {
    case TransportationSectionType.Bus:
    case TransportationSectionType.Train:
      return TransportationSectionType.Transit;

    default:
      return activeMobileItem.sectionId;
  }
});

export const transportationTransitTabSelector = createSelector([
  rawTransportationTransitTabSelector,
  isMobileModeSelector,
  activeMobileItemSelector,
], (value, isMobile, activeMobileItem) => (
  isMobile ? activeMobileItem.sectionId : value
));

export const hasTransportationWidgetDataSelector = createSelector([
  transportationAccordionDataSelector,
  transportationCarouselDataSelector,
  transportationHubsDataSelector,
], (accordionData, carouselData, transportationHubsData): boolean =>
  Boolean(accordionData.length || carouselData.length || transportationHubsData.length));
