import React, { ReactNode } from 'react';
import { ScrollSpyTarget } from 'components/scroll-spy';
import { WithContentSlotSidePaddingContainer } from 'ds/components/Layout';
import { InsightSection } from 'store/state/insightsContext';
import { InsightCarouselShiftedPadding, InsightsSectionItemInnerPadding } from 'ds/components/Insights';
import { CollapsedInsightWidget } from 'components/insight-widget-collapse';
import { DynamicData, renderInsightCollapsedDynamicContent } from './dynamic-content';
import { useLocale } from 'locale';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { marketplaceSelector } from 'store/state/selectors/router';
import { MarketplaceType, InsightType } from 'utils/entities';

interface WidgetWrapperProps {
  targetId: InsightSection;
  carousel?: ReactNode;
  transportationData?: ReactNode;
  dynamicContentData?: DynamicData;
  onlyAnalystInsight?: boolean;
  marketplace: MarketplaceType;
  styleType: InsightType;
}

const WidgetCollapsedWrapper: React.FC<WidgetWrapperProps> = ({
  targetId,
  children,
  carousel,
  transportationData,
  dynamicContentData = null,
  onlyAnalystInsight,
  marketplace,
  styleType,
}) => {
  const { t } = useLocale();
  const widgetHasCarouselData = Boolean(carousel);
  const isCommercial = marketplace === MarketplaceType.Commercial;

  const WIDGET_TITLE = {
    [InsightSection.NeighbourhoodLife]: t('widgetTitle.neighbourhoodLife.collapsed', { isCommercial }),
    [InsightSection.Prices]: t('widgetTitle.price.collapsed'),
    [InsightSection.Schools]: t('widgetTitle.schools.collapsed'),
    [InsightSection.Planning]: t('widgetTitle.newConstructions.collapsed'),
    [InsightSection.Transportation]: t('widgetTitle.transportation'),
    [InsightSection.Safety]: t('widgetTitle.safety'),
    [InsightSection.Nuisances]: t('widgetTitle.nuisances.collapsed'),
    [InsightSection.QuietStreets]: t('widgetTitle.quietStreets.collapsed'),
    [InsightSection.Neighbors]: t('widgetTitle.neighbors'),
    [InsightSection.FuturePlans]: t('simplex.futurePlan.title'),
    [InsightSection.DivisionAreaPlan]: t('simplex.divisionArea.title'),
  };

  return (
    <CollapsedInsightWidget
      styleType={styleType}
      type={targetId}
      dynamic={renderInsightCollapsedDynamicContent(targetId, dynamicContentData, widgetHasCarouselData, onlyAnalystInsight)}
      title={WIDGET_TITLE[targetId]}
    >
      <ScrollSpyTarget targetId={targetId}>
        {transportationData ? (
          <InsightsSectionItemInnerPadding>
            {transportationData}
          </InsightsSectionItemInnerPadding>
        ) : null}
        {children ? (
          <WithContentSlotSidePaddingContainer noMobilePadding>
            {carousel ? (
              <InsightsSectionItemInnerPadding>
                {children}
              </InsightsSectionItemInnerPadding>
            ) : children}
          </WithContentSlotSidePaddingContainer>
        ) : null}
        {carousel ? (
          <InsightCarouselShiftedPadding side="both">
            {carousel}
          </InsightCarouselShiftedPadding>
        ) : null}
      </ScrollSpyTarget>
    </CollapsedInsightWidget>
  );
};

const mapStateToProps = (state: State) => ({
  marketplace: marketplaceSelector(state),
});

export default connect(mapStateToProps)(WidgetCollapsedWrapper);

