import { ISOString } from 'locale/formatDate';
import { ILatLngPoint, PoiId, POC } from 'utils/entities';
import { RoomOption } from 'components/filters/types';


export enum PricesSectionType {
  LocalHistory = 'history',
  ComparableListings = 'comparable',
  Listing = 'listing',
}
export enum PricesTabType {
  Unit = 'unit',
  Building = 'building',
  Area = 'area',
  Compare = 'compare',
  CompareDeals = 'compareDeals',
  Street = 'street',
}
export interface IPricesHistoryTab {
  tabId: PricesTabType;
  tableData: HistoryItem[];
}
export type PricesAccordionTab = IPricesHistoryTab;
export interface PricesAccordionSection {
  sectionId: PricesSectionType;
  tabs?: PricesAccordionTab[];
}
export interface HistoryItem {
  id: string;
  date: ISOString;
  action: Action;
  price: number;
  beds: number;
  size: number;
  pricePerSqFt?: number;
  detailedSource?: string;
  buyer: string;
  seller: string;
  units?: Array<{
    id: string;
    type: string;
  }>;
  address?: string;
  prevPrice?: number;
  dealDetails?: DetailedAction;
  unit?: string;
  type?: string;
  source?: string;
  isFinal: boolean;
  year?: number;
  floor: number;
  unitsCount: number;
  currentPoiLabel: string;
  distance?: number;
  totalFloors?: number;
  projectName?: string;
  projectIds?: string[];
}
export interface HistoryItemAgent {
  agentId: string;
  agentName: string;
  agentImageUrl: string;
  agencyId: string;
  agencyName: string;
  agencyImageUrl: string;
}
export interface HistoryHebrewItem extends Omit<HistoryItem, 'buyer' | 'seller'> {
  buyer: HistoryItemAgent;
  seller: HistoryItemAgent;
  isAgent?: boolean;
}
export interface ComparableItem {
  unit: string;
  price: number;
  sqFt: number;
  beds: number;
  baths: number;
  id: string;
  sqFtDiff?: number;
  priceDiff?: number;
  address?: string;
  floor?: number;
  isCurrent?: boolean;
  type?: string;
}

export type PricesYearFilter = [ number, number ];
export type PricesBedsFilter = [ RoomOption, RoomOption ];

export interface PricesState {
  accordion: PricesSectionType;
  tab: PricesTabType;
  yearFilter: PricesYearFilter;
  bedsFilter: PricesBedsFilter;
  onlyFinalTransactionsFilter: boolean;
  activeEstimationIndex: number[];
  activeHistoryIndex: string[];
}
export type Action = 'sale' | 'bulkSale' | 'nonMarket' | 'partialSale' | 'listed' | 'removed' | 'price-drop' | 'price-up';
export type DetailedAction = 'nonMarket' | 'partialSale';

export interface RawHistoryItemAddress {
  hNo: string;
  borough: string;
  stName: string;
  neighbourhood: string;
  location: ILatLngPoint;
  docId: string;
  city: string;
}

export interface RawHistoryItem {
  action: Action;
  addressRecord: RawHistoryItemAddress;
  amount: number;
  saleId: string;
  baths: number;
  beds: number;
  date: ISOString;
  final: boolean;
  from: string;
  percentTransferred: number;
  propertiesCount: number;
  pricePerMeter: number;
  projectIds: string[];
  projectName: string;
  size: number;
  source: string;
  to: string;
  unit: string;
  type?: string;
  floor: number;
  yearBuilt: number;
  revenue?: {
    absolute: number;
    percentage: number;
    previousSale: {
      price: number;
      date: ISOString;
    };
  };
  distance?: number;
  totalFloors? : number;
}
export interface RawHistoryItemAgent {
  agentId: string;
  agentLogo: string;
  agentName: string;
  agentType: string;
  officeId: string;
  officeLogo: string;
  officeName: string;
}
export interface RawHebrewHistoryItem extends Omit<RawHistoryItem, 'from' | 'to'> {
  from: RawHistoryItemAgent[];
  to: RawHistoryItemAgent[];
}

interface EstimationRecordGeneric {
  address: string;
  price: number;
  rooms: number;
  area: number;
  date?: ISOString;
  pricePerSquareMeter: number;
  projectName?: string;
  projectIds?: string[];
  constructionYear: number;
  location?: [ number, number ];
  currentPoiLabel: string;
  distance?: number;
}


export interface IHistoricalPriceEstimationRecord extends EstimationRecordGeneric {
  id?: string;
  type: 'deal';
  soldId?: string;
  date: ISOString;
}

export interface IBulletinPriceEstimationRecord extends EstimationRecordGeneric {
  type: 'bulletin';
  id: string;
  date?: string;
  docId: string;
  firstTimeSeen: ISOString;
  poc?: POC;
  totalFloors?: number;
  street?: string;
  houseNumber?: number;
}

export type IPriceEstimationRecord = IHistoricalPriceEstimationRecord | IBulletinPriceEstimationRecord;

interface IRawEstimationListings {
  address: string;
  area: number;
  beds: number;
  buildingYear: number;
  id: PoiId;
  location: ILatLngPoint;
  price: number;
  pricePerMeter: number;
  projectName: string;
  projectIds: string[];
  type: 'deal' | 'bulletin';
  firstTimeSeen: ISOString;
  docId: string;
  poc: POC;
}

export interface IRawEstimation {
  boundaries: {
    bottomBoundary: number;
    topBoundary: number;
  };
  estimatedPrice: number;
  initialPrice: number;
  refListings: IRawEstimationListings[];
}
