import React from 'react';
import { useLocale } from 'locale';
import { MadadSearchResultCategory } from 'utils/entities';
import styled from '@emotion/styled';
import { SmallText, TextLabel } from 'ds/components/typography';
import Tooltip from 'ds/components/tooltip';


export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Tag = styled(TextLabel)<{
  madadSearchResult?: MadadSearchResultCategory;
  suppressMargin?: boolean;
}> `
  ${({ theme, suppressMargin }) => `
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.25)};
    border-radius: ${theme.borderRadius.tiny};
    line-height: 13px;
    height: 100%;
    white-space: nowrap;
    border: 1px solid ${theme.colors.neutrals.grey8};
    background-color: #fcfaf7;
    letter-spacing: 0.2px;
    ${suppressMargin ? '' : `margin-right: ${theme.spacing(0.5)}`};
  `}
  ${({ theme, madadSearchResult }) => {
    if (madadSearchResult === 'top_10') {
      return `
        color: #12a86b;
      `;
    }
    if (madadSearchResult === 'top_10_gold') {
      return `
        color: #c69202;
      `;
    }
    return `
      color: ${theme.colors.neutrals.grey1};
    `;
  }}
`;
Tag.defaultProps = { small: true, weight: 'bold' };


interface AgentTagsProps {
  isExclusive?: boolean;
  madadSearchResult?: MadadSearchResultCategory;
}

const AgentTags: React.FC<AgentTagsProps> = props => {
  const { isExclusive, madadSearchResult } = props;
  const { t } = useLocale();

  return (
    <TagsWrapper>
      <Tag data-auto={`agent-tag${isExclusive ? '-exclusive' : ''}`} suppressMargin>
        {t('listingCard.agent.label', { isExclusive })}
      </Tag>
      {madadSearchResult ? (
        <Tooltip
          placement="top-end"
          tooltip={<SmallText>{t('madad.categoryBadge.tooltip')}</SmallText>}
        >
          <Tag
            data-auto={`madad-tag-${madadSearchResult === 'top_10_gold' ? 'gold' : 'green'}`}
            madadSearchResult={madadSearchResult}
          >
            {t('listingCard.agent.madadWinnerLabel')}
          </Tag>
        </Tooltip>
      ) : null}
    </TagsWrapper>
  );
};

export default AgentTags;
