import {
  isLoadingProjectSearchListSelector,
  projectsByIdsSelector,
} from 'store/state/domainData/selectors';
import {
  makeMapStateToPropsFactory,
  PoiGetter,
} from 'components/cards/listing/makeMapStateToPropsFactory';
import { State } from 'store/state';
import { IBulletin, IProject, PoiId, ResolutionPreference } from 'utils/entities';
import { UniversalCardProps } from 'components/cards/listing/UniversalCard/UniversalCard';
import UniversalCard from 'components/cards/listing/UniversalCard';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Carousel from 'ds/components/carousels/carousel-projects';
import { CenteredCarouselControls } from 'ds/components/carousels/styled';
import { CardType } from 'components/cards/types';
import ContactButton from './ContactButton';
import { ContactAgentModalSource } from 'store/state/app';
import { ItemWrapper, Wrapper } from './styled';
import { projectsSelector } from './selectors';

interface ProjectCarouselProps {
  projectsList: Array<IBulletin | IProject>;
  isLoading: boolean;
  source: ContactAgentModalSource;
  carouselBgColor?: string;
}

const poiByIdsSelector: PoiGetter = (state: State, poiId: PoiId) => projectsByIdsSelector(state)[poiId];

const makeMapStateToProps = makeMapStateToPropsFactory(poiByIdsSelector);

const SpecificUniversalCard = (props: UniversalCardProps) => {
  let enhancedProps: UniversalCardProps = {
    ...props,
    hideLogoPreview: true,
    isMinHeight: true,
  };
  if (enhancedProps.cardType === CardType.Bulletin || enhancedProps.cardType === CardType.CommercialBulletin) {
    enhancedProps = {
      ...enhancedProps,
      resolutionPreferences: ResolutionPreference.Neighborhood,
    };
  }
  return <UniversalCard {...enhancedProps} />;
};

const ConnectedUniversalCard = connect(makeMapStateToProps)(SpecificUniversalCard);


export const ProjectCarousel: React.FC<ProjectCarouselProps> = ({ projectsList, isLoading, source, carouselBgColor }) => {
  const [ projects, setProjects ] = useState(projectsList);

  useEffect(() => {
    setProjects(projectsList);
  }, [ isLoading ]);
  if (!projects) return null;

  const hasArrows = projects.length > 2;

  const list = projects.map(({ id, type }) => {
    return (
      <ItemWrapper key={id}>
        <ConnectedUniversalCard id={id} showAddToFavourites={false} onlyOneImageShouldBeDisplayed />
        <ContactButton id={id} source={source} />
      </ItemWrapper>
    );
  });

  return (
    <Wrapper>
      <CenteredCarouselControls>
        <Carousel
          isLoading={isLoading}
          hasArrows={hasArrows}
          arrowsGradientBgColor={carouselBgColor}
        >
          {list}
        </Carousel>
      </CenteredCarouselControls>
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => ({
  projectsList: projectsSelector(state),
  isLoading: isLoadingProjectSearchListSelector(state),
});


export default connect(mapStateToProps)(ProjectCarousel);
