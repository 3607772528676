import React from 'react';
import { useRifm } from 'rifm';
import { useLocale } from 'locale';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { TextField, TextFieldProps } from 'ds/components/input/TextField';


type NumberInputProps = {
  value: string;
  name: string;
  onChange: (val: string) => void;
} & Exclude<TextFieldProps, 'onChange' | 'value'>;


const TextFieldWithValidationMessage = withValidationMessage(TextField);

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const { formatNumber } = useLocale();

  const format = (str: string) => {
    const r = parseInt(str.replace(/[^\d]+/gi, ''), 10);
    if (!r) return '';
    return formatNumber(r);
  };

  const handleChange = (str: string) => onChange(str.replace(/[^\d]+/gi, ''));

  const rifm = useRifm({
    value,
    onChange: handleChange,
    format,
  });

  return (
    <TextFieldWithValidationMessage
      {...rest}
      type="tel"
      value={rifm.value}
      onChange={rifm.onChange}
    />
  );
};

export default React.memo(NumberInput);
