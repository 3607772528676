import React, { useState } from 'react';
import { useInterval } from 'hooks/useInterval';
import { getInfiniteNextIndex } from 'utils/gallery';

interface TextIntervalProps {
  texts: string[];
  interval?: number;
  stopOnLast?: boolean;
}

export const TextInterval: React.FC<TextIntervalProps> = ({ texts, interval, stopOnLast }) => {
  const [ index, setIndex ] = useState<number>(0);

  const isLast = (texts.length - 1) === index;

  useInterval(() =>  {
    const stop = stopOnLast && isLast;
    if (!stop) {
      setIndex((prev) => getInfiniteNextIndex(prev, texts.length));
    }
  }, interval, [ index, texts.length, stopOnLast ]);

  const text = texts[index];

  return <>{text}</>;
};

TextInterval.defaultProps = {
  interval: 3000,
  stopOnLast: false,
};
