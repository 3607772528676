import { ResponseByType, LoadType, VariablesByType, IDataLayersResponse, IDataLayersVariables, Cursor } from 'store/sagas/apiService/types';
import { NetworkStatus } from 'apollo-client';
import { Domain } from './index';
import { keyBy, shuffle } from 'lodash';
import { IDataLayer } from 'utils/entities';


interface NextResult<T extends LoadType> {
  fetchMoreResult: ResponseByType[T];
  fetchMoreVariables: VariablesByType[T];
}

export type UpdateFn<T extends LoadType> = (
  prevResult: ResponseByType[T],
  nextResult: NextResult<T>
) => ResponseByType[T];

export type UpdateFnsMap = {
  [K in LoadType]?: UpdateFn<K>;
};

const defaultUpdater: UpdateFn<any> = (data) => data;

export type UpdateLoadFn<T extends LoadType> = (data: ResponseByType[T]) => ResponseByType[T];

export type UpdateLoadFnsMap = {
  [K in LoadType]?: UpdateLoadFn<K>;
};

const defaultLoadUpdater: UpdateLoadFn<any> = (data) => data;


interface SearchV2<T extends unknown> {
  searchPoiV2: {
    poi: T[];
    total?: number;
    cursor?: Cursor;
  };
}

const mergeSearchV2 = <T extends unknown>(previousResult: SearchV2<T>, { fetchMoreResult }: { fetchMoreResult: SearchV2<T>, fetchMoreVariables: unknown }) => ({
  searchPoiV2: {
    ...fetchMoreResult.searchPoiV2,
    poi: [
      ...(previousResult && previousResult.searchPoiV2 ? previousResult.searchPoiV2.poi : []),
      ...fetchMoreResult.searchPoiV2.poi,
    ],
  },
});

const mergeMapSearchV2 = <T extends unknown>(previousResult: SearchV2<T>, { fetchMoreResult }: { fetchMoreResult: SearchV2<T>, fetchMoreVariables: unknown }) => ({
  searchPoiV2: {
    ...fetchMoreResult.searchPoiV2,
    poi: [
      ...(previousResult && previousResult.searchPoiV2 ? previousResult.searchPoiV2.poi : []),
      ...shuffle(fetchMoreResult.searchPoiV2.poi),
    ],
  },
});

const shuffleMapData = <T extends unknown>(data: SearchV2<T>) => ({
  searchPoiV2: {
    ...data.searchPoiV2,
    poi: shuffle(data.searchPoiV2.poi),
  },
});

const TabuMerge: UpdateFn<LoadType.Tabu> = (_, { fetchMoreResult } ) => ({
  docId2NesachTabu: (fetchMoreResult && fetchMoreResult.docId2NesachTabu ? fetchMoreResult.docId2NesachTabu : null),
});

function mergeDataLayers<P extends IDataLayer>(
  prev: IDataLayersResponse<P>,
  { fetchMoreResult, fetchMoreVariables }: { fetchMoreResult: IDataLayersResponse<P>, fetchMoreVariables: IDataLayersVariables }
): IDataLayersResponse<P> {

  if (!fetchMoreResult.searchDataLayers.poi.length) {
    return prev;
  }

  const { zoom } = fetchMoreVariables;

  const prevAtZoom = prev && prev[zoom] || {};

  const nextResult = {
    [zoom]: {
      ...prevAtZoom,
      ...keyBy(fetchMoreResult.searchDataLayers.poi, (p) => p.id),
    },
  };

  return prev ? {
    ...prev,
    ...nextResult,
  } : nextResult;
}

const mergeFavoritesPois: UpdateFn<LoadType.FavoritesPois> = (prevResult, { fetchMoreResult }) => ({
  poiByIds: [ ...(prevResult && prevResult.poiByIds || []), ...(fetchMoreResult && fetchMoreResult.poiByIds || []) ],
});

const mergeShortlistPois: UpdateFn<LoadType.ShortlistPois> = (prevResult, { fetchMoreResult }) => ({
  poiByIds: [ ...(prevResult && prevResult.poiByIds || []), ...(fetchMoreResult && fetchMoreResult.poiByIds || []) ],
});

const UPDATE_QUERY_BY_LOAD_TYPE: UpdateFnsMap = {
  [LoadType.SearchList]: mergeSearchV2,
  [LoadType.SearchMapDataLayers]: mergeDataLayers,
  [LoadType.SearchMapClusters]: mergeDataLayers,
  [LoadType.SearchMapListings]: mergeMapSearchV2,
  [LoadType.Tabu]: TabuMerge,
  [LoadType.FavoritesPois]: mergeFavoritesPois,
  [LoadType.ShortlistPois]: mergeShortlistPois,
};

const UPDATE_LOAD_DATA_BY_TYPE: UpdateLoadFnsMap = {
  [LoadType.SearchMapListings]: shuffleMapData,
};

export const getUpdateFn = <T extends LoadType>(loadType: T) => UPDATE_QUERY_BY_LOAD_TYPE[loadType] || defaultUpdater;

export const getLoadUpdateFn = <T extends LoadType>(loadType: T) => UPDATE_LOAD_DATA_BY_TYPE[loadType] || defaultLoadUpdater;

export const domainInitialState: Domain<any> = {
  networkStatus: NetworkStatus.loading,
  data: null,
  pendingCount: 0,
  loading: true,
  stale: null,
  meta: null,
};

export function fillLoadTypes<K>(val: K): Record<LoadType, K> {
  return Object.values(LoadType).reduce((acc, current) => {
    acc[current] = val;
    return acc;
  }, {} as Record<LoadType, K>);
}
