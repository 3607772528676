import { IPriceEstimationRecord } from 'store/state/selectors/insights/prices';


export interface EstimatedPriceProps {
  estimatedPrice: number;
  price: number;
  minEstimation: number;
  maxEstimation: number;
  isLoading: boolean;
  records: IPriceEstimationRecord[];
  handleScroll?: () => void;
}

export enum PriceRange {
  Top = 'Top',
  Range = 'Range',
  Bottom = 'Bottom',
}
