import { BoundingBox } from './entities';

interface Box {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

const SCALE = 1;
const WORLD_SIZE = 512;


function scaleZoom(scale: number) {
  return Math.log(scale) / Math.LN2;
}
function zoomScale(zoom: number) {
  return Math.pow(2, zoom);
}

function mercatorXfromLng(lng: number) {
  return (180 + lng) / 360;
}

function mercatorYfromLat(lat: number) {
  return (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;
}

export function lngFromMercatorX(x: number) {
  return x * 360 - 180;
}

export function latFromMercatorY(y: number) {
  const y2 = 180 - y * 360;
  return 360 / Math.PI * Math.atan(Math.exp(y2 * Math.PI / 180)) - 90;
}

function project(lng: number, lat: number): [ number, number ] {
  return [
    mercatorXfromLng(lng) * WORLD_SIZE,
    mercatorYfromLat(lat) * WORLD_SIZE,
  ];
}

function unproject(x: number, y: number): [ number, number ] {
  return [
    lngFromMercatorX(x / WORLD_SIZE),
    latFromMercatorY(y / WORLD_SIZE),
  ];
}
const NO_PADDING: Box = {
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};


export function getAspectRatio(bounds: BoundingBox) {
  const [ [ west, south ], [ east, north ] ] = bounds;
  const [ x2, y2 ] = project(east, south);
  const [ x1, y1 ] = project(west, north);
  const dx = x2 - x1;
  const dy = y2 - y1;

  return Math.abs(dx / dy);
}


export function zoomForBounds(width: number, height: number, bounds: BoundingBox, padding: Box) {
  const [ [ west, south ], [ east, north ] ] = bounds;
  const [ x2, y2 ] = project(east, south);
  const [ x1, y1 ] = project(west, north);
  const dx = x2 - x1;
  const dy = y2 - y1;
  const padWithDefault = { ...NO_PADDING, ...padding };

  const scaleX = (width - (padWithDefault.left + padWithDefault.right)) / dx;
  const scaleY = (height - (padWithDefault.top + padWithDefault.bottom)) / dy;

  const paddingOffsetX = (padding.left - padding.right) / 2;
  const paddingOffsetY = (padding.top - padding.bottom) / 2;
  const zoom = scaleZoom(SCALE * Math.min(scaleX, scaleY));

  const paddingOffsetMult = SCALE / zoomScale(zoom);

  const offsetX = paddingOffsetX * paddingOffsetMult;
  const offsetY = paddingOffsetY * paddingOffsetMult;

  const center = unproject(
    (x1 + x2) / 2 - offsetX,
    (y1 + y2) / 2 - offsetY
  );

  return { zoom, center };
}
