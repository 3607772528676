import { connect } from 'react-redux';
import { State } from 'store/state';
import { manageListingsErrorModalSelector } from 'store/state/app/selectors';
import { closeManageListingsErrorModal } from 'store/state/app/actions';
import { ErrorModal } from './ErrorModal';
import * as actions from 'store/state/router/actions';
import { userWithReducedListingLimitSelector } from 'store/state/domainData/selectors';
import { uploadBulletinDraft } from '../helpers';
import { initialValuesSelector } from 'screens/EditBulletinPage';


const mapStateToProps = (state: State) => ({
  data: manageListingsErrorModalSelector(state),
  userWithReducedListingLimit: userWithReducedListingLimitSelector(state),
  initialUploadValues: uploadBulletinDraft.get(),
  initialEditValues: initialValuesSelector(state),
});

const mapDispatchToProps = {
  onClose: closeManageListingsErrorModal,
  navigateTo: actions.navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
