import React from 'react';
import { ShowAt } from 'consts/breakpoints';
import { Link } from 'components/link';
import { Route } from 'config/routes';
import ManageIcon from 'assets/svg/list.svg';
import { SpacingBlock } from 'components/spacing-block';
import { UserActionName } from 'components/navigation/userActions';
import { useLocale } from 'locale';
import { ManageLinkWrapper } from './styled';


const ManageLink: React.FC = () => {
  const { t } = useLocale();

  return (
    <ShowAt from={3}>
      <ManageLinkWrapper>
        <Link routeName={Route.ManageBulletins}>
          <ManageIcon height={24} width={24} />
          <SpacingBlock mTop={0.25}>
            {t('navigation.header.actions', { isAgent: false, action: UserActionName.MyListings })}
          </SpacingBlock>
        </Link>
      </ManageLinkWrapper>
    </ShowAt>
  );
};


export default ManageLink;
