import { SortValue } from 'components/listing-sort/types';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { CommutePreference, DealType, IAutocompleteEntry, PurchasingReason } from 'utils/entities';
import {
  GO_BACK,
  NEXT,
  SET_COMMUTE_ADDRESS,
  SET_COMMUTE_RUSH_HOUR,
  SET_COMMUTE_TYPE,
  SET_DEAL_TYPE,
  SET_DOC_ID,
  SET_MOVE_IN,
  SET_OPEN,
  SET_PREVIEW_DOC_ID,
  SET_PRICE_RANGE,
  SET_PURCHASING_REASON,
  SET_ROOMS_RANGE,
  SET_SEARCH_STR,
  SET_SMART_SORT,
  SKIP,
  SET_COMMUTE_SUB_STEP,
  SET_COMMUTE_MAX_DISTANCE_TRAIN,
  SET_COMMUTE_MAX_TIME_WALK,
  SET_COMMUTE_MAX_TIME_CAR,
  SET_COMMUTE_MAX_TIME,
} from './types';
import { RangeTuple, RoomOption } from 'components/filters/types';
import { SetWizardOpenPayload } from './payloads';


export enum MoveIn {
  Looking = 'onlyLooking',
  NotInAHurry = 'notInAHurry',
  Now = 'now',
}

export enum HomepageWizardStep {
  DealType = 'dealType',
  Filters = 'filters',
  Commute = 'commute',
  PurchasingReason = 'purchasingReason',
}

export const WIZARD_STEPS_IL: HomepageWizardStep[] = [
  HomepageWizardStep.DealType,
  HomepageWizardStep.Filters,
  HomepageWizardStep.Commute,
  HomepageWizardStep.PurchasingReason,
];

export interface HomepageWizardState {
  purchasingReason: PurchasingReason;
  currentStepIndex: number;
  dealType: DealType;
  sort: SortValue[];
  docId: string;
  previewDocId: string;
  address: IAutocompleteEntry;
  searchStr: string;
  commutePreference: CommutePreference;
  isOpen: boolean;
  priceRange: RangeTuple<number>;
  roomsRange: RangeTuple<RoomOption>;
  moveIn: MoveIn;
  isCommuteSubStep: boolean;
}

export type HomepageWizardAction = ActionType<typeof actions>;

const initialState: HomepageWizardState = {
  isOpen: false,
  currentStepIndex: 0,
  purchasingReason: null,
  dealType: null,
  sort: [],
  docId: null,
  previewDocId: null,
  address: null,
  searchStr: '',
  commutePreference: {
    location: null,
    text: '',
    rushHour: false,
    commuteType: null,
    docId: null,
    maxCommute: {
      distanceMaxBicycleCommute: undefined,
      distanceMaxTrainCommute: undefined,
      timeMaxWalkCommute: undefined,
      timeMaxCarCommute: undefined,
      timeMaxCommute: undefined,
    },
  },
  priceRange: [ null, null ],
  roomsRange: [ null, null ],
  moveIn: MoveIn.NotInAHurry,
  isCommuteSubStep: false,
};

const setDealType = (state: HomepageWizardState, value: DealType, autoNext = false): Partial<HomepageWizardState> => value ? ({
  dealType: value,
  currentStepIndex: state.currentStepIndex + (autoNext ? 1 : 0),
  priceRange: state.dealType === value ? state.priceRange : [ null, null ],
}) : {};

const shouldPrefillStateOnOpen = (isInitial: boolean, payload: SetWizardOpenPayload) => isInitial
  ? payload
  : ({ isOpen: payload.isOpen });

export default (state = initialState, action: HomepageWizardAction): HomepageWizardState => {
  const { currentStepIndex } = state;
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        currentStepIndex: 0,
        ...shouldPrefillStateOnOpen(state.dealType === initialState.dealType, action.payload),
        ...setDealType(state, action.payload.dealType, false), // dealType will always override prevState
      };
    case GO_BACK:
      return {
        ...state,
        currentStepIndex: currentStepIndex - 1,
      };
    // we need 2 separate events because of different side effects behaviours, we send skip event on skip action
    case SKIP:
    case NEXT:
      return {
        ...state,
        currentStepIndex: currentStepIndex + 1,
      };
    case SET_DEAL_TYPE:
      return {
        ...state,
        ...setDealType(state, action.payload, action.meta.autoNavigateToNextStep),
      };
    case SET_DOC_ID:
      return {
        ...state,
        docId: action.payload.docId,
        address: action.payload,
        currentStepIndex: action.meta.navigateToNextStep ? currentStepIndex + 1 : currentStepIndex,
      };
    case SET_PREVIEW_DOC_ID:
      return {
        ...state,
        previewDocId: action.payload.docId,
      };
    case SET_SMART_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_PURCHASING_REASON:
      return {
        ...state,
        purchasingReason: action.payload,
      };
    case SET_SEARCH_STR:
      return {
        ...state,
        searchStr: action.payload,
      };
    case SET_COMMUTE_TYPE:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          commuteType: action.payload,
        },
      };
    case SET_COMMUTE_MAX_DISTANCE_TRAIN:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          maxCommute: {
            ...state.commutePreference.maxCommute,
            distanceMaxTrainCommute: action.payload,
          },
        },
      };
    case SET_COMMUTE_MAX_TIME_WALK:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          maxCommute: {
            ...state.commutePreference.maxCommute,
            timeMaxWalkCommute: action.payload,
          },
        },
      };
    case SET_COMMUTE_MAX_TIME_CAR:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          maxCommute: {
            ...state.commutePreference.maxCommute,
            timeMaxCarCommute: action.payload,
          },
        },
      };
    case SET_COMMUTE_MAX_TIME:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          maxCommute: {
            ...state.commutePreference.maxCommute,
            timeMaxCommute: action.payload,
          },
        },
      };
    case SET_COMMUTE_ADDRESS:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          ...action.payload,
        },
      };
    case SET_COMMUTE_RUSH_HOUR:
      return {
        ...state,
        commutePreference: {
          ...state.commutePreference,
          rushHour: action.payload,
        },
      };
    case SET_PRICE_RANGE:
      return {
        ...state,
        priceRange: action.payload,
      };
    case SET_ROOMS_RANGE:
      return {
        ...state,
        roomsRange: action.payload,
      };
    case SET_MOVE_IN:
      return {
        ...state,
        moveIn: action.payload,
      };
    case SET_COMMUTE_SUB_STEP:
      return {
        ...state,
        isCommuteSubStep: action.payload,
      };
    default:
      return state;
  }
};
