
export enum FeatureDescriptor {
  HurricaneEvacuationPath = 'hurricane_evacuation_center_path',
  HurricaneEvacuationPoint = 'hurricane_evacuation_center_location',
  CrimePolygon = 'crime',
  Project = 'project',
  ZoneGeometry = 'zoneGeometry',
  SchoolZone = 'schoolZone',
  TrainStation = 'trainStation',
  BusStation = 'busStation',
  BusLine = 'busLine',
  SearchArea = 'searchArea',
  ActiveStreet = 'activeStreet',
  TargetBuilding = 'targetBuilding',
  Target = 'target',
  NewCustomArea = 'newCustomArea',
  NewCustomPath = 'newCustomPath',
  NewCustomPoint = 'newCustomPoint',
  CustomArea = 'customArea',
  CustomPath = 'customPath',
  CustomPoint = 'customPoint',
  Area = 'area',
  Antenna = 'antenna',
  AsbestosComplaint = 'asbestosComplaint',
  BikeLanes = 'bikeLanes',
  BlockingLine = 'blockingLine',
  Building = 'building',
  CitiBikeStations = 'citiBikeStations',
  Complaint = 'complaint',
  Elevator = 'elevator',
  ElevatorLine = 'elevatorLine',
  ElevatorLineShort = 'elevatorLineShort',
  FarRights = 'farRights',
  GrowthBuilding = 'growthBuilding',
  IndoorAirQualityComplaint = 'indoorAirQualityComplaint',
  Intersection = 'intersection',
  Job = 'job',
  InConstructionJob = 'inConstructionJob',
  InFilingJob = 'inFilingJob',
  JobActive = 'jobActive',
  InConstructionJobActive = 'inConstructionJobActive',
  InFilingJobActive = 'inFilingJobActive',
  Line = 'line',
  MarketPath = 'marketPath',
  MarketPoint = 'marketPoint',
  Neighborhood = 'neighborhood',
  NoiseComplaint = 'noiseComplaint',
  OutdoorAirQualityComplaint = 'outdoorAirQualityComplaint',
  OutOfScaleBuilding = 'outOfScaleBuilding',
  Park = 'park',
  ParkDesertBorder = 'parkDesertBorder',
  ParkingTickets = 'parkingTickets',
  ParkingViolations = 'parkingViolations',
  ParkMarker = 'parkMarker',
  PermanentArea = 'permanentArea',
  PermanentPath = 'permanentPath',
  PermanentPoint = 'permanentPoint',
  Playground = 'playground',
  PrimaryParkRoute = 'primaryParkRoute',
  SchoolBuilding = 'schoolBuilding',
  SchoolPoint = 'schoolPoint',
  SecondaryParkRoute = 'secondaryParkRoute',
  Shelter = 'shelter',
  StreetLightComplaint = 'streetLightComplaint',
  StreetSegment = 'streetSegment',
  SubwayLine = 'subwayLine',
  SubwayPoint = 'subwayPoint',
  SubwayStation = 'subwayStation',
  TargetPoint = 'targetPoint',
  WalkingPath = 'walkingPath',
  SecondaryWalkingPath = 'secondaryWalkingPath',
  QuietStreet = 'quietStreet',
  PolygonGeometry = 'polygonGeometry',
  ObservationPoint = 'observationPoint',
  ViewBefore = 'viewBefore',
  ViewAfter = 'viewAfter',
  FutureBuilding = 'futureBuilding',
  ConstructingBuilding = 'constructingBuilding',
  NearbyStation = 'nearbyStation',
  Station = 'station',
}
