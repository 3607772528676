import React from 'react';
import { CarouselControlsArrow, ICarouselControlsArrow } from 'ds/components/ListingCard';
import ArrowIcon from 'assets/svg/chevron-right.svg';
import ArrowLargeIcon from 'assets/svg/chevron-arrow-right.svg';


interface ControlsArrowProps extends ICarouselControlsArrow, React.HTMLAttributes<HTMLDivElement> {
  size?: 'medium' | 'large';
}

const ControlsArrow: React.FC<ControlsArrowProps> = ({ type, size, ...rest }) => (
  <CarouselControlsArrow type={type} size={size} {...rest}>
    {size === 'large' ? <ArrowLargeIcon width={24} height={24} /> : <ArrowIcon width={15} height={30} />}
  </CarouselControlsArrow>
);

export default ControlsArrow;
