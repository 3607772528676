import React from 'react';
import styled from '@emotion/styled';
import { TransformedImage } from 'components/transformed-image';

const DEVELOPER_LOGO_HEIGHT = 64;

const LogoBox = styled.div<{ skipMargin: boolean }>`
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(2)};
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: ${({ theme }) => theme.spacing(0.5)};
  ${({ skipMargin }) => skipMargin ? `
    && {
      margin: 0;
    }
  ` : ''}
  img {
    display: block;
    max-width: 90px;
    margin: 0 auto;
  }
`;

export interface LogoProps {
  developerLogo: string;
  dataAutoStr?: string;
  skipMargin?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ developerLogo, dataAutoStr, skipMargin }) => (
  <LogoBox data-auto={dataAutoStr} skipMargin={skipMargin}>
    {developerLogo ? (
      <TransformedImage
        path={developerLogo}
        height={DEVELOPER_LOGO_HEIGHT}
        fit="contain"
      />
    ) : null}
  </LogoBox>
);
