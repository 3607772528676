import React from 'react';
import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import SuccessIcon from 'assets/svg/interface-24-v-circle.svg';
import ErrorIcon from 'assets/svg/interface-24-error.svg';
import WarningIcon from 'assets/svg/interface-24-attention.svg';


const Text = styled(SmallText)`
  line-height: 24px;
  display: flex;
`;
const TextSpacer = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: 0 8px;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin: 0 6px;
  `}
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ToastWrapper = styled.div<{ type: ToastType }>`
  justify-content: flex-start;
  ${({ theme, type }) => theme.media.showAt({ to: 2 })`
    padding: 12px 8px;
    ${type ? '' : 'justify-content: center;'}
    display: flex;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding: 12px 6px;
    border-radius: ${theme.borderRadius.tiny};
    box-shadow: ${theme.shadow.level5};
    min-width: 242px;
    display: inline-flex;
    max-width: 540px;
  `}
  ${({ theme, type }) => {
    let color = theme.colors.alert.blue;
    let bg = theme.colors.alert.blueBackground;
    switch (type) {
      case 'error':
        color = theme.colors.alert.red;
        bg = theme.colors.alert.redBackground;
        break;
      case 'warning':
        color = theme.colors.alert.yellow;
        bg = theme.colors.alert.yellowBackground;
        break;
      case 'success':
        color = theme.colors.alert.green;
        bg = theme.colors.alert.greenBackground;
        break;
      default:
    }

    return `
      color: ${color};
      & svg path {
        fill: ${color};
      }
      background: ${bg};
    `;
  }}
  & svg {
    width: 24px;
    height: 24px;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      margin: 0 8px;
    `}
    ${({ theme }) => theme.media.showAt({ from: 3 })`
      margin: 0 6px;
    `}
  }
`;

export type ToastType =  'warning' | 'error' | 'success';

interface FeedbackToastProps {
  type?: ToastType;
  children: string;
}

export const FeedbackToast: React.FC<FeedbackToastProps> = ({ type, children }) => {

  let icon: JSX.Element = null;

  switch (type) {
    case 'error':
      icon = <ErrorIcon />;
      break;
    case 'success':
      icon = <SuccessIcon />;
      break;
    case 'warning':
      icon = <WarningIcon />;
      break;
  }

  const content = (
    <TextSpacer>
      {children}
    </TextSpacer>
  );

  return (
    <ToastWrapper data-auto="toast-message-wrapper" type={type}>
      <Text data-auto="toast-message-text">
        {icon ? (
          <IconWrapper>
            {icon}
            {content}
          </IconWrapper>
        ) : content}
      </Text>
    </ToastWrapper>
  );
};
