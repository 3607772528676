import { select, takeEvery, getContext, call } from 'redux-saga/effects';
import { SET_HOVERED_DATA_LAYER_ID } from 'store/state/insightsContext/types';
import { setHoveredDataLayerId } from 'store/state/insightsContext/actions';
import { searchDataLayersDictionarySelector } from 'store/state/selectors/search';
import { getCurrentDesignRange } from 'consts/breakpoints';
import { RootAction } from 'store/state';
import { FETCH_MORE } from 'store/state/domainData/types';
import { LoadType } from '../apiService/types';
import { fetchMore } from 'store/state/domainData/actions';
import { get, keys, toString } from 'lodash';
import { activeMapTransportationSelector } from 'store/state/insightsContext/selectors';
import { MapTransportationState } from 'store/state/insightsContext';

let prevZoom: number;

function* mapLayersSearchWorker(action: ReturnType<typeof fetchMore>) {
  const zoom = get(action, [ 'meta', 'variables', 'zoom' ]);
  if (zoom && prevZoom && (prevZoom !== zoom)) {
    const { sendEvent } = yield getContext('analytics');
    const activeLayers: MapTransportationState = yield select(activeMapTransportationSelector);

    yield call(
      sendEvent,
      'map_zoom', 'map',
      {
        event: {
          zoom_action: prevZoom <= zoom ? 'in' : 'out',
          zoom_level: toString(zoom),
          displayed_layers: keys(activeLayers),
          is_displaying_layer_objects: zoom >= 14,
        },
      }
    );
  }
  prevZoom = zoom;
}

const mapLayersSearchPattern = (action: RootAction) => action.type === FETCH_MORE && action.loadType === LoadType.SearchMapDataLayers;

function* mapHoverDataLayerResultEvent(action: ReturnType<typeof setHoveredDataLayerId>) {
  const { sendEvent } = yield getContext('analytics');
  const data = yield select(searchDataLayersDictionarySelector);
  const screenBreakpoint = getCurrentDesignRange();
  const source = screenBreakpoint <= 2 ? 'click' : 'hover';
  const layer = data[action.payload];

  if (layer && (layer.type === 'busStation' || layer.type === 'trainStation')) {
    yield call(sendEvent, 'map_layer_card_display', 'map', {
      event: {
        layer: layer.type.replace('Station', ''),
        source,
        station_id: action.payload,
      },
    });
  }
}

export function* mapHoverDataLayerWatcher() {
  yield takeEvery(mapLayersSearchPattern, mapLayersSearchWorker);
  yield takeEvery(SET_HOVERED_DATA_LAYER_ID, mapHoverDataLayerResultEvent);
}
