import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { H3, Text } from 'ds/components/typography';
import { useLocale } from 'locale';
import { OverlayWrapper } from 'components/overlay-wrapper';

import RotateIcon from 'assets/svg/screen-rotation.svg';
import { useGlobalEventListener } from 'hooks/useGlobalEventListener';

export const MAX_HEIGHT = 500;
export const MAX_WIDTH = 959;

const OrientationBlockWrapper = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutrals.black};
  color: ${({ theme }) => theme.colors.neutrals.white};
  svg {
    margin: 8px 0;
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
`;

const checkIsPortrait = () => {
  if (window && window.orientation) {
    return window.orientation === 0;
  }
  else if (window && window.screen && window.screen.orientation) {
    return window.screen.orientation.type === 'portrait-primary' || window.screen.orientation.type === 'portrait-secondary';
  }
  return false;
};

export const OrientationBlock: React.FC = () => {
  const { t } = useLocale();
  const [ isPortrait, setIsPortrait ] = useState<boolean>(checkIsPortrait);

  useEffect(() => {
    setIsPortrait(checkIsPortrait);
  }, []);

  useGlobalEventListener(window, 'orientationchange' as any, () => {
    setIsPortrait(checkIsPortrait);
  });

  if (isPortrait) return null;

  return (
    <OverlayWrapper>
      <OrientationBlockWrapper>
        <H3 weight="bold">{t('orientationBlock.title')}</H3>
        <RotateIcon width="48" height="48" />
        <Text>{t('orientationBlock.subtitle')}</Text>
      </OrientationBlockWrapper>
    </OverlayWrapper>
  );
};
