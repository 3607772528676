import React from 'react';
import { ListingCardWrapper } from 'ds/components/ListingCard';
import WrapperFreeUniversalCard, { DefaultUniversalCardProps } from './WrapperFreeUniversalCard';

export type UniversalCardProps = DefaultUniversalCardProps & { isActive?: boolean; isMinHeight?: boolean; hasDiscount?: boolean; };

const UniversalCard: React.FC<UniversalCardProps> = ({ isActive, isMinHeight, showAddToFavourites = true, ...props }) => (
  <ListingCardWrapper isActive={isActive} isMinHeight={isMinHeight}>
    <WrapperFreeUniversalCard
      showPriceDropLabel
      {...props}
      showAddToFavourites={showAddToFavourites}
    />
  </ListingCardWrapper>
);

export default React.memo(UniversalCard);
