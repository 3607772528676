

export interface IEnhancer {
  enhance: (url: string, init: RequestInit, innerFetch: Fetcher) => Promise<Response>;
}

export type Fetcher = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

function simplyEnhanceFetch(fetcher: Fetcher, enhancer: IEnhancer): Fetcher {
  return async (input, init) => {

    let url: string;
    let requestInit: RequestInit;

    if (typeof input === 'string') {
      url = input;
      requestInit = init;
    }
    else {
      url = input.url;
      const body = await (input.headers.get('Content-Type') ? input.blob() : Promise.resolve(undefined));

      requestInit = {
        body,
        cache: input.cache,
        credentials: input.credentials,
        headers: input.headers,
        integrity: input.integrity,
        keepalive: input.keepalive,
        method: input.method,
        mode: input.mode,
        redirect: input.redirect,
        referrer: input.referrer,
        referrerPolicy: input.referrerPolicy,
        signal: input.signal,
      };
    }

    return enhancer.enhance(url, requestInit, fetcher);
  };
}

export function enhanceFetch(fetcher: Fetcher, ...enhancers: IEnhancer[]) {
  let fetcherIter = fetcher;
  for (const enhancer of enhancers) {
    fetcherIter = simplyEnhanceFetch(fetcherIter, enhancer);
  }
  return fetcherIter;
}
