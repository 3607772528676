import { IPoiIdentifier } from 'utils/entities';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { savedDatedPoisSelector } from '../state/domainData/selectors';
import { LoadType, MutationType } from './apiService/types';
import { poiMapper } from './routing/shortlistPage';
import { fetchMore } from '../state/domainData/actions';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { RootAction } from 'store/state';
import { TOGGLE_FAVORITES } from 'store/state/app/types';
import { toggleFavorites } from 'store/state/app/actions';
import { favoritesAndShortlistDictSelector, baseFavoritesPoisSelector } from 'store/state/domainData/selectors/favorites';
import { queryData } from 'store/sagas/apiService';

export function* maybeFetchFavorites(datedPois: IPoiIdentifier[]) {
  const poisMap = yield select(favoritesAndShortlistDictSelector);
  const ids = datedPois.filter(p => !poisMap[p.id]);

  const data = yield select(baseFavoritesPoisSelector);

  if (!data) {
    yield call(queryData, {
      loadType: LoadType.FavoritesPois,
      meta: { variables: { ids } },
    });
    return;
  }

  if (!datedPois || !datedPois.length) return;

  if (ids.length) {
    yield put(fetchMore({
      loadType: LoadType.FavoritesPois,
      meta: {
        variables: { ids },
      },
    }));
  }
}

export const userLoginPattern = (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.CurrentUser)
  || (action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.GoogleLogin)
  || (action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.FacebookLogin)
  || (action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.LoginWithEmailAndPassword)
  || (action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.SignInSignUpWithEmail)
);

function* waitForUserFavoritesToChange() {
  const prevFavorites = yield select(savedDatedPoisSelector);
  let nextFavorites = prevFavorites;

  while (prevFavorites === nextFavorites) {
    yield take(userLoginPattern);
    nextFavorites = yield select(savedDatedPoisSelector);
  }

  return nextFavorites;
}

export function* fetchFavoritesHandler () {
  const favorites = yield select(savedDatedPoisSelector);

  yield maybeFetchFavorites(favorites.map(poiMapper));

  while (true) {
    yield call(waitForUserFavoritesToChange);

    const datedFavorites = yield select(savedDatedPoisSelector);
    yield maybeFetchFavorites(datedFavorites.map(poiMapper));
  }
}

const addToFavoriesPoiPattern = (action: RootAction) => action.type === TOGGLE_FAVORITES && !action.payload.isFavorite;

function* fetchAddedToFavoriesPoi(action: ReturnType<typeof toggleFavorites>) {
  const { payload: { favorites } } = action;
  yield maybeFetchFavorites(favorites);
}

export function* fetchFavoritesWatcher () {
  yield fork(fetchFavoritesHandler);
  yield takeEvery(addToFavoriesPoiPattern, fetchAddedToFavoriesPoi);
}
