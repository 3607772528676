import styled from '@emotion/styled';


export const RangeFieldWrapper = styled.div<{ fullWidth: boolean }>`
  ${({ fullWidth }) => `
    display: flex;
    align-items: baseline;
    width: 100%;
    ${fullWidth ? `
      > div:last-of-type,
      > div:first-of-type {
        width: 100%;
        button {
          width: 100%;
        }
      }
    ` : ''}
  `}
`;

export const RangeFieldDivider = styled.div`
  ${({ theme }) => `
    display: flex;
    margin: 0 ${theme.spacing(1)};
  `}
`;
