import { Article } from 'store/sagas/apiService/types';

export interface BlogCarouselProps {
  articles: Article[];
  loading: boolean;
  onCarouselScroll?: (direction: 'left' | 'right', isMobile: boolean) => void;
}

export function getDirection(current: number, next: number) {
  const isLeft = current < next;
  return isLeft ? 'left' : 'right';
}

export function isEmpty<T extends unknown>(arr: T[], isLoading: boolean) {
  return !(arr && arr.length) || isLoading;
}
