import { call, put, getContext, all, select } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { FetchInsightsParams } from '../loadInsights';
import { loadInsights } from '../loadInsights';
import { navigateTo } from 'store/state/router/actions';
import { cityDocIdSelector } from 'store/state/domainData/selectors';
import { fetchArticles } from '../utils';


export function* streetHandler(params: RouteState) {
  const docId = params.params.id;

  try {
    const insightsVariables: FetchInsightsParams = {
      docId,
      user: null,
    };

    yield call(queryData, {
      loadType: LoadType.StrictLocalDoc,
      meta: {
        variables: { docId },
      },
    });

    const cityDocId: string = yield select(cityDocIdSelector);

    yield all([
      cityDocId && call(queryData, {
        loadType: LoadType.LocalData,
        meta: {
          variables: { docId: cityDocId },
          errorPolicy: 'none',
        },
      }),
      call(queryData, {
        loadType: LoadType.StreetSearch,
        meta: {
          variables: {
            dealType: null,
            docId,
            poiTypes: [ 'bulletin', 'project' ],
          },
        },
      }),
      call(loadInsights, insightsVariables),
      call(fetchArticles, [ cityDocId ], 'street'),
    ].filter(Boolean));

  }
  catch (e) {
    const logger = yield getContext('logger');
    logger.error('One of queries failed in street page handler, redirecting Home.', e);
    yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }
}
