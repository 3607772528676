import { isFinite } from 'lodash';
import config from 'config';
import React, { useContext } from 'react';

interface CropFacesInterfaces {
  hPadding: number;
  vPadding: number;
}

export interface ImageOptions {
  height?: number;
  width?: number;
  padding?: number;
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
  rotateDegrees?: number;
  withoutEnlargement?: boolean;
  cropFaces?: CropFacesInterfaces;
  bubble?: 'active' | 'default';
  cacheVersion?: number;
}

const CACHE_VERSION = 2;

const createImageUrlBuilder = (userAgent: string) => (path: string, options: ImageOptions = {}) => {

  if (!path || path.startsWith('http') || path.startsWith('//')) {
    return path;
  }

  const {
    height,
    width,
    fit,
    padding,
    rotateDegrees,
    withoutEnlargement,
    cropFaces,
    bubble,
    cacheVersion = CACHE_VERSION,
  } = options;

  const transforms: string[] = [ `nonce:v=${cacheVersion}` ];

  if (bubble) {
    transforms.push(`bubble:type=${bubble}`);
  }

  if (height || width) {
    const params = [];
    if (height) {
      params.push(`height=${height}`);
    }

    if (width) {
      params.push(`width=${width}`);
    }

    if (padding) {
      params.push(`padding=${padding}`);
    }

    if (fit) {
      params.push(`fit=${fit}`);
    }

    if (withoutEnlargement) {
      params.push('withoutEnlargement=true');
    }

    transforms.push(`resize:${params.join(',')}`);
  }

  if (isFinite(rotateDegrees) && rotateDegrees !== 0) {
    transforms.push(`rotate:degrees=${rotateDegrees}`);
  }

  // fix for storybook
  userAgent = userAgent || '';
  const isChrome = userAgent.toLowerCase().indexOf('chrome') > -1;

  if (cropFaces) {
    const params = [];
    const { hPadding, vPadding } = cropFaces;

    params.push(`height=-1`);

    params.push(`width=-1`);

    if (hPadding) {
      params.push(`hPadding=${hPadding}`);
    }

    if (vPadding) {
      params.push(`vPadding=${vPadding}`);
    }

    params.push('detectFaces=true');
    params.push('version=6');

    transforms.unshift(`crop:${params.join(',')}`);
  }

  if (isChrome) {
    transforms.push('convert:type=webp');
  }

  if (path.startsWith('/')) {
    path = path.substr(1);
  }

  if (path.includes(' ')) {
    path = encodeURIComponent(path);
  }

  return `${config.imagesRoot}t:${transforms.join(';')}/${path}`;
};

export const UserAgentContext = React.createContext<string>(null);

export const useImageUrlBuilder = () => {
  const userAgent = useContext(UserAgentContext);
  return createImageUrlBuilder(userAgent);
};
