import styled from '@emotion/styled';
import { Theme } from 'ds';
import { colorToAlpha } from 'helpers/insight';
import { PriceRange } from '../types';
import { animated } from 'react-spring';

const getEstimatedColor = (priceRange: PriceRange, theme: Theme) => priceRange === PriceRange.Top ? theme.colors.alert.red : priceRange === PriceRange.Range ? theme.colors.insights.prices : theme.colors.alert.greenLight;

export const EstimatedPriceLabel = styled.div<{isActive: boolean, priceRange: PriceRange}>`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  display: flex;
  cursor: pointer;
  overflow: hidden;

  ${({ isActive }) => isActive ?
    `align-items: center; padding: 0 4px; height: 24px; border: 1px solid;`
    : `padding: 0; line-height: 1.6; justify-content: flex-end; border: none;`
  }

  border-color:${({ theme, isActive, priceRange }) => isActive ?
    colorToAlpha(getEstimatedColor(priceRange, theme), 0.06)
    : `transparent`};
  background: ${({ theme, isActive, priceRange }) => isActive ?
    colorToAlpha(getEstimatedColor(priceRange, theme), 0.06)
    : `transparent`
  };
  &:hover {
    border-color:${({ theme, isActive, priceRange }) => isActive ?
      colorToAlpha(getEstimatedColor(priceRange, theme), 0.2)
      : `transparent`
    };
    background: ${({ theme, isActive, priceRange }) => isActive ?
      colorToAlpha(getEstimatedColor(priceRange, theme), 0.2)
      : `transparent`
    };
  }
  > div {
    margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: 4px;
  }
}
`;

export const Label = styled.div<{isActive?: boolean, priceRange?: PriceRange}>`
  color: ${({ theme, isActive, priceRange }) => isActive ? getEstimatedColor(priceRange, theme) : theme.colors.neutrals.grey3};
  font-size: 15px;
`;

const EstimationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AnimatedEstimationWrapper = animated(EstimationWrapper);


