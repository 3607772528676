import { connect } from 'react-redux';
import { State } from 'store/state';
import { FeedbackPopupType, reportListingOptionsValues } from 'components/common-feedback-popup-view/utils';
import { isReportListingPopupModalOpenSelector } from 'store/state/app/selectors';
import { setReportListingPopupOpen, setToastMessage } from 'store/state/app/actions';
import FeedbackPopupView, { FeedbackPopupOwnProps } from 'components/common-feedback-popup-view';
import withEvents from 'analytics/withEvents';

const mapStateToProps = (state: State) => ({
  isPopupModalOpen: isReportListingPopupModalOpenSelector(state),
  options: reportListingOptionsValues,
  feedbackType: FeedbackPopupType.ReportListing,
});

const mapDispatchToProps = {
  onClosePopup: () => setReportListingPopupOpen(false),
  onSubmit: () => setToastMessage({ term: 'reportListing.popup.toasterMessageConfirmation' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withEvents<FeedbackPopupOwnProps>(sendEvent => ({
  onSubmit() {
    sendEvent('property_mistake_submit', 'property');
  },
}))(FeedbackPopupView));
