import React, { useCallback, useState } from 'react';
import { useLocale } from 'locale';
import SearchIcon from 'assets/svg/search-icon.svg';
import { NavigationSearchInputWrapper } from 'components/navigation/subheader/styled';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import { CompletionListGroup, config as completionTypesConfig } from 'components/commute-popup/CompletionTypeConfig';
import { IAutocompleteEntry } from 'utils/entities';
import { AppMode } from 'store/state/selectors/router';
import { IAutocompleteTrackingEventMeta, getResidentialNavigationParams } from 'components/search-widget/helpers';
import { Route, State as RouteState, NavigateToFn } from 'config/routes';
import { AddressAutocompleteSource } from 'components/autocomplete/types';

export interface NavigationSearchProps {
  onSearch?: (meta?: IAutocompleteTrackingEventMeta) => void;
  navigate: NavigateToFn;
  route: RouteState;
}

const MAX_RESULT_SHOWN_COUNT = 6;

export const NavigationSearch: React.FC<NavigationSearchProps> = (props) => {
  const { onSearch, route, navigate } = props;
  const [ searchStr, setSearchStr ] = useState('');
  const { t } = useLocale();

  const handleSearch = useCallback((value: IAutocompleteEntry) => {
    if (!value) {
      return;
    }
    if (onSearch) {
      onSearch({
        selectedItems: [ value ],
        searchMode: AppMode.Check,
      });
    }
    if (value.docId) {
      const {
        route: navRoute,
        params,
        options,
      } = getResidentialNavigationParams(value, AppMode.Check, { tracking_search_source: 'check_address' });
      navigate(navRoute, params, { replace: true, ...options });
    }
  }, [ route, navigate ]);

  const handleBlur = () => {
    setSearchStr('');
  };
  return (
    <NavigationSearchInputWrapper>
      <AddressAutocompleteInput
        searchStr={searchStr}
        onSearchStrChange={setSearchStr}
        placeholder={t('navigation.subheader.navigationSearchInputPlaceholder')}
        onChange={handleSearch}
        onBlur={handleBlur}
        inputIcon={<SearchIcon width={16} height={16} />}
        optionsCount={MAX_RESULT_SHOWN_COUNT}
        showType
        showClearButton
        completionTypes={completionTypesConfig[route.name === Route.AddressPage
          ? CompletionListGroup.CheckAddressTypes
          : CompletionListGroup.Default]
        }
        source={AddressAutocompleteSource.SearchResults}
      />
    </NavigationSearchInputWrapper>
  );
};
