import styled from '@emotion/styled';
import { Text, H3 } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const AfterUploadModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
`;

export const HeaderTitle = styled(H3)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const Description = styled(Text)`
  text-align: center;
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(4)};
  `}
`;

export const SocialLink = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => `
    box-shadow: ${theme.shadow.level2};
    background-color: ${theme.colors.neutrals.white};
    border: solid 1px ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.round};
    svg {
      path {
        fill: ${theme.colors.brand.main};
      }
    }
  `}
`;

export const SocialLinkWrapper = styled.span`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(3)};
  &:first-of-type {
    margin-right: 0;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.round};
    background: ${colorToAlpha(theme.colors.brand.main, 0.07)};
    margin-bottom: ${theme.spacing(3)};
  `}
`;
