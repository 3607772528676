const EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const isEmailValid = (email: string): boolean => EMAIL_REGEXP.test(email);

export const isNotEmpty = <T extends any>(value: T) => {
  if (Array.isArray(value)) {
    return !!value.length;
  }

  return !!value;
};

export const isNumber = <T extends unknown>(value: T) => !isNaN(Number(value));

export const isPositiveNumber = <T extends unknown>(value: T) => isNumber(value) && value as number > 0;

// maybe we should use this instead of the above /^0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
const IL_PHONE_NUMBER_REGEXP = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/; // Telephone numbers in Israel from https://en.wikipedia.org/wiki/Telephone_numbers_in_Israel
const NAME_REGEXP = /^[^\d]+$/;
export const FULL_NAME_REGEXP = /[^a-z\u0590-\u05fe\s]/gi;
export const PHONE_TEXT_REGEXP = /(?:\+?972|0|O)[^A-Z0-9\n\r\u0590-\u05fe]*[1-9][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO][^A-Z0-9\n\r\u0590-\u05fe]*[\dO]?[^A-Z0-9\n\r\u0590-\u05fe]*[\dO]?[^A-Z0-9\n\r\u0590-\u05fe]*/gmi;


export const isILMobilePhoneNumberValid = (phone: string): boolean => /^05\d{8}$/.test(phone);
export const isILPhoneNumberValid = (phone: string): boolean => /^(?:972|0)[1-9]\d{7,9}$/.test(phone);
export const isPhoneNumberValid = (phone: string): boolean => IL_PHONE_NUMBER_REGEXP.test(phone);
export const isNameValid = (name: string): boolean => NAME_REGEXP.test(name);
