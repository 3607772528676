import React from 'react';
import { connect } from 'react-redux';
import { PoiGetter, makeMapStateToPropsFactory } from 'components/cards/listing/makeMapStateToPropsFactory';
import PropertyImage from 'components/cards/new-layout/components/PropertyImage';
import PropertyInfo from './property-info';
import { HoverCardRoot, HoverCardInner } from './styled';
import { Link } from 'components/link';
import { routeByPoiTypeAndMarketplace } from 'utils/marketplaceRoutes';
import { DecodedRouteParams } from 'config/routes';
import { PoiId, MarketplaceType } from 'utils/entities';
import { favoritesAndShortlistDictSelector } from 'store/state/domainData/selectors/favorites';
import { UniversalCardPoiUnion, CardType } from 'components/cards/types';

const shortlistPoiByIdSelector: PoiGetter = (state, id) => favoritesAndShortlistDictSelector(state)[id];

const makeMapStateToProps = makeMapStateToPropsFactory(shortlistPoiByIdSelector);


const HoverCard: React.FC<UniversalCardPoiUnion & {id: PoiId}> = (props) => {
  const commercialCardTypes = new Set([ CardType.CommercialBulletin, CardType.CommercialProject ]);
  const marketplace = commercialCardTypes.has(props.cardType) ? MarketplaceType.Commercial : MarketplaceType.Residential;
  const linkRouteName = routeByPoiTypeAndMarketplace(props.type, marketplace);
  const linkRouteParams: DecodedRouteParams = {
    id: props.id,
    tracking_event_source: 'favorites_hover',
  };
  return (
    <HoverCardRoot>
      <Link
        data-auto="listed-bulletin-clickable"
        routeName={linkRouteName}
        routeParams={linkRouteParams}
        hideLinkParams
      >
        <HoverCardInner>
          <PropertyImage thumbnails={props.thumbnails} />
          <div data-auto="property-details">
            <PropertyInfo {...props} />
          </div>
        </HoverCardInner>
      </Link>
    </HoverCardRoot>
  );
};

export default connect(makeMapStateToProps)(HoverCard);
