import {
  PoiId,
  IDataLayerItem,
  IDealDataLayer,
  IBulletin,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
} from 'utils/entities';

import { searchMapListingsSelector, makeNullSafeDomainGetter, makeByIdsDictionarySelector, searchMapDataLayersSelector as baseSearchMapDataLayersSelector, searchMapClustersSelector, createIsLoadingSelector } from './common';
import { createSelector } from 'reselect';
import { flow, head } from 'lodash';
import { SearchMapBulletinFields, SearchMapProjectFields } from 'store/sagas/apiService/types';
import { isBusRouteByIdsVariableSelector } from './busLine';


export type MapListingsPoi = Pick<IBulletin, SearchMapBulletinFields> | Pick<ICommercialBulletin, SearchMapBulletinFields> | Pick<IProject, SearchMapProjectFields> | Pick<ICommercialProject, SearchMapProjectFields>;

const EMPTY_LISTINGS: MapListingsPoi[] = [];


export const mapPoiListSelector = createSelector(
  makeNullSafeDomainGetter(searchMapListingsSelector, 'searchPoiV2', true),
  (search) => (search && search.poi ? search.poi : EMPTY_LISTINGS)
);

export const searchMapDataLayersLoadingSelector = createIsLoadingSelector(baseSearchMapDataLayersSelector);
export const searchMapClustersLoadingSelector = createIsLoadingSelector(searchMapClustersSelector);
export const searchMapClustersPendingCountSelector = flow(searchMapClustersSelector, ({ pendingCount }) => pendingCount);

export const searchMapClustersDataSelector = createSelector(searchMapClustersSelector, ({ data }) => {
  const { searchDataLayers, ...zoomDiztionary } = data || {};
  return zoomDiztionary;
});

export const searchMapDataLayersDataSelector = createSelector([
  flow(baseSearchMapDataLayersSelector, (d) => d.data),
  flow(isBusRouteByIdsVariableSelector, v => v && v.busLineIds ? head(v.busLineIds) : null),
], (data, lineId): Record<number, Record<PoiId, IDataLayerItem>> => {

  if (!data) return data;

  const result: Record<number, Record<PoiId, IDataLayerItem>> = {};

  // tslint:disable-next-line: forin
  for (const zoom in data) {
    const dataForZoom = data[zoom];
    result[zoom] = {};

    // tslint:disable-next-line: forin
    for (const id in dataForZoom) {
      const item = dataForZoom[id];

      if (item.type === 'deal') {
        const groupHash = ('deals-' + item.addressDetails.docId) || item.id;

        if (result[zoom][groupHash] === undefined) {
          result[zoom][groupHash] = { ...item, id: groupHash, groupLength: 0, highestPrice: item.price };
        }

        const group = result[zoom][groupHash] as IDealDataLayer;

        if (item.saleDate >= group.saleDate) {
          Object.assign(group, item, { id: groupHash, latestId: item.id });
        }
        if (item.price > group.highestPrice) {
          group.highestPrice = item.price;
        }
        group.groupLength++;
      }
      else if (lineId && item.type === 'busStation') {
        item.busLines.forEach(line => {
          if (line.id === lineId) {
            result[zoom][item.id] = item;
          }
        });
      }
      else {
        result[zoom][item.id] = item;
      }
    }
  }

  return result;

});



export const mapPoiByIdsSelector = makeByIdsDictionarySelector(mapPoiListSelector);
