import React from 'react';
import { useLocale } from 'locale';
import { SmallText } from 'ds/components/typography';
import { Wrapper } from './styled';
import { LabelDynamicContent } from '../styled';

interface PlaningDynamicContentProps {
  count: number;
  year: string;
  label?: string;
}

export const PlaningDynamicContent: React.FC<PlaningDynamicContentProps> = props => {
  const { label, count, year } = props;
  const { t } = useLocale();

  if (!label && !count) return null;

  return label
    ? (
        <Wrapper>
          <LabelDynamicContent weight="bold">{label}</LabelDynamicContent>
        </Wrapper>
      )
    : count ? (
        <Wrapper>
          <SmallText>{t('newConstructionCollapsed.count', { count })}</SmallText>
          {year ? <SmallText>{t('newConstructionCollapsed.estEnd')} {year}</SmallText> : null}
        </Wrapper>
      ) : null;
};

