import React, { useRef, useEffect } from 'react';


// "io" in this file stands for Intersection Observer

type SingleEntryIOCallback = (e: IntersectionObserverEntry) => void;

class MulticastIntersectionObserver {

  private observer: IntersectionObserver;
  private callbacks = new Map<Element, SingleEntryIOCallback>();

  constructor(private options?: IntersectionObserverInit) {}

  public subscribe(node: Element, callback: SingleEntryIOCallback) {
    if (!this.observer) {
      this.observer = new IntersectionObserver((entries) => {
        for (const e of entries) {
          const cb = this.callbacks.get(e.target);
          cb(e);
        }
      }, this.options);
    }
    this.callbacks.set(node, callback);
    this.observer.observe(node);


    return () => {
      this.callbacks.delete(node);
      this.observer.unobserve(node);
    };
  }
}

function useSingletonIntersectionObserverFactory(instance: MulticastIntersectionObserver) {
  return (callback: SingleEntryIOCallback, target: React.MutableRefObject<HTMLElement>) => {
    const callbackRef = useRef<SingleEntryIOCallback>(callback);
    callbackRef.current = callback;

    useEffect(() => {
      const unsubscribe = instance.subscribe(target.current, (entry) => callbackRef.current(entry));
      return unsubscribe;
    }, []);
  };
}

const ioInstance = new MulticastIntersectionObserver();
export const useSingletonIntersectionObserver = useSingletonIntersectionObserverFactory(ioInstance);

const ioSearchEventInstance = new MulticastIntersectionObserver({ threshold: [ 1 ], rootMargin: '-121px 0px -65px 0px' });
export const useSingletonSearchEventIntersectionObserver = useSingletonIntersectionObserverFactory(ioSearchEventInstance);

const ioFullEntryInstance = new MulticastIntersectionObserver({ threshold: [ 1 ], rootMargin: '0px 0px -65px 0px' });
export const useSingletonIntersectionFullEntryObserver = useSingletonIntersectionObserverFactory(ioFullEntryInstance);

export const useSingletonRecommendedListingsCardIntersectionObserver = useSingletonIntersectionObserverFactory(
  new MulticastIntersectionObserver({ threshold: [ 1 ] })
);
