import React from 'react';
import { ITranslate, useLocale } from 'locale';
import { useField } from 'react-final-form-hooks';
import { Section, SectionHeading, AdditionalBedsWrapper, RadioGroupWrapper } from '../../../styled';
import { UploadBulletinStepProps } from '../../';
import { isNotEmpty } from 'utils/formValidationHelpers';
import RadioGroup from 'ds/components/input/RadioGroup';
import { generateYearOptions, generateMonthOptions } from 'helpers/dateUtils';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { SpacingBlock } from 'components/spacing-block';
import { AvailabilityType } from 'utils/entities';
import Select from 'ds/components/input/Select';
import { genAvailabilityTypeOptions } from './helpers';

const RadioGroupWithValidationMessage = withValidationMessage(RadioGroup);

const validateField = (t: ITranslate) => (val: string) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');


export const AvailabilitySection: React.FC<UploadBulletinStepProps> = ({ formInstance }) => {
  const { t } = useLocale();

  const availabilityType = useField('availabilityType', formInstance, validateField(t));
  const availabilityDateMonth = useField('availabilityDateMonth', formInstance);
  const availabilityDateYear = useField('availabilityDateYear', formInstance);

  return (
    <Section>
      <SpacingBlock mBottom={2}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.mainInfoStep.availabilityHeading')}
        </SectionHeading>
      </SpacingBlock>
      <RadioGroupWrapper error={availabilityType.meta.error && availabilityType.meta.touched}>
        <RadioGroupWithValidationMessage
          {...availabilityType.input}
          {...availabilityType.meta}
          options={genAvailabilityTypeOptions(t)}
        />
      </RadioGroupWrapper>

      {availabilityType.input.value === AvailabilityType.Date ?  (
        <AdditionalBedsWrapper>
          <Select
            size="large"
            items={generateMonthOptions(t)}
            {...availabilityDateMonth.input}
            {...availabilityDateMonth.meta}
          />
          <Select
            size="large"
            items={generateYearOptions(6)}
            {...availabilityDateYear.input}
            {...availabilityDateYear.meta}
          />
        </AdditionalBedsWrapper>
      ) : null}
    </Section>
  );
};
