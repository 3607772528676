import styled from '@emotion/styled';
import { DESKTOP_HEADER_TOTAL_HEIGHT } from 'components/navigation/header.constants';
import { H3, SmallText, Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';


export const LinkWrapper = styled.div`
  a {
    text-decoration: none;
    white-space: nowrap;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-right: ${theme.spacing(2)};
      width: 100%;
    `}
  }
`;

export const Label = styled(SmallText)`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    display: flex;
    height: 32px;
    padding: ${theme.spacing(1)} ${theme.spacing(1.5)};
    margin-right: ${theme.spacing(2)};
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    border-radius: ${theme.borderRadius.small};
    border: 1px solid ${theme.colors.neutrals.white};
    background: rgba(1, 132, 137, 0.05);
    color: ${theme.colors.brand.main};
    cursor: pointer;

    &:hover {
      border: 1px solid ${theme.colors.brand.main};
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    color: ${theme.colors.neutrals.grey1};
    font-size: ${typographyFontSizeCfg.smallText}px;
  `}
`;

export const MultiLocationList = styled.div`
  position: fixed !important;
  background: ${({ theme }) => theme.colors.neutrals.white};

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    right: 450px;
    top: ${DESKTOP_HEADER_TOTAL_HEIGHT - 5}px;
    padding: ${theme.spacing(0.5)} 0;
    background: ${theme.colors.neutrals.white};
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.10));
    border-radius: ${theme.borderRadius.small};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(3)};
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
    z-index: 10001;
  `}
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey7};
  font-size: ${typographyFontSizeCfg.text}px;
  line-height: ${typographyLineHeightCfg.smallText}px;
  height: 68px;

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    width: 353px;
    margin: ${theme.spacing(1)} ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: ${theme.spacing(1)} ${theme.spacing(1.5)};
  `}
`;

export const LocationName = styled(Text)`
  line-height: ${typographyLineHeightCfg.smallText}px;
`;

export const ListItemLinkWrapper = styled.div`
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.brand.main};

    svg {
      margin-right: ${({ theme }) => theme.spacing(0.5)};
      path {
        fill: ${({ theme }) => theme.colors.brand.main};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.brand.darker};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.brand.darker};
        }
      }
    }
  }
`;

export const MobileListHeader = styled(H3)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const MobileOverlay = styled.div `
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(48, 48, 48, 0.5);
  z-index: 10000;
`;
