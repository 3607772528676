import styled from '@emotion/styled';

export const FloorSelectWrapper = styled.div`
  display: flex;
  align-items: baseline;
  & > *:first-of-type {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ShekelWrapper = styled.div`
  height: 100%;
  display: flex;
  margin-top: 1px;
  justify-content: center;
  align-items: center;
`;

export const MainPriceWrapper = styled.div`
  div[data-auto="textfield-wrapper"] {
    width: 320px;
  }
`;

export const TaxPricesWrapper = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing(4)};
    margin-top: ${theme.spacing(5)};
  `}
`;

export const TaxPricesFieldsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;

    div[data-auto="textfield-wrapper"] {
      width: 152px;
      margin-left: ${theme.spacing(2)};
    }
  `}
`;

export const RoomsSubtitle = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};
    font-weight: normal;
  `}
`;

export const SectionInlineInner = styled.div`
  display: flex;
`;
