import { H3 } from 'ds/components/typography';
import styled from '@emotion/styled';

export const ClickSection = styled.div``;

export interface OpenSectionProps {
  alignment?: string;
}

export const OpenSection = styled.div<OpenSectionProps>`
  position: absolute;
  top: 52px;
  z-index: 1;
  ${({ theme, alignment }) => alignment === 'last' ? theme.isRTL ? 'right: auto; left: 0;' : 'right: 0; left: auto;' : ''};
`;

export const FilterPopupContainer = styled.div`
  ${props => props.theme.media.showAt({ from: 2 }) `min-width: 320px;`}

  ${({ theme }) => `
    border-radius: ${theme.borderRadius.tiny};
    box-shadow: ${theme.shadow.level7};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

export const FilterPopupTitle = styled(H3.withComponent('div'))`
  font-family: NovemberHebrew;
  text-align: center;
  color: ${props => props.theme.colors.neutrals.grey1};
  padding: 0 0 16px;
`;

export const FilterInnerContainer = styled.div<{ withOutPadding: boolean }>`
  ${({ theme, withOutPadding }) => `
    padding: ${withOutPadding ? '0' : theme.spacing(3)};
    box-shadow: ${theme.shadow.level0};
    max-height: 508px;
    overflow: auto;
    @media all and (max-height: 690px) {
      max-height: 250px;
    }
  `}
`;

export const FilterLine = styled.div`
  padding-top : ${({ theme }) => theme.spacing(1.5)};
  &:first-of-type {
    padding: 0;
  }
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    padding-top: ${theme.spacing(2.5)};
  `}
`;

export const FilterButtonsContainer = styled.div<{ hasScrollShadow?: boolean }>`
  ${({ theme, hasScrollShadow }) => `
    padding: ${theme.spacing(1.25)} ${theme.spacing(1.5)};
    display: flex;
    justify-content: space-between;
    ${hasScrollShadow ? `box-shadow: ${theme.shadow.level2Top};` : ''}
  `}
`;
