import * as Cookies from 'js-cookie';

// this function is here to support legacy id fetching for Israel
function getMadlanIds() {
  let cookie = Cookies.get('_ud');
  if (!cookie) {
    return {};
  }

  // remove signature
  cookie = cookie.substring(cookie.indexOf('-') + 1);
  const parts = cookie.split('&');

  const res: { cookieId?: string; userId?: string } = {};
  for (const i in parts) {
    if (!parts.hasOwnProperty(i)) continue;
    const kv = parts[i].split('=');
    if (kv[0] === 'cid') {
      res.cookieId = kv[1];
    }

    if (kv[0] === 'uid') {
      res.userId = kv[1];
    }
  }

  return res;
}

export function getIsraelLegacySecondaryUserId() {
  return getMadlanIds().cookieId || getMadlanIds().userId;
}
