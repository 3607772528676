import React from 'react';
import styled from '@emotion/styled';


const SIZE = 120;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: ${SIZE / -2}px;
  margin-left: ${SIZE / -2}px;
  display: inline-block;
  width: ${SIZE}px;
  height: ${SIZE}px;
  & div {
    position: absolute;
    border: 10px solid ${({ theme }) => theme.colors.brand.madlanGreen};
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.borderRadius.round};
    animation: lds-ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & div:nth-of-type(2) {
    animation-delay: -0.8s;
  }
  @keyframes lds-ripple {
    0% {
      transform: scale3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: scale3d(1, 1, 1);
      opacity: 0;
    }
  }
`;

export const Loader: React.FC = () => (
  <Wrapper>
    <div />
    <div />
  </Wrapper>
);
