import React from 'react';
import {
  SubheaderWrapper,
  SearchSubheaderWrapper,
  SearchInputSeparator,
  FiltersWrapper,
  ButtonsWrapper,
  WrapperTooltipFilter,
  SubheaderSeparator,
} from './styled';
import { ShowAt } from 'consts/breakpoints';
import { useLocale } from 'locale';
import FiltersView from 'components/filters';
import SaveSearchButton from 'components/saved-search/save-button/';
import withEvents from 'analytics/withEvents';
import { dealTypeSelector } from 'store/state/selectors/search';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { MultiLocationSearch } from './MultiLocationSearch';
import { CompletionType, DealType, IAutocompleteEntry, MarketplaceType } from 'utils/entities';
import { searchHistorySelector } from 'store/state/domainData/selectors';
import { ISaveSearch } from 'store/sagas/apiService/types';
import { buildSearchPayload } from 'components/search-widget/helpers';
import { DEAL_TYPE_TO_APP_MODE, marketplaceSelector } from 'store/state/selectors/router';
import SubheaderLocalData, { shouldDisplayLocalDataSelector } from 'components/local-data/subheader-local-data';

interface SearchSubheaderProps {
  onSearch: (value: string[], projectType?: CompletionType, selectedItems?: IAutocompleteEntry[]) => void;
  searchHistory: ISaveSearch[];
  marketplace: MarketplaceType;
  dealType: DealType;
  shouldDisplayLocalData: boolean;
  setMobileMapViewActive: (isActive: boolean) => void;
}

const SearchSubheader: React.FC<SearchSubheaderProps> = (props) => {
  const { onSearch, searchHistory, marketplace, shouldDisplayLocalData, setMobileMapViewActive } = props;
  const { t } = useLocale();

  const isCommercial = marketplace === MarketplaceType.Commercial;
  const searchInput = (
    <MultiLocationSearch
      marketplace={marketplace}
      onSearch={onSearch}
      searchHistory={searchHistory}
      setMobileMapViewActive={setMobileMapViewActive}
    />
  );

  return (
    <SubheaderWrapper>
      <SearchSubheaderWrapper>
        <ShowAt from={3}>
          {searchInput}
          <SubheaderLocalData />
          <SearchInputSeparator />
          <FiltersWrapper>
            <FiltersView screen="desktop" />
          </FiltersWrapper>
          {isCommercial ? null : (
            <>
              <SearchInputSeparator />
              <SaveSearchButton label={t('savedSearch.saveButton.label')} labelTextMode="searchIL" noMargin />
            </>
          )}
        </ShowAt>
        <ShowAt to={2}>
          {searchInput}
          <WrapperTooltipFilter>
            <ButtonsWrapper
              singleBtn
              shouldDisplayLocalData={shouldDisplayLocalData}
            >
              <FiltersView screen="mobile" />
              {shouldDisplayLocalData && <SubheaderSeparator />}
              <SubheaderLocalData />
            </ButtonsWrapper>
          </WrapperTooltipFilter>
        </ShowAt>
      </SearchSubheaderWrapper>
    </SubheaderWrapper>
  );
};

const SearchSubheaderWithEvents = withEvents<SearchSubheaderProps>((sendEvent, props) => ({
  onSearch(_: string[], projectType?: CompletionType, selectedItems?: IAutocompleteEntry[]) {
    if (!projectType) {
      sendEvent('search_submit', 'search', {
        event: {
          source: 'search_results',
        },
        search: buildSearchPayload({
          selectedItems,
          searchMode: DEAL_TYPE_TO_APP_MODE[props.dealType],
        }),
      });
    }
  },
}))(SearchSubheader);

const mapStateToProps = (state: State) => ({
  dealType: dealTypeSelector(state),
  marketplace: marketplaceSelector(state),
  searchHistory: searchHistorySelector(state),
  shouldDisplayLocalData: shouldDisplayLocalDataSelector(state),
});

export default connect(mapStateToProps)(SearchSubheaderWithEvents);
