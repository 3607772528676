import React from 'react';
import { UploadBulletinStepProps } from '../index';
import ImageGallery from 'components/unit-page/gallery';
import { ShowAt } from 'consts/breakpoints';
import MainInfo from 'components/unit-page/main-details/MainInfo';
import {
  BulletinPreviewWrapper,
  DetailsWrapper,
  ImageGalleryWrapper,
  InfoWrapper,
  MapWrapper,
  PreviewPageWrapper,
  PreviewWarningMessage,
  WarningLinkWrapper,
} from './styled';
import { StaticMap } from 'components/static-map/StaticMap';
import ApartmentDetails from 'components/unit-page/apartment-details';
import PreviewControls from './PreviewControls';
import { createPortal } from 'utils/universalPortal';
import { SEARCH_BOTTOM_ROOT } from 'consts/rootNodes';
import { getBuildingClassFromPropertyType, uploadFormValues2UploadInput } from 'utils/uploadBulletin';
import { useLocale } from 'locale';
import { Text } from 'ds/components/typography';
import { SpacingBlock } from 'components/spacing-block';
import { Route } from 'config/routes';
import AmenitiesOld from 'components/unit-page/amenities';
import DescriptionApartment from 'components/inner-contact-form/description-apartment';
import {
  BulletinStatusType,
  DealType,
  IAutocompleteAddress,
  IBulletinInput,
  IDocId2InformationResponse,
  IDocId2InformationVariables,
} from 'utils/entities';
import docId2Information from 'store/sagas/apiService/queries/localData.gql';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'components/link';
import { MoreInfo } from 'screens/UnitPage/commercial/MoreInfo';
import { AmenitiesNew } from 'components/unit-page/amenities-commercial';
import { featuredAmenities } from 'components/unit-page/amenities/config';


interface PreviewStepProps extends UploadBulletinStepProps {
  submitForm?: (formData: IBulletinInput) => void;
  isAgent: boolean;
  submitting: boolean;
}

const PreviewStep: React.FC<PreviewStepProps> = ({ formInstance, submitForm, isAgent, submitting }) => {
  const formValues = formInstance.getState().values;
  const inputData = uploadFormValues2UploadInput(formValues);
  const {
    type,
    images,
    price,
    commonCharges,
    generalCondition,
    dealType,
    availabilityType,
    availableDate,
    area,
    floor,
    floors,
    monthlyTax,
    furniture,
    buildingYear,
    propertyType,
    amenities,
    description,
    id,
  } = inputData;
  const isCommercial = type === 'commercialBulletin';
  const beds = 'beds' in inputData && inputData.beds;
  const numberOfEmployees = 'numberOfEmployees' in inputData && inputData.numberOfEmployees;
  const qualityClass = 'qualityClass' in inputData && inputData.qualityClass;
  const monthlyManagementFee = isCommercial && dealType === DealType.Rent && 'monthlyManagementFee' in inputData && inputData.monthlyManagementFee;
  const { t } = useLocale();

  let neighbourhood;
  const { data } = useQuery<IDocId2InformationResponse, IDocId2InformationVariables>(docId2Information, {
    variables: { docId: formValues.addressDetails.docId },
    skip: !!neighbourhood,
  });

  if (!images) return null;

  const parsedImages = images.map(({ isFloorplan, imageUrl, description: imgDescription }) => ({
    isFloorPlan: isFloorplan,
    url: imageUrl,
    description: imgDescription,
  }));
  const [ primaryAddress, secondaryAddress ] = formValues.addressDetails.name.split(',');
  const isFrozen = formValues.status && formValues.status.status === BulletinStatusType.Frozen;

  if (data.docId2Information) {
    neighbourhood = (data.docId2Information.document as IAutocompleteAddress).neighbourhood;
  }

  const secondaryAddressWithNeigh = neighbourhood ? `${neighbourhood}, ${secondaryAddress}` : secondaryAddress;

  const [ lng, lat ] = formValues.addressDetails.location;

  const onSubmit = () => submitForm(inputData);
  const controls = <PreviewControls publish={onSubmit} isFrozen={isFrozen} submitting={submitting} />;
  const editRoute = isCommercial ? Route.EditCommercialBulletin : Route.EditBulletin;

  return (
    <PreviewPageWrapper>
      <BulletinPreviewWrapper>
        <ImageGalleryWrapper>
          <PreviewWarningMessage>
            <Text>{t('uploadBulletinPage.previewControl.mobile.publishMessage.beforeLink')}
                <Link
                  routeName={id ? editRoute : Route.UploadBulletin}
                  routeParams={{
                    step: undefined,
                    dealType: undefined,
                    term: undefined,
                    ...({ id } || null)}}
                >
                  <WarningLinkWrapper>
                    {t('uploadBulletinPage.previewControl.mobile.publishMessage.linkText')}
                  </WarningLinkWrapper>
                </Link>
              {t('uploadBulletinPage.previewControl.mobile.publishMessage.afterLink')}</Text>
          </PreviewWarningMessage>
          <ImageGallery
            images={parsedImages}
            lng={lng}
            lat={lat}
            id={null}
            type="bulletin"
            enableShare={false}
          />
        </ImageGalleryWrapper>
        <ShowAt to={2}>
          {(isMobile) => (
            <InfoWrapper isMobile={isMobile}>
              <MainInfo
                currentPrice={price}
                primaryAddress={primaryAddress}
                secondaryAddress={secondaryAddressWithNeigh}
                dealType={dealType}
                buildingClass={getBuildingClassFromPropertyType(propertyType)}
                area={area}
                beds={beds}
                isAgent={isAgent}
                floor={floor}
                floors={floors}
                numberOfEmployees={numberOfEmployees}
                generalCondition={generalCondition}
                qualityClass={qualityClass}
                isCommercial={isCommercial}
              />
              {isCommercial ? (
                <MoreInfo
                  availabilityType={availabilityType}
                  availableDate={(new Date(availableDate)).getTime()}
                  monthlyTaxes={monthlyTax}
                  monthlyManagementFee={monthlyManagementFee}
                />
              ) : null}
              {description ? (
                <SpacingBlock mTop={3}>
                  <DescriptionApartment
                    description={description}
                    withRedesignedForm={true}
                  />
                </SpacingBlock>
              ) : null}
              <DetailsWrapper>
                {isCommercial ? (
                  <AmenitiesNew amenities={amenities} />
                ) : (
                  <>
                    <AmenitiesOld amenities={amenities} displayAmenities={featuredAmenities} unitPetPolicy={null} />
                    <ApartmentDetails
                      commonCharges={commonCharges}
                      monthlyTaxes={monthlyTax || null}
                      generalCondition={generalCondition}
                      availabilityType={availabilityType}
                      availableDate={(new Date(availableDate)).getTime()}
                      buildingYear={buildingYear}
                      furnitureDetails={furniture}
                    />
                  </>
                )}
              </DetailsWrapper>
            </InfoWrapper>
          )}
        </ShowAt>
        <ShowAt to={2}>
          {createPortal(controls, SEARCH_BOTTOM_ROOT)}
        </ShowAt>
        <ShowAt from={3}>
          {controls}
        </ShowAt>
      </BulletinPreviewWrapper>
      <MapWrapper>
        <StaticMap lng={lng} lat={lat} />
      </MapWrapper>
    </PreviewPageWrapper>
  );
};


export default PreviewStep;
