import React from 'react';
import { useLocale } from 'locale';
import { StateChangeOptions } from 'downshift';
import withEvents from 'analytics/withEvents';
import { Text } from 'ds/components/typography';
import { CopyText } from 'ds/components/copy-text';
import Select, { SelectItemProps } from 'ds/components/input/Select';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { useTheme } from 'hooks/useTheme';
import { getDirection } from 'utils';
import { PoiId } from 'utils/entities';
import { EnhancedShareButton, ItemWrapper } from './styled';
import { ControlButton } from 'ds/components/button';
import ShareIcon from 'assets/svg/share.svg';
import { shareActionTypeToIcon, ShareType } from 'helpers/share-helpers';
import { IconWrapper } from './styled';
import { noop } from 'lodash';
import { Mode } from 'ds/components/button/ControlButton';


const SHARE_OPTIONS: SelectItemProps[] = [
  {
    value: ShareType.Email,
    label: ShareType.Email,
    icon: shareActionTypeToIcon[ShareType.Email],
  },
  {
    value: ShareType.CopyLink,
    label: ShareType.CopyLink,
    icon: shareActionTypeToIcon[ShareType.CopyLink],
  },
  {
    value: ShareType.WhatsApp,
    label: ShareType.WhatsApp,
    icon: shareActionTypeToIcon[ShareType.WhatsApp],
    isMobileOnly: true,
  },
  {
    value: ShareType.Twitter,
    label: ShareType.Twitter,
    icon: shareActionTypeToIcon[ShareType.Twitter],
  },
  {
    value: ShareType.Facebook,
    label: ShareType.Facebook,
    icon: shareActionTypeToIcon[ShareType.Facebook],
  },
  {
    value: ShareType.FacebookMessenger,
    label: ShareType.FacebookMessenger,
    icon: shareActionTypeToIcon[ShareType.FacebookMessenger],
  },
];

export enum ShareButtonSource {
  ListingPage = 'listing_page',
  ShortlistHoverPreview = 'favorites_hover',
}

interface ShareButtonProps {
  handleShare?: (value: ShareType, poiId: string) => void;
  shouldStayOpen(value: string): boolean;
  shouldResetSelectedValue(value: string): boolean;
  initialIsOpen?: boolean;
  copyText?: string;
  url?: string;
  abTest?: boolean;
  verticalPosition?: 'top' | 'bottom';
  poiId?: PoiId;
  shareOptions?: SelectItemProps[];
  dropdownStyles?: React.CSSProperties;
  isRound?: boolean;
  alignment?: 'right' | 'left';
  source?: ShareButtonSource;
  onClose?: () => void;
  shareClick?: (poiId: string) => void;
  controlButtonMode?: Mode;
  menuZIndex?: number;
}

export const ShareButtonLocal: React.FC<ShareButtonProps> = props => {
  const { t } = useLocale();
  const {
    handleShare,
    shouldStayOpen,
    shouldResetSelectedValue,
    children,
    initialIsOpen,
    copyText,
    abTest = false,
    verticalPosition,
    poiId,
    shareOptions,
    dropdownStyles,
    isRound,
    alignment,
    shareClick = noop,
    onClose,
    controlButtonMode,
    menuZIndex,
  } = props;
  const { isRTL } = useTheme();

  const breakpoint = useScreenBreakpoint();
  const isMobileDevice = breakpoint <= 2;

  const options = shareOptions || SHARE_OPTIONS.filter(o => isMobileDevice || !o.isMobileOnly).map(({ value, icon }) => ({
    value,
    icon,
    label: t('select.share.label', { label: value }),
  }));

  const renderItem = (item: SelectItemProps, isSelected: boolean) => item.value === ShareType.CopyLink ? (
    <ItemWrapper negativeMargin><CopyText item={item} copyText={copyText} isSelected={isSelected} /></ItemWrapper>
  ) : (
    <ItemWrapper>{Select.defaultProps.renderItem(item, isSelected)}</ItemWrapper>
  );

  const onStateChange = (selectOptions: StateChangeOptions<unknown>) => {
    if (selectOptions.isOpen === false && onClose) {
      onClose();
    }
  };

  const buttonContent = isRound ? (
    <IconWrapper isWhite={controlButtonMode === 'transparent'}>
      <ShareIcon width={22} height={22} />
    </IconWrapper>
  ) : (
    <>
      <ShareIcon width={20} height={20} />
      <Text weight="medium">{t('tooltip.share')}</Text>
    </>
  );


  return (
    <ShowAt to={2}>
      {isMobile => (
        <Select
          menuZIndex={menuZIndex}
          isUncontrolled
          styles={dropdownStyles}
          initialIsOpen={initialIsOpen}
          items={options}
          onChange={(value: ShareType) => handleShare(value, poiId)}
          onStateChange={onStateChange}
          shouldStayOpen={shouldStayOpen}
          alignment={alignment || getDirection(isRTL, isMobile)}
          verticalPosition={verticalPosition}
          shouldResetSelectedValue={shouldResetSelectedValue}
          clickButtonEvent={() => shareClick(poiId)}
          selectionPromptText={t('unitPage.share.title')}
          renderItem={renderItem}
          renderCustomToggle={(toggleProps) => children ? (
            <div {...toggleProps} style={{ width: '100%' }}>{children}</div>
          ) : (
            abTest ? (
              <ControlButton
                {...toggleProps}
                size="large"
                fontType="important"
                data-auto="share-button"
                className="share-button"
                isRound={isRound}
                mode={controlButtonMode}
              >
                {buttonContent}
              </ControlButton>
            ) : (
              <EnhancedShareButton {...toggleProps} data-auto="share-button"/>
            )
          )}
        />
      )}
    </ShowAt>
  );
};

export const ShareButton = withEvents<ShareButtonProps>((sendEvent, props: ShareButtonProps) => {
  return {
    handleShare(type: ShareType, poiId: string) {
      const payload: any = { bulletinId: poiId };
      if (props.source) payload.event = { source: props.source };

      let method;
      if (type === ShareType.CopyLink) {
        method = 'copy_link';
      }
      else if (type === ShareType.FacebookMessenger) {
        method = 'messenger';
      }
      else if (type === ShareType.WhatsApp) {
        method = 'whatsapp';
      }
      else if (type === ShareType.Email || type === ShareType.Facebook || ShareType.Twitter) {
        method = type;
      }

      payload.event = {
        ...payload.event,
        share_method: method,
      };

      sendEvent('property_share_method', 'property', payload);
    },
    shareClick(poiId: string) {
      const payload: any = { bulletinId: poiId };
      if (props.source) payload.event = { source: props.source };

      sendEvent('property_share_click', 'property', payload);
    },
  };

})(ShareButtonLocal);
