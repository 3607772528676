import { values, isNil, memoize } from 'lodash';
import { IProject, ICommercialProject, IApartmentType } from 'utils/entities';
import { removeTypeName } from 'utils';

export function isCommercialProjectPredicate(p: IProject | ICommercialProject): boolean {
  return p.isCommercial;
}

export const filterApartmentType = memoize((apartmentType: IApartmentType[]): IApartmentType[] => apartmentType.filter(item => {
  if (!item) return false;
  const type: IApartmentType = removeTypeName(item);
  return values(type).some(val => !isNil(val));
}));
