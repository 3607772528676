import { createSelector } from 'reselect';
import {
  agentsInOfficeSelector,
  approvedAgentSelector,
  commercialEnablePrivateUserSelector,
  isBulletinsAdminSelector,
  isAgentManagerSelector,
  isAgentSelector,
} from 'store/state/domainData/selectors';
import {
  newCommercialListingResponseSelector,
  newListingResponseSelector,
} from 'store/state/mutationsResponse/selectors';

export const isCommercialUploadEnabledSelector = createSelector([
  isAgentSelector,
  isAgentManagerSelector,
  isBulletinsAdminSelector,
  commercialEnablePrivateUserSelector,
  approvedAgentSelector,
  agentsInOfficeSelector,
], (
  isAgent,
  isAgentManager,
  isBulletinsAdmin,
  isCommercialAllowed,
  isApprovedAgent,
  agentsInOffice
) =>
  isBulletinsAdmin
    || (isAgent && isApprovedAgent)
    || (isAgentManager && agentsInOffice && agentsInOffice.length)
    || (!isAgent && !isAgentManager && isCommercialAllowed)
);

export const submittingSelector = createSelector([
  newListingResponseSelector,
  newCommercialListingResponseSelector,
], (residentialResponse, commercialResponse) =>
  residentialResponse && residentialResponse.loading || commercialResponse && commercialResponse.loading
);
