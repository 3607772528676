import React from 'react';
import { DefaultLink } from 'components/static-footer/styled';

interface UploadBulletinLinkProps {
  text: string;
  source: 'topbar' | 'home_page_footer_bar';
  icon?: JSX.Element;
}

const UPLOAD_BULLETIN_OLD_LINK = '/upload-bulletin';

export const UploadBulletinLink: React.FC<UploadBulletinLinkProps> = (props) => {
  const {
    icon,
    text,
    source,
  } = props;

  const link = `${UPLOAD_BULLETIN_OLD_LINK}?source=${source}`;

  return (
    <DefaultLink href={link} data-auto="upload-listing">
      {icon}
      {text}
    </DefaultLink>
  );
};

UploadBulletinLink.defaultProps = {
  icon: null,
};
