import { createSelector } from 'reselect';
import { flow } from 'lodash';
import { State } from 'store/state';
import { getRouteParams, marketplaceSelector } from '../selectors/router';
import { pricesStateSelector } from '../selectors/insights/prices';
import { insightsByIdsSelector } from 'store/state/domainData/selectors/insights';
import { visibleSectionSelector, isNewUnitPageMapExpandedSelector } from 'store/state/app/selectors';
import { MarketplaceType } from 'utils/entities';
import { FORBIDDEN_COMMERCIAL_INSIGHTS_TYPES } from 'store/state/selectors/insights/construction';
import { Section } from 'components/navigation/subheader/SectionsNavigation';


const insightsContextSelector = (state: State) => (state.insightsContext);

export const activeInsightIdSelector = createSelector([
  flow(getRouteParams, (p) => p.insightId),
  insightsByIdsSelector,
  marketplaceSelector,
], (insightId, byIds, marketplace) => {
  const found = byIds[insightId];

  if (found && marketplace === MarketplaceType.Commercial && FORBIDDEN_COMMERCIAL_INSIGHTS_TYPES.has(found.type)) {
    return null;
  }

  return found ? insightId : null;
});

export const activeCollectionSelector = createSelector([
  flow(getRouteParams, (p) => p.insightCollection),
  activeInsightIdSelector,
], (insightCollection, activeId) => activeId ? insightCollection : null);

export const insightsMobileMapTypeSelector = flow(insightsContextSelector, (ctx) => ctx.insightsMobileMapType);
export const openedInsightSectionsSelector = flow(insightsContextSelector, (ctx) => ctx.openedInsightSections);

export const activeSectionSelector = createSelector([
  visibleSectionSelector,
  insightsMobileMapTypeSelector,
  isNewUnitPageMapExpandedSelector,
], (section, mobileSection, isNewUnitPageExpanded) => {
  if (isNewUnitPageExpanded && !mobileSection) {
    return Section.ABOUT;
  }
  return mobileSection || section;
});

export const buildingPermitStateSelector = flow(insightsContextSelector, (ctx) => ctx.buildingPermit);
export const activeBuildingPermitIdxSelector = flow(buildingPermitStateSelector, (p) => p.activeBuildingPermitIndex);

export const activeImpactInsightCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeImpactInsightCarouselIdx);
export const activeAreaHighlightsAboutToComeCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeAreaHighlightsAboutToComeCarouselIdx);
export const activeAreaHighlightsGoodToKnowCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeAreaHighlightsGoodToKnowCarouselIdx);
export const rawTransportationAccordionSelector = flow(insightsContextSelector, (ctx) => ctx.transportation.accordion);
export const rawTransportationTransitTabSelector = flow(insightsContextSelector, (ctx) => ctx.transportation.transitTab);
export const hoveredSchoolIdSelector = flow(insightsContextSelector, (ctx) => ctx.hoveredSchoolId);
export const activeConstructionIdSelector = flow(insightsContextSelector, (ctx) => ctx.activeConstructionId);
export const activeNuisanceCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeNuisanceCarouselIdx);
export const activePriceCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activePriceCarouselIdx);
export const activeSchoolCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeSchoolCarouselIdx);
export const activeSafetyCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeSafetyCarouselIdx);
export const activeLivabilityCarouselIdxSelector = flow(insightsContextSelector, (ctx) => ctx.activeLivabilityCarouselIdx);
export const schoolsAccordionSelector = flow(insightsContextSelector, (ctx) => ctx.schoolsAccordion);
export const schoolsTabSelector = flow(insightsContextSelector, (ctx) => ctx.schoolsTab);
export const rawActiveTransportationStationSelector = flow(insightsContextSelector, (ctx) => ctx.transportationStation);
export const selectedBusLineSelector = flow(insightsContextSelector, (ctx) => ctx.activeBusLine);
export const hoveredDataLayerIdSelector = flow(insightsContextSelector, (ctx) => ctx.hoveredDataLayerId);
export const tabuSubmittedSelector = flow(insightsContextSelector, (ctx) => ctx.tabuSubmitted);
export const activeMapTransportationSelector = createSelector(insightsContextSelector, (ctx) => ctx.activeMapTransportation);
export const activeMapTransportationCountSelector = createSelector(activeMapTransportationSelector, (ctx) => Object.values(ctx).filter(v => v).length);
export const isMobileLayersToggleOpenSelector = createSelector(insightsContextSelector, (ctx) => ctx.isMobileLayersToggleOpen);
export const isInfoBoxOpenSelector = createSelector(insightsContextSelector, (ctx) => ctx.isInfoBoxOpen);

export const pricesTabSelector = flow(pricesStateSelector, ({ tab }) => tab);
export const pricesYearFilterSelector = flow(pricesStateSelector, ({ yearFilter }) => yearFilter);
export const pricesBedsFilterSelector = flow(pricesStateSelector, ({ bedsFilter }) => bedsFilter);
export const pricesFinalTransactionsFilterSelector = flow(pricesStateSelector, ({ onlyFinalTransactionsFilter }) => onlyFinalTransactionsFilter);
