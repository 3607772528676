import { DealType } from 'utils/entities';
import { ThumbnailLogo } from 'ds/components/ListingCard';
import {
  InactivePlaceholder,
  LocationIconWrapper,
  MapViewWrapper,
  SaveToListWrapper,
} from './styled';
import React, { useState, useCallback } from 'react';
import { useLocale } from 'locale';
import { CardLabels } from './CardLabels';
import Carousel from './Carousel';
import MapView from 'components/unit-page/gallery/MapView';
import FavoritesToggler from 'components/favorites/FavoritesTogglerNew';
import { StyledShowAt } from 'ds/theme/mediaBreakpoints';
import LocationIcon from 'assets/svg/location-gallery-2.svg';
import GalleryIcon from 'assets/svg/gallery.svg';
import { TransformedImage } from 'components/transformed-image';
import { useScreenBreakpoint } from 'consts/breakpoints';
import config from 'config';
import { CheckedLink, getAgencyLink, getDeveloperLink } from 'utils/checkLinksWrapper';
import UniversalBulletinCard from './Cards/BulletinCard';
import UniversalCommercialBulletinCard from './Cards/CommercialBulletin';
import UniversalCommercialProjectCard from './Cards/CommercialProject';
import UniversalProjectProjectCard from './Cards/ProjectCard';
import DiscountLabel from 'components/cards/listing/UniversalCard/DiscountLabel';
import { UniversalCardPoiUnion, ExtraProps, CardType } from 'components/cards/types';


export type DefaultUniversalCardProps = UniversalCardPoiUnion & ExtraProps & { dealType: DealType; hasDiscount?: boolean; };

interface UniversalCardState {
  slideIndex: number;
  hadInteraction: boolean;
  hideMap: boolean;
  isHovered: boolean;
}

const WrapperFreeUniversalCard: React.FC<DefaultUniversalCardProps> = React.memo((props) => {
  const {
    isInactive,
    date,
    lastUpdatedDate,
    thumbnails,
    onlyOneImageShouldBeDisplayed,
    locationPoint,
    withPaddingBottom,
    type,
    cardType,
    id,
    officeId,
    developerId,
    showPriceDropLabel,
    showIsNewLabel = true,
    showAddToFavourites,
    logoPath,
    hideLogoPreview,
    emptyLazyLoadPlaceholder,
    altText,
    disableSwipe,
    dealType,
    hasDiscount,
  } = props;

  const { t } = useLocale();
  const breakpoint = useScreenBreakpoint();
  const [ state, setState ] = useState<UniversalCardState>({
    slideIndex: 0,
    hadInteraction: false,
    hideMap: true,
    isHovered: false,
  });

  const isCommercial = props.cardType === CardType.CommercialProject || props.cardType === CardType.CommercialBulletin;
  const isPromoted = (props.cardType === CardType.Project || props.cardType === CardType.CommercialProject) && props.isPromoted;
  const isProject = cardType === CardType.Project || cardType === CardType.CommercialProject;
  const eventsHistory = (props.cardType === CardType.Bulletin || props.cardType === CardType.CommercialBulletin) ? props.eventsHistory : [];
  const isBulletinType = props.cardType === CardType.Bulletin || props.cardType === CardType.CommercialBulletin;

  const toggleMap = (value: boolean) => {
    setState((prevState) => ({ ...prevState, hideMap: value }));
  };

  const onMapIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({ ...prevState, hideMap: !prevState.hideMap }));
  };

  const onMapIconMouseEnter = () => {
    toggleMap(false);
  };

  const onMapIconMouseLeave = () => {
    toggleMap(true);
  };

  const toggleFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleInteraction = () => {
    requestAnimationFrame(() => {
      setState(prevState => ({
        ...prevState,
        hadInteraction: true,
      }));
    });
  };

  const handleOnMouseEnter = () => {
    setState(prevState => ({ ...prevState, isHovered: true }));
  };

  const handleOnMouseLeave = () => {
    setState(prevState => ({ ...prevState, isHovered: false }));
  };

  const setSlideIndex = useCallback((slideIndexNumber: number) => {
    setState(prevState => ({
      ...prevState, slideIndex: slideIndexNumber,
    }));
  }, [ setState ]);

  const { hideMap, hadInteraction, slideIndex, isHovered } = state;
  const withLogoPreview = Boolean(config.universalCardWithLogo && logoPath && !hideLogoPreview);
  const showSaveButton = showAddToFavourites && hideMap;
  const isMobile = breakpoint <= 2;

  const carousel = (
    <Carousel
      id={id}
      emptyLazyLoadPlaceholder={emptyLazyLoadPlaceholder}
      thumbnails={thumbnails}
      slideIndex={slideIndex}
      setSlideIndex={setSlideIndex}
      hideLogoPreview={hideLogoPreview}
      hideMap={hideMap}
      logoPath={logoPath}
      hadInteraction={hadInteraction}
      onlyOneImageShouldBeDisplayed={onlyOneImageShouldBeDisplayed}
      handleInteraction={handleInteraction}
      isMobile={isMobile}
      altText={altText}
      disableSwipe={disableSwipe}
    >
      {isMobile && !hideMap ? (
        <MapViewWrapper>
          <MapView lng={locationPoint.lng} lat={locationPoint.lat} type={type} isPromoted={isPromoted} />
        </MapViewWrapper>
      ) : null}
      {hideMap ? (
        hasDiscount
          ? <DiscountLabel />
          : (
            <CardLabels
              isPromoted={isPromoted}
              showPriceDropLabel={showPriceDropLabel}
              eventsHistory={eventsHistory}
              showIsNewLabel={showIsNewLabel}
              isBulletinType={isBulletinType}
              date={date}
              lastUpdatedDate={lastUpdatedDate}
              id={id}
              isCommercial={isCommercial}
              dealType={dealType}
            />
          )
      ) : null}
      {showSaveButton ? (
        <SaveToListWrapper onClick={toggleFavorite} isCommercial={isCommercial}>
          <FavoritesToggler
            data-auto="bulletin-list-card-favorite-toggler"
            id={id}
            type={type}
          />
        </SaveToListWrapper>
      ) : null}
      {isMobile && locationPoint && !isInactive ? (
        <>
          <StyledShowAt isTouch>
            <LocationIconWrapper onClick={onMapIconClick}>
              {hideMap ? <LocationIcon width={24} height={24} /> : <GalleryIcon width={24} height={24} />}
            </LocationIconWrapper>
          </StyledShowAt>
          <StyledShowAt isTouch={false}>
            <LocationIconWrapper
              className="universal-card-hover-show"
              isActive={!hideMap}
              onMouseEnter={onMapIconMouseEnter}
              onMouseLeave={onMapIconMouseLeave}
            >
              <LocationIcon width={24} height={24} />
            </LocationIconWrapper>
          </StyledShowAt>
        </>
      ) : null}
      {withPaddingBottom && isInactive ? (
        <InactivePlaceholder>
          {t('universalCard.inactive')}
        </InactivePlaceholder>
      ) : null}
      {withLogoPreview && logoPath ? (
        <CheckedLink
          link={cardType === CardType.Project || cardType === CardType.CommercialProject ? getDeveloperLink(developerId) : getAgencyLink(officeId)}
          isPreventEvent
          data-auto={isProject ? 'developer-link' : 'office-link'}
        >
          <ThumbnailLogo isHovered={isMobile ? true : isHovered} className="thumbnail-logo">
            <TransformedImage
              path={logoPath}
              fit="inside"
              width={80}
              height={56}
              dataAuto={isProject ? 'developer-logo-image' : 'office-logo-image'}
            />
          </ThumbnailLogo>
        </CheckedLink>
      ) : null}
    </Carousel>
  );

  switch (props.cardType) {
    case CardType.Bulletin:
      return (
        <UniversalBulletinCard
          carousel={carousel}
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          breakpoint={breakpoint}
          {...props}
        />
      );
    case CardType.Project:
      return (
        <UniversalProjectProjectCard
          carousel={carousel}
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          breakpoint={breakpoint}
          {...props}
        />
      );
    case CardType.CommercialProject:
      return (
        <UniversalCommercialProjectCard
          carousel={carousel}
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          breakpoint={breakpoint}
          {...props}
        />
      );
    case CardType.CommercialBulletin:
      return (
        <UniversalCommercialBulletinCard
          carousel={carousel}
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          breakpoint={breakpoint}
          {...props}
        />
      );
  }
});

WrapperFreeUniversalCard.defaultProps = {
  notificationTags: [],
};

export default WrapperFreeUniversalCard;
