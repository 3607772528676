import React, { useEffect, useRef } from 'react';
import { noop, debounce } from 'lodash';
import StarIcon from 'assets/svg/three-star.svg';
import { SubMenuItem, SubMenuItemSeparator, SubMenuItemSeparatorWrapper } from './styled';
import { SmallText, Text } from 'ds/components/typography';
import ScrollableWrapper from 'components/scrollable-wrapper';
import ScrollIntoView from 'scroll-into-view';
import { AUTO_SCROLL_DURATION } from 'components/navigation/subheader/SectionsNavigation';
import { HEADER_HEIGHT } from 'components/navigation/header.constants';
import { DetailPageEventCategory } from 'analytics/handlers/detailPageSectionHandlers';
import { useScreenBreakpoint } from 'consts/breakpoints';

interface ISectionNavigationItem {
  label: string;
  value: string;
}

export interface ScrollableSectionsNavigationProps {
  activeItem: string;
  topOffset?: number;
  items: ISectionNavigationItem[];
  onItemClickNotify?: (item: string) => void;
  eventCategory?: DetailPageEventCategory;
  separator?: JSX.Element;
  topOffsetMap?: Record<string, number>;
  scrollOnClick?: boolean;
  abTest?: boolean;
}

function getActive(item: ISectionNavigationItem, active: string, abTest: boolean) {
  if (!active) return false;

  if (item.value === 'ImportantInfo' && abTest) {
    switch (active) {
      case 'ImportantInfo':
      case 'ExploreTheArea':
      case 'PricesEstimation':
        return true;
      default:
        return false;
    }
  }
  else {
    return active.startsWith(item.value);
  }
}

const ScrollableSectionsNavigation: React.FC<ScrollableSectionsNavigationProps> = (props) => {
  const { abTest, activeItem, items, onItemClickNotify = noop, topOffset = HEADER_HEIGHT, separator, topOffsetMap, scrollOnClick = true } = props;

  const screenBreakpoint = useScreenBreakpoint();
  const isMobile = screenBreakpoint <= 2;
  const onClick = scrollOnClick ? (value: string) => {
    const el = document.querySelector<HTMLElement>(`[data-scroll-spy-id="${value}"]`);
    if (topOffsetMap && topOffsetMap[value]) {
      const settings = {
        ...scrollSettings,
        align: {
          ...scrollSettings.align,
          topOffset: topOffsetMap[value],
        },
      };
      ScrollIntoView(el, settings);
    }
    else {
      ScrollIntoView(el, scrollSettings);
    }
    onItemClickNotify(value);
  } : noop;
  const buttonContentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollSettings = {
    time: AUTO_SCROLL_DURATION,
    align: {
      topOffset,
      top: 0, // range  0 - 1, where 0.5 - center
    },
  };
  const debouncedSiv = useRef(debounce(ScrollIntoView, 500));
  const separatorEl = separator || <SubMenuItemSeparator />;

  useEffect(() => {
    const el = document.querySelector<HTMLElement>(`[data-scroll-menu-item-id="${activeItem}"]`);
    debouncedSiv.current(el, {
      align: {
        top: 0,
        left: 0.5,
      },
      validTarget: target => target === buttonContentWrapperRef.current,
    });
    return debouncedSiv.current.cancel;
  }, [ activeItem ]);

  const renderLabel = (label: string, value: string, isMob: boolean) => {
    if (abTest) {
      const maybeIcon = value === 'ImportantInfo';
      return (
        <>
          {isMob
            ? <SmallText style={{ marginLeft: maybeIcon ? '8px' : 0 }} weight="bold" children={label} />
            : <Text style={{ marginLeft: maybeIcon ? '8px' : 0 }} weight="bold" children={label} />}
          {maybeIcon ? <StarIcon /> : null}
        </>
      );
    }
    return <SmallText children={label} />;
  };

  return (
    <ScrollableWrapper scrollableRef={buttonContentWrapperRef}>
      {items.map((item, idx) => {

        const isActive = getActive(item, activeItem, abTest);
        const onItemClick = () => onClick(item.value);

        return (
          <React.Fragment key={item.value}>
            <SubMenuItemSeparatorWrapper>
              {idx ? separatorEl : null}
            </SubMenuItemSeparatorWrapper>
            <SubMenuItem
              data-auto={item.value}
              data-auto-item="sub-menu-item"
              className="sub-menu-item"
              data-scroll-menu-item-id={item.value}
              isActive={isActive}
              withBorder={abTest && isActive}
              onClick={onItemClick}
            >
              {renderLabel(item.label, item.value, isMobile)}
            </SubMenuItem>
          </React.Fragment>
        );
      })}
    </ScrollableWrapper>
  );
};

export default ScrollableSectionsNavigation;
