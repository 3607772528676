import React, { useMemo, useCallback } from 'react';
import * as Styled from './styled';
import { useLocale } from 'locale';
import { PerformantCheckboxGroup as CheckboxList } from 'components/filters/common/PerformantCheckbox';
import { ValueSetter, IFiltersState, AmenityCheckboxGroupSection } from 'components/filters/types';


interface CheckboxGroupProps {
  group: AmenityCheckboxGroupSection;
  values: IFiltersState['amenities'];
  valueSetter: ValueSetter<'amenities'>;
  last: boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const {
    group: {
      name,
      items,
    },
    last,
    values,
    valueSetter,
  } = props;
  const { t } = useLocale();
  const header = t('filters.amenitiesSections', { type: name });
  const { iconsMap, options } = useMemo(() => {
    return {
      iconsMap: items.reduce((acc, { amenity, icon }) => (acc[amenity] = icon, acc), {}),
      options: items.map(({ amenity }) => amenity),
    };
  }, [ items ]);

  const iconGetter = useCallback((amenity) => {
    return iconsMap[amenity];
  }, [ iconsMap ]);

  const labelGetter = useCallback((value) => {
    return t('filters.amenitiesOptions', { value });
  }, [ t ]);

  return (
    <Styled.Root>
      {header ? <Styled.Header weight="bold">{header}</Styled.Header> : null}
      <Styled.Body last={last}>
        <CheckboxList
          values={values}
          labelGetter={labelGetter}
          valueSetter={valueSetter}
          iconGetter={iconGetter}
          options={options}
        />
      </Styled.Body>
    </Styled.Root>
  );
};

export default CheckboxGroup;
