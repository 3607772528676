import { UGCAction } from './actionType';
import {
  OPEN_REVIEW_WIZARD,
  NEXT_STEP,
  PREVIOUS_STEP,
  SET_USER_NAME_INPUT,
  SET_TEXT_REVIEW_INPUT,
  SET_RATING,
  SET_RATING_FROM_PREVIEW,
  INITIALIZE_RATINGS,
  ASK_REVIEW_WIZARD_FOR_ADDRESS,
  CLOSE_REVIEW_WIZARD,
  RESET_WIZARD,
} from './types';
import { Topic, RatingTopic, Step } from 'components/user-generated-content/utils/initialUGCConfig';
import { Modifiable, createModifiable, revertModifiableToOrigin } from 'helpers/modifiable';

export type RatingsDataMap = Record<RatingTopic, Modifiable<number>>;

export interface ReviewWizardState {
  isOpen: boolean;
  currentStepIndex: number;
  steps: Step[];
  ratingsDataMap: RatingsDataMap;
  userNameInput: string;
  textReviewInput: string;
  docId: string;
  addressName: string;
}

const INITIAL_RATINGS_DATA_MAP: RatingsDataMap = {
  [Topic.Doorman]: createModifiable(null),
  [Topic.SafetyUnit]: createModifiable(null),
  [Topic.Service]: createModifiable(null),
  [Topic.Roomates]: createModifiable(null),
};

const INITIAL_STATE: ReviewWizardState = {
  isOpen: false,
  currentStepIndex: 0,
  steps: [],
  ratingsDataMap: INITIAL_RATINGS_DATA_MAP,
  userNameInput: '',
  textReviewInput: '',
  docId: '',
  addressName: '',
};

export const reviewWizardReducer = (state: ReviewWizardState = INITIAL_STATE, action: UGCAction): ReviewWizardState => {
  switch (action.type) {
    case CLOSE_REVIEW_WIZARD: {
      // reset to initial state, but keep origin user content
      const ratingsDataMap = Object.keys(state.ratingsDataMap).reduce<RatingsDataMap>((acc: RatingsDataMap, topic: RatingTopic) => {
        return {
          ...acc,
          [topic]: revertModifiableToOrigin(state.ratingsDataMap[topic]),
        };
      }, {} as RatingsDataMap);
      return {
        ...INITIAL_STATE,
        ratingsDataMap,
      };
    }
    case OPEN_REVIEW_WIZARD: {
      const { docId, addressName, steps } = action.payload;
      return {
        ...state,
        isOpen: true,
        docId,
        addressName,
        steps,
      };
    }
    case NEXT_STEP: {
      const nextStepIndex = state.currentStepIndex + 1;
      return {
        ...state,
        currentStepIndex: nextStepIndex < state.steps.length ? nextStepIndex : state.currentStepIndex,
      };
    }
    case PREVIOUS_STEP: {
      const previousStepIndex = state.currentStepIndex - 1;
      return {
        ...state,
        currentStepIndex: previousStepIndex >= 0 ? previousStepIndex : state.currentStepIndex,
      };
    }
    case SET_USER_NAME_INPUT: {
      return {
        ...state,
        userNameInput: action.payload,
      };
    }
    case SET_TEXT_REVIEW_INPUT: {
      return {
        ...state,
        textReviewInput: action.payload,
      };
    }
    case SET_RATING_FROM_PREVIEW:
    case SET_RATING: {
      const { topic, value } = action.payload;
      return {
        ...state,
        ratingsDataMap: {
          ...state.ratingsDataMap,
          [topic]: {
            ...state.ratingsDataMap[topic],
            modified: value,
            hasChanged: value !== state.ratingsDataMap[topic].origin,
          },
        },
      };
    }
    case ASK_REVIEW_WIZARD_FOR_ADDRESS: {
      const { docId, name: addressName } = action.payload.autocompleteEntry;
      return {
        ...state,
        docId,
        addressName,
      };
    }
    case INITIALIZE_RATINGS: {
      const ratingsDataMap = Object.keys(state.ratingsDataMap).reduce<RatingsDataMap>((acc: RatingsDataMap, topic: RatingTopic) => {
        const data = action.payload.find(el => el.topic === topic);
        if (data) {
          return {
            ...acc,
            [topic]: createModifiable(data.value),
          };
        }
        return acc;
      }, INITIAL_RATINGS_DATA_MAP);
      return {
        ...state,
        ratingsDataMap,
      };
    }
    case RESET_WIZARD: {
      return { ...INITIAL_STATE };
    }
    default:
      return state;
  }
};
