import config from 'config';

const objectToGetParams = (object: { [key: string]: string | number | undefined | null }) => {
  const params = Object.entries(object)
    .filter(([ , value ]) => value !== undefined && value !== null)
    .map(([ key, value ]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
};

interface FacebookLinkOptions {
  quote?: string;
  hashtag?: string;
}

export const createFacebookLink = (url: string, options?: FacebookLinkOptions): string => {
  const { quote, hashtag } = options || {};
  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      quote,
      hashtag,
    })
  );
};

interface FacebookMessengerLinkOptions {
  appId?: string;
  redirectUri?: string;
  to?: string;
}

export const createFacebookMessengerLink = (url: string, options?: FacebookMessengerLinkOptions): string => {
  const { appId, redirectUri, to } = options || {};
  return (
    'https://www.facebook.com/dialog/send' +
    objectToGetParams({
      link: url,
      redirect_uri: redirectUri || url,
      app_id: appId || config.facebook.appId,
      to,
    })
  );
};

interface TwitterLinkOptions {
  text?: string;
  via?: string;
  hashtags?: string[];
}

export const createTwitterLink = (url: string, options?: TwitterLinkOptions): string => {
  const { text, via, hashtags = [] } = options || {};
  return (
    'https://twitter.com/share' +
    objectToGetParams({
      url,
      text,
      via,
      hashtags: hashtags.join(','),
    })
  );
};

interface WhatsAppLinkOptions {
  text?: string;
  phone?: string;
}

export const createWhatsAppLink = (options?: WhatsAppLinkOptions, isDesktopOnly = false) => {
  const { text = '', phone } = options || {};
  const whatsappLink = isDesktopOnly ? 'https://web.whatsapp.com/send/' : 'https://api.whatsapp.com/send/';
  return (
    whatsappLink +
    objectToGetParams({
      text,
      phone,
    })
  );
};

interface EmailLinkOptions {
  subject: string;
  body: string;
  email?: string;
}

export const createEmailLink = (options: EmailLinkOptions) => {
  const { subject, body, email } = options;

  const baseLink = email ? 'mailto:' + email : 'mailto:';

  return (
    baseLink +
    objectToGetParams({
      subject,
      body,
    })
  );
};
