import React, { Dispatch } from 'react';
import { RootAction } from 'store/state';
import {
  AvatarSection,
  BottomLinksSectionWrapper,
  LinksSectionWrapper,
  MobileMenuBody,
  MobileMenuContentWrapper,
  MobileMenuHeader,
  MobileMenuWrapper,
  MoreMenuLinksWithIconsSection,
  MyProfileText,
  NewLabelV2,
  SavedListingsCount,
  ProfileShortcutItem,
  UnloggedUserAvatarIconWrapper,
  UnloggedUserAvatarSection,
  UploadListingButton,
  UserInfo,
  UserInfoWithAvatarWrapper,
  UserSection,
  LinksDrawerInner,
  ProfileShortcutWrapper,
} from './styled';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { useLocale } from 'locale';
import { MenuLink } from './MenuLink';
import Logo from 'components/navigation/Logo';
import CloseIcon from 'assets/svg/dropdown/close.svg';
import UnloggedUserAvatarIcon from 'assets/svg/navigation/unlogged-user-avatar.svg';
import ChevronIcon from 'assets/svg/navigation/chevron-left.svg';
import BriefcaseIcon from 'assets/svg/navigation/briefcase.svg';
import ExclamationMarkIcon from 'assets/svg/navigation/exclamation-mark.svg';
import { Avatar } from '../header/styled';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import { IUserProfileFields, userProfileFieldsSelector } from 'components/navigation/profileFields';
import { isConnectedUserSelector } from 'store/state/domainData/selectors';
import { DecodedRouteParams, NavigateToFn, Route } from 'config/routes';
import { Link } from 'components/link';
import { H3, Text } from 'ds/components/typography';
import { setAuthModal, setUserMobileProfileOpen, setUserMobileProfileReturnable } from 'store/state/app/actions';
import { actionToIconMobile, UserActionName, mobileMoreMenuActions } from '../userActions';
import { navigateTo } from 'store/state/router/actions';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { getRouteParams } from 'store/state/selectors/router';
import { agentsLinks, informationAndServicesLinks, staticLinksSelector } from './staticLinks';
import { InformationAndServicesLinksSection, LinksSection, LinksSectionLink, SectionToLinks } from 'components/navigation/more-actions-menu/types';
import { LinksDrawer } from 'components/navigation/more-actions-menu/LinksDrawer';
import { AuthenticationModalPayload, AuthenticationModalType } from 'components/authentication/types';
import { AuthModalSource } from 'store/state/app';
import { userFavoritesCounterSelector } from 'store/state/domainData/selectors/favorites';
import { pushNotificationsEnabledSelector } from 'store/state/selectors/pushNotificationsStatus';
import { pushNotificationsFeatureEnabled } from 'utils/pushNotificationsFeatureEnabled';


interface UserMobileMoreMenuProps {
  userProfile: IUserProfileFields;
  isConnectedUser: boolean;
  pushEnabled: boolean;
  savedListingsCount: number;
  routeParams: DecodedRouteParams;
  staticLinks: SectionToLinks;
  onClose: () => void;
  onSetUserMobileProfileOpen: (isOpen: boolean) => void;
  onSetUserMobileProfileReturnable: (isReturnable: boolean) => void;
  onSetAuthModal: typeof setAuthModal;
  navigate: NavigateToFn;
}

const UserMobileMoreMenu: React.FC<UserMobileMoreMenuProps> = ({
  userProfile,
  isConnectedUser,
  savedListingsCount,
  routeParams,
  staticLinks,
  onClose,
  onSetUserMobileProfileOpen,
  onSetUserMobileProfileReturnable,
  onSetAuthModal,
  navigate,
  pushEnabled,
}) => {
  const { t } = useLocale();

  const isLoggedInUser = isConnectedUser && userProfile && userProfile.initials;
  const bottomSection = staticLinks[LinksSection.Localize];

  const handleAvatarSectionClick = () => {
    onSetUserMobileProfileOpen(true);
    onSetUserMobileProfileReturnable(true);
  };

  const handleSavedListingsClick = () => {
    navigate(Route.Shortlist, routeParams);
    onClose();
  };

  return (
    <OverlayWrapper>
      <MobileMenuWrapper style={{ minHeight: window ? window.innerHeight : 'auto' }}>
        <MobileMenuContentWrapper>
          <MobileMenuHeader whiteBackground>
            <CloseIcon onClick={onClose} />
            <Logo />
          </MobileMenuHeader>
          <MobileMenuBody>
            <UserSection>
              {isLoggedInUser ? (
                <AvatarSection onClick={handleAvatarSectionClick}>
                  <UserInfoWithAvatarWrapper>
                    <Avatar
                      userInitials={userProfile.initials}
                      imageURL={userProfile.avatar}
                      style={{ height: 56, width: 56, fontSize: typographyFontSizeCfg.h2.mobile }}
                    />
                    <UserInfo data-auto="mobile-user-info">
                      <H3 weight="bold">
                        {userProfile.firstName} {userProfile.lastName}
                      </H3>
                      <MyProfileText>{t('navigation.moreActionsMenu.myProfile')}</MyProfileText>
                    </UserInfo>
                  </UserInfoWithAvatarWrapper>
                  <ChevronIcon />
                </AvatarSection>
              ) : (
                <UnloggedUserAvatarSection
                  onClick={() => onSetAuthModal({ type: AuthenticationModalType.SignIn }, { isUserInitiated: true })}
                >
                  <UnloggedUserAvatarIconWrapper>
                    <UnloggedUserAvatarIcon />
                  </UnloggedUserAvatarIconWrapper>
                  {t('navigation.moreActionsMenu.loginOrRegister')}
                </UnloggedUserAvatarSection>
              )}
              <Link routeName={Route.UploadBulletin} preserveParams style={{ textDecoration: 'none' }} onClick={onClose}>
                <UploadListingButton size="extraLarge">
                  {t('navigation.moreActionsMenu.uploadBulletin')}
                </UploadListingButton>
              </Link>
              {isLoggedInUser && (
                <ProfileShortcutWrapper>
                  <ProfileShortcutItem onClick={handleSavedListingsClick}>
                    <MenuLink isInitialIconColor>
                      {actionToIconMobile[UserActionName.SavedListings]}
                      <Text>{t('navigation.header.actions', { action: UserActionName.SavedListings })}</Text>
                    </MenuLink>
                    <SavedListingsCount>{savedListingsCount}</SavedListingsCount>
                  </ProfileShortcutItem>
                  {pushNotificationsFeatureEnabled() ? (
                    <ProfileShortcutItem>
                      <MenuLink
                        routeName={Route.PushSettings}
                        hasWarning={!pushEnabled}
                        fullWidthAnchor
                        isInitialIconColor
                        onClick={onClose}
                      >
                        {actionToIconMobile.pushSettings}
                        {t('navigation.header.actions', { action:  UserActionName.PushSettings, isProfileMobileMenu: true })}
                      </MenuLink>
                    </ProfileShortcutItem>
                  ) : null}
                </ProfileShortcutWrapper>
              )}
            </UserSection>
            <MoreMenuLinksWithIconsSection>
              <LinksSectionWrapper>
                {mobileMoreMenuActions.map((action) => (
                  <MenuLink
                    key={action.name}
                    routeName={action.route}
                    routeParams={action.params}
                    isInitialIconColor
                    fullWidthAnchor
                    onClick={onClose}
                  >
                    {actionToIconMobile[action.name]}
                    {t('navigation.header.actions', {
                      action: action.name,
                      isAgent: userProfile.isAgent,
                      isProfileMobileMenu: true,
                    })}
                    {action.name === UserActionName.MortgageOffices && (
                      <NewLabelV2 weight="bold" small>
                        {t('navigation.header.new')}
                      </NewLabelV2>
                    )}
                  </MenuLink>
                ))}
                <LinksDrawer links={agentsLinks}>
                  <LinksDrawerInner data-auto="more-agents">
                    <BriefcaseIcon />
                    {t('navigation.moreActionsMenu.agents')}
                  </LinksDrawerInner>
                </LinksDrawer>
                <LinksDrawer
                  links={informationAndServicesLinks}
                  sections={[
                    InformationAndServicesLinksSection.Main,
                    InformationAndServicesLinksSection.GuidesAndTools,
                    InformationAndServicesLinksSection.ManagementSystems,
                  ]}
                >
                  <LinksDrawerInner data-auto="more-info">
                    <ExclamationMarkIcon />
                    {t('navigation.moreActionsMenu.informationAndServices')}
                  </LinksDrawerInner>
                </LinksDrawer>
              </LinksSectionWrapper>
            </MoreMenuLinksWithIconsSection>
            <BottomLinksSectionWrapper>
              {bottomSection.map((link: LinksSectionLink) => (
                <MenuLink key={link.label} routeName={link.routeName} href={link.path} fullWidthAnchor grey2Color>
                  {link.label}
                </MenuLink>
              ))}
            </BottomLinksSectionWrapper>
          </MobileMenuBody>
        </MobileMenuContentWrapper>
      </MobileMenuWrapper>
    </OverlayWrapper>
  );
};

const mapStateToProps = (state: State) => ({
  userProfile: userProfileFieldsSelector(state),
  isConnectedUser: isConnectedUserSelector(state),
  savedListingsCount: userFavoritesCounterSelector(state),
  routeParams: getRouteParams(state),
  staticLinks: staticLinksSelector(state),
  pushEnabled: pushNotificationsEnabledSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetUserMobileProfileOpen: (isOpen: boolean) => dispatch(setUserMobileProfileOpen(isOpen)),
  onSetUserMobileProfileReturnable: (isReturnable: boolean) => dispatch(setUserMobileProfileReturnable(isReturnable)),
  onSetAuthModal: (payload: AuthenticationModalPayload, meta: { isUserInitiated?: boolean, source?: AuthModalSource }) => dispatch(setAuthModal(payload, meta)),
  navigate: (name: Route, params?: DecodedRouteParams, opts?: NavigationOptions) => dispatch(navigateTo(name, params, opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMobileMoreMenu);
