import React, { useState } from 'react';
import { useLocale } from 'locale';
import * as Styled from './styled';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { TabuWizardStep } from '../TabuWizard';
import { Tabu } from 'utils/entities';
import { isEmailValid as isEmailValidHelper } from 'utils/formValidationHelpers';
import { BASE_PARAMS } from '../payment/Payment';
import withEvents from 'analytics/withEvents';
import { TabuSource } from 'components/tabu/Tabu';
import { useScreenBreakpoint } from 'consts/breakpoints';


interface TabuLoginProps {
  address: string;
  city: string;
  tabu: Tabu;
  email: string;
  name: string;
  source: TabuSource;
  setStep: React.Dispatch<React.SetStateAction<TabuWizardStep>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
}

type TabuLoginField = 'email' | 'name';

const TabuLogin: React.FC<TabuLoginProps> = (props) => {
  const {
    tabu,
    city,
    name,
    email,
    address,
    setStep,
    setName,
    setEmail,
  } = props;

  const { t } = useLocale();
  const breakPoint = useScreenBreakpoint();
  const [ dirtyFields, setDirtyFields ] = useState(new Set<TabuLoginField>([]));

  const { parcel, block } = tabu;
  const isMobile = breakPoint <= 2;

  const isValidEmail = isEmailValidHelper(email);
  const isValidName = Boolean(name);

  const handleSetDirty = (field: TabuLoginField) => setDirtyFields(prev => new Set([ ...prev, field ]));
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value);
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const handleSubmit = () => {
    if (isValidEmail && isValidName) {
      setStep(TabuWizardStep.Payment);
    }
    if (!name) {
      handleSetDirty('name');
    }
    if (!email) {
      handleSetDirty('email');
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setStep(TabuWizardStep.Feedback);
  };

  return (
    <Styled.Root>
      {isMobile ? null : <Styled.Header weight="bold">{t('tabu.wizard.login.header')}</Styled.Header>}

      <Styled.SimpleText>{t('tabu.wizard.login.info')}</Styled.SimpleText>
      <Styled.SimpleText weight="bold" >{t('tabu.wizard.login.address', { address, city })}</Styled.SimpleText>
      <Styled.SimpleText>{t('tabu.wizard.login.tabuDetails', { parcel, block })}</Styled.SimpleText>

      <Styled.FormContainer>
        <Styled.FieldWrapper>
          <TextField
            fullWidth
            value={email}
            onChange={handleEmailChange}
            label={t('tabu.wizard.login.email.label')}
            placeholder={t('tabu.wizard.login.email.placeholder')}
            hasError={!isValidEmail && dirtyFields.has('email')}
            errorMessage={t('tabu.wizard.login.email.error')}
            onBlur={() => handleSetDirty('email')}
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <TextField
            fullWidth
            value={name}
            onChange={handleNameChange}
            label={t('tabu.wizard.login.name.label')}
            hasError={!isValidName && dirtyFields.has('name')}
            errorMessage={t('tabu.wizard.login.name.error')}
            onBlur={() => handleSetDirty('name')}
          />
        </Styled.FieldWrapper>
      </Styled.FormContainer>
      <Button data-auto="tabu-login-submit-button" onClick={handleSubmit} fullWidth size="large">{t('tabu.wizard.login.submit.text')}</Button>
        <Styled.Footer>
          <div>{t('tabu.wizard.login.footer.text', { price: BASE_PARAMS.sum })}</div>
          <Styled.FooterLink data-auto="tabu-login-footer-link" href="#" onClick={handleClick}>
            {t('tabu.wizard.login.footer.link')}
          </Styled.FooterLink>
        </Styled.Footer>
    </Styled.Root>
  );
};

export default withEvents<TabuLoginProps>((sendEvent, { source, name, email }) => ({
  setStep: (step: TabuWizardStep) => {
    if (step === TabuWizardStep.Payment) {
      sendEvent('tabu_contact_details_submit', 'tabu', {
        event: {
          source,
          contact_details: {
            email,
            full_name: name,
          },
        },
      });
    }
  },
}))(TabuLogin);
