import { isAgentSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { State } from 'store/state';
import { createStructuredSelector } from 'reselect';
import { flow } from 'lodash';

export interface IUserProfileFields {
  initials: string;
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  isAgent: boolean;
}

export const userProfileFieldsSelector = createStructuredSelector<State, IUserProfileFields>({
  initials: flow(userProfileSelector, (user) => (
    (user && user.firstName && user.lastName)
      ? user.firstName[0] + user.lastName[0]
      : user && user.email
        ? user.email.substr(0, 2)
        : null
  )),
  isAgent: isAgentSelector,
  firstName: flow(userProfileSelector, (u) => u && u.firstName),
  lastName: flow(userProfileSelector, (u) => u && u.lastName),
  email: flow(userProfileSelector, (u) => u && u.email),
  avatar: flow(userProfileSelector, (u) => u && u.avatar),
});
