import React from 'react';
import { useLocale } from 'locale';
import ArrowIcon from 'assets/svg/left.svg';
import { NavigationWrapper, SubMenuBackButton } from './styled';
import { SmallText } from 'ds/components/typography';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import BackLink from 'components/navigation/BackLink';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { DetailPageEventCategory } from 'analytics/handlers/detailPageSectionHandlers';
import { detailPageCategorySelector } from 'store/state/selectors/analytics';
import { AppMode, appModeSelector, prevRouteSelector, routeSelector } from 'store/state/selectors/router';
import { Route, State as RouteState } from 'config/routes';
import NavigationSearch from './components/navigationSearch';
import { InsightSection } from 'store/state/insightsContext';
import { MOBILE_SUBMENU_HEIGHT, HEADER_HEIGHT } from '../header.constants';
import SectionsNavigationInner from './SectionsNavigationInner';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';
import { useCheckProjectPage } from 'hooks/useCheckProjectPage';
import SubheaderControls from './SubheaderControls';


export enum Section {
  OVERVIEW = 'Overview',
  SHOW_MORE = 'ShowMore',
  IMPORTANT_INFO = 'ImportantInfo',
  OVERVIEW_HEADER = 'Overview/header',
  OVERVIEW_PROS_AND_CONS = 'Overview/pros_and_cons',
  OVERVIEW_DESCRIPTION = 'Overview/description',
  OVERVIEW_LOCATION = 'Overview/location',
  PLANNING = 'Planning',
  TRANSPORTATION = 'Transportation',
  PRICES_ESTIMATION = 'PricesEstimation',
  PRICE_HISTORY = 'PriceHistory',
  OVERVIEW_PRICE_HISTORY = 'Overview/PriceHistory',
  SCHOOLS = 'Schools',
  NUISANCES = 'Nuisances',
  PRICES = 'Prices',
  SAFETY = 'Safety',
  POINTS_OF_INTEREST = 'PointsOfInterest',
  NEIGHBOURHOOD_LIFE = 'NeighbourhoodLife',
  QUIET_STREETS = 'QuietStreets',
  EXPLORE_THE_AREA = 'ExploreTheArea',
  REVIEWS = 'Reviews',
  LeadingStatement = 'LeadingStatement',
  FOOTER = 'Footer',
  TRENDS = 'Trends',
  EDUCATION = 'Education',
  AREA_INSIGHTS = 'area_insights',
  BUILDING_DETAILS = 'BuildingDetails',
  UGC = 'UGC',
  CONTACT = 'Contact',
  CONTACT_TOP = 'ContactTop',
  BUILDING_PERMIT = 'BuildingPermit',
  OVERVIEW_AREA = 'AreaOverview',
  MADAD = 'Madad',
  INSIGHTS = 'insights',
  SOCIAL_INDEX = 'SocialIndex',
  PRICE_TRENDS = 'PriceTrends',
  DEALS = 'Deals',
  LISTINGS = 'Listings',
  PROJECTS = 'Projects',
  PROJECTS_LIST = 'Projects/projects',
  PROJECTS_MAP = 'Projects/map',
  PROJECTS_PROMOTED = 'Projects/promoted',
  AMENITIES = 'amenities',
  ABOUT = 'About',
  TEAM = 'Team',
  NEARBY_ROADS = 'NearbyRoads',
  PROPERTY_CAROUSEL = 'PropertyCarousel',
  OFFICE_SERVICE = 'OfficeService',
  DEVELOPER_ABOUT = 'DeveloperAbout',
  VIDEO = 'Video',
  MEMBER_ASSOCIATION = 'MemberAssociation',
  AGENT_ABOUT = 'AgentAbout',
  ARTICLES = 'Articles',
  ABOUT_THE_AGENT = 'AboutTheAgent',
  ABOUT_THE_OFFICE = 'AboutTheOffice',
}

export const ANALYTICS_MAPPING: Record<Section, string> | Partial<Record<InsightSection, string>> = {
  [Section.OVERVIEW]: 'highlights',
  [Section.OVERVIEW_HEADER]: 'highlights/header',
  [Section.OVERVIEW_PROS_AND_CONS]: 'highlights/pros_and_cons',
  [Section.OVERVIEW_DESCRIPTION]: 'highlights/description',
  [Section.OVERVIEW_LOCATION]: 'highlights/location',
  [Section.OVERVIEW_PRICE_HISTORY]: 'highlights/price_history',
  [Section.ABOUT]: 'highlights',
  [Section.OVERVIEW_AREA]: 'area_overview',
  [Section.CONTACT]: 'highlights/contact',
  [Section.PLANNING]: 'new_construction',
  [Section.TRANSPORTATION]: 'transportation',
  [Section.PRICES_ESTIMATION]: 'prices_estimation',
  [Section.PRICE_HISTORY]: 'price_history',
  [Section.SCHOOLS]: 'education',
  [Section.NUISANCES]: 'nuisances',
  [Section.PRICES]: 'prices',
  [Section.SAFETY]: 'safety',
  [Section.POINTS_OF_INTEREST]: 'points_of_interest',
  [Section.NEIGHBOURHOOD_LIFE]: 'yelp',
  [Section.QUIET_STREETS]: 'quiet_streets',
  [Section.EXPLORE_THE_AREA]: 'explore_the_area',
  [Section.REVIEWS]: 'reviews',
  [Section.FOOTER]: 'footer',
  [Section.TRENDS]: 'prices',
  [Section.PRICE_TRENDS]: 'price_trends',
  [Section.DEALS]: 'deals',
  [Section.EDUCATION]: 'education',
  [Section.SOCIAL_INDEX]: 'social_index',
  [Section.AREA_INSIGHTS]: 'area_insights',
  [Section.INSIGHTS]: 'insights',
  [Section.BUILDING_DETAILS]: 'building_details',
  [Section.BUILDING_PERMIT]: 'building_permit',
  [Section.UGC]: 'ugc',
  [Section.MADAD]: 'madad',
  [Section.LISTINGS]: 'listings',
  [Section.PROJECTS]: 'projects',
  [Section.AMENITIES]: 'amenities',
  [Section.PROPERTY_CAROUSEL]: 'property_carousel',
  [InsightSection.Neighbors]: 'neighbors',
  [Section.DEVELOPER_ABOUT]: 'developer_about',
  [Section.ABOUT_THE_AGENT]: 'about_agent',
  [Section.SHOW_MORE]: 'amenities',
  [Section.IMPORTANT_INFO]: 'prices_estimation',
  [Section.ABOUT_THE_OFFICE]: 'about_office',
};

interface SectionsNavigationProps {
  onItemClickNotify?: (item: string) => void;
  items: string[];
  activeItem: string;
  eventCategory: DetailPageEventCategory;
  prevRoute: boolean;
  mode: AppMode;
  route: RouteState;
}

export const AUTO_SCROLL_DURATION = 500;
export const SUBMENU_NAVIGATION_CLASS_NAMES = {
  ROOT: 'submenu-navigation-wrapper',
};

const BackButton: React.FC<{
  prevRoute: boolean;
  isWithoutText?: boolean;
}> = React.memo(({
  prevRoute,
  isWithoutText,
}) => {
  const { t } = useLocale();

  return (
    <BackLink>
      <ArrowIcon width={24} height={24}/>
      {isWithoutText
        ? null
        : (
          <SmallText data-auto="back-button-text">
            {t('navigation.submenu.backToSearch', { prevRoute })}
          </SmallText>
        )}
    </BackLink>
  );
});

const isCheckMode = (mode: AppMode) => {
  return mode === AppMode.Check;
};

const SectionsNavigation: React.FunctionComponent<SectionsNavigationProps> = (props) => {
  const { items, activeItem, prevRoute, mode, route, eventCategory } = props;
  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();
  const isUnitPage = isUnitPageRoute(route.name);
  const isProjectPage = useCheckProjectPage();
  const isDesktop = screenBreakpoint >= 3;
  const isMobileListingPage = isUnitPage && screenBreakpoint <= 2;
  const mobileSubmenusHeight = isMobileListingPage
    ? MOBILE_SUBMENU_HEIGHT + HEADER_HEIGHT
    : HEADER_HEIGHT;
  const getSubMenuItemText = (item: string) => {
    switch (true) {
      case isProjectPage:
        return t('projectPage.subMenu.item', { item });
      case route.name === Route.Sold:
        return t('soldPage.subMenu.item', { item });
      default:
        return t('unitPage.subMenu.item', { item });
    }
  };
  const data = items.map(item => ({ label: getSubMenuItemText(item), value: item }));

  return (
    <NavigationWrapper
      isModeB={isUnitPage}
      data-auto={SUBMENU_NAVIGATION_CLASS_NAMES.ROOT}
      className={SUBMENU_NAVIGATION_CLASS_NAMES.ROOT}
    >
      <ShowAt from={3}>
        <SubMenuBackButton data-auto="sub-menu-back-button" isCheckMode={isCheckMode(mode)}>
          <BackButton
            isWithoutText={isCheckMode(mode)}
            prevRoute={prevRoute}
          />
        </SubMenuBackButton>
        {isCheckMode(mode) ? (
          <NavigationSearch/>
        ) : null}
      </ShowAt>
      <SectionsNavigationInner
        items={data}
        abTest={isUnitPage}
        separator={<div style={{ width: '44px' }} />}
        activeItem={activeItem}
        topOffset={mobileSubmenusHeight + 16}
        eventCategory={eventCategory}
      />
      {isUnitPage && isDesktop ? <SubheaderControls /> : null}
    </NavigationWrapper>
  );
};


const mapStateToProps = (state: State) => ({
  eventCategory: detailPageCategorySelector(state),
  prevRoute: Boolean(prevRouteSelector(state)),
  mode: appModeSelector(state),
  route: routeSelector(state),
});

export default connect(mapStateToProps, null)(SectionsNavigation);
