import styled from '@emotion/styled';

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListItem = styled.li`
  ${({ theme }) => `
    &:not(:last-of-type) {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};
    }
  `}
`;

export const ShareAction = styled.div`
  ${({ theme }) => `
    width: 55px;
    height: 55px;
    border-radius: ${theme.borderRadius.round};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.neutrals.white};
    box-shadow: ${theme.shadow.level2};
    border: 1px solid  ${theme.colors.neutrals.grey8};
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${theme.colors.brand.main};
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    &:hover {
      background-color: ${theme.colors.neutrals.grey10};
      & path {
        fill: ${theme.colors.brand.lighter};
      }
    }
  `}
`;
