import { CheckAddress } from './CheckAddress';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { navigateTo } from 'store/state/router/actions';
import { marketplaceSelector, routeSelector } from 'store/state/selectors/router';
import { detailPageCategorySelector } from 'store/state/selectors/analytics';
import { areasWithCommercialInfoPageSelector } from 'store/state/domainData/selectors/areasWithCommercialInfoPage';


const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
  eventCategory: detailPageCategorySelector(state),
  areasWithCommercialInfoPage: areasWithCommercialInfoPageSelector(state),
  marketplace: marketplaceSelector(state),
});

const mapDispatchToProps = {
  navigate: navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAddress);
