import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import { WithContentSlotSidePaddingContainer } from 'ds/components/Layout';
import { typographyLineHeightCfg } from 'ds/components/typography/types';

export const HeaderBackground = styled(WithContentSlotSidePaddingContainer)`
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding-left: 0;
    padding-right: 0;
  `}
  cursor: pointer;
`;

export const CollapsedHeader = styled.div<{ opened: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.grey9};
  .insight-collapsed-row:last-of-type & {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.white};
  }
  ${({ opened, theme }) => opened && theme.media.showAt({ from: 2 })`
    border-bottom: 2px solid ${theme.colors.neutrals.white};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    min-height: ${theme.isRTL ? '80px' : 'auto'};
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    padding-top: ${theme.spacing(3.5)};
    padding-bottom: ${theme.spacing(3.5)};
  `}
`;

export const CollapsedContent = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.neutrals.white};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: ${theme.spacing(1)} 3px;
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    padding-bottom: ${theme.spacing(3)};
  `}
`;

export const IconWrapper = styled.div<{ isIconReverse: boolean}>`
  display: flex;
  align-items: center;
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.isRTL ? 0 : theme.spacing(2)};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(3)};
  `}
  > svg {
    width: 24px;
    height: 24px;
    ${({ isIconReverse, theme }) => theme.isRTL && isIconReverse ? `
      transform: scale(-1,1);
    ` : ''}
    path {
      fill: ${({ theme }) => theme.colors.brand.main};
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    align-items: center;
    margin-right: ${theme.spacing(0.5)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column;
    margin-right: ${theme.spacing(1.5)};
  `}
`;

export const Title = styled(Text.withComponent('h3'))`
  text-transform: capitalize;
  white-space: nowrap;
  line-height: ${typographyLineHeightCfg.smallText}px;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(0.5)};
  `}
`;

export const DynamicContent = styled.div<{ type: string }>`
  width: 100%;
  margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: auto;
  ${({ theme }) => theme.media.showAt({ from: 2, to: 3 })`
    max-width: ${theme.isRTL ? '360px' : '310px'};
  `}
  ${({ theme }) => theme.media.showAt({ from: 4 })`
    max-width: 492px;
  `}
`;

export const CollapsedArrow = styled.div<{ opened: boolean, isVisible: boolean }>`
  ${({ opened }) => opened ? `
    transform: rotate(180deg);
  ` : ''}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(3)};
  `}

  ${({ theme, isVisible }) => !isVisible && `
    svg {
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  `}
`;
