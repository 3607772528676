import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IBulletin, ICommercialBulletin, PoiId, ResolutionPreference } from 'utils/entities';
import Carousel from 'ds/components/carousels/carousel-projects';
import { makeByIdsDictionarySelector } from 'store/state/domainData/selectors/common';
import { State } from 'store/state';
import { makeMapStateToPropsFactory } from 'components/cards/listing/makeMapStateToPropsFactory';
import { UniversalCardProps } from 'components/cards/listing/UniversalCard/UniversalCard';
import UniversalCard from 'components/cards/listing/UniversalCard';
import withEvents from 'analytics/withEvents';
import { CardType } from 'components/cards/types';
import { ContactAgentModalSource } from 'store/state/app';
import {
  isListingsPostLeadCarouselLoadingSelector,
  postLeadCarouselListingsSelector,
  listingsPostLeadCarouselListSelector,
} from 'store/state/domainData/selectors/listingsPostLeadCarousel';
import ContactButton from 'components/project-carousel/modeB/ContactButton';
import { ItemWrapper, ListingPostLeadCarouselWrapper, CenteredCarouselControls } from './styled';
import { LeadType } from 'store/sagas/apiService/types';


interface PrivateListingCarouselProps {
  listings: Array<IBulletin | ICommercialBulletin>;
  source: ContactAgentModalSource;
  isLoading: boolean;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  onScrollLeft?: () => void;
  onScrollRight?: () => void;
}

const listingsByIdsSelector = makeByIdsDictionarySelector(listingsPostLeadCarouselListSelector);
const poiByIdsSelector: any = (state: State, poiId: PoiId) => listingsByIdsSelector(state)[poiId];

const makeMapStateToProps = makeMapStateToPropsFactory(poiByIdsSelector);

const SpecificUniversalCard = (props: UniversalCardProps) => {
  let enhancedProps: UniversalCardProps = {
    ...props,
    hideLogoPreview: true,
    isMinHeight: true,
  };
  if (enhancedProps.cardType === CardType.Bulletin || enhancedProps.cardType === CardType.CommercialBulletin) {
    enhancedProps = {
      ...enhancedProps,
      resolutionPreferences: ResolutionPreference.Neighborhood,
    };
  }
  return <UniversalCard {...enhancedProps} />;
};

const ConnectedUniversalCard = connect(makeMapStateToProps)(SpecificUniversalCard);

const PrivateListingCarousel: React.FC<PrivateListingCarouselProps> = (props) => {
  const { listings: data, isLoading, onScrollLeft, onScrollRight, source } = props;
  const [ listings, setListings ] = useState(data);

  const hasArrows = listings.length > 2;
  useEffect(() => {
    setListings(data);
  }, [ isLoading ]);

  if (!listings || !listings.length) return null;

  const listingsList = listings.map(({ id, type }) => (
    <ItemWrapper key={id}>
      <ConnectedUniversalCard id={id} showAddToFavourites={false} onlyOneImageShouldBeDisplayed/>
      <ContactButton id={id} source={source} type={type === 'commercialBulletin' ? LeadType.CommercialBulletin : LeadType.Bulletin} />
    </ItemWrapper>
  ));

  return (
    <ListingPostLeadCarouselWrapper>
      <CenteredCarouselControls>
        <Carousel
          isLoading={isLoading}
          hasArrows={hasArrows}
          onScrollLeft={onScrollLeft}
          onScrollRight={onScrollRight}
        >
          {listingsList}
        </Carousel>
      </CenteredCarouselControls>
    </ListingPostLeadCarouselWrapper>
  );
};

const PrivateListingCarouselWithEvents = withEvents<PrivateListingCarouselProps>((sendEvent) => ({
  onScrollLeft() {
    sendEvent('property_carousel_scroll', 'property', {
      event: {
        carousel_type: 'similar_listings',
        direction: 'left',
        action: 'click',
      },
    });
  },
  onScrollRight() {
    sendEvent('property_carousel_scroll', 'property', {
      event: {
        carousel_type: 'similar_listings',
        direction: 'right',
        action: 'click',
      },
    });
  },
}))(PrivateListingCarousel);

const mapStateToProps = (state: State) => ({
  isLoading: isListingsPostLeadCarouselLoadingSelector(state),
  listings: postLeadCarouselListingsSelector(state),
});

export default connect(mapStateToProps)(PrivateListingCarouselWithEvents);
