import { createSelector } from 'reselect';
import { routeSelector } from './router';
import { Route } from 'config/routes';
import { DetailPageEventCategory } from 'analytics/handlers/detailPageSectionHandlers';

const RouteToDetailPageCategoryMap: Partial<Record<Route, DetailPageEventCategory>> = {
  [Route.AddressPage]: DetailPageEventCategory.Address,
  [Route.LocalPage]: DetailPageEventCategory.LocalPage,
  [Route.UnitPage]: DetailPageEventCategory.Property,
  [Route.ProjectPage]: DetailPageEventCategory.Property,
};

export const detailPageCategorySelector = createSelector([ routeSelector ],
  (route): DetailPageEventCategory => RouteToDetailPageCategoryMap[route.name] || DetailPageEventCategory.Property);
