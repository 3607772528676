// This is not actually polyfill. It's a ponyfill, see:
// https://github.com/sindresorhus/ponyfill
import ResizeObserver from 'resize-observer-polyfill';


class ROCorePool {
  private pool: ResizeObserverCore[] = [];

  constructor(poolSize: number) {
    for (let i = 0; i < poolSize; i++) {
      this.pool.push(new ResizeObserverCore());
    }
  }

  public getInstance() {

    const leastLoadedInstance = this.pool.reduce<ResizeObserverCore>((acc, instance) => {
      if (acc && acc.getObservationsCount() < instance.getObservationsCount()) {
        return acc;
      }
      return instance;
    }, null);

    return leastLoadedInstance;
  }
}


class ResizeObserverCore {

  private observer: ResizeObserver;
  private listeners = new Map<ResizeObserverCallback, Element[]>();

  constructor() {
    this.observer = new ResizeObserver(this.callback.bind(this));
  }

  public getObservationsCount() {
    return this.listeners.size;
  }

  private callback(allEntries: ResizeObserverEntry[], observer: ResizeObserver) {
    const entriesByTarget = new Map<Element, ResizeObserverEntry>(allEntries.map(e => [ e.target, e ]));

    for (const [ callback, elements ] of this.listeners.entries()) {
      const entriesForCallback: ResizeObserverEntry[] = [];

      for (const elt of elements) {
        if (entriesByTarget.has(elt)) {
          entriesForCallback.push(
            entriesByTarget.get(elt)
          );
        }
      }

      if (entriesForCallback.length) {
        callback(entriesForCallback, observer);
      }
    }
    entriesByTarget.clear();
  }

  public subscribe(target: Element, callback: ResizeObserverCallback) {
    this.observer.observe(target);

    if (!this.listeners.has(callback)) {
      this.listeners.set(callback, [ target ]);
    }

    this.listeners.get(callback).push(target);

    return () => {
      const maybeDeletableElements = new Set(this.listeners.get(callback));
      this.listeners.delete(callback);

      for (const [ , elements ] of this.listeners.entries()) {
        for (const elt of elements) {
          maybeDeletableElements.delete(elt);
        }
      }

      for (const elt of maybeDeletableElements) {
        this.observer.unobserve(elt);
      }
    };
  }

}


// tslint:disable-next-line: variable-name
let __pool: ROCorePool = null;
// calculated empirically
// flushing callbacks with this number takes sane time
const INSTANCES_NUM = 3;

function getInstance() {
  if (!__pool) {
    __pool = new ROCorePool(INSTANCES_NUM);
  }

  return __pool.getInstance();
}
/**
 * ResizeObserver implementation that utilizes Object pool pattern
 * Its public api is similar to native ResizeObserver,
 * but every new instance reuses one of pooled instances.
 * This is done to fix memory leaks on Safari where polyfill is used.
 */
class PooledResizeObserver implements ResizeObserver {

  private instance: ResizeObserverCore;
  private targets = new Map<Element, () => void>();

  constructor(
    private callback: ResizeObserverCallback
  ) {
    this.instance = getInstance();
  }

  public disconnect() {
    for (const [ , unsubscribe ] of this.targets.entries()) {
      unsubscribe();
    }
    this.targets.clear();
  }

  public observe(target: Element) {
    const unsubscribe = this.instance.subscribe(target, this.callback);
    this.targets.set(target, unsubscribe);
  }

  public unobserve(target: Element) {
    const unsubscribe = this.targets.get(target);
    unsubscribe();
    this.targets.delete(target);
  }
}

export { PooledResizeObserver as ResizeObserver };
