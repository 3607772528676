import React from 'react';
import styled from '@emotion/styled';
import { PriceEvent } from 'utils/entities';
import { isPriceDropped } from 'utils/priceEvents';
import { useLocale } from 'locale';
import { DefaultText, Text } from 'ds/components/typography';
import { typographyLineHeightCfg, typographyFontSizeCfg } from 'ds/components/typography/types';


const PriceWrapper = styled.div<{ direction: 'down' | 'up' | 'left'; gapSize: 'small' | 'medium' }>`
  ${({ theme, direction, gapSize }) => `
    color: ${theme.colors.neutrals.grey1};
    ${direction === 'left' ? `
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(gapSize === 'medium' ? 1 : 0.5)};
      align-items: center;
    ` : ''}
    ${direction === 'up' ? `
      display: flex;
      flex-direction: column-reverse;;
    ` : ''}
  `}
`;

const PropertyPrice = styled(DefaultText)<{ hasPriceDropped?: boolean; size?: 'small' | 'medium' | 'large'; }>`
  font-size: ${typographyFontSizeCfg.h2.mobile}px;
  line-height: ${typographyLineHeightCfg.h2.mobile}px;

  ${({ theme, hasPriceDropped, size }) => `
    color: ${hasPriceDropped ? theme.colors.label.violet : theme.colors.neutrals.grey1};
    ${size === 'small' ? `
      font-size: ${typographyFontSizeCfg.h3.mobile}px;
      line-height: ${typographyLineHeightCfg.h3.mobile}px;
    ` : ''}
    ${size === 'large' ? `
      font-size: ${typographyFontSizeCfg.h1.mobile}px;
      line-height: ${typographyLineHeightCfg.h1.mobile}px;
    ` : ''}
  `}
`;

PropertyPrice.defaultProps = {
  weight: 'bold',
};

const PropertyPreviousPrice = styled(Text)<{ size?: 'small' | 'medium' | 'large' }>`
  ${({ theme, size }) => `
    text-decoration: line-through;
    color: ${theme.colors.neutrals.grey2};
    ${size === 'small' ? `
      font-size: ${typographyFontSizeCfg.smallText}px;
      line-height: ${typographyLineHeightCfg.smallText}px;
    ` : ''}
  `}
`;

interface PriceProps {
  price: number;
  eventsHistory?: PriceEvent[];
  hidePriceDrop?: boolean;
  direction?: 'down' | 'up' | 'left';
  size?: 'small' | 'medium' | 'large';
  gapSize?: 'small' | 'medium';
}

const Price: React.FC<PriceProps> = (props) => {
  const {
    price,
    eventsHistory,
    hidePriceDrop,
    direction = 'down',
    size = 'medium',
    gapSize = 'small',
  } = props;
  const { formatMoney } = useLocale();
  const hasPriceDropped = isPriceDropped(eventsHistory);

  let prevPrice;
  if (hasPriceDropped) {
    prevPrice = eventsHistory[eventsHistory.length - 2].price;
  }

  return (
    <PriceWrapper direction={direction} gapSize={gapSize}>
      <PropertyPrice hasPriceDropped={hasPriceDropped} data-auto="property-price" size={size}>
        {formatMoney(price)}
      </PropertyPrice>
      {hasPriceDropped && !hidePriceDrop ? (
        <PropertyPreviousPrice data-auto="property-prevous-price" size={size}>
          {formatMoney(prevPrice)}
        </PropertyPreviousPrice>
      ) : null}
    </PriceWrapper>
  );
};


export default React.memo(Price);
