import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { StateChangeOptions, ControllerStateAndHelpers } from 'downshift';
import { useQuery } from '@apollo/react-hooks';
import he from 'convert-layout/he';

import config from 'config';
import { IAutocompleteEntry, CompletionType } from 'utils/entities';

import GET_ADDRESSES_QUERY from './getAddressesQuery.gql';
import GET_AUTOCOMPLETE_QUERY from './getAutocomplete.gql';
import GET_AUTOCOMPLETE_API3_QUERY from './getAutocompleteApi3.gql';
import { SingleInputOwnProps, SingleInputProps, MultiInputOwnProps, MultiInputProps } from './types';
import { debounce, throttle } from 'lodash';
import _ from 'lodash';


interface GetAddressesVariables {
  text: string;
  completionTypes: CompletionType[];
}

interface GetAddressesResponse {
  addresses: IAutocompleteEntry[];
}

interface GetApi3AutocompleteResponse {
  autocomplete: IAutocompleteEntry[];
}

interface Api3AutocompleteVariables {
  text: string;
  completionType: CompletionType[];
  limit: number;
}

const isRTL = config.isRTL;

const EMPTY_ARRAY: IAutocompleteEntry[] = [];

// throttle + debounce = throbounce
// https://www.peterbe.com/plog/how-to-throttle-and-debounce-an-autocomplete-input-in-react
function useThrobounced(value: string) {

  const [ throbounced, setThrobounced ] = useState(value);

  const setDebounced = useMemo(() => debounce(setThrobounced, 500), []);
  const setThrottled = useMemo(() => throttle(setThrobounced, 500), []);

  useEffect(() => {
    if (value && value.length < 5) {
      setDebounced.cancel();
      setThrottled(value);
    }
    else {
      setThrottled.cancel();
      setDebounced(value);
    }
  }, [ value, setDebounced, setThrottled ]);

  return throbounced;
}

export function useAutocompleteSuggestions(
  searchStr: string,
  completionTypes: CompletionType[],
  disableHebrewLayoutConvert: boolean,
  skip: boolean,
  useApi3AutocompleteWithApi2: boolean,
  useApi3: boolean,
  optionsCount: number = 5
) {

  const debthrottled = useThrobounced(searchStr);

  const isReallyLoading = (loading: boolean) => loading || (searchStr !== debthrottled);

  let reallyLoading: boolean;
  let suggestedAddresses: IAutocompleteEntry[];
  if (useApi3) {
    const variables: Api3AutocompleteVariables = {
      text: isRTL && !disableHebrewLayoutConvert ? he.fromEn(debthrottled) : debthrottled,
      completionType: completionTypes || [],
      limit: optionsCount,
    };
    const query = GET_AUTOCOMPLETE_API3_QUERY;

    const { data, loading } = useQuery<GetApi3AutocompleteResponse, Api3AutocompleteVariables>(query, {
      variables,
      skip: skip || !debthrottled,
    });

    reallyLoading = isReallyLoading(loading);
    suggestedAddresses = _.get(data, 'autocomplete') || EMPTY_ARRAY;
  }
  else {
    const variables: GetAddressesVariables = {
      text: isRTL && !disableHebrewLayoutConvert ? he.fromEn(debthrottled) : debthrottled,
      completionTypes: completionTypes as CompletionType[] || [],
    };
    const query = useApi3AutocompleteWithApi2 ? GET_AUTOCOMPLETE_QUERY : GET_ADDRESSES_QUERY;

    const { data, loading } = useQuery<GetAddressesResponse, GetAddressesVariables>(query, {
      variables,
      skip: skip || !debthrottled,
    });

    reallyLoading = isReallyLoading(loading);
    suggestedAddresses = (useApi3AutocompleteWithApi2 ? _.get(data, 'api3Autocomplete') : _.get(data, 'addresses')) || EMPTY_ARRAY;
  }

  return {
    loading: reallyLoading,
    suggestedAddresses,
  };
}

export function singleAutocompleteEnhancer(InputComp: React.ComponentType<SingleInputProps>) {
  const enhanced: React.FC<SingleInputOwnProps> = (props) => {
    // this flag will prevent useQuery from doing network request
    // on initialization before autocomplete has ever been opened
    const [ hasOpened, setHasOpened ] = useState(false);

    const onDownshiftStateChange = useCallback((
      options: StateChangeOptions<unknown>,
      stateAndHelpers: ControllerStateAndHelpers<unknown>
    ) => {
      if (!hasOpened && options.isOpen) {
        setHasOpened(true);
      }
      if (props.onDownshiftStateChange) {
        props.onDownshiftStateChange(options, stateAndHelpers);
      }
    }, [ props.onDownshiftStateChange, hasOpened, setHasOpened ]);

    const { suggestedAddresses, loading } = useAutocompleteSuggestions(
      props.searchStr,
      props.completionTypes,
      props.disableHebrewLayoutConvert,
      !(props.autoFocus || hasOpened),
      props.useApi3AutocompleteWithApi2,
      props.useApi3,
      props.optionsCount
    );

    return (
      <InputComp
        {...props}
        onDownshiftStateChange={onDownshiftStateChange}
        loading={loading}
        suggestedAddresses={suggestedAddresses}
      />
    );
  };

  return enhanced;
}


export function multiAutocompleteEnhancer(InputComp: React.ComponentType<MultiInputProps>) {
  const enhanced: React.FC<MultiInputOwnProps> = (props) => {

    const { suggestedAddresses, loading } = useAutocompleteSuggestions(
      props.searchStr,
      props.completionTypes,
      props.disableHebrewLayoutConvert,
      false,
      false,
      false,
      props.optionsCount
    );

    return (
      <InputComp
        {...props}
        loading={loading}
        suggestedAddresses={suggestedAddresses}
      />
    );
  };

  return enhanced;
}
