import { memoize } from 'lodash';
import { IABTestContext } from 'config/abTests';
import { UserTokenStore } from 'helpers/userToken';
import { getContext, select } from 'redux-saga/effects';
import { LoadType, MutationType } from './types';
import { LoadStrategy } from './LoadStrategy';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';
const operations = () => import('./operations');


const operationContainsProperty = (property: string) => (document: LoadStrategy<LoadType>) => {
  switch (document.kind) {
    case 'Document':
      return document.definitions.some(
        (def) => (def.kind === 'OperationDefinition' && def.variableDefinitions.some((v) => v.variable.name.value === property))
      );
    case 'CustomResolver': {
      return Boolean(document.additionalVariables) && document.additionalVariables.has(property);
    }
  }
};

export const operationNeedsToken = memoize(operationContainsProperty('userToken'));
export const operationNeedsAbTests = memoize(operationContainsProperty('abtests'));

export function* enrichGQLVariables(document: LoadStrategy<any>, metaVars: any) {
  const variables = { ...metaVars };
  if (operationNeedsToken(document) && variables.userToken === undefined) {
    const tokenStore: UserTokenStore = yield getContext('tokenStore');
    variables.userToken = tokenStore.get();
  }

  if (operationNeedsAbTests(document)) {
    const abTestsContext: IABTestContext = yield select(abTestsStateSelector);
    const abTestForServer = {};
    Object.entries(abTestsContext).forEach(([ key, value ]) => {
      if (key.startsWith('_be_')) {
        abTestForServer[key] = value;
      }
    });
    variables.abtests = abTestForServer;
  }

  return variables;
}

export async function getOperationByType(type: 'query' | 'mutation', opetationType: LoadType | MutationType) {
  const { default: ops } = await operations();
  return ops[type][opetationType];
}
