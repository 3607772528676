import React from 'react';
import { ICommercialProject, IProject, IBulletin, ICommercialBulletin, PromotionValues } from 'utils/entities';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps,
  getContactPageEventPayload,
} from 'helpers/contact';
import withEvents from 'analytics/withEvents';
import { IFormFields } from 'store/sagas/apiService/types';
import { useLocale } from 'locale';
import Modal from 'ds/components/modal';
import styled from '@emotion/styled';
import { FormApi } from 'final-form';
import { isBulletinPoiType, isProjectPoiType } from 'utils/marketplaceRoutes';
import ContactFormModeB from 'components/inner-contact-form/mortgage-form/modeB';
import { Text } from 'ds/components/typography';


const ModalContentRoot = styled.div`
  ${props => props.theme.media.showAt({ to: 2 })`
    width: 338px;
    margin: 0 auto;
    max-width: 100%;
  `}
`;

const TitleWrapper = styled(Text)`
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  text-align: center;
  position: absolute;
  top: -40px;
  left: -24px;
  right: -24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  z-index: -1;
`;

interface HebrewContactModalMobileProps {
  poi: IBulletin | IProject | ICommercialProject | ICommercialBulletin;
  onClose: () => void;
  handleSubmit: () => void;
  formApi: FormApi;
  isLeadingAgent: boolean;
  onCallClick?: (values: Partial<IFormFields>) => void;
  onAfterSubmit?: (values: IFormFields) => void;
  onPhoneClick?: () => void;
}

const HebrewContactModalMobile: React.FC<HebrewContactModalMobileProps> = ({
  poi,
  onClose,
  isLeadingAgent,
}) => {
  const { t } = useLocale();
  const hidePhoneNumber = isProjectPoiType(poi) && (!poi.promotionStatus || poi.promotionStatus.status !== PromotionValues.Promoted);
  return (
    <Modal
      isOpen
      onClose={onClose}
      title={<TitleWrapper weight="medium">{t('contactForm.contact.form.withMortgage.mobile.title', { isContacted: false })}</TitleWrapper>}
      mobileModalPositionBottom
    >
      <ModalContentRoot>
        <ContactFormModeB
          poi={poi}
          isLeadingAgent={isLeadingAgent}
          isModal={true}
          hidePhoneNumber={hidePhoneNumber}
        />
      </ModalContentRoot>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withEvents<HebrewContactModalMobileProps>((sendEvent, props) => ({
    onCallClick: (values) => sendEvent('property_lead_call_submit', 'property', {
      ...getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, values, true),
      bulletinId: props.poi.id,
    }),
    handleSubmit: () => {
      sendEvent(
        props.formApi.getState().hasValidationErrors ? 'property_lead_form_submit_attempt' : 'property_lead_form_submit',
        'property', {
          ...getContactPageEventPayload(isBulletinPoiType(props.poi) && props.poi.poc, props.formApi.getState().values),
          bulletinId: props.poi.id,
        });
    },
  }))(HebrewContactModalMobile)
);
