export const USER_LOGIN_SOCIAL = 'APP/USER_LOGIN_SOCIAL';
export const SIGN_IN_SIGN_UP_WITH_EMAIL = 'APP/SIGN_IN_SIGN_UP_WITH_EMAIL';
export const USER_LOGIN_WITH_EMAIL_AND_PASSWORD = 'APP/USER_LOGIN_WITH_EMAIL_AND_PASSWORD';
export const GOOGLE_LOGIN_WITH_CONSENTS = 'APP/GOOGLE_LOGIN_WITH_CONSENTS';
export const PASSWORD_LOGIN_WITH_CONSENTS = 'APP/PASSWORD_LOGIN_WITH_CONSENTS';
export const FACEBOOK_LOGIN_WITH_CONSENTS = 'APP/FACEBOOK_LOGIN_WITH_CONSENTS';
export const UPDATE_USER = 'APP/UPDATE_USER';
export const LOGOUT = 'APP/LOGOUT';
export const SET_TOAST_MESSAGE = 'APP/SET_TOAST_MESSAGE';
export const SET_AUTH_MODAL = 'APP/SET_AUTH_MODAL';
export const SET_COMMUTE_POPUP_STATUS = 'APP/SET_COMMUTE_POPUP_STATUS';
export const SET_MOBILE_DISCOVERY_POPUP_STATUS = 'APP/SET_MOBILE_DISCOVERY_POPUP_STATUS';
export const SET_CONTACT_AGENT_OPEN = 'APP/SET_CONTACT_AGENT_OPEN';
export const SET_FEEDBACK_POPUP_OPEN = 'APP/SET_FEEDBACK_POPUP_OPEN';
export const SET_UPLOAD_BULLETIN_REPORT_MODAL_OPEN = 'APP/SET_UPLOAD_BULLETIN_REPORT_MODAL_OPEN';
export const SET_AFTER_UPLOAD_BULLETIN_MODAL_OPEN = 'APP/SET_AFTER_UPLOAD_BULLETIN_MODAL_OPEN';
export const SET_ADDRESS_FEEDBACK_POPUP_OPEN = 'APP/SET_ADDRESS_FEEDBACK_POPUP_OPEN';
export const SET_REPORT_LISTING_POPUP_OPEN = 'APP/SET_REPORT_LISTING_POPUP_OPEN';
export const SET_FINISH_FORM_STATE = 'APP/SET_FINISH_FORM_STATE';
export const SHOW_CONTACT_PHONE = 'APP/SHOW_CONTACT_PHONE';

export const SET_GALLERY_STATUS = 'APP/SET_GALLERY_STATUS';

export const TOGGLE_FAVORITES = 'APP/TOGGLE_FAVORITES';
export const TOGGLE_ADDRESS = 'APP/TOGGLE_ADDRESS';

export const SAVE_SEARCH = 'APP/SAVE_SEARCH';
export const CONTINUE_EDITING = 'APP/CONTINUE_EDITING';
export const GET_BACK_TO_EDITING_LATER = 'APP/GET_BACK_TO_EDITING_LATER';
export const UPDATE_SAVED_SEARCH = 'APP/UPDATE_SAVED_SEARCH';
export const DELETE_SAVE_SEARCH = 'APP/DELETE_SAVE_SEARCH';
export const SET_SAVE_SEARCH_MODAL_OPEN = 'APP/SET_SAVE_SEARCH_MODAL_OPEN';
export const RESOLVE_SAVE_SEARCH_MODAL = 'APP/RESOLVE_SAVE_SEARCH_MODAL';

export const UPDATE_PENDING_ADDRESS_CARD_SETTINGS = 'APP/UPDATE_PENDING_ADDRESS_CARD_SETTINGS';

export const SET_NEEDS_POPUP_OPEN = 'APP/SET_NEEDS_POPUP_OPEN';
export const SET_CONTACT_AGENT_SUCCESS_OPEN = 'APP/SET_CONTACT_MODAL_SUCCESS_OPEN';

export const SET_MOBILE_SEARCH_MAP_VIEW_ACTIVE = 'APP/SET_MOBILE_SEARCH_MAP_VIEW_ACTIVE';
export const SET_VISIBLE_SECTION = 'APP/SET_VISIBLE_SECTION';

export const SET_SAVE_AGENT_RESULT_POPUP_OPEN = 'APP/SET_SAVE_AGENT_RESULT_POPUP_OPEN';

export const SET_FIXED_MULTI_PREVIEW = 'APP/SET_FIXED_MULTI_PREVIEW';

export const SET_USER_MOBILE_PROFILE_OPEN = 'APP/SET_USER_MOBILE_PROFILE_OPEN';

export const SET_SIDEBAR_OPEN = 'APP/SET_SIDEBAR_OPEN';

export const SET_USER_MOBILE_PROFILE_RETURNABLE = 'APP/SET_USER_MOBILE_PROFILE_RETURNABLE';

export const SUBMIT_CONTACT_AGENT = 'APP/SUBMIT_CONTACT_AGENT';

export const SUBMIT_USER_CONTACT_AGENT = 'APP/SUBMIT_USER_CONTACT_AGENT';

export const SET_SHARE_MODAL_OPEN = 'APP/SET_SHARE_MODAL_OPEN';

export const CONTACT_MODAL_CALL = 'APP/CONTACT_MODAL_CALL';

export const SET_MADAD_CONTACT_MODAL_STATE = 'APP/SET_MADAD_CONTACT_MODAL_STATE';

export const SET_TABU_STATE = 'APP/SET_TABU_STATE';
export const REQUEST_TABU_BY_DOC_ID = 'APP/REQUEST_TABU_BY_DOC_ID';

export const SET_AUTH_EMAIL = 'APP/SET_AUTH_EMAIL';

export const SET_MANAGE_LISTINGS_ERROR_MODAL = 'APP/SET_MANAGE_LISTINGS_ERROR_MODAL';
export const CLOSE_MANAGE_LISTINGS_ERROR_MODAL = 'APP/CLOSE_MANAGE_LISTINGS_ERROR_MODAL';
export const SET_OPEN_STATUS_DELETE_LISTING_MODAL = 'APP/SET_OPEN_STATUS_DELETE_LISTING_MODAL';
export const SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID = 'APP/SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID';

export const SET_MORTGAGE_MODAL = 'APP/SET_MORTGAGE_MODAL';
export const SET_DEVELOPER_FILTERS = 'APP/SET_DEVELOPER_FILTERS';
export const SET_IS_LISTING_ADDITIONAL_BANNER_CLOSED = 'APP/SET_IS_LISTING_ADDITIONAL_BANNER_CLOSED';
export const SET_IS_SEARCH_RESULT_ADDITIONAL_BANNER_CLOSED = 'APP/SET_IS_SEARCH_RESULT_ADDITIONAL_BANNER_CLOSED';
export const SET_IS_MOBILE_MENU_HIDDEN = 'APP/SET_IS_MOBILE_MENU_HIDDEN';

export const SET_MORTGAGE_FORM_SOURCE = 'APP/SET_MORTGAGE_FORM_SOURCE';

export const SET_IS_MIZRAHI_BANK_MODAL_OPEN = 'APP/SET_IS_MIZRAHI_BANK_MODAL_OPEN';

export const SET_AGENT_OFFICE_CONTACT_POPUP_OPEN = 'APP/SET_AGENT_OFFICE_CONTACT_POPUP_OPEN';
export const SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN = 'APP/SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN';

export const SET_SEARCH_VIEW_MODE = 'APP/SET_SEARCH_VIEW_MODE';

export const SET_IS_LIKED_TOAST_OPEN = 'APP/SET_IS_LIKED_TOAST_OPEN';

export const CONTACT_AGENT_INPUT_CLICK = 'APP/CONTACT_AGENT_INPUT_CLICK';

export const SET_IS_HEADER_TAB_SUBMENU_VISIBLE = 'APP/SET_IS_HEADER_TAB_SUBMENU_VISIBLE';

export const SET_PUSH_NOTIFICATIONS_MODAL_TYPE = 'APP/SET_PUSH_NOTIFICATIONS_MODAL_TYPE';

export const SET_DEVICE_PUSH_STATUS = 'APP/SET_DEVICE_PUSH_STATUS';
export const SET_LISTING_EDITED = 'APP/SET_LISTING_EDITED';

export  const SET_DEAL_AFFILIATION_POPUP = 'APP/SET_DEAL_AFFILIATION_POPUP';
export  const SET_NEW_UNIT_PAGE_MAP_EXPANDED = 'APP/SET_NEW_UNIT_PAGE_MAP_EXPANDED';

export const SET_MOBILE_DISCOVERY_DISABLED = 'APP/SET_MOBILE_DISCOVERY_DISABLED';
export const SET_MOBILE_DISCOVERY_ENABLED = 'APP/SET_MOBILE_DISCOVERY_ENABLED';

export const SET_INTENT_MODAL_OPEN = 'APP/SET_INTENT_MODAL_OPEN';
export const SET_WELCOME_MODAL_OPEN = 'APP/SET_WELCOME_MODAL_OPEN';
