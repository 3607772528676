import React from 'react';
import Modal from 'ds/components/modal';
import { Button } from 'ds/components/button';
import { HiddenListingModalProps } from './index';
import { ButtonWrapper, ModalContentWrapper } from './styled';
import { useLocale } from 'locale';
import { DealType } from 'utils/entities';
import { getQAAutomationParams } from 'utils/qa';
import { homeRouteByMarketplace, searchRouteByMarketplace } from 'utils/marketplaceRoutes';
import { Link } from '../link';


const HiddenListingDirectLinkPopup: React.FC<HiddenListingModalProps> = ({ hiddenListingModalOpen, onClose, marketplace }) => {

  const { t } = useLocale();
  const { popups } = getQAAutomationParams();
  const docId = 'ישראל';

  const handleClose = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    e.stopPropagation();
    onClose(homeRouteByMarketplace(marketplace), { hiddenListingModal: undefined }, { replace: true });
  };

  if (!hiddenListingModalOpen || popups === 'disable') return null;

  return (
    <Modal
      mobileModalPositionBottom
      onClose={handleClose}
      isOpen
      title={t('hidden.listing.direct.title')}
      footerStyle={{ padding: 0 }}
      buttons={(
        <ButtonWrapper>
          <Link
            routeName={searchRouteByMarketplace(marketplace)}
            routeParams={{
              dealType: DealType.Buy,
              term: [ docId ],
              hiddenListingModal: undefined,
            }}
          >
            <Button data-auto="after-hidden-direct-link-button" fullWidth size="extraLarge" mode="primary">
              {t('hidden.listing.direct.button', { marketplace })}
            </Button>
          </Link>
        </ButtonWrapper>
      )}
    >
      <ModalContentWrapper>{t('hidden.listing.direct.text', { marketplace })}</ModalContentWrapper>
    </Modal>
  );
};

export default HiddenListingDirectLinkPopup;
