import { fork, select, call, put, takeEvery } from 'redux-saga/effects';
import {
  approvedAgentSelector,
  isAgentManagerSelector,
  agentsInOfficeSelector,
} from 'store/state/domainData/selectors';
import { queryData } from 'store/sagas/apiService';
import { LoadType, ICommercialAgentsInOffice } from 'store/sagas/apiService/types';
import { dealsAffiliationRequestsSelector } from 'store/state/domainData/selectors/getDealsAffiliationRequests';
import { waitForUserResolve } from 'store/sagas/routing/handlers/utils';
import { resetDomainData } from 'store/state/domainData/actions';
import { SEARCH_DEALS } from 'store/state/search-deals/types';
import { searchDeals } from 'store/state/search-deals/actions';
import { routeSelector } from 'store/state/selectors/router';
import { navigateTo } from 'store/state/router/actions';


function* fetchAgentDeals() {
  yield call(waitForUserResolve);
  const isApprovedAgent = yield select(approvedAgentSelector);
  const isAgentManager = yield select(isAgentManagerSelector);

  if (!isApprovedAgent && !isAgentManager) {
    yield put(resetDomainData({ loadType: LoadType.GetDealsAffiliationRequests }));
    return;
  }
  const { name, params } = yield select(routeSelector);
  const agentsInOffice: ICommercialAgentsInOffice[] = yield select(agentsInOfficeSelector);

  if (params.agentId !== undefined && (!isAgentManager || (isAgentManager && !agentsInOffice.find(agent => agent.agentId === params.agentId)))) {
    yield put(navigateTo(name, { ...params, agentId: undefined }, { replace: true, bypassScrollMiddleware: true }));
  }

  const data = yield select(dealsAffiliationRequestsSelector);

  if (data && data.length) return;

  yield fork(queryData, {
    loadType: LoadType.GetDealsAffiliationRequests,
    meta: {
      variables: {},
    },
  });
}

function* searchDealsWatcher(action: ReturnType<typeof searchDeals>) {
  const { payload } = action;

  yield call(queryData, {
    loadType: LoadType.SearchDeals,
    meta: {
      variables: payload,
    },
  });
}

export function* dealsPageHandler() {
  yield fork(fetchAgentDeals);
  yield takeEvery(SEARCH_DEALS, searchDealsWatcher);
}
