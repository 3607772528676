import { TrackJS } from 'trackjs';
import { setIsMizrahiBankModalOpen } from 'store/state/app/actions';
import { call, fork, select, put, takeLatest, getContext } from 'redux-saga/effects';
import {
  queryAdditionalAds,
  getCityDocIdFromDocument,
  fetchArticles,
  querySearchPageProjectsAds,
} from '../utils';
import { State as RouteState } from 'config/routes';
import { IABTestContext, RecommendedListingsABTest } from 'config/abTests';
import { searchPageBannersByDocIdVariablesSelector } from 'store/state/domainData/selectors/bannersByDocId';
import {
  CompletionType,
  DealType,
  IAutocompleteEntry,
  IBannersByDocIdVariables,
  IFooterByDocIdVariables,
  IMadadWinnersBannersByDocIdVariables,
  IMadadWinnersBannersForStreetByDocIdVariables,
  SearchContext,
  MarketplaceType,
} from 'utils/entities';
import { queryData } from 'store/sagas/apiService';
import { cityFooterVariablesSelector } from './selectors';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { searchDocEntriesSelector } from 'store/state/domainData/selectors';
import { prevRouteSelector, marketplaceByRouteName, marketplaceSelector } from 'store/state/selectors/router';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';
import { getDocId } from './utils';
import { LocalStorage } from 'utils/localStorage';
import { IS_MIZRAHI_BANK_MODAL_SHOWN } from 'consts/localStorageKeys';
import { createSelector } from 'reselect';
import { RootAction } from 'store/state';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { UserTokenStore } from 'helpers/userToken';


export const availableTypeToFetch = new Set([
  CompletionType.City,
  CompletionType.Neighbourhood,
  CompletionType.Address,
  CompletionType.Street,
]);

export function* checkIfMadadWinnerBannersExist() {
  const currentDocs: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
  const availableType = currentDocs.every(entry => availableTypeToFetch.has(entry.type));

  if (availableType && currentDocs.length) {
    const doc = currentDocs[0];
    if (doc.type === CompletionType.Street) {
      const docIds = doc.relevantNeighbourhoods.map(el => el.docId);
      const variables: IMadadWinnersBannersForStreetByDocIdVariables = { docIds };

      yield fork(queryData, {
        loadType: LoadType.MadadWinnersExistForStreetForDocId,
        meta: {
          variables,
        },
      });
    }
    else {
      const variables: IMadadWinnersBannersByDocIdVariables = { docId: getDocId(doc) };
      yield fork(queryData, {
        loadType: LoadType.MadadWinnersExistForDocId,
        meta: {
          variables,
        },
      });
    }
  }
}

function* marketplaceChanged(currentRouteState: RouteState) {
  const prevRouteState: RouteState = yield select(prevRouteSelector);
  if (prevRouteState && prevRouteState.name) {
    return marketplaceByRouteName(prevRouteState.name) !== marketplaceByRouteName(currentRouteState.name);
  }
  return true;
}
const currentSearchCitiesDocIdsSelector = createSelector([ searchDocEntriesSelector ], getCityDocIdFromDocument);

function* searchArticles() {
  const currentCityDocIds: string[] = yield select(currentSearchCitiesDocIdsSelector);
  const marketplaceType: MarketplaceType = yield select(marketplaceSelector);
  yield fork(fetchArticles, currentCityDocIds, marketplaceType === MarketplaceType.Commercial ? 'searchCommercial' : 'searchResidential');
}

const newSearchDoc = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.SearchList && Boolean(action.payload.data)
);

function* searchArticlesWatcher() {
  yield takeLatest(newSearchDoc, searchArticles);
}

function* loadFeaturedMortgageOffice() {
  const loadOpts: LoadOptions<LoadType.FeaturedMortgageOffice> = {
    loadType: LoadType.FeaturedMortgageOffice,
    meta: {
      variables: {},
    },
  };

  yield call(queryData, loadOpts);
}

export function* loadRecommendedListings() {
  const abTests: IABTestContext = yield select(abTestsStateSelector);
  if (abTests && abTests.recommendedListings !== RecommendedListingsABTest.modeB) {
    return;
  }

  const logger = yield getContext('logger');
  try {
    const tokenStore: UserTokenStore = yield getContext('tokenStore');
    const userToken = tokenStore.get();
    const loadOptions: LoadOptions<LoadType.GetRecommendedListings> = {
      loadType: LoadType.GetRecommendedListings,
      meta: {
        variables: {
          userToken,
        },
      },
    };
    yield call(queryData, loadOptions);
  }
  catch (e) {
    const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
    const message = `Error in loadRecommendedListings: ${errorMessage}`;
    // tslint:disable-next-line: no-console
    logger.error(message, e);
    TrackJS.track([ message, e ]);
  }
}

function* searchPageHandler(routeState: RouteState, adsContext: SearchContext) {
  if (routeState.params.term && routeState.params.term.length) {
    yield fork(loadFeaturedMortgageOffice);
    yield fork(searchArticlesWatcher);
    const variables: IBannersByDocIdVariables = yield select(searchPageBannersByDocIdVariablesSelector);
    const isMarketplaceChanged = yield call(marketplaceChanged, routeState);
    const docId = routeState.params.term[0];
    if (!variables || variables.docId !== docId || isMarketplaceChanged) {
      yield fork(querySearchPageProjectsAds, docId, adsContext);
      yield fork(queryAdditionalAds, docId, 'stickySearchResult');
    }

    const isBuyDealType = routeState.params.dealType === DealType.Buy;
    const isMizrahiBankUtmCampaign = routeState.params.utm_campaign === 'madlan_mizrachy';
    const isMizrahiBankModalShown = LocalStorage.get(IS_MIZRAHI_BANK_MODAL_SHOWN);

    if (!isMizrahiBankModalShown && isBuyDealType && isMizrahiBankUtmCampaign) yield put(setIsMizrahiBankModalOpen(true));

    const footerVariables: IFooterByDocIdVariables = yield select(cityFooterVariablesSelector);
    if (footerVariables) {
      const footerOpts: LoadOptions<LoadType.FooterByDocId> = {
        loadType: LoadType.FooterByDocId,
        meta: { variables: footerVariables },
      };

      yield fork(queryData, footerOpts);
    }
  }
}

export function* residentialSearchPageHandler(routeState: RouteState) {
  yield fork(searchPageHandler, routeState, 'marketplace');
  yield fork(loadRecommendedListings);
}

export function* commercialSearchPageHandler(routeState: RouteState) {
  const loadOptions: LoadOptions<LoadType.AreasWithCommercialInfoPage> = {
    loadType: LoadType.AreasWithCommercialInfoPage,
    meta: {
      variables: {},
    },
  };
  yield call(queryData, loadOptions);

  yield fork(searchPageHandler, routeState, 'commercialMarketplace');
}
