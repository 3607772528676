import React, { useContext } from 'react';
import Responsive, { MediaQueryMatchers, useMediaQuery } from 'react-responsive';
import { getMediaQuery } from 'ds/theme/mediaBreakpoints';

export type BreakpointValue = 1 | 2 | 3 | 4 | 5;

export interface SingleRangeProps {
  at: BreakpointValue;
}

export interface DefinedRangeProps  {
  from?: BreakpointValue;
  to?: BreakpointValue;
}

export interface PointerMode {
  isTouch?: boolean;
}

export type VisibilityProps = (SingleRangeProps | DefinedRangeProps) & PointerMode;

export const setPointer = (isTouch: boolean , defaultPointer: string): string => {
  if (isTouch === true) {
    return 'coarse';
  }
  if (isTouch === false) {
    return 'fine';
  }
  return defaultPointer;
};

export const fromBreakpoints = {
  1: '0px',
  2: '720px',
  3: '960px',
  4: '1280px',
  5: '1920px',
};

export const toBreakpoints = {
  1: '719px',
  2: '959px',
  3: '1279px',
  4: '1919px',
  5: '9999px', // Range5 doesn't have max width
};

export const getCurrentDesignRange = () => parseInt(
  Object.keys(fromBreakpoints).find((key) => {
    const min = fromBreakpoints[key];
    const max = toBreakpoints[key];
    return window.matchMedia(`(min-width: ${min}) and (max-width: ${max})`).matches;
  })
  , 10
) as BreakpointValue;

export const getMinMaxBreakpoints = (visibilityProps: VisibilityProps): any => {
  let minWidthBreakpoint: string = null;
  let maxWidthBreakpoint: string = null;

  if ('at' in visibilityProps && !('from' in visibilityProps || 'to' in visibilityProps)) {
    minWidthBreakpoint = fromBreakpoints[visibilityProps.at];
    maxWidthBreakpoint = toBreakpoints[visibilityProps.at];
  }
  else {
    if ('from' in visibilityProps && 'to' in visibilityProps) {
      minWidthBreakpoint = fromBreakpoints[visibilityProps.from];
      maxWidthBreakpoint = toBreakpoints[visibilityProps.to];
    }
    else if ('from' in visibilityProps && !('to' in visibilityProps)) {
      minWidthBreakpoint = fromBreakpoints[visibilityProps.from];
      maxWidthBreakpoint = toBreakpoints[5];
    }
    else if ('to' in visibilityProps && !('from' in visibilityProps)) {
      minWidthBreakpoint = fromBreakpoints[1];
      maxWidthBreakpoint = toBreakpoints[visibilityProps.to];
    }
  }

  return {
    minWidthBreakpoint,
    maxWidthBreakpoint,
    isTouch: visibilityProps.isTouch !== undefined ? visibilityProps.isTouch : null,
  };
};

interface WithRenderProp {
  children: ((matches: boolean) => React.ReactNode) | React.ReactNode;
}

export const ResponsiveContext = React.createContext<Partial<MediaQueryMatchers>>({});

export const ShowAt: React.FunctionComponent<VisibilityProps & WithRenderProp> = (props) => {
  const ctxProps = useContext(ResponsiveContext);
  const { minWidthBreakpoint, maxWidthBreakpoint, isTouch } = getMinMaxBreakpoints(props);
  const query = getMediaQuery(minWidthBreakpoint, maxWidthBreakpoint, isTouch);

  return (
    <Responsive
      query={query}
      device={ctxProps}
      {...props}
    />
  );
};

export const useScreenBreakpoint = (): BreakpointValue => {
  const ctx = useContext(ResponsiveContext);
  const is1 = useMediaQuery({ minWidth: fromBreakpoints['1'], maxWidth: toBreakpoints['1'] }, ctx);
  const is2 = useMediaQuery({ minWidth: fromBreakpoints['2'], maxWidth: toBreakpoints['2'] }, ctx);
  const is3 = useMediaQuery({ minWidth: fromBreakpoints['3'], maxWidth: toBreakpoints['3'] }, ctx);
  const is4 = useMediaQuery({ minWidth: fromBreakpoints['4'], maxWidth: toBreakpoints['4'] }, ctx);

  if (is1) return 1;
  if (is2) return 2;
  if (is3) return 3;
  if (is4) return 4;
  return 5;
};


export const ShowBreakpoint: React.FC<{ children: (breakPoint: BreakpointValue) => JSX.Element; }> = ({ children }) => {
  const breakPoint = useScreenBreakpoint();

  return children(breakPoint);
};
