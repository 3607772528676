import React from 'react';
import { OVERLAY_ROOT_ID, REGULAR_ROOT_ID } from 'consts/rootNodes';
import { css } from 'emotion';
import { withTheme } from 'emotion-theming';
import { Theme } from 'ds';
import { createPortal } from 'utils/universalPortal';
import { isServer } from 'utils';



const getRootNode = () => document.getElementById(REGULAR_ROOT_ID);

type XYValue = [ number, number ];


const nonScrollBodyClassName = ([ x, y ]: XYValue) => css`
  position: fixed;
  width: 100%;
  z-index: -1;
  left: ${-x}px;
  top: ${-y}px;
`;

let scrollKeeper: XYValue = [ 0, 0 ];
/**
 * keep count of mounted overlays in order to not apply classes if there are currently
 * instances mounted inside the overlay
 */
let mountedCount = 0;

class BaseOverlayWrapper extends React.Component<{ theme: Theme, innerRef?: React.Ref<HTMLDivElement>, portalId?: string }, never> {
  public static defaultProps = {
    portalId: OVERLAY_ROOT_ID,
  };

  public componentDidMount() {
    if (mountedCount === 0) {
      this.fixBody();
    }
    mountedCount++;
  }

  public componentWillUnmount() {
    mountedCount--;
    if (mountedCount === 0) {
      this.unfixBody();
    }
  }

  private fixBody() {
    scrollKeeper = [ window.scrollX, window.scrollY ];
    const className = nonScrollBodyClassName(scrollKeeper);

    getRootNode().classList.add(className);
    window.scrollTo(0, 0);
  }

  public unfixBody() {
    const className = nonScrollBodyClassName(scrollKeeper);

    getRootNode().classList.remove(className);
    window.scrollTo(...scrollKeeper);
  }

  public render() {
    const { children, theme, innerRef, portalId, ...props } = this.props;

    return (
      <>
        {isServer() ? (
          <style>
            {`
              #${REGULAR_ROOT_ID} {
                position: fixed;
                width: 100%;
                z-index: -1;
                left: 0;
                top: 0;
              }
            `}
          </style>
        ) : null}
        {createPortal(
          <div data-auto="overlayRoot" {...props} ref={innerRef} style={{ direction: theme.isRTL ? 'rtl' : 'ltr' }}>{children}</div>,
          portalId
        )}
      </>
    );
  }
}


export const OverlayWrapper = withTheme(BaseOverlayWrapper);
