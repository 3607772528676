import { uniqBy } from 'lodash';
import { LoadType, MutationType, UserNotificationDevice } from 'store/sagas/apiService/types';
import { SET_MUTATION_RESPONSE, MUTATE } from 'store/state/mutationsResponse/types';
import { DeviceType } from 'utils/entities';
import { Domain } from '.';
import { domainInitialState } from './utils';
import { MutationsResponseAction } from '../mutationsResponse';


function mergeDevices(state: Domain<LoadType.UserNotificationDevices>, newDevices: UserNotificationDevice[]) {
  return {
    ...state,
    data: {
      ...state.data,
      getNotificationDevices: uniqBy(newDevices, (d) => d.deviceId),
    },
  };
}

function addNotificationDevice(state: Domain<LoadType.UserNotificationDevices>, deviceId: string, deviceType: DeviceType) {
  return mergeDevices(state, [
    ...(state.data ? state.data.getNotificationDevices : []),
    { deviceId, deviceType },
  ]);
}

function removeNotificationDevice(state: Domain<LoadType.UserNotificationDevices>, deviceId: string) {
  return mergeDevices(state, (state.data ? state.data.getNotificationDevices : []).filter((d) => d.deviceId !== deviceId));
}

export function userNotificationDevicesReducer(state: Domain<LoadType.UserNotificationDevices> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.UserNotificationDevices> {
  switch (action.type) {
    case MUTATE: {
      switch (action.mutationType) {
        case MutationType.AddNotificationDevice: {
          const { deviceToken, deviceType } = action.meta.variables;
          return addNotificationDevice(state, deviceToken, deviceType);
        }
        case MutationType.RemoveNotificationDevice: {
          return removeNotificationDevice(state, action.meta.variables.deviceToken);
        }
      }
    }
    case SET_MUTATION_RESPONSE: {
      switch (action.mutationType) {
        case MutationType.AddNotificationDevice: {
          // @todo remove
          return state;
          // const { deviceId, deviceType } = action.meta.response.data.addNotificationDevice;
          // return addNotificationDevice(state, deviceId, deviceType);
        }
        case MutationType.RemoveNotificationDevice: {
          return removeNotificationDevice(state, action.meta.response.data.removeNotificationDevice.deviceId);
        }
      }
    }
  }

  return state;
}
