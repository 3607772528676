import { upperCase, invert, trim, memoize, isNil } from 'lodash';
import {
  Amenity,
  AvailabilityType,
  BuildingClass,
  BulletinPoiType,
  BulletinStatusType,
  DealType,
  IAddressDetails,
  IAutocompleteAddress,
  IBulletin,
  IBulletinInput,
  ICommercialBulletin,
  IGeneralCondition,
  PocType,
  PoiId,
  ResolutionPreference,
} from './entities';
import { removeTypeName } from './index';
import {
  buildingAmenities as buildingAmenitiesConfig,
  unitAmenities as unitAmenitiesConfig,
} from 'components/unit-page/amenities/config';
import { DraftState } from 'screens/UploadBulletinPage/types';
import { PropertyType, QualityClassOption } from 'components/filters/types';
import { COMMERCIAL_AMENITIES } from 'screens/UploadBulletinPage/steps/AdditionalInfoStep/constants';

export const hideNumberOfEmployees = (val: PropertyType): boolean => {
  const buildingTypes = new Set([
    PropertyType.Office,
    PropertyType.SharedWorkSpace,
    PropertyType.Studio,
  ]);

  return !buildingTypes.has(val);
};

export const hideRoomsCount = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.Hotel,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
    PropertyType.Storeroom,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.Hotel,
    PropertyType.Restaurant,
    PropertyType.IndustrialBuilding,
    PropertyType.SharedWorkSpace,
    PropertyType.Studio,
    PropertyType.ParkingLot,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

export const hideUnitFloor = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
    PropertyType.Hotel,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.Hotel,
    PropertyType.Restaurant,
    PropertyType.IndustrialBuilding,
    PropertyType.ParkingLot,
    PropertyType.IncomeProducingProperty,
    PropertyType.Basement,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

export const hideTotalFloors = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Restaurant,
    PropertyType.ParkingLot,
    PropertyType.Basement,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

interface IUploadBulletinCommonFormValues {
  type: BulletinPoiType;
  id?: PoiId;
  area: string;
  addressDetails: IAutocompleteAddress;
  price: string;
  availabilityDateMonth: number;
  availabilityDateYear: number;
  availabilityType: AvailabilityType;
  buildingClass: PropertyType;
  buildingYear?: number;
  parcel?: number;
  block?: number;
  dealType: DealType;
  description?: string;
  floor: number;
  floors: number;
  furniture?: string;
  generalCondition: IGeneralCondition;
  images: string[];
  monthlyTaxes: string;
  amenities: Amenity[];
  sellerType?: PocType;
  userName: string;
  userPhone: string;
  status: {
    message: string;
    status: BulletinStatusType;
    promoted: boolean;
  };
  resolutionPreferences?: ResolutionPreference;
}


interface IUploadResidentialFormValues extends IUploadBulletinCommonFormValues {
  type: 'bulletin';
  beds: number;
  commonCharges: string;
  balconyArea: string;
  withHalfRoom: boolean;
}

interface IUploadCommercialFormValues extends IUploadBulletinCommonFormValues {
  type: 'commercialBulletin';
  rooms: string;
  numberOfEmployees: string;
  qualityClass: QualityClassOption;
  assignedAgentUserId?: string;
  monthlyManagementFee?: string;
}

export type IUploadBulletinFormValues = IUploadResidentialFormValues | IUploadCommercialFormValues;

export const buildingClassToPropertyTypeMap: Partial<Record<BuildingClass, PropertyType>> = {
  [BuildingClass.GardenApartment]: PropertyType.GardenApartment,
  [BuildingClass.DualCottage]: PropertyType.TwoFamilyDwelling,
  [BuildingClass.PenthouseApp]: PropertyType.PenthouseApp,
  [BuildingClass.RoofFlat]: PropertyType.RoofFlat,
  [BuildingClass.MiniPenthouse]: PropertyType.MiniPenthouse,
  [BuildingClass.ResidentialUnit]: PropertyType.ResidentialUnit,
  [BuildingClass.SerialCottage]: PropertyType.SerialCottage,
  [BuildingClass.AgricultureFarm]: PropertyType.AgricultureFarm,
  [BuildingClass.SplitFlat]: PropertyType.SplitFlat,
  [BuildingClass.AgricultureLand]: PropertyType.AgricultureLand,
  [BuildingClass.VacationProperty]: PropertyType.VacationProperty,
  [BuildingClass.BuyerTeam]: PropertyType.BuyerTeam,
  [BuildingClass.ProfessionalFarm]: PropertyType.ProfessionalFarm,
  [BuildingClass.FarmLand]: PropertyType.FarmLand,
  [BuildingClass.SharedWorkSpace]: PropertyType.SharedWorkSpace,
  [BuildingClass.ParkingLot]: PropertyType.ParkingLot,
  [BuildingClass.IndustrialBuilding]: PropertyType.IndustrialBuilding,
  [BuildingClass.IncomeProducingProperty]: PropertyType.IncomeProducingProperty,
};

export const propertyTypeToBuildingClassMap = invert(buildingClassToPropertyTypeMap);

export const getBuildingClassFromPropertyType = memoize((val: PropertyType): BuildingClass =>
  (propertyTypeToBuildingClassMap[val] || val) as BuildingClass);

interface EditICommercialBulletin extends ICommercialBulletin {
  originalAddress?: IAddressDetails;
}

export const bulletinToFormValues = (bulletin: IBulletin | ICommercialBulletin | EditICommercialBulletin): DraftState => {
  const {
    type,
    addressDetails,
    amenities,
    area,
    availabilityType,
    availableDate,
    buildingClass,
    dealType,
    description = '',
    floor,
    floors,
    generalCondition,
    images,
    monthlyTaxes,
    locationPoint,
    price,
    buildingYear,
    furnitureDetails,
    status,
    id,
    poc,
    userPhoneNumberEdit,
  } = bulletin;

  const isCommercial = type === 'commercialBulletin';

  let parsedUnitAmenities: Amenity[] = [];
  const isAgent = poc.type === PocType.Agent;
  const beds = 'beds' in bulletin && bulletin.beds || null;
  const rooms = 'rooms' in bulletin && bulletin.rooms || null;
  const commonCharges = 'commonCharges' in bulletin && bulletin.commonCharges || null;
  const numberOfEmployees = 'numberOfEmployees' in bulletin && bulletin.numberOfEmployees || null;
  const qualityClass = 'qualityClass' in bulletin && bulletin.qualityClass || null;
  const address = 'originalAddress' in bulletin && bulletin.originalAddress || addressDetails;
  const assignedAgentUserId = isCommercial && isAgent ? poc.userId : '';
  const parsedNumberOfEmployees = numberOfEmployees ? numberOfEmployees.toString() : '';
  const managementFee = 'monthlyManagementFee' in bulletin && bulletin.monthlyManagementFee || null;

  if (amenities) {
    parsedUnitAmenities = Object.entries(removeTypeName(amenities)).reduce((acc, [ amenity, isExist ]) => {
      if (isExist) acc.push(amenity);
      return acc;
    }, []);
  }

  let parsedArea;
  if (area) {
    parsedArea = area.toString();
  }

  let parsedBeds;
  let withHalfRoom;
  if (beds) {
    if (beds - Math.floor(beds) === 0.5) {
      withHalfRoom = true;
      parsedBeds = beds - 0.5;
    }
    else {
      parsedBeds = beds;
    }
  }

  let parsedPrice;
  if (price) {
    parsedPrice = price.toString();
  }

  let parsedMonthlyTaxes;
  if (monthlyTaxes) {
    parsedMonthlyTaxes = monthlyTaxes.toString();
  }

  let parsedCommonCharges;
  if (commonCharges) {
    parsedCommonCharges = commonCharges.toString();
  }

  const monthlyManagementFee = managementFee ? managementFee.toString() : null;


  let parsedImages;
  if (images && images.length) {
    parsedImages = images.map(image => image.imageUrl);
  }

  let availabilityDateYear;
  let availabilityDateMonth;

  const parsedAvailabilityType = upperCase(availabilityType) as AvailabilityType;
  if (parsedAvailabilityType === AvailabilityType.Date && availableDate) {
    const parsedDate = new Date(availableDate);
    availabilityDateYear = parsedDate.getFullYear();
    availabilityDateMonth = parsedDate.getMonth();
  }
  else {
    availabilityDateYear = (new Date).getFullYear();
    availabilityDateMonth = (new Date).getMonth();
  }

  const parsedRooms = rooms ? rooms.toString() : undefined;

  const parcel = address.parcel || undefined;
  const block = address.block || undefined;
  const resolutionPreferences = addressDetails.resolutionPreferences;

  const parsedBuildingClass: PropertyType = (buildingClassToPropertyTypeMap[buildingClass] || buildingClass) as PropertyType;

  const userPhone = userPhoneNumberEdit || (isAgent
    ? ('displayNumber' in poc ? poc.displayNumber : '')
    : ('contactInfo' in poc && poc.contactInfo && poc.contactInfo.phone || poc.displayNumber || ''));

  const userName = isAgent
    ? ('agentContact' in poc ? poc.agentContact.name : '')
    : ('contactInfo' in poc && poc.contactInfo && poc.contactInfo.name || '');

  if (isCommercial) {
    return {
      formValues: {
        type,
        sellerType: poc.type,
        addressDetails: {
          location: [ locationPoint.lng, locationPoint.lat ],
          docId: address.docId,
          name: `${address.streetName} ${address.streetNumber}, ${address.city}`,
        } as IAutocompleteAddress,
        resolutionPreferences,
        ...(assignedAgentUserId ? ({ assignedAgentUserId }) : null),
        amenities: parsedUnitAmenities,
        area: parsedArea,
        availabilityDateMonth,
        availabilityDateYear,
        qualityClass,
        numberOfEmployees: parsedNumberOfEmployees,
        availabilityType: parsedAvailabilityType,
        rooms: parsedRooms,
        buildingClass: parsedBuildingClass,
        commonCharges: parsedCommonCharges,
        dealType,
        description: description || '',
        floor: +floor,
        floors,
        generalCondition,
        images: parsedImages,
        monthlyTaxes: parsedMonthlyTaxes,
        price: parsedPrice,
        parcel,
        block,
        buildingYear: buildingYear || undefined,
        furniture: furnitureDetails || null,
        status,
        userName,
        userPhone,
        ...(monthlyManagementFee ? ({ monthlyManagementFee }) : null),
        ...(id ? ({ id }) : null),
      },
    };
  }

  return {
    formValues: {
      type,
      sellerType: poc.type,
      addressDetails: {
        location: [ locationPoint.lng, locationPoint.lat ],
        docId: addressDetails.docId,
        name: `${addressDetails.streetName} ${addressDetails.streetNumber}, ${addressDetails.city}`,
      } as IAutocompleteAddress,
      amenities: parsedUnitAmenities,
      area: parsedArea,
      availabilityDateMonth,
      availabilityDateYear,
      availabilityType: parsedAvailabilityType,
      beds: parsedBeds,
      buildingClass: parsedBuildingClass,
      commonCharges: parsedCommonCharges,
      dealType,
      description: description || '',
      floor: +floor,
      floors,
      generalCondition,
      images: parsedImages,
      monthlyTaxes: parsedMonthlyTaxes,
      price: parsedPrice,
      withHalfRoom,
      parcel,
      block,
      buildingYear: buildingYear || undefined,
      furniture: furnitureDetails || null,
      status,
      userName,
      userPhone,
      ...(id ? ({ id }) : null),
    },
  };
};

const isFloorSelected = (floor: number) => !!floor || floor === 0;

export const uploadFormValues2UploadInput = (formValues: Partial<IUploadBulletinFormValues>): IBulletinInput => {
  const {
    type,
    addressDetails,
    area,
    availabilityDateMonth,
    availabilityDateYear,
    availabilityType,
    buildingClass,
    buildingYear,
    parcel,
    block,
    dealType,
    description,
    sellerType,
    floor,
    floors,
    furniture,
    generalCondition,
    images,
    monthlyTaxes,
    price,
    amenities,
    userName,
    userPhone,
    id,
  } = formValues;

  const isCommercial = type === 'commercialBulletin';
  const isRent = dealType === DealType.Rent;

  const monthlyManagementFee = isRent && isCommercial && 'monthlyManagementFee' in formValues ? +formValues.monthlyManagementFee : null;
  const beds = formValues.type === 'bulletin' && formValues.beds;
  const rooms = formValues.type === 'commercialBulletin' && formValues.rooms;
  const commonCharges = formValues.type === 'bulletin' ? formValues.commonCharges : null;
  const withHalfRoom = formValues.type === 'bulletin' ? formValues.withHalfRoom : null;
  const numberOfEmployees = formValues.type === 'commercialBulletin' && formValues.numberOfEmployees || null;
  const qualityClass = formValues.type === 'commercialBulletin' && formValues.qualityClass || null;
  const resolutionPreferences = 'resolutionPreferences' in formValues && formValues.resolutionPreferences || null;
  const assignedAgentUserId = 'assignedAgentUserId' in formValues && formValues.assignedAgentUserId || null;


  const selectedAmenities = [ ...(amenities || []) ];
  const parsedAmenities = (isCommercial ? COMMERCIAL_AMENITIES : [ ...unitAmenitiesConfig, ...buildingAmenitiesConfig ])
    .reduce<Record<Amenity, boolean>>((acc, amenity) => {
      return ({
        ...acc,
        [amenity]: selectedAmenities.includes(amenity),
      });
    }, {} as Record<Amenity, boolean>);

  let parsedArea;
  if (area) {
    parsedArea = parseInt(area, 10);
  }

  let parsedNumberOfEmployees;
  if (numberOfEmployees && !hideNumberOfEmployees(buildingClass)) {
    parsedNumberOfEmployees = parseInt(numberOfEmployees, 10);
  }

  let availableDate;
  if (availabilityType === AvailabilityType.Date && availabilityDateYear && !isNil(availabilityDateMonth)) {
    availableDate = (new Date(availabilityDateYear, availabilityDateMonth)).toISOString();
  }

  let parsedBeds;
  if (beds && withHalfRoom) {
    parsedBeds = beds + 0.5;
  }
  else {
    parsedBeds = beds;
  }
  const parsedFloor = isFloorSelected(floor) && !hideUnitFloor(buildingClass, isCommercial) ? floor : undefined;
  const parsedFloors = isFloorSelected(floors) && !hideTotalFloors(buildingClass, isCommercial) ? floors : undefined;
  const parsedRooms = rooms && !hideRoomsCount(buildingClass, isCommercial) ? parseInt(rooms, 10) : undefined;

  let parsedPrice;
  if (price) {
    parsedPrice = parseInt(price, 10);
  }

  let parsedMonthlyTaxes;
  if (monthlyTaxes) {
    parsedMonthlyTaxes = parseInt(monthlyTaxes, 10);
  }

  let parsedCommonCharges;
  if (commonCharges) {
    parsedCommonCharges = parseInt(commonCharges, 10);
  }

  let parsedImages;
  if (images) {
    parsedImages = images.map((imageUrl: string) => ({ imageUrl, isFloorplan: false, description: undefined }));
  }

  const parsedDescription = description && trim(description) || null;

  if (isCommercial) {
    return {
      ...(resolutionPreferences ? ({ resolutionPreferences }) : null),
      ...(assignedAgentUserId ? ({ assignedAgentUserId }) : null),
      ...(monthlyManagementFee ? ({ monthlyManagementFee }) : null),
      type,
      docId: addressDetails.docId,
      price: parsedPrice,
      images: parsedImages || [],
      amenities: parsedAmenities,
      rooms: parsedRooms,
      area: parsedArea,
      floor: parsedFloor,
      floors: parsedFloors,
      dealType,
      propertyType: buildingClass,  // PropertyType!
      generalCondition,
      availableDate,
      availabilityType,
      furniture,
      description: parsedDescription,
      sellerType,
      numberOfEmployees: parsedNumberOfEmployees,
      qualityClass,
      monthlyTax: parsedMonthlyTaxes,
      buildingYear: buildingYear ? +buildingYear : null,
      parcel: parcel ? +parcel : 0,
      block: block ? +block : 0,
      userName: userName ? userName : 'null', // String! todo: should be removed from here after API update
      userPhone: userPhone ? userPhone : 'null',  // String! todo: should be removed from here after API update
      ...(id ? ({ id }) : null),
    };
  }

  return {
    type,
    docId: addressDetails.docId,
    price: parsedPrice,
    images: parsedImages || [],
    amenities: parsedAmenities,
    beds: parsedBeds,
    area: parsedArea,
    floor: parsedFloor,
    floors: parsedFloors,
    dealType,
    propertyType: buildingClass,  // PropertyType!
    generalCondition,
    availableDate,
    availabilityType,
    furniture,
    description: parsedDescription,
    sellerType,
    monthlyTax: parsedMonthlyTaxes,
    commonCharges: parsedCommonCharges,
    buildingYear: buildingYear ? +buildingYear : null,
    parcel: parcel ? +parcel : 0,
    block: block ? +block : 0,
    userName: userName ? userName : 'null', // String! todo: should be removed from here after API update
    userPhone: userPhone ? userPhone : 'null',  // String! todo: should be removed from here after API update
    ...(id ? ({ id }) : null),
  };
};
