import styled from '@emotion/styled';
import React from 'react';
import { H3 } from 'ds/components/typography';
import { AmenityType } from 'components/unit-page/amenities/types';
import { IconAmenity } from './blocks/IconAmenity';


export const Section = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;
export const SectionHeader = styled(H3)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const CellsIcon = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    margin: ${theme.spacing(2)} 0 0;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    border: 1px solid ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.small};
    overflow: hidden;
  `}
`;

export const CellIcon = styled.div`
  ${({ theme }) => `
    width: 50%;
    padding: 0 0 ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding-top: ${theme.spacing(3)};

    &:not(:nth-of-type(-n+2)) {
      border-top: 1px solid ${theme.colors.neutrals.grey8};
    }

    &:nth-of-type(odd) {
      border-right: 1px solid ${theme.colors.neutrals.grey8};
    }
  `}
`;

export interface IconAmenitiesProps {
  amenitiesTypes: AmenityType[];
}

const IconAmenities: React.FC<IconAmenitiesProps> = ({ amenitiesTypes }) => {
  return (
    <Section>
      <CellsIcon>
        {amenitiesTypes.map((props) => (
          <CellIcon key={props.title}>
            <IconAmenity {...props} />
          </CellIcon>
        ))}
      </CellsIcon>
    </Section>
  );
};

export default IconAmenities;
