export function crossAtob(str: string) {
  try {
    return atob(str);
  }
  catch (err) {
    return Buffer.from(str, 'base64').toString('utf8');
  }
}

export function crossBtoa(str: string) {
  try {
    return btoa(str);
  }
  catch (err) {
    return Buffer.from(str).toString('base64');
  }
}
