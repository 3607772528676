import { connect } from 'react-redux';
import { State } from 'store/state';
import { marketplaceSelector, routeNameSelector } from 'store/state/selectors/router';
import {
  searchDocEntriesSelector,
  searchDocLoadingSelector, searchInfoEntriesSelector,
} from 'store/state/domainData/selectors';
import { SubheaderLocalData } from 'components/local-data/subheader-local-data/SubheaderLocalData';
import { searchInfoEntriesByMarketplaceSelector } from 'store/state/selectors/search';
import { createSelector } from 'reselect';
import { areasWithCommercialInfoPageSelector } from 'store/state/domainData/selectors/areasWithCommercialInfoPage';
import { CompletionType, MarketplaceType } from 'utils/entities';
import { getRouteConfig } from 'components/local-data/LocalData';
import { intersection } from 'lodash';

export const shouldDisplayLocalDataSelector = createSelector([
  searchInfoEntriesSelector,
  searchDocEntriesSelector,
  searchDocLoadingSelector,
  marketplaceSelector,
  routeNameSelector,
  areasWithCommercialInfoPageSelector,
], (localData, resolvedAddresses, loading, marketplace, routeName, areasWithCommercialInfoPage): boolean => {
  let filteredLocalData;
  if (!loading && localData) {
    const isResidential = marketplace === MarketplaceType.Residential;
    filteredLocalData = isResidential
      ? localData.filter((item) => item.document.type !== CompletionType.EmploymentArea)
      : localData.filter((item) => item.document.type === CompletionType.EmploymentArea);
  }

  if (loading || !filteredLocalData || filteredLocalData.length === 0) return false;

  const isSingleLocation = filteredLocalData.length === 1;
  const areasWithCommercialInfoPageDocIds = areasWithCommercialInfoPage ? areasWithCommercialInfoPage.map(area => area.docId) : [];

  if (isSingleLocation) {
    const resolvedAddress = filteredLocalData && filteredLocalData[0].document;
    const { route, routeParams } = getRouteConfig(resolvedAddress, routeName);

    if (!route || !routeParams) return false;

    if (resolvedAddress && resolvedAddress.type === CompletionType.EmploymentArea) {
      const shouldDisplayLocalData = areasWithCommercialInfoPageDocIds.findIndex(id => id === resolvedAddress.docId) !== -1;
      if (!shouldDisplayLocalData) return false;
    }
  }
  else {
    const shouldRender = filteredLocalData
      .map((entry) => getRouteConfig(entry.document, routeName))
      .some((cfg) => cfg.route && cfg.routeParams);

    if (!shouldRender) return false;

    if (resolvedAddresses && resolvedAddresses.every(a => a.type === CompletionType.EmploymentArea)) {
      const resolvedAddressesDocIds = resolvedAddresses.map(address => address.docId);
      const shouldDisplayLocalData = intersection(areasWithCommercialInfoPageDocIds, resolvedAddressesDocIds).length > 0;
      if (!shouldDisplayLocalData) return false;
    }
  }

  return true;
});

const mapStateToProps = (state: State) => ({
  localData: searchInfoEntriesByMarketplaceSelector(state),
  routeName: routeNameSelector(state),
  loading: searchDocLoadingSelector(state),
  shouldDisplay: shouldDisplayLocalDataSelector(state),
});

export default connect(mapStateToProps)(SubheaderLocalData);
