import React from 'react';
import { useLocale } from 'locale';
import { FeedbackPopupView as FeedbackModal, ISubmitPopupData } from 'components/common-feedback-popup-view/feedback-popup-view';
import { FeedbackPopupType as FeedbackType, FeedbackPopupOptionsValue  } from 'components/common-feedback-popup-view/utils';

export const OPTIONS_VALUES = [
  FeedbackPopupOptionsValue.IncorrectInfo,
  FeedbackPopupOptionsValue.Fraud,
  FeedbackPopupOptionsValue.Other,
];

interface UgcFeedbackModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (data: ISubmitPopupData) => void;
}

export const UgcFeedbackModal: React.FC<UgcFeedbackModalProps> = props => {
  const { isOpen, handleClose, handleSubmit } = props;
  const { t } = useLocale();

  return (
    <FeedbackModal
      isPopupModalOpen={isOpen}
      options={OPTIONS_VALUES}
      feedbackType={FeedbackType.UGC}
      popupTitle={t('ugc.feedbackModal.title')}
      submitButtonText={t('ugc.feedbackModal.submitButton')}
      onClosePopup={handleClose}
      onSubmit={handleSubmit}
    />
  );
};
