import { MapOwnProps } from './types';
import { State as RouteState, Route } from 'config/routes';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { mergePadding } from './utils';
import { MapSnapshotBounds } from 'components/static-map/MapSnapshot';
import { mapViewBboxSelector } from 'store/state/selectors/search';
import { State } from 'store/state';
import { connect } from 'react-redux';
import { SafeSuspense } from 'utils/SafeSuspense';
import { BoundingBox, ContentWidthMode } from 'utils/entities';
import { withLocale } from 'locale';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { activePoiIdsSelector } from 'store/state/searchContext/selectors';
import { Loader } from 'components/loader/MapLoader';
import { createSelector } from 'reselect';
import { flow } from 'lodash';
import { activeSectionSelector } from 'store/state/insightsContext/selectors';
import { Section } from 'components/navigation/subheader/SectionsNavigation';
import { AbsoluteFiller } from 'ds/components/Layout';
import { requestIdleCallback } from 'utils/requestIdleCallback';
import { FitBoundsOptions } from 'mapbox-gl';
import { ROUTES_WITH_MAP, ROUTES_WITH_SUBHEADER } from 'screens/Main/types';

import { routeSelector, isMobileItemMapActiveSelector } from 'store/state/selectors/router';
import { insightsMobileMapTypeSelector } from 'store/state/insightsContext/selectors';
import { isMobileSearchMapViewActiveSelector, isNewUnitPageMapExpandedSelector } from 'store/state/app/selectors';
import { desktopContentWidthModeSelector } from 'store/state/selectors/search';
import { isSearchRoute } from 'utils/marketplaceRoutes';

const MapView = React.lazy(() => import('./ConnectedMap'));


interface DeferredMapProps extends MapOwnProps {
  route: RouteState;
  bounds: BoundingBox;
  hasInteraction: boolean;
}

const easing = (t: number) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

const defaultFitBoundsOptions: FitBoundsOptions = {
  padding: 6,
  maxZoom: 17.3,
  duration: 2000,
  linear: false,
  easing,
};


const PADDING_TOP_HEIGHT = 64;

interface DeferredMapProps extends MapOwnProps {
  route: RouteState;
  bounds: BoundingBox;
  hasInteraction: boolean;
  isSubheaderPresent: boolean;
}

const BREAKPOINT_TO_BASE = {
  1: 300,
  2: 400,
  3: 600,
  4: 840,
  5: 1000,
};

function resolveMouseMove() {
  return new Promise((resolve) => {
    const listener = () => {
      requestIdleCallback(resolve);
      window.removeEventListener('mousemove', listener);
    };

    window.addEventListener('mousemove', listener, { passive: true });

  });
}

const DeferredMap: React.FC<DeferredMapProps> = (props) => {
  const {
    isCoveredFromTop,
    bounds,
    route,
    hasInteraction,
  } = props;

  const bbox = bounds;

  const isReady = Boolean(bbox);

  const [ isMounted, setIsMounted ] = useState(false);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ showLoader, showShowLoader ] = useState(false);
  const breakpoint = useScreenBreakpoint();

  useEffect(() => {
    if (hasInteraction && !isMounted) {
      setIsMounted(true);
      showShowLoader(true);
    }
  }, [ hasInteraction, isMounted ]);

  useEffect(() => {
    if (ROUTES_WITH_MAP.has(route.name) && !isMounted) {
      resolveMouseMove().then(() => {
        setIsMounted(true);
        showShowLoader(true);
      });
    }
  }, [ route.name, isMounted ]);

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        setIsMounted(true);
      }, 10000);
    }
  }, [ isReady ]);

  const fitBoundsOptions = useMemo(() => {

    const opts: FitBoundsOptions = {
      ...defaultFitBoundsOptions,
      pitch: 0,
    };

    opts.padding = mergePadding(opts.padding, isCoveredFromTop ? { top: PADDING_TOP_HEIGHT } : {});

    return opts;

  }, [ isCoveredFromTop ]);

  const onMapLoad = useCallback(() => setIsLoaded(true), [ setIsLoaded ]);

  let loader: React.ReactElement = null;

  if (isReady) {
    loader = (
      <AbsoluteFiller style={{ overflow: 'hidden' }}>
        <MapSnapshotBounds
          bounds={bbox}
          base={BREAKPOINT_TO_BASE[breakpoint]}
          padding={fitBoundsOptions.padding}
        />
        {showLoader ? <Loader /> : null}
      </AbsoluteFiller>
    );
  }

  return (
    <>
      {isMounted ? (
        <div style={{ opacity: isLoaded ? 1 : 0 }}>
          <link href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.9.1/mapbox-gl.css" rel="stylesheet" />
          <SafeSuspense>
            <MapView {...props} onLoad={onMapLoad} fitBoundsOptions={fitBoundsOptions} />
          </SafeSuspense>
        </div>
      ) : null}
      {isLoaded ? null : loader}
    </>
  );
};

const hasInteractionSelector = createSelector([
  routeSelector,
  flow(activePoiIdsSelector, a => a.size > 0),
  activeSectionSelector,
  flow(routeSelector, r => r.meta.id > 1),
], (route, hasActivePoi, activeSection, hasPrevRoute) => {
  switch (route.name) {
    case Route.Search:
    case Route.SearchCommercial:
      return hasActivePoi || hasPrevRoute;
    case Route.UnitPage:
    case Route.UnitPageCommercial:
    case Route.ProjectPage:
    case Route.ProjectPageCommercial:
    case Route.LocalPage:
    case Route.StreetPage:
    case Route.Sold:
      return hasPrevRoute || (activeSection && activeSection !== Section.OVERVIEW_HEADER);
    case Route.CheckAddress:
      return hasPrevRoute;
    case Route.Home:
      return false;
  }

  return true;

});

const isMobileInsightsMapViewSelector = flow(insightsMobileMapTypeSelector, Boolean);
const isSubheaderPresentSelector = createSelector([ routeSelector ], (route) => ROUTES_WITH_SUBHEADER.has(route.name));
const isFrozenSelector = createSelector([
  routeSelector,
  isMobileSearchMapViewActiveSelector,
  isMobileItemMapActiveSelector,
  isNewUnitPageMapExpandedSelector,
  isMobileInsightsMapViewSelector,
  (_: State, isMobile: boolean) => isMobile,
], (route, isMobileSearchMapViewActive, isMobileMapViewActive, isNewUnitPageMapExpanded, isMobileInsightsMap, isMobile) => {
  const isSearch = isSearchRoute(route.name);
  const isMobileSearchMap = isMobileSearchMapViewActive && isMobile && isSearch;
  const isMobileUnitMap = (isMobileMapViewActive || isNewUnitPageMapExpanded) && isMobile;
  const isMobileMapSlidedInView = isMobileInsightsMap || isMobileSearchMap || isMobileUnitMap;
  return isMobile && !isMobileMapSlidedInView;
});

const contentWidthModeSelector = createSelector([
  desktopContentWidthModeSelector,
  (_: State, isMobile: boolean) => isMobile,
], (contentWidthMode, isMobile) => isMobile ? ContentWidthMode.Narrow : contentWidthMode);

const singleMapStateToProps = (state: State, { isMobile }: { isMobile: boolean }) => ({
  isSubheaderPresent: isSubheaderPresentSelector(state),
  isMobileInsightsMapView: isMobileInsightsMapViewSelector(state),
  mobileInsightsType: insightsMobileMapTypeSelector(state),
  isFrozen: isFrozenSelector(state, isMobile),
  contentWidthMode: contentWidthModeSelector(state, isMobile),
  bounds: mapViewBboxSelector(state),
  route: routeSelector(state),
  hasInteraction: hasInteractionSelector(state),
});

export const SingleMap = withLocale(connect(singleMapStateToProps)(DeferredMap));
