import styled from '@emotion/styled';
import { SmallText } from '../typography';
import { typographyFontSizeCfg } from '../typography/types';

interface InputFieldWrapperProps {
  fullWidth: boolean;
}

export const InputFieldWrapper = styled.div<InputFieldWrapperProps>`
  ${props => props.fullWidth ? `
    width: 100%;
  ` : `
    width: 288px;
    max-width: 100%;
  `}

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface InputWrapperProps {
  type?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  ${props => props.type === 'password' && `
    input {
      ${props.theme.isRTL ? `
        padding-left: ${props.theme.spacing(4)};
      ` : `
        padding-right: ${props.theme.spacing(4)};
      `}
    }
  `}
`;

export interface InputFieldProps {
  hasError?: boolean;
}

export const InputField = styled.input<InputFieldProps>`
  &[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  outline: none;
  display: block;
  height: 40px;
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${props => props.theme.colors.neutrals.grey6};
  width: 100%;
  font-family: NovemberHebrew;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: ${props => props.theme.colors.neutrals.grey1};
  transition: all .2s ease;
  &::placeholder {
    color: ${props => props.theme.colors.neutrals.grey4};
  }
  &:hover {
    border-color: ${props => props.theme.colors.neutrals.grey4};
  }
  &:focus {
    border-color: ${props => props.theme.colors.brand.light};
  }
  ${props => props.hasError && `
    border-color: ${props.theme.colors.alert.red};

    &:hover {
      border-color: ${props.theme.colors.alert.red};
    }
  `}
  &[disabled] {
    opacity: 0.5;
  }

  textarea& {
    height: auto;
    min-height: 40px;
  }
`;

export const TextAreaField = InputField.withComponent<'textarea'>('textarea');

export const InputInnerWrapper = styled.div<{ withIcon: boolean, disabled?: boolean }>`
  position: relative;
  ${({ withIcon, theme, disabled }) => withIcon ? `
    .textfield-icon {
      svg {
        path {
          fill: ${disabled ? theme.colors.neutrals.grey7 : theme.colors.neutrals.grey4}
        }
      }
    }

    & input {
      padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(4)};
    }
  ` : ''}
`;

export const IconWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
  display: flex;
  align-items: center;
  ${({ theme }) => `
    ${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(1)};
    svg {
      max-width: 100%;
      max-height: 100%;
      path {
        fill: ${theme.colors.neutrals.grey4};
      }
    }
  `}
`;

interface LabelTextProps {
  disabled: boolean;
}

export const LabelText = styled(SmallText)<LabelTextProps>`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${props => props.theme.colors.neutrals.grey2};
  transition: all .2s ease;
  ${props => props.disabled && `
    opacity: 0.5;
  `}
`;

export const PriceFilterLabelText = styled(LabelText)`
  font-size: ${typographyFontSizeCfg.text}px;
`;

export const AssistiveText = styled(SmallText)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${props => props.theme.colors.neutrals.grey3};
`;

export const ErrorMessage = styled(SmallText)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${props => props.theme.colors.alert.red};
`;

interface ShowPasswordButtonProps {
  isPasswordShown: boolean;
}

export const ShowPasswordButton = styled.div<ShowPasswordButtonProps>`
  position: absolute;
  min-height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  ${({ theme }) => theme.isRTL ? `
    left: ${theme.spacing(1)};
  ` : `
    right: ${theme.spacing(1)};
  `}
`;
