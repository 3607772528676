import { CommuteType, StationRoute } from 'utils/entities';
import { ISOString } from 'locale/formatDate';
import { SchoolInsightSummary, SchoolInsightSpecific, ILSchoolGroupsSummary, ILSchool } from 'store/state/selectors/insights/schools/types';
import { TRANSPORTATION_HUBS_TYPE } from './transportation/utils';
import { IRawEstimation, RawHistoryItem } from 'store/state/selectors/insights/prices';
import { BuildingPermitSummary } from 'components/insight-summary-renderer/BuildingPermitSummary/types';


export interface IQuietStreet {
  bin: number;
  celebration: ICelebration;
  transit: ITransit;
  useType: IUseType;
  generalText: string;
}

export enum CelebrationType {
  General = 'general',
  GreenAvenues = 'green_avenues',
  QuietStreets = 'quiet_streets',
}

export interface ICelebration {
  type: CelebrationType;
  header: string;
  body: string;
  generalText?: string;
}

export interface ITransit {
  hasTransit: boolean;
  text: string;
}

export enum UseType {
  Mixed = 'mixed_use',
  Public = 'residential',
  Commercial = 'commercial',
}

export interface IUseType {
  text: string;
  type: UseType;
}

export interface InsightSummary {
  text: string;
  nonText: {
    data: InsightCustomSummary;
  };
}

export interface PriceTrendsNewData {
  ['1']?: PriceTrendByRooms;
  ['1.5']?: PriceTrendByRooms;
  ['2']?: PriceTrendByRooms;
  ['3']?: PriceTrendByRooms;
  ['4']?: PriceTrendByRooms;
  ['5']?: PriceTrendByRooms;
  ['6']?: PriceTrendByRooms;
  ['7']?: PriceTrendByRooms;
  all?: PriceTrendByRooms;
  // [key: string]: PriceTrendByRooms;
}
export interface PriceTrendsNewSummary {
  data: PriceTrendsNewData;
  updateTime: Date;
}

export interface PricesHistorySummary {
  area: RawHistoryItem[];
  building: RawHistoryItem[];
  unit: RawHistoryItem[];
}

interface PriceTrend {
  past: PriceTrendsPrices[];
  future: PriceTrendsPrices[];
}

export interface PriceTrendByDealType {
  name: string;
  type: string;
  buyPrice?: number;
  rentPrice?: number;
  ppa?: number;
  yield?: number;
  priceTrend: PriceTrend;
  dealsNumber?: number;
}

export interface PriceTrendByRooms {
  all?: PriceTrendByDealType[];
  new?: PriceTrendByDealType[];
  secondHand?: PriceTrendByDealType[];
}

export interface PriceTrendsPrices {
  date: string;
  price: number;
}

export enum ConstructionTypes {
  IN_CONSTRUCTION = 'inConstruction',
  IN_FILING = 'inFiling',
  IN_FILING_APPROVED = 'inFilingApproved',
}

export interface IConstructionSmartSentence {
  jobDescription: string;
  stories: number;
  units: number;
  estEnd: string;
}

export interface IConstructionTimeLine {
  start: string;
  end: string;
  approved: string;
  filing: string;
}
export interface IConstructionJobLocation {
  jobAddress: string;
  borough: string;
  street: string;
  houseNumber: string;
}

export type ConstructionStatusType = 'filing' | 'approved' | 'start' | 'end';

export interface INewDevelopmentJob {
  status: ConstructionStatusType;
  smartSentence: IConstructionSmartSentence;
  timeLine: IConstructionTimeLine;
  jobLocation: IConstructionJobLocation;
  geoId?: string | number;
}

export enum Direction {
  West = 'w',
  East = 'e',
  South = 's',
  North = 'n',
}

export interface ScaleForBoroughType {
  low: [ number, number ];
  moderate: [ number, number ];
}

export enum DemographicsCategory {
  AgeGroup = 'age_group',
  Family = 'family',
  Income = 'income',
  Education = 'education',
  Occupation = 'occupation',
}

export interface IDemographicsData {
  text: string;
  category: DemographicsCategory;
}

export type IDemographicsSummary = IDemographicsData[];

export interface LiensItem {
  holder: string;
  date: ISOString;
  isTerminated: boolean;
  unit: string;
}

export interface LiensSummaryData {
  data: LiensItem[];
  text: string;
}

interface TabuCommon {
  header: string;
  content: string;
}

export interface TabuStages {
  parcellation: TabuCommon;
  condo: TabuCommon;
  tabu: TabuCommon;
}

export interface TabuAdditionalInformation {
  header: string;
  price: TabuCommon;
  mortgage: TabuCommon;
  buySellProcess: TabuCommon;
  transferenceCost: TabuCommon;
}

export interface TabuRegistrationSummaryData {
  header: string;
  problematicRegistrationAreaAddition: string;
  registrationImportance: {
    header: string,
    content: {
      p1: string,
      p2: string,
    },
  };
  status: {
    header: string,
    completedStages: {
      key: number,
      str: string,
    },
    stages: TabuStages,
    additionalInformation: TabuAdditionalInformation,
  };
}

export type InsightCustomSummary = IConstruction
  | HpdViolation[]
  | ConnectivityInsightSummary
  | IDemographicsSummary
  | BikesInsightSummary
  | SchoolInsightSummary
  | SchoolInsightSpecific
  | ILSchoolGroupsSummary
  | ILSchool
  | IQuietStreet
  | PriceTrendsNewSummary
  | PricesHistorySummary
  | LiensSummaryData
  | IRawEstimation
  | BuildingPermitSummary
  | TabuRegistrationSummaryData; // or other times to be implemented

export interface IConstruction {
  [ConstructionTypes.IN_FILING_APPROVED]: INewDevelopmentJob[];
  [ConstructionTypes.IN_FILING]: INewDevelopmentJob[];
  [ConstructionTypes.IN_CONSTRUCTION]: INewDevelopmentJob[];
}

export interface HpdViolation {
  location: string;
  category: string;
  date: ISOString;
  open: boolean;
  severity: ViolationSeverity;
  isCurrentUnit: boolean;
}

export type ViolationSeverity = 'low' | 'medium' | 'high';

export interface IHub {
  name: TRANSPORTATION_HUBS_TYPE;
  image: string;
  commuteTime: Record<CommuteType, number>;
}

export interface BusStation {
  walkTime: number;
  stopName: string;
  lines: StationRoute[];
}

export interface RailStationLine {
  routeName: string;
  routeColor: string;
  routeId: string;
  service: string;
}
interface RailStation {
  walkTime: number;
  access: boolean;
  stopName: string;
  lines?: RailStationLine[];
  routeLongName?: string;
}

export interface ConnectivityInsightSummary {
  hubs: {
    personal: IHub[];
    general: IHub[];
  };
  busStations: BusStation[];
  railStations: RailStation[];
}

export interface BikeRoute {
  time: number;
  text: string;
}

export type BikesInsightSummary = BikeRoute[];
