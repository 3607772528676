import { connect } from 'react-redux';
import withEvents from 'analytics/withEvents';
import { setGalleryStatus } from 'store/state/app/actions';
import PopupImageGallery, { PopupImageGalleryProps } from 'components/popup-image-gallery';
import { withLocale } from 'locale';

const mapDispatchToProps = {
  setGalleryStatus,
};

const withEventsPopupImageGalleryComponent = withEvents<PopupImageGalleryProps>((sendEvent) => ({
  eventViewPhotoScroll({ dir, image, index, total }) {
    sendEvent('property_view_photo_scroll', 'property', {
      event: {
        'direction_scrolled': dir,
        'image': {
          'description': image.description,
          'image_url': image.url,
          'isFloorPlanPhoto': Boolean(image.isFloorPlan),
        },
        'image_index': index,
        'image_source': 'gallery',
        'index_total': total,
      },
    });
  },
}))(PopupImageGallery);

export default connect(null, mapDispatchToProps)(withLocale(withEventsPopupImageGalleryComponent));
