
import { PluginFactory, Plugin, Router, Route } from 'router5';


type Listener = (loc: { pathname: string, search: string }) => void;

export function fakeHistoryPluginFactory(): PluginFactory {
  const fakeHistoryPlugin = (router: Router): Plugin => {
    const listeners: Listener[] = [];
    const fakeHistory = {
      listen: (listener: Listener) => {
        listeners.push(listener);
      },
    };
    router.setDependency('fakeHistory', fakeHistory);

    return {
      onTransitionSuccess(toState: Route) {
        const [ pathname, ...searchQ ] = toState.path.split('?');
        setTimeout(() => {
          listeners.forEach((listener) => {
            listener({
              pathname,
              search: searchQ.length ? `?${searchQ.join('?')}` : '',
            });
          });
        });
      },
    };
  };

  return Object.assign(fakeHistoryPlugin, { pluginName: 'FAKE_HISTORY_PLUGIN' });
}

