import React from 'react';


export interface ILineConfig {
  render: () => JSX.Element;
  label: string;
  showCondition: boolean;
  separator: JSX.Element;
}

export default function({ render, separator, label }: ILineConfig, index: number, arr: ILineConfig[]): JSX.Element {
  const lastIndex = arr.length - 1;
  const isLast = lastIndex === index;

  return (
    <React.Fragment key={label}>
      {render()}
      {isLast ? null : separator}
    </React.Fragment>
  );
}
