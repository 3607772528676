import { RootAction } from 'store/state';
import { take, race, call, delay, put, select, fork, all } from 'redux-saga/effects';

import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';
import { TransitionSuccessPayload } from 'store/state/router/actionPayloads';
import { setPushNotificationsModalType } from 'store/state/app/actions';
import { PushNotificationsModalType } from 'store/state/app';
import { pushNotificationsEnabledSelector } from 'store/state/selectors/pushNotificationsStatus';
import { savedSearchesListSelector } from 'store/state/domainData/selectors';
import { flow } from 'lodash';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from './apiService/types';
import { LocalStorage } from 'utils/localStorage';
import { pushNotificationsFeatureEnabled } from 'utils/pushNotificationsFeatureEnabled';

const filtersApplyPattern = (action: RootAction) => (
  visitedSearchPattern(action) && (action.payload.route.meta.options || {}).source === 'filters'
);
const visitedListingPattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS && (action.payload.route.name === Route.UnitPage || action.payload.route.name === Route.ProjectPage)
);

const visitedSearchPattern = (action: RootAction): action is PayloadAction<typeof TRANSITION_SUCCESS, TransitionSuccessPayload> => (
  action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.Search
);

const searchSavedPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.SaveSearchQuery
);

const fromSavedSearchPageToSearchPattern = (action: RootAction) => (
  visitedSearchPattern(action) && action.payload.previousRoute.name === Route.SavedSearchesPage
);

const hasSavedSearchSelector = flow(savedSearchesListSelector, (list) => list && list.length > 0);

const KEY = 'PUSH_DISCOVERY';

const DEFAULT_VALUE: Record<PushNotificationsModalType, number> = {
  [PushNotificationsModalType.EnablePushNotifications]: 0,
  [PushNotificationsModalType.SaveSearch]: 0,
};

function getMobileDiscoveryMeta(): Record<PushNotificationsModalType, number> {
  return LocalStorage.get(KEY) || DEFAULT_VALUE;
}

function getShownCountByType(modalType: PushNotificationsModalType): number {
  return getMobileDiscoveryMeta()[modalType];
}

function incrementShownCount(modalType: PushNotificationsModalType) {
  const discoveryMeta = getMobileDiscoveryMeta();
  LocalStorage.set(KEY, { ...discoveryMeta, [modalType]: discoveryMeta[modalType] + 1 });
}

const DELAY_BEFORE_MODAL_MS = 3000;
const MAX_MODAL_SHOW_COUNT = 2;

function* visitedListingAndCameBackToSearch() {
  yield take(visitedSearchPattern);
  yield take(visitedListingPattern);
  yield take(visitedSearchPattern);
  yield delay(DELAY_BEFORE_MODAL_MS);
}

export function* pushNotificationsModalWatcher() {
  const featureEnabled = yield call(pushNotificationsFeatureEnabled);

  if (!featureEnabled) return;

  yield all([
    fork(nonExistingSaveSearchFlow),
    fork(existingSaveSearchFlow),
  ]);
}

function* savedSeachAndCameToSearch() {
  yield take(searchSavedPattern);
  yield take(fromSavedSearchPageToSearchPattern);
}

function* existingSaveSearchFlow() {
  while (true) {
    yield race([
      call(savedSeachAndCameToSearch),
      call(visitedListingAndCameBackToSearch),
    ]);
    const hasSavedSearches = yield select(hasSavedSearchSelector);
    const pushEnabled = yield select(pushNotificationsEnabledSelector);
    const shownCount = getShownCountByType(PushNotificationsModalType.EnablePushNotifications);
    if (!pushEnabled && hasSavedSearches && shownCount < MAX_MODAL_SHOW_COUNT) {
      yield put(setPushNotificationsModalType(PushNotificationsModalType.EnablePushNotifications));
      incrementShownCount(PushNotificationsModalType.EnablePushNotifications);
      break;
    }
  }
}

function* takeFiltersApplyWithDelay() {
  yield take(filtersApplyPattern);
  yield delay(DELAY_BEFORE_MODAL_MS);
}

function* nonExistingSaveSearchFlow() {
  yield race([
    call(visitedListingAndCameBackToSearch),
    call(takeFiltersApplyWithDelay),
  ]);

  const hasSavedSearches = yield select(hasSavedSearchSelector);
  const pushEnabled = yield select(pushNotificationsEnabledSelector);
  const shownCount = getShownCountByType(PushNotificationsModalType.SaveSearch);
  if (!pushEnabled && !hasSavedSearches && shownCount < MAX_MODAL_SHOW_COUNT) {
    yield put(setPushNotificationsModalType(PushNotificationsModalType.SaveSearch));
    incrementShownCount(PushNotificationsModalType.SaveSearch);
  }
}
