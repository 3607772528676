import { put, select, call, takeLatest, delay, take, fork, race } from 'redux-saga/effects';
import { MutationType, UgcRatingReviewEntry, UgcTextReviewEntry } from './apiService/types';
import { RootAction } from 'store/state';
import {
  userProfileSelector,
  isConnectedUserSelector,
} from 'store/state/domainData/selectors';
import { SET_RATING, ASK_REVIEW_WIZARD_FOR_ADDRESS, SAVE_USER_REVIEW } from 'store/state/UGC/types';
import { AppMode } from 'store/state/selectors/router';
import { navigateTo } from 'store/state/router/actions';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { mutate } from 'store/state/mutationsResponse/actions';
import { goToNextStep, openReviewWizard, askReviewWizardForAddress, closeReviewWizard } from 'store/state/UGC/actions';
import { toggleAddress } from 'store/state/app/actions';
import { removeTypeName } from 'utils';
import { IAutocompleteEntry } from 'utils/entities';
import {
  userNameInputSelector,
  textReviewInputSelector,
  changedNumericUserInputSelector,
  hasNextStepSelector,
  reviewWizardDocIdSelector,
} from 'store/state/UGC/selectors';
import { Topic } from 'components/user-generated-content/utils/initialUGCConfig';
import { userInitiatedCloseAuthModalPattern } from './routing/handlers/utils';
import { TRANSITION_START } from 'store/state/router/types';
import { createUgcStepsOptions, StepOrder } from 'components/user-generated-content/utils';
import { getResidentialNavigationParams } from 'components/search-widget/helpers';
import { SET_SHARE_MODAL_OPEN } from '../state/app/types';
import { setShareModalOpen } from '../state/app/actions';
import { ShareModalType } from 'components/share-modal';

const saveAddressPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.SaveAddress
);

function* offerSignUpModaToNotConnectedUser(docId: string) {
  const isConnectedUser = yield select(isConnectedUserSelector);

  if (!isConnectedUser) {
    yield put(toggleAddress({
      docId,
      isSaved: false,
      settings: {
        insightsUpdates: true,
        isHome: false,
        newListingUpdates: true,
      },
    }, { source: 'user_generated_content' }));
    // pause saga, so not to show gratitude modal
    yield race([
      take(saveAddressPattern),
      take(userInitiatedCloseAuthModalPattern),
    ]);
  }
}

function* saveReviewWorker() {
  const name = yield select(userNameInputSelector);
  const textReviewInput = yield select(textReviewInputSelector);
  const docId = yield select(reviewWizardDocIdSelector);
  const userProfile = yield select(userProfileSelector);
  const changedNumericUserInput: UgcRatingReviewEntry[] = yield select(changedNumericUserInputSelector);
  const numericUserInput: UgcRatingReviewEntry[] = changedNumericUserInput.map(removeTypeName);

  const textUserInput: UgcTextReviewEntry[] = textReviewInput
    ? [ { topic: Topic.TextReview, textReview: textReviewInput } ]
    : [];


  if (!numericUserInput.length && !textUserInput.length) return;

  const variables = {
    docId,
    email: userProfile ? userProfile.email : undefined,
    numericUserInput,
    textUserInput,
    name,
  };

  yield put(mutate({
    mutationType: MutationType.AddUGC,
    meta: { variables },
  }));

  yield put(closeReviewWizard());

  yield call(offerSignUpModaToNotConnectedUser, docId);

  yield put(setShareModalOpen(ShareModalType.UgcGratitudeModal));
}

function* setRatingWorker() {
  const hasNextStep = yield select(hasNextStepSelector);
  if (!hasNextStep) return;
  // It is requirement to display next step after timeout
  yield delay(200);
  yield put(goToNextStep());
}

const closedGratitudeModalPattern = (action: RootAction) => (
  action.type === SET_SHARE_MODAL_OPEN && action.payload === null
);

function* navigateToAddressWorker(autocompleteEntry: IAutocompleteEntry) {
  const [ , shouldStop ] = yield race([
    take(closedGratitudeModalPattern),
    take(TRANSITION_START),
  ]);
  if (shouldStop) return;

  const { route, params, options } = yield call(getResidentialNavigationParams, autocompleteEntry, AppMode.Check);
  yield put(navigateTo(route, params, options));
}

function* askReviewWizardForAddressHandler({ payload }: ReturnType<typeof askReviewWizardForAddress>) {
  const { autocompleteEntry } = payload;
  const { docId, name: addressName } = autocompleteEntry;
  if (!docId) return;

  const steps = createUgcStepsOptions(true, [], StepOrder.TextReviewFirst);
  yield put(openReviewWizard({ steps, docId, addressName }));

  yield fork(navigateToAddressWorker, autocompleteEntry);
}

export function* ugcReviewWizardWatcher() {
  yield takeLatest(SAVE_USER_REVIEW, saveReviewWorker);
  yield takeLatest(SET_RATING, setRatingWorker);
  yield takeLatest(ASK_REVIEW_WIZARD_FOR_ADDRESS, askReviewWizardForAddressHandler);
}
