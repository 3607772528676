import { combineReducers } from 'redux';
import appReducer, { AppState, AppAction } from './app';
import abTestsReducer, { AbTestsState } from './ab-tests';
import domainDataReducer, { DomainDataState, DomainDataAction } from './domainData';
import searchContextReducer, { SearchContextState, SearchContextAction } from './searchContext';
import insightsContextReducer, { InsightsContextAction, InsightsContextState } from './insightsContext/index';
import mutationsResponseReducer, { MutationsResponseState, MutationsResponseAction } from './mutationsResponse/index';
import routerReducer, { RouterState } from './router';
import { RouterAction } from 'store/state/router/actionType';
import homepageWizardReducer, { HomepageWizardAction, HomepageWizardState } from './homepageWizard/index';
import ugcReducer, { UGCState } from './UGC';
import { UGCAction } from './UGC/actionType';
import { UploadBulletinAction } from './uploadBulletin';
import { SearchDeveloperAction } from './searchDeveloper';
import { SearchDealsAction } from 'store/state/search-deals';


export interface State {
  abTests: AbTestsState;
  domainData: DomainDataState;
  searchContext: SearchContextState;
  app: AppState;
  mutationsResponse: MutationsResponseState;
  insightsContext: InsightsContextState;
  router: RouterState;
  homepageWizard: HomepageWizardState;
  ugc: UGCState;
}


export type RootAction =
  RouterAction
  | DomainDataAction
  | SearchContextAction
  | AppAction
  | MutationsResponseAction
  | InsightsContextAction
  | HomepageWizardAction
  | UGCAction
  | UploadBulletinAction
  | SearchDeveloperAction
  | SearchDealsAction
  // | OtherAction in the future ;
  ;

export default combineReducers<State, RootAction>({
  abTests: abTestsReducer,
  insightsContext: insightsContextReducer,
  domainData: domainDataReducer,
  router: routerReducer,
  searchContext: searchContextReducer,
  app: appReducer,
  mutationsResponse: mutationsResponseReducer,
  homepageWizard: homepageWizardReducer,
  ugc: ugcReducer,
});
