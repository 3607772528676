import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from 'store/sagas/apiService/types';
import { takeLatest, put } from 'redux-saga/effects';
import { navigateTo } from 'store/state/router/actions';
import { Route } from 'config/routes';
import { setMutationResponse } from 'store/state/mutationsResponse/actions';


const deleteListingPatter = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE
  && (action.mutationType === MutationType.EditListingStatus || action.mutationType === MutationType.EditCommercialListingStatus)
);

function* deleteListingWorker(action: ReturnType<typeof setMutationResponse>) {
  let isDeleted = false;

  switch (action.mutationType) {
    case MutationType.EditListingStatus:
      isDeleted = (action.meta.response.data
        && (
          action.meta.response.data.editListingStatus.listing.status.status === 'deleted'
          || action.meta.response.data.editListingStatus.listing.status.status === 'frozen'));
      break;
    case MutationType.EditCommercialListingStatus:
      isDeleted = (action.meta.response.data
        && (
          action.meta.response.data.editCommercialListingStatus.commercialListing.status.status === 'deleted'
          || action.meta.response.data.editCommercialListingStatus.commercialListing.status.status === 'frozen'));
      break;
  }

  if (isDeleted) {
    yield put(navigateTo(Route.Home));
  }
}

export function* deleteListingWatcher() {
  yield takeLatest(deleteListingPatter, deleteListingWorker);
}
