import React from 'react';
import PlusIcon from 'assets/svg/plus-big-1-5.svg';
import OkIcon from 'assets/svg/v-1-5.svg';
import { AnimatedSelection, FlipWrapper, IconWrapper, TextWrapper, Wrapper } from './styled';
import { useSpring } from 'react-spring';

export type Size = 'medium' | 'large' | 'xLarge';
export type State = 'default' | 'hovered';
export type Mode = 'primary' | 'selected';

export interface CheckboxChipProps {
  mode?: Mode;
  label?: string;
  size?: Size;
  icon?: React.ReactNode;
  withSelectionIcon?: boolean;
  withShadow?: boolean;
  onClick?: () => void;
}

const CheckboxChip: React.FC<CheckboxChipProps> = (props) => {
  const { label, onClick, icon, mode, ...rest } = props;

  const flipped = mode === 'selected';

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `translateX(-50%) translateY(-50%) perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 800, friction: 80 },
  });


  const flipIcon = (
    <FlipWrapper>
      <AnimatedSelection
        mode={mode}
        className="selection-icon-wrapper"
        style={{ opacity: opacity.interpolate(o => 1 - o), transform }}
      >
        <PlusIcon />
      </AnimatedSelection>
      <AnimatedSelection
        mode={mode}
        className="selection-icon-wrapper"
        style={{ opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}
      >
        <OkIcon />
      </AnimatedSelection>
    </FlipWrapper>
  );

  const onChange = () => onClick();

  return (
    <Wrapper mode={mode} {...rest} onClick={onChange}>
      {flipIcon}
      {icon ? <IconWrapper mode={mode}>{icon}</IconWrapper> : null}
      <input type="checkbox" hidden onChange={onChange} checked={mode === 'selected'} />
      <TextWrapper>{label}</TextWrapper>
    </Wrapper>
  );
};

export default CheckboxChip;
