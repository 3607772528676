import styled from '@emotion/styled';
import { ListingCardsItem } from 'ds/components/ListingCard';
import { Link } from 'components/link';
import { colorToAlpha } from 'helpers/insight';

export const Wrapper = styled.div<{ isLeftArrowDisplayed: boolean, isRightArrowDisplayed: boolean, hasArrowsWhiteBg: boolean; arrowsGradientBgColor: string; }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  position: relative;
  margin-top: 24px;

  ${({ theme, hasArrowsWhiteBg, isLeftArrowDisplayed }) => hasArrowsWhiteBg && isLeftArrowDisplayed && `
    ::before {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 16px;
      background: ${theme.colors.neutrals.white};
      z-index: 1;
    }
  `}

  ${({ theme, hasArrowsWhiteBg, isRightArrowDisplayed }) => hasArrowsWhiteBg && isRightArrowDisplayed && `
    ::after {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 16px;
      background: ${theme.colors.neutrals.white};
      z-index: 1;
    }
  `}

  ${({ theme, arrowsGradientBgColor, isLeftArrowDisplayed }) => arrowsGradientBgColor && isLeftArrowDisplayed && theme.media.showAt({ from: 2 })`
    ::before {
      content: '';
      position: absolute;
      right: 0;
      height: 99%;
      width: 100px;
      background: linear-gradient(90deg, transparent 0%, ${colorToAlpha(arrowsGradientBgColor, 0.9)} 80%, ${colorToAlpha(arrowsGradientBgColor, 0.98)} 100%);
      z-index: 1;
    }
  `}

  ${({ theme, arrowsGradientBgColor, isLeftArrowDisplayed }) => arrowsGradientBgColor && isLeftArrowDisplayed && theme.media.showAt({ at: 1 })`
    ::before {
      content: '';
      position: absolute;
      right: 0;
      height: 99%;
      width: 70px;
      background: linear-gradient(90deg, transparent 0%, ${colorToAlpha(arrowsGradientBgColor, 0.9)} 80%, ${colorToAlpha(arrowsGradientBgColor, 0.98)} 100%);
      z-index: 1;
    }
  `}

  ${({ theme, arrowsGradientBgColor, isRightArrowDisplayed }) => arrowsGradientBgColor && isRightArrowDisplayed && theme.media.showAt({ from: 2 })`
    ::after {
      content: '';
      position: absolute;
      left: 0;
      height: 99%;
      width: 100px;
      background: linear-gradient(90deg, ${colorToAlpha(arrowsGradientBgColor, 0.98)} 0%, ${colorToAlpha(arrowsGradientBgColor, 0.9)} 20%, transparent 100%);
      z-index: 1;
    }
  `}

  ${({ theme, arrowsGradientBgColor, isRightArrowDisplayed }) => arrowsGradientBgColor && isRightArrowDisplayed && theme.media.showAt({ at: 1 })`
    ::after {
      content: '';
      position: absolute;
      left: 0;
      height: 99%;
      width: 70px;
      background: linear-gradient(90deg, ${colorToAlpha(arrowsGradientBgColor, 0.98)} 0%, ${colorToAlpha(arrowsGradientBgColor, 0.9)} 20%, transparent 100%);
      z-index: 1;
    }
  `}
`;

export const LeftBtnWrapper = styled.div<{ hasArrowsGradientBg: boolean; }>`
  > div {
    ${({ theme, hasArrowsGradientBg }) => hasArrowsGradientBg ? `
      border: 1px solid ${theme.colors.neutrals.grey6};
    ` : `
      right: -15px;
    `}
    top: calc(25% - 16px);
  }
`;

export const RightBtnWrapper = styled.div<{ hasArrowsGradientBg: boolean; }>`
  > div {
    ${({ theme, hasArrowsGradientBg }) => hasArrowsGradientBg ? `
      border: 1px solid ${theme.colors.neutrals.grey6};
    ` : `
      left: -15px;
    `}
    top: calc(25% - 16px);
  }
`;

export const ItemWrapper = styled.div`
  width: 284px;
  padding: 4px;
  text-align: initial;
  > a {
    outline: none;
    text-decoration: none;
  }
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 21px;
`;

export const PlaceholderItem = styled(ListingCardsItem)`
    overflow: auto;
`;

export const CenteredLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
  text-decoration: none;
`;
