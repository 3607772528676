import { IContactPageSubmitVariables } from 'store/sagas/apiService/types';
import { PoiId } from 'utils/entities';
import { MadadContactModalState } from '.';
import { RequestType } from 'components/contact-modal/HebrewContactModal';

export enum ContactAgentSubmitterSource {
  Modal = 'Modal',
  Form = 'Form',
}

export interface SubmitContactAgentPayload {
  key: PoiId;
  variables: IContactPageSubmitVariables;
  source: ContactAgentSubmitterSource;
  requestType?: RequestType;
}

export type SetMadadContactModalStatePayload = MadadContactModalState;
