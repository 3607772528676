import React from 'react';
import {
  PreviewControlsWrapper,
  PreviewControlsHeader,
} from './styled';
import { useLocale } from 'locale';
import { Button } from 'ds/components/button';
import { useScreenBreakpoint } from 'consts/breakpoints';

interface PreviewControlsProps {
  publish: () => void;
  isFrozen: boolean;
  submitting: boolean;
}

const PreviewControls: React.FC<PreviewControlsProps> = ({ publish, isFrozen, submitting }) => {
  const { t } = useLocale();
  const isMobile = useScreenBreakpoint() < 3;

  return (
    <PreviewControlsWrapper>
      {isFrozen && !isMobile ? null : (
        <div>
          <PreviewControlsHeader weight={isMobile ? 'normal' : 'medium'}>
            {t('uploadBulletinPage.previewControlHeader')}
          </PreviewControlsHeader>
        </div>
      )}
      <Button fullWidth={isFrozen || isMobile} size="large" disabled={submitting} onClick={publish}>
        {t('uploadBulletinPage.previewControlPublish', { isFrozen })}
      </Button>
    </PreviewControlsWrapper>
  );
};

export default PreviewControls;
