import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { Text } from 'ds/components/typography';
import LocationIcon from 'assets/svg/location-gallery.svg';
import { typographyFontSizeCfg } from 'ds/components/typography/types';

export const CoverWrapper = styled.div<{ isCommercial: boolean }>`
  ${({ theme, isCommercial }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${isCommercial ? theme.colors.brand.purpleWhite : theme.colors.brand.mintieWhite};
  `}
`;

export const Cover = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    padding-right: ${theme.spacing(9)};
    padding-left: ${theme.spacing(9)};
    padding-top: ${theme.spacing(14)};
    height: 449px;
    width: 100%;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: ${theme.spacing(7)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)};
    flex-direction: column;
    height: 510px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 2 })`
    padding: ${theme.spacing(10)} ${theme.spacing(4)};
    flex-direction: column;
    height: 645px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 3 })`
    padding-top: ${theme.spacing(10)};
    height: 380px;
  `}

  ${({ theme }) => theme.media.showAt({ from: 4 })`
    width: calc(1136px + 2 * ${theme.spacing(9)});
  `}
`;

export const SearchSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.media.showAt({ at: 3 })`
    align-items: flex-start;
  `}
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.brand.darker};
  font-size: ${typographyFontSizeCfg.display.desktop}px;
  line-height: 60px;
  font-weight: bold;
  width: 505px;
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    font-size: ${typographyFontSizeCfg.display.mobile}px;
    line-height: 32px;
    text-align: center;
    width: 238px;
    margin-bottom: ${theme.spacing(2)};
  `}

  ${({ theme }) => theme.media.showAt({ at: 2 })`
    font-size: 45px;
    text-align: center;
    width: 430px;
    margin-bottom: ${theme.spacing(3)};
  `}


  ${({ theme }) => theme.media.showAt({ at: 3 })`
    font-size: 34px;
    line-height: 50px;
    width: 320px;
  `}
`;

export const TitleMiddle = styled.span<{ isCommercial: boolean }>`
  color: ${({ theme, isCommercial }) => isCommercial ? theme.colors.brand.irisPurple : theme.colors.brand.ocean};
`;

export const CoverImageWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
  display: flex;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    align-self: center;
    margin-bottom: -${theme.spacing(1)};
  `};

  ${({ theme }) => theme.media.showAt({ at: 2 })`
    align-self: center;
    margin-bottom: -${theme.spacing(6)};
  `};
`;

export const SearchBlockRoot = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 520px;
  background-color: ${props => colorToAlpha(props.theme.colors.neutrals.grey8, 0.4)};
  z-index: 9;

  ${({ theme }) => `
    padding: ${theme.spacing(0.5)};
    border-radius: ${theme.borderRadius.small} 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  `}

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    background-color: ${theme.colors.neutrals.grey8};
  `};

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
    border-radius: 0 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)};
    margin-top: ${theme.spacing(6)};
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    max-width: 429px;
  `}

  ${({ theme }) => theme.media.showAt({ at: 2 })`
    max-width: 100%;
  `}

  ${({ theme }) => theme.media.showAt({ at: 3 })`
    max-width: 429px;
  `}
`;

export const SearchTypeSelectWrapper = styled(Text.withComponent('div'))`
  background-color: ${({ theme }) => theme.colors.neutrals.grey8};
  height: 32px;
  line-height: 32px;
  top: -32px;
  position: absolute;

  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    font-size: 17px;
    width: 100%;
    text-align: center;
    padding-top: ${theme.spacing(2)};
    padding-bottom: 0;
    height: 48px;
    top: -48px;
    background-color: ${colorToAlpha(theme.colors.neutrals.grey8, 0.4)};
    left: 0;
    label:first-of-type {
      margin-right: 7px;
    }
    label:last-of-type {
      margin-left: 9px;
    }
  `};

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    right: 0;
  `};

  label {
    font-weight: bold;
    color: ${({ theme }) => colorToAlpha(theme.colors.neutrals.grey1, 0.5)};
    padding: ${({ theme }) => theme.spacing(1)} 0;
    position: relative;
    cursor: pointer;
  }

  input[type='radio'] {
    display: none;
  }

  input:checked + label {
    color: ${({ theme }) => theme.colors.neutrals.grey1};
  }
`;

export const AutocompleteInputWrapper = styled.div<{ isError?: boolean; isMobileOrTablet: boolean; }>`
  flex: 1;
  ${({ theme }) => theme.media.showAt({ from: 2, to: 3 })`
    width: 315px;
  `};

  .textfield-input {
    font-size: 15px;
    height: 48px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0 none;

    ${({ theme }) => theme.media.showAt({ from: 4 })`
      width: 400px;
    `};

    ${({ theme, isMobileOrTablet }) => theme.media.showAt({ at: 1 })`
      height: 48px;
      border: ${isMobileOrTablet ? '0 none' : `2px solid ${theme.colors.neutrals.white}`}
    `};
    ${({ theme }) => `
      border-radius: 0 ${theme.borderRadius.small} ${theme.borderRadius.small} 0;
    `}
  }
  ${({ isError }) => isError ? `
    input::placeholder {
      color: #D0021B;
      font-weight: 600;
    }
  ` : ''}
`;

export const SearchTypeSelectLabel = styled.span`
  ${({ theme }) => theme.media.showAt({ at: 1 })`padding-left: ${theme.spacing(2)};`};
  padding-left: ${({ theme }) => theme.spacing(3)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const Separator = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey6};
    padding: 0 ${theme.spacing(1)};
  `}
`;

export const SearchButton = styled.button`
  font-family: NovemberHebrew;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding: 0 ${theme.spacing(2)};
    width: 108px;
    background-color: ${theme.colors.brand.main};
  `};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
  border: none;
  height: 48px;
  line-height: 48px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  width: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    background-color: ${theme.colors.brand.main};
    border-radius: ${theme.borderRadius.small} 0 0 ${theme.borderRadius.small};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    :hover {
      background-color:${colorToAlpha(theme.colors.brand.main, 0.9)};
    }
    :focus {
      outline: none;
    }
  `};
`;


export const ImpactInsightsWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    margin-top: -${theme.spacing(1)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    .scrollBox {
      padding: 0 ${theme.spacing(2)};
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 2 }) `
    .scrollBox {
      padding: 0 ${theme.spacing(5)};
    }
  `}
  .insight-card {
    cursor: default;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
    margin: 0 ${({ theme }) => theme.spacing(1)};
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }
  > div {
    padding: 0;
    > div {
      margin: 0;
    }
    h3 {
      display: none;
    }
    & div.view-pager-rail {
      ${({ theme }) => `
        padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(2.5)};
      `}
      ${({ theme }) => theme.media.showAt({ to: 2 }) `
        padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(2)};
      `}
    }
  }
  .carousel-shadow-root {
    &:before {
      ${({ theme }) => `
        background: linear-gradient(to right, ${theme.colors.neutrals.white}, rgba(255,255,255,0) 50%);
      `}
    }
    &:after {
      ${({ theme }) => `
        background: linear-gradient(to left, ${theme.colors.neutrals.white}, rgba(255,255,255,0) 50%);
      `}
    }
  }
`;

export const DropdownListWrapper = styled.div<{ isMobileOrTablet: boolean; }>`
  ${({ theme, isMobileOrTablet }) => `
    ${isMobileOrTablet ? `
      width: 100%;

      > div {
       width: 100%;
      }
    ` : `
      > div {
        width: 512px;
      }
    `}

    button {
      width: 100%;
      justify-content: space-between;
      height: 48px;
      border: none;
      border-radius: ${theme.borderRadius.small};
      font-size: 15px;
    }
  `}

  ${({ theme }) => theme.media.showAt({ at: 3 })`
    width: 100%;
    > div {
      width: 421px;
    }
  `}
`;

export const SelectPlaceholderIcon = styled(LocationIcon)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)};
  stroke: ${({ theme }) => theme.colors.neutrals.grey9};
  stroke-width: 0.5px;
  width: 20px;
  height: 20px;

`;

export const SelectPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutrals.grey4};
  padding-right: ${({ theme }) => theme.spacing(2.5)};
`;

export const OpenSelectPlaceholderWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  font-size: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: ${({ theme }) => theme.spacing(2)};
`;

export const CompletionTypeText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey4};
    padding-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(.5)};
  `}
  text-transform: capitalize;
  display: inline;
  &::before {
    content: '· ';
  }
`;

export const BlogSectionWrapper = styled.div`
  margin: 0 auto;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.grey8};
  ${({ theme }) => theme.media.showAt({ at: 1 })`padding: 0 ${theme.spacing(2)};`};
  ${({ theme }) => theme.media.showAt({ at: 2 })`padding: 0 ${theme.spacing(5)};`};
  ${({ theme }) => theme.media.showAt({ at: 3 })`max-width: 864px;`};
  ${({ theme }) => theme.media.showAt({ from: 4 })`max-width: 1144px;`};
`;
