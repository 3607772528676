import React, { useMemo } from 'react';
import { GenericFilterProps, AmenitiesBody, AmenitySection, BaseConfig } from '../../types';
import { useScreenBreakpoint, BreakpointValue } from 'consts/breakpoints';
import { MarketplaceType } from 'utils/entities';
import FilterSection from '../../common/DropdownSection';
import { useLocale } from 'locale';
import { isFilterActiveByType } from '../../utils';
import * as Styled from './styled';
import CheckboxGroup from './checkbox-group';
import { Section } from '../../styled/common';


function getConfigByBreakPoint(breakpoint: BreakpointValue = 5, conf: BaseConfig): AmenitiesBody {
  let data = conf.amenities[breakpoint];
  let maybePrevBreakpoint = breakpoint - 1;

  while (!data) {
    data = conf.amenities[maybePrevBreakpoint--];
  }
  return data;
}

function renderSection(section: AmenitySection, props: AmenitiesProps, isLast: boolean) {
  switch (section.type) {
    case 'checkboxGroup':
      return (
        <CheckboxGroup
          last={isLast}
          group={section}
          values={props.intermediateValue}
          valueSetter={props.setIntermediateValue}
        />
      );
    default:
      break;
  }
  return null;
}

interface AmenitiesProps extends GenericFilterProps<'amenities'> {}

const Amenities: React.FC<AmenitiesProps> = (props) => {
  const {
    resetValue,
    commitValues,
    commitedValue,
    intermediateValue,
    initialValue,
    alignment,
    type,
    isInline,
    marketplace,
    config,
  } = props;

  const breakpoint = useScreenBreakpoint();
  const isCommercial = marketplace === MarketplaceType.Commercial;
  const { t } = useLocale();
  const amenitiesConfig = useMemo(
    () => getConfigByBreakPoint(breakpoint, config),
    [ getConfigByBreakPoint, breakpoint, config ]
  );

  const labelTerm = isCommercial ? 'filters.commercial.amenities' : 'filters.amenities';
  const content = (
    <>
      {amenitiesConfig.title ? <Styled.Header weight="bold">{t(amenitiesConfig.title)}</Styled.Header> : null}
      {amenitiesConfig.sections.map((item, i, arr) => (
        <React.Fragment key={i}>
          {renderSection(item, props, (arr.length - 1) === i)}
        </React.Fragment>
      ))}
    </>
  );

  if (isInline) {
    return (
      <Section>
        {content}
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={!isFilterActiveByType.amenities(intermediateValue, initialValue)}
      type={type}
      alignment={alignment}
      icon={config.filtersIcons[type]}
      onClear={resetValue}
      label={t(labelTerm, { count: commitedValue.length })}
      onSubmit={commitValues}
      isActive={isFilterActiveByType.amenities(commitedValue, initialValue)}
    >
      {content}
    </FilterSection>
  );
};

export default Amenities;
