import React from 'react';

import FacebookIcon from 'assets/svg/facebook.svg';
import GoogleIcon from 'assets/svg/google.svg';
import { SocialVendor } from '../types';
import { Button } from 'ds/components/button';
import { SocialButtonWrapper } from './styled';

interface SocialButtonProps {
  type: SocialVendor;
  onClick(): void;
}

export const socialTypeToIconMap: Record<SocialVendor, JSX.Element> = {
  facebook: <FacebookIcon />,
  google: <GoogleIcon />,
};

export const SocialButton: React.FunctionComponent<SocialButtonProps> = ({ type, children, onClick }) => (
  <SocialButtonWrapper type={type}>
    <Button
      data-auto={`${type}-connect-button`}
      mode="ghost"
      fullWidth
      size="large"
      isInitialIconColor
      onClick={onClick}
    >
      {socialTypeToIconMap[type]}
      {children}
    </Button>
  </SocialButtonWrapper>
);
