import React from 'react';
import { IAutocompleteEntry, CompletionType } from 'utils/entities';
import { InitialSuggestion } from 'utils/initialSuggestionsConfig';
import { SuggestionItem } from './SuggestionItem';
import NoResult from './NoResult';
import LocationIcon from 'assets/svg/location-gallery.svg';
import { AddressAutocompleteSource } from '../types';
import { useLocale } from 'locale';
import { replaceString } from './utils';
import { GetItemPropsOptions } from 'downshift';


interface SuggestionsProps {
  initialSuggestions?: InitialSuggestion[];
  suggestedAddresses: IAutocompleteEntry[];
  loading?: boolean;
  searchStr: string;
  source: AddressAutocompleteSource;
  optionsCount: number;
  showType?: boolean;
  highlightedIndex: number;
  itemPropsGetter: (options: GetItemPropsOptions<any>) => any;
  isOpen: boolean;
  children: (suggestions: React.ReactNode) => JSX.Element;
  itemIcon?: React.ReactNode;
}

const highlighter = (str: string, i: number) => <span key={i}>{str}</span>;

export const Suggestions: React.FC<SuggestionsProps> = (props) => {
  const {
    initialSuggestions,
    suggestedAddresses,
    loading,
    searchStr,
    source,
    optionsCount,
    showType,
    highlightedIndex,
    itemPropsGetter,
    isOpen,
    children,
    itemIcon = <LocationIcon width={24} height={24} />,
  } = props;

  const { t } = useLocale();

  let content: React.ReactNode = null;

  if (!isOpen) {
    content = null;
  }
  else if (!suggestedAddresses.length && searchStr && !loading) {
    content = <NoResult source={source} str={searchStr} />;
  }
  else if (suggestedAddresses.length) {
    content = suggestedAddresses.slice(0, optionsCount).map((address, index) => (
      <SuggestionItem
        key={address.docId + index}
        icon={itemIcon}
        isActive={index === highlightedIndex}
        downshiftItemProps={itemPropsGetter({
          key: address.docId,
          item: address.docId,
          index,
        })}
        label={replaceString(address.name, searchStr, highlighter)}
        secondaryLabel={showType ? t('autocomplete.completionType', { type: address.type }) : null}
      />
    ));
  }
  else if (initialSuggestions && !searchStr) {
    const acc: React.ReactNodeArray = [];
    let index = 0;

    for (const { entries } of initialSuggestions) {
      for (const entry of entries) {
        acc.push(
          <SuggestionItem
            key={entry.docId + index}
            icon={itemIcon}
            isActive={index === highlightedIndex}
            downshiftItemProps={itemPropsGetter({
              key: entry.docId,
              item: entry.docId,
              index,
            })}
            label={entry.name}
            secondaryLabel={entry.type === CompletionType.Neighbourhood ? entry.borough : null}
          />
        );

        index++;
      }
    }

    content = acc;
  }

  return content ? children(content) : null;
};
