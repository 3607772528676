import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import defaultTheme from 'ds/theme/default';
import { CheckboxChipProps, Mode, Size, State } from './index';
import { animated } from 'react-spring';

const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.brand.main,
  },
};

const modeToBorderColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey6,
    selected: defaultTheme.colors.brand.lighter,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey6,
    selected: defaultTheme.colors.brand.lighter,
  },
};

const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.white,
    selected: defaultTheme.colors.brand.lightest,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.white,
    selected: defaultTheme.colors.brand.lightest,
  },
};

const modeToIconColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey2,
    selected: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey2,
    selected: defaultTheme.colors.brand.main,
  },
};

const modeToSelectedIconWrapperColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey9,
    selected: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey6,
    selected: defaultTheme.colors.brand.darker,
  },
};

const modeToSelectedIconColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey3,
    selected: defaultTheme.colors.neutrals.white,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.neutrals.white,
  },
};

const sizeToPadding: Record<Size, string> = {
  xLarge: `${defaultTheme.spacing(1.5)} ${defaultTheme.spacing(2)}`,
  large: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(2)}`,
  medium: `${defaultTheme.spacing(1)}`,
};

export const Wrapper = styled.div<CheckboxChipProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    max-width: 100%;
  `}

  ${({ theme, mode = 'primary', size = 'medium', withShadow }) => `
    border-radius: ${theme.borderRadius.extraXLarge};
    padding: ${sizeToPadding[size]};

    color: ${modeToFontColor.default[mode]};
    ${withShadow ? ` box-shadow: ${theme.shadow.level3};` : ``}
    border: 1px solid ${withShadow ? (mode === 'selected' ? modeToBgColor.hovered[mode] : theme.colors.neutrals.white) : modeToBorderColor.default[mode]};
    background-color: ${modeToBgColor.default[mode]};

    &:hover {
      color: ${modeToFontColor.hovered[mode]};
      border: 1px solid ${withShadow ? (mode === 'selected' ? modeToBgColor.hovered[mode] : theme.colors.neutrals.white) : modeToBorderColor.default[mode]};
      background-color: ${modeToBgColor.hovered[mode]};
      & .selection-icon-wrapper {
        background: ${modeToSelectedIconWrapperColor.hovered[mode]};
        & svg {
          path {
            fill: ${modeToSelectedIconColor.hovered[mode]};
          }
        }
      }
    }
  `}
`;

const SelectionIconWrapper = styled.div<{ mode: Mode }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  ${({ theme, mode = 'primary' }) => `
    border-radius: ${theme.borderRadius.round};
    background: ${modeToSelectedIconWrapperColor.default[mode]};
    & svg {
      path {
        fill: ${modeToSelectedIconColor.default[mode]};
      }
    }
  `}
`;

export const AnimatedSelection = animated(SelectionIconWrapper);

export const FlipWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  ${({ theme }) => `
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1.5)};
  `}
`;

export const IconWrapper = styled.div<{ mode: Mode }>`
  ${({ theme, mode }) => `
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
    & svg {
      path {
        fill: ${modeToIconColor.default[mode]};
      }
    }
  `}
`;

export const TextWrapper = styled(Text)`
  ${({ theme }) => `
    padding-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(1)};
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;
