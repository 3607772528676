import React from 'react';
import { useLocale } from 'locale';
import { head } from 'lodash';
import { Amenity, IFiltersState, PropertyType, RangeTuple, RoomOption, Seller } from 'components/filters/types';
import {
  IAutocompleteAddress,
  IAutocompleteEntry,
  IBulletin,
  DealType,
  ResolutionPreference,
  getBuildingClassKey,
  IProject,
  IAutocompleteStreet,
  ICommercialBulletin,
  ICommercialProject,
  CompletionType,
  MarketplaceType,
  IMortgageOffice,
  IOfficePageData,
} from 'utils/entities';
import { State } from 'store/state';
import { flow } from 'lodash';
import { filtersStateSelector } from 'store/state/selectors/search';
import { getRouteParams, routeNameSelector } from 'store/state/selectors/router';
import {
  addressDocEntrySelector,
  searchDocEntriesSelector,
  searchListTotalSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  resolvedStrictLocalDocSelector,
  projectPageCommercialProjectSelector,
  unitPageCommercialBulletinSelector,
} from 'store/state/domainData/selectors';
import { connect } from 'react-redux';
import { getActiveFilters } from 'components/filters/utils';
import { Config } from 'config';
import { Route, DecodedRouteParams } from 'config/routes';
import { config as filtersConfig } from 'components/filters/config';
import { createSelector } from 'reselect';
import { capitalize } from 'utils';
import {
  DefaultPrimaryParams,
  getAddress,
  getDefaultPrimaryParams,
  renderAddress,
  unitPagePrimaryTranslateKeys,
} from 'utils/addressFormatter';
import { madadLocalDocSelector, madadCityNameSelector } from 'store/state/selectors/madad';
import { marketplaceSelector } from 'store/state/selectors/router';
import {
  developersSearchTotalCountSelector,
  developersCityNameSelector,
} from 'store/state/domainData/selectors/searchDevelopers';
import { developerNameSelector } from 'screens/DeveloperPage/selectors';
import { mortgageOfficeSelector } from 'store/state/domainData/selectors/mortgageOffice';
import { officePageDataSelector } from 'store/state/domainData/selectors/officeById';



interface DocumentHeadStateProps {
  homeTypes: PropertyType[];
  dealType: DealType;
  searchArea: IAutocompleteEntry[];
  total: number;
  rooms: RoomOption;
  amenity: Amenity;
  routeName: Route;
  routeParams: DecodedRouteParams;
  price: RangeTuple<number>;
  bulletin: IBulletin;
  bulletinCommercial: ICommercialBulletin;
  project: IProject;
  projectCommercial: ICommercialProject;
  address: IAutocompleteAddress;
  localAreaAddress: IAutocompleteAddress;
  seller: Seller;
  streetAddress: IAutocompleteStreet;
  localDoc: IAutocompleteEntry;
  developersSearchCount: number;
  developersSearchCity: string;
  developerName: string;
  activeFilters: Set<keyof IFiltersState>;
  marketplace: MarketplaceType;
  mortgageOffice: IMortgageOffice;
  agentOfficeData: IOfficePageData;
  madadCityName: string;
}

export interface DocumentHeadRenderProp {
  title: string;
  description: string;
}

interface DocumentHeadOwnProps {
  config: Config;
  children: (payload: DocumentHeadRenderProp) => React.ReactElement;
}

interface DocumentHeadProps extends DocumentHeadStateProps, DocumentHeadOwnProps {}

const makeOneLiner = (str: string) => str.replace(/\s+/g, ' ').trim();

const DocumentHeadInner: React.FC<DocumentHeadProps> = (props) => {
  const { t, formatMoney, formatNumber } = useLocale();
  const {
    homeTypes, seller, searchArea, dealType, total, amenity, price, rooms,
    address, localAreaAddress, streetAddress, localDoc,
    routeParams,  routeName, config, children,
    bulletin, project, projectCommercial, bulletinCommercial,
    developersSearchCount, developersSearchCity, developerName,
    activeFilters, marketplace, mortgageOffice, agentOfficeData,
    madadCityName,
  } = props;

  const homeType = homeTypes && homeTypes.length === 1 ? homeTypes[0] : null;

  let titleText = t('html.titleDefault', { year: new Date().getFullYear() });
  let descText = t('html.descDefault');

  const formatPropertyTypes = (params: DecodedRouteParams) => {
    const propertyTypes = params && params.filters && params.filters.propertyTypes ? params.filters.propertyTypes : [];
    const translatedPropertyTypes = propertyTypes.map((type) => t('filters.propertyTypeOptions', { value: type }));

    return translatedPropertyTypes.join(', ');
  };
  const noFilters = activeFilters.size === 0;

  switch (routeName) {
    case Route.CheckAddress: {
      if (routeParams.ugc_invite === 'true') {
        titleText = t('ugcInvite.title');
        descText = t('ugcInvite.description');
      }
      break;
    }

    case Route.Search: {
      if (!searchArea || !searchArea.length) return null;

      const pageIndex = t('html.page.index', { page: routeParams.page || null });

      if (searchArea.length === 1) {
        const [ singleSearchArea ] = searchArea;

        const homeTypeFragment = t('html.homeTypeFragment', { homeType: seller === Seller.Developer ? 'projects' : homeType, dealType });
        const homeTypeFragmentSingular = t('html.homeTypeFragmentSingular', { homeType: seller === Seller.Developer ? 'projects' : homeType, dealType });
        const addressFragment = capitalize(t('html.searchAddressFragment', {
          ...(singleSearchArea as any),
          cityCode: config.city,
        }));

        const amenityAdj = t('html.searchAmenityAdjectiveFragment', { amenity }) || null;
        const amenityAdv = t('html.searchAmenityPronounFragment', { amenity }) || null;

        const [ priceFrom, priceTo ] = price;
        const priceFrag = t('html.searchPriceFragment', {
          priceFrom: priceFrom && formatMoney(priceFrom),
          priceTo: priceTo && formatMoney(priceTo),
        });
        const [ defaultPriceFrom, defaultPriceTo ] = filtersConfig[marketplace].budgetByDealType[dealType].initialBudgetValue;

        const isOnlyMaxPriceFilter =
          activeFilters.size === 1 &&
          activeFilters.has('priceRange') &&
          (!priceFrom || priceFrom === defaultPriceFrom) &&
          priceTo && priceTo !== defaultPriceTo;
        const isOnlyPropertyTypesFilter = activeFilters.size === 1 && activeFilters.has('propertyTypes');
        const isOnlyPropertyTypesAndRoomsRangeFilters =
          activeFilters.size === 2 &&
          activeFilters.has('propertyTypes') &&
          activeFilters.has('roomsRange');

        const dealTypeFrag = t('html.dealTypeFragment', { dealType });
        const totalFormatted = formatNumber(total);
        const { city } = singleSearchArea;
        let locationFragment: string;
        switch (singleSearchArea.type) {
          case CompletionType.Country:
            locationFragment = t('html.locationFragment', { completionType: singleSearchArea.type, noFilters });
            break;
          case CompletionType.City:
            locationFragment = t('html.locationFragment', { completionType: singleSearchArea.type, city, noFilters });
            break;
          case CompletionType.Neighbourhood:
            const { neighbourhood } = singleSearchArea;
            locationFragment = t('html.locationFragment', { completionType: singleSearchArea.type, city, neighbourhood, noFilters });
            break;
          case CompletionType.Street:
            const { street } = singleSearchArea;
            locationFragment = t('html.locationFragment', { completionType: singleSearchArea.type, city, street, noFilters });
            break;
        }

        if (rooms !== null) {
          const roomFrag = t('html.searchRoomsFragment', { rooms });

          if (!total) {
            titleText = t('html.searchNoResultsTitle', { homeTypeFragment, dealType, locationFragment });
          }
          else if (isOnlyPropertyTypesAndRoomsRangeFilters) {
            const propertyTypes = formatPropertyTypes(routeParams);
            const roomsFragment = t('html.searchRoomsFragment', { rooms });
            titleText = t('html.searchWithPropertyTypesAndRoomsRangeFiltersTitle', { dealTypeFrag, locationFragment, propertyTypes, roomsFragment });
          }
          else {
            titleText = t('html.searchWithBedsTitle', {
              addressFragment,
              roomFrag,
              homeTypeFragment,
              dealTypeFrag,
              pageIndex,
            });
          }

          if (priceTo !== null) {
            if (singleSearchArea.type === CompletionType.City || singleSearchArea.type === CompletionType.Neighbourhood || singleSearchArea.type === CompletionType.Street) {
              descText = t('html.searchWithBedsPriceHomeCityNeighbourhoodDesc', { roomFrag, homeTypeFragment: homeTypeFragmentSingular, dealType, addressFragment, priceTo: formatNumber(priceTo) });
            }
            else {
              descText = t('html.searchWithBedsPriceHomeCountryDesc', { roomFrag, homeTypeFragment: homeTypeFragmentSingular, dealType, priceTo: formatNumber(priceTo) });
            }
          }
          else if (singleSearchArea.type === CompletionType.City) {
            descText = t('html.searchWithBedsHomeCityDesc', { roomFrag, homeTypeFragment: homeTypeFragmentSingular, dealType, addressFragment });
          }
          else if (singleSearchArea.type === CompletionType.Neighbourhood || singleSearchArea.type === CompletionType.Street) {
            descText = t('html.searchWithBedsHomeNeighbourhoodDesc', { roomFrag, homeTypeFragment: homeTypeFragmentSingular, dealType, addressFragment, city: singleSearchArea.city });
          }
          else if (singleSearchArea.type === CompletionType.Country) {
            descText = t('html.searchWithBedsHomeCountryDesc', {
              roomFrag,
              homeTypeFragment: homeTypeFragmentSingular,
              dealType,
            });
          }
          else {
            descText = t('html.searchWithBedsDesc', {
              roomFrag,
              homeTypeFragment,
              dealTypeFrag,
              total,
              addressFragment,
              pageIndex,
            });
          }
        }
        else {
          if (!total) {
            titleText = t('html.searchNoResultsTitle', { homeTypeFragment, dealType, locationFragment });
          }
          else if (noFilters) {
            titleText = t('html.searchNoFiltersTitle', { dealTypeFrag, locationFragment, total: totalFormatted });
          }
          else if (isOnlyMaxPriceFilter) {
            const maxPrice = formatNumber(priceTo);
            titleText = t('html.searchWithMaxPriceFilterTitle', { dealTypeFrag, locationFragment, maxPrice });
          }
          else if (isOnlyPropertyTypesFilter) {
            const propertyTypes = formatPropertyTypes(routeParams);
            titleText = t('html.searchWithPropertyTypesFilterTitle', { dealTypeFrag, locationFragment, propertyTypes });
          }
          else {
            titleText = t('html.searchDefaultTitle', {
              dealType: seller === Seller.Developer ? 'projects' : dealType,
              homeTypeFragment,
              addressFragment,
              total,
              priceFrag,
              amenityAdj,
              amenityAdv,
              pageIndex,
            });
          }

          if (priceTo !== null && homeType) {
            if (singleSearchArea.type === CompletionType.City || singleSearchArea.type === CompletionType.Neighbourhood || singleSearchArea.type === CompletionType.Street) {
              descText = t('html.searchPropertyTypeMaxPriceCityNeighbourhoodDesc', { homeTypeFragment, dealType, total, addressFragment, priceTo: formatNumber(priceTo) });
            }
            else {
              descText = t('html.searchPropertyTypeMaxPriceCountryDesc', { homeTypeFragment, dealType, total, addressFragment, priceTo: formatNumber(priceTo) });
            }
          }
          else if (singleSearchArea.type === CompletionType.Country) {
            if (homeType && homeType !== PropertyType.Flat) {
              descText = t('html.searchHomeCountryDesc', { dealType, total, homeTypeFragment });
            }
            else {
              descText = t('html.searchCountryDesc', { dealType, total });
            }
          }
          else if (singleSearchArea.type === CompletionType.City) {
            if (homeType && homeType !== PropertyType.Flat) {
              descText = t('html.searchHomeCityDesc', { dealType, total, addressFragment, homeTypeFragment });
            }
            else {
              descText = t('html.searchCityDesc', { dealType, total, addressFragment });
            }
          }
          else {
            descText = (!homeTypes || homeTypes.length === 1)  && (singleSearchArea.type === CompletionType.Neighbourhood || singleSearchArea.type === CompletionType.Street)
              ? t('html.searchNeighbourhoodDesc', {
                total,
                homeTypeFragment,
                dealType,
                addressFragment,
                pageIndex,
              })
              : t('html.searchDefaultDesc', {
                total,
                homeTypeFragment,
                dealType,
                addressFragment,
                pageIndex,
              });
          }
        }
      }
      else {
        titleText = t('html.residential.multisearch.title', { pageIndex });
        descText = t('html.residential.multisearch.description', { pageIndex });
      }

      break;
    }

    case Route.ProjectPage: {
      if (!project) return null;
      titleText = project.projectName;
      descText = t('html.projectPageDesc', {
        projectName: project.projectName,
        projectDeveloper: project.developers && project.developers[0] && project.developers[0].name,
      });
      break;
    }

    case Route.UnitPage: {

      if (!bulletin) return null;
      let addressFragment;
      const addressDetails = bulletin.addressDetails;
      const buildingClassFrag = t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(bulletin.buildingClass), isCommercial: false });
      const { relevantDocIds, ...addressDetailsForText } = bulletin.addressDetails || {};
      if (addressDetails && addressDetails.resolutionPreferences && addressDetails.resolutionPreferences !== ResolutionPreference.Accurate) {
        const maskedAddress = getAddress<DefaultPrimaryParams>(t, renderAddress)(addressDetails, unitPagePrimaryTranslateKeys, addressDetails.resolutionPreferences, getDefaultPrimaryParams);
        addressFragment = capitalize(t('html.unitPageAddressFragment', {
          maskedAddress,
          ...addressDetailsForText,
          cityCode: config.city,
        }));
      }
      else {
        addressFragment = capitalize(t('html.unitPageAddressFragment', {
          ...addressDetailsForText,
          maskedAddress: null,
          cityCode: config.city,
        }));
      }

      const dealTypeFrag = t('html.dealTypeFragment', {
        dealType: bulletin.dealType,
      });
      titleText = t('html.unitPageTitle', {
        addressFragment,
        dealTypeFrag,
        buildingClassFrag,
      });

      descText = t('html.unitPageDesc', {
        area: bulletin.area,
        rooms: bulletin.beds,
        buildingClassFrag,
        addressFragment,
        dealTypeFrag,
        price: formatMoney(bulletin.price),
      });

      break;
    }

    case Route.AddressPage: {

      if (!address) return null;

      const ctx = {
        houseNumber: address.houseNumber,
        street: address.street,
        neighbourhood: address.neighbourhood,
        borough: address.borough,
        city: address.city,
        zipcode: address.zipcode,
        cityCode: config.city,
      };

      titleText = capitalize(t('html.addressPageTitle', ctx));
      descText = capitalize(t('html.addressPageDesc', ctx));

      break;
    }

    case Route.LocalPage: {

      if (!localAreaAddress) return null;

      const ctx = {
        neighbourhood: localAreaAddress.neighbourhood || '',
        city: localAreaAddress.city || '',
      };

      titleText = capitalize(t('html.areaInfoPageTitle', ctx));
      descText = capitalize(t('html.areaInfoPageDesc', ctx));

      break;
    }

    case Route.StreetPage: {

      if (!streetAddress) return null;

      const ctx = {
        name: streetAddress.name || '',
        street: streetAddress.street || '',
        city: streetAddress.city || '',
      };

      titleText = capitalize(t('html.streetInfoPageTitle', ctx));
      descText = capitalize(t('html.streetInfoPageDesc', ctx));

      break;
    }

    case Route.MadadArchive2020Page: {
      titleText = capitalize(t('html.madad.title.2020'));
      descText = capitalize(t('html.madad.description.2020'));
      break;
    }

    case Route.MadadArchivePage: {
      const year = routeParams.year;
      titleText = capitalize(t('html.madadArchive.title', { year }));
      descText = capitalize(t('html.madadArchive.description', { year }));
      break;
    }

    case Route.MadadPage: {
      titleText = capitalize(t('html.madad.title.2024'));
      descText = capitalize(t('html.madad.description.2024'));
      break;
    }

    case Route.MadadCity: {
      if (!madadCityName) return null;
      titleText = capitalize(t('html.madadCity.title', { cityName: madadCityName }));
      descText = capitalize(t('html.madadCity.description', { cityName: madadCityName }));
      break;
    }

    case Route.MadadAgentPage: {
      titleText = capitalize(t('html.madadAgentSearch.title'));
      descText = capitalize(t('html.madadAgentSearch.description'));
      break;
    }

    case Route.MadadSearchPage: {

      if (!localDoc) return null;

      const ctx = {
        city: localDoc.city || null,
      };

      titleText = capitalize(t('html.madadSearch.title', ctx));
      descText = capitalize(t('html.madadSearch.description', ctx));

      break;
    }
    case Route.CommercialMarketLanding: {

      titleText = capitalize(t('html.commercialLanding.title'));
      descText = capitalize(t('html.commercialLanding.description'));

      break;
    }
    case Route.SearchCommercial: {
      if (!searchArea || !searchArea.length) return null;

      const isSingleAddress = searchArea.length === 1;
      const mappedDealTypeText = t('html.dealTypeFragment', { dealType });
      const singlePropertyTypeText = t('searchListView.commercial.single.propertyType', { propertyType: head(homeTypes) });
      const mappedPropertyType = t('searchListView.commercial.multi.propertyType', { count: homeTypes && homeTypes.length || 0, propertyType: singlePropertyTypeText });
      const pageIndex = t('html.page.index', { page: routeParams.page || null });
      const sellerFragment = seller ? t('filters.sellerOptions', { value: seller }) : null;
      const secondSinglePropertyTypeText = homeTypes && homeTypes.length > 1 ? t('searchListView.commercial.single.propertyType', { propertyType: homeTypes[1] }) : null;

      descText = t('html.commercialSearch.description', { pageIndex });

      if (isSingleAddress) {
        const [ firstSearchArea ] = searchArea;
        const addressText = t('html.commercialSearch.address', { ...(firstSearchArea as any), cityCode: config.city });
        const propertiesStr = [ singlePropertyTypeText, secondSinglePropertyTypeText ].join(', ');

        titleText = t('html.commercialSearch.title', {
          location: addressText,
          dealType: mappedDealTypeText,
          propertyType: mappedPropertyType,
          pageIndex,
        });

        if (noFilters) {
          if (firstSearchArea.type === CompletionType.Country) {
            titleText = t('html.commercialSearch.title.noFilters.country', {
              total,
              dealType: mappedDealTypeText,
            });
            descText = t('html.commercialSearch.description.noFilters.country', {
              dealType: mappedDealTypeText,
            });
          }
          else if (firstSearchArea.type === CompletionType.City) {
            titleText = t('html.commercialSearch.title.noFilters.city', {
              city: addressText,
              dealType: mappedDealTypeText,
              total,
            });
            descText = t('html.commercialSearch.description.noFilters.city', {
              dealType: mappedDealTypeText,
              city: addressText,
            });
          }
        }
        else if (homeTypes && homeTypes.length && seller) {
          if (homeTypes.length === 1) {
            if (firstSearchArea.type === CompletionType.Country) {
              titleText = t('html.commercialSearch.title.singlePropertyType.seller.country', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
              });
              descText = t('html.commercialSearch.description.singlePropertyType.seller.country', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
              });
            }
            else if (firstSearchArea.type === CompletionType.City) {
              titleText = t('html.commercialSearch.title.singlePropertyType.seller.city', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
                city: addressText,
              });
              descText = t('html.commercialSearch.description.singlePropertyType.seller.city', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
                city: addressText,
              });
            }
          }
          else {
            if (firstSearchArea.type === CompletionType.Country) {
              titleText = t('html.commercialSearch.title.multiPropertyType.seller.country', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertiesStr,
              });
              descText = t('html.commercialSearch.description.multiPropertyType.seller.country', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertiesStr,
              });
            }
            else if (firstSearchArea.type === CompletionType.City) {
              titleText = t('html.commercialSearch.title.multiPropertyType.seller.city', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertiesStr,
                city: addressText,
              });
              descText = t('html.commercialSearch.description.multiPropertyType.seller.city', {
                sellerFragment,
                dealType: mappedDealTypeText,
                propertiesStr,
                city: addressText,
              });
            }
          }
        }
        else if (homeTypes && homeTypes.length) {
          if (homeTypes.length === 1) {
            if (firstSearchArea.type === CompletionType.Country) {
              titleText = t('html.commercialSearch.title.singlePropertyType.country', {
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
              });
              descText = t('html.commercialSearch.description.singlePropertyType.country', {
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
              });
            }
            else if (firstSearchArea.type === CompletionType.City) {
              titleText = t('html.commercialSearch.title.singlePropertyType.city', {
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
                city: addressText,
              });
              descText = t('html.commercialSearch.description.singlePropertyType.city', {
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
                city: addressText,
              });
            }
          }
          else {
            if (firstSearchArea.type === CompletionType.Country) {
              titleText = t('html.commercialSearch.title.multiPropertyType.country', {
                dealType: mappedDealTypeText,
                propertiesStr,
              });
              descText = t('html.commercialSearch.description.multiPropertyType.country', {
                dealType: mappedDealTypeText,
                propertyType: singlePropertyTypeText,
                propertiesStr,
              });
            }
            else if (firstSearchArea.type === CompletionType.City) {
              titleText = t('html.commercialSearch.title.multiPropertyType.city', {
                dealType: mappedDealTypeText,
                propertiesStr,
                city: addressText,
              });
              descText = t('html.commercialSearch.description.multiPropertyType.city', {
                dealType: mappedDealTypeText,
                propertiesStr,
                city: addressText,
              });
            }
          }
        }
        else if (seller) {
          if (firstSearchArea.type === CompletionType.Country) {
            titleText = t('html.commercialSearch.title.seller.country', {
              dealType: mappedDealTypeText,
              sellerFragment,
            });
            descText = t('html.commercialSearch.description.seller.country', {
              dealType: mappedDealTypeText,
              sellerFragment,
            });
          }
          else if (firstSearchArea.type === CompletionType.City) {
            titleText = t('html.commercialSearch.title.seller.city', {
              dealType: mappedDealTypeText,
              sellerFragment,
              city: addressText,
            });
            descText = t('html.commercialSearch.description.seller.city', {
              dealType: mappedDealTypeText,
              sellerFragment,
              city: addressText,
            });
          }
        }
      }
      else {
        titleText = t('html.commercialSearch.title.multi', {
          dealType: mappedDealTypeText,
          propertyType: mappedPropertyType,
          pageIndex,
        });
      }

      break;
    }
    case Route.UnitPageCommercial: {

      if (!bulletinCommercial) return null;

      let addressFragment;
      const addressDetails = bulletinCommercial.addressDetails;
      const buildingClassFrag = t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(bulletinCommercial.buildingClass), isCommercial: true });
      const { relevantDocIds, ...addressDetailsForText } = bulletinCommercial.addressDetails || {};
      if (addressDetails && addressDetails.resolutionPreferences && addressDetails.resolutionPreferences !== ResolutionPreference.Accurate) {
        const maskedAddress = getAddress<DefaultPrimaryParams>(t, renderAddress)(addressDetails, unitPagePrimaryTranslateKeys, addressDetails.resolutionPreferences, getDefaultPrimaryParams);
        addressFragment = capitalize(t('html.unitPageAddressFragment', {
          maskedAddress,
          ...addressDetailsForText,
          cityCode: config.city,
        }));
      }
      else {
        addressFragment = capitalize(t('html.unitPageAddressFragment', {
          ...addressDetailsForText,
          maskedAddress: null,
          cityCode: config.city,
        }));
      }

      const dealTypeFrag = t('html.dealTypeFragment', {
        dealType: bulletinCommercial.dealType,
      });


      titleText = t('html.commercialUnitPage.title', {
        addressFragment,
        dealTypeFrag,
        buildingClassFrag,
      });

      descText = capitalize(t('html.commercialUnitPage.description'));

      break;
    }
    case Route.ProjectPageCommercial: {

      if (!projectCommercial) return null;

      titleText = projectCommercial.projectName;
      descText = capitalize(t('html.commercialProjectPage.description'));

      break;
    }
    case Route.DevelopersSearchPage:
      titleText = capitalize(t('html.developersSearchPage.title'));
      descText = capitalize(t('html.developersSearchPage.description'));
      break;

    case Route.EmploymentAreaPage: {

      if (!localDoc) return null;

      titleText = capitalize(t('html.employmentAreaPageTitle', { areaName: localDoc.name }));
      descText = capitalize(t('html.employmentAreaPageDesc', { areaName: localDoc.name }));

      break;
    }
    case Route.DevelopersCitySearchPage:
      titleText = capitalize(t('html.developersSearchCityPage.title', { city: developersSearchCity }));
      descText = capitalize(t('html.developersSearchCityPage.description', { city: developersSearchCity, count: developersSearchCount }));
      break;

    case Route.DeveloperPage:
      titleText = capitalize(t('html.developerPage.title', { name: developerName }));
      descText = capitalize(t('html.developerPage.description', { name: developerName }));
      break;

    case Route.MortgageOfficesSearchPage:
      titleText = capitalize(t('html.mortgageOfficesPage.title', { page: routeParams.page || 1, address: (searchArea && searchArea.length && searchArea[0].name) || null }));
      descText = capitalize(t('html.mortgageOfficesPage.description'));
      break;
    case Route.MortgageOfficesCitySearchPage:
      titleText = capitalize(t('html.mortgageOfficesPage.title', { page: routeParams.page || 1, address: (searchArea && searchArea.length && searchArea[0].name) || null }));
      descText = capitalize(t('html.mortgageOfficesPageCity.description', { address: (searchArea && searchArea.length && searchArea[0].name) || null }));
      break;
    case Route.MortgageOfficePage: {
      if (!mortgageOffice) return null;
      titleText = capitalize(t('html.mortgageOfficePage.title', { title: mortgageOffice.name }));
      descText = capitalize(t('html.mortgageOfficePage.description', { title: mortgageOffice.name }));
      break;
    }
    case Route.OfficePage: {
      if (!(agentOfficeData && agentOfficeData.officeName)) return null;
      titleText = capitalize(t('html.agentOffice.title', { agentOfficeName: agentOfficeData.officeName }));
      descText = capitalize(t('html.agentOffice.description', { agentOfficeName: agentOfficeData.officeName }));
      break;
    }
  }

  return children({
    title: makeOneLiner(titleText),
    description: makeOneLiner(descText),
  });
};

export const activeFiltersSelector = createSelector(filtersStateSelector, marketplaceSelector, (filtersState, marketplace) => (
  new Set(filtersState ? getActiveFilters(filtersState, marketplace) : [])
));

export const homeTypesSelector = createSelector([
  filtersStateSelector,
  activeFiltersSelector,
], (filtersState, activeFilters) => {
  if (activeFilters.has('propertyTypes') && filtersState.propertyTypes.length) {
    return filtersState.propertyTypes;
  }
  return null;
});


const priceSelector = createSelector([
  filtersStateSelector,
  flow(getRouteParams, (p) => p.dealType),
  activeFiltersSelector,
  marketplaceSelector,
], (filters, dealType, activeFilters, marketplace): RangeTuple<number> => {

  if (!activeFilters.has('priceRange')) return [ null, null ];

  const [ from, to ] = filters.priceRange;
  const { max, min } = filtersConfig[marketplace].budgetByDealType[dealType].budgetSliderProps;

  if (from === min) return [ null, to ];
  else if (max === to) return [ from, null ];

  return [ null, null ];
});

const roomsSelector = createSelector([
  filtersStateSelector,
  activeFiltersSelector,
], (filters, activeFilters) => {

  if (activeFilters.has('roomsRange')) {
    const [ minRooms, maxRooms ] = filters.roomsRange;
    if (minRooms !== undefined && maxRooms === null) return minRooms;
  }
  return null;
});

const amenitySelector = createSelector([
  filtersStateSelector,
  activeFiltersSelector,
], (filtersState, activeFilters) => {
  if (activeFilters.has('amenities') && filtersState.amenities.length === 1) {
    const [ amenity ] = filtersState.amenities;
    return amenity;
  }
  return null;
});

export const sellerSelector = createSelector([
  filtersStateSelector,
  activeFiltersSelector,
], (filtersState, activeFilters) => {
  if (activeFilters.has('seller') && filtersState.seller.length === 1) {
    const [ seller ] = filtersState.seller;
    return seller;
  }
  return null;
});

const dealTypeSelector = flow(getRouteParams, (p) => p.dealType);

const mapStateToProps = (state: State) => {
  return ({
    homeTypes: homeTypesSelector(state),
    dealType: dealTypeSelector(state),
    searchArea: searchDocEntriesSelector(state),
    total: searchListTotalSelector(state),
    rooms: roomsSelector(state),
    amenity: amenitySelector(state),
    routeName: routeNameSelector(state),
    routeParams: getRouteParams(state),
    price: priceSelector(state),
    bulletin: unitPageBulletinSelector(state),
    bulletinCommercial: unitPageCommercialBulletinSelector(state),
    project: projectPageProjectSelector(state),
    projectCommercial: projectPageCommercialProjectSelector(state),
    address: addressDocEntrySelector(state),
    seller: sellerSelector(state),
    localAreaAddress: resolvedStrictLocalDocSelector(state),
    streetAddress: resolvedStrictLocalDocSelector(state),
    localDoc: madadLocalDocSelector(state),
    developersSearchCount: developersSearchTotalCountSelector(state),
    developersSearchCity: developersCityNameSelector(state),
    developerName: developerNameSelector(state),
    activeFilters: activeFiltersSelector(state),
    marketplace: marketplaceSelector(state),
    mortgageOffice: mortgageOfficeSelector(state),
    agentOfficeData: officePageDataSelector(state),
    madadCityName: madadCityNameSelector(state),
  });
};

export const DocumentHead = connect(mapStateToProps)(DocumentHeadInner);
