import React from 'react';
import { lowerCase } from 'lodash';
import { useLocale } from 'locale';
import { ApartmentDetailsContainer, ApartmentDetailsRow, ApartmentDetailsTitle, ApartmentDetailsValue } from './styled';
import { AvailabilityType, PoiType } from 'utils/entities';

export interface ApartmentDetailsProps {
  commonCharges: number;
  monthlyTaxes: number;
  generalCondition: string;
  buildingYear: number;
  availabilityType: string;
  availableDate: number;
  furnitureDetails: string;
  type?: PoiType;
}

export const simpleDateFormatter = (date: number) => {
  if (!date) return null;
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${month}/${year}`;
};

const ApartmentDetails: React.FunctionComponent<ApartmentDetailsProps> = (props) => {
  const {
    monthlyTaxes,
    generalCondition,
    availabilityType,
    availableDate,
    furnitureDetails,
  } = props;
  const { t } = useLocale();
  return (
    <ApartmentDetailsContainer data-auto="apartment-details-block">
      <ApartmentDetailsRow>
        <ApartmentDetailsTitle>{t('unitPage.buildingDetails.generalCondition')}</ApartmentDetailsTitle>
        <ApartmentDetailsValue data-auto="unit-general-condition-value">{t('unitPage.buildingDetails.value', { value:  generalCondition })}</ApartmentDetailsValue>
      </ApartmentDetailsRow>
      <ApartmentDetailsRow>
        <ApartmentDetailsTitle>{t('unitPage.buildingDetails.availabilityType')}</ApartmentDetailsTitle>
        <ApartmentDetailsValue data-auto="unit-availability-value">
          {t('unitPage.buildingDetails.value', { value: lowerCase(availabilityType) === AvailabilityType.Date.toLowerCase() ? simpleDateFormatter(availableDate) : availabilityType })}
        </ApartmentDetailsValue>
      </ApartmentDetailsRow>
      <ApartmentDetailsRow>
        <ApartmentDetailsTitle>{t('unitPage.buildingDetails.monthlyTaxes')}</ApartmentDetailsTitle>
        <ApartmentDetailsValue data-auto="unit-monthly-tax-value">{t('unitPage.buildingDetails.monthlyTaxesValue', { value:  monthlyTaxes })}</ApartmentDetailsValue>
      </ApartmentDetailsRow>
      <ApartmentDetailsRow>
        <ApartmentDetailsTitle>{t('unitPage.buildingDetails.furnitureDetails')}</ApartmentDetailsTitle>
        <ApartmentDetailsValue data-auto="unit-furniture-details-value">{t('unitPage.buildingDetails.value', { value:  furnitureDetails })}</ApartmentDetailsValue>
      </ApartmentDetailsRow>
    </ApartmentDetailsContainer>
  );
};

export default ApartmentDetails;
