import styled from '@emotion/styled';


export const PropertyDetailsText = styled.div`
  display: inline-block;
  text-transform: capitalize;
`;

export const PropertyDetailsSeparator = styled.span`
  width: 3px;
  height: 3px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  margin: 0 ${({ theme }) => theme.spacing(0.5)};
  position: relative;
  top: -2px;
  background: ${props => props.theme.colors.neutrals.grey1};
  display: inline-block;
`;
