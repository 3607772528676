import React, { useState, useRef } from 'react';
import { useLocale } from 'locale';
import ChatsTeardropIcon from 'assets/svg/chats-teardrop.svg';
import { StyledSupportButton } from './styled';
import { SupportPopup } from 'components/support/SupportPopup';
import { useClickAwayListener } from 'hooks/useClickAwayListener';

export const SupportButton: React.FC = () => {
  const [ isPopupOpen, setIsPopupOpen ] = useState(false);
  const { t } = useLocale();
  const supportPopupRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => setIsPopupOpen(!isPopupOpen);

  useClickAwayListener(supportPopupRef, () => setIsPopupOpen(false));

  return (
    <>
      <StyledSupportButton mode="ghost" size="large" isInitialIconColor onClick={handleButtonClick}>
        <ChatsTeardropIcon />
        {t('navigation.subheader.supportButton.label')}
      </StyledSupportButton>
      {isPopupOpen && <SupportPopup popupRef={supportPopupRef} />}
    </>
  );
};
