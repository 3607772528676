import { projectPageProjectsByIdsSelector, makeNullSafeDomainGetter, createIsLoadingSelector, projectPageCommercialProjectsByIdsSelector } from './common';
import { flow } from 'lodash';


const poiByIdsSelector = makeNullSafeDomainGetter(projectPageProjectsByIdsSelector, 'poiByIds');
export const isProjectPageProjectLoadingSelector = createIsLoadingSelector(projectPageProjectsByIdsSelector);

const poiByIdsCommercialSelector = makeNullSafeDomainGetter(projectPageCommercialProjectsByIdsSelector, 'poiByIds');
export const isCommercialProjectPageProjectLoadingSelector = createIsLoadingSelector(projectPageCommercialProjectsByIdsSelector);

export const projectPageProjectSelector = flow(
  poiByIdsSelector,
  (poiByIds) => {
    if (poiByIds && poiByIds.length) {
      const [ project ] = poiByIds;

      return project;
    }
    return null;
  }
);

export const projectPageCommercialProjectSelector = flow(
  poiByIdsCommercialSelector,
  (poiByIds) => {
    if (poiByIds && poiByIds.length) {
      const [ project ] = poiByIds;

      return project;
    }
    return null;
  }
);
