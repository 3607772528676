import React from 'react';
import { IThumbnail, PoiId, PoiType } from 'utils/entities';
import { DEFAULT_PLACEHOLDER_IMAGE, getInfiniteNextIndex, getInfinitePrevIndex } from 'utils/gallery';
import { ShowAt } from 'consts/breakpoints';
import { FullscreenView } from './FullscreenView';
import { GalleryControls } from './GalleryControls';
import { GalleryPager } from './GalleryPager';
import { ImageGallery } from './ImageGallery';
import MapView from './MapView';
import FloorplanButton from './FloorplanButton';
import { GalleryWrapper, PlaceholderImage } from './styled';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';

export interface PopupImageGalleryStatus {
  isOpen?: boolean;
  slideIndex?: number;
}

export const PopupImageGalleryStatusInitial = {
  isOpen: false,
  slideIndex: 0,
};

export interface GalleryStateProps extends PopupImageGalleryStatus {
  setGalleryStatus: (data: PopupImageGalleryStatus) => void;
}

export interface EventViewPhotoScrollProps extends EventViewPhotoGalleryOpenProps {
  dir: string;
}

export interface EventViewPhotoGalleryOpenProps {
  image: IThumbnail;
  index: number;
  total: number;
}

export interface GalleryOwnProps {
  images: IThumbnail[];
  lng: number;
  lat: number;
  id: PoiId;
  type: PoiType;
  eventPropertyFloorPlanOpen?: () => void;
  eventViewPhotoScroll?: (opts: EventViewPhotoScrollProps) => void;
  eventViewPhotoGalleryOpen?: (opts: EventViewPhotoGalleryOpenProps) => void;
  enableShare?: boolean;
  withMapAction?: boolean;
}

export interface PopupImageGalleryStatus {
  isOpen?: boolean;
  slideIndex?: number;
}

export interface GalleryState {
  isMapViewActive: boolean;
}

const GalleryPlaceholderImage: React.FunctionComponent<any> = () => {
  const constructImageURI = useImageUrlBuilder();
  return <PlaceholderImage imageUrl={constructImageURI(DEFAULT_PLACEHOLDER_IMAGE)}/>;
};

export class Gallery extends React.PureComponent<GalleryOwnProps & GalleryStateProps, GalleryState> {
  public state: GalleryState = {
    isMapViewActive: false,
  };

  public static defaultProps = {
    enableShare: true,
  };

  public componentWillUnmount() {
    this.props.setGalleryStatus({ slideIndex: 0 });
  }

  public handleSlideIndexChange = (idx: number) => {
    this.props.setGalleryStatus({ slideIndex: idx });
  };


  public handlePrevSlide = () => {
    const total = this.props.images.length;
    const slideIndex = getInfinitePrevIndex(this.props.slideIndex, total);
    const image = this.props.images[slideIndex];
    this.props.setGalleryStatus({ slideIndex });
    this.props.eventViewPhotoScroll({ dir: 'left', image, index: slideIndex, total });
  };

  public handleNextSlide = () => {
    const total = this.props.images.length;
    const slideIndex = getInfiniteNextIndex(this.props.slideIndex, total);
    const image = this.props.images[slideIndex];
    this.props.setGalleryStatus({ slideIndex });
    this.props.eventViewPhotoScroll({ dir: 'right', image, index: slideIndex, total });
  };

  private openFullscreen = (isFloorplan: boolean) => () => {
    const total = this.props.images.length;
    const slideIndex = this.props.slideIndex;
    const image = this.props.images[slideIndex];
    const state: PopupImageGalleryStatus = { isOpen: true };
    if (isFloorplan) {
      state.slideIndex = this.props.images.findIndex((img: IThumbnail) => img.isFloorPlan);
      this.props.eventPropertyFloorPlanOpen();
    }
    this.props.setGalleryStatus(state);
    this.props.eventViewPhotoGalleryOpen({ image, index: slideIndex, total });
  };

  private closeFullscreen = () => () => {
    this.props.setGalleryStatus({ isOpen: false });
  };

  private toggleMapView = () => {
    this.setState(prev => ({
      isMapViewActive: !prev.isMapViewActive,
    }));
  };

  public render () {
    const { images, lng, lat, isOpen, slideIndex, id, type, enableShare } = this.props;
    const { isMapViewActive } = this.state;
    const hasFloorPlan = images.some(image => image.isFloorPlan);

    return (
      <GalleryWrapper>
        {images.length ? (
          <ImageGallery
            images={images}
            openFullscreen={this.openFullscreen}
            activeSlide={slideIndex}
            handleSlideIndexChange={this.handleSlideIndexChange}
          />
        ) : <GalleryPlaceholderImage/>}
        <GalleryControls
          images={images}
          id={id}
          type={type}
          activeSlide={slideIndex}
          handleOpenFullscreen={this.openFullscreen}
          toggleMapView={this.toggleMapView}
          isMapViewActive={isMapViewActive}
          enableShare={enableShare}
        />
        <ShowAt to={2}>
          {isMapViewActive ? <MapView lng={lng} lat={lat} withMapAction={this.props.withMapAction} /> : null}
        </ShowAt>
        {hasFloorPlan && !isMapViewActive ? <FloorplanButton openFullscreen={this.openFullscreen} /> : null}
        {images.length ? (
          <GalleryPager
            images={images}
            handleNextSlide={this.handleNextSlide}
            handlePrevSlide={this.handlePrevSlide}
            handleSlideIndexChange={this.handleSlideIndexChange}
            activeSlide={slideIndex}
          />
        ) : null}
        {isOpen ? (
          <FullscreenView
            images={images}
            closeFullscreen={this.closeFullscreen}
            slideIndex={slideIndex}
          />
        ) : null}
      </GalleryWrapper>
    );
  }
}

export default Gallery;

