import React from 'react';

import { PropertyType } from '../types';
import { Section, SectionBody, SectionTitle, SecondaryTitle, PropertyTypeWrapper } from '../styled/common';
import { PerformantCheckboxGroup } from '../common/PerformantCheckbox';
import FilterSection from '../common/DropdownSection';
import { isFilterActiveByType } from '../utils';
import { WithLocaleProps, withLocale } from 'locale';
import { GenericFilterProps } from '../types';
import { head } from 'lodash';


interface PropertyTypesProps extends GenericFilterProps<'propertyTypes'>, WithLocaleProps {}
interface PropertyTypesState {
  isCollapsed: boolean;
}

class PropertyTypes extends React.PureComponent<PropertyTypesProps, PropertyTypesState> {
  public state: PropertyTypesState = {
    isCollapsed: true,
  };

  public handleCollapsed = () => this.setState(prev => ({
    isCollapsed: !prev.isCollapsed,
  }));

  private checkboxLabelGetter = (value: PropertyType) => (
    this.props.t('filters.propertyTypeOptions', { value, marketPlace: this.props.marketplace })
  );

  public render() {
    const {
      t,
      intermediateValue,
      isMobile,
      isInline,
      initialValue,
      setIntermediateValue,
      resetValue,
      commitValues,
      commitedValue,
      type,
      config,
      alignment,
    } = this.props;

    const { propertyTypes, filtersIcons } = config;

    const checkboxGroup = (
      <PerformantCheckboxGroup
        values={intermediateValue}
        options={propertyTypes}
        labelGetter={this.checkboxLabelGetter}
        valueSetter={setIntermediateValue}
      />
    );

    if (isInline) {
      return (
        <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
          <SectionTitle isBold={isMobile}>
            <div data-auto="propertyTypesTitle">
              {t('filters.propertyType', { count: null })}
              {intermediateValue.length ? (
                <SecondaryTitle data-auto="selectedTypesCount">
                  {intermediateValue.length}
                </SecondaryTitle>
              ) : null}
            </div>
          </SectionTitle >
          <SectionBody data-auto="filterCheckboxes">
            <PropertyTypeWrapper isInline={!isMobile}>
              {checkboxGroup}
            </PropertyTypeWrapper>
          </SectionBody>
        </Section>
      );
    }

    return (
      <FilterSection
        isClearButtonDisabled={!isFilterActiveByType.propertyTypes(intermediateValue, initialValue)}
        type={type}
        alignment={alignment}
        icon={filtersIcons[type]}
        onClear={resetValue}
        label={t('filters.propertyType', { count: commitedValue.length, value: this.checkboxLabelGetter(head(commitedValue)) })}
        onSubmit={commitValues}
        isActive={isFilterActiveByType.propertyTypes(commitedValue, initialValue)}
      >
        {checkboxGroup}
      </FilterSection>
    );
  }
}

export default withLocale(PropertyTypes);
