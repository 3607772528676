import * as MapboxGL from 'mapbox-gl';
import {
  ITileRange,
  BoundingBox,
  PoiId,
  DataLayerPoiType,
  DATA_LAYERS_POI_TYPES,
  DATA_LAYERS_COMMERCIAL_POI_TYPES,
  MarketplaceType, ContentWidthMode,
} from 'utils/entities';
import { shallowMemo } from 'utils';
import { Route } from 'config/routes';

export const IS_BULLETIN_FILTER = [ '==', [ 'get', 'type' ], 'bulletin' ];
export const IS_PROJECT_FILTER = [ '==', [ 'get', 'type' ], 'project' ];
export const PAGES_WITH_DEALS = new Set([ Route.LocalPage ]);

const METERS_MULTIPLIER = 111000;

type LocationPoint = [ number, number ];

export const getDistanceInMeters = (source: LocationPoint, dest: LocationPoint) =>
  Math.sqrt((dest[0] - source[0]) ** 2 + (dest[1] - source[1]) ** 2) * METERS_MULTIPLIER;

export function mergePadding(padA: number | MapboxGL.PaddingOptions, padB: Partial<MapboxGL.PaddingOptions>): MapboxGL.PaddingOptions {

  const result = typeof padA === 'number' ? {
    right: padA,
    bottom: padA,
    top: padA,
    left: padA,
  } : { ...padA };

  // tslint:disable-next-line: forin
  for (const side in padB) {
    result[side] += padB[side];
  }

  return result;
}

export const bboxEqual = (a: BoundingBox, b: BoundingBox) => {

  if (a === b) return true;
  if (!(a && b)) return (!a && !b);
  if (a.toString() === b.toString()) return true;

  const [ [ w1, s1 ], [ e1, n1 ] ] = a;
  const [ [ w2, s2 ], [ e2, n2 ] ] = b;

  return (
    w1.toFixed(5) === w2.toFixed(5)
    && s1.toFixed(5) === s2.toFixed(5)
    && e1.toFixed(5) === e2.toFixed(5)
    && n1.toFixed(5) === n2.toFixed(5)
  );
};


export const wsg84ToGoogleGrid = (lng: number, lat: number, zoom: number) => {
  const sinPhi = Math.sin(lat * Math.PI / 180);
  const normX = lng / 180;
  const normY = (0.5 * Math.log((1 + sinPhi) / (1 - sinPhi))) / Math.PI;
  const y = Math.pow(2, zoom) * ((1 - normY) / 2);
  const x = Math.pow(2, zoom) * ((normX + 1) / 2);

  return {
    x: Math.floor(x),
    y: Math.floor(y),
    z: zoom,
  };
};

export const boundsToTileRange = (bbox: BoundingBox, zoom: number): ITileRange => {
  const [ [ w, s ], [ e, n ] ] = bbox;

  const ne = wsg84ToGoogleGrid(e, n, zoom);
  const sw = wsg84ToGoogleGrid(w, s, zoom);

  return {
    x1: sw.x,
    y1: ne.y,
    x2: ne.x + 1,
    y2: sw.y + 1,
  };
};

export const getId = <T extends { id: PoiId }>({ id }: T) => id;

// Danger zone
// this function uses mapbox private API

const projectsLayers = [
  'heb_listings_dots',
  'heb_listings_dots_promoted',
  'heb_listings_markers_promoted',
  'heb_listings_dots_project_logo',
  'heb_listings_markers_project_logo',
];
const bulletinsLayers = [
  'eng_listings_dots',
  'heb_listings_dots',
  'eng_listings_markers',
  'heb_listings_markers_developer',
  'heb_listings_markers_2ndHand',
];

const getRelevantLayers = (feature: GeoJSON.Feature<any, any>, externalLayer: string): string[] => {
  switch (feature.properties.type) {
    case 'bulletin':
      return bulletinsLayers;
    case 'project':
      return projectsLayers;
  }

  return externalLayer ? [ externalLayer ] : null;
};

const getIconSize = (map: MapboxGL.Map, feature: GeoJSON.Feature<any, any>, externalLayer?: string): [ number, number, number, number ] => {
  let width: number;
  let height: number;
  let xOffset = 0;
  let yOffset = 0;

  const zoom = map.getZoom();
  const relevantLayers = getRelevantLayers(feature, externalLayer);

  for (const layerName of relevantLayers) {

    const layer: MapboxGL.Layer = map.getLayer(layerName);

    if (
      (layer.minzoom || 0) < zoom
      && (layer.maxzoom || Infinity) > zoom
    ) {
      switch (layer.type) {
        case 'symbol': {
          // @ts-ignore
          const imageExpr = layer.layout._values['icon-image'].value;
          const iconName = imageExpr.kind === 'constant' ? { name: imageExpr.value } : imageExpr.evaluate({ zoom }, feature);
          // @ts-ignore
          const iconSizeMultiplier = layer.layout._values['icon-size'].value.value;
          if (iconName && iconName.name && (map as any).style.imageManager.images[iconName.name]) {
            const { data, pixelRatio } = ((map as any).style.imageManager.images[iconName.name]);
            const divider = pixelRatio * 2;
            [ width, height ] = [
              data.width * iconSizeMultiplier / divider,
              data.height * iconSizeMultiplier / divider,
            ];

            // @ts-ignore
            const iconOffsetExpr = layer.layout._values['icon-offset'].value;
            if (iconOffsetExpr.kind === 'constant') {
              const [ x, y ] = iconOffsetExpr.value;
              xOffset = x * iconSizeMultiplier;
              yOffset = y * iconSizeMultiplier;
            }
          }

          break;
        }
        case 'circle': {
          // @ts-ignore
          const circleRadiusExpr = layer.paint._values['circle-radius'].value;
          // @ts-ignore
          const circleStrokeWidthExpr = layer.paint._values['circle-stroke-width'].value;

          const circleRadius = circleRadiusExpr.evaluate({ zoom }, feature);
          const circleStrokeWidth = circleStrokeWidthExpr.evaluate({ zoom }, feature);
          width = height = circleRadius + circleStrokeWidth;
        }
      }
    }
  }

  return [ width, height, xOffset, yOffset ];
};

// ^^^^^^^^^^^^^^^^^^^^^

const PREVIEW_OFFSET = 0;

const getOffset = ([ w, h, xOffset, yOffset ]: [ number, number, number, number ]): any => {
  w += PREVIEW_OFFSET;
  h += PREVIEW_OFFSET;
  return {
    'right': [ -w + xOffset, -h ],
    'left': [ w - xOffset, -h ],
    'bottom': [ 0, -h + yOffset ],
    'bottom-left': [ w, 0 ],
    'bottom-right': [ -w, 0 ],
    'top-right': [ -w, -h ],
    'top-left': [ w, -h ],
    'top': [ 0, h + yOffset ],
  };
};

export const getFirstFeature = (c: GeoJSON.FeatureCollection<any, any>) => c && c.features && c.features.length ? c.features[0] : null;

export const getOffsetForFeatures = shallowMemo((map: MapboxGL.Map, collection: GeoJSON.FeatureCollection<any, any>, layerName?: string) => {
  let iconSize: [ number, number, number, number ] = [ 0, 0, 0, 0 ];
  const firstFeature = getFirstFeature(collection);
  if (firstFeature) {
    iconSize = getIconSize(map, firstFeature, layerName);
  }

  return getOffset(iconSize);
}, ([ _1, c1 ], [ _2, c2 ]) => {
  const f1 = getFirstFeature(c1);
  const f2 = getFirstFeature(c2);
  return f1 === f2;
});

export const dataLayerTypeToLayerName: Partial<Record<DataLayerPoiType, string>> = {
  'school': 'DL_gen_schools_points',
  'park': 'DL_gen_parks_points',
  'busStation': 'DL_bus_stations',
  'trainStation': 'DL_train_stations',
  'newConstruction': 'DL_gen_construction',
  'dogPark': 'DL_gen_dogParks_points',
  'deal': 'IL_sold',
};

export const dataLayersToRender = [ ...DATA_LAYERS_POI_TYPES ];
export const mapDataLayersByMarket = (market : MarketplaceType) => [ ...(market === MarketplaceType.Residential ? DATA_LAYERS_POI_TYPES : DATA_LAYERS_COMMERCIAL_POI_TYPES) ];

export const genImageNameById = (id: string, logo: string, isActive?: boolean) =>
  `project-logo-${id}-${logo}-${isActive ? '-active' : ''}`;

export const contentPaddingLeftMap: Record<ContentWidthMode, number> = {
  [ContentWidthMode.Default]: 808,
  [ContentWidthMode.Wide]: 943,
  [ContentWidthMode.Narrow]: 0,
};
