import React from 'react';
import { useLocale } from 'locale';
import { featuredAmenities } from 'components/unit-page/amenities/config';
import { Amenity, PetPolicy } from 'utils/entities';
import IconAmenities, { SectionHeader } from './IconAmenities';
import { nonNull } from 'helpers';

interface AmenitiesProps {
  amenities: Record<Amenity, boolean>;
  unitPetPolicy?: PetPolicy;
  displayAmenities?: Array<{
    icon: React.ReactNode,
    key: Amenity,
  }>;
}

const Amenities: React.FunctionComponent<AmenitiesProps> = ({
  amenities,
  unitPetPolicy,
  displayAmenities,
}) => {
  const { t } = useLocale();

  const amenitiesTypes = (displayAmenities || featuredAmenities)
    .map(({ icon, key }) => ({
      icon,
      active: amenities[key],
      title: t('unitPage.amenities.featured', { key, active: amenities[key], unitPetPolicy }),
    }))
    .filter(({ active }) => nonNull(active));

  return (
    <div data-auto="amenities-block">
      <SectionHeader weight="bold">{t('unitPage.amenitiesHeader')}</SectionHeader>
      <IconAmenities amenitiesTypes={amenitiesTypes} />
    </div>
  );
};

export default React.memo(Amenities);

