import React, { useState } from 'react';
import { FooterSection, FooterLink, LinkType } from './types';
import { useLocale } from 'locale';
import { LinksSection, LinksItems, LinksItem, CollapseButton } from './styled';
import { SmallText } from 'ds/components/typography';
import ChevronIcon from 'assets/svg/chevron-down.svg';
import { Link } from 'components/link';
import { State as RouteState, Route, DecodedRouteParams } from 'config/routes';
import { DealType } from 'utils/entities';
import { marketplaceByRouteName } from 'store/state/selectors/router';
import { searchRouteByMarketplace } from 'utils/marketplaceRoutes';

const COLLAPSED_SECTION_LINKS_LIMIT = 10;

export const routeReset: Partial<DecodedRouteParams> = {
  bbox: undefined,
  page: undefined,
};

function getRouteNameForLink(linkRouteName: Route, route: RouteState) {
  const marketplace = marketplaceByRouteName(route.name);
  const defaultSearchRoute = searchRouteByMarketplace(marketplace);
  return linkRouteName || defaultSearchRoute;
}

function maybeAddAbsolutePath(href: string): string {
  return href.startsWith('/') ? href : `//${href}`;
}

export const Section: React.FC<{ section: FooterSection; route: RouteState; }> = ({ section, route }) => {
  const [ isCollapsed, setIsCollapsed ] = useState(true);
  const { t } = useLocale();
  const dealType = route.params.dealType || DealType.Rent;

  const resetScroll = () => {
    window.scrollTo(0, 0);
  };

  const renderLink = (link: FooterLink) => {
    const target = link.newTab ? '_blank' : '_self';

    switch (link.type) {
      case LinkType.RouterLink: {
        const routeName = getRouteNameForLink(link.routeName, route);
        const routeParams = link.routeName
          ? link.params
          : { ...route.params, ...routeReset, dealType, ...link.params };
        return (
          <LinksItem key={link.id + link.text}>
            <Link
              target={target}
              routeName={routeName}
              routeParams={routeParams}
              onClick={!link.newTab ? resetScroll : undefined}
            >
              {link.text}
            </Link>
          </LinksItem>
        );
      }
      case LinkType.ALink:
        return (
          <LinksItem key={link.id + link.text}>
            <a target={target} href={maybeAddAbsolutePath(link.href)}>{link.text}</a>
          </LinksItem>
        );
      default:
        return null;
    }
  };

  return (
    <LinksSection key={section.type}>
      <SmallText weight="bold">{t('footer.sectionLabel', { type: section.type })}</SmallText>
      <LinksItems data-auto={`footer-links-${section.type}`}>
        {section.links.length <= COLLAPSED_SECTION_LINKS_LIMIT || !isCollapsed
          ? section.links.map(renderLink)
          : section.links.slice(0, COLLAPSED_SECTION_LINKS_LIMIT).map(renderLink)
        }
        {section.links.length > COLLAPSED_SECTION_LINKS_LIMIT ? (
          <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed}>
            {isCollapsed ? t('collapsable.showMore') : t('collapsable.showLess')}
            <ChevronIcon width={20} height={20} />
          </CollapseButton>
        ) : null}
      </LinksItems>
    </LinksSection>
  );
};
