import React from 'react';
import { GenericFilterProps, RangeTuple } from '../types';
import { Section, SectionBody, SectionTitle } from '../styled/common';
import Range from 'ds/components/input/RangeField/RangeField';
import { useLocale } from 'locale';
import { parseRangeValues, prepareRangeValues } from '../utils';
import { MarketplaceType } from 'utils/entities';


const VISIBLE_ITEMS_COUNT = 5;

const Employees: React.FC<GenericFilterProps <'numberOfEmployeesRange'>> = ({
  type,
  config,
  marketplace,
  intermediateValue,
  setIntermediateValue,
}) => {
  const { t } = useLocale();
  const onChange = ([ from, to ]: RangeTuple<number>) => {
    setIntermediateValue([ parseRangeValues(from), parseRangeValues(to) ]);
  };
  const { employeesOptions } = config;
  const isCommercial = marketplace === MarketplaceType.Commercial;
  const options = employeesOptions.map((v) => ({
    value: v,
    label: t('filters.employeesLabels', { employees: v }) || `${v}`,
  }));

  return (
    <Section data-section-filter-name={type} data-auto-filter-name={type} data-auto={`filter-${type}`}>
      <SectionTitle isBold>{t('filters.employees', { isCommercial })}</SectionTitle>
      <SectionBody>
        <Range
          expandOptionsPosition="top"
          onChange={onChange}
          values={prepareRangeValues(intermediateValue)}
          options={options}
          readOnly
          fullWidth
          visibleItemsCount={VISIBLE_ITEMS_COUNT}
        />
      </SectionBody>
    </Section>
  );
};

export default Employees;
