import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';

export const MapViewLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;

export const MapViewLabelText = styled(Text)`
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutrals.white};
`;
