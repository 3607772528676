import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { SET_PREVIEW_DOC_ID, SUBMIT_WIZARD } from 'store/state/homepageWizard/types';
import * as actions from 'store/state/router/actions';
import { Route } from 'config/routes';
import {
  homepageWizardCommuteTypeSelector,
  homePageWizardPreviewDocIdSelector,
  homepageWizardSelector,
} from 'store/state/homepageWizard/selectors';
import { HomepageWizardState } from 'store/state/homepageWizard';
import config from 'config';
import { isEmptyRange } from 'components/filters/utils';
import { userPrioritiesSelector } from 'store/state/domainData/selectors';
import { CommuteType } from 'utils/entities';
import { setWizardOpen } from 'store/state/homepageWizard/actions';
import { fetchInsights } from 'store/sagas/routing/handlers/loadInsights';
import {
  addSaveSearchDataFeed,
  homepageWizardRouteParamsSelector,
} from 'store/state/selectors/savedSearchPage';
import { mutate } from 'store/state/mutationsResponse/actions';
import { MutationType } from './apiService/types';
import { mutateWorker } from './apiService/mutationsWatcher';
import { routeSelector } from 'store/state/selectors/router';
import { saveRecentSearch } from './routing/handlers/search/saveLocalDataRecentSearch';
import { LocalStorage } from 'utils/localStorage';
import { IS_WIZARD_COMPLETED } from 'consts/localStorageKeys';

function* submitWizardWorker() {
  const homepageWizardState: HomepageWizardState = yield select(homepageWizardSelector);
  const { commutePreference } = homepageWizardState;
  const hasCommute = Boolean(commutePreference.location);
  const hasMaxCommute = Object.values(commutePreference.maxCommute).some(v => v !== undefined);
  const purchasingReason = homepageWizardState.purchasingReason;
  const { params } = yield select(routeSelector);
  const { priceRange, roomsRange, moveIn } = homepageWizardState;

  if (hasCommute || hasMaxCommute || moveIn) {
    const commuteType: CommuteType = yield select(homepageWizardCommuteTypeSelector);

    const variables = {
      ...(hasCommute || hasMaxCommute ? {
        commutePreference: {
          ...commutePreference,
          commuteType,
        },
      } : {}),
      ...(moveIn ? { userInfo: { moveIn } } : {}),
    };

    yield call(mutateWorker, mutate({
      mutationType: MutationType.UpdateUserProfile,
      meta: {
        variables,
      },
    }));
  }

  const routeParams = yield select(homepageWizardRouteParamsSelector);

  const recentSearchQuery = yield select(addSaveSearchDataFeed);
  yield call(saveRecentSearch, recentSearchQuery);

  yield put(actions.navigateTo(Route.Search, {
    ...params,
    ...routeParams,
  }));

  yield put(setWizardOpen({ isOpen: false }));

  if (purchasingReason) {
    const priorities = yield select(userPrioritiesSelector);

    yield put(mutate({
      mutationType: MutationType.SaveUserData,
      meta: {
        variables: {
          purchasePurpose: purchasingReason,
          priorities,
          docId: homepageWizardState.docId || config.cityTerm,
          filters: {
            dealType: homepageWizardState.dealType,
            roomsRange: isEmptyRange(roomsRange) ? undefined : roomsRange,
            priceRange: isEmptyRange(priceRange) ? undefined : priceRange,
            amenities: undefined,
          },
        },
      },
    }));
  }

  if (LocalStorage.get(IS_WIZARD_COMPLETED) === undefined) {
    LocalStorage.set(IS_WIZARD_COMPLETED, true);
  }
}

function* setPreviewDocIdWorker() {
  const previewDocId: string = yield select(homePageWizardPreviewDocIdSelector);
  yield fork(fetchInsights, { docId: previewDocId, user: null, withGeoData: false });
}

export function* homepageWatcher() {
  yield takeEvery(SUBMIT_WIZARD, submitWizardWorker);
  yield takeEvery(SET_PREVIEW_DOC_ID, setPreviewDocIdWorker);
}
