import React, { useMemo } from 'react';
import { useLocale, WithLocaleProps } from 'locale';
import {
  getBuildingClassKey,
  BuildingClass,
} from 'utils/entities';
import {
  Body,
  PropertyDetailsText,
  NewPropertyDetailsSeparator,
  PropertyDetailsSeparator,
  TagsWrapper,
} from 'ds/components/ListingCard';
import {
  FirstLineLabel,
  LineLabels,
  SecondLineLabel,
  ThirdLineLabel,
  FirstLineWrapper,
  CommercialTag,
  MarketplaceTypeLabel,
  CommercialBulletinPromotion,
} from '../styled';
import { AgentTags } from '../AgentTags';
import { BreakpointValue } from 'consts/breakpoints';
import NotificationTag from '../NotificationTag';
import renderLineItem from 'components/labelLineItem';
import { UniversalCardLineLabels, useUniversalCardAddress } from '../utils';
import { CommercialBulletinUniversalCardProps, ExtraProps } from 'components/cards/types';


export interface UniversalCommercialBulletinCardProps extends CommercialBulletinUniversalCardProps, ExtraProps {
  breakpoint: BreakpointValue;
  carousel: JSX.Element;
  handleOnMouseEnter: () => void;
  handleOnMouseLeave: () => void;
}

const getInfoLineConfig = (area: number, price: number, size: number, floor: number, buildingClass: BuildingClass, { t, formatArea, formatMoney }: WithLocaleProps, breakpoint: BreakpointValue) => {
  const isMobile = breakpoint <= 2;
  const separator = isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={undefined} />;
  return [
    {
      label: UniversalCardLineLabels.BuildingClass,
      separator,
      showCondition: Boolean(buildingClass),
      render: () => (
        <PropertyDetailsText data-auto="property-class">
          {t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial: true })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Size,
      separator,
      showCondition: Boolean(size),
      render: () => (
        <PropertyDetailsText data-auto="property-size">
          {formatArea(size)}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.PPM,
      separator,
      showCondition: Boolean(area && price),
      render: () => (
        <PropertyDetailsText data-auto="property-ppm">
          {t('widget.prices.pricePerSqMeter', { price: formatMoney(price / area) })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator,
      showCondition: Boolean(floor),
      render: () => (
        <PropertyDetailsText data-auto="property-floor">
          {t('listingCard.floorLabel', { floor })}
        </PropertyDetailsText>
      ),
    },
  ];
};

const UniversalCommercialBulletinCard: React.FC<UniversalCommercialBulletinCardProps> = (props) => {

  const localeProps = useLocale();
  const { t, formatMoney } = localeProps;
  const {
    withPaddingBottom,
    city,
    notificationTags,
    carousel,
    breakpoint,
    handleOnMouseEnter,
    handleOnMouseLeave,
    buildingClass,
    size,
    floor,
    area,
    price,
    qualityClass,
    generalCondition,
    showMarketplaceType,
    showPromotion,
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    resolutionPreferences,
    isSearchInWholeCountry,
    hideTags,
  } = props;

  const [ notificationTag ] = notificationTags || [];
  const isOnSeparateLine = Boolean(props.isExclusive && props.madadSearchResult);

  const infoLineConfig = useMemo(() => {
    return getInfoLineConfig(area, price, size, floor, buildingClass, localeProps, breakpoint).filter(({ showCondition }) => showCondition);
  }, [ area, price, size, buildingClass, localeProps, breakpoint, getInfoLineConfig ]);

  const primaryAddress = useUniversalCardAddress(
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    isSearchInWholeCountry
  );

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {carousel}
      <Body withPaddingBottom={withPaddingBottom} className="universal-card-body-wrapper">
        {showMarketplaceType ? (
          <MarketplaceTypeLabel isCommercial>
            {t('listingCard.marketplaceTypeLabel', { isCommercial: true })}
          </MarketplaceTypeLabel>
        ) : null}
        {showPromotion ? (
          <CommercialBulletinPromotion>
            {t('listingCard.commercialBulletinPromotion')}
          </CommercialBulletinPromotion>
        ) : null}
        <LineLabels data-auto="property-details">
          <FirstLineLabel isHighlighted={false} weight="bold">
            <FirstLineWrapper
              data-auto="property-price"
              isFlexColumn={isOnSeparateLine}
            >
              {formatMoney(props.price)}
              {props.isAgent ? (
                <AgentTags
                  isExclusive={props.isExclusive}
                  isOnSeparateLine={isOnSeparateLine}
                />
              ) : null}
            </FirstLineWrapper>
          </FirstLineLabel>
          <SecondLineLabel>
            {infoLineConfig.map(renderLineItem)}
          </SecondLineLabel>
          <ThirdLineLabel>
            <PropertyDetailsText>
              {primaryAddress}
            </PropertyDetailsText>
          </ThirdLineLabel>
          {notificationTag && city ? <PropertyDetailsText faded>{city}</PropertyDetailsText> : null}
        </LineLabels>
        {notificationTag ? (
          <NotificationTag tag={notificationTag} />
        ) : null}
        {!hideTags && !showMarketplaceType && (generalCondition || qualityClass) ? (
          <TagsWrapper className="tags-wrapper">
            {generalCondition ? <CommercialTag>{t('filters.conditionsOptions', { value: generalCondition })}</CommercialTag> : null}
            {qualityClass ? <CommercialTag>{t('commercial.listing.offerDetails.qualityClass', { value: qualityClass })}</CommercialTag> : null}
          </TagsWrapper>
        ) : null}
      </Body>
    </div>
  );
};

export default UniversalCommercialBulletinCard;
