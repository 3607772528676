import { IInsight, InsightType } from 'utils/entities';
import { createSelector } from 'reselect';
import { insightsListSelector } from 'store/state/domainData/selectors';
import { flow } from 'lodash';
import { carouselDataMapper } from 'store/state/selectors/utils';


export const getNuisancesFromInsights = (insights: IInsight[]) => insights ? insights.filter(i => i.category === InsightType.Nuisances) : [];

const getNuisancesInsights = createSelector([
  insightsListSelector,
], getNuisancesFromInsights);

export const nuisancesCarouselDataSelector = createSelector([
  getNuisancesInsights,
], carouselDataMapper);

export const nuisancesIdsSelector = createSelector([
  getNuisancesInsights,
], (insights) => insights.map(({ id }) => id));

export const hasNuisancesDataSelector = flow(nuisancesCarouselDataSelector, (data) => data.length > 0);
