import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from '@emotion/styled';
import { SnapPosition, getNextSnapOnBarClick, getNextSnap, isScroll, momentum, almostEqual } from 'components/xy-drawer/utils';
import { gestureInitialState, GestureState, isHorizontal, applyIOSImmediateHack } from 'helpers/pan-responder';
import { usePanResponder } from 'hooks/usePanResponder';
import { useWindowSize } from 'hooks/useWindowSize';
import { THRESHOLD } from 'components/xy-drawer/magicNumbers';
import { useSpring, UseSpringProps, animated, SpringConfig } from 'react-spring';
import { PositionProperty } from 'csstype';
import { XYDrawerWrapper, XYDrawerFakeBG, XYDrawerBG, XYDrawerBarHandle } from 'components/xy-drawer/styled';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { createPubSub } from 'utils/pubSub';
import { STICKY_ROOT } from 'consts/rootNodes';

interface XYDrawerState {
  gesture: GestureState;
  snap: SnapPosition;
  contentHeight: number;
}

interface SpringProps {
  yValue: number;
}

type SpringOpts = SpringProps & UseSpringProps<SpringProps>;

const SPRING_CONFIG: SpringConfig = {
  mass: 0.5,
  tension: 650,
  friction: 45,
};

function getOffset(snap: SnapPosition, windowHeight: number, contentHeight: number) {
  if (windowHeight === null) return null;

  switch (snap) {
    case SnapPosition.Collapsed:
      return windowHeight;
    default:
      return windowHeight - contentHeight;
  }
}

const LocalXYDrawerWrapper = styled(XYDrawerWrapper)<{ coverModal?: boolean }>`
  z-index: ${({ coverModal }) => coverModal ? 1002 : undefined};
`;

const OverlayBg = styled.div<{ coverModal?: boolean }>`
  background: rgba(0, 0, 0, 0.50);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ coverModal }) => coverModal ? 1002 : undefined};
`;

const AnimatedWrapper = animated(LocalXYDrawerWrapper);
const AnimatedXYDrawerFakeBG = animated(XYDrawerFakeBG);

interface BottomSheetProps {
  onCollapse?: () => void;
  children: React.ReactNode;
  coverModal?: boolean;
}
export interface BottomSheetRef {
  collapse: () => Promise<void>;
}

export const BottomSheet = forwardRef<BottomSheetRef, BottomSheetProps>((props, ref) => {
  const [ state, setState ] = useState<XYDrawerState>({
    gesture: gestureInitialState,
    snap: SnapPosition.Collapsed,
    contentHeight: 0,
  });

  const pubSubRef = useRef(createPubSub<void>());

  const collapse = useCallback(() => {
    setState((prev) => ({ ...prev, snap: SnapPosition.Collapsed }));
  }, [ setState ]);

  useImperativeHandle(ref, () => ({
    collapse: () => {
      collapse();
      return new Promise((resolve) => {
        const unsub = pubSubRef.current.subscribe(() => {
          resolve();
          unsub();
        });
      });
    },
  }));

  const isExpanded = state.snap === SnapPosition.Expanded;

  const setGestureState = useCallback((gesture: GestureState) => {
    setState((prevState) => ({ ...prevState, gesture }));
  }, [ setState ]);

  const handleResize: ResizeObserverCallback = useCallback(([ entry ]) => {
    setState((prev) => ({ ...prev, contentHeight: entry.contentRect.height, snap: SnapPosition.Default }));
  }, [ setState ]);

  const containerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(handleResize, [ containerRef ]);

  const handleBarHandleClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      snap: getNextSnapOnBarClick(prev.snap, false),
    }));
  }, [ setState ]);

  const panHandlers = usePanResponder({
    onStart: setGestureState,
    onMove: setGestureState,
    onEnd: (gestureState) => {
      setState((prevState) => ({
        ...prevState,
        gesture: gestureState,
        snap: getNextSnap(prevState.snap, prevState.gesture.dy, false),
      }));
    },
    shouldGrant: (gs) => !isHorizontal(gs),
    shouldTerminate: (gs) => isScroll(gs.dy, window.scrollY, isExpanded),
    shouldPreventDefault: (gs, e) => gs.__granted && e.cancelable,
  });

  const [ , windowHeight ] = useWindowSize({ unsafelyForSSRInitialize: true });

  const getOffsetParams = [ windowHeight, state.contentHeight ] as const;
  const yOffset = getOffset(state.snap, ...getOffsetParams);
  const dy = state.gesture.__granted ? state.gesture.dy : 0;

  const min = getOffset(getNextSnap(state.snap, -THRESHOLD, false), ...getOffsetParams);
  const max = getOffset(getNextSnap(state.snap, THRESHOLD, false), ...getOffsetParams);

  const finalYOffset = momentum(yOffset + dy, min, max);

  const shouldDisableTransitions = React.Children.count(props.children) === 0 || yOffset === null;

  const springProps: SpringOpts = applyIOSImmediateHack({
    yValue: finalYOffset || 0,
    immediate: state.gesture.down || shouldDisableTransitions,
    config: SPRING_CONFIG,
    onFrame: (val) => {
      if (almostEqual(yOffset, (val as any as SpringProps).yValue, 5) && state.snap === SnapPosition.Collapsed) {
        props.onCollapse();
        pubSubRef.current.dispatch();
      }
    },
  }, state.gesture.down, dy);

  const spring = useSpring(springProps);

  const interpolateIsStatic = (y: number) => (
    almostEqual(yOffset, y, 1) && state.snap === SnapPosition.Expanded
  );

  return (
    <OverlayWrapper portalId={props.coverModal ? STICKY_ROOT : undefined}>
      <OverlayBg coverModal={props.coverModal} onClick={collapse} />
      <AnimatedWrapper
        {...panHandlers}
        coverModal={props.coverModal}
        style={{
          position: spring.yValue.to((y): PositionProperty => (
            interpolateIsStatic(y) ? 'static' : 'fixed'
          )),
          transform: spring.yValue.to((y) => `translate3d(0, ${y}px, 0)`),
        }}
      >

        <XYDrawerBG ref={containerRef} isExpanded={isExpanded}>
          <XYDrawerBarHandle onClick={handleBarHandleClick} down={isExpanded} />
          {props.children}
        </XYDrawerBG>

        <AnimatedXYDrawerFakeBG
          style={{
            display: spring.yValue.to((y) => (
              interpolateIsStatic(y) ? 'none' : 'block'
            )),
          }}
        />
      </AnimatedWrapper>
    </OverlayWrapper>
  );
});
