import React, { useRef } from 'react';
import Modal from 'ds/components/modal';
import { useForm } from 'react-final-form-hooks';
import { ReportForm } from './ReportUploadBulletinForm';
import { ReportPopupContentWrapper, Description } from './styled';
import { Button } from 'ds/components/button';

export interface ReportPopupViewProps {
  isPopupModalOpen?: boolean;
  onSubmit: (submitPopupData: ISubmitPopupData) => void;
  onClosePopup: () => void;
  submitButtonText: string;
  popupTitle: string;
  description?: string;
}

export interface ReportFormValues {
  commentMessage: string;
  userEmail: string;
}

export interface ISubmitPopupData {
  message: string;
  email: string;
}

const FormInitialValues = {
  commentMessage: '',
  userEmail: '',
};

export const ReportPopupView: React.FC<ReportPopupViewProps> = ({
  isPopupModalOpen,
  onClosePopup,
  onSubmit,
  popupTitle,
  submitButtonText,
  description,
}) => {
  const commentMessageRef = useRef(null);

  const onSubmitForm = (values: any) => {
    // server wants us to send actual label strings
    const submitPopupDataObj: ISubmitPopupData = {
      message: values.commentMessage,
      email: values.userEmail,
    };

    onSubmit(submitPopupDataObj);
    onClose();
  };

  const { form, valid } = useForm<ReportFormValues>({
    onSubmit: onSubmitForm,
    initialValues: FormInitialValues,
    validateOnBlur: true,
  });

  if (!isPopupModalOpen) return null;

  const onClose = () => {
    onClosePopup();
    setTimeout(() => form.reset());
  };

  return (
    <Modal
      title={popupTitle}
      isOpen={isPopupModalOpen}
      onClose={onClose}
      buttons={(
        <Button
          data-auto="report-submit-button"
          disabled={!valid}
          onClick={form.submit}
          fullWidth
          size="large"
        >
          {submitButtonText}
        </Button>
      )}
    >
      <>
        {description ? <Description>{description}</Description> : null}
        <ReportPopupContentWrapper>
          <ReportForm
            inputWrapperRef={(el: HTMLDivElement) => commentMessageRef.current = el}
            formInstance={form}
          />
        </ReportPopupContentWrapper>
      </>
    </Modal>
  );
};
