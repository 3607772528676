import { connect } from 'react-redux';
import { Profile } from './Profile';
import { State, RootAction } from 'store/state';
import { Dispatch } from 'redux';
import { setAuthModal } from 'store/state/app/actions';
import { MutationType, IUpdateUserProfileVariables } from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import { isAgentSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { deregisterUserResponseSelector } from 'store/state/mutationsResponse/selectors';


const mapStateToProps = (state: State) => ({
  user: userProfileSelector(state),
  isAgent: isAgentSelector(state),
  deregisterUserResponse: deregisterUserResponseSelector(state, null),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onUpdateUser: (data: IUpdateUserProfileVariables, key: string) => dispatch(inlineMutate(MutationType.UpdateUserProfile, data, key)),
  onUserDelete: () => dispatch(inlineMutate(MutationType.DeregisterUser, {})),
  onOpenResetPasswordModal: () => dispatch(setAuthModal({ type: AuthenticationModalType.ResetPassword }, { isUserInitiated: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
