import React from 'react';
import { ShowAt } from 'consts/breakpoints';
import { DesktopSuggestionsWrapper, MobileSubtitle, MobileSuggestionsWrapper } from './styled';
import { useLocale } from 'locale';

interface SuggestionsWrapperProps {
  children: React.ReactNode;
  searchStr: string;
}

export const SuggestionsWrapper: React.FC<SuggestionsWrapperProps> = ({ children, searchStr }) => {
  const { t } = useLocale();
  return (
    <>
      <ShowAt from={3}>
        <DesktopSuggestionsWrapper data-auto="autocomplete-suggestions">
          {children}
        </DesktopSuggestionsWrapper>
      </ShowAt>
      <ShowAt to={2}>
        <MobileSuggestionsWrapper data-auto="autocomplete-suggestions">
          {searchStr ? null : (
            <MobileSubtitle weight="medium">
              {t('autocompleteInput.multi.suggestionsTitle')}
            </MobileSubtitle>
          )}
          {children}
        </MobileSuggestionsWrapper>
      </ShowAt>
    </>
  );
};
