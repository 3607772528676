import { flow, head, inRange, isNumber } from 'lodash';
import { createSelector } from 'reselect';
import { capitalize } from 'utils';
import { DealType, IBulletin, InsightDescriptor, PoiId, ResolutionPreference } from 'utils/entities';
import { Route } from 'config/routes';
import { routeNameSelector } from 'store/state/selectors/router';
import {
  IBulletinPriceEstimationRecord,
  IPriceEstimationRecord,
  PricesAccordionSection,
  RawHebrewHistoryItem,
  RawHistoryItemAgent,
  PricesAccordionTab,
  HistoryHebrewItem,
  PricesSectionType,
  HistoryItemAgent,
  IRawEstimation,
  RawHistoryItem,
  DetailedAction,
  PricesTabType,
  HistoryItem,
} from './types';
import {
  insightsLoadingSelector,
  makeInsightsByTypeSelector,
  unitPageBulletinSelector,
} from 'store/state/domainData/selectors';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { State } from 'store/state';
import { dealTypeSelector } from 'store/state/selectors/search';
import {
  getDefaultPrimaryParams,
  PrimaryAccurateParams,
  PrimaryNeighborhoodParams,
  PrimaryStreetParams,
} from 'utils/addressFormatter';
import { PricesHistorySummary } from 'store/state/selectors/insights/summaryTypes';


const COMPARE_ITEMS_LIMIT = 8;

const currentUnitLabel = 'מודעה זו';

const comparablePricesSelector = flow(makeInsightsByTypeSelector(InsightDescriptor.ComparablePrices), head);
const historyPricesSelector = flow(makeInsightsByTypeSelector(InsightDescriptor.Prices), head);

export const pricesIdsSelector = createSelector([
  historyPricesSelector,
  comparablePricesSelector,
], (historyInsight, comparableInsight) => ({
  [PricesSectionType.LocalHistory]: historyInsight ? historyInsight.id : null,
  [PricesSectionType.ComparableListings]: comparableInsight ? comparableInsight.id : null,
  [PricesSectionType.Listing]: comparableInsight ? comparableInsight.id : null,
}));

const mapHebrewAgent = (rawAgent: RawHistoryItemAgent): HistoryItemAgent => {
  if (!rawAgent) {
    return null;
  }

  return {
    agentId: rawAgent.agentId,
    agentName: rawAgent.agentName,
    agentImageUrl: rawAgent.agentLogo,
    agencyId: rawAgent.officeId,
    agencyName: rawAgent.officeName,
    agencyImageUrl: rawAgent.officeLogo,
  };
};

const parseDistance = (distance: number): number => isNumber(distance) ? Math.ceil(distance) : null;

const mapAddress = (addressRecord: RawHistoryItem['addressRecord']): string => {
  if (!(addressRecord && addressRecord.stName)) return null;
  const address = `${addressRecord.stName} ${addressRecord.hNo ? `${addressRecord.hNo}` : ''}`;
  return capitalize(address);
};

type EitherHistoryItem = RawHistoryItem | RawHebrewHistoryItem;
type FromRaw<T extends EitherHistoryItem> = T extends RawHistoryItem ? HistoryItem : HistoryHebrewItem;


const historyDataMapper = <T extends EitherHistoryItem>(rawHistoryItems: T[]): Array<FromRaw<T>> => rawHistoryItems ? rawHistoryItems.map(item => ({
  address: mapAddress(item.addressRecord),
  date: item.date,
  prevPrice: item.revenue ? item.revenue.previousSale.price : null,
  price: item.amount,
  size: item.size,
  distance: parseDistance(item.distance),
  pricePerSqFt: item.size && item.amount ? Math.floor(item.amount / item.size) : null,
  source: item.source && item.source.toLowerCase(),
  action: item.action,
  beds: item.beds,
  type: item.type || '',
  seller: typeof item.from === 'string' ? item.from : mapHebrewAgent(item.from && item.from[0]),
  buyer: typeof item.to === 'string' ? item.to : mapHebrewAgent(item.to && item.to[0]),
  unit: item.unit || '',
  dealDetails: item.action === 'nonMarket' || item.action === 'partialSale' ? item.action : '' as DetailedAction,
  isFinal: item.final,
  unitsCount: item.propertiesCount,
  projectName: item.projectName,
  projectIds: item.projectIds,
  year: item.yearBuilt,
  id: item.saleId,
  floor: item.floor,
  totalFloors: item.totalFloors,
} as FromRaw<T>)) : [];

const TABS_ORDERED_BY_SPECIFICNESS = [ PricesTabType.Compare, PricesTabType.Unit, PricesTabType.Building, PricesTabType.Area ];

function getAccordionTabs(route: Route, hasEstimationData: boolean): PricesTabType[] {
  let tabs = new Set(TABS_ORDERED_BY_SPECIFICNESS);

  // IL's specificness order is reverse
  switch (route) {
    case Route.UnitPage:
      tabs = new Set([ PricesTabType.CompareDeals, PricesTabType.Building, PricesTabType.Area, PricesTabType.Compare ]);
      break;
    case Route.ProjectPage:
    case Route.AddressPage:
      tabs = new Set([ PricesTabType.Building, PricesTabType.Area ]);
      break;
    case Route.Sold:
      tabs = new Set([ PricesTabType.Building ]);
      break;
    case Route.LocalPage:
      tabs = new Set([ PricesTabType.Area ]);
      break;
    case Route.StreetPage:
      tabs = new Set([ PricesTabType.Street, PricesTabType.Area ]);
      break;
    default:
      tabs = new Set([]);
  }

  if (!hasEstimationData) {
    tabs.delete(PricesTabType.Compare);
    tabs.delete(PricesTabType.CompareDeals);
  }
  // LocalPage displays information of some area;
  // Area is less specific than Building and Unit, remove Building and Unit;
  if (route === Route.LocalPage) {
    tabs.delete(PricesTabType.Building);
    tabs.delete(PricesTabType.Unit);
  }

  return [ ...tabs ];
}
const priceEstimationInsightSelector = flow(makeInsightsByTypeSelector(InsightDescriptor.PricesEstimation), head);
export const priceEstimationInsightSummarySelector = flow(priceEstimationInsightSelector, (insight) =>
  insight ? insight.summary.nonText.data as IRawEstimation : null);

export const priceEstimationRecordsSelector = createSelector([
  priceEstimationInsightSummarySelector,
  currentPoiSelector,
], (
  data,
  currentPoi
) => {
  if (!data) return [];
  const records: IPriceEstimationRecord[] = data.refListings.map(item => referenceMapper(item));
  return records.map(item => item && currentPoi && item.type === 'bulletin' && currentPoi && item.id === currentPoi.id
      ? { ...item, currentPoiLabel: currentUnitLabel }
      : item
  ).filter(Boolean);
});

const currentBulletinHistoricalSelector = createSelector(unitPageBulletinSelector, (bulletin: IBulletin): HistoricalItem => {
  if (!bulletin) return null;

  const resolutionPreferences = bulletin.addressDetails.resolutionPreferences || ResolutionPreference.Accurate;
  const maskedAddress = getDefaultPrimaryParams({
    unitNumber: bulletin.addressDetails.unitNumber,
    streetName: bulletin.addressDetails.streetName,
    city: bulletin.addressDetails.city,
    streetNumber: bulletin.addressDetails.streetNumber,
    neighbourhood: bulletin.addressDetails.neighbourhood,
  } as PrimaryAccurateParams & PrimaryStreetParams & PrimaryNeighborhoodParams, resolutionPreferences);

  const addressArr = [
    'streetNumber' in maskedAddress ? maskedAddress.streetNumber : null,
    'streetName' in maskedAddress ? maskedAddress.streetName : null,
    'neighbourhood' in maskedAddress ? maskedAddress.neighbourhood : null,
    'city' in maskedAddress && resolutionPreferences !== ResolutionPreference.Accurate ? maskedAddress.city : null,
  ].filter(Boolean);

  const unit = bulletin.addressDetails && bulletin.addressDetails.unitNumber || null;

  const address = addressArr.join(' ');
  return ({
    id: bulletin.id,
    action: 'listed',
    address: address && address.length ? address : null,
    distance: 0,
    currentPoiLabel: currentUnitLabel,
    buyer: null,
    seller: null,
    beds: bulletin.beds ? bulletin.beds : null,
    floor: bulletin.floor ? +bulletin.floor : null,
    totalFloors: null,
    price: bulletin.price ? bulletin.price : null,
    date: null,
    size: bulletin.area ? bulletin.area : null,
    isFinal: null,
    year: bulletin.buildingYear,
    source: bulletin.source,
    unitsCount: null,
    unit,
    pricePerSqFt: bulletin.area && bulletin.price
        ? Math.floor(bulletin.price / bulletin.area)
        : null,
  });
});

const historyPricesWithCurrentUnitSelector = createSelector([
  historyPricesSelector,
  currentBulletinHistoricalSelector,
  routeNameSelector,
  dealTypeSelector,
], (insight, currentBulletinHistorical, route, dealType: DealType) => {
  if (!insight || !insight.summary || !insight.summary.nonText || !insight.summary.nonText.data) return null;
  const data = { ...insight.summary.nonText.data };
  for (const tab in data) {
    if (data[tab] && data[tab].length) {
      const mappedData = historyDataMapper(data[tab]);
      const hasCurrent = !currentBulletinHistorical || Boolean(mappedData.find((item: any) => item.id === currentBulletinHistorical.id));
      if (!hasCurrent && route === Route.UnitPage) {
        mappedData.unshift(currentBulletinHistorical);
      }
      data[tab] = mappedData;
    }
  }

  return data;
});

const mapEstimationHistoryData = <T extends EitherHistoryItem>(rawHistoryItems: IBulletinPriceEstimationRecord[], currentPoiId: PoiId): Array<FromRaw<T>> => rawHistoryItems ? rawHistoryItems.map(item => ({
  address: item.type === 'bulletin' && item.address ? item.address : (item.street ? `${item.street} ` : null) + (item.houseNumber ? `${item.houseNumber}` : null) || null,
  currentPoiLabel: item.id === currentPoiId ? currentUnitLabel : null,
  distance: parseDistance(item.distance),
  date: item.date,
  prevPrice: null,
  price: item.price,
  size: item.area,
  pricePerSqFt:
    item.pricePerSquareMeter
      ? item.pricePerSquareMeter
      : item.area && item.price
        ? Math.floor(item.price / item.area)
        : null,
  source: null,
  action: null,
  beds: item.rooms,
  type: item.type || '',
  seller: null,
  buyer: null,
  unit: '',
  dealDetails: null,
  isFinal: null,
  unitsCount: null,
  year: item.constructionYear,
  id: item.id,
  floor: null,
  totalFloors: item.totalFloors,
} as FromRaw<T>)) : [];


const estimationSectionData = createSelector([
  flow(priceEstimationInsightSummarySelector, (summary: IRawEstimation) => summary ? summary.refListings : null),
  flow(unitPageBulletinSelector, poi => poi ? poi.id : null),
], (estimationData, currentPoiId) => {
  if (!estimationData || !estimationData.length) return null;

  let compareDealsDataRaw: any[] = null;
  let compareDataRaw: any = estimationData;

  compareDealsDataRaw = estimationData
    .filter(({ type, id }) => type === 'deal' || id === currentPoiId);
  compareDataRaw = estimationData.filter(({ type }) => type === 'bulletin');

  let compareData = mapEstimationHistoryData(compareDataRaw, currentPoiId);
  let compareDealsData = mapEstimationHistoryData(compareDealsDataRaw, currentPoiId);
  if (compareData.filter(({ id }) => id !== currentPoiId).length > COMPARE_ITEMS_LIMIT) {
    const limitNumber = compareData.filter(({ id }) => id === currentPoiId).length ? COMPARE_ITEMS_LIMIT + 1 : COMPARE_ITEMS_LIMIT;
    compareData = compareData.slice(0, limitNumber);
  }
  if (compareDealsData.filter(({ id }) => id !== currentPoiId).length > COMPARE_ITEMS_LIMIT) {
    const limitNumber = compareDealsData.filter(({ id }) => id === currentPoiId).length ? COMPARE_ITEMS_LIMIT + 1 : COMPARE_ITEMS_LIMIT;
    compareDealsData = compareDealsData.slice(0, limitNumber);
  }

  return ({
    [PricesTabType.CompareDeals]: compareDealsData,
    [PricesTabType.Compare]: compareData,
  });
});

export const pricesListingsSelector = createSelector([
  routeNameSelector,
  estimationSectionData,
  historyPricesWithCurrentUnitSelector,
], (routeName, estimationData, historyData): PricesAccordionSection => {
  const tabs = getAccordionTabs(routeName, !!estimationData);
  return ({
    sectionId: PricesSectionType.Listing,
    tabs: tabs.map(tab => {
      const tableData = historyData && historyData[tab] || estimationData && estimationData[tab] || [];
      return ({
        tabId: tab,
        tableData,
      });
    }),
  });
});

export const isPricesListingsTabsWithDataSelector = createSelector([
  pricesListingsSelector,
], (section): boolean => {
  if (section.tabs && section.tabs.length) {
    for (const { tableData } of section.tabs) {
      if (tableData.length > 0) return true;
    }
  }
  return false;
});

export const pricesStateSelector = createSelector([
  (state: State) => state.insightsContext.prices,
  flow(unitPageBulletinSelector, b => b ? b.id : null),
  flow(pricesListingsSelector, (p) => p && p.tabs || null),
], (pricesState, bulletinId, tabs: PricesAccordionTab[]) => {
  if (tabs) {
    for (const tab of tabs) {
      const { tabId } = tab;
      const tableData: Array<IPriceEstimationRecord | HistoryItem> = tab.tableData;
      if (tableData && tableData.length && tableData.filter((row: IPriceEstimationRecord | HistoryItem) => !row.id || row.id && row.id !== bulletinId).length) {
        return { ...pricesState, tab: pricesState.tab || tabId };
      }
    }
  }
  const firstTab = tabs && tabs.length && tabs[0].tabId || null;

  return { ...pricesState, tab: pricesState.tab || firstTab };
});


export const accordionDataSelector = createSelector([
  historyPricesWithCurrentUnitSelector,
  priceEstimationRecordsSelector,
  routeNameSelector,
], (historyData, estimationData, routeName) => {
  const localHistoryTabs = getAccordionTabs(routeName, estimationData.length > 0);
  return ([
    localHistoryTabs && {
      sectionId: PricesSectionType.LocalHistory,
      tabs: localHistoryTabs.map(tab => ({
        tabId: tab,
        tableData: tab === PricesTabType.Compare || tab === PricesTabType.CompareDeals ? estimationData : historyData && historyData[tab]
          ? historyData[tab]
          : [],
      })),
    },
  ]).filter(Boolean) as PricesAccordionSection[];
});

export const hasPricesDataSelector = flow(accordionDataSelector, (data) => data.length > 0);

const HIGH_PRICE_THRESHOLD: number = 1.07;
const LOW_PRICE_THRESHOLD: number = 0.93;

enum PriceEstimationGrid {
  Lower = 'lower',
  BitLower = 'a_bit_lower',
  Similar = 'similar_price',
  BitHigher = 'a_bit_higher',
  Higher = 'higher',
  None = 'none',
}

const getPriceEstimationLevel = (min: number, max: number, estimatedPrice: number, bulletinPrice: number): PriceEstimationGrid => {
  const halfOfRange = (max - min) / 2;
  if (bulletinPrice < estimatedPrice * LOW_PRICE_THRESHOLD && inRange(bulletinPrice, min, max)) return PriceEstimationGrid.BitLower;
  if (bulletinPrice < min) return PriceEstimationGrid.Lower;
  if (Math.abs(estimatedPrice - bulletinPrice) < halfOfRange) return PriceEstimationGrid.Similar;
  if (bulletinPrice > estimatedPrice * HIGH_PRICE_THRESHOLD && inRange(bulletinPrice, min, max)) return PriceEstimationGrid.BitHigher;
  if (bulletinPrice > max) return PriceEstimationGrid.Higher;
  return PriceEstimationGrid.None;
};


export const referenceMapper = (raw: any, currentPoiId?: PoiId): IPriceEstimationRecord => {
  if (raw.type === 'bulletin') {
    return {
      type: 'bulletin',
      address: raw.address,
      currentPoiLabel: raw.id === currentPoiId ? currentUnitLabel : null,
      distance: parseDistance(raw.distance),
      area: raw.area,
      rooms: raw.beds || raw.rooms,
      constructionYear: raw.buildingYear,
      price: raw.price,
      pricePerSquareMeter: raw.pricePerMeter,
      location: raw.location,
      id: raw.id,
      docId: raw.docId,
      firstTimeSeen: raw.firstTimeSeen,
      poc: raw.poc,
    };
  }
  else {
    return {
      type: 'deal',
      address: (raw.street ? `${raw.street} ` : null) + (raw.houseNumber ? `${raw.houseNumber}` : null) || null,
      currentPoiLabel: raw.id === currentPoiId ? currentUnitLabel : null,
      area: raw.area,
      distance: parseDistance(raw.distance),
      soldId: raw.id,
      constructionYear: raw.buildingYear,
      location: raw.location,
      date: raw.date,
      price: raw.price,
      projectIds: raw.projectIds,
      projectName: raw.projectName,
      pricePerSquareMeter: raw.pricePerMeter,
      rooms: raw.numRooms || raw.rooms,
    };
  }
};

export const priceEstimationDataSelector = createSelector([
  insightsLoadingSelector,
  priceEstimationInsightSummarySelector,
  unitPageBulletinSelector,
  flow(pricesStateSelector, (p) => p.tab),
], (isLoading, data, bulletin, tab) => {
  if (isLoading || !data || !bulletin) {
    return {
      minEstimation: 0,
      maxEstimation: 0,
      estimatedPrice: 0,
      minPrice: 0,
      maxPrice: 0,
      estimationOffset: null,
      priceEstimationLevel: PriceEstimationGrid.None,
      currentRecord: null,
      records: [],
    };
  }

  const allRecords: IPriceEstimationRecord[] = data.refListings.map(item => referenceMapper(item));
  let records: IPriceEstimationRecord[] = [];
  switch (tab) {
    case PricesTabType.Compare:
      records = allRecords.filter(({ type }) => type === 'bulletin' );
      break;
    case PricesTabType.CompareDeals:
      records = allRecords.filter(({ type, id }) => type === 'deal' || bulletin && bulletin.id === id);
      break;
    default:
      records = allRecords;
      break;
  }


  const marketRecords = records.map(item => item.type === 'bulletin' && item.id === bulletin.id ? { ...item, address: 'מודעה זו' } : item);
  const orderedRecords = [ ...records ].sort((a, b) => a.price <= b.price ? -1 : 1);
  const minEstimation = data.boundaries.bottomBoundary;
  const maxEstimation = data.boundaries.topBoundary;
  const estimatedPrice = data.estimatedPrice;
  let minPrice = orderedRecords[0] && (orderedRecords[0].price * 0.95);
  let maxPrice = orderedRecords[orderedRecords.length - 1] && (orderedRecords[orderedRecords.length - 1].price * 1.05);
  if (!minPrice || minPrice > minEstimation) {
    minPrice = minEstimation * 0.95;
  }
  if (!maxPrice || maxPrice < maxEstimation) {
    maxPrice = maxEstimation * 1.05;
  }
  const estimationOffset =
    bulletin && bulletin.price ?
    bulletin.price > estimatedPrice * HIGH_PRICE_THRESHOLD ? 'high' :
    bulletin.price < estimatedPrice * LOW_PRICE_THRESHOLD ? 'low' :
    'accurate' :
    null;

  const currentRecord = bulletin && bulletin.id ? marketRecords.find(({ id, type }) => type === 'bulletin' && id === bulletin.id) : null;

  return {
    minEstimation,
    maxEstimation,
    estimatedPrice,
    minPrice,
    maxPrice,
    estimationOffset,
    priceEstimationLevel: getPriceEstimationLevel(minEstimation, maxEstimation, estimatedPrice, bulletin.price),
    currentRecord,
    records: marketRecords,
  };
});

type HistoricalItem = HistoryItem | HistoryHebrewItem;

export const pricesItemsCountSelector = flow(pricesListingsSelector, (s) => {
  if (!s || !s.tabs || !s.tabs.length) return 0;

  const [ tab ] = s.tabs;
  if (!tab || !tab.tableData) return 0;
  return tab.tableData.length;
});

export const historyPricesRawDataSelector = createSelector([
  historyPricesSelector,
], (insight) => {
  if (
    !insight ||
    !insight.summary ||
    !insight.summary.nonText ||
    !insight.summary.nonText.data
  ) {
    return [];
  }

  const data = insight.summary.nonText.data as PricesHistorySummary;

  return [ ...(data.building || []), ...(data.area || []), ...(data.unit || []) ];
});
