import React, { useEffect, useRef, useState } from 'react';
import { MultiInputProps, SingleInputValue } from '../types';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { noop, upperFirst } from 'lodash';
import LocationIcon from 'assets/svg/multi-autocomplete/location.svg';
import MapPinIcon from 'assets/svg/map-pin-grey.svg';
import ExpandIcon from 'assets/svg/multi-autocomplete/chevron-down.svg';
import CrossIcon from 'assets/svg/cross.svg';
import MobileSuggestionIcon from 'assets/svg/neighborhood.svg';
import Downshift from 'downshift';
import { flattenInitialSuggestions } from '../suggestions/utils';
import { IAutocompleteEntry, MarketplaceType } from 'utils/entities';
import { TextField } from 'ds/components/input/TextField';
import { STICKY_ROOT } from 'consts/rootNodes';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { Suggestions } from '../suggestions/Suggestions';
import { Text } from 'ds/components/typography';
import {
  AutocompleteInputWrapper,
  CloseIconWrapper,
  DesktopContentWrapper,
  DesktopIconWrapper,
  DesktopInputWrapper,
  ExpandIconWrapper,
  MobileContentWrapper,
  MobileIconWrapper,
  MobileOpenContentBody,
  MobileOpenContentHeader,
  MobileOpenContentWrapper,
  MobileSelectedValuesWrapper,
  MobileSubtitle,
  SELECTED_VALUE_CLASS,
  StickyDoneButton,
} from './styled';
import { useLocale } from 'locale';
import { ChipBasic } from 'ds/components/chips/Basic';
import { SuggestionsWrapper } from './SuggestionsWrapper';
import { Button } from 'ds/components/button';
import { getEntryName } from 'utils/texts';
import { KeyboardKeys } from 'utils/keyboardKeys';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { marketplaceSelector } from 'store/state/selectors/router';
import { connect, useSelector } from 'react-redux';
import { setMobileDiscoveryDisabled, setMobileDiscoveryEnabled } from 'store/state/app/actions';

const DEFAULT_INPUT_ICON = <LocationIcon width={16} height={16} />;
const DEFAULT_OPTIONS_COUNT = 4;
const CROPPED_LAST_VALUE_MIN_WIDTH = 50;
const HIDDEN_ELEMENTS_LABEL_OFFSET = 35;
const SYMBOL_WIDTH = 6;
const CHIP_PADDING = 24;
const EMPTY_ARR: IAutocompleteEntry[] = [];
const VALUES_LIMIT = 25;
const DEFAULT_CONTAINER_WIDTH = 176;

export const DESKTOP_MULTI_INPUT_WRAPPER_CLASS = 'multi-search-input-wrapper';

export const MultiLocationAutocompleteBase: React.FC<MultiInputProps> = (props) => {
  const {
    searchStr,
    onChange,
    onSearchStrChange,
    suggestedAddresses = EMPTY_ARR,
    values,
    onBlur = noop,
    onMobileClose = noop,
    overlayId = STICKY_ROOT,
    initialSuggestions,
    autoFocus = false,
    placeholder,
    loading,
    disabled,
    source,
    showType,
    onSuggest = noop,
    inputIcon = DEFAULT_INPUT_ICON,
    optionsCount = DEFAULT_OPTIONS_COUNT,
    onCancel,
    valuesLimit = VALUES_LIMIT,
    suggestionItemIcon,
    makeMobileDiscoveryDisabled,
    makeMobileDiscoveryEnabled,
  } = props;

  const [ isActive, setIsActive ] = useState(false);
  const [ wrapperWidth, setWrapperWidth ] = useState(DEFAULT_CONTAINER_WIDTH);
  const { t } = useLocale();
  const marketplace = useSelector(marketplaceSelector);
  const isCommercial = marketplace === MarketplaceType.Commercial;
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  useResizeObserver(([ entry ]) => {
    setWrapperWidth(entry.contentRect.width);
  }, [ contentWrapperRef ]);

  const inputRef = useRef<HTMLInputElement>(null);
  const currentRange = useScreenBreakpoint();
  const isMobileView = currentRange <= 2;
  const hasValues = Boolean(values && values.length);

  const inputIconWithMode = isMobileView ? <MapPinIcon width={20} height={20} /> : inputIcon;

  useEffect(() => {
    onSuggest(suggestedAddresses);
  }, [ onSuggest, suggestedAddresses ]);

  useEffect(() => {
    if (autoFocus) {
      isMobileView ? handleMobileActive() : handleDesktopActive();
    }
  }, [ autoFocus ]);

  useEffect(() => {
    // until a better solution is requested, PMs asked to make it impossible to make several value updates
    // in one edit session, so on desktop the input will deactivate and trigger an update on every change
    // if a multi-edit is enabled again, remove this condition
    if (!isMobileView && isActive && values.length) {
      onBlur();
      onSearchStrChange('');
      setIsActive(false);
    }
  }, [ values ]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchStrChange(e.currentTarget.value);
  };

  const flatInitialSuggestions = flattenInitialSuggestions(initialSuggestions);
  const allSuggestedAddresses = [ ...suggestedAddresses, ...flatInitialSuggestions ];

  const handleAddValue = (selectedValue: IAutocompleteEntry) => {
    if (selectedValue && !values.find(v => v.docId === selectedValue.docId)) {
      onSearchStrChange('');
      onChange([ ...values, selectedValue ]);
      if (isMobileView) handleInputBlur();
    }
  };

  const handleInputChange = (selectedItemDocId: string) => {
    handleAddValue(allSuggestedAddresses.find(address => address.docId === selectedItemDocId));
  };

  const makeOnRemoveHandler = (value: SingleInputValue) => (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const nextValues = values.filter(v => v.docId !== value.docId);
    onChange(nextValues);
    if (isMobileView || !nextValues.length) {
      handleInputFocus();
    }
  };

  const handleInputBlur = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDesktopActive = () => {
    setIsActive(true);
    handleInputFocus();
  };

  const handleMobileActive = () => {
    setIsActive(true);
    makeMobileDiscoveryDisabled();
  };

  const handleDesktopBlur = () => {
    handleInputBlur();
    setIsActive(false);
    onSearchStrChange('');
    onBlur();
  };

  const handleClose = () => {
    setIsActive(false);
    onSearchStrChange('');
    onMobileClose();
    makeMobileDiscoveryEnabled();
  };

  const handleCancel = () => {
    setIsActive(false);
    onSearchStrChange('');
    onCancel();
    makeMobileDiscoveryEnabled();
  };

  const handleKeyboardRemove = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Backspace && !e.currentTarget.value && values.length) {
      const nextValues = values.slice(0, -1);
      onChange(nextValues);
    }
  };

  const hasMultipleRows = values.length && isActive;
  const maxVisibleValues = values.reduce((res, val) => {
    const valLength = getEntryName(val).length;
    const nextCount = res.count + 1;
    const elPaddingOffset = CHIP_PADDING + SYMBOL_WIDTH * 2;
    const valWidth = elPaddingOffset + valLength * SYMBOL_WIDTH;
    const visibleElementsWidth = elPaddingOffset * res.count + res.symbols * SYMBOL_WIDTH;
    const totalWidth = visibleElementsWidth + valWidth;

    const containerWidth = wrapperWidth - (nextCount === values.length ? 0 : HIDDEN_ELEMENTS_LABEL_OFFSET);
    const baseNextValues = {
      ...res,
      count: nextCount,
      symbols: res.symbols + valLength,
    };

    if (containerWidth >= totalWidth) {
      return baseNextValues;
    }
    else {
      const maxLastWidth = containerWidth - visibleElementsWidth;
      if (maxLastWidth >= CROPPED_LAST_VALUE_MIN_WIDTH) {
        return { ...baseNextValues, lastWidth: maxLastWidth };
      }
      return { ...baseNextValues, count: res.count };
    }
  }, { count: 0, symbols: 0, lastWidth: null });

  return (
    <AutocompleteInputWrapper>
      <Downshift
        defaultHighlightedIndex={0}
        onSelect={handleInputChange}
        isOpen={isActive}
        onOuterClick={isMobileView ? undefined : handleDesktopBlur}
      >
        {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => {
          const input = (
            <TextField
              {...getInputProps()}
              data-auto="autocomplete-textfield"
              value={searchStr}
              disabled={disabled || values.length >= valuesLimit}
              autoFocus={isMobileView && isOpen}
              onChange={onInputChange}
              icon={isMobileView && isOpen ? inputIconWithMode : null}
              fullWidth={isMobileView && isOpen}
              inputRef={inputRef}
              onKeyDown={isMobileView ? undefined : handleKeyboardRemove}
              placeholder={hasValues ? t('autocompleteInput.multi.add') : placeholder}
            />
          );

          const desktopInput = values.length >= valuesLimit ? null : (
            <DesktopInputWrapper
              hasValues={hasValues}
              isActive={!isMobileView && isOpen}
            >
              {input}
            </DesktopInputWrapper>
          );

          const locationItemIcon: React.ReactNode = suggestionItemIcon ||
            (isMobileView ? <MobileSuggestionIcon /> : null);
          const suggestions = (
            <Suggestions
              isOpen={isOpen}
              initialSuggestions={initialSuggestions}
              suggestedAddresses={suggestedAddresses}
              loading={loading}
              searchStr={searchStr}
              source={source}
              optionsCount={optionsCount}
              showType={showType}
              highlightedIndex={highlightedIndex}
              itemPropsGetter={getItemProps}
              itemIcon={locationItemIcon}
            >
              {results => (
                <SuggestionsWrapper searchStr={searchStr}>
                  {results}
                </SuggestionsWrapper>
              )}
            </Suggestions>
          );

          const expandIcon = hasValues ? (
            <ExpandIconWrapper isActive={isOpen} onClick={handleClose}>
              <ExpandIcon />
            </ExpandIconWrapper>
          ) : null;

          const inputIconBlock = (
            <DesktopIconWrapper>
              {inputIcon}
            </DesktopIconWrapper>
          );

          const selectedValues = hasValues ? values.slice(0, isOpen ? undefined : maxVisibleValues.count)
            .map((value, id) => (
              <ChipBasic
                data-last-value={isOpen ? undefined : id === maxVisibleValues.count - 1}
                className={SELECTED_VALUE_CLASS}
                size={isMobileView ? 'small' : 'xSmall'}
                label={upperFirst(getEntryName(value as IAutocompleteEntry))}
                mode={isOpen ? 'selected' : 'primary'}
                onClick={makeOnRemoveHandler(value)}
                key={value.docId}
                data-auto="autocomplete-selected-value"
              />
            )) : null;

          const hiddenValues = hasValues && !isOpen && maxVisibleValues.count < values.length ? (
            <ChipBasic
              className={`${SELECTED_VALUE_CLASS}-limiter`}
              size={isMobileView ? 'small' : 'xSmall'}
              label={`+${values.length - maxVisibleValues.count}`}
              mode={isOpen ? 'selected' : 'primary'}
              data-auto="autocomplete-selected-value"
            />
          ) : null;

          const desktopContent = !isMobileView ? (
            <div>
              <DesktopContentWrapper
                isActive={isOpen}
                onClick={handleDesktopActive}
                ref={contentWrapperRef}
                multipleRows={hasMultipleRows}
                lastValueWidth={maxVisibleValues.lastWidth}
                className={DESKTOP_MULTI_INPUT_WRAPPER_CLASS}
                data-auto="desktop-multi-search-wrapper"
              >
                {inputIconBlock}
                {expandIcon}
                {selectedValues}
                {hiddenValues}
                {desktopInput}
                {suggestions}
              </DesktopContentWrapper>
            </div>
          ) : null;

          if (!isMobileView) return desktopContent;

          const mobileSelectedValues = searchStr || !hasValues ? null : (
            <>
              <MobileSubtitle weight="medium">
                {t('autocompleteInput.multi.yourLocations')}
              </MobileSubtitle>
              <MobileSelectedValuesWrapper>
                {selectedValues}
              </MobileSelectedValuesWrapper>
            </>
          );

          const mobileOpenContent = (
            <div>
              <MobileOpenContentWrapper>
                <MobileOpenContentHeader>
                  <Text weight="medium">{t('autocompleteInput.multi.mobileOpenHeader', { isCommercial })}</Text>
                  <CloseIconWrapper onClick={handleCancel}>
                    <CrossIcon width={16} height={16} />
                  </CloseIconWrapper>
                </MobileOpenContentHeader>
                <MobileOpenContentBody>
                  {input}
                  {mobileSelectedValues}
                  {suggestions}
                </MobileOpenContentBody>
              </MobileOpenContentWrapper>
              <StickyDoneButton>
                <Button data-auto="mobile-done-button" size="large" fullWidth onClick={handleClose}>{t('ugc.modal.button.done')}</Button>
              </StickyDoneButton>
            </div>
          );

          const mobileContent = (
            <div>
              <MobileContentWrapper
                onClick={handleMobileActive}
                ref={contentWrapperRef}
                lastValueWidth={maxVisibleValues.lastWidth}
                data-auto="mobile-multi-search-wrapper"
                hasValues={hasValues}
              >
                <MobileIconWrapper>
                  <MapPinIcon width={20} height={20}/>
                </MobileIconWrapper>
                {expandIcon}
                {selectedValues}
                {hiddenValues}
                {desktopInput}
              </MobileContentWrapper>
              {isActive ? (
                <OverlayWrapper portalId={overlayId}>
                  {mobileOpenContent}
                </OverlayWrapper>
              ) : null}
            </div>
          );

          return mobileContent;
        }}
      </Downshift>
    </AutocompleteInputWrapper>
  );
};


const mapDispatchToProps = {
  makeMobileDiscoveryDisabled: setMobileDiscoveryDisabled,
  makeMobileDiscoveryEnabled: setMobileDiscoveryEnabled,
};

export const MultiLocationAutocomplete = connect(null, mapDispatchToProps)(MultiLocationAutocompleteBase);
