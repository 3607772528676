import React from 'react';
import TabuIllustration from 'assets/svg/tabu-illustration.svg';
import RecentIcon from 'assets/svg/tabu-recent.svg';
import * as Styled from './styled';
import { LinkStyled } from 'ds/components/link/styled';
import { useLocale } from 'locale';
import { TabuWizardStep } from '../TabuWizard';


interface TabuFailProps {
  setStep: React.Dispatch<React.SetStateAction<TabuWizardStep>>;
}

export const TabuFail: React.FC<TabuFailProps> = ({ setStep }) => {

  const { t } = useLocale();

  const handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    setStep(TabuWizardStep.Payment);
  };

  return (
    <Styled.Root>
      <TabuIllustration width={72} height={72} />
      <Styled.Header weight="bold">{t('tabu.wizard.fail.header')}</Styled.Header>
      <Styled.InfoContainer>
        <LinkStyled href="#" onClick={handleClick}>
          <RecentIcon />
          {t('tabu.wizard.fail.info')}
        </LinkStyled>
      </Styled.InfoContainer>
    </Styled.Root>
  );
};
