import React from 'react';
import { useLocale } from 'locale';
import BackLink from 'components/navigation/BackLink';
import ArrowIcon from 'assets/svg/left.svg';
import { SmallText } from 'ds/components/typography';

interface BackButtonProps {
  label?: string;
}

export const BackButton: React.FC<BackButtonProps> = React.memo(props => {
  const { label } = props;
  const { t } = useLocale();
  return (
    <BackLink>
      <ArrowIcon width={24} height={24} />
      <SmallText data-auto="back-button-text">{label ? label : t('backButtonLabel.default')}</SmallText>
    </BackLink>
  );
});
