import { Route } from 'config/routes';
import { InsightSection } from 'store/state/insightsContext';
import { DealType } from 'utils/entities';

type TargetedPages = Route.UnitPage | Route.UnitPageCommercial | Route.AddressPage | Route.ProjectPage | Route.ProjectPageCommercial | Route.LocalPage | Route.Sold;

const pageSet = new Set<Route>([ Route.UnitPage, Route.UnitPageCommercial, Route.AddressPage, Route.ProjectPage, Route.ProjectPageCommercial, Route.LocalPage, Route.Sold ]);

const NONE_WIDGETS: widgetAliases[] = [];

export type widgetAliases =
  'transportation' |
  'schools' |
  'madad' |
  'ilSchools' |
  'safety' |
  'newConstructions' |
  'prices' |
  'quietStreets' |
  'nuisances' |
  'neighbors' |
  'neighbourhoodLife' |
  'trends' |
  'buildingPermit' |
  'deals'
;

interface CreateConfigOptions {
  isNewUnitPage: boolean;
  hasPricesEstimationSection: boolean;
}

const DEFAULT_OPTS: CreateConfigOptions = {
  isNewUnitPage: false,
  hasPricesEstimationSection: false,
};

export const unitPageWidgets: widgetAliases[] = [
  'prices',
  'ilSchools',
  'transportation',
  'newConstructions',
  'quietStreets',
  'neighbourhoodLife',
  'nuisances',
  'safety',
];

export const mapAnalyticsToWidgetsName = (key: string) => {
  const aliasConfig: Record<string, string | null> = {
    'transportation': null,
    'education': 'ilSchools',
    'safety': null,
    'new_construction': 'newConstructions',
    'prices': null,
    'nuisances': null,
    'yelp': 'neighbourhoodLife',
    'quiet_streets': 'quietStreets',
  };

  return aliasConfig[key] || key;
};

export const widgetNameToInsightSectionName = (name: widgetAliases) => {
  const aliasConfig: Record<string, InsightSection> = {
    'prices': InsightSection.Prices,
    'ilSchools': InsightSection.Schools,
    'transportation': InsightSection.Transportation,
    'newConstructions': InsightSection.Planning,
    'quietStreets': InsightSection.QuietStreets,
    'neighbourhoodLife': InsightSection.NeighbourhoodLife,
    'nuisances': InsightSection.Nuisances,
    'safety': InsightSection.Safety,
  };

  return aliasConfig[name];
};

export const createConfig = (route: Route, dealType: DealType, opts: CreateConfigOptions = DEFAULT_OPTS): widgetAliases[] => {
  if (!pageSet.has(route)) return null;

  const projectPageCommercialWidgets: widgetAliases[] = [
    'prices',
    'ilSchools',
    'safety',
    'newConstructions',
    'transportation',
    'nuisances',
    'quietStreets',
  ];


  const aliasConfig: Record<TargetedPages, Record<DealType, widgetAliases[]>> = {
    [Route.UnitPage]: {
      [DealType.Rent]: [ ...unitPageWidgets ],
      [DealType.Buy]: [ ...unitPageWidgets ],
    },
    [Route.AddressPage]: {
      [DealType.Rent]: [
        'madad',
        'prices',
        'ilSchools',
        'buildingPermit',
        'safety',
        'newConstructions',
        'transportation',
        'nuisances',
        'quietStreets',
      ],
      [DealType.Buy]: [
        'madad',
        'prices',
        'ilSchools',
        'buildingPermit',
        'safety',
        'newConstructions',
        'transportation',
        'nuisances',
        'quietStreets',
      ],
    },
    [Route.ProjectPage]: {
      [DealType.Rent]: [
        'ilSchools',
        'safety',
        'newConstructions',
        'transportation',
        'nuisances',
        'quietStreets',
      ],
      [DealType.Buy]: [
        'prices',
        'ilSchools',
        'safety',
        'newConstructions',
        'transportation',
        'nuisances',
        'quietStreets',
      ],
    },
    [Route.UnitPageCommercial]: {
      [DealType.Rent]: [ ...unitPageWidgets ],
      [DealType.Buy]: [ ...unitPageWidgets ],
    },
    [Route.ProjectPageCommercial]: {
      [DealType.Rent]: [ ...projectPageCommercialWidgets ],
      [DealType.Buy]: [ ...projectPageCommercialWidgets ],
    },
    [Route.Sold]: {
      [DealType.Buy]: [
        'prices',
      ],
      [DealType.Rent]: [
        'prices',
      ],
    },
    [Route.LocalPage]: {
      [DealType.Rent]: NONE_WIDGETS,
      [DealType.Buy]: NONE_WIDGETS,
    },
  };

  return (aliasConfig[route][dealType] || NONE_WIDGETS).filter(Boolean);
};
