import styled from '@emotion/styled';
import { SmallText, Text, TextLabel } from 'ds/components/typography';
import { Button } from 'ds/components/button';
import { typographyLineHeightCfg } from 'ds/components/typography/types';

export const MobileMenuWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.white};
  padding-left: ${({ theme }) => theme.spacing(3)};;
  z-index: 100;
  position: relative;
`;

export const MobileMenuContentWrapper = styled.div`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.06);
  min-height: 100vh;
  height: 100%;
`;

export const MobileMenuHeader = styled.div<{ whiteBackground?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 1;
  top: 0;
  height: 58px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  padding: 0 ${({ theme }) => theme.spacing(3)};
  background: ${({ theme, whiteBackground }) => whiteBackground ? theme.colors.neutrals.white : theme.colors.neutrals.grey11};
`;

export const MobileMenuBody = styled.div`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(3)}`};
`;

export const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const AvatarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const UserInfoWithAvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UnloggedUserAvatarIconWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const UnloggedUserAvatarSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const UploadListingButton = styled(Button)`
  width: 227px;
  background: ${({ theme }) => theme.colors.brand.madlanGreen};
  border-color: ${({ theme }) => theme.colors.brand.madlanGreen};
  line-height: ${typographyLineHeightCfg.smallText}px;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

export const UserInfo = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1.5)};
  > div {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    color: ${({ theme }) => theme.colors.neutrals.grey2};
  }
`;

export const MyProfileText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
`;

export const SidebarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: ${({ theme }) => theme.colors.neutrals.white};
  animation: fadeIn .3s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.92;
    }
  }
`;

export const SidebarWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 9999;
  ${({ theme }) => theme.isRTL ? 'left' : 'right'}: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.neutrals.white};
  ${({ isOpen, theme }) => isOpen ? `box-shadow: 0 ${theme.isRTL ? '-' : ''}2px 5px 0 rgba(0, 0, 0, 0.06), 0 ${theme.isRTL ? '-' : ''}2px 20px 0 rgba(0, 0, 0, 0.1);` : ''}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    width: 304px;
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    width: 275px;
  `}
`;

export const SidebarHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 28px; /** should be 32 but icon has some space inside */
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    justify-content: flex-end;
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    justify-content: flex-start;
  `}
`;

export const CloseButton = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;

export const SidebarBody = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const LinksSectionWrapper = styled.div`
  &:not(:last-of-type) {
    padding-bottom: ${({ theme }) => theme.spacing(4)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey9};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
  & > *:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${({ theme }) => theme.media.showAt({ at: 1 }) `
      margin-bottom: ${theme.spacing(5)};
    `}
  }
  > a {
    outline: none;
    text-decoration: none;
  }
`;

export const BottomLinksSectionWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)};

    & > *:not(:last-of-type) {
      margin-bottom: ${theme.spacing(3)};
    }
  `}
`;

export const MoreMenuLinksWithIconsSection = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const LinksSectionTitle = styled(TextLabel)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  text-transform: uppercase;
`;

export const DrawerSectionsWrapper = styled.div`
  margin-right: -${({ theme }) => theme.spacing(3)};
  margin-left: -${({ theme }) => theme.spacing(3)};
  margin-bottom: -${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.colors.neutrals.grey11};

  & > :first-of-type {
    padding-top: ${({ theme }) => theme.spacing(3)};
    margin-top: -${({ theme }) => theme.spacing(2.5)};
  }
`;

export const DrawerLinksWrapper = styled.div`
  margin-right: -${({ theme }) => theme.spacing(3)};
  margin-left: -${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.colors.neutrals.grey11};

  & {
    padding-bottom: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
  & > *:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${({ theme }) => theme.media.showAt({ at: 1 }) `
      margin-bottom: ${theme.spacing(5)};
    `}
  }
  & > :first-of-type {
    padding-top: ${({ theme }) => theme.spacing(3)};
    margin-top: -${({ theme }) => theme.spacing(2.5)};
  }
`;

export const DrawerSectionHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const DrawerLinksSection = styled.div`
  & :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
    margin-bottom: ${({ theme }) => theme.spacing(3)} !important;
  }
`;

export const DrawerSectionMenuLinkWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  &:last-of-type {
    margin-bottom: unset;
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const MenuLinkWrapper = styled(Text)<{ accented?: boolean, isInitialIconColor?: boolean, sticky?: boolean, isDrawerLink?: boolean, grey2Color?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  ${({ theme, sticky }) => sticky && `
    position: sticky;
    top: 58px;
    padding: ${theme.spacing(2.5)} ${theme.spacing(3)};
    margin: -${theme.spacing(2.5)} -${theme.spacing(3)};
    background: ${theme.colors.neutrals.white};
    z-index: 1;
  `};

  > svg {
    pointer-events: none;
  }
  > a {
    text-decoration: none;
  }
  > a, > div {
    display: flex;
    align-items: center;
    color: ${({ theme, accented }) => accented ? theme.colors.brand.main : theme.colors.neutrals.grey1};
    ${({ theme, isDrawerLink }) => isDrawerLink && `color: ${theme.colors.brand.secondary}`};
    ${({ theme, grey2Color }) => grey2Color && `color: ${theme.colors.neutrals.grey2}`};
    svg {
      margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) => theme.spacing(1)};
      path {
        fill: ${({ theme, accented, isInitialIconColor }) => {
          if (isInitialIconColor) return null;
          return accented ? theme.colors.brand.main : theme.colors.neutrals.grey1;
        }};
      }
    }
  }
`;

export const ProfileShortcutWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const ProfileShortcutItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SavedListingsCount = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
  width: 24px;
  text-align: center;
`;

export const ChevronIconContainer = styled.span`
  width: 24px;
  display: flex;
  justify-content: center;
`;

export const NewLabel = styled(TextLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    background-color: ${theme.colors.label.yellow};
    border-radius: ${theme.borderRadius.tiny};
    padding: ${theme.spacing(0.25)} ${theme.spacing(0.5)};
    margin-right: ${theme.spacing(1)};
    min-height: auto;
    height: 18px;
  `}
`;

export const NewLabelV2 = styled(NewLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    background-color: #FAE998;
  `}
`;

export const LinksDrawerInner = styled.span`
  display: flex;
  align-items: center;
`;
