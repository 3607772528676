import React from 'react';
import { useLocale } from 'locale';
import { GenericFilterProps } from '../types';
import FilterSection from '../common/DropdownSection';
import { isFilterActiveByType } from '../utils';
import { PerformantCheckboxGroup as CheckboxList } from 'components/filters/common/PerformantCheckbox';
import { head } from 'lodash';
import { Section, SectionBody, SectionTitle, SecondaryTitle, PropertyTypeWrapper, ColorWrapper } from '../styled/common';

interface QualityClassProps extends GenericFilterProps<'qualityClass'> { }

const QualityClass: React.FunctionComponent<QualityClassProps> = ({
  alignment,
  resetValue,
  commitValues,
  commitedValue,
  initialValue,
  setIntermediateValue,
  intermediateValue,
  config,
  isInline,
  isMobile,
  type,
}) => {
  const { t } = useLocale();
  const isActive = isFilterActiveByType.qualityClass(commitedValue, initialValue);
  const valuesAreInitial = !isFilterActiveByType.qualityClass(intermediateValue, initialValue);

  const { qualityClassOptions, filtersIcons } = config;
  const shortValueLabel = t('filters.qualityClass.label', { count: commitedValue.length, value: t('filters.qualityClassOptions.short' , { value: head(commitedValue) }) });

  if (isInline) {
    return (
      <Section data-section-filter-name={type} data-auto={`filter-${type}`}>
        <SectionTitle isBold>
          <div>
            {t('filters.qualityClass.singleLabel')}
            {intermediateValue.length ? (
              <SecondaryTitle>
                {intermediateValue.length}
              </SecondaryTitle>
            ) : null}
          </div>
        </SectionTitle >
        <SectionBody data-auto="filterCheckboxes">
          <PropertyTypeWrapper isInline={!isMobile}>
            <CheckboxList
              values={intermediateValue}
              labelGetter={(value) => t('filters.qualityClassOptions', { value })}
              valueSetter={setIntermediateValue}
              options={qualityClassOptions}
            />
          </PropertyTypeWrapper>
        </SectionBody>
      </Section>
    );
  }

  return (
    <FilterSection
      isClearButtonDisabled={valuesAreInitial}
      type={type}
      alignment={alignment}
      isActive={isActive}
      icon={filtersIcons[type]}
      label={shortValueLabel}
      onClear={resetValue}
      onSubmit={commitValues}
    >
      <ColorWrapper>
        <CheckboxList
          values={intermediateValue}
          labelGetter={(value) => t('filters.qualityClassOptions', { value })}
          valueSetter={setIntermediateValue}
          options={qualityClassOptions}
        />
      </ColorWrapper>
    </FilterSection>
  );
};

export default QualityClass;
