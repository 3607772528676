import React from 'react';
import { DealType, PoiId, PriceEvent } from 'utils/entities';
import { ISOString } from 'locale/formatDate';
import { useLocale } from 'locale';
import { LabelsContainer, PromotedProjectLabel } from 'ds/components/ListingCard';
import RelevancyLabel from 'components/unit-page/RelevancyLabel';
import SpecialLabel, { SpecialLabelType } from 'components/special-label';
import { isPriceDropped, getPriceHistoryDifference } from 'utils/priceEvents';


interface CardLabelsProps {
  isPromoted: boolean;
  showPriceDropLabel: boolean;
  showIsNewLabel: boolean;
  isBulletinType: boolean;
  date: ISOString;
  lastUpdatedDate: ISOString;
  id: PoiId;
  eventsHistory: PriceEvent[];
  dealType: DealType;
  isCommercial?: boolean;
}

export const CardLabels: React.FC<CardLabelsProps> = ({
  isPromoted,
  showPriceDropLabel,
  showIsNewLabel,
  isBulletinType,
  date,
  lastUpdatedDate,
  id,
  isCommercial,
  eventsHistory,
  dealType,
}) => {
  const { t, formatMoney } = useLocale();

  const hasPriceDropped = isPriceDropped(eventsHistory);
  const shouldShowPriceDropLabel = showPriceDropLabel && hasPriceDropped;
  const shouldShowRelevancyLabel = showIsNewLabel && !shouldShowPriceDropLabel;
  const priceDroppedValue = hasPriceDropped ? formatMoney(getPriceHistoryDifference(eventsHistory)) : null;

  return (
    <LabelsContainer isCommercial={isCommercial}>
      {shouldShowRelevancyLabel ? (
        <RelevancyLabel isBulletinType={isBulletinType} createdDate={date} lastUpdatedDate={lastUpdatedDate} id={id} dealType={dealType} />
      ) : null}
      {isPromoted ? (
        <PromotedProjectLabel small>{t('universalCard.promotedProject.label')}</PromotedProjectLabel>
      ) : null}
      {shouldShowPriceDropLabel ? <SpecialLabel type={SpecialLabelType.PriceDrop} dropValue={priceDroppedValue} /> : null}
    </LabelsContainer>
  );
};
