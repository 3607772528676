import React from 'react';
import { useForm } from 'react-final-form-hooks';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { AuthenticationField } from './types';
import { Text } from 'ds/components/typography';
import { SetPasswordFormRoot, FormControlsWrapper } from './styled';
import { FeedbackButton } from 'ds/components/button';


const ResetPasswordHintText = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  margin-bottom: 16px;
`;

interface ResetPasswordFormProps {
  email: string;
  onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void;
  isEmailValid: boolean;
  isResetPasswordSent: boolean;
  dirtyFields: Set<AuthenticationField>;
  onSetDirty: (field: AuthenticationField) => void;
  onSubmit: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  email,
  onEmailChange,
  isEmailValid,
  dirtyFields,
  onSetDirty,
  isResetPasswordSent,
  onSubmit,
}) => {
  const { t } = useLocale();
  const { handleSubmit } = useForm({ onSubmit });

  return (
    <SetPasswordFormRoot>
      <ResetPasswordHintText>
        <Text>{t('resetPassword.hintText')}</Text>
      </ResetPasswordHintText>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          data-auto="reset-password-field"
          type="email"
          fullWidth
          onChange={onEmailChange}
          value={email}
          placeholder={t('authentication.emailLabel')}
          hasError={!isEmailValid && dirtyFields.has('email')}
          errorMessage={t('authentication.emailInvalidMessage')}
          onBlur={() => onSetDirty('email')}
        />
        <FormControlsWrapper>
          <FeedbackButton
            data-auto="send-link-button"
            disabled={!isEmailValid || !email}
            fullWidth
            size="large"
            feedbackText={isResetPasswordSent ? t('resetPassword.submitButtonFeedbackText') : null}
          >
            {t('resetPassword.submitButtonLabel')}
          </FeedbackButton>
        </FormControlsWrapper>
      </form>
    </SetPasswordFormRoot>
  );
};

