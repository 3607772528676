import { ITileRange } from 'utils/entities';
import { isEqual } from 'lodash';
import { createHashSetFactory, HashSet } from 'utils/hashSetFactory';
import { IContextualSearchParametersWithoutSort } from './map';



const stringifyTileRange = ({ x1, y1, x2, y2 }: ITileRange): string => [ x1, y1, x2, y2 ].join(',');


export interface ITilesCacheResolver {
  add: (ranges: ITileRange[], zoom: number) => void;
  remove: (ranges: ITileRange[], zoom: number) => void;
  has: (ranges: ITileRange[], zoom: number) => boolean;
  get: (zoom: number) => ITileRange[];
  setCurrentParams: (params: IContextualSearchParametersWithoutSort) => boolean;
}

const createTilesHashSet = createHashSetFactory(stringifyTileRange);

export const createTilesCacheResolver = (): ITilesCacheResolver => {

  let storage: Record<number, HashSet<ITileRange>> = {};
  let currentParams: IContextualSearchParametersWithoutSort = null;

  return {
    setCurrentParams: (params) => {

      const shouldInvalidate = !isEqual(params, currentParams);
      if (shouldInvalidate) {
        storage = {};
      }
      currentParams = params;
      return shouldInvalidate;
    },
    add: (ranges, zoom) => {
      ranges.forEach((range) => {
        if (!storage[zoom]) storage[zoom] = createTilesHashSet();
        storage[zoom].add(range);
      });
    },
    remove: (ranges, zoom) => {
      ranges.forEach((range) => {
        if (!storage[zoom]) storage[zoom] = createTilesHashSet();
        storage[zoom].delete(range);
      });
    },
    get: (zoom) => storage[zoom] ? storage[zoom].values() : [],
    has: (ranges, zoom) => {
      if (!storage[zoom]) return false;
      return ranges.every(storage[zoom].has);
    },
  };
};
