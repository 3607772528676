import * as React from 'react';
import { Spring, animated, config } from 'react-spring';
import { GestureState, gestureInitialState, createPanResponder, applyIOSImmediateHack, calcAngle, isHorizontal } from 'helpers/pan-responder';
import { ResizeObserver } from 'utils/ResizeObserver';
import { AlignItemsProperty } from 'csstype';
import { Pane } from './Pane';
import { getInfiniteNextIndex, getInfinitePrevIndex } from 'utils/gallery';
import { calcToX, calcToXForCube, getScale } from './helpers';

export interface ViewPagerState {
  slidesWidths: number[];
  containerWidth: number;
  isResizing: boolean;
  slideIndex: number;
  gestureState: GestureState;
  infiniteIdx: number;
}

export type Align = 'center' | 'left' | 'right';

type Mode = 'carousel' | 'cube';

export interface ViewPagerProps {
  mode?: Mode;
  swipeDxThreshold: number;
  align: Align;
  verticalAlign?: AlignItemsProperty;
  gap?: number;
  children: React.ReactChildren | JSX.Element[] | React.ReactNode;
  slideIndex?: number;
  onSlideIndexChange?: (idx: number) => void;
  isRTL?: boolean;
  disableSwipe?: boolean;
  infinite?: boolean;
  stopMouseClickPropagation?: boolean;
  onTouchStart?: (gestureState: GestureState) => void;
  onTouchEnd?: (gestureState: GestureState) => void;
  onResize?: ResizeObserverCallback;
  useIOSImmediateHack?: boolean;
  entryContentDXOverride?: number;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

const containerStyle = { width: '100%', 'overflow': 'hidden' };

const VELOCITY_ANGLE_THRESHOLD = 0.7;
const DX_TERMINATE_THRESHOLD = 33;
const INFINITE_TELEPORT_MARGIN = 1;


enum MouseEvtPhase {
  Initial,
  Granted,
  Succeed,
}

export class ViewPager extends React.Component<ViewPagerProps, ViewPagerState> {

  public static defaultProps: Partial<ViewPagerProps> = {
    slideIndex: 0,
    verticalAlign: 'center',
    swipeDxThreshold: 33,
    stopMouseClickPropagation: true,
    onTouchStart: () => undefined,
    onTouchEnd: () => undefined,
    onResize: () => undefined,
  };

  private mousePhase: MouseEvtPhase = MouseEvtPhase.Initial;

  constructor(props: ViewPagerProps) {
    super(props);
    this.state = {
      slidesWidths: [],
      containerWidth: null,
      gestureState: gestureInitialState,
      isResizing: true,
      infiniteIdx: 1,
      slideIndex: props.slideIndex,
    };
  }

  private observer: ResizeObserver;
  private container = React.createRef<HTMLDivElement>();

  private get slides() {
    return Array.from(this.container.current.firstChild.childNodes) as HTMLDivElement[];
  }

  private panHandlers = createPanResponder({
    shouldTerminate: (state, e) => {
      const angle = calcAngle(state.vx, state.vy);
      return Math.abs(angle) > VELOCITY_ANGLE_THRESHOLD && (Math.abs(state.dx) < DX_TERMINATE_THRESHOLD || !e.cancelable);
    },
    shouldPreventDefault: (state, e) => state.__granted && e.cancelable,
    shouldGrant: isHorizontal,
    onMove: (gestureState) => {
      this.setState((state, props) => {

        let { infiniteIdx } = state;
        const { children, infinite } = props;

        if (infinite) {
          const { slideIndex } = state;
          const childrenCount = React.Children.count(children);

          const left = slideIndex - 0;
          const right = childrenCount - 1 - slideIndex;

          const shouldTeleportRight = infiniteIdx === 0 && left < INFINITE_TELEPORT_MARGIN;
          const shouldTeleportLeft = infiniteIdx === 1 && right < INFINITE_TELEPORT_MARGIN;

          if (shouldTeleportRight) infiniteIdx = 1;
          if (shouldTeleportLeft) infiniteIdx = 0;
        }

        return {
          gestureState,
          infiniteIdx,
        };
      });
    },
    onStart: (gestureState, e) => {
      this.props.onTouchStart(gestureState);
      if (!('touches' in e)) {
        this.mousePhase = MouseEvtPhase.Granted;
      }
      this.setState((state) => ({ gestureState }));
    },
    onEnd: (gestureState) => {
      this.props.onTouchEnd(gestureState);
      if (Math.abs(this.state.gestureState.dx) > this.props.swipeDxThreshold) {
        this.mousePhase = MouseEvtPhase.Succeed;
      }
      this.setState((state, props) => {
        let { infiniteIdx } = state;

        let index = state.slideIndex;
        const isXAxis = Math.abs(calcAngle(state.gestureState.dx, state.gestureState.dy)) < VELOCITY_ANGLE_THRESHOLD;

        if (state.gestureState.dx > props.swipeDxThreshold && isXAxis) {
          props.isRTL ? index++ : index--;
          if (props.onSwipeLeft) props.onSwipeLeft();
        }
        else if (state.gestureState.dx < -props.swipeDxThreshold && isXAxis) {
          props.isRTL ? index-- : index++;
          if (props.onSwipeRight) props.onSwipeRight();
        }

        const maxIdx = React.Children.count(props.children) - 1;

        if (props.infinite) {
          if (index < 0) infiniteIdx--;
          else if (index > maxIdx) infiniteIdx++;

          const childrenCount = React.Children.count(this.props.children);
          if (index < 0) index = childrenCount - 1;
          else if (index > childrenCount - 1) index = 0;
        }
        else {
          index = Math.min(Math.max(0, index), maxIdx);
        }

        return {
          ...state,
          gestureState,
          infiniteIdx,
          slideIndex: index,
        };

      }, () => {
        if (this.props.onSlideIndexChange) {
          // i think we can safely defer this update, having major win
          requestAnimationFrame(() => {
            if (this.props.slideIndex !== this.state.slideIndex) {
              this.props.onSlideIndexChange(this.state.slideIndex);
            }
          });
        }
      });
    },
  });


  public componentDidUpdate(prevProps: ViewPagerProps, prevState: ViewPagerState) {
    const childrenCount = React.Children.count(this.props.children);

    if (!childrenCount) {
      return;
    }

    const maxIdx = childrenCount - 1;

    const isExternalUpdate = this.props.slideIndex !== this.state.slideIndex
      && !(this.props.slideIndex === prevState.slideIndex && prevState.slideIndex === prevProps.slideIndex)
      && prevProps.slideIndex !== this.props.slideIndex;

    if (isExternalUpdate) {
      const { infinite, slideIndex } = this.props;
      const abs = Math.abs(slideIndex - this.state.slideIndex);

      const shouldTeleportLeft = slideIndex === maxIdx && abs > 1;
      const shouldTeleportRight = slideIndex === 0 && abs > 1;

      if (infinite && (shouldTeleportLeft || shouldTeleportRight)) {
        this.setState(() => ({
          infiniteIdx: shouldTeleportLeft ? 1 : 0,
          isResizing: true,
        }), () => {
          this.setState(() => ({
            slideIndex,
            isResizing: false,
            infiniteIdx: shouldTeleportRight ? 1 : 0,
          }));
        });
      }
      else {
        this.setState(() => ({
          slideIndex: this.props.slideIndex,
        }));
      }
    }

    if (prevProps.children !== this.props.children) {
      // start observing children if they changed
      this.slides.forEach(this.observer.observe, this.observer);

      if (maxIdx < this.props.slideIndex) {
        if (this.props.onSlideIndexChange) this.props.onSlideIndexChange(maxIdx);
      }
    }
  }

  private handleResize = (entries: ResizeObserverEntry[]) => {
    const entryContentDXOverride = this.props.entryContentDXOverride || 0;
    const gap = this.props.gap || 0;
    const entriesByTarget = new WeakMap(entries.map((e): [ Element, ResizeObserverEntry ] => [ e.target, e ]));

    this.setState((state) => {

      const newWidths = this.slides.map((slide, i) => {
        const entry = entriesByTarget.get(slide);
        return entry ? (entry.contentRect.width + entryContentDXOverride + gap) : state.slidesWidths[i];
      });

      const containerEntry = entriesByTarget.get(this.container.current);
      const newContWidth = containerEntry ? containerEntry.contentRect.width : state.containerWidth;

      return {
        slidesWidths: newWidths,
        containerWidth: newContWidth,
        isResizing: true,
      };
    }, () => {
      this.setState(() => ({ isResizing: false }));
    });
    const currentEntry = entriesByTarget.get(this.container.current);
    if (currentEntry) this.props.onResize([ currentEntry ], this.observer);
    // in order to assist GC
    // entriesByTarget = null;
  };

  public componentDidMount() {
    this.observer = new ResizeObserver(this.handleResize);

    this.observer.observe(this.container.current);
    this.slides.forEach(this.observer.observe, this.observer);
  }

  public componentWillUnmount() {
    this.observer.disconnect();
    // in order to assist GC
    this.observer = null;
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.stopMouseClickPropagation && this.mousePhase === MouseEvtPhase.Succeed) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.mousePhase = MouseEvtPhase.Initial;
  };

  private renderCarousel () {

    const { children, isRTL, infinite, verticalAlign, disableSwipe, useIOSImmediateHack, gap } = this.props;
    const { gestureState, isResizing, slideIndex } = this.state;
    const { down, dx } = gestureState;

    const childrenCount = React.Children.count(children);

    const toX = calcToX(this.props, this.state);

    const immediate = down || isResizing;

    const wobbly = slideIndex === 0 && dx > 0 || slideIndex === childrenCount - 1 && dx < 0;

    const springProps = {
      config: wobbly ? config.wobbly : config.default,
      immediate,
      to: { x: toX },
    };

    const finalSpringProps = useIOSImmediateHack
      ? applyIOSImmediateHack(springProps, gestureState.down, gestureState.dx)
      : springProps;

    return (
      <div onClickCapture={this.handleClick} className="view-pager-rail" ref={this.container} style={containerStyle} {...(disableSwipe ? {} : this.panHandlers)}>
        <Spring {...finalSpringProps}>
          {({ x }) => (
            <animated.div
              className="view-pager-bow"
              data-auto="bulletin-images"
              style={{
                display: 'inline-flex',
                minWidth: `${childrenCount * (infinite ? 2 : 1) * 100}%`,
                alignItems: verticalAlign,
                willChange: 'transform',
                transform: x.to(iX => `translate3d(${isRTL ? -iX : iX}px, 0, 0)`),
                gap: Number.isFinite(gap) ? `${gap}px` : undefined,
              }}
            >
              {infinite ? children : null}
              {children}
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }

  private renderCube() {
    if (this.props.infinite) {
      throw new Error('Infinite in cube mode not implemented');
    }

    const { children, disableSwipe, useIOSImmediateHack, isRTL } = this.props;
    const { gestureState, isResizing, slideIndex } = this.state;
    const { down } = gestureState;

    const toX = calcToXForCube(this.props, this.state);

    const immediate = down || isResizing;

    const springProps = {
      immediate,
      to: { x: toX },
    };

    const finalSpringProps = useIOSImmediateHack
      ? applyIOSImmediateHack(springProps, gestureState.down, gestureState.dx)
      : springProps;

    const { containerWidth: width } = this.state;

    const slides = React.Children.toArray(children);

    const paneInViewIndex = slideIndex % 4;

    const perspective = 800;
    const scaleRange: [ number, number ] = [ 1, 0.95 ];
    const maybeRtlMultiplier = isRTL ? -1 : 1;

    return (
      <div
        onClickCapture={this.handleClick}
        className="view-pager-rail"
        ref={this.container}
        style={{ ...containerStyle, perspective }}
        {...(disableSwipe ? {} : this.panHandlers)}
      >
        <Spring {...finalSpringProps}>
          {({ x }) => (
            <animated.div
              className="view-pager-bow"
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                backfaceVisibility: 'visible',
                willChange: 'transform',
                transformStyle: 'preserve-3d',
                transform: x.to(iX => `translateZ(-${width / 2}px) rotateY(${(maybeRtlMultiplier * iX)}deg) scale(${getScale(iX, scaleRange)}`),
              }}
            >
              {[ 0, 1, 2, 3 ].map(pane => {
                const isPaneInView = paneInViewIndex === pane;
                const isNextOfPaneInView = paneInViewIndex === getInfinitePrevIndex(pane, 4);
                const isPrevOfPaneInView = paneInViewIndex === getInfiniteNextIndex(pane, 4);

                let i = 0;

                if (isPrevOfPaneInView) {
                  if (slideIndex - 1 < 0) i = undefined;
                  else i = slideIndex - 1;
                }
                else if (isNextOfPaneInView) {
                  if (slideIndex + 1 > slides.length - 1) i = undefined;
                  else i = slideIndex + 1;
                }
                else if (isPaneInView) i = slideIndex % slides.length;
                else i = undefined;

                return (
                  <Pane
                    key={pane}
                    width={width}
                    rotate={pane * 90 * maybeRtlMultiplier}
                  >
                    {slides[i]}
                  </Pane>
                );
              })}
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }

  public render() {
    if (this.props.mode === 'cube') {
      return this.renderCube();
    }

    return this.renderCarousel();
  }
}
