import React from 'react';
import {
  createFacebookLink,
  createFacebookMessengerLink,
  createTwitterLink,
  createEmailLink,
  createWhatsAppLink,
} from 'utils/shareLinksUtils';
import { ITranslate } from 'locale';
import {
  IAutocompleteAddress,
  IBulletin,
  IDeal,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
  PoiType,
} from 'utils/entities';
import CopyLinkIcon from 'assets/svg/link.svg';
import EmailIcon from 'assets/svg/mail.svg';
import TwitterIcon from 'assets/svg/twitter.svg';
import FacebookIcon from 'assets/svg/fb.svg';
import FacebookMessengerIcon from 'assets/svg/messenger.svg';
import WhatsAppIcon from 'assets/svg/whatsapp.svg';
import { isBulletinPoiType, isProjectPoiType, routeByPoiType } from 'utils/marketplaceRoutes';
import { isServer } from 'utils';
import { RouteContext } from 'react-router5/types/types';


export enum ShareType {
  Email = 'email',
  CopyLink = 'copyLink',
  Twitter = 'twitter',
  Facebook = 'facebook',
  FacebookMessenger = 'facebookMessenger',
  WhatsApp = 'whatsApp',
}

export const shareActionTypeToIcon: Record<ShareType, JSX.Element> = {
  [ShareType.Email]: <EmailIcon />,
  [ShareType.CopyLink]: <CopyLinkIcon />,
  [ShareType.Twitter]: <TwitterIcon />,
  [ShareType.Facebook]: <FacebookIcon />,
  [ShareType.FacebookMessenger]: <FacebookMessengerIcon />,
  [ShareType.WhatsApp]: <WhatsAppIcon />,
};

interface ShareHandlerOptions {
  type: ShareType;
  url: string;
  text?: string;
  redirectUri?: string;
  shareViaEmailOptions?: ShareViaEmailOptions;
}


export function clearParamsBeforeShare(url: string) {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  params.delete('activeListingImage');

  const searchP = params.toString();

  return urlObj.origin.concat(urlObj.pathname).concat(searchP ? '?' + searchP : '');
}

export const shareHandler = (options: ShareHandlerOptions) => {
  const { type, url, redirectUri, text, shareViaEmailOptions } = options;

  switch (type) {
    case ShareType.Facebook: {
      const href = createFacebookLink(url, { quote: text });
      window.open(href);
      break;
    }
    case ShareType.FacebookMessenger: {
      const href = createFacebookMessengerLink(url, { redirectUri });
      window.open(href);
      break;
    }
    case ShareType.Twitter: {
      const href = createTwitterLink(url, { text });
      window.open(href);
      break;
    }
    case ShareType.WhatsApp: {
      const urlObj = new URL(url);
      urlObj.search = '';
      const { t } = shareViaEmailOptions;

      const href = createWhatsAppLink({ text: `${t('shareButton.whatsappIntroText')}:\n${urlObj.toString()}` });
      window.open(href);
      break;
    }
    case ShareType.Email: {
      shareViaEmail(shareViaEmailOptions);
      break;
    }
  }
};

interface ShareViaEmailOptions {
  t: ITranslate;
  url?: string;
  poi?: IBulletin | IProject | IDeal | ICommercialBulletin | ICommercialProject;
  addressDocEntry?: IAutocompleteAddress;
  subject?: string;
  body?: string;
}

export const shareViaEmail = (options: ShareViaEmailOptions) => {
  const { t, poi, addressDocEntry, url: shareUrl , subject: shareSubject, body: shareBody } = options;
  let subject: string;
  let body: string;

  if (shareSubject || shareBody) {
    subject = shareSubject;
    body = shareBody;
  }
  else if (poi) {
    const link = shareUrl || window.location.href;
    const url = `${link}${link.indexOf('?') === -1 ? '?' : '&'}share_id=${poi.id}`;
    if (poi.type === 'deal') {
      subject = t('soldPage.shareEmailSubject');
      body = t('soldPage.shareEmailBody', { url });
    }
    else if (isBulletinPoiType(poi)) {
      const { structuredAddress: { streetNumber, streetName }, buildingClass } = poi;
      const primaryAddress = t('unitPage.primaryAddress', {
        streetNumber,
        streetName,
        neighbourhood: null,
      });
      subject = t('shareButton.emailSubject', { buildingClass });
      body = t('shareButton.emailBody', { buildingClass, primaryAddress, url });
    }
    else if (isProjectPoiType(poi)) {
      subject = poi.projectName;
      body = url;
    }
  }
  else if (addressDocEntry) {
    const link = shareUrl || window.location.href;
    const url = `${link}${link.indexOf('?') === -1 ? '?' : '&'}share_id=${addressDocEntry.docId}`;
    const line1 = t('addressPage.addressLine1', addressDocEntry as any);
    const line2 = t('addressPage.addressLine2', addressDocEntry as any);
    subject = t('shareButton.emailSubjectAddressPage', { line1, line2 });
    body = t('shareButton.emailBodyAddressPage', { line1, line2, url });
  }

  if (subject && body) {
    const href = createEmailLink({ subject, body });
    window.location.href = href;
  }
};

export const cardShareUrl = (routeContext: RouteContext, poiId: string, poiType: PoiType) => {
  const url = isServer() ? null : window.location.origin + routeContext.router.buildUrl(routeByPoiType(poiType), { id: poiId });
  return url;
};

export const handleCardShare = (streetName: string, streetNumber: string, url: string, t: ITranslate) => {
  const primaryAddress = t('unitPage.primaryAddress', {
    streetNumber,
    streetName,
    neighbourhood: null,
  });

  return (shareType: ShareType) => {
    if (shareType === ShareType.Email) {
      const subjectText = t('shareButton.emailSubject');
      const bodyText = t('shareButton.emailBody', { primaryAddress, url });
      const uri = `mailto:?subject=${subjectText}&body=${bodyText}`;
      window.location.href = encodeURI(uri);
    }
    else {
      shareHandler({ type: shareType, url });
    }
  };
};
