import { isServer } from 'utils';
import { memoize } from 'lodash';

function getQAAutomationParamsImpl() {
  if (isServer()) return {};

  const { searchParams } = new URL(window.location.href);
  const operator = searchParams.get('operator');
  const popups = searchParams.get('popups');
  const googleLogin = searchParams.get('googleLogin');
  const GreatOaksFromLittleAcornsGrow = searchParams.get('GreatOaksFromLittleAcornsGrow');
  return {
    operator,
    popups,
    GreatOaksFromLittleAcornsGrow,
    googleLogin,
  };
}


export const getQAAutomationParams = memoize(getQAAutomationParamsImpl);
