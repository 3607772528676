import styled from '@emotion/styled';
import { H3, Text } from 'ds/components/typography';
import { Button } from 'ds/components/button';


export const Root = styled.div`
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.small};
    border: solid 1px ${theme.colors.neutrals.grey6};
    box-shadow: ${theme.shadow.level8};
    margin-bottom: ${theme.spacing(7)};
    overflow: hidden;
    background: ${theme.colors.brand.lightBlue};
  `}
`;

export const ModalRoot = styled.div`
  width: 425px;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 100%;
  `}
`;

export const Header = styled.div<{ isMobile: boolean; isProject?: boolean }>`
  ${({ theme, isMobile, isProject }) => `
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(isMobile ? 3 : 4)} ${theme.spacing(3)};
    ${isMobile ? `
      flex-direction: column;
    ` : `
      align-items: center;
      > a {
        margin-right: ${theme.spacing(2)};
      }
    `}
  `}
`;

export const ModalHeader = styled(Header)`
  background: ${({ theme }) => theme.colors.brand.lightBlue};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const Main = styled.div<{ isMobile?: boolean; isProject?: boolean; }>`
  ${({ theme, isMobile, isProject }) => `
    padding: ${theme.spacing(3)} 0 ${theme.spacing(isMobile && isProject ? 4 : 0)};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    padding-bottom: 0;
  `}
`;

export const PhoneButton = styled(Button.withComponent('a'))`
  text-decoration: none;
  display: flex;
  flex-grow: 0;
  flex-basis: 185px;
  white-space: nowrap;
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-basis: 100%;
  `}
`;

export const FormTitle = styled(H3.withComponent('div'))`
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const FieldsContainer = styled.div<{ isMobile: boolean; largeInput: boolean; noTopMargin?: boolean; isProject?: boolean; isDesktop?: boolean }>`
  ${({ theme, isMobile, largeInput, noTopMargin, isProject, isDesktop }) => `
    margin: ${theme.spacing(isMobile || (isProject && isDesktop) ? 1 : 4)} 0 ${theme.spacing(isMobile && isProject ? 4 : 3)};
    display: flex;
    flex-grow: 1;
    ${largeInput ? `
      input {
        height: 48px;
      }
    ` : ``}
    ${isMobile ? `
      margin-top: ${theme.spacing(2)};
      flex-direction: column;
      > div {
        margin-bottom: ${theme.spacing(3)};
        :last-of-type {
          margin-bottom: 0;
        }
      }
    ` : ``}
    ${isMobile && isProject ? `
      > div:nth-last-of-type(3) {
        margin-bottom: ${theme.spacing(1.5)};
      }
    ` : ``}
    ${noTopMargin ? 'margin-top: 0;' : ''}
  `}
`;

export const Row = styled.div`
  ${({ theme }) => `
    margin: 0 -${theme.spacing(1)};
    display: flex;
    flex-grow: 1;
  `}
`;

export const Column = styled.div`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    padding: 0 ${theme.spacing(1)};
  `}
`;

export const SentContainer = styled.div<{ isProject?: boolean; isShortlist: boolean }>`
  ${({ theme, isProject, isShortlist }) => `
    display: flex;
    flex-direction: column;
    align-items: ${isShortlist ? 'stretch' : 'center'};
    justify-content: center;
    padding: ${isProject ? '116px' : theme.spacing(8)} 0;
    max-width: ${isShortlist ? '100%' : '161px'};
    margin: 0 auto;
    color: ${theme.colors.neutrals.black};
    text-align: center;
    gap: ${theme.spacing(1)};
  `}
  ${({ theme, isShortlist }) => theme.media.showAt({ at: 1 })`
    padding: ${isShortlist ? '0' : theme.spacing(2)} 0;
  `}
`;

export const ModalFooter = styled.div<{ isProject?: boolean; isDesktop?: boolean }>`
  ${({ theme, isProject, isDesktop }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px ${theme.colors.neutrals.grey8};
    padding: ${theme.spacing(4)} ${theme.spacing(4)} 0;
    margin: ${theme.spacing(isProject && isDesktop ? 1 : 1.5)} -${theme.spacing(4)} 0;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: ${({ theme }) => `0 ${theme.spacing(3)} ${theme.spacing(4)}`};
  gap: ${({ theme }) => theme.spacing(3)};
  white-space: nowrap;
  & svg {
    flex-shrink: 0;
  }
  > * {
    min-width: calc(50% - 12px);
  }
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column-reverse;
    padding: ${`0 ${theme.spacing(2)} ${theme.spacing(4)}`};
    && > * {
     width: 100%;
     flex-basis: auto;
    }
  `}
`;

export const POCWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.neutrals.white};
  margin: ${({ theme }) => `0 ${theme.spacing(3)} ${theme.spacing(3)}`};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin: ${`0 ${theme.spacing(2)} ${theme.spacing(3)}`};
  `}
`;

export const POCMobileWrapper = styled(POCWrapper)`
  margin: 0;
`;

export const InputSection = styled.div``;

export const InputLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
