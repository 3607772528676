import React, { useEffect, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { useForm } from 'react-final-form-hooks';
import { IBulletinInput, ResolutionPreference, User, PocType } from 'utils/entities';
import withEvents from 'analytics/withEvents';
import { IUploadBulletinFormValues } from 'utils/uploadBulletin';
import { genUploadSteps, uploadBulletinDraft } from './helpers';
import { DraftState, UploadBulletinStep } from './types';
import {
  Container,
  ErrorContainer,
  ErrorInner,
  FormControlButtonsInner,
  FormControlButtonsWrapper,
  FormInner,
  FormWrapper,
  NextButtonWrapper,
  ProgressHint,
  StepsProgressWrapper,
} from 'screens/UploadBulletinPage/styled';
import AttentionIcon from 'assets/svg/attention.svg';
import { StepsProgress } from 'components/progress';
import { BasicButton } from 'ds/components/button/BasicButton';
import { CleanButton } from 'ds/components/button';
import { SmallText } from 'ds/components/typography';
import ManageLink from 'screens/UploadBulletinPage/components/manage-link';
import FreeUserMessage from 'screens/UploadBulletinPage/components/free-user-message';
import {
  AdditionalInfoStep,
  AddressStep,
  AuthenticationStep,
  BulletinTypeStep,
  ContactsStep,
  DealTypeStep,
  MainInfoStep,
  PreviewStep,
  SelectAgentStep,
  UploadImageStep,
  UploadBulletinStepProps,
} from 'screens/UploadBulletinPage/steps';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { useLocale } from 'locale';
import { NavigateToFn, State as RouteState } from 'config/routes';
import { stepToAnalyticsStep } from 'screens/UploadBulletinPage/analyticsHelpers';
import ValidatePhoneButton from 'screens/UploadBulletinPage/ValidatePhoneButton';


const defaultInitialValues: Partial<IUploadBulletinFormValues> = {
  availabilityDateMonth: (new Date).getMonth(),
  availabilityDateYear: (new Date).getFullYear(),
  floor: undefined,
  floors: undefined,
  amenities: [],
  userName: undefined,
  userPhone: '',
  buildingYear: undefined,
  description: '',
  furniture: null,
};


export interface UploadBulletinPageOwnProps {
  isEdit?: boolean;
}

interface UploadBulletinPageProps extends UploadBulletinPageOwnProps {
  user: User;
  navigate: NavigateToFn;
  route: RouteState;
  isBulletinsAdmin: boolean;
  isAgent: boolean;
  isAgentManager: boolean;
  isConnectedUser: boolean;
  isPrivateUser: boolean;
  isUserCommercialAllowed: boolean;
  initialValues?: DraftState;
  isLoading: boolean;
  submitting: boolean;
  submitForm: (formData: IBulletinInput) => void;
  onGoToNextStep?: (step: UploadBulletinStep, index: number) => void;
}

export const LS_UPLOAD_BULLETIN_KEY = 'UPLOAD_BULLETIN_DRAFT';

const UploadBulletinPage: React.FC<UploadBulletinPageProps> = ({
  isLoading,
  user,
  submitForm,
  initialValues,
  isEdit,
  navigate,
  route,
  isAgent,
  isBulletinsAdmin,
  isAgentManager,
  isConnectedUser,
  isPrivateUser,
  isUserCommercialAllowed,
  submitting,
  onGoToNextStep,
}) => {
  let formInitialValues;
  if (!isEdit && !isUserCommercialAllowed && initialValues && initialValues.formValues && initialValues.formValues.type === 'commercialBulletin') {
    formInitialValues = defaultInitialValues;
  }
  else {
    formInitialValues = initialValues ? initialValues.formValues : defaultInitialValues;
  }

  const contactInitialValues = {
    userName: user && user.firstName || '',
    userPhone: user && user.phone || '',
  };

  const { handleSubmit, form, valid } = useForm<Partial<IUploadBulletinFormValues>>({
    onSubmit: noop,
    initialValues: {
      ...formInitialValues,
      userName: formInitialValues.userName || contactInitialValues.userName,
      userPhone: formInitialValues.userPhone || contactInitialValues.userPhone,
      resolutionPreferences: isAgent
        ? 'resolutionPreferences' in formInitialValues && formInitialValues.resolutionPreferences || ResolutionPreference.Accurate
        : null,
    },
  });
  const sellerType = isEdit ? formInitialValues.sellerType : isAgent ? PocType.Agent : PocType.Private;
  const [ showError, setShowError ] = useState(false);
  const [ hideErrorOnFocus, setHideErrorOnFocus ] = useState(false);
  const isDesktop = useScreenBreakpoint() > 2;
  const { t } = useLocale();
  const isCommercial = form.getState().values.type === 'commercialBulletin';
  const steps = useMemo(() => genUploadSteps(isEdit, isCommercial, sellerType, {
    isAgent,
    isBulletinsAdmin,
    isAgentManager,
    isConnectedUser,
  }), [ isEdit, isCommercial, isBulletinsAdmin, isAgent, sellerType, isAgentManager, isConnectedUser ]);

  const maybeHideErrorOnFocus = isDesktop ? noop : setHideErrorOnFocus;

  useEffect(() => {
    if (isEdit) return null;

    const uns = form.subscribe((fState) => {
      uploadBulletinDraft.set({ formValues: fState.values });
    }, { values: true });
    return () => uns();
  }, [ isEdit ]);

  if (isLoading) return null;

  const currentStepIndex = steps.indexOf(route.params.step) === -1 ? 0 : steps.indexOf(route.params.step);
  const currentStep = steps[currentStepIndex];
  const totalSteps = steps.length;
  const isAuthStep = currentStep === UploadBulletinStep.Authentication;
  const isCommercialDisabled = currentStep === UploadBulletinStep.BulletinType && !isEdit && !isUserCommercialAllowed;
  const disableUpload = isCommercialDisabled && form.getState().values.type === 'commercialBulletin';
  const isValidatePhone = isPrivateUser && currentStep === UploadBulletinStep.Contacts;

  const goToStep = async (direction: 'next' | 'prev', valueChanged?: boolean) => {
    await form.submit();

    switch (direction) {
      case 'next':
        const values = form.getState().values;
        const isCommercialListing = values.type === 'commercialBulletin';
        if (isCommercialDisabled && isCommercialListing) {
          return;
        }
        setShowError(!valid && currentStep !== UploadBulletinStep.DealType && currentStep !== UploadBulletinStep.BulletinType);
        const isBulletinTypeNextStep = currentStep === UploadBulletinStep.BulletinType && values.type;
        const isDealTypeNextStep = currentStep === UploadBulletinStep.DealType && values.dealType;

        let nextStep = steps[currentStepIndex + 1];
        if (isBulletinTypeNextStep) {
          const newSteps = genUploadSteps(isEdit, isCommercialListing, sellerType, {
            isAgent,
            isBulletinsAdmin,
            isAgentManager,
            isConnectedUser,
          });
          nextStep = newSteps[currentStepIndex + 1];

          if (!isEdit && valueChanged) {
            uploadBulletinDraft.set({ formValues: {
              ...defaultInitialValues,
              ...contactInitialValues,
              type: values.type,
            }});
          }
        }
        else if (isDealTypeNextStep && !isEdit && valueChanged) {
          uploadBulletinDraft.set({ formValues: {
            ...defaultInitialValues,
            ...contactInitialValues,
            type: values.type,
            dealType: values.dealType,
          }});
        }

        if (valid || isBulletinTypeNextStep || isDealTypeNextStep) {
          onGoToNextStep(currentStep, currentStepIndex);
          navigate(route.name, {
            ...route.params,
            step: nextStep,
            dealType: undefined,
            term: undefined,
          });
        }
        break;
      case 'prev':
        setShowError(false);

        navigate(route.name, {
          ...route.params,
          step: steps[currentStepIndex - 1],
          dealType: undefined,
          term: undefined,
        });
        break;
    }
  };

  const goToNextStep = () => goToStep('next');
  const goToPrevStep = () => goToStep('prev');
  const moveForward = (valueChanged?: boolean) => goToStep('next', Boolean(valueChanged));
  const bulletinTypeMoveForward = (valueChanged?: boolean) => isCommercialDisabled ? noop : moveForward(valueChanged);

  let stepContent;
  let isPreviewStep = false;

  const commonProps: UploadBulletinStepProps = {
    formInstance: form,
    hideErrorOnFocus: maybeHideErrorOnFocus,
    isCommercial,
  };

  switch (currentStep) {
    case UploadBulletinStep.AdditionalInfo:
      stepContent = <AdditionalInfoStep {...commonProps} />;
      break;
    case UploadBulletinStep.Address:
      stepContent = <AddressStep {...commonProps} withResolutionPref={isAgent && isCommercial} />;
      break;
    case UploadBulletinStep.Authentication:
      stepContent = <AuthenticationStep />;
      break;
    case UploadBulletinStep.Contacts:
      stepContent = (
        <ContactsStep
          {...commonProps}
          showTip={isConnectedUser && !isBulletinsAdmin && !isAgent && !isAgentManager}
          isPrivateUser={isPrivateUser}
        />
      );
      break;
    case UploadBulletinStep.DealType:
      stepContent = <DealTypeStep {...commonProps} goToNextStep={moveForward} />;
      break;
    case UploadBulletinStep.MainInfo:
      stepContent = <MainInfoStep {...commonProps} />;
      break;
    case UploadBulletinStep.Preview:
      isPreviewStep = true;
      stepContent = <PreviewStep  {...commonProps} isAgent={isAgent} submitForm={submitForm} submitting={submitting} />;
      break;
    case UploadBulletinStep.SelectAgent:
      stepContent = <SelectAgentStep {...commonProps} />;
      break;
    case UploadBulletinStep.UploadImage:
      stepContent = <UploadImageStep {...commonProps} showFormError={setShowError} />;
      break;
    case UploadBulletinStep.BulletinType:
    default:
      stepContent = <BulletinTypeStep {...commonProps} goToNextStep={bulletinTypeMoveForward} />;
  }

  if (isPreviewStep) {
    return stepContent;
  }


  return (
    <Container>
      {showError && !valid && !hideErrorOnFocus ? (
        <ErrorContainer>
          <ErrorInner>
            <AttentionIcon width={20} height={18}/>
            {t('uploadBulletinPage.error', { step: currentStep })}
          </ErrorInner>
        </ErrorContainer>
      ) : null}
      <FormWrapper>
        <FormInner>
          <StepsProgressWrapper>
            <ProgressHint weight="medium">
              {isDesktop ? t('uploadBulletinPage.stepProgress.hint', { isEdit }) : null}
              {t('uploadBulletinPage.stepProgress.counter', {
                current: currentStepIndex + 1,
                count: totalSteps,
              })}
            </ProgressHint>
            <StepsProgress count={totalSteps} current={currentStepIndex} withoutGaps />
          </StepsProgressWrapper>
          {isAuthStep ? stepContent : (
            <form onSubmit={handleSubmit}>
              {stepContent}
            </form>
          )}
          {disableUpload ? <FreeUserMessage /> : null}
        </FormInner>
      </FormWrapper>

      {isAuthStep ? null : (
        <FormControlButtonsWrapper>
          <FormControlButtonsInner>
            {isValidatePhone ? (
              <ValidatePhoneButton goToNextStep={goToNextStep} form={form} />
            ) : (
              <NextButtonWrapper>
                <BasicButton size="large" onClick={goToNextStep} fullWidth={isDesktop} disabled={disableUpload}>
                  {t('uploadBulletinPage.nextStepButtonLabel', { isValidatePhone })}
                </BasicButton>
              </NextButtonWrapper>
            )}
            {currentStepIndex < 1 ? null : (
              <CleanButton mode="secondary" size="large" onClick={goToPrevStep}>
                <SmallText>{t('uploadBulletinPage.prevStepButtonLabel')}</SmallText>
              </CleanButton>
            )}
            <ManageLink />
          </FormControlButtonsInner>
        </FormControlButtonsWrapper>
      )}
    </Container>
  );
};

export default withEvents<UploadBulletinPageProps>((sendEvent, { isEdit, route }) => ({
  onGoToNextStep: (step: UploadBulletinStep, index: number) => !isEdit && sendEvent('bulletin_upload_wizard_next_click', 'bulletin_upload_wizard', {
    event: {
      'upload_wizard_step': stepToAnalyticsStep[step],
      'upload_wizard_step_index': index + 1,
      source: route.params && route.params.source || 'other',
    },
  }),
}))(UploadBulletinPage);
