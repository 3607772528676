import React from 'react';
import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { Text } from 'ds/components/typography';

export type Size = 'medium' | 'large' | 'extraLarge';

const sizeToHeight = {
  medium: 32,
  large: 40,
  extraLarge: 48,
};

const WrapperClass = styled.div`
  position: relative;
  display: flex;
  min-width: 0;
  margin: 0 -${({ theme }) => theme.spacing(0.5)};
`;

const FilterStyleItem = styled(Text)<{active: boolean, isRound?: boolean, size?: Size}>`
  border-radius: ${({ theme }) => theme.borderRadius.smallestSideRound};
  color: ${props => props.theme.colors.neutrals.grey1};
  background-color: ${props => props.theme.colors.neutrals.white};
  cursor: pointer;
  letter-spacing: 0.5px;
  padding: 0 ${({ theme, size }) => size !== 'medium' ? theme.spacing(2) : 0};
  justify-content: center;
  outline: none;
  margin: 0 ${({ theme }) => theme.spacing(0.5)};
  min-height: ${({ size = 'large' }) => sizeToHeight[size]}px;
  min-width: ${({ size = 'large' }) => sizeToHeight[size]}px;
  border: 1px solid ${props => props.theme.colors.neutrals.grey6};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  display: flex;
  align-items: center;
  span {
    text-align: center;
  }

  ${props => props.active ? `
    border: 1px solid ${props.theme.colors.brand.lighter};xk
    background-color: ${colorToAlpha(props.theme.colors.brand.lightest, 0.7)};
    color: ${props.theme.colors.brand.main};
  ` : `
    &:hover {
      border: 1px solid ${props.theme.colors.neutrals.grey4};
    }
  `}
`;

export interface ButtonSetProps {
  buttons: ButtonItem[];
}

export interface ButtonItem {
  label: React.ReactNode;
  click: (e: React.MouseEvent<any>) => void;
  active?: boolean;
  isRound?: boolean;
  size?: Size;
}
export class ButtonSet extends React.Component<ButtonSetProps> {

  public renderItem(button: ButtonItem, index: number) {
    const { label, click, active, isRound, size, ...divProps } = button;
    return (
      <FilterStyleItem
        key={index}
        active={active}
        onClick={click}
        isRound={isRound}
        size={size}
        {...divProps}
        data-auto={`filter-select-button-${label}`}
        data-auto-active={active}
      >
        {label}
      </FilterStyleItem>
    );
  }

  public render() {
    const { buttons } = this.props;

    return (
      <WrapperClass data-auto="selectButtonsRoot">
        {buttons.map(this.renderItem)}
      </WrapperClass>
    );
  }
}
