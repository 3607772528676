import { IEnhancer, Fetcher } from '.';
import { UserTokenStore } from 'helpers/userToken';
import { getDevAuthHeaders } from 'utils/getDevAuthHeaders';

function isRmoveNotificationsDevice(body: BodyInit) {
  if (typeof body === 'string') {
    let bodyObj = null;
    try {
      bodyObj = JSON.parse(body);
    }
    catch (e) {
      return false;
    }
    if (bodyObj && bodyObj.operationName === 'removeNotificationDevice') {
      return true;
    }
  }
  return false;
}

export class AuthFetchEnhancer implements IEnhancer {
  private lastToken: string | null = null;
  constructor(
    private tokenStore: UserTokenStore
  ) { }

  public enhance = (url: string, init: RequestInit, innerFetch: Fetcher) => {
    const headers = {
      ...init.headers,
      ...getDevAuthHeaders(),
    };
    const token = this.tokenStore.get();

    if (token) {
      this.lastToken = token;
      Object.assign(headers, {
        Authorization: `Bearer ${this.tokenStore.get()}`,
      });
    }
    // a hack to add last token to removeNotificationDevice mutation (for logout)
    else if (isRmoveNotificationsDevice(init.body)) {
      Object.assign(headers, {
        Authorization: `Bearer ${this.lastToken}`,
      });
    }

    return innerFetch(url, { ...init, headers });
  }
}
