import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { IFavoritesToggle, IFavoritesToggleMeta } from 'store/sagas/apiService/types';
import { toggleFavorites } from 'store/state/app/actions';
import { State } from 'store/state';
import { savedPoiIdsSelector } from 'store/state/searchContext/selectors';
import { PoiId, PoiType } from 'utils/entities';
import { Wrapper } from './styled';
import AnimatedHeartIcon from 'components/favorites/animated-heart-icon/AnimatedHeartIcon';

interface FavoritesToggleProps {
  onToggleFavorites: (variables: IFavoritesToggle, meta: IFavoritesToggleMeta) => void;
  isFavorite: boolean;
}

interface FavoritesToggleOwnProps {
  id: PoiId;
  type: PoiType;
  eventSource?: string;
  size?: 'medium' | 'large';
}

const FavoritesToggle: React.FC<FavoritesToggleProps & FavoritesToggleOwnProps> = ({
  onToggleFavorites,
  isFavorite,
  id,
  type,
  eventSource,
  size = 'medium',
}) => {
  const toggleFavoritesHandler = useCallback(() => {
    onToggleFavorites({
      favorites: [ { id, type } ],
      isFavorite,
    }, { source: eventSource });
  }, [ onToggleFavorites, isFavorite ]);

  return (
    <Wrapper
      data-auto="bulletin-favorite-button"
      data-auto-active={isFavorite}
      onClick={toggleFavoritesHandler}
      size={size}
    >
      <AnimatedHeartIcon isFilled={isFavorite} size={size === 'large' ? 26 : undefined} />
    </Wrapper>
  );
};

const mapDispatchToProps = {
  onToggleFavorites: toggleFavorites,
};

const mapStateToProps = (state: State, props: FavoritesToggleOwnProps) => ({
  isFavorite: savedPoiIdsSelector(state).has(props.id),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesToggle);
