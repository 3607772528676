import { BiEvent } from 'analytics';
import { eventChannel, Saga, buffers } from 'redux-saga';
import { takeEvery, call, fork, getContext } from 'redux-saga/effects';
import { insightsPreProcessor, closeInsightWorker } from './insights';
import { propertyPreProcessor } from './property';
import { userPreferencesPreProcessor } from './userPreferences';
import { propertyEventsWorker } from './propertyEvents';
import { searchPreProcessor, searchResultsWorker } from './search';
import { userPreProcessor } from './userPreProcessor';
import { sendEvent } from '@madlan145/client-toolbox';

import { homepageEventsWorker } from './homepageEvents';
import { addressPageEventsWorker } from './addressPage';
import { favoritesEventsWorker } from './favoritesWorker';
import { userLoginChangeWorker } from './userLoginWorker';
import { savedSearchEventsWorker } from './savedSearchEvents';
import { followAddressEventsWorker } from './followAddressEvents';
import { userAuthEventsWorker } from './userAuthEvents';
import { contactAgentModalEventsWorker, contactAgentModalPreProcessor } from './contactAgentModalEvents';
import { madadContactWatcher } from './madadContact';
import { ugcEventsWatcher } from './ugcEvents';
import { tabuEventsWatcher } from './tabuEventsWatcher';
import { uploadBulletinEventsWorker } from './uploadBulletinEvents';
import { developerPageWorker } from './developersSearchPage';
import { mapHoverDataLayerWatcher } from './mapHoverLayerEvents';
import { mortgageAdvisorPageWorker } from './mortgageAdvisorEvents';
import { madadAgentOfficeContactWatcher } from './agentPageContact';
import { agentPageWorker } from './agentPageEvents';
import { officePageWorker } from './officePageEvents';

const processors: Saga[] = [
  insightsPreProcessor,
  propertyPreProcessor,
  userPreferencesPreProcessor,
  searchPreProcessor,
  userPreProcessor,
  contactAgentModalPreProcessor,
];

export function* analyticsWorker(evt: BiEvent) {
  let lastResult: BiEvent = evt;
  for (const processor of processors) {
    lastResult = yield call(processor, lastResult);
    if (!lastResult) return;
  }

  yield call(
    sendEvent,
    lastResult.name,
    lastResult.category,
    lastResult.payload
  );
}

export function* analyticsWatcher() {
  const { subscribe } = yield getContext('analytics');
  const channel = yield call(eventChannel, subscribe, buffers.expanding(10));

  yield takeEvery(channel, analyticsWorker);
  yield fork(propertyEventsWorker);
  yield fork(homepageEventsWorker);
  yield fork(addressPageEventsWorker);
  yield fork(userAuthEventsWorker);
  yield fork(contactAgentModalEventsWorker);
  yield fork(favoritesEventsWorker);
  yield fork(savedSearchEventsWorker);
  yield fork(followAddressEventsWorker);
  yield fork(closeInsightWorker);
  yield fork(searchResultsWorker);
  yield fork(userLoginChangeWorker);
  yield fork(tabuEventsWatcher);
  yield fork(madadContactWatcher);
  yield fork(madadAgentOfficeContactWatcher);
  yield fork(ugcEventsWatcher);
  yield fork(uploadBulletinEventsWorker);
  yield fork(developerPageWorker);
  yield fork(mortgageAdvisorPageWorker);
  yield fork(mapHoverDataLayerWatcher);
  yield fork(agentPageWorker);
  yield fork(officePageWorker);
}
