import { ITranslate } from '../locale';
import { memoize } from 'lodash';
export const MS_IN_HOUR = 3.6e+6; // 1000 * 60 * 60
const MS_IN_DAY = 8.64e+7; // MS_IN_HOUR * 24

export const daysFrom = (earlierDate: Date, laterDate: Date = new Date()) => {
  return Math.floor((laterDate.getTime() - earlierDate.getTime()) / MS_IN_DAY);
};

export const hoursFrom = (earlierDate: Date, laterDate: Date = new Date()) => {
  return Math.floor((laterDate.getTime() - earlierDate.getTime()) / MS_IN_HOUR);
};

export const generateDaysOptions = (
  pickedMonth: number,
  pickedYear: number,
  pickedStartingDateDays?: number,
  pickedStartingDateMonth?: number,
  pickedStartingDateYear?: number
) => {
  const days = (month: number, year: number) => (
    new Date(year, month, 0).getDate()
  );

  if (pickedStartingDateYear === pickedYear) {
    if (pickedStartingDateMonth === pickedMonth) {
      return new Array(days(pickedMonth, pickedYear) - pickedStartingDateDays).fill(null).map((_, index) => ({
        value: pickedStartingDateDays + index, label: pickedStartingDateDays + index,
      }));
    }
  }

  return new Array(days(pickedMonth, pickedYear)).fill(null).map((_, index) => ({
    value: index + 1, label: index + 1,
  }));
};

export const generateMonthOptions = memoize((t: ITranslate) => new Array(12).fill(null).map((_, index) => ({
  value: index, label: t('months', { month: index }),
})));


export const generateYearOptions = memoize((numberOfYearSinceNow: number = 12) => new Array(Math.abs(numberOfYearSinceNow + 1)).fill(null).map((_, index) => {
  const currentYear = (new Date()).getFullYear();
  const optionStep = numberOfYearSinceNow < 0 ? index * (-1) : index;

  return { value: currentYear + optionStep, label: currentYear + optionStep };
}));

export const translateApproximatePastPeriod = (t: ITranslate, dateString?: string) => {
  const DAYS_IN_WEEK = 7;
  const DAYS_IN_MONTH = 30;
  const DAYS_IN_YEAR = 365;
  const HOURS_IN_DAY = 24;

  const days = daysFrom(new Date(dateString));
  const hours = hoursFrom(new Date(dateString));

  if (hours < 1) {
    return t('pastPeriod.justNow');
  }
  else if (hours <= HOURS_IN_DAY) {
    return t('pastPeriod.hours', { count: hours });
  }
  else if (days <= DAYS_IN_WEEK) {
    return t('pastPeriod.days', { count: days });
  }
  else if (days <= DAYS_IN_MONTH) {
    const weeks = Math.floor(days / DAYS_IN_WEEK);
    return t('pastPeriod.weeks', { count: weeks });
  }
  else if (days <= DAYS_IN_YEAR) {
    const months = Math.floor(days / DAYS_IN_MONTH);
    return t('pastPeriod.months', { count: months });
  }
  else {
    const years = Math.floor(days / DAYS_IN_YEAR);
    return t('pastPeriod.years', { count: years });
  }
};
