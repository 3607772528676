import React from 'react';
import { PlaceholderWrapper } from 'ds/components/ListingCard';

export const ROOT_CLASS_NAME = 'listing-card-placeholder';

export const ListingCardPlaceholder: React.FunctionComponent<{ disableAnimation?: boolean }> = ({ disableAnimation }) => (
  <PlaceholderWrapper
    disableAnimation={disableAnimation}
    className={ROOT_CLASS_NAME}
  >
    <div />
    <div />
    <div />
    <div />
    <div />
  </PlaceholderWrapper>
);
