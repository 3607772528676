import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';

export const ReportPopupContentWrapper = styled.div`
  position: relative;
  display: flex;
`;


// Report form

export const ReportFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CommentTextWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    margin-top: ${theme.spacing(2)};

    & > div {
      width: 100%;
    }
  `}
`;

export const EmailWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(5)};
  `}
`;

export const Description = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
    margin-bottom: ${theme.spacing(2)};
  `}
`;
