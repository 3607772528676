import { InputUGCEntry, IUserTextReviewResponse } from 'store/sagas/apiService/types';
import { Topic, Step, TOPICS, DEFAULT_ANSWER_TYPES, RatingTopic } from './initialUGCConfig';
import { SetEmotionPayload } from 'store/state/UGC/payloads';
import { ShareType } from 'helpers/share-helpers';

export enum StepOrder {
  TextReviewFirst = 'text',
  QuestionsFirst = 'rating',
}

export const RATING_STEPS: ReadonlySet<RatingTopic> = new Set([
  Topic.Service,
  Topic.SafetyUnit,
  Topic.Roomates,
  Topic.Doorman,
]);

export const filterRatingReviewEntries = (items: InputUGCEntry[]) => {
  return items.filter((item: InputUGCEntry) => RATING_STEPS.has(item.topic as any));
};

export const createRatingReviewSteps = (items: InputUGCEntry[]): Step[] => {
  return TOPICS
    .map(topic => {
      const completed = items.find(item => item.topic === topic);
      const activeAnswer = completed && completed.topic !== Topic.TextReview ? completed.numericReview : null;
      return { type: topic, answerTypes: [ ...DEFAULT_ANSWER_TYPES ], activeAnswer };
    })
    .sort((a, b) => {
      if (a.activeAnswer === null && b.activeAnswer === null) return 0;
      return a.activeAnswer === null ? -1 : 0;
    });
};

export const createUgcStepsOptions = (
  hasRatingsSteps: boolean,
  items: InputUGCEntry[],
  order: StepOrder = StepOrder.QuestionsFirst
): Step[] => {
  const textReviewStep = { type: Topic.TextReview };
  if (!hasRatingsSteps) {
    return [ textReviewStep ];
  }

  const ratingReviewSteps = createRatingReviewSteps(items);

  return order === StepOrder.QuestionsFirst
    ? [ ...ratingReviewSteps, textReviewStep ]
    : [ textReviewStep, ...ratingReviewSteps ];
};

export const createEmotionPayload = (item: IUserTextReviewResponse, newUserEmotion: number): SetEmotionPayload => {
  const oldUserEmotion = item.emotions && item.emotions.userEmotion;
  return {
    ugcId: item.ugcId,
    userEmotion: newUserEmotion,
    isAddEmotion: oldUserEmotion !== newUserEmotion,
  };
};

type UgcShareReviewEventMethod = 'facebook' | 'messenger' | 'twitter' | 'copy_link' | 'mail' | 'whats_app';
export const mapShareTypeToUgcShareEventMethod: Record<ShareType, UgcShareReviewEventMethod> = {
  [ShareType.FacebookMessenger]: 'messenger',
  [ShareType.Email]: 'mail',
  [ShareType.CopyLink]: 'copy_link',
  [ShareType.Facebook]: ShareType.Facebook,
  [ShareType.Twitter]: ShareType.Twitter,
  [ShareType.WhatsApp]: 'whats_app',
};
