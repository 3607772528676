import config from 'config';
import {
  CompletionType,
  DealType,
  IAutocompleteEntry,
  IAutocompleteAgent,
  IAutocompleteOffice,
  IAutocompleteDeveloper,
  IAutocompleteSchool,
  IAutocompleteAddress,
  MarketplaceType,
} from 'utils/entities';
import { AppMode } from 'store/state/selectors/router';
import { SortValue } from 'components/listing-sort/types';
import { Route, DecodedRouteParams } from 'config/routes';
import { CompletionListGroup, config as completionConfig } from 'components/commute-popup/CompletionTypeConfig';
import { NavigationOptions } from 'store/state/router/actionPayloads';


const searchModeToTrackingType: Record<Exclude<AppMode, 'blog'>, string> = {
  [AppMode.Buy]: 'buy_home',
  [AppMode.Rent]: 'rent_home',
  [AppMode.Check]: 'check_address',
};

export enum PersonaType {
  FAMILIES = 'families',
  QUIET = 'quiet',
  PRICE_ESTIMATION = 'priceEstimation',
}

export interface IAutocompleteTrackingEventMeta {
  selectedItems: IAutocompleteEntry[];
  searchMode: AppMode;
  persona?: PersonaType;
  powersortOptions?: SortValue[];
}

export function mapLocationTypeForEvents(type: CompletionType) {
  switch (type) {
    case CompletionType.CustomZone:
      return 'area';
    case CompletionType.SchoolDoc:
      return 'school_zone';
    case CompletionType.EmploymentArea:
      return 'employment_area';
    default:
      return type;
  }
}

export function buildSearchPayload(meta: IAutocompleteTrackingEventMeta) {
  return {
    search_type: searchModeToTrackingType[meta.searchMode],
    text_entered: meta.selectedItems.map((item: IAutocompleteAddress) => item.docId),
    persona_type: meta.persona,
    power_sort_options: meta.powersortOptions,
    locations_selected: meta.selectedItems.map((item: IAutocompleteAddress) => ({
      address: item.name,
      borough: item.borough ? item.borough : undefined,
      city: item.city,
      country: config.country,
      longitude: item.location && item.location[0],
      latitude: item.location && item.location[1],
      location_id: item.id,
      location_type: mapLocationTypeForEvents(item.type),
      neighborhood: item.neighbourhood,
      zip_code: item.zipcode,
    })),
  };
}

type AutocompleteEntryWithUrl = IAutocompleteAgent | IAutocompleteOffice | IAutocompleteDeveloper | IAutocompleteSchool;

export function isExternalSearch(input: IAutocompleteEntry): input is AutocompleteEntryWithUrl {
  return completionConfig[CompletionListGroup.External].includes(input.type) && 'url' in input;
}

export function getResidentialNavigationParams(
  input: IAutocompleteEntry,
  appMode: AppMode,
  additionalRouteParams?: Partial<DecodedRouteParams>
) {
  let route: Route = null;
  let params: DecodedRouteParams = null;
  let options: NavigationOptions = null;
  if (input.type === CompletionType.Project) {
    route = Route.ProjectPage;
    params = {
      ...additionalRouteParams,
      term: undefined,
      dealType: undefined,
      address: undefined,
      id: input.id,
    };
  }
  else if (input.type === CompletionType.CommercialProject) {
    route = Route.ProjectPageCommercial;
    params = {
      ...additionalRouteParams,
      term: undefined,
      dealType: undefined,
      address: undefined,
      id: input.id,
    };
  }
  else if (appMode === AppMode.Check && input.type === CompletionType.Street) {
    route = Route.StreetPage;
    params = {
      ...additionalRouteParams,
      id: input.docId,
      term: [ input.docId ],
      dealType: undefined,
    };
  }
  else if (input.type === CompletionType.Address && appMode === AppMode.Check) {
    route = Route.AddressPage;
    params = {
      ...additionalRouteParams,
      address: input.docId,
      term: undefined,
      dealType: undefined,
    };
  }
  else if (appMode === AppMode.Check && (
    completionConfig[CompletionListGroup.OnlyLocalPages].includes(input.type)
  )) {
    route = Route.LocalPage;
    params = {
      ...additionalRouteParams,
      id: input.docId,
      term: [ input.docId ],
      dealType: undefined,
    };
  }
  else if (appMode === AppMode.Check && (
    input.type === CompletionType.EmploymentArea
  )) {
    route = Route.EmploymentAreaPage;
    params = {
      ...additionalRouteParams,
      id: input.docId,
      term: [ input.docId ],
      dealType: DealType.Buy,
      marketplace: MarketplaceType.Commercial,
    };
  }
  else if (isExternalSearch(input)) {
    options = {
      externalUrl: input.url,
    };
  }
  else {
    route = Route.Search;
    params = {
      ...additionalRouteParams,
      term: [ input.docId ],
      dealType: appMode === AppMode.Buy ? DealType.Buy : DealType.Rent,
    };
  }

  return {
    route,
    options,
    params: {
      ...params,
      marketplace: params && params.marketplace || MarketplaceType.Residential,
    },
  };
}

export function getCommercialNavigationParams(
  input: IAutocompleteEntry,
  appMode: AppMode,
  additionalRouteParams?: Partial<DecodedRouteParams>
) {
  let route: Route = null;
  let params: DecodedRouteParams = null;
  let options: NavigationOptions = null;
  if (input.type === CompletionType.CommercialProject) {
    route = Route.ProjectPageCommercial;
    params = {
      ...additionalRouteParams,
      term: undefined,
      dealType: undefined,
      address: undefined,
      id: input.id,
    };
  }
  else if (appMode === AppMode.Check && input.type === CompletionType.EmploymentArea) {
    route = Route.EmploymentAreaPage;
    params = {
      ...additionalRouteParams,
      term: [ input.docId ],
      dealType: DealType.Buy,
      id: input.docId,
    };
  }
  else if (appMode === AppMode.Check && input.type === CompletionType.Street) {
    // TODO
  }
  else if (input.type === CompletionType.Address && appMode === AppMode.Check) {
    // TODO
  }
  else if (appMode === AppMode.Check && (
    completionConfig[CompletionListGroup.OnlyLocalPages].includes(input.type)
  )) {
    // TODO
  }
  else if (isExternalSearch(input)) {
    options = {
      externalUrl: input.url,
    };
  }
  else {
    route = Route.SearchCommercial;
    params = {
      ...additionalRouteParams,
      term: [ input.docId ],
      dealType: appMode === AppMode.Buy ? DealType.Buy : DealType.Rent,
      id: input.docId,
    };
  }

  return {
    route,
    options,
    params: {
      ...params,
      marketplace: MarketplaceType.Commercial,
    },
  };
}
