import styled from '@emotion/styled';
import { SelectWrapper } from '../../styled';

export const DealTypeSelectWrapper = styled(SelectWrapper)`
  .select-items-wrapper {
    justify-content: space-between;
    margin-right: 0;
  }

  button {
    margin: 0;
    width: 48%;
  }
`;
