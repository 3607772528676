import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import FilterSection from '../common/DropdownSection';
import { useLocale } from 'locale';
import { FiltersSingleComponentsMap } from 'components/filters/config';
import { AdditionalFiltersWrapper } from '../styled/common';
import { GenericFilterProps, GroupFilterProps } from '../types';
import { ShowAt } from 'consts/breakpoints';
import { LocalStorage } from 'utils/localStorage';
import { noPopupsSelector } from 'store/state/selectors/router';


const KEY = 'PRICE_TOOLTIP';
const ONE_HOUR_MS = 3600 * 1000;
const TOOLTIP_TIMES_TO_SHOW = 2;


const AdditionalFilters: React.FC<GroupFilterProps> = (props) => {
  const {
    getProps,
    commitValues,
    isFilterActive,
    filters,
    config,
    commitedValues,
    intermediateValues,
    countActiveFilters,
    marketplace,
    makeMultipleResetValue,
    isHeaderTabSubmenuVisible,
    noPopups,
  } = props;

  const { t } = useLocale();
  const [ showTooltip, setShowTooltip ] = useState(false);
  const filtersAliases = useMemo(() => filters.map(({ filterType }) => filterType), [ filters ]);

  useEffect(() => {
    if (noPopups) return;

    const tooltipLocalState: {count: number, time: number} = LocalStorage.get(KEY);

    if (!tooltipLocalState) {
      setShowTooltip(true);
      LocalStorage.set(KEY, { count: 1, time: Date.now() });
    }
    else {
      const { count, time } = tooltipLocalState || {};
      if (count < TOOLTIP_TIMES_TO_SHOW && (Date.now() - time) > ONE_HOUR_MS) {
        setShowTooltip(true);
        LocalStorage.set(KEY, { count: count + 1, time: Date.now() });
      }
      else {
        setShowTooltip(false);
      }
    }
  }, [ noPopups ]);

  const isActive = isFilterActive(filtersAliases, commitedValues);
  const reset = () => makeMultipleResetValue(filtersAliases);

  return (
    <AdditionalFiltersWrapper data-auto="filter-moreFilters-wrapper">
      <FilterSection
        isClearButtonDisabled={!isActive}
        type="moreFilters"
        automationTag="moreFilters"
        icon={config.filtersIcons.additional}
        onClear={reset}
        label={t('filters.additional', { count: countActiveFilters(filtersAliases, commitedValues) })}
        onSubmit={commitValues}
        isActive={isActive}
        withBottomSpacing
        ignoreWrapperPadding
        withPriceTooltip={showTooltip && !isHeaderTabSubmenuVisible}
      >
        <ShowAt to={2}>
          {isMobile => filters.map(({ filterType, isInline }) => {
            const Component: React.ComponentType<GenericFilterProps<typeof filterType>> = FiltersSingleComponentsMap[marketplace][filterType];
            return <Component key={filterType} {...getProps(filterType, intermediateValues, commitedValues)} isInline={isInline} isMobile={isMobile} />;
          })}
        </ShowAt>
      </FilterSection>
    </AdditionalFiltersWrapper>
  );
};

const mapStateToProps = (state: State) => ({
  noPopups: noPopupsSelector(state),
});

export default connect(mapStateToProps)(AdditionalFilters);
