import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ShareModal } from './ShareModal';
import { State } from 'store/state';
import { setShareModalOpen } from 'store/state/app/actions';
import { shareModalTypeSelector } from 'store/state/app/selectors';


export const isShareModalOpenSelector = createSelector([
  shareModalTypeSelector,
  (state: State, name: ShareModalType) => name,
], (type: ShareModalType, name: ShareModalType) => (
    type === name
  )
);

export enum ShareModalType {
  UploadBulletinShareModal = 'UploadBulletinShareModal',
  UgcGratitudeModal = 'UgcGratitudeModal',
  UgcTextReview = 'UgcTextReview',
}

export interface ShareModalOwnProps {
  mainIcon?: JSX.Element;
  header: string;
  description?: string;
  renderActions: (link: string) => JSX.Element;
  getBaseLink: () => string;
  name: ShareModalType;
}

const mapStateToProps = (state: State, props: ShareModalOwnProps) => ({
  isOpen: isShareModalOpenSelector(state, props.name),
});

const mapDispatchToProps = {
  setOpen: setShareModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
