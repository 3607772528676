// @todo refactor date parsing logic

export type ISOString = string;


// Safari converts iso date into date incorrectly. So please make changes here carefully

export const parseISODate = (dateS: ISOString) => {
  /**
   * this could look prettier, but it works
   * quicker this way, sorry
   */
  const YYYY = +dateS.substring(0, 4);
  const MM = +dateS.substring(5, 7);
  const DD = +dateS.substring(8, 10);
  const hh = +dateS.substring(11, 13);
  const mm = +dateS.substring(14, 16);

  return new Date(Date.UTC(YYYY, MM - 1, DD, hh || null, mm || null));
};

export const getHoursAndMinutes = (time: number) => {
  return {
    hours: Math.floor(time / 60),
    mins: time % 60,
  };
};
