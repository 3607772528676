import React from 'react';
import * as Styled from './styled';
import MadlanLogoIcon from 'assets/svg/il-logo-small.svg';
import UserPlaceholder from 'assets/svg/user-placeholder/user.svg';
import AgentPlaceholder from 'assets/svg/user-placeholder/agent.svg';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { IProject, PocType, PromotionValues, ICommercialProject, IBulletin, ICommercialBulletin, IOfficeAgentDisplayLevel } from 'utils/entities';
import {
  getDeveloperLink,
  getPOCAvatar,
  getPOCAvatarLink,
  getPOCCompanyName,
  getPOCCompanyTitle,
  getPOCExclusive,
  getPOCLogo,
  getPOCLogoLink,
  getPOCName,
  getPOCTitle,
  getProjectLogoPath,
  getProjectName,
} from 'helpers/contact';
import { CheckedLinkText } from 'utils/checkLinksWrapper';
import { useLocale } from 'locale';
import { isProjectPoiType, isBulletinPoiType } from 'utils/marketplaceRoutes';
import { DecodedRouteParams, Route } from 'config/routes';
import { Link } from 'components/link';
import { LinkWrapper } from './styled';
import { State } from 'store/state';
import { connect } from 'react-redux';
import { PricingTierApi3 } from 'store/sagas/apiService/types';
import { displayLevelAndPricingTierSelector } from 'store/state/domainData/selectors/agentWithBulletinsByIds';


export interface AvatarBoxProps {
  imageURL: string;
  placeholder: JSX.Element;
  showPlaceholder: boolean;
  show: boolean;
  shape?: 'circle';
  isLarge?: boolean;
  isLeadingAgent?: boolean;
  link?: string;
  dataAuto: string;
  newPagesData?: {
    routeName: Route;
    routeParams: Partial<DecodedRouteParams>;
  } | null;
}

const AvatarBox: React.FC<AvatarBoxProps> = (props) => {
  const {
    dataAuto,
    show,
    imageURL,
    shape,
    isLarge,
    link,
    showPlaceholder,
    placeholder,
    newPagesData,
    isLeadingAgent,
  } = props;

  const { t } = useLocale();

  if (!show) return null;

  const wrapWithLink = Boolean(link);
  const withLabel = isLeadingAgent && dataAuto === 'poc-agent-logo';
  const profileImage = <Styled.AvatarBox data-auto={`${dataAuto}-image`} isLarge={isLarge} isLeadingAgent={isLeadingAgent} shape={shape} imageURL={imageURL} />;

  const linkComp = newPagesData ? (
    <Link {...newPagesData}>{profileImage}</Link>
  ) : (
    <a data-auto={`${dataAuto}-link`} href={link} target="_blank">{profileImage}</a>
  );

  const maybeWrap = wrapWithLink ? linkComp : profileImage;
  const content = showPlaceholder ? placeholder : maybeWrap;
  const contentWithLabel = withLabel ? (
    <Styled.ContentWithLabel>
      {content}
      <Styled.LeadingLabel small weight="bold" >{t('leading.agent.contact.form.label')}</Styled.LeadingLabel>
    </Styled.ContentWithLabel>
  ) : content;

  return content ? <div data-auto={dataAuto}>{contentWithLabel}</div> : null;
};

AvatarBox.defaultProps = {
  shape: null,
  isLarge: false,
  link: null,
};

interface POCLayoutProps {
  oneRowLayout: boolean;
  isLeadingAgent: boolean;
  poi: IProject | ICommercialProject | IBulletin | ICommercialBulletin;
  showOnlyOffice: boolean;
  agentData?: {
    displayLevel: IOfficeAgentDisplayLevel;
    pricingTier: PricingTierApi3;
  };
  isModal?: boolean;
}

const POCLayoutBase: React.FC<POCLayoutProps> = (props) => {
  const {
    poi,
    oneRowLayout,
    showOnlyOffice,
    agentData,
    isLeadingAgent,
  } = props;

  const hideAgentInfo = agentData && agentData.displayLevel === IOfficeAgentDisplayLevel.Minimal && agentData.pricingTier !== PricingTierApi3.Discounted;
  const nonClickableAgent = agentData && agentData.pricingTier === PricingTierApi3.Discounted;
  const { t } = useLocale();
  const constructImageURI = useImageUrlBuilder();

  const poc = isBulletinPoiType(poi) && poi.poc;
  const bulletinName = poc ? (showOnlyOffice || hideAgentInfo ? getPOCCompanyName(poc) : getPOCName(poc)) : null;
  const title = poc && (showOnlyOffice ? getPOCCompanyTitle(poc) : getPOCTitle(poc));
  const avatarUrl = poc && constructImageURI(getPOCAvatar(poc));
  const avatarHref = showOnlyOffice || hideAgentInfo ? getPOCLogoLink(poc) : (nonClickableAgent ? null : getPOCAvatarLink(poc));
  const isAgent = poc && poc.type === PocType.Agent;
  const isExclusive = poc && getPOCExclusive(poc);
  const bulletinOfficeLogoUrl = poc ? constructImageURI(getPOCLogo(poc)) : null;
  const bulletinOfficeHref = poc ? getPOCLogoLink(poc) : null;

  const isProject = isProjectPoiType(poi);
  const projectName = isProjectPoiType(poi) ? getProjectName(poi, t) : null;
  const projectOfficeLogoUrl = isProjectPoiType(poi) ? constructImageURI(getProjectLogoPath(poi)) : null;
  const isPromotedProject = isProjectPoiType(poi) && poi.promotionStatus && poi.promotionStatus.status === PromotionValues.Promoted;
  const projectOfficeHref = isProjectPoiType(poi) ? getDeveloperLink(poi) : null;

  const name = bulletinName || projectName;
  const officeLogoUrl = bulletinOfficeLogoUrl || projectOfficeLogoUrl;
  const officeHref = bulletinOfficeHref || projectOfficeHref;
  const showOfficeLogo = Boolean(officeLogoUrl || isProject);
  const withOfficeAvatar = officeLogoUrl && (isPromotedProject || isBulletinPoiType(poi));

  const NameWrapper = !showOnlyOffice && nonClickableAgent ? Styled.NonClickableWrapper : Link;

  if (poc && isAgent && !agentData && !showOnlyOffice) return null;

  return (
    <Styled.Root isOneRowLayout={oneRowLayout}>
      <Styled.AvatarBoxContainer isOneRowLayout={oneRowLayout} data-auto="poc-image-container">
        <AvatarBox
          dataAuto="poc-office-logo"
          isLeadingAgent={isLeadingAgent}
          show={showOfficeLogo}
          imageURL={officeLogoUrl}
          isLarge={isProject}
          link={officeHref}
          showPlaceholder={!withOfficeAvatar}
          placeholder={<MadlanLogoIcon />}
          newPagesData={isBulletinPoiType(poi) && poc && poc.type === PocType.Agent ? { routeName: Route.OfficePage, routeParams: { id: poc.officeId, source: 'listing_page' } } : null}
        />
        {isProject || showOnlyOffice || hideAgentInfo ? null : (
          <AvatarBox
            dataAuto="poc-agent-logo"
            show
            imageURL={avatarUrl}
            isLarge={isProject}
            link={avatarHref}
            shape="circle"
            showPlaceholder={!avatarUrl}
            isLeadingAgent={isLeadingAgent}
            placeholder={isAgent ? <AgentPlaceholder /> : <UserPlaceholder />}
            newPagesData={isBulletinPoiType(poi) && poc && poc.type === PocType.Agent ? { routeName: Route.AgentPage, routeParams: { id: poc.agentId, source: 'listing_page' } } : null}
          />
        )}
      </Styled.AvatarBoxContainer>
      <Styled.AgentInfo isOneRowLayout={oneRowLayout}>
        {isBulletinPoiType(poi) && poc && poc.type === PocType.Agent ? (
          <LinkWrapper>
            <NameWrapper routeName={showOnlyOffice || hideAgentInfo ? Route.OfficePage : Route.AgentPage} routeParams={{ id: showOnlyOffice || hideAgentInfo ? poc.officeId : poc.agentId, source: 'listing_page' }}>
              <Styled.FitText weight="bold"  data-auto={isProject ? 'poc-project-name' : 'poc-name'}>
                {name}
              </Styled.FitText>
            </NameWrapper>
          </LinkWrapper>
        ) : (
          <CheckedLinkText link={avatarHref}>
            <Styled.FitText weight="bold"  data-auto={isProject ? 'poc-project-name' : 'poc-name'}>
              {name}
            </Styled.FitText>
          </CheckedLinkText>
        )}
        {isAgent && !hideAgentInfo ? (
          <>
            <Styled.AgentTitle>
              <Styled.AgentText weight="bold">{title}</Styled.AgentText>
              {title ? <Styled.AgentTextDivide /> : null}
              <Styled.AgentText>{t('unitPage.contactAgentHebrewExclusive', { exclusive: isExclusive })}</Styled.AgentText>
            </Styled.AgentTitle>
          </>
        ) : null}
      </Styled.AgentInfo>
    </Styled.Root>
  );
};


const mapStateToProps = (state: State) => ({
  agentData: displayLevelAndPricingTierSelector(state),
});

export const POCLayout = connect(mapStateToProps)(POCLayoutBase);
