import { CompletionType } from 'utils/entities';

export enum CompletionListGroup {
  Address,
  Places,
  Default,
  Commute,
  OnlyLocalPages,
  CheckAddressTypes,
  HomepagePowersort,
  CommercialLanding,
  External,
  DefaultBuy,
  CommercialDefault,
  HomepageBuy,
  DevelopersSearch,
  AgentConsoleDeals,
}

type CompletionTypeRecord = Record<CompletionListGroup, CompletionType[]>;

export const config: CompletionTypeRecord = {
  [CompletionListGroup.Address]: [],
  [CompletionListGroup.Commute]: [ CompletionType.Address, CompletionType.Node ],
  [CompletionListGroup.Places]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.Project, CompletionType.SchoolDoc, CompletionType.CustomZone, CompletionType.Country ],
  [CompletionListGroup.DefaultBuy]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.Project, CompletionType.CommercialProject, CompletionType.SchoolDoc, CompletionType.CustomZone, CompletionType.Country ],
  [CompletionListGroup.Default]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.Project, CompletionType.CommercialProject, CompletionType.Developer, CompletionType.Agent, CompletionType.Office, CompletionType.CustomZone, CompletionType.Country ],
  [CompletionListGroup.HomepageBuy]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.Project, CompletionType.CommercialProject, CompletionType.SchoolDoc, CompletionType.Developer, CompletionType.Agent, CompletionType.Office, CompletionType.CustomZone, CompletionType.Country ],
  [CompletionListGroup.CommercialDefault]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.CommercialProject, CompletionType.CustomZone, CompletionType.Country, CompletionType.EmploymentArea ],
  [CompletionListGroup.HomepagePowersort]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City ],
  [CompletionListGroup.CommercialLanding]: [ CompletionType.Neighbourhood, CompletionType.Street, CompletionType.Address, CompletionType.City, CompletionType.CommercialProject, CompletionType.Developer, CompletionType.Agent, CompletionType.Office, CompletionType.CustomZone, CompletionType.Country, CompletionType.EmploymentArea ],
  [CompletionListGroup.OnlyLocalPages]: [ CompletionType.Neighbourhood, CompletionType.City ],
  [CompletionListGroup.CheckAddressTypes]: [ CompletionType.Address, CompletionType.Street, CompletionType.Neighbourhood, CompletionType.City, CompletionType.Project, CompletionType.Developer, CompletionType.Agent, CompletionType.Office, CompletionType.Country ],
  [CompletionListGroup.External]: [ CompletionType.Agent, CompletionType.Developer, CompletionType.Office ],
  [CompletionListGroup.DevelopersSearch]: [ CompletionType.Developer, CompletionType.City ],
  [CompletionListGroup.AgentConsoleDeals]: [ CompletionType.Address, CompletionType.Street, CompletionType.Neighbourhood ],
};
