import { makeNullSafeDomainGetter, dealByIdsSelector, createIsLoadingSelector } from 'store/state/domainData/selectors/common';
import { flow } from 'lodash';

const baseSoldPageDealSelector = makeNullSafeDomainGetter(dealByIdsSelector, 'poiByIds');

export const soldPageDealSelector = flow(baseSoldPageDealSelector, (poiByIds) => {
  if (poiByIds && poiByIds.length) {
    const [ deal ] = poiByIds;

    return deal;
  }

  return null;
});

export const isSoldPageDealLoadingSelector = createIsLoadingSelector(dealByIdsSelector);
