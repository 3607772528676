import React, { useEffect } from 'react';
import { Tabu } from 'utils/entities';
import { TabuWizardStep } from '../TabuWizard';
import { useLocale } from 'locale';
import * as Styled from './styled';
import config from 'config';
import withEvents from 'analytics/withEvents';
import { TabuSource } from 'components/tabu/Tabu';
import { isServer } from 'utils';
import { useScreenBreakpoint } from 'consts/breakpoints';


function createTabuRedirectsUrl(result: PayloadResult): string {
  if (!isServer()) {
    let base = `https://${window.location.host}`;
    if (config.mode === 'development') {
      base = 'http://localhost:4002';
    }
    return `${base}/tabu/${result}`;
  }
  // tabu will never be used on SSR
  return '';
}

function createTermsUrl(): string {
  try {
    const parsedTermLink = new URL(config.termsOfServiceUrl);
    return `${parsedTermLink.origin}${parsedTermLink.pathname}?scrollTo=tabu`;
  }
  catch (e) {
    return '/etc/terms?scrollTo=tabu';
  }
}

const BASE_TABU_URL = 'https://direct.tranzila.com/rosetaltb/iframenew.php?';
export const BASE_PARAMS = {
  userType: 'tenant',
  sum: config.tabu.tabuPurchaseValue || 29,
  currency: 1,
  cred_type: 1,
  nologo: 1,
  lang: 'il',
  buttonLabel: 'אישור ושליחה',
  trButtonColor: '018489',
  token: 'AbCdEf',
  success_url_address: createTabuRedirectsUrl('success'),
  fail_url_address: createTabuRedirectsUrl('fail'),
  notify_url_address: config.tabu.notifyUrl,
  processId: config.tabu.processId,
};

function buildTabuIframeURL(downloadToken: string, docId: string, email: string, contact: string): string {
  const remarks = JSON.stringify({ downloadToken, 'doc-id': docId });
  const additionalParams = { remarks, email, contact };
  const params = { ...BASE_PARAMS, ...additionalParams };

  const encodedParams = Object.entries(params)
    .map((pair) => pair.map(encodeURIComponent)
    .join('='))
    .join('&');

  return `${BASE_TABU_URL}${encodedParams}`;
}

type PayloadResult = 'success' | 'fail';

function useIframeSubscription(cb: (v: any) => void) {
  const isSSR = isServer();
  useEffect(() => {
    if (!isSSR) {
      window.addEventListener('message', cb);
    }
    return () => {
      if (!isSSR) {
        window.removeEventListener('message', cb);
      }
    };
  }, []);
}

interface TabuPaymentProps {
  tabu: Tabu;
  name: string;
  email: string;
  docId: string;
  source: TabuSource;
  setStep: React.Dispatch<React.SetStateAction<TabuWizardStep>>;
}

const TabuPayment: React.FC<TabuPaymentProps> = (props) => {
  const {
    tabu,
    name,
    email,
    docId,
    setStep,
  } = props;

  const { downloadToken } = tabu;

  const iframeSrc = buildTabuIframeURL(downloadToken, docId, email, name);
  const breakPoint = useScreenBreakpoint();
  const { t } = useLocale();

  const isMobile = breakPoint <= 2;

  useIframeSubscription(({ data }) => {
    switch (data) {
      case 'success':
        setStep(TabuWizardStep.Success);
        break;
      case 'fail':
        setStep(TabuWizardStep.Fail);
        break;
      default:
        break;
    }
  });

  const handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    setStep(TabuWizardStep.Login);
  };

  // some configs can contain get params in url
  const termUrl = createTermsUrl();

  return (
    <Styled.Root>
      {isMobile ? null : <Styled.Header weight="bold">{t('tabu.wizard.payment.header')}</Styled.Header>}

      <Styled.SimpleText>{t('tabu.wizard.payment.info')}</Styled.SimpleText>
      <Styled.SimpleText weight="bold">{t('tabu.wizard.payment.cost', { price: BASE_PARAMS.sum })}</Styled.SimpleText>
      <Styled.IframeWrapper>
        <Styled.IframeContainer sideExpand={isMobile} src={iframeSrc} />
      </Styled.IframeWrapper>
      <Styled.Footer>
        <div>
          {t('tabu.wizard.payment.footer.info')}
          <Styled.FooterLink href={termUrl} target="_blank">
            {t('tabu.wizard.payment.footer.term')}
          </Styled.FooterLink>
        </div>
        <div>{t('tabu.wizard.payment.footer.text')}</div>
        <span>{email}</span>
        <Styled.FooterLink href="#" onClick={handleClick}>
          {t('tabu.wizard.payment.footer.link')}
        </Styled.FooterLink>
      </Styled.Footer>
    </Styled.Root>
  );
};

export default withEvents<TabuPaymentProps>((sendEvent, { source }) => ({
  setStep: (s: TabuWizardStep) => {
    if (s === TabuWizardStep.Success) {
      sendEvent('tabu_payment_submit', 'tabu', {
        event: {
          source,
        },
      });
    }
  },
}))(TabuPayment);
