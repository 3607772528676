import { RootAction, State } from 'store/state';
import { searchHistorySelector } from 'store/state/domainData/selectors';
import { dealTypeSelector } from 'store/state/selectors/search';
import { NavigationSearch, NavigationSearchProps } from './navigationSearch';
import { connect } from 'react-redux';
import { routeSelector } from 'store/state/selectors/router';
import { Dispatch } from 'redux';
import { DecodedRouteParams, Route } from 'config/routes';
import { navigateTo as navigateToAction } from 'store/state/router/actions';
import { buildSearchPayload } from 'components/search-widget/helpers';
import withEvents from 'analytics/withEvents';
import { NavigationOptions } from 'store/state/router/actionPayloads';

const mapStateToProps = (state: State) => ({
  searchHistory: searchHistorySelector(state),
  dealType: dealTypeSelector(state),
  route: routeSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  navigate: (name: Route, params?: DecodedRouteParams, opts?: NavigationOptions) =>
    dispatch(navigateToAction(name, params, opts)),
});

const EventfulNavigationSearch = withEvents<NavigationSearchProps>((sendEvent) => ({
  onSearch(meta) {
    sendEvent('search_submit', 'search', {
      search: buildSearchPayload(meta),
      event: {
        source: 'check_address',
      },
    });
  },
}))(NavigationSearch);

export default connect(mapStateToProps, mapDispatchToProps)(EventfulNavigationSearch);
