export const rtSymbolToColor = {
  A: 'hsl(200, 100%, 30%)',
  C: 'hsl(200, 100%, 30%)',
  E: 'hsl(200, 100%, 30%)',
  1: 'hsl(0, 100%, 60%)',
  2: 'hsl(0, 100%, 60%)',
  3: 'hsl(0, 100%, 60%)',
  4: 'hsl(140, 100%, 30%)',
  5: 'hsl(140, 100%, 30%)',
  6: 'hsl(140, 100%, 30%)',
  7: 'hsl(305, 56%, 45%)',
  B: 'hsl(30, 100%, 60%)',
  D: 'hsl(30, 100%, 60%)',
  F: 'hsl(30, 100%, 60%)',
  M: 'hsl(30, 100%, 60%)',
  N: 'hsl(60, 100%, 45%)',
  Q: 'hsl(60, 100%, 45%)',
  R: 'hsl(60, 100%, 45%)',
  G: 'hsl(75, 100%, 40%)',
  J: 'hsl(40, 100%, 30%)',
  Z: 'hsl(40, 100%, 30%)',
  L: 'hsl(0, 0%, 60%)',
  default: '#b2b2b2',
};

export const stationColorToColor = {
  blue: 'hsl(204, 99%, 36%)',
  lightGreen: 'hsl(97, 48%, 50%)',
  green: 'hsl(144, 100%, 29%)',
  orange: 'hsl(37, 94%, 54%)',
  purple: 'hsl(306, 61%, 37%)',
  red: 'hsl(357, 85%, 52%)',
  lightPurple: 'hsl(220, 50%, 80%)',
  default: '#0098ff',
};

export const serviceToColor = {
  ['METRO-NORTH']: 'hsl(204, 99%, 36%)',
  LIRR: 'hsl(97, 48%, 50%)',
  PATH: 'hsl(306, 61%, 37%)',
  default: '#0098ff',
};

export enum TRANSPORTATION_HUBS_TYPE {
  MIDTOWN = 'midtown',
  FINANCIAL_DISTRICT = 'financial district',
  DOWNTOWN_BROOKLYN = 'downtown brooklyn',
  LONG_ISLAND_CITY = 'long island city',
  PERSONAL = 'personal',
}
