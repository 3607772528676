import { styled } from 'ds';
import { H3, Text, TextLabel } from 'ds/components/typography';

type AmenityColumn = 2 | 3;

const amenityPercentColumnMap: Record<AmenityColumn, string> = {
  2: '50%',
  3: '33.3%',
};

export const AmenityWrapper = styled.div<{ isActive?: boolean; columnLayoutCount: AmenityColumn}>`
  display: flex;
  align-items: center;
  ${({ theme, isActive, columnLayoutCount }) => `
    width: ${amenityPercentColumnMap[columnLayoutCount]};
    color: ${isActive ? theme.colors.neutrals.grey1 : theme.colors.neutrals.grey4};
    text-decoration: ${isActive ? 'none' : 'line-through'};
    margin-bottom: ${theme.spacing(1.5)};
    & svg {
      width: 16px;
      margin-left: ${theme.spacing(1)};
      path {
        fill: ${isActive ? theme.colors.neutrals.grey1 : theme.colors.neutrals.grey4};
      }
    }
  `}
`;

export const AmenityText = styled(Text)``;

export const ListTitle = styled(H3)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const AmenitySectionWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(5)};
  `}
`;

export const AmenitySectionTitle = styled(TextLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    padding-bottom: ${theme.spacing(2)}
  `};
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  && > * {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const WhatElse = styled.div<{ columnLayoutCount: AmenityColumn }>`
  width: ${({ columnLayoutCount }) => amenityPercentColumnMap[columnLayoutCount]};
  display: flex;
  align-items: center;
  & svg {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
  padding: 0 ${({ theme }) => theme.spacing(2)};
  &:last-of-type {
    padding-left: 0;
  }
  &:first-of-type {
    padding-right: 0;
  }
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1.5)};
  `}
`;

export const MoreText = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;
