import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AuthenticationField, SocialVendor } from 'components/authentication/types';
import { setAuthEmail, signInSignUpWithEmail, userLoginSocial } from 'store/state/app/actions';
import { RootAction, State } from 'store/state';
import { useLocale } from 'locale';
import { Heading, Wrapper } from './styled';
import AuthSocialsButtons from 'components/authentication/social-buttons';
import { AuthenticationEmailForm } from 'components/authentication/AuthenticationEmailForm';
import { isEmailValid as isEmailValidHelper } from 'utils/formValidationHelpers';
import { ISignInSignUpWithEmailVariables } from 'store/sagas/apiService/types';
import { authEmailSelector } from 'store/state/app/selectors';

interface AuthenticationStepProps {
  onSocialLogin: (vendor: SocialVendor) => void;
  onSignInSignUpWithEmail: (data: ISignInSignUpWithEmailVariables) => void;
  email: string;
  setEmail: (email: string) => void;
}

const AuthenticationStep: React.FC<AuthenticationStepProps> = ({
  onSignInSignUpWithEmail,
  onSocialLogin,
  setEmail,
  email,
}) => {
  const { t } = useLocale();
  const [ dirty, setDirty ] = useState(new Set<AuthenticationField>([]));
  const isEmailValid = email && isEmailValidHelper(email);

  const handleSetDirty = (field: AuthenticationField) => setDirty(prev => new Set([ ...prev, field ]));
  const handleEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value);

  const handleSignInSignUpSubmit = () => {
    if (email && isEmailValid) {
      onSignInSignUpWithEmail({ email: email.toLowerCase() });
    }
    else {
      handleSetDirty('email');
    }
  };

  return (
    <Wrapper>
      <Heading weight="bold">{t('uploadBulletinPage.authStep.heading')}</Heading>
      <AuthenticationEmailForm
        onSetDirty={handleSetDirty}
        email={email}
        submitText={t('uploadBulletinPage.authStep.submit')}
        isEmailValid={isEmailValid}
        dirtyFields={dirty}
        onEmailChange={handleEmailChange}
        onSubmit={handleSignInSignUpSubmit}
      />
      <AuthSocialsButtons onSocialLogin={onSocialLogin} withSuggestionBefore />
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => ({
  email: authEmailSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSocialLogin: (vendor: SocialVendor) => dispatch(userLoginSocial(vendor)),
  onSignInSignUpWithEmail: (data: ISignInSignUpWithEmailVariables) => dispatch(signInSignUpWithEmail(data)),
  setEmail: (email: string) => dispatch(setAuthEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationStep);
