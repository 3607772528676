import { IUploadBulletinFormValues } from 'utils/uploadBulletin';

export enum UploadBulletinStep {
  AdditionalInfo = 'additionalInfo',
  Address = 'address',
  Authentication = 'auth',
  BulletinType = 'bulletinType',
  Contacts = 'contacts',
  DealType = 'dealType',
  MainInfo = 'mainInfo',
  Preview = 'preview',
  SelectAgent = 'selectAgent',
  UploadImage = 'uploadImage',
  AgentResidential = 'agentResidential',
}

export interface DraftState {
  formValues: Partial<IUploadBulletinFormValues>;
}
