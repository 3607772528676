import React from 'react';
import { AnswerWrapper, AnswerTextWrapper } from './styled';

interface RatingAnswerButtonProps {
  text: string;
  icon: JSX.Element;
  isActive?: boolean;
  select: () => void;
}

export const RatingAnswerButton: React.FC<RatingAnswerButtonProps> = React.memo(props => {
  const { icon, text, isActive, select } = props;

  return (
    <AnswerWrapper
      className={isActive ? 'active' : ''}
      onClick={select}
      data-auto={`ugc-answer-${props.text}`}
    >
      {icon}
      <AnswerTextWrapper isActive={isActive} caps weight="bold" small>{text}</AnswerTextWrapper>
    </AnswerWrapper>
  );
});
