import styled from '@emotion/styled';
import { SmallText, Text, TextLabel } from 'ds/components/typography';
import { AvatarBoxProps } from '.';
import { PROFILE_ITEM_SIZE_DEFAULT, PROFILE_ITEM_SIZE_LARGE } from 'components/inner-contact-form/const';
import { Link } from 'ds/components/link';
import { colorToAlpha } from 'helpers/insight';


export const Root = styled.div<{ isOneRowLayout: boolean }>`
  ${({ isOneRowLayout, theme }) => `
    display: flex;
    padding-bottom: ${theme.spacing(1.5)};
    ${isOneRowLayout ? `
      align-items: center;
    ` : `
      flex-direction: column;
    `}
  `}
`;

export const ContentWithLabel = styled.div`
  position: relative;
  > svg {
    width: 80px;
    height: 80px;
  }
`;

export const AvatarBoxContainer = styled.div<{ isOneRowLayout: boolean; }>`
  ${({ theme, isOneRowLayout }) => `
    display: flex;
    > div {
      ${isOneRowLayout ? `
        margin-left: ${theme.spacing(1.5)};
      ` : `
        margin-left: ${theme.spacing(1.5)};
        margin-bottom: ${theme.spacing(1.5)};
      `}
    }
    svg {
      display: block;
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    justify-content: center;
    > div:last-of-type {
      margin-left: 0;
    }
  `}
`;

export const AgentInfo = styled.div<{ isOneRowLayout: boolean }>`
  ${({ theme, isOneRowLayout }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${isOneRowLayout ? `
      margin-right: ${theme.spacing(.5)};
    ` : `
      margin-bottom: ${theme.spacing(3)};
    `};
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: 0;
    && * {
      text-align: center;
      display: inline-block;
    }
  `}
`;

export const FitText = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const AgentTitle = styled.div``;

export const AgentText = styled(SmallText.withComponent('span'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const AgentTextDivide = styled(AgentText)`
  ${({ theme }) => `
    margin: 0 ${theme.spacing(.5)};
    ::after {
      display: inline;
      content: '•';
    }
  `}
`;

export const LeadingLabel = styled(TextLabel)<{ disablePosition?: boolean }>(({ theme, disablePosition }) => ({
  color: theme.colors.brand.lighter,
  backgroundColor: theme.colors.brand.lightest,
  whiteSpace: 'nowrap',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.borderRadius.tiny,
  border: `1px solid ${colorToAlpha(theme.colors.brand.lighter, 0.2)}`,
  ...(disablePosition ? { } : {
    bottom: '-16px',
    position: 'absolute',
    zIndex: 12,
    left: '50%',
    transform: 'translateX(calc(-50% - 4px))',
  }),
}));


function getSize(isLarge: boolean, isLeadingAgent: boolean) {
  if (isLeadingAgent) return '80px';
  return isLarge ? PROFILE_ITEM_SIZE_LARGE : PROFILE_ITEM_SIZE_DEFAULT;
}

export const AvatarBox = styled.div<Pick<AvatarBoxProps, 'imageURL' | 'shape' | 'isLarge' | 'isMadadBadge' | 'isOfficeLogo' | 'isLeadingAgent'>>`
  ${({ theme, imageURL, shape, isLarge, isMadadBadge, isOfficeLogo, isLeadingAgent }) => `
    width: ${isMadadBadge ? '36px' : getSize(isLarge, isLeadingAgent)};
    height: ${getSize(isLarge, isLeadingAgent)};
    margin-right: ${isLeadingAgent ? theme.spacing(1) : 0};
    overflow: hidden;
    ${shape === 'circle' ? `
      border-radius: ${theme.borderRadius.round};
      border: 1px solid ${theme.colors.neutrals.grey6};
    ` : ''}
    background-repeat: no-repeat;
    background-size: ${isMadadBadge || isOfficeLogo ? 'contain' : 'cover'};
    background-position: center center;
    background-color: ${isMadadBadge ? 'none' : (isOfficeLogo ? theme.colors.neutrals.white : theme.colors.neutrals.grey6)};
    ${imageURL ? `background-image: url("${imageURL}");` : ''}
    border: ${isOfficeLogo ? `1px solid ${theme.colors.neutrals.grey8}` : 'none'};
  `}
`;

export const SingleDeveloperLink = styled(Link)`
  color: ${({ theme }) => theme.colors.brand.main};
  font-weight: 500;
  font-size: 13px;
`;

export const LinkWrapper = styled.div`
  & > a,
  & > div {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 24px;
    ${({ theme }) => `
      color: ${theme.colors.neutrals.grey1};
      > svg {
        fill: ${theme.colors.neutrals.grey1};
      }
      &:hover {
        color: ${theme.colors.neutrals.grey1};
        > svg {
          fill: ${theme.colors.neutrals.grey1};
        }
      }
      &:focus {
        color: ${theme.colors.neutrals.grey1}
      }
    `};
  }
`;

export const NonClickableWrapper = styled.div<any>``;
