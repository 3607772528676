import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import { BasicButton } from 'ds/components/button/BasicButton';
import { CleanButton } from 'ds/components/button';


export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CloseWrapper = styled.div`
  ${({ theme }) => `
    position: absolute;
    right: -${theme.spacing(1)};
    top: -${theme.spacing(1)};
    cursor: pointer;
  `}
`;

export const WarningWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
  `}
`;

export const CardOverlaySubtitle = styled(SmallText)`
  ${({ theme }) => `
    margin: ${theme.spacing(1.5)} 0 ${theme.spacing(4)};
    color: ${theme.colors.neutrals.grey3};
  `}
`;

export const DeleteButton = styled(BasicButton)`
  ${({ theme }) => `
    &, &:hover {
      background-color: ${theme.colors.alert.red};
      color: ${theme.colors.neutrals.white};
      border: none;
    }
  `}
`;

export const CancelButtonWrapper = styled(SmallText)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1.5)};
    color: ${theme.colors.neutrals.grey3};
    cursor: pointer;
  `}
`;

export const CancelButton = styled(CleanButton)`
  &:hover {
    background: none;
  }
`;
