import { pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { IBulletin, ICommercialBulletin, IProject, ICommercialProject } from 'utils/entities';
import {
  searchListPoisDictionarySelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  ilHomePageBulletinsByIdsSelector,
  projectsByIdsSelector,
  streetSearchPoiDictionarySelector,
  addressSearchPoiDictionarySelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
  userListingsByIdsSelector,
} from 'store/state/domainData/selectors';
import { favoritesAndShortlistDictSelector } from 'store/state/domainData/selectors/favorites';
import { listingsPostLeadCarouselDictSelector } from 'store/state/domainData/selectors/listingsPostLeadCarousel';

type ActualPoiEntry = IBulletin | IProject | ICommercialBulletin | ICommercialProject;
export type PoiHeapDict = Record<string, ActualPoiEntry>;

const searchListPoiWithoutAdsSelector = createSelector(
  [ searchListPoisDictionarySelector ],
  (pois): PoiHeapDict => (
    pickBy(pois, (poi): poi is ActualPoiEntry => poi.type !== 'ad')
  )
);

export const allPoisByIdsHeapSelector = createSelector([
  favoritesAndShortlistDictSelector,
  searchListPoiWithoutAdsSelector,
  addressSearchPoiDictionarySelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  ilHomePageBulletinsByIdsSelector,
  projectsByIdsSelector,
  streetSearchPoiDictionarySelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
  userListingsByIdsSelector,
  listingsPostLeadCarouselDictSelector,
], (
  shortList,
  searchList,
  addressSearch,
  unitPageBulletin,
  projectPageProject,
  homePage,
  projectsList,
  streetPois,
  unitPageCommercialBulletin,
  projectPageCommercialProject,
  userListings,
  listingsCarousel
): PoiHeapDict => {
  const resultHeap: PoiHeapDict = {
    ...shortList,
    ...searchList,
    ...addressSearch,
    ...homePage,
    ...projectsList,
    ...streetPois,
    ...userListings,
    ...listingsCarousel,
  };
  if (unitPageBulletin) resultHeap[unitPageBulletin.id] = unitPageBulletin;
  if (unitPageCommercialBulletin) resultHeap[unitPageCommercialBulletin.id] = unitPageCommercialBulletin;
  if (projectPageProject) resultHeap[projectPageProject.id] = projectPageProject;
  if (projectPageCommercialProject) resultHeap[projectPageCommercialProject.id] = projectPageCommercialProject;

  return resultHeap;
});

export const contactPoisByIdsHeapSelector = createSelector([
  favoritesAndShortlistDictSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  projectPageCommercialProjectSelector,
  unitPageCommercialBulletinSelector,
  projectsByIdsSelector,
  listingsPostLeadCarouselDictSelector,
], (shortList, unitPageBulletin, projectPageProject, projectPageCommercialProject, unitPageCommercialBulletin, projectsList, listingsCarousel): PoiHeapDict => {
  const resultHeap: PoiHeapDict = {
    ...projectsList,
    ...shortList,
    ...listingsCarousel,
  };
  if (unitPageBulletin) resultHeap[unitPageBulletin.id] = unitPageBulletin;
  if (unitPageCommercialBulletin) resultHeap[unitPageCommercialBulletin.id] = unitPageCommercialBulletin;
  if (projectPageProject) resultHeap[projectPageProject.id] = projectPageProject;
  if (projectPageCommercialProject) resultHeap[projectPageCommercialProject.id] = projectPageCommercialProject;

  return resultHeap;
});
