import Project16AirCondition from 'assets/svg/filters/project-16-air-condition.svg';
import Project16Pending from 'assets/svg/filters/project-16-pending.svg';
import Project16Sold from 'assets/svg/filters/project-16-sold.svg';
import Project16List from 'assets/svg/filters/project-16-list.svg';
import Project16Doorman from 'assets/svg/filters/project-16-doorman.svg';
import Project16ElevatorIl from 'assets/svg/filters/project-16-elevator-il.svg';
import AmenitiesIlIcon from 'assets/svg/filters/project-16-elevator-il.svg';
import Project16SafetyUnit from 'assets/svg/filters/project-16-saftey-unit.svg';
import Project16Wheelchair from 'assets/svg/filters/project-16-wheelchair.svg';
import Project16Balcony from 'assets/svg/filters/project-16-balcony.svg';
import Project16Bars from 'assets/svg/filters/project-16-bars.svg';
import Project16ParkingIl from 'assets/svg/filters/project-16-parking-il.svg';
import Project16Warehouse from 'assets/svg/filters/project-16-warehouse.svg';
import Project16Floorplan from 'assets/svg/filters/project-16-floorplan.svg';
import Project16AB from 'assets/svg/filters/project-16-ab.svg';

import FeeComponent from './sections/Fee';
import AmenitiesComponent from './sections/amenities/Amenities';
import PriceRangeComponent from './sections/price/price-range';
import QualityClassComponent from './sections/QualityClass';
import MonthlyTaxRangeComponent from './sections/MonthlyTaxRange';
import RoomsComponent from './sections/BedsRange';
import BathsRangeComponent from './sections/BathsRange';
import PropertyTypesComponent from './sections/PropertyTypes';
import ConditionsComponent from './sections/Conditions';
import EmployeesRangeComponent from './sections/Employees';
import SellerComponent from './sections/Seller';
import FloorRangeComponent from './sections/FloorRange';
import AreaComponent from './sections/Area';
import PricePerSquareMeterComponent from './sections/price/price-per-square-meter';
import PriceChanges from './sections/PriceChanges';
import ProjectDiscountSection from './sections/ProjectDiscount';

import Additional from './sections/Additional';
import Price from './sections/price';
import Size from './sections/Size';
import RoomsRangeIcon from 'assets/svg/filters/bed.svg';
import ConditionsIcon from 'assets/svg/filters/brush.svg';
import PriceRangeIcon from 'assets/svg/filters/project-16-price-us.svg';
import PropertyTypeIcon from 'assets/svg/filters/building-type.svg';
import SellerIcon from 'assets/svg/filters/project-16-doorman.svg';
import AdditionalIcon from 'assets/svg/filters.svg';

import React from 'react';
import {
  Amenity,
  Condition,
  ConfigByMarketplaceType,
  FiltersListByRange,
  GroupFilterMap,
  GroupType,
  PropertyType,
  QualityClassOption,
  RoomOption,
  Seller,
  SingleFilterMap,
} from './types';
import DealTypeSection from './sections/deal-type/DealType';
import { DealType, MarketplaceType } from 'utils/entities';
import { FilterDivide, Section } from './styled/common';



const DividerItem = (
  <Section>
    <FilterDivide />
  </Section>
);

const commonSingleComponents = {
  fee: FeeComponent,
  amenities: AmenitiesComponent,
  priceRange: PriceRangeComponent,
  monthlyTaxRange: MonthlyTaxRangeComponent,
  roomsRange: RoomsComponent,
  conditions: ConditionsComponent,
  propertyTypes: PropertyTypesComponent,
  bathsRange: BathsRangeComponent,
  seller: SellerComponent,
  floorRange: FloorRangeComponent,
  areaRange: AreaComponent,
  ppmRange: PricePerSquareMeterComponent,
  priceChanges: PriceChanges,
  dealType: DealTypeSection,
  numberOfEmployeesRange: EmployeesRangeComponent,
  qualityClass: QualityClassComponent,
  projectDiscount: ProjectDiscountSection,
};

export const FiltersSingleComponentsMap: SingleFilterMap = {
  commercial: {
    ...commonSingleComponents,
  },
  residential: {
    ...commonSingleComponents,
  },
};

export const FiltersGroupComponentsMap: GroupFilterMap = {
  commercial: {
    [GroupType.Additional]: Additional,
    [GroupType.Price]: Price,
    [GroupType.Size]: Size,
  },
  residential: {
    [GroupType.Additional]: Additional,
    [GroupType.Price]: Price,
    [GroupType.Size]: null,
  },
};

const commonFloorsOptions = [ -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20 ];
const commonConfigOptions = (marketplace: MarketplaceType) => ({
  floorsOptions: [ ...commonFloorsOptions, ...(marketplace === MarketplaceType.Commercial ? [ 25, 30 ] : []) ],
  uploadFloorsOptions: [ -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
    55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
    84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 ],
  areaOptions: [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 250, 300, -1 ],
  employeesOptions: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, -1 ],
  dealTypeOptions: [ DealType.Buy, DealType.Rent ],
  seller: [
    Seller.Private,
    Seller.Agent,
    Seller.Developer,
  ],
});

const residentialFilters: FiltersListByRange = {
  1: [
    { type: 'single', item: { filterType: 'priceRange', isInline: true } },
    { type: 'single', item: { filterType: 'ppmRange', isInline: true } },
    { type: 'single', item: { filterType: 'priceChanges', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'roomsRange', isInline: true } },
    { type: 'single', item: { filterType: 'areaRange', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'conditions', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'seller', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'amenities', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'floorRange', isInline: true } },
  ],
  3: [
    { type: 'group', groupType: GroupType.Price, items: [
      { filterType: 'priceRange', isInline: true },
      { filterType: 'ppmRange', isInline: true },
    ]},
    { type: 'single', item: { filterType: 'roomsRange', isInline: false } },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: false } },
    { type: 'group', groupType: GroupType.Additional, items: [
      { filterType: 'priceChanges', isInline: true },
      { filterType: 'amenities', isInline: true },
      { filterType: 'seller', isInline: true },
      { filterType: 'conditions', isInline: true },
      { filterType: 'areaRange', isInline: true },
      { filterType: 'floorRange', isInline: true },
    ]},
  ],
  4: [
    { type: 'group', groupType: GroupType.Price, items: [
      { filterType: 'priceRange', isInline: true },
      { filterType: 'ppmRange', isInline: true },
    ]},
    { type: 'single', item: { filterType: 'roomsRange', isInline: false } },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: false } },
    { type: 'single', item: { filterType: 'seller', isInline: false } },
    { type: 'single', item: { filterType: 'amenities', isInline: false } },
    { type: 'group', groupType: GroupType.Additional, items: [
      { filterType: 'priceChanges', isInline: true },
      { filterType: 'conditions', isInline: true },
      { filterType: 'areaRange', isInline: true },
      { filterType: 'floorRange', isInline: true },
    ]},
    { type: 'single', item: { filterType: 'projectDiscount', isInline: false } },
  ],
};

const commercialFilters: FiltersListByRange = {
  1: [
    { type: 'single', item: { filterType: 'dealType', isInline: true } },
    { type: 'single', item: { filterType: 'priceRange', isInline: true } },
    { type: 'single', item: { filterType: 'ppmRange', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'areaRange', isInline: true } },
    { type: 'single', item: { filterType: 'roomsRange', isInline: true } },
    { type: 'single', item: { filterType: 'numberOfEmployeesRange', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'amenities', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'conditions', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'floorRange', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'qualityClass', isInline: true } },
    { type: 'divider', item: DividerItem },
    { type: 'single', item: { filterType: 'seller', isInline: true } },
  ],
  3: [
    { type: 'single', item: { filterType: 'dealType', isInline: false } },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: false } },
    { type: 'group', groupType: GroupType.Price, items: [
      { filterType: 'priceRange', isInline: true },
      { filterType: 'ppmRange', isInline: true },
    ]},
    { type: 'group', groupType: GroupType.Additional, items: [
      { filterType: 'conditions', isInline: true },
      { filterType: 'floorRange', isInline: true },
      { filterType: 'amenities', isInline: true },
      { filterType: 'areaRange', isInline: true },
      { filterType: 'roomsRange', isInline: true },
      { filterType: 'numberOfEmployeesRange', isInline: true },
      { filterType: 'qualityClass', isInline: true },
      { filterType: 'seller', isInline: true },
    ]},
  ],
  4: [
    { type: 'single', item: { filterType: 'dealType', isInline: false } },
    { type: 'single', item: { filterType: 'propertyTypes', isInline: false } },
    { type: 'single', item: { filterType: 'seller', isInline: false } },
    { type: 'group', groupType: GroupType.Price, items: [
      { filterType: 'priceRange', isInline: true },
      { filterType: 'ppmRange', isInline: true },
    ]},
    { type: 'group', groupType: GroupType.Size, items: [
      { filterType: 'areaRange', isInline: true },
      { filterType: 'roomsRange', isInline: true },
      { filterType: 'numberOfEmployeesRange', isInline: true },
    ]},
    { type: 'single', item: { filterType: 'qualityClass', isInline: false } },
    { type: 'group', groupType: GroupType.Additional, items: [
      { filterType: 'conditions', isInline: true },
      { filterType: 'floorRange', isInline: true },
      { filterType: 'amenities', isInline: true },
    ]},
  ],
};

export const config: ConfigByMarketplaceType = {
  residential: {
    ...commonConfigOptions(MarketplaceType.Residential),
    qualityClassOptions: [],
    amenities: {
      1: {
        title: 'filters.commercial.amenities.main',
        sections: [
          {
            name: 'common',
            type: 'checkboxGroup',
            items: [
              {
                type: 'checkbox',
                amenity: Amenity.Parking,
                icon: <Project16ParkingIl />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Elevator,
                icon: <Project16ElevatorIl />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Balcony,
                icon: <Project16Balcony />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.SecureRoom,
                icon: <Project16SafetyUnit />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.AirConditioner,
                icon: <Project16AirCondition />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Storage,
                icon: <Project16Warehouse />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Accessible,
                icon: <Project16Wheelchair />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Grating,
                icon: <Project16Bars />,
              },
            ],
          },
        ],
      },
      4: {
        title: null,
        sections: [
          {
            name: 'common',
            type: 'checkboxGroup',
            items: [
              {
                type: 'checkbox',
                amenity: Amenity.Parking,
                icon: <Project16ParkingIl />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Elevator,
                icon: <Project16ElevatorIl />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Balcony,
                icon: <Project16Balcony />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.SecureRoom,
                icon: <Project16SafetyUnit />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.AirConditioner,
                icon: <Project16AirCondition />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Storage,
                icon: <Project16Warehouse />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Accessible,
                icon: <Project16Wheelchair />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Grating,
                icon: <Project16Bars />,
              },
            ],
          },
        ],
      },
    },
    budgetByDealType: {
      [DealType.Rent]: {
        initialBudgetValue: [ 0, 15000 ],
        budgetSliderProps: {
          min: 0,
          max: 15000,
          step: 250,
          minDelta: 250,
        },
      },
      [DealType.Buy]: {
        initialBudgetValue: [ 0, 10000000 ],
        budgetSliderProps: {
          min: 0,
          max: 10000000,
          step: 100000,
          minDelta: 100000,
        },
      },
    },
    budgetByPerSquareMeter: {
      [DealType.Rent]: {
        initialBudgetValue: [ 0, 10000 ],
        budgetSliderProps: {
          min: 0,
          max: 10000,
          step: 250,
          minDelta: 250,
        },
      },
      [DealType.Buy]: {
        initialBudgetValue: [ 0, 100000 ],
        budgetSliderProps: {
          min: 0,
          max: 100000,
          step: 1000,
          minDelta: 1000,
        },
      },
    },
    propertyTypes: [
      PropertyType.Flat,
      PropertyType.PrivateHouse,
      PropertyType.GardenApartment,
      PropertyType.Cottage,
      PropertyType.TwoFamilyDwelling,
      PropertyType.Penthouse,
      PropertyType.Rooftop,
      PropertyType.FarmLand,
      PropertyType.Duplex,
      PropertyType.Land,
      PropertyType.Building,
    ],
    roomsOptions: [
      RoomOption.One,
      RoomOption.Two,
      RoomOption.Three,
      RoomOption.Four,
      RoomOption.Five,
      RoomOption.Six,
    ],
    bathsOptions: [ 1, 1.5, 2, 2.5, 3 ],
    conditions: [
      Condition.New,
      Condition.AsNew,
      Condition.Renovated,
      Condition.Preserved,
      Condition.ToRenovated,
    ],
    filtersIcons: {
      price: <PriceRangeIcon />,
      monthlyTaxRange: <PriceRangeIcon />,
      roomsRange: <RoomsRangeIcon />,
      conditions: <ConditionsIcon />,
      additional: <AdditionalIcon />,
      amenities: <AmenitiesIlIcon />,
      seller: <SellerIcon />,
      propertyTypes: <PropertyTypeIcon />,
      floorRange: null,
      bathsRange: null,
      areaRange: null,
      dealType: null,
      fee: null,
      projectDiscount: null,
      ppmRange: null,
      priceRange: null,
      priceChanges: null,
      numberOfEmployeesRange: null,
      qualityClass: null,
    },
    filtersByDealType: {
      [DealType.Buy]: residentialFilters,
      [DealType.Rent]: residentialFilters,
    },
    priceChanges: {
      underPriceEstimation: false,
      priceDrop: false,
    },
  },
  commercial: {
    ...commonConfigOptions(MarketplaceType.Commercial),
    priceChanges: {
      underPriceEstimation: false,
      priceDrop: false,
    },
    roomsOptions: [
      RoomOption.One,
      RoomOption.Two,
      RoomOption.Three,
      RoomOption.Four,
      RoomOption.Five,
      RoomOption.Six,
      RoomOption.Seven,
      RoomOption.Eight,
      RoomOption.Nine,
      RoomOption.Ten,
      RoomOption.Fifteen,
      RoomOption.Twenty,
      RoomOption.All,
    ],
    bathsOptions: [],
    conditions: [
      Condition.Skin,
      Condition.Finish,
      Condition.FurnishedFinish,
      Condition.FullFinish,
      Condition.RequiresRenovation,
    ],
    budgetByDealType: {
      [DealType.Rent]: {
        initialBudgetValue: [ 0, 500000 ],
        budgetSliderProps: {
          min: 0,
          max: 500000,
          step: 10000,
          minDelta: 10000,
        },
      },
      [DealType.Buy]: {
        initialBudgetValue: [ 0, 10000000 ],
        budgetSliderProps: {
          min: 0,
          max: 10000000,
          step: 100000,
          minDelta: 100000,
        },
      },
    },
    budgetByPerSquareMeter: {
      [DealType.Rent]: {
        initialBudgetValue: [ 0, 10000 ],
        budgetSliderProps: {
          min: 0,
          max: 10000,
          step: 250,
          minDelta: 250,
        },
      },
      [DealType.Buy]: {
        initialBudgetValue: [ 0, 100000 ],
        budgetSliderProps: {
          min: 0,
          max: 100000,
          step: 1000,
          minDelta: 1000,
        },
      },
    },
    qualityClassOptions: [ QualityClassOption.ClassA, QualityClassOption.ClassB, QualityClassOption.ClassC ],
    amenities: {
      1: {
        title: 'filters.commercial.amenities.main',
        sections: [
          {
            name: 'common',
            type: 'checkboxGroup',
            items: [
              {
                type: 'checkbox',
                amenity: Amenity.FullTimeAccess,
                icon: <Project16Pending />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Elevator,
                icon: <Project16ElevatorIl />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.AirConditioner,
                icon: <Project16AirCondition />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Alarm,
                icon: <Project16Sold />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.SecureRoom,
                icon: <Project16SafetyUnit />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.SubDivisible,
                icon: <Project16List />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Accessible,
                icon: <Project16Wheelchair />,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Doorman,
                icon: <Project16Doorman />,
              },
            ],
          },
          {
            name: 'another',
            type: 'checkboxGroup',
            items: [
              {
                type: 'checkbox',
                amenity: Amenity.Reception,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.ConferenceRoom,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Kitchenette,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.OuterSpace,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.Storage,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.ColdRoom,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.LoadingRamp,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.HighCeiling,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.ActiveBusiness,
                icon: null,
              },
            ],
          },
          {
            name: 'parking',
            type: 'checkboxGroup',
            items: [
              {
                type: 'checkbox',
                amenity: Amenity.ParkingVisitors,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.ParkingEmployee,
                icon: null,
              },
              {
                type: 'checkbox',
                amenity: Amenity.ParkingBikes,
                icon: null,
              },
            ],
          },
        ],
      },
    },
    filtersIcons: {
      price: <PriceRangeIcon />,
      monthlyTaxRange: null,
      roomsRange: null,
      conditions: null,
      additional: <AdditionalIcon />,
      amenities: null,
      seller: <SellerIcon />,
      propertyTypes: <PropertyTypeIcon />,
      floorRange: null,
      bathsRange: null,
      areaRange: <Project16Floorplan />,
      dealType: null,
      fee: null,
      ppmRange: null,
      projectDiscount: null,
      priceRange: null,
      priceChanges: null,
      numberOfEmployeesRange: null,
      qualityClass: <Project16AB />,
    },
    propertyTypes: [
      PropertyType.Office,
      PropertyType.Shop,
      PropertyType.SharedWorkSpace,
      PropertyType.Studio,
      PropertyType.Hall,
      PropertyType.Clinic,
      PropertyType.Restaurant,
      PropertyType.Hotel,
      PropertyType.Building,
      PropertyType.IndustrialBuilding,
      PropertyType.Storeroom,
      PropertyType.Basement,
      PropertyType.ParkingLot,
      PropertyType.Land,
      PropertyType.IncomeProducingProperty,
      PropertyType.AgricultureLand,
      PropertyType.FarmLand,
    ],
    filtersByDealType: {
      [DealType.Buy]: commercialFilters,
      [DealType.Rent]: commercialFilters,
    },
  },
};
