import Cookie from 'js-cookie';
import { debounce } from 'lodash';


export const WINDOW_WIDTH_KEY = 'WINDOW_WIDTH';

const storeToCookie = (w: number) => {
  if (window.innerWidth) { // to resolve weird 0 issue in dev mode
    Cookie.set(WINDOW_WIDTH_KEY, w.toString());
  }
};

export function persistWindowWidth() {
  storeToCookie(window.innerWidth);
  const handler = debounce(() => {
    storeToCookie(window.innerWidth);
  }, 1000);
  window.addEventListener('resize', handler);
}
