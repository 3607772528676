import { orderBy, keys } from 'lodash';
import {
  DeveloperProjectSection,
  IDeveloperProject,
  ProjectBuildingStage,
  ProjectIntegrationLevel,
} from 'utils/entities';
import { DeveloperFiltersState } from 'store/state/app';
import { Section } from 'components/navigation/subheader/SectionsNavigation';

export type NonPromotedDeveloperProjectSection = Exclude<DeveloperProjectSection, 'promoted'>;

const initValueFactory = (): Record<NonPromotedDeveloperProjectSection, IDeveloperProject[]> => ({
  [DeveloperProjectSection.Planning]: [],
  [DeveloperProjectSection.Building]: [],
  [DeveloperProjectSection.Done]: [],
});

function extractor (project: IDeveloperProject) {
  switch (project.integrationLevel) {
    case ProjectIntegrationLevel.Full: return 3;
    case ProjectIntegrationLevel.DevDisplay: return 2;
    case ProjectIntegrationLevel.Demo: return 1;
    default:
      return 0;
  }
}

export const filterProjects = (projects: IDeveloperProject[], filters: DeveloperFiltersState): IDeveloperProject[] => {
  if (!filters) return projects;
  const names = keys(filters);

  return projects.filter(project => {
    for (const name of names) {
      const value = project[name];
      const filterValues = Array.isArray(filters[name]) && filters[name].length ? filters[name] : null;
      if (value && filterValues) {
        if (!filterValues.includes(value)) {
          return false;
        }
      }
    }
    return true;
  });
};

export const isProjectPromoted = (project: IDeveloperProject) =>
  project && project.integrationLevel === ProjectIntegrationLevel.Full;

export const isProjectPlanning = (project: IDeveloperProject) =>
  project && project.buildingStage === ProjectBuildingStage.Planning;

export const isProjectBuilding = (project: IDeveloperProject) =>
  project && project.buildingStage === ProjectBuildingStage.Building;

export const isProjectDone = (project: IDeveloperProject) =>
  project && project.buildingStage === ProjectBuildingStage.Done;

export const projectsBySectionMapper = (projects: IDeveloperProject[]): Record<NonPromotedDeveloperProjectSection, IDeveloperProject[]> => {
  const projectsBySection = projects.reduce((res, project) => {
    if (isProjectPlanning(project)) {
      res[DeveloperProjectSection.Planning].push(project);
    }
    if (isProjectBuilding(project)) {
      res[DeveloperProjectSection.Building].push(project);
    }
    if (isProjectDone(project)) {
      res[DeveloperProjectSection.Done].push(project);
    }

    return res;
  }, initValueFactory());

  return ({
    [DeveloperProjectSection.Planning]: projectsBySection[DeveloperProjectSection.Planning],
    [DeveloperProjectSection.Building]: projectsBySection[DeveloperProjectSection.Building],
    [DeveloperProjectSection.Done]: projectsBySection[DeveloperProjectSection.Done],
  });
};

export const sortPromotedProjects = (projects: IDeveloperProject[]): IDeveloperProject[] =>
  orderBy(projects, 'updatedAt', 'desc');

export const sortNonPromotedProjects = (projects: IDeveloperProject[]): IDeveloperProject[] =>
  orderBy(projects, [ extractor, 'updatedAt' ], [ 'desc', 'desc' ]);

export const genProjectSectionScrollSpy = (section: DeveloperProjectSection) => `${Section.PROJECTS}-${section}`;

export const projectFilterSections = new Set([
  Section.PROJECTS_MAP,
  Section.PROJECTS_LIST,
  ...Object.values(DeveloperProjectSection).map(genProjectSectionScrollSpy),
]);
