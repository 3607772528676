export interface Modifiable<T> {
  origin: T;
  modified: T;
  hasChanged?: boolean;
}

export function createModifiable<T>(value: T): Modifiable<T> {
  return {
    origin: value,
    modified: value,
    hasChanged: false,
  };
}

export function revertModifiableToOrigin<T>(value: Modifiable<T>): Modifiable<T> {
  return {
    origin: value.origin,
    modified: value.origin,
    hasChanged: false,
  };
}
