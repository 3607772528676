import React from 'react';
import { HighlightGroupLabel } from './styled';
import { TextLabel } from 'ds/components/typography';
import { connect } from 'react-redux';
import { IInsight, MarketplaceType } from 'utils/entities';
import { State } from 'store/state';
import { useLocale } from 'locale';
import { activeCollectionSelector } from 'store/state/insightsContext/selectors';
import { ExpandCollection } from 'store/state/insightsContext';
import { HighlightGroup } from 'components/expanded-highlights-page/types';
import { makeExpandedInsightSelector } from 'store/state/selectors/insights';
import { insightsByIdsSelector } from 'store/state/domainData/selectors';
import { SubheaderBasic } from './components/SubheaderBasic';
import InsightBackButton from './components/InsightBackButton';
import { marketplaceSelector } from 'store/state/selectors/router';

interface ExpandSubheaderProps {
  activeInsight: IInsight;
  activeCollection: ExpandCollection;
  marketplace: MarketplaceType;
}

export const ExpandSubheader: React.FC<ExpandSubheaderProps> = ({
  activeInsight,
  activeCollection,
  marketplace,
}) => {
  const { t } = useLocale();
  let subheaderLabel = '';
  const isCommercial = marketplace === MarketplaceType.Commercial;

  switch (activeCollection) {
    case ExpandCollection.Tradeoff: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', {
        group: activeInsight.tradeoff.goodTradeoff
          ? HighlightGroup.ThingsYouWouldLove
          : HighlightGroup.GoodToKnow,
        isCommercial,
      });
      break;
    }
    case ExpandCollection.Transportation:
    case ExpandCollection.Nuisances:
    case ExpandCollection.Planning: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', { group: HighlightGroup.ThingsYouShouldKnow, isCommercial });
      break;
    }
    case ExpandCollection.LivabilityCarousel:
    case ExpandCollection.QuietStreets: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', { group: HighlightGroup.ThingsYouWouldLove, isCommercial });
      break;
    }
    case ExpandCollection.Schools:
    case ExpandCollection.SchoolsCarousel:
    case ExpandCollection.ILSchools: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', { group: HighlightGroup.Schools, isCommercial });
      break;
    }
    case ExpandCollection.PricesCarousel:
    case ExpandCollection.Prices: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', { group: HighlightGroup.Prices, isCommercial });
      break;
    }
    case ExpandCollection.Neighbourhood: {
      subheaderLabel = t('expandedHighlight.highlightGroupLabel', { group: HighlightGroup.Neighbourhood, isCommercial });
      break;
    }
    case ExpandCollection.AreaHighlights:
    case ExpandCollection.NonVerified:
    case ExpandCollection.Verified: {
      subheaderLabel = t('insightType.title', { insightType: activeInsight.category });
      break;
    }
  }

  return (
    <SubheaderBasic
      title={(
        subheaderLabel ? (
          <HighlightGroupLabel type={activeInsight.type} group={activeCollection} category={activeInsight.category}>
            <TextLabel>{subheaderLabel}</TextLabel>
          </HighlightGroupLabel>
        ) : null
      )}
      back={<InsightBackButton />}
    />
  );
};

const expandedInsightSelector = makeExpandedInsightSelector(insightsByIdsSelector);

const mapStateToProps = (state: State) => ({
  activeInsight: expandedInsightSelector(state),
  activeCollection: activeCollectionSelector(state),
  marketplace: marketplaceSelector(state),
});

export default connect(mapStateToProps, null)(ExpandSubheader);
