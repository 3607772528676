import styled from '@emotion/styled';
import { H3, SmallText } from 'ds/components/typography';

export const Root = styled.a<{ withLeftMargin: boolean; }>`
  ${({ theme, withLeftMargin }) => `
    display: flex;
    padding: ${theme.spacing(1)};
    overflow: hidden;
    width: 328px;
    text-decoration: none;
    margin-left: ${withLeftMargin ? theme.spacing(1) : 0};
    border: 1px solid ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.small};
    gap: ${theme.spacing(1.5)};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

export const Body = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: ${theme.spacing(1)};
    gap: ${theme.spacing(0.5)};
  `}
`;

export const Title = styled(H3)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  `}
`;

export const LinkWrap = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 170px;
    a {
      text-decoration: underline;
      color: inherit;
      :hover {
        color: ${theme.colors.brand.main};
      }
    }
  `}
`;

export const Image = styled.img`
  ${({ theme }) => `
    display: block;
    height: 128px;
    width: 128px;
    flex-shrink: 0;
    border-radius: ${theme.borderRadius.small};
  `}
`;
