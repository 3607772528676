import React from 'react';
import { useLocale } from 'locale';
import { MadadSearchResultCategory } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';
import styled from '@emotion/styled';
import { SmallText, TextLabel } from 'ds/components/typography';
import Tooltip from 'ds/components/tooltip';


export const TagsWrapper = styled.div<{ isOnSeparateLine: boolean }>`
${({ theme, isOnSeparateLine }) => `
  display: flex;
  align-items: center;
  ${isOnSeparateLine ? `margin: ${theme.spacing(1)} 0;` : ''}
`}
`;

interface TagProps {
  isMapPreview?: boolean;
  madadSearchResult?: MadadSearchResultCategory;
  suppressMargin?: boolean;
}

export const Tag = styled(TextLabel)<TagProps>`
  ${({ theme, suppressMargin }) => `
    padding: ${theme.spacing(0.75)} ${theme.spacing(1)};
    border-radius: ${theme.borderRadius.tiny};
    line-height: 16px;
    height: 100%;
    white-space: nowrap;
    ${suppressMargin ? '' : `margin-right: ${theme.spacing(1)}`};
  `}
  ${({ theme, isMapPreview, madadSearchResult }) => {
    if (madadSearchResult === 'top_10') {
      return `
        background-color: ${theme.colors.brand.madlanGreen};
        color: ${theme.colors.neutrals.white};
      `;
    }
    if (madadSearchResult === 'top_10_gold') {
      return `
        color: ${theme.colors.neutrals.white};
        background: linear-gradient(to right, #E6C06E, #C9A357);
      `;
    }
    if (isMapPreview) {
      return `
        color: ${theme.colors.neutrals.white};
        background-color: ${colorToAlpha(theme.colors.neutrals.black, 0.4)};
        position: absolute;
        bottom: 36px;
      `;
    }
    return `
      color: ${theme.colors.neutrals.grey2};
      background-color: ${theme.colors.neutrals.grey9};
    `;
  }}
`;
Tag.defaultProps = { small: true, weight: 'bold' };


interface AgentTagsProps {
  isExclusive?: boolean;
  isMapPreview?: boolean;
  madadSearchResult?: MadadSearchResultCategory;
  isOnSeparateLine?: boolean;
}

export const AgentTags: React.FC<AgentTagsProps> = props => {
  const { isExclusive, isMapPreview, madadSearchResult, isOnSeparateLine } = props;
  const { t } = useLocale();

  if (madadSearchResult) {
    return (
      <TagsWrapper isOnSeparateLine={isOnSeparateLine}>
        {isExclusive ? (
          <Tag isMapPreview={isMapPreview} small={false} suppressMargin={isOnSeparateLine}>
            {t('universalCard.agent.label', { isExclusive })}
          </Tag>
        ) : null}
        <Tooltip
          placement="top-end"
          tooltip={<SmallText>{t('madad.categoryBadge.tooltip')}</SmallText>}
        >
          <Tag
            data-auto={`madad-tag-${madadSearchResult === 'top_10_gold' ? 'gold' : 'green'}`}
            madadSearchResult={madadSearchResult}
            small={false}
          >
            {t('universalCard.agent.madadWinnerLabel')}
          </Tag>
        </Tooltip>
      </TagsWrapper>
    );
  }

  return (
    <Tag
      data-auto={`agent-tag${isExclusive ? '-exclusive' : ''}`}
      isMapPreview={isMapPreview}
      small={false}
    >
      {t('universalCard.agent.label', { isExclusive })}
    </Tag>
  );
};
