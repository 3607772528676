import styled from '@emotion/styled';


export const ItemWrapper = styled.div`
  width: 284px;
  padding: ${({ theme }) => theme.spacing(0.5)};
  text-align: initial;

  ${({ theme }) => `
    &:first-of-type {
      margin-right: ${theme.spacing(4)};
    }
    > div:first-of-type {
      border: 1px solid ${theme.colors.neutrals.grey8};
      box-shadow: none;
    }
  `}
`;

export const ListingPostLeadCarouselWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: ${theme.spacing(3)};
  `}
`;

export const CenteredCarouselControls = styled.div`
  [dir="prev"],
  [dir="next"] {
    top: calc(50% - 16px);
  }
`;
