import React, { useState } from 'react';
import { getInfiniteNextIndex, getInfinitePrevIndex } from 'utils/gallery';
import { useInterval } from 'hooks/useInterval';
import { SideClickListener } from 'components/view-pager-to-stories/SideClickListener';
import { ViewPager } from 'components/view-pager';
import { InViewportListener } from 'components/view-pager-to-stories/InViewportListener';
import { Dots, DotStyled, DotsWrapper, DotStyledWrapper, ArrowsWrapper } from './styled';
import { useTheme } from 'hooks/useTheme';
import Arrow from 'assets/svg/left.svg';
import { ShowAt } from 'consts/breakpoints';
import { SlideBtn } from 'ds/components/carousels/styled';


interface StoriesProps {
  interval?: number;
  children: JSX.Element[];
  onStoryIndexChangeNotify?: (nextIdx: number, prevIndex: number, isAutomatic: boolean) => void;
  dotColor?: string;
  withArrows?: boolean;
}

export const Stories: React.FC<StoriesProps> = ({ interval, children, onStoryIndexChangeNotify, dotColor, withArrows }) => {
  const [ activeStory, realSetActiveStory ] = useState(0);
  const [ isIntervalActive, setIsIntervalActive ] = useState(false);
  const [ isPaused, setIsPaused ] = useState(false);

  const setActiveStory = (value: React.SetStateAction<number>, isAutomatic = false) => {
    const newValue = typeof value === 'number' ? value : value(activeStory);
    if (newValue !== activeStory && onStoryIndexChangeNotify) {
      onStoryIndexChangeNotify(newValue, activeStory, isAutomatic);
    }
    realSetActiveStory(newValue);
  };

  const childrenCount = React.Children.count(children);

  const handleLeftSideClick = () => setActiveStory((prev) => getInfinitePrevIndex(prev, childrenCount));
  const handleRightSideClick = () => setActiveStory((prev) => getInfiniteNextIndex(prev, childrenCount));
  const handleClickStory = (e: React.MouseEvent<HTMLDivElement>, idx: number) => {
    e.stopPropagation();
    setActiveStory(idx);
  };
  const handleEnterViewport = () => setIsIntervalActive(true);
  const handleLeaveViewport = () => setIsIntervalActive(false);
  const handleTouchStart = () => setIsPaused(true);
  const handleTouchEnd = () => setIsPaused(false);

  const { isRTL } = useTheme();

  useInterval(() =>  {
    if (!isPaused && isIntervalActive && childrenCount > 1) {
      setActiveStory((prev) => getInfiniteNextIndex(prev, childrenCount), true);
    }
  }, interval, [ activeStory, isPaused, isIntervalActive, childrenCount ]);

  return (
    <SideClickListener
      onLeftSideClick={handleLeftSideClick}
      onRightSideClick={handleRightSideClick}
    >
      <ArrowsWrapper>
        <ViewPager
          onSlideIndexChange={setActiveStory}
          slideIndex={activeStory}
          align="left"
          isRTL={isRTL}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {children}
        </ViewPager>
        {withArrows ? (
            <ShowAt from={2}>
              <div className="arrows-area">
                <SlideBtn dir="prev">
                  <Arrow/>
                </SlideBtn>
                <SlideBtn dir="next">
                  <Arrow/>
                </SlideBtn>
              </div>
            </ShowAt>
        ) : null}
        {childrenCount > 1 ? (
          <InViewportListener
            onEnterViewport={handleEnterViewport}
            onLeaveViewport={handleLeaveViewport}
          >
            <Dots>
              <DotsWrapper>
                {Array(childrenCount).fill(null).map((_, index: number) => (
                  <DotStyledWrapper
                    key={index}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickStory(e, index)}
                  >
                    <DotStyled
                      color={dotColor}
                      interval={interval}
                      isCompleted={index < activeStory}
                      isActive={index === activeStory}
                      isPaused={isPaused || !isIntervalActive}
                    />
                  </DotStyledWrapper>
                ))}
              </DotsWrapper>
            </Dots>
          </InViewportListener>
        ) : null}
      </ArrowsWrapper>
    </SideClickListener>
  );
};

Stories.defaultProps = {
  interval: 5000,
};
