import styled from '@emotion/styled';
import { H2 } from 'ds/components/typography';

export const Heading = styled(H2)`
  white-space: pre;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const Wrapper = styled.div`
  max-width: 380px;
`;
