import React, { useRef } from 'react';
import {
  ScrollableContentWrapperRoot,
  ScrollableContentWrapper,
  LeftGhostItem,
  LeftGradient,
  RightGhostItem,
  RightGradient,
  ScrollableContent,
} from './styled';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';


const Wrapper: React.FC<{ scrollableRef?: React.Ref<HTMLDivElement> }> = ({ children, scrollableRef }) => {
  const root = useRef<HTMLDivElement>(null);
  const leftGradient = useRef<HTMLDivElement>(null);
  const rightGradient = useRef<HTMLDivElement>(null);
  const leftGhostItem = useRef<HTMLDivElement>(null);
  const rightGhostItem = useRef<HTMLDivElement>(null);

  useIntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const target = entry.target as HTMLDivElement;
        const opacity = 1 - entry.intersectionRatio;
        const { dataset: { leftGhost, rightGhost } } = target;

        if (leftGhost && leftGradient.current) {
          leftGradient.current.style.opacity = `${opacity}`;
        }
        else if (rightGhost && rightGradient.current) {
          rightGradient.current.style.opacity = `${opacity}`;
        }
      });
    },
    [ leftGhostItem, rightGhostItem ],
    { root, threshold: [ 0.99, 0.7, 0.5, 0.3, 0 ] }
  );

  return (
    <ScrollableContentWrapperRoot ref={root}>
      <ScrollableContentWrapper ref={scrollableRef}>
        <ScrollableContent>
          <LeftGhostItem ref={leftGhostItem} data-left-ghost={true} />
          {children}
          <RightGhostItem ref={rightGhostItem} data-right-ghost={true} />
        </ScrollableContent>
      </ScrollableContentWrapper>
      <RightGradient ref={rightGradient} />
      <LeftGradient ref={leftGradient} />
    </ScrollableContentWrapperRoot>
  );
};

export default Wrapper;
