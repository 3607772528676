import React, { useEffect } from 'react';
import { ITranslate, useLocale } from 'locale';
import { useField } from 'react-final-form-hooks';
import {
  Section,
  SectionHeading,
  HeadingHint,
  SelectWrapper,
  AdditionalBedsWrapper,
} from 'screens/UploadBulletinPage/styled';
import { UploadBulletinStepProps } from 'screens/UploadBulletinPage/steps';
import { isNotEmpty } from 'utils/formValidationHelpers';
import { SelectStyleType, Select } from 'screens/UploadBulletinPage/components/select';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { SpacingBlock } from 'components/spacing-block';
import { genBedsOptions, MAX_ROOMS } from './helpers';
import { hideRoomsCount } from 'utils/uploadBulletin';

const SelectWithValidationMessage = withValidationMessage(Select);

const constructTotalBedsHint = (t: ITranslate, beds: number, withHalfRoom: boolean = false): string => {
  if (!beds) return '';

  const totalBeds = withHalfRoom ? beds + 0.5 : beds;
  return t('uploadBulletinPage.mainInfoStep.totalBedsHeading', { totalBeds });
};

const validateField = (t: ITranslate) => (val: any) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');

export const BedsNumberSection: React.FC<UploadBulletinStepProps> = ({ formInstance }) => {
  const { t } = useLocale();

  const buildingClass = formInstance.getState().values.buildingClass;
  const hideBeds = hideRoomsCount(buildingClass, false);
  const beds = useField('beds', formInstance, hideBeds ? undefined : validateField(t));
  const withHalfRoom = useField('withHalfRoom', formInstance);

  const totalBedsHint = constructTotalBedsHint(t, beds.input.value, beds.input.value !== 10 && withHalfRoom.input.value);

  useEffect(() => {
    if (beds.input.value === MAX_ROOMS) {
      formInstance.change('withHalfRoom', false);
    }
  }, [ formInstance, beds.input.value ]);

  if (hideBeds) return null;

  return (
    <Section>
      <SpacingBlock mBottom={3}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.mainInfoStep.bedsNumberHeading')}
          {totalBedsHint ? (<HeadingHint>{totalBedsHint}</HeadingHint>) : null}
        </SectionHeading>
      </SpacingBlock>
      <SelectWrapper>
        <SelectWithValidationMessage
          styleType={SelectStyleType.circle}
          options={genBedsOptions(t)}
          {...beds.input}
          {...beds.meta}
        />
      </SelectWrapper>
      <SelectWrapper>
        <AdditionalBedsWrapper>
          <CheckboxField
            disabled={beds.input.value === MAX_ROOMS}
            checked={withHalfRoom.input.value}
            {...withHalfRoom.input}
            label={t('uploadBulletinPage.mainInfoStep.withHalfRoomLabel')}
          />
        </AdditionalBedsWrapper>
      </SelectWrapper>
    </Section>
  );
};
