import { combineReducers } from 'redux';
import { uniqBy } from 'lodash';
import { ActionType } from 'typesafe-actions';
import { PoiId, DatedPoi, BoundingBox } from 'utils/entities';
import * as actions from './actions';
import {
  SET_ACTIVE_POI_IDS,
  ADD_VIEWED_POI,
  DATA_LAYERS_BBOX_UPDATE,
  SET_BBOX,
} from './types';


export type SearchContextAction = ActionType<typeof actions>;

export interface SearchContextState {
  /** Last viewed pois. These are only relevant for non-logged in users. */
  viewedPois: DatedPoi[];
  activePoiIds: PoiId[];
  lastSearchBbox: BoundingBox;
  dataLayers: {
    zoom: number;
    bbox: BoundingBox;
  };
}

export default combineReducers<SearchContextState, SearchContextAction>({
  lastSearchBbox: (state = null, action) => {
    switch (action.type) {
      case SET_BBOX:
        return action.payload;
      default: return state;
    }
  },
  dataLayers: (state = { zoom: null, bbox: null }, action) => {
    switch (action.type) {
      case DATA_LAYERS_BBOX_UPDATE:
        return action.payload;
      default: return state;
    }
  },
  viewedPois: (state = [], action) => {
    switch (action.type) {
      case ADD_VIEWED_POI:
        return uniqBy([ action.payload, ...state ], (i) => i.poiId.id);
      default:
        return state;
    }
  },
  activePoiIds: (state = [], action) => {
    switch (action.type) {
      case SET_ACTIVE_POI_IDS:
        return action.payload;
      default:
        return state;
    }
  },
});
