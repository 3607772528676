import React from 'react';

import styled from '@emotion/styled';


interface CheckboxWrapperProps {
  reversed?: boolean;
  disabled: boolean;
  alignStart?: boolean;
}


export const CheckboxWrapper = styled.label<CheckboxWrapperProps>`
  ${({ theme, reversed, disabled, alignStart }) => `
    position: relative;
    user-select: none;
    display: flex;
    flex-direction: ${reversed ? 'row-reverse' : 'row'};
    align-items: ${alignStart ? 'start' : 'center'};
    ${disabled ? `
      > div:last-of-type {
        opacity: 0.5;
      }
    ` : ''}
    > svg {
      ${theme.isRTL ? 'margin-right' : 'margin-left'}: -${theme.spacing(0.5)};
      ${theme.isRTL ? 'margin-left' : 'margin-right'}: ${theme.spacing(1)};
    }
  `}
`;


export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface CheckmarkProps {
  checked?: boolean;
  disabled?: boolean;
  size?: number;
}

export const Checkmark = styled.div<CheckmarkProps>`
  ${({ theme, checked, disabled, size }) => `
    cursor: ${disabled ? 'auto' : 'pointer'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    top: 0;
    left: 0;
    height: ${size || 18}px;
    width: ${size || 18}px;
    border-radius: ${theme.borderRadius.tiny};
    margin-left: ${theme.spacing(1.5)};
    transition: background-color .2s;
    background-color: ${checked ? theme.colors.brand.main : theme.colors.neutrals.white};
    border: ${checked ? 'none' : `1px solid ${theme.colors.neutrals.grey6}`};
    &:hover {
      background-color: ${checked ? theme.colors.brand.dark : theme.colors.neutrals.grey10};
    }
    &[disabled] {
      cursor: not-allowed;
      background-color: ${checked ? theme.colors.neutrals.grey6 : theme.colors.neutrals.grey8};
    }

    > svg {
      fill: ${theme.colors.neutrals.white};
    }
  `}
`;

export const Checkbox: React.FunctionComponent<React.HTMLProps<HTMLInputElement>> = (props) => (
  <Input data-auto="checkbox" {...props} type="checkbox" />
);
