import React, { useCallback } from 'react';
import { SliderRange, InternalSliderRange } from './SliderRange';
import {
  MouseOrTouchEvent,
  ValuesTuple,
} from './utils';
import { useTheme } from 'hooks/useTheme';

export { SliderRange };

export interface SliderProps {
  disabled?: boolean;
  min: number;
  max: number;
  value: number;
  step?: number;
  onChange: (value: number, evt: MouseOrTouchEvent) => void;
  onDragStart?: (evt: MouseOrTouchEvent) => void;
  onDragEnd?: (evt: MouseOrTouchEvent) => void;
}

const ZERO_HANDLE = new Set([ 0 ]);
const ONE_HANDLE = new Set([ 1 ]);

export const Slider: React.FC<SliderProps> = (props) => {

  const { value, onChange, ...otherProps } = props;
  const { isRTL } = useTheme();

  const handleChange = useCallback(([ n0, n1 ]: ValuesTuple, evt: MouseOrTouchEvent) => {
    props.onChange(isRTL ? -n0 : n1, evt);
  }, [ props.onChange, isRTL ]);

  if (isRTL) {
    return (
      <InternalSliderRange
        {...otherProps}
        min={-otherProps.max}
        max={-otherProps.min}
        minDelta={-Infinity}
        onChange={handleChange}
        values={[ -value, -otherProps.min ]}
        drawHandles={ZERO_HANDLE}
      />
    );
  }

  return (
    <InternalSliderRange
      {...otherProps}
      minDelta={-Infinity}
      onChange={handleChange}
      values={[ props.min, value ]}
      drawHandles={ONE_HANDLE}
    />
  );
};


