import React from 'react';
import { FormApi } from 'final-form';
import { useField } from 'react-final-form-hooks';
import { useLocale } from 'locale';
import styled from '@emotion/styled';
import { TextField } from 'ds/components/input/TextField';


const FormWrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(3)} 0 0;

    & > form > div:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`;

interface ContactFormProps {
  formApi: FormApi;
  handleSubmit: () => void;
  onPhoneInputClick: () => void;
  onMessageInputClick: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ formApi, handleSubmit, onPhoneInputClick, onMessageInputClick }) => {
  const { t } = useLocale();
  const { submitFailed, submitting } = formApi.getState();
  const message = useField('message', formApi);
  const name = useField('name', formApi);
  const phone = useField('phone', formApi);

  const messageField = (
    <TextField
      data-auto="agent-contact-message"
      {...message.input}
      hasError={(submitFailed || message.meta.dirty) && message.meta.invalid}
      errorMessage={message.meta.error}
      disabled={submitting}
      multiline
      rows={4}
      fullWidth
      label={t('contactAgentModal.labels.message')}
      onClick={onMessageInputClick}
    />
  );

  const nameField = (
    <TextField
      data-auto="agent-contact-name"
      {...name.input}
      hasError={(submitFailed || name.meta.dirty) && name.meta.invalid}
      errorMessage={name.meta.error}
      disabled={submitting}
      type="text"
      fullWidth
      label={t('contactAgentModal.labels.name')}
    />
  );

  const phoneField = (
    <TextField
      data-auto="agent-contact-phone"
      {...phone.input}
      hasError={(submitFailed || phone.meta.dirty) && phone.meta.invalid}
      type="tel"
      fullWidth
      label={t('contactAgentModal.labels.phone')}
      errorMessage={phone.meta.error}
      disabled={submitting}
      onClick={onPhoneInputClick}
    />
  );

  const fields = [ messageField, phoneField, nameField ];

  return (
    <FormWrapper data-auto="agent-contact-form-wrapper">
      <form data-auto="agent-contact-form" onSubmit={handleSubmit}>
        {fields.map((field, idx) => <React.Fragment key={idx}>{field}</React.Fragment>)}
      </form>
    </FormWrapper>
  );
};

export default ContactForm;
