import { IFiltersState } from 'components/filters/types';
import { Route, DecodedRouteParams } from 'config/routes';
import { AppMode } from 'store/state/selectors/router';


export interface CommonSeoLinksSection {
  title: string;
  type: HeaderLinkType | 'old' | 'dummy';
  icon?: JSX.Element;
  children?: SeoLinksSection[][];
}

export interface SeoLinksInnerLink extends CommonSeoLinksSection {
  type: HeaderLinkType.Inner;
  routeName: Route;
  params: Partial<DecodedRouteParams>;
}

export interface SeoLinksOuterLink extends CommonSeoLinksSection {
  type: HeaderLinkType.Outer;
  href: string;
}

export interface SeoLinksOldLink extends CommonSeoLinksSection {
  type: 'old';
  href: string;
}

export interface DummyLink extends CommonSeoLinksSection {
  type: 'dummy';
}

export interface TitleLink extends CommonSeoLinksSection {
  type: HeaderLinkType.None;
}

export type SeoLinksSection = SeoLinksInnerLink | SeoLinksOuterLink | SeoLinksOldLink | DummyLink | TitleLink;

export type TopNavLinksType = AppMode | 'blog' | 'madad' | 'commercial';

export enum HeaderLinkType {
  Inner = 'inner',
  Outer = 'outer',
  None = 'none',
}

export interface CommonHeaderLink {
  seoLinks?: SeoLinksSection[];
  type: HeaderLinkType;
  label: string;
}

export type LinkActivePredicate = (currentRoute: Route, appMode: AppMode | undefined) => boolean;

export interface InnerLink extends CommonHeaderLink {
  type: HeaderLinkType.Inner;
  routeName: Route;
  params: Partial<DecodedRouteParams>;
  activePredicate: LinkActivePredicate;
}

export interface OuterLink extends CommonHeaderLink {
  type: HeaderLinkType.Outer;
  href: string;
  name: 'blog';
}

export type HeaderLinkItem = InnerLink | OuterLink;

export interface ResidentialBuyRentHistoryFilters {
  residentialBuyFilter: IFiltersState;
  residentialRentFilter: IFiltersState;
}
