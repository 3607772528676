import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import {
  COLLAPSE_INSIGHT_SECTION,
  INIT,
  SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX,
  SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX,
  SET_ACTIVE_CONSTRUCTION_ID,
  SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX,
  SET_ACTIVE_LIVABILITY_CAROUSEL_IDX,
  SET_ACTIVE_NUISANCE_CAROUSEL_IDX,
  SET_ACTIVE_PRICE_CAROUSEL_IDX,
  SET_ACTIVE_SAFETY_CAROUSEL_IDX,
  SET_ACTIVE_SCHOOL_CAROUSEL_IDX,
  SET_BUILDING_PERMIT_INDEX,
  SET_HOVERED_DATA_LAYER_ID,
  SET_HOVERED_SCHOOL_ID,
  SET_HOVERED_TRANSPORTATION_STATION,
  SET_MOBILE_MAP_TYPE,
  SET_PRICES_BEDS_FILTER,
  SET_PRICES_ESTIMATION_INDEX,
  SET_PRICES_FINAL_TRANSACTIONS_FILTER,
  SET_PRICES_HISTORY_INDEX,
  SET_PRICES_STATE,
  SET_PRICES_TAB,
  SET_PRICES_YEAR_FILTER,
  SET_SCHOOLS_TAB,
  SET_TABU_SUBMITTED,
  SET_TRANSPORTATION_STATE,
  SET_ACTIVE_MAP_TRANSPORTATION,
  SET_IS_MOBILE_LAYERS_TOGGLE_OPEN,
  SET_IS_INFO_BOX_OPEN,
  SET_ACTIVE_BUS_LINE,
} from './types';
import { PricesSectionType, PricesState } from '../selectors/insights/prices';
import { isNull } from 'lodash';


export enum ExpandCollection {
  Tradeoff = 'TradeoffCollection',
  Transportation = 'TransportationCollection',
  Nuisances = 'NuisancesCollection',
  Planning = 'PlanningCollection',
  Schools = 'SchoolsCollection',
  ILSchools = 'ILSchoolsCollection',
  Prices = 'PricesCollection',
  PricesCarousel = 'PricesCarousel',
  SchoolsCarousel = 'SchoolsCarousel',
  LivabilityCarousel = 'LivabilityCarousel',
  SafetyCarousel = 'SafetyCarousel',
  QuietStreets = 'QuietStreetsCollection',
  AreaHighlights = 'AreaHighlights',
  Neighbourhood = 'Neighbourhood',
  Verified = 'Verified',
  NonVerified = 'NonVerified',
  BuildingPermit = 'buildingPermit',
}

export enum MapTransportation {
  Train = 'train',
  Bus = 'bus',
}

export enum TransportationSectionType {
  Transit = 'transit',
  Bike = 'bike',
  Train = 'train',
  Bus = 'bus',
  Car = 'car',
}

export enum SchoolsType {
  Elementary = 'Elementary',
  Middle = 'Middle School',
  High = 'High School',
}

export enum ILSchoolsType {
  Secular = 'secular',
  Orthodoxy = 'orthodox',
}

export enum InsightSection {
  Transportation = 'Transportation',
  Planning = 'Planning',
  Schools = 'Schools',
  Nuisances = 'Nuisances',
  Safety = 'Safety',
  Prices = 'Prices',
  PricesEstimation = 'PricesEstimation',
  NeighbourhoodLife = 'NeighbourhoodLife',
  QuietStreets = 'QuietStreets',
  Neighbors = 'Neighbors',
  BuildingPermit = 'BuildingPermit',
  FuturePlans = 'FuturePlans',
  DivisionAreaPlan = 'DivisionAreaPlan',
}



export interface TransportationState {
  accordion: TransportationSectionType;
  transitTab: TransportationSectionType;
}

export interface BuildingPermitState {
  activeBuildingPermitIndex: string[];
}

export type InsightsContextAction = ActionType<typeof actions>;
export interface BusLine {
  id?: string;
  name?: string;
  busStationId?: string;
}

export type MapTransportationState = Partial<Record<MapTransportation, boolean>>;

export interface InsightsContextState {
  transportation: TransportationState;
  activeMapTransportation: MapTransportationState;
  schoolsAccordion: SchoolsType;
  schoolsTab: number;
  activeNuisanceCarouselIdx: number;
  activePriceCarouselIdx: number;
  activeSchoolCarouselIdx: number;
  activeSafetyCarouselIdx: number;
  activeLivabilityCarouselIdx: number;
  activeImpactInsightCarouselIdx: number;
  activeAreaHighlightsAboutToComeCarouselIdx: number;
  activeAreaHighlightsGoodToKnowCarouselIdx: number;
  activeConstructionId: string | number;
  prices: PricesState;
  buildingPermit: BuildingPermitState;
  insightsMobileMapType: InsightSection;
  hoveredSchoolId: number;
  transportationStation: string | number;
  hoveredDataLayerId: string;
  activeBusLine: BusLine;
  openedInsightSections: InsightSection[];
  tabuSubmitted: boolean;
  isMobileLayersToggleOpen: boolean;
  isInfoBoxOpen: boolean;
}


const initialState: InsightsContextState = {
  schoolsAccordion: SchoolsType.Elementary,
  activeBusLine: {},
  activeMapTransportation: {},
  schoolsTab: 0,
  transportationStation: null,
  insightsMobileMapType: null,
  transportation: {
    accordion: TransportationSectionType.Transit,
    transitTab: TransportationSectionType.Train,
  },
  activeNuisanceCarouselIdx: 0,
  activePriceCarouselIdx: 0,
  activeSchoolCarouselIdx: 0,
  activeSafetyCarouselIdx: 0,
  activeLivabilityCarouselIdx: 0,
  activeImpactInsightCarouselIdx: 0,
  activeAreaHighlightsAboutToComeCarouselIdx: 0,
  activeAreaHighlightsGoodToKnowCarouselIdx: 0,
  activeConstructionId: null,
  prices: {
    accordion: PricesSectionType.LocalHistory,
    tab: null,
    yearFilter: null,
    bedsFilter: [ null, null ],
    onlyFinalTransactionsFilter: false,
    activeEstimationIndex: [ null ],
    activeHistoryIndex: [],
  },
  buildingPermit: {
    activeBuildingPermitIndex: [],
  },
  hoveredSchoolId: null,
  hoveredDataLayerId: null,
  openedInsightSections: [ InsightSection.FuturePlans, InsightSection.DivisionAreaPlan ],
  tabuSubmitted: false,
  isMobileLayersToggleOpen: false,
  isInfoBoxOpen: false,
};


export default (state: InsightsContextState = initialState, action: InsightsContextAction): InsightsContextState => {
  switch (action.type) {
    case INIT:
      return {
        ...initialState,
        activeMapTransportation: state.activeMapTransportation,
        activeBusLine: state.activeBusLine,
      };
    case SET_ACTIVE_BUS_LINE:
      return {
        ...state,
        activeBusLine: action.payload,
      };
    case SET_TRANSPORTATION_STATE:
      return {
        ...state,
        transportation: {
          accordion: action.payload.accordion || state.transportation.accordion,
          transitTab: action.payload.transitTab || state.transportation.transitTab,
        },
      };
    case SET_PRICES_STATE: {

      const next = {
        ...state.prices,
        ...action.payload,
      };

      if (action.payload.accordion) {
        next.tab = null;
      }

      return {
        ...state,
        prices: next,
      };
    }
    case SET_ACTIVE_MAP_TRANSPORTATION:
      return {
        ...state,
        activeMapTransportation: isNull(action.payload) ? {} : {
          ...state.activeMapTransportation,
          ...action.payload,
        },
      };
    case SET_PRICES_TAB:
      return {
        ...state,
        prices: {
          ...state.prices,
          tab: action.payload,
        },
      };
    case SET_PRICES_ESTIMATION_INDEX:
      return {
        ...state,
        prices: {
          ...state.prices,
          activeEstimationIndex: action.payload,
        },
      };
    case SET_PRICES_HISTORY_INDEX:
      return {
        ...state,
        prices: {
          ...state.prices,
          activeHistoryIndex: action.payload,
        },
      };
    case SET_PRICES_BEDS_FILTER:
      return {
        ...state,
        prices: {
          ...state.prices,
          bedsFilter: action.payload,
        },
      };
    case SET_PRICES_YEAR_FILTER:
      return {
        ...state,
        prices: {
          ...state.prices,
          yearFilter: action.payload,
        },
      };
    case SET_PRICES_FINAL_TRANSACTIONS_FILTER:
      return {
        ...state,
        prices: {
          ...state.prices,
          onlyFinalTransactionsFilter: action.payload,
        },
      };
    case SET_BUILDING_PERMIT_INDEX:
      return {
        ...state,
        buildingPermit: {
          ...state.buildingPermit,
          activeBuildingPermitIndex: action.payload,
        },
      };
    case SET_MOBILE_MAP_TYPE:
      return {
        ...state,
        insightsMobileMapType: action.payload,
      };
    case SET_ACTIVE_NUISANCE_CAROUSEL_IDX:
      return {
        ...state,
        activeNuisanceCarouselIdx: action.payload,
      };
    case SET_ACTIVE_PRICE_CAROUSEL_IDX:
      return {
        ...state,
        activePriceCarouselIdx: action.payload,
      };
    case SET_ACTIVE_SCHOOL_CAROUSEL_IDX:
      return {
        ...state,
        activeSchoolCarouselIdx: action.payload,
      };
    case SET_ACTIVE_SAFETY_CAROUSEL_IDX:
      return {
        ...state,
        activeSafetyCarouselIdx: action.payload,
      };
    case SET_TABU_SUBMITTED:
      return {
        ...state,
        tabuSubmitted: action.payload,
      };
    case SET_ACTIVE_LIVABILITY_CAROUSEL_IDX:
      return {
        ...state,
        activeLivabilityCarouselIdx: action.payload,
      };
    case SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX:
      return {
        ...state,
        activeImpactInsightCarouselIdx: action.payload,
      };
    case SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX:
      return {
        ...state,
        activeAreaHighlightsAboutToComeCarouselIdx: action.payload,
      };
    case SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX:
      return {
        ...state,
        activeAreaHighlightsGoodToKnowCarouselIdx: action.payload,
      };
    case SET_ACTIVE_CONSTRUCTION_ID:
      return {
        ...state,
        activeConstructionId: action.payload,
      };

    case SET_HOVERED_SCHOOL_ID:
      return {
        ...state,
        hoveredSchoolId: action.payload,
      };
    case SET_SCHOOLS_TAB:
      return {
        ...state,
        schoolsTab: action.payload,
      };
    case SET_HOVERED_TRANSPORTATION_STATION:
      return {
        ...state,
        transportationStation: action.payload,
      };

    case SET_HOVERED_DATA_LAYER_ID:
      return {
        ...state,
        hoveredDataLayerId: action.payload,
      };

    case COLLAPSE_INSIGHT_SECTION: {
      const opened = new Set(state.openedInsightSections);
      if (opened.has(action.payload)) {
        opened.delete(action.payload);
      }
      else {
        opened.add(action.payload);
      }

      return { ...state, openedInsightSections: [ ...opened ] };
    }
    case SET_IS_MOBILE_LAYERS_TOGGLE_OPEN:
      return {
        ...state,
        isMobileLayersToggleOpen: action.payload,
      };
    case SET_IS_INFO_BOX_OPEN:
      return {
        ...state,
        isInfoBoxOpen: action.payload,
      };

    default:
      return state;
  }
};
