import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { flow } from 'lodash';

import { State, RootAction } from 'store/state';

import { IUpdateUserProfileVariables } from 'store/sagas/apiService/types';
import { updateUser, setCommutePopupStatus } from 'store/state/app/actions';
import { commutePopupStatusSelector } from 'store/state/app/selectors';

import { CommutePopup } from './CommutePopup';
import { CommutePopupStatus } from './types';
import { userProfileSelector } from 'store/state/domainData/selectors';


const isOpenSelector = flow(commutePopupStatusSelector, (status) => status === CommutePopupStatus.Open);

const mapStateToProps = (state: State) => ({
  user: userProfileSelector(state),
  isOpen: isOpenSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(setCommutePopupStatus(CommutePopupStatus.Closed)),
  onUpdateUserProfile: (variables: IUpdateUserProfileVariables, key: string) => dispatch(updateUser({
    variables,
    key,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommutePopup);
