import {
  makeNullSafeDomainGetter,
  createIsLoadingSelector,
  getAffiliationRequestsSelector,
  getDealAffiliationRequestsBySaleIdSelector,
} from 'store/state/domainData/selectors/common';
import { groupBy } from 'lodash';
import { createSelector } from 'reselect';
import { agentIdSelector, agentsInOfficeSelector, isAgentManagerSelector, isAgentSelector } from 'store/state/domainData/selectors/currentUser';
import { DealAffiliationStatus, PoiId, DealAffiliationType, IRequestDealAffiliationResponse } from 'utils/entities';
import { State } from 'store/state/index';
import { IDealAffiliationUserRequestsToStatus } from 'components/deal-affiliation-popup/types';
import { popupStateSelector } from 'components/deal-affiliation-popup/selectors';

export const baseGetAffiliationRequestsSelector = makeNullSafeDomainGetter(getAffiliationRequestsSelector, 'getDeals');
export const dealsAffiliationRequestsLoadingSelector = createIsLoadingSelector(getAffiliationRequestsSelector);

export const baseDealAffiliationRequestsBySaleIdSelector = makeNullSafeDomainGetter(getDealAffiliationRequestsBySaleIdSelector, 'getDealAffiliationRequestBySaleId');
export const dealAffiliationRequestsBySaleIdLoadingSelector = createIsLoadingSelector(getDealAffiliationRequestsBySaleIdSelector);

export const dealsAffiliationRequestsSelector = createSelector(baseGetAffiliationRequestsSelector, (data) => data && data.deals || []);
export const dealsAffiliationPendingRequestsBySoldIdSelector = createSelector([
  dealsAffiliationRequestsSelector,
  (_: State, soldId: PoiId) => soldId,
], (data, soldId) => {
  if (!data || !data.length) return null;

  const pendingRequestsList = data.filter(i => i.affiliationStatus === DealAffiliationStatus.Pending);
  const pendingRequestsBySoldId = groupBy(pendingRequestsList, 'soldId');

  const requests = pendingRequestsBySoldId[soldId];
  if (!requests) return null;

  const requestsBySide = groupBy(requests, 'affiliationType');

  const bothRequests = requestsBySide[DealAffiliationType.Both];
  const buyerRequests = requestsBySide[DealAffiliationType.Buyer];
  const sellerRequests = requestsBySide[DealAffiliationType.Seller];

  if (bothRequests || buyerRequests && sellerRequests) return DealAffiliationType.Both;
  if (buyerRequests) return DealAffiliationType.Buyer;
  if (sellerRequests) return DealAffiliationType.Seller;

  return null;
});

const dealAffiliationRequestsBySaleIdSelector = createSelector(baseDealAffiliationRequestsBySaleIdSelector, (data) => data && data.results);
const pendingDealAffiliationRequestsBySaleIdSelector = createSelector(
  dealAffiliationRequestsBySaleIdSelector,
  (requests) => requests && requests.filter((r) => r.status === DealAffiliationStatus.Pending || [])
);
export const pendingUserDealAffiliationRequestsBySaleIdSelector = createSelector(
  [
    pendingDealAffiliationRequestsBySaleIdSelector,
    isAgentSelector,
    isAgentManagerSelector,
    agentIdSelector,
    agentsInOfficeSelector,
  ],
  (requests, isAgent, isAgentManager, agentId, agentsInOffice): IRequestDealAffiliationResponse[] => {
    if (!(requests && requests.length)) return [];

    if (isAgentManager) {
      return requests.filter((r) => agentsInOffice.findIndex((aio) => aio.agentId === r.agentId) !== -1);
    }
    else if (isAgent) return requests.filter((r) => r.agentId === agentId);

    return [];
  }
);

const dealAffiliationUserRequestsAndApprovedSelector = createSelector([
  dealAffiliationRequestsBySaleIdSelector,
  isAgentSelector,
  isAgentManagerSelector,
  agentIdSelector,
  agentsInOfficeSelector,
  popupStateSelector,
], (data, isAgent, isAgentManager, agentId, agentsInOffice, popupState) => {
  if (!(data && data.length)) return [];

  const requestIdToExclude = popupState && popupState.isOpen && popupState.affiliationRequest ? popupState.affiliationRequest.affiliationRequestId : null;
  const requests = data.filter(request => request.id !== requestIdToExclude);
  if (isAgentManager) {
    return requests.filter((r) => r.status === DealAffiliationStatus.Approved || agentsInOffice.findIndex((aio) => aio.agentId === r.agentId) !== -1);
  }
  else if (isAgent) return requests.filter((r) => r.status === DealAffiliationStatus.Approved || r.agentId === agentId);

  return [];
});

const getRequestsStatus = (requests: IRequestDealAffiliationResponse[]): DealAffiliationStatus => {
  if (requests && requests.length) {
    if (requests.find(r => r.status === DealAffiliationStatus.Approved)) {
      return DealAffiliationStatus.Approved;
    }
    else if (requests.find(r => r.status === DealAffiliationStatus.Pending)) {
      return DealAffiliationStatus.Pending;
    }
    else if (requests.find(r => r.status === DealAffiliationStatus.Rejected)) {
      return DealAffiliationStatus.Rejected;
    }
  }

  return null;
};

export const dealAffiliationUserRequestsToStatusMapSelector = createSelector(dealAffiliationUserRequestsAndApprovedSelector, (requests): IDealAffiliationUserRequestsToStatus => {
  const result: IDealAffiliationUserRequestsToStatus = {
    [DealAffiliationType.Seller]: { status: null, side: null },
    [DealAffiliationType.Buyer]: { status: null, side: null },
    [DealAffiliationType.Both]: { status: null, side: null },
  };

  if (!requests || !requests.length) return result;

  const requestsBySideMap = groupBy(requests, 'agentType');

  const requestsStatusMap = [ DealAffiliationType.Seller, DealAffiliationType.Buyer, DealAffiliationType.Both ].reduce((acc, val) => {
    const requestsBySide = requestsBySideMap[val];
    return { ...acc, [val]: getRequestsStatus(requestsBySide) };
  }, {
    [DealAffiliationType.Seller]: null,
    [DealAffiliationType.Buyer]: null,
    [DealAffiliationType.Both]: null,
  });

  if (requestsStatusMap[DealAffiliationType.Both] === DealAffiliationStatus.Approved ||
    requestsStatusMap[DealAffiliationType.Seller] === DealAffiliationStatus.Approved && requestsStatusMap[DealAffiliationType.Buyer] === DealAffiliationStatus.Approved
  ) {
    return {
      ...result,
      [DealAffiliationType.Seller]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Both },
      [DealAffiliationType.Buyer]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Both },
      [DealAffiliationType.Both]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Both },
    };
  }
  else if (requestsStatusMap[DealAffiliationType.Seller] === DealAffiliationStatus.Approved) {
    return {
      ...result,
      [DealAffiliationType.Seller]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Seller },
      [DealAffiliationType.Buyer]: { status: requestsStatusMap[DealAffiliationType.Buyer] },
      [DealAffiliationType.Both]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Seller },
    };
  }
  else if (requestsStatusMap[DealAffiliationType.Buyer] === DealAffiliationStatus.Approved) {
    return {
      ...result,
      [DealAffiliationType.Seller]: { status: requestsStatusMap[DealAffiliationType.Seller] },
      [DealAffiliationType.Buyer]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Buyer },
      [DealAffiliationType.Both]: { status: DealAffiliationStatus.Approved, side: DealAffiliationType.Buyer },
    };
  }

  return {
    ...result,
    [DealAffiliationType.Seller]: { status: requestsStatusMap[DealAffiliationType.Seller] },
    [DealAffiliationType.Buyer]: { status: requestsStatusMap[DealAffiliationType.Buyer] },
    [DealAffiliationType.Both]: { status: requestsStatusMap[DealAffiliationType.Both] },
  };
});
