import styled from '@emotion/styled';
import { H3, Text } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 370px;
  margin: 0 auto;
`;

export const Header = styled(H3)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
  `}
`;

export const Description = styled(Text)`
  white-space: pre-wrap;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2.5)} 0;
    width: 88px;
    height: 88px;
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.07)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.round};
    margin-bottom: ${theme.spacing(4)};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(3)};
  `}
`;

export const ShareActionsWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(4)};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(3)};
  `}
`;
