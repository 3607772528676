import { createSelector } from 'reselect';
import { insightsLoadingSelector, unitPageBulletinSelector } from 'store/state/domainData/selectors';
import {
  IPriceEstimationRecord,
  referenceMapper,
  priceEstimationInsightSummarySelector,
} from 'store/state/selectors/insights/prices';


export const priceEstimationDataSelector = createSelector([
  insightsLoadingSelector,
  priceEstimationInsightSummarySelector,
  unitPageBulletinSelector,
], (isLoading, data, bulletin) => {

  if (isLoading || !data || !bulletin) {
    return {
      minEstimation: 0,
      maxEstimation: 0,
      estimatedPrice: 0,
      isLoading,
      price: bulletin ? bulletin.price : null,
      records: null,
    };
  }

  const minEstimation = data.boundaries.bottomBoundary;
  const maxEstimation = data.boundaries.topBoundary;
  const estimatedPrice = data.estimatedPrice;
  const records: IPriceEstimationRecord[] = data.refListings.map(item => referenceMapper(item));

  return {
    minEstimation,
    maxEstimation,
    estimatedPrice,
    isLoading,
    price: bulletin.price,
    records,
  };
});
