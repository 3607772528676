import { getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import { submitContactAgent } from 'store/state/app/actions';
import { SUBMIT_USER_CONTACT_AGENT } from 'store/state/app/types';
import { SetMutationResponseAction, mutate } from 'store/state/mutationsResponse/actions';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from '../apiService/types';
import { agentPageDataSelector } from 'store/state/domainData/selectors/agentById';
import { officePageDataSelector } from 'store/state/domainData/selectors/officeById';
import { IAgentPageData, IOfficePageData, LeadingAgentData, LeadingAgentFeature } from 'utils/entities';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';
import { leadingAgentAwardDataSelector } from 'store/state/domainData/selectors/searchAgentAwardsByIds';
import { checkEnabledLeadingAgentFeature } from 'helpers/contact';

const contactSubmitMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.AgentOfficeContactPageSubmit
);

export function* madadAgentOfficeContactWorker(eventAction: ReturnType<typeof submitContactAgent>) {
  const { sendEvent } = yield getContext('analytics');

  const { payload } = eventAction;
  const route = yield select(routeSelector);

  const isAgent = route.name === Route.AgentPage;

  const { variables, key } = payload;

  const mutationParams = {
    mutationType: MutationType.AgentOfficeContactPageSubmit,
    meta: {
      key,
      variables: { ...variables },
    },
  };
  yield put(mutate(mutationParams));

  const isAgentPage = route.name === Route.AgentPage;

  if (!isAgentPage && route.name !== Route.OfficePage) return;

  const agentPageData: IAgentPageData = yield select(agentPageDataSelector);
  const officePageData: IOfficePageData = yield select(officePageDataSelector);
  const leadingAgentData: LeadingAgentData = yield select(leadingAgentAwardDataSelector);
  const isLeadingAgent = leadingAgentData.isLeadingAgent ? checkEnabledLeadingAgentFeature(leadingAgentData.award, LeadingAgentFeature.LeadingStatement) : false;

  let agentDetails;
  if (officePageData) {
    const officePhone = officePageData.virtualPhone;
    agentDetails = {
      office_id: officePageData.officeId,
      office_name: officePageData.officeName,
      phone_number: officePhone,
    };
    if (agentPageData && isAgent) {
      const agentPhone = agentPageData.virtualPhone || officePhone;
      agentDetails = {
        id: agentPageData.agentId,
        phone_number: officePageData.officeConnectionInAgentPage || !agentPhone ? officePhone : agentPhone,
        name: agentPageData.name,
        office_id: agentPageData.officeId,
        office_name: agentPageData.officeName,
        ...(isAgentPage ?  { is_leading_agent: isLeadingAgent } : {}),
      };
    }
  }

  const event = {
    source: isAgent ? 'agent_page' : 'office_page',
    agent_details: agentDetails || undefined,
    contact_details: {
      email: variables.email,
      phone: variables.phone,
    },
  };

  sendEvent('user_contact_me_form_submit', 'user', { event });

  const action: SetMutationResponseAction<MutationType.AgentOfficeContactPageSubmit> = yield take(contactSubmitMutationPattern);

  const { response: { data } } = action.meta;
  const leadId = data && data.contactV4 && data.contactV4.lead && data.contactV4.lead.leadId;

  if (leadId) {
    sendEvent('user_contact_me_form_submit_success', 'user', {
      event: {
        ...event,
        lead_id: leadId,
      },
    });
  }
}

export function* madadAgentOfficeContactWatcher() {
  yield takeEvery(SUBMIT_USER_CONTACT_AGENT, madadAgentOfficeContactWorker);
}
