import styled from '@emotion/styled';
import { H3 } from 'ds/components/typography';
import { colorToAlpha } from 'helpers/insight';

export const SuccessPopupContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => colorToAlpha(theme.colors.brand.main, 0.07)};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  padding: ${({ theme }) => theme.spacing(3)};
`;


export const Title = styled(H3)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
