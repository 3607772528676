import {
  ILocalDataResponse,
  ISaveSearch,
  ISaveSearchFromState,
  LoadType,
  MutationType,
} from 'store/sagas/apiService/types';
import { RootAction } from 'store/state';
import { RESET_SSR_HYDRATION, SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { DealType, IAutocompleteEntry, ISearchPoiVariables, MarketplaceType, User } from 'utils/entities';
import { all, call, select, take, race } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { waitForUserChange, waitForUserResolve, makeTransitionPattern } from 'store/sagas/routing/handlers/utils';
import {
  isUserSelector,
  localDataInfoSelector,
  resolvedStrictLocalDocSelector, searchDocEntriesSelector,
  searchHistorySelector,
} from 'store/state/domainData/selectors';
import { addSaveSearchFromDocument } from 'store/state/selectors/savedSearchPage';
import { isSearchEqual } from 'utils/saveSearch';
import { TrackJS } from 'trackjs';
import { mutateWorker } from 'store/sagas/apiService/mutationsWatcher';
import { mutate } from 'store/state/mutationsResponse/actions';
import { routeSelector } from 'store/state/selectors/router';
import { MulticastChannel } from 'redux-saga';
import { marketplaceSelector } from 'store/state/selectors/router';


export function* saveRecentSearch(search: ISaveSearchFromState) {
  yield call(waitForUserResolve);
  const isUser: boolean = yield select(isUserSelector);

  if (!isUser) {
    yield call(waitForUserChange);
  }

  const currentRecentSearchesList: ISaveSearch[] = yield select(searchHistorySelector);
  const isNotDuplicate = currentRecentSearchesList.find((s) => isSearchEqual(search, s)) === undefined;

  if (!search.query.dealType) {
    TrackJS.track('Save recent search - Deal type null');
    return;
  }
  if (!search.title) {
    TrackJS.track('Save recent search - title is null');
    return;
  }

  if (isNotDuplicate) {
    yield call(mutateWorker, mutate({
      mutationType: MutationType.SaveRecentSearch,
      meta: {
        variables: { search },
      },
    }));
  }
}

const makeDomainLoadPattern = (loadType: LoadType) => (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === loadType && Boolean(action.payload.data))
  || (action.type === RESET_SSR_HYDRATION && action.payload.loadType === loadType)
);

const localDataLoadPattern = makeDomainLoadPattern(LoadType.LocalData);
const strictLocalDocLoadPattern = makeDomainLoadPattern(LoadType.StrictLocalDoc);

function userComparator(a: User, b: User) {
  return !(b && !b.searchHistory.length);
}


function* loadPage (route: Route, chnl: MulticastChannel<ISearchPoiVariables>) {
  yield take(makeTransitionPattern(route));
  let nextSearch: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
  while (!(nextSearch && nextSearch.length)) {
    yield take(chnl, '*');
    nextSearch = yield select(searchDocEntriesSelector);
  }
}

export function* saveLocalDataRecentSearch(chnl: MulticastChannel<ISearchPoiVariables>) {
  yield all([
    call(waitForUserChange, userComparator),
    race([
      call(loadPage, Route.UnitPage, chnl),
      call(loadPage, Route.ProjectPage, chnl),
      all([
        race([
          take(makeTransitionPattern(Route.AddressPage)),
          take(makeTransitionPattern(Route.StreetPage)),
        ]),
        take(localDataLoadPattern),
      ]),
      all([
        take(makeTransitionPattern(Route.LocalPage)),
        take(strictLocalDocLoadPattern),
      ]),
    ]),
  ]);

  const { name: routeName, params }: RouteState = yield select(routeSelector);
  const dealType = params.dealType || DealType.Buy;

  let document: IAutocompleteEntry;

  switch (routeName) {
    case Route.ProjectPage:
    case Route.UnitPage:
      [ document ] = yield select(searchDocEntriesSelector);
      break;
    case Route.AddressPage:
    case Route.StreetPage:
      const localData: ILocalDataResponse = yield select(localDataInfoSelector);
      document = localData.document;
      break;
    case Route.LocalPage:
      document = yield select(resolvedStrictLocalDocSelector);
      break;
    default:
      break;
  }
  const currentRecentSearchesList: ISaveSearch[] = yield select(searchHistorySelector);
  const marketplace: MarketplaceType = yield select(marketplaceSelector);
  if (document && (currentRecentSearchesList || []).length === 0) {
    yield call(saveRecentSearch, addSaveSearchFromDocument(document, dealType, marketplace));
  }
}
