import { NavigationOptions } from 'store/state/router/actionPayloads';
import { createStandardAction, createAction } from 'typesafe-actions';
import {
  NAVIGATE_TO,
  CANCEL_TRANSITION,
  CLEAR_ERRORS,
  TRANSITION_START,
  TRANSITION_SUCCESS,
  TRANSITION_ERROR,
  CAN_ACTIVATE,
  CAN_DEACTIVATE,
} from './types';
import {
  TransitionStartPayload,
  TransitionSuccessPayload,
  TransitionErrorPayload,
  CanActivatePayload,
  CanDeactivatePayload,
} from './actionPayloads';


export const navigateTo = createAction(NAVIGATE_TO, (actionCallback) => {
  return (name: string, params?: Record<string, any>, opts?: NavigationOptions) => (
    actionCallback({ name, params, opts: opts || {} })
  );
});
export const cancelTransition = createStandardAction(CANCEL_TRANSITION)();
export const clearErrors = createStandardAction(CLEAR_ERRORS)();
export const transitionStart = createStandardAction(TRANSITION_START)<TransitionStartPayload>();
export const transitionSuccess = createStandardAction(TRANSITION_SUCCESS)<TransitionSuccessPayload>();
export const transitionError = createStandardAction(TRANSITION_ERROR)<TransitionErrorPayload>();
export const canActivate = createStandardAction(CAN_ACTIVATE)<CanActivatePayload>();
export const canDeactivate = createStandardAction(CAN_DEACTIVATE)<CanDeactivatePayload>();
