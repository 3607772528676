import { ITranslate } from 'locale';
import { isPhoneNumberValid } from 'utils/formValidationHelpers';
import { IContactAgentValues } from 'helpers/contactFormStore';


export const createValidationFn = (t: ITranslate) => (values: IContactAgentValues) => {
  const { phone } = values;
  const errors: Partial<IContactAgentValues> = {};

  if (!phone) {
    errors.phone = t('contactAgentModal.form.phone.required');
  }
  else if (!isPhoneNumberValid(phone)) {
    errors.phone = t('contactAgentModal.form.phone.invalid');
  }

  return errors;
};


interface ErrorOption {
  invalid: string;
  required: string;
}

interface MortgageErrorsText {
  phone: Partial<ErrorOption>;
  message: Partial<ErrorOption>;
}

interface MortgageFields {
  phone: string;
  message: string;
}

export const createMortgageValidationFn = (errorsText: MortgageErrorsText) => (values: Partial<MortgageFields>) => {
  const { phone, message } = values;
  const errors: Partial<MortgageFields> = {};

  if (!phone && errorsText.phone.required) {
    errors.phone = errorsText.phone.required;
  }
  else if (!isPhoneNumberValid(phone) && errorsText.phone.invalid) {
    errors.phone = errorsText.phone.invalid;
  }

  if (!message && errorsText.message.required) {
    errors.message = errorsText.message.required;
  }

  return errors;
};
