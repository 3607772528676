import { createSelector } from 'reselect';
import { processImgs } from 'utils/gallery';
import {
  PoiId,
  PromotionValues,
  PocType,
  IThumbnail,
  ITag,
  IBulletin,
  IProject,
  ICommercialBulletin,
  ICommercialProject,
} from 'utils/entities';
import { State } from 'store/state';
import { activePoiIdsSelector } from 'store/state/searchContext/selectors';
import { SortValue } from 'components/listing-sort/types';
import { config as sortConfig } from 'components/listing-sort/config';
import { UniversalCardProps } from './UniversalCard/UniversalCard';
import { userCommuteTypeSelector } from 'store/state/domainData/selectors';
import { getPOCLogo, getProjectOrDeveloperLogoPath } from 'helpers/contact';
import { isSearchInWholeCountrySelector } from 'store/state/selectors/router';
import { isBulletinPoiType, isProjectPoiType, isBulletin, isCommercialBulletin, isCommercialProject } from 'utils/marketplaceRoutes';
import { CardType } from 'components/cards/types';

type SortParamsGetter = (state: State, id: PoiId, tagsSortParams?: SortValue[]) => SortValue[];
export type TagsSelectorFactoryCreator = (sortParamsGetter: SortParamsGetter) => TagsSelectorFactory;
export type TagsSelectorFactory = (poiGetter: PoiGetter) => (state: State, id: PoiId, tagsSortParams?: SortValue[]) => ITag[];
export type PoiGetter = (state: State, id: PoiId) => IBulletin | IProject | ICommercialBulletin | ICommercialProject;


export const makePoiTagsSelector: TagsSelectorFactoryCreator = (sortParamsGetter) => (poiGetter) => createSelector([
  poiGetter,
  sortParamsGetter,
  userCommuteTypeSelector,
], (poi, sortParams = [], commuteType): ITag[] => {
  if (isProjectPoiType(poi) || isBulletinPoiType(poi)) {
    return sortConfig.getTags(poi, sortParams.map(([ f ]) => f), commuteType);
  }
  return [];
});

const makePoiImagesSelector = (poiGetter: PoiGetter) =>
  createSelector(
    [ poiGetter ],
    (poi): IThumbnail[] => {
      if (isBulletinPoiType(poi)) {
        return processImgs(poi.images);
      }

      let images: IThumbnail[] = poi.images.filter(i => !(poi.previewImage && i.path === poi.previewImage.path)).map(i => ({
        url: i.path,
        isFloorplan: null,
      }));

      if (poi.previewImage && poi.previewImage.path) {
        const previewImage: IThumbnail = {
          url: poi.previewImage.path,
          isFloorPlan: null,
        };

        images = [ previewImage, ...images ];
      }

      return images;
    }
  );

interface UniversalCardOwnProps {
  id: PoiId;
  onlyOneImageShouldBeDisplayed?: boolean;
  showAddToFavourites?: boolean;
  tagsSortParams?: SortValue[];
  emptyLazyLoadPlaceholder?: boolean;
  disableSwipe?: boolean;
  withPaddingBottom?: boolean;
  fixedBottomControl?: boolean;
  notificationTags?: string[];
}

export const makeMapStateToPropsFactory = (
  poiByIdSelector: PoiGetter
) => () => {
  const thumbnailsByIdSelector = makePoiImagesSelector(poiByIdSelector);

  return (state: State, props: UniversalCardOwnProps): UniversalCardProps => {
    const poi = poiByIdSelector(state, props.id);
    const activePoiIds = activePoiIdsSelector(state);
    const isSearchInWholeCountry = isSearchInWholeCountrySelector(state);

    if (isBulletin(poi)) {
      return {
        thumbnails: thumbnailsByIdSelector(state, props.id),
        isActive: activePoiIds.has(poi.id),
        cardType: CardType.Bulletin,
        isExclusive: Boolean(poi.poc && poi.poc.type === PocType.Agent && poi.poc.exclusivity && poi.poc.exclusivity.exclusive),
        price: poi.price,
        date: poi.firstTimeSeen,
        lastUpdatedDate: poi.lastUpdated,
        isSearchInWholeCountry,
        bedsCount: poi.beds,
        floor: poi.floor,
        size: poi.area,
        buildingClass: poi.buildingClass,
        type: poi.type,
        logoPath: getPOCLogo(poi.poc),
        officeId: (poi.poc && poi.poc.type === PocType.Agent && poi.poc.officeId) || null,
        dealType: poi.dealType,
        resolutionPreferences: poi.addressDetails.resolutionPreferences,
        streetNumber: poi.addressDetails.streetNumber,
        streetName: poi.addressDetails.streetName,
        unitNumber: poi.addressDetails.unitNumber || null,
        neighbourhood: poi.addressDetails.neighbourhood,
        city: poi.addressDetails.city,
        isAgent: poi.poc && poi.poc.type === PocType.Agent,
        eventsHistory: poi.eventsHistory,
        locationPoint: poi.locationPoint,
        altText: poi.address,
        hasVirtualTour: poi.virtualTours && poi.virtualTours.hasVirtualTour,
        madadSearchResult: poi.poc && poi.poc.type === PocType.Agent && poi.poc.madadSearchResult || null,
        generalCondition: poi.generalCondition,
        area: poi.area,
        id: poi.id,
      };
    }

    if (isCommercialBulletin(poi)) {
      return {
        thumbnails: thumbnailsByIdSelector(state, props.id),
        isActive: activePoiIds.has(poi.id),
        cardType: CardType.CommercialBulletin,
        isExclusive: Boolean(poi.poc && poi.poc.type === PocType.Agent && poi.poc.exclusivity && poi.poc.exclusivity.exclusive),
        price: poi.price,
        date: poi.firstTimeSeen,
        lastUpdatedDate: poi.lastUpdated,
        bedsCount: poi.rooms,
        floor: poi.floor,
        isSearchInWholeCountry,
        size: poi.area,
        buildingClass: poi.buildingClass,
        type: poi.type,
        logoPath: getPOCLogo(poi.poc),
        officeId: (poi.poc && poi.poc.type === PocType.Agent && poi.poc.officeId) || null,
        resolutionPreferences: poi.addressDetails.resolutionPreferences,
        streetNumber: poi.addressDetails.streetNumber,
        streetName: poi.addressDetails.streetName,
        unitNumber: poi.addressDetails.unitNumber || null,
        neighbourhood: poi.addressDetails.neighbourhood,
        city: poi.addressDetails.city,
        isAgent: poi.poc && poi.poc.type === PocType.Agent,
        eventsHistory: poi.eventsHistory,
        locationPoint: poi.locationPoint,
        altText: poi.address,
        dealType: poi.dealType,
        hasVirtualTour: poi.virtualTours && poi.virtualTours.hasVirtualTour,
        madadSearchResult: poi.poc && poi.poc.type === PocType.Agent && poi.poc.madadSearchResult || null,
        generalCondition: poi.generalCondition,
        area: poi.area,
        numberOfEmployees: poi.numberOfEmployees,
        qualityClass: poi.qualityClass,
        id: poi.id,
      };
    }

    // TODO remove that after BE resolve commercialProject poi type
    if (poi.isCommercial || isCommercialProject(poi)) {
      return {
        logoPath: getProjectOrDeveloperLogoPath(poi),
        developerId: (poi.developers && poi.developers.length > 0 && poi.developers[0].id) || null,
        cardType: CardType.CommercialProject,
        projectName: poi.projectName,
        priceRange: poi.priceRange,
        floorRange: poi.blockDetails.floorRange,
        streetNumber: poi.addressDetails.streetNumber,
        streetName: poi.addressDetails.streetName,
        city: poi.addressDetails.city,
        neighbourhood: poi.addressDetails.neighbourhood,
        type: poi.type,
        date: poi.firstTimeSeen,
        isActive: activePoiIds.has(poi.id),
        isPromoted: poi.promotionStatus.status === PromotionValues.Promoted,
        thumbnails: thumbnailsByIdSelector(state, props.id),
        locationPoint: poi.locationPoint,
        altText: poi.projectName,
        apartmentType: poi.apartmentType,
        dealType: poi.dealType,
        hasDiscount: !!(poi.discount && poi.discount.showDiscount),
        id: poi.id,
      };
    }

    return {
      logoPath: getProjectOrDeveloperLogoPath(poi),
      developerId: (poi.developers && poi.developers.length > 0 && poi.developers[0].id) || null,
      cardType: CardType.Project,
      bedsRange: poi.bedsRange,
      projectName: poi.projectName,
      priceRange: poi.priceRange,
      floorRange: poi.blockDetails.floorRange,
      streetNumber: poi.addressDetails.streetNumber,
      streetName: poi.addressDetails.streetName,
      city: poi.addressDetails.city,
      neighbourhood: poi.addressDetails.neighbourhood,
      type: poi.type,
      date: poi.firstTimeSeen,
      isActive: activePoiIds.has(poi.id),
      isPromoted: poi.promotionStatus.status === PromotionValues.Promoted,
      thumbnails: thumbnailsByIdSelector(state, props.id),
      locationPoint: poi.locationPoint,
      altText: poi.projectName,
      dealType: poi.dealType,
      hasDiscount: !!(poi.discount && poi.discount.showDiscount),
      id: poi.id,
    };
  };
};
