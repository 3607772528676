import { DetailPageEventCategory } from 'analytics/handlers/detailPageSectionHandlers';
import ScrollableSectionsNavigation, {
  ScrollableSectionsNavigationProps,
} from 'components/scrollable-sections-navigation';
import withEvents from 'analytics/withEvents';


const SectionsNavigationInnerWithEvents = withEvents<ScrollableSectionsNavigationProps>((sendEvent, { eventCategory }) => ({
  onItemClickNotify(section: string) {
    const name = eventCategory === DetailPageEventCategory.LocalPage
      ? 'local_page_top_menu_click'
      : `${eventCategory}_view_top_menu_click`;
    sendEvent(name, eventCategory, {
      event: { section },
    });
  },
}))(ScrollableSectionsNavigation);

export default SectionsNavigationInnerWithEvents;
