import { fork } from 'redux-saga/effects';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';


export function* savedSearchesPageHandler() {

  const loadOptions: LoadOptions<LoadType.SavedSearchesNotifications> = {
    loadType: LoadType.SavedSearchesNotifications,
    meta: {
      variables: {},
    },
  };
  yield fork(queryData, loadOptions);

}
