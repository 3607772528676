import { css } from '@emotion/core';

export default css`
  #overlay-root > div:not(:last-of-type) {
    display: none;
  }
  .data-layer {
    z-index: 2;
  }
  #g_a11y_announcement {
    left: -10000px;
    right: auto;
  }
`;
