import { all, call, fork, getContext, put, select } from 'redux-saga/effects';
import { createSelector, OutputSelector } from 'reselect';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { State as RouteState } from 'config/routes';
import { queryData } from 'store/sagas/apiService';
import { FetchInsightsParams, loadInsights } from '../loadInsights';
import {
  resolvedStrictUnitDocSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
  userCommuteWithPrioritiesSelector,
} from 'store/state/domainData/selectors';
import { flow, head } from 'lodash';
import * as actions from 'store/state/router/actions';
import {
  IBulletin,
  ICommercialBulletin,
  IPoiUserData,
  ISearchPoiVariables,
  SearchContext,
  MarketplaceType,
  PocType,
  RealEstateLevel,
} from 'utils/entities';
import { queryAdditionalAds, queryProjectsAds, waitForUserResolve, fetchPostLeadCarousel } from '../utils';
import { makeSearchVariablesSelector } from 'store/state/app/selectors';
import { getTileRange } from 'utils/geo';
import { setShareModalOpen } from 'store/state/app/actions';
import { ShareModalType } from 'components/share-modal';
import { tabuWatcher } from 'store/sagas/tabuWatcher';
import { State } from 'store/state';
import { deleteListingWatcher } from './deleteListingWatcher';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { StateMeta } from 'router5';
import { marketplaceSelector, prevRouteSelector } from 'store/state/selectors/router';
import { homeRouteByMarketplace } from 'utils/marketplaceRoutes';
import { Seller } from 'components/filters/types';
import { collapsedSectionsWithDataSelector } from 'store/state/selectors/insights/insightSections';
import { collapseInsightSection } from 'store/state/insightsContext/actions';
import { mapAnalyticsToWidgetsName, unitPageWidgets, widgetNameToInsightSectionName } from 'utils/widgetsConfig';

function* fetchProjectOffices(listingsType: MarketplaceType, officeId?: string) {
  const loadOptions: LoadOptions<LoadType.GetOfficeListings> = {
    loadType: LoadType.GetOfficeListings,
    meta: {
      variables: { officeId, listingsType, limit: 11, _cachePreventor: new Date().getTime() },
    },
  };
  if (officeId) {
    yield call(queryData, loadOptions);
  }
}

const insightParamsCombiner = (bulletin: IBulletin | ICommercialBulletin): FetchInsightsParams => {
  if (!bulletin) return null;

  const { docId } = bulletin.addressDetails;

  return {
    docId,
    user: null,
    location: [ bulletin.locationPoint.lng, bulletin.locationPoint.lat ],
  };
};

const localDocIdSelector = (bulletin: IBulletin | ICommercialBulletin): string => {
  if (!bulletin) return null;
  return bulletin.addressDetails.neighbourhoodDocId ? bulletin.addressDetails.neighbourhoodDocId : bulletin.addressDetails.cityDocId;
};

const byAddressVariablesSelector = (bulletin: IBulletin | ICommercialBulletin): ISearchPoiVariables => {
  const { lng, lat } = bulletin.locationPoint;
  const tileRange = getTileRange(lng, lat);

  return {
    dealType: null,
    tileRanges: [ tileRange ],
    poiTypes: [ bulletin.type === 'commercialBulletin' ? 'commercialBulletin' : 'bulletin', 'project' ],
  };
};

const byListingVariablesSelector = (bulletin: IBulletin | ICommercialBulletin): ISearchPoiVariables => {
  const { dealType, type } = bulletin;
  const isCommercial = type === 'commercialBulletin';
  const rooms = bulletin.type === 'commercialBulletin' ? null : bulletin.beds;
  const locationDocId = localDocIdSelector(bulletin);

  return {
    tileRanges: null,
    dealType,
    locationDocId,
    sellerType: [ Seller.Agent ],
    ...(isCommercial ? null : { roomsRange: [ Math.floor(rooms), Math.ceil(rooms) ] }),
    poiTypes: [ type ],
    limit: 50,
    isCommercialRealEstate: isCommercial,
  };
};

const residentialInsightsVariablesSelector = createSelector([ unitPageBulletinSelector ], insightParamsCombiner);
const commercialInsightsVariablesSelector = createSelector([ unitPageCommercialBulletinSelector ], insightParamsCombiner);

const residentialBulletinLocalDocIdSelector = flow(unitPageBulletinSelector, localDocIdSelector);
const commercialBulletinLocalDocIdSelector = flow(unitPageCommercialBulletinSelector, localDocIdSelector);

const hasResidentialBulletinSelector = flow(unitPageBulletinSelector, Boolean);
const hasCommercialBulletinSelector = flow(unitPageCommercialBulletinSelector, Boolean);

const isPrivateResidentialBulletinSelector = flow(unitPageBulletinSelector, (l => l && l.poc && l.poc.type === PocType.Private));
const isPrivateCommercialBulletinSelector = flow(unitPageCommercialBulletinSelector, (l => l && l.poc && l.poc.type === PocType.Private));

const isAgentResidentialBulletinSelector = flow(unitPageBulletinSelector, (l => l && l.poc && l.poc.type === PocType.Agent));
const isAgentCommercialBulletinSelector = flow(unitPageCommercialBulletinSelector, (l => l && l.poc && l.poc.type === PocType.Agent));

const searchResidentialByAddressVariablesSelector = flow(unitPageBulletinSelector, byAddressVariablesSelector);
const searchCommercialByAddressVariablesSelector = flow(unitPageCommercialBulletinSelector, byAddressVariablesSelector);
const searchResidentialListingsVariablesSelector = flow(unitPageBulletinSelector, byListingVariablesSelector);
const searchCommercialListingsVariablesSelector = flow(unitPageCommercialBulletinSelector, byListingVariablesSelector);

interface UnitPageHandlerFactoryOptions {
  makeLoadOptions: (userData: IPoiUserData, id: string, meta?: StateMeta & NavigationOptions) => LoadOptions<LoadType.UnitPageBulletin | LoadType.UnitPageCommercialBulletin>;
  bulletinExistsSelector: (a1: State) => boolean;
  searchByAddressVariablesSelector: (a1: State) => ISearchPoiVariables;
  insightsVariablesSelector: OutputSelector<State, FetchInsightsParams, (res: IBulletin | ICommercialBulletin) => FetchInsightsParams>;
  bulletinLocalDocIdSelector: (a1: State) => string;
  adsContext: SearchContext;
  marketplaceType: MarketplaceType;
  isPrivateListingSelector: (a1: State) => boolean;
  isAgentBulletinSelector: (a1: State) => boolean;
  searchAdditionalListingsVariablesSelector: (a1: State) => ISearchPoiVariables;
  currentBulletinSelector: (a1: State) => IBulletin | ICommercialBulletin;
}

function SSRunitPageHandlerFactory(options: UnitPageHandlerFactoryOptions) {
  const {
    bulletinExistsSelector,
    searchByAddressVariablesSelector,
    insightsVariablesSelector,
    bulletinLocalDocIdSelector,
    makeLoadOptions,
    adsContext,
    marketplaceType,
    isPrivateListingSelector,
    isAgentBulletinSelector,
    searchAdditionalListingsVariablesSelector,
    currentBulletinSelector,
  } = options;

  return function* (params: RouteState) {
    const { id } = params.params;
    if (!id) return;

    yield call(waitForUserResolve);
    const userData: IPoiUserData = yield select(userCommuteWithPrioritiesSelector);
    const loadOptions = makeLoadOptions(userData, id, params.meta);
    yield call(queryData, loadOptions);
    const bulletinExists = yield select(bulletinExistsSelector);

    if (!bulletinExists) {
      const prevRoute = yield select(prevRouteSelector);

      const logger = yield getContext('logger');
      logger.error('Unit page bulletin did not exist, redirecting Home.');
      yield put(actions.navigateTo(homeRouteByMarketplace(marketplaceType), { hiddenListingModal: Boolean(prevRoute) ? undefined : 1 }, { replace: true, ssrHttpStatus: 302 }));
      return;
    }

    const currentBulletin = yield select(currentBulletinSelector);
    const isAgentBulletin = yield select(isAgentBulletinSelector);

    if (isAgentBulletin) {
      yield call(queryData, { loadType: LoadType.AgentWithBulletinsById, meta: { variables: { id: currentBulletin.poc.agentId } } });

      yield fork(queryData, { loadType: LoadType.GetUnitPageLeadingData, meta: { variables: { args: { ids: [ currentBulletin.poc.agentId ] } } } });
      yield fork(queryData, { loadType: LoadType.OfficeDataById, meta: { variables: { id: currentBulletin.poc.officeId, level: RealEstateLevel.Public } } });
      yield fork(fetchProjectOffices, marketplaceType, currentBulletin.poc.officeId);
    }

    if (params.params.shareBulletin) {
      yield put(setShareModalOpen(ShareModalType.UploadBulletinShareModal));
    }

    const searchByAddressVariables = yield select(searchByAddressVariablesSelector);

    const searchByAddressOpts: LoadOptions<LoadType.AddressSearch> = {
      loadType: LoadType.AddressSearch,
      meta: {
        variables: searchByAddressVariables,
      },
    };
    yield fork(queryData, searchByAddressOpts);

    const insightsVariables: FetchInsightsParams = yield select(insightsVariablesSelector);

    const isPrivateListing = yield select(isPrivateListingSelector);
    if (isPrivateListing) {
      const variables = yield select(searchAdditionalListingsVariablesSelector);
      const searchAdditionalListings: LoadOptions<LoadType.AdditionalCarouselSearch> = {
        loadType: LoadType.AdditionalCarouselSearch,
        meta: {
          variables,
        },
      };
      yield fork(queryData, searchAdditionalListings);
    }

    if (insightsVariables) {
      yield fork(loadInsights, insightsVariables);
      const { docId } = insightsVariables;
      yield all([
        fork(queryData, {
          loadType: LoadType.UserContentTextReviews,
          meta: { variables: { docId } },
        }),
        fork(queryData, {
          loadType: LoadType.UserContentRatingReviews,
          meta: { variables: { docId } },
        }),
      ]);
    }

    const localDocId = yield select(bulletinLocalDocIdSelector);
    const localLoadOpts: LoadOptions<LoadType.LocalDocPreview> = {
      loadType: LoadType.LocalDocPreview,
      meta: { variables: { docId: localDocId } },
    };

    yield fork(queryProjectsAds, localDocId, adsContext);
    yield fork(queryAdditionalAds, localDocId, 'listing');
    yield fork(queryData, localLoadOpts);
    yield fork(fetchPostLeadCarousel, currentBulletin, searchAdditionalListingsVariablesSelector);

    yield call(queryData, {
      loadType: LoadType.StrictUnitDoc,
      meta: { variables: { docId: localDocId } },
    });

    const searchProjectListVariables: ISearchPoiVariables = yield select(makeSearchVariablesSelector(resolvedStrictUnitDocSelector));
    const projectLoadOptions: LoadOptions<LoadType.SearchProjectList> = {
      loadType: LoadType.SearchProjectList,
      meta: { variables: searchProjectListVariables },
    };
    yield call(queryData, projectLoadOptions);

    let insightCollapsedSectionsWithData: string[] = yield select(collapsedSectionsWithDataSelector);
    const marketplace = yield select(marketplaceSelector);

    if (insightCollapsedSectionsWithData) {
      if (marketplace === MarketplaceType.Commercial) {
        insightCollapsedSectionsWithData = insightCollapsedSectionsWithData.filter(el => el !== 'prices');
      }

      if (insightCollapsedSectionsWithData.length && insightCollapsedSectionsWithData.length <= 3) {
        const unitPageWidgetsWithDataNames = insightCollapsedSectionsWithData.map(mapAnalyticsToWidgetsName);
        const orderedUnitPageWidgetsWithDataNames = unitPageWidgets.filter((el: string) => unitPageWidgetsWithDataNames.includes(el));
        yield put(collapseInsightSection(widgetNameToInsightSectionName(head(orderedUnitPageWidgetsWithDataNames))));
      }
    }
  };
}

export const commercialSSRUnitPageHandler = SSRunitPageHandlerFactory({
  makeLoadOptions: (userData, id, meta) => ({
    loadType: LoadType.UnitPageCommercialBulletin,
    meta: {
      variables: {
        ids: [
          { type: 'commercialBulletin', id },
        ],
        userData,
        includeFrozen: meta && meta.options && meta.options.includeFrozen || undefined,
      },
    },
  }),
  bulletinExistsSelector: hasCommercialBulletinSelector,
  searchByAddressVariablesSelector: searchCommercialByAddressVariablesSelector,
  insightsVariablesSelector: commercialInsightsVariablesSelector,
  bulletinLocalDocIdSelector: commercialBulletinLocalDocIdSelector,
  adsContext: 'commercialMarketplace',
  marketplaceType: MarketplaceType.Commercial,
  isPrivateListingSelector: isPrivateCommercialBulletinSelector,
  isAgentBulletinSelector: isAgentCommercialBulletinSelector,
  searchAdditionalListingsVariablesSelector: searchCommercialListingsVariablesSelector,
  currentBulletinSelector: unitPageCommercialBulletinSelector,
});

export const residentialSSRUnitPageHandler = SSRunitPageHandlerFactory({
  makeLoadOptions: (userData, id, meta) => ({
    loadType: LoadType.UnitPageBulletin,
    meta: {
      variables: {
        ids: [
          { type: 'bulletin', id },
        ],
        userData,
        includeFrozen: meta && meta.options && meta.options.includeFrozen || undefined,
      },
    },
  }),
  bulletinExistsSelector: hasResidentialBulletinSelector,
  searchByAddressVariablesSelector: searchResidentialByAddressVariablesSelector,
  insightsVariablesSelector: residentialInsightsVariablesSelector,
  bulletinLocalDocIdSelector: residentialBulletinLocalDocIdSelector,
  adsContext: 'marketplace',
  marketplaceType: MarketplaceType.Residential,
  isPrivateListingSelector: isPrivateResidentialBulletinSelector,
  isAgentBulletinSelector: isAgentResidentialBulletinSelector,
  searchAdditionalListingsVariablesSelector: searchResidentialListingsVariablesSelector,
  currentBulletinSelector: unitPageBulletinSelector,
});

export function* residentialUnitPageHandler(params: RouteState) {
  yield fork(residentialSSRUnitPageHandler, params);
  yield fork(tabuWatcher);
  yield fork(deleteListingWatcher);
}

export function* commercialUnitPageHandler(params: RouteState) {
  yield fork(commercialSSRUnitPageHandler, params);
  yield fork(tabuWatcher);
  yield fork(deleteListingWatcher);
}
