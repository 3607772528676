import React from 'react';
import { useForm } from 'react-final-form-hooks';
import { AuthenticationRoot, FormControlsWrapper } from './styled';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { useLocale } from 'locale';
import { AuthenticationField } from './types';

interface AuthenticationEmailFormProps {
  email: string;
  submitText: string;
  isEmailValid: boolean;
  dirtyFields: Set<AuthenticationField>;
  smallWidthSubmitButton?: boolean;
  onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onSetDirty: (field: AuthenticationField) => void;
  onSubmit: () => void;
}

export const AuthenticationEmailForm: React.FC<AuthenticationEmailFormProps> = (props) => {
  const {
    email,
    submitText,
    isEmailValid,
    dirtyFields,
    smallWidthSubmitButton,
    onEmailChange,
    onSetDirty,
    onSubmit,
  } = props;

  const { t } = useLocale();
  const { handleSubmit } = useForm({ onSubmit });

  return (
    <AuthenticationRoot>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          data-auto="signin-email-field"
          type="email"
          fullWidth
          onChange={onEmailChange}
          value={email}
          placeholder={t('authentication.emailPlaceholder')}
          hasError={!isEmailValid && dirtyFields.has('email')}
          errorMessage={t('authentication.emailInvalidMessage')}
          onBlur={() => onSetDirty('email')}
        />
        <FormControlsWrapper smallWidth={smallWidthSubmitButton}>
          <Button
            data-auto="authentication-submit-button"
            fullWidth
            size="large"
          >
            {submitText}
          </Button>
        </FormControlsWrapper>
      </form>
    </AuthenticationRoot>
  );
};
