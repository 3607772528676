import { getContext, select, call, take } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { getRouteParams } from 'store/state/selectors/router';
import { officePageDataSelector } from 'store/state/domainData/selectors/officeById';
import { IOfficeAgentDisplayLevel, IOfficePageData } from 'utils/entities';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType } from '../apiService/types';


const officePagePattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.OfficePage
);

export const setOfficePattern = (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.OfficeById)
);

function* waitForData() {
  const officeData: IOfficePageData = yield select(officePageDataSelector);
  const { id } = yield select(getRouteParams);

  if (!officeData || officeData.officeId !== id) {
    yield take(setOfficePattern);
  }
}

export function* officePageWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {

    yield take(officePagePattern);
    yield call(waitForData);

    const officeData: IOfficePageData = yield select(officePageDataSelector);
    const { source: paramsSource } = yield select(getRouteParams);
    const isPromoted = officeData.displayLevel === IOfficeAgentDisplayLevel.Premium;

    const eventData = {
      office_details: {
        id: officeData.officeId,
        name: officeData.officeName,
        is_free: !isPromoted,
      },
    };

    yield call(sendEvent, 'office_page_view', 'office', {
      ...eventData,
      event: {
        source: paramsSource || 'direct',
        display_type: isPromoted ? 'full' : 'basic',
      },
    });
  }
}
