import styled from '@emotion/styled';
import { breakpointToContentSlotPadding } from 'ds/components/Layout';
import { BreakpointValue } from 'consts/breakpoints';

interface ISlideBtn {
  dir: 'prev' | 'next';
}

export const Wrapper = styled.div `
  position: relative;
`;

export const SlideBtn = styled.div<ISlideBtn>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  cursor: pointer;
  top: calc(50% - 16px);
  background: ${({ theme }) => theme.colors.neutrals.white};
  box-shadow: ${({ theme }) => theme.shadow.level3};

  > svg { transform: ${(props) => props.dir === 'next'
    ? props.theme.isRTL ? '' : 'rotate(180deg)'
    : props.theme.isRTL ? 'rotate(180deg)' : ''}}

  ${({ theme, dir }) => Object.keys(breakpointToContentSlotPadding).map(key => {
    const padding = breakpointToContentSlotPadding[key];
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
      ${dir === 'next'
        ? theme.isRTL ? 'left' : 'right'
        : theme.isRTL ? 'right' : 'left'
      }: ${theme.spacing(padding * 0.5 as any)};
    `;
  })}
`;

