import { useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { isServer } from 'utils';
import { useGlobalEventListener } from './useGlobalEventListener';
import { useRoute } from 'config/routes';

export enum ScrollDirection {
  Up = 'UP',
  Down = 'DOWN',
}

const DEFAULT_DEBOUNCE_TIME = 100;
const DEBOUNCE_OPTIONS = { leading: false };
export const MIN_TOP_OFFSET = 50;

const useScrollDirection = (debounceTime = DEFAULT_DEBOUNCE_TIME, topOffset = MIN_TOP_OFFSET) => {
  const touched = useRef(false);
  const lastScrollY = useRef(0);
  const isBlocked = useRef(false);
  const [ scrollDirection, setScrollDirection ] = useState<ScrollDirection>(null);
  const { route } = useRoute();

  useEffect(() => {
    setScrollDirection(null);
    isBlocked.current = true;
    setTimeout(() => {
      lastScrollY.current = window.scrollY;
      isBlocked.current = false;
    });
  }, [ route.name ]);

  const onMove = () => {
    if (isBlocked.current) return;

    const currentScroll = window.scrollY;

    if (lastScrollY.current < 0 || currentScroll < topOffset) {
      setScrollDirection(null);
    }

    else if (currentScroll > lastScrollY.current) {
      setScrollDirection(ScrollDirection.Down);
    }

    else if (currentScroll < lastScrollY.current) {
      setScrollDirection(ScrollDirection.Up);
    }

    lastScrollY.current = currentScroll;
  };

  const debouncedMove = useRef(debounce(onMove , debounceTime, DEBOUNCE_OPTIONS));
  const debouncedTouchMove = useRef(debounce(onMove , debounceTime, DEBOUNCE_OPTIONS));

  const debouncedStart = useRef(debounce(() => {
    touched.current = true;
  }, debounceTime, DEBOUNCE_OPTIONS));

  const debouncedEnd = useRef(debounce(() => {
    touched.current = false;
  }, debounceTime, DEBOUNCE_OPTIONS));

  const glob = isServer() ? null : window;

  useGlobalEventListener(glob, 'scroll', debouncedMove.current, { passive: true });

  useGlobalEventListener(glob, 'touchstart', debouncedStart.current, { passive: true });

  useGlobalEventListener(glob, 'touchmove', debouncedTouchMove.current, { passive: true });

  useGlobalEventListener(glob, 'touchend', debouncedEnd.current, { passive: true });

  return scrollDirection;
};

export default useScrollDirection;
