import { MutationType, MutationResponseByType } from 'store/sagas/apiService/types';
import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import { ApolloQueryResult } from 'apollo-client';
import { SET_MUTATION_RESPONSE, MUTATE, RESET_MUTATION_RESPONSE } from './types';

export type MutationsResponseAction = ActionType<typeof actions>;

export type MutationsResponseState = {
  [K in keyof MutationResponseByType]: {
    [index: string]: ApolloQueryResult<MutationResponseByType[K]>;
  }
};

const initialState: MutationsResponseState = Object.values(MutationType).reduce((acc, current) => {
  acc[current] = {};
  return acc;
}, {} as MutationsResponseState);

export default (state: MutationsResponseState = initialState, action: MutationsResponseAction): MutationsResponseState => {
  switch (action.type) {
    case MUTATE:
      return ({
        ...state,
        [action.mutationType]: {
          ...state[action.mutationType],
          [action.meta.key]: {
            ...(state[action.mutationType][action.meta.key] || {}),
            loading: true,
            meta: {
              variables: action.meta.variables,
            },
          },
        },
      });
    case RESET_MUTATION_RESPONSE: {
      const { mutationType, key } = action.payload;
      return ({
        ...state,
        [mutationType]: {
          ...state[mutationType],
          [key]: {},
        },
      });
    }
    case SET_MUTATION_RESPONSE:
      return ({
        ...state,
        [action.mutationType]: {
          ...state[action.mutationType],
          [action.meta.key]: {
            ...action.meta.response,
            meta: {
              variables: action.meta.variables,
            },
            loading: false,
          },
        },
      });
    default:
      return state;
  }
};
