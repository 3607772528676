import { searchListSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { flow } from 'lodash';
import { createSelector } from 'reselect';

const listingSearchListSelector = makeNullSafeDomainGetter(searchListSelector, 'searchPoiV2');
export const isLoadingListingSearchListSelector = createIsLoadingSelector(searchListSelector);
export const poiSearchListingListSelector = flow(listingSearchListSelector, (searchPoi) => searchPoi && searchPoi.poi);
export const searchBulletinsSelector = createSelector([
  poiSearchListingListSelector,
], (poi) => poi ? poi.filter(p => p.type === 'bulletin') : poi);
export const searchCommercialBulletinsSelector = createSelector([
  poiSearchListingListSelector,
], (poi) => poi ? poi.filter(p => p.type === 'commercialBulletin') : poi);
