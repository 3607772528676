import { IDocId2InsightsVariables, PoiId, UserInsightContextInfo, UserInsightContextBulletinInfo } from 'utils/entities';
import { all, put, call, cancel, select, getContext, fork, takeEvery, take } from 'redux-saga/effects';
import { initialize, setActiveInsight } from 'store/state/insightsContext/actions';
import { queryData } from 'store/sagas/apiService';
import { LoadType, LoadOptions } from 'store/sagas/apiService/types';
import { waitForUserResolve, waitForUserChange } from './utils';
import { getRouteParams, marketplaceSelector, routeSelector } from 'store/state/selectors/router';
import { flow, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { resetDomainData } from 'store/state/domainData/actions';
import { Task } from 'redux-saga';
import { isServer } from 'utils';
import { State as RouteState, DecodedRouteParams } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { SET_ACTIVE_INSIGHT } from 'store/state/insightsContext/types';
import { interestPointsSelector } from 'store/state/domainData/selectors';
import { RootAction } from 'store/state';
import { INIT } from 'store/state/domainData/types';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { isBot } from 'utils/bot';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';
import { IABTestContext } from 'config/abTests';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';


const insightsUserContextSelector = createSelector([
  interestPointsSelector,
  currentPoiSelector,
  flow(getRouteParams, ({ dealType }) => dealType),
], (interestPoints, currentPoi, routeDealType): UserInsightContextInfo => {

  const userContext: UserInsightContextInfo = {
    interestPoints,
  };
  const poiCtx: UserInsightContextBulletinInfo = {};

  if (currentPoi) {
    if (currentPoi.type === 'project') {
      poiCtx.id = currentPoi.id;
    }
    // POMPA
    // there could be case for 'deal', but not now
    else if (currentPoi.type === 'bulletin') {
      const unitNumber = currentPoi.addressDetails.unitNumber;
      const poiId: PoiId = currentPoi.id;
      const dealType = currentPoi.dealType || routeDealType;

      if (dealType) poiCtx.dealType = dealType;
      if (unitNumber) poiCtx.unit = unitNumber;
      if (poiId) poiCtx.id = poiId;
      if (currentPoi.exposures) poiCtx.exposures = currentPoi.exposures;
      if (currentPoi.floor) poiCtx.floor = currentPoi.floor;
    }
  }

  if (!isEmpty(poiCtx)) userContext[currentPoi.type] = poiCtx;

  return userContext;
});

function* setActiveWorker(action: ReturnType<typeof setActiveInsight>) {
  const route: RouteState = yield select(routeSelector);
  const params: DecodedRouteParams = {
    ...route.params,
    insightCollection: action.payload.insightId
      ? action.payload.collection || route.params.insightCollection
      : null,
    insightId: action.payload.insightId,
  };
  const shouldReplace = Boolean(route.params.insightId) || isUnitPageRoute(route.name);
  yield put(navigateTo(route.name, params, { replace: shouldReplace }));
}


export interface FetchInsightsParams extends IDocId2InsightsVariables {
  location?: [ number, number ];
}

export function* fetchInsights(variables: IDocId2InsightsVariables) {
  yield call(waitForUserResolve);
  const user = yield select(insightsUserContextSelector);
  const marketplace = yield select(marketplaceSelector);
  const abTests: IABTestContext = yield select(abTestsStateSelector);
  const isSEOMode: boolean = yield getContext('isSEOMode');
  const ssr = yield call(isServer);

  const bot = isSEOMode || (!ssr && isBot());

  try {
    const loadOpts: LoadOptions<LoadType.Insights> = {
      loadType: LoadType.Insights,
      meta: {
        variables: {
          ...variables,
          user: {
            ...user,
            abtests: abTests,
            marketplace,
            bot,
          },
        },
      },
    };

    yield call(queryData, loadOpts);
  }
  catch (e) {
    yield put(resetDomainData({ loadType: LoadType.Insights }));
    const logger = yield getContext('logger');
    logger.error('Insights response was not successful', e);
  }
}

const insightsDataInitializePattern = (action: RootAction) => (
  action.type === INIT && action.payload.loadType === LoadType.Insights
);

function* maybeInitialize() {
  yield take(insightsDataInitializePattern);
  yield put(initialize());
}

function* longRunningInsightsWatcher({ location, ...variables }: FetchInsightsParams) {
  yield takeEvery(SET_ACTIVE_INSIGHT, setActiveWorker);
  const tasks: Task[] = [];
  const lastRoute: RouteState = yield select(routeSelector);

  try {
    while (true) {
      tasks.push(
        yield fork(fetchInsights, { ...variables, withGeoData: true }),
        yield fork(maybeInitialize)
      );
      while (true) {
        yield call(waitForUserChange);
        yield all(tasks.map((task) => cancel(task)));
        break;
      }
    }
  }
  finally {
    yield all(tasks.map((task) => cancel(task)));
    const currentRoute: RouteState = yield select(routeSelector);

    if (currentRoute.name !== lastRoute.name) {
      yield put(initialize());
    }
  }
}

export function* loadInsights(variables: FetchInsightsParams) {
  const ssr = yield call(isServer);
  if (ssr) {
    yield call(fetchInsights, { ...variables, withGeoData: false });
  }
  else {
    yield fork(longRunningInsightsWatcher, variables);
  }
}
