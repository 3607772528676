import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import {
  isPhoneRegisteredLoadingSelector,
  isPhoneRegisteredSelector,
} from 'store/state/selectors/uploadPhoneValidation';
import { State } from 'store/state';
import { useLocale } from 'locale';
import { BasicButton } from 'ds/components/button/BasicButton';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { FormApi } from 'final-form';
import { IUploadBulletinFormValues } from 'utils/uploadBulletin';
import { validateUploadPhoneAction } from 'store/state/uploadBulletin/actions';
import ValidatePhoneModal from './ValidatePhoneModal';
import { NextButtonWrapper } from './styled';


interface ValidatePhoneButtonProps {
  isLoading: boolean;
  isRegistered: boolean;
  form: FormApi<Partial<IUploadBulletinFormValues>>;
  validatePhone: (phone: string) => void;
  goToNextStep: () => {};
}

const ValidatePhoneButton: React.FC<ValidatePhoneButtonProps> = (props) => {
  const { form, validatePhone, isLoading, isRegistered, goToNextStep } = props;
  const [ isValidating, setIsValidating ] = useState(false);
  const [ isModalShown, setIsModalShown ] = useState(false);

  const { t } = useLocale();
  const isDesktop = useScreenBreakpoint() > 2;

  const closeModal = () => setIsModalShown(false);
  const openModal = () => setIsModalShown(true);

  useEffect(() => {
    if (!isLoading && isValidating && !isNil(isRegistered)) {
      setIsValidating(false);

      if (isRegistered) {
        goToNextStep();
      }
      else {
        openModal();
      }
    }
  }, [ isLoading, isValidating, isRegistered ]);

  const onNextClick = async () => {
    if (!form) return;

    await form.submit();
    const formState = form.getState();
    const isValid = formState.valid;
    const phoneNumber = formState.values.userPhone;

    if (isValid) {
      validatePhone(phoneNumber);
      setIsValidating(true);
    }
  };

  return (
    <>
      <NextButtonWrapper isValidatePhone>
        <BasicButton size="large" onClick={onNextClick} fullWidth={isDesktop} disabled={isValidating}>
          {t('uploadBulletinPage.nextStepButtonLabel', { isValidatePhone: true })}
        </BasicButton>
      </NextButtonWrapper>

      {isModalShown ? (
        <ValidatePhoneModal
          closeModal={closeModal}
          phoneNumber={form.getState().values.userPhone}
          goToNextStep={goToNextStep}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  isLoading: isPhoneRegisteredLoadingSelector(state),
  isRegistered: isPhoneRegisteredSelector(state),
});

const mapDispatchToProps = {
  validatePhone: validateUploadPhoneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatePhoneButton);
