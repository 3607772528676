import React, { useMemo } from 'react';
import Select, { SelectItemProps } from 'ds/components/input/Select';
import { RangeFieldWrapper, RangeFieldDivider } from './styled';
import { RangeTuple } from 'components/filters/types';
import { last } from 'lodash';

interface RangeFieldProps {
  options: SelectItemProps[];
  values: number[];
  onChange: (value: RangeTuple<number>) => void;
  expandOptionsPosition?: 'top' | 'bottom';
  labelFrom?: string;
  labelTo?: string;
  divider?: React.ComponentType;
  readOnly?: boolean;
  fullWidth?: boolean;
  visibleItemsCount?: number;
  isMaxValueInfinite?: boolean;
}

const DEFAULT_EMPTY_VALUE = -1;

const RangeField: React.FC<RangeFieldProps> = ({
  options,
  values,
  onChange,
  divider = '-',
  labelFrom,
  labelTo,
  fullWidth = false,
  visibleItemsCount,
  expandOptionsPosition,
  isMaxValueInfinite,
}) => {
  const [ fromValue = DEFAULT_EMPTY_VALUE, toValue = DEFAULT_EMPTY_VALUE ] = values;

  const onFromChange = (val: number) => {
    if (val === DEFAULT_EMPTY_VALUE || val <= toValue || toValue === DEFAULT_EMPTY_VALUE) {
      onChange([ val, toValue ]);
    }
    else if (val > toValue) {
      onChange([ val, val ]);
    }
  };

  const onToChange = (val: number) => {
    if (val === DEFAULT_EMPTY_VALUE || val >= fromValue || fromValue === DEFAULT_EMPTY_VALUE) {
      onChange([ fromValue, val ]);
    }
    else if (val < fromValue) {
      onChange([ val, val ]);
    }
  };

  const toValueOptions = useMemo(() => {
    if (isMaxValueInfinite) {
      const lastOption = last(options);
      const formattedLastOption = { ...last(options), label: `${lastOption.label}+` };
      return options.slice(0, -1).concat(formattedLastOption);
    }

    return options;
  }, [ options, isMaxValueInfinite ]);

  return(
    <RangeFieldWrapper fullWidth={fullWidth}>
      <Select
        selectionPromptText={labelFrom}
        items={options}
        value={fromValue}
        onChange={onFromChange}
        size="large"
        visibleItemsCount={visibleItemsCount}
        verticalPosition={expandOptionsPosition}
      />
      <RangeFieldDivider>{divider}</RangeFieldDivider>
      <Select
        onChange={onToChange}
        items={toValueOptions}
        selectionPromptText={labelTo}
        value={toValue}
        size="large"
        visibleItemsCount={visibleItemsCount}
        verticalPosition={expandOptionsPosition}
      />
    </RangeFieldWrapper>
  );
};

export default React.memo(RangeField);
