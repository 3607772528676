import { createSelector } from 'reselect';
import { MadadDto, MadadAgentDto, MadadOfficeDto } from 'utils/entities';
import { MadadAgentCard, MadadAgencyCard } from 'components/madad/entities';
import { mapToTopTenBadgeVariant } from 'components/madad/utils';
import { State } from 'store/state';
import { LoadType } from 'store/sagas/apiService/types';
import { madadDomainSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { flow } from 'lodash';


const madadSelector = makeNullSafeDomainGetter(madadDomainSelector, LoadType.Madad);
export const isLoadingMadadSelector = createIsLoadingSelector(madadDomainSelector);

const EMPTY_MADAD_LIST: MadadDto[] = [];
const madadRecordsSelector = createSelector(madadSelector, records => records || EMPTY_MADAD_LIST);

const isWinner = (category?: MadadDto['category']) => category === 'top_10' || category === 'top_10_gold';
const isAgent = (role: MadadDto['role']): role is 'agent' => role === 'agent';
const isAgency = (role: MadadDto['role']): role is 'office' => role === 'office';
const isWinnerInCity = (record: MadadDto) => isWinner(record.category) && record.docId === record.cityDocId;

const sortBySortLevel = (a: MadadDto, b: MadadDto) => a.sortLevel - b.sortLevel;

export const convertAgentDtoToCard = (record: MadadAgentDto): MadadAgentCard => ({
  id: record.agentId,
  docId: record.docId,
  cityDocId: record.cityDocId,
  neighborhoodDocId: record.neighborhoodDocId,
  agentName: record.agentName,
  soldCount: record.soldCount,
  exclusiveListingsCount: record.exclusiveListingsCount,
  agencyName: record.officeName,
  agencyImageUrl: record.officeIconUrl,
  agencyId: record.officeId,
  coverImage: record.coverImage,
  avatarImage: record.avatarImage,
  topTenBadgeVariant: mapToTopTenBadgeVariant[record.category],
  phone: record.phone,
  isRegistered: record.isRegistered,
});

export const convertOfficeDtoToCard = (record: MadadOfficeDto): MadadAgencyCard => ({
  id: record.officeId,
  docId: record.docId,
  cityDocId: record.cityDocId,
  neighborhoodDocId: record.neighborhoodDocId,
  soldCount: record.soldCount,
  exclusiveListingsCount: record.exclusiveListingsCount,
  agencyName: record.officeName,
  coverImage: record.coverImage,
  avatarImage: record.avatarImage,
  topTenBadgeVariant: mapToTopTenBadgeVariant[record.category],
  phone: record.phone,
  isRegistered: record.isRegistered,
});

// An office/agent that won in neighborhood, but not in the city won't have a badge.
// So we need to update the sortLevel of those agents/offices. Unfortunately, this logic is placed on the FE.
const SORT_LEVEL_WINNER_WITHOUT_BADGE = 25;
const covertToWinnerWithoutBadge = (record: MadadDto) => ({
  ...record,
  category: 'winner',
  sortLevel: SORT_LEVEL_WINNER_WITHOUT_BADGE,
});

export const createAgentsCardsSelector = (selector: (state: State) => MadadDto[]) => (
  createSelector(selector, records => records ? records.map(convertAgentDtoToCard) : records)
);

export const createAgenciesCardsSelector = (selector: (state: State) => MadadDto[]) => (
  createSelector(selector, records => records ? records.map(convertOfficeDtoToCard) : records)
);

const createFilteredMadadDtoSelector = (filterFn: (record: MadadAgentDto | MadadOfficeDto) => boolean) => (
  createSelector(
    madadRecordsSelector,
    records => records.filter(filterFn)
  )
);

const registeredAgentsSelector = createSelector(
  madadRecordsSelector,
  records => {
    return records.reduce((registeredAgents, record) => {
      if (record.isRegistered && isAgent(record.role)) {
        return [
          ...registeredAgents,
          isWinnerInCity(record) ? record : covertToWinnerWithoutBadge(record),
        ];
      }
      return registeredAgents;
    }, []);
  }
);

const registeredAgenciesSelector = createSelector(
  madadRecordsSelector,
  records => {
    return records.reduce((registeredAgencies, record) => {
      if (record.isRegistered && isAgency(record.role)) {
        return [
          ...registeredAgencies,
          isWinnerInCity(record) ? record : covertToWinnerWithoutBadge(record),
        ];
      }
      return registeredAgencies;
    }, []);
  }
);

export const registeredAgentsCardsSelector = createAgentsCardsSelector(registeredAgentsSelector);
export const registeredAgenciesCardsSelector = createAgenciesCardsSelector(registeredAgenciesSelector);

const agentsWinnersSelector = createFilteredMadadDtoSelector(({ role, category }) => (
  isWinner(category) && isAgent(role)
));
const agenciesWinnersSelector = createFilteredMadadDtoSelector(({ role, category }) => (
  isWinner(category) && isAgency(role)
));

const cityToWinnersMapperSelector = createSelector(
  madadRecordsSelector,
  records => {
    if (!records.length) return null;
    return records.reduce((mapper, record) => {
      if (isWinnerInCity(record)) {
        const { role, agentId, officeId, cityDocId } = record;
        const id = isAgent(role) ? agentId : officeId;
        const key = isAgent(role) ? 'agentsMap' : 'agenciesMap';
        if (mapper[key].has(cityDocId)) {
          mapper[key].get(cityDocId).add(id);
        }
        else {
          mapper[key].set(cityDocId, new Set([ id ]));
        }
      }
      return mapper;
    }, {
      agentsMap: new Map<MadadDto['cityDocId'], Set<MadadDto['agentId']>>(),
      agenciesMap: new Map<MadadDto['cityDocId'], Set<MadadDto['officeId']>>(),
    });
  }
);

export const agentsWinnersCardsSelector = createAgentsCardsSelector(
  createSelector([
    agentsWinnersSelector,
    flow(cityToWinnersMapperSelector, mapper => mapper ? mapper.agentsMap : null),
  ], (agents, map) => {
    if (!map) return agents;
    return agents
      .reduce((acc, agent) => (
        map.has(agent.cityDocId) && map.get(agent.cityDocId).has(agent.agentId)
          ? [ ...acc, agent ]
          : [ ...acc, covertToWinnerWithoutBadge(agent) ]
      ), [])
      .sort(sortBySortLevel);
  })
);

export const agenciesWinnersCardsSelector = createAgenciesCardsSelector(
  createSelector([
    agenciesWinnersSelector,
    flow(cityToWinnersMapperSelector, mapper => mapper ? mapper.agenciesMap : null),
  ], (agencies, map) => {
    if (!map) return agencies;
    return agencies
      .reduce((acc, agency) => (
        map.has(agency.cityDocId) && map.get(agency.cityDocId).has(agency.officeId)
          ? [ ...acc, agency ]
          : [ ...acc, covertToWinnerWithoutBadge(agency) ]
      ), [])
      .sort(sortBySortLevel);
  })
);
