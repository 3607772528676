import { call, put, takeEvery } from 'redux-saga/effects';
import { setIsLikedToastOpen, toggleFavorites } from 'store/state/app/actions';
import { TOGGLE_FAVORITES } from 'store/state/app/types';
import { LocalStorage } from 'utils/localStorage';
import { IS_LIKED_TOAST_SHOWN } from 'consts/localStorageKeys';
import { AuthGateResponse, getAuthGateResponse } from 'store/sagas/routing/handlers/utils';

function* toggleFavoritesWorker(action: ReturnType<typeof toggleFavorites>) {
  const { payload: { isFavorite } } = action;
  const authGateResponse: AuthGateResponse = yield call(getAuthGateResponse);

  if (authGateResponse !== AuthGateResponse.Rejected && !isFavorite && !LocalStorage.get(IS_LIKED_TOAST_SHOWN)) {
    yield put(setIsLikedToastOpen(true));
    LocalStorage.set(IS_LIKED_TOAST_SHOWN, true);
  }
}

export function* toggleFavoritesWatcher() {
  yield takeEvery(TOGGLE_FAVORITES, toggleFavoritesWorker);
}
