import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import * as Types from 'store/state/uploadBulletin/types';
import {
  editListingAction,
  newListingAction,
  reportAddressErrorAction,
  editListingStatusAction,
  editListingPromoteStatusAction,
  validateUploadPhoneAction,
} from 'store/state/uploadBulletin/actions';
import {
  mutate,
  SetMutationResponseAction,
  resetMutationResponse,
} from 'store/state/mutationsResponse/actions';
import {
  IEditCommercialListingResponse,
  IEditListingResponse,
  INewCommercialListingResponse,
  INewListingResponse,
  IUpdateUserProfileVariables,
  ListingResponseType,
  LoadType,
  MutationType,
  UpdateListingResponse,
} from './apiService/types';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { Route } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { uploadBulletinDraft } from 'screens/UploadBulletinPage/helpers';
import {
  closeManageListingsErrorModal,
  setAfterUploadBulletinModalOpen,
  setListingEdited,
  setManageListingsErrorModal,
  setToastMessage,
} from 'store/state/app/actions';
import { IMutationResponse, uploadBulletinBadAddressReportSelector } from '../state/mutationsResponse/selectors';
import {
  BulletinStatusType,
  IEditBulletinInput,
  INewBulletinCommercialInput,
  INewBulletinInput,
  INewBulletinResidentialInput,
  PocType,
  PoiId,
  User,
  ListingStatusType,
  IGeneralCondition,
} from 'utils/entities';
import { isAgentManagerSelector, isAgentSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { resetDomainData } from 'store/state/domainData/actions';
import { CLOSE_MANAGE_LISTINGS_ERROR_MODAL } from 'store/state/app/types';
import { routeSelector } from 'store/state/selectors/router';
import { manageListingsErrorModalSelector } from 'store/state/app/selectors';
import { queryData } from 'store/sagas/apiService';
import { isPhoneRegisteredDomainSelector } from 'store/state/domainData/selectors/common';

const REMARKS_DELIMITER = '___';

const newListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.NewListing
);

const newCommercialListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.NewCommercialListing
);

const editListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditListing
);

const editCommercialListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditCommercialListing
);

function* reportErrorAddress(action: ReturnType<typeof reportAddressErrorAction>) {
  const { email, message } = action.payload;
  const draftValues = uploadBulletinDraft.get();
  const { docId } = draftValues.formValues.addressDetails;

  const result: IMutationResponse<MutationType.UploadBulletinBadAddressReport> = yield select(uploadBulletinBadAddressReportSelector);

  yield put(mutate({
    mutationType: MutationType.UploadBulletinBadAddressReport,
    meta: {
      variables: {
        docId,
        remarks: message + REMARKS_DELIMITER + email,
      },
    },
  }));

  if (result && result.data && result.data.badAddressReport) {
    yield put(setToastMessage({ term: 'reportListing.popup.toasterMessageConfirmation' }));
  }
}

const generalConditionNewMap = {
  [IGeneralCondition.ToRenovated]: IGeneralCondition.ToRenovate,
};

function mapGeneralConditionToNew(generalCondition: IGeneralCondition): IGeneralCondition {
  return generalConditionNewMap[generalCondition] || generalCondition;
}

function* prepareListingData (listing: INewBulletinInput | IEditBulletinInput, isEdit?: boolean) {
  const isAgent = yield select(isAgentSelector);
  const isAgentManager = yield select(isAgentManagerSelector);
  const { type, userName, userPhone, generalCondition, ...rest } = listing;
  const assignedAgentUserId = 'assignedAgentUserId' in listing && isAgentManager ? listing.assignedAgentUserId : undefined;

  return  {
    ...rest,
    generalCondition: mapGeneralConditionToNew(generalCondition),
    sellerType: isEdit ? rest.sellerType : isAgent ? PocType.Agent : PocType.Private,
    userName: isAgent ? null : userName,
    assignedAgentUserId,
    userPhone: isAgent ? null : userPhone,
  };
}

function* editListingWatcher(action: ReturnType<typeof editListingAction>) {
  const { payload: { listing } } = action;
  const data = yield call(prepareListingData, listing, true);

  if (listing.type === 'commercialBulletin') {
    yield put(mutate({
      mutationType: MutationType.EditCommercialListing,
      meta: {
        variables: { editCommercialListing: data },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.EditListing,
      meta: {
        variables: { editListing: data },
      },
    }));
  }
}

function* createNewListingWatcher(action: ReturnType<typeof newListingAction>) {
  const { payload: { listing } } = action;
  const data = yield call(prepareListingData, listing);

  if (listing.type === 'commercialBulletin') {
    yield put(mutate({
      mutationType: MutationType.NewCommercialListing,
      meta: {
        variables: { newCommercialListing: data as INewBulletinCommercialInput },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.NewListing,
      meta: {
        variables: { newListing: data as INewBulletinResidentialInput },
      },
    }));
  }

  const isAgent = yield select(isAgentSelector);
  if (isAgent) return;

  const currentUser: User = yield select(userProfileSelector);
  const metaVariables: IUpdateUserProfileVariables = {};
  if (!currentUser.firstName) {
    const [ firstName, lastName ] = data.userName.split(' ');
    if (firstName) {
      metaVariables.firstName = firstName;
    }
    if (lastName) {
      metaVariables.lastName = lastName;
    }
  }
  if (!currentUser.phone) {
    metaVariables.phone = data.userPhone;
  }
  if (!isEmpty(metaVariables)) {
    yield put(mutate({
      mutationType: MutationType.UpdateUserProfile,
      meta: {
        variables: metaVariables,
      },
    }));
  }
}

function* listingResponseErrorWatcher(data: INewListingResponse | IEditListingResponse | INewCommercialListingResponse | IEditCommercialListingResponse) {
  if (!data) {
    yield put(setToastMessage({ term: 'uploadBulletin.errorToast', type: 'error' }));
    return;
  }

  switch (data.__typename) {
    case ListingResponseType.UserCannotAddListingError:
    case ListingResponseType.GeneralListingError:
    case ListingResponseType.InvalidInputError:
      yield put(setManageListingsErrorModal({ type: data.__typename, field: data.field }));
      break;
    case ListingResponseType.NoPermissionsError:
      yield put(setToastMessage({ term: 'uploadBulletin.errorToast', type: 'error' }));
      break;
    default:
      yield put(navigateTo(Route.ManageBulletins, {}, { replace: true }));
  }
}

function* newListingResponseHandler(id: PoiId, isCommercial?: boolean) {
  yield put(navigateTo(isCommercial ? Route.UnitPageCommercial : Route.UnitPage, { id }, { replace: true }));
  yield put(setAfterUploadBulletinModalOpen(true));
  yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
  uploadBulletinDraft.clear();
}

function* newCommercialListingResponseWatcher(action: SetMutationResponseAction<MutationType.NewCommercialListing>) {
  const newCommercialListing = action.meta.response.data && action.meta.response.data.newCommercialListing;
  const commercialListing = newCommercialListing && newCommercialListing.commercialListing;

  if (commercialListing) {
    yield call(newListingResponseHandler, commercialListing.id, true);
  }
  else {
    yield fork(listingResponseErrorWatcher, newCommercialListing);
  }
}

function* newResidentialListingResponseWatcher(action: SetMutationResponseAction<MutationType.NewListing>) {
  const { data: { newListing } } = action.meta.response;
  const { listing } = newListing;

  if (listing) {
    yield call(newListingResponseHandler, newListing.listing.id);
  }
  else {
    yield fork(listingResponseErrorWatcher, newListing);
  }
}

function* editListingResponseWorker(listing: UpdateListingResponse, isCommercial?: boolean) {
  const { id, status: { status } } = listing;
  yield put(resetDomainData({ loadType: LoadType.UnitPageBulletin }));
  yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
  yield put(setListingEdited(Date.now()));
  if (status === BulletinStatusType.Displayed) {
    yield put(navigateTo(isCommercial ? Route.UnitPageCommercial : Route.UnitPage, { id }, { replace: true, includeFrozen: true }));
  }
  else {
    yield put(navigateTo(Route.ManageBulletins, {}, { replace: true }));
  }
  yield put(resetDomainData({ loadType: LoadType.EditListingPoi }));
}

function* editResidentialListingResponseWatcher(action: SetMutationResponseAction<MutationType.EditListing>) {
  const { data: { editListing } } = action.meta.response;
  if (editListing.listing) {
    yield call(editListingResponseWorker, editListing.listing);
  }
  else {
    yield fork(listingResponseErrorWatcher, editListing);
  }
}
function* editCommercialListingResponseWatcher(action: SetMutationResponseAction<MutationType.EditCommercialListing>) {
  const { data: { editCommercialListing } } = action.meta.response;
  if (editCommercialListing.commercialListing) {
    yield call(editListingResponseWorker, editCommercialListing.commercialListing, true);
  }
  else {
    yield fork(listingResponseErrorWatcher, editCommercialListing);
  }
}

function* closeErrorModalWatcher(action: ReturnType<typeof closeManageListingsErrorModal>) {
  const route = yield select(routeSelector);
  if (route.name === Route.UploadBulletin) {
    const modalData = yield select(manageListingsErrorModalSelector);
    if (modalData && modalData.type === ListingResponseType.UserCannotAddListingError) {
      uploadBulletinDraft.clear();
    }
  }

  if (route.name !== Route.ManageBulletins) {
    yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
    if (!action.payload || !action.payload.noRedirect) {
      yield put(navigateTo(Route.ManageBulletins, {}, { replace: true }));
    }
  }

  yield put(setManageListingsErrorModal(null));
}


const PoiStatusToListingStatusMap: Record<BulletinStatusType, ListingStatusType> = {
  [BulletinStatusType.Displayed]: ListingStatusType.Displayed,
  [BulletinStatusType.Deleted]: ListingStatusType.Deleted,
  [BulletinStatusType.Frozen]: ListingStatusType.Frozen,
};

function* editListingStatusWorker(action: ReturnType<typeof editListingStatusAction>) {
  const { payload: { id, type, status } } = action;
  const isBulletin = type === 'bulletin';
  const mutationType = isBulletin ? MutationType.EditListingStatus : MutationType.EditCommercialListingStatus;
  const isFrozen = PoiStatusToListingStatusMap[status] === ListingStatusType.Frozen;

  yield put(mutate({
    mutationType,
    meta: {
      variables: {
        editListingStatus: {
          status: PoiStatusToListingStatusMap[status],
          id,
        },
      },
    },
  }));

  if (!isBulletin && isFrozen) {
    yield put(editListingPromoteStatusAction({ promotedStatus: false, id }));
  }
}

function* editListingPromoteStatusWorker(action: ReturnType<typeof editListingPromoteStatusAction>) {
  const { payload: { id, promotedStatus: promoted } } = action;

  yield put(mutate({
    mutationType: MutationType.EditCommercialListingPromotionStatus,
    meta: {
      variables: {
        editCommercialListingPromotionStatus: {
          id,
          promoted,
        },
      },
    },
  }));
}

function* validatePhoneWorker(action: ReturnType<typeof validateUploadPhoneAction>) {
  yield put(resetDomainData({ loadType: LoadType.IsPhoneRegistered }));
  yield put(resetMutationResponse({ mutationType: MutationType.GenerateValidationCode, key: null }));
  yield put(resetMutationResponse({ mutationType: MutationType.CheckValidationCode, key: null }));

  const phoneNumber = action.payload;

  yield call(queryData, {
    loadType: LoadType.IsPhoneRegistered,
    meta: { variables: { phoneNumber } },
  });

  const isRegisteredResponse = yield select(isPhoneRegisteredDomainSelector);
  const isRegistered = isRegisteredResponse && isRegisteredResponse.data ? isRegisteredResponse.data.isPhoneRegistered : null;

  if (isRegistered === false) {
    yield put(mutate({
      mutationType: MutationType.GenerateValidationCode,
      meta: { variables: { phoneNumber } },
    }));
  }
}


export function* addEditBulletinWatcher() {
  yield takeLatest(Types.REPORTING_ERROR_ADDRESS, reportErrorAddress);
  yield takeLatest(Types.NEW_LISTING, createNewListingWatcher);
  yield takeLatest(Types.EDIT_LISTING, editListingWatcher);
  yield takeLatest(newListingMutationPattern, newResidentialListingResponseWatcher);
  yield takeLatest(newCommercialListingMutationPattern, newCommercialListingResponseWatcher);
  yield takeLatest(editListingMutationPattern, editResidentialListingResponseWatcher);
  yield takeLatest(editCommercialListingMutationPattern, editCommercialListingResponseWatcher);
  yield takeEvery(CLOSE_MANAGE_LISTINGS_ERROR_MODAL, closeErrorModalWatcher);
  yield takeEvery(Types.EDIT_LISTING_STATUS, editListingStatusWorker);
  yield takeEvery(Types.EDIT_LISTING_PROMOTE_STATUS, editListingPromoteStatusWorker);
  yield takeEvery(Types.VALIDATE_PHONE, validatePhoneWorker);
}
