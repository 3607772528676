import React from 'react';
import styled from '@emotion/styled';
import { Text, SmallText } from 'ds/components/typography';
import defaultTheme from 'ds/theme/default';
import CrossIcon from 'assets/svg/chip-close.svg';
import { colorToAlpha } from 'helpers/insight';
import { noop } from 'lodash';
type Size = 'xSmall' | 'small' | 'medium';
type State = 'default' | 'hovered';
type Mode = 'primary' | 'selected';
interface BasicChipProps {
  mode?: Mode;
  label?: string;
  size?: Size;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}
const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey2,
    selected: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey2,
    selected: defaultTheme.colors.brand.main,
  },
};
const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey9,
    selected: colorToAlpha(defaultTheme.colors.brand.lightest, 0.7),
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey9,
    selected: colorToAlpha(defaultTheme.colors.brand.lightest, 0.7),
  },
};
const modeCrossIconColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.brand.main,
    selected: defaultTheme.colors.brand.darker,
  },
};
const sizeToHeight: Record<Size, number> = {
  xSmall: 24,
  small: 28,
  medium: 32,
};
const sizeToTypographyComponent: Record<Size, React.FC> = {
  xSmall: SmallText,
  small: SmallText,
  medium: Text,
};
const Wrapper = styled.div<BasicChipProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-align: center;
  ${({ theme, mode = 'primary', size = 'medium' }) => `
    border-radius: ${theme.borderRadius.extraXLarge};
    padding: 0 ${theme.spacing(1.5)};
    height: ${sizeToHeight[size]}px;
    white-space: nowrap;
    transition: all .2s ease;
    * {
      pointer-events: none;
    }
    color: ${modeToFontColor.default[mode]};
    background-color: ${modeToBgColor.default[mode]};
    div {
      font-weight: 500;
    }
    &:hover {
      color: ${modeToFontColor.hovered[mode]};
      background-color: ${modeToBgColor.hovered[mode]};
      svg {
        path {
          fill: ${modeCrossIconColor.hovered[mode]};
        }
      }
    }
    svg {
      flex-shrink: 0;
      margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(1)};
      path {
        fill: ${modeCrossIconColor.default[mode]};
      }
    }
  `}
`;
export const ChipBasic: React.FC<BasicChipProps> = (props) => {
  const { label, onClick, size = 'medium', ...rest } = props;
  const TypographyComponent = sizeToTypographyComponent[size];
  return (
    <Wrapper {...rest} size={size} onClick={onClick}>
      <input type="checkbox" hidden onChange={noop} checked={rest.mode === 'selected'} />
      <TypographyComponent>{label}</TypographyComponent>
      {rest.mode === 'selected' ? <CrossIcon /> : null}
    </Wrapper>
  );
};
