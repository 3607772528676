import styled from '@emotion/styled';
import { SmallText, H3 } from 'ds/components/typography';
import { BookmarkIconWrapper } from 'components/favorites/styled';
import { Tag } from 'ds/components/ListingCard';
import { colorToAlpha } from 'helpers/insight';


interface IPlaceholderImage {
  imageUrl: string;
}

export const PlaceholderImage = styled.div<IPlaceholderImage>`
  border-radius: ${props => props.theme.borderRadius.tiny};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${props => props.theme.colors.neutrals.white};
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  transform-origin: top left;
  background-size: cover;
`;

interface IFirstLineLabel {
  isHighlighted: boolean;
}

export const FirstLineLabel = styled(H3.withComponent('div'))<IFirstLineLabel> `
  color: ${({ isHighlighted, theme }) => isHighlighted ? theme.colors.brand.darker : theme.colors.neutrals.grey1};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LineLabels = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1.25)};
`;

export const FirstLineWrapper = styled.div<{ isFlexColumn?: boolean; }>`
  display: flex;
  ${({ isFlexColumn }) => isFlexColumn ? `
    width: 100%;
    flex-direction: column;
  ` : `
    align-items: center;
  `}
`;

export const SaveToListWrapper = styled(BookmarkIconWrapper)<{ isCommercial: boolean }>`
  z-index: 11;
  position: absolute;
  width: auto;
  height: auto;
  ${({ theme, isCommercial }) => `
    top: ${theme.spacing(isCommercial ? 1 : 1.5)};
    ${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(isCommercial ? 1 : 1.5)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })` z-index: 1; `}
`;

export const InactivePlaceholder = styled.div `
  position: absolute;
  top: calc(50% - ${({ theme }) => theme.spacing(3)});
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  z-index: 2;
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  padding: 0 ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const LocationIconWrapper = styled.div<{ isActive?: boolean }>`
  height: 32px;
  width: 32px;
  position: absolute;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, isActive }) => `
    padding: ${theme.spacing(1)};
    border-radius: ${theme.borderRadius.round};
    background-color: rgba(0, 0, 0, ${isActive ? '0.8' : '0.4'});
    ${theme.isRTL ? 'right' : 'left' }: ${theme.spacing(1.5)};
    bottom: ${theme.spacing(1.5)};
    > svg {
      position: absolute;
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  `}
`;

export const MapViewWrapper = styled.div`
  display: flex;
`;

export const NextControlArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 100%;
  background: linear-gradient(90deg, rgba(0,0,0,0.4), ${({ theme }) => theme.colors.neutrals.transparent});
`;

export const PagerCategoryWrapper = styled.div`
  display: flex;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: 1px 4px 2px;
`;

export const PagerWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.isRTL ? 'transform: scale(-1);' : ''};

  .insight-impact-pager {
    .bulletPager {
      opacity: 1;
      background: ${({ theme }) => theme.colors.brand.lighter}!important;

      &.bulletCurrent {
        transform: scale(1.1, 1.1)!important;
        background: ${({ theme }) => theme.colors.brand.theLightest}!important;
      }
    }
  }
`;

export const PrevControlArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 100%;
  background: linear-gradient(90deg, ${({ theme }) => theme.colors.neutrals.transparent}, rgba(0,0,0,0.4));
`;

export const SecondLineLabel = styled.div `
  ${props => props.theme.media.showAt({ to: 2 })`
    & > div {
      span {
        color: ${props.theme.colors.neutrals.grey1};
      }
    }
  `}
`;

export const ThirdLineLabel = styled.div `
  ${props => props.theme.media.showAt( { to: 2 })`
    & > div {
      span {
        color: ${props.theme.colors.neutrals.grey1};
      }
    }
  `}
`;

export const PromotionalText = styled(SmallText) `
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const CommercialTag = styled(Tag)`
  ${({ theme }) => `
    background: ${colorToAlpha(theme.colors.brand.madlanGreen, 0.07)};
    color: ${theme.colors.brand.madlanGreen};
  `};
`;

export const MarketplaceTypeLabel = styled(Tag)<{ isCommercial?: boolean; }>`
  ${({ theme, isCommercial }) => `
    background: ${isCommercial ? theme.colors.neutrals.grey2 : colorToAlpha(theme.colors.brand.madlanGreen, 0.15)};
    color: ${isCommercial ? theme.colors.neutrals.white : theme.colors.brand.lighter};
    height: 20px;
    margin: ${theme.spacing(1)} 0 ${theme.spacing(0.5)};
  `};
`;

export const CommercialBulletinPromotion = styled(Tag)`
  ${({ theme }) => `
    background: ${theme.colors.neutrals.grey10};
    height: 20px;
    color: ${theme.colors.neutrals.grey1};
    margin-right: ${theme.spacing(1)};
  `}
`;
