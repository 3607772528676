import React from 'react';
import { SingleMap } from 'components/map';
import { ShowAt } from 'consts/breakpoints';
import { MapRefFn } from 'utils/mapUtilityTypes';

interface IMapAppProps {
  refFn: MapRefFn;
}

function MapApp(props: IMapAppProps)  {

  return (
    <ShowAt to={2}>
      {(isMobile) => (
        <SingleMap
          isMobile={isMobile}
          isCoveredFromTop={!isMobile}
          refFn={props.refFn}
        />
      )}
    </ShowAt>
  );
}

export default MapApp;
