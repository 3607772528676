import React from 'react';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { useLocale } from 'locale';
import { Action, Actions, Avatar, Separator, UserActionsMenu, UserActionsWrapper, UserInfo } from './styled';
import { SmallText, Text } from 'ds/components/typography';
import { Link } from 'components/link';
import { DecodedRouteParams, Route } from 'config/routes';
import { IUserProfileFields, userProfileFieldsSelector } from '../profileFields';
import withEvents from 'analytics/withEvents';
import { State } from 'store/state';
import { desktopUserActionsSelector, actionToIcon, UserAction, UserActionName } from '../userActions';
import { useScreenBreakpoint } from '../../../consts/breakpoints';


interface ActionLinkProps {
  actionName: UserActionName;
  routeName: Route;
  routeParams: Partial<DecodedRouteParams>;
  onClick: (e: React.MouseEvent) => void;
}

const ActionLink: React.FC<ActionLinkProps> = ({ actionName, routeName, routeParams, onClick }) => {
  const { t } = useLocale();
  return (
    <Action onClick={onClick}>
      <Link data-auto={`action-${routeName}`} routeName={routeName} routeParams={routeParams} preserveParams>
        {actionToIcon[actionName]}
        <SmallText>{t('navigation.header.actions', { action: actionName, isAgent: false, isProfileMobileMenu: false })}</SmallText>
      </Link>
    </Action>
  );
};

interface UserActionsProps extends UserActionsOwnProps, IUserProfileFields {
  onMenuClick?: () => void;
  orderedActions?: UserAction[];
}

interface UserActionsOwnProps {
  onLogout: () => void;
}

const UserActionsUnwrapped: React.FC<UserActionsProps> = (props) => {
  const {
    orderedActions,
    onMenuClick,
    firstName,
    lastName,
    initials,
    onLogout,
    isAgent,
    avatar,
    email,
  } = props;
  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();
  const isMiddleScreen = screenBreakpoint === 3;

  return (
    <Downshift>
      {({ toggleMenu, isOpen, getLabelProps }) => {
        const onAvatarClick = () => {
          toggleMenu();
          onMenuClick();
        };
        return (
          <div>
            <UserActionsWrapper isMiddleScreen={isMiddleScreen}>
              {initials ? (
                <Avatar
                  data-auto="user-menu"
                  userInitials={initials}
                  imageURL={avatar}
                  {...getLabelProps()}
                  onClick={onAvatarClick}
                />
              ) : null}
              {isOpen ? (
                <UserActionsMenu data-auto="user-action-menu">
                  <UserInfo data-auto="user-info">
                    <Text weight="bold">{firstName} {lastName}</Text>
                    <SmallText data-auto="user-email">{email}</SmallText>
                  </UserInfo>
                  <Actions>
                    {orderedActions.map(action => action.customRoute ? (
                      <Action key={action.name}>
                        <a target="_blank" href={action.customRoute}>
                          {actionToIcon[action.name]}
                          <SmallText>{t('navigation.header.actions', { action: action.name, isAgent, isProfileMobileMenu: false })}</SmallText>
                        </a>
                      </Action>
                    ) : (
                      <ActionLink
                        key={action.name}
                        actionName={action.name}
                        routeName={action.route}
                        routeParams={action.params}
                        onClick={toggleMenu as (e: React.MouseEvent) => void}
                      />
                    ))}
                    <Separator />
                    <Action onClick={onLogout}>
                      {actionToIcon[UserActionName.Logout]}
                      <SmallText data-auto="action-log-out">{t('navigation.header.actions', { action: UserActionName.Logout })}</SmallText>
                    </Action>
                  </Actions>
                </UserActionsMenu>
              ) : null}
            </UserActionsWrapper>
          </div>
        );
      }}
    </Downshift>
  );
};

const UserActionsUnwrappedWithEvents = withEvents<UserActionsProps>(sendEvent => ({
  onMenuClick() {
    sendEvent('page_navigation_bar_icon_click', 'page', { event: { icon: 'personal' } });
  },
}))(UserActionsUnwrapped);

const mapStateToProps = (state: State) => ({
  profile: userProfileFieldsSelector(state),
  userActions: desktopUserActionsSelector(state),
});

export const UserActions = connect(mapStateToProps, null, (stateProps, _, ownProps: UserActionsOwnProps) => ({
  ...stateProps.profile,
  orderedActions: stateProps.userActions,
  ...ownProps,
}))(UserActionsUnwrappedWithEvents);
