import { PoiId, PoiType } from 'utils/entities';
import { useSelector } from 'react-redux';
import { contactPoisByIdsHeapSelector } from 'store/state/selectors/poisHeap';
import { isBulletinPoiType } from 'utils/marketplaceRoutes';

interface UseDisplayPhoneOpts {
  id: PoiId;
  type: PoiType;
}

export function useDisplayPhone({ id, type }: UseDisplayPhoneOpts) {
  if (type === 'ad') {
    return null;
  }

  const poisDictionary = useSelector(contactPoisByIdsHeapSelector);
  const poi = poisDictionary[id];

  if (!poi) return null;

  let displayPhone: string;

  if (isBulletinPoiType(poi)) {
    displayPhone = poi.poc.displayNumber;
  }
  else if (poi.phoneNumber.length) {
    [ displayPhone ] = poi.phoneNumber;
  }

  return displayPhone || null;
}

export const createPhoneURI = (phone: string) => `tel:${phone}`;
