import styled from '@emotion/styled';
import * as Color from 'color';



export const DotsWrapper = styled.div `
  position: absolute;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  ${props => props.theme.media.showAt({ from: 3 }) `padding: 0 20px;`}
`;

export const DotStyledWrapper = styled.div`
  padding: 8px 4px;
  cursor: pointer;
  flex-grow: 1;
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    :hover div::before {opacity: .5}
    :hover div::after  {visibility: visible; opacity: .5}
  `}
`;

export const DotStyled = styled.div<{ isActive: boolean, isCompleted?: boolean, isPaused: boolean, interval: number, color?: string }>`
  position: relative;
  height: 2px;
  margin: 0 4px;
  flex-grow: 1;
  ${({ theme }) => `background: ${Color(theme.colors.neutrals.grey7).alpha(0.6)}`};

  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    left: 0;
    top: 0;
    background: ${({ color, theme }) => color ? color : theme.colors.insights.livability};
    ${({ isPaused, isActive, interval }) => !isPaused && isActive ? `
    animation: activeStory ${interval}ms linear;
  ` : 'width: 0;'}
  }

  @keyframes activeStory {
    from {
      width: 0
    }

    to {
      width: 100%
    }
  }

  @keyframes inactiveStory {
    from {
      width: 100%
    }

    to {
      width: 0
    }
  }

  &:after {
    content: '';
    visibility: hidden;
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    top: 0;
    background: ${({ color, theme }) => color ? color : theme.colors.insights.livability};
  }

  ${({ isCompleted = false }) => isCompleted && `
    &:after {
      z-index: 1;
      visibility: visible;
    }

    &:before {
      width: 0px;
      animation: inactiveStory 0.1s linear;
    }
  `}

  ${({ isActive = true, isPaused }) => isActive && !isPaused && `
    &:before {
      width: 100%;
    }
  `}

  ${({ isActive = false, isCompleted = false, interval }) => !isCompleted && !isActive && `
    &:before {
      visibility: hidden;
      animation: inactiveStory ${interval}ms linear;
    }

    &:after {
      visibility: hidden;
    }
  `}
`;

export const Dots = styled.div`
  position: relative;
`;

export const ArrowsWrapper = styled.div`
  position: relative;
  .arrows-area {
    display: none;
  }
  &:hover .arrows-area {
    display: block;
  }
`;
