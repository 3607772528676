import React from 'react';
import { connect } from 'react-redux';
import { isEditAllowedSelector, isEditCommercialAllowedSelector } from 'store/state/domainData/selectors';
import { Route, useRoute } from 'config/routes';
import { State } from 'store/state';
import EditBulletinButton from 'components/edit-bulletin-button';


interface MaybeFavoritesTogglerWithRouteProps {
  isEditAllowed: boolean;
  isCommercialEditAllowed: boolean;
}

const MaybeFavoritesTogglerWithRoute: React.FC<MaybeFavoritesTogglerWithRouteProps> = ({
  isEditAllowed,
  isCommercialEditAllowed,
}) => {
  const { route: { name, params: { id } } } = useRoute();

  switch (name) {
    case Route.UnitPage:
      return isEditAllowed ? <EditBulletinButton id={id} /> : null;
    case Route.UnitPageCommercial:
      return isCommercialEditAllowed ? <EditBulletinButton id={id} /> : null;
    default:
      return null;
  }
};

const mapStateToProps = (state: State) => ({
  isEditAllowed: isEditAllowedSelector(state),
  isCommercialEditAllowed: isEditCommercialAllowedSelector(state),
});

export default connect(mapStateToProps)(MaybeFavoritesTogglerWithRoute);
