import React from 'react';

import styled from '@emotion/styled';
import { Input } from './Checkbox';

export type ToggleSwitchSize = 'small' | 'large';

interface SwitchToggleProps {
  checked?: boolean;
  disabled?: boolean;
  reversed?: boolean;
  size?: ToggleSwitchSize;
}

const CIRCLE_SIZE_PX_MAP = {
  small: 14,
  large: 24,
};

export const Circle = styled.div<SwitchToggleProps>`
  background-color: ${props => props.theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  transition: transform 200ms ease;
  height: ${props => CIRCLE_SIZE_PX_MAP[props.size || 'small']}px;
  width: ${props => CIRCLE_SIZE_PX_MAP[props.size || 'small']}px;
  margin: 0 ${props => props.checked ? '3px' : '2px'};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
`;

const TOGGLE_SIZE_PX_MAP = {
  small: {
    width: 36,
    height: 20,
  },
  large: {
    width: 50,
    height: 30,
  },
};
export const Toggle = styled.div<SwitchToggleProps>`
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: ${props => props.checked ? 'flex-end' : 'flex-start'};
  top: 0;
  left: 0;
  height: ${props => TOGGLE_SIZE_PX_MAP[props.size || 'small'].height}px;
  width: ${props => TOGGLE_SIZE_PX_MAP[props.size || 'small'].width}px;
  border-radius: ${({ theme }) => theme.borderRadius.smallestSideRound};
  border: 1px solid ${props => props.theme.colors.neutrals.grey6};
  background-color: ${props => props.checked ? props.theme.colors.neutrals.white : props.theme.colors.neutrals.grey8};
  ${({ theme }) => theme.isRTL ? 'margin-left' : 'margin-right'}: ${({ theme }) => theme.spacing(1.5)};
  ${({ theme, reversed }) => theme.isRTL && reversed ? `margin-right: ${theme.spacing(1.5)}; margin-left: 0px;` : ''}
  ${({ theme, reversed }) => !theme.isRTL && reversed ? `margin-left: ${theme.spacing(1.5)}; margin-right: 0px;` : ''}

  transition: background-color .2s;

  &:hover {
    background-color: ${props => props.checked ? props.theme.colors.neutrals.grey10 : props.theme.colors.neutrals.grey7};
  }

  &[disabled] {
    background-color: ${props => props.theme.colors.neutrals.grey8};
    pointer-events: none;
    opacity: 0.5;
  }

  ${props => props.checked && `
    background-color: ${props.theme.colors.brand.main};
    border: none;

    &:hover {
      background-color: ${props.theme.colors.brand.dark};
    }

    &[disabled] {
      background-color: ${props.theme.colors.neutrals.grey6};
    }
  `}
`;


export const Switch: React.FunctionComponent<React.HTMLProps<HTMLInputElement>> = (props) => <Input {...props} type="checkbox" />;
