import React, { useState, useEffect, useRef } from 'react';
import config from 'config';
import MultiLocationAutocomplete from 'components/autocomplete/multi-location-autocomplete';
import multiLocationSearchValidationFn from 'components/autocomplete/multi-location-autocomplete/multiLocationSearchValidationFn';
import { MultiInputValue, AddressAutocompleteSource } from 'components/autocomplete/types';
import { MapButton, SearchInputWrapper } from './styled';
import { useLocale } from 'locale';
import { config as completionTypesConfig, CompletionListGroup } from 'components/commute-popup/CompletionTypeConfig';
import { Route, useRoute } from 'config/routes';
import docIds2InfoQuery from 'store/sagas/apiService/queries/docIds2Information.gql';
import { useQuery } from '@apollo/react-hooks';
import { IAutocompleteEntry, IDocIds2InformationVariables, IDocIds2InformationResponse, CompletionType, MarketplaceType } from 'utils/entities';
import { autocompleteEntriesToSuggestions } from 'components/autocomplete/suggestions/utils';
import { ISaveSearch } from 'store/sagas/apiService/types';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { uniq } from 'lodash';
import MapIcon from 'assets/svg/map-1-5.svg';

const MOBILE_VISIBLE_SUGGESTIONS_LIMIT = 5;
const VISIBLE_SUGGESTIONS_LIMIT = 8;

interface MultiLocationSearchProps {
  onSearch: (value: string[], projectType?: CompletionType) => void;
  searchHistory?: ISaveSearch[];
  marketplace: MarketplaceType;
  setMobileMapViewActive: (isActive: boolean) => void;
}

interface MultiLocationSearchInnerProps {
  onSearch: (value: string[], projectType?: CompletionType, selectedItems?: MultiInputValue) => void;
  values: MultiInputValue;
  loading: boolean;
  initialSuggestions: IAutocompleteEntry[];
  marketplace: MarketplaceType;
  setMobileMapViewActive: (isActive: boolean) => void;
}

const EMPTY_INITIAL: string[] = [];

export const MultiLocationSearch: React.FC<MultiLocationSearchProps> = ({
  onSearch,
  searchHistory,
  marketplace,
  setMobileMapViewActive,
}) => {
  const { route } = useRoute();
  const term = route.params.term || EMPTY_INITIAL;
  const searchHistoryEntries = uniq(searchHistory.map(entry => entry.term))
    .filter(docId => !term.includes(docId));

  const initialSuggestions = searchHistoryEntries.length
    ? searchHistoryEntries
    : null;

  const filteredInitialSuggestions = (initialSuggestions || EMPTY_INITIAL)
    .filter(docId => !term.includes(docId));

  return (
    <GetEntries initialSuggestions={filteredInitialSuggestions}>
      {(values, recentSearch, loading) => (
        <MultiLocationSearchInner
          values={values}
          loading={loading}
          onSearch={onSearch}
          marketplace={marketplace}
          initialSuggestions={recentSearch}
          setMobileMapViewActive={setMobileMapViewActive}
        />
      )}
    </GetEntries>
  );
};

export const MultiLocationSearchInner: React.FC<MultiLocationSearchInnerProps> = (props) => {
  const { onSearch, values, loading, initialSuggestions, marketplace, setMobileMapViewActive } = props;
  const range = useScreenBreakpoint();
  const { t } = useLocale();
  const { route, previousRoute } = useRoute();
  const [ searchStr, setSearchStr ] = useState('');
  const initialValues = useRef<MultiInputValue>([]);
  const [ selectedValues, setSelectedValues ] = useState<MultiInputValue>([]);
  const id = route.params.term && route.params.term.join();
  const isMobile = range < 3;
  const filteredInitialSuggestions = initialSuggestions
    .filter(value => !selectedValues.map(entry => entry.docId).includes(value.docId))
    .slice(0, isMobile ? MOBILE_VISIBLE_SUGGESTIONS_LIMIT : VISIBLE_SUGGESTIONS_LIMIT);

  useEffect(() => {
    if (!loading && values.length) {
      initialValues.current = values;
    }
  }, [ loading ]);

  useEffect(() => {
    if (values.length || (previousRoute && previousRoute.name === Route.SearchCommercial && route.name === Route.Search)) {
      setSelectedValues(values);
    }
  }, [ values, previousRoute, route ]);

  const isCommercial = marketplace === MarketplaceType.Commercial;
  const completionTypes = completionTypesConfig[isCommercial ? CompletionListGroup.CommercialDefault : CompletionListGroup.DefaultBuy];

  const handleSearch = () => {
    const submittedValues = selectedValues.map(v => v.docId);
    onSearch(submittedValues, null, selectedValues);
    initialValues.current = selectedValues;
  };

  const handleChange = (nextValues: MultiInputValue) => {
    const nextValidatedValues = multiLocationSearchValidationFn(nextValues);
    if (nextValidatedValues.length === 1 && (nextValidatedValues[0].type === CompletionType.Project || nextValidatedValues[0].type === CompletionType.CommercialProject)) {
      onSearch(nextValidatedValues.map(v => v.id), nextValidatedValues[0].type, selectedValues);
    }
    else {
      setSelectedValues(nextValidatedValues);
    }
  };

  const handleCancel = () => {
    setSelectedValues(initialValues.current);
  };

  return (
    <SearchInputWrapper>
      <MultiLocationAutocomplete
        key={id}
        disabled={loading}
        searchStr={searchStr}
        onChange={handleChange}
        values={selectedValues}
        onSearchStrChange={setSearchStr}
        onBlur={handleSearch}
        onMobileClose={handleSearch}
        placeholder={t('navigation.subheader.searchInputPlaceholder', { isCommercial })}
        optionsCount={isMobile ? MOBILE_VISIBLE_SUGGESTIONS_LIMIT : VISIBLE_SUGGESTIONS_LIMIT}
        showType
        completionTypes={completionTypes}
        source={AddressAutocompleteSource.SearchResults}
        initialSuggestions={autocompleteEntriesToSuggestions(filteredInitialSuggestions, t)}
        onCancel={handleCancel}
      />
      {isMobile && (
        <MapButton mode="ghost" size="large" onClick={() => setMobileMapViewActive(true)}>
          <MapIcon width={20} height={20} />
          {t('navigation.subheader.mapButton.label')}
        </MapButton>
      )}
    </SearchInputWrapper>
  );
};

interface GetTermEntriesProps {
  children: (values: IAutocompleteEntry[], initialSuggestions?: IAutocompleteEntry[], loading?: boolean) => JSX.Element;
  initialSuggestions?: string[];
}

const EMPTY: IAutocompleteEntry[] = [];

const GetEntries: React.FC<GetTermEntriesProps> = ({ children, initialSuggestions = EMPTY_INITIAL }) => {
  const { route } = useRoute();
  const term = route.params.term || [];

  // default search should not be reflected as selected in the input
  const shouldSkip = !(term.length || initialSuggestions.length);
  const shouldOmitTerm = term.length === 1 && term[0] === config.cityTerm;

  const { loading, data } = useQuery<IDocIds2InformationResponse, IDocIds2InformationVariables>(docIds2InfoQuery, {
    variables: { docIds: [ ...term, ...initialSuggestions ] },
    skip: shouldSkip,
  });

  if (loading || shouldSkip) return children(EMPTY, EMPTY, loading);

  const termEntries = !shouldOmitTerm && data && data.docIds2Information &&
    data.docIds2Information.slice(0, term.length).map(entry => entry.document);
  const initialSuggestionsEntries = data && data.docIds2Information &&
    data.docIds2Information.slice(term.length).map(entry => entry.document);

  return children(termEntries || EMPTY, initialSuggestionsEntries || EMPTY);
};
