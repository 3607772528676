/* tslint:disable: no-console */

import ReactDOM from 'react-dom';
import { noop } from 'lodash';
import React, { useEffect, useContext } from 'react';
import { MapLike, MapRefFn } from 'utils/mapUtilityTypes';

interface IStack<T> {
  push(item: T): void;
  pop(): T | undefined;
  peek(): T | undefined;
  size(): number;
}

class Stack<T> implements IStack<T> {
  private storage: T[] = [];

  public push(item: T): void {
    this.storage.push(item);
  }

  public pop(): T | undefined {
    return this.storage.pop();
  }

  public peek(): T | undefined {
    return this.storage[this.size() - 1];
  }

  public size(): number {
    return this.storage.length;
  }
}

const MapMoverContext = React.createContext<MapMover | null>(null);

export const MapMoverContextProvider = MapMoverContext.Provider;

export function useMapMover(mapSlotRef: React.RefObject<HTMLDivElement>) {
  const mapMover = useContext(MapMoverContext)!;
  useEffect(() => {
    const refCurrent = mapSlotRef.current!;
    return mapMover.moveInto(refCurrent);
  }, []);
}

export class MapMover {
  private mapContainer = document.createElement('div');
  private map: MapLike | null = null;

  public initialize(appTree: (refFn: MapRefFn) => JSX.Element) {
    ReactDOM.render(appTree((map) => {
      this.map = map;
    }), this.mapContainer);
  }

  private stack = new Stack<Element>();

  private moveMapInto(element: Element) {
    element.appendChild(this.mapContainer);
    if (this.map) {
      this.map.resize();
      this.map.__resizing = true;
    }
  }

  public moveInto(element: Element) {
    if (this.stack.peek() === element) {
      return noop;
    }

    this.stack.push(element);
    console.debug('[map] Moving into element', element);
    this.moveMapInto(element);

    return () => {
      console.debug('[map] unmount', element);
      this.stack.pop();
      const lastElt = this.stack.peek();
      if (lastElt) {
        this.moveMapInto(lastElt);
      }
      else {
        console.debug('[map] No element to move into');
      }
    };
  }
}
