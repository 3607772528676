import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  MutationType,
} from 'store/sagas/apiService/types';
import { mutate } from 'store/state/mutationsResponse/actions';
import { TOGGLE_ADDRESS } from 'store/state/app/types';
import { setToastMessage, toggleAddress } from 'store/state/app/actions';
import { authGate, AuthGateResponse } from 'store/sagas/routing/handlers/utils';
import { removeTypeName } from 'utils';
import { PendingActionType, IPendingSaveAddress } from 'components/authentication/types';
import { authModalPendingActionSelector } from 'store/state/app/selectors';


function* toggleAddressWorker(action: ReturnType<typeof toggleAddress>) {
  const { payload: { docId, settings, isSaved, isChanged }, meta } = action;

  const mutationType = isSaved ? MutationType.DeleteAddress : MutationType.SaveAddress;
  const variables = isSaved ? { docId } : { docId, settings: removeTypeName(settings) };
  const term = isSaved ? 'saveAddress.removeMessage' : 'saveAddress.addMessage';

  const pendingAction: IPendingSaveAddress = {
    type: PendingActionType.SaveAddress,
    meta,
    ...variables,
  };

  const authGateResponse = yield call(authGate, pendingAction);
  if (authGateResponse === AuthGateResponse.Rejected) return;

  const pendingActionUpdated: IPendingSaveAddress = yield select(authModalPendingActionSelector);

  yield put(mutate({
    mutationType,
    meta: {
      variables: authGateResponse === AuthGateResponse.Resolved
        ? { ...variables, settings: pendingActionUpdated.settings }
        : variables,
    },
  }));

  if (!isChanged) {
    yield put(setToastMessage({ term }));
  }
}

export function* toggleAddressWatcher() {
  yield takeEvery(TOGGLE_ADDRESS, toggleAddressWorker);
}
