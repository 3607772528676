export class LimitedSizeStack<T> {
  constructor(private limitSize: number, private items: T[] = []) {}

  public add(value: T) {
    this.items = [ value, ...this.items.slice(0, this.size - 1) ];
    return this;
  }

  public get size() {
    return this.limitSize;
  }

  public get value() {
    return [ ...this.items ];
  }
}
