import { BlogCard, BlogCardProps } from './blog-card';
import withEvents from 'analytics/withEvents';

const BlogCardWithEvents = withEvents<BlogCardProps>(sendEvent => ({
  onCardExposeEvent: (isPaid: boolean, articleId: string, developer?: { id: string; name: string; }) => sendEvent('article_carousel_item_expose', 'article', {
    event: {
      is_paid: isPaid,
      article_id: articleId,
    },
    ...(developer ? { developer } : {}),
  }),
  onItemClick: (isPaid: boolean, articleId: string, developer?: { id: string; name: string; }) => sendEvent('article_carousel_item_click', 'article', {
    event: {
      is_paid: isPaid,
      article_id: articleId,
    },
    ...(developer ? { developer } : {}),
  }),
}))(BlogCard);

export default BlogCardWithEvents;
