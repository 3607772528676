import { connect } from 'react-redux';
import { State } from 'store/state';
import { editListingStatusAction } from 'store/state/uploadBulletin/actions';
import { createSelector } from 'reselect';
import { routeSelector } from 'store/state/selectors/router';
import { unitPageBulletinSelector, unitPageCommercialBulletinSelector } from 'store/state/domainData/selectors';
import { Route } from 'config/routes';
import { DeleteListingPopup } from './DeleteListing';
import { openStatusDeleteListingModalSelector } from 'store/state/app/selectors';
import { setOpenStatusDeleteListingModal } from 'store/state/app/actions';


const poiSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
], (route, bulletin, commercialBulletin) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
  }
  return poi;
});

const mapStateToProps = (state: State) => ({
  poi: poiSelector(state),
  isOpen: openStatusDeleteListingModalSelector(state),
});

const mapDispatchToProps = {
  editListingStatus: editListingStatusAction,
  setModalOpenStatus: setOpenStatusDeleteListingModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteListingPopup);
