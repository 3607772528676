import { takeLatest, call, put } from 'redux-saga/effects';
import { SET_ACTIVE_BUS_LINE } from 'store/state/insightsContext/types';
import { setActiveBusLine } from 'store/state/insightsContext/actions';
import { LoadType } from './apiService/types';
import { queryData } from './apiService';
import { resetDomainData } from 'store/state/domainData/actions';

function* loadBusLine(action: ReturnType<typeof setActiveBusLine>) {
  if (action.payload && action.payload.id) {
    yield call(queryData, {
      loadType: LoadType.BusRouteByIds,
      meta: { variables: { busLineIds: [ action.payload.id ] } },
    });
  }
  else {
    yield put(resetDomainData({ loadType: LoadType.BusRouteByIds }));
  }
}

export function* busLineWatcher() {
  yield takeLatest(SET_ACTIVE_BUS_LINE, loadBusLine);
}
