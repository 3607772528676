import { State } from 'store/state';
import { IInsight } from 'utils/entities';
import { createSelector } from 'reselect';
import { activeInsightIdSelector } from 'store/state/insightsContext/selectors';

export const makeExpandedInsightSelector = (dictionarySelector: (state: State) => Record<string, IInsight>) => createSelector([
  dictionarySelector,
  activeInsightIdSelector,
  /**
   * @temp remove after selectors are refactored
   */
], (dictionary, activeInsightId) => dictionary[activeInsightId] || null);
