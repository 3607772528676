import { SortValue } from 'components/listing-sort/types';

export const SET_DEAL_TYPE = 'HOMEPAGE_WIZARD/SET_DEAL_TYPE';
export const SET_DOC_ID = 'HOMEPAGE_WIZARD/SET_DOC_ID';
export const SET_PREVIEW_DOC_ID = 'HOMEPAGE/SET_PREVIEW_DOC_ID';
export const SET_SMART_SORT = 'HOMEPAGE_WIZARD/SET_SMART_SORT';
export const GO_BACK = 'HOMEPAGE_WIZARD/GO_BACK';
export const SKIP = 'HOMEPAGE_WIZARD/SKIP';
export const NEXT = 'HOMEPAGE_WIZARD/NEXT';
export const SUBMIT_WIZARD = 'HOMEPAGE_WIZARD/SUBMIT';
export const SET_SEARCH_STR = 'HOMEPAGE_WIZARD/SET_SEARCH_STR';
export const SET_COMMUTE_ADDRESS = 'HOMEPAGE_WIZARD/SET_COMMUTE_ADDRESS';
export const SET_COMMUTE_TYPE = 'HOMEPAGE_WIZARD/SET_COMMUTE_TYPE';
export const SET_COMMUTE_RUSH_HOUR = 'HOMEPAGE_WIZARD/SET_COMMUTE_RUSH_HOUR';
export const SET_OPEN = 'HOMEPAGE_WIZARD/SET_OPEN';
export const SET_PRICE_RANGE = 'HOMEPAGE_WIZARD/SET_PRICE_RANGE';
export const SET_ROOMS_RANGE = 'HOMEPAGE_WIZARD/SET_ROOMS_RANGE';
export const SET_PURCHASING_REASON = 'HOMEPAGE_WIZARD/SET_PURCHASING_REASON';
export const SET_MOVE_IN = 'HOMEPAGE_WIZARD/SET_MOVE_IN';
export const SET_COMMUTE_SUB_STEP = 'HOMEPAGE_WIZARD/SET_COMMUTE_SUB_STEP';
export const SET_COMMUTE_MAX_DISTANCE_TRAIN = 'HOMEPAGE_WIZARD/SET_COMMUTE_MAX_DISTANCE_TRAIN';
export const SET_COMMUTE_MAX_TIME_WALK = 'HOMEPAGE_WIZARD/SET_COMMUTE_MAX_TIME_WALK';
export const SET_COMMUTE_MAX_TIME_CAR = 'HOMEPAGE_WIZARD/SET_COMMUTE_MAX_TIME_CAR';
export const SET_COMMUTE_MAX_TIME = 'HOMEPAGE_WIZARD/SET_COMMUTE_MAX_TIME';

export interface ISetSmartSortMeta {
  value: SortValue;
  toggledOn: boolean;
}

export interface ISetDocIdMeta {
  navigateToNextStep?: boolean;
}

export interface ISetDealTypeMeta {
  autoNavigateToNextStep?: boolean;
}
