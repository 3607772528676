import React, { useEffect } from 'react';
import withEvents from 'analytics/withEvents';
import { AddressAutocompleteSource } from '../types';
import { SearchStringWrapper, SearchStringValue } from './styled';
import { useLocale } from 'locale';

interface NoResultProps {
  str: string;
  source: AddressAutocompleteSource;
  sendZeroResultsEvent?: (text: string, source: AddressAutocompleteSource) => void;
}

const NoResult: React.FC<NoResultProps> = ({ str, source, sendZeroResultsEvent }) => {
  const { t } = useLocale();

  useEffect(() => {
    sendZeroResultsEvent(str, source);
  }, []);

  return (
    <SearchStringWrapper>
      {t('autocompleteInput.noResult.message')}<SearchStringValue weight="bold"> {str}</SearchStringValue>
    </SearchStringWrapper>
  );
};

const NoResultWithEvents = React.memo(withEvents<NoResultProps>((sendEvent) => ({
  sendZeroResultsEvent(text: string, source: AddressAutocompleteSource) {
    sendEvent('search_zero_results', 'search', {
      event: {
        auto_complete_source: source,
        source: 'auto_complete',
      },
      search: {
        text_entered: [ text ],
      },
    });
  },
}))(NoResult));

export default NoResultWithEvents;
