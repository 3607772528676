import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import { TOGGLE_FAVORITES } from 'store/state/app/types';
import { toggleFavorites } from 'store/state/app/actions';
import { AuthGateResponse, getAuthGateResponse } from '../routing/handlers/utils';
import { Route, State as RouteState } from 'config/routes';
import { routeSelector } from 'store/state/selectors/router';

const savedSourceMap = {
  [Route.Shortlist]: 'favorites_page',
  [Route.UnitPage]: 'unit_page',
  [Route.UnitPageCommercial]: 'unit_page',
};

function* toggleFavoritesWorker(action: ReturnType<typeof toggleFavorites>) {
  const { payload: { isFavorite, favorites }, meta } = action;
  const [ { id } ] = favorites;
  const { sendEvent } = yield getContext('analytics');
  const route: RouteState = yield select(routeSelector);

  const eventName = isFavorite ? 'favorites_unsave_listing' : 'favorites_save_listing_click';
  const payload = {
    bulletinId: id,
    event: {
      saved_source: meta.source || savedSourceMap[route.name] || 'list',
    },
  };

  yield call(sendEvent, eventName, 'favorites', payload);

  const authGateResponse: AuthGateResponse = yield call(getAuthGateResponse);

  if (authGateResponse !== AuthGateResponse.Rejected) {
    if (!isFavorite) {
      yield call(sendEvent, 'favorites_save_listing_success', 'favorites', payload);
    }
  }
}

export function* favoritesEventsWorker() {
  yield takeEvery(TOGGLE_FAVORITES, toggleFavoritesWorker);
}
