
import { CommutePreference, CommuteType } from 'utils/entities';

export const commuteTypeToTrackingCommuteType: Record<CommuteType, string> = {
  walk: 'walk',
  bike: 'bike',
  car: 'car',
  commute: 'public',
  train: 'train',
  bus: 'bus',
};


export function createUserCommutePreferences(commutePreference: CommutePreference) {
  if (!commutePreference.commuteType && !(
    commutePreference.location || Object.values(commutePreference.maxCommute).some(v => v !== undefined)
  )) {
    return null;
  }

  let commuteResult = {};

  if (commutePreference.commuteType) {
    commuteResult = {
      commute_type: commuteTypeToTrackingCommuteType[commutePreference.commuteType],
    };
  }

  if (commutePreference.location) {
    commuteResult = {
      ...commuteResult,
      commute_location: {
        location_type: 'address',
        address: commutePreference.text,
        latitude: commutePreference.location ? commutePreference.location.lat : null,
        longitude: commutePreference.location ? commutePreference.location.lng : null,
      },
    };
  }

  return {
    ...commuteResult,
    is_rush_hour: commutePreference.rushHour,
  };
}
