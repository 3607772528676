import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'utils/gallery';

interface IStreetViewImgParams {
  width: number;
  height: number;
}

export const makeDefaultStreetStaticUrl = ({ width, height }: IStreetViewImgParams) => {
  const constructImageURI = useImageUrlBuilder();
  const imageURL = constructImageURI(DEFAULT_PLACEHOLDER_IMAGE, { height, width });

  return imageURL;
};

