import { createSelector } from 'reselect';
import { flow, mapValues, shuffle } from 'lodash';
import {
  IDeveloperProject,
  ProjectStatusType,
  ProjectBuildingStage,
  DeveloperIntegrationLevel,
  MarketplaceType,
  DeveloperProjectMarketplace,
  PoiId, ICommercialProject, IProject,
} from 'utils/entities';
import { developerFiltersSelector } from 'store/state/app/selectors';
import {
  filterProjects,
  isProjectPromoted,
  NonPromotedDeveloperProjectSection,
  projectsBySectionMapper,
  sortNonPromotedProjects,
  sortPromotedProjects,
} from 'screens/DeveloperPage/helpers';
import { DeveloperFiltersState } from 'store/state/app';
import { rawDeveloperSelector } from 'store/state/domainData/selectors/developerById';
import { countFilters } from 'screens/DeveloperPage/components/filters/helpers';
import { Section } from 'components/navigation/subheader/SectionsNavigation';
import { rawToHtml } from 'utils/draft-js';


const buildingStages = new Set([
  ProjectBuildingStage.Planning,
  ProjectBuildingStage.Building,
  ProjectBuildingStage.Done,
]);

export const developerSelector = createSelector(rawDeveloperSelector, rawDeveloper => {

  if (!rawDeveloper) return rawDeveloper;

  const { draftjsDescription, integrationLevel } = rawDeveloper;
  const freeIntegration = integrationLevel === DeveloperIntegrationLevel.Free;
  const basicIntegration = integrationLevel === DeveloperIntegrationLevel.Basic;

  let projects;
  if (freeIntegration) projects = null;
  else if (basicIntegration) projects = rawDeveloper.projects && rawDeveloper.projects.filter(isProjectPromoted);
  else projects = rawDeveloper.projects;

  return {
    ...rawDeveloper,
    description: rawToHtml(draftjsDescription),
    projects,
  };
});
export const developerNameSelector = createSelector(developerSelector, developer => developer ? developer.name : null);

const developerProjectsSelector = createSelector(developerSelector, (developer): IDeveloperProject[] =>
  developer && Array.isArray(developer.projects)
    ? developer.projects.filter(project => project && project.status === ProjectStatusType.Published && buildingStages.has(project.buildingStage))
    : []
);

export const hasProjectsSelector = createSelector(developerProjectsSelector, projects => Boolean(projects && projects.length));

export const hasPromotedProjectsSelector = createSelector(developerProjectsSelector, projects => projects.findIndex(p => isProjectPromoted(p)) !== -1);

export const developerProjectsCountersSelector = createSelector(developerProjectsSelector, (
  projects
): Record<NonPromotedDeveloperProjectSection, number> => {
  const projectsBySection = projectsBySectionMapper(projects);
  return mapValues(
    projectsBySection,
    (items) => Array.isArray(items) ? items.length : 0
  ) as Record<NonPromotedDeveloperProjectSection, number>;
});

export const developerFilteredProjectsSelector = createSelector([
  developerProjectsSelector,
  developerFiltersSelector,
], (projects: IDeveloperProject[], filters: DeveloperFiltersState) => filterProjects(projects, filters));

export const developerFilteredProjectsBySectionSelector = createSelector(developerFilteredProjectsSelector,
  (projects): Record<NonPromotedDeveloperProjectSection, IDeveloperProject[]> =>
    mapValues(
      projectsBySectionMapper(projects),
      (items: IDeveloperProject[]) => sortNonPromotedProjects(items)
    ) as Record<NonPromotedDeveloperProjectSection, IDeveloperProject[]>
);

export const promotedDeveloperProjectsSelector = createSelector(developerProjectsSelector, (projects): IDeveloperProject[] =>
  sortPromotedProjects(projects.filter(project => isProjectPromoted(project))));
export const hasPromotedDeveloperProjectsSelector = createSelector(promotedDeveloperProjectsSelector, (projects): boolean => Boolean(projects && projects.length));

export function promotedProjectsIdsExtractor(projects: IDeveloperProject[], marketplace: MarketplaceType, currentPoi: IProject | ICommercialProject): PoiId[] {
  const isCommercial = marketplace === MarketplaceType.Commercial;

  return projects && shuffle(projects.filter(project => project && currentPoi && project.id !== currentPoi.id
      ? (isCommercial
        ? project.marketplaceType === DeveloperProjectMarketplace.Commercial
        : project.marketplaceType === DeveloperProjectMarketplace.Residential)
      : null
    )
    .map(project => project && project.id)
    .filter(Boolean)
    .slice(0, 10))
    || [];
}

export const hasDeveloperFilteredProjectsSelector = createSelector(developerFilteredProjectsSelector, (projects): boolean => Boolean(projects && projects.length));

export const developerFiltersCountSelector = flow(developerFiltersSelector, countFilters);

export const developerSectionsSelector = createSelector([ developerSelector, hasProjectsSelector ], (developer, hasProjects): Section[] => {
  if (!developer) return [];
  const {
    integrationLevel,
    images,
    staffMembers,
    videoUrl,
    description,
    awards,
    shouldPresentProjectCounter,
    articles,
  } = developer;
  const freeIntegration = integrationLevel === DeveloperIntegrationLevel.Free;
  const basicIntegration = integrationLevel === DeveloperIntegrationLevel.Basic;
  const fullIntegration = integrationLevel === DeveloperIntegrationLevel.Full;
  const showOverview = Boolean((fullIntegration || basicIntegration) && images && images.length);
  const showAbout = fullIntegration && (videoUrl || description || (awards && awards.length) || (shouldPresentProjectCounter && hasProjects))
    || basicIntegration && description;
  const showTeam = fullIntegration && staffMembers && staffMembers.length;
  const showProjects = Boolean(freeIntegration || ((fullIntegration || basicIntegration) && hasProjects));
  const showArticles = Boolean(articles && articles.length);

  const sections: Section[] = [
    (showOverview ? Section.OVERVIEW : null),
    (showAbout ? Section.ABOUT : null),
    (showArticles ? Section.ARTICLES : null),
    (showTeam ? Section.TEAM : null),
    (showProjects ? Section.PROJECTS : null),
  ].filter(Boolean);

  return sections.length ? sections : [ Section.PROJECTS ];
});

export const hasOnlyDeveloperProjectSection = createSelector(developerSectionsSelector, (sections => sections.length === 1 && sections[0] === Section.PROJECTS));
