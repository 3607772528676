import React from 'react';
import { useTransition } from 'react-spring';
import { StepperWrapper, ProgressLine, AnimatedStepWrapper } from './styled';

interface StepperProps {
  steps: JSX.Element[];
  currentIndex: number;
}

export const Stepper: React.FC<StepperProps> = React.memo(props => {
  const { steps, currentIndex } = props;
  const width = ((currentIndex + 1) / steps.length) * 100;
  const stepProgress = <ProgressLine width={width} />;

  const transitions = useTransition(currentIndex, i => i, {
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 1000 } },
    leave: { display: 'none' },
    initial: { opacity: 0 },
  });

  return (
    <StepperWrapper>
      {stepProgress}
      {transitions.map(({ item, key, props: style }) => (
        <AnimatedStepWrapper style={style} key={key}>
          {steps[item]}
        </AnimatedStepWrapper>
      ))}
    </StepperWrapper>
  );
});
