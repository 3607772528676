import { Route, useRoute } from 'config/routes';

export const useCheckUnitPage = () => {
  const { route } = useRoute();
  return route.name === Route.UnitPage;
};

export const useCheckCommercialUnitPage = () => {
  const { route } = useRoute();
  return route.name === Route.UnitPageCommercial;
};
