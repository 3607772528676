import React, { FC, useRef } from 'react';
import { useIntersectionObserver, IIntesertionObserverOptions } from 'hooks/useIntersectionObserver';

interface IntersectionSpyProps {
  onIntersection: (entry: IntersectionObserverEntry) => void;
  intersectionOptions?: IIntesertionObserverOptions;
}

export const IntersectionSpy: FC<IntersectionSpyProps> = props => {
  const { onIntersection, intersectionOptions } = props;
  const spyRef = useRef(null);
  const callback = ([ entry ]: IntersectionObserverEntry[]) => onIntersection(entry);

  useIntersectionObserver(callback, [ spyRef ], intersectionOptions);

  return <div ref={spyRef} />;
};

IntersectionSpy.defaultProps = {
  intersectionOptions: {
    threshold: [ 0, 1 ],
  },
};
