import { styled } from 'ds';
import { SmallText } from 'ds/components/typography';


export const InputWrapper = styled.div<{ hasError: boolean; }>`
  ${({ theme, hasError }) => `
    margin: 0 ${theme.spacing(1)};
    border: 1px solid ${hasError ? theme.colors.alert.red : theme.colors.brand.lighter};
    border-radius: ${theme.borderRadius.small};
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      outline: none;
      border: none;
      font-size: 20px;
      text-align: center;
      color: ${hasError ? theme.colors.alert.red : theme.colors.brand.light};

      ::focus {
        outline: none;
      }
    }
  `}
`;

export const CodeInputsWrapper = styled.div<{ hasBottomMargin: boolean; }>`
  ${({ theme, hasBottomMargin }) => `
    margin: ${theme.spacing(4)} -${theme.spacing(1)} ${hasBottomMargin ? theme.spacing(5) : '0'};
    display: flex;
    justify-content: center;
  `}
`;

export const ErrorText = styled(SmallText)`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.alert.red};
    margin: ${theme.spacing(1.5)} 0 ${theme.spacing(3)};
  `}
`;

