import React, { useState, useEffect } from 'react';
import { useLocale } from 'locale';
import MizrahiBankLogo from 'assets/svg/mizrahi-bank-logo.svg';
import Modal from 'ds/components/modal';
import { noop } from 'lodash';
import { LocalStorage } from 'utils/localStorage';
import { IS_MIZRAHI_BANK_MODAL_SHOWN } from 'consts/localStorageKeys';
import * as Styled from './styled';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useScreenBreakpoint } from 'consts/breakpoints';

export interface MizrahiBankModalProps {
  isOpen : boolean;
  setIsOpen: (isOpen: boolean) => void;
  onFormOpenEvent?: () => void;
  onFormCloseEvent?: () => void;
  onExternalLinkClick?: (url: string) => void;
}

const MizrahiBankModal: React.FC<MizrahiBankModalProps> = ({
  isOpen,
  setIsOpen,
  onFormOpenEvent,
  onFormCloseEvent,
  onExternalLinkClick,
}) => {
  const isDevice = useScreenBreakpoint() < 3;
  const isTablet = useScreenBreakpoint() === 2;
  const constructImageURI = useImageUrlBuilder();
  const imageURL = constructImageURI('homepage/mizrahi-bank-cover.jpg');
  const imageTabletURL = constructImageURI('homepage/mizrahi-bank-cover-tab.png');

  const { t } = useLocale();
  const [ isHidden, setIsHidden ] = useState<boolean>(true);
  const headerButtonUrl = 'https://sc.mizrahi-tefahot.co.il/TofesMashkantaClient/?cID=116&sID=20&pID=586&oID=7&lID=5&trg=TFH&src=7';

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isOpen) {
      timeout = setTimeout(() => {
        onFormOpenEvent();
        setIsHidden(false);
        LocalStorage.set(IS_MIZRAHI_BANK_MODAL_SHOWN, true);
      }, 10000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [ isOpen ]);

  if (isHidden || !isOpen) return null;

  const close = () => {
    setIsOpen(false);
    setIsHidden(true);
  };

  const handleLinkClick = () => {
    onExternalLinkClick(headerButtonUrl);
    close();
  };

  const handleClose = () => {
    onFormCloseEvent();
    close();
  };

  return (
    <Modal
      isOpen
      onClose={noop}
      hideCloseButton
      mobileModalPositionBottom
      desktopModalPositionCenter
      contentWrapperStyle={{ padding: 0, minWidth: 'unset' }}
      isFullWidthMainContent={isDevice}
    >
      <Styled.Wrapper>
        <Styled.Header imageURL={isTablet ? imageTabletURL : imageURL}>
          <MizrahiBankLogo />
        </Styled.Header>
        <Styled.Content>
          <Styled.Title>{t('mizrahiBank.form.title')}</Styled.Title>
          <Styled.Subtitle>{t('mizrahiBank.form.subtitle')}</Styled.Subtitle>
          <Styled.Link weight="bold" href={headerButtonUrl} target="_blank" onClick={handleLinkClick}>{t('mizrahiBank.form.link')}</Styled.Link>
          <Styled.Close onClick={handleClose}>{t('mizrahiBank.form.close')}</Styled.Close>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  );
};

export default MizrahiBankModal;
