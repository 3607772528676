import { SortValue, SortField } from './types';
import { createHashSetFactory, HashSet } from 'utils/hashSetFactory';
import { ITagsValues, DataLayerPoiType } from 'utils/entities';
import { isNumber } from 'lodash';


export const stringify = (v: SortValue) => v && v.join('-');
export const parse = (s: string): SortValue => s && s.replace(/[^A-z0-9-]/, '').split('-') as SortValue;

export const makeSet = createHashSetFactory(stringify);

export function sortsEqual(a: SortValue[], b: SortValue[]) {
  if (a.length !== b.length) return false;
  const aSet = makeSet(a);
  return !b.some((opt) => !aSet.has(opt));
}

export type ISortValueSet = HashSet<SortValue>;

export const sortValueToTagType: Partial<Record<SortField, keyof ITagsValues>> = {
  [SortField.BestSchool]: 'bestSchool',
  [SortField.BestSecular]: 'bestSecular',
  [SortField.BestReligious]: 'bestReligious',
  [SortField.Commute]: 'commute',
  [SortField.Parks]: 'parkAccess',
  [SortField.Safety]: 'safety',
  [SortField.QuietStreets]: 'quietStreet',
  [SortField.DogParks]: 'dogPark',
  [SortField.FamilyFriendly]: 'familyFriendly',
  [SortField.LightRail]: 'lightRail',
};

export const sortFieldToDataLayerType: Partial<Record<SortField, DataLayerPoiType>> = {
  [SortField.BestSchool]: 'school',
  [SortField.Parks]: 'park',
  [SortField.Commute]: 'trainStation',
  [SortField.DogParks]: 'dogPark',
};

export const getBestSchoolTypeIL = (bestSecular: number, bestReligious: number): SortField => {
  let bestSchoolType = null;
  if (isNumber(bestSecular) && isNumber(bestReligious)) {
    if (bestSecular > bestReligious) {
      bestSchoolType = SortField.BestReligious;
    }
    else {
      bestSchoolType = SortField.BestSecular;
    }
  }
  return bestSchoolType;
};
