import React, { useMemo } from 'react';
import { useLocale, WithLocaleProps } from 'locale';
import {
  Body,
  PropertyDetailsText,
  NewPropertyDetailsSeparator,
  PropertyDetailsSeparator,
} from 'ds/components/ListingCard';
import {
  FirstLineLabel,
  LineLabels,
  SecondLineLabel,
  ThirdLineLabel,
} from '../styled';
import { BreakpointValue } from 'consts/breakpoints';
import NotificationTag from '../NotificationTag';
import renderLineItem from 'components/labelLineItem';
import { UniversalCardLineLabels } from '../utils';
import { getBedsRangeParams } from 'utils/addressFormatter';
import { ProjectUniversalCardProps, ExtraProps, RangeValues } from 'components/cards/types';


export interface UniversalProjectCardProps extends ProjectUniversalCardProps, ExtraProps {
  breakpoint: BreakpointValue;
  carousel: JSX.Element;
  handleOnMouseEnter: () => void;
  handleOnMouseLeave: () => void;
}

const getInfoLineConfig = (bedsRange: RangeValues, isPromoted: boolean, priceRange: RangeValues, floorRange: RangeValues, { t, formatMoney }: WithLocaleProps, breakpoint: BreakpointValue) => {
  const isMobile = breakpoint <= 2;
  const showBeds = bedsRange && typeof bedsRange.min === 'number' && typeof bedsRange.max === 'number';
  const showPrice = Boolean(priceRange && priceRange.min);
  const showFloor = floorRange && typeof floorRange.max === 'number' && (showPrice || showBeds);

  return [
    {
      label: UniversalCardLineLabels.Beds,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: showBeds,
      render: () => (
        <PropertyDetailsText
          data-auto="property-rooms"
          isPromoted={isPromoted}
        >
          {t('universalCard.project.listing.bedsRangeLabel', getBedsRangeParams(bedsRange.min, bedsRange.max))}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Floor,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: showFloor,
      render: () => (
        <PropertyDetailsText data-auto="property-floor" isPromoted={isPromoted}>
          {t('universalCard.project.floor', { floor: floorRange.max })}
        </PropertyDetailsText>
      ),
    },
    {
      label: UniversalCardLineLabels.Price,
      separator: isMobile ? <NewPropertyDetailsSeparator /> : <PropertyDetailsSeparator isPromoted={isPromoted} />,
      showCondition: showPrice,
      render: () => (
        <PropertyDetailsText isPromoted={isPromoted}>
          {t('universalCard.project.minPrice', { minPrice: formatMoney(priceRange.min) })}
        </PropertyDetailsText>
      ),
    },
  ];
};

const UniversalProjectProjectCard: React.FC<UniversalProjectCardProps> = (props) => {

  const localeProps = useLocale();
  const { t } = localeProps;
  const {
    withPaddingBottom,
    city,
    notificationTags,
    carousel,
    breakpoint,
    handleOnMouseEnter,
    handleOnMouseLeave,
    projectName,
    bedsRange,
    isPromoted,
    priceRange,
    floorRange,
  } = props;

  const [ notificationTag ] = notificationTags || [];
  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');

  const infoLineConfig = useMemo(() => {
    return getInfoLineConfig(bedsRange, isPromoted, priceRange, floorRange, localeProps, breakpoint).filter(({ showCondition }) => showCondition);
  }, [ bedsRange, isPromoted, priceRange, localeProps, breakpoint, getInfoLineConfig ]);

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {carousel}
      <Body withPaddingBottom={withPaddingBottom} className="universal-card-body-wrapper">
        <LineLabels data-auto="property-details">
          <FirstLineLabel isHighlighted={isPromoted} weight="bold">
            {t('universalCard.project.name', { projectName: name })}
          </FirstLineLabel>
          <SecondLineLabel>
            {infoLineConfig.map(renderLineItem)}
          </SecondLineLabel>
          <ThirdLineLabel>
            <PropertyDetailsText>
              {t('universalCard.project.address', {
                city: props.city || null,
                streetName: props.streetName || null,
                streetNumber: props.streetNumber || null,
              })}
            </PropertyDetailsText>
          </ThirdLineLabel>
          {notificationTag && city ? <PropertyDetailsText faded>{city}</PropertyDetailsText> : null}
        </LineLabels>
        {notificationTag ? <NotificationTag tag={notificationTag} /> : null}
      </Body>
    </div>
  );
};

export default UniversalProjectProjectCard;
