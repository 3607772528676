import React from 'react';
import SearchIcon from 'assets/svg/saved_search-24.svg';
import CheckCircleIcon from 'assets/svg/check-circle-24.svg';
import { Button } from 'ds/components/button';
import { Text } from 'ds/components/typography';
import { useLocale } from 'locale';
import { SavedSearchLink, SaveSearchButtonMobileRoot } from 'components/saved-search/save-button/styled';
import { Route } from 'config/routes';


interface SaveSearchMobileButtonProps {
  isSaved: boolean;
  disabled: boolean;
  onClick: (saveSilently?: boolean) => void;
}

const SaveSearchMobileButton: React.FC<SaveSearchMobileButtonProps> = ({ onClick, isSaved, disabled }) => {
  const { t } = useLocale();

  if (disabled) return null;

  const onButtonClick = () => onClick(true);

  return (
    <SaveSearchButtonMobileRoot>
      {isSaved ? (
        <SavedSearchLink routeName={Route.SavedSearchesPage}>
          <CheckCircleIcon />
          <Text weight="medium">
            {t('savedSearch.saveButton.withImpact.link')}
          </Text>
        </SavedSearchLink>
      ) : (
        <Button
          data-auto="save-search-button"
          data-auto-active={isSaved}
          onClick={onButtonClick}
          size="extraLarge"
        >
          <SearchIcon width={24} height={24} />
          {t('savedSearch.saveButton.withImpact.label')}
        </Button>
      )}
    </SaveSearchButtonMobileRoot>
  );
};

export default SaveSearchMobileButton;
