import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  span {
    text-transform: uppercase;
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.neutrals.grey7};
  margin: 0 ${({ theme }) => theme.spacing(2)};
`;
