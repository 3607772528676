import { searchDocSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { createSelector } from 'reselect';

export const searchInfoEntriesSelector = makeNullSafeDomainGetter(searchDocSelector, 'docIds2Information');
export const searchDocEntriesSelector =
  createSelector(searchInfoEntriesSelector, (docs) => docs && docs.map(doc => doc.document));
export const searchCitiesSelector =
  createSelector(searchDocEntriesSelector, (entries) => entries && entries.map(entry => entry.city));

export const searchDocLoadingSelector = createIsLoadingSelector(searchDocSelector);
