import React, { useRef, useState } from 'react';
import { useSingletonIntersectionObserver } from 'hooks/useSingletonIntersectionObserver';


interface LazyLoadProps<T extends HTMLElement> {
  renderPlaceholder?: (containerRef: React.MutableRefObject<T>) => React.ReactNode;
  children: React.ReactNode;
}

/**
 * Single instance of Intersection Observer based lazy-load
 * Should only be used for performance optimizations.
 * Handle with care.
 */
export const LazyLoad = <T extends HTMLElement>(props: LazyLoadProps<T>) => {
  const containerRef = useRef<T>(null);
  const [ isVisible, setVisible ] = useState(false);

  useSingletonIntersectionObserver((e) => {
    if (e.isIntersecting && !isVisible) {
      setVisible(true);
    }
  }, containerRef);

  return (
    <>
      {isVisible ? props.children : (
        props.renderPlaceholder(containerRef)
      )}
    </>
  );
};

LazyLoad.defaultProps = {
  renderPlaceholder: (ref: React.MutableRefObject<HTMLDivElement>) => <div ref={ref} />,
};
