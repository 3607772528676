import React from 'react';
import { IThumbnail } from 'utils/entities';
import { ShowAt } from 'consts/breakpoints';
import PopupImageGallery from 'components/unit-page/gallery/PopupImageGallery';

interface FullscreenViewProps {
  images: IThumbnail[];
  closeFullscreen(isFloorplan: boolean): () => void;
  slideIndex: number;
}

export const FullscreenView: React.FunctionComponent<FullscreenViewProps> = ({
  closeFullscreen,
  images,
  slideIndex,
}) => {
  const renderFullscreenView = (isMobile: boolean) => {
    return (
      <PopupImageGallery
        mode={isMobile ? 'carousel' : 'slider'}
        images={images}
        slideIndex={slideIndex}
        onCloseClick={closeFullscreen(false)}
      />
    );
  };

  return (
    <>
      <ShowAt to={2}>
        {renderFullscreenView(true)}
      </ShowAt>
      <ShowAt from={3}>
        {renderFullscreenView(false)}
      </ShowAt>
    </>
  );
};
