import styled from '@emotion/styled';
import { H1, Text } from 'ds/components/typography';
import { Button } from 'ds/components/button';


export const Root = styled.div`
  @keyframes slideIn {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1002;
  bottom: 0;
  transform: translateY(100%);
  animation: slideIn 0.5s ease 0.2s forwards;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 103;
  background: rgba(248, 248, 249, 0.9);
`;


export const CloseButton = styled.div`${({ theme }) => `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`}`;

export const MainContentWrapper = styled.div`${({ theme }) => `
  padding: ${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(4)};
  background: ${theme.colors.neutrals.white};
  width: 100%;
  height: 82vh;
  display: flex;
  flex-direction: column;
`}`;


export const DiscoveryInfo = styled.div`${({ theme }) => `
  padding: ${theme.spacing(2.5)};
  background-color: ${theme.colors.brand.madlanGreen};
  border-radius: ${theme.borderRadius.small};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`}`;

export const DiscoveryInfoWithPush = styled(DiscoveryInfo)`${({ theme }) => `
  background-color: ${theme.colors.brand.darker};
`}`;

export const Title = styled(H1.withComponent('div'))`${({ theme }) => `
  margin-top: ${theme.spacing(2)};
  color: ${theme.colors.neutrals.white};
  white-space: pre-wrap;
  text-align: center;
`}`;

export const BodyTxt = styled(Text)`${({ theme }) => `
  margin-top: ${theme.spacing(1)};
  color: ${theme.colors.neutrals.white};
  font-weight: normal;
  white-space: pre-wrap;
  text-align: center;
`}`;

export const Picture = styled.div<{ imgUrl: string }>`${({ imgUrl, theme }) => `
  background: url("${imgUrl}") center center;
  border-radius: ${theme.borderRadius.small};
  background-size: cover;
  flex-grow: 1;
  margin-top: ${theme.spacing(4)};
  position: relative;
`}`;

export const MoreBtn = styled(Button)`${({ theme }) => `
  background-color: ${theme.colors.brand.secondary};
  margin: ${theme.spacing(3)} ${theme.spacing(3)} 0;
`}`;
