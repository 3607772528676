import { Domain } from './index';
import { IListingStatusUpdate, LoadType, MutationType, ResponseByType, IUserListingsResponse } from 'store/sagas/apiService/types';
import { domainInitialState } from './utils';
import { MutationsResponseAction } from '../mutationsResponse';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { BulletinStatusType, IBulletin, ICommercialBulletin } from 'utils/entities';
import { isNull } from 'lodash';

type BulletinsData = ResponseByType[LoadType.UserAllListings];

const genNextState = <T extends IBulletin | ICommercialBulletin>(list: T[], listing: IListingStatusUpdate): T[] => {
  const { id, status: { status } } = listing;
  const bulletinIndex = list.findIndex((bulletin: IBulletin | ICommercialBulletin) => bulletin.id === id);

  if (status === BulletinStatusType.Deleted) {
    return [
      ...list.slice(0, bulletinIndex),
      ...list.slice(bulletinIndex + 1),
    ];
  }

  return [
    ...list.slice(0, bulletinIndex),
    { ...list[bulletinIndex], status: { ...list[bulletinIndex].status, status } },
    ...list.slice(bulletinIndex + 1),
  ];
};

const editBulletinStatus = (state: BulletinsData, action: SetMutationResponseAction<MutationType.EditListingStatus>): BulletinsData => {
  const res = action.meta.response;

  const { listing } = res.data.editListingStatus;

  if (!listing) return state;

  return {
    ...state,
    getUsersListings: {
      commercial: state.getUsersListings.commercial,
      residential: genNextState(state.getUsersListings.residential, listing),
    },
  };
};

const editCommercialBulletinStatus = (state: BulletinsData, action: SetMutationResponseAction<MutationType.EditCommercialListingStatus>): BulletinsData => {
  const res = action.meta.response;

  const { commercialListing } = res.data.editCommercialListingStatus;

  if (!commercialListing) return state;

  return {
    ...state,
    getUsersListings: {
      commercial: genNextState(state.getUsersListings.commercial, commercialListing),
      residential: state.getUsersListings.residential,
    },
  };
};

const editListingAssignedAgent = (state: BulletinsData, action: SetMutationResponseAction<MutationType.EditCommercialListingAssignedAgent>): BulletinsData => {
  const res = action.meta.response;
  const { commercialListing } = res.data.editAssignedAgentCommercialListing;

  if (!commercialListing) return state;

  const { id, agentId } = commercialListing;
  const bulletinIndex = state.getUsersListings.commercial.findIndex((bulletin: ICommercialBulletin) => bulletin.id === id);

  return {
    ...state,
    getUsersListings: {
      residential: state.getUsersListings.residential,
      commercial: [
        ...state.getUsersListings.commercial.slice(0, bulletinIndex),
        { ...state.getUsersListings.commercial[bulletinIndex], agentId },
        ...state.getUsersListings.commercial.slice(bulletinIndex + 1),
      ],
    },
  };
};

const editListingPromotion = (state: BulletinsData, action: SetMutationResponseAction<MutationType.EditCommercialListingPromotionStatus>): BulletinsData => {
  const res = action.meta.response;
  const { commercialListing } = res.data.editCommercialListingPromotionStatus;

  if (!commercialListing) return state;

  const { id, status: { promoted } } = commercialListing;
  const bulletinIndex = state.getUsersListings.commercial.findIndex((bulletin: ICommercialBulletin) => bulletin.id === id);

  return {
    ...state,
    getUsersListings: {
      residential: state.getUsersListings.residential,
      commercial: [
        ...state.getUsersListings.commercial.slice(0, bulletinIndex),
        {
          ...state.getUsersListings.commercial[bulletinIndex],
          status: {
            ...state.getUsersListings.commercial[bulletinIndex].status,
            promoted,
          },
        },
        ...state.getUsersListings.commercial.slice(bulletinIndex + 1),
      ],
    },
  };
};


const usersListingsDataReducer = (state: BulletinsData, action: MutationsResponseAction): BulletinsData => {
  switch (action.type) {
    case SET_MUTATION_RESPONSE: {
      switch (action.mutationType) {
        case MutationType.EditListingStatus:
          return editBulletinStatus(state, action);

        case MutationType.EditCommercialListingStatus:
          return editCommercialBulletinStatus(state, action);

        case MutationType.EditCommercialListingAssignedAgent:
          return editListingAssignedAgent(state, action);

        case MutationType.EditCommercialListingPromotionStatus:
          return editListingPromotion(state, action);

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export function usersListingsDomainReducer(state: Domain<LoadType.UserAllListings> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.UserAllListings> {
  if (action.type === SET_MUTATION_RESPONSE && action.meta.response.errors && action.meta.response.errors.length) {
    return {
      ...state,
      data: null,
      errors: action.meta.response.errors,
    };
  }

  if (isNull(state.data)) return state;

  const reduced = usersListingsDataReducer(state.data, action);
  if (state.data === reduced) return state;

  return {
    ...state,
    data: reduced,
  };
}

export function adminSearchDomainReducer(state: Domain<LoadType.AdminSearch> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.AdminSearch> {
  if (action.type === SET_MUTATION_RESPONSE && action.meta.response.errors && action.meta.response.errors.length) {
    return {
      ...state,
      data: null,
      errors: action.meta.response.errors,
    };
  }

  if (isNull(state.data)) return state;
  const data: IUserListingsResponse = {
    getUsersListings: state.data.userListingsWithOffices.listings,
  };

  const reduced = usersListingsDataReducer(data, action);
  if (data === reduced) return state;

  return {
    ...state,
    data: {
      userListingsWithOffices: {
        ...state.data.userListingsWithOffices,
        listings: reduced.getUsersListings,
      },
    },
  };
}
