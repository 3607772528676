import { connect } from 'react-redux';
import FeedbackPopupView from 'components/common-feedback-popup-view';
import { State } from 'store/state';
import { setFeedbackPopupOpen, setToastMessage } from 'store/state/app/actions';
import { isFeedbackPopupModalOpenSelector } from 'store/state/app/selectors';
import { feedbackOptionsValues, FeedbackPopupType } from 'components/common-feedback-popup-view/utils';


const mapStateToProps = (state: State) => ({
  isPopupModalOpen: isFeedbackPopupModalOpenSelector(state),
  options: feedbackOptionsValues,
  feedbackType: FeedbackPopupType.Feedback,
});

const mapDispatchToProps = {
  onClosePopup: () => setFeedbackPopupOpen({ isOpen: false, insightId: null }),
  onSubmit: () => setToastMessage({ term: 'feedback.popup.toasterMessageConfirmation' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPopupView);
