import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { H3, Text } from 'ds/components/typography';
import {
  MobileMenuBody,
  MobileMenuHeader,
  MobileMenuWrapper,
  UserInfo,
  UserInfoWrapper,
  LinksSectionWrapper,
  MobileMenuContentWrapper,
} from './styled';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { useLocale } from 'locale';
import {
  logout,
  setUserMobileProfileOpen,
  setUserMobileProfileReturnable,
  setSidebarOpen,
} from 'store/state/app/actions';
import { Avatar } from '../header/styled';
import { MenuLink } from './MenuLink';
import { IUserProfileFields, userProfileFieldsSelector } from '../profileFields';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import { mobileProfileMenuActionsSelector, UserActionName, UserAction, actionToIconMobile } from '../userActions';
import Logo from 'components/navigation/Logo';
import CloseIcon from 'assets/svg/dropdown/close.svg';
import ChevronIcon from 'assets/svg/navigation/chevron-right.svg';
import { isUserMobileProfileReturnableSelector } from 'store/state/app/selectors';
import { insideReactNative } from 'react-native/lib/platform';

interface UserMobileProfileMenuProps extends IUserProfileFields {
  onLogout: () => void;
  onSetUserMobileProfileOpen: (isOpen: boolean) => void;
  onSetUserMobileProfileReturnable: (isReturnable: boolean) => void;
  userActions: UserAction[];
  isUserMobileProfileReturnable: boolean;
  onSetSidebarOpen: (isOpen: boolean) => void;
}

const UserMobileProfileMenu: React.FC<UserMobileProfileMenuProps> = ({
  onLogout,
  onSetUserMobileProfileOpen,
  onSetUserMobileProfileReturnable,
  userActions,
  isUserMobileProfileReturnable,
  onSetSidebarOpen,
  ...user
}) => {
  const { t } = useLocale();
  const { avatar, initials, firstName, lastName, isAgent } = user;

  const isInsideRN = insideReactNative();

  const handleLogout = () => {
    onLogout();
    onSetUserMobileProfileOpen(false);
  };

  const closeUserMobileProfile = () => {
    onSetUserMobileProfileOpen(false);
    onSetUserMobileProfileReturnable(false);
  };

  const onLinksClick = () => {
    closeUserMobileProfile();
    onSetSidebarOpen(false);
  };

  return (
    <>
      <OverlayWrapper>
        <MobileMenuWrapper data-auto="mobile-user-menu-wrapper" style={{ minHeight: window ? window.innerHeight : 'auto' }}>
          <MobileMenuContentWrapper>
            <MobileMenuHeader>
              {isUserMobileProfileReturnable ? <ChevronIcon onClick={closeUserMobileProfile} /> : <CloseIcon onClick={closeUserMobileProfile} />}
              <Logo />
            </MobileMenuHeader>
            <MobileMenuBody>
              <UserInfoWrapper>
                <Avatar
                  userInitials={initials}
                  imageURL={avatar}
                  style={{ height: 56, width: 56, fontSize: typographyFontSizeCfg.h2.mobile }}
                />
                <UserInfo data-auto="mobile-user-info">
                  <H3 weight="bold">{firstName} {lastName}</H3>
                </UserInfo>
              </UserInfoWrapper>
              <LinksSectionWrapper>
                {userActions.map(action => (UserActionName.MyListings === action.name) && isAgent && isInsideRN ? null : (
                  <MenuLink
                    key={action.name}
                    href={action.customRoute}
                    target={action.customRoute ? '_blank' : null}
                    routeName={action.route}
                    routeParams={action.params}
                    withArrow
                    fullWidthAnchor
                    isInitialIconColor
                    hasWarning={action.hasWarning}
                    onClick={onLinksClick}
                  >
                    {actionToIconMobile[action.name]}
                    {t('navigation.header.actions', { action: action.name, isAgent, isProfileMobileMenu: true })}
                  </MenuLink>
                ))}
                <MenuLink onClick={handleLogout} isInitialIconColor>
                  {actionToIconMobile[UserActionName.Logout]}
                  <Text data-auto="mobile-log-out">{
                    t('navigation.header.actions', { action: UserActionName.Logout })}
                  </Text>
                </MenuLink>
              </LinksSectionWrapper>
            </MobileMenuBody>
          </MobileMenuContentWrapper>
        </MobileMenuWrapper>
      </OverlayWrapper>
    </>
  );
};

const mapDispatchToProps = {
  onLogout: logout,
  onSetUserMobileProfileOpen: setUserMobileProfileOpen,
  onSetUserMobileProfileReturnable: setUserMobileProfileReturnable,
  onSetSidebarOpen: setSidebarOpen,
};

const mapStateToProps = (state: State) => ({
  profile: userProfileFieldsSelector(state),
  userActions: mobileProfileMenuActionsSelector(state),
  isUserMobileProfileReturnable: isUserMobileProfileReturnableSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps.profile,
  userActions: stateProps.userActions,
  isUserMobileProfileReturnable: stateProps.isUserMobileProfileReturnable,
  ...dispatchProps,
  ...ownProps,
}))(UserMobileProfileMenu);
