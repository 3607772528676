import { ISOString } from 'locale/formatDate';
import {
  IThumbnail,
  PoiType,
  ILatLngPoint,
  DealType,
  BuildingClass,
  MadadSearchResultCategory,
  IGeneralCondition,
  PriceEvent,
  ResolutionPreference,
  IApartmentType,
  PoiId,
} from 'utils/entities';
import { QualityClassOption } from 'components/filters/types';

export enum CardType {
  Bulletin = 'bulletin',
  Project = 'project',
  CommercialProject = 'commercialProject',
  CommercialBulletin = 'commercialBulletin',
}

export interface CommonProps {
  date: ISOString;
  lastUpdatedDate?: ISOString;
  thumbnails: IThumbnail[];
  streetNumber: string;
  logoPath: string;
  streetName: string;
  city: string;
  neighbourhood: string;
  type: PoiType;
  altText: string;
  locationPoint: ILatLngPoint;
  dealType: DealType;
  isActive?: boolean;
}

export interface BulletinUniversalCardProps extends CommonProps {
  area: number;
  price: number;
  bedsCount: number;
  floor: number;
  size?: number;
  buildingClass: BuildingClass;
  isExclusive: boolean;
  cardType: CardType.Bulletin;
  isAgent: boolean;
  madadSearchResult?: MadadSearchResultCategory;
  generalCondition: IGeneralCondition;
  isSearchInWholeCountry?: boolean;
  hasVirtualTour?: boolean;
  eventsHistory?: PriceEvent[];
  unitNumber: string;
  resolutionPreferences: ResolutionPreference;
  officeId?: string;
}

export interface CommercialBulletinUniversalCardProps extends CommonProps {
  area: number;
  price: number;
  bedsCount: number;
  floor: number;
  size?: number;
  buildingClass: BuildingClass;
  isExclusive: boolean;
  cardType: CardType.CommercialBulletin;
  isAgent: boolean;
  madadSearchResult?: MadadSearchResultCategory;
  generalCondition: IGeneralCondition;
  numberOfEmployees?: number;
  isSearchInWholeCountry?: boolean;
  qualityClass?: QualityClassOption;
  hasVirtualTour?: boolean;
  eventsHistory?: PriceEvent[];
  unitNumber: string;
  resolutionPreferences: ResolutionPreference;
  showPromotion?: boolean;
  officeId?: string;
}

export interface ProjectUniversalCardProps extends CommonProps {
  bedsRange: RangeValues;
  priceRange: RangeValues;
  floorRange: RangeValues;
  cardType: CardType.Project;
  projectName: string;
  isPromoted: boolean;
  hasDiscount?: boolean;
  developerId?: string;
}


export interface CommercialProjectUniversalCardProps extends CommonProps {
  priceRange: RangeValues;
  floorRange: RangeValues;
  cardType: CardType.CommercialProject;
  projectName: string;
  apartmentType: IApartmentType[];
  isPromoted: boolean;
  hasDiscount?: boolean;
  developerId?: string;
}

export interface ExtraProps {
  hideLogoPreview?: boolean;
  showIsNewLabel?: boolean;
  showAddToFavourites?: boolean;
  showPriceDropLabel?: boolean;
  isInactive?: boolean;
  emptyLazyLoadPlaceholder?: boolean;
  neighborhoodValue?: string;
  notificationTags?: string[];
  disableSwipe?: boolean;
  onlyOneImageShouldBeDisplayed?: boolean;
  id?: PoiId;
  officeId?: string;
  developerId?: string;
  withPaddingBottom?: boolean;
  showMarketplaceType?: boolean;
  hideTags?: boolean;
}

export interface RangeValues {
  min: number;
  max: number;
}

export type UniversalCardPoiUnion = ProjectUniversalCardProps | BulletinUniversalCardProps | CommercialProjectUniversalCardProps | CommercialBulletinUniversalCardProps;
