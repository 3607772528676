import React from 'react';
import { SelectItem } from './';
import {
  SelectItemInnerMarkup,
  SelectItemsWrapper,
} from './styled';

export enum SelectStyleType {
  circle,
  square,
}

export type SelectValueType = string | number | boolean;

interface SelectItemOption<T extends SelectValueType> {
  label: React.ReactNode;
  value: T;
  icon?: JSX.Element;
}

interface StyleTypeException<T extends SelectValueType> {
  values: T[];
  styleType: SelectStyleType;
}

interface UploadBulletinSelectProps<T extends SelectValueType> {
  options: Array<SelectItemOption<T>>;
  isUnSelectable?: boolean;
  styleType?: SelectStyleType;
  styleTypeException?: StyleTypeException<T>;
}

interface UploadBulletinSelectPropsSingle<T extends SelectValueType> extends UploadBulletinSelectProps<T> {
  isMultiple?: false;
  onChange: (value: T) => void;
  value?: T;
  touched?: boolean;
  error?: string;
}

interface UploadBulletinSelectPropsMultiple<T extends SelectValueType> extends UploadBulletinSelectProps<T> {
  isMultiple?: true;
  onChange: (value: T[]) => void;
  value?: T[];
  touched?: boolean;
  error?: string;
}


export class Select<T extends SelectValueType> extends React.Component<UploadBulletinSelectPropsSingle<T> | UploadBulletinSelectPropsMultiple<T>> {
  public static defaultProps = {
    isMultiple: false,
    isUnSelectable: false,
    styleType: SelectStyleType.square,
    error: '',
    touched: false,
  };

  public onSelect = (value: T) => () => {
    const { isUnSelectable } = this.props;

    if (this.props.isMultiple === true) {
      if (isUnSelectable && this.props.value.includes(value)) {
        this.props.onChange(this.props.value.filter(val => val !== value));
      }
      else {
        this.props.onChange([ ...this.props.value, value ]);
      }
    }
    else {
      if (isUnSelectable) {
        this.props.onChange(this.props.value === value ? undefined : value);
      }
      else {
        this.props.onChange(value);
      }
    }
  };

  private static renderInnerMarkup(icon: JSX.Element, label: React.ReactNode): JSX.Element {
    return (
      <SelectItemInnerMarkup data-auto={`select-${label}`}>
        {icon ? icon : null}
        {label}
      </SelectItemInnerMarkup>
    );
  }

  private optionMapper = ({ label, value, icon }: SelectItemOption<T>, index: number) => {
    const { styleType, styleTypeException } = this.props;
    const isActive = this.props.isMultiple ? this.props.value.includes(value) : value === this.props.value;
    const optionMapped = {
      innerMarkup: Select.renderInnerMarkup(icon, label),
      onSelect: this.onSelect(value),
      isActive,
      styleType,
    };

    if (styleTypeException && styleTypeException.values.includes(value)) {
      return ({
        ...optionMapped,
        styleType: styleTypeException.styleType,
      });
    }

    return optionMapped;
  };

  public render() {
    const { options, error, touched } = this.props;

    const selectItems = options.map(this.optionMapper);

    return (
      <SelectItemsWrapper className="select-items-wrapper">
        {selectItems.map((item, index) => (
          <SelectItem key={index} {...item} error={touched && error} />
        ))}
      </SelectItemsWrapper>
    );
  }
}
