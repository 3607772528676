import styled from '@emotion/styled';
import { breakpointToContentSlotPadding } from 'ds/components/Layout';
import { BreakpointValue } from 'consts/breakpoints';
import { SpacingScale } from 'ds/theme/spacing';

interface ISlideItem {
  dir: 'prev' | 'next';
  buttonShadowLevel?: string;
}

export const SlideBtn = styled.div<ISlideItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  ${({ theme, dir }) => Object.keys(breakpointToContentSlotPadding).map(key => {
    const padding = breakpointToContentSlotPadding[key];
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
      ${dir === 'next'
        ? theme.isRTL ? 'left' : 'right'
        : theme.isRTL ? 'right' : 'left'
      }: ${theme.spacing(padding * .5 as SpacingScale)};
    `;
  })}
  top: calc(50% - 16px);
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutrals.white};
  box-shadow: ${({ theme, buttonShadowLevel }) => buttonShadowLevel ? buttonShadowLevel : theme.shadow.level3};

  > svg { transform: ${(props) => props.dir === 'next'
    ? props.theme.isRTL ? '' : 'rotate(180deg)'
    : props.theme.isRTL ? 'rotate(180deg)' : ''}}
`;

export const SlideArrow = styled.div<ISlideItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  height: 40px;
  width: 40px;
  ${({ theme, dir }) => Object.keys(breakpointToContentSlotPadding).map(key => {
    const padding = breakpointToContentSlotPadding[key];
    return theme.media.showAt({ at: (+key as BreakpointValue) }) `
        ${dir === 'next'
      ? theme.isRTL ? 'left' : 'right'
      : theme.isRTL ? 'right' : 'left'
    }: ${theme.spacing(padding * .5 as SpacingScale)};
      `;
  })}
  top: calc(50% - 16px);
  cursor: pointer;

  > svg {
    transform: ${(props) => props.dir === 'next'
      ? props.theme.isRTL ? '' : 'rotate(180deg)'
      : props.theme.isRTL ? 'rotate(180deg)' : ''};
    height: 100%;
    width: 100%;
    filter: drop-shadow(${({ theme }) => theme.shadow.icon});

    path {
      fill: ${({ theme }) => theme.colors.neutrals.white};
    }
  }
`;

export const CenteredCarouselControls = styled.div`
  [dir="prev"],
  [dir="next"] {
    top: calc(50% - 16px);
  }
`;
