import React from 'react';
import Modal from 'ds/components/modal';
import CrossIcon from 'assets/svg/close-modal.svg';
import WarningIcon from 'assets/svg/warning-icon.svg';
import { useLocale } from 'locale';
import { IBulletin, ICommercialBulletin, BulletinStatusType } from 'utils/entities';
import { EditListingStatusParams } from 'store/state/uploadBulletin/actions';
import { Text } from 'ds/components/typography';
import * as Styled from './styled';


interface DeleteListingPopupProps {
  poi: IBulletin | ICommercialBulletin;
  isOpen: boolean;
  editListingStatus: (data: EditListingStatusParams) => void;
  setModalOpenStatus: (openStatus: boolean) => void;
}


export const DeleteListingPopup: React.FC<DeleteListingPopupProps> = (props) => {
  const {
    poi,
    isOpen,
    editListingStatus,
    setModalOpenStatus,
  } = props;

  const { t } = useLocale();
  const poiExist = poi && poi.id;
  if (!(isOpen && poiExist)) return null;

  const onClose = () => setModalOpenStatus(false);
  const onDelete = () => {
    editListingStatus({ status: BulletinStatusType.Deleted, id: poi.id, type: poi.type });
    onClose();
  };

  return (
    <Modal
      isOpen
      hideCloseButton={true}
      onClose={onClose}
    >
      <Styled.Root>
        <Styled.CloseWrapper onClick={onClose}>
          <CrossIcon width={24} height={24} />
        </Styled.CloseWrapper>
        <Styled.WarningWrapper>
          <WarningIcon />
        </Styled.WarningWrapper>
        <Text weight="bold">{t('manageBulletinPage.bulletin.deleteOverlay.title')}</Text>
        <Styled.CardOverlaySubtitle>
          {t('manageBulletinPage.bulletin.deleteOverlay.subtitle')}
        </Styled.CardOverlaySubtitle>
        <Styled.DeleteButton onClick={onDelete} fullWidth size="large">
          {t('manageBulletinPage.bulletin.deleteOverlay.button')}
        </Styled.DeleteButton>
        <Styled.CancelButtonWrapper>
          <Styled.CancelButton mode="secondary" onClick={onClose}>
            {t('manageBulletinPage.bulletin.deleteOverlay.cancel')}
          </Styled.CancelButton>
        </Styled.CancelButtonWrapper>
      </Styled.Root>
    </Modal>
  );
};
