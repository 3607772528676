import { useContext } from 'react';
import { getApolloContext } from '@apollo/react-hooks';
import userUploadUrl from '../store/sagas/apiService/queries/userUploadUrl.gql';
import { TokenContext } from '../helpers/userToken';

type UploadType =  'bulletin' | 'exclusiveForm' | 'agent';

export const useUploadFiles = () => {
  const tokenStore = useContext(TokenContext);
  const { client } = useContext(getApolloContext());

  return  async (
    files: File[],
    uploadType: UploadType
  ): Promise<string[]> => {
    const uploadFile = async (file: File) => {
      const {
        data: {
          userUploadUrl: { uploadUrl, filePath },
        },
      } = await client.query({
        query: userUploadUrl,
        variables: { fileName: file.name, fileType: file.type, type: uploadType, userToken: tokenStore.get() },
        fetchPolicy: 'no-cache',
      });

      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type && file.type.length === 0 ? 'application/octet-stream' : file.type,
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return filePath;
    };

    try {
      return await Promise.all(files.map(uploadFile));
    }
    catch (e) {
      throw new Error(e.message);
    }
  };
};

