import { createSelector } from 'reselect';
import { groupBy, keyBy } from 'lodash';
import { IInsight } from 'utils/entities';

import { insightsListSelector as baseInsightsListSelector } from 'store/state/domainData/selectors';
import { makeExpandedInsightSelector } from 'store/state/selectors/insights';


const getId = ({ id }: IInsight) => id;
const getType = ({ type }: IInsight) => type;

export const insightsListSelector = createSelector([
  baseInsightsListSelector,
], (list) => list.filter((i) => i.geoData));

export const insightsByIdsSelector = createSelector([
  insightsListSelector,
], (insights) => keyBy(insights, getId));

export const expandedInsightSelector = makeExpandedInsightSelector(insightsByIdsSelector);

export const insightsByTypeSelector = createSelector([
  insightsListSelector,
], (insights) => groupBy(insights, getType));
