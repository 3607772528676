import React from 'react';
import { State as RouteState, Route } from 'config/routes';
import { Wrapper, Breadcrumbs, LinksWrapper, SocialLinks, SocialLink, GeneralFooter, Copyright, GeneralLinks, Arrow } from './styled';
import { FooterSection, SocialLinkType, FooterSectionType } from './types';
import { WithContentSlotSidePaddingContainer } from 'ds/components/Layout';
import { useLocale } from 'locale';
import { SmallText } from 'ds/components/typography';
import {
  buildNearbyNeighbourhoodsSection,
  socialLinks as socialLinkSettings,
  GENERAL_LINKS,
} from './config';

import FacebookIcon from 'assets/svg/fb.svg';
import TwitterIcon from 'assets/svg/twitter.svg';
import LinkedinIcon from 'assets/svg/linkedin.svg';
import ChevronIcon from 'assets/svg/chevron-right.svg';
import LocalizeLogoIcon from 'assets/svg/localize-logo-mini-v3.svg';
import { Link } from 'components/link';
import { Section, routeReset } from './Section';
import { IDocItem, User, DealType, MarketplaceType } from 'utils/entities';
import { StyledShowAt } from 'ds/theme/mediaBreakpoints';
import { ShowAt } from 'consts/breakpoints';

/** @todo: insert social icons once the spec is ready */
export const socialLinkToIcon: Record<SocialLinkType, JSX.Element> = {
  facebook: <FacebookIcon width={16} height={16} />,
  twitter: <TwitterIcon width={16} height={16} />,
  linkedin: <LinkedinIcon width={16} height={16} />,
  localize: <LocalizeLogoIcon width={16} height={16} />,
};

interface FooterProps {
  route: RouteState;
  breadcrumbs: IDocItem[];
  nearbyNeighbourhoods: IDocItem[];
  sections: FooterSection[];
  cityFooter: FooterSection;
  user: User;
  noPadding?: boolean;
  noBreadcrumbs?: boolean;
  isWide?: boolean;
  marketplace: MarketplaceType;
  dealTypeFromBulletin?: DealType;
  alignItems?: 'stretched' | 'right';
}

export const BottomFooter: React.FC<{ withSitemap?: boolean }> = ({ withSitemap = true }) => {
  const { t } = useLocale();
  return (
    <>
      <GeneralLinks>
        {GENERAL_LINKS.map(link => (
          <a target="_blank" rel="nofollow" href={link.href} key={link.type}>
            <SmallText data-auto={`${link.type}-link`}>
              {t('footer.staticLink', { type: link.type })}
            </SmallText>
          </a>
        ))}

        {withSitemap ? (
          <a href={`/${Route.Sitemap}`} key="sitemap">
            <SmallText data-auto="site-map-link">
            {t('footer.staticLink', { type: 'sitemap' })}
            </SmallText>
          </a>
        ) : null}

        <a target="_blank" rel="nofollow" href="https://localizeos.com" key="localizeos.com">
          <SmallText>
            {t('localize.city')}
          </SmallText>
        </a>

      </GeneralLinks>
      <Copyright data-auto="copyright">{t('footer.copyright', { year: new Date().getFullYear() })}</Copyright>
    </>
  );
};

export const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useLocale();
  const { route, breadcrumbs, nearbyNeighbourhoods, noPadding, user, sections, noBreadcrumbs, isWide, cityFooter, marketplace, dealTypeFromBulletin, alignItems = 'stretched' } = props;
  const gridAutoFill = alignItems === 'right';

  const isCommercial = marketplace === MarketplaceType.Commercial;
  const dealType = route.params.dealType || dealTypeFromBulletin || DealType.Rent;
  const isSearch = route.name === Route.Search || route.name === Route.SearchCommercial;

  const nearbyNeighbourhoodsSection: FooterSection = nearbyNeighbourhoods
    ? buildNearbyNeighbourhoodsSection(nearbyNeighbourhoods)
    : null;

  const socialLinks = socialLinkSettings.map(link => (
    <a href={link.url} key={link.type} target="_blank">
      <SocialLink isSearch={isSearch}>
        {socialLinkToIcon[link.type]}
      </SocialLink>
    </a>
  ));

  const resetScroll = () => {
    window.scrollTo(0, 0);
  };

  const extendedSections = nearbyNeighbourhoodsSection && !isCommercial
    ? [ nearbyNeighbourhoodsSection, ...sections ]
    : sections;

  return (
    <Wrapper>
      <WithContentSlotSidePaddingContainer noPadding={noPadding}>
        {noBreadcrumbs ? null : (
          <Breadcrumbs data-auto="breadcrumbs">
            <Link routeName={Route.Home}>
              <SmallText>{t('footer.homepage')}</SmallText>
            </Link>
            {breadcrumbs.map((breadcrumb, i) => (
              <React.Fragment key={breadcrumb.docId}>
                <Arrow specularY>
                  <ChevronIcon width={12} height={20} />
                </Arrow>
                {
                  (i + 1 === breadcrumbs.length) ? (
                    <SmallText data-auto="breadcrumb-text">{breadcrumb.text}</SmallText>
                  ) : (
                    <Link
                      data-auto="breadcrumb-link"
                      routeName={Route.Search}
                      routeParams={{ ...route.params, ...routeReset, dealType, term: [ breadcrumb.docId ] }}
                      onClick={resetScroll}
                    >
                      <SmallText data-auto="breadcrumb-text">{breadcrumb.text}</SmallText>
                    </Link>
                  )
                }
              </React.Fragment>
            ))}
          </Breadcrumbs>
        )}
        <LinksWrapper data-auto="footers" isWide={isWide} gridAutoFill={gridAutoFill}>
          {extendedSections.filter(s => isCommercial ? s.type !== FooterSectionType.ApartmentType : true).map(section => (
            <Section route={route} key={section.type} section={section} />
          ))}
          {cityFooter ? <Section route={route} key={cityFooter.type} section={cityFooter} /> : null}
        </LinksWrapper>
        <StyledShowAt from={2}>
          <SocialLinks>
            {socialLinks}
          </SocialLinks>
        </StyledShowAt>
      </WithContentSlotSidePaddingContainer>
      <GeneralFooter isSearch={isSearch}>
        <WithContentSlotSidePaddingContainer data-auto="lower_links">
          <StyledShowAt at={1}>
            <SocialLinks>
              {socialLinks}
            </SocialLinks>
          </StyledShowAt>
          <BottomFooter />
        </WithContentSlotSidePaddingContainer>
        <ShowAt to={2}>
          {isSearch && user ? (
            // make some space for user mobile sticky nav
            <div style={{ height: 100 }} />
          ) : null}
        </ShowAt>
      </GeneralFooter>
    </Wrapper>
  );
};
