import { Route, State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { cancel, fork, getContext, put, all, call, select } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { loadInsights } from '../loadInsights';
import { ISearchPoiVariables } from 'utils/entities';
import { makeSearchVariablesSelector } from 'store/state/app/selectors';
import { resolvedStrictLocalDocSelector } from 'store/state/domainData/selectors';
import { queryProjectsAds, fetchArticles, getCityDocIdFromDocument } from '../utils';

export function* localPageHandler(params: RouteState) {
  const docId = params.params.id;

  try {
    const insightsTask = yield fork(loadInsights, { docId, user: null });

    const queries = [
      call(queryData, {
        loadType: LoadType.StrictLocalDoc,
        meta: { variables: { docId } },
      }),
      call(queryData, {
        loadType: LoadType.LocalDoc,
        meta: { variables: { docId } },
      }),
      call(queryData, {
        loadType: LoadType.FooterByDocId,
        meta: { variables: { cityDocId: docId, footerContext: 'area' } },
      }),
      call(queryData, {
        loadType: LoadType.SearchAwardsByDocIds,
        meta: { variables: { args: { docIds: [ docId ] } } },
      }),
    ];

    try {
      yield all(queries);
    }
    catch (e) {
      const logger = yield getContext('logger');
      logger.error('A page docId query failed, redirecting Home.', e);
      yield cancel(insightsTask);
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
      return;
    }
    const document = yield select(resolvedStrictLocalDocSelector);
    const variables: ISearchPoiVariables = yield select(makeSearchVariablesSelector(resolvedStrictLocalDocSelector));
    yield fork(fetchArticles, getCityDocIdFromDocument([ document ]), 'areaPage');
    const projectLoadOptions: LoadOptions<LoadType.SearchProjectList> = {
      loadType: LoadType.SearchProjectList,
      meta: { variables },
    };

    yield fork(queryData, projectLoadOptions);

  }
  catch (e) {
    const logger = yield getContext('logger');
    logger.error('One of queries failed in local page handler, redirecting Home.', e);
    yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }

  yield fork(queryProjectsAds, docId, 'information');
}
