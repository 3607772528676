import { State } from 'store/state';
import { findLast, flow, pick } from 'lodash';
import { COMMERCIAL_ROUTES, Route, State as RouteState } from 'config/routes';
import { createSelector } from 'reselect';
import { DealType, MarketplaceType, PoiId } from 'utils/entities';
import { unitPageBulletinSelector, projectPageProjectSelector } from 'store/state/domainData/selectors';
import { fixedMultiPreviewSelector } from 'store/state/app/selectors';
import { TabuPollingResponse } from 'store/sagas/apiService/queries/fetchTabu';
import config from 'config';
import { IFiltersState } from 'components/filters/types';


export enum DialogWithURLType {
  TabuWizard = 'tabuWizard',
}

export interface TabuDialogMeta extends Omit<TabuPollingResponse['docId2NesachTabuPolling'], 'isReady'> {
  dialog: DialogWithURLType.TabuWizard;
}

export type DialogMeta = TabuDialogMeta;

export interface DialogUrl {
  type: DialogWithURLType;
  meta: DialogMeta;
}

const baseRouterSelector = (state: State) => state.router;

const baseRouteSelector = (state: State) => {
  const { routesHistory } = state.router;
  return routesHistory[routesHistory.length - 1];
};

const basePrevRouteSelector = (state: State) => {
  const { routesHistory } = state.router;
  const { length } = routesHistory;
  return length >= 2 ? routesHistory[length - 2] : null;
};

export const routeResultFn = (route: RouteState) => {
  if (route && route.name === Route.LocalPage && !route.params.dealType) {
    return {
      ...route,
      params: {
        ...route.params,
        dealType: DealType.Buy,
      },
    };
  }

  return route;
};

export const routeSelector = createSelector(
  [ baseRouteSelector ],
  routeResultFn
);

export const routeHistorySelector = createSelector(
  [ baseRouterSelector ],
  (router) => router.routesHistory
);

export const prevSearchFilterSelector = createSelector(
  [ baseRouterSelector ],
  (router) => {
    const lastSearchRoute = findLast(router.routesHistory, r => r.name === Route.Search);
    const lastBuySearchRoute = findLast(router.routesHistory, r => r.name === Route.Search && r.params.filters && r.params.filters.dealType === DealType.Buy);
    const lastRentSearchRoute = findLast(router.routesHistory, r => r.name === Route.Search && r.params.filters && r.params.filters.dealType === DealType.Rent);

    if (!lastSearchRoute) return undefined;

    const commonFilters: Partial<IFiltersState> = {
      ...lastSearchRoute.params.filters,
      priceRange: null,
      ppmRange: null,
    };

    const lastBuyPricingFilter: Pick<IFiltersState, 'priceRange' | 'ppmRange'> = lastBuySearchRoute ? {
      priceRange: lastBuySearchRoute.params.filters && lastBuySearchRoute.params.filters.priceRange || null,
      ppmRange: lastBuySearchRoute.params.filters && lastBuySearchRoute.params.filters.ppmRange || null,
    } : null;

    const lastRentPricingFilter: Pick<IFiltersState, 'priceRange' | 'ppmRange'> = lastRentSearchRoute ? {
      priceRange: lastRentSearchRoute.params.filters && lastRentSearchRoute.params.filters.priceRange || null,
      ppmRange: lastRentSearchRoute.params.filters && lastRentSearchRoute.params.filters.ppmRange || null,
    } : null;

    return {
      residentialBuyFilter: {
        ...commonFilters,
        ...lastBuyPricingFilter,
      },
      residentialRentFilter: {
        ...commonFilters,
        ...lastRentPricingFilter,
      },
    };
  }
);

export const routeNameSelector = flow(routeSelector, (route) => route.name);
export const routeMetaIdSelector = flow(routeSelector, (route) => route.meta.id);

export const routeFixedMultiPreviewSelector = createSelector([
  routeMetaIdSelector,
  fixedMultiPreviewSelector,
], (routeId, fixedMultiPreview) => fixedMultiPreview[routeId] as PoiId[]);

export const prevRouteSelector = createSelector(
  [ basePrevRouteSelector ],
  (route: RouteState) => route && routeResultFn(route)
);

export const getRouteParams = flow(routeSelector, ({ params }) => params);
export const getSaveSearchRouteParams = createSelector(getRouteParams, (data) => pick(data, [ 'dealType', 'filters', 'term' ]));

export const dialogFromURLSelector = flow(getRouteParams, ({ dialog }) => dialog);
const isDialogOpenFromURLSelectorFactory = (sel: (_: State) => DialogUrl, dialogToCheck: DialogWithURLType) => {
  return flow(sel, (dialog) => dialog && (dialog.type === dialogToCheck));
};

export const isTabuWizardDialogOpenSelector = isDialogOpenFromURLSelectorFactory(dialogFromURLSelector, DialogWithURLType.TabuWizard);

export enum AppMode {
  Check = 'check',
  Buy = 'buy',
  Rent = 'rent',
}

export const DEAL_TYPE_TO_APP_MODE: Record<DealType, AppMode> = {
  [DealType.Buy]: AppMode.Buy,
  [DealType.Rent]: AppMode.Rent,
};

export const APP_MODE_TO_DEAL_TYPE: Partial<Record<AppMode, DealType>> = {
  [AppMode.Buy]: DealType.Buy,
  [AppMode.Rent]: DealType.Rent,
  [AppMode.Check]: undefined,
};


export const appModeSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
], ({ name, params }, bulletin, project): AppMode => {
  const MODE_ROUTES = new Set([ Route.StreetPage, Route.AddressPage, Route.UnitPage, Route.UnitPageCommercial, Route.Search, Route.SearchCommercial, Route.ProjectPage, Route.ProjectPageCommercial, Route.LocalPage ]);

  if (((name === Route.AddressPage || name === Route.LocalPage || name === Route.StreetPage || name === Route.Sold) && !params.dealType) || name === Route.CheckAddress) {
    return AppMode.Check;
  }
  if (params.dealType && MODE_ROUTES.has(name)) {
    return DEAL_TYPE_TO_APP_MODE[params.dealType];
  }
  if ((name === Route.UnitPage || name === Route.UnitPageCommercial) && bulletin) {
    return DEAL_TYPE_TO_APP_MODE[bulletin.dealType];
  }

  if (name === Route.ProjectPage && project) {
    return DEAL_TYPE_TO_APP_MODE[project.dealType];
  }

  return null;

});

export const isMobileItemMapActiveSelector = createSelector([
  routeSelector,
], (route) => {
  const ITEM_PAGES = new Set([ Route.UnitPage, Route.UnitPageCommercial, Route.ProjectPageCommercial, Route.ProjectPage, Route.AddressPage ]);
  return route.params.isMapActive && ITEM_PAGES.has(route.name);
});

export const isUGCReviewPageActiveSelector = createSelector(routeSelector, (route) => route.params.isActiveReviewResultPage);

export const isSearchInWholeCountrySelector = createSelector(routeSelector, (route) => {
  return route.params.term && route.params.term[0] === 'ישראל';
});


export function marketplaceByRouteName(routeName: Route) {
  return COMMERCIAL_ROUTES.has(routeName) ? MarketplaceType.Commercial : MarketplaceType.Residential;
}

export const marketplaceSelector = createSelector([
  routeNameSelector,
  getRouteParams,
], (routeName, params): MarketplaceType => {
  if (params.marketplace) return params.marketplace;

  return marketplaceByRouteName(routeName);
});

export const hiddenListingModalSelector = createSelector([ getRouteParams ], params => !!params.hiddenListingModal);

export const hasDemoProjectFlagSelector = flow(getRouteParams, (p) => p.projectIL === 'DEMO');

export const isMapExpandedSelector = createSelector([ routeSelector ],  (route) => route.params.isMapExpanded);

export const noPopupsSelector = flow(getRouteParams, (params) => params && params.noPopups);

export const showAgentConsoleSelector = createSelector(getRouteParams, (params): boolean => {
  return Boolean(params.showAgentConsole) && config.mode !== 'production';
});
