import React from 'react';
import {
  CategoryWrapper,
} from './styled';

export interface ImagesCategoryProps {
  name: string;
  icon: React.ReactNode;
}

export const ImagesCategory: React.FC<ImagesCategoryProps> = ({ name, icon }) => (
  <CategoryWrapper>
    <div>{icon}</div>
    <div>{name}</div>
  </CategoryWrapper>
);
