import styled from '@emotion/styled';
import { H3, Text } from 'ds/components/typography';


export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled(H3)`
  ${({ theme }) => `
    text-align: center;
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(1)};
  `}
`;

export const SimpleText = styled(Text)`
  text-align: center;
`;
