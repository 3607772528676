import React from 'react';
import { useLocale } from 'locale';
import { AdditionalDataTitle, QuietnessMeterStage, QuietnessMeterWrapper, QuietnessMeterBar, QuietnessMeterBarIndicators } from './styled';
import { SmallText } from 'ds/components/typography';

const quietnessStages = [ 0, 1, 2, 3 ];

export const QuietnessMeter: React.FC<{ value: number }> = ({ value }) => {
  const { t } = useLocale();
  return (
    <QuietnessMeterWrapper>
      <AdditionalDataTitle style={{ marginBottom: 32 }}>{t('widget.quietStreet.quietnessMeterTitle')}</AdditionalDataTitle>
      <QuietnessMeterBar>
        {quietnessStages.map(stage => <QuietnessMeterStage key={stage} isActive={stage === value} />)}
      </QuietnessMeterBar>
      <QuietnessMeterBarIndicators>
        <SmallText>{t('widget.quietStreet.quiet')}</SmallText>
        <SmallText>{t('widget.quietStreet.loud')}</SmallText>
      </QuietnessMeterBarIndicators>
    </QuietnessMeterWrapper>
  );
};
