export const INIT = 'INSIGHTS_CONTEXT/INIT';
export const SET_MOBILE_MAP_TYPE = 'INSIGHTS_CONTEXT/SET_COCO_TYPE';
export const SET_ACTIVE_INSIGHT = 'INSIGHTS_CONTEXT/SET_ACTIVE_INSIGHT';
export const SET_TRANSPORTATION_STATE = 'INSIGHTS_CONTEXT/SET_TRANSPORTATION_STATE';
export const SET_ACTIVE_NUISANCE_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_NUISANCE_CAROUSEL_IDX';
export const SET_ACTIVE_PRICE_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_PRICE_CAROUSEL_IDX';
export const SET_ACTIVE_SCHOOL_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_SCHOOL_CAROUSEL_IDX';
export const SET_ACTIVE_SAFETY_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_SAFETY_CAROUSEL_IDX';
export const SET_ACTIVE_LIVABILITY_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_LIVABILITY_CAROUSEL_IDX';
export const SET_ACTIVE_CONSTRUCTION_ID = 'INSIGHTS_CONTEXT/SET_ACTIVE_CONSTRUCTION_ID';
export const SET_SCHOOLS_ACCORDION = 'INSIGHTS_CONTEXT/SET_SCHOOLS_ACCORDION';
export const SET_PRICES_STATE = 'INSIGHTS_CONTEXT/SET_PRICES_STATE';
export const SET_PRICES_TAB = 'INSIGHTS_CONTEXT/SET_PRICES_TAB';
export const SET_PRICES_ESTIMATION_INDEX = 'INSIGHTS_CONTEXT/SET_PRICES_ESTIMATION_INDEX';
export const SET_PRICES_HISTORY_INDEX = 'INSIGHTS_CONTEXT/SET_PRICES_HISTORY_INDEX';
export const SET_PRICES_BEDS_FILTER = 'INSIGHTS_CONTEXT/SET_PRICES_BEDS_FILTER';
export const SET_PRICES_YEAR_FILTER = 'INSIGHTS_CONTEXT/SET_PRICES_YEAR_FILTER';
export const SET_PRICES_FINAL_TRANSACTIONS_FILTER = 'INSIGHTS_CONTEXT/SET_PRICES_FINAL_TRANSACTIONS_FILTER';
export const SET_PRICES_LAST_EXPANDED_ROW_SALE_ID = 'INSIGHTS_CONTEXT/SET_PRICES_LAST_EXPANDED_ROW_SALE_ID';
export const SET_BUILDING_PERMIT_INDEX = 'INSIGHTS_CONTEXT/SET_BUILDING_PERMIT_INDEX';
export const SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_IMPACT_INSIGHT_CAROUSEL_IDX';
export const SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_AREA_HIGHLIGHTS_ABOUT_TO_COME_CAROUSEL_IDX';
export const SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX = 'INSIGHTS_CONTEXT/SET_ACTIVE_AREA_HIGHLIGHTS_GOOD_TO_KNOW_CAROUSEL_IDX';
export const SET_HOVERED_SCHOOL_ID = 'INSIGHTS_CONTEXT/SET_HOVERED_SCHOOL_ID';
export const SET_SCHOOLS_TAB = 'INSIGHTS_CONTEXT/SET_SCHOOLS_TAB';
export const SET_HOVERED_TRANSPORTATION_STATION = 'INSIGHTS_CONTEXT/SET_HOVERED_TRANSPORTATION_STATION';
export const SET_HOVERED_DATA_LAYER_ID = 'INSIGHTS_CONTEXT/SET_HOVERED_DATA_LAYER_ID';
export const COLLAPSE_INSIGHT_SECTION = 'INSIGHTS_CONTEXT/COLLAPSE_INSIGHT_SECTION';
export const SET_TABU_SUBMITTED = 'INSIGHTS_CONTEXT/SET_TABU_SUBMITTED';
export const SET_ACTIVE_MAP_TRANSPORTATION = 'INSIGHTS_CONTEXT/SET_ACTIVE_MAP_TRANSPORTATION';
export const SET_IS_MOBILE_LAYERS_TOGGLE_OPEN = 'INSIGHTS_CONTEXT/SET_IS_MOBILE_LAYERS_TOGGLE_OPEN';
export const SET_IS_INFO_BOX_OPEN = 'INSIGHTS_CONTEXT/SET_IS_INFO_BOX_OPEN';
export const SET_ACTIVE_BUS_LINE = 'INSIGHTS_CONTEXT/SET_ACTIVE_BUS_LINE';
