import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { ToastType, FeedbackToast } from './FeedbackToast';
import { useLocale, HebrewKeys } from 'locale';
import { STICKY_ROOT } from 'consts/rootNodes';

const ToastContainerWrapper = styled.div`
  position: fixed;
  z-index: 2019;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    left: 0;
    bottom: 0;
    right: 0;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    bottom: 24px;
    border-radius: ${theme.borderRadius.tiny};
    ${theme.isRTL ? 'left' : 'right'}: 24px;
  `}
`;

export const TOAST_AUTO_HIDE_TIMEOUT = 3000;

export interface FeedbackToastMessage {
  term: HebrewKeys;
  params?: Record<string, string | boolean | number>;
  type?: ToastType;
}

interface ToastContainerProps {
  message: FeedbackToastMessage;
  onClose: () => void;
  isDesktop: boolean;
}

export const ToastContainer: React.FC<ToastContainerProps> = ({ message, onClose, isDesktop }) => {
  const { t } = useLocale();

  useEffect(() => {
    let timeout: number = null;
    if (message) {
      timeout = window.setTimeout(onClose, TOAST_AUTO_HIDE_TIMEOUT);
    }
    return () => clearTimeout(timeout);
  }, [ message, isDesktop ]);

  if (!message) return null;

  return ReactDOM.createPortal(
    <>
      <ToastContainerWrapper
        data-auto="toast-container-wrapper"
        onClick={onClose}
      >
        <FeedbackToast type={message.type}>
          {t(message.term, message.params)}
        </FeedbackToast>
      </ToastContainerWrapper>
    </>,
    document.getElementById(STICKY_ROOT)
  );
};
