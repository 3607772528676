export enum KeyboardKeys {
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  ESC = 'Escape',
  Backspace = 'Backspace',
}

export enum KeyboardNumberKeys {
  Enter = 13,
  Space = 32,
}
