import React from 'react';
import styled from '@emotion/styled';
import { GenericFilterProps } from '../types';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { useLocale } from 'locale';
import { TextLabel } from 'ds/components/typography';

const Root = styled.div<{ useDesktopMedia: boolean }>(({ theme, useDesktopMedia }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  ...(useDesktopMedia ? {
    marginRight: theme.spacing(1),
    '[data-auto="checkmark"]': {
      marginLeft: theme.spacing(0.75),
    },
    [theme.media.createCustomObjectMediaQuery('0', '1443px')]: {
      display: 'none',
    },
  } : {
    [theme.media.createCustomObjectMediaQuery('1444px', '9999px')]: {
      display: 'none',
    },
  }),
}));

const LabelNew = styled(TextLabel)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: theme.borderRadius.tiny,
  backgroundColor: theme.colors.label.yellow,
}));

interface ProjectDiscountSectionProps extends GenericFilterProps<'projectDiscount'> {}

interface BaseProjectDiscountProps {
  handleChange: () => void;
  value: boolean;
  useDesktopMedia?: boolean;
}

export const BaseProjectDiscount: React.FC<BaseProjectDiscountProps> = ({
  value,
  handleChange,
  useDesktopMedia = false,
}) => {
  const { t } = useLocale();

  return (
    <Root useDesktopMedia={useDesktopMedia}>
      <CheckboxField checked={value} onClick={handleChange} small={useDesktopMedia} label={t('filters.projectDiscount')} />
      <LabelNew small weight="bold">{t('navigation.header.new')}</LabelNew>
    </Root>
  );
};

const ProjectDiscountSection: React.FC<ProjectDiscountSectionProps> = ({
  intermediateValue,
  setIntermediateValue,
  commitValues,
}) => {
  const handleChange = () => {
    setIntermediateValue(!intermediateValue, commitValues);
  };

  return <BaseProjectDiscount useDesktopMedia value={intermediateValue} handleChange={handleChange} />;
};

export default ProjectDiscountSection;
