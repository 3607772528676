import styled from '@emotion/styled';
import { InsightType } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';

export const SeeAllButton = styled.div<{ type: InsightType }>`
  cursor: pointer;
  margin: 16px 0;
  color: ${({ theme, type }) => theme.colors.insights[type]};
  display: inline-flex;
  svg {
    margin-${({ theme }) => theme.isRTL ? 'right' : 'left'}: 4px;
    transform: rotate(${({ theme }) => theme.isRTL ? '' : '-'}90deg);
    path {
      fill: ${({ theme, type }) => theme.colors.insights[type]};
    }
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    padding: 0 24px;
    border-radius: ${theme.borderRadius.tiny};
    position: relative;
    border: 1px solid ${theme.colors.neutrals.grey8};
    background: ${theme.colors.neutrals.white};
    overflow: hidden;
  `};
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    padding: 8px 24px;
    background: ${theme.colors.neutrals.white};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadow.level3};
  `}
`;

export const Content = styled.div<{ type: InsightType, isActive: boolean }>`
  position: relative;
  & > section {
    padding: 0;
  }
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    &:not(:last-of-type) > section > div:last-of-type {
      border-bottom: 1px solid ${theme.colors.neutrals.grey8};
    }
  `}
  ${({ theme, isActive, type }) => theme.media.showAt({ from: 2 })`
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      right: -24px;
      left: -24px;
      height: 1px;
      background: ${theme.colors.neutrals.grey8};
    }
    ${isActive !== undefined ? (
      isActive ? `
        &:after {
          content: '';
          position: absolute;
          ${theme.isRTL ? 'right' : 'left'}: -24px;
          top: 0;
          bottom: 0;
          width: 6px;
          background: ${colorToAlpha(theme.colors.insights[type], 0.2)};
        }
      ` : ''
) : ''}
  `}
`;
