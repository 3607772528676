import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useField } from 'react-final-form-hooks';
import { agentsInOfficeSelector } from 'store/state/domainData/selectors';
import { ICommercialAgentsInOffice } from 'store/sagas/apiService/types';
import { State } from 'store/state';
import { UploadBulletinStepProps } from 'screens/UploadBulletinPage/steps';
import { SelectFieldWrapper, StepHeading } from 'screens/UploadBulletinPage/styled';
import { ITranslate, useLocale } from 'locale';
import { Text } from 'ds/components/typography';
import { SpacingBlock } from 'components/spacing-block';
import Select from 'ds/components/input/Select';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { BuildingClass } from 'utils/entities';
import { isNotEmpty } from 'utils/formValidationHelpers';


const SelectWithValidationMessage = withValidationMessage(Select);

const validateField = (t: ITranslate) => (val: BuildingClass) =>
  isNotEmpty(val) ? undefined : t('uploadBulletinPage.emptyFieldValidationMessage');

interface SelectAgentStepProps extends UploadBulletinStepProps{
  agents: ICommercialAgentsInOffice[];
}

const SelectAgentStep: React.FC<SelectAgentStepProps> = ({
  agents,
  formInstance,
}) => {
  const { t } = useLocale();
  const assignedAgentUserId = useField('assignedAgentUserId', formInstance, validateField(t));

  const allOptions = useMemo(() => agents.map(agent => ({
    label: agent.agentName,
    value: agent.userId,
  })), [ agents ]);

  return (
    <>
      <StepHeading>{t('uploadBulletinPage.selectAgent.heading')}</StepHeading>
      <Text>{t('uploadBulletinPage.selectAgent.subheader')}</Text>
      <SpacingBlock mTop={2}>
        <SelectFieldWrapper
          error={assignedAgentUserId.meta.error && assignedAgentUserId.meta.touched}
          isEmpty={!assignedAgentUserId.input.value}
        >
          <SelectWithValidationMessage
            size="large"
            items={allOptions}
            {...assignedAgentUserId.meta}
            {...assignedAgentUserId.input}
            selectionPromptText={t('uploadBulletinPage.selectAgent.placeholder')}
          />
        </SelectFieldWrapper>
      </SpacingBlock>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  agents: agentsInOfficeSelector(state),
});

export default connect(mapStateToProps)(SelectAgentStep);
