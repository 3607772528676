import styled from '@emotion/styled';

export const HoverCardRoot = styled.div`
  ${({ theme }) => `
    & > a {
      text-decoration: inherit;
      color: inherit;
    }
  `}
`;

export const HoverCardInner = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.spacing(1)};

    & > a {
      text-decoration: inherit;
      color: inherit;
    }
  `}
`;
