import React from 'react';
import { useField } from 'react-final-form-hooks';
import { ITranslate, useLocale } from 'locale';
import { DealType } from 'utils/entities';
import { isNotEmpty, isPositiveNumber } from 'utils/formValidationHelpers';
import { SpacingBlock } from 'components/spacing-block';
import { UploadBulletinStepProps } from '../../';
import { InputWrapper, Section, SectionHeading } from 'screens/UploadBulletinPage/styled';
import { MainPriceWrapper, ShekelWrapper, TaxPricesFieldsWrapper, TaxPricesWrapper } from './styled';
import { RangeTuple } from 'components/filters/types';
import NumberInput from 'screens/UploadBulletinPage/components/number-input';


const ShekelIcon = () => <ShekelWrapper>&#8362;</ShekelWrapper>;

export const validatePriceField = (t: ITranslate, isRent: boolean, [ min, max ]: RangeTuple<number> ) => (val: string) => {
  if (!isNotEmpty(val) || !isPositiveNumber(val)) {
    return t('uploadBulletinPage.emptyFieldValidationMessage');
  }

  const inputPrice = Number.parseInt(val, 10);
  const isOutOfRange = inputPrice < min || inputPrice > max;

  if (isRent && isOutOfRange) {
    return t('uploadBulletinPage.wrongRentPrice');
  }

  if (!isRent && isOutOfRange) {
    return t('uploadBulletinPage.wrongBuyPrice');
  }

  return undefined;
};

const COMMON_CHARGES_MAX = 100000;
const MONTHLY_TAXES_MAX = 999999;
const MONTHLY_MANAGEMENT_FEE_MIN = 5;
const MONTHLY_MANAGEMENT_FEE_MAX = 99999;

const validateManagementFee = (t: ITranslate, maxValue: number, minValue: number) => (val: string) => {
  if (isNotEmpty(val) && (+val > maxValue || +val < minValue)) {
    return t('uploadBulletinPage.pricesStep.monthlyManagementFeeError');
  }

  return undefined;
};

const validateAdditionalFields = (t: ITranslate, maxValue: number) => (val: string) => {
  if (isNotEmpty(val)) {
    if (!isPositiveNumber(val)) {
      return t('uploadBulletinPage.emptyFieldValidationMessage');
    }
    else if (+val > maxValue) {
      return t('uploadBulletinPage.maxValueFieldValidationMessage', { forMoney: true });
    }
  }

  return undefined;
};

interface PriceSectionProps extends UploadBulletinStepProps{
  isCommercial?: boolean;
  priceLimits: Record<DealType, RangeTuple<number>>;
}

export const PricesSection: React.FC<PriceSectionProps> = ({
  formInstance,
  isCommercial,
  priceLimits,
  hideErrorOnFocus,
}) => {
  const { t } = useLocale();

  const formValues = formInstance.getState().values;
  const dealType = formValues.dealType || DealType.Buy;
  const isRent = dealType === DealType.Rent;

  const priceField = useField('price', formInstance, validatePriceField(t, isRent, priceLimits[dealType]));
  const monthlyTaxesField = useField('monthlyTaxes', formInstance, validateAdditionalFields(t, MONTHLY_TAXES_MAX));
  const commonCharges = useField('commonCharges', formInstance, validateAdditionalFields(t, COMMON_CHARGES_MAX));
  const monthlyManagementFee = useField('monthlyManagementFee', formInstance, validateManagementFee(t, MONTHLY_MANAGEMENT_FEE_MAX, MONTHLY_MANAGEMENT_FEE_MIN));

  return (
    <Section>
      <SpacingBlock mBottom={1}>
        <SectionHeading weight="bold">
          {t('uploadBulletinPage.pricesStep.mainPriceLabel')}
        </SectionHeading>
      </SpacingBlock>
      <MainPriceWrapper>
        <InputWrapper error={priceField.meta.touched && priceField.meta.error}>
          <NumberInput
            {...priceField.input}
            {...priceField.meta}
            onFocus={() => hideErrorOnFocus(true)}
            onBlur={() => hideErrorOnFocus(false)}
            icon={<ShekelIcon />}
          />
        </InputWrapper>
      </MainPriceWrapper>
      <TaxPricesWrapper>
        <SpacingBlock mBottom={1}>
          <SectionHeading weight="bold">
            {t('uploadBulletinPage.pricesStep.taxPriceLabel')}
          </SectionHeading>
        </SpacingBlock>
        <TaxPricesFieldsWrapper>
          <InputWrapper error={monthlyTaxesField.meta.touched && monthlyTaxesField.meta.error}>
            <NumberInput
              {...monthlyTaxesField.input}
              {...monthlyTaxesField.meta}
              icon={<ShekelIcon />}
              label={t('uploadBulletinPage.pricesStep.propertyTaxLabel')}
            />
          </InputWrapper>
          {isRent && isCommercial ? (
            <InputWrapper error={monthlyManagementFee.meta.touched && monthlyManagementFee.meta.error}>
              <NumberInput
                {...monthlyManagementFee.input}
                {...monthlyManagementFee.meta}
                icon={<ShekelIcon />}
                label={t('uploadBulletinPage.pricesStep.monthlyManagementFeeLabel')}
              />
            </InputWrapper>
          ) : null}
          {isCommercial ? null : (
            <InputWrapper error={commonCharges.meta.touched && commonCharges.meta.error}>
              <NumberInput
                {...commonCharges.input}
                {...commonCharges.meta}
                icon={<ShekelIcon />}
                label={t('uploadBulletinPage.pricesStep.committeeTaxLabel')}
              />
            </InputWrapper>
          )}
        </TaxPricesFieldsWrapper>
      </TaxPricesWrapper>
    </Section>
  );
};
