import { State } from 'store/state';
import { routeSelector } from 'store/state/selectors/router';
import { DumbLink } from './Link';
import { connect } from 'react-redux';
import { navigateTo } from 'store/state/router/actions';

const mapStateToProps = (state: State) => ({
  route: routeSelector(state),
});

const mapDispatchToProps = {
  navigate: navigateTo,
};

export const Link = connect(mapStateToProps, mapDispatchToProps)(DumbLink);
