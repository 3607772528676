import { IEnhancer, Fetcher } from '.';

export class PXFetchEnhancer implements IEnhancer {
  private px: string = null;

  constructor() {
    const { searchParams } = new URL(window.location.href);
    this.px = searchParams.get('GreatOaksFromLittleAcornsGrow');
  }

  public enhance = (url: string, init: RequestInit, innerFetch: Fetcher) => {
    const urlObj = new URL(url);
    if (this.px) {
      urlObj.searchParams.append('GreatOaksFromLittleAcornsGrow', this.px);
      urlObj.search = urlObj.searchParams.toString();
    }

    return innerFetch(urlObj.toString(), init);
  }
}
