import {
  projectPageProjectSelector,
  schoolTradeoffsSelector,
  unitPageBulletinSelector,
} from 'store/state/domainData/selectors';
import { createSelector } from 'reselect';

export const bestSchoolSelector = createSelector([
  schoolTradeoffsSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
], (bestSchool, bulletin, project) => {

  if (bulletin) {
    return { bestSchool, rating: bulletin.tags.bestSchool };
  }
  if (project) {
    return { bestSchool, rating: project.tags && project.tags.bestSchool };
  }

  return { bestSchool };
});

