import React, { useContext, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isServer } from 'utils';
import { groupBy } from 'lodash';
import { requestIdleCallback } from './requestIdleCallback';


export const PortalContext = React.createContext<Array<[ string, React.ReactNode ]>>(null);

const SSRPortal: React.FC<{ containerId: string, children: React.ReactNode }> = (props) => {
  const ctx = useContext(PortalContext);
  ctx.push([ props.containerId, props.children ]);
  return null;
};

export const PortalOutput: React.FC<{ ids: string[] }> = (props) => {

  const ctx = useContext(PortalContext);
  const grouped = groupBy(ctx, ([ id ]) => id);

  return (
    <>
      {props.ids.map((id) => (
        <div key={id} id={id}>
          {(grouped[id] || []).map(([ _, children ]) => children)}
        </div>
      ))}
    </>
  );
};

const ClientPortalContext = React.createContext(false);

export const ClientPortalProvider: React.FC<{ ids: string[] }> = ({ children, ids }) => {
  const [ portalsMounted, setPortalsMounted ] = useState(false);
  const elementsToRemove = useRef<ChildNode[]>([]);

  useEffect(() => {

    ids.forEach((id) => {
      const element = document.getElementById(id);
      element.childNodes.forEach(e => elementsToRemove.current.push(e));
    });

    requestIdleCallback(() => setPortalsMounted(true));
  }, [ ids ]);

  useEffect(() => {
    if (portalsMounted) {
      for (const e of elementsToRemove.current) {
        e.remove();
      }
    }
  }, [ portalsMounted, ids ]);

  return (
    <ClientPortalContext.Provider value={portalsMounted}>
      {children}
    </ClientPortalContext.Provider>
  );
};

const ClientPortal: React.FC<{ containerId: string }> = ({ containerId, children }) => {
  const portalsMounted = useContext(ClientPortalContext);

  const container = document.getElementById(containerId);
  return portalsMounted ? ReactDOM.createPortal(children, container) : null;
};

export function createPortal(children: React.ReactNode, containerId: string): React.ReactPortal {
  if (isServer()) {
    return <SSRPortal containerId={containerId}>{children}</SSRPortal> as React.ReactPortal;
  }
  return <ClientPortal containerId={containerId}>{children}</ClientPortal> as React.ReactPortal;

}
