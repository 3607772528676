import React from 'react';

export interface FeedbackFormFields {
  radioButtonOption: string;
  commentMessage: string;
  userEmail: string;
}

export interface IFeedbackOption {
  label: React.ReactNode;
  value: string;
}

export enum FeedbackPopupOptionsValue {
  Cool = 'Cool',
  Wrong = 'Wrong',
  Review = 'Review',
  IncorrectInfo = 'IncorrectInfo',
  NotAvailable = 'NotAvailable',
  NotExclusive = 'NotExclusive',
  Fraud = 'Fraud',
  Irrelevant = 'Irrelevant',
  NotInterested = 'NotInterested',
  Useful = 'Useful',
  Other = 'Other',
}

export const addressPageFeedbackOptions = [
  FeedbackPopupOptionsValue.Review,
  FeedbackPopupOptionsValue.IncorrectInfo,
  FeedbackPopupOptionsValue.Other,
];

export const feedbackOptionsValues = [
  FeedbackPopupOptionsValue.Cool,
  FeedbackPopupOptionsValue.Wrong,
  FeedbackPopupOptionsValue.Other,
];

export const reportListingOptionsValues = [
  FeedbackPopupOptionsValue.IncorrectInfo,
  FeedbackPopupOptionsValue.NotAvailable,
  FeedbackPopupOptionsValue.Fraud,
  FeedbackPopupOptionsValue.Other,
];

export enum FeedbackPopupType {
  Feedback = 'insight',
  ReportListing = 'report',
  UGC = 'UGC',
  Tabu = 'tabu',
}
