import IconArea from 'assets/svg/area.svg';
import IconBaths from 'assets/svg/baths.svg';
import IconBeds from 'assets/svg/beds.svg';
import IconFloor from 'assets/svg/floor.svg';
import IconBusinessClass from 'assets/svg/project-16-type.svg';
import IconPricePerSqft from 'assets/svg/ppsm.svg';
import IconPeople from 'assets/svg/people.svg';
import IconFurniture from 'assets/svg/furniture.svg';
import IconQuality from 'assets/svg/ok-circle.svg';
import React from 'react';
import { isNil } from 'lodash';
import { TextLabel } from 'ds/components/typography';
import {
  AddressWrapper,
  CurrentPrice,
  CurrentPriceText,
  DealTypeInfo,
  DealTypeInfoWrapper,
  MainInfoWrapper,
  OfferDetails,
  PriceFirstLine,
  PriceWrapper,
  PrimaryAddress,
  PrimaryAddressText,
  SecondaryAddress,
  SecondaryAddressText,
  PreviousPrice,
} from './styled';
import { BuildingClass, DealType, getBuildingClassKey, IGeneralCondition } from 'utils/entities';
import EstimatedPrice from './estimated-price';
import { useLocale, ITranslate } from 'locale';
import { AgentTags } from 'components/cards/listing/UniversalCard/AgentTags';
import ScrollIntoView from 'scroll-into-view';
import { AUTO_SCROLL_DURATION, Section as SectionEnum } from 'components/navigation/subheader/SectionsNavigation';
import withEvents from 'analytics/withEvents';
import { OfferDetail } from './OfferDetail';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { DESKTOP_HEADER_TOTAL_HEIGHT, MOBILE_HEADER_TOTAL_HEIGHT, MOBILE_SUBMENU_HEIGHT } from 'components/navigation/header.constants';
import { QualityClassOption } from 'components/filters/types';

interface MainInfoProps {
  currentPrice: number;
  primaryAddress: string;
  secondaryAddress: string;
  dealType: string;
  floor?: string | number;
  floors?: string | number;
  beds?: number;
  baths?: number;
  area?: number;
  buildingClass: BuildingClass;
  hideOfferDetailIcon?: boolean;
  priceDropped?: number;
  numberOfEmployees?: number;
  qualityClass?: QualityClassOption;
  generalCondition?: IGeneralCondition;
  isCommercial?: boolean;
}

export interface AgentProps {
  isExclusive?: boolean;
  isAgent?: boolean;
}

enum OfferDetailsType {
  BuildingClass = 'buildingClass',
  BedsCount = 'bedsCount',
  Bath = 'bath',
  Floor = 'floor',
  Area = 'area',
  PricePerSize = 'pricePerSize',
  People = 'people',
  GeneralCondition = 'generalCondition',
  QualityClass = 'qualityClass',
}

const DEFAULT_OFFER_DETAILS = [
  OfferDetailsType.BuildingClass,
  OfferDetailsType.BedsCount,
  OfferDetailsType.Bath,
  OfferDetailsType.Floor,
  OfferDetailsType.Area,
  OfferDetailsType.PricePerSize,
];

const COMMERCIAL_OFFER_DETAILS = [
  OfferDetailsType.BuildingClass,
  OfferDetailsType.Area,
  OfferDetailsType.PricePerSize,
  OfferDetailsType.People,
  OfferDetailsType.Floor,
  OfferDetailsType.GeneralCondition,
  OfferDetailsType.QualityClass,
];

interface FloorDetailsOptions extends Pick<MainInfoProps, 'hideOfferDetailIcon'> {
  text: string;
}
interface FloorDescriptionOption extends Pick<MainInfoProps, 'floor' | 'floors'> {
  t: ITranslate;
  isCommercial: boolean;
}

function createFloorDescription({ t, isCommercial, floor, floors }: FloorDescriptionOption): string {
  if (isCommercial && isNil(floor) && !isNil(floors)) {
    return t('unitPage.commercial.floors', { floors });
  }
  else if (!isNil(floor)) {
    return t('unitPage.floors', { floor, floors });
  }
  return null;
}

function createFloorDetailsConfig({ hideOfferDetailIcon, text }: FloorDetailsOptions) {
  return {
    locator: 'floor',
    icon: <IconFloor width={16} height={16} />,
    hideIcon: hideOfferDetailIcon,
    isVisible: Boolean(text),
    content: <div>{text}</div>,
  };
}


type WrapperMainInfoProps = MainInfoProps & AgentProps & { sendPriceEstimationClickEvent?: () => void };

const MainInfo: React.FC<WrapperMainInfoProps> = React.memo(({
  currentPrice,
  primaryAddress,
  secondaryAddress,
  dealType,
  beds,
  floor,
  baths,
  area,
  buildingClass,
  isAgent,
  isExclusive,
  sendPriceEstimationClickEvent,
  hideOfferDetailIcon,
  priceDropped,
  floors,
  numberOfEmployees,
  generalCondition,
  qualityClass,
  isCommercial,
}) => {
  const { t, formatMoney, formatArea, formatNumber } = useLocale();
  const breakpoint = useScreenBreakpoint();

  const isMobile = breakpoint <= 2;
  const pricePerSizeUnit = area ? currentPrice / area : undefined;

  const mobileSubmenuHeight = isMobile
    ? MOBILE_HEADER_TOTAL_HEIGHT + MOBILE_SUBMENU_HEIGHT
    : DESKTOP_HEADER_TOTAL_HEIGHT;

  const scrollSettings = {
    time: AUTO_SCROLL_DURATION,
    align: {
      topOffset: mobileSubmenuHeight + 16,
      top: 0,
    },
  };

  const handleClick = () => {
    if (typeof sendPriceEstimationClickEvent === 'function') sendPriceEstimationClickEvent();
    const el = document.querySelector<HTMLElement>(`[data-scroll-into-view=${SectionEnum.PRICES_ESTIMATION}]`);
    ScrollIntoView(el, scrollSettings);
  };

  const offerDetailsConfig = {
    [OfferDetailsType.BuildingClass]: {
      locator: 'business-class',
      icon: <IconBusinessClass width={16} height={16} />,
      hideIcon: false,
      isVisible: Boolean(buildingClass),
      content: t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial }),
    },
    [OfferDetailsType.BedsCount]: {
      locator: 'beds-count',
      icon: <IconBeds width={16} height={16} />,
      hideIcon: hideOfferDetailIcon,
      isVisible: typeof beds === 'number',
      content: t('listingCard.bedsCountLabel', { bedsCount: beds }),
    },
    [OfferDetailsType.Bath]: {
      locator: 'baths',
      icon: <IconBaths width={16} height={16} />,
      hideIcon: hideOfferDetailIcon,
      isVisible: Boolean(),
      content: t('unitPage.baths', { count: baths }),
    },
    [OfferDetailsType.Floor]: createFloorDetailsConfig({
      text: createFloorDescription({ t, floor, floors, isCommercial }),
      hideOfferDetailIcon,
    }),
    [OfferDetailsType.Area]: {
      locator: 'area',
      icon: <IconArea width={16} height={16} />,
      hideIcon: hideOfferDetailIcon,
      isVisible: Boolean(area) && area > 0,
      content: formatArea(area),
    },
    [OfferDetailsType.PricePerSize]: {
      locator: '',
      icon: <IconPricePerSqft width={16} height={16} />,
      hideIcon: hideOfferDetailIcon,
      isVisible: Boolean(pricePerSizeUnit) && (isCommercial || dealType === DealType.Buy) && pricePerSizeUnit > 0,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('unitPage.perSquareFeet', { price: formatMoney(pricePerSizeUnit) }),
          }}
        />
      ),
    },
    [OfferDetailsType.People]: {
      locator: '',
      icon: <IconPeople width={16} height={16} />,
      hideIcon: false,
      isVisible: Boolean(numberOfEmployees),
      content: t('commercial.listing.offerDetails.numberOfEmployees', { count: formatNumber(numberOfEmployees) }),
    },
    [OfferDetailsType.GeneralCondition]: {
      locator: '',
      icon: <IconFurniture width={16} height={16} />,
      hideIcon: false,
      isVisible: Boolean(generalCondition),
      content: t('filters.conditionsOptions', { value: generalCondition }),
    },
    [OfferDetailsType.QualityClass]: {
      locator: '',
      icon: <IconQuality width={16} height={16} />,
      hideIcon: false,
      isVisible: Boolean(qualityClass),
      content: t('commercial.listing.offerDetails.qualityClass', { value: qualityClass }),
    },
  };

  return (
    <>
      <MainInfoWrapper>
        <AddressWrapper>
          <PrimaryAddress data-auto="primary_address">
            <PrimaryAddressText isCommercial={isCommercial} data-auto="primary_address_text">{primaryAddress}</PrimaryAddressText>
          </PrimaryAddress>
          <SecondaryAddress data-auto="secondary_address">
            <SecondaryAddressText isCommercial={isCommercial} data-auto="secondary_address_text">{secondaryAddress}</SecondaryAddressText>
            {isAgent ? <AgentTags isExclusive={isExclusive} /> : null}
          </SecondaryAddress>
        </AddressWrapper>
        <PriceWrapper>
          <PriceFirstLine data-auto="price">
            <CurrentPrice data-auto="current-price">
              <CurrentPriceText isCommercial={isCommercial} weight="bold">{formatMoney(currentPrice)}</CurrentPriceText>
            </CurrentPrice>
            {priceDropped ? (
              <PreviousPrice>{formatMoney(priceDropped)}</PreviousPrice>
            ) : null}
          </PriceFirstLine>
          {dealType === DealType.Buy && !isCommercial ? <EstimatedPrice handleScroll={handleClick} /> : null}
        </PriceWrapper>
      </MainInfoWrapper>

      <DealTypeInfoWrapper>
        <DealTypeInfo>
          <TextLabel caps weight="bold">
            {t('unitPage.dealTypeLabel', { buildingClass: getBuildingClassKey(buildingClass), dealType, isCommercial })}
          </TextLabel>
        </DealTypeInfo>
      </DealTypeInfoWrapper>

      {/* Offer-details should be fixed for correct commercial bulletin and project card type */}
      <OfferDetails data-auto="offer-details" isMobile={isMobile}>
        {(isCommercial ? COMMERCIAL_OFFER_DETAILS : DEFAULT_OFFER_DETAILS).map((offerType, i) => {
          const { locator, icon, hideIcon, isVisible, content } = offerDetailsConfig[offerType];
          return isVisible ? (
            <OfferDetail
              key={i}
              locator={locator || undefined}
              icon={icon}
              hideIcon={hideIcon}
            >
              {content}
            </OfferDetail>
          ) : null;
        })}
      </OfferDetails>
    </>
  );
});

const MainInfoWithEvents = withEvents<WrapperMainInfoProps>(sendEvent => ({
  sendPriceEstimationClickEvent() {
    sendEvent('property_estimation_click', 'property');
  },
}))(MainInfo);

export default MainInfoWithEvents;
