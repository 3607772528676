import { call, put, select, take } from 'redux-saga/effects';
import { addViewedPoi } from 'store/state/searchContext/actions';
import { IPoiIdentifier, PoiType, User, PoiId, ICommercialProject, ICommercialBulletin } from 'utils/entities';
import { Route } from 'config/routes';


import { RootAction } from 'store/state';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { TransitionAction } from 'store/sagas/routing';
import { IBulletin, IProject } from 'utils/entities';
import { unitPageBulletinSelector, projectPageProjectSelector, isUserSelector, projectPageCommercialProjectSelector, unitPageCommercialBulletinSelector } from 'store/state/domainData/selectors';
import { LoadType, MutationType } from './apiService/types';
import { mutate } from 'store/state/mutationsResponse/actions';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { waitForUserChange } from './routing/handlers/utils';


const poiPageTransitionPattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS && (
    action.payload.route.name === Route.UnitPage ||
    action.payload.route.name === Route.ProjectPage ||
    action.payload.route.name === Route.UnitPageCommercial ||
    action.payload.route.name === Route.ProjectPageCommercial
  )
);

const poiPageLoadPattern = (action: RootAction) => {
  if (action.type === SET_DOMAIN_DATA) {
    if (action.loadType === LoadType.UnitPageBulletin) {
      return action.payload.data && action.payload.data.poiByIds.length > 0;
    }
    else if (action.loadType === LoadType.ProjectPageProject) {
      return action.payload.data && action.payload.data.poiByIds.length > 0;
    }
    else if (action.loadType === LoadType.UnitPageCommercialBulletin) {
      return action.payload.data && action.payload.data.poiByIds.length > 0;
    }
    else if (action.loadType === LoadType.ProjectPageCommercialProject) {
      return action.payload.data && action.payload.data.poiByIds.length > 0;
    }
  }
  return false;
};

const ROUTE_TO_POI_TYPE: Partial<Record<Route, PoiType>> = {
  [Route.UnitPage]: 'bulletin',
  [Route.ProjectPage]: 'project',
  [Route.UnitPageCommercial]: 'commercialBulletin',
  [Route.ProjectPageCommercial]: 'commercialProject',
};

export function* waitForPoiView() {
  let routeName: string = null;
  let routeId: PoiId = null;

  while (true) {
    const transitionAction: TransitionAction = yield take(poiPageTransitionPattern);
    routeName = transitionAction.payload.route.name;
    routeId = transitionAction.payload.route.params.id;

    const isSame = transitionAction.payload.previousRoute
      && transitionAction.payload.previousRoute.name === routeName
      && transitionAction.payload.previousRoute.params.id === routeId;

    if (!isSame) break;
  }

  const unitPageBulletin: IBulletin = yield select(unitPageBulletinSelector);
  const projectPageProject: IProject = yield select(projectPageProjectSelector);
  const commercialProjectPageProject: ICommercialProject = yield select(projectPageCommercialProjectSelector);
  const commercialUnitPageBulletin: ICommercialBulletin = yield select(unitPageCommercialBulletinSelector);

  const isAlreadyLoaded = (
    routeName === Route.UnitPage && unitPageBulletin && unitPageBulletin.id === routeId
  ) || (
    routeName === Route.ProjectPage && projectPageProject && projectPageProject.id === routeId
  ) || (
    routeName === Route.UnitPageCommercial && commercialUnitPageBulletin && commercialUnitPageBulletin.id === routeId
  ) || (
    routeName === Route.ProjectPageCommercial && commercialProjectPageProject && commercialProjectPageProject.id === routeId
  );

  if (!isAlreadyLoaded) {
    yield take(poiPageLoadPattern);
  }

  const poiId: IPoiIdentifier = { type: ROUTE_TO_POI_TYPE[routeName], id: routeId };
  return poiId;
}


export function* viewedPoiWatcher() {
  while (true) {
    const poiId: IPoiIdentifier = yield call(waitForPoiView);
    const isUser: User = yield select(isUserSelector);

    if (!isUser) {
      yield call(waitForUserChange);
    }

    yield put(mutate({
      mutationType: MutationType.AddRecentlyViewed,
      meta: {
        variables: {
          lastViewed: [ poiId ],
        },
      },
    }));

    yield put(addViewedPoi({ date: new Date().toISOString(), poiId }));
  }
}
